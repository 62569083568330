import React from 'react';
import { useQuery, gql } from '@apollo/client';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Spinner, ErrorMessage } from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import Truncate from '../../../../components/Truncate';
import Comment from '../../components/comment';
import { getRole } from '../../../../utilities/types/role-types';
import { useFvsRequirements } from '../requirements-provider';
import { useFvsCommentsContext } from '../../fvs-comments-provider';

const useGetComment = (commentId, isCommentLoaded) => {
  const queryResult = useQuery(
    gql`
      query getCommentById($id: Float!) {
        getCommentById(id: $id) {
          id
          comment
          repliesCount
          status
          authorType
          createdByUser
          createdByUserInfo {
            id
            fullName
            email
          }
          createdAt
          publishStatus
          replies {
            id
            comment
            status
            authorType
            createdByUser
            createdAt
            publishStatus
            createdByUserInfo {
              id
              fullName
              email
            }
          }
          linkedEntity {
            ... on EquipmentAssociation {
              standardItemId
              equipmentCodeId
              associationTypeCode
              associationText
              standardItem {
                standardItemNumber
              }
              associationType {
                title
              }
              equipmentCode {
                code
                title
                program
                category
                content {
                  content
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        id: parseInt(commentId, 10),
      },
      skip: !commentId || isCommentLoaded,
      fetchPolicy: 'cache-and-network',
    },
  );

  return queryResult;
};

const CommentDetail = () => {
  const { currentUser } = useCurrentUser();
  const { selectedComment, equipmentAssociation } = useFvsRequirements();
  const { deleteStandardsComment } = useFvsCommentsContext();
  const role = getRole(currentUser?.roles);

  const location = useLocation();
  const { commentId } = queryString.parse(location?.search);
  const isCommentLoaded = selectedComment && selectedComment.id;
  const commentQueryResult = useGetComment(commentId, isCommentLoaded);
  const comment = selectedComment || commentQueryResult.data?.getCommentById;
  const eqAssociation = equipmentAssociation || comment?.linkedEntity;

  if (!commentQueryResult.called || commentQueryResult.loading) {
    return <Spinner />;
  }

  if (commentQueryResult.error) {
    return <ErrorMessage>{commentQueryResult.error.message}</ErrorMessage>;
  }

  if (!comment) {
    return <ErrorMessage>Comment not found</ErrorMessage>;
  }

  return (
    <>
      <div className="grid-col">
        <Comment
          role={role}
          comment={comment}
          currentUsrId={currentUser?.id}
          deleteStandardsComment={deleteStandardsComment}
          submissionStatus={{
            action: 'delete',
            status: 'success',
            message: 'Deleted successfully',
          }}
          deletingComment={false}
        />
        <br />
        <div
          style={{ borderLeft: '1px solid #DFE1E2' }}
          className="padding-left-2"
        >
          {comment?.replies?.map((reply, i) => (
            <div
              key={reply.id}
              className={`${i === 0 ? 'margin-top-2' : 'margin-top-4'}`}
            >
              <ul className="add-list-reset">
                <li className="margin-bottom-4">
                  <Comment
                    role={role}
                    comment={reply}
                    currentUserEmail=""
                    deleteStandardsComment={deleteStandardsComment}
                    submissionStatus={{
                      action: 'delete',
                      status: 'success',
                      message: 'Deleted successfully',
                    }}
                    deletingComment={false}
                  />
                </li>
              </ul>
            </div>
          ))}
        </div>
        <hr />
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <div className="text-bold" tabIndex="0">
          Equipment Code
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <span tabIndex="0">{eqAssociation?.equipmentCode?.code}</span>
        <br />
        <br />
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <div className="text-bold" tabIndex="0">
          Long title
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <span tabIndex="0">{eqAssociation?.equipmentCode?.title}</span>
        <br />
        <br />
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <div className="text-bold" tabIndex="0">
          Description
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <span tabIndex="0">
          {eqAssociation?.equipmentCode?.content?.content && (
            <Truncate
              size={500}
              content={eqAssociation?.equipmentCode?.content?.content}
            />
          )}
          {!eqAssociation?.equipmentCode?.content?.content && (
            <span>&ndash;</span>
          )}
        </span>
        <br />
        <br />
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <div className="text-bold" tabIndex="0">
          Category
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <span tabIndex="0">{eqAssociation?.equipmentCode?.category}</span>
        <br />
        <br />
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <div className="text-bold" tabIndex="0">
          Standard/Optional
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <span tabIndex="0">{eqAssociation?.associationType?.title}</span>
      </div>
    </>
  );
};

CommentDetail.propTypes = {};

export default CommentDetail;
