import React from 'react';
import BidLineDetailsProvider from './provider/bid-line-details-provider';
import BidLineDetailsContent from './bid-line-details-content';

const BidLineDetails = () => {
  return (
    <BidLineDetailsProvider>
      <BidLineDetailsContent />
    </BidLineDetailsProvider>
  );
};

export default BidLineDetails;
