import React, { useEffect, useState } from 'react';
import { Spinner } from '@gsa/afp-component-library';
import { useParams, useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import SolicitationStepper from '../../../components/solicitation-stepper';
import Breadcrumbs from '../../../../../widgets/breadcrumbs';
import SolicitationNotification from '../../../utils/solicitation-notification';
import { GET_SOLICITATION_BY_ID } from '../../../queries';
import './edit-solicitation.scss';
import EditSolicitationFormWrapper from './edit-solicitation-form-context';
import { processOpenPeriod } from '../../common/reopen-periods/open-reopen-period-util';
import SolicitationStatusBadge from '../../../components/solicitation-status-badge';
import { ACTION_PARAM_KEY } from '../../../route-utils';

const EditSolicitation = () => {
  const { id } = useParams();
  const { search } = useLocation();
  const solicitaionProcessIdentifier = new URLSearchParams(search).get(
    ACTION_PARAM_KEY,
  );
  const [solicitation, setSolicitation] = useState(null);
  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs.home}/home`,
      label: 'Home',
    },
    {
      location: '/catalog/solicitations',
      label: 'Solicitations',
    },
  ];

  const [getSolicitationById, { loading }] = useLazyQuery(
    GET_SOLICITATION_BY_ID,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const current = data.getSolicitationById;
        const formattedSolicitation = {
          upiidAAC: current.solicitationNumber.substring(0, 6),
          upiidSQ: current.solicitationNumber.substring(6, 13),
          solicitationTitle: current.title,
          solicitationDescription: current.description,
          solicitationPosting: current.samUrl,
          contractYear: current.contractYear,
          purchaseType: current.purchaseTypeCode,
          solicitationType: current.solicitationType,
          solicitationStatus: current.status,
          solicitationNumber: current.solicitationNumber,
          solicitationID: current.id,
          bidType: current.bidType,
          openPeriod: processOpenPeriod([
            ...data.getSolicitationById.solicitationPeriods,
          ]),
        };
        setSolicitation(formattedSolicitation);
      },
    },
  );

  useEffect(() => {
    getSolicitationById({ variables: { id: Number(id) } });
  }, [id]);

  // eslint-disable-next-line
  return (
    <>
      <div className="grid-col edit-solicitation">
        {solicitaionProcessIdentifier === 'Renew' ? (
          <Breadcrumbs current="Renew Solicitation" path={breadcrumbsPath} />
        ) : (
          <Breadcrumbs current="Edit Solicitation" path={breadcrumbsPath} />
        )}
        <SolicitationNotification isModal={false} />
        {!loading ? (
          <>
            <div className="grid-row flex-row">
              {solicitaionProcessIdentifier === 'Renew' ? (
                <h1>Renew Solicitation - {solicitation?.solicitationNumber}</h1>
              ) : (
                <h1>Edit Solicitation - {solicitation?.solicitationNumber}</h1>
              )}
              <div className="statusBadgeContainer margin-left-1">
                {solicitaionProcessIdentifier !== 'Renew' ? (
                  <SolicitationStatusBadge
                    status={solicitation?.solicitationStatus}
                  />
                ) : null}
              </div>
            </div>
            <SolicitationStepper currentStep={1} />

            {solicitation?.solicitationID && (
              <EditSolicitationFormWrapper solicitation={solicitation} />
            )}
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
};

export default EditSolicitation;
