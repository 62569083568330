import React from 'react';
import BidLineDetailsProvider from '../provider/bid-line-details-provider';
import BidLineReviewAndSubmit from './bid-line-review-and-submit';
import { VendorInfoProvider } from '../../../bids/bids-center/apis/vendor-api';

export default () => {
  return (
    <VendorInfoProvider>
      <BidLineDetailsProvider isReviewSubmit>
        <BidLineReviewAndSubmit />
      </BidLineDetailsProvider>
    </VendorInfoProvider>
  );
};
