import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Icon } from '@gsa/afp-component-library';
import { CommentPropType } from '../../../utilities/types';

const Comment = ({ comment }) => {
  const fullName = comment?.createdByUserInfo?.fullName;
  const authorName = fullName || 'Public user';
  const author =
    comment?.authorType === 'Engineer' ||
    comment?.authorType === 'Senior Engineer'
      ? 'GSA Engineer'
      : authorName;

  return (
    <>
      <div className="grid-row">
        <div className="grid-col-10">
          <div className="display-flex flex-justify">
            <div className="display-flex flex-align-center">
              <Icon
                iconName="account_circle"
                className="usa-icon--size-3 margin-right-1"
              />
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
              <span className="text-bold" tabIndex="0">
                {author}
              </span>
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
              <span className="margin-x-4" tabIndex="0">
                {moment(comment?.createdAt).format('l')} &bull;{' '}
                {moment(comment?.createdAt).format('h:mm a')}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <p data-testid="comment-text" tabIndex="0">
        {comment?.comment}
      </p>
    </>
  );
};

Comment.propTypes = {
  comment: PropTypes.shape(CommentPropType).isRequired,
};

export default Comment;
