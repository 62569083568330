import React from 'react';
import { Button } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

const StandardItemAddButton = ({ setData }) => {
  const handleAddStandardItem = () => {
    setData('SET_FORM_MODAL', true);
    setData('SET_MODAL_MODE', 'add');
  };
  return (
    <div className="grid-row margin-bottom-2">
      <div className="grid-col">
        <div className="float-right">
          <Button
            data-testid="add-standard-item-btn"
            onClick={handleAddStandardItem}
            label="Add Standard Item"
            leftIcon={{
              name: 'add',
            }}
          />
        </div>
      </div>
    </div>
  );
};

StandardItemAddButton.propTypes = {
  setData: PropTypes.func.isRequired,
};

export default StandardItemAddButton;
