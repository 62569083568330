import React from 'react';
import PropTypes from 'prop-types';
import { Button, Spinner, EmptyState } from '@gsa/afp-component-library';

const DocumentViewTabContent = ({ download, useFvsSupportingDocs }) => {
  const {
    signedUrl,
    gettingReadURL,
    selectedDocument,
  } = useFvsSupportingDocs();

  if (
    selectedDocument?.metadata?.scanStatus === 'Accepted' ||
    selectedDocument?.metadata?.scanStatus === 'New'
  )
    return (
      <>
        <Button
          aria-label="download document"
          className="float-right margin-top-2 margin-bottom-6"
          onClick={() => download(selectedDocument)}
          label="Download"
          leftIcon={{
            name: 'file_download',
          }}
        />
        {gettingReadURL ? (
          <Spinner size="medium" />
        ) : (
          <div style={{ height: '90vh' }}>
            {selectedDocument?.metadata?.fileMimeType === 'application/pdf' ? (
              <object
                data={signedUrl}
                title={selectedDocument?.metadata?.name}
                type="application/pdf"
                width="100%"
                height="100%"
                aria-label={`PDF Document: ${selectedDocument?.metadata?.name}`}
              />
            ) : (
              <EmptyState
                hasBackground
                containerStyles="padding-y-8 margin-top-1"
                topText={
                  <strong>Preview is not available for the file format</strong>
                }
                topTextStyles="font-sans-md"
              />
            )}
          </div>
        )}
      </>
    );

  // all other scanStatuses
  return <div className="margin-top-4">The file is not available.</div>;
};

DocumentViewTabContent.propTypes = {
  download: PropTypes.func.isRequired,
  useFvsSupportingDocs: PropTypes.func.isRequired,
};
export default DocumentViewTabContent;
