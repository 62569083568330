import React from 'react';
import { useHistory } from 'react-router-dom';
import { PageTitle, Button, Spinner, Alert } from '@gsa/afp-component-library';
import { Can } from '@gsa/afp-shared-ui-utils';
import { OPERATIONS, SUBJECTS } from '../../../utilities/constants';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import { useBidLineEvaluation } from './bid-line-evaluation-provider';
import BidLineEvaluationFilter from './bid-line-evaluation-filter';
import BidLineEvaluationTable from './bid-line-evaluation-table';
import ReassignBidLineModal from './reassign-bid-line-modal';
import './bid-line-evaluation.scss';

const BidLineEvaluationContent = () => {
  const history = useHistory();
  const {
    bidInfo,
    evaluable,
    awardEvaluations,
    loadingBid,
    autoSaving,
    awarding,
    reassignRow,
    alert,
    setAlert,
  } = useBidLineEvaluation();

  return (
    <div className="bid-line-evaluation">
      <Breadcrumbs
        current="Line Items Evaluation"
        path={[
          {
            location: `${window.AFP_CONFIG.appURLs?.home}/home`,
            label: 'Home',
          },
          {
            location: `/catalog/proposal-evaluation`,
            label: 'Proposal Evaluation',
          },
        ]}
      />
      <div className="display-flex flex-row flex-justify margin-bottom-2">
        <div className="flex-grow">
          <PageTitle
            title="Line Items Evaluation"
            aria-label="Line Items Evaluation"
            className="margin-bottom-0"
          />
        </div>
        {!loadingBid && (
          <div className="padding-top-4">
            <div className="display-flex flex-row">
              <div className="text-right text-bold text-no-wrap">
                {bidInfo.vendorName} <br /> Solicitation
              </div>
              <div className="margin-x-1">
                {bidInfo.vendorUEI} <br />
                {bidInfo.solicitationNumber}
              </div>
            </div>
            <div className="text-right margin-top-1">
              <Button
                type="button"
                variant="outline"
                onClick={() => history.push('/catalog/bid-reports')}
                label="Bid reports"
              />
            </div>
          </div>
        )}
      </div>
      {alert && (
        <Alert
          className="margin-bottom-2"
          slim
          focused
          type={alert.type}
          onClose={() => setAlert(null)}
        >
          {alert.message}
        </Alert>
      )}
      {loadingBid && <Spinner size="small" />}
      {!loadingBid && evaluable !== null && (
        <div>
          {evaluable && (
            <Can I={OPERATIONS.Approve} a={SUBJECTS.Bid}>
              <Button
                variant="primary"
                label="Mark line items awarded"
                className="margin-bottom-2"
                onClick={awardEvaluations}
              />
            </Can>
          )}
          <BidLineEvaluationFilter />
          <BidLineEvaluationTable />
        </div>
      )}
      {(autoSaving || awarding) && (
        <div
          role="none"
          className="afp-modal-overlay"
          style={{ background: 'rgba(0, 0, 0, 0)' }}
          data-testid="save-evaluation-spinner"
        >
          <Spinner
            style={{
              position: 'absolute',
              top: '50%',
              transform: 'translate(0, -50%)',
            }}
          />
        </div>
      )}
      <Can I={OPERATIONS.EngineerReview} a={SUBJECTS.Bid}>
        {reassignRow && <ReassignBidLineModal />}
      </Can>
    </div>
  );
};

export default BidLineEvaluationContent;
