import { gql } from '@apollo/client';

export const GET_CONTRACT_LINES_BY_CONTRACT_ID = gql`
  query getContractLinesByContractId(
    $contractId: String!
    $limit: Float
    $offset: Float
    $order: OrderBy
  ) {
    getContractLinesByContractId(
      contractId: $contractId
      limit: $limit
      offset: $offset
      order: $order
    ) {
      count
      rows {
        id
        scheduleLine
        modelYear
        standardItemId
        standardItem {
          id
          standardItemNumber
          title
          vehicleType
          vehicleGroup {
            title
            code
          }
          vehicleTypeCode {
            title
          }
        }
        make {
          makeCode
          makeName
        }
        model {
          modelCode
          modelName
        }
        chassisMake {
          makeCode
          makeName
        }
        chassisModel {
          modelCode
          modelName
        }
        orderStartDate
        orderEndDate
        contractModificationRef {
          contract {
            contractUPiid
            solicitation {
              solicitationUpiid
            }
          }
        }
      }
      hasMore
    }
  }
`;

export const GET_CONTRACT_LINES_BY_CRITERIA = gql`
  query GetContractLinesByCriteria(
    $criteria: ContractLineFilterCriteriaType!
    $limit: Float
    $offset: Float
    $order: OrderBy
  ) {
    getContractLinesByCriteria(
      criteria: $criteria
      limit: $limit
      offset: $offset
      order: $order
    ) {
      count
      rows {
        id
        scheduleLine
        standardItemId
        standardItem {
          id
          standardItemNumber
          title
          vehicleType
          vehicleGroup {
            title
            code
          }
          vehicleTypeCode {
            title
          }
        }
        make {
          makeCode
          makeName
        }
        model {
          modelCode
          modelName
        }
        chassisMake {
          makeCode
          makeName
        }
        chassisModel {
          modelCode
          modelName
        }
        isDirect
        makeCode
        modelCode
        chassisMakeCode
        chassisModelCode
        modelYear
        orderStartDate
        orderEndDate
        solicitationLineId
        manufacturerModelNumber
        additionalInformation
      }
      hasMore
    }
  }
`;

export const GET_CONTRACT_LINES_FOR_FILTERS = gql`
  query getContractLinesForFilters($contractHeaderId: String!) {
    getContractLinesForFilters(contractHeaderId: $contractHeaderId) {
      id
      scheduleLine
      make {
        makeCode
        makeName
      }
      model {
        modelCode
        modelName
      }
      chassisMake {
        makeCode
        makeName
      }
      chassisModel {
        modelCode
        modelName
      }
      makeCode
      modelCode
      chassisMakeCode
      chassisModelCode
      modelYear
      manufacturerModelNumber
    }
  }
`;

export const GET_CONTRACT_LINES_FOR_IMPORT = gql`
  query GetContractLinesByCriteria($contractHeaderId: String!) {
    getContractLinesByCriteria(
      criteria: { contractHeaderId: $contractHeaderId }
      limit: 9999
      offset: 0
    ) {
      count
      rows {
        id
        bidLineId
        solicitationLineId
        scheduleLine
        standardItemId
        modelYear
        makeCode
        modelCode
        chassisMakeCode
        chassisModelCode
        modelYear
      }
    }
  }
`;

export const UPDATE_CONTRACT_LINES = gql`
  mutation updateMultipleContractLines(
    $contractLineUpdates: [UpdateContractLineInput!]!
  ) {
    updateMultipleContractLines(contractLineUpdates: $contractLineUpdates) {
      updatedContractLines {
        id
        orderStartDate
        orderEndDate
        updatedByUser
        isDirect
        scheduleLine
        # Include other fields you want to return
      }
      errors {
        contractLineId
        scheduleLine
        lineTemplateErrors {
          lineTabErrors {
            fieldName
            message
          }
          lineDetailTabErrors {
            fieldName
            message
          }
          colorsTabErrors {
            colorCode
            errors {
              fieldName
              message
            }
          }
          documentsTabError
        }
      }
    }
  }
`;

export const DELETE_CONTRACT_LINE_BY_ID = gql`
  mutation deleteContractLineById($contractLineId: Int!) {
    deleteContractLineById(contractLineId: $contractLineId)
  }
`;

export const GET_AWARDED_BID_LINES = gql`
  query getAwardedBidLinesDetails(
    $solicitationId: Float!
    $vendorId: String!
    $solicitationNumber: String
  ) {
    getAwardedBidLinesDetails(
      solicitationId: $solicitationId
      vendorId: $vendorId
      solicitationNumber: $solicitationNumber
    ) {
      id
      bidId
      scheduleLine
      standardItemId
      makeCode
      modelCode
      modelYear
      chassisMakeCode
      chassisModelCode
      standardItem {
        standardItemNumber
      }
      afpMake {
        makeName
      }
      afpModel {
        modelName
      }
      chassisMake {
        makeName
      }
      chassisModel {
        modelName
      }
      bid {
        soliciation {
          contractYear
        }
      }
    }
  }
`;

export const IMPORT_BID_LINES_TO_CONTRACT = gql`
  mutation importBidLinesToContract(
    $contractHeaderId: String!
    $bidLineId: Float!
    $contractLineId: Float
  ) {
    importBidLinesToContract(
      contractHeaderId: $contractHeaderId
      bidLineId: $bidLineId
      contractLineId: $contractLineId
    )
  }
`;
