import { isEqual } from 'lodash';
import { getTimestampFromDateNTime } from '../../../common/reopen-periods/open-reopen-period-util';

export const isSolicitationDetailsDataDirty = (initialData, currentData) => {
  if (!initialData || !currentData) {
    return false;
  }
  const oldData = {
    purchaseType: initialData.purchaseType,
    title: initialData.solicitationTitle,
    description: initialData.solicitationDescription,
    samPostingURL: initialData.solicitationPosting,
  };
  const newData = {
    purchaseType: currentData.purchaseType,
    title: currentData.solicitationTitle,
    description: currentData.solicitationDescription,
    samPostingURL: currentData.solicitationPosting,
  };
  return !isEqual(oldData, newData);
};

export const isSolicitationOpenPeriodDataDirty = (
  initialData,
  currentData,
  currentVendorData,
) => {
  if (!initialData || !currentData) {
    return false;
  }
  const initialSolicitationOpenPeriod = initialData?.solicitationPeriods
    ?.filter((period) => period?.periodType === 'O')
    ?.find((period) => period); // Using find() to get the first element, or undefined if the array is empty

  const oldData = {
    audience: initialSolicitationOpenPeriod?.audience,
    startDate: initialSolicitationOpenPeriod?.startDate,
    endDate: initialSolicitationOpenPeriod?.endDate,
    vendorIds: initialSolicitationOpenPeriod?.solicitationPeriodVendors?.map(
      (vendor) => vendor?.vendorDetail?.vendorId,
    ),
  };
  const newData = {
    audience: currentData?.audience,
    startDate: getTimestampFromDateNTime(
      currentData?.startDate,
      currentData?.startTime,
    ),
    endDate: getTimestampFromDateNTime(
      currentData?.endDate,
      currentData?.endTime,
    ),
    vendorIds: currentVendorData?.map((vendor) => vendor?.vendorId),
  };
  return !isEqual(oldData, newData);
};

export const isSolicitationSINSelectionsDataDirty = (initialData, newData) => {
  if (!initialData || !newData) {
    return false;
  }
  return !isEqual(initialData, newData);
};
