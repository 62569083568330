import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import AssociationForm from './equipment-code-association-form';
import { formSchema } from './equipment-code-association-form-elements/equipment-code-association-form-schema';
import { useEquipmentDetailCodes } from './equipment-code-detail-provider';

const AssociationAdd = () => {
  const {
    addEquipmentAssociation,
    selectedEquipmentCode,
    setData,
  } = useEquipmentDetailCodes();

  const { errors, handleSubmit, control, register, setValue } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onBlur',
  });

  const [standardItemId, setStandardItemId] = useState('');

  const onSubmit = (data) => {
    addEquipmentAssociation({
      variables: {
        equipmentAssociation: {
          ...data,
          standardItemId: Number(standardItemId),
          equipmentCodeId: Number(selectedEquipmentCode?.id),
        },
      },
    }).then((addEquipmentAssociationResponse) => {
      if (addEquipmentAssociationResponse?.data?.addEquipmentAssociation?.id) {
        const message = `You have successfully added Standard Item ${data.standardItemId} to Equipment Code ${selectedEquipmentCode?.code}`;
        setData('SET_SUCCESS_MESSAGE', message);
      }
    });
  };

  return (
    <form id="association-add-form" onSubmit={handleSubmit(onSubmit)}>
      <AssociationForm
        control={control}
        errors={errors}
        register={register}
        setValue={setValue}
        updateStandardItemId={(id) => {
          setStandardItemId(id);
        }}
        standardItemId={standardItemId}
      />
    </form>
  );
};

export default AssociationAdd;
