import { Button, Spinner } from '@gsa/afp-component-library';
import React from 'react';
import Modal from '../../../components/Modal/Modal';
import {
  CATALOG_CODES_MODAL_MODES,
  useCatalogCodes,
} from '../catalog-codes-provider';
import CatalogCodeMessages from './catalog-code-messages';
import CatalogCodeAdd from './catalog-codes-add';
import CatalogCodeEdit from './catalog-codes.edit';

const ModalTitle = () => {
  const {
    catalogCodesModalMode,
    selectedCategoryName,
    ccAddInProgress,
    ccUpdateInProgress,
  } = useCatalogCodes();

  let title = '';
  switch (catalogCodesModalMode) {
    case 'add':
      title = `Add ${selectedCategoryName}`;
      break;
    case 'edit':
      title = `Edit ${selectedCategoryName}`;
      break;
    case 'confirm_delete':
      title = `Confirm deletion of ${selectedCategoryName}`;
      break;
    default:
      break;
  }

  return (
    <div className="margin-top-5">
      <CatalogCodeMessages isModal />
      {ccAddInProgress && <Spinner size="small" />}
      {ccUpdateInProgress && <Spinner size="small" />}
      <h3>{title}</h3>
    </div>
  );
};

const ModalContent = () => {
  const { selectedCatalogCode, catalogCodesModalMode } = useCatalogCodes();

  if (catalogCodesModalMode === CATALOG_CODES_MODAL_MODES.ADD)
    return <CatalogCodeAdd />;
  if (catalogCodesModalMode === CATALOG_CODES_MODAL_MODES.EDIT)
    return <CatalogCodeEdit />;
  if (catalogCodesModalMode === CATALOG_CODES_MODAL_MODES.CONFIRM_DELETE) {
    const category = selectedCatalogCode?.metadata?.category;
    const title = selectedCatalogCode?.title;
    return (
      <div>
        {`You are about to delete ${category} ${title}.
         Are you sure you want to delete this ${category}?`}
      </div>
    );
  }

  return null;
};

const ModalAction = () => {
  const {
    selectedCatalogCode,
    catalogCodesModalMode,
    setCatalogCodesData,
    deleteCatalogCode,
  } = useCatalogCodes();
  const onCancel = () => {
    setCatalogCodesData('SET_MODAL_MODE', null);
    setCatalogCodesData('SET_SELECTED_CATALOG_CODE', null);
  };

  if (catalogCodesModalMode === CATALOG_CODES_MODAL_MODES.EDIT) {
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="catalog-code-edit-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="primary"
          type="submit"
          form="catalog-code-edit-form"
          data-testid="catalog-code-edit-save-btn"
          label="Save and close"
        />
      </>
    );
  }

  if (catalogCodesModalMode === CATALOG_CODES_MODAL_MODES.CONFIRM_DELETE) {
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="catalog-code-delete-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="secondary"
          data-testid="catalog-code-delete-remove-btn"
          label={`Delete ${selectedCatalogCode?.metadata?.category}`}
          onClick={async () => {
            if (selectedCatalogCode?.id) {
              deleteCatalogCode({
                variables: {
                  id: selectedCatalogCode.id,
                },
              });
            }
          }}
        />
      </>
    );
  }

  if (catalogCodesModalMode === CATALOG_CODES_MODAL_MODES.ADD) {
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="catalog-code-add-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="primary"
          type="submit"
          form="catalog-code-add-form"
          data-testid="catalog-code-add-save-btn"
          label="Save and close"
        />
      </>
    );
  }

  return null;
};

const CatalogCodeWrapper = () => {
  const {
    catalogCodeShowModal,
    setCatalogCodesData,
    clearModalMessages,
  } = useCatalogCodes();
  return (
    <Modal
      title={<ModalTitle />}
      actions={<ModalAction />}
      show={catalogCodeShowModal}
      onClose={() => {
        setCatalogCodesData('SET_MODAL_MODE', null);
        setCatalogCodesData('SET_SELECTED_CATALOG_CODE', null);
        clearModalMessages();
      }}
    >
      <ModalContent />
    </Modal>
  );
};

export default CatalogCodeWrapper;
