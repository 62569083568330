/* eslint-disable react/prop-types */
import React from 'react';
import { AFPTableRowAction } from '@gsa/afp-component-library';
import createStatusBadgeWithVariants from '../../../components/createStatusBadgeWithVariants';
import { emDashUnicode } from '../../../utilities/constants';
import { formatDate } from '../../../utilities/date-helpers';

const statusVariantMap = {
  'Not started': 'Warning-Gray',
  'In progress': 'Warning-Gray',
  Completed: 'Ready-Gray',
  'Ready to submit': 'Info-Gray',
};

const statusContentMap = {
  Completed: 'Submitted',
};

const StatusBadge = createStatusBadgeWithVariants(
  statusVariantMap,
  statusContentMap,
);

export default function getColumns(onActionClick, isBidClosed) {
  const actionList = [
    {
      icon: 'arrow_downward',
      label: 'Download line item',
    },
  ];
  if (!isBidClosed)
    actionList.unshift(
      {
        icon: 'arrow_forward',
        label: 'Bid line details',
      },
      {
        icon: 'edit',
        label: 'Edit line item',
      },
      {
        icon: 'content_copy',
        label: 'Copy line item',
      },
      {
        icon: 'cancel',
        label: 'Remove from bid',
      },
    );

  return [
    {
      Header: 'Line Item',
      id: 'lineItem',
      sortable: true,
      accessor: 'scheduleLine',
      Cell: ({ row }) => {
        const { scheduleLine } = row?.original || {};
        return `${scheduleLine || emDashUnicode}`;
      },
    },

    {
      Header: 'Body Make and Model',
      sortable: false,
      Cell: ({ row }) => {
        const { afpMake, afpModel } = row?.original || {};
        return `${afpMake?.makeName || emDashUnicode} ${
          afpModel?.modelName || emDashUnicode
        }`;
      },
    },
    {
      Header: 'Chassis Make and Model',
      sortable: false,
      Cell: ({ row }) => {
        const { chassisMake, chassisModel } = row?.original || {};
        return `${chassisMake?.makeName || emDashUnicode} ${
          chassisModel?.modelName || emDashUnicode
        }`;
      },
    },
    {
      Header: 'Standard Item',
      sortable: false,
      Cell: ({ row: { original = {} } = {} }) =>
        `${original?.solicitationLine?.standardItem?.standardItemNumber} - ${original?.solicitationLine?.standardItem?.title}`,
    },
    {
      Header: 'Date submitted to GSA for review',
      sortable: false,
      Cell: ({ row }) => {
        const { submittedAt } = row?.original || {};
        return submittedAt
          ? `${formatDate(submittedAt)} ${formatDate(submittedAt, 'hh:mma')}`
          : emDashUnicode;
      },
    },
    {
      Header: 'Submission status',
      sortable: false,
      Cell: ({ row }) => {
        return <StatusBadge value={row?.original.status} />;
      },
    },
    {
      Header: 'Actions',
      sortable: false,
      Cell: ({ row }) => {
        return (
          <AFPTableRowAction
            row={row}
            onSelectAction={(action) => onActionClick(action, row.original)}
            actions={actionList}
          />
        );
      },
    },
  ];
}
