/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { TextInput } from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { useCommentsAdmin } from './comments-admin-provider';
import { getAuthor } from '../../utilities/types/role-types';

const BulkReply = ({ formId }) => {
  const [reply, setReply] = useState('');
  const { selectedComments, bulkReplyReview, setData } = useCommentsAdmin();
  const {
    currentUser: { roles },
  } = useCurrentUser();
  const handleFormSubmit = (isPublish) => {
    const commentIds = selectedComments.map((comment) => {
      return Number(comment.original.id);
    });

    const bulkInput = {
      status: null,
      comment: reply,
      actionType: 'REPLY',
      applyToIds: commentIds,
      authorType: getAuthor(roles),
      isPublish,
    };

    bulkReplyReview({
      variables: {
        bulkInput,
      },
    });
    setData('SET_MODAL_MODE', null);
  };

  return (
    <>
      <div className="grid-col">
        <form
          id={formId}
          onSubmit={(e) => {
            e.preventDefault();
            const butonName = e.nativeEvent?.submitter?.name;
            const isPublish = butonName === 'reply_publish';
            handleFormSubmit(isPublish);
          }}
        >
          {/* eslint-disable-next-line */}
          <span tabIndex="0" aria-label="Replay" className="text-bold">
            Reply
          </span>
          <TextInput
            id="comment-text"
            type="textarea"
            value={reply}
            maxLength="2000"
            data-testid="bulk-reply-text-input"
            onChange={(e) => setReply(e.target.value)}
            aria-label="Reply 2,000 characters allowed"
          />
          {/* eslint-disable-next-line */}
          <span tabIndex="0" aria-label="2,000 characters allowed">
            2,000 characters allowed
          </span>
        </form>
      </div>
    </>
  );
};

export default BulkReply;
