/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, gql } from '@apollo/client';
import { Modal, Button, Alert } from '@gsa/afp-component-library';
import CommentModalEmailForm from './comment-modal-email-form';
import { useFederalStandards } from '../fvs-provider';

const CommentModal = ({
  commentText,
  linkedEntityId,
  linkedEntityType,
  fvsCode,
  contractYear,
  onClose,
}) => {
  const { vehicleStandard } = useFederalStandards();
  const [matchingEmail, setMatchingEmail] = useState('');
  const [isExistingAccount, setIsExistingAccount] = useState(false);
  const [addPublicComment, addPublicCommentResult] = useMutation(
    gql`
      mutation AddStandardsComment(
        $email: String!
        $commentInput: StandardsCommentInput!
      ) {
        addStandardsComment(email: $email, commentInput: $commentInput) {
          id
        }
      }
    `,
  );

  let modalTitle = 'Post a standard item comment';
  if (linkedEntityType === 3) {
    modalTitle = 'Post a documentation comment';
  }

  return (
    <div className="afp-modal-wrapper edit-conflicts-modal">
      <div className="afp-modal-overlay">
        <Modal
          id="copy-line-item-modal"
          variant="large"
          title={<h1 className="title-l">{modalTitle}</h1>}
          onClose={() => {
            onClose(addPublicCommentResult.data);
          }}
          actions={
            <div>
              <Button
                data-testid="comment-modal-close-action"
                variant="unstyled"
                onClick={() => {
                  onClose(addPublicCommentResult.data);
                }}
                label={addPublicCommentResult.data ? 'Close' : 'Cancel'}
              />
              {!addPublicCommentResult.data && !isExistingAccount && (
                <Button
                  className="margin-left-1"
                  data-testid="comment-modal-submit-action"
                  variant="primary"
                  disabled={!matchingEmail || addPublicCommentResult?.loading}
                  onClick={() => {
                    if (matchingEmail) {
                      addPublicComment({
                        variables: {
                          email: matchingEmail,
                          commentInput: {
                            comment: commentText,
                            year: parseInt(contractYear, 10),
                            linkedEntityType,
                            linkedEntityId,
                            fvsCode,
                            fvsId: vehicleStandard.id,
                          },
                        },
                      });
                    }
                  }}
                  label={
                    addPublicCommentResult.loading ? 'Submitting...' : 'Submit'
                  }
                />
              )}
            </div>
          }
        >
          {addPublicCommentResult?.error && (
            <Alert type="error" slim>
              {addPublicCommentResult?.error.message}
            </Alert>
          )}
          {addPublicCommentResult?.data && (
            <Alert type="success" slim>
              We have sent you an email to confirm your comment. Please click
              &quot;Finalize comment&quot; in the email to submit your comment
              for review.
            </Alert>
          )}
          <CommentModalEmailForm
            commentText={commentText}
            linkedEntityId={linkedEntityId}
            linkedEntityType={linkedEntityType}
            fvsCode={fvsCode}
            contractYear={contractYear}
            onEmailChange={(e, ec, isMatching, _isExistingAccount) => {
              if (e && isMatching) {
                setMatchingEmail(e);
              } else {
                setMatchingEmail('');
              }
              setIsExistingAccount(_isExistingAccount);
            }}
          />
        </Modal>
      </div>
    </div>
  );
};

CommentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  commentText: PropTypes.string.isRequired,
};

CommentModal.defaultProps = {};

export default CommentModal;
