import React from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '@gsa/afp-shared-ui-utils';
import ContractSearch from './contract-search/contract-search';
import ContractHeader from './contract-header/contract-header';
import ContractLineListing from './contract-line/contract-line-listing';
import ContractLineTemplate from './line-template/contract-line-template';
import ContractLineHistory from './line-history/index';
import ContractHeaderHistory from './contract-header-history';
import ContractHeaderHistoryVersion from './contract-header-history-version/contract-header-history-version';
import ContractProvider from './contract-provider';

const Contract = () => {
  return (
    <ContractProvider>
      <Switch>
        <PrivateRoute
          exact
          path="/catalog/contract/"
          title="Contracts"
          component={ContractSearch}
        />

        <PrivateRoute
          exact
          path="/catalog/contract/contract-header/:contractHeaderId"
          title="Contract Header"
          component={ContractHeader}
        />

        <PrivateRoute
          exact
          path="/catalog/contract/contract-header/:contractHeaderId/history"
          title="Contract History"
          component={ContractHeaderHistory}
        />

        <PrivateRoute
          exact
          path="/catalog/contract/contract-header/:contractHeaderId/history/:versionNumber"
          title="Contract Header History Version"
          component={ContractHeaderHistoryVersion}
        />

        <PrivateRoute
          exact
          path="/catalog/contract/contract-header/:contractHeaderId/lines"
          title="Contract Line Listing"
          component={ContractLineListing}
        />

        <PrivateRoute
          exact
          path="/catalog/contract/:contractHeaderId/contract-line-template/:contractLineId"
          title="Contract Line Template"
          component={ContractLineTemplate}
        />

        <PrivateRoute
          exact
          path="/catalog/contract/:contractHeaderId/contract-line-template/:contractLineId/history"
          title="Contract Line History"
          component={ContractLineHistory}
        />

        <PrivateRoute
          exact
          path="/catalog/contract/:contractHeaderId/contract-line-template/:contractLineId/history/:versionNumber"
          title="Contract Line History Version"
          component={ContractLineTemplate}
        />
      </Switch>
    </ContractProvider>
  );
};

export default Contract;
