import React from 'react';
import { useStandards } from './standards-provider';
import StandardItemForm from '../../components/forms/standard-item-form';
import StandardItemFormContext from '../../components/forms/standard-item-form-context';

const StandardItemEdit = () => {
  const {
    selectedStandardItem,
    multipleOptions,
    updateStandardItem,
  } = useStandards();
  const handleSave = async (data) => {
    const tags = { value: [] };
    if (data?.tags) {
      tags.value = data.tags;
    }
    const standardItemInput = {
      ...data,
      tags,
      contentId: selectedStandardItem?.content?.id,
      year: parseInt(data?.year, 10),
    };
    await updateStandardItem({
      variables: {
        id: selectedStandardItem.id,
        standarditem: standardItemInput,
      },
    });
  };
  return (
    <>
      <StandardItemFormContext standardItem={selectedStandardItem}>
        <StandardItemForm
          formId="standard-item-edit-form"
          standardItemTags={multipleOptions?.tags}
          fedStandardCodes={multipleOptions?.fedStandardCode}
          vehicleGroups={multipleOptions?.vehicleGroup}
          vehicleTypes={multipleOptions?.vehicleType}
          years={multipleOptions?.futureYears}
          handleSave={handleSave}
          fastCodes={multipleOptions?.fastCodes}
          isAddForm={false}
        />
      </StandardItemFormContext>
    </>
  );
};

export default StandardItemEdit;
