import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  RequiredFieldIndicator,
  SelectDropdown,
  TextInput,
} from '@gsa/afp-component-library';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import OverlaySpinner from '../../../../../components/overlay-spinner';
import { INPUT_DROPDOWN_DEFAULT_OPTION } from '../../../../bid-dashboard/bid-line-details/provider/helpers';
import { useContractLine } from '../../provider/contract-line-provider';
import { isLineDetailTabFieldrequired } from '../fields-requirement';
import ToastMessage from '../../../../../components/Toast/toast';
import { ReadOnlyWrapper } from '../../helpers/ec-components';

const AddAssociatedLocationForm = () => {
  const {
    contractLine,
    detailTabData,
    setDetailTabData,
    vendorLocations,
    vendorLocQueryResult,
    isAwardedInFleet,
  } = useContractLine();

  const [toast, setToast] = useState(null);

  const isVendorLocationsLoading = vendorLocQueryResult?.loading;

  const assemblyPointOptions = [INPUT_DROPDOWN_DEFAULT_OPTION];
  const inspectionPointOptions = [INPUT_DROPDOWN_DEFAULT_OPTION];

  const groupedLocations = vendorLocations?.reduce((groups, location) => {
    const { code } = location.commonCode;
    return { ...groups, [code]: [...(groups[code] || []), location] };
  }, {});

  const getPointOptions = (points) => {
    const options = [];

    points?.forEach((point) => {
      options.push({
        label: `${point?.country?.countryName} (${point.country?.isoCountryCode2}) ${point?.address1} ${point?.city} ${point?.state?.stateCode}, ${point?.postalCode}`,
        value: point.id,
      });
    });

    return options;
  };

  assemblyPointOptions.push(...getPointOptions(groupedLocations?.AsmPoint));
  inspectionPointOptions.push(...getPointOptions(groupedLocations?.InspPoint));

  const schema = yup.object().shape({
    assemblyPoint: yup.string().required('Final assembly point is required.'),
    inspectionPoint: yup.string().required('Inspection point is required.'),
    percentageOfDomContent: yup.mixed().when('$percentageRequired', {
      is: false,
      then: yup.string(),
      otherwise: yup
        .number()
        .transform((value) =>
          value === '' || Number.isNaN(value) ? undefined : value,
        )
        .required('Percentage of domestic content is required.')
        .min(0, 'Percentage must be between 0 and 100.')
        .max(100, 'Percentage must be between 0 and 100.'),
    }),
  });

  const { errors, control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      assemblyPoint: '',
      inspectionPoint: '',
      percentageOfDomContent: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const genLabel = (label, required) => (
    <div aria-label={label} className="text-bold" data-testid={label}>
      {label} {required && <RequiredFieldIndicator />}
    </div>
  );

  // Maintain a local state for associated locations
  const [associatedLocations, setAssociatedLocations] = useState([]);

  // Synchronize the associated locations from detailTabData and contractLine
  useEffect(() => {
    setAssociatedLocations(
      detailTabData?.associatedLocations ||
        contractLine?.associatedLocations ||
        [],
    );
  }, [detailTabData, contractLine]);

  const onSubmit = useCallback(
    (formData) => {
      const isDuplicate = associatedLocations?.some(
        (location) =>
          location.vendorAssemblyPointId === +formData.assemblyPoint &&
          location.vendorInspectionPointId === +formData.inspectionPoint,
      );
      if (isDuplicate) {
        setToast({
          type: 'error',
          message:
            'Duplicate entry selection. If you want to update existing entry, please delete it first.',
        });
        return;
      }
      setToast(null);

      const updates = {
        vendorAssemblyPointId: +formData.assemblyPoint,
        vendorInspectionPointId: +formData.inspectionPoint,
        percentageOfDomContent: +formData.percentageOfDomContent,
      };

      setAssociatedLocations((prevLocations) => {
        const newLocs = [...prevLocations, updates];
        setDetailTabData((prevData) => ({
          ...prevData,
          associatedLocations: newLocs,
        }));
        return newLocs;
      });
      reset();
    },
    [associatedLocations, setDetailTabData, reset],
  );

  const InputElementWrapper = ({ help, label, children }) => {
    return (
      <>
        <div>
          {genLabel(
            label,
            isLineDetailTabFieldrequired(
              isAwardedInFleet,
              'associatedLocations',
            ),
          )}
        </div>
        {help && <span>{help}</span>}
        <ReadOnlyWrapper>{children}</ReadOnlyWrapper>
      </>
    );
  };

  InputElementWrapper.propTypes = {
    help: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };

  return (
    <div className="bg-primary-lightest padding-bottom-6 padding-top-4">
      {isVendorLocationsLoading && <OverlaySpinner />}

      <form
        id="add-vendor-associated-loc-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="display-flex flex-row flex-justify flex-align-end margin-x-4">
          <div
            className={`grid-col-2 ${
              errors.assemblyPoint ? 'padding-left-2' : ''
            } width-mobile`}
          >
            <Controller
              name="assemblyPoint"
              control={control}
              render={({ name, value, onChange, onBlur }) => (
                <InputElementWrapper
                  help="Select address from your vendor company profile"
                  label="Final assembly point"
                >
                  <SelectDropdown
                    name={name}
                    id="assembly-point"
                    data-testid="assembly_point"
                    help="Test"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={errors.assemblyPoint?.message}
                    options={assemblyPointOptions}
                    required={isLineDetailTabFieldrequired(
                      isAwardedInFleet,
                      'associatedLocations',
                    )}
                  />
                </InputElementWrapper>
              )}
            />
          </div>
          <div
            className={`grid-col-2 ${
              errors.inspectionPoint ? 'padding-left-2' : ''
            } width-mobile`}
          >
            <Controller
              name="inspectionPoint"
              control={control}
              render={({ name, value, onChange, onBlur }) => (
                <InputElementWrapper
                  help="Select address from your vendor company profile"
                  label="Inspection point"
                >
                  <SelectDropdown
                    name={name}
                    id="inspection-point"
                    data-testid="inspection_point"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={errors.inspectionPoint?.message}
                    options={inspectionPointOptions}
                  />
                </InputElementWrapper>
              )}
            />
          </div>
          <div
            className={`grid-col-2 ${
              errors.percentageOfDomContent ? 'padding-left-2' : ''
            } width-mobile`}
          >
            <Controller
              name="percentageOfDomContent"
              control={control}
              render={({ name, value, onChange, onBlur }) => (
                <InputElementWrapper
                  help="Enter the percentage of domestic content for this location"
                  label="Percentage of domestic content"
                >
                  <TextInput
                    name={name}
                    data-testid="percentage_of_dom_content"
                    type="number"
                    suffix="%"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={errors.percentageOfDomContent?.message}
                  />
                </InputElementWrapper>
              )}
            />
          </div>
          <div className="text-right">
            <Button
              type="submit"
              variant="outline"
              label="Add"
              leftIcon={{ name: 'add' }}
              className="margin-left-2 margin-right-0"
            />
          </div>
        </div>
        {toast && (
          <ToastMessage
            type={toast.type}
            message={toast.message}
            onClose={() => setToast(null)}
            closable
            className="margin-4"
          />
        )}
      </form>
    </div>
  );
};

export default AddAssociatedLocationForm;
