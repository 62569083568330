import React from 'react';
import PropTypes from 'prop-types';
import { AFPTable, Pagination, EmptyState } from '@gsa/afp-component-library';

const TableWrapper = ({ tableProps, paginationProps, emptyStateText }) => (
  <>
    <AFPTable {...tableProps} key={tableProps.columns.length} />
    {paginationProps.itemsCount > 0 && <Pagination {...paginationProps} />}
    {!tableProps.data?.length && emptyStateText && (
      <div className="text-center margin-top-neg-2 height-full">
        <EmptyState
          hasBackground
          containerStyles="padding-top-9 height-full"
          bottomText={
            <p aria-label={emptyStateText}>
              <strong>{emptyStateText}</strong>
            </p>
          }
        />
      </div>
    )}
  </>
);

TableWrapper.propTypes = {
  tableProps: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  paginationProps: PropTypes.shape({
    itemsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    onPageChange: PropTypes.func,
    variant: PropTypes.string,
    itemsPerPage: PropTypes.number,
    currentPage: PropTypes.number,
    itemsCount: PropTypes.number,
    isReset: PropTypes.bool,
  }).isRequired,
  emptyStateText: PropTypes.string,
};

TableWrapper.defaultProps = {
  emptyStateText: '',
};

export default TableWrapper;
