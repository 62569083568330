import React, { createContext, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useRecoilState } from 'recoil';
import { bidsDashboardMessageAtom } from '../bids/atoms/bids-atoms';
import {
  GET_SOLICITATION_BY_ID_FOR_BID,
  GET_BID,
} from '../solicitation/queries';
import {
  GET_BID_STANDARD_ITEMS,
  IS_UNSEEN_BID_CHANGE_EXIST,
} from '../bids/bids.gql';

export const BidContext = createContext({});

export const BidProvider = ({ children }) => {
  const { solId, bidId } = useParams();
  const [
    getBid,
    { data: bidData, called, loading, error, refetch },
  ] = useLazyQuery(GET_BID);
  useEffect(() => {
    if (bidId) {
      getBid({
        variables: { bidId: parseInt(bidId, 10) },
      });
    }
  }, [bidId]);
  const bid = bidData?.getBidById;

  const { data: isUnseenBidChangeData } = useQuery(IS_UNSEEN_BID_CHANGE_EXIST, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { bidId: parseInt(bidId, 10) },
    skip: !bidId,
  });

  const [toaster, setToaster] = useRecoilState(bidsDashboardMessageAtom);

  const {
    called: soliCalled,
    loading: soliLoading,
    data: soliData,
    error: soliError,
  } = useQuery(GET_SOLICITATION_BY_ID_FOR_BID, {
    fetchPolicy: 'cache-and-network',
    variables: { id: parseInt(solId, 10) },
    skip: !solId,
  });

  const {
    called: bidSICalled,
    loading: bidSILoading,
    data: bidSIData,
    error: bidSIError,
    refetch: refetchSI,
  } = useQuery(GET_BID_STANDARD_ITEMS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { bidId },
    skip: !bidId,
  });

  return (
    <BidContext.Provider
      value={{
        solId,
        bidId,
        bid,
        called,
        loading,
        error,
        refetch,

        toaster,
        isUnseenBidElementsChangesExist:
          isUnseenBidChangeData?.isUnseenBidElementsChangesExist,
        setToaster,

        soliCalled,
        soliLoading,
        soliData,
        soliError,

        bidSICalled,
        bidSILoading,
        bidSIData,
        bidSIError,
        refetchSI,
      }}
    >
      {children}
    </BidContext.Provider>
  );
};

BidProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useBid = () => useContext(BidContext);
