import React, { createContext, useContext, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import {
  GET_ANNOUNCEMENT_BY_BOARD,
  UPDATE_ANNOUNCEMENT_BY_BOARD,
} from '../../../services/data-store';
import {
  CREATE_SPR_TASK,
  TOGGLE_TASK_STATUS,
} from '../../../services/fragments/tasks';
import { CREATE_COMMENTING_PERIOD, GET_STANDARDS_REL_TASKS } from '../fvs.gql';
import { useFederalStandards } from '../fvs-provider';

export const FvsLandingPageContext = createContext({});

const initialState = {
  modalMode: null,
  showFormModal: false,
  relatedTasks: {
    rows: [],
    hasMore: false,
    count: 0,
  },
};

export const FvsLandingPageReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case 'SET_MODAL_MODE': {
      if (action.payload) {
        return { ...state, modalMode: action.payload, showFormModal: true };
      }
      return { ...state, modalMode: action.payload, showFormModal: false };
    }
    case 'SET_RELATED_TASKS': {
      return { ...state, relatedTasks: action.payload };
    }

    // Announcement Billboard Copy
    case 'SET_HEADER_COPY': {
      return { ...state, headerCopy: action.payload };
    }
    case 'SET_INFO_COPY': {
      return { ...state, infoCopy: action.payload };
    }
    default: {
      return state;
    }
  }
};

function FvsLandingPageProvider({ children, ...props }) {
  const history = useHistory();

  const [state, dispatch] = useReducer(
    FvsLandingPageReducer,
    initialState,
    () => {
      return initialState;
    },
  );

  const {
    addOrReplaceFvsMessageById,
    vehicleStandard,
    selectedYear,
    getVehicleStandards,
    getOpenCommentingPeriodCount,
  } = useFederalStandards();
  const { currentUser } = useCurrentUser();

  const setFvsLandingPageData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const [
    getAnnouncementForFvsHeader,
    { variables: headerVariable },
  ] = useLazyQuery(GET_ANNOUNCEMENT_BY_BOARD, {
    fetchPolicy: 'network-only',
    onError: (requestError) => {
      // eslint-disable-next-line no-console
      console.error(requestError);
    },
    onCompleted: (res) => {
      if (res?.getAnnouncementByBoard) {
        if (headerVariable.boardType === 1) {
          setFvsLandingPageData(
            'SET_HEADER_COPY',
            res?.getAnnouncementByBoard?.content,
          );
        }
      }
    },
  });

  const [getAnnouncementForFvsInfo, { variables: infoVariable }] = useLazyQuery(
    GET_ANNOUNCEMENT_BY_BOARD,
    {
      fetchPolicy: 'network-only',
      onError: (requestError) => {
        // eslint-disable-next-line no-console
        console.error(requestError);
      },
      onCompleted: (res) => {
        if (res?.getAnnouncementByBoard) {
          if (infoVariable.boardType === 2) {
            setFvsLandingPageData(
              'SET_INFO_COPY',
              res?.getAnnouncementByBoard?.content,
            );
          }
        }
      },
    },
  );

  const [updateAnnouncementByBoard] = useMutation(
    UPDATE_ANNOUNCEMENT_BY_BOARD,
    {
      onError: (requestError) => {
        // eslint-disable-next-line no-console
        console.error(requestError);
      },
      onCompleted: (res) => {
        if (res?.updateAnnouncementByBoard) {
          const boardType = res?.updateAnnouncementByBoard?.boardType;
          if (boardType === 'FVS_HEADER') {
            setFvsLandingPageData(
              'SET_HEADER_COPY',
              res?.updateAnnouncementByBoard?.content,
            );
          }
          if (boardType === 'FVS_INFO') {
            setFvsLandingPageData(
              'SET_INFO_COPY',
              res?.updateAnnouncementByBoard?.content,
            );
          }
        }
      },
    },
  );

  const [createSprTask] = useMutation(CREATE_SPR_TASK, {
    onError: (requestError) => {
      // eslint-disable-next-line no-console
      console.warn(requestError);
    },
    onCompleted: (responseData) => {
      if (responseData?.createStandardsPeerReviewTask) {
        const { taskId } = responseData?.createStandardsPeerReviewTask;
        history.push(`/catalog/tasks/${taskId}`);
      }
    },
  });

  const handleCreateSprTask = async () => {
    const taskInput = {
      title: `Peer Review: Fed Std ${vehicleStandard?.fedStandardCode} - ${vehicleStandard?.fedStandard?.title}`,
      description: '',
      fvsId: Number.parseInt(vehicleStandard.id, 10),
      dueDate: null,
      assignor: currentUser.id,
      documentAuthor: currentUser.id,
      documentLink: '',
      fedVehicleStandardsLink: '/catalog/vehicle-standards',
      documentStatus: `${vehicleStandard.status} - ${selectedYear}`,
      fedStandardDoc: vehicleStandard?.fedStandard?.code,
      programName: vehicleStandard?.fedStandard?.title,
      classType: null,
    };

    await createSprTask({
      variables: {
        input: taskInput,
      },
    });
  };

  const [
    getStandardsRelatedTasks,
    { refetch: refreshRelatedtasks },
  ] = useLazyQuery(GET_STANDARDS_REL_TASKS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (requestError) => {
      // eslint-disable-next-line no-console
      console.error(requestError);
    },
    onCompleted: (responseData) => {
      if (responseData?.getStandardsRelatedTasks) {
        setFvsLandingPageData(
          'SET_RELATED_TASKS',
          responseData.getStandardsRelatedTasks,
        );
      }
    },
  });

  const [toggleTaskStatus] = useMutation(TOGGLE_TASK_STATUS, {
    onError: (requestError) => {
      // setError('GET_RELATED_TASKS', requestError.message ?? 'Unknown Error.');
      // eslint-disable-next-line no-console
      console.error(requestError);
    },
    onCompleted: () => {
      refreshRelatedtasks();
    },
  });

  const [saveCommentingPeriod] = useMutation(CREATE_COMMENTING_PERIOD, {
    onError: (requestError) => {
      const message = {
        id: 'CREATE_COMMENTING_PERIOD',
        message: requestError.message ?? 'Unknown Error.',
        type: 'error',
      };
      addOrReplaceFvsMessageById(message);
    },
    onCompleted: (periodResponse) => {
      if (periodResponse) {
        const message = {
          id: 'CREATE_COMMENTING_PERIOD',
          message: periodResponse?.addOrUpdateCommentingPeriods,
          type: 'success',
        };
        addOrReplaceFvsMessageById(message);

        setFvsLandingPageData('SET_MODAL_MODE', null);
        getVehicleStandards({
          variables: {
            filters: {
              operator: 'AND',
              value: [
                {
                  operator: 'EQ',
                  key: 'year',
                  value: selectedYear,
                },
                {
                  operator: 'EQ',
                  key: 'fedStandardCode',
                  value: vehicleStandard.fedStandardCode,
                },
              ],
            },
            limit: 100,
            offset: 0,
            order: 'fedStandardCode ASC',
          },
        });
        getOpenCommentingPeriodCount();
      }
    },
  });

  return (
    <FvsLandingPageContext.Provider
      value={{
        ...state,
        setFvsLandingPageData,
        getAnnouncementForFvsHeader,
        getAnnouncementForFvsInfo,
        updateAnnouncementByBoard,
        handleCreateSprTask,
        getStandardsRelatedTasks,
        saveCommentingPeriod,
        toggleTaskStatus,
        ...props,
      }}
    >
      {children}
    </FvsLandingPageContext.Provider>
  );
}

export default FvsLandingPageProvider;

FvsLandingPageProvider.defaultProps = {};

FvsLandingPageProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useFvsLandingPageContext = () => useContext(FvsLandingPageContext);
