import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { Icon } from '@gsa/afp-component-library';
import { CommentPropType } from '../../../utilities/types';
import { ROLES_ENGINEER } from '../../../utilities/types/role-types';
import DeleteCommentItem from './delete-comment-item';

const Comment = ({ comment, deleteStandardsComment, role }) => {
  const { currentUser } = useCurrentUser();
  const fullName = comment?.createdByUserInfo?.fullName;
  const isOwnComment = comment?.createdByUser === currentUser?.id;
  const authorName =
    isOwnComment || (role === ROLES_ENGINEER && fullName)
      ? fullName
      : 'Public user';

  const author =
    !isOwnComment && comment?.authorType === ROLES_ENGINEER
      ? 'GSA Engineer'
      : authorName;

  return (
    <>
      <div className="grid-row">
        <div className="grid-col-10">
          <div className="display-flex flex-justify">
            <div className="display-flex flex-align-center">
              <Icon
                iconName="account_circle"
                className="usa-icon--size-3 margin-right-1"
              />
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
              <span className="text-bold" tabIndex="0">
                {author}
              </span>
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
              <span className="margin-x-4" tabIndex="0">
                {moment(comment?.createdAt).format('l')} &bull;{' '}
                {moment(comment?.createdAt).format('h:mm a')}
              </span>
            </div>
            <DeleteCommentItem
              comment={comment}
              allowChanges={isOwnComment}
              currentUsrId={currentUser.id}
              deleteStandardsComment={deleteStandardsComment}
              deletingComment={false}
              submissionStatus={{
                action: 'delete',
                status: 'success',
                message: '',
              }}
            />
          </div>
        </div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <p data-testid="comment-text" tabIndex="0">
        {comment?.comment}
      </p>
    </>
  );
};

Comment.propTypes = {
  role: PropTypes.string.isRequired,
  comment: PropTypes.shape(CommentPropType).isRequired,
  deleteStandardsComment: PropTypes.func.isRequired,
};

export default Comment;
