/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useCommentsAdmin } from './comments-admin-provider';
import { emDashUnicode } from '../../utilities/constants';

const CommentMessage = ({
  newComments,
  invalidComments,
  publishableComments,
  selectedComments,
}) => {
  let message = `${publishableComments?.length} of the selected ${selectedComments?.length} comments will be published. `;

  if (newComments?.length || invalidComments?.length) {
    message += `The following ${
      newComments?.length + invalidComments?.length
    } comments cannot be published at this time for the reasons indicated:`;
  }
  return (
    <>
      {/* eslint-disable-next-line */}
      <div aria-label={message} tabIndex="0">
        {message}
      </div>
      {newComments?.map((comment) => (
        <ul>
          {/* eslint-disable-next-line */}
          <li tabIndex={0}>
            <b>
              {comment.original.createdByUserInfo?.fullName || emDashUnicode},{' '}
              {comment.original.authorType},
            </b>{' '}
            {moment(comment.original.createdAt).format('MM-DD-YYYY')} : Cannot
            be in &quot;new&quot; state
          </li>
        </ul>
      ))}
      {invalidComments?.map((comment) => (
        <ul>
          {/* eslint-disable-next-line */}
          <li key={comment.original.id} tabIndex={0}>
            <b>
              {comment.original.createdByUserInfo?.fullName || emDashUnicode},{' '}
              {comment.original.authorType},
            </b>{' '}
            {moment(comment.original.createdAt).format('MM-DD-YYYY')} : Cannot
            be in &quot;invalid&quot; state
          </li>
        </ul>
      ))}
    </>
  );
};

const BulkPublish = ({ formId }) => {
  const { selectedComments, setData, bulkPublishComments } = useCommentsAdmin();
  const [filteredComments, setFilteredComments] = useState({});
  useEffect(() => {
    // here we need to filter comments based on status
    const newComments = selectedComments.filter(
      (comment) => comment.original.status === 'New',
    );
    const invalidComments = selectedComments.filter(
      (comment) => comment.original.status === 'Invalid',
    );
    const publishableComments = selectedComments.filter(
      (comment) =>
        comment.original.status !== 'New' &&
        comment.original.status !== 'Invalid',
    );
    setFilteredComments({
      newComments,
      invalidComments,
      publishableComments,
      selectedComments,
    });
  }, [selectedComments]);

  const handleFormSubmit = () => {
    const commentIds = selectedComments.map((comment) => {
      return Number(comment.original.id);
    });
    const bulkInput = {
      applyToIds: commentIds,
    };
    bulkPublishComments({
      variables: {
        bulkInput,
      },
    });
    setData('SET_MODAL_MODE', null);
  };

  return (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault();
        handleFormSubmit();
      }}
    >
      <CommentMessage {...filteredComments} />
    </form>
  );
};

export default BulkPublish;
