import React from 'react';
import { isEmpty } from 'lodash';
import {
  hasSpace,
  isNumeric,
  isUpperCase,
  isNotLetter,
  hasFiveMaxCharacters,
  hasSpecialCharacter,
  hasThreeMaxCharacters,
  hasFourMaxCharacters,
  hasThirtyMaxCharacters,
  hasTwentyMaxCharacters,
  titleNoSpecialCharacter,
  codeNoSpecialCharacter,
  hasAtLeastThreeCharacter,
  hasOneTwentyMaxCharacters,
  hasAtLeastOneCharacter,
  isNumericAndMax5digits,
  hasSpecialCharacterinSequence,
  isSequenceNumeric,
} from './validation-rules';

const constants = {
  // Catalog Code Form Fields
  PROGRAM: 'Program',
  VEHICLE_TYPE: 'Vehicle Type',
  VEHICLE_GROUP: 'Vehicle Group',
  EQUIPMENT_CATEGORY: 'Equipment Category',
  FEDERAL_STD_CODE: 'Federal Standard Codes',
  STANDARD_ITEM_TAGS: 'Standard Item Tags',
  EQUIPMENT_CODE_TAGS: 'Equipment Code Tags',
  VEHICLE_TYPE_TAGS: 'Vehicle Type Tags',
  FUEL_TYPE_TAGS: 'Fuel Type Tags',
  FUEL_TYPE: 'Fuel Type',
  UNIT: 'Units',
  COLOR: 'Colors',
  TITLE: 'Title',
  FASTCODE: 'FAST Codes',

  // Standard Item Form Fields
  STANDARD_ITEM_NUMBER: 'StandardItemNumber',

  // Equipment Code Form Fields
  EQUIPMENT_CODE: 'EquipmentCode',

  // Sequence
  SEQUENCE: 'Sequence',
};

const getRuleDefinitions = (category, value) => {
  switch (category) {
    case constants.PROGRAM: {
      const isValidThreeUpperCase =
        hasThreeMaxCharacters(value) && isUpperCase(value);

      return [
        {
          id: 1,
          condition: isValidThreeUpperCase,
          message: '3 uppercase letters',
        },
        {
          id: 2,
          condition: isNotLetter(value),
          message: 'No numeric characters',
        },
        {
          id: 3,
          condition: !hasSpecialCharacter(value),
          message: 'No special characters',
        },
        { id: 4, condition: !hasSpace(value), message: 'No space' },
      ];
    }
    case constants.FEDERAL_STD_CODE: {
      return [
        {
          id: 1,
          condition: !hasSpecialCharacter(value),
          message: 'No special characters',
        },
        {
          id: 3,
          condition: hasFiveMaxCharacters(value),
          message: 'No more than 5 characters',
        },
      ];
    }

    case constants.VEHICLE_TYPE:
    case constants.VEHICLE_GROUP: {
      return [
        {
          id: 1,
          condition: !hasSpecialCharacter(value),
          message: 'No special characters',
        },
        {
          id: 2,
          condition: isNumeric(value),
          message: 'No non-numeric characters',
        },
        {
          id: 3,
          condition: hasFiveMaxCharacters(value),
          message: 'No more than 5 characters',
        },
      ];
    }
    case constants.TITLE: {
      return [
        {
          id: 1,
          condition: hasAtLeastThreeCharacter(value),
          message: 'Minimum 3 characters',
        },
        {
          id: 2,
          condition: hasOneTwentyMaxCharacters(value),
          message: 'No more than 120 characters',
        },
        {
          id: 3,
          condition: !titleNoSpecialCharacter(value),
          message: 'No special characters except ( ) # . , -  _ and SPACE',
        },
      ];
    }
    case constants.FASTCODE: {
      const isValidThirtyChars = hasThirtyMaxCharacters(value);

      return [
        {
          id: 1,
          condition: isValidThirtyChars,
          message: 'Max 30 characters',
        },
        {
          id: 2,
          condition: !codeNoSpecialCharacter(value),
          message: 'No special characters except ( ) and -',
        },
      ];
    }
    case constants.UNIT:
    case constants.COLOR:
    case constants.FUEL_TYPE:
    case constants.VEHICLE_TYPE_TAGS:
    case constants.STANDARD_ITEM_TAGS:
    case constants.EQUIPMENT_CODE_TAGS:
    case constants.FUEL_TYPE_TAGS:
    case constants.EQUIPMENT_CATEGORY: {
      return [
        {
          id: 1,
          condition: !hasSpecialCharacter(value),
          message: 'No special characters',
        },
        {
          id: 2,
          condition: hasAtLeastOneCharacter(value),
          message: 'Minimum 1 character',
        },
        {
          id: 3,
          condition: hasTwentyMaxCharacters(value),
          message: 'No more than 20 characters',
        },
        { id: 4, condition: !hasSpace(value), message: 'No space' },
      ];
    }

    case constants.EQUIPMENT_CODE:
    case constants.STANDARD_ITEM_NUMBER: {
      return [
        {
          id: 1,
          condition: !hasSpecialCharacter(value),
          message: 'No special characters',
        },
        {
          id: 2,
          condition: hasAtLeastOneCharacter(value),
          message: 'Minimum 1 character',
        },
        {
          id: 3,
          condition: hasFourMaxCharacters(value),
          message: 'No more than 4 characters',
        },
        { id: 4, condition: !hasSpace(value), message: 'No space' },
      ];
    }
    case constants.SEQUENCE: {
      return [
        {
          id: 1,
          condition: !hasSpecialCharacterinSequence(value),
          message: 'No special characters',
        },
        {
          id: 2,
          condition: isSequenceNumeric(value?.toString()),
          message: 'Sequence should be a numerical value.',
        },
        {
          id: 3,
          condition: isNumericAndMax5digits(value?.toString()),
          message:
            'Sequence should be atleast one digit and not be more than five digits.',
        },
      ];
    }
    default:
      return [];
  }
};

export const getRules = (value, category) => {
  return getRuleDefinitions(category, value);
};

export const validate = (rules) =>
  isEmpty(rules.filter(({ condition }) => !condition));

export const getValidator = (value, category) => {
  const rules = getRules(value, category);
  return validate(rules);
};

export const renderCodeError = (hasCodeError, message) => {
  // tabIndex
  return hasCodeError ? (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <span aria-label={`${message}`} role="alert" tabIndex="0">
      {message}
    </span>
  ) : null;
};

export const renderTitleError = (hasTittleError) => {
  return hasTittleError ? (
    <span
      role="alert"
      aria-label="Make sure the title meets the requirements listed below."
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex="0"
    >
      Make sure the title meets the requirements listed below.
    </span>
  ) : null;
};

export const renderSequenceError = (hasSequenceError, message) => {
  // tabIndex
  return hasSequenceError ? (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <span aria-label={`${message}`} role="alert" tabIndex="0">
      {message}
    </span>
  ) : null;
};
