/* eslint-disable react/prop-types */
import React, { createContext, useContext, useState, useCallback } from 'react';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import PropTypes from 'prop-types';
import {
  makesFetchOptionsPromise,
  modelsFetchOptionsPromise,
} from '../../../fvs/requirements/components/make-model/api-helpers';
import { CHASSIS_TAG_REGEX } from '../../constants';
import { getApprovalStatus } from '../../helpers';

export const BidMakeAndModelContext = createContext({});

function BidsMakeAndModelProvider({ children, ...props }) {
  const { initialData: bidLineEditData } = props;

  const { getToken } = useCurrentUser();

  const fetchMakes = useCallback(
    async (input, signal) => {
      const token = await getToken();
      return makesFetchOptionsPromise(input, token, signal);
    },
    [getToken],
  );

  const fetchModels = useCallback(
    async (input, makeCode, signal) => {
      const token = await getToken();
      return modelsFetchOptionsPromise(input, makeCode, token, signal);
    },
    [getToken],
  );

  const hasChassis = bidLineEditData?.standardItem?.tags?.value.some((tag) =>
    tag.match(CHASSIS_TAG_REGEX),
  );

  const [status, setStatus] = useState(getApprovalStatus(bidLineEditData));

  const [modelYear, setModelYear] = useState(bidLineEditData?.modelYear);
  const [makeModelApprovalStatus, setMakeModelApprovalStatus] = useState();

  const bodyMakeInitialState = bidLineEditData?.afpMake
    ? {
        label: bidLineEditData?.afpMake?.makeName,
        value: bidLineEditData?.afpMake?.makeCode,
      }
    : undefined;

  const [bodyMake, setBodyMake] = useState(bodyMakeInitialState);
  const [bodyMakeInputValue, setBodyMakeInputValue] = useState(
    bodyMakeInitialState?.label || '',
  );
  const [bodyMakeOptions, setBodyMakeOptions] = useState();

  const bodyModelInitialState = bidLineEditData?.afpModel
    ? {
        label: bidLineEditData?.afpModel?.modelName,
        value: bidLineEditData?.afpModel?.modelCode,
      }
    : undefined;

  const [bodyModel, setBodyModel] = useState(bodyModelInitialState);
  const [bodyModelInputValue, setBodyModelInputValue] = useState(
    bodyModelInitialState?.label || '',
  );
  const [bodyModelOptions, setBodyModelOptions] = useState();

  const chassisMakeInitialState = bidLineEditData?.chassisMake
    ? {
        label: bidLineEditData?.chassisMake?.makeName,
        value: bidLineEditData?.chassisMake?.makeCode,
      }
    : undefined;

  const [chassisMake, setChassisMake] = useState(chassisMakeInitialState);
  const [chassisMakeInputValue, setChassisMakeInputValue] = useState(
    chassisMakeInitialState?.label || '',
  );
  const [chassisMakeOptions, setChassisMakeOptions] = useState();

  const chassisModelInitialState = bidLineEditData?.chassisModel
    ? {
        label: bidLineEditData?.chassisModel?.modelName,
        value: bidLineEditData?.chassisModel?.modelCode,
      }
    : undefined;

  const [chassisModel, setChassisModel] = useState(chassisModelInitialState);
  const [chassisModelInputValue, setChassisModelInputValue] = useState(
    chassisModelInitialState?.label || '',
  );
  const [chassisModelOptions, setChassisModelOptions] = useState();

  const [createError] = useState();

  const [dirtyFields, setDirtyFields] = useState([]);
  const getErrors = useCallback(() => {
    const requiredMsg = 'This is a required field';
    const errors = [];

    if (!bodyMake) {
      if (!bodyMakeInputValue) {
        errors.push({
          field: 'bodyMake',
          name: 'Body make',
          message: requiredMsg,
        });
      }
    }

    if (!bodyModel) {
      if (!bodyModelInputValue) {
        errors.push({
          field: 'bodyModel',
          name: 'Body model',
          message: requiredMsg,
        });
      }
    }

    if (hasChassis) {
      if (!chassisMake) {
        if (!chassisMakeInputValue) {
          errors.push({
            field: 'chassisMake',
            name: 'Chassis make',
            message: requiredMsg,
          });
        }
      }

      if (!chassisModel) {
        if (!chassisModelInputValue) {
          errors.push({
            field: 'chassisModel',
            name: 'Chassis model',
            message: requiredMsg,
          });
        }
      }
    }

    if (!modelYear) {
      errors.push({
        field: 'modelYear',
        name: 'Model year',
        message: requiredMsg,
      });
    }

    return errors;
  }, [
    bodyMake,
    bodyMakeInputValue,
    bodyModel,
    bodyModelInputValue,
    chassisMake,
    chassisMakeInputValue,
    chassisModel,
    chassisModelInputValue,
    modelYear,
    hasChassis,
  ]);

  const reset = () => {
    setBodyMakeInputValue('');
    setBodyModelInputValue('');
    setChassisMakeInputValue('');
    setChassisModelInputValue('');
    setModelYear('');
    setDirtyFields([]);
    setStatus(null);
  };

  return (
    <BidMakeAndModelContext.Provider
      value={{
        hasChassis,
        status,
        // setHasChassis,

        bodyMake,
        setBodyMake,
        bodyMakeInputValue,
        setBodyMakeInputValue,
        bodyMakeOptions,
        setBodyMakeOptions,

        bodyModel,
        setBodyModel,
        bodyModelInputValue,
        setBodyModelInputValue,
        bodyModelOptions,
        setBodyModelOptions,

        chassisMake,
        setChassisMake,
        chassisMakeInputValue,
        setChassisMakeInputValue,
        chassisMakeOptions,
        setChassisMakeOptions,

        chassisModel,
        setChassisModel,
        chassisModelInputValue,
        setChassisModelInputValue,
        chassisModelOptions,
        setChassisModelOptions,

        modelYear,
        setModelYear,

        makeModelApprovalStatus,
        setMakeModelApprovalStatus,

        fetchMakes,
        fetchModels,

        getErrors,
        dirtyFields,
        setDirtyFields,

        createError,

        reset,
      }}
    >
      {children}
    </BidMakeAndModelContext.Provider>
  );
}

export default BidsMakeAndModelProvider;

BidsMakeAndModelProvider.defaultProps = {};

BidsMakeAndModelProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useBidMakeAndModelContext = () =>
  useContext(BidMakeAndModelContext);
