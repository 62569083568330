import React from 'react';
import { PropTypes } from 'prop-types';
import { emDashUnicode } from '../../../utilities/constants';

const MakeModelDetailsRow = ({
  makeCode,
  nhtsaModelCode,
  modelCode,
  comment,
}) => {
  return (
    <div className="grid-container">
      <div className="grid-row border-bottom border-base-lighter padding-bottom-1">
        <div className="grid-col flex-2 text-bold">Comments</div>
        <div className="grid-col flex-6 ">
          {comment || <span>{emDashUnicode}</span>}
        </div>
      </div>

      <div className="grid-row padding-top-2">
        <h3 className="text-primary">MAKE AND MODEL CODE</h3>
      </div>
      <div className="grid-row border-bottom border-base-lighter padding-bottom-1">
        <div className="grid-col flex-2 text-bold">Body make</div>
        <div className="grid-col flex-6 ">{makeCode}</div>
      </div>
      <div className="grid-row border-bottom border-base-lighter padding-bottom-1 padding-top-1">
        <div className="grid-col flex-2 text-bold">Body model</div>
        <div className="grid-col flex-6">{nhtsaModelCode || modelCode}</div>
      </div>
    </div>
  );
};

MakeModelDetailsRow.propTypes = {
  comment: PropTypes.string,
  makeCode: PropTypes.string.isRequired,
  modelCode: PropTypes.string.isRequired,
  nhtsaModelCode: PropTypes.string.isRequired,
};

MakeModelDetailsRow.defaultProps = {
  comment: '',
};

export default MakeModelDetailsRow;
