import React from 'react';
import PropTypes from 'prop-types';
import { StatusBadge } from '@gsa/afp-component-library';
import {
  SOLICITATION_PERIOD_STATUS_BADGE_VARIANT_MAP as VARIANT_MAP,
  SOLICITATION_PERIOD_STATUS_FIELD_NAME as STATUS_FIELDNAME,
  SOLICITATION_PERIOD_STATUS_CONTENT_MAP as CONTENT_MAP,
} from '../constants';

const SolicitationPeriodStatusBadge = ({ row }) => {
  const { [STATUS_FIELDNAME]: badgeStatus } = row?.original || {};
  const badgeContent = CONTENT_MAP[badgeStatus] || badgeStatus;
  const variant = VARIANT_MAP[badgeStatus];

  return <StatusBadge variant={variant}>{badgeContent}</StatusBadge>;
};

SolicitationPeriodStatusBadge.propTypes = {
  row: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default SolicitationPeriodStatusBadge;
