import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { Button, SelectDropdown, TextInput } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import VendorSearchTypeahead from './vendor-search-typeahead';
import { useVendorInfo } from '../../apis/vendor-api';
import { GET_BIDS_BY_SOLI_NUMBER } from './queries.gql';
import { bidDashboardAdmivDefaultViewAtom } from '../../../atoms/bids-atoms';

const AdminBidSearchPanel = ({ onBidSearchCompletion }) => {
  const [, setSearchOpt] = useRecoilState(bidDashboardAdmivDefaultViewAtom);
  const { setVendorInfoForAdmin } = useVendorInfo();
  const [selectedBidSearchOption, setSelectedBidSearchOption] = useState('');
  const [solicitationNumber, setSolicitationNumber] = useState('');
  const [
    bidSearchOptionInputErrorMessage,
    setBidSearchOptionInputErrorMessage,
  ] = useState(null);
  const [
    solicitationNumInputErrorMessage,
    setSolicitationNumInputErrorMessage,
  ] = useState('');
  const [showSearchResultText, setShowSearchResultText] = useState({
    show: false,
    text: '',
  });
  const [
    vendorTypeaheadSearchResult,
    setVendorTypeaheadSearchResult,
  ] = useState(null);

  const [getBidsBySolicitationNumber] = useLazyQuery(GET_BIDS_BY_SOLI_NUMBER, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getBidsBySolicitationNumber: data }) => {
      onBidSearchCompletion(data);
      setShowSearchResultText({ show: true, text: solicitationNumber });
    },
    onError: () => {
      setShowSearchResultText({
        show: true,
        text: 'Not able to fetch bids data',
      });
    },
  });

  const handleVendorSelection = (data) => {
    const { id, uniqueEntityIdentifier, vendorName } = data || {};
    setVendorTypeaheadSearchResult({
      id,
      uniqueEntityIdentifier,
      vendorName,
    });
  };

  const handleSearch = () => {
    if (!solicitationNumber || solicitationNumber === '') {
      setSolicitationNumInputErrorMessage('This field is required.');
    }
    if (!selectedBidSearchOption || selectedBidSearchOption === '') {
      setBidSearchOptionInputErrorMessage('This field is required.');
    }

    if (solicitationNumber !== '' && selectedBidSearchOption !== '') {
      // reset
      setSolicitationNumInputErrorMessage('');
      setBidSearchOptionInputErrorMessage('');

      // call search bid by soli number
      getBidsBySolicitationNumber({
        variables: {
          solicitationNumber,
        },
      });
    }

    if (
      selectedBidSearchOption === 'Vendor name/UEI' &&
      vendorTypeaheadSearchResult
    ) {
      setVendorInfoForAdmin(vendorTypeaheadSearchResult);
      setShowSearchResultText({
        show: true,
        text: vendorTypeaheadSearchResult?.vendorName,
      });
    }
  };

  const searchBidByOptions = [
    {
      label: '-Select-',
      value: '',
    },
    {
      label: 'Vendor name/UEI',
      value: 'Vendor name/UEI',
    },
    {
      label: 'Solicitation number',
      value: 'Solicitation number',
    },
  ];

  const handleBidSearchOptionChange = (value) => {
    // reset
    setSolicitationNumInputErrorMessage('');
    setBidSearchOptionInputErrorMessage('');
    onBidSearchCompletion([]);
    setSolicitationNumber('');
    setShowSearchResultText({ show: false, text: '' });
    setSelectedBidSearchOption(value);
    setVendorTypeaheadSearchResult({});
    setVendorInfoForAdmin({});
    setSearchOpt(value);
  };

  return (
    <>
      <div className="bg-primary-lightest padding-bottom-6 padding-top-4 margin-bottom-6">
        <div className="margin-2 grid-row">
          <div className="grid-col-3">
            <div className="padding-left-3 width-card-lg">
              <SelectDropdown
                name="search-bid-by"
                required
                id="search-bid-by"
                data-testid="search-bid-by"
                help="Select"
                onChange={(ev) => handleBidSearchOptionChange(ev.target.value)}
                options={searchBidByOptions}
                label="Search by"
                errorMessage={
                  bidSearchOptionInputErrorMessage &&
                  bidSearchOptionInputErrorMessage
                }
              />
            </div>
          </div>
          <div className="grid-col-3">
            <div className="width-auto">
              {selectedBidSearchOption === 'Solicitation number' ? (
                <TextInput
                  name="solicitation-number"
                  id="solicitation-number"
                  data-testid="solicitation-number"
                  type="text"
                  label={`Search for a bid by ${selectedBidSearchOption}`}
                  value={solicitationNumber}
                  onChange={(e) => {
                    setSolicitationNumber(e.target.value);
                    setSolicitationNumInputErrorMessage('');
                  }}
                  errorMessage={solicitationNumInputErrorMessage}
                />
              ) : (
                <VendorSearchTypeahead
                  onVendorSelection={handleVendorSelection}
                  label={selectedBidSearchOption}
                />
              )}
            </div>
          </div>
          <div className="grid-col-3">
            <div className="padding-top-7">
              <Button
                name="search-bid-for-admin"
                data-testid="search-bid-for-admin"
                onClick={() => handleSearch()}
                type="submit"
                variant="outline"
                label="Search"
                leftIcon={{ name: 'search' }}
                className="margin-left-2 margin-right-0"
              />
            </div>
          </div>
          <div className="grid-col-3" />
        </div>
      </div>
      {showSearchResultText.show && (
        <div className="margin-bottom-8 margin-top-neg-2">
          <span className="text-bold">
            Search Results for {selectedBidSearchOption} &quot;{' '}
            {showSearchResultText.text} &quot;
          </span>
        </div>
      )}
    </>
  );
};

AdminBidSearchPanel.propTypes = {
  onBidSearchCompletion: PropTypes.func.isRequired,
};

export default AdminBidSearchPanel;
