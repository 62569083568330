// jsx-a11y/no-noninteractive-tabindex
import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, RequiredFieldIndicator } from '@gsa/afp-component-library';

const MakeModelApprovalComment = ({ onCommentChange }) => (
  <div className="grid-row grid-gap padding-top-8">
    <div className="grid-col flex-10 width-tablet-lg">
      {/* eslint-disable-next-line  */}
      <div tabIndex="0" aria-label="Provide comments">
        Provide comments
        <RequiredFieldIndicator />
      </div>
      <TextInput
        className="minw-9 maxw-tablet"
        type="textarea"
        required
        data-testid="make-model-comment-section"
        aria-label="comment"
        onChange={(e) => onCommentChange('SET_COMMENT', e.target.value)}
        characterLimit={500}
      />
    </div>
  </div>
);

MakeModelApprovalComment.propTypes = {
  onCommentChange: PropTypes.func.isRequired,
};

export default MakeModelApprovalComment;
