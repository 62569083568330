import { atom } from 'recoil';

const solicitationNotifications = atom({
  key: 'Solicitation/notifications',
  default: [],
});

export const selectedSolicitationAtom = atom({
  key: 'Solicitation/selectedRow',
  default: {},
});

export const solicitationModalMode = atom({
  key: 'Solicitation/modalMode',
  default: {},
});

export default solicitationNotifications;
