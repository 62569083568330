import { Alert, Spinner, ErrorMessage } from '@gsa/afp-component-library';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DescriptionField from './DescriptionField';

const formSchema = yup.object().shape({
  documentStatus: yup.string().required('Please choose draft or final'),
});

const RadioGroup = ({ onChange }) => {
  return (
    <fieldset className="usa-fieldset padding-bottom-2" onChange={onChange}>
      <legend className="usa-legend usa-legend usa-sr-only">
        Indicate the current status of this document
      </legend>
      <div className="usa-radio">
        <input
          data-testid="draft-radio"
          className="usa-radio__input"
          id="draft"
          type="radio"
          name="copyType"
          value="Draft"
        />
        <label className="usa-radio__label" htmlFor="draft">
          Draft
        </label>
      </div>
      <div className="usa-radio">
        <input
          data-testid="final-radio"
          className="usa-radio__input"
          id="final"
          type="radio"
          name="copyType"
          value="Final"
        />
        <label className="usa-radio__label" htmlFor="final">
          Final
        </label>
      </div>
    </fieldset>
  );
};

RadioGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const SELECT_FILE_MESSAGE = 'Please select a file';

const AttachmentAddForm = ({
  requireFile,
  isFromTaskDetail,
  addState,
  uploadPath,
  attachmentErrors,
  fileTypes,
  maxFileSize,
  linkedEntities,
  onAdd,
}) => {
  const [file, setFile] = useState({});
  const [fileErrorMessage, setFileErrorMessage] = useState();

  const {
    register,
    control,
    handleSubmit,
    errors,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(
      !isFromTaskDetail ? formSchema : yup.object().shape({}),
    ),
  });

  const fileInputRef = useRef();

  useEffect(() => {
    if (fileInputRef.current) {
      register(fileInputRef.current, { required: requireFile });
      fileInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (errors?.file?.message) {
      setFileErrorMessage(errors?.file?.message);
    } else if (!file?.type && errors?.file?.type === 'required') {
      setFileErrorMessage(SELECT_FILE_MESSAGE);
    }
  }, [errors && errors.file]);

  const onSubmit = (fileInfo) => {
    if (!file || !file?.type) {
      setFileErrorMessage(SELECT_FILE_MESSAGE);
      return;
    }
    const formData = new FormData();

    formData.append('attachment', file);
    formData.append('description', fileInfo?.description);
    formData.append('path', uploadPath);
    formData.append('linkedEntities', JSON.stringify(linkedEntities));
    formData.append('status', fileInfo.documentStatus);

    onAdd(formData);
  };

  const fileInputGroupStyles = classnames('usa-form-group', {
    [`usa-form-group--error`]: !!fileErrorMessage,
  });

  return (
    <form
      id="attachment-upload-form"
      data-testid="modal-attachment-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {addState?.adding && (
        <Spinner data-testid="adding-spinner" size="small" />
      )}

      {attachmentErrors?.save?.message && (
        <Alert type="error">{attachmentErrors?.save?.message}</Alert>
      )}

      <div className={fileInputGroupStyles}>
        <label className="usa-label" htmlFor="attachment-file-input-single">
          <strong>Supporting file</strong>
        </label>
        <span id="document-textarea-hint" className="usa-hint">
          Accepts .pdf, .docx, .jpeg files
        </span>
        {fileErrorMessage && (
          <span className="usa-error-message">{fileErrorMessage}</span>
        )}
        <div className="usa-file-input">
          <div className="usa-file-input__target">
            <div className="usa-file-input__instructions" aria-hidden="true">
              <span className="usa-file-input__drag-text">
                Drag file here or&nbsp;
              </span>
              <span className="usa-file-input__choose">choose from folder</span>{' '}
              (maximum size: 10 MB)
            </div>
            <div className="usa-file-input__box" />
            <input
              disabled={addState?.adding}
              name="file"
              ref={fileInputRef}
              id="attachment-file-input-single"
              data-testid="attachment-file-input"
              className="usa-file-input__input"
              type="file"
              aria-describedby="file-input-specific-hint"
              onChange={(e) => {
                // Clear errors and file on every select.
                clearErrors('file');
                setFile(null);
                setFileErrorMessage(undefined);

                // Supports single file upload only,
                // extract file [0];
                if (e.target.files?.length) {
                  const [selected] = e.target.files;

                  // Checks file type during drag drop.
                  const extension = selected.name
                    .substr(selected.name.lastIndexOf('.') + 1)
                    .toLowerCase();

                  if (!fileTypes?.includes(extension)) {
                    setError('file', {
                      type: 'file-type',
                      message: 'File format not supported',
                    });
                    return;
                  }

                  // Checks file size
                  if (selected.size === 0 || selected.size > maxFileSize) {
                    let errorMessage = 'Size should be under 10 MB';

                    if (selected.size === 0) errorMessage = 'Invalid file size';

                    setError('file', {
                      type: 'file-size',
                      message: errorMessage,
                    });
                    return;
                  }

                  setFile(selected);
                }
              }}
              accept={fileTypes}
            />
          </div>
        </div>
      </div>

      {file?.name && (
        <span id="attachment-file-input-single-hint" className="usa-hint">
          <strong>Selected file: </strong>
          {file?.name}
        </span>
      )}
      <Alert type="warning">
        Help prevent a privacy incident by ensuring that any supporting document
        uploaded here does not contain{' '}
        <a
          href="https://www.gsa.gov/reference/gsa-privacy-program/rules-and-policies-protecting-pii-privacy-act"
          target="_blank"
          rel="noreferrer"
        >
          personally identifiable information
        </a>{' '}
        (PII).
      </Alert>
      <DescriptionField
        fieldRef={register({ maxLength: 100 })}
        showErrorMessage={errors?.description?.type === 'required'}
      />
      {!isFromTaskDetail && (
        <div
          className={
            errors.documentStatus?.message
              ? 'usa-form-group usa-form-group--error'
              : ''
          }
        >
          <label className="usa-label" htmlFor="document-status">
            <strong>Status&nbsp;</strong>
            <abbr title="required" className="usa-hint usa-hint--required">
              *
            </abbr>
          </label>
          {errors?.documentStatus?.message && (
            <ErrorMessage>{errors.documentStatus?.message}</ErrorMessage>
          )}
          <span id="attachment-select-status-hint" className="usa-hint">
            Indicate the current status of this document
          </span>
          <Controller
            control={control}
            name="documentStatus"
            defaultValue=""
            render={({ onChange, value }) => (
              <RadioGroup
                data-testid="documentStatus"
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
              />
            )}
          />
        </div>
      )}
    </form>
  );
};

export default AttachmentAddForm;

AttachmentAddForm.defaultProps = {
  requireFile: true,
  isFromTaskDetail: false,
  fileTypes: '.jpg,.jpeg,.png,.bmp,.pdf,.doc,.docx,.xls,.xlsx,.txt,.csv',
  maxFileSize: 52428800, // 50MB
  linkedEntities: {},
  uploadPath: '',
  attachmentErrors: {},
};
AttachmentAddForm.propTypes = {
  requireFile: PropTypes.bool,
  isFromTaskDetail: PropTypes.bool,
  fileTypes: PropTypes.string,
  maxFileSize: PropTypes.number,
  linkedEntities: PropTypes.shape(Object),
  addState: PropTypes.shape(Object).isRequired,
  uploadPath: PropTypes.string,
  attachmentErrors: PropTypes.shape(Object),
  onAdd: PropTypes.func.isRequired,
};
