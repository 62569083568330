import * as yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const formSchema = yup.object().shape({
  standardItemId: yup.lazy((value) => {
    if (value !== undefined) {
      return yup.string().required('Standard Item is required');
    }
    return yup.mixed().notRequired();
  }),
  associationTypeCode: yup.string().required('Association type is required'),
  inputTypeCode: yup.string().when('associationTypeCode', {
    is: (associationTypeCode) => associationTypeCode === 'S',
    then: yup.string().required('Input type is required'),
    otherwise: yup.string(),
  }),
  criteriaCode: yup.lazy((value) => {
    if (value !== undefined) {
      return yup.string().required('Input type is required');
    }
    return yup.mixed().notRequired();
  }),
  unitCode: yup.lazy((value) => {
    if (value !== undefined) {
      return yup.string().required('Unit is required');
    }
    return yup.mixed().notRequired();
  }),
  lowerBound: yup.lazy((value) => {
    if (value !== undefined) {
      return yup
        .number()
        .typeError('Must be a number')
        .moreThan(0, 'Must be greater than 0')
        .required('Numerical value is required');
    }
    return yup.mixed().notRequired();
  }),
  upperBound: yup.lazy((value, { parent: { lowerBound } }) => {
    if (value !== undefined) {
      return yup
        .number()
        .typeError('Must be a number')
        .moreThan(lowerBound, `Must be greater than ${lowerBound}`)
        .required('Numerical value 2 is required');
    }
    return yup.mixed().notRequired();
  }),
  associationText: yup.lazy((value) => {
    if (value !== undefined && value.length > 80) {
      return yup
        .string()
        .length(80, 'Additional info cannot exceed 80 characters');
    }
    return yup.mixed().notRequired();
  }),
});
