import React from 'react';
import PropTypes from 'prop-types';
import { MultiSelectDropdown } from '@gsa/afp-component-library';
import { useContractLine } from '../provider/contract-line-provider';
import { getOptionalEcList } from '../helpers/ec-helpers';
import { getEditConflictBlock, displayRequires } from './conflicts-components';

const EditRequiresBlock = ({ equipment, field, data, options, onUpdate }) => {
  const relations =
    data.length && !data[data.length - 1].length ? [...data] : [...data, []];

  const { optionalECs } = useContractLine();
  const ecList = getOptionalEcList(optionalECs);

  const onChange = (i, value) => {
    const newRelations = [...relations];
    newRelations.splice(i, 1, value);
    onUpdate('requires', newRelations);
  };

  const getForm = () =>
    relations.map((row, i) => (
      <div
        // eslint-disable-next-line
        key={`requires-input-${i}`}
        className={`${
          i < relations.length - 1 && row.length === 0 ? 'display-none' : ''
        }`}
      >
        <MultiSelectDropdown
          id={`${field}-${i}`}
          selectedValues={row}
          options={options}
          onChange={(value) => onChange(i, value)}
          // queryThreshold={1}
          chipShowValue
        />
        {row.length > 0 && <div className="text-bold font-sans-2xs">OR</div>}
      </div>
    ));

  const getSummary = () => {
    const rows = relations.filter((row) => row.length);
    if (rows.length > 0) return displayRequires(rows, ecList);
    return <div className="margin-left-2">(None)</div>;
  };

  return getEditConflictBlock(equipment, field, getForm, getSummary);
};

EditRequiresBlock.propTypes = {
  equipment: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  options: PropTypes.arrayOf(PropTypes.objectOf({})).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default EditRequiresBlock;
