/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { Modal, Button, Alert, connectModal } from '@gsa/afp-component-library';
import OverlaySpinner from '../../../../components/overlay-spinner';
import MakeAndModelSearchPanel from './make-and-model-select-panel';
import MakeModelApprovalComment from './make-model-approval-comment-input';
import { useMakeAndModeApprovalRequestContext } from './make-model-approval-provider';
import { makeModelStatusMessageAtom } from '../../atoms/make-model-atoms';
import {
  GET_BID_LINES_BY_MAKE_MODEL_FOR_APPROVAL,
  EDIT_MAKE_MODEL_APPROVAL_REQUEST,
  GET_PRE_BID_SELECTION_BY_MAKE_MODEL_FOR_APPROVAL,
} from '../../queries.gql';

const EditMakeModelApprovalModal = ({ onClose, data, refetchData }) => {
  const [alert, setAlert] = useState(null);
  const hasChassis = data?.chassisMakeCode && data?.chassisModelCode;
  const {
    setAddMakeModelContext,
    comment,
    makeCode,
    makeName,
    makeCustomKeyword,
    modelCode,
    modelName,
    modelCustomKeyword,
    chassisMakeCode,
    chassisMakeName,
    chassisModelCode,
    chassisModelName,
    chassisMakeCustomKeyword,
    chassisModelCustomKeyword,
  } = useMakeAndModeApprovalRequestContext();

  const [, setToaster] = useRecoilState(makeModelStatusMessageAtom);

  useEffect(() => {
    setAddMakeModelContext('SET_MAKE', {
      makeCode: data?.bodyMake?.makeCode,
      makeName: data?.bodyMake?.makeName,
    });
    setAddMakeModelContext('SET_MODEL', {
      modelCode: data?.bodyModel?.modelCode,
      modelName: data?.bodyModel?.modelName,
    });
    setAddMakeModelContext('SET_CHASSIS_MAKE', {
      makeCode: data?.chassisMake?.makeCode,
      makeName: data?.chassisMake?.makeName,
    });
    setAddMakeModelContext('SET_CHASSIS_MODEL', {
      modelCode: data?.chassisModel?.modelCode,
      modelName: data?.chassisModel?.modelName,
    });
  }, [data]);

  const originalRequestData = {
    makeCode: data?.bodyMake?.makeCode,
    makeName: data?.bodyMake?.makeName,
    modelCode: data?.bodyModel?.modelCode,
    modelName: data?.bodyModel?.modelName,
    chassisMakeCode: data?.chassisMake?.makeCode,
    chassisMakeName: data?.chassisMake?.makeName,
    chassisModelCode: data?.chassisModel?.modelCode,
    chassisModelName: data?.chassisModel?.modelName,
  };

  const [editMakeModelApprovalRequest] = useMutation(
    EDIT_MAKE_MODEL_APPROVAL_REQUEST,
    {
      onCompleted: () => {
        setToaster({
          type: 'success',
          message: (
            <>
              You have successfully approved {makeName || makeCustomKeyword} and{' '}
              {modelName || modelCustomKeyword}{' '}
              {chassisMakeName && chassisModelName
                ? `/ ${chassisMakeName || chassisMakeCustomKeyword} - ${
                    chassisModelName || chassisModelCustomKeyword
                  }`
                : ''}
              .
            </>
          ),
        });
        refetchData();
        onClose();
      },
      onError: () => {
        setToaster({
          type: 'error',
          message: (
            <>
              There was an error while approving {makeName} and {modelName}{' '}
              {chassisMakeName && chassisModelName
                ? `/ ${chassisMakeName} - ${chassisModelName}`
                : ''}
              .
            </>
          ),
        });
        refetchData();
        onClose();
      },
    },
  );

  const {
    data: bidLineMakeModelData,
    loading: isBidLineMakeModelDataLoading,
  } = useQuery(GET_BID_LINES_BY_MAKE_MODEL_FOR_APPROVAL, {
    fetchPolicy: 'cache-and-network',
    variables: {
      makeModelSearchInput: {
        makeCode: Number(data?.bodyMake?.makeCode),
        modelCode: data?.bodyModel?.modelCode,
        chassisMakeCode:
          (data?.chassisMake?.makeCode &&
            Number(data?.chassisMake?.makeCode)) ||
          null,
        chassisModelCode: data?.chassisModel?.modelCode || null,
      },
    },

    skip: !Number(data?.bodyMake?.makeCode) || !data?.bodyModel?.modelCode,
  });

  const {
    data: preBidMakeModelData,
    loading: isPreBidMakeModelDataLoading,
  } = useQuery(GET_PRE_BID_SELECTION_BY_MAKE_MODEL_FOR_APPROVAL, {
    fetchPolicy: 'cache-and-network',
    variables: {
      makeCode: Number(originalRequestData?.makeCode),
      modelCode: originalRequestData?.modelCode,
      // include chassis if it exists
      ...(originalRequestData?.chassisMakeCode && {
        chassisMakeCode: Number(originalRequestData?.chassisMakeCode),
        chassisModelCode: originalRequestData?.chassisModelCode,
      }),
    },

    skip: !Number(data?.bodyMake?.makeCode) || !data?.bodyModel?.modelCode,
  });

  // helper method to get open bid bid line ids
  const getValidAffectedBidLines = (bidLines) => {
    const currentDate = new Date();
    const validIds = [];

    bidLines?.forEach((line) => {
      const bidSolicitationId = line?.bid?.solicitationPeriodId;
      const {
        solicitationPeriods,
      } = line?.solicitationLine?.solicitationProgram?.solicitation;

      solicitationPeriods.forEach((period) => {
        const endDate = new Date(period?.endDate);
        if (
          bidSolicitationId === parseInt(period?.id, 10) &&
          endDate >= currentDate
        ) {
          validIds.push(line);
        }
      });
    });

    return validIds;
  };

  // get all the veh-suppliers emails who used the
  // make & model combination in their bid-line for the open bid
  const bidVehicleSupplierEmails =
    !isBidLineMakeModelDataLoading &&
    Array.from(
      new Set(
        getValidAffectedBidLines(
          bidLineMakeModelData?.getBidLinesByMakeAndModel,
        )?.map((bidLine) => bidLine?.vehicleSupplierUser?.email),
      ),
    );

  // bid line IDs affected by the make & model combination
  const affectedBidLineIds =
    !isBidLineMakeModelDataLoading &&
    getValidAffectedBidLines(
      bidLineMakeModelData?.getBidLinesByMakeAndModel,
    )?.map((bidLine) => parseInt(bidLine?.id, 10));

  const showAlert = () => {
    if (!alert) return null;
    const { message, ...restProps } = alert;
    return (
      <div className="grid-row flex-column grid-gap">
        <div className="grid-col flex-fill margin-bottom-1">
          <Alert
            slim
            showClose
            focused
            onClose={() => setAlert(null)}
            {...restProps}
          >
            {message}
          </Alert>
        </div>
      </div>
    );
  };

  // pre-bid make-model selection ids
  const affectedPreBidMakeModelIds =
    !isPreBidMakeModelDataLoading &&
    Array.from(
      new Set(
        preBidMakeModelData?.getPreBidMakeModels?.map((selection) =>
          parseInt(selection?.id, 10),
        ),
      ),
    );

  // pre-bid vehicle supplier emails
  const preBidVehicleSupplierEmails =
    !isPreBidMakeModelDataLoading &&
    preBidMakeModelData?.getPreBidMakeModels?.map(
      (selection) => selection?.preBidSelection?.vendorDetail?.emailAddress,
    );

  const modalTitle = 'Edit make and model';
  const modalSubTitle =
    'Are you sure you want to edit the below make and model?';

  const onUpdateMakeModel = () => {
    const fields = [
      { missing: !comment || comment === '', field: 'Comment' },
      { missing: !makeCode && !makeCustomKeyword, field: 'Body Make' },
      { missing: !modelCode && !modelCustomKeyword, field: 'Body Model' },
      {
        missing:
          chassisMakeCode && !chassisMakeCode && !chassisMakeCustomKeyword,
        field: 'Chassis Make',
      },
      {
        missing:
          chassisMakeCode && !chassisModelCode && !chassisModelCustomKeyword,
        field: 'Chassis Model',
      },
    ];
    const missingField = fields.find((f) => f.missing);
    if (missingField) {
      setAlert({
        type: 'error',
        message: (
          <>
            <strong>{missingField.field}</strong> is a required field.
          </>
        ),
      });
    } else {
      setAlert(null);

      // prepare the input for the mutation
      const editMakeModelApprovalRequestInput = {
        makeModelApprovalId: parseInt(data?.makeModelApprovalId, 10),
        makeCode: parseInt(makeCode, 10),
        makeName: makeCustomKeyword || makeName,
        modelCode,
        modelName: modelCustomKeyword || modelName,
        ...((chassisMakeName || chassisMakeCustomKeyword) && {
          chassisMakeCode: parseInt(chassisMakeCode, 10),
          chassisMakeName: chassisMakeCustomKeyword || chassisMakeName,
        }),
        ...((chassisModelName || chassisModelCustomKeyword) && {
          chassisModelCode,
          chassisModelName: chassisModelCustomKeyword || chassisModelName,
        }),
        bidLineIds: affectedBidLineIds,
        preBidMakeModelSelectionIds: affectedPreBidMakeModelIds,
        comment,
        vehicleSupplierEmails: [
          ...bidVehicleSupplierEmails,
          ...preBidVehicleSupplierEmails,
        ],
        originalRequestData,
      };
      editMakeModelApprovalRequest({
        variables: { editMakeModelApprovalRequestInput },
      });
    }
  };

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          id="edit-make-model-approval-modal"
          className="edit-make-model-approval-request-modal"
          variant="extra-large"
          title={
            <h2 tabIndex="0" aria-label={modalTitle}>
              {modalTitle}
            </h2>
          }
          onClose={onClose}
          actions={
            <div>
              <Button
                data-testid="edit-make-model-approval-modal-cancel-btn"
                variant="unstyled"
                onClick={onClose}
                label="Cancel"
              />
              <Button
                data-testid="edit-make-model-approval-modal-save-btn"
                variant="primary"
                onClick={onUpdateMakeModel}
                label="Save and approve"
              />
            </div>
          }
        >
          {isBidLineMakeModelDataLoading && (
            <OverlaySpinner message="Getting vendor information ..." />
          )}
          <div className="padding-top-2 padding-bottom-6">
            <span tabIndex="0" aria-label={modalSubTitle}>
              {modalSubTitle}
            </span>
          </div>
          <div>
            {showAlert()}
            <MakeAndModelSearchPanel hasChassis={hasChassis} />
            <MakeModelApprovalComment
              onCommentChange={setAddMakeModelContext}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};

EditMakeModelApprovalModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    makeCode: PropTypes.number.isRequired,
    makeName: PropTypes.string.isRequired,
    modelCode: PropTypes.string.isRequired,
    modelName: PropTypes.string.isRequired,
    chassisMakeCode: PropTypes.number,
    chassisModelCode: PropTypes.string,
    makeModelApprovalId: PropTypes.number.isRequired,
  }).isRequired,
  refetchData: PropTypes.func.isRequired,
};

export default connectModal(EditMakeModelApprovalModal);
