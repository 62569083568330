import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { EmptyState } from '@gsa/afp-component-library';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import {
  VehicleStandardsHeader,
  VehicleStandardsFilter,
  VehicleStandardsInfo,
  FVSSelectSI,
  VehicleStandardsActionsPanel,
} from './components';
import FvsLandingPageProvider from './fvs-landing-page-provider';
import { useFederalStandards } from '../fvs-provider';
import FvsMessages from '../components/fvs-messages';
import ToastMessage from '../../../components/Toast/toast';
import VehicleStandardsRelatedInfo from './components/related-info';

const LandingPage = () => {
  const {
    selectedYear,
    vehicleStandard,
    getOpenCommentingPeriodCount,
    commentingPeriodsData,
    fvsMessages,
    clearAllMessages,
  } = useFederalStandards();

  useEffect(() => {
    return () => {
      // Clear generated messages before unload
      clearAllMessages();
    };
  }, []);

  useEffect(() => {
    getOpenCommentingPeriodCount();
  }, []);

  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs.home}/home`,
      label: 'Home',
    },
  ];

  const defaultFilters = {
    operator: 'AND',
    value: [
      {
        key: 'year',
        operator: 'EQ',
        value: selectedYear,
      },
      {
        key: 'vehicleType',
        operator: 'EQ',
        value: null,
      },
      {
        key: 'vehicleGroup',
        operator: 'EQ',
        value: null,
      },
      {
        key: 'fedStandardCode',
        operator: 'EQ',
        value: null,
      },
    ],
  };

  const [, setFilters] = useState(defaultFilters);
  const [, setCanShowDashboard] = useState(false);

  useEffect(() => {
    setCanShowDashboard(false);
    if (commentingPeriodsData?.psoCommentPeriodCounts?.length > 0) {
      commentingPeriodsData?.psoCommentPeriodCounts.forEach((cp) => {
        if (cp.year === selectedYear && cp?.openCount > 0) {
          setCanShowDashboard(true);
        }
      });
    }
  }, [commentingPeriodsData, selectedYear]);

  return (
    <FvsLandingPageProvider>
      <div>
        <Breadcrumbs
          current="Federal Vehicle Standards"
          path={breadcrumbsPath}
        />
        {commentingPeriodsData?.allOpenCount > 0 && (
          <ToastMessage
            key="commenting_period_count_message"
            message={
              <span>
                There are currently {commentingPeriodsData?.allOpenCount} open
                commenting periods.
                <Link
                  to="/public/fvs/vehicle-standards/commenting-periods"
                  className="padding-left-1"
                >
                  View commenting periods
                </Link>
              </span>
            }
            type="info"
            onClose={clearAllMessages}
            closeable={false}
          />
        )}
        {fvsMessages && <FvsMessages />}
        <div>
          <div className="grid-row grid-gap flex-justify">
            <VehicleStandardsHeader />
            <div className="desktop:grid-col-3 display-flex desktop:flex-justify-end desktop:margin-top-3">
              <VehicleStandardsActionsPanel />
            </div>
          </div>

          <div className="grid-row grid-gap">
            <div className="desktop:grid-col-3 tablet-lg:grid-col-3 standard-table-filters-wrapper">
              <div className="fvs-mobile-sticky-wrapper">
                <div className="fvs-mobile-sticky-track">
                  <div className="fvs-mobile-sticky-container">
                    <VehicleStandardsFilter
                      onFilter={(filterValue) => {
                        setFilters(filterValue);
                      }}
                    />
                    {/* <MobileActionsPanel /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="desktop:grid-col-9 standard-table-wrapper tbl">
              {vehicleStandard?.id ? (
                <>
                  <VehicleStandardsInfo />
                  <FVSSelectSI />
                  <VehicleStandardsRelatedInfo />
                </>
              ) : (
                <EmptyState
                  hasBackground
                  containerStyles="padding-y-8 margin-top-1 height-full"
                  topText={
                    <>
                      <strong>No Federal Vehicle Standard selected</strong>
                      <br />
                      <p>
                        Start with a Contract year and choose a Federal Standard
                        to inspect on the left
                      </p>
                    </>
                  }
                  topTextStyles="margin-top-7"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </FvsLandingPageProvider>
  );
};

export default LandingPage;
