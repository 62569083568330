import React, { useState, useEffect } from 'react';
import { PageTitle, Spinner } from '@gsa/afp-component-library';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import SolicitationStepper from '../../../components/solicitation-stepper';
import Breadcrumbs from '../../../../../widgets/breadcrumbs';
import SolicitationNotification from '../../../utils/solicitation-notification';
import SolicitationFormWrapper from './add-solicitation-form-context';
import { GET_SOLICITATION_BY_ID } from '../../../queries';

const NewSolicitation = () => {
  const { id } = useParams();
  const [solicitation, setSolicitation] = useState(null);

  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs.home}/home`,
      label: 'Home',
    },
    {
      location: '/catalog/solicitations',
      label: 'Solicitations',
    },
  ];
  const [getSolicitationById, { loading }] = useLazyQuery(
    GET_SOLICITATION_BY_ID,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const current = data.getSolicitationById;
        const formattedSolicitation = {
          upiidAAC: current.solicitationNumber.substring(0, 6),
          upiidSQ: current.solicitationNumber.substring(6, 13),
          solicitationTitle: current.title,
          solicitationDescription: current.description,
          solicitationPosting: current.samUrl,
          contractYear: current.contractYear,
          purchaseType: current.purchaseTypeCode,
          solicitationType: current.solicitationType,
          solicitationStatus: current.status,
          solicitationNumber: current.solicitationNumber,
          solicitationID: current.id,
        };
        setSolicitation(formattedSolicitation);
      },
    },
  );

  useEffect(() => {
    if (id) {
      getSolicitationById({ variables: { id: Number(id) } });
    }
  }, [id]);

  return (
    <>
      <div className="grid-col">
        <Breadcrumbs current="New Solicitation" path={breadcrumbsPath} />
        <SolicitationNotification isModal={false} />
        <PageTitle
          title="New Solicitation"
          aria-label="New Solicitation"
          tabIndex="0"
        />
        <SolicitationStepper currentStep={1} />
        {!loading ? (
          <SolicitationFormWrapper solicitation={solicitation} />
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
};

export default NewSolicitation;
