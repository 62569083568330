/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import {
  AFPTable,
  EmptyState,
  Button,
  FilterTableFrame,
} from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import BidHistoryTableFilter from './bid-history-table-filters';
import { emDashUnicode } from '../../../utilities/constants';

const BidHistoryTable = ({ bidData }) => {
  const history = useHistory();
  const [filters, setFilters] = useState([]);
  const [closedBidTableData, setClosedBidTableData] = useState(bidData);
  const solicitationPeriodTypeMap = {
    O: 'Open',
    R: 'Reopen',
    M: 'Mid-cycle',
  };
  /* eslint-disable-next-line */
  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Solicitation uPIID',
        accessor: 'soliciation.solicitationNumber',
      },
      {
        Header: 'Title',
        sortable: false,
        accessor: 'soliciation.title',
      },
      {
        Header: 'Posting date',
        accessor: 'solicitationPeriod.startDate',
        Cell: ({ value }) => {
          return moment(value).format('L');
        },
      },
      {
        Header: 'Closing date',
        accessor: 'solicitationPeriod.endDate',
        Cell: ({ value }) => {
          return moment(value).format('L');
        },
      },
      {
        Header: 'Contract year',
        accessor: 'soliciation.contractYear',
      },
      {
        Header: 'Solicitation period',
        accessor: 'solicitationPeriod.periodType',
        Cell: ({ value }) => {
          return solicitationPeriodTypeMap[value] || emDashUnicode;
        },
      },
      {
        Header: 'Actions',
        accessor: 'view_dashboard',
        sortable: false,
        cellClassName: 'afpTable-cell-wordBreak',
        // eslint-disable-next-line
        Cell: ({ row }) => {
          return (
            <Button
              variant="unstyled"
              onClick={() => {
                history.push(
                  `/catalog/solicitations/${
                    // eslint-disable-next-line react/prop-types
                    row?.original?.soliciation?.id
                    // eslint-disable-next-line react/prop-types
                  }/bid-dashboard/${Number(row?.original?.id)}`,
                );
              }}
              label="View Dashboard"
            />
          );
        },
      },
    ];

    return columnList;
  }, [bidData]);

  const deepFilterClosedBidData = (partiallyFilteredData, orgFilters) => {
    if (!partiallyFilteredData?.length) return partiallyFilteredData;
    return partiallyFilteredData.filter((item) => {
      return item.BidLines.some((line) => {
        return orgFilters.every((filter) => {
          const keys = filter.key.split('.');
          if (
            filter.key === 'afpMake.makeCode' ||
            filter.key === 'afpModel.modelCode' ||
            filter.key === 'chassisMake.makeCode' ||
            filter.key === 'chassisModel.modelCode' ||
            filter.key === 'standardItem.standardItemNumber'
          ) {
            return line?.[keys[0]]?.[keys[1]] === filter.value;
          }
          return true;
        });
      });
    });
  };

  function shallowFilterClosedBidData(data, appliedFilters) {
    if (!appliedFilters?.length || !data?.length) return data;

    const partiallyFilteredData = data.filter((item) => {
      const filterChecker = (object) => {
        return appliedFilters.every((filter) => {
          if (
            filter.key === 'soliciation.solicitationNumber' ||
            filter.key === 'soliciation.contractYear'
          ) {
            const keys = filter.key.split('.');
            let target = object;
            // eslint-disable-next-line
            for (const key of keys) {
              if (target === undefined) return false;
              target = target[key];
            }
            return target === filter.value;
            // eslint-disable-next-line
          } else {
            return true;
          }
        });
      };
      return filterChecker(item);
    });

    const finalFilteredData = deepFilterClosedBidData(
      partiallyFilteredData,
      appliedFilters,
    );
    return finalFilteredData;
  }

  useEffect(() => {
    const filteredData = shallowFilterClosedBidData(bidData, filters);
    setClosedBidTableData(filteredData);
  }, [filters]);

  const header = () => {
    return (
      <div
        className="text-primary title-s-caps padding-top-2"
        data-testid="bids-history-header"
        aria-label="My closed solicitations"
      >
        MY CLOSED SOLICITATIONS
      </div>
    );
  };

  const TableWrapper = ({ tableProps }) => (
    <div className="grid-col">
      <AFPTable {...tableProps} />
      {!tableProps?.data?.length && !tableProps?.isDataLoading && (
        <div className="table-empty-state">
          <EmptyState bottomText="No results found." />
        </div>
      )}
    </div>
  );

  const FTF = useMemo(
    () => FilterTableFrame(header, null, BidHistoryTableFilter, TableWrapper),
    [],
  );

  return (
    <>
      <FTF
        lowLeftProps={{
          filterStructureData: bidData,
          setFilters,
        }}
        lowRightProps={{
          tableProps: {
            testId: 'closed-solicitation-table',
            fullWidth: true,
            columns,
            data: closedBidTableData || [],
          },
        }}
      />
    </>
  );
};

BidHistoryTable.propTypes = {
  bidData: PropTypes.arrayOf(PropTypes.array).isRequired,
};

export default BidHistoryTable;
