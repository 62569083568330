import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Button,
  connectModal,
  Modal,
  useModal,
  Alert,
  StatusBadge,
  Tag,
} from '@gsa/afp-component-library';
import { CommentPropType } from '../../../utilities/types';
import { tagColors } from '../../comment-admin/helpers';

export const DeleteModal = ({
  closeModal,
  pending,
  onSubmit,
  formErrorMessage,
  onClose,
}) => (
  <Modal
    id="delete-comment-modal"
    title={<h2>Delete Comment</h2>}
    onClose={() => {
      closeModal();
      onClose();
    }}
    actions={
      <>
        <Button
          type="button"
          data-testid="cancel-comment-btn-modal"
          variant="unstyled"
          className="margin-right-2"
          onClick={() => {
            closeModal();
            onClose();
          }}
          label="Cancel"
        />
        <Button
          data-testid="delete-comment-btn-modal"
          disabled={pending}
          type="submit"
          onClick={onSubmit}
          variant="secondary"
          label={pending ? 'Deleting..' : 'Delete comment'}
        />
      </>
    }
  >
    {formErrorMessage && (
      <Alert type="error" className="margin-bottom-1">
        {formErrorMessage}
      </Alert>
    )}
    Are you sure you want to delete this comment?
  </Modal>
);

DeleteModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pending: PropTypes.bool.isRequired,
  formErrorMessage: PropTypes.string,
  onClose: PropTypes.func,
};

DeleteModal.defaultProps = {
  formErrorMessage: null,
  onClose: () => {
    // noop
  },
};

export default function DeleteCommentItem({
  comment,
  allowChanges,
  currentUsrId,
  deleteStandardsComment,
  deletingComment,
  submissionStatus,
}) {
  const { isOpen, openModal, closeModal } = useModal();
  const [formErrorMessage, setFormErrorMessage] = useState(null);
  if (currentUsrId !== comment?.createdByUser) {
    return null;
  }

  const commentIsPublished = comment?.publishStatus === 'Published';

  const removeComment = (commentId) => {
    deleteStandardsComment({
      variables: {
        id: commentId,
      },
    });
  };

  useEffect(() => {
    if (
      !_.isEmpty(submissionStatus) &&
      _.get(submissionStatus, 'action') === 'delete'
    ) {
      if (_.get(submissionStatus, 'status') === 'success') {
        closeModal();
      }
      if (_.get(submissionStatus, 'status') === 'fail') {
        setFormErrorMessage(_.get(submissionStatus, 'message'));
      }
    }
  }, [submissionStatus]);

  const handleOnClose = () => {
    setFormErrorMessage(null);
  };

  const ConnectedAddModal = connectModal(() => (
    <DeleteModal
      closeModal={closeModal}
      pending={deletingComment}
      onSubmit={() => {
        removeComment(parseFloat(comment.id));
      }}
      formErrorMessage={formErrorMessage}
      onClose={handleOnClose}
    />
  ));

  return (
    <>
      <ConnectedAddModal isOpen={isOpen} closeModal={closeModal} />
      {allowChanges && !commentIsPublished ? (
        <Button
          type="button"
          variant="unstyled"
          data-testid="show-delete-comment-modal-btn"
          className="cursor-pointer display-flex flex-align-center"
          onClick={openModal}
          label="Delete comment"
          leftIcon={{
            name: 'cancel',
            className: 'usa-icon--size-3 text-base',
          }}
        />
      ) : null}
      {commentIsPublished && (
        <div className="display-flex flex-justify">
          <Tag variant={tagColors[comment.status]}>{comment.status}</Tag>
          <StatusBadge variant="Ready-Gray">
            {comment?.publishStatus}
          </StatusBadge>
        </div>
      )}
    </>
  );
}

DeleteCommentItem.propTypes = {
  comment: PropTypes.shape(CommentPropType).isRequired,
  allowChanges: PropTypes.bool.isRequired,
  currentUsrId: PropTypes.string.isRequired,
  deleteStandardsComment: PropTypes.func.isRequired,
  deletingComment: PropTypes.bool.isRequired,
  submissionStatus: PropTypes.shape({
    action: PropTypes.string,
    status: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
};
