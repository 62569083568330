import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const ContractContext = createContext();

const initialState = {
  maxVersionNumber: 0,
};

const ContractReducer = (state, action) => {
  switch (action.type) {
    case 'SET_MAX_VERSION_NUMBER':
      return { ...state, maxVersionNumber: action.payload };
    default:
      return state;
  }
};

const ContractProvider = ({ children, ...props }) => {
  const [state, dispatch] = useReducer(ContractReducer, initialState);

  const setMaxVersionNumber = (maxVersionNumber) => {
    dispatch({ type: 'SET_MAX_VERSION_NUMBER', payload: maxVersionNumber });
  };

  return (
    <ContractContext.Provider
      value={{ ...state, setMaxVersionNumber, ...props }}
    >
      {children}
    </ContractContext.Provider>
  );
};

export default ContractProvider;

ContractProvider.defaultProps = {};

ContractProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
