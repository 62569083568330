import React from 'react';
import PropTypes from 'prop-types';
import { useCatalogCodes } from '../catalog-codes-provider';
import ToastMessage from '../../../components/Toast/toast';

const CatalogCodeMessages = ({ isModal }) => {
  const { catalogCodesMessages, removeMessageById } = useCatalogCodes();

  const closeMessage = (id) => {
    removeMessageById(id);
  };

  return (
    <>
      {!isModal &&
        catalogCodesMessages &&
        catalogCodesMessages.map((m) => {
          if (!m.showInModal) {
            return (
              <ToastMessage
                message={m.message}
                onClose={() => closeMessage(m.id)}
                type={m.type}
              />
            );
          }
          return null;
        })}

      {isModal &&
        catalogCodesMessages &&
        catalogCodesMessages.map((m) => {
          if (m.showInModal) {
            return (
              <ToastMessage
                message={m.message}
                onClose={() => closeMessage(m.id)}
                type={m.type}
              />
            );
          }
          return null;
        })}
    </>
  );
};

CatalogCodeMessages.defaultProps = {
  isModal: false,
};

CatalogCodeMessages.propTypes = {
  isModal: PropTypes.bool,
};

export default CatalogCodeMessages;
