import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Spinner, Link } from '@gsa/afp-component-library';
import {
  GET_DOCUMENT_META_RECORD_BY_ID,
  GET_SIGNED_READ_URL,
} from '../provider/queries';

const DownloadableDocumentCell = ({ documentMetadataId, docName }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [scanStatus, setScanStatus] = useState(null);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [getSignedReadURL] = useLazyQuery(GET_SIGNED_READ_URL, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setUrl(data?.generateReadSignedURL);
      setIsLoading(false);
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  const [getDocumentMetaRecord] = useLazyQuery(GET_DOCUMENT_META_RECORD_BY_ID, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data?.getDocMetadata) {
        setScanStatus(data?.getDocMetadata?.scanStatus ?? null);
        getSignedReadURL({
          variables: {
            fileKey: data.getDocMetadata.fileLocation,
            fileType: data.getDocMetadata.fileMimeType,
          },
        });
      }
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (documentMetadataId && isMounted.current === true) {
      setIsLoading(true);
      getDocumentMetaRecord({
        variables: {
          id: String(documentMetadataId),
        },
      });
    }
  }, [documentMetadataId, isMounted]);

  let content = null;
  if (isLoading) {
    content = <Spinner size="small" />;
  } else if (scanStatus === 'New') {
    content = (
      <div title="Scanning file for viruses">
        <div>{docName}</div>
        <div className="body-small-tight">Virus scanning...</div>
      </div>
    );
  } else if (scanStatus === 'Quarantined') {
    content = (
      <div>
        <div className="body-small-tight">Quarantined</div>
      </div>
    );
  } else if (scanStatus === 'Accepted' && url) {
    content = (
      <Link href={url} target="__blank" alt="Bidline attachment download">
        {docName}
      </Link>
    );
  }

  return content;
};

DownloadableDocumentCell.propTypes = {
  documentMetadataId: PropTypes.string.isRequired,
  docName: PropTypes.string.isRequired,
};

export default DownloadableDocumentCell;
