import React, { useState } from 'react';
import { Button } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const PredeterminedInput = ({ preDefinedValue }) => {
  const [expanded, setExpanded] = useState(false);

  const iconName = expanded ? 'expand_less' : 'expand_more';

  const listClass = classnames('grid-row margin-top-2', 'expandable-list', {
    'expand-predetermined': !!expanded,
  });

  return (
    <div className="grid-row">
      <Button
        variant="unstyled"
        onClick={() => setExpanded(!expanded)}
        label="Predetermined value"
        rightIcon={{
          name: iconName,
        }}
      />
      <div className={listClass}>
        {preDefinedValue?.value?.map((v) => (
          <div key={v}>{v}</div>
        ))}
      </div>
    </div>
  );
};

PredeterminedInput.propTypes = {
  preDefinedValue: PropTypes.shape(Object).isRequired,
};

export default PredeterminedInput;
