/* eslint-disable import/prefer-default-export */
import { keyBy } from 'lodash';

export const MAKE_MODEL_STATUS_MAP = {
  PendingReview: 0,
  Approved: 1,
  Rejected: 2,
  GSAReview: 3,
  Archived: 4,
};

export const ModelMakeStatuses = keyBy(
  [
    {
      label: 'Approved',
      value: 'Approved',
    },
    {
      label: 'Rejected',
      value: 'Rejected',
    },
    {
      label: 'Pending review',
      value: 'PendingReview',
    },
    {
      label: 'Archived',
      value: 'Archived',
    },
  ],
  'value',
);

export const MAKE_MODEL_APPROVAL_STATUS_MAP = {
  PendingReview: 0,
  Approved: 1,
  Rejected: 2,
  GSAReview: 3,
  Archived: 4,
};

export const ModelMakeApprovalStatuses = keyBy(
  [
    {
      label: 'Approved',
      value: 'Approved',
    },
    {
      label: 'Rejected',
      value: 'Rejected',
    },
    {
      label: 'Pending review',
      value: 'PendingReview',
    },
    {
      label: 'Archived',
      value: 'Archived',
    },
  ],
  'value',
);
