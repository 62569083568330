/* eslint-disable react/prop-types */
import React from 'react';
import { useCommentsAdmin } from './comments-admin-provider';

const Message = ({ total, noted, approved, disapproved, invalid }) => {
  return (
    <>
      <div>
        {`${total} ${
          total > 1 ? 'comments' : 'comment'
        } and their replies will be archived. All review states,
      comments, and replies will no longer be editable.`}
      </div>
      <div>
        <ul>
          <li>Noted: {noted}</li>
          <li>Approved: {approved}</li>
          <li>Disapproved: {disapproved}</li>
          <li>Invalid: {invalid}</li>
        </ul>
      </div>
    </>
  );
};

const ArchiveComments = ({ formId }) => {
  const {
    setData,
    selectedCommentingPeriod,
    archiveCommentingPeriod,
  } = useCommentsAdmin();
  const handleFormSubmit = () => {
    archiveCommentingPeriod({
      variables: { commentingPeriodId: selectedCommentingPeriod.id },
    });
    setData('SET_MODAL_MODE', null);
  };

  const {
    noted,
    approved,
    disapproved,
    invalid,
  } = selectedCommentingPeriod.statusDetail;

  return (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault();
        handleFormSubmit();
      }}
    >
      <div>
        <Message
          total={noted + approved + disapproved + invalid}
          noted={noted}
          approved={approved}
          disapproved={disapproved}
          invalid={invalid}
        />
      </div>
    </form>
  );
};

export default ArchiveComments;
