import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useEquipmentDetailCodes } from './equipment-code-detail-provider';
import { formSchema } from './equipment-code-association-form-elements/equipment-code-association-form-schema';
import AssociationForm from './equipment-code-association-form';

const AssociationEdit = () => {
  const {
    equipmentAssociation,
    updateEquipmentAssociation,
  } = useEquipmentDetailCodes();

  const { errors, control, handleSubmit, register, setValue } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onBlur',
  });

  const determineUpdatableFields = (rawFormData) => {
    const typeCode = rawFormData?.associationTypeCode;
    const inputTypeCode = rawFormData?.inputTypeCode;
    if (typeCode === 'O') {
      return {
        associationText: null,
        inputTypeCode: null,
        lowerBound: null,
        upperBound: null,
        unitCode: null,
        preDefinedValue: null,
        associationTypeCode: typeCode,
      };
    }
    // Numerical input
    if (inputTypeCode === 'N') {
      return {
        associationText: rawFormData?.associationText,
        inputTypeCode,
        lowerBound: rawFormData?.lowerBound,
        upperBound: null,
        unitCode: rawFormData?.unitCode,
        criteriaCode: rawFormData?.criteriaCode,
        preDefinedValue: null,
        associationTypeCode: typeCode,
      };
    }
    // Range input
    if (inputTypeCode === 'R') {
      return {
        associationText: rawFormData?.associationText,
        inputTypeCode,
        lowerBound: rawFormData?.lowerBound,
        upperBound: rawFormData?.upperBound,
        unitCode: rawFormData?.unitCode,
        criteriaCode: null,
        preDefinedValue: null,
        associationTypeCode: typeCode,
      };
    }
    // Predefined input
    if (inputTypeCode === 'P') {
      return {
        associationText: rawFormData?.associationText,
        inputTypeCode,
        lowerBound: null,
        upperBound: null,
        unitCode: null,
        criteriaCode: null,
        preDefinedValue: rawFormData?.preDefinedValue,
        associationTypeCode: typeCode,
      };
    }
    // Comply or Free text
    if (inputTypeCode === 'C' || inputTypeCode === 'F') {
      return {
        associationText: rawFormData?.associationText,
        inputTypeCode,
        lowerBound: null,
        upperBound: null,
        unitCode: null,
        criteriaCode: null,
        preDefinedValue: null,
        associationTypeCode: typeCode,
      };
    }
    return {
      associationText: rawFormData?.associationText,
      inputTypeCode,
      lowerBound: null,
      upperBound: null,
      unitCode: null,
      preDefinedValue: null,
      associationTypeCode: typeCode,
    };
  };

  const onSubmit = (data) => {
    const { id, standardItemId, equipmentCodeId } = equipmentAssociation;
    const updatableFields = determineUpdatableFields(data);
    updateEquipmentAssociation({
      variables: {
        id: Number(id),
        equipmentAssociation: {
          standardItemId,
          equipmentCodeId,
          ...updatableFields,
        },
      },
    });
  };

  return (
    <form id="association-edit-form" onSubmit={handleSubmit(onSubmit)}>
      <AssociationForm
        control={control}
        errors={errors}
        setValue={setValue}
        register={register}
        updateStandardItemId={() => {}}
        equipmentCodeId="0"
      />
    </form>
  );
};

export default AssociationEdit;
