export const PREBID_SUMMARY_DESCRIPTION_TEXT =
  'The <b>Pre-Bid Summary</b> page provides a centralized location to manage your pre-bid questionnaire submissions.<br>From here you can: <ul> <li>Add or edit your current Standard Item selection</li><li>Review the list of Standard Items populated from the pre-bid questionnaires you completed</li><li>Navigate to a specific Standard Item page listed below</li><li>Identify what Standard Item numbers still require your attention</li><li>Number of Make and Models you have provided for each Standard Item number</li></ul>';

export const PRE_BID_SUMMARY_LABELS = {
  VIEW_DETAILS: 'View Details',
  VIEW_MAKE_MODEL: 'View Make and Model',
  STANDARD_ITEM: 'Standard Item',
  STATUS: 'Status',
  MAKE_MODEL_COUNT: 'Make and Model Count',
  ACTIONS: 'Actions',
};

export const MODAL_MODES = {
  ADD: 'add',
  EDIT: 'edit',
  CONFIRM_DELETE: 'confirm_delete',
  VIEW_MAKE_MODEL: 'veiw_make_model',
};
