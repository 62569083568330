/* eslint-disable react/prop-types */
import React from 'react';
import { AFPTableRowAction } from '@gsa/afp-component-library';
import { ReadyIcon } from '../components/tags';
import { COLOR_TYPE } from './helpers';
import { enDashUnicode } from '../../../../utilities/constants';
import PhotoCell from '../documentation/photo-cell';
import DownloadableDocumentCell from '../documentation/downloadable-doc-cell';
import ImageOrderUpDown from '../documentation/image-order-up-down';
import { useBidLineDetails } from './bid-line-details-provider';

export const DELETE_ACTION = 'Delete';

export const MARK_REP_IMG_ACTION = 'Mark as the representative image in Store';

const colorsActions = [
  {
    icon: 'cancel',
    label: DELETE_ACTION,
  },
];

export const getColorsReadOnlyColumns = (gsaColors) => [
  {
    Header: 'Vendor color name',
    accessor: 'vendorColorName',
    sortable: false,
  },
  {
    Header: 'Vendor color code',
    accessor: 'vendorColorCode',
    sortable: false,
  },
  {
    Header: 'GSA color',
    accessor: 'gsaColorCodeId',
    sortable: false,
    Cell: ({ value }) =>
      gsaColors.find((c) => +c.id === value)?.title || enDashUnicode,
  },
  {
    Header: 'Standard or optional',
    accessor: 'standardPremiumCode',
    sortable: false,
    Cell: ({ value }) => {
      if (value === COLOR_TYPE.standard) return 'Standard';
      if (value === COLOR_TYPE.optional) return 'Optional';
      return enDashUnicode;
    },
  },
  {
    Header: 'Price',
    accessor: 'price',
    sortable: false,
    cellClassName: 'cell-right',
    Cell: ({ value }) =>
      (value || 0).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
  },
];

export const getColorColumns = (onActionClick, gsaColors) => [
  {
    Header: 'Vendor color name',
    accessor: 'vendorColorName',
    sortable: false,
    Cell: ({ value, row }) => {
      const { findHighlight } = useBidLineDetails();
      const highlight = findHighlight(
        'color',
        'bid_line_color',
        row?.original?.id,
        'vendorColorName',
      );
      const isPriceHighlighted = !!highlight;

      return (
        <span
          className={
            isPriceHighlighted ? 'bid-line-highlighted-input-data' : ''
          }
          style={isPriceHighlighted ? { padding: 4 } : {}}
        >
          {value}
        </span>
      );
    },
  },
  {
    Header: 'Vendor color code',
    accessor: 'vendorColorCode',
    sortable: false,
    Cell: ({ value, row }) => {
      const { findHighlight } = useBidLineDetails();
      const highlight = findHighlight(
        'color',
        'bid_line_color',
        row?.original?.id,
        'vendorColorCode',
      );
      const isPriceHighlighted = !!highlight;

      return (
        <span
          className={
            isPriceHighlighted ? 'bid-line-highlighted-input-data' : ''
          }
          style={isPriceHighlighted ? { padding: 4 } : {}}
        >
          {value}
        </span>
      );
    },
  },
  {
    Header: 'GSA color',
    accessor: 'gsaColorCodeId',
    sortable: false,
    Cell: ({ value, row }) => {
      const label =
        gsaColors.find((c) => +c.id === value)?.title || enDashUnicode;
      const { findHighlight } = useBidLineDetails();
      const highlight = findHighlight(
        'color',
        'bid_line_color',
        row?.original?.id,
        'gsaColorCodeId',
      );
      const isPriceHighlighted = !!highlight;

      return (
        <span
          className={
            isPriceHighlighted ? 'bid-line-highlighted-input-data' : ''
          }
          style={isPriceHighlighted ? { padding: 4 } : {}}
        >
          {label}
        </span>
      );
    },
  },
  {
    Header: 'Standard or optional',
    accessor: 'standardPremiumCode',
    sortable: false,
    Cell: ({ value, row }) => {
      const { findHighlight } = useBidLineDetails();
      const highlight = findHighlight(
        'color',
        'bid_line_color',
        row?.original?.id,
        'standardPremiumCode',
      );
      const isPriceHighlighted = !!highlight;
      let label = enDashUnicode;
      if (value === COLOR_TYPE.standard) label = 'Standard';
      if (value === COLOR_TYPE.optional) label = 'Optional';
      return (
        <span
          className={
            isPriceHighlighted ? 'bid-line-highlighted-input-data' : ''
          }
          style={isPriceHighlighted ? { padding: 4 } : {}}
        >
          {label}
        </span>
      );
    },
  },
  {
    Header: 'Price',
    accessor: 'price',
    sortable: false,
    cellClassName: 'cell-right',
    Cell: ({ value, row }) => {
      const { findHighlight } = useBidLineDetails();
      const highlight = findHighlight(
        'color',
        'bid_line_color',
        row?.original?.id,
        'price',
      );
      const isPriceHighlighted = !!highlight;

      return (
        <span
          className={
            isPriceHighlighted ? 'bid-line-highlighted-input-data' : ''
          }
          style={isPriceHighlighted ? { padding: 4 } : {}}
        >
          {(value || 0).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </span>
      );
    },
  },
  {
    Header: 'Actions',
    sortable: false,
    Cell: ({ row }) => {
      return (
        <AFPTableRowAction
          row={row}
          actions={colorsActions}
          onSelectAction={(action) => onActionClick(action, row)}
        />
      );
    },
  },
];

const documentActions = [
  {
    icon: 'cancel',
    label: DELETE_ACTION,
  },
];

export const getDocumentReadOnlyColumns = () => [
  {
    Header: <span className="margin-left-4">Name</span>,
    accessor: 'documentMetadataId',
    sortable: false,
    Cell: ({ value, row }) => {
      return (
        <DownloadableDocumentCell
          documentMetadataId={value}
          docName={row?.original?.name}
        />
      );
    },
  },
  {
    Header: 'Description',
    accessor: 'description',
    sortable: false,
  },
];

export const getDocumentColumns = (onActionClick) => [
  {
    Header: <span className="margin-left-4">Name</span>,
    accessor: 'documentMetadataId',
    sortable: false,
    Cell: ({ value, row }) => {
      return (
        <DownloadableDocumentCell
          documentMetadataId={value}
          docName={row?.original?.name}
        />
      );
    },
  },
  {
    Header: 'Description',
    accessor: 'description',
    sortable: false,
  },
  {
    Header: 'Actions',
    sortable: false,
    Cell: ({ row }) => {
      return (
        <AFPTableRowAction
          row={row}
          actions={documentActions}
          onSelectAction={(action) => onActionClick(action, row)}
        />
      );
    },
  },
];

const photoActions = [
  ...documentActions,
  {
    icon: 'check',
    label: MARK_REP_IMG_ACTION,
  },
];

export const getPhotosReadonlyColumns = () => [
  {
    Header: 'Thumbnail',
    accessor: 'documentMetadataId',
    sortable: false,
    Cell: ({ value }) => {
      return <PhotoCell documentMetadataId={value} />;
    },
  },
  {
    Header: <span className="margin-left-4">Name</span>,
    accessor: 'name',
    sortable: false,
  },
  {
    Header: 'Representative image in Store',
    accessor: 'representativeImage',
    sortable: false,
    Cell: ({ value }) => (value === true ? <ReadyIcon isReady /> : null),
  },
  {
    Header: 'Description',
    accessor: 'description',
    sortable: false,
  },
  {
    accessor: 'orderNum',
    sortable: false,
    Cell: () => null,
  },
];

export const getPhotoColumns = (onActionClick) => [
  {
    Header: 'Thumbnail',
    accessor: 'documentMetadataId',
    sortable: false,
    Cell: ({ value }) => {
      return <PhotoCell documentMetadataId={value} />;
    },
  },
  {
    Header: <span className="margin-left-4">Name</span>,
    accessor: 'name',
    sortable: false,
  },
  {
    Header: 'Representative image in Store',
    accessor: 'representativeImage',
    sortable: false,
    Cell: ({ value }) => (value === true ? <ReadyIcon isReady /> : null),
  },
  {
    Header: 'Description',
    accessor: 'description',
    sortable: false,
  },
  {
    Header: 'Order',
    accessor: 'orderNum',
    sortable: false,
    Cell: ImageOrderUpDown,
  },
  {
    Header: 'Actions',
    sortable: false,
    Cell: ({ row, rows }) => {
      return (
        <AFPTableRowAction
          row={row}
          actions={photoActions}
          onSelectAction={(action) => onActionClick(action, row, rows)}
        />
      );
    },
  },
];
