import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SelectDropdown, Button, DatePicker } from '@gsa/afp-component-library';
import classNames from 'classnames';
import { getFilterOptions } from './contract-header-history-helpers';

export const DEFAULT_FILTERS = {
  versonNumber: '',
  fieldName: '',
  changedBy: '',
  changedAtFrom: '',
  changedAtTo: '',
};

const ContractHeaderHistoryFilters = ({
  allVersions,
  filters,
  onFilterChange,
  resetFilters,
}) => {
  const [isHidden, setIsHidden] = useState(false);

  const getSingleSelect = (field, label) => {
    const options = getFilterOptions(allVersions, field);
    return (
      <div className="grid-col contract-line-listing-search">
        <SelectDropdown
          data-testid={`${field}-filter`}
          label={label}
          value={filters[field]}
          options={options}
          onChange={({ target: { value } }) => onFilterChange(field, value)}
        />
      </div>
    );
  };

  const getDatePicker = (field, label) => {
    return (
      <div className="grid-col contract-line-listing-search">
        <DatePicker
          data-testid={`${field}-filter`}
          aria-label={label}
          label={label}
          onChange={(value) => onFilterChange(field, value)}
          value={filters[field]}
        />
      </div>
    );
  };

  return (
    <div className="contract-line-listing-search-block padding-2 bg-primary-lighter">
      <div className="display-flex flex-row flex-justify">
        <div className="contract-line-listing-search-header">Filters</div>
        <Button
          label={isHidden ? 'Show filters' : 'Hide filters'}
          variant="outline"
          size="small"
          onClick={() => {
            setIsHidden(!isHidden);
          }}
          leftIcon={{ name: 'filter_alt' }}
        />
      </div>
      <div
        className={classNames('contract-line-listing-search-content', {
          hidden: isHidden,
        })}
        aria-hidden={isHidden}
      >
        <div className="contract-line-listing-search-description display-flex flex-row flex-justify">
          <div>Apply at least one filter to see your results.</div>
          <div className="contract-line-listing-search-reset">
            <Button
              variant="unstyled"
              label="Reset all filters"
              onClick={resetFilters}
              leftIcon={{ name: 'close' }}
            />
          </div>
        </div>
        <div className="grid-row grid-gap">
          {getSingleSelect('versionNumber', 'Version')}
          {getSingleSelect('fieldName', 'Field name')}
          {getSingleSelect('changedBy', 'Changed by')}
          {getDatePicker('changedAtFrom', 'Changed date from')}
          {getDatePicker('changedAtTo', 'Changed date to')}
        </div>
      </div>
    </div>
  );
};

ContractHeaderHistoryFilters.propTypes = {
  allVersions: PropTypes.arrayOf(Object).isRequired,
  filters: PropTypes.shape(Object).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
};

export default ContractHeaderHistoryFilters;
