import { gql } from '@apollo/client';

export const GET_CATALOG_CODES = gql`
  query GetStandardCodes(
    $filters: FilterType
    $virtualFilters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getStandardCodes(
      filters: $filters
      virtualFilters: $virtualFilters
      order: $order
      offset: $offset
      limit: $limit
    ) {
      rows {
        id
        code
        title
        tags
        parentCategory
        parentCode
        additionalProps
        description
        status
        requestStatus
        metadata {
          id
          category
          description
          parentCategory
          categoryOfTags
          requiresApproval
          allowNewEntries
          propertiesSchema
        }
        standardsVehicleGroupPocs {
          name
          pocType
          email
          phoneNumber
        }
      }
      count
      hasMore
    }
  }
`;

export const ADD_CATALOG_CODE = gql`
  mutation AddCatalogCode($catalogcode: CatalogCodeInput!) {
    addCatalogCode(catalogcode: $catalogcode) {
      id
      code
      title
      tags
      parentCategory
      parentCode
      additionalProps
      description
      status
      requestStatus
      metadata {
        id
        category
        description
        parentCategory
        categoryOfTags
        requiresApproval
        allowNewEntries
        propertiesSchema
      }
    }
  }
`;

export const DELETE_CATALOG_CODE = gql`
  mutation deleteCatalogCode($id: String!) {
    deleteCatalogCode(id: $id) {
      id
      code
    }
  }
`;

export const UPDATE_CATALOG_CODE = gql`
  mutation UpdateCatalogCode($id: Float!, $catalogCode: CatalogCodeInput!) {
    updateCatalogCode(id: $id, catalogcode: $catalogCode) {
      id
      code
      title
      tags
      parentCategory
      parentCode
      additionalProps
      description
      status
      requestStatus
      metadata {
        id
        category
        description
        parentCategory
        categoryOfTags
        requiresApproval
        allowNewEntries
        propertiesSchema
      }
    }
  }
`;

export const GET_CHANGE_REQUEST_HISTORY = gql`
  query GetStandardCodesChangeRequest(
    $filters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getStandardCodesChangeRequest(
      filters: $filters
      order: $order
      offset: $offset
      limit: $limit
    ) {
      rows {
        id
        approvalStatus
        changeType
        differences
        createdByUser
        approvedByUser
        approvedAt
        createdAt
        standardsCode {
          id
          status
        }
        requester {
          fullName
        }
        approver {
          fullName
        }
      }
      count
      hasMore
    }
  }
`;

export const CHANGE_APPROVAL_STATUS = gql`
  mutation ChangeApprovalStatus($id: Float!, $approval: Float!) {
    changeApprovalStatus(id: $id, approval: $approval) {
      id
      code
      approvalStatus
      title
      changeType
    }
  }
`;

export const GET_CODES_METADATA = gql`
  query GetMetadata(
    $offset: Int
    $limit: Int
    $order: OrderBy
    $filters: FilterType
  ) {
    getCatalogCodesMetadata(
      offset: $offset
      limit: $limit
      order: $order
      filters: $filters
    ) {
      rows {
        id
        category
        description
        parentCategory
        categoryOfTags
        propertiesSchema
        requiresApproval
      }
    }
  }
`;
