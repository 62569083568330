import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FileUpload,
  RequiredFieldIndicator,
  FlexView,
  Alert,
  TextInput,
  Checkbox,
} from '@gsa/afp-component-library';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { ADD_DOCUMENT_TO_CONTRACTLINE } from './query';

const AddDocForm = ({ contractLineId, onClose, onSuccess, docType }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    errors,
    setError: setFormError,
    clearErrors,
  } = useForm();
  const [error, setError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const allowedDocTypes = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/rtf',
    'text/plain',
  ];
  const allowedImageTypes = [
    'image/jpeg',
    'image/png',
    'image/jpg',
    'image/bmp',
  ];

  const isDocument = docType === 'D';

  const [getSignedUrl] = useMutation(ADD_DOCUMENT_TO_CONTRACTLINE, {
    onError: (e) => {
      setError(e.message);
      setIsUploading(false);
    },
    onCompleted: async (data) => {
      try {
        const res = await fetch(data?.addDocumentToContractLine?.signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': selectedFile.type,
          },
          body: selectedFile,
        });

        if (res.ok) {
          const docName = selectedFile.name;
          const newDoc = {
            id: data?.addDocumentToContractLine?.id,
            name: docName,
            description: data?.addDocumentToContractLine?.description,
            size: selectedFile.size,
            fileMimeType: selectedFile.type,
            representativeImage:
              data?.addDocumentToContractLine?.sequenceNumber === 1,
            sequenceNumber: data?.addDocumentToContractLine?.sequenceNumber,
          };
          setIsUploading(false);
          onSuccess(newDoc);
          return;
        }
        setError('There was an error uploading your file. Please try again.');
        setIsUploading(false);
      } catch (e) {
        setError(e.message);
        setIsUploading(false);
      }
    },
  });

  const onSubmit = useCallback(
    async (formValues) => {
      if (!selectedFile) {
        setFormError('supportingDocument', {
          message: 'File is required.',
        });
        return;
      }

      // Check if the file type is acceptable
      const isFileTypeValid = isDocument
        ? allowedDocTypes.includes(selectedFile.type)
        : allowedImageTypes.includes(selectedFile.type);

      if (!isFileTypeValid) return;

      clearErrors(null);
      setError(null);
      setIsUploading(true);

      try {
        const { data } = await getSignedUrl({
          variables: {
            addDocumentToContractLine: {
              contractLineId: parseInt(contractLineId, 10),
              documentType: docType,
              description: formValues.description,
              representativeImage: formValues.representativeImage,
              name: formValues.supportingDocument,
              size: selectedFile.size,
              fileMimeType: selectedFile.type,
            },
          },
        });

        // const data = response.data;
        const res = await fetch(data?.addDocumentToContractLine?.signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': selectedFile.type,
          },
          body: selectedFile,
        });

        if (res.ok) {
          const docName = selectedFile.name;
          const newDoc = {
            id: data?.addDocumentToContractLine?.id,
            name: docName,
            description: data?.addDocumentToContractLine?.description,
            size: selectedFile.size,
            fileMimeType: selectedFile.type,
            representativeImage:
              data?.addDocumentToContractLine?.sequenceNumber === 1,
            sequenceNumber: data?.addDocumentToContractLine?.sequenceNumber,
          };
          setIsUploading(false);
          onSuccess(newDoc);
          return;
        }
        setError('There was an error uploading your file. Please try again.');
        setIsUploading(false);
      } catch (catchError) {
        setError(catchError.message);
        setIsUploading(false);
      }
    },
    [
      selectedFile,
      contractLineId,
      docType,
      onSuccess,
      clearErrors,
      setFormError,
      setError,
      getSignedUrl,
    ],
  );

  return (
    <form
      id="contractline-doc-attachment-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="contractline-doc-attachment-form-fields">
        <div className="title-l">
          {isDocument ? 'Add Documents' : 'Add Photos'}
        </div>
        <div>
          Required fields are marked with an asterisk (
          <RequiredFieldIndicator />
          ).
        </div>
        {error && <Alert type="error">{error}</Alert>}
        <Controller
          control={control}
          name="supportingDocument"
          defaultValue=""
          render={({ name, value }) => {
            return (
              <FileUpload
                required
                label={isDocument ? 'Supporting file' : 'Supporting photo'}
                acceptableFiles={
                  isDocument ? allowedDocTypes : allowedImageTypes
                }
                acceptableFilesLabel={
                  isDocument
                    ? 'Accepted file types:  .doc/.docx, .csv, .xls/.xlsx, .ppt/.pptx, .txt/.rtf, .pdf.'
                    : 'Accepted file types: .jpeg, .jpg, .png, .bmp.'
                }
                fileSizeLimit={10} // MB
                onChange={(file) => {
                  setSelectedFile(file);
                  setValue(name, file?.name);
                }}
                defaultValue={value ? { name: value } : null}
                errorMessage={errors.supportingDocument?.message}
              />
            );
          }}
        />
        {docType === 'I' && (
          <div className="usa-form-group margin-top-8 margin-bottom-4">
            <Controller
              control={control}
              name="representativeImage"
              defaultValue={false}
              render={({ name, value }) => {
                return (
                  <>
                    <Checkbox
                      label="Mark as the representative image in Store."
                      name={name}
                      checked={value}
                      onChange={() => {
                        setValue(name, !getValues(name));
                      }}
                    />
                  </>
                );
              }}
            />
          </div>
        )}
        <Controller
          control={control}
          name="description"
          defaultValue=""
          render={({ name, value, onChange, onBlur }) => {
            return (
              <TextInput
                className="margin-0"
                name={name}
                data-testid="attachment-file-description"
                defaultValue={value}
                label={<span className="text-bold">Description</span>}
                help="A few words to help identify this document"
                type="textarea"
                characterLimit={500}
                onChange={onChange}
                onBlur={onBlur}
                aria-describedby="attachment-textarea-info attachment-textarea-hint"
              />
            );
          }}
        />
      </div>
      <FlexView className="actions bg-base-lightest">
        <Button
          data-testid="attachment-add-cancel-button"
          disabled={false}
          type="button"
          variant="unstyled"
          className="margin-right-2"
          onClick={onClose}
          label="Cancel"
        />
        <Button
          disabled={isUploading}
          type="submit"
          form="contractline-doc-attachment-form"
          className="add-document-button action-button margin-left-1"
          label={isUploading ? 'Saving...' : 'Save'}
        />
      </FlexView>
    </form>
  );
};

AddDocForm.defaultProps = {};

AddDocForm.propTypes = {
  contractLineId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  docType: PropTypes.oneOf(['D', 'I']).isRequired,
};

export default AddDocForm;
