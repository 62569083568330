/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { AFPTable, StatusBadge, Button } from '@gsa/afp-component-library';
import moment from 'moment';

const CommentingPeriodInfo = ({ data, handleArchiveCommentingPeriod }) => {
  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Federal Vehicle Standard',
        accessor: 'fvsTitle',
        sortable: false,
        Cell: ({ value, row: { original } }) => {
          return `${value} (${original.fvsCode})`;
        },
      },
      {
        Header: 'Type',
        accessor: 'userType',
        sortable: false,
        Cell: ({ value }) => {
          return value === 'VehicleSupplier' ? 'Vehicle Supplier' : value;
        },
      },
      {
        Header: 'Starts',
        accessor: 'periodStart',
        sortable: false,
        Cell: ({ value }) => {
          return moment(value).format('L');
        },
      },
      {
        Header: 'Ends',
        accessor: 'periodEnd',
        sortable: false,
        Cell: ({ value }) => {
          return moment(value).format('L');
        },
      },
      {
        Header: 'Days left',
        accessor: 'daysLeft',
        sortable: false,
      },
      {
        Header: 'State',
        accessor: 'status',
        sortable: false,
        Cell: ({ value }) => {
          return (
            <StatusBadge
              variant={value === 'Open' ? 'Ready-White' : 'Inactive-White'}
            >
              {value}
            </StatusBadge>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'resolveStatus',
        sortable: false,
        Cell: ({ row }) => {
          if (row.original.statusDetail?.unResolvedCount === 0) {
            return 'All comments resolved';
          }
          return `${row.original.statusDetail.unResolvedCount} unresolved comments`;
        },
      },
      {
        Header: 'Actions',
        sortable: false,
        Cell: ({ row }) => {
          if (
            row?.original?.statusDetail?.isResolved &&
            row?.original?.status === 'Closed'
          ) {
            return (
              <div>
                <Button
                  onClick={() => handleArchiveCommentingPeriod(row.original)}
                  variant="outline"
                  label="Archive"
                  className="width-15"
                />
              </div>
            );
          }
          return '-';
        },
      },
    ];

    return columnList;
  }, []);
  return (
    <>
      <div className="desktop:grid-col-12 bg-gray-3 width-full padding-3 commenting-period-info">
        <span className="text-primary margin-bottom-0 commenting-period-info-header">
          Active commenting periods
        </span>
        <AFPTable
          fullWidth
          testId="commenting-periods-table"
          columns={columns}
          data={data}
          className="commenting-period-info-table"
        />
      </div>
    </>
  );
};

export default CommentingPeriodInfo;
