import { isEmpty, omit } from 'lodash';

export const getFormattedTags = (data) => {
  let tags = null;
  if (!isEmpty(data?.tags)) {
    tags = { value: [] };
    tags.value = data.tags;
  }
  return tags;
};

export const getAdditionalProps = (catalogCodeCategory, allowNewBids, data) => {
  let additionalProps = null;

  if (catalogCodeCategory === 'Equipment Category') {
    const { sequence, selectionType } = data;
    additionalProps = { sequence: Number(sequence), selectionType };
  }

  if (catalogCodeCategory === 'Fuel Type') {
    const { fastCode, shortHand } = data;
    additionalProps = {
      fastCode,
      shortHand,
      allowNewBids: allowNewBids === 'Y',
    };
  }
  return additionalProps;
};

export const removeCustomSchemaFields = (data) => {
  return omit(data, [
    'sequence',
    'selectionType',
    'fastCode',
    'shortHand',
    'allowNewBids',
  ]);
};
