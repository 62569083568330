import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  GET_PRE_BID_MAKE_MODEL_DATA,
  GET_VEHICLES_STANDARDS,
} from '../fvs.gql';
import { useFederalStandards } from '../fvs-provider';

export const FvsPreBidSelectionContext = createContext({});

const initialState = {
  selectedFedStandardCodes: [],
  selectedVehicleGroup: '',
  selectedFedStandards: [],
  includesOpenPeriods: false,
  selectedYearForPBR: null,
  standardItemsForReporting: [],
  preBidSelectionsData: {
    rows: [],
    hasMore: false,
    count: 0,
  },
};

const FvsPreBidSelectionReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PRE_BID_SELECTIONS_DATA': {
      return { ...state, preBidSelectionsData: action.payload };
    }
    case 'SET_INCLUDE_OPEN_PERIODS': {
      return { ...state, includesOpenPeriods: action.payload };
    }
    case 'SET_SELECTED_STANDARDS': {
      return { ...state, selectedFedStandards: action.payload };
    }
    case 'SET_SELECTED_CODES': {
      return { ...state, selectedFedStandardCodes: action.payload };
    }
    case 'SET_FILTERED_SI_LIST': {
      return { ...state, standardItemsForReporting: action.payload };
    }
    case 'SET_VEHICLE_GROUP_REPORTING': {
      return { ...state, selectedVehicleGroup: action.payload };
    }
    case 'SET_PBR_SELECTED_YEAR': {
      return { ...state, selectedYearForPBR: action.payload };
    }
    default: {
      return state;
    }
  }
};

function FvsPreBidSelectionProvider({ children, ...props }) {
  const { setFvsError, selectedYear, selectedOptions } = useFederalStandards();

  const [state, dispatch] = useReducer(
    FvsPreBidSelectionReducer,
    initialState,
    () => {
      return {
        ...initialState,
        selectedFedStandardCodes: selectedOptions?.fedStandardCode
          ? [selectedOptions?.fedStandardCode]
          : [],
        selectedYearForPBR: selectedYear,
      };
    },
  );

  const setFVSPreBidReportContextData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const [
    getPreBidMakeModelData,
    { loading: isPreBidSelectionLoading },
  ] = useLazyQuery(GET_PRE_BID_MAKE_MODEL_DATA, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (requestError) => {
      setFvsError(requestError);
    },
    onCompleted: (responseData) => {
      // if the (preBidMake) id of a row is negative, it means the preBidSelection
      // object included is not linked to a preBidMakeModel object
      if (responseData?.getPreBidMakeModelData) {
        setFVSPreBidReportContextData(
          'SET_PRE_BID_SELECTIONS_DATA',
          responseData.getPreBidMakeModelData,
        );
      }
    },
  });

  const [getMultipleVehicleStandards] = useLazyQuery(GET_VEHICLES_STANDARDS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (requestError) => {
      setFvsError(requestError);
    },
    onCompleted: (responseData) => {
      if (responseData?.getFederalStandards) {
        setFVSPreBidReportContextData(
          'SET_SELECTED_STANDARDS',
          responseData?.getFederalStandards?.rows,
        );
      }
    },
  });

  useEffect(() => {
    let flag = false;
    if (state?.selectedFedStandards && state?.selectedFedStandards.length > 0) {
      state.selectedFedStandards.forEach((s) => {
        if (s?.status === 'AllCommentOpen' || s?.status === 'PsoCommentOpen') {
          flag = true;
        }
      });
    }
    setFVSPreBidReportContextData('SET_INCLUDE_OPEN_PERIODS', flag);
  }, [state.selectedFedStandards]);

  useEffect(() => {
    setFVSPreBidReportContextData('SET_INCLUDE_OPEN_PERIODS', false);
    if (
      state?.selectedFedStandardCodes &&
      state?.selectedFedStandardCodes.length > 0
    ) {
      getMultipleVehicleStandards({
        variables: {
          filters: {
            operator: 'AND',
            value: [
              {
                operator: 'IN',
                key: 'fedStandardCode',
                value: state.selectedFedStandardCodes,
              },
              {
                operator: 'EQ',
                key: 'year',
                value: state.selectedYearForPBR,
              },
            ],
          },
          limit: 100,
          offset: 0,
          order: 'fedStandardCode ASC',
        },
      });
    }
  }, [state.selectedFedStandardCodes, state.selectedYearForPBR]);

  return (
    <FvsPreBidSelectionContext.Provider
      value={{
        ...state,
        setFVSPreBidReportContextData,
        getPreBidMakeModelData,
        isPreBidSelectionLoading,
        ...props,
      }}
    >
      {children}
    </FvsPreBidSelectionContext.Provider>
  );
}

FvsPreBidSelectionProvider.defaultProps = {};

FvsPreBidSelectionProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default FvsPreBidSelectionProvider;

export const useFvsPreBidSelectionsReport = () =>
  useContext(FvsPreBidSelectionContext);
