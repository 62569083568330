/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  AFPTable,
  Spinner,
  EmptyState,
  useModal,
  StatusBadge,
} from '@gsa/afp-component-library';
import OverlaySpinner from '../../../components/overlay-spinner';
import getColumns from './columns';
import RemoveConfirmationModal from './remove-confirmation-modal';
import CopyLineItemModal from '../bid-line-details/copy-line-item/copy-line-item-modal';
import EditBidLineItemModalWrapper from './edit-bid-line-item/index';
import {
  bidDashboardActiveTabAtom,
  bidsDashboardMessageAtom,
  lineItemTableSelectedItems,
  lineItemsAtom,
} from '../../bids/atoms/bids-atoms';
import { TAB_KEY } from '../bid-line-details/copy-line-item/helpers';
import {
  DOWNLOAD_LINE_ITEM,
  getDownloadAlert,
} from '../../bids/bid-reports/bid-report-helpers';
import TabStatuses from './tab-statuses';
import { getMakeModelStatusBadge, getApprovalStatus } from '../helpers';
import { emDashUnicode } from '../../../utilities/constants';

const LineItemsTable = ({ tableProps, refetchData, isBidClosed }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const {
    isOpen: isRemoveOpen,
    openModal: openRemoveModal,
    closeModal: closeRemoveModal,
  } = useModal();
  const [, setTabIndex] = useRecoilState(bidDashboardActiveTabAtom);
  const [, setToaster] = useRecoilState(bidsDashboardMessageAtom);
  const history = useHistory();
  const {
    isOpen: isEditOpen,
    openModal: openEditModal,
    closeModal: closeEditModal,
  } = useModal();
  const {
    isOpen: isCopyOpen,
    openModal: openCopyModal,
    closeModal: closeCopyModal,
  } = useModal();

  // Query to generate pdf report
  const [downloadLineItem, { loading: loadingJSReport }] = useLazyQuery(
    DOWNLOAD_LINE_ITEM,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const { content, contentType, fileExtension } = data.downloadLineItem;
        setToaster(
          getDownloadAlert(
            'Line item report',
            content.data,
            contentType,
            `Line Item ${selectedItem.scheduleLine} Report.${fileExtension}`,
          ),
        );
      },
    },
  );

  const onDownload = (bidLineId) => {
    setToaster(null);
    downloadLineItem({ variables: { bidLineId: +bidLineId } });
  };

  const { isPending } = tableProps || {};

  const [items] = useRecoilState(lineItemsAtom);
  const setSelectedItems = useSetRecoilState(lineItemTableSelectedItems);

  const lineItemData = items?.filter(
    (lineItem) => lineItem?.scheduleLine !== null,
  );
  const selectDisabledRows = useMemo(() => {
    const disabledRows = [];
    lineItemData.forEach((item, index) => {
      if (item.status !== 'Ready to submit') {
        disabledRows.push(index);
      }
    });
    return disabledRows;
  }, [lineItemData]);

  const isNoData = !lineItemData?.length;

  const onActionClick = (action, original) => {
    switch (action) {
      case 'Bid line details':
        history.push(`./${original.bidId}/bid-line-details/${original.id}`);
        break;
      case 'Edit line item':
        setEditItem(original);
        openEditModal();
        break;
      case 'Remove from bid':
        setSelectedItem(original);
        openRemoveModal();
        break;
      case 'Download line item':
        setSelectedItem(original);
        onDownload(original.id);
        break;
      default:
        // 'Copy line item'
        setEditItem(original);
        openCopyModal();
        break;
    }
  };

  const columns = useMemo(() => {
    return getColumns(onActionClick, isBidClosed);
  }, [onActionClick, isBidClosed]);

  const RowSubComponent = ({ row: { original = {} } }) => {
    const status = getApprovalStatus(original);
    const badge = getMakeModelStatusBadge(status);

    return (
      <section className="grid-row grid-gap padding-left-5">
        <section className="grid-col-4">
          <h3 className="text-primary margin-top-0">BID STATUS</h3>
          <TabStatuses
            original={original}
            isBidClosed={isBidClosed}
            refetchData={refetchData}
          />
        </section>
        <section className="grid-col">
          <section>
            <h3 className="text-primary margin-top-0">CLASSIFICATION</h3>
            <section className="display-flex flex-column">
              <p className="border-bottom border-base-lighter margin-top-0">
                <span className="text-bold">Program:</span>
                <span className="margin-left-1">
                  {`${original?.solicitationLine?.solicitationProgram?.programInfo?.code} - ${original?.solicitationLine?.solicitationProgram?.programInfo?.title}`}
                </span>
              </p>
              <p className="border-bottom border-base-lighter margin-top-0">
                <span className="text-bold">Vehicle Group:</span>
                <span className="margin-left-1">
                  {`${original?.solicitationLine?.standardItem?.vehicleGroup?.code} - ${original?.solicitationLine?.standardItem?.vehicleGroup?.title}`}
                </span>
              </p>
              <p className="border-bottom border-base-lighter margin-top-0">
                <span className="text-bold">Vehicle Type:</span>
                <span className="margin-left-1">
                  {`${original?.solicitationLine?.standardItem?.vehicleTypeCode?.code} - ${original?.solicitationLine?.standardItem?.vehicleTypeCode?.title}`}
                </span>
              </p>
              <p className="border-bottom border-base-lighter margin-top-0">
                <span className="text-bold">Standard Item:</span>
                <span className="margin-left-1">
                  {`${original?.solicitationLine?.standardItem?.standardItemNumber} - ${original?.solicitationLine?.standardItem?.title}`}
                </span>
              </p>
            </section>
          </section>
          <section>
            <h3 className="text-primary margin-top-0">MODEL AND MAKE</h3>
            <section className="display-flex flex-column">
              <p className="border-bottom border-base-lighter margin-top-0">
                <span className="text-bold">Review status </span>
                <span className="margin-left-1">
                  {badge ? (
                    <StatusBadge variant={badge.variant}>
                      {badge.label}
                    </StatusBadge>
                  ) : (
                    emDashUnicode
                  )}
                </span>
              </p>
            </section>
          </section>
        </section>
      </section>
    );
  };

  const onLineItemRowSelect = ({ selectedFlatRows }) => {
    const ids = selectedFlatRows.map((r) => r.original.id);
    setSelectedItems(ids);
  };

  const onCloseRemoveModal = (toRefetch) => {
    closeRemoveModal();
    if (toRefetch === true && refetchData) {
      refetchData();
      setTabIndex(1);
    }
  };

  const onCloseEditModal = (toRefetch) => {
    closeEditModal();
    if (toRefetch === true && refetchData) refetchData();
  };

  return (
    <>
      <AFPTable
        {...tableProps}
        data={lineItemData}
        columns={columns}
        defaultSort="lineItem asc"
        expandable
        selectable
        onRowSelect={onLineItemRowSelect}
        selectDisabledRows={selectDisabledRows}
        renderRowSubComponent={RowSubComponent}
      />
      {loadingJSReport && <OverlaySpinner />}
      {isPending && <Spinner aria-busy="true" size="small" role="status" />}
      {isNoData && !isPending && (
        <div className="text-center margin-top-neg-2 height-full">
          <EmptyState
            hasBackground
            containerStyles="padding-y-6 height-full"
            topText={<p>No Line Item available</p>}
          />
        </div>
      )}

      {isRemoveOpen && selectedItem && (
        <RemoveConfirmationModal
          data={selectedItem}
          onClose={onCloseRemoveModal}
        />
      )}
      {isEditOpen && editItem && editItem.id && (
        <EditBidLineItemModalWrapper
          initialData={editItem}
          onClose={onCloseEditModal}
        />
      )}
      {isCopyOpen && editItem && editItem.id && (
        <CopyLineItemModal
          bidLine={editItem}
          tabKey={TAB_KEY.allTabs}
          onClose={closeCopyModal}
          setAlert={setToaster}
          onCopySuccess={refetchData}
        />
      )}
    </>
  );
};

export default LineItemsTable;
