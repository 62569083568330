// eslint-disable-next-line no-unused-vars
import React from 'react';

const getOptions = (metadataList) => {
  return metadataList.map((m) => {
    return { label: m.category, value: m.category };
  });
};

const getDefaultStatus = (catalogCodeFilter) => {
  if (catalogCodeFilter && !catalogCodeFilter?.status) {
    return [];
  }
  return catalogCodeFilter?.status || ['Active'];
};

// eslint-disable-next-line import/prefer-default-export
export const getFilterStructure = (metadataList, catalogCodeFilter) => [
  {
    key: 'category',
    title: 'Category',
    type: 'select',
    options: getOptions(metadataList),
    value: catalogCodeFilter?.category || 'Vehicle Group',
    operator: 'EQ',
    expanded: true,
    hideClear: true,
  },
  {
    key: 'code',
    title: 'Code',
    type: 'typeahead',
    value: catalogCodeFilter?.code || '',
    operator: 'EQ',
    expanded: true,
    hideClear: true,
    customFieldProps: { inputCharNum: 1 },
  },
  {
    key: 'status',
    title: 'Status',
    type: 'multiselect',
    options: [
      { label: 'Active', value: 'Active' },
      { label: 'Inactive', value: 'Inactive' },
      { label: 'Draft', value: 'Draft' },
    ],
    value: getDefaultStatus(catalogCodeFilter),
    operator: 'OR',
    expanded: true,
    hideClear: true,
  },
  {
    key: 'requestStatus',
    title: 'Request Status',
    type: 'multiselect',
    options: [{ label: 'Pending', value: 'Pending' }],
    value: catalogCodeFilter?.requestStatus || [],
    operator: 'OR',
    expanded: true,
    hideClear: true,
  },
];
