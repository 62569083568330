import React, { useEffect, useState } from 'react';
import { Alert } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import CommentItem from './comment-item';
import CommentForm from './comment-form';
import { useFvsCommentsContext } from '../fvs-comments-provider';
import { useFederalStandards } from '../fvs-provider';
import ConfirmComment from '../doc-preview/confirm-comment';

export const CommentLinkedEntityTypes = {
  StandardItem: 0,
  EquipmentCode: 1,
  Association: 2,
  Document: 3,
};

const StandardComments = ({ linkedEntityType }) => {
  const { commentList, getStandardsComments } = useFvsCommentsContext();

  const {
    vehicleStandard,
    isCommentingAllowed4Fvs4User,
    standardItem,
  } = useFederalStandards();

  const [linkedEntityId, setLinkedEntityId] = useState(null);

  useEffect(() => {
    if (vehicleStandard || standardItem) {
      const filters = {
        operator: 'AND',
        value: [
          {
            key: 'linkedEntityType',
            operator: 'EQ',
            value: linkedEntityType,
          },
        ],
      };
      // hide invalid
      filters.value.push({
        key: 'status',
        operator: 'NOTIN',
        value: [4],
      });

      if (linkedEntityType === CommentLinkedEntityTypes.Document) {
        setLinkedEntityId(Number(vehicleStandard?.id));
        filters.value.push({
          key: 'linkedEntityId',
          operator: 'EQ',
          value: vehicleStandard?.id,
        });
      } else if (linkedEntityType === CommentLinkedEntityTypes.StandardItem) {
        setLinkedEntityId(Number(standardItem?.id));
        filters.value.push({
          key: 'linkedEntityId',
          operator: 'EQ',
          value: standardItem?.id,
        });
      }
      getStandardsComments({
        variables: {
          filters,
          limit: 50, // TODO - not paginated right now
          offset: 0,
        },
      });
    }
  }, [vehicleStandard, standardItem]);

  const ownCommentList = commentList;

  return (
    <div className="grid-row padding-bottom-2 margin-top-5">
      <div className="grid-col-8">
        <ConfirmComment tokenName="standardItemCommentToken" />
        {isCommentingAllowed4Fvs4User && linkedEntityId && vehicleStandard && (
          <>
            {linkedEntityType === CommentLinkedEntityTypes.Document && (
              <p>
                Federal Standard Code Documentation comments apply to all of the
                documents associated with this Federal Standard code. If your
                comment applies to a specific document, please include the
                document name as part of your comment.
              </p>
            )}
            <CommentForm
              linkedEntityId={linkedEntityId}
              contractYear={vehicleStandard?.year}
              fvsCode={vehicleStandard?.fedStandardCode}
              linkedEntityType={linkedEntityType}
            />
          </>
        )}
        {ownCommentList?.length > 0 && (
          <div
            style={{ color: '#005EA2', fontWeight: 900 }}
            className="text-bold margin-bottom-4"
          >
            COMMENTS
          </div>
        )}
        {commentList.length > 0 ? (
          <div className="padding-bottom-3">
            <ul className="add-list-reset">
              {commentList.map((comment) => {
                return <CommentItem key={comment.id} comment={comment} />;
              })}
            </ul>
          </div>
        ) : (
          <div className="padding-bottom-3">
            <Alert type="info" className="margin-3">
              No comments available.
            </Alert>
          </div>
        )}
      </div>
    </div>
  );
};

StandardComments.propTypes = {
  linkedEntityType: PropTypes.number.isRequired,
};

export default StandardComments;
