import React, { useEffect, useState } from 'react';
import { InternalError } from '@gsa/afp-shared-ui-utils';
import dataStore, {
  GetGlobalMessagesCacheQuery,
} from '../../services/data-store';
import ToastMessage from '../../components/Toast/toast';

// reads and displays error messages (if any) cached in GetGlobalMessagesCacheQuery in dataStore
const GlobalErrorMessages = () => {
  const [globalMessages, setGlobalMessages] = useState([]);

  // read if any messages from GlobalMessagesCacheQuery set by dataStore
  useEffect(() => {
    dataStore
      .watchQuery({
        query: GetGlobalMessagesCacheQuery,
        fetchPolicy: 'cache-only',
      })
      .subscribe({
        next: ({ data }) => {
          if (data?.messages) {
            setGlobalMessages(data?.messages);
          }
        },
      });
  }, []);

  // clear messages upon close
  const clearMessages = () => {
    setGlobalMessages([]);
  };

  return (
    globalMessages?.length > 0 &&
    globalMessages.map((m, i) => {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        if (m?.message) {
          return (
            <ToastMessage
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              message={m?.message}
              onClose={() => clearMessages()}
              type={m.type}
              closeable={m.closeable}
            />
          );
        }
      }
      return m?.message ? <InternalError id="system-error" /> : null;
    })
  );
};

export default GlobalErrorMessages;
