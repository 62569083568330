/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import {
  AFPTable,
  AFPTableRowAction,
  ButtonDropdown,
  ButtonDropdownItem,
  EmptyState,
  FilterTableFrame,
  Pagination,
  Spinner,
  Tag,
} from '@gsa/afp-component-library';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import useDidMountEffect from '../../hooks/use-did-mount';
import { LABELS, MODAL_MODES } from './constants';
import CommentsAdminModalWrapper from './comment-admin-modal';
import CommentingPeriodInfo from './commenting-period-info';
import { useCommentsAdmin } from './comments-admin-provider';
import { options, tagColors } from './helpers';
import CommentDetail from './comment-detail';
import CommentsFilters from './comments-filter-panel';

/* eslint-disable react/prop-types */
const CommentsAdminTable = () => {
  const {
    setData,
    getComments,
    comments,
    getOpenAndRecentCommentingPeriods,
    commentingPeriodInfo,
    isLoading,
    getMultipleOptions,
    commentingPeriodIsLoading,
    selectedComments,
  } = useCommentsAdmin();
  const { currentUser } = useCurrentUser();

  const defaultFilters = {
    operator: 'AND',
    value: [
      {
        key: 'year',
        operator: 'EQ',
        value: `${new Date().getFullYear()}`,
      },
    ],
  };

  const [filters, setFilters] = useState(defaultFilters);
  const [order, setOrder] = useState('createdAt DESC');
  const [commentingPeriods, setCommentingPeriods] = useState([]);
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  useEffect(() => {
    getMultipleOptions({
      variables: {
        options,
      },
    });
  }, []);

  const tableRef = React.createRef();

  const getData = () => {
    const { limit, offset } = paginationState;
    const archivedFilter = { key: 'isArchived', operator: 'EQ', value: false };
    const adjustedFilters = {
      ...filters,
      value: [...filters.value, archivedFilter],
    };
    getComments({
      variables: { filters: adjustedFilters, limit, offset, order },
    });
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  useDidMountEffect(() => {
    handlePaginationChange(1, paginationState.limit);
    setOrder('createdAt DESC');
    getData();
  }, [filters]);

  useDidMountEffect(() => {
    getData();
  }, [order, paginationState]);

  useEffect(() => {
    getOpenAndRecentCommentingPeriods({
      variables: { includeStatus: true, excludeArchived: true },
    });
  }, [comments]);

  useEffect(() => {
    setCommentingPeriods(commentingPeriodInfo);
  }, [commentingPeriodInfo]);

  const handleSelectedAction = (evt, row) => {
    if (evt === 'Manage') {
      setData('SET_SELECTED_COMMENT', row.original);
      setData('SET_MODAL_MODE', MODAL_MODES.MANAGE_COMMENT);
    }
  };

  const actions = [
    {
      icon: 'edit',
      label: 'Manage',
      canShowIndicator: 'canEdit',
    },
  ];

  const columns = useMemo(() => {
    return [
      {
        Header: LABELS.DATE,
        accessor: 'createdAt',
        minWidth: 30,
        width: 150,
        maxWidth: 400,
        Cell: ({ value }) => (
          <div aria-label={moment(value).format('MM/DD/YYYY')}>
            {moment(value).format('MM/DD/YYYY')}
          </div>
        ),
      },
      {
        Header: LABELS.COMMENT_DETAIL,
        accessor: 'comment',
        sortable: false,
        minWidth: 30,
        width: 150,
        maxWidth: 400,
        Cell: ({ row }) => (
          <CommentDetail props={row?.original} currentUser={currentUser?.id} />
        ),
      },
      {
        Header: LABELS.REVIEW_STATE,
        accessor: 'status',
        minWidth: 30,
        width: 150,
        maxWidth: 400,
        Cell: ({ value }) => <Tag variant={tagColors[value]}>{value}</Tag>,
      },
      {
        Header: LABELS.ACTIONS,

        sortable: false,
        minWidth: 30,
        width: 150,
        maxWidth: 400,
        Cell: (props) => (
          <AFPTableRowAction
            {...props}
            actions={actions}
            onSelectAction={(evt) => handleSelectedAction(evt, props.row)}
          />
        ),
      },
    ];
  }, []);

  const handleSelectedComments = (modalMode) => () => {
    if (selectedComments?.length) {
      setData('SET_ERROR_MESSAGE', '');
      setData('SET_MODAL_MODE', modalMode);
    } else
      setData(
        'SET_ERROR_MESSAGE',
        'Please select one or more Comment(s) to proceed.',
      );
  };

  const handleArchiveCommentingPeriod = (e) => {
    setData('SET_SELECTED_COMMENTING_PERIOD', e);
    setData('SET_MODAL_MODE', MODAL_MODES.CONFIRM_ARCHIVE);
  };

  const commentState = () => {
    return (
      <>
        {commentingPeriods?.length > 0 ? (
          <CommentingPeriodInfo
            data={commentingPeriods}
            handleArchiveCommentingPeriod={handleArchiveCommentingPeriod}
          />
        ) : (
          <div className="desktop:grid-col-12 tablet-lg:grid-col-12">
            <EmptyState
              hasBackground
              containerStyles="padding-y-8 margin-top-1 height-full"
              topText={
                <>
                  <strong>There are no active commenting periods.</strong>
                </>
              }
              topTextStyles="margin-top-7"
            />
          </div>
        )}
      </>
    );
  };

  const handleRowSelect = ({ selectedFlatRows }) => {
    if (comments?.count) {
      setData('SET_SELECTED_COMMENTS', selectedFlatRows);
    }
  };

  const TableWrapper = ({ tableProps, paginationProps }) => (
    <>
      <AFPTable {...tableProps} />
      {tableProps.data?.length ? <Pagination {...paginationProps} /> : null}
      {tableProps.data === null ||
        (tableProps.data?.length === 0 && (
          <div className="text-center margin-top-neg-2 height-full">
            <EmptyState
              hasBackground
              containerStyles="padding-top-9 height-full"
              topText={
                <>
                  <strong>No comments to manage</strong>
                </>
              }
            />
          </div>
        ))}
    </>
  );

  const FTF = useMemo(
    () => FilterTableFrame(null, null, CommentsFilters, TableWrapper),
    [],
  );

  return (
    <>
      <div className="grid-row margin-bottom-2">
        {commentingPeriodIsLoading ? (
          <div className=" desktop:grid-col-10 tablet-lg:grid-col-9 padding-top-7">
            <Spinner />
          </div>
        ) : (
          commentState()
        )}
      </div>
      <div className="grid-row margin-bottom-2">
        <div className="grid-col">
          <div className="float-right">
            <ButtonDropdown
              label="Manage selected comments"
              variant="primary"
              data-testId="manage-comments-button"
            >
              <ButtonDropdownItem
                label="Apply review state"
                iconName="edit"
                onClick={handleSelectedComments(MODAL_MODES.APPLY_REVIEW_STATE)}
              />
              <ButtonDropdownItem
                label="Write reply"
                iconName="comment"
                onClick={handleSelectedComments(MODAL_MODES.REPLY)}
              />
              <ButtonDropdownItem
                label="Publish"
                iconName="file_upload"
                onClick={handleSelectedComments(MODAL_MODES.PUBLISH)}
              />
            </ButtonDropdown>
          </div>
        </div>
      </div>

      <FTF
        lowLeftProps={{
          currentFilters: filters,
          setFilters,
        }}
        lowRightProps={{
          tableProps: {
            columns,
            data: comments?.rows || [],
            onSort: setOrder,
            defaultSort: order,
            fullWidth: true,
            ref: tableRef,
            testId: 'comment-admin-table',
            onRowSelect: handleRowSelect,
            selectable: true,
            selectedRows: selectedComments,
          },
          paginationProps: {
            itemsPerPageOptions: [10, 25, 50],
            onPageChange: handlePaginationChange,
            variant: 'advanced',
            itemsPerPage: paginationState.limit,
            currentPage: paginationState.currentPage,
            itemsCount: comments.count,
          },
        }}
        filterToggle
      />

      <CommentsAdminModalWrapper />
    </>
  );
};

export default CommentsAdminTable;
