import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import useLocalStorage from '../../../hooks/use-local-storage';
import { getFilterStructure } from './catalog-codes-filter-structure';
import { useCatalogCodes } from '../catalog-codes-provider';

const filterKeys = ['requestStatus', 'category', 'status'];

const CatalogCodesFilters = ({
  currentFilters,
  setFilters,
  setVirtualFilters,
}) => {
  const {
    metadataList,
    typeaheadData,
    setCatalogCodesData,
    getTypeAheadOptions,
    metadataOfSelectedCategory,
  } = useCatalogCodes();

  const { FilterPanel: CatalogCodesFilterPanel } = FilterPanel;
  const [rerenderKey, setRerenderKey] = useState(1);

  const [catalogCodesFilter, setCatalogCodesFilter] = useLocalStorage(
    'CATALOG_CODES_FILTER',
    null,
  );

  const handleFiltersChange = (updatedFilters) => {
    const filterByKeys = updatedFilters?.reduce(
      (filterObj, currentFilterObj) => {
        return { ...filterObj, [currentFilterObj?.key]: currentFilterObj };
      },
      {},
    );

    const newFilter = {
      ...currentFilters,
      value: [...updatedFilters.filter((f) => !filterKeys.includes(f.key))],
    };

    if (filterByKeys?.category) {
      // Add selected category to the context.
      setCatalogCodesData(
        'SET_SELECTED_CATEGORY_NAME',
        filterByKeys?.category.value,
      );

      const selectedCategoryMetadata = metadataList.find(
        (m) => m.category === filterByKeys?.category.value,
      );

      newFilter.value.push({
        operator: 'EQ',
        key: 'standardCodeMetadataId',
        // find metadata for selected category or set default to vehicle group
        value: selectedCategoryMetadata ? selectedCategoryMetadata.id : 10,
      });
    }

    if (filterByKeys?.status) {
      const statusFilters = {
        operator: 'OR',
        value: [],
      };

      filterByKeys?.status.value?.forEach((v) => {
        if (v === 'Active')
          statusFilters.value.push({
            operator: 'EQ',
            key: 'status',
            value: 1,
          });

        if (v === 'Inactive')
          statusFilters.value.push({
            operator: 'EQ',
            key: 'status',
            value: 2,
          });

        if (v === 'Draft')
          statusFilters.value.push({
            operator: 'EQ',
            key: 'status',
            value: 0,
          });
      });
      newFilter.value.push(statusFilters);
    }

    if (filterByKeys?.requestStatus) {
      const virtualFilters = {
        operator: 'OR',
        value: [],
      };

      filterByKeys?.requestStatus.value?.forEach((v) => {
        if (v === 'Pending') {
          virtualFilters.value.push({
            key: 'requestStatus',
            operator: 'IN',
            value: ['Pending-new', 'Pending-edit', 'Pending-delete'],
          });
        }
      });
      setVirtualFilters(virtualFilters);
    } else {
      setVirtualFilters(undefined);
    }

    let selectedCatalogCodeFilterValues = newFilter.value.reduce(
      (reducedCatalogCodeFilter, currentFilter) => {
        if (currentFilter?.key === 'standardCodeMetadataId') {
          return {
            ...reducedCatalogCodeFilter,
            category: filterByKeys?.category.value,
          };
        }
        return {
          ...reducedCatalogCodeFilter,
          [currentFilter.key]: currentFilter.value,
        };
      },
      {},
    );

    if (filterByKeys?.requestStatus) {
      selectedCatalogCodeFilterValues = {
        ...selectedCatalogCodeFilterValues,
        requestStatus: filterByKeys?.requestStatus.value,
      };
    }

    if (filterByKeys?.status) {
      selectedCatalogCodeFilterValues = {
        ...selectedCatalogCodeFilterValues,
        status: filterByKeys?.status.value,
      };
    }

    setCatalogCodesData('SET_TYPEAHEAD_FILTER_DATA', null);
    setCatalogCodesFilter(selectedCatalogCodeFilterValues);
    setFilters(newFilter);
  };

  const handleFetchTypeaheads = ({ variables: queryVariables }) => {
    const selectedKey = queryVariables?.field;
    const filterObj = queryVariables?.filters[0]?.conditions?.find(
      (filter) => filter.key === selectedKey,
    );

    getTypeAheadOptions?.({
      variables: {
        model: 'StandardsCodeModel',
        label: 'title',
        value: 'code',
        filter: {
          operator: 'AND',
          value: [
            { operator: 'LIKE', key: selectedKey, value: filterObj.value },
            {
              operator: 'EQ',
              key: 'standardCodeMetadataId',
              value: metadataOfSelectedCategory?.id,
            },
          ],
        },
      },
    });
  };
  return (
    <CatalogCodesFilterPanel
      key={rerenderKey} // This is the key that will force the component to rerender
      clearButtonLabel="Reset All"
      filterStructure={getFilterStructure(metadataList, catalogCodesFilter)}
      model="catalogCode"
      order="code ASC"
      setQueryFiltersState={handleFiltersChange}
      fetchTypeaheads={handleFetchTypeaheads}
      typeaheadData={typeaheadData}
      showClearIcon
      handleClearAll={() => {
        setCatalogCodesFilter(null);
        setRerenderKey(rerenderKey + 1);
      }}
      retainFilters={false}
    />
  );
};

CatalogCodesFilters.propTypes = {
  currentFilters: PropTypes.shape({
    operator: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]),
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  setVirtualFilters: PropTypes.func.isRequired,
};

export default CatalogCodesFilters;
