/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { TextInput } from '@gsa/afp-component-library';
import { useFormContext } from 'react-hook-form';
import './solicitation-detail.scss';

const SolicitationDetail = () => {
  const { register, errors } = useFormContext();

  return (
    <>
      <div className="solicitation-details-container grid-col-6">
        <div className="title-m-bold" tabIndex={0}>
          Solicitation details
        </div>
        <TextInput
          data-testid="solicitation-title"
          className="title-input"
          label="Solicitation title"
          aria-label="Solicitation title"
          name="solicitationTitle"
          maxLength="255"
          inputRef={register}
          errorMessage={
            errors.solicitationTitle && errors.solicitationTitle.message
          }
          required
        />
        <TextInput
          type="textarea"
          data-testid="solicitation-description"
          aria-label="Solicitation description"
          name="solicitationDescription"
          inputRef={register}
          label="Description"
          maxLength="1000"
          hint="1000 characters allowed"
          errorMessage={
            errors.solicitationDescription &&
            errors.solicitationDescription.message
          }
        />
        <TextInput
          label="Solicitation posting"
          aria-label="Solicitation posting"
          data-testid="solicitation-posting"
          name="solicitationPosting"
          help="Enter the SAM posting URL"
          inputRef={register}
          errorMessage={
            errors.solicitationPosting && errors.solicitationPosting.message
          }
        />
      </div>
    </>
  );
};

export default SolicitationDetail;
