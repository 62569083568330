export const FVS_MESSAGE_ID = {
  ACTION: 'ACTION',
};

export const FvsStatus = {
  Draft: 0,
  PeerReview: 1,
  PsoCommentOpen: 2,
  PublicCommentOpen: 3,
  AllCommentOpen: 4,
  AllCommentClosed: 5,
  Published: 6,
  Amended: 7,
};

export const PreBidReviewStatus = {
  NeedReview: 'NeedReview',
  Done: 'Done',
};

export const POLLING = {
  // every 20 seconds
  Interval: 20000,
  // poll till 2.5 minutes
  MaxAge: 150000,
};
