/* eslint-disable react/prop-types */
import React from 'react';
import { RadioButton, Alert } from '@gsa/afp-component-library';
import { useFederalStandards } from '../../../fvs-provider';

import './make-model-table.scss';

const IntentionToBid = ({
  preBidSelectionId,
  intentToBid,
  standardItemId,
  addOrUpdateIntentionToBid,
  deleteMakeAndModelForSelection,
}) => {
  const { selectedYear, isCommentingAllowed4Fvs4User } = useFederalStandards();

  return isCommentingAllowed4Fvs4User ? (
    <div className="intentToBidWrapper">
      <div className="intentionToBidTitle">
        <p>Are you interested in bidding on this Standard Item?</p>
      </div>
      <div className="intentionToBidYesNoWrapper">
        <RadioButton
          name="intentionToBidYes"
          label="Yes"
          checked={intentToBid}
          data-testid="intentionToBidYes"
          onChange={() => {
            addOrUpdateIntentionToBid({
              variables: {
                hasIntentionToBid: true,
                standardItemId: Number.parseInt(standardItemId, 10),
                contractYear: selectedYear,
              },
            });
          }}
        />
        <RadioButton
          name="intentionToBidNo"
          label="No"
          checked={!intentToBid}
          data-testid="intentionToBidNo"
          onChange={() => {
            addOrUpdateIntentionToBid({
              variables: {
                hasIntentionToBid: false,
                standardItemId: Number.parseInt(standardItemId, 10),
                contractYear: selectedYear,
              },
            });
            deleteMakeAndModelForSelection({
              variables: {
                preBidSelectionId: Number.parseInt(preBidSelectionId, 10),
              },
            });
          }}
        />
      </div>
    </div>
  ) : (
    <Alert type="info" className="margin-3">
      There is no open commenting period for this standard item. You can not
      submit a Make and Model or show intention to bid.
    </Alert>
  );
};

export default IntentionToBid;
