import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Modal } from '@gsa/afp-component-library';
import useSolicitation from './add-edit-solicitation/apis/solicitation-apis';
import useReopenPeriod from './add-edit-solicitation/apis/reopen-period-apis';
import { MODAL_MODES } from './constants';
import './styles/solicitation-modal.scss';

const ModalTitle = () => {
  const { modalMode } = useSolicitation();
  let title = '';
  switch (modalMode?.mode) {
    case MODAL_MODES.SOLICITATION_CONFIRM_DELETE:
      title = 'Delete confirmation';
      break;
    case MODAL_MODES.REOPEN_PERIOD_CONFIRM_DELETE:
      title = 'Delete confirmation';
      break;
    default:
      break;
  }
  return (
    <div className="margin-top-5">
      {/* eslint-disable-next-line */}
      <h3 tabIndex="0" aria-label={title}>
        {title}
      </h3>
    </div>
  );
};

const ModalContent = ({ solPeriod }) => {
  const {
    modalMode,
    selectedSolicitationRow: solicitation,
  } = useSolicitation();

  const deleteSolMsg = `Are you sure you want to delete solicitation ${solicitation?.solicitationNumber}?`;
  const deleteSolPeriodMsg = `Are you sure you want to delete the ${
    solPeriod.periodType === 'M' ? 'Mid-cycle' : 'Reopen'
  } period starting from 
  ${moment(solPeriod?.startDate).format('L')} to ${moment(
    solPeriod?.endDate,
  ).format('L')}?`;

  switch (modalMode?.mode) {
    case MODAL_MODES.SOLICITATION_CONFIRM_DELETE:
      return (
        <div aria-label={deleteSolMsg}>
          Are you sure you want to delete solicitation{' '}
          <strong>{solicitation?.solicitationNumber}? </strong>
        </div>
      );
    case MODAL_MODES.REOPEN_PERIOD_CONFIRM_DELETE:
      return (
        <div aria-label={deleteSolPeriodMsg}>
          Are you sure you want to delete the{' '}
          {solPeriod.periodType === 'M' ? 'Mid-cycle' : 'Reopen'} period
          starting from{' '}
          <strong>{moment(solPeriod?.startDate).format('L')}</strong> to{' '}
          <strong>{moment(solPeriod?.endDate).format('L')}? </strong>
        </div>
      );
    default:
      break;
  }
  return null;
};

const ModalAction = ({ onComplete, onClose, solPeriod }) => {
  const {
    modalMode,
    selectedSolicitationRow: solicitation,
    setModalMode,
    deleteSolicitation,
  } = useSolicitation(onComplete);

  const { deleteSolicitationPeriod } = useReopenPeriod(onComplete);

  switch (modalMode?.mode) {
    case MODAL_MODES.SOLICITATION_CONFIRM_DELETE:
      return (
        <>
          <Button
            variant="unstyled"
            className="margin-right-2"
            aria-label="Cancel"
            onClick={onClose}
            data-testid="solicitation-delete-cancel-btn"
            label="Cancel"
          />
          <Button
            variant="secondary"
            data-testid="solicitation-delete-remove-btn"
            aria-label="Delete"
            onClick={() => {
              deleteSolicitation({
                variables: {
                  solicitationId: Number.parseInt(solicitation?.id, 10),
                  forceDelete: 'Y',
                },
              });
              setModalMode({ mode: null, showInModal: false });
              onClose();
            }}
            label="Delete"
          />
        </>
      );
    case MODAL_MODES.REOPEN_PERIOD_CONFIRM_DELETE:
      return (
        solPeriod && (
          <>
            <Button
              variant="unstyled"
              className="margin-right-2"
              aria-label="Cancel"
              onClick={onClose}
              data-testid="reopen-period-delete-cancel-btn"
              label="Cancel"
            />
            <Button
              variant="secondary"
              data-testid="reopen-period-delete-remove-btn"
              aria-label="Delete"
              onClick={() => {
                deleteSolicitationPeriod({
                  variables: {
                    id: Number.parseInt(solPeriod?.id, 10),
                  },
                });
                onClose();
              }}
              label="Delete"
            />
          </>
        )
      );
    default:
      break;
  }
  return null;
};

const SolicitationModal = ({
  onComplete,
  onClose,
  currentPeriodData: solPeriod,
}) => {
  const { modalMode } = useSolicitation();
  return (
    <>
      {modalMode && (
        <Modal
          title={<ModalTitle />}
          variant="extra-large"
          className="solicitation-modal"
          onClose={onClose}
          actions={
            <ModalAction
              onComplete={onComplete}
              onClose={onClose}
              solPeriod={solPeriod}
            />
          }
          show={modalMode?.showInModal}
          onClick={onClose}
        >
          <ModalContent solPeriod={solPeriod} />
        </Modal>
      )}
    </>
  );
};

ModalContent.defaultProps = {
  solPeriod: {},
};

ModalContent.propTypes = {
  solPeriod: PropTypes.shape(Object),
};

ModalAction.defaultProps = {
  solPeriod: {},
};

ModalAction.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  solPeriod: PropTypes.shape(Object),
};

SolicitationModal.defaultProps = {
  currentPeriodData: {},
};

SolicitationModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  currentPeriodData: PropTypes.shape(Object),
};
export default SolicitationModal;
