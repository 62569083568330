/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import { getFilterStructure } from './util/standard-item-filter-structure';
import { useStandards } from './standards-provider';
import { standardItemOptions } from './util/standard-item-filter-helper';
import useLocalStorage from '../../hooks/use-local-storage';

const filterKeys = ['tags', 'status', 'vehicleType', 'fedStandardCode'];

const StandardItemFilters = ({
  currentFilters,
  setFilters,
  setVirtualFilters,
}) => {
  const {
    multipleOptions,
    getStandardItemOptions,
    selectedOptions,
    setData,
    selectedYearFilter,
    getFilterTypeAheadOptions,
    typeaheadData,
  } = useStandards();

  const { FilterPanel: SIComponentFilterPanel } = FilterPanel;
  const [rerenderKey, setRerenderKey] = useState(1);

  useEffect(() => {
    getStandardItemOptions({
      variables: {
        options: standardItemOptions,
      },
    });
  }, []);

  const [standardItemsFilter, setStandardItemFilter] = useLocalStorage(
    'STANDARD_ITEM_FILTER',
    null,
  );

  useEffect(() => {
    if (standardItemsFilter) {
      setData('SET_SELECTED_VEH_HIERARCHY', {
        fedStandardCode: standardItemsFilter.fedStandardCode,
        vehicleType: standardItemsFilter.vehicleType,
      });
    }
  }, []);

  const resetToDefaultFilters = () => {
    setStandardItemFilter('STANDARD_ITEM_FILTER', null);
    setFilters({
      operator: 'AND',
      value: [
        {
          key: 'year',
          operator: 'EQ',
          value: `${new Date().getFullYear() + 1}`,
        },
      ],
    });
    setVirtualFilters({
      operator: 'OR',
      value: [{ operator: 'EQ', key: 'status', value: 'Active' }],
    });
    setRerenderKey(rerenderKey + 1);
  };

  const getLabelByFilterNameAndValue = (filterName, filterValue) => {
    if (!multipleOptions) return '';
    const filterOptions = multipleOptions[filterName]?.find(
      ({ value }) => value === filterValue,
    );
    return filterOptions?.label;
  };

  const getValueFromLabel = (label) => {
    return label?.split('-')[0];
  };

  const handleFiltersChange = (updatedFilters) => {
    const filterByKeys = updatedFilters?.reduce(
      (filterObj, currentFilterObj) => {
        return { ...filterObj, [currentFilterObj?.key]: currentFilterObj };
      },
      {},
    );

    const newFilter = {
      ...currentFilters,
      value: [...updatedFilters.filter((f) => !filterKeys.includes(f.key))],
    };

    if (filterByKeys?.year) {
      setData('SET_SELECTED_FILTER_YEAR', filterByKeys?.year.value);
    }

    if (filterByKeys?.status) {
      const virtualFilter = {
        operator: 'OR',
        value: filterByKeys.status?.value?.map((v) => ({
          key: 'status',
          operator: 'EQ',
          value: v,
        })),
      };
      setVirtualFilters(virtualFilter);
    } else {
      setVirtualFilters(undefined);
    }

    if (filterByKeys?.tags) {
      newFilter.value.push({
        key: 'StandardItem.tags', // tags is ambiguous as it also exists in associated table.
        operator: 'JSON_CONTAINS',
        value: { value: filterByKeys.tags?.value },
      });
    }

    const vehicleTypeOrFedStdCodeFilterIsSelected =
      filterByKeys?.vehicleType || filterByKeys?.fedStandardCode;

    if (vehicleTypeOrFedStdCodeFilterIsSelected) {
      const vehicleTypeFilter = filterByKeys?.vehicleType;
      const fedStandardCodeFilter = filterByKeys?.fedStandardCode;
      const vehicleTypeFilterIsSelected =
        vehicleTypeFilter &&
        vehicleTypeFilter.value !== selectedOptions?.vehicleType;
      const fedStandardCodeFilterIsSelected =
        fedStandardCodeFilter &&
        fedStandardCodeFilter.value !== selectedOptions?.fedStandardCode;

      if (vehicleTypeFilterIsSelected) {
        setData('SET_SELECTED_VEH_HIERARCHY', {
          fedStandardCode: '',
          vehicleType: vehicleTypeFilter.value,
        });
      } else if (fedStandardCodeFilterIsSelected) {
        setData('SET_SELECTED_VEH_HIERARCHY', {
          fedStandardCode: fedStandardCodeFilter.value,
          vehicleType: '',
        });
      }
    }

    if (filterByKeys?.vehicleType) {
      newFilter.value.push({
        key: 'vehicleType',
        operator: 'EQ',
        value: getValueFromLabel(selectedOptions.vehicleType),
      });
    }

    if (filterByKeys?.fedStandardCode) {
      newFilter.value.push({
        key: 'fedStandardCode',
        operator: 'EQ',
        value: getValueFromLabel(selectedOptions.fedStandardCode),
      });
    }

    let selectedStandardItemFilterValues = newFilter.value.reduce(
      (reducedStdFilter, currentFilter) => {
        if (currentFilter?.key === 'StandardItem.tags') {
          return { ...reducedStdFilter, tags: currentFilter.value.value };
        }
        if (
          currentFilter?.key === 'vehicleType' ||
          currentFilter?.key === 'fedStandardCode'
        ) {
          return {
            ...reducedStdFilter,
            [currentFilter.key]: getLabelByFilterNameAndValue(
              currentFilter.key,
              currentFilter.value,
            ),
          };
        }
        return {
          ...reducedStdFilter,
          [currentFilter.key]: currentFilter.value,
        };
      },
      {},
    );

    if (filterByKeys?.status) {
      selectedStandardItemFilterValues = {
        ...selectedStandardItemFilterValues,
        status: filterByKeys?.status.value,
      };
    }

    setData('SET_TYPEAHEAD_FILTER_DATA', null);
    setStandardItemFilter(selectedStandardItemFilterValues);

    setFilters(newFilter);
  };

  const handleFetchTypeaheads = ({ variables: queryVariables }) => {
    const selectedKey = queryVariables?.field;
    const filterObj = queryVariables?.filters[0]?.conditions?.find(
      (filter) => filter.key === selectedKey,
    );

    getFilterTypeAheadOptions({
      variables: {
        key: selectedKey,
        search: filterObj.value,
        filters: [
          {
            operator: '$and',
            conditions: [
              { operator: '$exact', key: 'year', value: selectedYearFilter },
            ],
          },
        ],
      },
    });
  };
  return (
    <SIComponentFilterPanel
      key={rerenderKey}
      clearButtonLabel="Reset All"
      filterStructure={getFilterStructure(multipleOptions, standardItemsFilter)}
      model="standardItem"
      order="standardItemNumber ASC"
      setQueryFiltersState={handleFiltersChange}
      fetchTypeaheads={handleFetchTypeaheads}
      typeaheadData={typeaheadData}
      showClearIcon
      handleClearAll={resetToDefaultFilters}
      retainFilters={false}
    />
  );
};

StandardItemFilters.propTypes = {
  currentFilters: PropTypes.arrayOf(
    PropTypes.shape({
      operator: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
      ]),
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  setFilters: PropTypes.func.isRequired,
  setVirtualFilters: PropTypes.func.isRequired,
};

export default StandardItemFilters;
