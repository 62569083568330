export const ENGINEER_APPROVAL_STATUS = {
  Approved: 'Approved',
  Pending: 'Pending',
  Rejected: 'Rejected',
  PendingReview: 'Pending review',
};

export const FLEET_APPROVAL_STATUS = {
  Approved: 'Approved',
  Rejected: 'Rejected',
  PendingReview: 'PendingReview',
};

export const MAKE_MODEL_APPROVAL_STATUS_BADGE = {
  PendingReview: { label: 'Pending review', variant: 'Warning-Gray' }, // 0
  Approved: { label: 'Approved', variant: 'Ready-Gray' }, // 1
  Rejected: { label: 'Rejected', variant: 'Urgent-Gray' }, // 2
  GSAReview: { label: 'GSA review', variant: 'Warning-Gray' }, // 3
  Archived: { label: 'Archived', variant: 'Inactive-Gray' }, // 4
};

export const BID_LINE_STATUS = {
  NotStarted: 'Not started',
  InProgress: 'In progress',
  ReadyToSubmit: 'Ready to submit',
  Submitted: 'Completed',
};

export const BID_LINE_STATUS_VARIANT_MAP = {
  [BID_LINE_STATUS.NotStarted]: 'Ready-Gray',
  [BID_LINE_STATUS.InProgress]: 'Warning-Gray',
  [BID_LINE_STATUS.InReview]: 'Warning-Gray',
  [BID_LINE_STATUS.Submitted]: 'Urgent-Gray',
};

export const BID_STANDARDITEM_TABLE_ACTIONS = {
  SELECT_MAKE_MODEL: 'Select Makes and Models',
  IMPORT_DATA: 'Import data',
  REMOVE: 'Remove from bid',
};

export const STANDARD_ITEM_TAG = {
  Chassis: 'REQ_CHASSIS_MOD',
};
export const CHASSIS_TAG_REGEX = new RegExp(STANDARD_ITEM_TAG.Chassis, 'i');

export const ENTRY_SOURCE = {
  PRE_BID: 1,
  BID: 2,
  CONTRACT: 3,
};

export const BID_CLOSE_MONITOR_SETTINGS = {
  // every 10 seconds
  CHECK_INTERVAL_MS: 10000,
  // show alert within 5 minutes of bid close
  ALERT_THRESHOLD_MINUTES: 5,
};
