/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import { useStandardItem } from './standard-item-provider';

const filterStructure = [
  {
    key: 'code',
    title: 'Equipment Code',
    type: 'typeahead',
    value: '',
    operator: 'EQ',
    expanded: true,
    hideClear: true,
    customFieldProps: { inputCharNum: 1 },
  },
  {
    key: 'title',
    title: 'Title',
    type: 'typeahead',
    value: '',
    operator: 'EQ',
    expanded: true,
    hideClear: true,
    customFieldProps: { inputCharNum: 1 },
  },
  {
    key: 'associationType',
    title: 'Type',
    type: 'multiselect',
    options: [
      { label: 'Optional', value: 'O' },
      { label: 'Standard', value: 'S' },
    ],
    value: [],
    operator: 'OR',
    expanded: true,
    hideClear: true,
  },
];

const AssociationFilters = ({ setFilters }) => {
  const { getOptions, options, setData, standardItem } = useStandardItem();

  const yearFilter = {
    operator: 'EQ',
    value: standardItem?.year,
    key: '$standardItem.year$',
  };
  const standardItemIdFilter = {
    operator: 'EQ',
    value: standardItem?.id,
    key: 'standardItemId',
  };
  const activeEQFilters = [
    {
      operator: 'EQ',
      value: 1,
      key: '$equipmentCode.is_active$',
    },
    {
      operator: 'EQ',
      value: null,
      key: '$equipmentCode.deleted_at$',
    },
  ];

  const defaultFilter = [yearFilter, standardItemIdFilter, ...activeEQFilters];

  const { FilterPanel: SIComponentFilterPanel } = FilterPanel;
  const [searchKey, setSearchKey] = useState(null);
  const [typeaheadData, setTypeaheadData] = useState(null);

  useEffect(() => {
    if (options?.length) {
      setTypeaheadData({
        field: searchKey,
        values: options.map(({ value }) => value),
      });
    }
  }, [options]);

  const handleFiltersChange = (selectedFilters) => {
    const filterByKeys = selectedFilters?.reduce(
      (filterObj, currentFilterObj) => {
        return { ...filterObj, [currentFilterObj?.key]: currentFilterObj };
      },
      {},
    );

    const updatedFilters = selectedFilters
      ?.filter((f) => f.key !== 'associationType')
      .map((filter) => {
        if (filter?.key === 'code') {
          return { ...filter, key: '$equipmentCode.code$' };
        }
        if (filter?.key === 'title') {
          return { ...filter, key: '$equipmentCode.title$' };
        }
        return filter;
      });

    const newFilter = {
      operator: 'AND',
      value: [...updatedFilters, ...defaultFilter],
    };

    if (filterByKeys?.associationType) {
      newFilter.value.push({
        operator: 'OR',
        value: filterByKeys.associationType?.value.map((value) => ({
          key: 'associationTypeCode',
          operator: 'EQ',
          value,
        })),
      });
    }

    setData('SET_OPTIONS', []);
    setTypeaheadData(null);
    setFilters(newFilter);
  };

  const prepareSearchVariables = useCallback(
    (key, search) => {
      let variables = {};

      setSearchKey(key);

      if (key === 'title')
        variables = {
          model: 'EquipmentCode',
          label: 'title',
          value: 'title',
          filter: {
            operator: 'AND',
            value: [
              { operator: 'LIKE', key: 'title', value: search },
              { operator: 'EQ', key: 'year', value: standardItem?.year },
            ],
          },
        };

      if (key === 'code')
        variables = {
          model: 'EquipmentCode',
          label: 'code',
          value: 'code',
          filter: {
            operator: 'AND',
            value: [
              { operator: 'LIKE', key: 'code', value: search },
              { operator: 'EQ', key: 'year', value: standardItem?.year },
            ],
          },
        };

      return variables;
    },
    [standardItem],
  );

  const handleFetchTypeaheads = ({ variables: queryVariables }) => {
    const selectedKey = queryVariables?.field;
    const filterObj = queryVariables?.filters[0]?.conditions?.find(
      (filter) => filter.key === selectedKey,
    );

    getOptions({
      variables: prepareSearchVariables(selectedKey, filterObj.value),
    });
  };
  return (
    <SIComponentFilterPanel
      clearButtonLabel="Reset All"
      filterStructure={filterStructure}
      model="equipmentCode"
      order="equipmentCode.code ASC"
      setQueryFiltersState={handleFiltersChange}
      fetchTypeaheads={handleFetchTypeaheads}
      typeaheadData={typeaheadData}
      showClearIcon
      retainFilters={false}
    />
  );
};

AssociationFilters.propTypes = {
  setFilters: PropTypes.func.isRequired,
};

export default AssociationFilters;
