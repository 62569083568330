import React from 'react';
import {
  RequiredFieldIndicator,
  AccordionItem,
  FlexView,
} from '@gsa/afp-component-library';
import VehicleInfo from './vehicle-info';
import PriceInfoSection from './price-info';

const LineSection = () => {
  return (
    <FlexView column className="margin-top-6">
      <span className="title-m-bold">Contract line</span>
      <p>
        Required fields are marked with an asterisk (
        <RequiredFieldIndicator />
        ).
      </p>

      <AccordionItem
        key="vehicle_info"
        id="vehicle_info"
        title="Vehicle information"
        expanded
        bordered
        content={
          <div>
            <VehicleInfo />
          </div>
        }
      />

      <AccordionItem
        key="price_info"
        id="price_info"
        title="Price information"
        expanded
        bordered
        content={
          <div>
            <PriceInfoSection />
          </div>
        }
      />
    </FlexView>
  );
};

export default LineSection;
