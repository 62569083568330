import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { find, orderBy } from 'lodash';
import {
  GET_SOLICITATION_BY_ID,
  UPDATE_SOLICITATION,
  SUBMIT_SOLICITATION_AS_NEW,
  GET_FVS_OPTIONS_FOR_YEAR,
} from '../../queries';
import solicitationNotifications from '../../utils/solicitation-notification-helpers';
import {
  solicitationNoOpenPeriodErrorNotifications,
  solicitationNoSinErrorNotifications,
} from '../../atoms/solicitaion-submit-error-atom';

export default function useReview() {
  const emDashUnicode = '\u2014';
  const history = useHistory();
  const [solicitation, setSolicitation] = useState(null);
  const [SIcount, setSICount] = useState(0);
  const [notifications, setSolicitationNotification] = useRecoilState(
    solicitationNotifications,
  );
  const [
    noOpenPeriodErrnotifications,
    setnoOpenPeriodErrnotification,
  ] = useRecoilState(solicitationNoOpenPeriodErrorNotifications);
  const [noSinErrnotifications, setnoSinErrnotification] = useRecoilState(
    solicitationNoSinErrorNotifications,
  );

  useEffect(() => {
    // on component unmount clear error messages
    return () => {
      setSolicitationNotification([{}]);
      setnoOpenPeriodErrnotification([{}]);
      setnoSinErrnotification([{}]);
    };
  }, []);

  const setOpenPeriodErrorNotification = () => {
    setnoOpenPeriodErrnotification([
      {
        id: 'SUBMIT_SOLICITATION_AS_NEW_NO_OPEN_PEROD',
        message: 'Add an open period to submit the solicitation.',
        type: 'error',
        closeable: false,
        showInModal: false,
      },
      noOpenPeriodErrnotifications,
    ]);
  };

  const setNoSinErrorNotification = () => {
    setnoSinErrnotification([
      {
        id: 'SUBMIT_SOLICITATION_AS_NEW_NO_SIN',
        message:
          'Select at least one Standard Item and assign quantities to submit the solicitation.',
        type: 'error',
        closeable: false,
        showInModal: false,
      },
      noSinErrnotifications,
    ]);
  };
  const [standardItems, setStandardItems] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);

  const getStandardItemCount = (programs) => {
    let count = 0;
    if (programs && programs.length > 0) {
      programs.forEach((program) => {
        count += program.solicitationLines?.length;
      });
    }

    return count;
  };
  const getStandardItems = (programs, vTypes) => {
    const stdItems = [];
    if (programs && programs.length > 0) {
      programs.forEach((program) => {
        program.solicitationLines?.forEach((solctnLine) => {
          const vehicleType = find(vTypes, {
            value: solctnLine.standardItem.vehicleType,
          });
          stdItems.push({
            standardItemNumber: solctnLine.standardItem.standardItemNumber,
            title: `${solctnLine.standardItem.standardItemNumber}-${solctnLine.standardItem.title}`,
            vehicleType: vehicleType ? vehicleType.label : emDashUnicode,
            estimatedQty: solctnLine.estimatedQty,
            estimatedFleetQty: solctnLine.estimatedFleetQty,
          });
        });
      });
    }

    return orderBy([...stdItems], 'standardItemNumber', 'asc');
  };

  const [getSolicitationById, { loading: solicitationLoading }] = useLazyQuery(
    GET_SOLICITATION_BY_ID,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (responseData) => {
        setSolicitation(responseData?.getSolicitationById);
        const programs = responseData?.getSolicitationById?.programs;
        setSICount(getStandardItemCount(programs));
      },
    },
  );

  const [updateSolicitation] = useMutation(UPDATE_SOLICITATION, {
    onError: (requestError) => {
      setSolicitationNotification([
        {
          id: 'UPDATE_SOLICITATION_ERROR',
          message: requestError.message,
          type: 'error',
          closeable: false,
          showInModal: false,
        },
        notifications,
      ]);
    },
    onCompleted: (response) => {
      const { id } = response.updateSolicitation;
      if (id) {
        setSolicitationNotification([
          {
            id: 'UPDATE_SOLICITATION',
            message: `Solicitation ${solicitation.solicitationNumber} was successfully updated.`,
            type: 'success',
            closeable: true,
            showInModal: false,
          },
        ]);
        history.push(`/catalog/solicitations`);
      }
    },
  });

  const [submitSolicitation] = useMutation(SUBMIT_SOLICITATION_AS_NEW, {
    onError: (requestError) => {
      // no open-period and no sin association found
      if (requestError.message === '100003') {
        setOpenPeriodErrorNotification();
        setNoSinErrorNotification();
      }
      // no open period with the solicitaion error code
      if (requestError.message === '100001') {
        setOpenPeriodErrorNotification();
      }
      // no SIN associated with the solicitaion error code
      if (requestError.message === '100002') {
        setNoSinErrorNotification();
      }
      // general page level error notification
      setSolicitationNotification([
        {
          id: 'SUBMIT_SOLICITATION_AS_NEW',
          message: (
            <div>
              <div className="text-bold">This page contains errors</div>Please
              address all fields outlined in red before proceeding to the next
              step.
            </div>
          ),
          type: 'error',
          closeable: false,
          showInModal: false,
        },
        notifications,
      ]);
    },
    onCompleted: (response) => {
      const { id } = response.submitSolicitation;
      if (id) {
        setSolicitationNotification([
          {
            id: 'SUBMIT_SOLICITATION_AS_NEW',
            message: `Solicitation ${solicitation.solicitationNumber} was successfully updated.`,
            type: 'success',
            closeable: true,
            showInModal: false,
          },
        ]);
        history.push(`/catalog/solicitations`);
      }
    },
  });

  const [getFvsCodes] = useLazyQuery(GET_FVS_OPTIONS_FOR_YEAR, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const vtypes = data.getVehicleClassificationFVS.filter(
        (inst) => inst.uniqueKey === 'vehicleType',
      );
      setVehicleTypes(vtypes);
    },
  });

  useEffect(() => {
    if (solicitation?.programs?.length && vehicleTypes?.length) {
      setStandardItems(getStandardItems(solicitation.programs, vehicleTypes));
    }
  }, [solicitation, vehicleTypes]);

  return {
    solicitationLoading,
    solicitation,
    getSolicitationById,
    updateSolicitation,
    submitSolicitation,
    SIcount,
    getFvsCodes,
    vehicleTypes,
    standardItems,
  };
}
