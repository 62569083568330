/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@gsa/afp-component-library';

const InteractiveCheck = ({ checks, isDirty }) => {
  const colorChange = () => {
    if (!isDirty) {
      return 'text-gray-50 font-sans-md text-heavy';
    }
    return 'font-sans-md';
  };

  const iconStyle = (condition) => {
    return condition
      ? 'text-white font-sans-lg text-heavy bg-green radius-pill'
      : 'text-white font-sans-lg text-heavy bg-red radius-pill';
  };

  const iconChange = (condition) => {
    return condition ? 'check' : 'close';
  };

  return checks.map((item, index) => (
    <p
      key={item.id}
      className={colorChange()}
      data-testid={`interactive-check-testid-${index + 1}`}
      aria-label={item.message}
    >
      {isDirty ? (
        <Icon
          className={iconStyle(item.condition)}
          iconName={iconChange(item.condition)}
          style={{ marginRight: 12 }}
        />
      ) : (
        <svg
          height="10"
          width="10"
          style={{ marginRight: 12 }}
          aria-hidden="true"
          focusable="false"
          role="img"
        >
          <circle cx="5" cy="5" r="3" fill="#71767A" />
        </svg>
        // eslint-disable-next-line react/jsx-no-comment-textnodes
      )}
      <span aria-hidden="false" tabIndex="0">
        {item.message}
      </span>
    </p>
  ));
};

InteractiveCheck.defaultProps = {
  checks: [],
  isDirty: false,
};

InteractiveCheck.propTypes = {
  checks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      condition: PropTypes.bool.isRequired,
      message: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isDirty: PropTypes.bool.isRequired,
};

export default InteractiveCheck;
