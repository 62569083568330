import {
  AFPTable,
  AFPTableRowAction,
  Button,
  FilterTableFrame,
  Pagination,
  StatusBadge,
  Tag,
  EmptyState,
} from '@gsa/afp-component-library';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useAppAbility, Can } from '@gsa/afp-shared-ui-utils';
import Truncate from '../../components/Truncate';
import useDidMountEffect from '../../hooks/use-did-mount';
import { SI_LABELS } from './constants';
import StandardItemModalWrapper from './standard-item-modal';
import { useStandards } from './standards-provider';
import './styles/standard-items-table.scss';
import { OPERATIONS, SUBJECTS } from '../../utilities/constants';
import StandardItemFilters from './standard-item-filter-panel';
import StandardItemAddButton from './standard-item-add-button';

/* eslint-disable react/prop-types */
const StandardItemsTable = () => {
  const { setData, standardItemList, getStandardItems } = useStandards();

  const ability = useAppAbility();
  const defaultFilters = {
    operator: 'AND',
    value: [
      {
        key: 'year',
        operator: 'EQ',
        value: `${new Date().getFullYear()}`,
      },
    ],
  };

  const defaultVirtualFilters = {
    operator: 'OR',
    value: [{ operator: 'EQ', key: 'status', value: 'Active' }],
  };

  const [filters, setFilters] = useState(defaultFilters);
  const [virtualFilters, setVirtualFilters] = useState(defaultVirtualFilters);
  const [order, setOrder] = useState('standardItemNumber ASC');
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  const history = useHistory();

  const tableRef = React.createRef();

  const getData = () => {
    const { limit, offset } = paginationState;
    const variables = { filters, virtualFilters, limit, offset, order };
    getStandardItems({ variables });
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useDidMountEffect(() => {
    handlePaginationChange(1, paginationState.limit);
    setOrder('standardItemNumber ASC');
    tableRef.current.toggleSortBy('standardItemNumber', false);
    getData();
  }, [filters]);

  useDidMountEffect(() => {
    getData();
  }, [order, paginationState]);

  const handleSelectedAction = (evt, row) => {
    // SET SELECTED ITEM.
    setData('SET_SELECTED_ITEM', row.original);

    if (evt === SI_LABELS.EDIT) {
      setData('SET_MODAL_MODE', 'edit');
      setData('SET_FORM_MODAL', true);
    }
    if (evt === SI_LABELS.MANAGE || evt === SI_LABELS.VIEW) {
      const { standardItemNumber, year } = row.original;
      const url = `/catalog/standard-item/${standardItemNumber}-${year}`;
      history.push(url, row.original);
    }
    if (evt === 'view_associations') {
      setData('SET_MODAL_MODE', 'view_associations');
      setData('SET_FORM_MODAL', true);
    }
    if (evt === SI_LABELS.DELETE) {
      setData('SET_MODAL_MODE', 'confirm_delete');
      setData('SET_FORM_MODAL', true);
    }
  };

  const actions = [
    {
      icon: 'edit',
      label: SI_LABELS.EDIT,
      canShowIndicator: 'canEdit',
    },
    {
      icon: 'visibility',
      label: SI_LABELS.VIEW,
      canShowIndicator: 'canView',
    },
    // TODO - add this feature after contract implementation
    // {
    //   icon: 'delete',
    //   label: SI_LABELS.DELETE,
    //   canShowIndicator: 'canDelete',
    // },
    {
      icon: 'settings',
      label: SI_LABELS.MANAGE,
      canShowIndicator: 'canEdit',
    },
  ];

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: SI_LABELS.NUMBER,
        accessor: 'standardItemNumber',
        minWidth: 30,
        width: 150,
        maxWidth: 400,
        Cell: ({ row, value }) => (
          <Button
            variant="unstyled"
            onClick={() => handleSelectedAction(SI_LABELS.MANAGE, row)}
            label={value}
          />
        ),
      },
      {
        Header: SI_LABELS.FED_CODE,
        accessor: 'fedStandard.code',
        minWidth: 30,
        width: 150,
        maxWidth: 400,
      },
      {
        Header: SI_LABELS.TYPE,
        accessor: 'vehicleTypeCode.title',
        minWidth: 30,
        width: 150,
        maxWidth: 400,
      },
      {
        Header: SI_LABELS.STATUS,
        accessor: 'status',
        minWidth: 30,
        width: 150,
        maxWidth: 400,
        Cell: ({ value }) => (
          <StatusBadge
            variant={value === 'Active' ? 'Ready-Gray' : 'Inactive-Gray'}
          >
            {value}
          </StatusBadge>
        ),
      },
      {
        Header: SI_LABELS.TITLE,
        accessor: 'title',
        cellClassName: 'afpTable-cell-wordBreak',
        minWidth: 300,
        width: 400,
        maxWidth: 600,
      },
      {
        Header: SI_LABELS.ASSOCIATED_VIEW,
        sortable: false,
        minWidth: 30,
        width: 150,
        maxWidth: 400,
        Cell: (props) => (
          <Button
            variant="unstyled"
            onClick={() => handleSelectedAction('view_associations', props.row)}
            label="View"
          />
        ),
      },
    ];

    if (ability.can(OPERATIONS.Manage, SUBJECTS.StandardItem)) {
      columnList.push({
        Header: 'Actions',
        sortable: false,
        minWidth: 30,
        width: 150,
        maxWidth: 400,
        Cell: (props) => (
          <AFPTableRowAction
            {...props}
            actions={actions}
            onSelectAction={(evt) => handleSelectedAction(evt, props.row)}
          />
        ),
      });
    }

    return columnList;
  }, []);

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    return (
      <div className="grid-container standard-items-sub-component-wrapper">
        <div className="grid-row grid-gap">
          <div className="tablet:grid-col">
            <div className="grid-row standard-item-wrapper standard-item-separator">
              <div className="tablet:grid-col standard-item-title">
                Federal standard code
              </div>
              <div className="tablet:grid-col  text-right">
                {original?.fedStandard?.code ? (
                  <div>
                    {original?.fedStandard?.code} (
                    {original?.fedStandard?.title})
                  </div>
                ) : (
                  <div className="text-bold">&ndash;</div>
                )}
              </div>
            </div>

            <div className="grid-row standard-item-wrapper standard-item-separator">
              <div className="tablet:grid-col standard-item-title">Tags</div>
              <div className="tablet:grid-col text-right standard-item-last-row">
                {original?.tags?.value?.length ? (
                  [...original?.tags?.value].map((tag) => (
                    <Tag
                      key={tag}
                      className="usa-tag afp-tag bg-base text-white standard-item-tag-item"
                    >
                      {tag}
                    </Tag>
                  ))
                ) : (
                  <div className="text-bold">&ndash;</div>
                )}
              </div>
            </div>

            <div className="grid-row standard-item-wrapper standard-item-separator">
              <div className="tablet:grid-col standard-item-title">
                FAST Code
              </div>
              <div className="tablet:grid-col  text-right">
                {original?.fastCode?.title ? (
                  <div>{original?.fastCode?.title}</div>
                ) : (
                  <div className="text-bold">&ndash;</div>
                )}
              </div>
            </div>
          </div>
          <div className="tablet:grid-col">
            <div className="standard-item-wrapper">
              <div className="standard-item-title">Description</div>

              {original?.content?.content ? (
                <Truncate content={original?.content?.content} size={100} />
              ) : (
                <div className="text-bold">
                  <br />
                  &ndash;
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }, []);

  const TableWrapper = ({ tableProps, paginationProps }) => (
    <>
      <AFPTable {...tableProps} />
      {tableProps.data?.length ? <Pagination {...paginationProps} /> : null}
      {tableProps.data === null ||
        (tableProps.data?.length === 0 && (
          <div className="text-center margin-top-neg-2 height-full">
            <EmptyState
              hasBackground
              containerStyles="padding-top-9 height-full"
              topText={
                <>
                  <strong>No Standard Item</strong>
                </>
              }
            />
          </div>
        ))}
    </>
  );

  const FTF = useMemo(
    () => FilterTableFrame(null, null, StandardItemFilters, TableWrapper),
    [],
  );

  return (
    <>
      <Can I={OPERATIONS.Manage} a={SUBJECTS.StandardItem}>
        <StandardItemAddButton setData={setData} />
      </Can>

      <FTF
        lowLeftProps={{
          currentFilters: filters,
          setVirtualFilters,
          setFilters,
        }}
        lowRightProps={{
          tableProps: {
            expandable: true,
            columns,
            data: standardItemList.rows || [],
            renderRowSubComponent,
            onSort: setOrder,
            defaultSort: order,
            fullWidth: true,
            ref: tableRef,
            testId: 'standard-items-table',
            hiddenColumns: ['fedStandard.code'],
          },
          paginationProps: {
            itemsPerPageOptions: [10, 25, 50],
            onPageChange: handlePaginationChange,
            variant: 'advanced',
            itemsPerPage: paginationState.limit,
            currentPage: paginationState.currentPage,
            itemsCount: standardItemList?.count,
          },
        }}
        filterToggle
      />
      <StandardItemModalWrapper />
    </>
  );
};

export default StandardItemsTable;
