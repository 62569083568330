import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AFPTable,
  AFPTableRowAction,
  Button,
  Pagination,
  StatusBadge,
  Tag,
  PageTitle,
  FilterTableFrame,
} from '@gsa/afp-component-library';
import { Can, useAppAbility } from '@gsa/afp-shared-ui-utils';
import {
  CATALOG_CODES_MODAL_MODES,
  useCatalogCodes,
} from '../catalog-codes-provider';
import { OPERATIONS, SUBJECTS } from '../../../utilities/constants';
import useDidMountEffect from '../../../hooks/use-did-mount';
import Truncate from '../../../components/Truncate';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import WelcomeMessage from '../../../widgets/welcome-message';
import CatalogCodeWrapper from '../components/catalog-codes-form-modal';
import CatalogCodeMessages from '../components/catalog-code-messages';
import CatalogCodesFilters from './catalog-codes-filter-panel';

const actions = [
  {
    icon: 'edit',
    label: 'Edit',
    operation: OPERATIONS.Update,
  },
  {
    icon: 'delete',
    label: 'Delete',
    operation: OPERATIONS.Delete,
  },
  {
    icon: 'visibility',
    label: 'View Code',
    operation: OPERATIONS.View,
  },
];

const CatalogCodesTable = () => {
  const ability = useAppAbility();
  const history = useHistory();
  const {
    selectedCategoryName,
    filteredCodes,
    getCatalogCodes,
    setCatalogCodesData,
  } = useCatalogCodes();

  const defaultFilters = {
    operator: 'AND',
    value: [
      {
        operator: 'EQ',
        key: 'standardCodeMetadataId',
        value: 10,
      },
    ],
  };

  const [tableData, setTableData] = useState(filteredCodes.rows);
  const [filters, setFilters] = useState(defaultFilters);
  const [virtualFilters, setVirtualFilters] = useState(null);
  const [order, setOrder] = useState('code ASC');
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  const tableRef = React.createRef();

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  const getData = () => {
    const { limit, offset } = paginationState;

    getCatalogCodes({
      variables: { filters, virtualFilters, limit, offset, order },
    });
  };

  useDidMountEffect(() => {
    handlePaginationChange(1, paginationState.limit);
    setOrder('code ASC');
    tableRef.current.toggleSortBy('code', false);
    getData();
  }, [filters, virtualFilters]);

  useDidMountEffect(() => {
    getData();
  }, [order, paginationState]);

  useEffect(() => {
    getData();
    setCatalogCodesData('SET_CATALOG_CODE_MESSAGES', []);
  }, []);

  const handleSelectedAction = useCallback((e, row) => {
    setCatalogCodesData('SET_SELECTED_CATALOG_CODE', row.original);
    setCatalogCodesData('SET_CATALOG_CODE_MESSAGES', []);
    if (e === 'View Code') {
      const { metadata, code } = row.original;
      const url = `/catalog/codes/${metadata?.id}/${code}`;
      return history.push(url);
    }
    if (e === 'Edit') {
      setCatalogCodesData('SET_MODAL_MODE', CATALOG_CODES_MODAL_MODES.EDIT);
    }
    if (e === 'Delete') {
      setCatalogCodesData(
        'SET_MODAL_MODE',
        CATALOG_CODES_MODAL_MODES.CONFIRM_DELETE,
      );
    }
    return true;
  }, []);

  useEffect(() => {
    setTableData(filteredCodes?.rows);
  }, [filters, filteredCodes]);

  const mapStatusToBadgeColor = (catalogCodeStatus) => {
    const status = catalogCodeStatus?.toLowerCase();

    if (!status) return '';
    if (status === 'active') return 'Ready-Gray';
    if (status === 'draft') return 'Warning-Gray';
    return 'Inactive-Gray';
  };

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Code',
        accessor: 'code',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value, row }) => (
          <Button
            data-testid="catalog-codes-link"
            variant="unstyled"
            onClick={() => handleSelectedAction('View Code', row)}
            label={value}
          />
        ),
      },
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Parent',
        accessor: 'metadata.parentCategory',
        Cell: ({ value, row: { original } }) => {
          const code = original?.parentCode ? `- ${original.parentCode}` : '';
          return value?.length ? `${value} ${code}` : '-';
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        minWidth: 30,
        width: 150,
        maxWidth: 400,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          return (
            <StatusBadge variant={mapStatusToBadgeColor(value)}>
              {value}
            </StatusBadge>
          );
        },
      },
      {
        Header: 'Request Status',
        accessor: 'requestStatus',
        sortable: false,
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value,
        }) => {
          if (!value) {
            return null;
          }
          const mapRequestStatusToBadgeColor = (requestStatus) => {
            const status = requestStatus?.toLowerCase();

            const isPendingStatusType = () => {
              if (
                status === 'pending-new' ||
                status === 'pending-edit' ||
                status === 'pending-delete'
              )
                return true;
              return false;
            };

            if (!status) return '';
            if (isPendingStatusType()) return 'Warning-Gray';
            if (status === 'approved') return 'Ready-Gray';
            return 'Inactive-Gray';
          };

          return (
            <StatusBadge variant={mapRequestStatusToBadgeColor(value)}>
              {value}
            </StatusBadge>
          );
        },
      },
    ];

    const allowedActions = actions.filter((a) =>
      ability.can(a.operation, SUBJECTS.CatalogCodes),
    );
    if (allowedActions.length > 0) {
      columnList.push({
        Header: 'Actions',
        sortable: false,
        Cell: (props) => {
          // eslint-disable-next-line
          const codeStatus = props?.row?.original?.status;
          // eslint-disable-next-line react/prop-types
          const filteredActions = allowedActions.filter(
            (a) =>
              !(
                (a.label === 'Delete' && codeStatus !== 'Draft') ||
                (a.label === 'Edit' && codeStatus === 'Inactive')
              ),
          );
          return (
            <AFPTableRowAction
              // eslint-disable-next-line
              actions={filteredActions}
              // eslint-disable-next-line react/prop-types
              onSelectAction={(evt) => handleSelectedAction(evt, props.row)}
              {...props}
            />
          );
        },
      });
    }

    return columnList;
  }, []);

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    return (
      <div className=" catalog-code-sub-component-wrapper">
        <div className="grid-row grid-gap padding-x-2 padding-y-4">
          {/* Left Column */}
          <div className="desktop:grid-col margin-bottom-4 desktop:margin-left-8">
            <div className="grid-row text-bold margin-bottom-2">
              Description
            </div>

            <div className="grid-row">
              {original?.description ? (
                <Truncate content={original?.description} />
              ) : (
                <div
                  data-testid="catalog-code-empty-description"
                  className="text-bold"
                >
                  <span>&ndash;</span>
                </div>
              )}
            </div>
          </div>
          {/* Right Column */}
          {original?.tags?.value.length ? (
            <div className="desktop:grid-col margin-bottom-4">
              <div className="grid-row text-bold margin-bottom-2">Tags</div>

              <div className="grid-row">
                <div className="grid-col">
                  {[...original?.tags?.value].map((tag) => (
                    <div key={tag} className="grid-row margin-bottom-2">
                      <Tag>{tag}</Tag>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }, []);

  // eslint-disable-next-line react/prop-types
  const TableWrapper = ({ tableProps, paginationProps }) => (
    <>
      <AFPTable {...tableProps} />
      <Pagination {...paginationProps} />
    </>
  );

  const FTF = useMemo(
    () => FilterTableFrame(null, null, CatalogCodesFilters, TableWrapper),
    [],
  );

  return (
    <>
      <Breadcrumbs
        path={[
          {
            location: `${window.AFP_CONFIG.appURLs.home}/home`,
            label: 'Home',
          },
        ]}
        current="Catalog Codes"
      />
      <CatalogCodeMessages />
      <PageTitle
        aria-label="Catalog Codes"
        tabIndex="0"
        title="Catalog Codes"
      />
      <WelcomeMessage message="Catalog Codes are common across vehicle programs and products in the AFP. You may use the table to filter and add Codes . You may edit, delete and archive Codes using actions." />
      <h2 data-testid="catalog-code-category" className="margin-bottom-4">
        {selectedCategoryName}
      </h2>
      <Can I={OPERATIONS.Create} a={SUBJECTS.CatalogCodes}>
        <div className="grid-row margin-bottom-2">
          <div className="grid-col">
            <div className="float-right">
              <Button
                data-testid="catalog-codes-add-button"
                onClick={() => {
                  setCatalogCodesData('SET_MODAL_MODE', 'add');
                }}
                label="Add Code"
                leftIcon={{
                  name: 'add',
                }}
              />
            </div>
          </div>
        </div>
      </Can>

      <FTF
        lowLeftProps={{
          currentFilters: filters,
          setFilters,
          setVirtualFilters,
        }}
        lowRightProps={{
          tableProps: {
            expandable: true,
            columns,
            data: tableData,
            renderRowSubComponent,
            onSort: setOrder,
            defaultSort: order,
            fullWidth: true,
            ref: tableRef,
            testId: 'catalog-codes-table',
          },
          paginationProps: {
            itemsPerPageOptions: [10, 25, 50],
            onPageChange: handlePaginationChange,
            variant: 'advanced',
            itemsPerPage: paginationState.limit,
            currentPage: paginationState.currentPage,
            itemsCount: filteredCodes?.count,
          },
        }}
        filterToggle
      />
      <CatalogCodeWrapper />
    </>
  );
};

export default CatalogCodesTable;
