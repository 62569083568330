import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import './contract-search-table-expanded-row.scss';

const ContractSearchTableExpandedRow = ({ row }) => {
  const { original } = row;
  return (
    <div id="contract-search-table-expanded-row">
      <div className="grid-container">
        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-6">
            <div className="grid-row item-wrapper item-separator">
              <div className="tablet:grid-col item-title">
                Solicitation uPIID
              </div>
              <div className="tablet:grid-col text-right margin-left-1">
                {original.solicitation?.solicitationUpiid}
              </div>
            </div>
            <div className="grid-row item-wrapper item-separator">
              <div className="tablet:grid-col item-title">
                Legacy solicitation
              </div>
              <div className="tablet:grid-col text-right margin-left-1">
                {original.solicitation?.solicitationNumberLegacy}
              </div>
            </div>
            <div className="grid-row item-wrapper item-separator">
              <div className="tablet:grid-col item-title">Contract number</div>
              <div className="tablet:grid-col text-right margin-left-1">
                {original.contractNumber}
              </div>
            </div>
          </div>

          <div className="tablet:grid-col-6">
            <div className="grid-row item-wrapper item-separator">
              <div className="tablet:grid-col item-title">Award date</div>
              <div className="tablet:grid-col text-right margin-left-1">
                {original.awardedDate
                  ? moment(original.awardedDate).format('L')
                  : ''}
              </div>
            </div>
            <div className="grid-row item-wrapper item-separator">
              <div className="tablet:grid-col item-title">Close date</div>
              <div className="tablet:grid-col text-right margin-left-1">
                {original.contractEndDate
                  ? moment(original.contractEndDate).format('L')
                  : ''}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ContractSearchTableExpandedRow.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
};

export default ContractSearchTableExpandedRow;
