import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import SolicitationPeriodErrors from './solicitation-period-errors';
import SolicitationPeriodField from './solicitation-period-field';
import {
  startDateProps,
  startTimeProps,
  endDateProps,
  endTimeProps,
  audienceProps,
  vendorProps,
} from '../utils/solicitation-period-utils';
import { ReadOnlyAudience } from './readonly-audience';

const SolicitationPeriod = ({
  maxDate,
  minDate,
  title,
  titleHint,
  contractYear,
  currentSolicitationPeriod,
  solicitationOprType,
  stepperStage,
  children,
  formWrapperClassName,
  readonlyFields,
  endDateTimeMsg,
  idPrefix,

  /* eslint-disable react/prop-types */
  formRegister: registerFromProps,
  formControl: controlFromProps,
  formErrors: errorsFromProps,
  formWatch: watchFromProps,
  rawSolPeriod,
  /* eslint-enable react/prop-types */
}) => {
  const getISODate = (dateStr) =>
    moment(dateStr || undefined)
      .startOf('day')
      .toISOString();

  const MIN_DATE = getISODate(minDate);
  const MAX_DATE = (maxDate && getISODate(maxDate)) || undefined;
  const [currentPeriod, setCurrentPeriod] = useState({});

  const {
    register: registerFromContext,
    control: controlFromContext,
    errors: errorsFromContext,
    watch: watchFromContext,
  } = useForm() || {};

  const register = registerFromProps || registerFromContext;
  const control = controlFromProps || controlFromContext;
  const errors =
    controlFromProps && registerFromProps ? errorsFromProps : errorsFromContext;
  const watch =
    controlFromProps && registerFromProps ? watchFromProps : watchFromContext;

  const {
    [startDateProps.name]: startDateValue,
    // [audienceProps.name]: selectedAudienceValue,
  } = watch?.() || {};

  useEffect(() => {
    if (solicitationOprType === 'edit') {
      setCurrentPeriod({
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
        audience: null,
      });
    }
  }, [solicitationOprType]);

  useEffect(() => {
    setCurrentPeriod(currentSolicitationPeriod);
  }, [currentSolicitationPeriod]);

  return (
    <>
      {!!title && (
        <div className="grid-row grid-gap-2">
          <span
            className="text-black margin-top-1"
            aria-label={title}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex="0"
          >
            {title}
          </span>
        </div>
      )}

      {contractYear && (
        <div
          className="grid-row margin-top-4"
          data-testid="solicitation-period-contract-year-wrapper"
        >
          <span
            className="text-bold"
            aria-label="Contract Year"
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex="0"
          >
            Contract year
          </span>
          <span
            className="padding-left-8 text-normal"
            aria-label={contractYear}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex="0"
          >
            {contractYear}
          </span>
        </div>
      )}
      <div className="solicitation-period-container margin-bottom-2 maxw-tablet-lg">
        {titleHint && (
          <p aria-label={titleHint}>
            <span className="usa-hint">{titleHint}</span>
          </p>
        )}
        <SolicitationPeriodErrors
          errorIdList={[
            startDateProps.name,
            startTimeProps.name,
            endDateProps.name,
            endTimeProps.name,
          ]}
          errorObj={errors}
        />
        <div className={`wrapper ${formWrapperClassName}`}>
          <div className="grid-row grid-gap-3">
            <div
              className="grid-col-5"
              data-testid="solicitation-start-date-select"
            >
              <SolicitationPeriodField
                idPrefix={idPrefix}
                {...startDateProps}
                control={control}
                register={register}
                stepperStage={stepperStage}
                solicitationOprType={solicitationOprType}
                minDate={MIN_DATE}
                maxDate={MAX_DATE}
                currentValue={currentPeriod?.startDate}
                isReadonly={readonlyFields?.includes(startDateProps.name)}
              />
            </div>
            <div
              className="grid-col"
              data-testid="solicitation-start-time-select"
            >
              <SolicitationPeriodField
                idPrefix={idPrefix}
                {...startTimeProps}
                control={control}
                register={register}
                stepperStage={stepperStage}
                solicitationOprType={solicitationOprType}
                currentValue={currentPeriod?.startTime}
                component="TimePicker"
                isReadonly={readonlyFields?.includes(startDateProps.name)}
              />
            </div>
          </div>
          <div className="grid-row grid-gap-3">
            {endDateTimeMsg && (
              <p className="margin-bottom-neg-105 text-medium">
                {endDateTimeMsg}
              </p>
            )}
            <div
              className="grid-col-5"
              data-testid="solicitation-end-date-select"
            >
              <SolicitationPeriodField
                idPrefix={idPrefix}
                {...endDateProps}
                control={control}
                register={register}
                stepperStage={stepperStage}
                solicitationOprType={solicitationOprType}
                minDate={getISODate(startDateValue || MIN_DATE)}
                maxDate={MAX_DATE}
                currentValue={currentPeriod?.endDate}
                isReadonly={readonlyFields?.includes(endDateProps.name)}
              />
            </div>
            <div
              className="grid-col"
              data-testid="solicitation-end-time-select"
            >
              <SolicitationPeriodField
                idPrefix={idPrefix}
                {...endTimeProps}
                control={control}
                register={register}
                stepperStage={stepperStage}
                solicitationOprType={solicitationOprType}
                currentValue={currentPeriod?.endTime}
                component="TimePicker"
                isReadonly={readonlyFields?.includes(endTimeProps.name)}
              />
            </div>
          </div>
          <div className="grid-row padding-top-6 padding-bottom-10">
            <SolicitationPeriodField
              idPrefix={idPrefix}
              {...audienceProps}
              data-testid="solicitation-audience-select"
              control={control}
              register={register}
              component="RadioButton"
              currentValue={currentPeriod}
              isReadonly={readonlyFields?.includes(audienceProps.name)}
            >
              <ReadOnlyAudience solPeriod={rawSolPeriod} />
            </SolicitationPeriodField>

            {/* <SolicitationPeriodField
              idPrefix={idPrefix}
              {...audienceProps}
              data-testid="solicitation-audience-select"
              control={control}
              register={register}
              component="SelectDropdown"
              currentValue={audienceProps.defaultValue}
              isReadonly={readonlyFields?.includes(audienceProps.name)}
            />
            {selectedAudienceValue === 'S' && (
              <SolicitationPeriodField
                idPrefix={idPrefix}
                {...vendorProps}
                data-testid="solicitation-vendor-selection"
                control={control}
                register={register}
                component="SelectDropdown"
                options={[
                  { label: 'Vendor 1', value: 'v1' },
                  { label: 'Vendor 2', value: 'v2' },
                ]}
                isReadonly={readonlyFields?.includes(vendorProps.name)}
              />
            )} */}
          </div>
          {children && (
            <>
              <br />
              {children}
            </>
          )}
        </div>
      </div>
    </>
  );
};

SolicitationPeriod.defaultProps = {
  minDate: '',
  maxDate: undefined,
  title: 'Open period',
  titleHint: '',
  contractYear: '',
  currentSolicitationPeriod: {},
  solicitationOprType: '',
  stepperStage: '',
  formWrapperClassName: '',
  children: '',
  readonlyFields: [],
  endDateTimeMsg: '',
  idPrefix: '',
  solicitationStatus: undefined,
  rawSolPeriod: null,
};

SolicitationPeriod.propTypes = {
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  title: PropTypes.string,
  titleHint: PropTypes.string,
  contractYear: PropTypes.number,
  currentSolicitationPeriod: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }),
  solicitationOprType: PropTypes.string,
  stepperStage: PropTypes.string,
  formWrapperClassName: PropTypes.string,
  children: PropTypes.node,
  readonlyFields: PropTypes.arrayOf(
    PropTypes.oneOf([
      startDateProps.name,
      startTimeProps.name,
      endDateProps.name,
      endTimeProps.name,
      audienceProps.name,
      vendorProps.name,
    ]),
  ),
  endDateTimeMsg: PropTypes.string,
  idPrefix: PropTypes.string,
  solicitationStatus: PropTypes.string,
  rawSolPeriod: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }),
};

export default SolicitationPeriod;
