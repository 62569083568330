/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import { createConditionalExpandableColumn } from '../../../../../utilities/table-utils';
import {
  getAudienceValues,
  isOpenSeasonAudienceType,
} from '../../../utils/solicitation-period-utils';

export const ReopenPeriodcolumns = [
  createConditionalExpandableColumn(isOpenSeasonAudienceType),
  {
    Header: 'Period',
    accessor: 'period',
    sortable: true,
  },
  {
    Header: 'Posting date',
    accessor: 'startDate',
    Cell: ({ value }) =>
      `${moment(value).format('MM/DD/YYYY')}  ${moment(value).format(
        'hh:mm A',
      )}`,
    sortable: true,
  },
  {
    Header: 'Closing date',
    accessor: 'endDate',
    Cell: ({ value }) =>
      `${moment(value).format('MM/DD/YYYY')}  ${moment(value).format(
        'hh:mm A',
      )}`,
    sortable: true,
  },
  {
    Header: 'Audience',
    accessor: 'audience',
    sortable: false,
    Cell: ({
      // eslint-disable-next-line react/prop-types
      row: { original },
    }) => getAudienceValues(original),
  },
];
