import React from 'react';
import { PageTitle } from '@gsa/afp-component-library';
import Breadcrumbs from '../../widgets/breadcrumbs';

import CommentAdminMessages from './comment-admin-messages';
import CommentsAdminProvider from './comments-admin-provider';
import CommentsAdminTable from './comments-admin-table';

const CommentsAdmin = () => {
  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs.home}/home`,
      label: 'Home',
    },
    {
      label: 'Federal Vehicle Standards',
      location: `${window.AFP_CONFIG.appURLs.catalog}/catalog/vehicle-standards`,
    },
  ];

  return (
    <CommentsAdminProvider>
      <div className="grid-col">
        <Breadcrumbs current="Comment Management" path={breadcrumbsPath} />
        <CommentAdminMessages />
        <PageTitle
          title="Comment Management"
          aria-label="Comment Management"
          tabIndex="0"
        />
        <CommentsAdminTable />
      </div>
    </CommentsAdminProvider>
  );
};

export default CommentsAdmin;
