// ! Some components in this file are used in contract/line-template page
/** Constants */
export const ASSOCIATION_TYPE_CODE = {
  STANDARD: 'S',
  OPTIONAL: 'O',
  ENGINE: 'E', // front-end only
};

export const INPUT_TYPE_CODE = {
  NUMERICAL: 'N',
  RANGE: 'R',
  DROPDOWN: 'P',
  TEXT: 'F',
  COMPLY: 'C',
};
export const INPUT_DROPDOWN_OTHER_OPTION = {
  label: 'Other',
  value: '_other_',
};
export const INPUT_DROPDOWN_DEFAULT_OPTION = {
  label: '-Select-',
  value: '',
};

export const CRITERIA_CODE = {
  MIN: 'MIN',
  MAX: 'MAX',
  EXACT: 'EXA',
};

export const EC_CLARIFICATION_TYPE = {
  exception: 'E',
  explanation: 'C',
};

export const EC_OPTION_TYPE = {
  standard: 'Standard',
  standalone: 'Standalone',
  na: 'NA',
};

export const EC_CONFLICT_TYPE = {
  includes: 'I',
  excludes: 'E',
  requires: 'R',
};

export const EC_QUANTITY_REQUIRED_LIST = ['V', 'O'];

export const ATTACH_TYPE = {
  doc: 'Document',
  photo: 'Photo',
};

export const COLOR_TYPE = {
  standard: 'Standard',
  optional: 'Premium',
};

export const SI_MPG_TAG = 'MPG';
export const SI_OCONUS_TAG = 'OCONUS';
export const SI_GPTKM_TAG = 'GPTKM';
export const EC_MPG_TAG = 'MPG';
export const BASE_ENGINE_VIRTUAL_CODE = '_BASE_ENGINE';
export const BASE_ENGINE_EC = {
  id: 0,
  categoryCode: BASE_ENGINE_VIRTUAL_CODE,
  equipmentCode: BASE_ENGINE_VIRTUAL_CODE,
  associationTypeCode: ASSOCIATION_TYPE_CODE.ENGINE,
  catSequence: -1,
  sequence: -1,
  equipment: 'Base Engine',
};

export const CHARGE_TYPES = ['Level 1', 'Level 2', 'Level 3 DC fast charge'];
export const FAST_CHARGE_TYPE = CHARGE_TYPES[2];
export const DC_FAST_CHARGE_TYPES = [
  'CCS1',
  'CCS2',
  'CHAdeMO',
  'Tesla (NACS)',
  'Other',
];
export const DELIMITER = ',';

export const ENGINE_INPUT_MAP = [
  { code: '10', displacement: true, range: true, mpgC: true },
  { code: '20', displacement: true, range: true, mpgC: true },
  { code: '21', displacement: true, range: true, mpgC: true },
  { code: '24', displacement: true, range: true, mpgC: true, mpgA: true },
  { code: '31', displacement: true, range: true, mpgC: true, mpgA: true },
  { code: '41', displacement: true, range: true, mpgC: true, mpgA: true },
  { code: '50', displacement: true, range: true, mpgA: true },
  { code: '54', displacement: true, range: true, mpgC: true, mpgA: true },
  { code: '60', displacement: true, range: true, mpgA: true },
  { code: '64', displacement: true, range: true, mpgC: true, mpgA: true },
  { code: '70', rangeE: true, charging: true, mpgE: true },
  {
    code: '71',
    displacement: true,
    range: true,
    rangeE: true,
    charging: true,
    mpgC: true,
    mpgE: true,
  },
  {
    code: '72',
    displacement: true,
    range: true,
    rangeE: true,
    charging: true,
    mpgC: true,
    mpgE: true,
  },
  {
    code: '73',
    displacement: true,
    range: true,
    rangeE: true,
    charging: true,
    mpgC: true,
    mpgA: true,
    mpgE: true,
  },
  { code: '74', displacement: true, range: true, mpgC: true },
  { code: '75', displacement: true, range: true, mpgC: true },
  { code: '76', displacement: true, range: true, mpgA: true },
  { code: '78', displacement: true, range: true, mpgA: true },
  { code: '79', displacement: true, range: true, mpgA: true },
  { code: '80', displacement: true, range: true, mpgA: true },
];

/** Helper functions */
export const number2string = (value) => {
  if (typeof value !== 'number') return '';
  return value.toString();
};
export const string2number = (value) => {
  const val = parseFloat(value);
  return Number.isNaN(val) ? null : val;
};
export const bool2string = (value) => {
  if (typeof value !== 'boolean') return '';
  return value ? 'Y' : 'N';
};
export const string2bool = (value) => {
  if (value === 'Y') return true;
  if (value === 'N') return false;
  return null;
};

/**
 * Helpers for Equipment Code data
 */
export const isBidDetailTabReady = (bidDetailData, locationData) => {
  const isVehicleInfoReady =
    (bidDetailData.isIn1122Program ||
      typeof bidDetailData.isIn1122Program === 'boolean') &&
    bidDetailData.shipmentDays > 0;
  const isPriceInfoReady =
    bidDetailData.unitPrice >= 1 &&
    bidDetailData.invoicePrice >= 1 &&
    bidDetailData.msrp >= 1 &&
    bidDetailData.destinationCharge >= 1 &&
    (!bidDetailData.hasSPLTag || bidDetailData.asePrice >= 1);
  const isLocationReady = locationData.length > 0;
  return isVehicleInfoReady && isPriceInfoReady && isLocationReady;
};
export const isEcTabReady = (tabData) =>
  tabData ? tabData.every((item) => item.data.every((ec) => ec.ready)) : false;
export const isEngineTabReady = (tabData) =>
  tabData ? tabData.every((item) => item.ready) : false;
export const isColorsTabReady = (tabData) =>
  tabData ? tabData.length > 0 : false;
export const isDocumentationTabReady = (docs = [], pics = []) =>
  pics.length > 0 && pics.length + docs.length <= 10;

export const isMinReqItemReady = (input, comply, exception) => {
  if (!comply) return undefined;
  if (!input) return false;
  if (comply === 'Y') return true;
  return !!exception;
};

export const isOptEqItemReady = (
  optionType,
  maxQuantity,
  unitPrice,
  quantityRequired,
) => {
  if (!optionType) return undefined;
  if (optionType === EC_OPTION_TYPE.na) return true;
  if (EC_QUANTITY_REQUIRED_LIST.includes(quantityRequired) && !maxQuantity)
    return false;
  return !(optionType === EC_OPTION_TYPE.standalone && unitPrice === '');
};

export const isInactiveOptionType = (optionType) =>
  !optionType || optionType === EC_OPTION_TYPE.na;

const getClarification = (bidLineEc, type) =>
  bidLineEc?.clarifications.find((c) => c.clarificationType === type)
    ?.clarification || '';

const parseConflict = (ecRelations, field) => {
  const relation = ecRelations?.find(
    (c) => c.relationshipCode === EC_CONFLICT_TYPE[field],
  );
  if (!relation) return { value: [] };

  const mapEcs = (obj) => obj.ecs.map((ec) => ec.relatedEc);
  const value =
    field === 'requires'
      ? relation.conflicts.relations.map((rel) => mapEcs(rel)) // ec[][]
      : mapEcs(relation.conflicts); // ec[]
  return { id: relation.id, value };
};

export const ecTitleLookup = (equipmentCode, ecList) => {
  const ec = ecList.find((e) => e.equipmentCode === equipmentCode);
  return ec?.equipment || equipmentCode;
};

const processEcItem = (item, index, bidLineECs) => {
  const {
    code,
    title,
    categoryCode,
    sequence,
    tags,
    quantityRequired,
  } = item.equipmentCode;
  const equipment = `${code} - ${title}`;

  const bidLineEc = bidLineECs.find((ec) => ec.associationId === +item.id);
  const inputValue = bidLineEc?.value || '';
  const inputException = getClarification(
    bidLineEc,
    EC_CLARIFICATION_TYPE.exception,
  );
  const inputExplanation = getClarification(
    bidLineEc,
    EC_CLARIFICATION_TYPE.explanation,
  );
  const inputOptionType = bidLineEc?.optionType || '';
  const inputMaxQuantity = bidLineEc?.maxQuantity?.toFixed(0) || '';
  const inputUnitPrice =
    bidLineEc?.unitPrice == null ? '' : bidLineEc.unitPrice.toString();
  const inputShipmentDays = bidLineEc?.shipmentDays?.toFixed(0) || '';

  const comply = bool2string(bidLineEc?.comply);

  const commonData = {
    id: +item.id,
    bidLineEcId: bidLineEc ? +bidLineEc.id : undefined,
    categoryCode: categoryCode.code,
    categoryTitle: categoryCode.title,
    equipmentCode: code,
    equipment,
    catSequence: categoryCode.additionalProps?.sequence,
    sequence,
    associationTypeCode: item.associationTypeCode,
    inputExplanation,
    inputException,
  };
  const standardData =
    item.associationTypeCode === ASSOCIATION_TYPE_CODE.STANDARD
      ? {
          inputTypeCode: item.inputTypeCode || INPUT_TYPE_CODE.TEXT,
          lowerBound: item.lowerBound,
          upperBound: item.upperBound,
          preDefinedValue: item.preDefinedValue,
          unitCode: item.unitCode,
          criteriaCode: item.criteriaCode,
          associationText: item.associationText,
          inputValue,
          comply,
          ready: isMinReqItemReady(inputValue, comply, inputException),
        }
      : {};
  const optionalData =
    item.associationTypeCode === ASSOCIATION_TYPE_CODE.OPTIONAL
      ? {
          quantityRequired,
          inputTypeCode: 'OPT',
          inputOptionType,
          inputMaxQuantity,
          inputUnitPrice,
          inputShipmentDays,
          includes: parseConflict(bidLineEc?.ecRelations, 'includes'),
          excludes: parseConflict(bidLineEc?.ecRelations, 'excludes'),
          requires: parseConflict(bidLineEc?.ecRelations, 'requires'),
          hasMpgTag: tags.value.includes(EC_MPG_TAG),
          ready: isOptEqItemReady(
            inputOptionType,
            inputMaxQuantity,
            inputUnitPrice,
            quantityRequired,
          ),
        }
      : {};

  return {
    index,
    title: categoryCode.title,
    id: categoryCode.code,
    expanded: false,
    data: [{ ...commonData, ...standardData, ...optionalData }],
    tableData: [
      {
        iCat: index,
        iData: 0,
        id: commonData.id,
        categoryCode: commonData.categoryCode,
        equipment,
        sequence,
        isExpanded: false,
      },
    ],
  };
};

const categorizeData = (data, bidLineECs) => {
  if (!data.length) return [];

  // sort category by sequence then title
  const sorted = data.sort(
    (
      { equipmentCode: { categoryCode: catA } },
      { equipmentCode: { categoryCode: catB } },
    ) => {
      if (catA.additionalProps?.sequence === catB.additionalProps?.sequence)
        return catA.title < catB.title ? -1 : 1;
      return catA.additionalProps?.sequence < catB.additionalProps?.sequence
        ? -1
        : 1;
    },
  );

  const categories = [processEcItem(sorted[0], 0, bidLineECs)];
  let index = 0;
  for (let i = 1; i < sorted.length; i += 1) {
    const item = processEcItem(sorted[i], index + 1, bidLineECs);
    if (item.id === categories[index].id) {
      categories[index].data.push(item.data[0]);
      categories[index].tableData.push({
        ...item.tableData[0],
        iCat: index,
        iData: categories[index].data.length - 1,
      });
    } else {
      categories.push(item);
      index += 1;
    }
  }

  categories.forEach((cat) => {
    // sort EC by sequence then title - reversed
    cat.tableData.sort((a, b) => {
      if (a.sequence === b.sequence) return a.equipment < b.equipment ? 1 : -1;
      return a.sequence < b.sequence ? 1 : -1;
    });
  });

  return categories;
};

/**
 * Helpers for Engine and Fuel data
 */
const sortEngineECsFn = (a, b) => {
  if (a.catSequence === b.catSequence) return a.sequence < b.sequence ? 1 : -1;
  return a.catSequence < b.catSequence ? 1 : -1;
};

const processEngineMpgItem = (fuelType, type, engineMgps) => {
  const mpg = engineMgps.find(
    (m) => m.fuelType === fuelType && m.type === type,
  );
  return number2string(mpg?.value);
};

const processEngineMpg = (fuelType, engineMgps) => {
  const mpg = {};
  [
    { type: 'City', key: 'city' },
    { type: 'Highway', key: 'highway' },
    { type: 'Combined', key: 'combined' },
    { type: 'Grams/mi', key: 'gpmi' },
  ].forEach((item) => {
    mpg[`${item.key}Input`] = processEngineMpgItem(
      fuelType,
      item.type,
      engineMgps,
    );
  });
  return mpg;
};

export const getFuelInputMap = (fuelTypeCodeId, FuelTypes) => {
  if (!fuelTypeCodeId) return {};
  const fuelType = FuelTypes.find((ft) => fuelTypeCodeId - ft.id === 0);
  return ENGINE_INPUT_MAP.find((item) => fuelType?.code === item.code) ?? {};
};

export const isEngineDataReady = (data) => {
  const isMpgReady = (mpg, isMpgE = false) =>
    mpg.cityInput &&
    mpg.highwayInput &&
    mpg.combinedInput &&
    (isMpgE || mpg.gpmiInput);
  const isFastChargeTypesReady = (fcTypes) => {
    const selected = Object.values(fcTypes).filter((item) => item.checked);
    return (
      selected.length > 0 &&
      selected.every(
        (item) =>
          item.chargeOption &&
          (item.fastChargeType !== 'Other' || item.userEnteredType),
      )
    );
  };
  const isChargingReady = (charging) => {
    if (
      charging.typeInput.includes(FAST_CHARGE_TYPE) &&
      charging.isFastChargeCapableInput !== 'Y'
    )
      return false;
    return (
      charging.typeInput.length &&
      charging.bkwhInput &&
      charging.isFastChargeCapableInput &&
      (charging.isFastChargeCapableInput === 'N' ||
        isFastChargeTypesReady(charging.fcTypes))
    );
  };

  if (!data.fuelTypeCodeInput) return false;
  if (
    !data.requiresOCONUS &&
    (!data.deliveryRegionType ||
      (data.deliveryRegionType === 'Other' && !data.deliveryStatesInput.length))
  )
    return false;

  if (Object.keys(data.fuelInputMap).length === 0) return true;

  const fuelMap = data.fuelInputMap;

  if (
    data.requiresMPG &&
    !data.requiresOCONUS &&
    !data.isFuelDataUnknown &&
    ((fuelMap.mpgC && !isMpgReady(data.mpgC)) ||
      (fuelMap.mpgA && !isMpgReady(data.mpgA)) ||
      (fuelMap.mpgE && !isMpgReady(data.mpgE, true)))
  )
    return false;

  return (
    (!fuelMap.displacement ||
      (data.cylindersInput && data.engineLitresInput)) &&
    (!fuelMap.range ||
      !data.requiresMPG ||
      fuelMap.rangeE ||
      data.requiresOCONUS ||
      data.isFuelDataUnknown ||
      data.rangeInput) &&
    (!fuelMap.rangeE || data.rangeElectricInput) &&
    (!fuelMap.charging || isChargingReady(data.charging))
  );
};

const getEmptyFcTypeObject = (fcType) => ({
  checked: false,
  fastChargeType: fcType,
  chargeOption: '',
  userEnteredType: '',
});

export const resetFcTypes = () => {
  const result = {};
  DC_FAST_CHARGE_TYPES.forEach((fcType) => {
    result[fcType] = getEmptyFcTypeObject(fcType);
  });
  return result;
};

const processEngineItem = (ecItem, bidLineEngines, FuelTypes, siTags) => {
  const engineData = bidLineEngines.find(
    (eng) => eng.associationId === +ecItem.id,
  );
  const fuelInputMap = getFuelInputMap(engineData?.fuelTypeCodeId, FuelTypes);

  const getFastChargeTypes = (fcTypes = []) => {
    const result = {};
    DC_FAST_CHARGE_TYPES.forEach((fcType) => {
      const fcObj = fcTypes.find((fct) => fct.fastChargeType === fcType);
      result[fcType] = fcObj
        ? {
            checked: true,
            fastChargeType: fcType,
            chargeOption: fcObj.chargeOption || '',
            userEnteredType: fcObj.userEnteredType || '',
          }
        : getEmptyFcTypeObject(fcType);
    });
    return result;
  };

  const inputItem = {
    // control data
    id: ecItem.id,
    bidLineEngineId: engineData?.id,
    categoryCode: ecItem.categoryCode,
    equipmentCode: ecItem.equipmentCode,
    associationTypeCode: ecItem.associationTypeCode,
    catSequence: ecItem.catSequence,
    sequence: ecItem.sequence,
    fuelInputMap,
    requiresMPG: siTags.includes(SI_MPG_TAG),
    requiresOCONUS: siTags.includes(SI_OCONUS_TAG),
    requiresGPTKM: siTags.includes(SI_GPTKM_TAG),
    isMetric: engineData?.isMetric,
    isGPTKM: engineData?.isGPTKM,
    // table row data
    equipment: ecItem.equipment,
    engineModel: engineData?.engineModel || '',
    fuelTypeCodeInput: number2string(engineData?.fuelTypeCodeId),
    ready: false,
    // delivery data
    deliveryRegionType: engineData?.deliveryRegionType || '',
    deliveryStatesInput: engineData?.deliveryStates
      ? engineData.deliveryStates.split(DELIMITER)
      : [],
    // fuel data - flag
    isFuelDataUnknown: !!engineData?.isFuelDataUnknown,
    // fuel data - engine displacement
    cylindersInput: number2string(engineData?.cylinders),
    engineLitresInput: number2string(engineData?.engineLitres),
    // fuel data - range
    rangeInput: number2string(engineData?.range),
    rangeElectricInput: number2string(engineData?.rangeElectric),
    // fuel data - charging blocks
    charging: {
      typeInput: engineData?.charging?.type
        ? engineData.charging.type.split(DELIMITER)
        : [],
      isFastChargeCapableInput: bool2string(
        engineData?.charging?.isFastChargeCapable,
      ),
      fcTypes: getFastChargeTypes(engineData?.charging?.fastChargeTypes),
      bkwhInput: number2string(engineData?.charging?.bkwh),
    },
    // fuel data - mpg blocks
    mpgC: processEngineMpg('C', engineData?.mpgs || []),
    mpgA: processEngineMpg('A', engineData?.mpgs || []),
    mpgE: processEngineMpg('E', engineData?.mpgs || []),
    // optional clarification
    explanation: engineData?.clarification?.clarification || '',
  };
  inputItem.ready = isEngineDataReady(inputItem);
  return inputItem;
};

const processEngineData = (optionalEcData, bidLine, FuelTypes) => {
  const engineECs = [BASE_ENGINE_EC];
  optionalEcData.forEach((cat) => {
    engineECs.push(
      ...cat.data.filter(
        (ec) =>
          ec.hasMpgTag && ec.inputOptionType === EC_OPTION_TYPE.standalone,
      ),
    );
  });
  return engineECs
    .map((item) =>
      processEngineItem(
        item,
        bidLine.engines,
        FuelTypes,
        bidLine?.standardItem?.tags?.value || [],
      ),
    )
    .sort(sortEngineECsFn);
};

export const mapEngineDataToTable = ({ id, equipment }) => ({
  id,
  equipment,
  isExpanded: false,
});

export const deleteEngineLine = (id, setEngineData, setEngineTable) => {
  // update table FIRST
  setEngineTable((prev) => prev.filter((e) => e.id !== id));
  setEngineData((prev) => prev.filter((e) => e.id !== id));
};

export const addEngineLine = (
  ecItem,
  setEngineData,
  setEngineTable,
  siTags,
) => {
  const newItem = processEngineItem(ecItem, [], [], siTags);
  setEngineData((prev) => {
    const newEngines = [...prev];
    newEngines.unshift(newItem);
    newEngines.sort(sortEngineECsFn);
    setEngineTable(newEngines.map(mapEngineDataToTable));
    return newEngines;
  });
};

/**
 * Helper function to generate provider data from equipment code data and
 * saved bid line data for 'Minimum requirements', 'Optional equipment',
 * and 'Engine and fuel' tabs
 */
export const processEquipmentCodesData = (data, bidLine, FuelTypes) => {
  const dataS = data.filter(
    (item) =>
      item.equipmentCode &&
      item.associationTypeCode === ASSOCIATION_TYPE_CODE.STANDARD,
  );
  const dataO = data.filter(
    (item) =>
      item.equipmentCode &&
      item.associationTypeCode === ASSOCIATION_TYPE_CODE.OPTIONAL,
  );

  const standard = categorizeData(dataS, bidLine.equipmentCodes);
  const optional = categorizeData(dataO, bidLine.equipmentCodes);
  const engines = processEngineData(optional, bidLine, FuelTypes);
  return { standard, optional, engines };
};

/** Helper function to get equipment code lookup list from optional equipment */
export const getOptionalEcList = (optionalECs) => {
  const ecList = [];
  optionalECs.forEach((cat) => {
    ecList.push(
      ...cat.data.map(({ id, inputOptionType, equipmentCode, equipment }) => ({
        id,
        inputOptionType,
        equipmentCode,
        equipment,
      })),
    );
  });
  return ecList;
};

/** Helper functions to check conflict dependencies */
export const hasConflicts = (original) =>
  ['includes', 'excludes', 'requires'].some(
    (field) => original[field].value.length > 0,
  );

export const getConflictDependencies = (original, optionalECs) => {
  const isDependencyOf = (ecItem) => {
    if (original.id === ecItem.id) return false;
    if (ecItem.includes.value.includes(original.equipmentCode)) return true;
    if (ecItem.excludes.value.includes(original.equipmentCode)) return true;
    return ecItem.requires.value.some((ecs) =>
      ecs.includes(original.equipmentCode),
    );
  };
  const dependencies = [];
  optionalECs.forEach((cat) => {
    cat.data.forEach((ecItem) => {
      if (isDependencyOf(ecItem))
        dependencies.push({
          categoryTitle: ecItem.categoryTitle,
          equipment: ecItem.equipment,
        });
    });
  });
  return dependencies;
};
