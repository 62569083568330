/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CounterTag,
  EmptyState,
  Accordion,
  MultiSelect,
  Checkbox,
} from '@gsa/afp-component-library';

const ProgramSection = ({
  value,
  selections,
  selectedStandardItems,
  setSelections,
  setHasAssociatedLineItems,
}) => {
  const [selectedVehcileGroup, setselectedVehcileGroup] = useState({});
  const [currSelected, setCurrSelected] = useState(null);
  const [isVehTypesAllChecked, setIsVehTypesAllChecked] = useState({});
  const [isVehGroupAllChecked, setIsVehGroupAllChecked] = useState(false);

  const associatedLineItems = selectedStandardItems
    .filter((item) => item?.scheduleLine)
    .reduce(
      (prev, curr) => ({
        ...prev,
        [curr.soliLineSi]: curr.scheduleLine,
      }),
      {},
    );

  useEffect(() => {
    const checkedObj = {};
    Object.entries(selectedVehcileGroup).forEach(([vehTypeKey, sis]) => {
      checkedObj[vehTypeKey] = sis.every((si) =>
        selections.find(
          (item) =>
            item.vehGrp === currSelected &&
            item.soliLineSi === `${si?.id}-${si?.solicitationLineID}`,
        ),
      );
    });
    setIsVehTypesAllChecked(checkedObj);
  }, [selections, currSelected, setselectedVehcileGroup]);
  useEffect(() => {
    const typeChecked = Object.values(isVehTypesAllChecked);
    setIsVehGroupAllChecked(typeChecked.length && typeChecked.every((c) => c));
  }, [isVehTypesAllChecked]);

  const getGroupedVehicleTypesWithSI = (selectedVehGroup, selectedSIs) => {
    const groupedSIByVehicleType = selectedSIs.reduce((acc, curr) => {
      const { code, title } = curr.vehicleTypeCode;
      const vehType = `${code}-${title}`;
      if (!acc[vehType]) acc[vehType] = [];
      acc[vehType].push({
        id: curr.id,
        number: curr.standardItemNumber,
        title: curr.title,
        status: curr.status,
        solicitationLineID: curr.solicitationLineID,
        vehicleGroup: selectedVehGroup,
        vehicleType: vehType,
      });
      return acc;
    }, {});
    setselectedVehcileGroup(groupedSIByVehicleType);
    setCurrSelected(selectedVehGroup);
  };

  const handleSelectStandardItem = (item, selectedVehGroup) => {
    const existingSelection = selections.find(
      (selection) =>
        selection.vehGrp === selectedVehGroup && selection.soliLineSi === item,
    );
    if (existingSelection) {
      const updatedSelections = selections.filter(
        (selection) =>
          !(
            selection.vehGrp === selectedVehGroup &&
            selection.soliLineSi === item
          ),
      );
      const associatedItemsLength = updatedSelections.filter(
        (si) => si.scheduleLine,
      ).length;
      const hasAssociatedLineItems = Object.keys(associatedLineItems).length === associatedItemsLength
   

        setHasAssociatedLineItems(!hasAssociatedLineItems);
     

      setSelections(updatedSelections);
    } else {
      const newSelections = {
        vehGrp: selectedVehGroup,
        soliLineSi: item,
        scheduleLine: associatedLineItems[item] || null,
      };
      const mergedSelections = [...selections, newSelections];
    
      const associatedItemsLength = mergedSelections.filter(
        (si) => si.scheduleLine,
      ).length;
      const hasAssociatedLineItems = Object.keys(associatedLineItems).length === associatedItemsLength
      setHasAssociatedLineItems(!hasAssociatedLineItems);
    
      setSelections(mergedSelections);
    }
  };

  const getSelectedSIs = (vehGrp, SIs) => {
    return selections
      .filter(
        (selection) =>
          selection.vehGrp === vehGrp &&
          SIs.some(
            (si) =>
              selection.soliLineSi === `${si?.id}-${si?.solicitationLineID}`,
          ),
      )
      .map((selection) => selection.soliLineSi);
  };

  const handleSelectAll = (checked, sis) => {
    setSelections((prev) => {
      if (checked) {
        const newVal = [...prev];
        sis.forEach((si) => {
          const siItem = {
            vehGrp: currSelected,
            soliLineSi: `${si?.id}-${si?.solicitationLineID}`,
            scheduleLine:
              associatedLineItems[`${si?.id}-${si?.solicitationLineID}`] ||
              null,
          };
          if (
            !newVal.find(
              (item) =>
                item.vehGrp === siItem.vehGrp &&
                item.soliLineSi === siItem.soliLineSi,
            )
          )
            newVal.push(siItem);
        });
        setHasAssociatedLineItems(false);
        return newVal;
      }
     
      if (Object.keys(associatedLineItems).length > 0) {
        setHasAssociatedLineItems(true);
      }
      return prev.filter(
        (item) =>
          !sis.some(
            (si) =>
              item.vehGrp === currSelected &&
              item.soliLineSi === `${si?.id}-${si?.solicitationLineID}`,
          ),
      );
    });
  };

  const handleSelectVehicleType = (checked, vehTypeKey) => {
    const [vehType] = vehTypeKey.split('-');
    const sis = value[currSelected].filter((si) => si.vehicleType === vehType);
    handleSelectAll(checked, sis);
  };

  const handleSelectVehicleGroup = (checked) => {
    const sis = value[currSelected];
    handleSelectAll(checked, sis);
  };

  return (
    <div className="columns-wrapper grid-row flex-row margin-top-2 grid-gap-3">
      {/** Vehicle Groups selection section */}
      <div className="grid-col flex-4">
        <div className="header text-primary">
          <span ariaLable="Selected programs">Vehicle groups</span>
        </div>
        {Object.entries(value).map(([vehGroupKey, vehGroupSIs]) => {
          const count = getSelectedSIs(vehGroupKey, vehGroupSIs).length;
          return (
            <ul key={vehGroupKey} className="usa-sidenav">
              <li className="">
                <a
                  style={{ cursor: 'pointer' }}
                  className={
                    vehGroupKey === currSelected ? 'usa-current' : null
                  }
                  onClick={() => {
                    getGroupedVehicleTypesWithSI(vehGroupKey, vehGroupSIs);
                  }}
                  ariaLable={vehGroupKey}
                >
                  {vehGroupKey}
                  {count > 0 && (
                    <CounterTag count={count} className="margin-left-1" />
                  )}
                </a>
              </li>
            </ul>
          );
        })}
      </div>
      {/** Standard Items selection section */}
      <div className="grid-col flex-8">
        <div className="header text-primary">
          <div className="display-flex flex-row flex-align-end margin-bottom-1">
            <div className="">
              <span ariaLable="Standard items">Standard items</span>
            </div>
            {Object.keys(selectedVehcileGroup).length > 0 && (
              <div className="margin-left-2">
                <Checkbox
                  data-testid={`select-all-${currSelected}`}
                  checked={isVehGroupAllChecked}
                  label={isVehGroupAllChecked ? 'Unselect all' : 'Select all'}
                  className="text-checkbox"
                  onChange={({ target: { checked } }) => {
                    handleSelectVehicleGroup(checked);
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {Object.keys(selectedVehcileGroup).length > 0 ? (
          Object.entries(selectedVehcileGroup).map(
            ([vehTypeKey, vehTypeSIs], vGindex) => {
              const { vehicleGroup } = vehTypeSIs[0];
              const content = (
                <div>
                  {vehTypeSIs.length > 1 && (
                    <div className="width-card-lg padding-bottom-105 border-bottom-1px border-gray-30">
                      <Checkbox
                        data-testid={`select-all-${vehTypeKey}`}
                        checked={isVehTypesAllChecked[vehTypeKey]}
                        label={
                          isVehTypesAllChecked[vehTypeKey]
                            ? 'Unselect all'
                            : 'Select all'
                        }
                        className="text-checkbox"
                        onChange={({ target: { checked } }) => {
                          handleSelectVehicleType(checked, vehTypeKey);
                        }}
                      />
                    </div>
                  )}
                  <MultiSelect
                    data-testid={`solicitation-std-item-multiselect-${vGindex}`}
                    options={vehTypeSIs.map((item) => {
                      return {
                        label: `${item.number} - ${item.title}`,
                        value: `${item.id}-${item.solicitationLineID}`,
                      };
                    })}
                    onChange={(v) => handleSelectStandardItem(v, vehicleGroup)}
                    selectedValues={getSelectedSIs(vehicleGroup, vehTypeSIs)}
                  />
                </div>
              );

              return (
                <div
                  key={vehTypeKey}
                  className="select-standard-item-accordion"
                >
                  <Accordion
                    multiselectable
                    bordered
                    className="margin-top-1"
                    items={[{ title: vehTypeKey, content }]}
                  />
                </div>
              );
            },
          )
        ) : (
          <EmptyState
            ariaLable="No Standard Item available."
            topText="Select a vehicle group to view Standard Items."
            containerStyles="padding-y-2 margin-top-1"
            hasBackground
          />
        )}
      </div>
    </div>
  );
};

ProgramSection.propTypes = {
  value: PropTypes.shape(PropTypes.object).isRequired,
  selections: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedStandardItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSelections: PropTypes.func.isRequired,
  setHasAssociatedLineItems: PropTypes.func.isRequired
};

export default ProgramSection;
