import React from 'react';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { PageTitle } from '@gsa/afp-component-library';
import OverlaySpinner from '../../../components/overlay-spinner';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import BidHistoryTable from './bid-history-table';
import { GET_CLOSED_BIDS_BY_VENDOR_ID } from '../bids.gql';

const BidHistory = () => {
  const location = useLocation();
  const { id, vendorName, uniqueEntityIdentifier } =
    location.state?.vendorData || {};

  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs?.home}/home`,
      label: 'Home',
    },
    {
      location: '/catalog/bids',
      label: 'Bids Management',
    },
  ];

  const headerText =
    'Here is a list of available solicitations and/or bids that have been closed.';

  const { data, loading: isDataLoading } = useQuery(
    GET_CLOSED_BIDS_BY_VENDOR_ID,
    {
      fetchPolicy: 'network-only',
      variables: {
        vendorId: id,
      },
      skip: !id,
    },
  );

  return (
    <>
      <Breadcrumbs current="Bids History" path={breadcrumbsPath} />
      <div className="grid-row">
        <div className="grid-col flex-4">
          <PageTitle
            title="Bids History"
            aria-label="Bids History"
            tabIndex="0"
          />
          <div className="margin-top-neg-2 margin-bottom-5">{headerText}</div>
        </div>
        <div className="grid-col flex-2 margin-top-3 flex-align-end">
          {vendorName && (
            <div className="display-inline">
              {vendorName || '-'}, {uniqueEntityIdentifier || '-'}
            </div>
          )}
          <div
            className="margin-top-1 flex-align-end"
            aria-label="Bid management"
          >
            {/* eslint-disable-next-line */}
            <a href="/catalog/bids">Bid management</a>
          </div>
        </div>
      </div>

      <div className="grid-row">
        {isDataLoading && <OverlaySpinner />}
        {!isDataLoading && (
          <BidHistoryTable bidData={data?.getClosedBidsByVendorId} />
        )}
      </div>
    </>
  );
};

export default BidHistory;
