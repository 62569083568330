/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import '../../comment-admin.scss';
import { emDashUnicode } from '../../../../../utilities/constants';

const CommentReply = ({ reply }) => {
  return (
    <div className="margin-bottom-4">
      <div className="reply-main">
        <span className="text-bold padding-right-1">
          {reply.createdByUserInfo?.fullName || emDashUnicode}
        </span>{' '}
        <span className="padding-right-1">
          {' '}
          {moment(reply.createdAt).format('l')} &bull;{' '}
          {moment(reply.createdAt).format('h:mm a')}
        </span>
      </div>
      <p className="margin-top-05">{reply.comment}</p>
    </div>
  );
};

CommentReply.propTypes = {};

export default CommentReply;
