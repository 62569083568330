import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import MakeTypeAhead from '../components/body-make-typeahead';
import ChassisMakeTypeAhead from '../components/chassis-make-typeahead';
import ModelTypeAhead from '../components/body-model-typeahead';
import ChassisModelTypeAhead from '../components/chassis-model-typeahead';
import {
  ModelMakeApprovalStatuses,
  MAKE_MODEL_APPROVAL_STATUS_MAP,
} from '../constants';

const MakeModelFilter = ({ handleFilterChange }) => {
  const [selectedBodyMakeCode, setSelectedBodyMakeCode] = useState('');
  const [selectedChassisMakeCode, setSelectedChassisMakeCode] = useState('');

  const handleFilter = (updatedFilters) => {
    const filters = updatedFilters.flatMap((filter) => {
      if (filter.key === 'bodyMake') {
        const makeCode = filter.value;
        setSelectedBodyMakeCode(makeCode);
        return {
          operator: '$exact',
          key: 'makeCode',
          value: makeCode,
        };
      }
      if (filter.key === 'chassisMake') {
        const chMakeCode = filter.value;
        setSelectedChassisMakeCode(chMakeCode);
        return {
          operator: '$exact',
          key: 'chassisMakeCode',
          value: chMakeCode,
        };
      }
      if (filter.key === 'bodyModel') {
        const modelCode = filter.value;
        return {
          operator: '$exact',
          key: 'modelCode',
          value: modelCode,
        };
      }
      if (filter.key === 'chassisModel') {
        const modelCode = filter.value;
        return {
          operator: '$exact',
          key: 'chassisModelCode',
          value: modelCode,
        };
      }

      if (filter.key === 'fleetApprovalStatus') {
        return {
          operator: '$in',
          key: 'fleetApprovalStatus',
          value: filter.value.map((v) =>
            String(MAKE_MODEL_APPROVAL_STATUS_MAP[v]),
          ),
        };
      }

      if (filter.key === 'approvedByUser') {
        if (filter.value === 'gsaEng' || filter.value?.includes('gsaEng')) {
          return { ...filter, operator: '$ne', value: 'auto-sync' };
        }
        return { ...filter, operator: '$exact', value: 'auto-sync' };
      }

      return filter;
    });
    handleFilterChange(filters);
  };
  return (
    <FilterPanel.FilterPanel
      filterStructure={[
        {
          title: 'Body Make',
          key: 'bodyMake',
          id: 'bodyMake',
          operator: '$exact',
          value: '',
          component: MakeTypeAhead,
          isSingleValueFilter: true,
          permanent: false,
          expanded: true,
          hideClear: true,
          customFieldProps: {
            inputCharNum: 1,
            clearPanelFilterOnEmpty: true,
          },
        },
        {
          title: 'Body Model',
          key: 'bodyModel',
          id: 'bodyModel',
          operator: '$exact',
          value: '',
          component: ModelTypeAhead,
          isSingleValueFilter: true,
          permanent: false,
          expanded: true,
          hideClear: true,
          customFieldProps: {
            inputCharNum: 1,
            clearPanelFilterOnEmpty: true,
            makeCode: selectedBodyMakeCode,
          },
        },
        {
          title: 'Chassis Make',
          key: 'chassisMake',
          id: 'chassisMake',
          operator: '$exact',
          value: '',
          component: ChassisMakeTypeAhead,
          isSingleValueFilter: true,
          permanent: false,
          expanded: true,
          hideClear: true,
          customFieldProps: {
            inputCharNum: 1,
            clearPanelFilterOnEmpty: true,
          },
        },
        {
          title: 'Chassis Model',
          key: 'chassisModel',
          id: 'chassisModel',
          operator: '$exact',
          value: '',
          component: ChassisModelTypeAhead,
          isSingleValueFilter: true,
          permanent: false,
          expanded: true,
          customFieldProps: {
            inputCharNum: 1,
            clearPanelFilterOnEmpty: true,
            makeCode: selectedChassisMakeCode,
          },
        },
        {
          title: 'Engineer review status',
          key: 'fleetApprovalStatus',
          id: 'fleetApprovalStatus',
          type: 'multiselect',
          value: [],
          options: Object.keys(ModelMakeApprovalStatuses).map(
            (k) => ModelMakeApprovalStatuses[k],
          ),
          permanent: false,
          operator: '$in',
          hideClear: true,
          expanded: true,
        },
      ]}
      clearButtonLabel="Reset All"
      showClearIcon
      setQueryFiltersState={handleFilter}
      model="AfpModel"
      order={[['id', 'ASC']]}
      subTitle="Apply filters"
      titleSeparator
      showSearchIcon
      retainFilters={false}
    />
  );
};

MakeModelFilter.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
};

export default MakeModelFilter;
