import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Can, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { EmptyState } from '@gsa/afp-component-library';
import OverlaySpinner from '../../../components/overlay-spinner';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import {
  VehicleStandardsHeader,
  VehicleStandardsFilter,
  VehicleStandardsInfo,
  FVSSelectSI,
  VehicleStandardsModalWrapper,
  VehicleStandardsActionsPanel,
  VehicleStandardsRelatedInfo,
} from './components';
import { RoleIDs } from '../../../utilities/types/role-types';
import FvsLandingPageProvider from './fvs-landing-page-provider';
import { useFederalStandards } from '../fvs-provider';
import FvsMessages from '../components/fvs-messages';
import { OPERATIONS, SUBJECTS } from '../../../utilities/constants';
import { useFvsPreBidContext } from '../fvs-pre-bid-selection-provider';
import PsoDashboard from '../components/pso-dashboard';
import ToastMessage from '../../../components/Toast/toast';

const LandingPage = () => {
  const {
    selectedYear,
    vehicleStandard,
    getOpenCommentingPeriodCount,
    commentingPeriodsData,
    fvsMessages,
    clearAllMessages,
    isApplicableFvsYearsLoading,
    isFVSOptionsForYearLoading,
  } = useFederalStandards();

  const {
    currentUser: { roles },
  } = useCurrentUser();

  const hasValidRole = () => {
    const hasVS = roles?.some((obj) => obj?.id === RoleIDs.VS);
    const hasVM = roles?.some((obj) => obj?.id === RoleIDs.VM);

    return (hasVS && !hasVM) || (hasVS && hasVM);
  };

  const { preBidSelectionDataForVendor } = useFvsPreBidContext();

  const [docReviewRec, setDocReviewRec] = useState(null);

  useEffect(() => {
    return () => {
      // Clear generated messages before unload
      clearAllMessages();
    };
  }, []);

  useEffect(() => {
    setDocReviewRec(null);
    if (
      preBidSelectionDataForVendor &&
      preBidSelectionDataForVendor?.docsReview
    ) {
      let drForFvs;
      if (vehicleStandard) {
        drForFvs = preBidSelectionDataForVendor.docsReview.find(
          (d) => d.fvsId === vehicleStandard?.id,
        );
        if (drForFvs) {
          setDocReviewRec(drForFvs);
          return;
        }
      }

      drForFvs = preBidSelectionDataForVendor.docsReview.find(
        (d) => d.reviewStatus !== 1,
      );
      if (drForFvs) {
        setDocReviewRec(drForFvs);
      }
    }
  }, [preBidSelectionDataForVendor, vehicleStandard]);

  useEffect(() => {
    getOpenCommentingPeriodCount();
  }, []);

  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs.home}/home`,
      label: 'Home',
    },
  ];

  const defaultFilters = {
    operator: 'AND',
    value: [
      {
        key: 'year',
        operator: 'EQ',
        value: selectedYear,
      },
      {
        key: 'vehicleType',
        operator: 'EQ',
        value: null,
      },
      {
        key: 'vehicleGroup',
        operator: 'EQ',
        value: null,
      },
      {
        key: 'fedStandardCode',
        operator: 'EQ',
        value: null,
      },
    ],
  };

  const [, setFilters] = useState(defaultFilters);
  const [canShowDashboard, setCanShowDashboard] = useState(false);

  useEffect(() => {
    setCanShowDashboard(false);
    if (commentingPeriodsData?.psoCommentPeriodCounts?.length > 0) {
      commentingPeriodsData?.psoCommentPeriodCounts.forEach((cp) => {
        if (cp.year === selectedYear && cp?.openCount > 0) {
          setCanShowDashboard(true);
        }
      });
    }
  }, [commentingPeriodsData, selectedYear]);

  return (
    <FvsLandingPageProvider>
      {(isApplicableFvsYearsLoading || isFVSOptionsForYearLoading) && (
        <OverlaySpinner />
      )}
      <Breadcrumbs current="Federal Vehicle Standards" path={breadcrumbsPath} />
      {commentingPeriodsData?.allOpenCount > 0 && (
        <ToastMessage
          key="commenting_period_count_message"
          message={
            <span>
              There are currently {commentingPeriodsData?.allOpenCount} open
              commenting periods.
              <Link
                to="/catalog/vehicle-standards/commenting-periods"
                className="padding-left-1"
              >
                View commenting periods
              </Link>
            </span>
          }
          type="info"
          closeable={false}
        />
      )}
      {fvsMessages && <FvsMessages />}
      <div>
        <div className="grid-row grid-gap flex-justify">
          <VehicleStandardsHeader />
          <div className="desktop:grid-col-3 display-flex desktop:flex-justify-end desktop:margin-top-3">
            <VehicleStandardsActionsPanel />
          </div>
        </div>

        <div className="grid-row grid-gap">
          <div className="desktop:grid-col-12 tablet-lg:grid-col-12 padding-bottom-2 margin-right-2 margin-left-2">
            <Can I={OPERATIONS.Create} a={SUBJECTS.PSOQuestionnaire}>
              <>
                {/* PRE-BID SELECTION DASHBOARD ONLY FOR VEHICLE SUPPLIER/PSO */}
                {canShowDashboard && hasValidRole() && (
                  <PsoDashboard
                    year={selectedYear}
                    docsCount={preBidSelectionDataForVendor?.counts?.docsCount}
                    standardItemsCount={
                      preBidSelectionDataForVendor?.counts?.selectionCount
                    }
                    docsReviewed={
                      preBidSelectionDataForVendor?.counts?.docsCount -
                      preBidSelectionDataForVendor?.counts?.reviewedDocsCount
                    }
                    standardItemsReviewed={
                      preBidSelectionDataForVendor?.counts?.selectionCount -
                      preBidSelectionDataForVendor?.counts
                        ?.reviewedSelectionCount
                    }
                    reviewFvsId={docReviewRec?.fvsId}
                  />
                )}
              </>
            </Can>
          </div>

          <div className="desktop:grid-col-3 tablet-lg:grid-col-3 standard-table-filters-wrapper">
            <div className="fvs-mobile-sticky-wrapper">
              <div className="fvs-mobile-sticky-track">
                <div className="fvs-mobile-sticky-container">
                  <VehicleStandardsFilter
                    onFilter={(filterValue) => {
                      setFilters(filterValue);
                    }}
                  />
                  {/* <MobileActionsPanel /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="desktop:grid-col-9 standard-table-wrapper tbl">
            {vehicleStandard?.id ? (
              <>
                <VehicleStandardsInfo />
                <FVSSelectSI />
                <VehicleStandardsRelatedInfo />
              </>
            ) : (
              <EmptyState
                hasBackground
                containerStyles="padding-y-8 margin-top-1 height-full"
                topText={
                  <>
                    <strong>No Federal Vehicle Standard selected</strong>
                    <br />
                    <p>
                      Start with a Contract year and choose a Federal Standard
                      to inspect on the left
                    </p>
                  </>
                }
                topTextStyles="margin-top-7"
              />
            )}
          </div>
        </div>
        <VehicleStandardsModalWrapper />
      </div>
    </FvsLandingPageProvider>
  );
};

export default LandingPage;
