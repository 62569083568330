import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Button, Icon, Spinner } from '@gsa/afp-component-library';
import { SET_CONTRACTLINE_ATTACHMENTS_ORDER } from './query';
import { useContractLine } from '../provider/contract-line-provider';

const ImageOrderUpDown = ({ rows, row }) => {
  const isFirstRow = row.id === '0';
  const isLastRow = row.id === String(rows.length - 1);
  const { loadContractLineAttachments } = useContractLine();
  const [movingType, setMovingType] = useState(null);

  const [setContractLineAttachmentsOrder, { loading }] = useMutation(
    SET_CONTRACTLINE_ATTACHMENTS_ORDER,
    {
      onCompleted: () => {
        loadContractLineAttachments();
        setMovingType(null);
      },
      onError: () => {
        setMovingType(null);
      },
    },
  );

  const orderedIds = rows.map((r) => parseInt(r.original.id, 10));

  const moveUp = (attachmentId) => {
    const currentIndex = orderedIds.indexOf(parseInt(attachmentId, 10));
    if (currentIndex === 0) return;
    const newOrderedIds = [...orderedIds];
    const temp = newOrderedIds[currentIndex - 1];
    newOrderedIds[currentIndex - 1] = newOrderedIds[currentIndex];
    newOrderedIds[currentIndex] = temp;
    setMovingType('up');
    setContractLineAttachmentsOrder({
      variables: {
        contractLineId: parseInt(row.original.contractLineId, 10),
        orderedAttachmentIds: newOrderedIds,
      },
    });
  };

  const moveDown = (attachmentId) => {
    const currentIndex = orderedIds.indexOf(parseInt(attachmentId, 10));
    if (currentIndex === orderedIds.length - 1) return;
    const newOrderedIds = [...orderedIds];
    const temp = newOrderedIds[currentIndex + 1];
    newOrderedIds[currentIndex + 1] = newOrderedIds[currentIndex];
    newOrderedIds[currentIndex] = temp;
    setMovingType('down');
    setContractLineAttachmentsOrder({
      variables: {
        contractLineId: parseInt(row.original.contractLineId, 10),
        orderedAttachmentIds: newOrderedIds,
      },
    });
  };

  if (rows.length === 1) return null;

  return (
    <div className="margin-1" style={{ minWidth: 96 }}>
      {isLastRow ? (
        <div style={{ width: 56, display: 'inline-block' }} />
      ) : (
        <Button
          data-testid="move-down"
          variant="unstyled"
          aria-label="Move image down"
          className="margin-right-2"
          disabled={loading}
          onClick={() => moveDown(row.original.id, 10)}
          onKeyDown={(evt) => {
            if (evt.key === 'Enter') {
              moveDown(row.original.id);
            }
          }}
          label={
            <div
              style={{
                width: 40,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {loading && movingType === 'down' ? (
                <Spinner size="small" />
              ) : (
                <Icon iconName="arrow_downward" style={{ color: 'black' }} />
              )}
            </div>
          }
        />
      )}
      {isFirstRow ? null : (
        <Button
          variant="unstyled"
          data-testid="move-up"
          disabled={loading}
          aria-label="Move image up"
          onClick={() => moveUp(row.original.id)}
          onKeyPress={(evt) => {
            if (evt.key === 'Enter') {
              moveUp(row.original.id);
            }
          }}
          label={
            <div
              style={{
                width: 40,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {loading && movingType === 'up' ? (
                <Spinner size="small" />
              ) : (
                <Icon iconName="arrow_upward" style={{ color: 'black' }} />
              )}
            </div>
          }
        />
      )}
    </div>
  );
};

ImageOrderUpDown.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      contractLineId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ImageOrderUpDown;
