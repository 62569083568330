import React, { useState } from 'react';
import { pick } from 'lodash';
import EquipmentCodeForm from '../../components/forms/equipment-code-form';
import EquipmentCodeFormContext from '../../components/forms/equipment-code-form-context';
import { useEquipmentCodes } from './equipment-codes-provider';

const EquipmentCodesAdd = () => {
  const { addEquipmentCode, multipleOptions } = useEquipmentCodes();

  const [equipmentCodeId, setEqID] = useState('');

  const onSubmit = (data) => {
    const tags = { value: [] };
    if (data?.tags) {
      tags.value = data.tags;
    }
    const filteredData = pick(data, [
      'category',
      'code',
      'content',
      'program',
      'quantityRequired',
      'sequence',
      'status',
      'title',
      'unit',
    ]);

    addEquipmentCode({
      variables: {
        equipmentcode: {
          ...filteredData,
          tags,
          year: Number(data?.year),
          copyFromEquipmentCodeId: data?.copyFromEquipmentCodeId,
          copyFromYear: Number(data?.copyFromYear),
        },
      },
    });
  };
  const categoryOptions = multipleOptions
    ?.filter(
      (o) => o?.type === 'standardsCodeModel' && o?.uniqueKey === 'category',
    )
    .map((o) => ({ ...o, label: `${o.value} - ${o.label}` }));

  const programOptions = multipleOptions
    ?.filter(
      (o) => o?.type === 'standardsCodeModel' && o?.uniqueKey === 'program',
    )
    .map((o) => ({ ...o, label: `${o.label}` }));

  const isEqualOrGreaterThanCurrentYear = (year) => {
    const currentYear = multipleOptions.find((o) => o.currentYear === true);
    return year >= currentYear.value;
  };
  const isYearOption = (o) => o?.type === 'year' && o?.uniqueKey === 'year';

  const yearOptions = multipleOptions
    ?.filter((o) => isYearOption(o) && isEqualOrGreaterThanCurrentYear(o.value))
    .reverse();

  const tagOptions = multipleOptions
    ?.filter((o) => o?.type === 'standardsCodeModel' && o?.uniqueKey === 'tags')
    .map((o) => ({ ...o, label: `${o.label}` }));

  const unitOptions = [
    { label: '- Select -', value: '' },
    ...multipleOptions
      ?.filter(
        (o) => o?.type === 'standardsCodeModel' && o?.uniqueKey === 'units',
      )
      .map((o) => ({ ...o, label: `${o.value} - ${o.label}` })),
  ];

  const quantityRequiredOptions = [
    { label: 'Select', value: '' },
    ...multipleOptions
      ?.filter(
        (o) =>
          o?.type === 'standardsCodeModel' &&
          o?.uniqueKey === 'quantityRequired',
      )
      .map((o) => ({ ...o, label: `${o.value} - ${o.label}` })),
  ];

  return (
    <>
      <EquipmentCodeFormContext>
        <EquipmentCodeForm
          yearOptions={yearOptions}
          categoryOptions={categoryOptions}
          quantityRequiredOptions={quantityRequiredOptions}
          unitOptions={unitOptions}
          tagOptions={tagOptions}
          formId="equipment-code-add"
          handleSave={(d) => {
            onSubmit(d);
          }}
          updateEquipmentCodeId={(id) => {
            setEqID(id);
          }}
          equipmentCodeId={equipmentCodeId}
          isAddForm
          typeAheadError
          programs={programOptions}
        />
      </EquipmentCodeFormContext>
    </>
  );
};

export default EquipmentCodesAdd;
