/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import { getFilterStructure } from './comments-admin-filter-structure';
import { useCommentsAdmin } from './comments-admin-provider';
import { options } from './helpers';
import {
  AUTHOR_FILTER,
  PUBLISH_STATUS_FILTER,
  STATUS_FILTER,
} from './constants';

const filterKeys = [
  'status',
  'publishStatus',
  'userType',
  'origin',
  'fedStandardCode',
  'myComments',
];

const ORIGIN_MAP = {
  EC: 2,
  SI: 0,
  FVS: 1,
};

const CommentsFilters = ({ currentFilters, setFilters }) => {
  const { getMultipleOptions, groupMultipleOptions } = useCommentsAdmin();
  const { FilterPanel: CommentsAdminFilterPanel } = FilterPanel;

  useEffect(() => {
    getMultipleOptions({
      variables: {
        options,
      },
    });
  }, []);

  const handleFiltersChange = (updatedFilters) => {
    const filterByKeys = updatedFilters?.reduce(
      (filterObj, currentFilterObj) => {
        return { ...filterObj, [currentFilterObj?.key]: currentFilterObj };
      },
      {},
    );

    const newFilter = {
      ...currentFilters,
      value: [...updatedFilters.filter((f) => !filterKeys.includes(f.key))],
    };

    if (filterByKeys?.publishStatus) {
      const publishStatusFilter = {
        operator: 'OR',
        value: [],
      };
      filterByKeys?.publishStatus?.value?.forEach((v) => {
        publishStatusFilter.value.push({
          operator: 'EQ',
          key: 'publishStatus',
          value: PUBLISH_STATUS_FILTER[v],
        });
      });
      newFilter.value.push(publishStatusFilter);
    }

    if (filterByKeys?.status) {
      const statusFilter = {
        operator: 'OR',
        value: [],
      };
      filterByKeys?.status?.value?.forEach((v) => {
        statusFilter.value.push({
          operator: 'EQ',
          key: 'status',
          value: STATUS_FILTER[v],
        });
      });
      newFilter.value.push(statusFilter);
    }

    if (filterByKeys?.userType) {
      const userFilter = {
        operator: 'OR',
        value: [],
      };
      filterByKeys?.userType?.value?.forEach((v) => {
        userFilter.value.push({
          operator: 'EQ',
          key: 'authorType',
          value: AUTHOR_FILTER[v],
        });
      });
      newFilter.value.push(userFilter);
    }

    if (filterByKeys?.origin) {
      const originFilter = {
        operator: 'OR',
        value: [],
      };
      filterByKeys?.origin?.value?.forEach((v) => {
        originFilter.value.push({
          operator: 'EQ',
          key: 'linkedEntityType',
          value: ORIGIN_MAP[v],
        });
      });
      newFilter.value.push(originFilter);
    }

    if (filterByKeys?.fedStandardCode) {
      const value = [];
      filterByKeys?.fedStandardCode?.value?.forEach((v) => {
        value.push({ operator: 'EQ', key: 'fvsCode', value: v });
      });
      if (value.length) newFilter.value.push({ operator: 'OR', value });
    }

    setFilters(newFilter);
  };

  return (
    <CommentsAdminFilterPanel
      clearButtonLabel="Reset All"
      filterStructure={getFilterStructure(groupMultipleOptions)}
      model="commentAdmin"
      order="createdAt DESC"
      setQueryFiltersState={handleFiltersChange}
      showClearIcon
      retainFilters={false}
    />
  );
};

CommentsFilters.propTypes = {
  currentFilters: PropTypes.shape({
    operator: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]),
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default CommentsFilters;
