import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@gsa/afp-component-library';

const SiSelectionConfirmationModal = ({ closeModal, handleSave }) => {
  return (
    <Modal
      id="si-selection-confirmation-modal"
      variant="large"
      className="solicitation-modal"
      onClose={closeModal}
      actions={
        <>
          <Button
            type="button"
            variant="unstyled"
            onClick={closeModal}
            label="Cancel"
          />
          <Button
            data-testid="modal-save-changes-button"
            type="button"
            onClick={() => {
              handleSave();
            }}
            label="Save Changes"
          />
        </>
      }
    >
      <div className="modal-content">
        <h2>Confirmation</h2>

        <p>
          There are line items associated to the standard item(s) you are
          unselecting. Removing the standard item will also remove the
          associated line items from the bid.
        </p>
      </div>
    </Modal>
  );
};

SiSelectionConfirmationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default SiSelectionConfirmationModal;
