import React from 'react';
import { Button } from '@gsa/afp-component-library';
import Modal from '../../../../components/Modal/Modal';
import ScheduleCommentingContent from './schedule-commenting-periods';
import AttachmentAddForm from '../../../../components/Attachment/AttachmentAddForm';
import RTEPopover from './rte-popover';
import { MODAL_MODES } from '../constants';
import { useFederalStandards } from '../../fvs-provider';
import { useFvsLandingPageContext } from '../fvs-landing-page-provider';
import { useFvsSupportingDocs } from '../../fvs-supporting-docs-provider';
import PublishStandard from './fvs-publish';

const ModalTitle = () => {
  const { vehicleStandard } = useFederalStandards();
  const { modalMode } = useFvsLandingPageContext();

  let title = '';

  switch (modalMode) {
    case MODAL_MODES.PUBLISH:
      title = 'Publish documents';
      break;
    case MODAL_MODES.UPLOAD:
      title = 'Upload file';
      break;
    case MODAL_MODES.SCHEDULE_COMMENTING:
      title = `Scheduling commenting period for ${vehicleStandard?.fedStandard?.code} - ${vehicleStandard?.fedStandard?.title}`;
      break;
    default:
      break;
  }
  return (
    <div className="margin-top-5">
      <div className="title-l">{title}</div>
      {modalMode === MODAL_MODES.SCHEDULE_COMMENTING && (
        <span className="body">
          Please select the start and end dates of the corresponding user group
        </span>
      )}
    </div>
  );
};

const ModalContent = () => {
  const { vehicleStandard } = useFederalStandards();

  const {
    modalMode,
    setFvsLandingPageData,
    headerCopy,
    infoCopy,
  } = useFvsLandingPageContext();

  const {
    addState,
    uploadFvsDocument,
    saveDocument,
    attachmentErrors,
    getStandardsSupportingDocs,
    fetchDocumentVariables,
  } = useFvsSupportingDocs();

  const handleDocUpload = async (formData) => {
    const docMetadata = await uploadFvsDocument({
      variables: {
        input: {
          fvsId: Number.parseInt(vehicleStandard?.id, 10),
          fvsCode: vehicleStandard?.fedStandardCode,
          name: formData.get('attachment').name,
          description: formData.get('description'),
          size: formData.get('attachment').size,
          fileMimeType: formData.get('attachment').type,
          status: formData.get('status'),
        },
      },
    });
    if (docMetadata?.data?.uploadSupportingDoc) {
      await saveDocument(formData, docMetadata.data.uploadSupportingDoc);
      getStandardsSupportingDocs(fetchDocumentVariables);
      setFvsLandingPageData('SET_MODAL_MODE', null);
    }
  };

  if (modalMode === MODAL_MODES.PUBLISH) return <PublishStandard />;
  if (modalMode === MODAL_MODES.UPLOAD)
    return (
      <AttachmentAddForm
        addState={addState}
        onAdd={handleDocUpload}
        attachmentErrors={attachmentErrors}
      />
    );

  if (modalMode === MODAL_MODES.SCHEDULE_COMMENTING)
    return <ScheduleCommentingContent />;

  if (modalMode === MODAL_MODES.HEADER_RTE)
    return <RTEPopover boardType={1} copy={headerCopy} formId="headerRTE" />;

  if (modalMode === MODAL_MODES.INFO_RTE)
    return <RTEPopover boardType={2} copy={infoCopy} formId="infoRTE" />;

  return null;
};

const ModalAction = () => {
  const { modalMode, setFvsLandingPageData } = useFvsLandingPageContext();

  const { addState, setAddState } = useFvsSupportingDocs();

  const onCancel = () => {
    setFvsLandingPageData('SET_MODAL_MODE', null);
  };

  if (modalMode === MODAL_MODES.PUBLISH) {
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="equipment-code-edit-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="primary"
          type="submit"
          form="fvs-effective-date-form"
          data-testid="equipment-code-edit-save-btn"
          label="Publish"
        />
      </>
    );
  }

  if (modalMode === MODAL_MODES.SCHEDULE_COMMENTING) {
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="schedule-commenting-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="primary"
          type="submit"
          form="commenting-period-schedule-form"
          data-testid="schedule-commenting-submit-btn"
          label="Schedule commenting periods"
        />
      </>
    );
  }

  if (modalMode === MODAL_MODES.UPLOAD) {
    return (
      <>
        <Button
          data-testid="attachment-add-cancel-button"
          disabled={addState?.adding}
          variant="unstyled"
          className="margin-right-2"
          onClick={() => {
            onCancel();
            setAddState({ show: false, adding: false, added: false });
          }}
          label="Cancel"
        />
        <Button
          data-testid="attachment-add-upload-button"
          disabled={addState?.adding}
          type="submit"
          form="attachment-upload-form"
          className="add-document-button action-button"
          label={addState?.adding ? 'Uploading...' : 'Add File'}
        />
      </>
    );
  }

  if (modalMode === MODAL_MODES.HEADER_RTE) {
    return (
      <>
        <Button
          onClick={onCancel}
          className="margin-right-105"
          variant="unstyled"
          data-testid="cancel-edit-announcement"
          aria-label="Cancel"
          label="Cancel"
        />
        <Button
          variant="primary"
          data-testid="save-edit-announcement"
          aria-label="Save"
          type="submit"
          form="headerRTE"
          label="Save"
        />
      </>
    );
  }

  if (modalMode === MODAL_MODES.INFO_RTE) {
    return (
      <>
        <Button
          onClick={onCancel}
          className="margin-right-105"
          variant="unstyled"
          data-testid="cancel-edit-announcement"
          aria-label="Cancel"
          label="Cancel"
        />
        <Button
          variant="primary"
          data-testid="save-edit-announcement"
          aria-label="Save"
          type="submit"
          form="infoRTE"
          label="Save"
        />
      </>
    );
  }

  return null;
};

const VehicleStandardsModalWrapper = () => {
  const { showFormModal, setFvsLandingPageData } = useFvsLandingPageContext();
  return (
    <Modal
      title={<ModalTitle />}
      actions={<ModalAction />}
      show={showFormModal}
      onClose={() => {
        setFvsLandingPageData('SET_MODAL_MODE', null);
      }}
    >
      <ModalContent />
    </Modal>
  );
};

export default VehicleStandardsModalWrapper;
