import { useEffect, useRef } from 'react';

/**
 * A custom hook that extends useEffect.
 * Use as useEffect for dependencies that are not required
 * to run on component first render/mount.
 */
const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

export default useDidMountEffect;
