import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import {
  AFPTable,
  AFPTableRowAction,
  EmptyState,
  Button,
} from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

const AdminActiveBidVendors = ({ bidData }) => {
  const history = useHistory();

  const handleSelectedAction = (evt, row) => {
    if (evt === 'View bid history') {
      history.push('/catalog/bids/bid-center/bid-history', {
        vendorData: {
          id: row.original.vendorDetail.id,
          vendorName: row.original.vendorDetail.vendorName,
          uniqueEntityIdentifier:
            row.original.vendorDetail.uniqueEntityIdentifier,
        },
      });
    }
    if (evt === 'View bid dashboard') {
      const { id: bidId, soliciation } = row?.original;
      history.push(
        `/catalog/solicitations/${soliciation?.id}/bid-dashboard/${bidId}`,
      );
    }
  };

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Solicitation number (uPIID)',
        accessor: 'soliciation.solicitationNumber',
        sortable: false,
      },
      {
        Header: 'Vendor name',
        accessor: 'vendorDetail.vendorName',
        sortable: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value, row }) => (
          // button wrapped by a div for testing reason
          <div data-testid={value}>
            <Button
              variant="unstyled"
              data-testid="navigate-to-bid-dashboard"
              onClick={() => {
                // eslint-disable-next-line
                window.location.href = `${window.AFP_CONFIG.appURLs.home}/vendor/details/${row?.original?.vendorDetail?.id}`;
              }}
              label={value}
            />
          </div>
        ),
      },
      {
        Header: 'UEI',
        accessor: 'vendorDetail.uniqueEntityIdentifier',
        sortable: false,
      },
      {
        Header: 'Actions',
        sortable: false,
        Cell: (props) => {
          return (
            <AFPTableRowAction
              {...props}
              actions={[
                {
                  label: 'View bid dashboard',
                  icon: 'arrow_back',
                },
                {
                  label: 'View bid history',
                  icon: 'history',
                },
              ]}
              // eslint-disable-next-line
              onSelectAction={(evt) => handleSelectedAction(evt, props.row)}
            />
          );
        },
      },
    ];
    return columnList;
  }, []);

  return (
    <div className="desktop:grid-col-12">
      <div
        className="text-primary bid-header"
        data-testid="admin-active-bids-header"
        aria-label="ACTIVE VENDORS"
      >
        ACTIVE VENDORS
      </div>
      {bidData && (
        <AFPTable
          testId="admin-active-bids-table"
          columns={columns}
          data={bidData || []}
        />
      )}
      {!bidData.length && (
        <div className="table-empty-state" aria-label="No results found">
          <EmptyState bottomText="No results found." />
        </div>
      )}
    </div>
  );
};

AdminActiveBidVendors.propTypes = {
  bidData: PropTypes.arrayOf(PropTypes.array).isRequired,
};

export default AdminActiveBidVendors;
