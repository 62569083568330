import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  TextInput,
  RequiredFieldIndicator,
  Icon,
} from '@gsa/afp-component-library';
import {
  AUTO_SAVE_BID_LINE_DETAILS,
  GET_BID_LINE_PREVIOUS_PRICE,
  GET_PAST_YEAR_STANDARD_ITEM_IDS,
} from '../../provider/queries';
import { useBidLineDetails } from '../../provider/bid-line-details-provider';
import {
  emDashUnicode,
  emSpaceUnicode,
} from '../../../../../utilities/constants';

export const formatPrice = (price, style, currency) => {
  return new Intl.NumberFormat('en-US', {
    style,
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  }).format(price);
};

const validationSchema = yup.object().shape({
  unitPrice: yup
    .number()
    .integer('Current price must be a whole number.')
    .transform((value) =>
      value === '' || Number.isNaN(value) ? undefined : value,
    )
    .min(1, 'Current price must be $1 or more.')
    .max(999999999, 'Current price must be less than $1,000,000,000')
    .required('Current price is required.'),
  invoicePrice: yup
    .number()
    .integer('Net Invoice price must be a whole number.')
    .transform((value) =>
      value === '' || Number.isNaN(value) ? undefined : value,
    )
    .min(1, 'Net Invoice price must be $1 or more.')
    .max(999999999, 'Net Invoice price must be less than $1,000,000,000')
    .required('Net Invoice price is required'),
  msrp: yup
    .number()
    .integer('Net MSRP must be a whole number.')
    .transform((value) =>
      value === '' || Number.isNaN(value) ? undefined : value,
    )
    .min(1, 'Net MSRP must be $1 or more.')
    .max(999999999, 'Net MSRP must be less than $1,000,000,000')
    .required('Net MSRP is required.'),
  asePrice: yup
    .number()
    .integer('AS&E unit Price must be a whole number.')
    .transform((value) =>
      value === '' || Number.isNaN(value) ? undefined : value,
    )
    .min(1, 'AS&E unit Price must be $1 or more.')
    .max(999999999, 'AS&E unit Price must be less than $1,000,000,000')
    .required('AS&E price is required.'),
  quantity: yup
    .number()
    .transform((value) =>
      value === '' || Number.isNaN(value) ? undefined : value,
    )
    .moreThan(0, 'Allocation quantity must be 1 or more.'),
  destinationCharge: yup
    .number()
    .required('Destination charge is required.')
    .integer('Destination charge must be a whole number.')
    .transform((value) =>
      value === '' || Number.isNaN(value) ? undefined : value,
    )
    .min(1, 'Destination charge must be $1 or more.')
    .test(
      'lessThanUnitPrice',
      'Destination charge must be less than the current price.',
      function lessThanUnitPrice(value) {
        const unitPrice = parseInt(this.resolve(yup.ref('unitPrice')), 10);
        return value < unitPrice;
      },
    ),
});

const PriceInfoSection = () => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [prevPrice, setPrevPrice] = useState(null);
  const [standardItemIds, setStandardItemIds] = useState([]);
  const {
    bidDetailData,
    bidLine,
    updateBidDetailData,
    vendorData,
    isAdmin,
  } = useBidLineDetails();
  const hasSPLTag = bidLine?.standardItem?.tags?.value.some(
    (tag) => tag === 'SPL',
  );
  const { control, register, getValues, errors } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      unitPrice: bidDetailData?.unitPrice || bidLine?.unitPrice,
      invoicePrice: bidDetailData?.invoicePrice || bidLine?.invoicePrice,
      msrp: bidDetailData?.msrp || bidLine?.msrp,
      asePrice: bidDetailData?.asePrice || bidLine?.asePrice,
      quantity: bidDetailData?.quantity || bidLine?.quantity,
      destinationCharge:
        bidDetailData?.destinationCharge || bidLine?.destinationCharge,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useQuery(GET_PAST_YEAR_STANDARD_ITEM_IDS, {
    variables: {
      sin: bidLine?.standardItem?.standardItemNumber,
    },
    onCompleted: (data) => {
      setStandardItemIds(data?.getPastYearStandardItemIdsBySINs);
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: !bidLine?.standardItem?.standardItemNumber,
  });

  const { data: prevPriceData } = useQuery(GET_BID_LINE_PREVIOUS_PRICE, {
    variables: {
      vendorId: vendorData?.id,
      standardItemIds,
      modelYear: bidLine?.modelYear,
      ...(bidLine?.afpMake?.makeCode && {
        makeCode: parseInt(bidLine?.afpMake?.makeCode, 10),
      }),
      modelCode: bidLine?.afpModel?.modelCode,
      ...(bidLine?.chassisMake?.makeCode && {
        chassisMakeCode: parseInt(bidLine?.chassisMake?.makeCode, 10),
      }),
      chassisModelCode: bidLine?.chassisModel?.modelCode,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: !vendorData?.id || !standardItemIds?.length,
  });

  const genLabel = (label, required) => (
    <span className="text-bold" data-testid={label}>
      {label} {required && <RequiredFieldIndicator />}
    </span>
  );

  useEffect(() => {
    // eslint-disable-next-line
    bidLine &&
      setTotalPrice(
        formatPrice(
          bidLine?.unitPrice * +bidLine?.solicitationLine?.estimatedQty,
          'currency',
          'USD',
        ),
      );
  }, []);

  useEffect(() => {
    if (prevPriceData?.getPastContractPriceForSIN) {
      setPrevPrice(
        formatPrice(
          prevPriceData?.getPastContractPriceForSIN,
          'currency',
          'USD',
        ),
      );
    }
  }, [prevPriceData]);

  const handelOnChange = ({ target: { value, name } }) => {
    if (name === 'unitPrice') {
      setTotalPrice(
        formatPrice(
          value ? +value * +bidLine?.solicitationLine?.estimatedQty : 0,
          'currency',
          'USD',
        ),
      );
    }
  };

  const [autoSaveBidLineDetails] = useMutation(AUTO_SAVE_BID_LINE_DETAILS);

  const autoSave = async (name, value) => {
    if (
      value === undefined ||
      value === null ||
      (name === 'quantity' && value <= 0) ||
      (name !== 'quantity' && value < 1) ||
      isAdmin
    )
      return;
    autoSaveBidLineDetails({
      variables: {
        input: {
          bidLineId: bidLine?.id,
          [name]: value,
        },
      },
    });
    updateBidDetailData(name, value);
  };

  const handleBlur = async (field) => {
    const value = getValues(field);
    autoSave(field, +value);
  };

  return (
    <>
      <form>
        <div className="grid-row margin-top-1  margin-bottom-2">
          <div className="grid-col flex-1 margin-top-3">
            <div className="body-bold" data-testid="privious price">
              {/* TODO: should be fetched from contract */}
              Previous price
            </div>
            <span> {prevPrice || emDashUnicode} </span>
          </div>
          <div className="grid-col flex-3">
            <div className="width-mobile">
              <Controller
                name="unitPrice"
                control={control}
                render={({ value, onChange, onBlur }) => (
                  <div>
                    <TextInput
                      name="unitPrice"
                      ref={register}
                      value={value === 0 ? emSpaceUnicode : value}
                      data-testid="current_price"
                      help="Current price must include base vehicle price and destination charge."
                      type="number"
                      min={0}
                      label={genLabel('Current price', true)}
                      prefix={<Icon iconName="attach_money" />}
                      errorMessage={
                        errors.unitPrice && errors.unitPrice.message
                      }
                      onChange={(e) => {
                        onChange(e);
                        handelOnChange(e);
                      }}
                      onBlur={(e) => {
                        if (!errors.unitPrice) {
                          onBlur(e);
                          handleBlur('unitPrice', e.target.value);
                        }
                      }}
                    />
                  </div>
                )}
              />
            </div>
          </div>
        </div>

        <div className="grid-row margin-top-4">
          <div className="grid-col flex-1">
            <Controller
              name="invoicePrice"
              control={control}
              render={({ value, onChange, onBlur }) => (
                <div className="width-card">
                  <TextInput
                    name="invoicePrice"
                    ref={register}
                    value={value}
                    data-testid="net_price"
                    label={genLabel('Net Invoice price', true)}
                    type="number"
                    min={0}
                    prefix={<Icon iconName="attach_money" />}
                    errorMessage={
                      errors.invoicePrice && errors.invoicePrice.message
                    }
                    onChange={onChange}
                    onBlur={(e) => {
                      if (!errors.invoicePrice) {
                        onBlur(e);
                        handleBlur('invoicePrice', e.target.value);
                      }
                    }}
                  />
                </div>
              )}
            />
          </div>
          <div className="grid-col flex-1">
            <Controller
              name="msrp"
              control={control}
              render={({ value, onChange, onBlur }) => (
                <div className="width-card">
                  <TextInput
                    name="msrp"
                    ref={register}
                    value={value}
                    data-testid="msrp"
                    label={genLabel('Net MSRP', true)}
                    type="number"
                    min={0}
                    prefix={<Icon iconName="attach_money" />}
                    errorMessage={errors.msrp && errors.msrp.message}
                    onChange={onChange}
                    onBlur={(e) => {
                      if (!errors.msrp) {
                        onBlur(e);
                        handleBlur('msrp', e.target.value);
                      }
                    }}
                  />
                </div>
              )}
            />
          </div>
          {hasSPLTag && (
            <div className="grid-col flex-1">
              <div className="width-card">
                <Controller
                  name="asePrice"
                  control={control}
                  render={({ value, onChange, onBlur }) => (
                    <div className="width-card">
                      <TextInput
                        name="asePrice"
                        ref={register}
                        value={value}
                        data-testid="asePrice"
                        label={genLabel('AS&E unit price', true)}
                        type="number"
                        min={0}
                        prefix={<Icon iconName="attach_money" />}
                        errorMessage={
                          errors.asePrice && errors.asePrice.message
                        }
                        onChange={onChange}
                        onBlur={(e) => {
                          if (!errors.asePrice) {
                            onBlur(e);
                            handleBlur('asePrice', e.target.value);
                          }
                        }}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
          )}
          <div className={`grid-col ${hasSPLTag ? 'flex-1' : 'flex-2'}`}>
            <div className="width-card">
              <Controller
                name="quantity"
                control={control}
                render={({ value, onChange, onBlur }) => (
                  <div className="width-card">
                    <TextInput
                      name="quantity"
                      ref={register}
                      value={value === 0 ? emSpaceUnicode : value}
                      data-testid="quantity"
                      label={genLabel('Allocation', false)}
                      type="number"
                      min={0}
                      errorMessage={errors.quantity && errors.quantity.message}
                      onChange={onChange}
                      onBlur={(e) => {
                        if (!errors.quantity) {
                          onBlur(e);
                          handleBlur('quantity', e.target.value);
                        }
                      }}
                    />
                  </div>
                )}
              />
            </div>
          </div>
        </div>

        <div className="grid-row margin-top-4  margin-bottom-4">
          <div className="grid-col flex-3">
            <div className="width-mobile">
              <Controller
                name="destinationCharge"
                control={control}
                render={({ value, onChange, onBlur }) => (
                  <div className="width-mobile">
                    <TextInput
                      name="destinationCharge"
                      ref={register}
                      value={value}
                      help="Destination charge is included in current price but should be provided separately in this field for analysis."
                      data-testid="destinationCharge"
                      label={genLabel('Destination charge', true)}
                      type="number"
                      min={0}
                      prefix={<Icon iconName="attach_money" />}
                      errorMessage={
                        errors.destinationCharge &&
                        errors.destinationCharge.message
                      }
                      onChange={onChange}
                      onBlur={(e) => {
                        if (!errors.destinationCharge) {
                          onBlur(e);
                          handleBlur('destinationCharge', e.target.value);
                        }
                      }}
                    />
                  </div>
                )}
              />
            </div>
          </div>
          <div className="grid-col flex-3 margin-top-3">
            <div className="body-bold" data-testid="estimated quantity">
              Estimated quantity
            </div>
            {bidLine?.solicitationLine
              ? bidLine?.solicitationLine?.estimatedQty
              : emDashUnicode}
          </div>
        </div>

        <div className="grid-row margin-top-4  margin-bottom-4">
          <div className="grid-col flex-1 margin-top-3">
            <div className="body-bold" data-testid="total price">
              Total price
            </div>
            <div className="text-body text-base-dark font-sans-xs">
              {' '}
              Current price*estimated quantity{' '}
            </div>
            <div className="body-bold">{totalPrice}</div>
          </div>
        </div>
      </form>
    </>
  );
};

export default PriceInfoSection;
