import { FVS_STATUS } from '../../../utilities/constants';

export const mapStatusToTags = (status) => {
  switch (status) {
    case FVS_STATUS.Draft: {
      return [
        {
          value: 'Draft',
          status,
          variant: 'Info-Gray',
        },
      ];
    }
    case FVS_STATUS.PeerReview: {
      return [
        {
          value: 'Peer review',
          status,
          variant: 'Warning-Gray',
        },
      ];
    }
    case FVS_STATUS.PSOCommentOpen: {
      return [
        {
          value: 'Vehicle Supplier comment open',
          status,
          variant: 'Warning-Gray',
        },
      ];
    }
    case FVS_STATUS.PublicCommentOpen: {
      return [
        {
          value: 'Public comment open',
          status,
          variant: 'Warning-Gray',
        },
      ];
    }
    case FVS_STATUS.AllCommentOpen: {
      return [
        {
          value: 'Vehicle Supplier comment open',
          status,
          variant: 'Warning-Gray',
        },
        {
          value: 'Public comment open',
          status,
          variant: 'Warning-Gray',
        },
      ];
    }
    case FVS_STATUS.AllCommentClosed: {
      return [
        {
          value: 'Vehicle Supplier comment closed',
          status,
          variant: 'Inactive-Gray',
        },
        {
          value: 'Public comment closed',
          status,
          variant: 'Inactive-Gray',
        },
      ];
    }
    case FVS_STATUS.Published: {
      return [
        {
          value: 'Published',
          status,
          variant: 'Ready-Gray',
        },
      ];
    }
    case FVS_STATUS.Amended: {
      return [
        {
          value: 'Amended',
          status,
          variant: 'Ready-Gray',
        },
      ];
    }
    default:
      return [];
  }
};

export default mapStatusToTags;
