import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  ButtonDropdown,
  ButtonDropdownItem,
  FlexView,
} from '@gsa/afp-component-library';

const ContractHeaderHistoryVersionActions = () => {
  const history = useHistory();
  const { contractHeaderId } = useParams();

  const actionsPanelLinks = [
    {
      title: 'Back to contract history',
      onClickHandler: () => {
        history.push(
          `/catalog/contract/contract-header/${contractHeaderId}/history`,
        );
      },
      icon: 'arrow_back',
    },
    {
      title: 'View latest contract version',
      onClickHandler: () => {
        history.push(`/catalog/contract/contract-header/${contractHeaderId}`);
      },
      icon: 'visibility',
    },
  ];

  return (
    <FlexView hAlignContent="right">
      <ButtonDropdown
        className="margin-right-0 margin-bottom-1"
        variant="outline"
        label="Actions"
        side="right"
      >
        {actionsPanelLinks.map((link) => (
          <React.Fragment key={link.title}>
            <ButtonDropdownItem
              className="text-primary hover:bg-blue-5"
              label={link.title}
              iconName={link.icon}
              iconType={link.customIcon && 'custom'}
              iconFill="#005ea2"
              onClick={link.onClickHandler}
            />
          </React.Fragment>
        ))}
      </ButtonDropdown>
    </FlexView>
  );
};

export default ContractHeaderHistoryVersionActions;
