import { Button } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AttachmentAddForm from './AttachmentAddForm';
import AttachmentModal from './AttachmentModal';
import { useAttachmentComponent } from './ComponentContext';

const AttachmentAdd = ({ title, isFromTaskDetail }) => {
  const {
    setAttachmentErrors,
    addState,
    setAddState,
    uploadButtonLabel,
    uploadPath,
    attachmentErrors,
    fileTypes,
    maxFileSize,
    linkedEntities,
    onAdd,
  } = useAttachmentComponent();

  const clearErrors = () =>
    setAttachmentErrors((prev) => ({ ...prev, save: {} }));

  // Close modal on success.
  useEffect(() => {
    if (addState?.added) {
      clearErrors();
      setAddState({ show: false, adding: false, added: false });
    }
  }, [addState]);

  return (
    <div
      data-testid="attachment-add-wrapper"
      className={!isFromTaskDetail ? 'attachment-add-wrapper' : ''}
    >
      <AttachmentModal
        id="attachment-add-modal"
        title={title}
        show={addState?.show}
        onClose={() => {
          clearErrors();
          setAddState({ show: false, adding: false, added: false });
        }}
        actions={
          <>
            <Button
              data-testid="attachment-add-cancel-button"
              disabled={addState?.adding}
              type="button"
              variant="unstyled"
              className="margin-right-2"
              onClick={() => {
                clearErrors();
                setAddState({ show: false, adding: false, added: false });
              }}
              label="Cancel"
            />
            <Button
              data-testid="attachment-add-upload-button"
              disabled={addState?.adding}
              type="submit"
              form="attachment-upload-form"
              className="add-document-button action-button"
              label={addState?.adding ? 'Uploading...' : 'Add File'}
            />
          </>
        }
      >
        <AttachmentAddForm
          isFromTaskDetail={isFromTaskDetail}
          uploadPath={uploadPath}
          attachmentErrors={attachmentErrors}
          fileTypes={fileTypes}
          maxFileSize={maxFileSize}
          linkedEntities={linkedEntities}
          onAdd={onAdd}
          addState={addState}
        />
      </AttachmentModal>

      <Button
        data-testid="attachment-add-button"
        type="button"
        className={!isFromTaskDetail ? 'attachment-add-button' : ''}
        onClick={() => {
          clearErrors();
          setAddState({ show: true, adding: false, added: false });
        }}
        label={uploadButtonLabel}
        leftIcon={{
          className: 'usa-icon--size-2',
          name: 'add',
        }}
      />
    </div>
  );
};

AttachmentAdd.defaultProps = {
  title: <h2>Upload file</h2>,
  isFromTaskDetail: false,
};

AttachmentAdd.propTypes = {
  title: PropTypes.node,
  isFromTaskDetail: PropTypes.bool,
};

export default AttachmentAdd;
