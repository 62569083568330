import { useLazyQuery } from '@apollo/client';
import {
  ErrorMessage,
  Label,
  SelectDropdown,
  Typeahead,
} from '@gsa/afp-component-library';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { GET_OPTIONS } from '../../services/data-store';
import {
  MODAL_MODES,
  useEquipmentDetailCodes,
} from './equipment-code-detail-provider';

const RadioGroup = ({ onChange }) => {
  return (
    <fieldset className="usa-fieldset" onChange={onChange}>
      <legend className="usa-legend usa-legend usa-sr-only">
        How would you like to apply the copied values
      </legend>
      <div className="usa-radio">
        <input
          data-testid="append-radio"
          className="usa-radio__input"
          id="append"
          type="radio"
          name="copyType"
          value="Append"
          aria-label="Append"
          aria-describedby="append-div"
        />
        <label className="usa-radio__label" htmlFor="append">
          Append
        </label>
      </div>
      <div className="usa-radio">
        <input
          data-testid="replace-radio"
          className="usa-radio__input"
          id="replace"
          type="radio"
          name="copyType"
          value="Replace"
          aria-label="Replace"
          aria-describedby="replace-div"
        />
        <label className="usa-radio__label" htmlFor="replace">
          Replace
        </label>
      </div>
    </fieldset>
  );
};

RadioGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
};

let userTyped = false;
const AutoCompleteSelect = ({ onChange, value, getValues }) => {
  const [option, setOption] = useState({ values: [], raw: [] });

  const [getOptions] = useLazyQuery(GET_OPTIONS, {
    onCompleted: (data) => {
      let result = { values: [], raw: [] };
      if (data?.getOptions) {
        result = {
          values: data.getOptions.map((v) => v.value),
          raw: data.getOptions,
        };
      }

      setOption(result);
    },
    onError: () => {
      // intentional
    },
  });

  AutoCompleteSelect.defaultProps = {
    value: '',
  };
  AutoCompleteSelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    getValues: PropTypes.func.isRequired,
  };

  return (
    <div className="margin-top-1">
      <Typeahead
        name="Copy to Equipment Code"
        required="true"
        role="textbox"
        data-testid="toEquipmentCode"
        filterValue={value}
        typeaheadValues={option?.values}
        onFilterKeyDown={() => {
          userTyped = true;
        }}
        onOptionEnter={(selected) => {
          const selectedData = option?.raw.find((o) => o.value === selected);
          onChange({ selected, selectedData });
        }}
        fetchTypeaheadValues={(_, search) => {
          const keyword = search.trim();
          if (userTyped) {
            getOptions({
              variables: {
                model: 'EquipmentCode',
                label: 'code',
                value: 'code',
                filter: {
                  operator: 'AND',
                  value: [
                    {
                      operator: 'EQ',
                      key: 'year',
                      value: getValues('copyToYear'),
                    },
                    {
                      operator: 'LIKE',
                      key: 'code',
                      value: keyword,
                    },
                  ],
                },
              },
            });

            userTyped = false;
          }
        }}
        debounceDelay={500}
        inputCharNum={0}
        onClear={(selected) => {
          onChange({ selected, undefined });
        }}
      />
    </div>
  );
};

const formSchema = yup.object().shape({
  toEquipmentCodeId: yup.string().required('Copy to Equipment Code required'),
  copyFromYear: yup.string().required('Copy from year is required'),
  copyToYear: yup.string().required('Copy to year is required'),
  copyType: yup.string().required('Copy type is required'),
});

const AssociationCopy = () => {
  const {
    selectedEquipmentCode,
    multipleOptions,
    selectedAssociations,
    setData,
  } = useEquipmentDetailCodes();

  const [toEquipmentCode, setToEquipmentCode] = useState({});

  const { errors, control, handleSubmit, register, getValues } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = (data) => {
    // Prep data.
    const copyInput = {
      toEquipmentCode,
      fromEquipmentCodeId: Number(selectedEquipmentCode.id),
      toEquipmentCodeId: Number(toEquipmentCode?.id),
      copyFromYear: Number(data.copyFromYear),
      copyToYear: Number(data.copyToYear),
      associationsToCopy: selectedAssociations?.map((a) =>
        Number(a?.original?.id),
      ),
      copyType: data.copyType.toLowerCase(),
    };

    setData('SET_EQUIPMENT_ASSOCIATION_COPY', copyInput);
    if (data.copyType.toLowerCase() === 'append') {
      setData('SET_MODAL_MODE', MODAL_MODES.CONFIRM_APPEND);
    } else {
      setData('SET_MODAL_MODE', MODAL_MODES.CONFIRM_COPY);
    }
    setData('SET_FORM_MODAL', true);
  };

  return (
    <form
      id="equipment-code-copy-form"
      className="usa-form usa-form--large"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="text-bold margin-bottom-1">Copy from Equipment Code</div>
      <div>
        {`${selectedEquipmentCode?.code} - ${selectedEquipmentCode?.title} - ${selectedEquipmentCode?.year}`}
      </div>
      <input
        id="fromEquipmentCodeId"
        data-testid="fromEquipmentCodeId"
        name="fromEquipmentCodeId"
        type="hidden"
        ref={register}
        defaultValue={selectedEquipmentCode?.code ?? ''}
      />
      <input
        id="copyFromYear"
        data-testid="copyFromYear"
        name="copyFromYear"
        type="hidden"
        ref={register}
        defaultValue={selectedEquipmentCode?.year ?? ''}
      />

      <Label htmlFor="year" className="text-bold">
        Copy to year&nbsp;
        <abbr title="required" className="usa-hint usa-hint--required">
          *
        </abbr>
      </Label>
      <Controller
        id="copyToYear"
        name="copyToYear"
        control={control}
        defaultValue={
          multipleOptions.find((o) => o.currentYear === true)?.value ?? ''
        }
        render={({ onChange, value }) => (
          <SelectDropdown
            data-testid="copyToYear"
            aria-label="Copy to year"
            aria-required="true"
            value={
              value ??
              multipleOptions.find((o) => o.currentYear === true)?.value
            }
            options={multipleOptions?.filter(
              (o) =>
                o?.type === 'year' &&
                o?.uniqueKey === 'year' &&
                o.value >=
                  multipleOptions.find((c) => c.currentYear === true)?.value,
            )}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        )}
      />
      {errors?.copyToYear?.message && (
        <ErrorMessage>{errors.copyToYear?.message}</ErrorMessage>
      )}

      <Label htmlFor="toEquipmentCodeId" className="text-bold">
        Copy to Equipment Code&nbsp;
        <abbr title="required" className="usa-hint usa-hint--required">
          *
        </abbr>
      </Label>
      <Controller
        control={control}
        name="toEquipmentCodeId"
        defaultValue=""
        render={({ onChange, value }) => (
          <AutoCompleteSelect
            value={value ?? ''}
            getValues={getValues}
            onChange={({ selected, selectedData }) => {
              onChange(selected);
              setToEquipmentCode(selectedData);
            }}
          />
        )}
      />
      {errors?.toEquipmentCodeId?.message && (
        <ErrorMessage>{errors.toEquipmentCodeId?.message}</ErrorMessage>
      )}

      <Label htmlFor="toEquipmentCodeId" className="text-bold">
        How would you like to apply the copied values?&nbsp;
        <abbr title="required" className="usa-hint usa-hint--required">
          *
        </abbr>
      </Label>
      <Controller
        control={control}
        name="copyType"
        defaultValue=""
        render={({ onChange, value }) => (
          <RadioGroup
            data-testid="copyType"
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        )}
      />
      {errors?.copyType?.message && (
        <ErrorMessage>{errors.copyType?.message}</ErrorMessage>
      )}
      <br />
      <div className="text-bold">Replace</div>
      <div className="text-italic" id="replace-div">
        All options will be copied. Existing options will be overwritten.
      </div>
      <br />
      <div className="text-bold">Append</div>
      <div className="text-italic" id="append-div">
        Any option not found in the COPY TO Standard Item will be appended.
      </div>
    </form>
  );
};

export default AssociationCopy;
