import React, { createContext, useState, useContext } from 'react';
import { NON_STANDARD_LINE_TYPES } from '../make-model/si-make-model-panel-provider';

const AddContractLineContext = createContext();

// eslint-disable-next-line react/prop-types
export function AddContractLineProvider({ children }) {
  const [manufacturerModelNumber, setManufacturerModelNumber] = useState('');
  const [nonStandardLineType, setNonStandardLineType] = useState();

  const getManufacturerModelNumberErrors = () => {
    const errors = [];
    if (
      nonStandardLineType !== NON_STANDARD_LINE_TYPES.MANUFACTURER_MODEL_NUMBER
    ) {
      return errors;
    }

    if (!manufacturerModelNumber || !String(manufacturerModelNumber).trim()) {
      errors.push({
        field: 'manufacturerModelNumber',
        message: 'Manufacturer Model Number is required',
      });
    }
    return errors;
  };

  return (
    <AddContractLineContext.Provider
      value={{
        manufacturerModelNumber,
        setManufacturerModelNumber,
        nonStandardLineType,
        setNonStandardLineType,
        getManufacturerModelNumberErrors,
      }}
    >
      {children}
    </AddContractLineContext.Provider>
  );
}

export function useAddContractLineProvider() {
  const context = useContext(AddContractLineContext);
  if (!context) {
    throw new Error(
      'useAddContractLineProvider must be used within a AddContractLineProvider',
    );
  }
  return context;
}
