import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PrivateRoute, NotFound } from '@gsa/afp-shared-ui-utils';
import CatalogCodesProvider from './catalog-codes-provider';
import { OPERATIONS, SUBJECTS } from '../../utilities/constants';
import CatalogCodeDetail from './detail';
import CatalogCodesTable from './table';

const CatalogCodes = () => {
  return (
    <CatalogCodesProvider>
      <Switch>
        <PrivateRoute
          exact
          path="/catalog/codes"
          component={CatalogCodesTable}
          operation={OPERATIONS.View}
          subject={SUBJECTS.CatalogCodes}
        />
        <PrivateRoute
          exact
          path="/catalog/codes/:standardCodeMetadataId/:code"
          component={CatalogCodeDetail}
          title="Catalog Code Detail"
          operation={OPERATIONS.View}
          subject={SUBJECTS.CatalogCodes}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </CatalogCodesProvider>
  );
};

export default CatalogCodes;
