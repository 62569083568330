import React from 'react';
import PropTypes from 'prop-types';
import { StatusBadge as BaseStatusBadge } from '@gsa/afp-component-library';

const createStatusBadgeWithVariants = (VARIANT_MAP, CONTENT_MAP) => {
  const StatusBadge = ({ value, ...restProps }) => {
    const badgeContent = (CONTENT_MAP && CONTENT_MAP[value]) || value;
    const variant = VARIANT_MAP && VARIANT_MAP[value];

    return (
      <BaseStatusBadge {...restProps} variant={variant}>
        {badgeContent}
      </BaseStatusBadge>
    );
  };

  StatusBadge.propTypes = {
    value: PropTypes.string.isRequired,
  };
  return StatusBadge;
};

export default createStatusBadgeWithVariants;
