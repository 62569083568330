import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { ErrorMessage, SelectDropdown } from '@gsa/afp-component-library';

// eslint-disable-next-line react/prop-types
const YearInput = ({ name, isAddForm, years, isRequired = false }) => {
  const { errors, control, watch, setValue } = useFormContext();
  const nextYear = new Date().getFullYear() + 1;

  useEffect(() => {
    if (isAddForm) {
      setValue('year', nextYear.toString());
    }
  }, [setValue]);

  return (
    <>
      {/* eslint-disable-next-line */}
      <h2 className="font-sans-xs" tabIndex="0" ariaLabel="Contract year">
        Contract year{' '}
        {isRequired && <span className="usa-hint usa-hint--required">*</span>}
      </h2>
      <Controller
        id={name}
        name={name}
        control={control}
        render={({ onChange, onBlur, value, ref }) => (
          <SelectDropdown
            data-testid={`${name}-select`}
            aria-label="Contract year"
            aria-required={isRequired ? 'true' : 'false'}
            options={years}
            onChange={(v) => {
              onChange(v);
            }}
            onBlur={onBlur}
            value={value}
            style={{ display: isAddForm ? 'block' : 'none' }}
            inputRef={ref}
          />
        )}
      />
      {!isAddForm && <span className=".text-normal">{watch('year')}</span>}
      {errors[name]?.message && (
        <ErrorMessage>{errors[name].message}</ErrorMessage>
      )}
    </>
  );
};

YearInput.propTypes = {
  name: PropTypes.string.isRequired,
  isAddForm: PropTypes.bool.isRequired,
  years: PropTypes.shape(Object).isRequired,
  isRequired: PropTypes.bool,
};

YearInput.defaultProps = {
  isRequired: false,
};

export default YearInput;
