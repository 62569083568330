import React from 'react';
import { SelectDropdown, Button } from '@gsa/afp-component-library';
import { useBidLineEvaluation } from './bid-line-evaluation-provider';

const BidLineEvaluationFilter = () => {
  const {
    filterOptions,
    filters,
    onFilterChange,
    onResetFilters,
  } = useBidLineEvaluation();

  const getSingleSelect = (field, label) => {
    return (
      <div className="grid-col bid-line-eval-filter-item">
        <SelectDropdown
          data-testid={`${field}-filter`}
          label={label}
          value={filters[field]}
          options={filterOptions[field]}
          onChange={({ target: { value } }) => onFilterChange(field, value)}
        />
      </div>
    );
  };

  return (
    <div className="bid-line-eval-filter-block padding-2 bg-primary-lighter">
      <div className="display-flex flex-row flex-justify">
        <div className="bid-line-eval-filter-header">Filters</div>
        <div className="bid-line-eval-filter-reset">
          <Button
            variant="unstyled"
            label="Reset all filters"
            onClick={onResetFilters}
          />
        </div>
      </div>
      <div className="grid-row grid-gap">
        {getSingleSelect('standardItem', 'Standard Item')}
        {getSingleSelect('vehicleType', 'Vehicle type')}
        {getSingleSelect('body', 'Body make and model')}
        {getSingleSelect('status', 'Status')}
      </div>
    </div>
  );
};

export default BidLineEvaluationFilter;
