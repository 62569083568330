import React from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonDropdown, ButtonDropdownItem } from '@gsa/afp-component-library';
import { MODAL_MODES } from '../constants';
import { useFvsLandingPageContext } from '../fvs-landing-page-provider';
import { useFederalStandards } from '../../fvs-provider';

const FVSActionsPanel = () => {
  const { vehicleStandard } = useFederalStandards();

  const { setFvsLandingPageData } = useFvsLandingPageContext();

  const history = useHistory();

  const filteredActionsPanelLinks = (handler) => [
    {
      title: 'View comments',
      onClickHandler: handler?.viewComments,
      customIcon: true,
      icon: 'calendar',
    },
  ];

  const unFilteredActionsPanelLinks = (handler) => [
    // ENG/PSO/Public
    {
      title: 'View comments',
      onClickHandler: handler?.viewComments,
      customIcon: true,
      icon: 'calendar',
    },
  ];

  const actionHandlers = {
    supportingDoc: () => {
      setFvsLandingPageData('SET_MODAL_MODE', MODAL_MODES.UPLOAD);
    },
    viewComments: () => {
      // TODO after building view comments page
      history.push('/public/fvs/comments', vehicleStandard);
    },
  };

  const filteredActionsLinks = filteredActionsPanelLinks(actionHandlers);
  const unFilteredActionsLinks = unFilteredActionsPanelLinks(actionHandlers);

  return (
    <>
      <ButtonDropdown
        className="margin-right-0"
        label="Actions"
        variant="primary"
        iconSize={3}
        side="right"
      >
        {(vehicleStandard?.id
          ? filteredActionsLinks
          : unFilteredActionsLinks
        ).map((link, i) => (
          <div key={link}>
            {link ? (
              <ButtonDropdownItem
                // eslint-disable-next-line react/no-array-index-key
                key={`dropdownItem${i}`}
                className="text-primary hover:bg-blue-5"
                label={link.title}
                iconName={link.icon}
                iconType={link.customIcon && 'custom'}
                iconFill="#005ea2"
                onClick={() => link.onClickHandler()}
              />
            ) : null}
          </div>
        ))}
      </ButtonDropdown>
    </>
  );
};

export default FVSActionsPanel;
