import PropTypes from 'prop-types';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { OPERATIONS, SUBJECTS } from '../../../utilities/constants';

export const canIChangeBidData = () => {
  const ability = useAppAbility();

  const hasAdminPrivilege =
    ability.can(OPERATIONS.Manage, SUBJECTS.All) || false;

  const hasVehicleSupplierPrivilege =
    ability.can(OPERATIONS.Create, SUBJECTS.PSOQuestionnaire) || false;

  return !hasAdminPrivilege && hasVehicleSupplierPrivilege;
};

/**
 * Component to check if a bid data can be manupulted or not
 * based on the logged in user permission
 * @param children:  a react node
 * @returns children if the user can change the bid data
 */
const CanIChangeBidData = ({ children }) => {
  // if the logged in user is not admin and has a create PSOQuestionnaire permission
  if (canIChangeBidData()) {
    return children;
  }

  return null;
};

CanIChangeBidData.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CanIChangeBidData;
