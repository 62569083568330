import { Button } from '@gsa/afp-component-library';
import React from 'react';
import Modal from '../../components/Modal/Modal';
import ArchiveComments from './archive-comments';
import BulkPublish from './bulk-publish';
import BulkReply from './bulk-reply';
import BulkReview from './bulk-review';
import { useCommentsAdmin } from './comments-admin-provider';
import { MODAL_MODES } from './constants';
import DeleteReply from './delete-comment';
import ManageComment from './manage-comment';

const ModalTitle = () => {
  const { modalMode } = useCommentsAdmin();
  let title = '';
  switch (modalMode) {
    case MODAL_MODES.MANAGE_COMMENT:
      title = 'Manage comment';
      break;
    case MODAL_MODES.REPLY:
      title = 'Reply to selected comments';
      break;
    case MODAL_MODES.APPLY_REVIEW_STATE:
      title = 'Apply review state to selected comments';
      break;
    case MODAL_MODES.PUBLISH:
      title = 'Publish selected comments';
      break;
    case MODAL_MODES.DELETE_COMMENT:
      title = 'Delete comment';
      break;
    case MODAL_MODES.CONFIRM_ARCHIVE:
      title = 'Archive commenting period';
      break;
    default:
      break;
  }

  return (
    <div className="margin-top-5" aria-label={title}>
      {/* eslint-disable-next-line */}
      <h3 tabIndex="0">{title}</h3>
    </div>
  );
};

const ModalContent = () => {
  const { modalMode } = useCommentsAdmin();

  if (modalMode === MODAL_MODES.MANAGE_COMMENT)
    return (
      <>
        <ManageComment formId="manage_comments" />
      </>
    );

  if (modalMode === MODAL_MODES.REPLY) {
    return <BulkReply formId="bulk_reply" />;
  }

  if (modalMode === MODAL_MODES.APPLY_REVIEW_STATE) {
    return <BulkReview formId="bulk_review" />;
  }
  if (modalMode === MODAL_MODES.PUBLISH) {
    return <BulkPublish formId="bulk_publish" />;
  }
  if (modalMode === MODAL_MODES.DELETE_COMMENT) {
    return <DeleteReply formId="delete_reply" />;
  }
  if (modalMode === MODAL_MODES.CONFIRM_ARCHIVE) {
    return <ArchiveComments formId="archive_comments" />;
  }
  return null;
};

const ModalAction = () => {
  const { modalMode, setData, commentFormState } = useCommentsAdmin();

  const onCancel = () => {
    setData('SET_MODAL_MODE', null);
    setData('SET_COMMENT_FORM_STATE', null);
  };

  if (modalMode === MODAL_MODES.MANAGE_COMMENT) {
    return (
      <>
        <Button
          className="padding-right-1"
          aria-label="Cancel"
          variant="unstyled"
          onClick={onCancel}
          data-testid="manage-comment-cancel-btn"
          label="Cancel"
        />
        <Button
          variant={commentFormState !== 'Invalid' ? 'outline' : 'primary'}
          type="submit"
          name="save"
          form="manage_comments"
          aria-label="Save"
          data-testid="manage-comment-submit-btn"
          label="Save"
        />
        {commentFormState !== 'Invalid' ? (
          <Button
            variant="primary"
            name="save_publish"
            type="submit"
            form="manage_comments"
            aria-label="Save and Publish"
            data-testid="manage-comment-publish-btn"
            label="Save and Publish"
          />
        ) : null}
      </>
    );
  }
  if (modalMode === MODAL_MODES.APPLY_REVIEW_STATE) {
    return (
      <>
        <Button
          variant="unstyled"
          className="padding-right-1"
          onClick={onCancel}
          aria-label="Cancel"
          data-testid="standard-item-edit-cancel-btn"
          label="Cancel"
        />
        <Button
          variant={commentFormState !== 'Invalid' ? 'outline' : 'primary'}
          type="submit"
          form="bulk_review"
          name="apply"
          aria-label="Apply"
          data-testid="standard-item-apply-save-btn"
          label="Apply"
        />
        {commentFormState !== 'Invalid' ? (
          <Button
            variant="primary"
            type="submit"
            form="bulk_review"
            name="apply_publish"
            aria-label="Apply and Publish"
            data-testid="standard-item-apply-publish-save-btn"
            label="Apply and Publish"
          />
        ) : null}
      </>
    );
  }

  if (modalMode === MODAL_MODES.REPLY) {
    return (
      <>
        <Button
          variant="unstyled"
          className="padding-right-1"
          onClick={onCancel}
          aria-label="Cancel"
          data-testid="standard-item-edit-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="outline"
          type="submit"
          form="bulk_reply"
          name="reply"
          aria-label="Reply"
          data-testid="standard-item-reply-save-btn"
          label="Reply"
        />
        <Button
          variant="primary"
          type="submit"
          form="bulk_reply"
          name="reply_publish"
          aria-label="Reply and Publish"
          data-testid="standard-item-reply-publish-save-btn"
          label="Reply and Publish"
        />
      </>
    );
  }
  if (modalMode === MODAL_MODES.PUBLISH) {
    return (
      <>
        <Button
          variant="unstyled"
          className="padding-right-1"
          onClick={onCancel}
          aria-label="Cancel"
          data-testid="standard-item-edit-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="primary"
          type="submit"
          form="bulk_publish"
          aria-label="Publish comments"
          data-testid="bulk-publish-save-btn"
          label="Publish comments"
        />
      </>
    );
  }

  if (modalMode === MODAL_MODES.DELETE_COMMENT) {
    return (
      <>
        {' '}
        <Button
          type="button"
          data-testid="cancel-comment-btn-modal"
          aria-label="Cancel"
          variant="outline"
          onClick={onCancel}
          label="Cancel"
        />
        <Button
          data-testid="delete-comment-btn-modal"
          aria-label="Delete comment"
          type="submit"
          form="delete_reply"
          variant="secondary"
          label="Delete comment"
        />
      </>
    );
  }
  if (modalMode === MODAL_MODES.CONFIRM_ARCHIVE) {
    return (
      <>
        <Button
          variant="unstyled"
          className="padding-right-1"
          onClick={onCancel}
          aria-label="Cancel"
          data-testid="standard-item-edit-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="primary"
          type="submit"
          form="archive_comments"
          aria-label="Arvhive comments"
          data-testid="archive-comments-save-btn"
          label="Complete archive"
        />
      </>
    );
  }

  return null;
};

const CommentsAdminModalWrapper = () => {
  const { showFormModal, setData } = useCommentsAdmin();

  return (
    <Modal
      title={<ModalTitle />}
      actions={<ModalAction />}
      show={showFormModal}
      onClose={() => {
        setData('SET_MODAL_MODE', null);
        setData('SET_COMMENT_FORM_STATE', null);
      }}
    >
      <ModalContent />
    </Modal>
  );
};

export default CommentsAdminModalWrapper;
