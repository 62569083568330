import React, { useMemo } from 'react';
import { AFPTable, Button } from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import PropTypes from 'prop-types';
import moment from 'moment';
import '../styles/comment-table.scss';
import Truncate from '../../../../components/Truncate';
import { CommentPropType } from '../../../../utilities/types';
import { canViewComment } from '../../../../utilities/types/role-types';
import { useFvsRequirements } from '../requirements-provider';
import { FVS_RT_MODAL_MODES, FVS_RT_ACTIONS } from '../constants';

// eslint-disable-next-line react/prop-types
const CommentsTable = ({ comments, rowDetail, role }) => {
  const { setFvsRequirementsContextData } = useFvsRequirements();
  const { currentUser } = useCurrentUser();

  const handleClick = (row) => {
    setFvsRequirementsContextData(
      FVS_RT_ACTIONS.SET_EQUIPMENT_ASSOCIATION,
      rowDetail,
    );
    setFvsRequirementsContextData(
      FVS_RT_ACTIONS.SET_SELECTED_COMMENT,
      row.original,
    );
    setFvsRequirementsContextData(
      FVS_RT_ACTIONS.SET_RT_MODAL_MODE,
      FVS_RT_MODAL_MODES.COMMENT,
    );
  };

  const ownCommentList = comments?.filter((comment) =>
    canViewComment(comment, currentUser.id, role),
  );
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'createdAt',
        sortable: false,
        Cell: ({ value }) => {
          return moment(value).format('MM/DD/YYYY');
        },
      },
      {
        Header: 'Comment',
        accessor: 'comment',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row, value }) => (
          <>
            <div className="display-flex flex-justify">
              <div className="display-flex flex-justify">
                <Truncate
                  content={value}
                  // eslint-disable-next-line react/prop-types
                  size={row?.original?.repliesCount > 0 ? 50 : 58}
                  showViewMore={false}
                />
                {/* eslint-disable-next-line react/prop-types */}
                <span>{value?.length > 100 ? '...' : ''}</span>
                <Button
                  data-testid="fvs-comments-table-read-more"
                  style={{ textDecoration: 'none' }}
                  className="usa-button usa-button--unstyled margin-left-1"
                  onClick={() => handleClick(row)}
                  label="Read more"
                />
              </div>
              {/* eslint-disable-next-line react/prop-types */}
              {row?.original?.repliesCount ? (
                <div
                  className="text-uppercase bg-green text-white
              font-sans-3xs padding-2px padding-x-05 margin-right-3"
                >
                  {/* eslint-disable-next-line react/prop-types */}
                  {row?.original?.repliesCount} Reply
                </div>
              ) : null}
            </div>
          </>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <hr />
      <div className="text-bold">EXISTING COMMENTS</div>
      <AFPTable
        className="comment"
        columns={columns}
        // eslint-disable-next-line react/prop-types
        data={ownCommentList.map((comment) => ({
          ...comment,
          createdAt: comment.createdAt,
        }))}
        onSort={() => {
          // eslint-disable-next-line no-console
          console.log('No sort required');
        }}
        expandable={false}
        fullWidth
      />
    </>
  );
};

CommentsTable.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape(CommentPropType)).isRequired,
};

export default CommentsTable;
