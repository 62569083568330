export const FVS_RT_MODAL_MODES = {
  EDIT_ASSOCIATION: 'edit_association',
  CONFIRM_DELETE_ASSOCIATION: 'confirm_delete_association',
  COMMENT: 'COMMENT',
  CONFIRM_CANCEL: 'CONFIRM_CANCEL',
};

export const FVS_RT_ACTIONS = {
  SET_EQUIPMENT_ASSOCIATION: 'SET_EQUIPMENT_ASSOCIATION',
  SET_SELECTED_COMMENT: 'SET_SELECTED_COMMENT',
  SET_RT_MODAL_MODE: 'SET_RT_MODAL_MODE',
};

export const HasIntentionToBid = {
  No: 0,
  Yes: 1,
};
