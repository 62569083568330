// eslint-disable-next-line no-unused-vars
import React from 'react';
import VehicleGroupFilterItem from './vehicle-group-filter-item';
import VehicleTypeFilterItem from './vehicle-type-filter-item';

// eslint-disable-next-line import/prefer-default-export
export const getFilterStructure = (
  applicableFvsYears,
  defaultFilter,
  standardItemsAsOptions,
  lookupCodes,
) => [
  {
    key: 'year',
    title: 'Contract year',
    type: 'select',
    options: applicableFvsYears?.map((year) => ({
      label: year.label,
      value: year.value,
    })),
    value:
      defaultFilter?.year?.toString() || new Date().getFullYear().toString(),
    operator: 'EQ',
    expanded: true,
    hideClear: true,
  },
  {
    key: 'vehicleType',
    title: 'Vehicle type',
    component: VehicleTypeFilterItem,
    operator: 'EQ',
    expanded: true,
    hideClear: true,
  },
  {
    key: 'vehicleGroup',
    title: 'Vehicle group',
    component: VehicleGroupFilterItem,
    operator: 'EQ',
    expanded: true,
    hideClear: false,
  },
  {
    key: 'standardItem',
    title: 'Standard Item',
    type: 'select',
    options: standardItemsAsOptions,
    value: defaultFilter?.standardItem || '',
    operator: 'EQ',
    expanded: true,
    hideClear: true,
  },
  {
    key: 'code',
    title: 'Code',
    type: 'typeahead',
    value: '',
    operator: 'EQ',
    expanded: true,
    hideClear: true,
    customFieldProps: { inputCharNum: 1 },
  },
  {
    // There is similar key with other filter hence prefix with ec
    key: 'title',
    title: 'Title',
    type: 'typeahead',
    value: '',
    operator: 'EQ',
    expanded: true,
    hideClear: true,
    customFieldProps: { inputCharNum: 1 },
  },
  {
    key: 'associationType',
    title: 'Type',
    type: 'multiselect',
    options: lookupCodes?.associationType
      ? lookupCodes?.associationType?.map((type) => ({
          value: type.value,
          label: type.label,
        }))
      : [],
    value: [],
    operator: 'EQ',
    expanded: false,
    hideClear: true,
  },
  {
    key: 'category',
    title: 'Category',
    type: 'select',
    options: lookupCodes?.category
      ? lookupCodes?.category?.map((category) => ({
          value: category.value,
          label: category.label,
        }))
      : [],
    value: '',
    operator: 'EQ',
    expanded: false,
    hideClear: true,
  },
];
