import React from 'react';
import { PageTitle } from '@gsa/afp-component-library';
import { useStandardItem } from './standard-item-provider';

const StandardItemPageTitle = () => {
  const { standardItem } = useStandardItem();

  return (
    <>
      <PageTitle
        aria-label={`${standardItem?.standardItemNumber} - ${standardItem?.title} - ${standardItem?.year}`}
        tabIndex="0"
        title={`${standardItem?.standardItemNumber} - ${standardItem?.title} - ${standardItem?.year}`}
      />
    </>
  );
};

export default StandardItemPageTitle;
