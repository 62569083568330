import React from 'react';
import { Link } from '@gsa/afp-component-library';

import { useContractLine } from '../../provider/contract-line-provider';
import AddAssociatedLocationForm from './add-vendor-associated-location';
import VendorAssociatedLocationTable from './vendor-associated-location-table';

const VendorAssociatedLocationSection = () => {
  const { contractLine, canUpdateContract } = useContractLine();

  const contract = contractLine?.contractModificationRef?.contract;
  const vendor = contract?.vendor;
  const vendorLink = (
    <Link
      href={`${window.AFP_CONFIG.appURLs.home}/vendor/details/${vendor?.id}`}
    >
      vendor company profile
    </Link>
  );
  return (
    <>
      {canUpdateContract && (
        <>
          <div
            className="width-tablet-lg padding-bottom-2"
            data-testid="vendor_associated_loc_sec_info"
          >
            If the correct address is not available in the dropdown list below,
            navigate to the {vendorLink} and add the address in the Vehicle
            Supplier tab under the associated locations section.{' '}
          </div>
          <AddAssociatedLocationForm />
        </>
      )}
      <VendorAssociatedLocationTable isReadOnly={!canUpdateContract} />
    </>
  );
};

export default VendorAssociatedLocationSection;
