import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, AFPTable, EmptyState } from '@gsa/afp-component-library';
import { useBidLineDetails } from '../provider/bid-line-details-provider';
import { getDocumentColumns, DELETE_ACTION } from '../provider/table-defs';
import { ATTACH_TYPE } from '../provider/helpers';
import AddDocModal from './add-doc-modal';
import Notification from './notification';
import DeleteDocModal from './delete-doc-modal';
import ImagesTable from './images-table';
import { TAB_KEY } from '../copy-line-item/helpers';

const DocumentationTab = ({ openCopyModal }) => {
  const [isAddDocModal, setIsAddDocModal] = useState(false);
  const [isAddImageModal, setIsAddImageModal] = useState(false);
  const [docSuccessMsg, setDocSuccessMsg] = useState();
  const [imageSuccessMsg, setImageSuccessMsg] = useState();
  const [docToDelete, setDocToDelete] = useState(null);

  const {
    documents,
    photos,
    bidLine,
    reloadBidLineDocuments,
    setAlert,
  } = useBidLineDetails();

  const onActionClick = useCallback((type, action, { original }) => {
    if (action === DELETE_ACTION) {
      setDocToDelete(original);
    }
  }, []);

  const onDocumentActionClick = useCallback(
    (action, row) => onActionClick(ATTACH_TYPE.doc, action, row),
    [],
  );

  const documentColumns = useMemo(
    () => getDocumentColumns(onDocumentActionClick),
    [onDocumentActionClick],
  );

  const onAddClick = useCallback(
    (docType) => {
      setImageSuccessMsg(null);
      setDocSuccessMsg(null);
      setAlert(null);
      if (documents.length + photos.length >= 10) {
        setAlert({
          type: 'error',
          message:
            'You have exceeded the allowed maximum of 10 pictures/documents per bid.',
        });
        return;
      }
      if (docType === ATTACH_TYPE.doc) {
        setIsAddDocModal(true);
      } else {
        setIsAddImageModal(true);
      }
    },
    [documents, photos],
  );

  return (
    <div className="margin-bottom-8 bid-line-item-details">
      <div className="display-flex flex-row flex-justify flex-align-end margin-bottom-4">
        <h3>Documentation</h3>
      </div>
      <h4 className="text-primary">DOCUMENTS</h4>
      {docSuccessMsg && <Notification type="success" content={docSuccessMsg} />}
      <div className="display-flex flex-row flex-justify margin-top-4">
        <div style={{ maxWidth: '40rem' }}>
          You have the ability to upload a combined maximum of 10
          pictures/documents per bid.
        </div>
        <div className="margin-top-2">
          <Button
            variant="outline"
            label="Copy documents"
            leftIcon={{
              name: 'content_copy',
            }}
            className="margin-left-2"
            onClick={() => openCopyModal(TAB_KEY.documents)}
          />
          <Button
            variant="outline"
            label="Add documents"
            leftIcon={{ name: 'add' }}
            onClick={() => onAddClick(ATTACH_TYPE.doc)}
          />
          {isAddDocModal && (
            <AddDocModal
              bidLineId={parseInt(bidLine?.id, 10)}
              onClose={() => setIsAddDocModal(false)}
              onSuccess={(newDoc) => {
                reloadBidLineDocuments();
                setDocSuccessMsg(
                  `You have successfully uploaded ${newDoc.name}`,
                );
                setIsAddDocModal(false);
              }}
              docType="D"
            />
          )}
          {docToDelete && (
            <DeleteDocModal
              doc={docToDelete}
              onCancel={() => setDocToDelete(null)}
              onSuccess={() => {
                if (docToDelete.documentType === 'D') {
                  setDocSuccessMsg(
                    `${docToDelete.name} has been removed from the bid line.`,
                  );
                } else {
                  setImageSuccessMsg(
                    `${docToDelete.name} has been removed from the bid line.`,
                  );
                }

                reloadBidLineDocuments();
                setDocToDelete(null);
              }}
            />
          )}
        </div>
      </div>
      <AFPTable
        fullWidth
        columns={documentColumns}
        data={documents}
        defaultSort={[['name', 'asc']]}
      />
      {!documents.length && (
        <div className="table-empty-state">
          <EmptyState bottomText="No documents available. Please use the add documents button to upload documents." />
        </div>
      )}

      <h4 className="text-primary">PHOTOS</h4>
      {imageSuccessMsg && (
        <Notification type="success" content={imageSuccessMsg} />
      )}
      <div className="display-flex flex-row flex-justify margin-top-4">
        <div style={{ maxWidth: '40rem' }}>
          Please upload all required pictures and identify a representative
          image to be displayed for the customers while purchasing. A
          representative image must be selected before bid submission. You will
          have the ability to upload up to 10 pictures/documents related to your
          bid.
        </div>
        <div className="margin-top-2">
          <Button
            variant="outline"
            label="Copy photos"
            leftIcon={{
              name: 'content_copy',
            }}
            className="margin-left-2"
            onClick={() => openCopyModal(TAB_KEY.photos)}
          />
          <Button
            variant="outline"
            label="Add photos"
            leftIcon={{ name: 'add' }}
            onClick={() => onAddClick(ATTACH_TYPE.photo)}
          />
          {isAddImageModal && (
            <AddDocModal
              bidLineId={parseInt(bidLine?.id, 10)}
              onClose={() => setIsAddImageModal(false)}
              onSuccess={(newPhoto) => {
                reloadBidLineDocuments();
                setImageSuccessMsg(
                  `You have successfully uploaded ${newPhoto.name}`,
                );
                setIsAddImageModal(false);
              }}
              docType="I"
            />
          )}
        </div>
      </div>
      <ImagesTable setDocToDelete={setDocToDelete} />
    </div>
  );
};

DocumentationTab.propTypes = {
  openCopyModal: PropTypes.func.isRequired,
};

export default DocumentationTab;
