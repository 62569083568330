import { atom } from 'recoil';

const viewSolicitationDetailAtom = atom({
  key: 'viewSolicitation/detail',
  default: {},
});

const viewSolicitationOpenPeriodsAtom = atom({
  key: 'viewSolicitation/openPeriod',
  default: [],
});

const viewSolicitationReopenPeriodsAtom = atom({
  key: 'viewSolicitation/reopenPeriods',
  default: [],
});

const viewSolicitationStdItemsAtom = atom({
  key: 'viewSolicitation/standardItems',
  default: [],
});

const viewSolicitationStdItemsCountAtom = atom({
  key: 'viewSolicitation/standardItemsCount',
  default: 0,
});

const viewSolicitationIdAtom = atom({
  key: 'viewSolicitation/id',
  default: '',
});

export {
  viewSolicitationDetailAtom,
  viewSolicitationOpenPeriodsAtom,
  viewSolicitationReopenPeriodsAtom,
  viewSolicitationStdItemsAtom,
  viewSolicitationStdItemsCountAtom,
  viewSolicitationIdAtom,
};
