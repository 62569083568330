import React from 'react';
import AddAssociatedLocationForm from './add-vendor-associated-location.js';
import VendorAssociatedLocationTable from './vendor-associated-location-table';

const VendorAssociatedLocationSection = () => {
  return (
    <>
      <div
        className="width-tablet-lg padding-bottom-2"
        data-testid="vendor_associated_loc_sec_info"
      >
        Even though you might have several assembly points, please add the final
        assembly point. If the correct address for the final assembly or
        inspection point is not available in the dropdown list below, return to
        your vendor company profile and add the address in the Vehicle Supplier
        tab under the associated locations section.
      </div>
      <AddAssociatedLocationForm />
      <VendorAssociatedLocationTable />
    </>
  );
};

export default VendorAssociatedLocationSection;
