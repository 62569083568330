/* eslint-disable import/prefer-default-export */
import { MAKE_MODEL_APPROVAL_STATUS_BADGE as BADGE } from './constants';

export const getMakeModelStatusBadgeConfig = (makeModelApproval) => {
  const status = makeModelApproval?.fleetApprovalStatus;
  if (!status || !BADGE[status]) return null;
  return BADGE[status];
};

export const getMakeModelStatusBadge = (status) => {
  if (!status || !BADGE[status]) return null;
  return BADGE[status];
};

export const getApprovalStatus = (data) => {
  // Check if makeModelApproval.fleetApprovalStatus is not null
  if (
    data?.makeModelApproval &&
    data?.makeModelApproval?.fleetApprovalStatus !== null
  ) {
    return data?.makeModelApproval?.fleetApprovalStatus;
  }

  const makeStatus = data?.make
    ? data.make.fleetStatus
    : data?.afpMake?.fleetStatus;
  const modelStatus = data?.make
    ? data.model.fleetStatus
    : data?.afpModel?.fleetStatus;

  const makeApproved = makeStatus === 'Approved';
  const modelApproved = modelStatus === 'Approved';

  // Return the approval status based on the combination
  if (makeApproved && modelApproved) {
    return 'Approved';
  }
  return 'Rejected';
};
