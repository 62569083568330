import React, { useEffect } from 'react';
import { CounterTag } from '@gsa/afp-component-library';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { OPERATIONS, SUBJECTS } from '../../../../utilities/constants';
import { useFederalStandards } from '../../fvs-provider';
import { useFvsSupportingDocs } from '../../fvs-supporting-docs-provider';
import { useFvsLandingPageContext } from '../fvs-landing-page-provider';
import VehicleStandardsSupportingDoc from './supporting-docs';
import RelatedTasks from './related-tasks';
import FvsHistory from './fvs-history';

const RelatedInfo = () => {
  const ability = useAppAbility();

  const { vehicleStandard } = useFederalStandards();

  const { supportingDocs } = useFvsSupportingDocs();

  const { relatedTasks, getStandardsRelatedTasks } = useFvsLandingPageContext();

  useEffect(() => {
    // TODO - this will lead to wrong count showing in the badge
    if (vehicleStandard && vehicleStandard.id) {
      getStandardsRelatedTasks({
        variables: {
          filters: {
            operator: 'AND',
            value: [
              {
                operator: 'EQ',
                key: 'fvsId',
                value: vehicleStandard.id,
              },
            ],
          },
          limit: 5,
          offset: 0,
          order: 'createdAt DESC',
        },
      });
    }
  }, [vehicleStandard]);

  return (
    <div className="related-info-tabs margin-top-7">
      <h2>Related information</h2>
      <Tabs className="related-info-tabs-width">
        <TabList style={{ padding: 0 }}>
          <Tab data-testid="vehicle-standards-docs-tab">
            <span className="text-no-wrap text-bold">
              Documents
              {supportingDocs?.count > 0 && (
                <CounterTag
                  count={supportingDocs?.count}
                  className="margin-left-1"
                />
              )}
            </span>
          </Tab>
          {ability.can(OPERATIONS.Update, SUBJECTS.FVS) ? (
            <Tab data-testid="vehicle-standards-task-tab">
              <span className="text-no-wrap text-bold">
                Tasks
                {relatedTasks?.count > 0 && (
                  <CounterTag
                    count={relatedTasks?.count}
                    className="margin-left-1"
                  />
                )}
              </span>
            </Tab>
          ) : null}
          {ability.can(OPERATIONS.Update, SUBJECTS.FVS) ? (
            <Tab data-testid="vehicle-standards-history-tab">
              <span className="text-no-wrap text-bold">
                History
                {vehicleStandard?.statusHistory?.length > 0 && (
                  <CounterTag
                    count={vehicleStandard?.statusHistory?.length}
                    className="margin-left-1"
                  />
                )}
              </span>
            </Tab>
          ) : null}
        </TabList>

        <TabPanel>
          <VehicleStandardsSupportingDoc />
        </TabPanel>

        {ability.can(OPERATIONS.Update, SUBJECTS.FVS) ? (
          <TabPanel>
            <RelatedTasks />
          </TabPanel>
        ) : null}

        {ability.can(OPERATIONS.Update, SUBJECTS.FVS) ? (
          <TabPanel>
            <FvsHistory />
          </TabPanel>
        ) : null}
      </Tabs>
    </div>
  );
};

export default RelatedInfo;
