/* eslint-disable react/prop-types */
/*  eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import PropTypes from 'prop-types';
import { RequiredFieldIndicator } from '@gsa/afp-component-library';
import '../../../fvs/requirements/components/make-model/make-model-table.scss';
import MakeTypeAhead from '../../../fvs/requirements/components/make-model/make-type-ahead';
import ModelTypeAhead from '../../../fvs/requirements/components/make-model/model-type-ahead';
import { useMakeAndModeApprovalRequestContext } from './make-model-approval-provider';

const MakeAndModelHeader = ({ title, required }) => {
  return (
    <div className="titleHeader" aria-label={title} tabIndex="0">
      {title}
      {required && (
        <span className="margin-left-05">
          <RequiredFieldIndicator />
        </span>
      )}
    </div>
  );
};

const MakeAndModelSearchPanel = ({ hasChassis }) => {
  const {
    makeCode,
    makeName,
    modelCode,
    modelName,
    // status,
    chassisMakeCode,
    chassisMakeName,
    chassisModelCode,
    chassisModelName,
    chassisMakeCustomKeyword,
    chassisModelCustomKeyword,
    makeCustomKeyword,
    modelCustomKeyword,
    setAddMakeModelContext,
    isSubmitted,
    isClearedTypeAhead,
  } = useMakeAndModeApprovalRequestContext();

  // const hasChassis = chassisMakeCode && chassisModelCode;

  const handleModelChanged = (val) => {
    setAddMakeModelContext('SET_MODEL', val);
  };
  const handleMakeChanged = (val) => {
    handleModelChanged();
    setAddMakeModelContext('SET_MAKE', val);
  };
  const handleChassisModelChanged = (val) => {
    setAddMakeModelContext('SET_CHASSIS_MODEL', val);
  };
  const handleChassisMakeChanged = (val) => {
    handleChassisModelChanged();
    setAddMakeModelContext('SET_CHASSIS_MAKE', val);
  };

  return (
    <>
      <div className="grid-row grid-gap bg-primary-lighter padding-top-2">
        <div className="grid-col flex-1 margin-bottom-3">
          <MakeAndModelHeader title="Body Make" required />
          <MakeTypeAhead
            dataTestId="make"
            required
            isClearedTypeAhead={isClearedTypeAhead}
            isSubmitted={isSubmitted}
            onChange={handleMakeChanged}
            makeCode={makeCode}
            makeName={makeName}
            makeCustomKeyword={makeCustomKeyword}
            setFvsPreBidContextData={setAddMakeModelContext}
            customKeywordKey="SET_MAKE_CUSTOM_KEYWORD"
            placeholder="Search makes"
          />
        </div>
        <div className="grid-col flex-1">
          <MakeAndModelHeader title="Body Model" required />
          <ModelTypeAhead
            dataTestId="model"
            isClearedTypeAhead={isClearedTypeAhead}
            isSubmitted={isSubmitted}
            onChange={handleModelChanged}
            modelCode={modelCode}
            makeCode={makeCode}
            modelName={modelName}
            modelCustomKeyword={modelCustomKeyword}
            setFvsPreBidContextData={setAddMakeModelContext}
            customKeywordKey="SET_MODEL_CUSTOM_KEYWORD"
            placeholder="Search models"
          />
        </div>
        {hasChassis && (
          <div className="grid-col flex-1">
            <>
              <MakeAndModelHeader title="Chassis Make" required />
              <MakeTypeAhead
                dataTestId="chassisMake"
                isClearedTypeAhead={isClearedTypeAhead}
                isSubmitted={isSubmitted}
                onChange={handleChassisMakeChanged}
                makeCode={chassisMakeCode}
                makeName={chassisMakeName}
                makeCustomKeyword={chassisMakeCustomKeyword}
                setFvsPreBidContextData={setAddMakeModelContext}
                customKeywordKey="SET_CHASSIS_MAKE_CUSTOM_KEYWORD"
                placeholder="Search chassis makes"
              />
            </>
          </div>
        )}
        {hasChassis && (
          <div className="grid-col flex-1">
            <>
              <MakeAndModelHeader title="Chassis Model" required />
              <ModelTypeAhead
                dataTestId="chassisModel"
                isClearedTypeAhead={isClearedTypeAhead}
                isSubmitted={isSubmitted}
                onChange={handleChassisModelChanged}
                makeCode={chassisMakeCode}
                modelName={chassisModelName}
                modelCode={chassisModelCode}
                modelCustomKeyword={chassisModelCustomKeyword}
                setFvsPreBidContextData={setAddMakeModelContext}
                customKeywordKey="SET_CHASSIS_MODEL_CUSTOM_KEYWORD"
                placeholder="Search chassis models"
              />
            </>
          </div>
        )}
      </div>
    </>
  );
};

MakeAndModelSearchPanel.propTypes = {
  hasChassis: PropTypes.bool.isRequired,
};

export default MakeAndModelSearchPanel;
