import React, { useEffect, useState } from 'react';
import { Spinner } from '@gsa/afp-component-library';
import { gql } from '@apollo/client';
import Attachment from '../../../../components/Attachment';
import AttachmentProvider, {
  useAttachments,
} from '../../../../components/Attachment/AttachmentProvider';

import { GET_STANDARDS_REL_DOCS } from '../../fvs.gql';
import { useFederalStandards } from '../../fvs-provider';
import { useFvsSupportingDocs } from '../../fvs-supporting-docs-provider';

const AttachmentWrapper = () => {
  const {
    fetching,
    rows,
    count,
    hasMore,
    loadMore,
    currentFetchVariables: { limit },
    sortAttachment,
    attachmentErrors,
    setAttachmentErrors,
    linkedEntities,
  } = useAttachments();
  const { downloadPublicFile } = useFvsSupportingDocs();

  return (
    <div className="attachment" data-testid="attachment-container">
      <Attachment
        publicView
        title=""
        loading={fetching}
        data={rows}
        count={count}
        hasMore={hasMore}
        loadMoreLimit={5}
        paginationLimit={limit}
        onLoadMore={loadMore}
        onDownload={(v) => {
          downloadPublicFile({
            fileKey: v.readUri,
            fileType: v.fileMimeType,
            fileName: v.name,
          });
        }}
        onSort={sortAttachment}
        currentUser={undefined}
        errors={attachmentErrors}
        setErrors={setAttachmentErrors}
        linkedEntities={linkedEntities}
        allowChanges={false}
        isFromTaskDetail={false}
        fileTypes={['pdf', 'jpeg', 'jpg', 'docx']}
        maxFileSize={10 * 1024 * 1024} // 10MB
      />
    </div>
  );
};

AttachmentWrapper.defaultProps = {};
AttachmentWrapper.propTypes = {};

const VehicleStandardsSupportingDoc = () => {
  const { vehicleStandard } = useFederalStandards();

  const {
    getStandardsSupportingDocs,
    setSupportingDocs,
    isAttachmentLoading,
  } = useFvsSupportingDocs();

  const [showAttachments, setShowAttachments] = useState(false);

  useEffect(() => {
    setShowAttachments(!isAttachmentLoading);
  }, [isAttachmentLoading]);

  useEffect(() => {
    if (vehicleStandard && vehicleStandard.id) {
      getStandardsSupportingDocs({
        variables: {
          filters: {
            operator: 'AND',
            value: [
              {
                operator: 'EQ',
                key: 'fvsId',
                value: vehicleStandard.id,
              },
            ],
          },
          limit: 5,
          offset: 0,
          order: 'createdAt DESC',
        },
      });
    }
  }, [vehicleStandard]);

  return (
    <>
      {!showAttachments && <Spinner size="small" />}

      {showAttachments && (
        <AttachmentProvider
          uploadButtonLabel="Add supporting document"
          fetchGql={GET_STANDARDS_REL_DOCS}
          deleteGql={gql`
            mutation DeleteFvsDocument($id: Float!) {
              deleteSupportingDocument(id: $id)
            }
          `}
          fetchVariables={{
            filters: {
              operator: 'AND',
              value: [
                {
                  operator: 'EQ',
                  key: 'fvsId',
                  value: vehicleStandard?.id,
                },
              ],
            },
            limit: 5,
            offset: 0,
            order: 'createdAt DESC',
          }}
          setParentContext={setSupportingDocs}
          linkedEntities={{}}
          currentUser={undefined}
        >
          <AttachmentWrapper allowChanges={false} />
        </AttachmentProvider>
      )}
    </>
  );
};

export default VehicleStandardsSupportingDoc;
