import React from 'react';

export const convertToFullName = (listOfUsers, userId) => {
  if (listOfUsers) {
    const userFound = listOfUsers.find((user) => user.id === userId);
    if (userFound) return `${userFound?.fullName}`;
  }
  return <span>&ndash;</span>;
};

export const capitalizeFirstLetter = (string = '') =>
  string.charAt(0).toUpperCase() + string.slice(1);

const termMap = {
  SPR: 'Standards Peer Review',
};

export const displayName = (term) => {
  return termMap[term] || term;
};
