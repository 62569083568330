/* eslint-disable no-unused-vars */
import {
  AFPTable,
  EmptyState,
  FilterTableFrame,
  Pagination,
  Tag,
} from '@gsa/afp-component-library';
import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import useDidMountEffect from '../../../hooks/use-did-mount';
import { OPERATIONS, SUBJECTS } from '../../../utilities/constants';
import { LABELS } from '../constants';
import { useCommentsAdmin } from '../comments-admin-provider';
import { tagColors } from '../helpers';
import CommentDetail from './components/comment-detail';
import CommentsFilters from '../comments-filter-panel';

/* eslint-disable react/prop-types */
const ViewCommentsTable = () => {
  const { getComments, comments } = useCommentsAdmin();
  const { currentUser } = useCurrentUser();
  const ability = useAppAbility();

  const defaultFilters = {
    operator: 'AND',
    value: [
      {
        key: 'year',
        operator: 'EQ',
        value: `${new Date().getFullYear()}`,
      },
    ],
  };

  const [filters, setFilters] = useState(defaultFilters);
  const [order, setOrder] = useState('createdAt DESC');
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  const tableRef = React.createRef();

  const getData = () => {
    const { limit, offset } = paginationState;
    getComments({
      variables: { filters, limit, offset, order },
    });
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  useDidMountEffect(() => {
    handlePaginationChange(1, paginationState.limit);
    setOrder('createdAt DESC');
    getData();
  }, [filters]);

  useDidMountEffect(() => {
    getData();
  }, [order, paginationState]);

  const columns = useMemo(() => {
    return [
      {
        Header: LABELS.DATE,
        accessor: 'createdAt',
        Cell: ({ value }) => (
          <div aria-label={moment(value).format('MM/DD/YYYY')}>
            {moment(value).format('MM/DD/YYYY')}
          </div>
        ),
      },
      {
        Header: LABELS.COMMENT_DETAIL,
        accessor: 'comment',
        sortable: false,
        Cell: ({ row }) => (
          <CommentDetail
            props={row?.original}
            currentUser={currentUser.id}
            canManage={ability.can(OPERATIONS.Manage, SUBJECTS.FVSComments)}
          />
        ),
      },
      {
        Header: LABELS.REVIEW_STATE,
        accessor: 'status',
        Cell: ({ value }) => (
          <div className="width-15">
            <Tag variant={tagColors[value]}>{value}</Tag>
          </div>
        ),
      },
    ];
  }, []);

  const TableWrapper = ({ tableProps, paginationProps }) => (
    <>
      <AFPTable {...tableProps} />
      {tableProps.data?.length ? <Pagination {...paginationProps} /> : null}
      {tableProps.data === null ||
        (tableProps.data?.length === 0 && (
          <div className="text-center margin-top-neg-2 height-full">
            <EmptyState
              hasBackground
              containerStyles="padding-top-9 height-full"
              topText={
                <>
                  <strong>No comments to view</strong>
                </>
              }
            />
          </div>
        ))}
    </>
  );

  const FTF = useMemo(
    () => FilterTableFrame(null, null, CommentsFilters, TableWrapper),
    [],
  );

  return (
    <FTF
      lowLeftProps={{
        currentFilters: filters,
        setFilters,
      }}
      lowRightProps={{
        tableProps: {
          columns,
          data: comments?.rows || [],
          onSort: setOrder,
          defaultSort: order,
          fullWidth: true,
          ref: tableRef,
          testId: 'comment-admin-table',
        },
        paginationProps: {
          itemsPerPageOptions: [10, 25, 50],
          onPageChange: handlePaginationChange,
          variant: 'advanced',
          itemsPerPage: paginationState.limit,
          currentPage: paginationState.currentPage,
          itemsCount: comments.count,
        },
      }}
      filterToggle
    />
  );
};

export default ViewCommentsTable;
