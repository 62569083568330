import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage, RichTextEditor } from '@gsa/afp-component-library';

const DescriptionInput = () => {
  const { errors, control } = useFormContext();

  return (
    <>
      <div className="grid-col-auto">
        <h2 className="font-sans-xs">Description</h2>
      </div>

      <Controller
        id="si_form_description"
        name="content"
        control={control}
        render={({ onChange, value }) => (
          <RichTextEditor
            label="Description"
            onChange={(val) => {
              onChange(val);
            }}
            content={value}
          />
        )}
      />
      {errors?.content?.message && (
        <ErrorMessage>{errors.content.message}</ErrorMessage>
      )}
    </>
  );
};

DescriptionInput.propTypes = {};

export default DescriptionInput;
