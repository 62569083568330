import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  SelectDropdown,
  MultiSelectDropdown,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import {
  getReportTypes,
  REPORTS_ALL,
  REPORTS_VEHICLE_SUPPLIER,
  REPORT_CONFIG,
} from './bid-report-types';
import { DEFAULT_OPTION } from './bid-report-helper';

const ReportSelectionForm = ({
  canViewAll,
  formValues,
  setFormValues,
  onNext,
}) => {
  const reportGroups = canViewAll ? REPORTS_ALL : REPORTS_VEHICLE_SUPPLIER;
  const getReportOptions = () => {
    const { value } = formValues.reportType;
    if (value)
      return [
        DEFAULT_OPTION,
        ...reportGroups[value].map((rpt) => ({
          value: rpt,
          label: REPORT_CONFIG[rpt].label,
        })),
      ];
    return [DEFAULT_OPTION];
  };

  const getContractYearOptions = (withDefault = false) => {
    const currentYear = new Date().getFullYear();
    const years = withDefault ? [DEFAULT_OPTION] : [];
    for (let i = currentYear + 1; i >= currentYear - 1; i -= 1) {
      years.push({ value: i.toString(), label: i.toString() });
    }
    return years;
  };

  const onReportTypeChange = ({ target: { value } }) => {
    setFormValues({
      ...formValues,
      reportType: {
        value,
        errorMessage: value ? '' : 'Report type is required',
      },
      report: { value: '', errorMessage: '' },
      contractYear: { value: '', errorMessage: '' },
      contractYears: { value: [], errorMessage: '' },
    });
  };

  const onReportChange = ({ target: { value } }) => {
    setFormValues({
      ...formValues,
      report: { value, errorMessage: value ? '' : 'Report is required' },
      contractYear: { value: '', errorMessage: '' },
      contractYears: { value: [], errorMessage: '' },
    });
  };

  const onContractYearsChange = (value) => {
    setFormValues({
      ...formValues,
      contractYears: {
        value,
        errorMessage:
          formValues.contractYears.value.length && value.length !== 2
            ? 'Must select two contract years'
            : '',
      },
    });
  };
  const onContractYearChange = ({ target: { value } }) => {
    setFormValues({
      ...formValues,
      contractYear: {
        value,
        errorMessage: value ? '' : 'Contract year is required',
      },
    });
  };

  const onClickNext = () => {
    const { reportType, report, contractYear, contractYears } = formValues;
    if (
      reportType.value &&
      report.value &&
      (contractYear.value || contractYears.value.length === 2)
    )
      onNext();
    else
      setFormValues({
        ...formValues,
        ...(reportType.value
          ? {}
          : {
              reportType: {
                value: '',
                errorMessage: 'Report type is required',
              },
            }),
        ...(report.value
          ? {}
          : { report: { value: '', errorMessage: 'Report is required' } }),
        ...(!REPORT_CONFIG[report.value]?.contractYearsMS && !contractYear.value
          ? {
              contractYear: {
                value: '',
                errorMessage: 'Contract year is required',
              },
            }
          : {}),
        ...(REPORT_CONFIG[report.value]?.contractYearsMS &&
        contractYears.value.length !== 2
          ? {
              contractYears: {
                value: contractYears.value,
                errorMessage: 'Must select two contract years',
              },
            }
          : {}),
      });
  };

  return (
    <>
      <div className="grid-row margin-top-4">
        <div className="grid-col-6">
          <SelectDropdown
            autoFocus
            label={<strong>Select a report type</strong>}
            name="select report type"
            className="usa-select"
            data-testid="report-type-select"
            onChange={onReportTypeChange}
            value={formValues.reportType.value}
            errorMessage={formValues.reportType.errorMessage}
            options={[
              DEFAULT_OPTION,
              ...getReportTypes(reportGroups).map((type) => ({
                value: type,
                label: type,
              })),
            ]}
            required
          />
        </div>
      </div>
      <div className="grid-row">
        <div className="grid-col-6">
          <SelectDropdown
            label={<strong>Select a report</strong>}
            name="select a report"
            className="usa-select"
            data-testid="report-select"
            value={formValues.report.value}
            errorMessage={formValues.report.errorMessage}
            options={getReportOptions()}
            onChange={onReportChange}
            required
          />
        </div>
      </div>
      <div className="grid-row">
        <div className="grid-col-6">
          {REPORT_CONFIG[formValues.report.value]?.contractYearsMS ? (
            <div
              data-testid="contract-year-multi-select"
              className={
                formValues.contractYears.errorMessage
                  ? 'grid-col-9 border-left-05 border-secondary-dark padding-left-2 margin-left-neg-2'
                  : 'grid-col-9'
              }
            >
              <label
                className="usa-label margin-bottom-1"
                htmlFor="select-standard-items"
              >
                <strong>Select 2 years from last 3 contract years</strong>{' '}
                <RequiredFieldIndicator />
              </label>
              {formValues.contractYears.errorMessage && (
                <div className="text-bold text-secondary-dark margin-bottom-1">
                  {formValues.contractYears.errorMessage}
                </div>
              )}
              <div
                className={
                  formValues.contractYears.errorMessage
                    ? 'border-05 border-secondary-dark'
                    : ''
                }
              >
                <MultiSelectDropdown
                  id="contract-year-multi-select"
                  name="contract-year-multi-select"
                  selectedValues={formValues.contractYears.value}
                  options={getContractYearOptions()}
                  onChange={onContractYearsChange}
                />
              </div>
            </div>
          ) : (
            <SelectDropdown
              label={
                <strong>Select contract year (last 3 contract years)</strong>
              }
              name="select contract year"
              className="usa-select"
              data-testid="contract-year-select"
              value={formValues.contractYear.value}
              errorMessage={formValues.contractYear.errorMessage}
              options={getContractYearOptions(true)}
              onChange={onContractYearChange}
              required
            />
          )}
        </div>
      </div>
      <div className="grid-row margin-top-8">
        <Button
          variant="primary"
          label="Next"
          data-testid="next-button"
          rightIcon={{ name: 'arrow_forward' }}
          onClick={onClickNext}
        />
      </div>
    </>
  );
};

ReportSelectionForm.defaultProps = { canViewAll: false };
ReportSelectionForm.propTypes = {
  canViewAll: PropTypes.bool,
  formValues: PropTypes.shape(Object).isRequired,
  setFormValues: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default ReportSelectionForm;
