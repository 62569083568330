/* eslint-disable react/prop-types */
import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import AddSolicitationFrom from './add-solicitation-form';

const solicationFormSchema = yup.object().shape({
  upiidAAC: yup
    .string()
    .min(6, 'This field must be 6 characters')
    .max(6, 'This field must be 6 characters')
    .required('This is a required field')
    .matches('[a-zA-Z0-9]{6}', 'Invalid UPIID number'),
  upiidSQ: yup
    .string()
    .min(7, 'This field must be 7 characters')
    .max(7, 'This field must be 7 characters')
    .required('This is a required field')
    .matches('^[0-9]{2}[a-zA-Z]{1}[0-9]{4}$', 'Invalid UPIID number'),
  solicitationTitle: yup.string().required('This is a required field'),
  solicitationDescription: yup.string(),
  solicitationPosting: yup.string().url('Please enter a valid URL'),
  contractYear: yup.string().required(),
  purchaseType: yup.string().required(),
});
// eslint-disable-next-line react/prop-types
const AddSoliciationFormContext = ({ children }) => {
  const defaultValues = {
    upiidAAC: '47QMCA',
    bidType: 'IN_FLEET',
  };
  const methods = useForm({
    resolver: yupResolver(solicationFormSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues,
  });
  return <FormProvider {...methods}>{children}</FormProvider>;
};

const EditSolicitationFormContext = ({ solicitation, children }) => {
  const defaultValues = {
    ...solicitation,
  };
  const methods = useForm({
    resolver: yupResolver(solicationFormSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues,
  });
  return <FormProvider {...methods}>{children}</FormProvider>;
};

const SolicitationFormWrapper = ({ solicitation }) => {
  // if we are coming back from prev page.
  if (solicitation?.solicitationID) {
    return (
      // Edit context is required because when we navigage back from Open-period page, since the solicitation is already created we
      // need to treat the add page as edit so the context is different.
      <EditSolicitationFormContext solicitation={solicitation}>
        <AddSolicitationFrom />
      </EditSolicitationFormContext>
    );
  }
  return (
    <AddSoliciationFormContext>
      <AddSolicitationFrom />
    </AddSoliciationFormContext>
  );
};

export default SolicitationFormWrapper;
