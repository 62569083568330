import React from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import BidCommunicationCenterTable from './change-notification-table';
import { GET_BID_ELEMENTS_CHANGE_HISTORY } from '../bids.gql';

const BidCommunicationCenter = ({ bidId }) => {
  const { data, error, isDataLoading } = useQuery(
    GET_BID_ELEMENTS_CHANGE_HISTORY,
    {
      fetchPolicy: 'cache-and-network',
      variables: { bidId: parseInt(bidId, 10) },
      skip: !bidId,
    },
  );

  const tableData =
    data?.getBidElementsChangeWithHistory?.changeHistories || [];

  return (
    <BidCommunicationCenterTable
      tableData={tableData}
      error={error}
      isDataLoading={isDataLoading}
    />
  );
};

BidCommunicationCenter.propTypes = {
  bidId: PropTypes.number.isRequired,
};

export default BidCommunicationCenter;
