import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@gsa/afp-component-library';
import ReactTooltip from 'react-tooltip';
import { useLazyQuery } from '@apollo/client';
import OverlaySpinner from '../../../../components/overlay-spinner';
import { FT_PDF, REPORT_CONFIG } from '../bid-reports/bid-report-types';
import {
  GET_JS_REPORT,
  getDownloadAlert,
  asyncDownloadAlert,
} from '../../bid-reports/bid-report-helpers';

const BidsDownloadLink = ({ bidId, setAlert }) => {
  const [getJSReportData, { loading: loadingJSReport }] = useLazyQuery(
    GET_JS_REPORT,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ getJSReport: res }) => {
        setAlert(
          res.requestSent
            ? asyncDownloadAlert
            : getDownloadAlert(
                res.reportName,
                res.content.data,
                res.contentType,
                `${res.reportName}.${res.fileExtension}`,
              ),
        );
      },
    },
  );

  const onDownload = (reportTypeId) => {
    setAlert(null);
    getJSReportData({
      variables: {
        input: {
          reportTypeId,
          outputType: FT_PDF,
          queryParams: { bidId: +bidId },
        },
      },
    });
  };

  return (
    <div className="grid-container">
      {loadingJSReport && <OverlaySpinner />}
      <div className="grid-row padding-top-0 flex-display">
        <Button
          type="button"
          variant="unstyled"
          onClick={() => {
            onDownload(REPORT_CONFIG.submittedLineItems.reportTypeId);
          }}
          className="margin-left-1 gird-col-3 text-primary"
          label="Download submitted line items"
          data-testid="sol-download-submitted-line-items"
          leftIcon={{ name: 'file_download', type: 'file_download' }}
          rightIcon={{ name: 'info', type: 'info' }}
          data-tip="Download all the submitted bid lines for this solicitation with the bid inputs"
          data-for="submittedItemsTooltip"
        />
        <ReactTooltip id="submittedItemsTooltip" place="right" effect="solid" />
      </div>
      <div className="grid-row padding-top-0">
        <Button
          type="button"
          variant="unstyled"
          onClick={() => onDownload(REPORT_CONFIG.allLineItems.reportTypeId)}
          className="margin-left-1 gird-col-3 text-primary"
          label="Download all line items"
          data-testid="sol-download-all-line-items"
          leftIcon={{ name: 'file_download', type: 'file_download' }}
          rightIcon={{ name: 'info', type: 'info' }}
          data-tip="Download all the bid lines for this solicitation with the bid inputs"
          data-for="allItemsTooltip"
        />
        <ReactTooltip id="allItemsTooltip" place="right" effect="solid" />
      </div>
    </div>
  );
};

BidsDownloadLink.propTypes = {
  bidId: PropTypes.string.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default BidsDownloadLink;
