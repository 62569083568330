/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Typeahead } from '@gsa/afp-component-library';
import { GET_MAKE_BY_PARTIAL_MATCH } from '../../../fvs.gql';

let userTyped = false;

const MakeTypeAhead = ({
  onChange,
  customKeywordKey,
  makeName,
  makeCustomKeyword,
  setFvsPreBidContextData,
  isSubmitted,
  isClearedTypeAhead,
  placeholder,
  dataTestId,
}) => {
  const [option, setOption] = useState({ values: [], raw: [] });
  // eslint-disable-next-line no-unused-vars
  const [, setCustomKeyword] = useState('');

  const [getMakesByPartialName] = useLazyQuery(GET_MAKE_BY_PARTIAL_MATCH, {
    onCompleted: (data) => {
      let result = { values: [], raw: [] };
      if (data?.getMakesByPartialMatch?.length) {
        result = {
          values: data.getMakesByPartialMatch.map(
            (m) => `${m.makeName} -- ${m.makeCode}`,
          ),
          raw: data.getMakesByPartialMatch,
        };
      }
      setOption(() => {
        if (result.values.length > 0) {
          return result;
        }
        return { values: ['No results found.'], raw: ['No results found.'] };
      });
    },
  });

  const forceUpdateValue =
    makeCustomKeyword != null && !makeName
      ? { value: makeCustomKeyword }
      : false;

  const generateCustomOption = (opt) => {
    const item = option?.raw.find((o) => o.makeCode === opt?.split(' -- ')[1]);
    return (
      <>
        {item ? (
          <div className="display-flex flex-column">
            <span className="text-ink">Make: {item?.makeName}</span>
            <span className="text-ink">Code: {item?.makeCode}</span>
          </div>
        ) : (
          opt
        )}
      </>
    );
  };

  return (
    <Typeahead
      id={dataTestId}
      role="textbox"
      name="makeModel"
      filterValue={makeName}
      typeaheadValues={option?.values}
      onFilterKeyDown={(e) => {
        userTyped = true;
        setFvsPreBidContextData(customKeywordKey, e.target.value);
        if (isClearedTypeAhead) {
          setFvsPreBidContextData('SET_TYPE_AHEAD_IS_CLEARED', false);
        }
      }}
      onOptionEnter={(selected) => {
        const selectedData = option?.raw.find(
          (o) => o.makeCode === selected?.split(' -- ')[1],
        );
        if (selectedData) {
          onChange({ ...selectedData });
        }
        setFvsPreBidContextData(customKeywordKey, '');
        setFvsPreBidContextData('SET_IS_SUBMITTED', false);
      }}
      fetchTypeaheadValues={(_, search) => {
        const keyword = search.trim();
        setCustomKeyword(keyword);

        if (userTyped) {
          getMakesByPartialName({
            variables: {
              makeName: keyword,
              dataSource: ['AFP', 'NHTSA'],
            },
          });
          userTyped = false;
        }
      }}
      forceUpdate={forceUpdateValue}
      debounceDelay={500}
      inputCharNum={0}
      placeholder={placeholder}
      reset={isSubmitted}
      onClear={(selected) => {
        onChange({ selected, undefined });
      }}
      generateCustomOption={generateCustomOption}
      disablePaste
      promptText="Type to search for makes"
    />
  );
};

MakeTypeAhead.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default MakeTypeAhead;
