import React from 'react';
import * as PropTypes from 'prop-types';
import { Alert, Button } from '@gsa/afp-component-library';

export const MessageType = {
  SUCCESS: 'success',
  ERROR: 'error',
};

const ToastMessage = ({ type, onClose, message, closeable, className }) => {
  return (
    <Alert
      slim
      className={className}
      actions={
        <div className="position-absolute right-0 top-0">
          {closeable && (
            <Button
              data-testid="toast-msg-close-btn"
              aria-label="close"
              variant="unstyled"
              style={{ color: 'black' }}
              className="margin-x-1"
              onClick={onClose}
              leftIcon={{
                name: 'close',
                className: 'usa-icon--size-4',
              }}
            />
          )}
        </div>
      }
      focused
      type={type}
    >
      {message}
    </Alert>
  );
};

ToastMessage.defaultProps = {
  closeable: true,
  className: '',
};

ToastMessage.propTypes = {
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(Object)])
    .isRequired,
  closeable: PropTypes.bool,
  className: PropTypes.string,
};

export default ToastMessage;
