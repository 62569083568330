import { useLazyQuery } from '@apollo/client';
import { ErrorMessage, Label, Typeahead } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { GET_OPTIONS } from '../../services/data-store';
import YearInput from './year-input';

let userTyped = false;

const AutoCompleteSelect = ({
  onChange,
  value,
  retrieveValues,
  yearInputName,
  year,
}) => {
  const [option, setOption] = useState({ values: [], raw: [] });
  const [reset, setReset] = useState(false);
  const [userInput, setUserInput] = useState('');
  let TypeAheadErrorMessage = '';
  // Executes when the Year filter changes.
  useEffect(() => {
    setReset(true);
    onChange({ selectedData: { id: '0' } });
    setOption({ values: [], raw: [] });
  }, [year]);

  // Executes when the text changes with in the TypeAhead.
  useEffect(() => {
    if (userInput?.length <= 1 && value > 0) {
      setUserInput('');
      onChange({ selectedData: { id: '0' } });
    }
  }, [userInput]);

  // determines the error message based on the error code
  const optionvalue = option?.values[0];
  if (optionvalue === 'Error: 404') {
    TypeAheadErrorMessage =
      'Standard Item Code was not found for the selected Contract year.';
  } else if (optionvalue === 'Error: emptyYear') {
    TypeAheadErrorMessage = 'Please select a Contract year';
  }

  const [getOptions] = useLazyQuery(GET_OPTIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let result = { values: [], raw: [] };
      if (data?.getOptions) {
        result = {
          values: data.getOptions.map((v) => v.value),
          raw: data.getOptions,
        };
      }
      // Determine what needs to be sent as a response to display in the Error Message for the TypeAhead.
      setOption(() => {
        if (result.values.length > 0) {
          return result;
        }
        if (userInput?.length === 0) {
          return {
            values: ['Error: emptyKeyword'],
            raw: ['Error: emptyKeyword'],
          };
        }
        if (year === '0' || year === 0) {
          return { values: ['Error: emptyYear'], raw: ['Error: emptyYear'] };
        }
        return { values: ['Error: 404'], raw: ['Error: 404'] };
      });
    },
    onError: () => {
      //  TODO - intentionally blank now
    },
  });

  AutoCompleteSelect.defaultProps = {
    value: '',
    yearInputName: '',
  };
  AutoCompleteSelect.propTypes = {
    value: PropTypes.string,
    year: PropTypes.string.isRequired,
    yearInputName: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    retrieveValues: PropTypes.func.isRequired,
  };

  return (
    <div className="margin-top-1">
      <Typeahead
        name="from standard item number"
        aria-label="from standard item number"
        role="textbox"
        id="copyFromStandardItemId"
        filterValue=""
        typeaheadValues={option?.values}
        onFilterKeyDown={(e) => {
          // Executes when the user types text in the type ahead.
          setUserInput(e.target.value);
          userTyped = true;
          setReset(false);
        }}
        onOptionEnter={(selected) => {
          // Executes the following when the user selects a option from the returned options.
          const selectedData = option?.raw.find((o) => o.label === selected);
          onChange({ selected, selectedData });
        }}
        fetchTypeaheadValues={(_, search) => {
          // Executes when text changes in the type ahead and fetches the values.
          const keyword = search.trim();
          if (userTyped) {
            getOptions({
              variables: {
                model: 'StandardItem',
                label: 'standardItemNumber',
                value: 'standardItemNumber',
                limit: 10,
                filter: {
                  operator: 'AND',
                  value: [
                    {
                      operator: 'EQ',
                      key: 'year',
                      value: retrieveValues(yearInputName),
                    },
                    {
                      operator: 'LIKE',
                      key: 'standardItemNumber',
                      value: keyword,
                    },
                  ],
                },
              },
            });

            userTyped = false;
          }
        }}
        debounceDelay={250}
        errorMessage={TypeAheadErrorMessage}
        inputCharNum={0}
        forceUpdate={reset ? { value: '' } : null}
        onClear={(selected) => {
          onChange({ selected, undefined });
        }}
      />
    </div>
  );
};

const StandardItemCopyInput = ({
  sectionTitle,
  isMandatory,
  yearInputName,
  years,
  sinInputName,
}) => {
  const {
    errors,
    clearErrors,
    control,
    getValues,
    watch,
    setValue,
  } = useFormContext();

  const setCopyFromStandardItemId = (selectedData) => {
    if (selectedData?.id !== '0') {
      setValue('copyFromStandardItemId', parseInt(selectedData?.id, 10));
      clearErrors('copyFromStandardItemId');
    }
  };

  return (
    <>
      <Label
        htmlFor={yearInputName}
        className="text-bold text-primary"
        aria-label="Contract year"
      >
        {sectionTitle}&nbsp;
        {isMandatory && (
          <abbr title="required" className="usa-hint usa-hint--required">
            *
          </abbr>
        )}
      </Label>

      <YearInput name={yearInputName} isAddForm years={years} />

      <Label htmlFor={sinInputName} className="text-bold">
        Standard Item Number&nbsp;
        {isMandatory && (
          <abbr title="required" className="usa-hint usa-hint--required">
            *
          </abbr>
        )}
      </Label>

      <AutoCompleteSelect
        value={watch('copyFromStandardItemId')}
        name={sinInputName}
        retrieveValues={getValues}
        aria-label="standard item number"
        onChange={({ selectedData }) => {
          setCopyFromStandardItemId(selectedData);
        }}
        yearInputName={yearInputName}
        year={watch('copyFromYear')}
      />

      {errors?.copyFromStandardItemId?.message && (
        <ErrorMessage>{errors.copyFromStandardItemId?.message}</ErrorMessage>
      )}
      <Controller control={control} name="copyFromYear" defaultValue={0} />
      <Controller
        control={control}
        name="copyFromStandardItemId"
        defaultValue={0}
      />
    </>
  );
};

StandardItemCopyInput.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  isMandatory: PropTypes.bool.isRequired,
  yearInputName: PropTypes.string.isRequired,
  years: PropTypes.arrayOf(PropTypes.number).isRequired,
  sinInputName: PropTypes.string.isRequired,
};

export default StandardItemCopyInput;
