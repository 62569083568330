export const tagColors = {
  // New: '#008817',
  // Noted: '#D9E8F6',
  // Invalid: '#71767A',
  // Disapproved: '#FAF3D1',
  // Approved: '#DDF9C7',
  New: 'Ready-Dark',
  Noted: 'Info-Light',
  Invalid: 'Inactive-Medium',
  Disapproved: 'Important-Light',
  Approved: 'Ready-Light',
};

export const tagClasses = {
  New: 'text-white',
  Noted: 'text-black',
  Invalid: 'text-white',
  Disapproved: 'text-black',
  Approved: 'text-black',
};

export const publishStatusColor = {
  Published: 'Ready-Gray',
  Unpublished: 'InActive-Gray',
};
export const STATUS_MAP = {
  Noted: 1,
  Approved: 2,
  Disapproved: 3,
  Invalid: 4,
};

export const PUBLISH_STATUS_MAP = {
  Unpublished: 0,
  Published: 1,
};

export const options = [
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: {
      operator: 'EQ',
      key: 'category',
      value: 'Federal Standard Codes',
    },
    uniqueKey: 'fedStandardCode',
    includeValueInLabel: true,
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: {
      operator: 'EQ',
      key: 'category',
      value: 'Comments Review Status',
    },
    uniqueKey: 'status',
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: {
      operator: 'EQ',
      key: 'category',
      value: 'Comments Publish Status',
    },
    uniqueKey: 'publishStatus',
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: { operator: 'EQ', key: 'category', value: 'User Type' },
    uniqueKey: 'userType',
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: { operator: 'EQ', key: 'category', value: 'Origin' },
    uniqueKey: 'origin',
  },
];
