import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '@gsa/afp-component-library';

const ImportResultsModal = ({ failed, onClose }) => {
  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          id="copy-results-modal"
          variant="large"
          title={<h1>Line Items not imported</h1>}
          onClose={onClose}
          actions={
            <Button
              data-testid="close-copy-results-modal-btn"
              variant="unstyled"
              onClick={onClose}
              label="Close"
            />
          }
        >
          <div className="minh-mobile maxh-mobile-lg">
            <div>Line Items that could not be imported are listed below</div>
            <ul>
              {failed.map((f) => (
                <li key={f.label}>
                  <b>{f.label}</b> - {f.errorMessage}
                </li>
              ))}
            </ul>
          </div>
        </Modal>
      </div>
    </div>
  );
};

ImportResultsModal.propTypes = {
  failed: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImportResultsModal;
