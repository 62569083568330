import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Breadcrumbs from '../../widgets/breadcrumbs';

export const BREADCRUMBS = {
  CONTRACTS: 'Contracts',
  CONTRACTS_SEARCH: 'Contracts',
  CONTRACT_HEADER: 'Contract header',
  CONTRACT_HISTORY: 'Contract history',
  CONTRACT_HISTORY_VERSION: 'Contract history version',

  // Contract line listing
  CONTRACT_LINE_LISTING: 'Contract line listing',
  CONTRACT_LINE_ITEM: 'Line item',
  CONTRACT_LINE_HISTORY: 'History',
  CONTRACT_LINE_HISTORY_VERSION: 'Version',
};

const ContractBreadcrumbs = ({ current, title, scheduleLine }) => {
  const { contractHeaderId, contractLineId } = useParams();

  const breadcrumbs = [
    {
      location: `${window.AFP_CONFIG.appURLs?.home}/home`,
      label: 'Home',
    },
  ];

  const addBreadcrumb = (label, location) => {
    breadcrumbs.push({ label, location });
  };

  switch (current) {
    case BREADCRUMBS.CONTRACTS:
      break;

    case BREADCRUMBS.CONTRACT_HEADER:
      addBreadcrumb(BREADCRUMBS.CONTRACTS, '/catalog/contract');
      break;

    case BREADCRUMBS.CONTRACT_HISTORY:
      addBreadcrumb(BREADCRUMBS.CONTRACTS, '/catalog/contract');
      addBreadcrumb(
        BREADCRUMBS.CONTRACT_HEADER,
        `/catalog/contract/contract-header/${contractHeaderId}`,
      );
      break;

    case BREADCRUMBS.CONTRACT_HISTORY_VERSION:
      addBreadcrumb(BREADCRUMBS.CONTRACTS, '/catalog/contract');
      addBreadcrumb(
        BREADCRUMBS.CONTRACT_HEADER,
        `/catalog/contract/contract-header/${contractHeaderId}`,
      );
      addBreadcrumb(
        BREADCRUMBS.CONTRACT_HISTORY,
        `/catalog/contract/contract-header/${contractHeaderId}/history`,
      );
      break;

    case BREADCRUMBS.CONTRACT_LINE_LISTING:
      addBreadcrumb(BREADCRUMBS.CONTRACTS, '/catalog/contract');
      addBreadcrumb(
        BREADCRUMBS.CONTRACT_HEADER,
        `/catalog/contract/contract-header/${contractHeaderId}`,
      );
      break;

    case BREADCRUMBS.CONTRACT_LINE_ITEM:
      addBreadcrumb(BREADCRUMBS.CONTRACTS, '/catalog/contract');
      addBreadcrumb(
        BREADCRUMBS.CONTRACT_HEADER,
        `/catalog/contract/contract-header/${contractHeaderId}`,
      );
      addBreadcrumb(
        BREADCRUMBS.CONTRACT_LINE_LISTING,
        `/catalog/contract/contract-header/${contractHeaderId}/lines`,
      );
      break;

    case BREADCRUMBS.CONTRACT_LINE_HISTORY:
      addBreadcrumb(BREADCRUMBS.CONTRACTS, '/catalog/contract');
      addBreadcrumb(
        BREADCRUMBS.CONTRACT_LINE_LISTING,
        `/catalog/contract/contract-header/${contractHeaderId}/lines`,
      );
      addBreadcrumb(
        BREADCRUMBS.CONTRACT_LINE_ITEM,
        `/catalog/contract/${contractHeaderId}/contract-line-template/${contractLineId}`,
      );
      break;

    case BREADCRUMBS.CONTRACT_LINE_HISTORY_VERSION:
      addBreadcrumb(BREADCRUMBS.CONTRACTS, '/catalog/contract');
      addBreadcrumb(
        BREADCRUMBS.CONTRACT_LINE_LISTING,
        `/catalog/contract/contract-header/${contractHeaderId}/lines`,
      );
      addBreadcrumb(
        `${BREADCRUMBS.CONTRACT_LINE_ITEM} ${scheduleLine || ''}`,
        `/catalog/contract/${contractHeaderId}/contract-line-template/${contractLineId}`,
      );
      addBreadcrumb(
        BREADCRUMBS.CONTRACT_LINE_HISTORY,
        `/catalog/contract/${contractHeaderId}/contract-line-template/${contractLineId}/history`,
      );
      break;
    default:
      break;
  }

  return <Breadcrumbs path={breadcrumbs} current={title || current} />;
};

ContractBreadcrumbs.propTypes = {
  current: PropTypes.string.isRequired,
  title: PropTypes.string,
  scheduleLine: PropTypes.string,
};

ContractBreadcrumbs.defaultProps = {
  title: null,
  scheduleLine: null,
};

export default ContractBreadcrumbs;
