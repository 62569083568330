import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import MakeTypeAhead from './components/makeTypeahead';
import ModelTypeAhead from './components/modelTypeahead';
import { ModelMakeStatuses, MAKE_MODEL_STATUS_MAP } from '../constants';


const MakeModelFilter = ({ handleFilterChange }) => {
  const [selectedMakeCode, setSelectedMakeCode] = useState('');

  const defaultFilter = 
      {
        operator: '$in',
        key: 'fleetStatus',
        value: ['1', '2'],
      };


  const handleFilter = (updatedFilters) => {
    const filters = updatedFilters.flatMap((filter) => {
      
      // add default filter for all filters
      if (filter.key === 'bodyMake') {
        const makeCode = filter.value;
        setSelectedMakeCode(makeCode);
        return {
          ...filter,
          key: 'makeCode',
          value: makeCode,
        };
      }

      if (filter.key === 'bodyModel') {
        const modelCode = filter.value;
        return {
          ...filter,
          key: 'modelCode',
          value: modelCode,
        };
      }

      if (filter.key === 'approvalStatus') {
        return {
          operator: '$in',
          key: 'fleetStatus',
          value: filter.value.map((v) => String(MAKE_MODEL_STATUS_MAP[v])),
        };
      }

      if (filter.key === 'approvedByUser') {
        if (filter.value === 'NHTSA' || filter.value?.includes?.('NHTSA')) {
          return [
            {
              ...filter,
              key: 'approvalStatus',
              value: [
                String(MAKE_MODEL_STATUS_MAP[ModelMakeStatuses.Approved.value]),
              ],
            },
            {
              ...filter,
              key: 'fleetStatus',
              value: [
                String(MAKE_MODEL_STATUS_MAP[ModelMakeStatuses.Approved.value]),
              ],
            },
          ];
        }
        if (filter.value === 'gsaEng' || filter.value?.includes?.('gsaEng')) {
          return [
            {
              operator: '$notIn',
              key: 'fleetStatus',
              value: [
                String(MAKE_MODEL_STATUS_MAP[ModelMakeStatuses.Approved.value]),
              ],
            },
            {
              operator: '$in',
              key: 'approvalStatus',
              value: [
                String(MAKE_MODEL_STATUS_MAP[ModelMakeStatuses.Approved.value]),
                String(MAKE_MODEL_STATUS_MAP[ModelMakeStatuses.Rejected.value]),
              ],
            },
            {
              operator: '$in',
              key: 'fleetStatus',
              value: [
                String(MAKE_MODEL_STATUS_MAP[ModelMakeStatuses.Rejected.value]),
              ],
            },
          ];
        }
        return null;
      }
      return filter;
    });
    // if the filters doesn't have filter.key === 'approvalStatus'
    // then add the default filter
    if (!filters.some((filter) => filter.key === 'fleetStatus')) {
      filters.push(defaultFilter);
    }
    handleFilterChange(filters);
  };

  return (
    <FilterPanel.FilterPanel
      filterStructure={[
        {
          title: 'Make',
          key: 'bodyMake',
          id: 'bodyMake',
          operator: '$exact',
          component: MakeTypeAhead,
          value: '',
          isSingleValueFilter: true,
          permanent: false,
          expanded: true,
          hideClear: true,
          customFieldProps: {
            inputCharNum: 1,
            clearPanelFilterOnEmpty: true,
          },
        },
        {
          title: 'Model',
          key: 'bodyModel',
          id: 'bodyModel',
          operator: '$exact',
          component: ModelTypeAhead,
          value: '',
          isSingleValueFilter: true,
          expanded: true,
          hideClear: true,
          customFieldProps: { inputCharNum: 1, clearPanelFilterOnEmpty: true, makeCode: selectedMakeCode },
        },
        {
          title: 'Status',
          key: 'approvalStatus',
          id: 'approvalStatus',
          type: 'multiselect',
          value: [],
          options: Object.keys(ModelMakeStatuses)
            .map((k) => ModelMakeStatuses[k])
            .filter((option) => (option.value !== 'Archived' && option.value !== 'PendingReview')),
          permanent: false,
          operator: '$in',
          hideClear: true,
          expanded: true,
        },
        {
          title: 'Approved By',
          key: 'approvedByUser',
          id: 'approvedByUser',
          type: 'multiselect',
          value: [],
          options: [
            { label: 'GSA Engineer', value: 'gsaEng' },
            { label: 'NHTSA', value: 'NHTSA' },
          ],
          permanent: false,
          operator: '$exact',
          hideClear: true,
          expanded: true,
        },
      ]}
      clearButtonLabel="Reset All"
      showClearIcon
      setQueryFiltersState={handleFilter}
      model="AfpModel"
      order={[['id', 'ASC']]}
      subTitle="Apply filters"
      titleSeparator
      showSearchIcon
      retainFilters={false}
    />
  );
};

MakeModelFilter.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
};

export default MakeModelFilter;
