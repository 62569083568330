import React from 'react';
import { PropTypes } from 'prop-types';

function BidStandardItemClassificationInfo(props) {
  const { original } = props;

  return (
    <div>
      <Title>CLASSIFICATION</Title>

      <ClassificationLine
        label="Program:"
        value={`${original?.programCode}-${original?.programTitle}`}
      />

      <ClassificationLine
        label="Vehicle Group:"
        value={original.vehicleGroup}
      />

      <ClassificationLine label="Vehicle Type:" value={original.vehicleType} />
    </div>
  );
}

BidStandardItemClassificationInfo.propTypes = {
  original: PropTypes.shape({
    programCode: PropTypes.string,
    programTitle: PropTypes.string,
    vehicleGroup: PropTypes.string,
    vehicleType: PropTypes.string,
  }).isRequired,
};

function Title(props) {
  const { children } = props;

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <p className="text-primary title-s-caps" ariaLable={children} tabIndex={0}>
      {children}
    </p>
  );
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

function ClassificationLine(props) {
  const { label, value } = props;

  return (
    <div className="grid-row-auto solicitation-separator padding-bottom-1 padding-top-1">
      <label
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        ariaLable={label}
        className="text-bold padding-right-1"
      >
        {label}
      </label>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <span tabIndex={0} ariaLable={value}>
        {value}
      </span>
    </div>
  );
}

ClassificationLine.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default BidStandardItemClassificationInfo;
