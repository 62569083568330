/* eslint-disable react/prop-types */
import {
  AFPTable,
  AFPTableRowAction,
  Button,
  Pagination,
  StatusBadge,
  Tag,
  Link,
  FilterTableFrame,
} from '@gsa/afp-component-library';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useAppAbility, Can } from '@gsa/afp-shared-ui-utils';
import Truncate from '../../components/Truncate';
import useDidMountEffect from '../../hooks/use-did-mount';
import '../standard-items/styles/standard-items-table.scss';
import EquipmentModalWrapper from './equipment-codes-form-modal';
import { MODAL_MODES, useEquipmentCodes } from './equipment-codes-provider';
import { OPERATIONS, SUBJECTS } from '../../utilities/constants';
import EquipmentCodeFilters from './equipment-codes-filter-panel';

const defaultFilters = {
  operator: 'AND',
  value: [
    {
      key: 'deletedAt',
      operator: 'EQ',
      value: null,
    },
    {
      key: 'isActive',
      operator: 'EQ',
      value: 1,
    },
    {
      key: 'year',
      operator: 'EQ',
      value: `${new Date().getFullYear()}`,
    },
  ],
};

const options = [
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: {
      operator: 'EQ',
      key: 'standardCodeMetadataId',
      value: '8',
    },
    uniqueKey: 'program',
    includeValueInLabel: true,
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: { operator: 'EQ', key: 'standardCodeMetadataId', value: '14' },
    uniqueKey: 'units',
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: {
      operator: 'EQ',
      key: 'standardCodeMetadataId',
      value: '7',
    },
    uniqueKey: 'quantityRequired',
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: {
      operator: 'EQ',
      key: 'standardCodeMetadataId',
      value: '3',
    },
    uniqueKey: 'tags',
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    limit: 1000,
    filter: {
      operator: 'EQ',
      key: 'standardCodeMetadataId',
      value: '6',
    },
    uniqueKey: 'category',
  },
];

const actions = [
  {
    icon: 'edit',
    label: 'Edit Equipment Code',
    canShowIndicator: 'canEdit',
  },
  // will be activated once we do contracts
  // {
  //   icon: 'delete',
  //   label: 'Delete Equipment Code',
  //   canShowIndicator: 'canDelete',
  // },
  {
    icon: 'visibility',
    label: 'Manage Standard Items',
    canShowIndicator: 'canDView',
  },
];

const EquipmentCodesTable = () => {
  const {
    setData,
    getMultipleOptions,
    getEquipmentCodes,
    equipmentCodes,
  } = useEquipmentCodes();
  const ability = useAppAbility();

  const [order, setOrder] = useState('code ASC');
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });
  const [filters, setFilters] = useState(defaultFilters);
  const [showFilters] = useState(true);

  const tableRef = React.createRef();
  const history = useHistory();

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  const getData = () => {
    const { limit, offset } = paginationState;
    getEquipmentCodes({
      variables: { filters, limit, offset, order },
    });
  };

  useEffect(() => {
    getData();
    getMultipleOptions({
      variables: {
        options,
      },
    });
  }, []);

  useDidMountEffect(() => {
    handlePaginationChange(1, paginationState.limit);
    setOrder('code ASC');
    tableRef.current.toggleSortBy('code', false);
    getData();
  }, [filters]);

  useDidMountEffect(() => {
    getData();
  }, [order, paginationState]);

  const handleSelectedAction = (evt, row) => {
    const { code, year, program } = row.original;
    if (evt === 'Manage Standard Items') {
      const url = `/catalog/equip-codes/${year}/${program}/${code}`;
      // eslint-disable-next-line react/prop-types
      history.push(url, row.original);
      return;
    }
    setData('SET_EQUIPMENT_CODE', row?.original);
    setData('SET_FORM_MODAL', true);
    if (evt === 'Delete Equipment Code') {
      setData('SET_MODAL_MODE', MODAL_MODES.CONFIRM_DELETE);
    } else if (evt === 'Edit Equipment Code') {
      setData('SET_MODAL_MODE', MODAL_MODES.EDIT);
    }
  };

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Program',
        accessor: 'program',
      },
      {
        Header: 'Code',
        accessor: 'code',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value, row }) =>
          value ? (
            <Link
              // eslint-disable-next-line react/prop-types
              to={`/catalog/equip-codes/${row.original.year}/${row.original.program}/${value}`}
              className="usa-button usa-button--unstyled standard-item-view-button"
              tabIndex="0"
              onClick={() => {
                // eslint-disable-next-line react/prop-types
                setData('SET_EQUIPMENT_CODE', row?.original);
                // eslint-disable-next-line react/prop-types
                const url = `/catalog/equip-codes/${row.original.year}/${row.original.program}/${value}`;
                // eslint-disable-next-line react/prop-types
                history.push(url, row.original);
              }}
            >
              {value}
            </Link>
          ) : null,
      },
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Associated Standard Items',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => (
          <Button
            type="button"
            className="usa-button usa-button--unstyled standard-item-view-button"
            onClick={() => {
              // eslint-disable-next-line react/prop-types
              setData('SET_EQUIPMENT_CODE', row.original);
              setData('SET_FORM_MODAL', true);
              setData('SET_MODAL_MODE', 'view-associated-standard-items');
            }}
            label="View"
          />
        ),
      },
    ];
    if (ability.can(OPERATIONS.Manage, SUBJECTS.EquipmentCodes)) {
      columnList.push({
        Header: 'Actions',
        sortable: false,
        Cell: (props) => (
          <AFPTableRowAction
            {...props}
            actions={actions}
            // eslint-disable-next-line react/prop-types
            onSelectAction={(evt) => handleSelectedAction(evt, props.row)}
          />
        ),
      });
    }

    return columnList;
  }, []);

  const renderRowSubComponent = useCallback(({ row: { original = {} } }) => {
    return (
      <div className="grid-container standard-items-sub-component-wrapper">
        <div className="grid-row grid-gap-6">
          <div className="tablet:grid-col">
            <div className="grid-row standard-item-wrapper standard-item-separator">
              <div className="tablet:grid-col standard-item-title">Status</div>
              <div className="tablet:grid-col  text-right">
                {original?.status && (
                  <StatusBadge
                    variant={
                      original.status === 'Active'
                        ? 'Ready-Gray'
                        : 'Inactive-Gray'
                    }
                  >
                    {original.status}
                  </StatusBadge>
                )}
              </div>
            </div>
            <div className="grid-row standard-item-wrapper standard-item-separator">
              <div className="tablet:grid-col standard-item-title">
                Valid quantity
              </div>
              <div className="tablet:grid-col  text-right">
                {original?.quantityRequiredCode && (
                  <div>{original.quantityRequiredCode.title}</div>
                )}
              </div>
            </div>
            <div className="grid-row standard-item-wrapper standard-item-separator">
              <div className="tablet:grid-col standard-item-title">
                Category
              </div>
              <div className="tablet:grid-col  text-right">
                {original?.categoryCode && (
                  <div>{original?.categoryCode.title}</div>
                )}
              </div>
            </div>
            <div className="grid-row standard-item-wrapper standard-item-separator">
              <div className="tablet:grid-col standard-item-title">Unit</div>
              <div className="tablet:grid-col  text-right">
                {(original?.unitCode && (
                  <div>{original.unitCode.title}</div>
                )) || <div className="text-bold">&ndash;</div>}
              </div>
            </div>
          </div>
          <div className="tablet:grid-col">
            <div className="grid-row standard-item-wrapper standard-item-separator">
              <div className="tablet:grid-col standard-item-title">Tags</div>
              <div className="tablet:grid-col text-right standard-item-last-row">
                {original?.tags?.value &&
                  [...original?.tags?.value].map((tag) => (
                    <Tag
                      variant="Inactive-Medium"
                      className="usa-tag afp-tag bg-base text-white standard-item-tag-item"
                      key={tag}
                    >
                      {tag}
                    </Tag>
                  ))}
              </div>
            </div>
            <div className="grid-row standard-item-wrapper standard-item-separator">
              <div className="tablet:grid-col standard-item-title">
                Sequence
              </div>
              <div className="tablet:grid-col  text-right">
                {original?.sequence && <div>{original?.sequence}</div>}
              </div>
            </div>
            <div className="standard-item-wrapper">
              <div className="standard-item-title">Description</div>
              {original?.content?.content ? (
                <Truncate content={original?.content?.content} size={100} />
              ) : (
                <>
                  <div className="text-bold">
                    <br />
                    &ndash;
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }, []);

  const TableWrapper = ({ tableProps, paginationProps }) => (
    <>
      <AFPTable {...tableProps} />
      <Pagination {...paginationProps} />
    </>
  );

  const FTF = useMemo(
    () => FilterTableFrame(null, null, EquipmentCodeFilters, TableWrapper),
    [],
  );

  return (
    <>
      <Can I={OPERATIONS.Manage} a={SUBJECTS.EquipmentCodes}>
        <div className="grid-row margin-bottom-2">
          <div className="grid-col">
            <div className="float-right">
              <Button
                onClick={() => {
                  setData('SET_FORM_MODAL', true);
                  setData('SET_MODAL_MODE', 'add');
                }}
                label="Add Equipment Code"
                leftIcon={{
                  name: 'add',
                }}
              />
            </div>
          </div>
        </div>
      </Can>
      <FTF
        lowLeftProps={{
          currentFilters: filters,
          setFilters,
        }}
        lowRightProps={{
          tableProps: {
            expandable: true,
            columns,
            data: equipmentCodes?.rows || [],
            renderRowSubComponent,
            onSort: setOrder,
            defaultSort: order,
            fullWidth: true,
            ref: tableRef,
          },
          paginationProps: {
            itemsPerPageOptions: [10, 25, 50],
            onPageChange: handlePaginationChange,
            variant: 'advanced',
            itemsPerPage: paginationState.limit,
            currentPage: paginationState.currentPage,
            itemsCount: equipmentCodes?.count,
          },
        }}
        filterToggle={showFilters}
      />

      <EquipmentModalWrapper />
    </>
  );
};

export default EquipmentCodesTable;
