import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useRecoilState } from 'recoil';
import solicitationNotifications, {
  selectedSolicitationAtom,
  solicitationModalMode,
} from '../../utils/solicitation-notification-helpers';
import { GET_SOLICITATIONS, DELETE_SOLICITATION } from '../../queries';
import MarkUpText from '../../../../components/MarkUpText/MarkUpText';

export default function useSolicitation(onCompleteCallback) {
  const [solicitations, setSolicitations] = useState([]);
  const [notifications, setSolicitationNotification] = useRecoilState(
    solicitationNotifications,
  );

  const [selectedSolicitationRow, setSelectedSolicitationRow] = useRecoilState(
    selectedSolicitationAtom,
  );

  const [modalMode, setModalMode] = useRecoilState(solicitationModalMode);

  useEffect(() => {
    // on component unmount clear error messages
    return () => {
      setSolicitationNotification([{}]);
    };
  }, []);

  const [getSolicitations] = useLazyQuery(GET_SOLICITATIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setSolicitations(data.getSolicitations);
    },
  });

  const [deleteSolicitation] = useMutation(DELETE_SOLICITATION, {
    onError: (requestError) => {
      setSolicitationNotification([
        {
          id: 'DELETE_SOLICITATION_ERROR',
          message: requestError.message,
          type: 'error',
          closeable: false,
          showInModal: false,
        },
        ...notifications,
      ]);
    },
    onCompleted: (response) => {
      const { id, solicitationNumber } = response.deleteSolicitationById || {};
      if (id) {
        const message = `Solicitation ${solicitationNumber} has been successfully deleted.`;
        setSolicitationNotification([
          {
            id: 'DELETE_SOLICITATION',
            message: (
              <MarkUpText
                content={message}
                textToMarkUp={`Solicitation ${solicitationNumber}`}
                marker="strong"
              />
            ),
            type: 'success',
            closeable: true,
            showInModal: false,
          },
        ]);
        if (onCompleteCallback) onCompleteCallback();
      }
    },
  });

  return {
    modalMode,
    setModalMode,
    selectedSolicitationRow,
    setSelectedSolicitationRow,
    solicitations,
    setSolicitations,
    getSolicitations,
    deleteSolicitation,
  };
}
