/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  AFPTable,
  Pagination,
  Button,
  Spinner,
} from '@gsa/afp-component-library';

const BidEvaluationTable = ({
  loading,
  data,
  offset,
  limit,
  count,
  onPageChange,
  onSort,
}) => {
  const history = useHistory();

  const columns = useMemo(() => [
    {
      Header: 'Solicitation number (uPIID)',
      accessor: 'soliciation.solicitationNumber',
      sortable: true,
    },
    {
      Header: 'Vendor name',
      accessor: 'vendorDetail.vendorName',
      Cell: ({ row: { original } }) => (
        <a
          href={`${window.AFP_CONFIG.appURLs?.home}/vendor/details/${original.vendorId}`}
        >
          {original.vendorDetail.vendorName}
        </a>
      ),
      sortable: false,
    },
    {
      Header: 'UEI',
      accessor: 'vendorDetail.uniqueEntityIdentifier',
      sortable: false,
    },
    {
      Header: 'Actions',
      sortable: false,
      Cell: ({ row: { original } }) => {
        return (
          <Button
            variant="unstyled"
            label="View line items"
            onClick={() => {
              history.push(`./proposal-evaluation/bid/${original.id}`);
            }}
          />
        );
      },
    },
  ]);

  if (loading)
    return (
      <div className="margin-y-8">
        <Spinner size="small" />
      </div>
    );

  if (!data.length) return null;

  return (
    <div className="margin-top-4">
      <div className="text-primary text-bold text-uppercase">Vendors</div>
      <AFPTable
        expandable={false}
        fullWidth
        columns={columns}
        data={data}
        onSort={onSort}
      />
      <div className="padding-y-4 pagination">
        <Pagination
          itemsPerPageOptions={[5, 10, 25]}
          onPageChange={onPageChange}
          variant="advanced"
          itemsPerPage={limit}
          currentPage={offset / limit + 1}
          itemsCount={count}
        />
      </div>
    </div>
  );
};

BidEvaluationTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default BidEvaluationTable;
