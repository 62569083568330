/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Button } from '@gsa/afp-component-library';
import cx from 'classnames';

export const createConditionalExpandableColumn = (validatorFunc) => ({
  // Make an expander cell
  Header: '', // No header
  id: 'expander', // It needs an ID
  sortable: false,
  headerClassName: 'cell-expander',
  Cell: ({
    // eslint-disable-next-line react/prop-types
    row: { id, isExpanded = false, getToggleRowExpandedProps, original },
  }) => {
    const iconName = isExpanded ? 'expand_more' : 'navigate_next';

    const buttonClassName = cx('table__expand_button', {
      'display-none': validatorFunc(original),
    });
    return (
      <>
        <Button
          variant="unstyled"
          className={buttonClassName}
          aria-expanded={isExpanded}
          data-testid={`row-expander-${id}`}
          {...getToggleRowExpandedProps()}
          rightIcon={{
            name: iconName,
            className: 'usa-icon--size-4 text-middle',
          }}
        />
      </>
    );
  },
});
