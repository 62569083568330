import { groupBy } from 'lodash';
import moment from 'moment';
import { POSSIBLE_DATE_FORMAT } from '../components/solicitation-form-context';
import {
  AUDIENCE_MAP,
  CUSTOM_SOL_PERIOD_AUDIENCE_DESCRIPTION,
  SOLICITATION_PERIOD_AUDIENCE_TYPE_FIELD_NAME,
  SOLICITATION_PERIOD_STATUS,
  SOLICITATION_PERIOD_STATUS_FIELD_NAME,
} from '../constants';
import { verifyIsSolicitationStatusOpen } from './solicitation-status-utils';

export const startDateProps = {
  label: 'Posting date',
  required: true,
  name: 'startDate',
};

export const startTimeProps = {
  label: 'Posting time',
  required: true,
  name: 'startTime',
};

export const endDateProps = {
  label: 'Closing date',
  required: true,
  name: 'endDate',
};

export const endTimeProps = {
  label: 'Closing time',
  required: true,
  name: 'endTime',
};

export const audienceOptions = [
  { label: 'Open Season', value: 'Open' },
  { label: 'Awardees only', value: 'O' },
  { label: 'Active bidders', value: 'O' },
  { label: 'Custom Audience	', value: 'O' },
];

export const audienceProps = {
  name: 'audience',
  label: 'Choose audience',
  defaultValue: 'Open',
};

export const vendorProps = {
  name: 'vendor',
  label: 'Choose vendor',
};
export const OpenSolicitationReadonlyEditPeriodFields = [
  startDateProps.name,
  startTimeProps.name,
];

export const OpenSolicitationReadonlyEditPeriodEndDateTimeFields = [
  ...OpenSolicitationReadonlyEditPeriodFields,
  endDateProps.name,
  endTimeProps.name,
  audienceProps.name,
  vendorProps.name,
];

export const getReadonlyFieldsForOpenSolicitation = (
  status,
  startDateTime,
  endDateTime,
) => {
  if (verifyIsSolicitationStatusOpen(status, startDateTime)) {
    if (endDateTime && verifyIsSolicitationStatusOpen(status, endDateTime))
      return OpenSolicitationReadonlyEditPeriodEndDateTimeFields;
    return OpenSolicitationReadonlyEditPeriodFields;
  }
  return [];
};

export const READ_ONLY_FIELD_LABEL_MAP = {
  [startDateProps.name]: startDateProps.label,
  [startTimeProps.name]: startTimeProps.label,
  [endDateProps.name]: endDateProps.label,
  [endTimeProps.name]: endTimeProps.label,
  [audienceProps.name]: 'Audience',
  [vendorProps.name]: 'Vendor',
};

export const parseDateString = (value, originalValue) => {
  if (!originalValue) return null;
  const parsedDate =
    originalValue instanceof Date && originalValue
      ? originalValue
      : moment(originalValue, POSSIBLE_DATE_FORMAT, true).toDate();
  return parsedDate;
};

export const getSolicitationPeriodStatus = (
  solPeriodObj,
  contractAwardDate,
) => {
  const startDate = new Date(solPeriodObj?.startDate);
  const endDate = new Date(solPeriodObj?.endDate);

  const todayDate = new Date();
  if (
    solPeriodObj?.periodType === 'R' &&
    contractAwardDate &&
    new Date(contractAwardDate) < startDate
  )
    return todayDate > startDate
      ? SOLICITATION_PERIOD_STATUS.DEACTIVATED_PAST_DATE
      : SOLICITATION_PERIOD_STATUS.DEACTIVATED;

  if (todayDate > endDate) return SOLICITATION_PERIOD_STATUS.COMPLETED;
  if (todayDate >= startDate && todayDate <= endDate)
    return SOLICITATION_PERIOD_STATUS.ACTIVE;

  return SOLICITATION_PERIOD_STATUS.SCHEDULED;
};

export const hasFutureDeactiavedSolPeriods = (solPeriods) =>
  !!solPeriods?.some(
    (aSolPeriod) =>
      aSolPeriod?.[SOLICITATION_PERIOD_STATUS_FIELD_NAME] ===
      SOLICITATION_PERIOD_STATUS.DEACTIVATED,
  );
export const isSolPeriodDeactivated = (solPeriod) =>
  [
    SOLICITATION_PERIOD_STATUS.DEACTIVATED,
    SOLICITATION_PERIOD_STATUS.DEACTIVATED_PAST_DATE,
  ].includes(solPeriod?.[SOLICITATION_PERIOD_STATUS_FIELD_NAME]);

export const isOpenSeasonAudienceType = (solPeriod) =>
  solPeriod?.audience === AUDIENCE_MAP.Open;

export const transformedSolPeriodVendorList = (solPeriod) =>
  Object.entries(
    groupBy(
      solPeriod?.solicitationPeriodVendors,
      SOLICITATION_PERIOD_AUDIENCE_TYPE_FIELD_NAME,
    ),
  );
export const getAudienceValues = (solPeriod) => {
  if (isOpenSeasonAudienceType(solPeriod))
    return CUSTOM_SOL_PERIOD_AUDIENCE_DESCRIPTION[solPeriod?.audience];
  return Object.keys(
    groupBy(
      solPeriod?.solicitationPeriodVendors,
      SOLICITATION_PERIOD_AUDIENCE_TYPE_FIELD_NAME,
    ),
  )
    ?.map(
      (eachAudience) => CUSTOM_SOL_PERIOD_AUDIENCE_DESCRIPTION[eachAudience],
    )
    .filter(
      (eachAudience, index, audienceDescriptionArray) =>
        audienceDescriptionArray.indexOf(eachAudience) === index,
    )
    .join(', ');
};
