/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { uniq } from 'lodash';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import { useEquipmentDetailCodes } from './equipment-code-detail-provider';

const EquipmentCodeAssociationFilter = ({ setFilters }) => {
  const {
    getMultipleOptions,
    groupMultipleOptions,
    selectedEquipmentCode,
    options,
    getOptions,
    setData,
  } = useEquipmentDetailCodes();

  const { FilterPanel: ComponentFilterPanel } = FilterPanel;
  const [searchKey, setSearchKey] = useState(null);
  const [typeaheadData, setTypeaheadData] = useState(null);

  const yearFilter = {
    operator: 'EQ',
    value: selectedEquipmentCode?.year,
    key: '$standardItem.year$',
  };

  const standardItemIdFilter = {
    operator: 'EQ',
    value: selectedEquipmentCode?.id,
    key: 'equipmentCodeId',
  };

  const defaultFilter = [yearFilter, standardItemIdFilter];

  useEffect(() => {
    if (options?.length) {
      setTypeaheadData({
        field: searchKey,
        values: uniq(options.map(({ value }) => value)),
      });
    }
  }, [options]);

  useEffect(() => {
    const optionsItems = [
      {
        model: 'StandardsCodeModel',
        label: 'title',
        value: 'code',
        filter: {
          operator: 'EQ',
          key: 'standardCodeMetadataId',
          value: '2',
        },
        uniqueKey: 'associationType',
      },
    ];
    getMultipleOptions({
      variables: {
        options: optionsItems,
      },
    });
  }, []);

  const handleFiltersChange = (selectedFilters) => {
    const filterByKeys = selectedFilters?.reduce(
      (filterObj, currentFilterObj) => {
        return { ...filterObj, [currentFilterObj?.key]: currentFilterObj };
      },
      {},
    );

    const updatedFilters = selectedFilters
      ?.filter((f) => f.key !== 'associationType')
      .map((filter) => {
        if (filter?.key === 'standardItem') {
          return { ...filter, key: '$standardItem.standard_item_number$' };
        }
        if (filter?.key === 'title') {
          return { ...filter, key: '$standardItem.title$' };
        }
        return filter;
      });

    const newFilter = {
      operator: 'AND',
      value: [...updatedFilters, ...defaultFilter],
    };

    if (filterByKeys?.associationType) {
      newFilter.value.push({
        operator: 'OR',
        value: filterByKeys.associationType?.value.map((value) => ({
          key: 'associationTypeCode',
          operator: 'EQ',
          value,
        })),
      });
    }

    setData('SET_OPTIONS', []);
    setTypeaheadData(null);
    setFilters(newFilter);
  };

  const prepareSearchVariables = useCallback(
    (key, search) => {
      let variables = {};

      setSearchKey(key);

      if (key === 'title')
        variables = {
          model: 'StandardItem',
          label: 'title',
          value: 'title',
          filter: {
            operator: 'LIKE',
            key: 'title',
            value: search,
          },
        };

      if (key === 'standardItem')
        variables = {
          model: 'StandardItem',
          label: 'standardItemNumber',
          value: 'standardItemNumber',
          filter: {
            operator: 'LIKE',
            key: 'standardItemNumber',
            value: search,
          },
        };

      return variables;
    },
    [selectedEquipmentCode],
  );

  const handleTypeaheadFetch = ({ variables: queryVariables }) => {
    const selectedKey = queryVariables?.field;
    const filterObj = queryVariables?.filters[0]?.conditions?.find(
      (filter) => filter.key === selectedKey,
    );

    getOptions({
      variables: prepareSearchVariables(selectedKey, filterObj.value),
    });
  };

  return (
    <ComponentFilterPanel
      clearButtonLabel="Reset All"
      filterStructure={[
        {
          key: 'standardItem',
          title: 'Standard Item',
          type: 'typeahead',
          value: '',
          operator: 'EQ',
          expanded: true,
          hideClear: true,
          customFieldProps: { inputCharNum: 1 },
        },
        {
          key: 'title',
          title: 'Title',
          type: 'typeahead',
          value: '',
          operator: 'EQ',
          expanded: true,
          hideClear: true,
          customFieldProps: { inputCharNum: 1 },
        },
        {
          key: 'associationType',
          title: 'Type',
          type: 'multiselect',
          options:
            groupMultipleOptions?.associationType.map((item) => ({
              label: item.label,
              value: item.value,
            })) || [],
          value: [],
          operator: 'EQ',
          expanded: true,
          hideClear: true,
        },
      ]}
      model="EquipmentAssociation"
      order="`standardItem.standardItemNumber` ASC"
      setQueryFiltersState={handleFiltersChange}
      // eslint-disable-next-line no-console
      fetchTypeaheads={handleTypeaheadFetch}
      typeaheadData={typeaheadData}
      showClearIcon
      retainFilters={false}
    />
  );
};

EquipmentCodeAssociationFilter.propTypes = {
  setFilters: PropTypes.func.isRequired,
};

export default EquipmentCodeAssociationFilter;
