import { Button } from '@gsa/afp-component-library';
import React from 'react';
import Modal from '../../components/Modal/Modal';
import StandardAssociationAdd from './standard-item-equipment-association-add';
import StandardAssociationCopy from './standard-item-equipment-association-copy';
import StandardAssociationEdit from './standard-item-equipment-association-edit';
import { useStandardItem } from './standard-item-provider';
import StandardItemEdit from './standard-item-edit';
import AssociationMessages from './standard-item-equipment-messages';

const ModalTitle = () => {
  const { modalMode } = useStandardItem();

  let title = '';
  switch (modalMode) {
    case 'add':
      title = 'Add Equipment Code';
      break;
    case 'edit':
      title = 'Edit Equipment Code';
      break;
    case 'copy':
      title = 'Copy Equipment Code';
      break;
    case 'confirm_replace':
      title = 'Confirm replacement of Equipment Codes';
      break;
    case 'confirm_delete':
      title = 'Confirm removal of Equipment Code';
      break;
    case 'confirm_copy':
      title = 'Confirm replacement of Equipment Codes';
      break;
    case 'confirm_append':
      title = 'Confirm addition of Equipment Codes';
      break;
    case 'edit_standard_item':
      title = 'Edit Standard Item';
      break;
    case 'confirm_bulk_delete':
      title = 'Confirm removal of Equipment Codes';
      break;
    default:
      break;
  }

  return (
    <div className="margin-top-5">
      <AssociationMessages isModal isVisible={false} />
      {/* eslint-disable-next-line */}
      <h3 aria-label={title} tabIndex="0">
        {title}
      </h3>
    </div>
  );
};

const ModalContent = () => {
  const {
    modalMode,
    equipmentAssociation,
    copyAssociation,
    standardItem,
    selectedAssociations,
  } = useStandardItem();

  if (modalMode === 'add') return <StandardAssociationAdd />;
  if (modalMode === 'edit') return <StandardAssociationEdit />;
  if (modalMode === 'copy') return <StandardAssociationCopy />;
  if (modalMode === 'edit_standard_item') return <StandardItemEdit />;
  if (modalMode === 'confirm_delete')
    return (
      <div>
        {`Are you sure you want to remove the ${equipmentAssociation?.equipmentCode?.code} Code?`}
      </div>
    );

  if (modalMode === 'confirm_bulk_delete') {
    return (
      <div>
        {`You are about to remove ${selectedAssociations?.length} Equipment Codes associated to ${standardItem?.title}.
         Please confirm that you want to perform this action by selecting "Remove" below.
          To cancel this action, select "Cancel".`}
      </div>
    );
  }

  if (modalMode === 'confirm_copy' || modalMode === 'confirm_append') {
    const copyType = copyAssociation?.copyType?.toLowerCase();
    const fromStandardItem = standardItem?.standardItemNumber;
    const toStandardItem = copyAssociation?.toStandardItem?.label;
    const selectedCount = copyAssociation?.associationsToCopy?.length ?? 0;
    return copyType === 'append' ? (
      <div>
        You are about to add <b>{selectedCount} </b> Equipment Code(s)
        associated with Standard Item {fromStandardItem} to Standard Item{' '}
        {toStandardItem}. This will not impact the existing Equipment Codes
        currently associated with Standard Item {toStandardItem}.
      </div>
    ) : (
      <div>
        You are about to replace all existing Equipment Codes associated with
        Standard Item {toStandardItem} with the <b>{selectedCount} </b>
        Equipment Code(s) associated with Standard Item {fromStandardItem}. This
        action cannot be undone.
      </div>
    );
  }
  return null;
};

const ModalAction = () => {
  const {
    MODAL_MODES,
    modalMode,
    equipmentAssociation,
    deleteEquipmentAssociation,
    setData,
    copyAssociation,
    copyEquipmentAssociation,
    selectedAssociations,
  } = useStandardItem();

  const onCancel = () => {
    setData('SET_FORM_MODAL', false);
    setData('SET_MODAL_MODE', null);
    setData('SET_EQUIPMENT_ASSOCIATION', null);
    setData('SET_EQUIPMENT_ASSOCIATION_COPY', null);
    setData('SET_ERROR_MESSAGE', '');
  };

  if (modalMode === 'edit')
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="association-modal-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="outline"
          onClick={() => {
            setData('SET_MODAL_MODE', MODAL_MODES.CONFIRM_DELETE);
          }}
          label="Remove Equipment Code"
        />

        <Button
          variant="primary"
          type="submit"
          form="equipment-code-edit-form"
          data-testid="association-modal-save-btn"
          label="Save and close"
        />
      </>
    );

  if (modalMode === 'copy')
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="association-modal-cancel-btn"
          label="Cancel"
        />
        <Button
          type="submit"
          form="equipment-code-copy-form"
          variant="primary"
          data-testid="association-modal-submit-btn"
          label="Submit"
        />
      </>
    );

  if (modalMode === 'confirm_replace')
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="association-modal-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="primary"
          data-testid="association-modal-replace-btn"
          label="Replace"
        />
      </>
    );

  if (modalMode === 'confirm_delete')
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="association-modal-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="secondary"
          data-testid="association-modal-remove-btn"
          onClick={async () => {
            if (equipmentAssociation?.id) {
              await deleteEquipmentAssociation({
                variables: {
                  equipmentAssociationDelete: {
                    ids: [Number(equipmentAssociation.id)],
                  },
                },
              });
              setData('SET_EQUIPMENT_ASSOCIATION', null);
              setData('SET_FORM_MODAL', false);
            }
          }}
          label="Remove"
        />
      </>
    );
  if (modalMode === 'confirm_bulk_delete') {
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="association-modal-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="secondary"
          data-testid="association-modal-remove-btn"
          onClick={async () => {
            if (selectedAssociations.length) {
              const ids = selectedAssociations.map(({ original }) =>
                Number(original.id),
              );
              await deleteEquipmentAssociation({
                variables: { equipmentAssociationDelete: { ids } },
              });
              setData('SET_EQUIPMENT_ASSOCIATION', null);
              setData('SET_FORM_MODAL', false);
              setData('SET_SELECTALL_SELECTED', false);
            }
          }}
          label="Remove"
        />
      </>
    );
  }

  if (modalMode === 'add')
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="association-modal-cancel-btn"
          label="Cancel"
        />

        <Button
          variant="primary"
          type="submit"
          form="equipment-code-add-form"
          data-testid="association-modal-save-btn"
          label="Save and close"
        />
      </>
    );

  if (modalMode === 'confirm_copy' || modalMode === 'confirm_append') {
    const { toStandardItem, copyType, ...dataToCopy } = copyAssociation;

    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="association-modal-cancel-btn"
          label="Cancel"
        />
        <Button
          type="submit"
          variant="primary"
          data-testid="association-modal-append-btn"
          onClick={() => {
            copyEquipmentAssociation({
              variables: {
                copyInput: { ...dataToCopy, copyType: copyType.toLowerCase() },
              },
            });
          }}
          label={copyType.toLowerCase() === 'append' ? 'Add' : 'Replace'}
        />
      </>
    );
  }

  if (modalMode === 'edit_standard_item') {
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="standard-item-edit-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="primary"
          type="submit"
          form="standard-item-edit-form"
          data-testid="standard-item-edit-save-btn"
          label="Save"
        />
      </>
    );
  }

  return null;
};

const AssociationModalWrapper = () => {
  const { showFormModal, setData } = useStandardItem();

  return (
    <Modal
      title={<ModalTitle />}
      actions={<ModalAction />}
      show={showFormModal}
      onClose={() => {
        setData('SET_FORM_MODAL', false);
        setData('SET_MODAL_MODE', null);
        setData('SET_EQUIPMENT_ASSOCIATION', null);
        setData('SET_ERROR_MESSAGE', '');
      }}
    >
      <ModalContent />
    </Modal>
  );
};

export default AssociationModalWrapper;
