import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '@gsa/afp-component-library';

const CommentSection = ({ commentBody, onCommentChange }) => (
  <>
    <strong>Comments</strong>
    <TextInput
      type="textarea"
      data-testid="make-model-comment-section"
      className=" height-card width-full comment"
      aria-label="comment"
      value={commentBody}
      onChange={(e) => onCommentChange(e.target.value)}
      characterLimit={2000}
    />
  </>
);

CommentSection.propTypes = {
  commentBody: PropTypes.string.isRequired,
  onCommentChange: PropTypes.func.isRequired,
};

export default CommentSection;
