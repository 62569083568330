/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Button, SelectDropdown, Label } from '@gsa/afp-component-library';
import { Controller, useFormContext } from 'react-hook-form';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router';
import { useRecoilState } from 'recoil';
import UpiidNumber from '../../../components/upiid-number';
import SolicitationDetails from '../../../components/solicitation-details';
import { SOLICITATION_STATUSES } from '../../../constants';
import {
  GET_MULTIPLE_OPTIONS,
  UPDATE_SOLICITATION,
  RENEW_SOLICITATION,
} from '../../../queries';
import solicitationNotifications from '../../../utils/solicitation-notification-helpers';
import BidSelection from '../../../components/bid-selection';
import { SOLICITATION_PROCESS } from '../../add-solicitaion/upiid-details/constants';
import biddingType from '../../../atoms/solicitation-helpers';
import ContractYearChangeWarnModal from '../../add-solicitaion/upiid-details/contract-year-change-warn-modal';
import useSimpleAlertModal from '../../../../../hooks/use-simple-alert-modal';
import {
  isRenewActionFlow,
  isReopenActionFlow,
  isMidCycleActionFlow,
} from '../../../route-utils';
import { isSolicitationDetailsDataDirty } from '../select-si/utils/is-data-dirty';

const isStandAlonePurchaseType = (value) => ['E', 'N', 'M'].includes(value);
const EditSolicitationFrom = ({ solicitation }) => {
  const history = useHistory();
  const { id } = useParams();
  const { handleSubmit, control } = useFormContext();
  const [options, setOptions] = useState([]);
  const [bidType, setBidType] = useRecoilState(biddingType);
  const [solicitationProcess, setSolicitationProcess] = useState(null);
  const [contractYearUpdate, setContractYearUpdate] = useState(false);
  const [notifications, setSolicitationNotification] = useRecoilState(
    solicitationNotifications,
  );
  const {
    isModalOpen: showContractYearChangeModal,
    handleToggleModal: toggleContractYearChangeModal,
    handleOnModalProceed,
  } = useSimpleAlertModal();
  // Queries
  const [getOptions] = useLazyQuery(GET_MULTIPLE_OPTIONS, {
    fetchPolicy: 'c',
    onCompleted: (data) => {
      setOptions(data.getMultipleOptions);
    },
  });

  const hasFutureOpenPeriodNewDraftSolicitation = () =>
    ['Draft', 'New'].includes(solicitation?.solicitationStatus) &&
    new Date(solicitation?.openPeriod?.startDate) > new Date();

  const [renewSolicitation] = useMutation(RENEW_SOLICITATION, {
    onCompleted: (response) => {
      if (response.renewSolicitation) {
        const {
          bidType: renewSolBidType,
          id: renewSolId,
        } = response.renewSolicitation;
        const { solicitationNumber } = response.renewSolicitation;
        setSolicitationNotification([
          {
            id: 'RENEW_SOLICITATION',
            message: [
              <b>Solicitation {solicitationNumber} </b>,
              `was successfully added.`,
            ],
            type: 'success',
            closeable: true,
            showInModal: false,
          },
          notifications,
        ]);
        const pathname = `/catalog/solicitations/edit-solicitation/${renewSolId}/${
          renewSolBidType === 'OUTSIDE_FLEET' ? 'select-sin' : 'open-period'
        }`;

        history.push({
          pathname,
          state: response?.renewSolicitation,
          search: history.location.search,
        });
      }
    },
    onError: (requestError) => {
      setSolicitationNotification([
        {
          id: 'RENEW_SOLICITATION_ERROR',
          message: requestError.message,
          type: 'error',
          closeable: false,
          showInModal: false,
        },
      ]);
    },
  });

  const navigateToNextPage = () => {
    let pathname;
    if (contractYearUpdate) {
      pathname = `/catalog/solicitations/new-solicitation/${id}/open-period`;
    } else {
      pathname = `/catalog/solicitations/${
        solicitation?.openPeriod?.startDate ? 'edit' : 'new'
      }-solicitation/${id}/${
        bidType === 'OUTSIDE_FLEET' ? 'select-sin' : 'open-period'
      }`;
    }
    history.push({ pathname, search: history.location.search });
  };

  const [updateSolicitation] = useMutation(UPDATE_SOLICITATION, {
    onError: (requestError) => {
      setSolicitationNotification([
        {
          id: 'UPDATE_SOLICITATION_ERROR',
          message: requestError.message,
          type: 'error',
          closeable: false,
          showInModal: false,
        },
        notifications,
      ]);
    },
    onCompleted: () => {
      // set success message
      setSolicitationNotification([
        {
          id: 'UPDATE_SOLICITATION',
          message: `Solicitation has been successfully updated.`,
          type: 'success',
          closeable: true,
          showInModal: false,
        },
        notifications,
      ]);

      navigateToNextPage();
    },
  });

  const handleRenewSolicitation = (data) => {
    const solContractYear = Number(solicitation?.contractYear);
    const dataContractYear = Number(data?.contractYear);
    const yearToRenewal =
      solContractYear === dataContractYear
        ? solContractYear + 1
        : dataContractYear;

    renewSolicitation({
      variables: {
        year: yearToRenewal,
        input: {
          solicitationID: Number(id),
          solicitationNumber: (data.upiidAAC + data.upiidSQ).toUpperCase(),
          title: data.solicitationTitle,
          purchaseTypeCode: data.purchaseType,
          solicitationType: SOLICITATION_PROCESS.RENEWAL,
          description: data.solicitationDescription,
          status: SOLICITATION_STATUSES.Draft,
          samUrl: data.solicitationPosting,
        },
      },
    });
  };

  const onSubmit = (data) => {
    if (isRenewActionFlow()) {
      handleRenewSolicitation(data);
    } else if (
      `${solicitation?.contractYear}` !== `${data?.contractYear}` &&
      !showContractYearChangeModal
    ) {
      setContractYearUpdate(true);
      toggleContractYearChangeModal(data);
    }
    // before blindly updating the solicitation, check if there is any change in the data(isDataDirty)
    else if (isSolicitationDetailsDataDirty(solicitation, data)) {
      updateSolicitation({
        variables: {
          solicitationinput: {
            solicitationID: Number(id),
            solicitationNumber: (data.upiidAAC + data.upiidSQ).toUpperCase(),
            title: data.solicitationTitle,
            contractYear: Number(data.contractYear),
            purchaseTypeCode: data.purchaseType,
            solicitationType: solicitationProcess,
            description: data.solicitationDescription,
            prevStatus:
              isReopenActionFlow() || isMidCycleActionFlow()
                ? solicitation?.solicitationStatus
                : solicitation?.prevStatus,
            status:
              isReopenActionFlow() || isMidCycleActionFlow()
                ? SOLICITATION_STATUSES.Draft
                : solicitation?.solicitationStatus,
            samUrl: data.solicitationPosting,
            bidType: data.bidType || 'IN_FLEET',
          },
        },
      });
    } else {
      navigateToNextPage();
    }
  };

  useEffect(() => {
    const dropdownOptions = [
      {
        model: 'StandardsCodeModel',
        label: 'title',
        value: 'code',
        filter: {
          operator: 'EQ',
          key: 'category',
          value: 'Purchase Type',
        },
        uniqueKey: 'purchaseType',
        includeValueInLabel: false,
      },
    ];
    getOptions({
      variables: {
        options: dropdownOptions,
      },
    });
    return setSolicitationNotification([]);
  }, []);

  const handleSolicitationProcessChange = (purchaseTypeValue) => {
    if (isRenewActionFlow())
      setSolicitationProcess(SOLICITATION_PROCESS.RENEWAL);
    else {
      setSolicitationProcess(
        isStandAlonePurchaseType(purchaseTypeValue)
          ? SOLICITATION_PROCESS.STANDALONE
          : (setBidType('IN_FLEET'), SOLICITATION_PROCESS.RECOMPETE),
      );
    }
  };

  useEffect(() => {
    handleSolicitationProcessChange(solicitation?.purchaseType);
    setBidType(solicitation?.bidType);
  }, [solicitation]);

  const getYearOptions = (allOptions) => {
    const years = allOptions
      ?.filter((item) => item.uniqueKey === 'year')
      ?.map((yearOption) => ({
        label: yearOption.label,
        value: yearOption.value,
      }))
      ?.sort((a, b) => a.value - b.value);

    if (hasFutureOpenPeriodNewDraftSolicitation())
      return years.filter((year) => year.value >= new Date().getFullYear());

    return isRenewActionFlow()
      ? years
          .sort((a, b) => a.value - b.value)
          ?.filter((year) =>
            parseInt(solicitation?.contractYear, 10) ===
            new Date().getFullYear()
              ? parseInt(year.value, 10) > new Date().getFullYear()
              : parseInt(year.value, 10) >= new Date().getFullYear(),
          )
      : years;
  };

  const getPurchaseTypeOptions = (allOptions) => {
    const purchaseTypes = allOptions
      ?.filter((item) => item.uniqueKey === 'purchaseType')
      ?.map((option) => ({
        label: option.label,
        value: option.value,
      }));
    return isRenewActionFlow()
      ? purchaseTypes.filter((pt) => pt.value === 'S' || pt.value === 'Y')
      : purchaseTypes;
  };

  return (
    <>
      <ContractYearChangeWarnModal
        isOpen={showContractYearChangeModal}
        onClose={toggleContractYearChangeModal}
        onProceed={handleOnModalProceed(onSubmit)}
      />
      <form
        id="edit-solicitation-form"
        onSubmit={handleSubmit(onSubmit)}
        className="edit-solicitation-form"
        data-testid="edit-solicitation-form"
        noValidate
      >
        <div className="grid-row grid-col-6">
          {' '}
          <p className="text-ink" tabIndex={0}>
            Enter a new solicitation number to create your solicitation. If you
            are entering an existing solicitation number, then you will be
            prompted to proceed with renewing the solicitation.
          </p>{' '}
        </div>

        <UpiidNumber isDisabled />
        <div className="grid-row grid-col-6">
          <div className="grid-row grid-gap-2 detail-row">
            {/* Contract year */}
            <div tabIndex={0}>
              <Controller
                data-testid="new-solicitation-contract-year"
                name="contractYear"
                control={control}
                defaultValue={solicitation?.contractYear}
                render={({ onChange, value, ref }) => (
                  <SelectDropdown
                    data-testid="solicitation-contract-year-select"
                    aria-label="contract year"
                    aria-required="true"
                    aria-disabled={
                      !isRenewActionFlow() &&
                      !hasFutureOpenPeriodNewDraftSolicitation()
                    }
                    onChange={(ev) => {
                      onChange(ev.target.value);
                    }}
                    label="Contract year"
                    options={getYearOptions(options)}
                    inputRef={ref}
                    value={value}
                    disabled={
                      !isRenewActionFlow() &&
                      !hasFutureOpenPeriodNewDraftSolicitation()
                    }
                    required
                  />
                )}
              />
            </div>
            {/* Purchase type */}
            <div tabIndex={0}>
              <Controller
                data-testid="solicitation-purchase-type-select"
                name="purchaseType"
                control={control}
                defaultValue="S"
                render={({ onChange, value, ref }) => (
                  <SelectDropdown
                    data-testid="solicitation-purchase-type-select"
                    aria-label="purchase type"
                    aria-required="true"
                    aria-disabled={
                      isReopenActionFlow() ||
                      isRenewActionFlow() ||
                      isMidCycleActionFlow()
                    }
                    onChange={(ev) => {
                      onChange(ev.target.value);
                      handleSolicitationProcessChange(ev.target.value);
                    }}
                    default=""
                    label="Purchase type"
                    options={getPurchaseTypeOptions(options)}
                    inputRef={ref}
                    value={value}
                    disabled={
                      isReopenActionFlow() ||
                      isRenewActionFlow() ||
                      isMidCycleActionFlow()
                    }
                    required
                  />
                )}
              />
            </div>

            <div className="solicitation-process" tabIndex={0}>
              <Label className="text-bold">Solicitation process </Label>
              <span>{solicitationProcess}</span>
            </div>
          </div>
        </div>
        <br />
        {solicitationProcess &&
          solicitationProcess === SOLICITATION_PROCESS.STANDALONE && (
            <div>
              <BidSelection />
            </div>
          )}
        <br />
        <SolicitationDetails />
        <br />
        <br />

        {isRenewActionFlow() ? (
          <div className="grid-row grid-gap-4">
            <Button
              variant="unstyled"
              label="Cancel"
              onClick={() => {
                history.push('/catalog/solicitations');
              }}
            />
            <Button
              label="Save and continue"
              type="submit"
              data-testid="save-btn"
              rightIcon={{
                name: 'arrow_forward',
              }}
            />
          </div>
        ) : (
          <div className="grid-row grid-gap-4">
            <Button
              label="Next"
              type="submit"
              data-testid="save-btn"
              className="margin-left-2"
              rightIcon={{
                name: 'arrow_forward',
              }}
            />
          </div>
        )}
      </form>
    </>
  );
};
export default EditSolicitationFrom;
