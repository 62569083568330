import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import { isEmpty } from 'lodash';
import {
  Button,
  ErrorMessage,
  Icon,
  SelectDropdown,
  TextInput,
} from '@gsa/afp-component-library';
import EquipmentCode from './equipment-code';
import PredefinedValueTable from './predefined-values-table';
import EquipmentCodeDescription from './equipment-code-description';

const constants = {
  LABEL_EQUIPMENT_CODE: 'Equipment Code',
  LABEL_EQUIPMENT_UNIT: 'Unit',
  LABEL_EQUIPMENT_CRITERIA: 'Criteria',
  LABEL_EQUIPMENT_NUMERICAL_VALUE: 'Numerical value',
  LABEL_EQUIPMENT_NUMERICAL_VALUE_1: 'Numerical value 1',
  LABEL_EQUIPMENT_NUMERICAL_VALUE_2: 'Numerical value 2',
};

const AssociationForm = ({
  control,
  errors,
  register,
  setValue,
  updateEquipmentCodeId,
  equipmentCodeId,
  equipmentAssociation = {},
  multipleOptions = [],
}) => {
  const isSpecificationRequired = () => {
    // minimus ans details are not required for optional/O ECs
    if (equipmentAssociation?.associationTypeCode === 'O') return false;
    return true;
  };
  const isNew = equipmentAssociation?.id === undefined;
  const inputTypeCode = equipmentAssociation?.inputType?.code?.toLowerCase();

  const [isNumerical, setIsNumerical] = useState(inputTypeCode === 'n');
  const [isNumericalRange, setIsNumericalRange] = useState(
    inputTypeCode === 'r',
  );

  const isNumericalRangeOrNumerical = isNumerical || isNumericalRange;
  const [isPredefinedValues, setIsPredefinedValues] = useState(
    inputTypeCode === 'p',
  );

  const [predefinedValues, setPredefinedValues] = useState([]);

  const [deletedRows, setDeletedRows] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [newRows, setNewRows] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState('');
  const [showSpec, setShowSpec] = useState(isSpecificationRequired());

  const renderRequiredComponent = (value, additionStyle) => {
    return (
      <>
        <h2
          aria-label={value}
          // eslint-disable-next-line
          tabIndex="0"
          className={`font-sans-xs ${additionStyle}`}
        >
          {value} <span className="usa-hint usa-hint--required">*</span>
        </h2>
      </>
    );
  };

  const renderBoundValue = (name, defaultValue) => {
    return (
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ onChange, value }) => (
          <TextInput
            type="number"
            aria-label="Numerical value"
            hint="10 characters allowed"
            maxLength={10}
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        )}
      />
    );
  };

  const renderToolTip = () => (
    <ReactTooltip
      id="unit-tooltip"
      textColor="black"
      delayHide={1000}
      clickable
      backgroundColor="#FAF3D1"
      getContent={() => {
        return (
          <div className="padding-1 radius-lg">
            <h2 className="text-black font-sans-lg margin-0">
              <span>
                <Icon
                  iconName="info"
                  className="usa-icon--size-3 text-baseline"
                  style={{ fill: 'black' }}
                />
              </span>
              Create new unit types
            </h2>

            <p className="font-sans-lg margin-0">
              New unit types can be created in
            </p>
            <p className="font-sans-lg margin-0">
              <span className="margin-left- 1">the</span>
              <a
                href="/"
                className="text-underline margin-left-1"
                style={{ cursor: 'pointer' }}
              >
                Catalog codes table
              </a>
            </p>
          </div>
        );
      }}
    />
  );

  const defaultEqAssValue =
    equipmentAssociation?.inputTypeCode !== undefined &&
    equipmentAssociation?.inputTypeCode !== null &&
    equipmentAssociation?.inputTypeCode !== ''
      ? equipmentAssociation?.inputTypeCode
      : '';

  const defaultNumericalOrNumericalRangeValues = (currentSelectedInputType) => {
    if (
      equipmentAssociation?.inputTypeCode === 'N' ||
      equipmentAssociation?.inputTypeCode === 'R'
    ) {
      if (equipmentAssociation?.inputTypeCode !== currentSelectedInputType) {
        setValue('unitCode', '');
        setValue('lowerBound', 0);
      } else {
        setValue('unitCode', equipmentAssociation?.unitCode);
        setValue('lowerBound', equipmentAssociation?.lowerBound);
      }
    }
  };

  return (
    <>
      <div className="grid-row padding-bottom-2">
        <div className="grid-col-6">
          {renderRequiredComponent('Program')}
          {isNew && (
            <Controller
              id="ec_program"
              name="program"
              control={control}
              render={({ onChange, value }) => (
                <SelectDropdown
                  id="ec_program_sel"
                  data-testid="program"
                  aria-label="Program"
                  onChange={(v) => {
                    onChange(v);
                    setSelectedProgram(v.target.value);
                  }}
                  options={[
                    { label: 'Select', value: '' },
                    ...multipleOptions?.filter(
                      (o) =>
                        o?.type === 'standardsCodeModel' &&
                        o?.uniqueKey === 'program',
                    ),
                  ]}
                  value={value}
                  readOnly
                />
              )}
            />
          )}
          {!isNew && <>{equipmentAssociation?.equipmentCode.program} </>}
          {errors?.program?.message && (
            <ErrorMessage>{errors.program.message}</ErrorMessage>
          )}
        </div>
      </div>

      <EquipmentCode
        isNew={isNew}
        setEQcode={updateEquipmentCodeId}
        control={control}
        errors={errors}
        register={register}
        equipmentAssociation={equipmentAssociation}
        program={selectedProgram}
        setValue={setValue}
      />

      {isNew && equipmentCodeId && (
        <EquipmentCodeDescription equipmentCodeId={equipmentCodeId} />
      )}

      {!isNew && (
        <div className="grid-row padding-bottom-2">
          <div className="grid-col-6">
            <h2 className="font-sans-xs">Title</h2>
            {equipmentAssociation?.equipmentCode?.title}
          </div>
        </div>
      )}

      {!isNew && (
        <EquipmentCodeDescription
          equipmentCodeId={`${equipmentAssociation?.equipmentCode?.id}`}
          desc={equipmentAssociation?.equipmentCode?.content?.content}
        />
      )}

      <div className="grid-row padding-bottom-2">
        <div className="grid-col-3 font-sans-xs">
          {renderRequiredComponent('Type')}
          <Controller
            id="si_edit_type"
            name="associationTypeCode"
            control={control}
            defaultValue={equipmentAssociation?.associationTypeCode ?? ''}
            render={({ onChange, value }) => (
              <SelectDropdown
                id="si_edit_type_sel"
                data-testid="associationTypeCode"
                aria-label="Association Type"
                onChange={(v) => {
                  setShowSpec(v.target.value === 'S');
                  onChange(v);
                }}
                options={[
                  { label: 'Select', value: '' },
                  ...multipleOptions?.filter(
                    (o) =>
                      o?.type === 'standardsCodeModel' &&
                      o?.uniqueKey === 'associationType',
                  ),
                ]}
                value={value}
                readOnly
              />
            )}
          />
          {errors?.associationTypeCode?.message && (
            <ErrorMessage>{errors.associationTypeCode.message}</ErrorMessage>
          )}
        </div>
      </div>

      {/* standard details  input displayable only for standard input type */}
      {showSpec && (
        <div className="grid-row padding-bottom-2">
          <div className="grid-col-6">
            <>
              <h2 className="font-sans-xs">Standard details</h2>
              <Controller
                id="additional-info-text"
                name="associationText"
                defaultValue={equipmentAssociation?.associationText ?? ''}
                control={control}
                render={({ onChange, value }) => (
                  <TextInput
                    type="textarea"
                    aria-label="Standard details text input 80 characters allowed"
                    size="medium"
                    value={value}
                    maxLength={80}
                    name="associationText"
                    error={errors?.associationText}
                    onChange={(e) => onChange(e.target.value)}
                    hint="80 characters allowed"
                  />
                )}
              />
            </>

            {errors?.associationText?.message && (
              <ErrorMessage>{errors.associationText.message}</ErrorMessage>
            )}
          </div>
        </div>
      )}

      {/* Minimums  input displayable only for standard input type */}
      {showSpec && (
        <div className="grid-row padding-bottom-2 grid-gap-3 display-flex flex-row">
          <div className="grid-col-3">
            {renderRequiredComponent('Minimums')}
            <Controller
              name="inputTypeCode"
              defaultValue={defaultEqAssValue}
              control={control}
              render={({ onChange, value }) => (
                <SelectDropdown
                  data-testid="inputTypeCode"
                  aria-label="Minimums"
                  aria-required="true"
                  value={value}
                  options={[
                    { label: 'Select', value: '' },
                    ...multipleOptions?.filter(
                      (o) =>
                        o?.type === 'standardsCodeModel' &&
                        o?.uniqueKey === 'inputType',
                    ),
                  ]}
                  onChange={(e) => {
                    const v = e.target.value;
                    defaultNumericalOrNumericalRangeValues(v);
                    setIsNumerical(v?.toLowerCase() === 'n');
                    setIsNumericalRange(v?.toLowerCase() === 'r');
                    setIsPredefinedValues(v?.toLowerCase() === 'p');
                    onChange(v);
                  }}
                />
              )}
            />
            {errors?.inputTypeCode?.message && (
              <ErrorMessage>{errors.inputTypeCode?.message}</ErrorMessage>
            )}
          </div>
          {isPredefinedValues && (
            <div className="grid-col-3 margin-left-1px padding-0 flex-align-self-end">
              <Button
                variant="unstyled"
                type="button"
                onClick={() => {
                  setShowAdd(isPredefinedValues);
                }}
                style={{ cursor: 'pointer' }}
                label="Add new value"
              />
            </div>
          )}
        </div>
      )}

      {showSpec && isNumericalRangeOrNumerical && (
        <div className="grid-row padding-bottom-2 grid-gap-2">
          {/* Numerical */}
          <div className={isNumerical ? 'grid-col-6' : 'grid-col-4'}>
            {isNumerical
              ? renderRequiredComponent(
                  constants.LABEL_EQUIPMENT_NUMERICAL_VALUE,
                )
              : renderRequiredComponent(
                  constants.LABEL_EQUIPMENT_NUMERICAL_VALUE_1,
                  'margin-bottom-0',
                )}

            {isNumericalRange && (
              <p className="text-gray-50 margin-0">
                Must be greater than or equal to X
              </p>
            )}

            {renderBoundValue(
              'lowerBound',
              equipmentAssociation?.lowerBound ?? 0,
            )}

            {errors?.lowerBound?.message && (
              <ErrorMessage>{errors.lowerBound.message}</ErrorMessage>
            )}
          </div>

          {isNumericalRange && (
            <div className="grid-col-4">
              {renderRequiredComponent(
                constants.LABEL_EQUIPMENT_NUMERICAL_VALUE_2,
                'margin-bottom-0',
              )}
              <p className="text-gray-50 margin-0">
                Must be less than or equal to Y
              </p>
              {renderBoundValue(
                'upperBound',
                equipmentAssociation?.upperBound ?? 0,
              )}

              {errors?.upperBound?.message && (
                <ErrorMessage>{errors.upperBound.message}</ErrorMessage>
              )}
            </div>
          )}

          {isNumerical && (
            <div className="grid-col-3">
              {renderRequiredComponent(constants.LABEL_EQUIPMENT_CRITERIA)}
              <Controller
                id="si_edit_criteria"
                name="criteriaCode"
                control={control}
                defaultValue={equipmentAssociation?.criteriaCode ?? ''}
                render={({ onChange, value }) => (
                  <SelectDropdown
                    id="si_edit_criteria_sel"
                    aria-label="criteria"
                    onChange={(selected) => {
                      onChange(selected);
                    }}
                    options={[
                      { label: '- Select -', value: '' },
                      ...multipleOptions?.filter(
                        (o) =>
                          o?.type === 'standardsCodeModel' &&
                          o?.uniqueKey === 'criteria',
                      ),
                    ]}
                    value={value}
                  />
                )}
              />
              {errors?.criteriaCode?.message && (
                <ErrorMessage>{errors.criteriaCode.message}</ErrorMessage>
              )}
            </div>
          )}

          <div className="grid-col-3">
            <h2
              className={`font-sans-xs ${
                isNumerical ? '' : 'padding-bottom-3 margin-bottom-0'
              }`}
            >
              <div data-tip="" data-for="unit-tooltip">
                {constants.LABEL_EQUIPMENT_UNIT}
                <span style={{ color: 'red' }}>*</span>
                <span>
                  <Icon
                    iconName="info"
                    className="font-sans-xs margin-bottom-0 margin-left-1px"
                    style={{ fill: '#005EA2' }}
                  />
                </span>
              </div>
            </h2>
            {renderToolTip()}
            <Controller
              id="si_edit_unit"
              name="unitCode"
              control={control}
              defaultValue={equipmentAssociation?.unitCode ?? ''}
              render={({ onChange, value }) => (
                <SelectDropdown
                  id="si_edit_unit_sel"
                  aria-label="unit"
                  value={value}
                  onChange={(v) => {
                    onChange(v);
                  }}
                  options={[
                    { label: '- Select -', value: '' },
                    ...multipleOptions?.filter(
                      (o) =>
                        o?.type === 'standardsCodeModel' &&
                        o?.uniqueKey === 'units',
                    ),
                  ]}
                />
              )}
            />
            {errors?.unitCode?.message && (
              <ErrorMessage>{errors.unitCode.message}</ErrorMessage>
            )}
          </div>
        </div>
      )}

      {showSpec && isPredefinedValues && (
        <Controller
          name="preDefinedValue"
          defaultValue={
            isEmpty(predefinedValues)
              ? equipmentAssociation?.preDefinedValue?.value ?? []
              : predefinedValues
          }
          control={control}
          render={({ onChange, value }) => (
            <PredefinedValueTable
              data-testid="preDefinedValue"
              value={
                value.length > 0
                  ? value.filter((r) => !deletedRows.includes(r))
                  : value
              }
              predefinedValues={predefinedValues}
              setPredefinedValues={setPredefinedValues}
              deletedRows={deletedRows}
              showAdd={showAdd}
              setShowAdd={setShowAdd}
              setDeletedRows={setDeletedRows}
              showEdit={showEdit}
              setShowEdit={setShowEdit}
              setNewRows={setNewRows}
              newRows={newRows}
              onChange={(v) => {
                const newValues = !isEmpty(v)
                  ? v.filter((r) => !deletedRows.includes(r))
                  : v;
                onChange({ value: newValues });
              }}
            />
          )}
        />
      )}
    </>
  );
};

AssociationForm.propTypes = {
  updateEquipmentCodeId: PropTypes.func.isRequired,
  equipmentCodeId: PropTypes.string.isRequired,
  errors: PropTypes.shape(Object).isRequired,
  control: PropTypes.shape(Object).isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  equipmentAssociation: PropTypes.shape(Object).isRequired,
  multipleOptions: PropTypes.arrayOf(Object).isRequired,
};

export default AssociationForm;
