import { atom } from 'recoil';

const solicitationNoSinErrorNotifications = atom({
  key: 'Solicitation/noSinNotifications',
  default: [],
});

const solicitationNoOpenPeriodErrorNotifications = atom({
  key: 'Solicitation/noOpenPeriodNotifications',
  default: [],
});

export {
  solicitationNoSinErrorNotifications,
  solicitationNoOpenPeriodErrorNotifications,
};
