import React from 'react';
import PropTypes from 'prop-types';
import { StatusBadge as BaseStatusBadge } from '@gsa/afp-component-library';
import { emDashUnicode } from '../../../../utilities/constants';

const getVariant = (closingInDays) => {
  const days = +closingInDays;
  if (days >= 15) return 'Info-Gray';
  if (days >= 7) return 'Warning-Gray';
  if (days >= 0) return 'Urgent-Gray';
  return 'Inactive-Gray';
};

const getClosingInMsg = (closingInDays, closingInHrs, closingInMinutes) => {
  if ([null, undefined].includes(closingInDays)) return emDashUnicode;
  if (closingInDays > 0) return `${closingInDays} Day(s)`;
  if (closingInHrs > 0) return `${closingInHrs} Hr(s)`;
  if (closingInMinutes > 0) return `${closingInMinutes} Min(s)`;
  return 'Closed';
};

const StatusBadge = ({ closingInDays, closingInHrs, closingInMinutes }) => {
  const closingInDaysStr = getClosingInMsg(
    closingInDays,
    closingInHrs,
    closingInMinutes,
  );

  const variant = getVariant(closingInDays);
  return (
    <BaseStatusBadge
      data-testid="bid-active-sol-status-badge"
      data-testvariant={variant}
      variant={variant}
    >
      {closingInDaysStr}
    </BaseStatusBadge>
  );
};

StatusBadge.propTypes = {
  closingInDays: PropTypes.number.isRequired,
  closingInHrs: PropTypes.number,
  closingInMinutes: PropTypes.number,
};
StatusBadge.defaultProps = {
  closingInHrs: 0,
  closingInMinutes: 0,
};

export default StatusBadge;
