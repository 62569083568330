import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Button, Spinner } from '@gsa/afp-component-library';
import CommentsContextProvider, {
  commentsContext,
} from './CommentsContextProvider';
import CommentForm from './CommentForm';
import Comment from './Comment';
import {
  CommentPropType,
  PaginationPropType,
  GQLPropType,
} from '../../utilities/types';
import './Comments.scss';

const CommentContent = ({ comments, allowChanges }) => {
  let deletedGroup = [];
  const elements = [];
  comments.forEach((comment, index) => {
    if (comment.deletedAt !== null) {
      deletedGroup.push(comment);
    } else {
      if (deletedGroup.length) {
        elements.push(
          deletedGroup.length > 1
            ? `${deletedGroup.length} comments have been deleted`
            : `A comment has been deleted`,
        );
        deletedGroup = [];
      }
      elements.push(comment);
    }
    if (index === comments.length - 1 && deletedGroup.length) {
      elements.push(
        deletedGroup.length > 1
          ? `${deletedGroup.length} comments have been deleted`
          : `A comment has been deleted`,
      );
    }
  });
  const hideComments =
    comments.length === 0 ||
    (comments.length === 1 && comments[0].id && comments[0].deletedAt);
  return !hideComments ? (
    <ul className="add-list-reset">
      {elements.map((element) =>
        element.id ? (
          <Comment
            comment={element}
            key={element.id}
            allowChanges={allowChanges}
          />
        ) : (
          <li key={element} className="afp-deleted-comment margin-bottom-4">
            {element}
          </li>
        ),
      )}
    </ul>
  ) : (
    ''
  );
};

CommentContent.defaultProps = {
  allowChanges: true,
};

CommentContent.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape(CommentPropType)).isRequired,
  allowChanges: PropTypes.bool,
};

const HasMore = ({ onClick, comments }) => {
  const remaining = comments.count - comments.rows.length;
  const text = `Show previous ${remaining} comment${remaining > 1 ? 's' : ''}`;

  return remaining > 0 ? (
    <Button
      type="button"
      onClick={onClick}
      className="margin-bottom-6"
      variant="unstyled"
      data-testid="has-more-button"
      label={text}
    />
  ) : (
    ''
  );
};

HasMore.propTypes = {
  onClick: PropTypes.func.isRequired,
  comments: PropTypes.shape(PaginationPropType).isRequired,
};

const scrollBottomIntoView = (el) => {
  if (el.getBoundingClientRect().bottom > window.innerHeight) {
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

const Comments = ({
  parentPrimaryKey,
  fetchGQL,
  fetchResponseKey,
  createGQL,
  setParentContext,
  allowChanges = true,
}) => {
  const [showAll, setShowAll] = useState(false);
  const [comments, setComments] = useState({
    rows: [],
    count: 0,
    hasMore: false,
  });
  const ref = useRef();

  const { loading, data, refetch } = useQuery(fetchGQL, {
    variables: {
      id: parentPrimaryKey,
      order: [['createdAt', 'DESC']],
      offset: 0,
      limit: showAll ? comments.count : 5,
    },
  });

  useEffect(() => {
    if (data) {
      setComments(data[fetchResponseKey]);
      setParentContext(data[fetchResponseKey]);
    }

    if (showAll) {
      scrollBottomIntoView(ref.current);
    }
  }, [data]);

  return (
    <CommentsContextProvider
      parentPrimaryKey={parentPrimaryKey}
      comments={comments}
      setComments={setComments}
      refetchComments={refetch}
    >
      <div className="afp-comments margin-bottom-4" ref={ref}>
        <h2 className="margin-top-6">Comments</h2>
        {allowChanges ? (
          <CommentForm onUpdate={refetch} createGQL={createGQL} />
        ) : null}
        {loading ? (
          <Spinner />
        ) : (
          <>
            <CommentContent
              comments={comments.rows}
              allowChanges={allowChanges}
            />
            <HasMore
              comments={comments}
              onClick={() => setShowAll(comments.count)}
            />
          </>
        )}
      </div>
    </CommentsContextProvider>
  );
};

Comments.context = commentsContext;

Comments.defaultProps = {
  allowChanges: true,
};

Comments.propTypes = {
  parentPrimaryKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  fetchGQL: PropTypes.shape(GQLPropType).isRequired,
  fetchResponseKey: PropTypes.string.isRequired,
  createGQL: PropTypes.shape(GQLPropType).isRequired,
  setParentContext: PropTypes.func.isRequired,
  allowChanges: PropTypes.bool,
};

export default Comments;
