import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Alert, Button, Spinner, TextInput } from '@gsa/afp-component-library';
import { GQLPropType } from '../../utilities/types';

import { commentsContext } from './CommentsContextProvider';

const CommentForm = ({ createGQL }) => {
  const { parentPrimaryKey, refetchComments } = useContext(commentsContext);
  const [body, setBody] = useState('');
  const [fieldErrorMessage, setFieldErrorMessage] = useState(undefined);
  const [formErrorMessage, setFormErrorMessage] = useState(undefined);
  const [createComment, { loading }] = useMutation(createGQL, {
    onError: (error) => {
      setFormErrorMessage(`An unexpected error has occurred: ${error.message}`);
    },
    onCompleted: () => {
      refetchComments();
      setBody('');
    },
  });

  const submit = () => {
    if (!body.length) {
      setFieldErrorMessage('Please fill out the comment field');
    } else if (body.length > 1000) {
      setFieldErrorMessage('Comment must be fewer that 1,000 characters');
    } else {
      setFieldErrorMessage(undefined);

      createComment({
        variables: {
          id: parentPrimaryKey,
          comment: body,
        },
      });
    }
  };

  return (
    <div className="afp-comment-form margin-bottom-8">
      {formErrorMessage && <Alert type="error">{formErrorMessage}</Alert>}
      <TextInput
        id="comment-text"
        type="textarea"
        label="Comment"
        value={body}
        onChange={(e) => setBody(e.target.value)}
        hint="1,000 characters allowed"
        errorMessage={fieldErrorMessage}
      />

      <div className="display-flex flex-justify-end">
        {loading ? (
          <Spinner size="small" />
        ) : (
          <>
            <Button
              type="button"
              variant="outline"
              onClick={() => setBody('')}
              label="Clear"
            />
            <Button
              type="button"
              onClick={submit}
              className="margin-right-0"
              label="Post Comment"
            />
          </>
        )}
      </div>
    </div>
  );
};

CommentForm.propTypes = {
  createGQL: PropTypes.shape(GQLPropType).isRequired,
};

export default CommentForm;
