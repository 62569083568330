import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import React, { createContext, useContext, useReducer } from 'react';
import * as resolvers from '../../services/data-store';
import {
  DELETE_EQUIPMENT_CODE,
  EQUIPMENT_CODES,
  GET_MULTIPLE_OPTIONS,
  GET_OPTIONS,
  UPDATE_EQUIPMENT_CODE,
  OVERWRITE_EQUIPMENT_CODE,
  EQUIPMENT_CODES_TYPEAHEAD_OPTIONS_SEARCH,
} from '../../services/data-store';
import groupMultipleOptions from '../../utilities/options-helper';
import {
  addOrReplaceMessageById,
  removeMessageById,
} from '../../utilities/messages-util';

export const EquipmentCodesContext = createContext();

export const MODAL_MODES = {
  ADD: 'add',
  EDIT: 'edit',
  CONFIRM_DELETE: 'confirm_delete',
  CONFIRM_UPDATE: 'confirm_update',
  VIEW_ASSOCIATION: 'view-associated-standard-items',
};

const initialState = {
  MODAL_MODES,
  errors: null,
  modalMode: null,
  showFormModal: false,
  showEditor: false,
  selectedEquipmentCode: null,
  updatingEquipmentCode: null,
  multipleOptions: [],
  groupMultipleOptions: null,
  equipmentCodes: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  options: [],
  successMessage: '',
  errorMessage: '',
  selectedYearFilter: `${new Date().getFullYear()}`,
  selectedProgramFilter: null,
  ecMessages: [],
  typeaheadData: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_EQUIPMENT_CODE':
      return { ...state, selectedEquipmentCode: action.payload };
    case 'SET_UPDATING_EQUIPMENT_CODE':
      return { ...state, updatingEquipmentCode: action.payload };
    case 'SET_SELECTED_FILTER_YEAR':
      return { ...state, selectedYearFilter: action.payload };
    case 'SET_SELECTED_FILTER_PROGRAM':
      return { ...state, selectedProgramFilter: action.payload };
    case 'SET_MULTIPLE_OPTIONS':
      return { ...state, multipleOptions: action.payload };
    case 'SET_GROUP_MULTIPLE_OPTIONS':
      return { ...state, groupMultipleOptions: action.payload };
    case 'SET_EQUIPMENT_CODES':
      return { ...state, equipmentCodes: action.payload };
    case 'SET_MODAL_MODE': {
      return { ...state, modalMode: action.payload };
    }
    case 'SET_SUCCESS_MESSAGE':
      return { ...state, successMessage: action.payload };
    case 'SET_ERROR_MESSAGE':
      return { ...state, errorMessage: action.payload };
    case 'SET_OPTIONS':
      return { ...state, options: action.payload };
    case 'SET_FORM_MODAL': {
      return { ...state, showFormModal: action.payload };
    }
    case 'SET_SHOW_EDITOR': {
      return { ...state, showEditor: action.payload };
    }
    case 'SET_TYPEAHEAD_FILTER_DATA':
      return { ...state, typeaheadData: action.payload };
    case 'SET_EC_MESSAGES': {
      return { ...state, ecMessages: action.payload };
    }
    case 'RESET': {
      return initialState;
    }
    default:
      return state;
  }
};

function EquipmentCodesProvider({ children, ...props }) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const setData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const setEcTableError = (error, showInModal) => {
    const message = {
      id: 'EC_ERROR',
      message: error?.message,
      showInModal,
      type: 'error',
    };
    setData(
      'SET_EC_MESSAGES',
      addOrReplaceMessageById(message, state.ecMessages),
    );
  };

  const removeEcMessageById = (id) => {
    setData('SET_EC_MESSAGES', removeMessageById(id, state.ecMessages));
  };

  const resetState = () => {
    setData('RESET');
  };

  // Get Equipment Codes
  const [getEquipmentCodes, { refetch, loading: isECsLoading }] = useLazyQuery(
    EQUIPMENT_CODES,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (requestError) => {
        setEcTableError(requestError, false);
      },
      onCompleted: (responseData) => {
        if (responseData?.getEquipmentCodes) {
          setData('SET_EQUIPMENT_CODES', responseData.getEquipmentCodes);
          removeEcMessageById('EC_ERROR');
        }
      },
    },
  );

  // Get Multiple Options
  const [getMultipleOptions] = useLazyQuery(GET_MULTIPLE_OPTIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (requestError) => {
      setEcTableError(requestError, false);
    },
    onCompleted: (multipleOptionsData) => {
      if (multipleOptionsData?.getMultipleOptions) {
        const options = groupMultipleOptions(
          multipleOptionsData?.getMultipleOptions,
        );
        options.category?.unshift({ label: '', value: '' });
        setData(
          'SET_MULTIPLE_OPTIONS',
          multipleOptionsData?.getMultipleOptions,
        );
        setData('SET_GROUP_MULTIPLE_OPTIONS', options);
        removeEcMessageById('EC_ERROR');
      }
    },
  });

  // Get Options
  const [getOptions] = useLazyQuery(GET_OPTIONS, {
    onError: (requestError) => {
      setEcTableError(requestError, false);
    },
    onCompleted: (optionsData) => {
      if (optionsData?.getOptions) {
        setData('SET_OPTIONS', optionsData.getOptions);
        removeEcMessageById('EC_ERROR');
      }
    },
  });

  const [getEquipmentCode] = useLazyQuery(EQUIPMENT_CODES, {
    onError: (requestError) => {
      setEcTableError(requestError, false);
    },
    onCompleted: (response) => {
      if (response?.getEquipmentCodes) {
        setData('SET_EQUIPMENT_CODE', response?.getEquipmentCodes?.rows[0]);
      }
    },
  });

  const [updateEquipmentCode] = useMutation(UPDATE_EQUIPMENT_CODE, {
    onError: (requestError) => {
      // setData('SET_FORM_MODAL', true);
      // only show th Confirm-Update modal if the Equipment-Code exists
      // which the requestError returns "Please enter a unique code" as part
      // of the error message
      // eslint-disable-next-line
      String(requestError)?.indexOf('Please enter a unique code') > -1
        ? setData('SET_MODAL_MODE', MODAL_MODES.CONFIRM_UPDATE)
        : setEcTableError(requestError, true);
    },
    onCompleted: (equipmentCodeResponse) => {
      if (equipmentCodeResponse?.updateEquipmentCode) {
        removeEcMessageById('EC_ERROR');
        const { code, title, id } = equipmentCodeResponse?.updateEquipmentCode;
        const m = {
          id: 'EC_MESSAGE',
          message: `Successfully updated Equipment Code ${code} - ${title}`,
          type: 'success',
          closeable: true,
          showInModal: false,
        };
        setData(
          'SET_EC_MESSAGES',
          addOrReplaceMessageById(m, state.ecMessages),
        );
        setData('SET_FORM_MODAL', false);
        setData('SET_SHOW_EDITOR', initialState.showEditor);
        getEquipmentCode({
          variables: { filters: { operator: 'EQ', key: 'id', value: id } },
        });
        refetch();
      }
    },
  });

  // Overwrite Equipment Code
  const [overwriteEquipmentCode] = useMutation(OVERWRITE_EQUIPMENT_CODE, {
    onError: (requestError) => {
      setEcTableError(requestError, true);
    },
    onCompleted: (equipmentCodeResponse) => {
      if (equipmentCodeResponse?.overwriteEquipmentCode) {
        removeEcMessageById('EC_ERROR');
        const {
          code,
          title,
          id,
        } = equipmentCodeResponse?.overwriteEquipmentCode;
        const m = {
          id: 'EC_MESSAGE',
          message: `Successfully updated Equipment Code ${code} - ${title}`,
          type: 'success',
          closeable: true,
          showInModal: false,
        };
        setData(
          'SET_EC_MESSAGES',
          addOrReplaceMessageById(m, state.ecMessages),
        );
        setData('SET_FORM_MODAL', false);
        setData('SET_SHOW_EDITOR', initialState.showEditor);
        getEquipmentCode({
          variables: { filters: { operator: 'EQ', key: 'id', value: id } },
        });
      }
    },
  });

  const [deleteEquipmentCode] = useMutation(DELETE_EQUIPMENT_CODE, {
    onError: (requestError) => {
      setEcTableError(requestError, true);
    },
    onCompleted: (equipmentCodeResponse) => {
      if (equipmentCodeResponse?.deleteEquipmentCode) {
        removeEcMessageById('EC_ERROR');
        const m = {
          id: 'EC_MESSAGE',
          message: `Successfully deleted the Equipment Code`,
          type: 'success',
          closeable: true,
          showInModal: false,
        };
        setData(
          'SET_EC_MESSAGES',
          addOrReplaceMessageById(m, state.ecMessages),
        );
        setData('SET_FORM_MODAL', false);
        setData('SET_SHOW_EDITOR', initialState.showEditor);
        refetch();
      }
    },
  });

  // Add Equipment Code
  const [addEquipmentCode] = useMutation(resolvers.ADD_EQUIPMENT_CODE, {
    onError: (requestError) => {
      setEcTableError(requestError, true);
    },
    onCompleted: (equipmentCodeResponse) => {
      if (equipmentCodeResponse?.addEquipmentCode) {
        const { code, year, program } = equipmentCodeResponse?.addEquipmentCode;
        setData('SET_FORM_MODAL', initialState.showFormModal);
        setData('SET_MODAL_MODE', initialState.modalMode);
        const message = `Successfully created Equipment Code 
          ${equipmentCodeResponse?.addEquipmentCode?.code} - 
          ${equipmentCodeResponse?.addEquipmentCode?.title}`;
        history.push(
          `/catalog/equip-codes/${year}/${program}/${code}`,
          message,
        );
      }
    },
  });

  const [getTypeAheadOptions] = useLazyQuery(
    EQUIPMENT_CODES_TYPEAHEAD_OPTIONS_SEARCH,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ getEquipmentCodesTypeAheadOptions }) => {
        if (getEquipmentCodesTypeAheadOptions) {
          const { options, key } = getEquipmentCodesTypeAheadOptions;

          // Add "ec_" prefix to title as par the key used in the filterStructure
          setData('SET_TYPEAHEAD_FILTER_DATA', {
            field: key === 'title' ? 'ec_title' : key,
            values: options,
          });
        }
      },
    },
  );

  return (
    <EquipmentCodesContext.Provider
      value={{
        ...state,
        setData,
        resetState,
        getOptions,
        getMultipleOptions,
        getEquipmentCodes,
        updateEquipmentCode,
        overwriteEquipmentCode,
        addEquipmentCode,
        getTypeAheadOptions,
        removeEcMessageById,
        deleteEquipmentCode,
        isECsLoading,
        ...props,
      }}
    >
      {children}
    </EquipmentCodesContext.Provider>
  );
}

export default EquipmentCodesProvider;

EquipmentCodesProvider.defaultProps = {};

EquipmentCodesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useEquipmentCodes = () => useContext(EquipmentCodesContext);
