import React, { useCallback, useState } from 'react';
import moment from 'moment';
import {
  FlexView,
  DatePicker,
  TimePicker,
  SelectDropdown,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import { useContractLine } from '../provider/contract-line-provider';
import { ReadOnlyWrapper } from '../helpers/ec-components';

const StartEndDateTimePicker = () => {
  const {
    contractLine,
    lineTabData,
    setLineTabData,
    canUpdateContract,
  } = useContractLine();

  const startDateStr =
    'orderStartDate' in lineTabData
      ? lineTabData.orderStartDate
      : contractLine?.orderStartDate;
  const startDate = startDateStr ? moment(startDateStr) : null;
  const formattedStartDate = startDate
    ? startDate?.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    : null;
  const formattedStartTime = startDate
    ? startDate?.utc().format('HH:mm')
    : null;

  const endDateStr =
    'orderEndDate' in lineTabData
      ? lineTabData?.orderEndDate
      : contractLine?.orderEndDate;
  const endDate = endDateStr ? moment(endDateStr) : null;
  const formattedEndDate = endDate
    ? endDate?.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    : null;
  const formattedEndTime = endDate ? endDate?.utc().format('HH:mm') : null;
  const isDirect = contractLine?.isDirect;

  // bellow is used to work around a bug in the DatePicker component, which calls on change on intial render
  const [firstDateComChange, setFirstDateComChange] = useState({
    // eslint-disable-next-line
    orderStartDate: formattedStartDate ? true : false,
    // eslint-disable-next-line
    orderStartDateTime: true,
    // eslint-disable-next-line
    orderEndDate: formattedEndDate ? true : false,
    // eslint-disable-next-line
    orderEndDateTime: true,
  });

  const onDateChange = useCallback(
    (fieldKey, value) => {
      const isFirstInitialOnChange = firstDateComChange[fieldKey];
      if (isFirstInitialOnChange) {
        setFirstDateComChange((prev) => ({
          ...prev,
          [fieldKey]: false,
        }));
        return;
      }

      if (!value) {
        setLineTabData((prev) => ({
          ...prev,
          [fieldKey]: null,
        }));
        return;
      }
      const date =
        (fieldKey === 'orderStartDate' ? startDate : endDate) ||
        moment().startOf('day');

      const parsedDate = moment(value, 'MM/DD/YYYY');
      const day = parsedDate.date();
      const month = parsedDate.month();
      const year = parsedDate.year();
      date.year(year);
      date.month(month);
      date.date(day);

      setLineTabData((prev) => ({
        ...prev,
        [fieldKey]: date.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      }));
    },
    [firstDateComChange, startDate, endDate],
  );

  const onTimeChange = useCallback(
    (fieldKey, value) => {
      const isFirstInitialOnChange = firstDateComChange[`${fieldKey}Time`];
      if (isFirstInitialOnChange) {
        setFirstDateComChange((prev) => ({
          ...prev,
          [`${fieldKey}Time`]: false,
        }));
        return;
      }

      const [hours, minutes] = value?.split(':').map(Number) || [];
      const date =
        (fieldKey === 'orderStartDate' ? startDate : endDate) ||
        moment().utc().startOf('day');
      date.set({
        hour: hours,
        minute: minutes,
      });
      setLineTabData((prev) => ({
        ...prev,
        [fieldKey]: date.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      }));
    },
    [firstDateComChange, startDate, endDate],
  );

  const onDirectChange = useCallback(
    (value) => {
      setLineTabData((prev) => ({
        ...prev,
        isDirect: value,
      }));
    },
    [isDirect],
  );

  return (
    <>
      <FlexView column className="margin-top-6">
        <div className="grid-row margin-bottom-6">
          <ReadOnlyWrapper wrapperType="grid" dataType="date">
            <DatePicker
              id="open-date"
              name="openDate"
              maxDate={formattedEndDate || undefined}
              minDate={moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}
              defaultValue={formattedStartDate}
              className="margin-right-4"
              labelClass="text-bold margin-bottom-2"
              label="Open date"
              onChange={(value) => onDateChange('orderStartDate', value)}
              disabled={!canUpdateContract}
            />
          </ReadOnlyWrapper>
          <ReadOnlyWrapper wrapperType="grid">
            <TimePicker
              id="open-time"
              defaultValue={formattedStartTime}
              name="openTime"
              label="Open time"
              className="margin-right-4"
              onChange={(value) => onTimeChange('orderStartDate', value)}
              disabled={!canUpdateContract}
            />
          </ReadOnlyWrapper>
          <ReadOnlyWrapper wrapperType="grid" dataType="date">
            <DatePicker
              id="close-date"
              name="closeDate"
              defaultValue={formattedEndDate}
              minDate={formattedStartDate}
              labelClass="text-bold margin-bottom-2"
              className="margin-right-4"
              label="Closeout date"
              onChange={(value) => onDateChange('orderEndDate', value)}
              disabled={!canUpdateContract}
            />
          </ReadOnlyWrapper>
          <ReadOnlyWrapper wrapperType="grid">
            <TimePicker
              id="close-time"
              name="closeTime"
              defaultValue={formattedEndTime}
              label="Closeout time"
              onChange={(value) => onTimeChange('orderEndDate', value)}
              disabled={!canUpdateContract}
            />
          </ReadOnlyWrapper>
        </div>
        <div className="width-card">
          <div className="text-bold">
            Direct <RequiredFieldIndicator />
          </div>
          <ReadOnlyWrapper wrapperType="grid">
            <SelectDropdown
              id="isDirect"
              name="isDirect"
              options={[
                { value: null, label: '-Select-' },
                { value: 1, label: 'Yes' },
                { value: 0, label: 'No' },
              ]}
              value={isDirect}
              onChange={(e) => {
                onDirectChange(e.target.value);
              }} // Handle state change
              required
              disabled={!canUpdateContract}
            />
          </ReadOnlyWrapper>
        </div>
      </FlexView>
    </>
  );
};

export default StartEndDateTimePicker;
