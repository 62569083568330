import React, { useEffect, useState } from 'react';
import { useStandardItem } from './standard-item-provider';
import StandardItemForm from '../../components/forms/standard-item-form';
import StandardItemFormContext from '../../components/forms/standard-item-form-context';
import groupMultipleOptions from '../../utilities/options-helper';

const StandardItemEdit = () => {
  const {
    standardItem,
    multipleOptions,
    updateStandardItem,
  } = useStandardItem();

  const [requiredFilters, setRequiredFilters] = useState({
    tags: [],
    fedStandardCode: [],
    vehicleType: [],
    futureYears: [],
    fastCodes: [],
  });

  useEffect(() => {
    if (!multipleOptions?.length) return;
    setRequiredFilters(groupMultipleOptions(multipleOptions));
  }, [multipleOptions]);

  const handleSave = async (data) => {
    const tags = { value: [] };
    if (data?.tags) {
      tags.value = data.tags;
    }
    const standardItemInput = {
      ...data,
      tags,
      contentId: standardItem?.content?.id,
      year: parseInt(data?.year, 10),
    };
    await updateStandardItem({
      variables: {
        id: standardItem.id,
        standarditem: standardItemInput,
      },
    });
  };

  return (
    <>
      <StandardItemFormContext standardItem={standardItem}>
        <StandardItemForm
          formId="standard-item-edit-form"
          standardItemTags={requiredFilters?.tags}
          fedStandardCodes={requiredFilters?.fedStandardCode}
          // VechicleGroups added to auto populate fedStandardCode of an SI in SI Edit
          vehicleGroups={requiredFilters?.vehicleGroup}
          vehicleTypes={requiredFilters?.vehicleType}
          years={requiredFilters?.futureYears}
          fastCodes={requiredFilters?.fastCodes}
          handleSave={handleSave}
          isAddForm={false}
        />
      </StandardItemFormContext>
    </>
  );
};

export default StandardItemEdit;
