import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_SOLICITATION_BY_ID } from '../../queries';
import {
  processOpenPeriod,
  processOnlyReopenPeriods,
  processOnlyMidcyclePeriods,
  getLastSolicitationPeriod,
} from '../common/reopen-periods/open-reopen-period-util';

export default function useSolicitationDetailsView() {
  const [solicitation, setSolicitation] = useState({});
  const [openPeriod, setOpenPeriod] = useState({});
  const [lastSolicitationPeriod, setLastSolicitationPeriod] = useState({});
  const [reopenPeriods, setReopenPeriods] = useState([]);
  const [midcyclePeriods, setMidcyclePeriods] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [contractYear, setSolicitationContractYear] = useState(
    new Date().getFullYear(),
  );

  const [getSolicitationById, { loading: solicitationLoading }] = useLazyQuery(
    GET_SOLICITATION_BY_ID,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setSolicitation(data.getSolicitationById);
        setSolicitationContractYear(data.getSolicitationById.contractYear);
        const openPeriodTemp = processOpenPeriod([
          ...data.getSolicitationById.solicitationPeriods,
        ]);

        if (openPeriodTemp?.startDate) {
          setOpenPeriod(openPeriodTemp);
        }

        if (
          data.getSolicitationById?.programs &&
          data.getSolicitationById?.programs.length > 0
        ) {
          setPrograms(data.getSolicitationById?.programs);
        }

        if (
          data.getSolicitationById?.solicitationPeriods &&
          data.getSolicitationById?.solicitationPeriods?.length > 0
        ) {
          setReopenPeriods(
            processOnlyReopenPeriods([
              ...data.getSolicitationById.solicitationPeriods,
            ]),
          );
          setMidcyclePeriods(
            processOnlyMidcyclePeriods([
              ...data.getSolicitationById.solicitationPeriods,
            ]),
          );
          setLastSolicitationPeriod(
            getLastSolicitationPeriod([
              ...data.getSolicitationById.solicitationPeriods,
            ]),
          );
        }
      },
    },
  );

  return {
    solicitationLoading,
    solicitation,
    contractYear,
    openPeriod,
    reopenPeriods,
    midcyclePeriods,
    lastSolicitationPeriod,
    programs,
    getSolicitationById,
  };
}
