/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState, useEffect } from 'react';
import { Button, SelectDropdown, Label } from '@gsa/afp-component-library';
import { Controller, useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import UpiidNumber from '../../../components/upiid-number';
import SolicitationDetails from '../../../components/solicitation-details';
import {
  GET_MULTIPLE_OPTIONS,
  CREATE_SOLICITATION,
  UPDATE_SOLICITATION,
} from '../../../queries';
import solicitationNotifications from '../../../utils/solicitation-notification-helpers';
import './add-solicitation.scss';
import BidSelection from '../../../components/bid-selection';
import { SOLICITATION_PROCESS } from './constants';
import biddingType from '../../../atoms/solicitation-helpers';
import DuplicateUpiidRenewalAlert from './duplicate-upiid-renewal-alert';

const UPIID_EXISTS_ACROSS_PREVIOUS_YEAR = 'uPIID_EXISTS_ACROSS_PREVIOUS_YEAR';
const UPIID_DRAFT_EXISTS_PREVIOUS_YEAR = 'UPIID_DRAFT_EXISTS_PREVIOUS_YEAR';

const AddSolicitationFrom = () => {
  const history = useHistory();
  const { handleSubmit, control, watch, setError } = useFormContext();
  const id = watch('solicitationID');
  const [options, setOptions] = useState([]);
  const [areFieldsDisabled, setAreFieldsDisabled] = useState(false);
  const [bidType, setBidType] = useRecoilState(biddingType);
  const [solicitationProcess, setSolicitationProcess] = useState(
    SOLICITATION_PROCESS.RECOMPETE,
  );
  const [notifications, setSolicitationNotification] = useRecoilState(
    solicitationNotifications,
  );
  // Queries
  const [getOptions] = useLazyQuery(GET_MULTIPLE_OPTIONS, {
    fetchPolicy: 'c',
    onCompleted: (data) => {
      setOptions(data.getMultipleOptions);
    },
  });

  const [createDraftSolicitation] = useMutation(CREATE_SOLICITATION, {
    onError: (requestError) => {
      const {
        jse_info: { solicitationObj },
        name: errorName,
      } = requestError?.graphQLErrors[0]?.extensions?.exception ?? {
        jse_info: {},
      };

      const isDuplicateUpiidError =
        errorName === UPIID_EXISTS_ACROSS_PREVIOUS_YEAR;

      if (isDuplicateUpiidError) {
        // set form inputs in error state
        setError('upiidAACRenewal', { type: 'custom' });
        setError('upiidSQRenewal', { type: 'custom' });
        setSolicitationNotification([
          {
            id: 'CREATE_SOLICITATION_ERROR',
            message: (
              <DuplicateUpiidRenewalAlert solicitationObj={solicitationObj} />
            ),
            type: 'error',
            closeable: true,
            showInModal: false,
          },
        ]);
        return;
      }

      if (errorName === UPIID_DRAFT_EXISTS_PREVIOUS_YEAR) {
        setSolicitationNotification([
          {
            id: 'CREATE_SOLICITATION_ERROR',
            message: (
              <>
                <h3 className="usa-alert__heading margin-right-2">
                  uPIID already exists
                </h3>
                <p>
                  There is already a solicitation with this uPIID. Please return
                  to the{' '}
                  <Link to="/catalog/solicitations" className="text-primary">
                    Solicitation listing
                  </Link>{' '}
                  page and filter to the uPIID.
                </p>
              </>
            ),
            type: 'error',
            closeable: true,
            showInModal: false,
          },
        ]);
        return;
      }

      // otherwise we show the returned error msg
      setSolicitationNotification([
        {
          id: 'CREATE_SOLICITATION_ERROR',
          message: requestError?.message,
          type: 'error',
          closeable: false,
          showInModal: false,
        },
      ]);
    },
    onCompleted: (response) => {
      const { solicitationNumber, id: uid } = response.createSolicitation;
      setSolicitationNotification([
        {
          id: 'CREATE_SOLICITATION',
          message: `Solicitation ${solicitationNumber} was successfully added.`,
          type: 'success',
          closeable: true,
          showInModal: false,
        },
        notifications,
      ]);
      if (bidType === 'IN_FLEET') {
        history.push(
          `/catalog/solicitations/new-solicitation/${uid}/open-period`,
        );
      } else if (bidType === 'OUTSIDE_FLEET') {
        history.push(
          `/catalog/solicitations/new-solicitation/${uid}/select-sin`,
        );
      }
    },
  });

  const [updateSolicitation] = useMutation(UPDATE_SOLICITATION, {
    onError: (requestError) => {
      setSolicitationNotification([
        {
          id: 'UPDATE_SOLICITATION_ERROR',
          message: requestError.message,
          type: 'error',
          closeable: false,
          showInModal: false,
        },
        notifications,
      ]);
    },
    onCompleted: () => {
      // set success message
      setSolicitationNotification([
        {
          id: 'UPDATE_SOLICITATION',
          message: `Solicitation has been successfully updated.`,
          type: 'success',
          closeable: true,
          showInModal: false,
        },
        notifications,
      ]);

      history.push(
        `/catalog/solicitations/edit-solicitation/${id}/open-period`,
      );
    },
  });

  useEffect(() => {
    setSolicitationNotification([]);
  }, []);

  const onSubmit = (data) => {
    if (id) {
      updateSolicitation({
        variables: {
          solicitationinput: {
            solicitationID: Number(id),
            solicitationNumber: (data.upiidAAC + data.upiidSQ).toUpperCase(),
            title: data.solicitationTitle,
            contractYear: Number(data.contractYear),
            purchaseTypeCode: data.purchaseType,
            solicitationType: solicitationProcess,
            description: data.solicitationDescription,
            status: 'Draft',
            samUrl: data.solicitationPosting,
            bidType: data.bidType || 'IN_FLEET',
          },
        },
      });
    } else {
      createDraftSolicitation({
        variables: {
          solicitationinput: {
            solicitationNumber: (data.upiidAAC + data.upiidSQ).toUpperCase(),
            title: data.solicitationTitle,
            contractYear: Number(data.contractYear),
            purchaseTypeCode: data.purchaseType,
            solicitationType: solicitationProcess,
            description: data.solicitationDescription,
            status: 'Draft',
            samUrl: data.solicitationPosting,
            bidType: data.bidType || 'IN_FLEET',
          },
        },
      });
    }
  };

  useEffect(() => {
    const dropdownOptions = [
      {
        model: 'StandardsCodeModel',
        label: 'title',
        value: 'code',
        filter: {
          operator: 'EQ',
          key: 'category',
          value: 'Purchase Type',
        },
        uniqueKey: 'purchaseType',
        includeValueInLabel: false,
      },
    ];
    getOptions({
      variables: {
        options: dropdownOptions,
      },
    });
    setBidType('IN_FLEET');
  }, []);

  useEffect(() => {
    if (id) {
      setAreFieldsDisabled(true);
    }
  }, [id]);

  const handleSolicitationProcessChange = (value) => {
    if (value === 'E' || value === 'N' || value === 'M') {
      setSolicitationProcess(SOLICITATION_PROCESS.STANDALONE);
    } else {
      setSolicitationProcess(SOLICITATION_PROCESS.RECOMPETE);
      setBidType('IN_FLEET');
    }
  };

  // get current and future contract years
  const getContractYears = () => {
    const years = options?.filter((item) => item.uniqueKey === 'year');
    const contractYears = years.filter(
      (year) => year.value >= new Date().getFullYear(),
    );
    return contractYears.sort((a, b) => a?.value - b?.value);
  };

  return (
    <>
      <form
        id="form"
        onSubmit={handleSubmit(onSubmit)}
        className="new-solicitation-form"
        data-testid="new-solicitation-form"
        noValidate
      >
        <div className="grid-row grid-col-6">
          <p className="text-ink" tabIndex={0}>
            Enter a new solicitation number to create your solicitation. If you
            are entering an existing solicitation number, then you will be
            prompted to proceed with renewing the solicitation.
          </p>
        </div>

        <UpiidNumber isDisabled={areFieldsDisabled} />
        <div className="grid-row grid-col-6">
          <div className="grid-row grid-gap-2 detail-row">
            {/* Contract year */}
            <Controller
              data-testid="new-solicitation-contract-year"
              name="contractYear"
              control={control}
              defaultValue={new Date().getFullYear()}
              render={({ onChange, value, ref }) => (
                <SelectDropdown
                  data-testid="solicitation-contract-year-select"
                  aria-label="contract year"
                  aria-required="true"
                  onChange={(ev) => {
                    onChange(ev.target.value);
                  }}
                  label="Contract year"
                  options={getContractYears()?.map((option) => ({
                    label: option.label,
                    value: option.value,
                  }))}
                  inputRef={ref}
                  value={value}
                  disabled={areFieldsDisabled}
                  required
                />
              )}
            />
            {/* Purchase type */}
            <Controller
              data-testid="solicitation-purchase-type-select"
              name="purchaseType"
              control={control}
              defaultValue="S"
              render={({ onChange, value, ref }) => (
                <SelectDropdown
                  data-testid="solicitation-purchase-type-select"
                  aria-label="purchase type"
                  aria-required="true"
                  onChange={(ev) => {
                    onChange(ev.target.value);
                    handleSolicitationProcessChange(ev.target.value);
                  }}
                  default=""
                  label="Purchase type"
                  options={options
                    ?.filter((item) => item.uniqueKey === 'purchaseType')
                    ?.map((option) => ({
                      label: option.label,
                      value: option.value,
                    }))}
                  inputRef={ref}
                  value={value}
                  required
                />
              )}
            />
            <div className="solicitation-process" tabIndex={0}>
              <Label className="text-bold">Solicitation process </Label>
              <span>{solicitationProcess}</span>
            </div>
          </div>
        </div>
        <br />
        {solicitationProcess === SOLICITATION_PROCESS.STANDALONE && (
          <div>
            <BidSelection />
          </div>
        )}

        <br />
        <SolicitationDetails />
        <br />
        <br />

        <div className="grid-row grid-gap-4 margin-left-4">
          {!areFieldsDisabled && (
            <Button
              variant="unstyled"
              label="Cancel"
              onClick={() => {
                history.push('/catalog/solicitations');
              }}
            />
          )}
          <Button
            label={areFieldsDisabled ? 'Next' : 'Save and continue'}
            type="submit"
            data-testid="save-btn"
            className={areFieldsDisabled ? 'margin-left-2' : ''}
            rightIcon={{
              name: 'arrow_forward',
            }}
          />
        </div>
      </form>
    </>
  );
};

export default AddSolicitationFrom;
