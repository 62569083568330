import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { uniq } from 'lodash';
import StandardItemMakeModelPanel from '../make-model/si-make-model-panel';
import {
  NON_STANDARD_LINE_TYPES,
  useSIMakeModelProvider,
} from '../make-model/si-make-model-panel-provider';
import StandardItemSelect from './standard-item-select';

import './add-non-standard-line.scss';
import { useAddContractLineProvider } from './add-contract-line-provider';

const AddNonStandardLineExpress = ({ contractHeader }) => {
  const {
    selectedStandardItem,
    setSelectedStandardItem,
    getErrors,
    setDirtyFields,
    dirtyFields,
  } = useSIMakeModelProvider();

  const { setNonStandardLineType } = useAddContractLineProvider();

  useEffect(() => {
    setNonStandardLineType(NON_STANDARD_LINE_TYPES.MAKE_MODEL_YEAR);
  }, []);

  const solicitationStandardItems = contractHeader?.solicitation?.programs?.flatMap(
    (program) => {
      const standardItems = program?.solicitationLines?.map((line) => {
        return line?.standardItem;
      });
      return standardItems;
    },
  );
  const options = solicitationStandardItems?.map((s) => ({
    label: `${s.standardItemNumber} - ${s.title}`,
    value: s.id,
  }));
  const errors = getErrors();
  const standardItemError = errors?.find((e) => e.field === 'standardItem')
    ?.message;

  return (
    <div
      id="add-non-standard-line"
      data-testid="add-non-standard-line"
      className="margin-top-4"
    >
      <div className="margin-bottom-4">
        To create a line item, select a standard item and enter a make/ model/
        year
      </div>
      <StandardItemSelect
        options={options || []}
        onChange={(evt) => {
          const selectedStandardItemId = evt.target.value;
          const newSelectedStandardItem = solicitationStandardItems.find(
            (s) => s.id === selectedStandardItemId,
          );
          setSelectedStandardItem(newSelectedStandardItem);
        }}
        onBlur={() => {
          setDirtyFields((prev) => uniq([...prev, 'standardItem']));
        }}
        errorMessage={
          dirtyFields?.includes('standardItem') && standardItemError
            ? standardItemError
            : undefined
        }
      />

      <div className="options margin-top-4 padding-top-4 padding-bottom-4">
        <StandardItemMakeModelPanel standardItem={selectedStandardItem} />
      </div>
    </div>
  );
};

AddNonStandardLineExpress.propTypes = {
  contractHeader: PropTypes.shape(Object).isRequired,
};

export default AddNonStandardLineExpress;
