/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FilterTableFrame,
  AFPTable,
  EmptyState,
  AFPTableRowAction,
  Pagination,
  StatusBadge,
  useModal,
} from '@gsa/afp-component-library';
import TableFilters from './make-model-approval-filters';
import { ModelMakeStatuses } from '../constants';
import ApproveRejectConfirmationModal from './make-model-approve-reject-modal';
import MakeModelApprovalModalWrapper from './edit-make-model-approval-request';

const MakeModelTable = ({
  tableProps,
  paginationState,
  setPaginationState,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMakeModel, setSelectedMakeModel] = useState({});
  const [action, setAction] = useState(null);

  const {
    isOpen: isMakeModelOpen,
    openModal: openMakeModelModal,
    closeModal: closeMakeModelModal,
  } = useModal();

  const handleSelectedAction = (evt, row) => {
    setSelectedMakeModel(row.original);
    if (evt === 'Approve' || evt === 'Reject') {
      setAction(evt);
      setIsOpen(true);
    }
    if (evt === 'Edit') {
      openMakeModelModal();
    }
  };

  const closeMakeModelApprovalModel = () => {
    setIsOpen(false);
  };

  const columns = [
    {
      Header: 'Body Make',
      accessor: 'bodyMake.makeName',
    },
    {
      Header: 'Body Model',
      accessor: 'bodyModel.modelName',
    },
    {
      Header: 'Chassis Make',
      accessor: 'chassisMake.makeName',
    },
    {
      Header: 'Chassis Model',
      accessor: 'chassisModel.modelName',
    },
    {
      Header: 'Status',
      accessor: 'fleetApprovalStatus',
      sortable: false,
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => {
        let variant;
        switch (value) {
          case ModelMakeStatuses.Approved.value:
            variant = 'Ready-Gray';
            break;
          case ModelMakeStatuses.PendingReview.value:
            variant = 'Warning-Gray';
            break;
          case ModelMakeStatuses.Rejected.value:
            variant = 'Urgent-Gray';
            break;
          case ModelMakeStatuses.Archived.value:
            variant = 'Inactive-Gray';
            break;
          default:
            variant = 'Inactive-Gray';
        }

        return (
          variant && (
            <StatusBadge variant={variant}>
              {ModelMakeStatuses[value]?.label}
            </StatusBadge>
          )
        );
      },
    },
    {
      Header: 'Approved by',
      accessor: 'approvedByUser',
      sortable: false,
      Cell: ({ value }) => {
        return value === 'auto-sync' ? 'NHTSA' : 'GSA Engineer';
      },
    },
    {
      Header: 'Actions',
      sortable: false,
      minWidth: 30,
      width: 150,
      maxWidth: 400,
      Cell: (props) => {
        const {
          row: { original: fleetApprovalStatus },
        } = props;
        const actions = [
          {
            icon: 'check',
            label: 'Approve',
          },
          {
            icon: 'close',
            label: 'Reject',
          },
        ];
        if (fleetApprovalStatus !== ModelMakeStatuses.Archived.value) {
          actions.push({
            icon: 'edit',
            label: 'Edit',
          });
        }
        return (
          <AFPTableRowAction
            {...props}
            actions={actions}
            onSelectAction={(evt) => handleSelectedAction(evt, props.row)}
          />
        );
      },
    },
  ];

  const isNoData = !tableProps?.data?.length;

  return (
    <>
      <AFPTable data={tableProps?.data} columns={columns} {...tableProps} />
      {tableProps?.data.length > 0 && (
        <Pagination
          itemsPerPageOptions={[50, 100, 150, 200]}
          variant="advanced"
          onPageChange={(currentPage, itemsPerPage) => {
            const newState = {
              ...paginationState,
              currentPage,
              offset: (currentPage - 1) * itemsPerPage,
              limit: itemsPerPage,
            };
            setPaginationState(newState);
          }}
          itemsPerPage={paginationState.limit}
          currentPage={paginationState.currentPage}
          itemsCount={tableProps.count}
        />
      )}
      {isNoData && (
        <div className="text-center margin-top-neg-2 height-full">
          <EmptyState
            hasBackground
            containerStyles="padding-y-6 height-full"
            topText={<p>No data is available.</p>}
          />
        </div>
      )}

      {/* Modals */}

      {/* approval/rejection modal */}
      <ApproveRejectConfirmationModal
        isOpen={isOpen}
        data={selectedMakeModel}
        action={action}
        onClose={closeMakeModelApprovalModel}
        refetchData={tableProps?.refetchMakeModelApproval}
      />

      {/* edit approval request modal */}
      <MakeModelApprovalModalWrapper
        isOpen={isMakeModelOpen}
        onClose={closeMakeModelModal}
        data={selectedMakeModel}
        refetchData={tableProps?.refetchMakeModelApproval}
      />
    </>
  );
};

MakeModelTable.propTypes = {
  tableProps: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    count: PropTypes.number,
  }).isRequired,
  paginationState: PropTypes.shape({
    currentPage: PropTypes.number,
    offset: PropTypes.number,
    limit: PropTypes.number,
  }).isRequired,
  setPaginationState: PropTypes.func.isRequired,
};

export default FilterTableFrame(null, null, TableFilters, MakeModelTable);
