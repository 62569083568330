/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import AddStandardItems from './si-selection';
import { GET_BID_LINES, GET_MULTIPLE_OPTIONS_FOR_BID } from '../bids.gql';

const SelectStandardItemForBid = () => {
  const { bidId } = useParams();

  const { data: selectedBidLines, loading: isBidLineDataLoading } = useQuery(
    GET_BID_LINES,
    {
      fetchPolicy: 'network-only',
      variables: { bidId: +bidId },
      skip: !bidId,
    },
  );

  const { data: fvsPrograms } = useQuery(GET_MULTIPLE_OPTIONS_FOR_BID, {
    fetchPolicy: 'network-only',
    variables: {
      options: [
        {
          model: 'StandardsCodeModel',
          label: 'title',
          value: 'code',
          filter: {
            operator: 'EQ',
            key: 'category',
            value: 'Program',
          },
          uniqueKey: 'program',
          includeValueInLabel: true,
        },
      ],
    },
  });

  const uniqueArray =
    (!isBidLineDataLoading &&
      selectedBidLines &&
      Array.from(
        new Set(
          selectedBidLines?.getBidLines?.map((obj) => obj?.solicitationLineId),
        ),
      )?.map((id) => {
        return selectedBidLines.getBidLines?.find(
          (obj) => obj?.solicitationLineId === id,
        );
      })) ||
    [];

  const selectedStandardItems = [];
  uniqueArray?.length &&
    uniqueArray.forEach((element) => {
      selectedStandardItems.push({
        vehGrp: element?.standardItem?.vehicleGroup?.title,
        soliLineSi: `${element?.standardItem?.id}-${element?.solicitationLineId}`,
        scheduleLine: element?.scheduleLine
      });
    });

  return (
    <>
      <div className="grid-col">
        <AddStandardItems
          selectedBidLines={selectedStandardItems}
          fvsPrograms={fvsPrograms?.getMultipleOptions}
        />
      </div>
    </>
  );
};

export default SelectStandardItemForBid;
