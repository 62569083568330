import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { Button } from '@gsa/afp-component-library';
import { ACTION_PARAM_KEY, createURLSearchParam } from '../../../route-utils';

const DuplicateUpiidRenewalAlert = ({ solicitationObj }) => {
  const history = useHistory();

  return (
    <>
      <h3 className="usa-alert__heading margin-right-2">Error status</h3>

      <p className="margin-bottom-0">
        There is already a solicitation with the entered uPIID in the system and
        is eligible for renewal.
      </p>
      <p className="margin-top-0 margin-bottom-1">
        Select Renew to renew the{' '}
        <b> Solicitation {solicitationObj.solicitationNumber} </b> or create a
        new solicitation using a unique uPIID number.
      </p>

      <Button
        data-testid="solicitation-renew-btn"
        type="button"
        className="margin-top-2 margin-bottom-2"
        onClick={() => {
          history.push({
            pathname: `/catalog/solicitations/edit-solicitation/${solicitationObj.id}/upiid-details`,
            search: `?${createURLSearchParam({
              [ACTION_PARAM_KEY]: 'Renew',
            })}`,
            state: solicitationObj,
          });
        }}
        label="Renew"
      />
    </>
  );
};

DuplicateUpiidRenewalAlert.propTypes = {
  solicitationObj: PropTypes.shape({
    id: PropTypes.number,
    solicitationNumber: PropTypes.string,
  }).isRequired,
};

export default DuplicateUpiidRenewalAlert;
