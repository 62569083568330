import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import { useFederalStandards } from '../../../fvs-provider';

const {
  FilterPanelField,
  FilterPanelFilterShape,
  useFilterPanel,
} = FilterPanel;

const VehicleTypeFilterItem = ({ filter }) => {
  const { clearOneFilter } = useFilterPanel();

  const {
    vehicleClassificationOptions,
    selectedOptions,
  } = useFederalStandards();

  useEffect(() => {
    clearOneFilter(filter);
  }, [selectedOptions]);

  return (
    <>
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'select',
          options: vehicleClassificationOptions?.vehicleType
            ? vehicleClassificationOptions?.vehicleType?.map((vType) => ({
                label: vType.label,
                value: vType.value,
              }))
            : [],
          value: (selectedOptions && selectedOptions?.vehicleType) || '',
        }}
      />
    </>
  );
};

VehicleTypeFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default VehicleTypeFilterItem;
