import { atom, selector } from 'recoil';
import { getSolicitationFilters } from './filter-helpers';

const selectedProcess = atom({
  key: 'solicitationFilters/selectedProcess',
  default: [],
});
const selectedYear = atom({
  key: 'solicitationFilters/selectedYear',
  default: [],
});
const selectedAudience = atom({
  key: 'solicitationFilters/selectedAudience',
  default: [],
});
const selectedStatus = atom({
  key: 'solicitationFilters/selectedStatus',
  default: [],
});
const selectedPurchaseType = atom({
  key: 'solicitationFilters/selectedPurchaseType',
  default: [],
});
const selectedProgramName = atom({
  key: 'solicitationFilters/selectedProgramName',
  default: [],
});
const selectedSolicitationNumber = atom({
  key: 'solicitationFilters/selectedSolicitationNumber',
  default: [],
});
const selectedTitle = atom({
  key: 'solicitationFilters/selectedTitle',
  default: [],
});
const selectedStandardItem = atom({
  key: 'solicitationFilters/selectedStandardItem',
  default: [],
});

const filterSelector = selector({
  key: 'solicitationFilter/filterSelector',
  get: ({ get }) => {
    return getSolicitationFilters({
      process: get(selectedProcess),
      year: get(selectedYear),
      audience: get(selectedAudience),
      status: get(selectedStatus),
      purchaseType: get(selectedPurchaseType),
      programName: get(selectedProgramName),
      solicitationNumber: get(selectedSolicitationNumber),
      title: get(selectedTitle),
      standardItem: get(selectedStandardItem),
    });
  },
});

export {
  selectedProcess,
  selectedYear,
  selectedAudience,
  selectedStatus,
  selectedPurchaseType,
  selectedProgramName,
  selectedSolicitationNumber,
  selectedTitle,
  selectedStandardItem,
  filterSelector,
};
