export const MODAL_MODES = {
  ADD: 'add',
  EDIT: 'edit',
  CONFIRM_DELETE: 'confirm_delete',
  VIEW_ASSOCIATION: 'view_associations',
};

export const SI_WELCOME_TEXT =
  'Standard Item Numbers are the way in which GSA Fleet categorizes its vehicles within the Standard Order Program (SOP). You may use the Standard Item table to filter and update Standard Items. You may also view Equipment Codes associated with Standard Items using the Associated EC link.';

export const SI_LABELS = {
  VIEW: 'View Standard Item',
  MANAGE: 'Manage Equipment Codes',
  DELETE: 'Delete Standard Item',
  EDIT: 'Edit Standard Item',
  ADD: 'Add Standard Item',
  NUMBER: 'Standard Item',
  TYPE: 'Vehicle type',
  STATUS: 'Status',
  TITLE: 'Title',
  ASSOCIATED_VIEW: 'Associated Equipment Codes',
  YEAR: 'Contract year',
  FED_CODE: 'Fed. Std. Code',
  TAGS: 'Tags',
  FASTCODE: 'FAST Code',
};
