export const YES = 'Y';
export const NO = 'N';
export const NO_VAL = '';
const EMPTY_OPTION = { label: '-Select-', value: NO_VAL };
export const EVAL_AWARD_OPTIONS = [
  EMPTY_OPTION,
  { label: 'Yes', value: YES },
  { label: 'No', value: NO },
];
export const EVAL_STATUS = {
  Ready: 'Ready for review',
  InReview: 'In review',
  Recommend: 'Recommended',
  NotRecommend: 'Not recommended',
  Awarded: 'Awarded',
};
export const EVAL_FILTER_OPTIONS = {
  standardItem: [EMPTY_OPTION],
  vehicleType: [EMPTY_OPTION],
  body: [EMPTY_OPTION],
  status: [EMPTY_OPTION],
};
export const EVAL_FILTERS = {
  standardItem: '',
  vehicleType: '',
  body: '',
  status: '',
};

const bool2str = (b) => {
  if (b === true) return YES;
  if (b === false) return NO;
  return NO_VAL;
};
const str2bool = (s) => {
  if (s === YES) return true;
  if (s === NO) return false;
  return null;
};

export const getEvalStatus = (ev) => {
  if (!ev.evalId) return EVAL_STATUS.Ready;
  if (ev.awarded) return EVAL_STATUS.Awarded;
  if (ev.techEvalCompleted && ev.priceEvalCompleted) {
    if (ev.techEvalAward === YES && ev.priceEvalAward === YES)
      return EVAL_STATUS.Recommend;
    if (ev.techEvalAward === NO || ev.priceEvalAward === NO)
      return EVAL_STATUS.NotRecommend;
  }
  return EVAL_STATUS.InReview;
};

export const set2options = (s) => [
  EMPTY_OPTION,
  ...[...s].sort().map((i) => ({ label: i, value: i })),
];

const isBidEvaluable = (bid) => {
  const now = new Date();
  if (bid.soliciation.contractYear < now.getFullYear()) return false;
  const solPeriods = bid.soliciation.solicitationPeriods
    .filter((p) => p.endDate < now.toISOString())
    .sort((a, b) => (a.startDate > b.startDate ? -1 : 1));
  if (!solPeriods.length) return false;
  return bid.solicitationPeriodId === +solPeriods[0].id;
};

export const processBid = (bid) => {
  const info = {
    solicitationNumber: bid.soliciation.solicitationNumber,
    vendorName: bid.vendorDetail.vendorName,
    vendorUEI: bid.vendorDetail.uniqueEntityIdentifier,
  };
  const lines = [];
  const rows = [];
  const siSet = new Set();
  const vtSet = new Set();
  const bodySet = new Set();

  bid.BidLines.forEach((bl) => {
    const evalObj = {
      evalId: bl.evaluation?.id,
      techEvalCompleted: !!bl.evaluation?.techEvalCompleted,
      techEvalAward: bool2str(bl.evaluation?.techEvalAward),
      priceEvalCompleted: !!bl.evaluation?.priceEvalCompleted,
      priceEvalAward: bool2str(bl.evaluation?.priceEvalAward),
      awarded: !!bl.evaluation?.awarded,
    };
    lines.push({
      bidLineId: +bl.id,
      ...evalObj,
      status: getEvalStatus(evalObj),
    });

    const rowObj = {
      id: bl.id,
      bidLineId: +bl.id,
      bidId: bl.bidId,
      solId: bid.solicitationId,
      solNumber: bid.soliciation.solicitationNumber,
      vendorName: bid.vendorDetail.vendorName,
      scheduleLine: bl.scheduleLine,
      body: `${bl.afpMake.makeName} ${bl.afpModel.modelName}`,
      chassis: bl.chassisMake
        ? `${bl.chassisMake?.makeName} ${bl.chassisModel.modelName}`
        : '',
      standardItemId: bl.standardItem.id,
      standardItem: `${bl.standardItem.standardItemNumber} - ${bl.standardItem.title}`,
      vehicleType: bl.standardItem.vehicleTypeCode.title,
      selectDisabled: true,
    };
    rows.push(rowObj);

    siSet.add(rowObj.standardItem);
    vtSet.add(rowObj.vehicleType);
    bodySet.add(rowObj.body);
  });

  const options = {
    standardItem: set2options(siSet),
    vehicleType: set2options(vtSet),
    body: set2options(bodySet),
    status: set2options(Object.values(EVAL_STATUS)),
  };

  const evaluable = isBidEvaluable(bid);

  return { info, lines, rows, options, evaluable };
};

export const getItemByBidLineId = (list, bidLineId) => {
  return list.find((i) => i.bidLineId === bidLineId);
};

export const getFilteredRows = (lines, rows, filter) => {
  return rows.filter((row) => {
    return Object.keys(filter).every((key) => {
      const obj =
        key === 'status' ? getItemByBidLineId(lines, row.bidLineId) : row;
      return filter[key] === '' || filter[key] === obj[key];
    });
  });
};

export const getTableData = (rows, offset, limit) => {
  return rows.slice(offset, offset + limit).reverse();
};

export const prepAutoSaveFields = (update) => {
  const fields = { ...update };
  if (update.techEvalAward !== undefined)
    fields.techEvalAward = str2bool(update.techEvalAward);
  if (update.priceEvalAward !== undefined)
    fields.priceEvalAward = str2bool(update.priceEvalAward);
  return fields;
};
