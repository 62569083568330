import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import {
  Button,
  connectModal,
  Modal,
  useModal,
  ResetButton,
} from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { DELETE_COMMENT } from '../../services/fragments/tasks';
import { commentsContext } from './CommentsContextProvider';
import { CommentPropType } from '../../utilities/types';

const DeleteModal = ({ closeModal, pending, onSubmit }) => (
  <Modal
    id="delete-comment-modal"
    title={<h2>Delete Comment</h2>}
    onClose={closeModal}
    actions={
      <>
        <Button
          type="button"
          variant="outline"
          onClick={closeModal}
          label="Cancel"
        />
        <Button
          disabled={pending}
          type="submit"
          onClick={onSubmit}
          variant="secondary"
          label={pending ? 'Deleting...' : 'Delete'}
        />
      </>
    }
  >
    Are you sure you want to delete this comment?
  </Modal>
);

DeleteModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pending: PropTypes.bool.isRequired,
};

export default function DeleteCommentButton({ comment, allowChanges }) {
  const { currentUser } = useCurrentUser();
  const { isOpen, openModal, closeModal } = useModal();
  if (currentUser.email !== comment.createdByUser) {
    return null;
  }

  const { refetchComments } = useContext(commentsContext);
  const [deleteComment, { data, loading }] = useMutation(DELETE_COMMENT, {
    variables: {
      id: parseFloat(comment.id),
    },
  });

  useEffect(() => {
    if (data && data.deleteComment) {
      refetchComments();
      closeModal();
    }
  }, [data]);

  const ConnectedAddModal = connectModal(() => (
    <DeleteModal
      closeModal={closeModal}
      pending={loading}
      onSubmit={deleteComment}
    />
  ));

  return (
    <>
      <ConnectedAddModal isOpen={isOpen} closeModal={closeModal} />
      {allowChanges ? (
        <ResetButton onClick={openModal}>Delete Comment</ResetButton>
      ) : null}
    </>
  );
}

DeleteCommentButton.defaultProps = {
  allowChanges: true,
};

DeleteCommentButton.propTypes = {
  comment: PropTypes.shape(CommentPropType).isRequired,
  allowChanges: PropTypes.bool,
};
