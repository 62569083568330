import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '@gsa/afp-component-library';

import { useContractLine } from '../provider/contract-line-provider';

const DeleteColorModal = ({
  color: { vendorColorCode, vendorColorName },
  onClose,
}) => {
  const { setColorsTabData } = useContractLine();
  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          id="remove-standard-item-modal"
          variant="medium"
          title={<h3>Delete confirmation</h3>}
          onClose={() => onClose()}
          actions={
            <div>
              <Button
                data-testid="bid-si-removal-modal-cancel-btn"
                variant="unstyled"
                onClick={() => onClose()}
                label="Cancel"
              />
              <Button
                variant="secondary"
                onClick={() => {
                  setColorsTabData((prevColors) =>
                    prevColors.filter(
                      (c) => c.vendorColorCode !== vendorColorCode,
                    ),
                  );
                  onClose();
                }}
                label="Delete"
              />
            </div>
          }
        >
          <div className="margin-top-2 margin-bottom-8">
            Are you sure you want to delete <b>color {vendorColorName}</b>?
          </div>
        </Modal>
      </div>
    </div>
  );
};

DeleteColorModal.propTypes = {
  color: PropTypes.shape(
    PropTypes.objectOf({
      id: PropTypes.number.isRequired,
      vendorColorName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeleteColorModal;
