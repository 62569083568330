import React, { useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import { EQUIPMENT_CODES_TYPEAHEAD_OPTIONS_SEARCH } from '../../../../services/data-store';
import { useFederalStandards } from '../../fvs-provider';
import { useFvsRequirements } from '../requirements-provider';
import { getVehicleClassificationHierarchy } from '../../../../utilities/options-helper';
import { getFilterStructure } from './requirement-filters/requirement-filter-structure';

const RequirementsFilter = ({ setFilters }) => {
  const {
    vehicleClassificationOptions,
    applicableFvsYears,
    selectedOptions,
    selectedYear,
    setFvsContextData,
    standardItemsAsOptions,
  } = useFederalStandards();

  const { FilterPanel: RequirementsFilterPanel } = FilterPanel;

  const { lookupCodes, setFvsRequirementsContextData } = useFvsRequirements();
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { standardItemNumber, year, fedCode } = params;
  const [defaultFilters] = useState({
    // Set default filters here
    year,
    vehicleGroup: selectedOptions?.vehicleGroup,
    standardItem: `${standardItemNumber}`,
    vehicleType: selectedOptions?.vehicleType,
  });
  const [typeaheadData, setTypeaheadData] = useState(null);
  const [searchKey, setSearchKey] = useState(null);

  const [getTypeAheadOptions] = useLazyQuery(
    EQUIPMENT_CODES_TYPEAHEAD_OPTIONS_SEARCH,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ getEquipmentCodesTypeAheadOptions }) => {
        if (getEquipmentCodesTypeAheadOptions) {
          const { options } = getEquipmentCodesTypeAheadOptions;
          // return unique options only.
          const uniqueOptions = Array.from(new Set(options));
          setTypeaheadData({
            field: searchKey,
            values: uniqueOptions,
          });
        }
      },
    },
  );

  const removeReqFilterTitlePrefix = (f) =>
    f.key?.includes('req_') ? f.key.split('_')[1] : f.key;

  const handleFiltersChange = (updatedFilters) => {
    const filters = {
      operator: 'AND',
      value: [],
    };

    const filterByKeys = updatedFilters
      ?.map((f) => ({ ...f, key: removeReqFilterTitlePrefix(f) }))
      ?.reduce((filterObj, currentFilterObj) => {
        return { ...filterObj, [currentFilterObj?.key]: currentFilterObj };
      }, {});

    // eslint-disable-next-line
    let state = {
      year: Number.parseInt(filterByKeys?.year?.value, 10),
      standardItem: filterByKeys?.standardItem?.value,
      vehicleGroup: '',
      vehicleType: '',
    };

    if (filterByKeys?.year?.value !== selectedOptions?.year) {
      setFvsContextData?.(
        'SET_SELECTED_YEAR',
        Number.parseInt(filterByKeys?.year.value, 10),
      );
      history.push(
        `/catalog/vehicle-standards/requirements/${filterByKeys?.year.value}/${fedCode}/${standardItemNumber}${location?.search}`,
      );
      setFvsContextData?.('SET_SELECTED_STANDARD_ITEM_OBJ', null);
      setFvsRequirementsContextData('SET_EQUIPMENT_ASSOCIATION_LIST', {
        rows: [],
      });
    }

    if (
      filterByKeys.vehicleType &&
      filterByKeys.vehicleType !== selectedOptions?.vehicleType
    ) {
      state.standardItem = filterByKeys?.standardItem?.value;
      state.vehicleGroup = filterByKeys?.vehicleGroup?.value;
      state.vehicleType = filterByKeys?.vehicleType?.value;
      const classification = getVehicleClassificationHierarchy(
        vehicleClassificationOptions,
        state,
        selectedOptions,
      );
      /* eslint-disable no-param-reassign */
      state.vehicleGroup = classification?.vehicleGroup;
      setFvsRequirementsContextData('SET_EQUIPMENT_ASSOCIATION_LIST', {
        rows: [],
      });
      setFvsContextData('SET_SELECTED_OPTIONS', {
        fedStandardCode: classification?.fedStandardCode,
        vehicleGroup: classification?.vehicleGroup,
        vehicleType: classification?.vehicleType,
      });
    } else if (
      filterByKeys.vehicleGroup &&
      filterByKeys.vehicleGroup !== selectedOptions?.vehicleGroup
    ) {
      state.standardItem = filterByKeys?.standardItem?.value;
      state.vehicleGroup = filterByKeys?.vehicleGroup?.value;
      state.vehicleType = '';
      const classification = getVehicleClassificationHierarchy(
        vehicleClassificationOptions,
        state,
        selectedOptions,
      );
      /* eslint-disable no-param-reassign */
      state.vehicleType = '';
      setFvsRequirementsContextData('SET_EQUIPMENT_ASSOCIATION_LIST', {
        rows: [],
      });
      setFvsContextData('SET_SELECTED_OPTIONS', {
        fedStandardCode: classification?.fedStandardCode,
        vehicleGroup: classification?.vehicleGroup,
        vehicleType: '',
      });
    }

    if (filterByKeys.associationType) {
      filters.value.push({
        key: 'associationTypeCode',
        operator: 'EQ',
        value: filterByKeys.associationType?.value,
      });
    }

    if (filterByKeys.category) {
      filters.value.push({
        key: '$equipmentCode.category$',
        operator: 'EQ',
        value: filterByKeys.category?.value,
      });
    }

    if (filterByKeys.title) {
      filters.value.push({
        key: '$equipmentCode.title$',
        operator: 'EQ',
        value: filterByKeys.title?.value,
      });
    }

    if (filterByKeys.code) {
      filters.value.push({
        key: '$equipmentCode.code$',
        operator: 'EQ',
        value: filterByKeys.code?.value,
      });
    }

    Object.keys(state).forEach((key) => {
      const value = state[key];

      if (key === 'year') {
        filters.value.push({
          key: '$standardItem.year$',
          operator: 'EQ',
          value,
        });
      } else if (key === 'standardItem') {
        setFvsContextData?.('SET_SELECTED_STANDARD_ITEM', value);
        filters.value.push({
          key: '$standardItem.standard_item_number$',
          operator: 'EQ',
          value,
        });
      } else if (
        key === 'vehicleType' ||
        key === 'vehicleGroup' ||
        key === 'fedStandardCode'
      ) {
        // do nothing. Handled below
      } else {
        // other filters
        filters.value.push({ key, operator: 'EQ', value });
      }
    });

    filters.value.push(
      {
        key: '$equipmentCode.is_active$',
        operator: 'EQ',
        value: 1,
      },
      {
        key: '$equipmentCode.deleted_at$',
        operator: 'EQ',
        value: null,
      },
    );

    setTypeaheadData(null);
    setFilters(filters);
  };

  const handleFetchTypeaheads = ({ variables: queryVariables }) => {
    const selectedKey = queryVariables?.field;
    const filterObj = queryVariables?.filters[0]?.conditions?.find(
      (filter) => filter.key === selectedKey,
    );

    setSearchKey(selectedKey);
    getTypeAheadOptions({
      variables: {
        key: selectedKey,
        search: filterObj.value,
        filters: [
          {
            operator: '$and',
            conditions: [
              { operator: '$exact', key: 'year', value: `${selectedYear}` },
            ],
          },
        ],
      },
    });
  };

  return (
    <>
      <RequirementsFilterPanel
        clearButtonLabel="Reset All"
        filterStructure={getFilterStructure(
          applicableFvsYears,
          defaultFilters,
          [{ label: 'Select', value: '' }, ...standardItemsAsOptions],
          lookupCodes,
        )}
        model="standardItem"
        order="standardItemNumber ASC"
        setQueryFiltersState={handleFiltersChange}
        fetchTypeaheads={handleFetchTypeaheads}
        typeaheadData={typeaheadData}
        showClearIcon
        retainFilters={false}
      />
    </>
  );
};

RequirementsFilter.defaultProps = {};

RequirementsFilter.propTypes = {
  setFilters: PropTypes.func.isRequired,
};

export default RequirementsFilter;
