import React, { createContext, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as resolvers from '../../../services/data-store';
import { useFederalStandards } from '../fvs-provider';
import groupMultipleOptions from '../../../utilities/options-helper';
import { GET_EQUIPMENT_ASSOCIATIONS_WITH_COMMENTS } from './query.gql';

export const FvsRequirementsContext = createContext({});

const initialState = {
  lookupCodesUngrouped: [],
  lookupCodes: {},
  doHighlightChanges: false,
  rtModalMode: null,
  rtShowFormModal: false,
  equipmentAssociations: [],
  equipmentAssociation: null,
  selectedComment: null,
  isDeletingComment: false,
};

const options = [
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: {
      operator: 'EQ',
      key: 'category',
      value: 'Equipment Association Type',
    },
    uniqueKey: 'associationType',
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    limit: 1000,
    filter: {
      operator: 'EQ',
      key: 'category',
      value: 'Equipment Category',
    },
    uniqueKey: 'category',
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: {
      operator: 'EQ',
      key: 'category',
      value: 'Equipment Input Type',
    },
    uniqueKey: 'inputType',
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: {
      operator: 'EQ',
      key: 'category',
      value: 'Numerical Input Type',
    },
    uniqueKey: 'criteria',
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: { operator: 'EQ', key: 'category', value: 'Units' },
    uniqueKey: 'units',
  },
];

const FvsRequirementsReducer = (state, action) => {
  switch (action.type) {
    case 'FVS_REQ_LOOKUP_CODES':
      return {
        ...state,
        lookupCodes: action.payload?.lookupCodes,
        lookupCodesUngrouped: action.payload?.lookupCodesUngrouped,
      };
    case 'SET_HIGHLIGHT_CHANGES':
      return { ...state, doHighlightChanges: action.payload };
    case 'SET_RT_MODAL_MODE':
      if (action.payload) {
        return { ...state, rtModalMode: action.payload, rtShowFormModal: true };
      }
      return { ...state, rtModalMode: action.payload, rtShowFormModal: false };
    case 'SET_EQUIPMENT_ASSOCIATION_LIST':
      return { ...state, equipmentAssociations: action.payload };
    case 'SET_EQUIPMENT_ASSOCIATION':
      return { ...state, equipmentAssociation: action.payload };
    case 'SET_SELECTED_COMMENT':
      return { ...state, selectedComment: action.payload };
    case 'SET_IS_DELETING_COMMENT': {
      return { ...state, isDeletingComment: action.payload };
    }
    default:
      return state;
  }
};

function FvsRequirementsProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(
    FvsRequirementsReducer,
    initialState,
    () => {
      return initialState;
    },
  );

  const { setFvsError, addOrReplaceFvsMessageById } = useFederalStandards();

  const setFvsRequirementsContextData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const [getFvsReqLookupCodes] = useLazyQuery(resolvers.GET_MULTIPLE_OPTIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (requestError) => {
      setFvsError(requestError);
    },
    onCompleted: (multipleOptionsData) => {
      if (multipleOptionsData?.getMultipleOptions) {
        const grouped = groupMultipleOptions(
          multipleOptionsData?.getMultipleOptions,
        );
        grouped?.category?.unshift({ label: '', value: '' });
        const { year, ...others } = grouped;
        setFvsRequirementsContextData('FVS_REQ_LOOKUP_CODES', {
          lookupCodesUngrouped: multipleOptionsData.getMultipleOptions,
          lookupCodes: others,
        });
      }
    },
  });

  const [getEquipmentAssociations, { refetch }] = useLazyQuery(
    GET_EQUIPMENT_ASSOCIATIONS_WITH_COMMENTS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (requestError) => {
        setFvsError(requestError);
      },
      onCompleted: (responseData) => {
        if (responseData?.getEquipmentAssociations) {
          setFvsRequirementsContextData(
            'SET_EQUIPMENT_ASSOCIATION_LIST',
            responseData.getEquipmentAssociations,
          );
        }
      },
    },
  );

  const [getEquipmentAssociationsWithChanges] = useLazyQuery(
    resolvers.GET_PUBLIC_EQUIPMENT_ASSOCIATIONS_WITH_CHANGES,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (requestError) => {
        setFvsError(requestError);
      },
      onCompleted: (responseData) => {
        if (responseData?.getEquipmentAssociations) {
          setFvsRequirementsContextData(
            'SET_EQUIPMENT_ASSOCIATION_LIST',
            responseData.getEquipmentAssociations,
          );
        }
      },
    },
  );

  // Update Association
  const [updateEquipmentAssociation] = useMutation(
    resolvers.UPDATE_EQUIPMENT_ASSOCIATION,
    {
      onError: (requestError) => {
        setFvsError(requestError);
      },
      onCompleted: () => {
        const message = {
          id: 'FVS_REQUIREMENTS',
          message: `You have successfully updated the equipment code association.`,
          type: 'success',
          closeable: true,
        };
        addOrReplaceFvsMessageById(message);
        setFvsRequirementsContextData('SET_RT_MODAL_MODE', null);
        refetch();
        setFvsRequirementsContextData('SET_EQUIPMENT_ASSOCIATION', null);
      },
    },
  );

  // Delete Association
  const [deleteEquipmentAssociation] = useMutation(
    resolvers.DELETE_EQUIPMENT_ASSOCIATION,
    {
      onError: (requestError) => {
        setFvsError(requestError);
      },
      onCompleted: () => {
        const message = {
          id: 'FVS_REQUIREMENTS',
          message: `You have successfully disassociated the equipment code.`,
          type: 'success',
          closeable: true,
        };
        addOrReplaceFvsMessageById(message);
        setFvsRequirementsContextData('SET_RT_MODAL_MODE', null);
        refetch();
        setFvsRequirementsContextData('SET_EQUIPMENT_ASSOCIATION', null);
      },
    },
  );

  useEffect(() => {
    getFvsReqLookupCodes({
      variables: {
        options,
      },
    });
  }, []);

  return (
    <FvsRequirementsContext.Provider
      value={{
        ...state,
        setFvsRequirementsContextData,
        getEquipmentAssociations,
        getEquipmentAssociationsWithChanges,
        updateEquipmentAssociation,
        deleteEquipmentAssociation,
        ...props,
      }}
    >
      {children}
    </FvsRequirementsContext.Provider>
  );
}

export default FvsRequirementsProvider;

FvsRequirementsProvider.defaultProps = {};

FvsRequirementsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useFvsRequirements = () => useContext(FvsRequirementsContext);
