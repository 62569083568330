import React from 'react';
import PropTypes from 'prop-types';
import { emDashUnicode } from '../../../utilities/constants';

const Programs = ({ programs, noProgramsMsg }) => (
  <>
    <div className="text-bold text-ink">Programs</div>
    {programs?.length ? (
      <ul>
        {programs?.map((aProgram) => (
          <li key={aProgram?.programInfo?.code}>
            {`${aProgram?.program || ''} - ${
              aProgram?.programInfo?.title || ''
            }`}
          </li>
        ))}
      </ul>
    ) : (
      <p>{noProgramsMsg}</p>
    )}
  </>
);

Programs.propTypes = {
  programs: PropTypes.arrayOf().isRequired,
  noProgramsMsg: PropTypes.string,
};
Programs.defaultProps = {
  noProgramsMsg: emDashUnicode,
};

export default Programs;
