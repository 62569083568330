/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import useLocalStorage from '../../hooks/use-local-storage';
import { useEquipmentCodes } from './equipment-codes-provider';
import { getFilterStructure } from './util/equipment-codes-filter-structure';

const filterKeys = ['tags', 'status', 'quantityRequired'];

const mapQuantityRequiredCode = {
  'Not Applicable': 'N',
  Order: 'O',
  Vehicle: 'V',
};

const EquipmentCodeFilters = ({ currentFilters, setFilters }) => {
  const {
    setData,
    groupMultipleOptions,
    selectedProgramFilter,
    getTypeAheadOptions,
    selectedYearFilter,
    typeaheadData,
  } = useEquipmentCodes();

  const { FilterPanel: ECComponentFilterPanel } = FilterPanel;
  const [rerenderKey, setRerenderKey] = useState(1);

  const [equipmentCodesFilter, setEquipmentCodesFilter] = useLocalStorage(
    'EQUIPMENT_CODES_FILTER',
    null,
  );

  const removeECPrefix = (f) =>
    f.key?.includes('ec_') ? f.key.split('_')[1] : f.key;

  const resetToDefaultFilters = () => {
    setEquipmentCodesFilter('EQUIPMENT_CODES_FILTER', null);
    setFilters({
      operator: 'AND',
      value: [
        {
          key: 'deletedAt',
          operator: 'EQ',
          value: null,
        },
        {
          key: 'isActive',
          operator: 'EQ',
          value: 1,
        },
        {
          key: 'year',
          operator: 'EQ',
          value: `${new Date().getFullYear()}`,
        },
      ],
    });
    setRerenderKey(rerenderKey + 1);
  };

  const handleFiltersChange = (updatedFilters) => {
    const filterByKeys = updatedFilters
      ?.map((f) => ({ ...f, key: removeECPrefix(f) }))
      ?.reduce((filterObj, currentFilterObj) => {
        return { ...filterObj, [currentFilterObj?.key]: currentFilterObj };
      }, {});

    const newFilter = {
      ...currentFilters,
      value: [
        ...updatedFilters
          ?.map((f) => ({ ...f, key: removeECPrefix(f) }))
          .filter((f) => !filterKeys.includes(f.key)),
      ],
    };

    if (filterByKeys?.year) {
      setData('SET_SELECTED_FILTER_YEAR', filterByKeys?.year.value);
    }
    if (filterByKeys?.program) {
      setData('SET_SELECTED_FILTER_PROGRAM', filterByKeys?.program.value);
    }

    if (filterByKeys?.status?.value.length === 1) {
      const statusFilter = {
        operator: filterByKeys?.status?.value[0] === 'Active' ? 'AND' : 'OR',
        value: [],
      };

      filterByKeys?.status?.value.forEach((s) =>
        statusFilter.value.push(
          {
            key: 'deletedAt',
            operator: s === 'Active' ? 'EQ' : 'NOT',
            value: null,
          },
          {
            key: 'isActive',
            operator: 'EQ',
            value: s === 'Active' ? 1 : 0,
          },
        ),
      );
      newFilter.value.push(statusFilter);
    }

    if (filterByKeys?.quantityRequired) {
      const quantityRequiredFilter = {
        operator: 'OR',
        value: [],
      };
      filterByKeys?.quantityRequired?.value?.forEach((v) => {
        quantityRequiredFilter.value.push({
          operator: 'EQ',
          key: 'quantityRequired',
          value: mapQuantityRequiredCode[v],
        });
      });
      newFilter.value.push(quantityRequiredFilter);
    }

    if (filterByKeys?.tags) {
      newFilter.value.push({
        key: 'EquipmentCode.tags',
        operator: 'JSON_CONTAINS',
        value: { value: filterByKeys.tags?.value },
      });
    }

    let selectedEquipmentCodeFilterValues = newFilter.value.reduce(
      (reducedStdFilter, currentFilter) => {
        if (currentFilter?.key === 'EquipmentCode.tags') {
          return { ...reducedStdFilter, tags: currentFilter.value.value };
        }
        return {
          ...reducedStdFilter,
          [currentFilter.key]: currentFilter.value,
        };
      },
      {},
    );

    if (filterByKeys?.status) {
      selectedEquipmentCodeFilterValues = {
        ...selectedEquipmentCodeFilterValues,
        status: filterByKeys?.status.value,
      };
    }

    setData('SET_TYPEAHEAD_FILTER_DATA', null);
    setEquipmentCodesFilter(selectedEquipmentCodeFilterValues);
    setFilters(newFilter);
  };

  const handleFetchTypeaheads = ({ variables: queryVariables }) => {
    const selectedKey = queryVariables?.field;
    const filterObj = queryVariables?.filters[0]?.conditions?.find(
      (filter) => filter.key === selectedKey,
    );

    const key = selectedKey?.includes('ec_')
      ? selectedKey.split('_')[1]
      : selectedKey;
    const filters = [
      {
        operator: '$and',
        conditions: [
          { operator: '$exact', key: 'year', value: selectedYearFilter },
        ],
      },
    ];
    if (selectedProgramFilter) {
      filters[0].conditions.push({
        operator: '$exact',
        key: 'program',
        value: selectedProgramFilter,
      });
    }

    getTypeAheadOptions({
      variables: {
        key,
        search: filterObj.value,
        filters,
      },
    });
  };

  return (
    <ECComponentFilterPanel
      key={rerenderKey}
      clearButtonLabel="Reset All"
      filterStructure={getFilterStructure(
        groupMultipleOptions,
        equipmentCodesFilter,
      )}
      model="equipmentCode"
      order="equipmentCode ASC"
      setQueryFiltersState={handleFiltersChange}
      fetchTypeaheads={handleFetchTypeaheads}
      typeaheadData={typeaheadData}
      showClearIcon
      handleClearAll={resetToDefaultFilters}
      retainFilters={false}
    />
  );
};

EquipmentCodeFilters.propTypes = {
  currentFilters: PropTypes.arrayOf(
    PropTypes.shape({
      operator: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
      ]),
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default EquipmentCodeFilters;
