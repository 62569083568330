import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Spinner,
  Label,
  PageTitle,
  SelectDropdown,
  TextInput,
  Icon,
  connectModal,
  useModal,
  Link,
  NotFound,
} from '@gsa/afp-component-library';
import { Controller, useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';

import './contract-header.scss';

import {
  GET_CONTRACT_HEADER_BY_ID,
  GET_VENDOR_DETAIL_BY_ID,
  UNPUBLISH_CONTRACT_HEADER,
  UPDATE_CONTRACT_HEADER,
} from './contract-header.gql';
import ContractHeaderMaximumValueModal from './contract-header-maximum-value-modal';
import contractHeaderSchema from './contract-header-schema';
import ContractHeaderActions from './contract-header-actions';
import ContractHeaderUnsavedModal from './contract-header-unsaved-modal';
import {
  emDashUnicode,
  OPERATIONS,
  SUBJECTS,
} from '../../../utilities/constants';
import ContractDetails from '../components/contract-details';
import ToastMessage from '../../../components/Toast/toast';
import ContractBreadcrumbs, { BREADCRUMBS } from '../contract-breadcrumbs';
import { SOLICITATION_NUMBERS } from '../contract-constants';

// TODO: Refactor contract-header-history-version.js to use this component
const StandardFieldset = ({ label, children, name, className = '' }) => {
  return (
    <fieldset className={`usa-fieldset usa-fieldset ${className}`} name={name}>
      {label && (
        <div className="text-primary text-bold text-uppercase border-bottom border-base-lighter padding-bottom-1">
          {label}
        </div>
      )}
      {children}
    </fieldset>
  );
};
StandardFieldset.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
StandardFieldset.defaultProps = {
  label: undefined,
  className: '',
};

const StandardFieldsetRow = ({ children, className = '' }) => {
  return (
    <div className={`grid-row margin-bottom-4 ${className}`}>{children}</div>
  );
};

StandardFieldsetRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

StandardFieldsetRow.defaultProps = {
  children: null,
  className: '',
};

const StandardFieldsetRowCol = ({ colWidth, children, label, data }) => {
  return (
    <div className={`col grid-col-${colWidth}`} aria-label={label}>
      {label && <Label>{label}</Label>}
      {data && <span>{data}</span>}
      {children}
    </div>
  );
};

StandardFieldsetRowCol.propTypes = {
  colWidth: PropTypes.number,
  children: PropTypes.node,
  label: PropTypes.string,
  data: PropTypes.node,
};

StandardFieldsetRowCol.defaultProps = {
  colWidth: 1,
  children: null,
  label: null,
  data: null,
};

const ReadOnlyWrapper = ({ children, readOnly }) => {
  const valuesOrEmDash = (...values) => {
    return values.find((value) => value) || emDashUnicode;
  };

  let extractedProps = {};
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      extractedProps = { extractedProps, ...child.props };
    }
  });

  const { defaultValue } = extractedProps;

  if (readOnly) {
    return valuesOrEmDash(defaultValue);
  }

  return children;
};

const ContractHeader = () => {
  const [contractHeader, setContractHeader] = useState({});
  const [isMaxDisabled, setIsMaxDisabled] = useState(false);
  const [isPublishedInFleetDisabled, setIsPublishedInFleetDisabled] = useState(
    false,
  );
  const [getVendorDetailByIdData, setGetVendorDetailByIdData] = useState(null);
  const [alert, setAlert] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const history = useHistory();
  const { contractHeaderId } = useParams();
  const locationRef = useRef(null);
  const unblockRef = useRef(null);

  const {
    isOpen: maximumValueModalIsOpen,
    openModal: maximumValueModalOpenModal,
    closeModal: maximumValueModalCloseModal,
  } = useModal();
  const {
    isOpen: unsavedModalIsOpen,
    openModal: unsavedModalOpenModal,
    closeModal: unsavedModalCloseModal,
  } = useModal();

  const ability = useAppAbility();
  const canUpdateContract = ability.can(OPERATIONS.Update, SUBJECTS.Contract);

  const ConnectedContractHeaderMaximumValueModal = connectModal(
    ContractHeaderMaximumValueModal,
  );

  const ConnectedContractHeaderUnsavedModal = connectModal(
    ContractHeaderUnsavedModal,
  );

  const { handleSubmit, control, reset, errors, formState } = useForm({
    resolver: yupResolver(contractHeaderSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {},
  });

  const [getVendorDetailById] = useLazyQuery(GET_VENDOR_DETAIL_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data?.getVendorDetailById) {
        return;
      }

      setGetVendorDetailByIdData(data.getVendorDetailById);
    },
  });

  const { loading } = useQuery(GET_CONTRACT_HEADER_BY_ID, {
    fetchPolicy: 'network-only',
    variables: {
      contractHeaderId,
    },
    onCompleted: (data) => {
      if (!data?.getContractHeaderById) {
        return;
      }

      setContractHeader(data.getContractHeaderById);
      setReadOnly(
        // NOTE: Disable medium headvy Legacy
        // https://cm-jira.usa.gov/browse/AFP-144880
        data.getContractHeaderById?.solicitation?.solicitationNumber ===
          SOLICITATION_NUMBERS.MEDIUM_HEAVY,
      );
      reset({
        pocName: data.getContractHeaderById.pocName,
        pocEmail: data.getContractHeaderById.pocEmail,
        pocPhone: data.getContractHeaderById.pocPhone,
        publishedInFleet: data.getContractHeaderById.publishedInFleet,
        dollarValueMaximumOrder:
          data.getContractHeaderById.dollarValueMaximumOrder,
      });
      if (data.getContractHeaderById.dollarValueMaximumOrder !== null) {
        setIsMaxDisabled(true);
      }

      getVendorDetailById({
        variables: {
          id: data.getContractHeaderById.vendorId,
        },
      });
    },
  });

  const [updateContractHeader, { loading: updateLoading }] = useMutation(
    UPDATE_CONTRACT_HEADER,
    {
      variables: {
        contractHeaderId,
      },
      onCompleted: (data) => {
        if (!data?.updateContractHeader) {
          return;
        }

        setAlert({
          type: 'success',
          message: 'Contract header has been saved successfully.',
        });

        setContractHeader((previous) => {
          return {
            ...previous,
            ...data.updateContractHeader,
          };
        });

        reset({
          pocName: data.updateContractHeader.pocName,
          pocEmail: data.updateContractHeader.pocEmail,
          pocPhone: data.updateContractHeader.pocPhone,
          publishedInFleet: data.updateContractHeader.publishedInFleet,
          dollarValueMaximumOrder:
            data.updateContractHeader.dollarValueMaximumOrder,
        });

        if (data.updateContractHeader.dollarValueMaximumOrder !== null) {
          setIsMaxDisabled(true);
        }
      },
    },
  );

  const [unpublishContractHeader] = useMutation(UNPUBLISH_CONTRACT_HEADER, {
    variables: {
      contractHeaderId,
    },
    onCompleted: () => {
      reset({ publishedInFleet: false });
    },
  });

  const onSubmit = (data) => {
    setAlert(null);

    if (
      !isMaxDisabled &&
      data.dollarValueMaximumOrder !== null &&
      !maximumValueModalIsOpen
    ) {
      maximumValueModalOpenModal();
      return;
    }

    updateContractHeader({
      variables: {
        contractHeaderUpdateInput: {
          pocName: data.pocName,
          pocEmail: data.pocEmail,
          pocPhone: data.pocPhone,
          publishedInFleet:
            data.publishedInFleet === true ||
            data.publishedInFleet === 'true' ||
            false,
          dollarValueMaximumOrder:
            data.dollarValueMaximumOrder !== null
              ? Number(data.dollarValueMaximumOrder)
              : null,
        },
      },
    });
  };

  const handleUnpublishContractHeader = ({ emailType }) => {
    if (contractHeader.publishedInFleet !== true) {
      return;
    }

    unpublishContractHeader({
      variables: {
        contractHeaderId,
        emailType,
      },
    });
  };

  const isEndDateInThePast = useMemo(() => {
    return (
      (contractHeader.contractEndDate &&
        moment().isAfter(contractHeader.contractEndDate, 'day')) ||
      false
    );
  }, [contractHeader.contractEndDate]);

  const isAwardCancelledDatePast = useMemo(() => {
    return (
      (contractHeader.awardCancelledDate &&
        moment().isAfter(contractHeader.awardCancelledDate, 'day')) ||
      false
    );
  }, [contractHeader.awardCancelledDate]);

  const isVendorExpired = useMemo(() => {
    return (
      (contractHeader.vendor?.registrationExpirationDate &&
        moment().isAfter(
          contractHeader.vendor.registrationExpirationDate,
          'day',
        )) ||
      false
    );
  }, [contractHeader.vendor?.registrationExpirationDate]);

  useEffect(() => {
    if (Object.keys(contractHeader).length === 0) return;

    if (isEndDateInThePast || isAwardCancelledDatePast) {
      setIsPublishedInFleetDisabled(true);
      handleUnpublishContractHeader({
        emailType: 'UNPUBLISH_CONTRACT_END_DATE_PAST',
      });
    }
    const isVendorFleetStatusInactive =
      contractHeader.vendor?.fleetStatus === 'Inactive';

    if (isVendorFleetStatusInactive) {
      setIsPublishedInFleetDisabled(true);
      handleUnpublishContractHeader({
        emailType: 'UNPUBLISH_VEHICLE_SUPPLIER_FLEET_STATUS_INACTIVE',
      });
    }

    if (isVendorExpired) {
      setIsPublishedInFleetDisabled(true);
      handleUnpublishContractHeader({
        emailType: 'UNPUBLISH_SAM_EXPIRATION_DATE_PAST',
      });
    }
  }, [
    isEndDateInThePast,
    isAwardCancelledDatePast,
    isVendorExpired,
    contractHeader,
  ]);

  useEffect(() => {
    if (!getVendorDetailByIdData) {
      return;
    }

    const isVehicleSupplierValid =
      getVendorDetailByIdData?.vendorOrderTransmittals?.length > 0 &&
      getVendorDetailByIdData.vendorOrderTransmittals.some(
        (vendorOrderTransmittal) => {
          if (
            vendorOrderTransmittal.orderTransmittalCode &&
            vendorOrderTransmittal.orderTransmittalCode !== 'E'
          ) {
            return true;
          }

          if (
            vendorOrderTransmittal.orderTransmittalCode === 'E' &&
            vendorOrderTransmittal.emailAddr
          ) {
            return true;
          }

          return false;
        },
      );

    if (isVehicleSupplierValid) {
      return;
    }

    setIsPublishedInFleetDisabled(true);
    handleUnpublishContractHeader({
      emailType: 'UNPUBLISH_VEHICLE_SUPPLIER_TRANSMISSION_METHOD_INCOMPLETE',
    });
  }, [getVendorDetailByIdData]);

  // NOTE: ensures that users are warned about unsaved changes before navigating away
  // using in app navigation
  useEffect(() => {
    unblockRef.current = history.block((location) => {
      if (formState.isDirty) {
        locationRef.current = location;
        unsavedModalOpenModal();
        return false;
      }

      return true;
    });

    return () => {
      unblockRef.current();
    };
  }, [formState.isDirty, history]);

  // NOTE: ensures that users are warned about unsaved changes before navigating away
  // using browser navigation
  useEffect(() => {
    const beforeWindowNavigationHandler = (e) => {
      e.preventDefault();
      e.returnValue =
        'Are you sure you want to leave this page? Changes you made will not be saved.';
    };

    if (!formState.isDirty) {
      return () => {};
    }

    window.addEventListener('beforeunload', beforeWindowNavigationHandler);

    return () => {
      window.removeEventListener('beforeunload', beforeWindowNavigationHandler);
    };
  }, [formState.isDirty]);

  const breadcrumbs = (
    <ContractBreadcrumbs current={BREADCRUMBS.CONTRACT_HEADER} />
  );

  if (loading) {
    return (
      <div id="contract-header">
        {breadcrumbs}
        <Spinner data-testid="contract-header-spinner" size="small" />
      </div>
    );
  }

  if (Object.keys(contractHeader).length === 0) {
    return (
      <div id="contract-header">
        {breadcrumbs}
        <NotFound />
      </div>
    );
  }

  return (
    <div id="contract-header">
      {breadcrumbs}

      {alert && (
        <ToastMessage
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert(null)}
          closable
          className="margin-bottom-2"
        />
      )}

      <form id="contract-header-form" onSubmit={handleSubmit(onSubmit)}>
        <div
          className="view-solicitation"
          data-testid="view-solicitation-detail"
        >
          <div className="grid-row grid-gap margin-bottom-2">
            <div className="grid-col-10">
              <PageTitle
                title={
                  <>
                    Contract uPIID:{' '}
                    {contractHeader.contractUpiid ||
                      contractHeader.solicitation?.solicitationNumber ||
                      emDashUnicode}
                  </>
                }
              />
              <div>
                <span>Contractor: </span>
                <Link
                  href={`${window.AFP_CONFIG.appURLs.home}/vendor/details/${contractHeader.vendor?.id}`}
                >
                  {contractHeader.vendor?.vendorName}
                </Link>
              </div>
            </div>
            <div className="grid-col-2 display-flex flex-align-end flex-justify-end">
              <ContractHeaderActions />
            </div>
          </div>

          <ContractDetails contract={contractHeader} />

          <StandardFieldset
            name="vehicle-supplier-information"
            label="Vehicle Supplier Information"
          >
            <StandardFieldsetRow className="margin-bottom-8">
              <StandardFieldsetRowCol
                colWidth={3}
                label="Contractor UEI"
                data={contractHeader.vendor?.uniqueEntityIdentifier}
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="Fleet vendor number"
                data={
                  contractHeader.vendor?.fleetVendorNumber ||
                  contractHeader.vendor?.fleetVendorNo
                }
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="Fleet vendor status"
                data={contractHeader.vendor?.fleetStatus}
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="SAM registration expiration date"
                data={
                  contractHeader.vendor?.registrationExpirationDate
                    ? moment(
                        contractHeader.vendor?.registrationExpirationDate,
                      ).format('L')
                    : ''
                }
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="Point of contact"
                data={
                  <ReadOnlyWrapper readOnly={readOnly}>
                    <Controller
                      control={control}
                      name="pocName"
                      defaultValue={contractHeader.pocName || ''}
                      render={({ name, onChange, value }) => {
                        return (
                          <TextInput
                            name={name}
                            onChange={onChange}
                            value={value}
                            errorMessage={errors.pocName?.message}
                            disabled={!canUpdateContract}
                          />
                        );
                      }}
                    />
                  </ReadOnlyWrapper>
                }
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="Email"
                data={
                  <ReadOnlyWrapper readOnly={readOnly}>
                    <Controller
                      control={control}
                      name="pocEmail"
                      defaultValue={contractHeader.pocEmail || ''}
                      render={({ name, onChange, value }) => {
                        return (
                          <TextInput
                            name={name}
                            onChange={onChange}
                            value={value}
                            errorMessage={errors.pocEmail?.message}
                            disabled={!canUpdateContract}
                          />
                        );
                      }}
                    />
                  </ReadOnlyWrapper>
                }
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="Phone number"
                data={
                  <ReadOnlyWrapper readOnly={readOnly}>
                    <Controller
                      control={control}
                      name="pocPhone"
                      defaultValue={contractHeader.pocPhone || ''}
                      render={({ name, onChange, value }) => {
                        return (
                          <TextInput
                            name={name}
                            onChange={onChange}
                            value={value}
                            errorMessage={errors.pocPhone?.message}
                            disabled={!canUpdateContract}
                          />
                        );
                      }}
                    />
                  </ReadOnlyWrapper>
                }
              />
            </StandardFieldsetRow>
          </StandardFieldset>
          <StandardFieldset name="buyer-information" label="Buyer Information">
            <StandardFieldsetRow className="margin-bottom-8">
              <StandardFieldsetRowCol
                colWidth={3}
                label="Contracting officer"
                data={contractHeader.contractingOfficerName}
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="Contracting officer email"
                data={contractHeader.contractingOfficerEmail}
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="Contracting specialist"
                data={contractHeader.contractSpecialistName}
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="Contracting specialist email"
                data={contractHeader.contractSpecialistEmail}
              />
            </StandardFieldsetRow>
          </StandardFieldset>
          <StandardFieldset
            name="additional-contract-information"
            label="Additional Contract Information"
          >
            <StandardFieldsetRow className="margin-bottom-8 grid-gap-4">
              <StandardFieldsetRowCol
                colWidth={3}
                label="Start date"
                data={
                  contractHeader.contractStartDate
                    ? moment(contractHeader.contractStartDate).format('L')
                    : ''
                }
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="End date"
                data={
                  contractHeader.contractEndDate
                    ? moment(contractHeader.contractEndDate).format('L')
                    : ''
                }
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="Date last updated"
                data={
                  contractHeader.updatedAt
                    ? moment(contractHeader.updatedAt).format('L')
                    : ''
                }
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="Termination date"
                data={
                  contractHeader.awardCancelledDate
                    ? moment(contractHeader.awardCancelledDate).format('L')
                    : '-'
                }
              />

              <StandardFieldsetRowCol
                colWidth={3}
                label="Contract maximum value"
                data={
                  <ReadOnlyWrapper readOnly={readOnly}>
                    <Controller
                      control={control}
                      name="dollarValueMaximumOrder"
                      defaultValue={
                        contractHeader.dollarValueMaximumOrder ?? ''
                      }
                      render={({ name, onChange, value }) => {
                        return (
                          <TextInput
                            containerClassName={isMaxDisabled ? 'disabled' : ''}
                            data-testid="contract-header-maximum-value"
                            name={name}
                            value={value ?? ''}
                            disabled={isMaxDisabled || !canUpdateContract}
                            onChange={onChange}
                            type="number"
                            prefix={<Icon iconName="attach_money" />}
                            errorMessage={
                              errors.dollarValueMaximumOrder?.message
                            }
                          />
                        );
                      }}
                    />
                  </ReadOnlyWrapper>
                }
              />
              <StandardFieldsetRowCol
                colWidth={3}
                label="Legacy solicitation"
                data={contractHeader.solicitation?.solicitationNumberLegacy}
              />
              <StandardFieldsetRowCol
                colWidth={2}
                label="Publish"
                data={
                  <ReadOnlyWrapper readOnly={readOnly}>
                    <Controller
                      control={control}
                      name="publishedInFleet"
                      defaultValue={contractHeader.publishedInFleet || ''}
                      render={({ name, onChange, value }) => {
                        return (
                          <SelectDropdown
                            name={name}
                            onChange={onChange}
                            options={[
                              { label: 'Yes', value: true },
                              { label: 'No', value: false },
                            ]}
                            value={value}
                            disabled={
                              isPublishedInFleetDisabled || !canUpdateContract
                            }
                          />
                        );
                      }}
                    />
                  </ReadOnlyWrapper>
                }
              />
            </StandardFieldsetRow>
          </StandardFieldset>
          {canUpdateContract && !readOnly && (
            <StandardFieldset name="save">
              <StandardFieldsetRow>
                <Button
                  data-testid="contract-header-submit-button"
                  type="submit"
                  form="contract-header-form"
                  label="Save changes"
                />
              </StandardFieldsetRow>
            </StandardFieldset>
          )}
          <ConnectedContractHeaderMaximumValueModal
            isOpen={maximumValueModalIsOpen}
            closeModal={maximumValueModalCloseModal}
            disabled={updateLoading}
            onComplete={async () => {
              handleSubmit(onSubmit)();
              maximumValueModalCloseModal();
            }}
          />
          <ConnectedContractHeaderUnsavedModal
            isOpen={unsavedModalIsOpen}
            closeModal={unsavedModalCloseModal}
            disabled={updateLoading}
            leavePage={() => {
              unsavedModalCloseModal();
              unblockRef.current();
              history.push(locationRef.current.pathname);
            }}
            onComplete={async () => {
              handleSubmit(onSubmit)();
              unsavedModalCloseModal();
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default ContractHeader;
