import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import { useFederalStandards } from '../fvs-provider';

const ConfirmComment = ({ tokenName }) => {
  const location = useLocation();
  const history = useHistory();
  const { addOrReplaceFvsMessageById } = useFederalStandards();

  const query = new URLSearchParams(location.search);
  const [verifyCommentCode, verifyCommentCodeMutationResult] = useMutation(
    gql`
      mutation ConfirmComment($publicCommentToken: String!) {
        confirmComment(publicCommentToken: $publicCommentToken) {
          id
          comment
        }
      }
    `,
    {
      onCompleted: () => {
        addOrReplaceFvsMessageById({
          id: 'confirm-comment-success',
          type: 'success',
          message:
            'Your comment was successfully sent to engineering for review. You will receive an email when a decision has been made.',
        });
      },
      onError: (error) => {
        addOrReplaceFvsMessageById({
          id: 'confirm-comment-error',
          type: 'error',
          message: error.message,
        });
      },
    },
  );
  const token = query.get(tokenName);

  useEffect(() => {
    if (token) {
      verifyCommentCode({
        variables: { publicCommentToken: token },
      });
    }
    return () => {
      query.delete(tokenName);
      history.push({ search: query.toString() });
    };
  }, []);

  if (!token) {
    return null;
  }

  if (
    !verifyCommentCodeMutationResult.called ||
    verifyCommentCodeMutationResult.loading
  ) {
    return <div>Verifying...</div>;
  }

  return null;
};

ConfirmComment.propTypes = {
  tokenName: PropTypes.string.isRequired,
};

export default ConfirmComment;
