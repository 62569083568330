import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useRecoilState } from 'recoil';
import { STANDARD_ITEMS } from '../../../../services/data-store';
import {
  GET_MULTIPLE_OPTIONS,
  GET_FVS_OPTIONS_FOR_YEAR,
  GET_SOLICITATION_BY_ID,
} from '../../queries';
import {
  vehicleClassifications as vc,
  multipleOptions,
  modalMode as currentModalMode,
  inactiveSIArray,
} from '../atoms/sin-selection-atoms';

export default function useSinSelection() {
  const [options, setOptions] = useRecoilState(multipleOptions);
  const [vehicleClassifications, setVehicleClassifications] = useRecoilState(
    vc,
  );

  const [inactiveSIList, setInactiveSIList] = useRecoilState(inactiveSIArray);
  const [modalMode, setModalMode] = useRecoilState(currentModalMode);

  const [standardItems, setStandardItems] = useState([]);
  const [solicitation, setSolicitation] = useState(null);

  const [getOptions, { loading: optionsLoading }] = useLazyQuery(
    GET_MULTIPLE_OPTIONS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setOptions(data.getMultipleOptions);
      },
    },
  );

  const [getFVsCodes, { loading: fvsLoading }] = useLazyQuery(
    GET_FVS_OPTIONS_FOR_YEAR,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setVehicleClassifications(data.getVehicleClassificationFVS);
      },
    },
  );

  const [getStandardItems, { loading: SILoading }] = useLazyQuery(
    STANDARD_ITEMS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (responseData) => {
        // if (responseData?.getStandardItems) {
        setStandardItems(responseData.getStandardItems.rows);
        // }
      },
    },
  );

  const [getSolicitationById, { loading: solicitationLoading }] = useLazyQuery(
    GET_SOLICITATION_BY_ID,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (responseData) => {
        getFVsCodes({
          variables: {
            year: responseData?.getSolicitationById?.contractYear,
            includeOnlyCommentable: false,
          },
        });

        setSolicitation(responseData?.getSolicitationById);
      },
    },
  );

  return {
    optionsLoading,
    fvsLoading,
    SILoading,
    solicitationLoading,
    options,
    vehicleClassifications,
    standardItems,
    solicitation,
    getOptions,
    getFVsCodes,
    getSolicitationById,
    getStandardItems,
    modalMode,
    setModalMode,
    inactiveSIList,
    setInactiveSIList,
  };
}
