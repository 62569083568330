import { Button } from '@gsa/afp-component-library';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Modal from '../../../../components/Modal/Modal';
import EquipmentCodeAssociationEdit from './equipment-code-association-edit';
import CommentDetail from './comment-detail';
import FvsMessages from '../../components/fvs-messages';
import { useFederalStandards } from '../../fvs-provider';
import { FVS_RT_MODAL_MODES } from '../constants';
import { useFvsRequirements } from '../requirements-provider';

const ModalTitle = () => {
  const { removeFvsMessageById, fvsMessages } = useFederalStandards();
  const { rtModalMode } = useFvsRequirements();

  let title = '';
  switch (rtModalMode) {
    case FVS_RT_MODAL_MODES.EDIT_ASSOCIATION:
      title = 'Edit Equipment Code';
      break;
    case FVS_RT_MODAL_MODES.CONFIRM_DELETE_ASSOCIATION:
      title = 'Confirm removal of Equipment Code';
      break;
    case FVS_RT_MODAL_MODES.COMMENT:
      title = 'Comment';
      break;
    case FVS_RT_MODAL_MODES.CONFIRM_CANCEL:
      title = 'Confirm';
      break;
    default:
      break;
  }

  const isDeletingComment = fvsMessages.find(
    (m) => m.id === 'FVS_COMMENT' && m.message.includes('delete'),
  );

  const renderNotification = () => {
    return title === 'Comment' ? (
      <FvsMessages onClose={() => removeFvsMessageById('FVS_COMMENT')} />
    ) : null;
  };
  return (
    <div className="margin-top-8" aria-label={title}>
      {isDeletingComment ? renderNotification() : null}
      {/* eslint-disable-next-line */}
      <h3 tabIndex="0">{title}</h3>
    </div>
  );
};

const ModalContent = () => {
  const { rtModalMode, equipmentAssociation } = useFvsRequirements();

  if (rtModalMode === FVS_RT_MODAL_MODES.COMMENT)
    return (
      <>
        <CommentDetail />
      </>
    );

  if (rtModalMode === FVS_RT_MODAL_MODES.CONFIRM_CANCEL) {
    return <div>Are you sure you want to cancel the comment?</div>;
  }

  if (rtModalMode === FVS_RT_MODAL_MODES.EDIT_ASSOCIATION) {
    return <EquipmentCodeAssociationEdit />;
  }

  if (rtModalMode === FVS_RT_MODAL_MODES.CONFIRM_DELETE_ASSOCIATION) {
    return (
      <p>
        {`Are you sure you want to dissociate equipment code ${equipmentAssociation?.equipmentCode?.code}?`}
      </p>
    );
  }

  return null;
};

const ModalAction = () => {
  const {
    rtModalMode,
    equipmentAssociation,
    deleteEquipmentAssociation,
    setFvsRequirementsContextData,
  } = useFvsRequirements();
  const history = useHistory();
  const location = useLocation();

  const onCancel = () => {
    setFvsRequirementsContextData('SET_EQUIPMENT_ASSOCIATION', null);
    setFvsRequirementsContextData('SET_RT_MODAL_MODE', null);
    history.push({ pathname: location?.pathname, search: '' });
  };

  const onCancelComment = () => {
    setFvsRequirementsContextData('SET_SELECTED_COMMENT', null);
    onCancel();
  };

  if (rtModalMode === FVS_RT_MODAL_MODES.CONFIRM_CANCEL) {
    return (
      <>
        <Button
          variant="secondary"
          onClick={onCancel}
          data-testid="standard-item-edit-cancel-btn"
          label="No"
        />
        <Button
          variant="primary"
          type="submit"
          form="standard-item-edit-form"
          data-testid="standard-item-edit-save-btn"
          onClick={onCancelComment}
          label="Yes"
        />
      </>
    );
  }

  if (rtModalMode === FVS_RT_MODAL_MODES.COMMENT)
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="comment-modal-cancel-btn"
          label="Cancel"
        />
      </>
    );

  if (rtModalMode === FVS_RT_MODAL_MODES.EDIT_ASSOCIATION)
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="association-modal-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="primary"
          type="submit"
          form="equipment-code-edit-form"
          data-testid="association-modal-save-btn"
          label="Save and close"
        />
      </>
    );

  if (rtModalMode === FVS_RT_MODAL_MODES.CONFIRM_DELETE_ASSOCIATION)
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="association-modal-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="secondary"
          data-testid="association-modal-remove-btn"
          onClick={async () => {
            if (equipmentAssociation?.id) {
              await deleteEquipmentAssociation({
                variables: {
                  equipmentAssociationDelete: {
                    ids: [Number(equipmentAssociation.id)],
                  },
                },
              });
              onCancel();
            }
          }}
          label="Remove"
        />
      </>
    );

  return null;
};

const RequirementsModalWrapper = () => {
  const {
    rtShowFormModal,
    setFvsRequirementsContextData,
  } = useFvsRequirements();
  const history = useHistory();
  const location = useLocation();
  return (
    <Modal
      title={<ModalTitle />}
      actions={<ModalAction />}
      show={rtShowFormModal}
      onClose={() => {
        setFvsRequirementsContextData('SET_EQUIPMENT_ASSOCIATION', null);
        setFvsRequirementsContextData('SET_RT_MODAL_MODE', null);
        history.push({ pathname: location?.pathname, search: '' });
      }}
    >
      <ModalContent />
    </Modal>
  );
};

export default RequirementsModalWrapper;
