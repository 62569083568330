/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import {
  DatePicker,
  ErrorMessage,
  StatusBadge,
} from '@gsa/afp-component-library';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import './commenting-schedule.scss';

const MIN_DATE = moment().startOf('day').toDate();
const POSSIBLE_DATE_FORMAT = ['MM/DD/YYYY', 'YYYY-MM-DDTHH:mm:ss.SSSZ'];
const parseDateString = (value, originalValue) => {
  if (!originalValue) return undefined;
  const parsedDate =
    originalValue instanceof Date && originalValue
      ? originalValue
      : moment(originalValue, POSSIBLE_DATE_FORMAT, true).toDate();
  return parsedDate;
};

const SchedulingCommentingForm = ({ commentingPeriod, handleSave }) => {
  const [publicDaysDiff, setPublicDaysDiff] = useState(0);
  const [psoDaysDiff, setPsoDaysDiff] = useState(0);

  const [publicBackgroundColor, setPublicBackgroundColor] = useState(
    'bg-gray-3',
  );
  const [psoBackgroundColor, setPsoBackgroundColor] = useState('bg-gray-3');

  const inputData = {
    publicPeriodId: commentingPeriod?.publicPeriodId
      ? commentingPeriod.publicPeriodId
      : null,
    publicPeriodStart: commentingPeriod?.publicPeriodStart
      ? moment(commentingPeriod.publicPeriodStart)
      : null,
    publicPeriodEnd: commentingPeriod?.publicPeriodEnd
      ? moment(commentingPeriod.publicPeriodEnd)
      : null,
    publicStatus: commentingPeriod?.publicStatus
      ? commentingPeriod.publicStatus
      : 'Unscheduled',
    psoPeriodId: commentingPeriod?.psoPeriodId
      ? commentingPeriod.psoPeriodId
      : null,
    psoPeriodStart: commentingPeriod?.psoPeriodStart
      ? moment(commentingPeriod.psoPeriodStart)
      : null,
    psoPeriodEnd: commentingPeriod?.psoPeriodEnd
      ? moment(commentingPeriod.psoPeriodEnd)
      : null,
    psoStatus: commentingPeriod?.psoStatus
      ? commentingPeriod.psoStatus
      : 'Unscheduled',
  };

  let publicMin = MIN_DATE.toISOString();
  if (inputData.publicPeriodStart) {
    if (inputData.publicPeriodStart.diff(MIN_DATE, 'days') < 0) {
      publicMin = inputData.publicPeriodStart.toISOString();
    }
  }

  let psoMin = MIN_DATE.toISOString();
  if (inputData.psoPeriodStart) {
    if (inputData.psoPeriodStart.diff(MIN_DATE, 'days') < 0) {
      psoMin = inputData.psoPeriodStart.toISOString();
    }
  }

  const CommentSchedulingFormSchema = yup.object().shape({
    publicPeriodId: yup.number().nullable(),
    publicPeriodStart: yup
      .date()
      .typeError('Invalid date')
      .transform(parseDateString)
      .nullable()
      .min(publicMin, `Start date cannot be in the past`),
    publicPeriodEnd: yup
      .date()
      .typeError('Invalid date')
      .transform(parseDateString)
      .nullable()
      .min(yup.ref('publicPeriodStart'), 'End date must be after start date')
      .when(
        'publicPeriodStart',
        (publicPeriodStart, schema) =>
          publicPeriodStart &&
          schema.max(
            moment(publicPeriodStart).add(365, 'days').toDate(),
            'End date must be within 365 days of start date',
          ),
      )
      .nullable(),
    publicStatus: yup.string().nullable(),
    psoPeriodId: yup.number().nullable(),
    psoPeriodStart: yup
      .date()
      .typeError('Invalid date')
      .transform(parseDateString)
      .nullable()
      .min(psoMin, `Start date cannot be in the past`),
    psoPeriodEnd: yup
      .date()
      .typeError('Invalid date')
      .transform(parseDateString)
      .nullable()
      .min(yup.ref('psoPeriodStart'), 'End date must be after start date')
      .when(
        'psoPeriodStart',
        (psoPeriodStart, schema) =>
          psoPeriodStart &&
          schema.max(
            moment(psoPeriodStart).add(365, 'days').toDate(),
            'End date must be within 365 days of start date',
          ),
      )
      .nullable(),
    psoStatus: yup.string().nullable(),
  });

  const {
    register,
    errors,
    watch,
    control,
    handleSubmit,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(CommentSchedulingFormSchema),
    defaultValues: inputData,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const currentStartDateVal = watch('publicPeriodStart');
  const currentEndDateVal = watch('publicPeriodEnd');
  const psoCurrentStartDateVal = watch('psoPeriodStart');
  const psoCurrentEndDateVal = watch('psoPeriodEnd');
  const publicStatus = watch('publicStatus');
  const psoStatus = watch('psoStatus');

  const onSubmit = () => {
    let dataToSave = {};
    if (
      psoCurrentStartDateVal &&
      psoCurrentEndDateVal &&
      (moment(psoCurrentStartDateVal).diff(inputData.psoPeriodStart, 'days') !==
        0 ||
        moment(psoCurrentEndDateVal).diff(inputData.psoPeriodEnd, 'days') !== 0)
    ) {
      dataToSave = {
        psoPeriodId: commentingPeriod?.psoPeriodId,
        psoPeriodStart: getValues('psoPeriodStart'),
        psoPeriodEnd: getValues('psoPeriodEnd'),
      };
    }

    if (
      currentStartDateVal &&
      currentEndDateVal &&
      (moment(currentStartDateVal).diff(inputData.publicPeriodStart, 'days') !==
        0 ||
        moment(currentEndDateVal).diff(inputData.publicPeriodEnd, 'days') !== 0)
    ) {
      dataToSave = {
        ...dataToSave,
        publicPeriodId: commentingPeriod?.publicPeriodId,
        publicPeriodStart: getValues('publicPeriodStart'),
        publicPeriodEnd: getValues('publicPeriodEnd'),
      };
    }

    handleSave(dataToSave);
  };

  const handleChange = (
    startDateValue,
    endDateValue,
    startDateField,
    endDateField,
  ) => {
    const ma = startDateValue ? moment(startDateValue) : null;
    const mb = endDateValue ? moment(endDateValue) : null;

    if (startDateValue && !endDateValue) {
      setValue(
        endDateField,
        moment(startDateValue).add(45, 'days').toISOString(),
        { shouldDirty: true, shouldValidate: true },
      );
      if (startDateField === 'publicPeriodStart') {
        setPublicBackgroundColor('bg-blue-5');
      } else {
        setPsoBackgroundColor('bg-blue-5');
      }
    }

    if (ma && mb) {
      if (startDateField === 'publicPeriodStart') {
        setPublicDaysDiff(mb.diff(ma, 'days'));
      } else {
        setPsoDaysDiff(mb.diff(ma, 'days'));
      }
    }

    // TODO - formState.isDirty and formState.dirtyFields doesn't seem to return the changes with datepicker - hence the below custom checks
    if (ma && inputData[startDateField]) {
      const m1 = moment(inputData[startDateField]);
      if (m1.diff(ma, 'days') !== 0) {
        if (startDateField === 'publicPeriodStart') {
          setPublicBackgroundColor('bg-blue-5');
        } else {
          setPsoBackgroundColor('bg-blue-5');
        }
      }
    }

    if (mb && inputData[endDateField]) {
      const m2 = moment(inputData[endDateField]);
      if (m2.diff(mb, 'days') !== 0) {
        if (startDateField === 'publicPeriodStart') {
          setPublicBackgroundColor('bg-blue-5');
        } else {
          setPsoBackgroundColor('bg-blue-5');
        }
      }
    }
  };

  useEffect(() => {
    handleChange(
      currentStartDateVal,
      currentEndDateVal,
      'publicPeriodStart',
      'publicPeriodEnd',
    );
  }, [currentStartDateVal, currentEndDateVal]);

  useEffect(() => {
    handleChange(
      psoCurrentStartDateVal,
      psoCurrentEndDateVal,
      'psoPeriodStart',
      'psoPeriodEnd',
    );
  }, [psoCurrentStartDateVal, psoCurrentEndDateVal]);

  const mapStatusToBadgeColor = (historyStatus) => {
    const status = historyStatus?.toLowerCase();

    if (!status) return '';
    if (status === 'unscheduled') return 'Inactive-Gray';
    if (status === 'open') return 'Ready-Gray';
    if (status === 'scheduled') return 'Info-Gray';
    return 'Inactive-Gray';
  };

  return (
    <form
      id="commenting-period-schedule-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="scheduling-meeting-form-container">
        <div className="padding-1 width-full">
          <div className={`grid-row grid-gap-lg ${publicBackgroundColor}`}>
            <div className="grid-col-12 ">
              <div className="title-m-bold text-primary padding-bottom-1">
                For public users
              </div>

              <StatusBadge variant={mapStatusToBadgeColor(publicStatus)}>
                {publicStatus}
              </StatusBadge>
            </div>
          </div>
          <div
            className={`grid-row grid-gap-lg padding-top-2 ${publicBackgroundColor}`}
          >
            <div className="desktop-lg:grid-col-4 mobile-lg:grid-col-12 margin-right-6">
              <div
                className="usa-form-group"
                data-testid="public-commenting-start-date"
              >
                <span className="body-bold">Start Date</span>
                <div className="usa-hint">mm/dd/yyyy</div>
                <Controller
                  name="publicPeriodStart"
                  control={control}
                  defaultValue={commentingPeriod.publicPeriodStart}
                  render={({ name, value, onBlur }) => (
                    <DatePicker
                      inputRef={register}
                      name={name}
                      minDate={publicMin}
                      onBlur={onBlur}
                      onChange={(val) => {
                        setValue(
                          'publicPeriodStart',
                          val ? moment(val, POSSIBLE_DATE_FORMAT) : null,
                        );
                      }}
                      defaultValue={value}
                      aria-label="Start Date for public users"
                    />
                  )}
                />
                {errors?.publicPeriodStart?.message && (
                  <ErrorMessage>
                    {errors.publicPeriodStart.message}
                  </ErrorMessage>
                )}
              </div>
            </div>
            <div className="desktop-lg:grid-col-4 mobile-lg:grid-col-12">
              <div
                className="usa-form-group"
                data-testid="public-commenting-end-date"
              >
                <span className="body-bold">End Date</span>
                <div className="usa-hint">mm/dd/yyyy</div>
                <Controller
                  name="publicPeriodEnd"
                  control={control}
                  defaultValue={commentingPeriod.publicPeriodEnd}
                  render={({ name, value, onBlur }) => {
                    return (
                      <DatePicker
                        inputRef={register}
                        name={name}
                        onBlur={onBlur}
                        minDate={publicMin}
                        onChange={(val) => {
                          const v =
                            val !== undefined && val !== null && val !== ''
                              ? moment(val, POSSIBLE_DATE_FORMAT)
                              : undefined;
                          setValue('publicPeriodEnd', v);
                          return v;
                        }}
                        defaultValue={value}
                        aria-label="End Date for public users"
                      />
                    );
                  }}
                />
                <div
                  className="usa-hint body"
                  id="end-date-text-hint"
                  tabIndex="0"
                  aria-label="Defaults to 45 calendar days after start date"
                >
                  Defaults to 45 calendar days after start date
                </div>
                {errors?.publicPeriodEnd?.message && (
                  <ErrorMessage>{errors.publicPeriodEnd.message}</ErrorMessage>
                )}
              </div>
            </div>

            <div className="desktop-lg:grid-col-3 mobile-lg:grid-col-12 flex-align-self-center">
              {publicDaysDiff === 1 && (
                <div
                  className="title-m-bold text-primary"
                  aria-label={`${publicDaysDiff} day`}
                  tabIndex="0"
                >
                  {`${publicDaysDiff} day`}
                </div>
              )}
              {publicDaysDiff > 1 && (
                <div
                  className="title-m-bold text-primary"
                  aria-label={`${publicDaysDiff} day`}
                  tabIndex="0"
                >
                  {`${publicDaysDiff} days`}
                </div>
              )}
            </div>
          </div>

          <div
            className={`grid-row grid-gap-lg margin-top-2 ${psoBackgroundColor}`}
          >
            <div className="grid-col-12">
              <div className="title-m-bold text-primary padding-bottom-1">
                {/* For product service offerors (PSOs) */}
                For Vehicle Suppliers
              </div>
              <StatusBadge variant={mapStatusToBadgeColor(psoStatus)}>
                {psoStatus}
              </StatusBadge>
            </div>
          </div>

          <div
            className={`grid-row grid-gap-lg padding-top-2 ${psoBackgroundColor}`}
          >
            <div className="desktop-lg:grid-col-4 mobile-lg:grid-col-12 margin-right-6">
              <div
                className="usa-form-group"
                data-testid="pso-commenting-start-date"
              >
                <span className="body-bold">Start Date</span>
                <div className="usa-hint">mm/dd/yyyy</div>
                <Controller
                  name="psoPeriodStart"
                  control={control}
                  defaultValue={commentingPeriod.psoPeriodStart}
                  render={({ name, value, onBlur }) => (
                    <DatePicker
                      name={name}
                      minDate={psoMin}
                      onBlur={onBlur}
                      onChange={(val) => {
                        setValue(
                          'psoPeriodStart',
                          val !== undefined && val !== null
                            ? moment(val, POSSIBLE_DATE_FORMAT).toDate()
                            : null,
                        );
                      }}
                      defaultValue={value}
                      aria-label="Start Date for Vehicle-Supplier users"
                    />
                  )}
                />
                {errors?.psoPeriodStart?.message && (
                  <ErrorMessage>{errors.psoPeriodStart.message}</ErrorMessage>
                )}
              </div>
            </div>
            <div className="desktop-lg:grid-col-4 mobile-lg:grid-col-12">
              <div
                className="usa-form-group"
                data-testid="pso-commenting-end-date"
              >
                <span className="body-bold">End Date</span>
                <div className="usa-hint" id="end-date-hint">
                  mm/dd/yyyy
                </div>
                <Controller
                  name="psoPeriodEnd"
                  control={control}
                  defaultValue={commentingPeriod.psoPeriodEnd}
                  render={({ name, value, onBlur }) => {
                    return (
                      <DatePicker
                        name={name}
                        minDate={psoMin}
                        onBlur={onBlur}
                        onChange={(val) => {
                          setValue(
                            'psoPeriodEnd',
                            val !== undefined && val !== null && val !== ''
                              ? moment(val, POSSIBLE_DATE_FORMAT).toDate()
                              : undefined,
                          );
                        }}
                        defaultValue={value}
                        aria-label="End Date for Vehicle-Supplier users"
                      />
                    );
                  }}
                />
                <div
                  className="usa-hint body"
                  id="end-date-text-hint"
                  tabIndex="0"
                  aria-label="Defaults to 45 calendar days after start date"
                >
                  Defaults to 45 calendar days after start date
                </div>
                {errors?.psoPeriodEnd?.message && (
                  <ErrorMessage>{errors.psoPeriodEnd.message}</ErrorMessage>
                )}
              </div>
            </div>

            <div className="desktop-lg:grid-col-3 mobile-lg:grid-col-12 flex-align-self-center">
              {psoDaysDiff === 1 && (
                <div
                  className="title-m-bold text-primary"
                  aria-label={`${psoDaysDiff} day`}
                  tabIndex="0"
                >
                  {`${psoDaysDiff} day`}
                </div>
              )}
              {psoDaysDiff > 1 && (
                <div
                  className="title-m-bold text-primary"
                  aria-label={`${psoDaysDiff} days`}
                  tabIndex="0"
                >
                  {`${psoDaysDiff} days`}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

SchedulingCommentingForm.propTypes = {
  handleSave: PropTypes.func.isRequired,
  commentingPeriod: PropTypes.shape(Object).isRequired,
};

export default SchedulingCommentingForm;
