import { Button } from '@gsa/afp-component-library';
import React from 'react';
import Modal from '../../../../../../components/Modal/Modal';
import useSinSelection from '../../../apis/sin-selection-apis';
import { MODAL_MODES } from './constants';
import InactiveSITable from './inactive-si-table';

const ModalTitle = () => {
  const { modalMode } = useSinSelection();
  let title = '';
  switch (modalMode) {
    case MODAL_MODES.INACTIVE_SI:
      title = 'Inactive Standard Items';
      break;
    default:
      break;
  }

  return (
    <div className="margin-top-5" aria-label={title}>
      {/* eslint-disable-next-line */}
      <h3 tabIndex="0">{title}</h3>
    </div>
  );
};

const ModalContent = () => {
  const { modalMode, inactiveSIList } = useSinSelection();

  if (modalMode === MODAL_MODES.INACTIVE_SI)
    return (
      <>
        <InactiveSITable data={inactiveSIList} />
      </>
    );
  return null;
};

const ModalAction = () => {
  const { modalMode, setModalMode } = useSinSelection();
  if (modalMode === MODAL_MODES.INACTIVE_SI) {
    return (
      <>
        <Button
          className="padding-right-1"
          aria-label="Cancel"
          variant="unstyled"
          onClick={() => setModalMode(null)}
          data-testid="invalid-si-cancel-btn"
          label="Close"
        />
      </>
    );
  }
  return null;
};

const SelectSIModalWrapper = () => {
  const { modalMode, setModalMode } = useSinSelection();
  return (
    <Modal
      title={<ModalTitle />}
      actions={<ModalAction />}
      show={modalMode}
      onClose={() => {
        setModalMode(null);
      }}
    >
      <ModalContent />
    </Modal>
  );
};

export default SelectSIModalWrapper;
