/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, StatusBadge } from '@gsa/afp-component-library';
import CommentReplyList from './comment-replies-list';
import { publishStatusColor } from './helpers';
import '../../comment-admin.scss';
import { emDashUnicode } from '../../../../utilities/constants';

const CommentDetail = ({ props, currentUser, canManage }) => {
  const [showMore, setShowMore] = useState(false);
  const [displayHeader, setDisplayHeader] = useState('');
  const [searchParams, setSearchParams] = useState({});
  const [pathName, setPathName] = useState('');

  useEffect(() => {
    let header = `Fed Standard  ${props.fvsCode}`;
    if (props.linkedEntityType === 'StandardItem') {
      header += ` > Standard Item ${props.linkedEntity?.standardItemNumber}`;
    }
    if (props.linkedEntityType === 'Association') {
      header += ` > Standard Item ${props.linkedEntity?.standardItem?.standardItemNumber} > Equipment Code ${props.linkedEntity?.equipmentCode?.code}`;
    }

    let type = '';
    if (props.linkedEntityType === 'StandardItem') {
      type = 'si';
    } else if (props.linkedEntityType === 'Association') {
      type = 'ec';
    } else {
      type = 'doc';
    }
    setDisplayHeader(header);
    setSearchParams({
      standardItemNumber:
        props.linkedEntity?.standardItem?.standardItemNumber ||
        props.linkedEntity?.standardItemNumber,
      equipmentCode: props.linkedEntity?.equipmentCode?.code,
      fedCode: props.fvsCode,
      year: props.year,
      entityType: type,
      commentId: props.id,
      linkedEntityId: props.linkedEntityId,
    });
  }, [props]);

  const getPathname = () => {
    let subUrl = '';
    if (searchParams.entityType === 'si') {
      subUrl = `requirements/${searchParams.year}/${searchParams.fedCode}/${searchParams.standardItemNumber}?showCommentTab=true`;
    } else if (searchParams.entityType === 'ec') {
      subUrl = `requirements/${searchParams.year}/${searchParams.fedCode}/${searchParams.standardItemNumber}?code=${searchParams.equipmentCode}&commentId=${searchParams.commentId}`;
    } else {
      subUrl = `preview/${searchParams.year}/${searchParams.linkedEntityId}?showCommentTab=true`;
    }
    return `/catalog/vehicle-standards/${subUrl}`;
  };

  useEffect(() => {
    const path = getPathname();
    setPathName(path);
  }, [searchParams]);

  const handleClick = () => {
    const current = showMore;
    setShowMore(!current);
  };

  const expandBtnLabel = !showMore ? 'More' : 'Less';

  const username = () => {
    return props.createdByUser === currentUser ? (
      <span className="text-bold margin-top-105">
        {props.createdByUserInfo?.fullName || emDashUnicode},{' '}
        {props.authorType === 'VehicleSupplier'
          ? 'Vehicle Supplier'
          : props.authorType}
      </span>
    ) : (
      <span className="text-bold margin-top-105">Anonymous</span>
    );
  };
  return (
    <div className="commentWrapper">
      <Link to={pathName} className="text-primary">
        {displayHeader}
      </Link>
      <br />
      {canManage ? (
        <span className="text-bold margin-top-105">
          {props.createdByUserInfo?.fullName || emDashUnicode},{' '}
          {props.authorType === 'VehicleSupplier'
            ? 'Vehicle Supplier'
            : props.authorType}
        </span>
      ) : (
        username()
      )}
      <br />
      <div className="margin-bottom-105 margin-top-105">
        <StatusBadge variant={publishStatusColor[props.publishStatus]}>
          {props.publishStatus}
        </StatusBadge>
      </div>
      <p className={!showMore ? 'comment-container' : null}>{props.comment}</p>
      {props.replies.length && showMore ? (
        <CommentReplyList
          replies={props.replies}
          currentUser={currentUser}
          isPublished={props.publishStatus !== 'Unpublished'}
          canManage={canManage}
        />
      ) : null}
      {props.comment.length > 200 || props.replies.length ? (
        <div className="more-btn">
          <Button
            data-testid="comment-detail-expand-btn"
            variant="unstyled"
            onClick={() => handleClick()}
            label={expandBtnLabel}
            rightIcon={{
              name: !showMore ? 'expand_more' : 'expand_less',
              className: 'usa-icon--size-3',
            }}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

CommentDetail.propTypes = {};

export default CommentDetail;
