import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import moment from 'moment';
import * as resolvers from '../../../services/data-store';
import groupMultipleOptions from '../../../utilities/options-helper';
import { GET_COMMENTS } from '../requirements/query.gql';

export const CommentsAdminContext = createContext({});

const initialState = {
  errors: null,
  modalMode: null,
  showFormModal: false,
  multipleOptions: [],
  groupMultipleOptions: null,
  comments: [],
  selectedComment: null,
  selectedComments: [],
  successMessage: '',
  errorMessage: '',
  warningMessage: '',
  commentFormState: null,
  formComment: '',
  bulkResponse: null,
  selectedReply: null,
  commentingPeriodInfo: [],
  selectedCommentingPeriod: null,
  isLoading: false,
};

const commentsAdminReducer = (state, action) => {
  switch (action.type) {
    case 'SET_MODAL_MODE': {
      let showForm = false;
      if (action.payload && action.payload !== '') {
        showForm = true;
      }
      return {
        ...state,
        modalMode: action.payload,
        showFormModal: showForm,
      };
    }
    case 'SET_MULTIPLE_OPTIONS':
      return { ...state, multipleOptions: action.payload };
    case 'SET_GROUP_MULTIPLE_OPTIONS':
      return { ...state, groupMultipleOptions: action.payload };
    case 'SET_COMMENTS':
      return { ...state, comments: { ...action.payload } };
    case 'SET_SELECTED_COMMENT':
      return { ...state, selectedComment: action.payload };
    case 'SET_SELECTED_COMMENTS':
      return { ...state, selectedComments: action.payload };
    case 'SET_COMMENT_FORM_STATE':
      return { ...state, commentFormState: action.payload };
    case 'SET_BULK_RESPONSE':
      return { ...state, bulkResponse: action.payload };
    case 'SET_SELECTED_REPLY':
      return { ...state, selectedReply: action.payload };
    case 'RESET': {
      return initialState;
    }
    case 'SET_SUCCESS_MESSAGE':
      return { ...state, successMessage: action.payload };
    case 'SET_WARNING_MESSAGE':
      return { ...state, warningMessage: action.payload };
    case 'SET_ERROR_MESSAGE':
      return { ...state, errorMessage: action.payload };
    case 'SET_ERROR_MESSAGE_LIST':
      return { ...state, errorMessageList: action.payload };
    case 'SET_COMMENTING_PERIODS_INFO_DATA':
      return { ...state, commentingPeriodInfo: action.payload };
    case 'SET_SELECTED_COMMENTING_PERIOD':
      return { ...state, selectedCommentingPeriod: action.payload };
    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_COMMENTING_PERIOD_IS_LOADING':
      return { ...state, commentingPeriodIsLoading: action.payload };
    default:
      throw new Error('Invalid action');
  }
};

function CommentsAdminProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(
    commentsAdminReducer,
    initialState,
    () => {
      return initialState;
    },
  );

  const setError = (type, payload) => {
    dispatch({
      type: 'SET_ERROR_MESSAGE_LIST',
      payload: { ...state.errors, [type]: payload },
    });
  };

  const setData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const resetState = () => {
    setData('RESET');
  };

  // Get Multiple Options
  const [getMultipleOptions] = useLazyQuery(resolvers.GET_MULTIPLE_OPTIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (requestError) => {
      setError(
        'GET_MULTIPLE_OPTIONS',
        requestError.message ?? 'Unknown Error.',
      );
    },
    onCompleted: (multipleOptionsData) => {
      if (multipleOptionsData?.getMultipleOptions) {
        setData(
          'SET_MULTIPLE_OPTIONS',
          multipleOptionsData?.getMultipleOptions,
        );
        setData(
          'SET_GROUP_MULTIPLE_OPTIONS',
          groupMultipleOptions(multipleOptionsData?.getMultipleOptions),
        );
      }
    },
  });

  // commenting period
  const [
    getOpenAndRecentCommentingPeriods,
    { loading: commentingPeriodIsLoading },
  ] = useLazyQuery(resolvers.GET_OPEN_AND_RECENT_COMMENTING_PERIODS, {
    fetchPolicy: 'network-only',
    onError: () => {
      // This is intentional
    },
    onCompleted: (periodResponse) => {
      setData(
        'SET_COMMENTING_PERIODS_INFO_DATA',
        periodResponse?.getOpenAndRecentCommentingPeriods,
      );
    },
  });

  useEffect(() => {
    setData('SET_COMMENTING_PERIOD_IS_LOADING', commentingPeriodIsLoading);
  }, [commentingPeriodIsLoading]);

  const [getComments, { refetch, loading }] = useLazyQuery(GET_COMMENTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (requestError) => {
      setError('GET_COMMENTS', requestError.message ?? 'Unknown Error.');
    },
    onCompleted: (responseData) => {
      if (responseData?.getStandardsComments) {
        setData('SET_COMMENTS', responseData.getStandardsComments);
        setError('GET_COMMENTS', '');
      }
    },
  });

  useEffect(() => {
    setData('SET_IS_LOADING', loading);
  }, [loading]);

  // add comment
  const [addComment] = useMutation(resolvers.ADD_COMMENT, {
    onError: (requestError) => {
      setError('ADD_COMMENT', requestError.message ?? 'Unknown Error.');
      setData('SET_ERROR_MESSAGE', requestError.message);
    },
    onCompleted: () => {
      refetch();
    },
  });

  // update comment
  const [updateComment] = useMutation(resolvers.UPDATE_COMMENT, {
    onError: (requestError) => {
      setError('UPDATE_COMMENT', requestError.message ?? 'Unknown Error.');
      setData('SET_ERROR_MESSAGE', requestError?.message);
    },
    onCompleted: () => {
      refetch();
    },
  });

  const [manageComment] = useMutation(resolvers.MANAGE_COMMENT, {
    onError: (requestError) => {
      setData('SET_ERROR_MESSAGE', requestError?.message);
      refetch();
    },
    onCompleted: (response) => {
      if (response) {
        const message = `Your actions have been applied to the comment made from ${response?.manageComment.createdByUserInfo?.fullName}, ${response?.manageComment.authorType}`;
        setData('SET_SUCCESS_MESSAGE', message);
      }
      refetch();
    },
  });

  const [deleteStandardsComment] = useMutation(
    resolvers.DELETE_STANDARD_COMMENT,
    {
      onError: (requestError) => {
        setError('SET_ERROR_MESSAGE', requestError.message ?? 'Unknown Error.');
      },
      onCompleted: (response) => {
        if (response?.deleteStandardsComment) {
          const message = `You have successfully deleted your comment.`;
          setData('SET_SUCCESS_MESSAGE', message);
          setData('SET_MODAL_MODE', null);
        }
        refetch();
      },
    },
  );

  const handleBulkReplyReviewResponse = (response) => {
    setData('SET_BULK_RESPONSE', response);
    if (response?.status !== 200) {
      setData(
        'SET_SUCCESS_MESSAGE',
        `Your actions have been applied to all ${state.selectedComments.length} comments. `,
      );
    } else {
      setData('SET_WARNING_MESSAGE', 'WARNING');
      setData(
        'SET_SUCCESS_MESSAGE',
        `Your reply have been applied to all ${state.selectedComments.length} comments. `,
      );
    }
  };

  // bulk
  const [bulkReplyReview] = useMutation(resolvers.BULK_REVIEW_REPLY_COMMENT, {
    onError: (err) => {
      setData('SET_ERROR_MESSAGE', err.message);
    },
    onCompleted: (response) => {
      if (response) {
        handleBulkReplyReviewResponse(response?.bulkReviewReplyComments);
      }
      refetch();
    },
  });

  const handleBulkPublishResponse = (response) => {
    setData('SET_BULK_RESPONSE', response);
    setData(
      'SET_SUCCESS_MESSAGE',
      `${response?.published.length} comments were published.`,
    );
    if (response?.new.length || response?.invalid.length) {
      setData(
        'SET_WARNING_MESSAGE',
        `${response?.published.length} comments were published.`,
      );
    }

    refetch();
  };

  const [bulkPublishComments] = useMutation(resolvers.BULK_PUBLISH_COMMENTS, {
    onError: () => {
      setData(
        'SET_ERROR_MESSAGE',
        'An error occured. Please make sure to select comments before bulk action. ',
      );
    },
    onCompleted: (response) => {
      if (response) {
        handleBulkPublishResponse(response?.bulkPublishComments);
      }
    },
  });

  const [archiveCommentingPeriod] = useMutation(
    resolvers.ARCHIVE_COMMENTING_PERIOD,
    {
      onError: () => {
        setData('SET_ERROR_MESSAGE', 'An error occured');
      },
      onCompleted: (response) => {
        if (response) {
          refetch();
          setData(
            'SET_SUCCESS_MESSAGE',
            `The ${
              response?.archiveCommentingPeriod.userType
            } commenting period from ${moment(
              response.archiveCommentingPeriod.periodStart,
            ).format('MM/DD/YYYY')} to ${moment(
              response.archiveCommentingPeriod.periodEnd,
            ).format('MM/DD/YYYY')} has been archived.`,
          );
        }
      },
    },
  );

  return (
    <CommentsAdminContext.Provider
      value={{
        ...state,
        setData,
        setError,
        resetState,
        getMultipleOptions,
        getComments,
        addComment,
        updateComment,
        bulkReplyReview,
        bulkPublishComments,
        manageComment,
        deleteStandardsComment,
        getOpenAndRecentCommentingPeriods,
        archiveCommentingPeriod,
        ...props,
      }}
    >
      {children}
    </CommentsAdminContext.Provider>
  );
}

export default CommentsAdminProvider;

CommentsAdminProvider.defaultProps = {};

CommentsAdminProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useCommentsAdmin = () => useContext(CommentsAdminContext);
