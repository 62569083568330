/* eslint-disable react/prop-types */
import {
  AFPTable,
  AFPTableRowAction,
  Checkbox,
  CounterTag,
  Pagination,
  StatusBadge,
  FilterTableFrame,
  EmptyState,
} from '@gsa/afp-component-library';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import HtmlDiff from 'htmldiff-js';
import { isEmpty } from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import { useLocation } from 'react-router';
import queryString from 'query-string';

import CommentForm from '../../components/comment-form';
import CommentsTable from './comments-table';
import useDidMountEffect from '../../../../hooks/use-did-mount';
import RequirementsFilter from './requirements-filter';
import '../../../standard-items/styles/standard-items-table.scss';
import Truncate from '../../../../components/Truncate';
import DiffHighlighter from '../../components/diff-highlighter';
import TypeHighlighter from '../../components/type-highlighter';
import MinimumsHighlighter from '../../components/minimums-highlighter';
import useQuery from '../../../../hooks/useQuery';
import { useFederalStandards } from '../../fvs-provider';
import { useFvsCommentsContext } from '../../fvs-comments-provider';
import { useFvsRequirements } from '../requirements-provider';
import '../styles/requirements.scss';
import { FVS_RT_ACTIONS, FVS_RT_MODAL_MODES } from '../constants';
import ConfirmComment from '../../doc-preview/confirm-comment';

const RequirementsTable = () => {
  const params = useParams();
  const { year, standardItemNumber } = params;

  const query = useQuery();

  const allowedActions = () => {
    const actions = [];
    actions.push({ icon: 'visibility', label: 'Comments' });
    return actions;
  };

  const {
    standardItem,
    selectedOptions,
    // selectedYear,
    setFvsContextData,
    isCommentingAllowed4Fvs4User,
  } = useFederalStandards();

  const {
    getEquipmentAssociations,
    getEquipmentAssociationsWithChanges,
    equipmentAssociations,
    groupMultipleOptions,
    doHighlightChanges,
    setFvsRequirementsContextData,
  } = useFvsRequirements();

  const { getStandardsComments, isDeletingComment } = useFvsCommentsContext();

  const location = useLocation();
  const { code, commentId } = queryString.parse(location.search);

  const defaultFilters = {
    operator: 'AND',
    value: [
      {
        key: '$standardItem.year$',
        operator: 'EQ',
        value: year,
      },
      {
        key: '$standardItem.standard_item_number$',
        operator: 'EQ',
        value: standardItemNumber,
      },
    ],
  };

  const [order, setOrder] = useState('');
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });
  const [filters, setFilters] = useState(defaultFilters);
  const [tableData, setTableData] = useState([]);
  const tableRef = React.createRef();
  const [showFilters] = useState(true);

  useEffect(() => {
    if (commentId && !isEmpty(equipmentAssociations?.rows)) {
      setFvsRequirementsContextData(
        FVS_RT_ACTIONS.SET_EQUIPMENT_ASSOCIATION,
        equipmentAssociations.rows[0],
      );
      setFvsRequirementsContextData(
        FVS_RT_ACTIONS.SET_RT_MODAL_MODE,
        FVS_RT_MODAL_MODES.COMMENT,
      );
    }
  }, [equipmentAssociations?.rows]);

  useEffect(() => {
    if (code) {
      setFvsContextData('SET_EQUIPMENT_CODE_QUERY_STRING', code);
    }
  }, [code]);

  const getAssociationData = () => {
    const { limit, offset } = paginationState;

    if (doHighlightChanges) {
      getEquipmentAssociationsWithChanges({
        variables: { filters, limit, offset, order },
      });
    } else {
      getEquipmentAssociations({
        variables: { filters, limit, offset, order },
      });
    }
  };

  const loadComments = () => {
    const filter = {
      operator: 'AND',
      value: [
        {
          key: 'linkedEntityId',
          operator: 'EQ',
          value: standardItem?.id,
        },
      ],
    };

    filter.value.push({
      key: 'status',
      operator: 'NOTIN',
      value: [4],
    });
    getStandardsComments?.({
      variables: {
        filters: filter,
      },
    });
  };

  useEffect(() => {
    loadComments();
  }, [filters]);

  const updateFiltersOnVehicleGroupChange = () => {
    return {
      ...filters,
      value: filters?.value?.map((filter) => {
        if (filter.key === '$standardItem.standard_item_number$') {
          return { ...filter, value: selectedOptions?.standardItemNumber };
        }
        return filter;
      }),
    };
  };

  useEffect(() => {
    const updatedFilter = updateFiltersOnVehicleGroupChange();
    setFilters(updatedFilter);
  }, [selectedOptions?.standardItemNumber, standardItemNumber]);

  useEffect(() => {
    if (isEmpty(standardItem?.standardItemNumber)) {
      setTableData([]);
    }
  }, [standardItem?.standardItemNumber]);

  useEffect(() => {
    getAssociationData();
  }, []);

  useDidMountEffect(() => {
    getAssociationData();
  }, [
    filters,
    order,
    paginationState,
    doHighlightChanges,
    groupMultipleOptions,
    isDeletingComment,
    // equipmentCodeQueryString,
  ]);
  const handleSelectedAction = useCallback((evt, row) => {
    if (evt === 'Comments' && row.isExpanded === undefined) {
      row.toggleRowExpanded();
    } else if (evt === 'Edit Equipment Code Association' || evt === 'Remove') {
      const { original: equipmentAssociation } = row;
      setFvsRequirementsContextData(
        'SET_EQUIPMENT_ASSOCIATION',
        equipmentAssociation,
      );
      setFvsRequirementsContextData(
        'SET_RT_MODAL_MODE',
        evt === 'Remove'
          ? FVS_RT_MODAL_MODES.CONFIRM_DELETE_ASSOCIATION
          : FVS_RT_MODAL_MODES.EDIT_ASSOCIATION,
      );
    }
    return null;
  }, []);

  const getCategoryTitle = (cat) => {
    const equipmentCodeCategory = groupMultipleOptions?.category?.find(
      (c) => c.value === cat,
    );
    return equipmentCodeCategory?.label;
  };

  const updateEquipmentAssociationsWithCategoryTitle = () => {
    return equipmentAssociations?.rows?.map((ea) => {
      const { comments } = ea;
      const commentsCount = comments?.length;
      return {
        ...ea,
        categoryTitle: getCategoryTitle(ea?.equipmentCode?.category),
        comments,
        commentsCount,
      };
    });
  };

  useEffect(() => {
    const newData = updateEquipmentAssociationsWithCategoryTitle();
    setTableData(newData);
  }, [equipmentAssociations?.rows, groupMultipleOptions]);

  useEffect(() => {
    const ec = query?.get('equipmentCode');
    const type = query?.get('type');
    if (type === 'ec') {
      const filter = {
        operator: 'AND',
        value: [
          {
            key: '$standardItem.year$',
            operator: 'EQ',
            value: year,
          },
          {
            key: '$standardItem.standard_item_number$',
            operator: 'EQ',
            value: selectedOptions.standardItemNumber,
          },
          {
            key: '$equipmentCode.code$',
            operator: 'EQ',
            value: `${ec}`,
          },
        ],
      };

      setFilters(filter);
    } else if (type === 'si') {
      // TODO
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'equipmentCode.title',
        Cell: ({ value, row }) => {
          return (
            <>
              {row?.original?.change?.isInserted === 'Yes' && (
                <span className="padding-right-1">
                  <StatusBadge variant="Ready-Gray">New</StatusBadge>
                </span>
              )}
              <DiffHighlighter
                entity={row?.original?.equipmentCode}
                value={value}
                fieldName="title"
              />
            </>
          );
        },
      },
      {
        Header: 'Standard details',
        accessor: 'associationText',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value, row }) => {
          return (
            <DiffHighlighter
              entity={row?.original}
              value={value}
              fieldName="associationText"
            />
          );
        },
      },
      {
        Header: 'Minimums',
        accessor: 'inputType.title',
        Cell: (props) => <MinimumsHighlighter {...props} />,
      },
      {
        Header: 'Type',
        accessor: 'associationType.title',
        Cell: ({ value, row }) => {
          return (
            <TypeHighlighter
              entity={row?.original}
              value={value}
              fieldName="associationTypeCode"
            />
          );
        },
      },
      {
        Header: 'Category',
        accessor: 'categoryTitle',
        Cell: ({ row }) => {
          return (
            <DiffHighlighter
              entity={row?.original?.equipmentCode}
              value={row?.original?.equipmentCode?.category}
              fieldName="category"
            />
          );
        },
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          const count = value?.length;
          if (count > 0) {
            return <CounterTag count={count} />;
          }
          return null;
        },
      },
      {
        Header: 'Actions',
        sortable: false,
        Cell: (props) => (
          <AFPTableRowAction
            {...props}
            actions={allowedActions()}
            // eslint-disable-next-line react/prop-types
            onSelectAction={(evt) => handleSelectedAction(evt, props.row)}
          />
        ),
      },
    ],
    [],
  );

  const renderRowSubComponent = useCallback(
    ({ row: { original } }) => {
      const filteredComments = original?.comments?.filter((comment) => {
        return comment.status !== 'Invalid';
      });

      return (
        <div className="">
          <div className="grid-row grid-gap">
            <div className="tablet:grid-col">
              <div className="grid-row">
                <div className="tablet:grid-col">
                  <div className="text-bold">
                    <DiffHighlighter
                      entity={original?.equipmentCode}
                      value={original?.equipmentCode?.title}
                      fieldName="title"
                    />
                  </div>
                  <div className="tablet:grid-col">
                    {original?.equipmentCode?.content?.content &&
                      doHighlightChanges &&
                      ReactHtmlParser(`<div>
                    ${HtmlDiff.execute(
                      original?.equipmentCode?.change?.prevVersionDesc,
                      original?.equipmentCode?.content?.content,
                    )}
                    </div>`)}

                    {original?.equipmentCode?.content?.content &&
                      !doHighlightChanges && (
                        <Truncate
                          content={original?.equipmentCode?.content?.content}
                          size={250}
                        />
                      )}

                    {!original?.equipmentCode?.content?.content && (
                      <div className="text-bold">&ndash;</div>
                    )}
                  </div>
                  <div className="tablet:grid-col margin-top-3">
                    <div className="text-bold">Equipment Code</div>
                    {original?.equipmentCode?.code ? (
                      <div>{original?.equipmentCode?.code}</div>
                    ) : (
                      <span>&ndash;</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {isCommentingAllowed4Fvs4User && (
              <div className="tablet:grid-col">
                <CommentForm
                  linkedEntityId={Number(original?.id)}
                  linkedEntityType={2}
                  fvsCode={selectedOptions?.fedStandardCode}
                  contractYear={year}
                />
              </div>
            )}
          </div>
          {filteredComments?.length ? (
            <CommentsTable comments={filteredComments} rowDetail={original} />
          ) : null}
        </div>
      );
    },
    [isCommentingAllowed4Fvs4User],
  );

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;

    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  useDidMountEffect(() => {
    handlePaginationChange(1, paginationState.limit);
    getAssociationData();
  }, [filters]);

  const TableWrapper = ({ tableProps, paginationProps }) => (
    <>
      {/* <AFPTable {...tableProps} /> */}
      <AFPTable
        expandable
        columns={tableProps.columns}
        data={tableProps.data || []}
        renderRowSubComponent={tableProps.renderRowSubComponent}
        onSort={tableProps.onSort}
        defaultSort={tableProps.defaultSort}
        fullWidth
        ref={tableProps.ref}
      />
      {tableProps.data?.length ? <Pagination {...paginationProps} /> : null}
      {tableProps.data === null ||
        (tableProps.data?.length === 0 && (
          <div
            className="text-center margin-top-neg-2 height-full"
            style={{ height: '165vh' }}
          >
            <EmptyState
              hasBackground
              containerStyles="padding-top-9 height-full"
              topText={
                <>
                  <strong>
                    No Equipment Codes are associated with this Standard Item
                  </strong>
                </>
              }
            />
          </div>
        ))}
    </>
  );

  const HighLightDifference = ({ onChange, value, defaultChecked }) => (
    <Checkbox
      className="float-right"
      label="Highlight differences compared to last year"
      name="chbox_highlight"
      value={value}
      data-testid="requirements-table-highlight-changes"
      onChange={onChange}
      defaultChecked={defaultChecked}
    />
  );

  const FTF = useMemo(
    () =>
      FilterTableFrame(
        null,
        HighLightDifference,
        RequirementsFilter,
        TableWrapper,
      ),
    [],
  );

  return (
    <>
      <div className="grid-row grid-gap margin-top-3">
        <div className="desktop:grid-col-6 tablet-lg:grid-col-6 ">
          {/* eslint-disable-next-line */}
          <h2 tabIndex="0" aria-label="Requirements Table">
            Requirements Table
          </h2>
        </div>
      </div>
      <ConfirmComment tokenName="equipmentCodeCommentToken" />
      <FTF
        upRightProps={{
          onChange: () => {
            setFvsRequirementsContextData(
              'SET_HIGHLIGHT_CHANGES',
              !doHighlightChanges,
            );
          },
          value: doHighlightChanges,
          defaultChecked: false,
        }}
        lowLeftProps={{
          // onFilter: (filterValue) => setFilters(filterValue.filters),
          setFilters,
        }}
        lowRightProps={{
          tableProps: {
            expandable: true,
            columns,
            data: tableData || [],
            renderRowSubComponent,
            onSort: setOrder,
            defaultSort: order,
            fullWidth: true,
            ref: tableRef,
          },
          paginationProps: {
            itemsPerPageOptions: [10, 25, 50],
            onPageChange: handlePaginationChange,
            variant: 'advanced',
            itemsPerPage: paginationState.limit,
            currentPage: paginationState.currentPage,
            itemsCount: equipmentAssociations?.count,
          },
        }}
        filterToggle={showFilters}
      />
    </>
  );
};

export default RequirementsTable;
