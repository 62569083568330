/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import PropTypes from 'prop-types';

const WelcomeMessage = ({ message }) => {
  return (
    <div
      data-testid="message"
      className="usa-prose margin-bottom-2"
      tabIndex="0"
    >
      <p>{message}</p>
    </div>
  );
};

WelcomeMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default WelcomeMessage;
