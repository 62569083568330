/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import { Button, useModal } from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import { SUBMIT_BID_LINES } from '../bid-line-details/provider/queries';
import BidMakeAndModelWrapper from '../si-filter-table-frame/make-and-model/index';
import { StandardItemShape } from '../si-filter-table-frame/make-and-model/add-makes-and-models-modal';
import {
  lineItemTableSelectedItems,
  lineItemsAtom,
} from '../../bids/atoms/bids-atoms';

const addIconProps = {
  name: 'add',
};

const LineItemsSubmitAddButtons = ({
  siData,
  refetchData,
  setToaster,
  isBidClosed,
}) => {
  const [selectedItems] = useRecoilState(lineItemTableSelectedItems);
  const [allLineItems] = useRecoilState(lineItemsAtom);

  const {
    isOpen: isMakeModelOpen,
    openModal: openMakeModelModal,
    closeModal: closeMakeModelModal,
  } = useModal();

  const onCloseMakeModelModal = (toRefetch) => {
    closeMakeModelModal();
    if (toRefetch === true && refetchData) refetchData();
  };

  const { solId } = useParams();

  const [submitBidLines, { loading }] = useMutation(SUBMIT_BID_LINES, {
    onCompleted: (data) => {
      if (data.errors) {
        setToaster({
          type: 'error',
          message: 'There was an error submitting the selected line items.',
        });
        return;
      }
      const lineItemErrors = data.submitBidLines.filter((i) => i.error);

      if (lineItemErrors.length > 0) {
        setToaster({
          type: 'error',
          message: (
            <div>
              The following selected items could not be submitted:
              <ul style={{ paddingTop: 0, marginTop: 0 }}>
                {lineItemErrors.map((itemWithError) => {
                  const lineItemData = allLineItems?.find(
                    (i) => i.id === String(itemWithError.bidLineId),
                  );
                  return (
                    <li>
                      Line item {lineItemData.scheduleLine} -{' '}
                      {itemWithError.error}
                    </li>
                  );
                })}
              </ul>
            </div>
          ),
        });
        refetchData();
        return;
      }

      setToaster({
        type: 'success',
        message: 'The selected line items have been submitted.',
      });
      refetchData();
    },
    onError: (error) => {
      setToaster({
        type: 'error',
        message:
          error.message ||
          'There was an error submitting the selected line items.',
      });
    },
  });

  const handleSubmitSelectedItems = useCallback(() => {
    submitBidLines({
      variables: {
        bidLineIds: selectedItems?.map((id) => parseInt(id, 10)),
        solId: parseInt(solId, 10),
      },
    });
  }, [submitBidLines, selectedItems, solId]);

  return (
    <section className="display-flex flex-justify">
      {!isBidClosed && (
        <>
          <Button
            variant="primary"
            label={loading ? 'Submitting...' : 'Submit Line Items selected'}
            className="usa-button"
            disabled={selectedItems?.length === 0 || loading}
            onClick={handleSubmitSelectedItems}
          />
          <Button
            leftIcon={addIconProps}
            variant="outline"
            label="Add Line Items"
            className="usa-button"
            onClick={openMakeModelModal}
          />
          <BidMakeAndModelWrapper
            isOpen={isMakeModelOpen}
            initialData={{
              siData,
              genericSIModal: true,
            }}
            onClose={onCloseMakeModelModal}
            modalTitle="Add line item"
          />
        </>
      )}
    </section>
  );
};

LineItemsSubmitAddButtons.propTypes = {
  siData: PropTypes.arrayOf(StandardItemShape).isRequired,
  isBidClosed: PropTypes.bool.isRequired,
  refetchData: PropTypes.func,
};

LineItemsSubmitAddButtons.defaultProps = {
  refetchData: () => {},
};

export default LineItemsSubmitAddButtons;
