import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import {
  EQUIPMENT_CODES,
  GET_MULTIPLE_OPTIONS,
  GET_OPTIONS,
  UPDATE_EQUIPMENT_CODE,
  GET_EQUIPMENT_ASSOCIATIONS,
  UPDATE_EQUIPMENT_ASSOCIATION,
  ADD_EQUIPMENT_ASSOCIATION,
  DELETE_EQUIPMENT_ASSOCIATION,
  COPY_EQUIPMENT_ASSOCIATIONS,
  OVERWRITE_EQUIPMENT_CODE,
} from '../../services/data-store';
import groupMultipleOptions from '../../utilities/options-helper';

export const EquipmentCodeDetailContext = createContext();

export const MODAL_MODES = {
  ADD: 'add',
  EDIT: 'edit',
  COPY: 'copy',
  APPEND: 'append',
  REPLACE: 'replace',
  ADD_ASSOCIATION: 'add_association',
  EDIT_ASSOCIATION: 'edit_association',
  REMOVE_ASSOCIATION: 'remove_association',
  CONFIRM_COPY: 'confirm_copy',
  CONFIRM_APPEND: 'confirm_append',
  CONFIRM_UPDATE: 'confirm_update',
  CONFIRM_BULK_DELETE: 'confirm_bulk_delete',
};

const initialState = {
  MODAL_MODES,
  errors: null,
  modalMode: null,
  showFormModal: false,
  showEditor: false,
  selectedEquipmentCode: null,
  updatingEquipmentCode: null,
  multipleOptions: [],
  groupMultipleOptions: null,
  associationList: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  options: [],
  successMessage: '',
  errorMessage: '',
  selectedYearFilter: `${new Date().getFullYear()}`,
  equipmentAssociation: null,
  selectedAssociations: [],
  copyAssociation: {},
  selectAllSelected: false,
  clearCheckBox: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTALL_SELECTED':
      return { ...state, selectAllSelected: action.payload };
    case 'SET_CLEAR_CHECKBOX':
      return { ...state, clearCheckBox: action.payload };
    case 'SET_EQUIPMENT_CODE':
      return { ...state, selectedEquipmentCode: action.payload };
    case 'SET_SELECTED_FILTER_YEAR':
      return { ...state, selectedYearFilter: action.payload };
    case 'SET_MULTIPLE_OPTIONS':
      return { ...state, multipleOptions: action.payload };
    case 'SET_GROUP_MULTIPLE_OPTIONS':
      return { ...state, groupMultipleOptions: action.payload };
    case 'SET_MODAL_MODE': {
      return { ...state, modalMode: action.payload };
    }
    case 'SET_SUCCESS_MESSAGE':
      return { ...state, successMessage: action.payload };
    case 'SET_OPTIONS':
      return { ...state, options: action.payload };
    case 'SET_FORM_MODAL': {
      return { ...state, showFormModal: action.payload };
    }
    case 'SET_SHOW_EDITOR': {
      return { ...state, showEditor: action.payload };
    }
    case 'SET_ASSOCIATION_LIST':
      return { ...state, associationList: action.payload };
    case 'SET_EQUIPMENT_ASSOCIATION':
      return { ...state, equipmentAssociation: action.payload };
    case 'SET_EQUIPMENT_ASSOCIATION_SELECTIONS':
      return { ...state, selectedAssociations: action.payload };
    case 'SET_EQUIPMENT_ASSOCIATION_COPY':
      return { ...state, copyAssociation: action.payload };
    case 'SET_ERROR_MESSAGE': {
      return { ...state, errorMessage: action.payload };
    }
    case 'RESET': {
      return initialState;
    }
    case 'SET_UPDATING_EQUIPMENT_CODE':
      return { ...state, updatingEquipmentCode: action.payload };
    default:
      return state;
  }
};

function EquipmentCodeDetailProvider({ children, selected, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const setData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };
  const setError = (type, payload) => {
    dispatch({
      type: 'SET_ERROR',
      payload: { ...state.errors, [type]: payload },
    });
  };
  const resetState = () => {
    setData('RESET');
  };

  useEffect(() => {
    if (selected?.id) {
      setData('SET_EQUIPMENT_CODE', selected);
    }
  }, [selected]);

  // Get Multiple Options
  const [getMultipleOptions] = useLazyQuery(GET_MULTIPLE_OPTIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (requestError) => {
      setError(
        'GET_MULTIPLE_OPTIONS',
        requestError.message ?? 'Unknown Error.',
      );
    },
    onCompleted: (multipleOptionsData) => {
      if (multipleOptionsData?.getMultipleOptions) {
        setData(
          'SET_MULTIPLE_OPTIONS',
          multipleOptionsData?.getMultipleOptions,
        );
        setData(
          'SET_GROUP_MULTIPLE_OPTIONS',
          groupMultipleOptions(multipleOptionsData?.getMultipleOptions),
        );
        setError('GET_MULTIPLE_OPTIONS', '');
      }
    },
  });

  // Get Options
  const [getOptions] = useLazyQuery(GET_OPTIONS, {
    onError: (requestError) => {
      setError('GET_OPTIONS', requestError.message ?? 'Unknown Error.');
    },
    onCompleted: (optionsData) => {
      if (optionsData?.getOptions) {
        setData('SET_OPTIONS', optionsData.getOptions);
        setError('GET_OPTIONS', '');
      }
    },
  });

  const [getEquipmentCode] = useLazyQuery(EQUIPMENT_CODES, {
    fetchPolicy: 'network-only',
    onError: (requestError) => {
      setError('GET_EQUIPMENT_CODE', requestError.message ?? 'Unknown Error.');
    },
    onCompleted: (response) => {
      if (response?.getEquipmentCodes) {
        setData('SET_EQUIPMENT_CODE', response?.getEquipmentCodes?.rows[0]);
        setError('GET_EQUIPMENT_CODE', '');
      }
    },
  });

  const [updateEquipmentCode] = useMutation(UPDATE_EQUIPMENT_CODE, {
    onError: (requestError) => {
      // eslint-disable-next-line
      String(requestError)?.indexOf('Please enter a unique code') > -1
        ? setData('SET_MODAL_MODE', MODAL_MODES.CONFIRM_UPDATE)
        : setError(
            'UPDATE_EQUIPMENT_CODE',
            requestError.message ?? 'Unknown Error.',
          );
    },
    onCompleted: (equipmentCodeResponse) => {
      if (equipmentCodeResponse?.updateEquipmentCode) {
        const { code, title, id } = equipmentCodeResponse?.updateEquipmentCode;
        const message = `Successfully updated Equipment Code ${code} - ${title}`;

        // Reset
        setData('SET_SUCCESS_MESSAGE', message);
        setData('SET_FORM_MODAL', false);
        setError('UPDATE_EQUIPMENT_CODE', '');
        setData('SET_SHOW_EDITOR', initialState.showEditor);
        getEquipmentCode({
          variables: { filters: { operator: 'EQ', key: 'id', value: id } },
        });
      }
    },
  });

  // Overwrite Equipment Code
  const [overwriteEquipmentCode] = useMutation(OVERWRITE_EQUIPMENT_CODE, {
    onError: (requestError) => {
      setError(
        'UPDATE_EQUIPMENT_CODE',
        requestError.message ?? 'Unknown Error.',
      );
    },
    onCompleted: (equipmentCodeResponse) => {
      if (equipmentCodeResponse?.overwriteEquipmentCode) {
        const {
          code,
          title,
          id,
        } = equipmentCodeResponse?.overwriteEquipmentCode;
        const message = `Successfully updated Equipment Code ${code} - ${title}`;
        // Reset
        setData('SET_SUCCESS_MESSAGE', message);
        setData('SET_FORM_MODAL', false);
        setError('UPDATE_EQUIPMENT_CODE', '');
        setData('SET_SHOW_EDITOR', initialState.showEditor);
        getEquipmentCode({
          variables: { filters: { operator: 'EQ', key: 'id', value: id } },
        });
      }
    },
  });

  // Get Associations lazy.
  const [getEquipmentAssociations, { refetch }] = useLazyQuery(
    GET_EQUIPMENT_ASSOCIATIONS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (requestError) => {
        setError(
          'GET_EQUIPMENT_ASSOCIATIONS',
          requestError.message ?? 'Unknown Error.',
        );
      },
      onCompleted: (responseData) => {
        if (responseData?.getEquipmentAssociations) {
          setData(
            'SET_ASSOCIATION_LIST',
            responseData.getEquipmentAssociations,
          );
          setError('GET_EQUIPMENT_ASSOCIATIONS', '');
        }
      },
    },
  );

  // Add Association
  const [addEquipmentAssociation] = useMutation(ADD_EQUIPMENT_ASSOCIATION, {
    onError: (requestError) => {
      setError(
        'ADD_EQUIPMENT_ASSOCIATION',
        requestError.message ?? 'Unknown Error.',
      );
      setData('SET_ERROR_MESSAGE', requestError?.message);
    },
    onCompleted: () => {
      refetch();
      setData('SET_FORM_MODAL', false);
      setData('SET_ERROR_MESSAGE', '');
      setError('ADD_EQUIPMENT_ASSOCIATION', '');
    },
  });

  // Update Association
  const [updateEquipmentAssociation] = useMutation(
    UPDATE_EQUIPMENT_ASSOCIATION,
    {
      onError: (requestError) => {
        setError(
          'UPDATE_EQUIPMENT_ASSOCIATION',
          requestError.message ?? 'Unknown Error.',
        );
        setData('SET_ERROR_MESSAGE', requestError?.message);
      },
      onCompleted: () => {
        setData('SET_FORM_MODAL', false);
        setData('SET_ERROR_MESSAGE', '');
        refetch();
        const message = `You have successfully edited Standard Item ${state.equipmentAssociation?.standardItem?.standardItemNumber} in Equipment Code ${state.selectedEquipmentCode?.code}`;
        setData('SET_SUCCESS_MESSAGE', message);
        setData('SET_EQUIPMENT_ASSOCIATION', null);
        setError('UPDATE_EQUIPMENT_ASSOCIATION', '');
      },
    },
  );

  // Delete Association
  const [deleteEquipmentAssociation] = useMutation(
    DELETE_EQUIPMENT_ASSOCIATION,
    {
      onError: (requestError) => {
        setError(
          'DELETE_EQUIPMENT_ASSOCIATION',
          requestError.message ?? 'Unknown Error.',
        );
      },
      onCompleted: () => {
        setData('SET_FORM_MODAL', false);
        refetch();
        let message = '';
        if (state.selectedAssociations.length) {
          message = `You have successfully deleted ${state.selectedAssociations.length} Standard Items from Equipment Code ${state.selectedEquipmentCode?.code}`;
        } else {
          message = `You have successfully deleted Standard Item ${state.equipmentAssociation?.standardItem?.standardItemNumber} from Equipment Code ${state.selectedEquipmentCode?.code}`;
        }
        setData('SET_SUCCESS_MESSAGE', message);
        setData('SET_EQUIPMENT_ASSOCIATION', null);
        setError('DELETE_EQUIPMENT_ASSOCIATION', '');
      },
    },
  );

  // Copy Association
  const [copyEquipmentAssociation] = useMutation(COPY_EQUIPMENT_ASSOCIATIONS, {
    onError: (requestError) => {
      setError(
        'COPY_EQUIPMENT_ASSOCIATION',
        requestError.message ?? 'Unknown Error.',
      );
    },
    onCompleted: (copyResponse) => {
      if (copyResponse?.copyAssociations) {
        const { selectedEquipmentCode, copyAssociation } = state;

        const fromEquipmentCode = selectedEquipmentCode?.code;
        const toEquipmentCode = copyAssociation?.toEquipmentCode?.label;
        const selectedCount = copyAssociation?.associationsToCopy?.length ?? 0;
        const message = `You have successfully copied ${selectedCount} Standard Items from Equipment Code ${fromEquipmentCode} to Equipment Code ${toEquipmentCode}.`;

        // Reset
        setData('SET_SUCCESS_MESSAGE', message);
        setData('SET_FORM_MODAL', initialState.showFormModal);
        setData('SET_EQUIPMENT_ASSOCIATION_COPY', initialState.copyAssociation);
        setData('SET_MODAL_MODE', initialState.modalMode);
        setError('COPY_EQUIPMENT_ASSOCIATION', '');
        setData('SET_SELECTALL_SELECTED', false);
        setData('SET_CLEAR_CHECKBOX', true);
      }
    },
  });

  return (
    <EquipmentCodeDetailContext.Provider
      value={{
        ...state,
        setData,
        setError,
        resetState,
        getOptions,
        getMultipleOptions,
        updateEquipmentCode,
        getEquipmentAssociations,
        addEquipmentAssociation,
        updateEquipmentAssociation,
        deleteEquipmentAssociation,
        copyEquipmentAssociation,
        overwriteEquipmentCode,
        ...props,
      }}
    >
      {children}
    </EquipmentCodeDetailContext.Provider>
  );
}

export default EquipmentCodeDetailProvider;

EquipmentCodeDetailProvider.defaultProps = {
  selected: null,
};

EquipmentCodeDetailProvider.propTypes = {
  children: PropTypes.element.isRequired,
  selected: PropTypes.shape(Object),
};

export const useEquipmentDetailCodes = () =>
  useContext(EquipmentCodeDetailContext);
