import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import {
  PageTitle,
  Spinner,
  ErrorMessage,
  TabSet,
  Alert,
  Button,
  Icon,
} from '@gsa/afp-component-library';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import { useBidLineDetails } from './provider/bid-line-details-provider';
import MinimumRequirements from './minimum-requirements/minimum-requirements';
import OptionalEquipment from './optional-equipment/optional-equipment';
import EngineAndFuel from './engine-and-fuel/engine-and-fuel';
import ColorsTab from './colors/colors-tab';
import DocumentationTab from './documentation/documentation-tab';
import BidDetails from './details/index';
import CopyLineItemModal from './copy-line-item/copy-line-item-modal';
import { isBidClosed, isBidClosedDateToday } from './bid-line-utils';
import BidClosedDateChecker from './check-bid-close-date';

const BidLineDetailsContent = () => {
  const history = useHistory();
  const { solId, bidId } = useParams();

  const {
    solicitation,
    bidLine,
    loading,
    loadingError,
    alert,
    setAlert,
    tabIndex,
    setTabIndex,
    tabStatuses,
    hasSomeHighlights,
    hasSomeHighlightByTabKey,
    isShowHighlights,
    setIsShowHighlights,
  } = useBidLineDetails();

  const [copyTabKey, setCopyTabKey] = useState(null);
  const openCopyModal = (tabKey) => setCopyTabKey(tabKey);
  const closeCopyModal = () => setCopyTabKey(null);

  if (loading) return <Spinner size="medium" className="margin-top-6" />;

  if (loadingError)
    return (
      <ErrorMessage>
        Error occurred while loading data: {loadingError.message}
      </ErrorMessage>
    );

  if (!bidLine) {
    return (
      <Alert
        type="info"
        title="No Bid Line Found"
        description="No bid line found for this solicitation."
      />
    );
  }

  const { standardItem, scheduleLine } = bidLine;
  const bidEndDate = bidLine.bid.solicitationPeriod?.endDate;

  const highlightTabClasses = classNames({
    'highlight-tab-1': hasSomeHighlightByTabKey('detail'),
    'highlight-tab-2': hasSomeHighlightByTabKey('min'),
    'highlight-tab-3': hasSomeHighlightByTabKey('optional'),
    'highlight-tab-4': hasSomeHighlightByTabKey('engine'),
    'highlight-tab-5': hasSomeHighlightByTabKey('color'),
  });

  return (
    <>
      <Breadcrumbs
        path={[
          {
            location: `${window.AFP_CONFIG.appURLs.home}/home`,
            label: 'Home',
          },
          {
            location: `/catalog/bids`,
            label: 'Bids Management',
          },
          {
            location: `/catalog/solicitations/${solId}/bid-dashboard/${bidId}`,
            label: `Bid Dashboard: ${solicitation.title}`,
          },
        ]}
        current={`Line Item ${scheduleLine}`}
      />

      {!isBidClosed(bidLine?.bid?.solicitationPeriod) ? (
        <>
          {isBidClosedDateToday(bidEndDate) && (
            <BidClosedDateChecker
              closedDate={bidEndDate}
              solId={solId}
              bidId={bidId}
              setAlert={setAlert}
            />
          )}
          {alert && (
            <Alert
              type={alert.type}
              slim
              focused
              className="margin-top-0"
              showClose
              onClose={() => setAlert(null)}
              actions={
                <div className="position-absolute top-1 right-1 cursor-pointer">
                  <Icon
                    data-testid="alert-close"
                    iconName="close"
                    onClick={() => setAlert(null)}
                    className="text-large text-heavy font-sans-xl text-gray-500"
                  />
                </div>
              }
            >
              {alert.message}
            </Alert>
          )}
          {hasSomeHighlights && (
            <Alert type="warning" className="margin-top-2" title="Highlights">
              The data for this line item has been imported or copied from
              another line item and requirements may have changed.
              <br />
              Click the “Highlight modified items” to view the modified fields
              highlighted in yellow. Please review and update as needed.
              <div className="margin-top-2 flex-align-start flex-justify-start">
                {isShowHighlights === true && (
                  <Button
                    data-testid="hide-highlights-btn"
                    variant="unstyled"
                    onClick={() => setIsShowHighlights(false)}
                    label="Hide highlights"
                  />
                )}
                {isShowHighlights === false && (
                  <Button
                    variant="primary"
                    onClick={() => setIsShowHighlights(true)}
                    label="Highlight modified items"
                  />
                )}
              </div>
            </Alert>
          )}
          <div className="display-flex flex-justify flex-align-end margin-bottom-4">
            <div
              style={{
                fontWeight: 400,
                fontSize: '24px',
                lineHeight: '135%',
                color: '#565C65',
              }}
            >
              <PageTitle
                title={`Line Item ${scheduleLine}`}
                aria-label={`Line Item ${scheduleLine}`}
                className="margin-bottom-2"
              />
              {standardItem?.title}
            </div>
            <div>
              <div className="text-right margin-right-1 margin-bottom-2">
                Your last edits are{' '}
                <span className="text-info-darker text-bold">
                  <Icon iconName="check" className="text-middle" />
                  AUTOSAVED
                </span>
              </div>
              <div>
                <Button
                  onClick={() => {
                    history.push(
                      `/catalog/solicitations/${solId}/bid-dashboard/${bidId}`,
                    );
                  }}
                  label="Bid Dashboard"
                  variant="unstyled"
                  leftIcon={{ name: 'arrow_back', type: '', className: '' }}
                  className="margin-right-2"
                />
                <Button
                  label="Review and Submit"
                  onClick={() => {
                    history.push(
                      `/catalog/solicitations/${solId}/bid-dashboard/${bidId}/bid-line-details/${bidLine.id}/review-and-submit`,
                    );
                  }}
                />
              </div>
            </div>
          </div>

          <div className={highlightTabClasses}>
            <TabSet
              onSelect={setTabIndex}
              tabs={[
                {
                  heading: 'Bid detail',
                  content: <BidDetails openCopyModal={openCopyModal} />,
                  tabSelectedWhenOpen: tabIndex < 1 || tabIndex > 5,
                  ready: tabStatuses[0],
                },
                {
                  heading: 'Minimum requirements',
                  content: (
                    <MinimumRequirements openCopyModal={openCopyModal} />
                  ),
                  tabSelectedWhenOpen: tabIndex === 1,
                  ready: tabStatuses[1],
                },
                {
                  heading: 'Optional equipment',
                  content: <OptionalEquipment openCopyModal={openCopyModal} />,
                  tabSelectedWhenOpen: tabIndex === 2,
                  ready: tabStatuses[2],
                },
                {
                  heading: 'Engine and fuel',
                  content: <EngineAndFuel openCopyModal={openCopyModal} />,
                  tabSelectedWhenOpen: tabIndex === 3,
                  ready: tabStatuses[3],
                },
                {
                  heading: 'Colors',
                  content: <ColorsTab openCopyModal={openCopyModal} />,
                  tabSelectedWhenOpen: tabIndex === 4,
                  ready: tabStatuses[4],
                },
                {
                  heading: 'Documentation',
                  content: <DocumentationTab openCopyModal={openCopyModal} />,
                  tabSelectedWhenOpen: tabIndex === 5,
                  ready: tabStatuses[5],
                },
              ]}
            />
          </div>

          {copyTabKey && (
            <CopyLineItemModal
              bidLine={bidLine}
              tabKey={copyTabKey}
              onClose={closeCopyModal}
              setAlert={setAlert}
            />
          )}
        </>
      ) : (
        // If the Bid is closed redirect to review and submit page
        history.push(
          `/catalog/solicitations/${solId}/bid-dashboard/${bidId}/bid-line-details/${bidLine.id}/review-and-submit`,
        )
      )}
    </>
  );
};

export default BidLineDetailsContent;
