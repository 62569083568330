/* eslint-disable react/prop-types */
import React from 'react';
import { SelectDropdown } from '@gsa/afp-component-library';
import './make-model-table.scss';

import TypeAhead from '../../../../contract/contract-line/make-model/typeahead';
import MakeAndModelHeader from './make-and-model-header';
import { useFvsPreBidContext } from '../../../fvs-pre-bid-selection-provider';
import { ReactComponent as Info } from './info.svg';

const MakeAndModelSearchPanel = ({ hasChassis, handleAddMakeAndModel }) => {
  const {
    bodyMake,
    setBodyMake,
    bodyMakeInputValue,
    setBodyMakeInputValue,
    bodyMakeOptions,
    setBodyMakeOptions,

    bodyModel,
    setBodyModel,
    bodyModelInputValue,
    setBodyModelInputValue,
    bodyModelOptions,
    setBodyModelOptions,

    chassisMake,
    setChassisMake,
    chassisMakeInputValue,
    setChassisMakeInputValue,
    chassisMakeOptions,
    setChassisMakeOptions,

    chassisModel,
    setChassisModel,
    chassisModelInputValue,
    setChassisModelInputValue,
    chassisModelOptions,
    setChassisModelOptions,

    modelYear,
    setModelYear,

    fetchMakes,
    fetchModels,

    getErrors,
    dirtyFields,
    setDirtyFields,
  } = useFvsPreBidContext();

  const headers = ['Make', 'Model', 'Chassis Make', 'Chassis Model'];
  const tableWidth = hasChassis ? 'chassisWidth' : 'makeAndModelWidth';

  const getModelYearRange = (previousYears, nextYears) => {
    const currentYear = new Date().getFullYear();
    const years = [{ value: '', label: '-Select-' }];
    for (
      let i = currentYear - previousYears;
      i <= currentYear + nextYears;
      i += 1
    ) {
      years.push({ value: i, label: i.toString() });
    }
    return years;
  };

  const makeOptionRenderer = (option) => {
    return (
      <>
        <div>
          <b>Make:</b> {option.label}
        </div>
        <div>
          <b>Code:</b>{' '}
          {option?.nhtsaMakeCode ? option?.nhtsaMakeCode : option.value}
        </div>
      </>
    );
  };

  const modelOptionRenderer = (option) => {
    return (
      <>
        <div>
          <b>Model:</b> {option.label}
        </div>
        <div>
          <b>Code:</b>{' '}
          {option?.nhtsaModelCode ? option?.nhtsaModelCode : option.value}
        </div>
      </>
    );
  };

  const errors = getErrors();
  const bodyMakeError = errors?.find((e) => e.field === 'bodyMake')?.message;
  const bodyModelError = errors?.find((e) => e.field === 'bodyModel')?.message;
  const chassisMakeError = errors?.find((e) => e.field === 'chassisMake')
    ?.message;
  const chassisModelError = errors?.find((e) => e.field === 'chassisModel')
    ?.message;

  return (
    <>
      <div className="introWrapper">
        <div>
          <strong>Make and Model</strong>
        </div>
        <div>
          <p>
            <strong>Select existing Make and Models:</strong> To select an
            existing Make and Model, you may begin typing and choose from the
            suggestions available in the dropdown list.
          </p>
          <p>
            <strong>Request new entry:</strong> For entries not found in the
            dropdown list, you may enter your new Make and Model and click
            ‘Add’. New entries will go through a review process and be listed as
            ‘Needs engineering review’ then ‘Engineering approved ’ if approved
            or ‘Engineering rejected’ if declined.
          </p>
          <p>
            <strong>Duplicate entry:</strong> Also, please note that duplicate
            entries are not allowed i.e. if you have already entered a
            particular Make and Model combination then you won`t be able to add
            it again.
          </p>
        </div>
      </div>

      <div className={`headerTitleWrapper ${tableWidth}`}>
        <div
          className="statusWrapper"
          style={{ width: hasChassis ? '218px' : '236px' }}
        >
          <p className="titleHeader">Status</p>
          <Info />
        </div>
        {headers.map((header, index) => {
          if (
            !hasChassis &&
            (header === 'Chassis Make' || header === 'Chassis Model')
          ) {
            return null;
          }
          return (
            <MakeAndModelHeader
              key={header}
              index={index}
              title={header}
              hasChassis={hasChassis}
              headers={header}
            />
          );
        })}
        <MakeAndModelHeader title="Model year" />
        <div className="makeAndModelActions">
          <p className="titleHeader">Actions</p>
        </div>
      </div>
      <div className={`searchInputWrapper ${tableWidth}`}>
        <div className="typeAheadWrapper">
          <TypeAhead
            disablePaste
            testId="make"
            placeholder="Enter make"
            preLoadHelpMsg="Type to search for makes"
            required
            options={bodyMakeOptions || []}
            setOptions={setBodyMakeOptions}
            selectedOption={bodyMake}
            inputValue={bodyMakeInputValue}
            setInputValue={(v) => {
              setBodyMakeInputValue(v);
              if (bodyModel || bodyModelInputValue) {
                setBodyModel(undefined);
                setBodyModelInputValue('');
                setBodyModelOptions([]);
              }
            }}
            setSelectedOption={setBodyMake}
            fetchOptions={fetchMakes}
            renderOptionLabel={makeOptionRenderer}
            onBlur={() => {
              setDirtyFields((prev) => [...prev, 'bodyMake']);
            }}
            errorMessage={
              dirtyFields?.includes('bodyMake') && bodyMakeError
                ? bodyMakeError
                : undefined
            }
          />
        </div>
        <div className="typeAheadWrapper">
          <TypeAhead
            testId="model"
            disablePaste
            placeholder="Enter model"
            preLoadHelpMsg="Type to search for models"
            required
            options={bodyModelOptions || []}
            setOptions={setBodyModelOptions}
            selectedOption={bodyModel}
            inputValue={bodyModelInputValue}
            setInputValue={setBodyModelInputValue}
            setSelectedOption={setBodyModel}
            fetchOptions={(inputValue, signal) => {
              return fetchModels(
                inputValue,
                bodyMake?.value ? parseInt(bodyMake?.value, 10) : -1,
                signal,
              );
            }}
            renderOptionLabel={modelOptionRenderer}
            responseProcessor={(response) => {
              return response.data?.getModelsByPartialMatch?.map((model) => ({
                value: model.value,
                label: model.label,
                fleetApprovalStatus: model?.fleetStatus,
                NHTSApprovalStatus: model?.approvalStatus,
                nhtsaModelCode: model?.nhtsaModelCode,
              }));
            }}
            onBlur={() => {
              setDirtyFields((prev) => [...prev, 'bodyModel']);
            }}
            errorMessage={
              dirtyFields?.includes('bodyModel') && bodyModelError
                ? bodyModelError
                : undefined
            }
          />
        </div>
        {hasChassis && (
          <div className="typeAheadWrapper">
            <TypeAhead
              disablePaste
              testId="chassisMake"
              placeholder="Enter chassis make"
              preLoadHelpMsg="Type to search for chassis makes"
              required
              options={chassisMakeOptions || []}
              setOptions={setChassisMakeOptions}
              selectedOption={chassisMake}
              inputValue={chassisMakeInputValue}
              setInputValue={(v) => {
                setChassisMakeInputValue(v);
                if (chassisModel || chassisModelInputValue) {
                  setChassisModel(undefined);
                  setChassisModelInputValue('');
                  setChassisModelOptions([]);
                }
              }}
              setSelectedOption={setChassisMake}
              fetchOptions={fetchMakes}
              renderOptionLabel={makeOptionRenderer}
              onBlur={() => {
                setDirtyFields((prev) => [...prev, 'chassisMake']);
              }}
              errorMessage={
                dirtyFields?.includes('chassisMake') && chassisMakeError
                  ? chassisMakeError
                  : undefined
              }
            />
          </div>
        )}
        {hasChassis && (
          <div className="typeAheadWrapper">
            <TypeAhead
              disablePaste
              testId="chassisModel"
              placeholder="Enter chassis model"
              preLoadHelpMsg="Type to search for chassis models"
              required
              options={chassisModelOptions || []}
              setOptions={setChassisModelOptions}
              selectedOption={chassisModel}
              inputValue={chassisModelInputValue}
              setInputValue={setChassisModelInputValue}
              setSelectedOption={setChassisModel}
              fetchOptions={(inputValue, signal) => {
                return fetchModels(
                  inputValue,
                  chassisMake?.value ? parseInt(chassisMake?.value, 10) : -1,
                  signal,
                );
              }}
              renderOptionLabel={modelOptionRenderer}
              responseProcessor={(response) => {
                return response.data?.getModelsByPartialMatch?.map((model) => ({
                  value: model.value,
                  label: model.label,
                  fleetApprovalStatus: model?.fleetStatus,
                  NHTSApprovalStatus: model?.approvalStatus,
                  nhtsaModelCode: model?.nhtsaModelCode,
                }));
              }}
              onBlur={() => {
                setDirtyFields((prev) => [...prev, 'chassisModel']);
              }}
              errorMessage={
                dirtyFields?.includes('chassisModel') && chassisModelError
                  ? chassisModelError
                  : undefined
              }
            />
          </div>
        )}
        <div className="typeAheadWrapper">
          <SelectDropdown
            name="model_year"
            onChange={({ target: { value } }) => setModelYear(value)}
            value={modelYear}
            options={getModelYearRange(1, 3)}
            className=""
          />
        </div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
        <div
          className="addBtn"
          style={{ cursor: 'pointer' }}
          onClick={handleAddMakeAndModel}
          role="button"
        >
          <p className="btnTxt" data-testid="add_make_model_btn">
            <span className="btnIcon">+</span>
            Add
          </p>
        </div>
      </div>
    </>
  );
};

export default MakeAndModelSearchPanel;
