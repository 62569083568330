import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import AssociationForm from '../../components/forms/equipment-association-form';
import { formSchema } from '../../components/forms/equipment-association-schema';
import { useStandardItem } from './standard-item-provider';

const StandardAssociationAdd = () => {
  const {
    addEquipmentAssociation,
    standardItem,
    multipleOptions,
  } = useStandardItem();

  const { errors, handleSubmit, control, register, setValue } = useForm({
    resolver: yupResolver(formSchema),
  });

  const [equipmentCodeId, setEqID] = useState('');
  const onSubmit = (data) => {
    const { program, ...rest } = data;
    addEquipmentAssociation({
      variables: {
        equipmentAssociation: {
          ...rest,
          standardItemId: Number(standardItem?.id),
          equipmentCodeId: Number(equipmentCodeId),
        },
      },
    });
  };

  return (
    // form id must exist in order to trigger submit from outside form
    <form
      id="equipment-code-add-form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <AssociationForm
        control={control}
        errors={errors}
        register={register}
        setValue={setValue}
        updateEquipmentCodeId={(id) => {
          setEqID(id);
        }}
        equipmentCodeId={equipmentCodeId}
        multipleOptions={multipleOptions}
      />
    </form>
  );
};

export default StandardAssociationAdd;
