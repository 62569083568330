import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useReducer,
} from 'react';
import * as GQL from './helpers';

export const AttachmentContext = createContext();

const initialState = {
  successMessage: null,
  errorMessage: null,
  selectedRow: {},
};

const AttachemntReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ERROR_MESSAGE':
      return { ...state, errorMessage: action.payload };
    case 'SET_SUCCESS_MESSAGE':
      return { ...state, successMessage: action.payload };
    case 'SET_SELECTED_ROW':
      return { ...state, selectedRow: action.payload };
    default:
      throw new Error('Invalid action');
  }
};
export default function AttachmentProvider({
  fetchVariables,
  children,
  setParentContext,
  linkedEntities,
  currentUser,
  fetchGql,
  deleteGql,
  uploadButtonLabel,
}) {
  const [state, dispatch] = useReducer(AttachemntReducer, initialState, () => {
    return initialState;
  });

  const setData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const [attachmentData, setAttachmentData] = useState({
    rows: [],
    count: 0,
    hasMore: false,
  });
  // Temporary state for file to be downloaded.
  const [downloadObject, setDownloadObject] = useState(null);

  // Error state.
  const [attachmentErrors, setAttachmentErrors] = useState({});

  // Helpful to give success feedbacks.
  const [saved, setSaved] = useState(false);
  const [saving, setSaving] = useState(false);

  const [updated, setUpdated] = useState(false);
  const [deleted, setDeleted] = useState(false);

  /**
   * Attachment fetch handlers.
   * Fetch PaginatedResponse of shape {rows, count, hasMore}
   */
  const {
    loading: fetching,
    data,
    errors: fetchErrors,
    variables: currentFetchVariables,
    refetch,
  } = useQuery(fetchGql, {
    variables: {
      ...fetchVariables,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    // If data is loaded, set to context state.
    if (data?.getAttachments) {
      const attachmentResponse = data?.getAttachments;
      setAttachmentData({
        ...attachmentResponse,
      });

      // Pass response to parent context.
      setParentContext({
        ...attachmentResponse,
      });
    }

    if (data?.getStandardsDocuments) {
      const attachmentResponse = data?.getStandardsDocuments;
      const r = [];
      attachmentResponse.rows.forEach((a) => {
        const { metadata, ...rest } = a;
        const flatData = { ...metadata, ...rest };
        r.push(flatData);
      });
      setAttachmentData({
        rows: r,
        count: attachmentResponse.count,
        hasMore: attachmentResponse.hasMore,
      });

      // Pass response to parent context.
      setParentContext({
        ...attachmentResponse,
      });
    }
  }, [data]);

  useEffect(() => {
    // If fetch error occurs set to attachment error
    setAttachmentData((prev) => ({ ...prev, fetch: fetchErrors }));
  }, [fetchErrors]);

  /**
   * Download handlers.
   */

  const [getSignedReadURL, { loading: gettingReadURL }] = useLazyQuery(
    GQL.GET_AWS_SIGNED_READ_URL,
    {
      onError: (readURLError) => {
        setAttachmentErrors((prev) => ({
          ...prev,
          downloadSignedURL: readURLError,
        }));
        setDownloadObject(null);
      },
      onCompleted: async (requestData) => {
        try {
          if (requestData?.generateReadSignedURL) {
            const fileURL = requestData.generateReadSignedURL;
            const type = 'application/pdf';
            // Download file using signed url.
            const response = await fetch(fileURL, {
              method: 'GET',
              headers: { 'Content-Type': type },
            });
            // Grab blob from response
            const blob = await response.blob();
            // Browser starts download
            fileDownload(blob, downloadObject?.name);
          }
        } catch (error) {
          // Clear temporary download state
          setDownloadObject(null);
          setAttachmentErrors((prev) => ({ ...prev, download: error }));
        }
      },
    },
  );

  const downloadAttachment = useCallback((attachment) => {
    if (attachment?.contentURL) {
      setDownloadObject(attachment);
      getSignedReadURL({
        variables: {
          fileKey: attachment.contentURL,
          fileType: attachment.fileMimeType,
        },
      });
    }

    if (attachment?.fileLocation) {
      setDownloadObject(attachment);
      getSignedReadURL({
        variables: {
          fileKey: attachment.fileLocation,
          fileType: attachment.fileMimeType,
        },
      });
    }
  }, []);

  /**
   * Sort Attachment
   */
  const sortAttachment = useCallback((order = '') => {
    refetch({
      order,
    });
  }, []);

  /**
   * Edit Attachment.
   */
  const [updateDescription, { loading: updating }] = useMutation(
    GQL.UPDATE_DOC_DESCRIPTION,
    {
      onError: (error) => {
        setAttachmentErrors((prev) => ({ ...prev, update: error }));
        setUpdated(false);
        setData('SET_ERROR_MESSAGE', error.message);
      },
      onCompleted: async () => {
        setData(
          'SET_SUCCESS_MESSAGE',
          `The description for <strong>${state.selectedRow?.name}</strong> has been updated.`,
        );
        setUpdated(true);
        setUpdated(false);
        refetch();
      },
    },
  );

  /**
   * Edit Effective Date.
   */
  const [updateSupportingDocEffectiveDate] = useMutation(
    GQL.UPDATE_DOC_EFFECTIVE_DATE,
    {
      onError: (error) => {
        setAttachmentErrors((prev) => ({ ...prev, update: error }));
        setUpdated(false);
        setData('SET_ERROR_MESSAGE', error.message);
      },
      onCompleted: async () => {
        setData(
          'SET_SUCCESS_MESSAGE',
          `The effective date for <strong>${state.selectedRow?.name}</strong> has been updated.`,
        );
        setUpdated(true);
        setUpdated(false);
        refetch();
      },
    },
  );

  /**
   *  Save Attachment.
   */
  const saveAttachment = async (formData, docMetadata) => {
    try {
      setSaving(true);
      const res = await fetch(docMetadata?.signedUrl, {
        method: 'PUT',
        body: formData,
        headers: {
          'Content-Type': formData.get('fileMimeType'),
        },
      });
      if (res.ok) {
        const status = formData.get('status');
        const docName = formData.get('attachment').name;
        setData(
          'SET_SUCCESS_MESSAGE',
          status === 'undefined'
            ? `You have successfully uploaded <strong>${docName}</strong>.`
            : `You have successfully uploaded <strong>${docName}</strong> in <strong>${status}</strong> status.`,
        );
        setSaved(true);
        refetch();
      } else {
        setAttachmentErrors((prev) => ({
          ...prev,
          save: { message: res.statusText },
        }));
      }
      setSaving(false);
      setSaved(false);
    } catch (e) {
      setSaving(false);
      setSaved(false);
      setAttachmentErrors((prev) => ({ ...prev, save: e }));
      setData('SET_ERROR_MESSAGE', e.message);
    }
  };

  /**
   *  Delete Attachment.
   */

  const [processAttachmentDelete, { loading: deleting }] = useMutation(
    deleteGql,
    {
      onError: (error) => {
        setAttachmentErrors((prev) => ({ ...prev, delete: error }));
        setDeleted(false);
        setData('SET_ERROR_MESSAGE', error.message);
      },
      onCompleted: async () => {
        setData(
          'SET_SUCCESS_MESSAGE',
          `<strong>${state.selectedRow?.name}</strong> has been deleted.`,
        );
        setDeleted(true);
        setDeleted(false);
        refetch();
      },
    },
  );

  const deleteAttachment = useCallback((attachment) => {
    if (attachment?.id) {
      processAttachmentDelete({
        variables: {
          id: parseInt(attachment.id, 10),
          t: new Date().getTime(), // FIXME: Used to trick cache
        },
      });
    }
  }, []);

  /**
   * update doc status.
   */
  const [updateDocumentStatus] = useMutation(GQL.UPDATE_SUPPORTING_DOC_STATUS, {
    onError: (error) => {
      setAttachmentErrors((prev) => ({ ...prev, update: error }));
      setData('SET_ERROR_MESSAGE', error.message);
    },
    onCompleted: async () => {
      setData(
        'SET_SUCCESS_MESSAGE',
        `The status for the <strong>${state.selectedRow?.name}</strong> has been updated to Final`,
      );
      refetch();
    },
  });

  return (
    <AttachmentContext.Provider
      value={{
        ...state,
        setData,
        attachmentErrors,
        ...attachmentData,
        currentFetchVariables,
        fetching,
        gettingReadURL,
        saving,
        saved,
        updating,
        updated,
        deleting,
        deleted,
        linkedEntities,
        currentUser,
        setAttachmentErrors,
        saveAttachment,
        updateDescription,
        updateSupportingDocEffectiveDate,
        deleteAttachment,
        downloadAttachment,
        sortAttachment,
        refetch,
        uploadButtonLabel,
        updateDocumentStatus,
        getSignedReadURL,
      }}
    >
      {children}
    </AttachmentContext.Provider>
  );
}

AttachmentProvider.defaultProps = {
  linkedEntities: {},
  currentUser: { email: '', token: '' },
  setParentContext: () => {},
  uploadButtonLabel: 'Add File',
  fetchGql: undefined,
  deleteGql: undefined,
};

AttachmentProvider.propTypes = {
  fetchVariables: PropTypes.objectOf(Object).isRequired,
  children: PropTypes.node.isRequired,
  setParentContext: PropTypes.func,
  linkedEntities: PropTypes.shape(Object),
  currentUser: PropTypes.shape({
    email: PropTypes.string,
    token: PropTypes.string,
  }),
  fetchGql: PropTypes.shape(Object),
  deleteGql: PropTypes.shape(Object),
  uploadButtonLabel: PropTypes.string,
};

// Hook to exposes context value.
export const useAttachments = () => useContext(AttachmentContext);
