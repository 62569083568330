import {
  Button,
  ErrorMessage,
  Icon,
  SelectDropdown,
  Textbox,
  TextInput,
} from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import { isEmpty } from 'lodash';
import StandardItem from './equipment-code-association-form-elements/standard-item';
import PredefinedValueTable from '../../components/forms/predefined-values-table';
import StandardItemDescription from './equipment-code-association-form-elements/standard-item-description';
import { useEquipmentDetailCodes } from './equipment-code-detail-provider';

const AssociationForm = ({
  control,
  errors,
  register,
  setValue,
  updateStandardItemId,
  standardItemId,
}) => {
  const { equipmentAssociation, multipleOptions } = useEquipmentDetailCodes();

  const isSpecificationRequired = () => {
    // minimus and details are not required for optional/O association types
    if (equipmentAssociation?.associationType?.code === 'S') return true;
    return false;
  };

  const defaultNumericalOrNumericalRangeValues = (currentSelectedInputType) => {
    if (
      equipmentAssociation?.inputTypeCode === 'N' ||
      equipmentAssociation?.inputTypeCode === 'R'
    ) {
      if (equipmentAssociation?.inputTypeCode !== currentSelectedInputType) {
        setValue('unitCode', '');
        setValue('lowerBound', 0);
      } else {
        setValue('unitCode', equipmentAssociation?.unitCode);
        setValue('lowerBound', equipmentAssociation?.lowerBound);
      }
    }
  };

  const isNew = equipmentAssociation?.id === undefined;
  const inputTypeCode = equipmentAssociation?.inputType?.code?.toLowerCase();

  const [isNumerical, setIsNumerical] = useState(inputTypeCode === 'n');
  const [isNumericalRange, setIsNumericalRange] = useState(
    inputTypeCode === 'r',
  );

  const isNumericalRangeOrNumerical = isNumerical || isNumericalRange;
  const [isPredefinedValues, setIsPredefinedValues] = useState(
    inputTypeCode === 'p',
  );

  const [predefinedValues, setPredefinedValues] = useState([]);

  const [deletedRows, setDeletedRows] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [newRows, setNewRows] = useState([]);
  const [renderSpecificationInput, setRenderSpecificationInput] = useState(
    isSpecificationRequired(),
  );

  const renderRequiredComponent = (value, additionStyle) => {
    return (
      <>
        <h2 className={`font-sans-md ${additionStyle}`}>
          {value} <span className="usa-hint usa-hint--required">*</span>
        </h2>
      </>
    );
  };

  const renderBoundValue = (name, defaultValue) => {
    return (
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        type="number"
        render={({ onChange, value }) => (
          <Textbox
            aria-label="Numerical value"
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        )}
      />
    );
  };

  const renderToolTip = () => (
    <ReactTooltip
      id="unit-tooltip"
      textColor="black"
      delayHide={1000}
      clickable
      backgroundColor="#FAF3D1"
      getContent={() => {
        return (
          <div className="padding-1 radius-lg">
            <h2 className="text-black font-sans-lg margin-0">
              <span>
                <Icon
                  iconName="info"
                  className="usa-icon--size-3 text-baseline"
                  style={{ fill: 'black' }}
                />
              </span>
              Create new unit types
            </h2>

            <p className="font-sans-lg margin-0">
              New unit types can be created in
            </p>
            <p className="font-sans-lg margin-0">
              <span className="margin-left- 1">the</span>
              <a
                href="/"
                className="text-underline"
                style={{ cursor: 'pointer' }}
              >
                Catalog codes table
              </a>
            </p>
          </div>
        );
      }}
    />
  );

  return (
    <>
      <StandardItem
        isNew={isNew}
        setStandardItemId={updateStandardItemId}
        control={control}
        errors={errors}
        register={register}
      />
      {isNew && standardItemId && (
        <StandardItemDescription standardItemId={standardItemId} />
      )}

      {!isNew && (
        <StandardItemDescription
          standardItemId={`${equipmentAssociation?.standardItem?.id}`}
        />
      )}

      <div className="grid-row padding-bottom-2">
        <div className="grid-col-3">
          {renderRequiredComponent('Type')}
          <Controller
            id="si_edit_type"
            name="associationTypeCode"
            control={control}
            defaultValue={equipmentAssociation?.associationTypeCode ?? ''}
            render={({ onChange, value }) => (
              <SelectDropdown
                id="si_edit_type_sel"
                data-testid="association-type-field"
                aria-label="Association Type"
                aria-required="true"
                onChange={(v) => {
                  setRenderSpecificationInput(v.target.value === 'S');
                  onChange(v);
                }}
                options={[
                  { label: 'Select', value: '' },
                  ...multipleOptions?.filter(
                    (o) =>
                      o?.type === 'standardsCodeModel' &&
                      o?.uniqueKey === 'associationType',
                  ),
                ]}
                value={value}
                readOnly
              />
            )}
          />
          {errors?.associationTypeCode?.message && (
            <ErrorMessage>{errors.associationTypeCode.message}</ErrorMessage>
          )}
        </div>
      </div>

      <div className="grid-row padding-bottom-2">
        <div className="grid-col-6">
          <>
            <h2 className="font-sans-md">Additional information</h2>
            <Controller
              id="additional-info-text"
              name="associationText"
              defaultValue={equipmentAssociation?.associationText ?? ''}
              control={control}
              render={({ onChange, value }) => (
                <TextInput
                  data-testid="association-add-info-field"
                  aria-label="Additional Information text input 80 characters allowed"
                  type="textarea"
                  size="medium"
                  value={value}
                  name="associationText"
                  error={errors?.associationText}
                  onChange={(e) => onChange(e.target.value)}
                  hint="80 characters allowed"
                />
              )}
            />
          </>

          {errors?.associationText?.message && (
            <ErrorMessage>{errors.associationText.message}</ErrorMessage>
          )}
        </div>
      </div>
      {renderSpecificationInput && (
        <>
          <div className="grid-row padding-bottom-2 grid-gap-3 display-flex flex-row">
            <div className="grid-col-6">
              {renderRequiredComponent('Input type')}
              <Controller
                name="inputTypeCode"
                defaultValue={equipmentAssociation?.inputTypeCode ?? ''}
                control={control}
                render={({ onChange, value }) => (
                  <SelectDropdown
                    data-testid="association-input-type-field"
                    aria-label="Input Type"
                    aria-required="true"
                    value={value}
                    options={[
                      { label: 'Select', value: '' },
                      ...multipleOptions?.filter(
                        (o) =>
                          o?.type === 'standardsCodeModel' &&
                          o?.uniqueKey === 'inputType',
                      ),
                    ]}
                    onChange={(e) => {
                      const v = e.target.value;
                      defaultNumericalOrNumericalRangeValues(v);
                      setIsNumerical(v?.toLowerCase() === 'n');
                      setIsNumericalRange(v?.toLowerCase() === 'r');
                      setIsPredefinedValues(v?.toLowerCase() === 'p');
                      onChange(v);
                    }}
                  />
                )}
              />
              {errors?.inputTypeCode?.message && (
                <ErrorMessage>{errors.inputTypeCode?.message}</ErrorMessage>
              )}
            </div>

            {isPredefinedValues && (
              <div className="grid-col-3 margin-left-1px padding-0 flex-align-self-end">
                <Button
                  variant="unstyled"
                  type="button"
                  onClick={() => {
                    setShowAdd(isPredefinedValues);
                  }}
                  style={{ cursor: 'pointer' }}
                  data-testid="equipment-code-predefined-value"
                  label="Add new value"
                />
              </div>
            )}
          </div>

          {isNumericalRangeOrNumerical && (
            <div className="grid-row padding-bottom-2 grid-gap-2">
              {/* Numerical */}
              <div className={isNumerical ? 'grid-col-6' : 'grid-col-4'}>
                {isNumerical
                  ? renderRequiredComponent('Numerical value')
                  : renderRequiredComponent(
                      'Numerical value 1',
                      'margin-bottom-0',
                    )}

                {isNumericalRange && (
                  <p className="text-gray-50 margin-0">
                    Must be greater than or equal to X
                  </p>
                )}

                {renderBoundValue(
                  'lowerBound',
                  equipmentAssociation?.lowerBound ?? 0,
                )}

                {errors?.lowerBound?.message && (
                  <ErrorMessage>{errors.lowerBound.message}</ErrorMessage>
                )}
              </div>

              {isNumericalRange && (
                <div className="grid-col-4">
                  {renderRequiredComponent(
                    'Numerical value 2',
                    'margin-bottom-0',
                  )}
                  <p className="text-gray-50 margin-0">
                    Must be less than or equal to Y
                  </p>
                  {renderBoundValue(
                    'upperBound',
                    equipmentAssociation?.upperBound ?? 0,
                  )}

                  {errors?.upperBound?.message && (
                    <ErrorMessage>{errors.upperBound.message}</ErrorMessage>
                  )}
                </div>
              )}

              {isNumerical && (
                <div className="grid-col-3">
                  {renderRequiredComponent('Criteria')}
                  <Controller
                    id="si_edit_criteria"
                    name="criteriaCode"
                    control={control}
                    defaultValue={equipmentAssociation?.criteriaCode ?? ''}
                    render={({ onChange, value }) => (
                      <SelectDropdown
                        id="si_edit_criteria_sel"
                        aria-label="criteria"
                        onChange={(selected) => {
                          onChange(selected);
                        }}
                        options={[
                          { label: '- Select -', value: '' },
                          ...multipleOptions?.filter(
                            (o) =>
                              o?.type === 'standardsCodeModel' &&
                              o?.uniqueKey === 'criteria',
                          ),
                        ]}
                        value={value}
                      />
                    )}
                  />
                  {errors?.criteriaCode?.message && (
                    <ErrorMessage>{errors.criteriaCode.message}</ErrorMessage>
                  )}
                </div>
              )}

              <div className="grid-col-3">
                <h2
                  className={`font-sans-md ${
                    isNumerical ? '' : 'padding-bottom-4 margin-bottom-0'
                  }`}
                >
                  <div data-tip="" data-for="unit-tooltip">
                    Unit
                    <span style={{ color: 'red' }}>*</span>
                    <span>
                      <Icon
                        iconName="info"
                        className="font-sans-md margin-bottom-0 margin-left-1px"
                        style={{ fill: '#005EA2' }}
                      />
                    </span>
                  </div>
                </h2>
                {renderToolTip()}
                <Controller
                  id="si_edit_unit"
                  name="unitCode"
                  control={control}
                  defaultValue={equipmentAssociation?.unitCode ?? ''}
                  render={({ onChange, value }) => (
                    <SelectDropdown
                      id="si_edit_unit_sel"
                      aria-label="unit"
                      value={value}
                      onChange={(v) => {
                        onChange(v);
                      }}
                      options={[
                        { label: '- Select -', value: '' },
                        ...multipleOptions?.filter(
                          (o) =>
                            o?.type === 'standardsCodeModel' &&
                            o?.uniqueKey === 'units',
                        ),
                      ]}
                    />
                  )}
                />
                {errors?.unitCode?.message && (
                  <ErrorMessage>{errors.unitCode.message}</ErrorMessage>
                )}
              </div>
            </div>
          )}
          {isPredefinedValues && (
            <Controller
              name="preDefinedValue"
              defaultValue={
                isEmpty(predefinedValues)
                  ? equipmentAssociation?.preDefinedValue?.value ?? []
                  : predefinedValues
              }
              control={control}
              render={({ onChange, value }) => (
                <PredefinedValueTable
                  data-testid="preDefinedValue"
                  value={
                    value.length > 0
                      ? value.filter((r) => !deletedRows.includes(r))
                      : value
                  }
                  predefinedValues={predefinedValues}
                  setPredefinedValues={setPredefinedValues}
                  deletedRows={deletedRows}
                  showAdd={showAdd}
                  setShowAdd={setShowAdd}
                  setDeletedRows={setDeletedRows}
                  showEdit={showEdit}
                  setShowEdit={setShowEdit}
                  setNewRows={setNewRows}
                  newRows={newRows}
                  onChange={(v) => {
                    const newValues = !isEmpty(v)
                      ? v.filter((r) => !deletedRows.includes(r))
                      : v;
                    onChange({ value: newValues });
                  }}
                />
              )}
            />
          )}
        </>
      )}
    </>
  );
};

AssociationForm.propTypes = {
  updateStandardItemId: PropTypes.func.isRequired,
  standardItemId: PropTypes.string.isRequired,
  errors: PropTypes.shape(Object).isRequired,
  control: PropTypes.shape(Object).isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default AssociationForm;
