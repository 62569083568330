/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_EQUIPMENT_ASSOCIATIONS_WITH_COMMENTS = gql`
  query GetEquipmentAssociations(
    $filters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getEquipmentAssociations(
      filters: $filters
      order: $order
      offset: $offset
      limit: $limit
    ) {
      rows {
        id
        standardItemId
        standardItem {
          id
          title
          year
          standardItemNumber
          vehicleType
        }
        equipmentCodeId
        equipmentCode {
          id
          title
          code
          program
          category
          content {
            content
          }
        }
        associationTypeCode
        associationType {
          id
          code
          title
        }
        associationText
        inputTypeCode
        inputType {
          id
          code
          title
        }
        lowerBound
        upperBound
        criteriaCode
        criteria {
          id
          code
          title
        }
        unitCode
        unit {
          id
          code
          title
        }
        preDefinedValue
        createdByUser
        createdAt
        updatedByUser
        updatedAt
        deletedAt
        commentsCount
        comments {
          id
          comment
          repliesCount
          status
          authorType
          createdByUser
          createdByUserInfo {
            id
            fullName
            email
          }
          createdAt
          publishStatus
          replies {
            id
            comment
            status
            authorType
            createdByUser
            createdAt
            publishStatus
            createdByUserInfo {
              id
              fullName
              email
            }
          }
        }
      }
      count
      hasMore
    }
  }
`;

export const GET_COMMENTS = gql`
  query GetComments(
    $filters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getStandardsComments(
      filters: $filters
      order: $order
      offset: $offset
      limit: $limit
    ) {
      rows {
        id
        threadId
        comment
        authorType
        publishStatus
        status
        year
        createdAt
        createdByUser
        linkedEntityType
        linkedEntityId
        fvsCode
        createdByUserInfo {
          id
          fullName
          email
        }
        replies {
          id
          comment
          status
          authorType
          createdByUser
          createdAt
          publishStatus
          createdByUserInfo {
            id
            fullName
            email
          }
        }
        repliesCount
        linkedEntity {
          __typename
          ... on StandardItem {
            standardItemNumber
            year
          }
          ... on EquipmentAssociation {
            standardItemId
            equipmentCodeId
            associationTypeCode
            associationText
            standardItem {
              standardItemNumber
            }
            equipmentCode {
              code
            }
          }
        }
      }
      count
      hasMore
    }
  }
`;
