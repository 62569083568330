import { gql } from '@apollo/client';

export const CREATE_STANDARD_ITEM = gql`
  mutation CreateStandardItem($standarditem: StandardItemInput!) {
    addStandardItem(standarditem: $standarditem) {
      id
    }
  }
`;

export const UPDATE_PEER_REVIEW = gql`
  mutation UpdatePeerReview($id: Float!, $input: StandardsPeerReviewInput!) {
    updateStandardPeerReviewTask(id: $id, input: $input) {
      taskIdStr
    }
  }
`;

export const CREATE_SPR_TASK = gql`
  mutation CreateSPRTask($input: StandardsPeerReviewInput!) {
    createStandardsPeerReviewTask(input: $input) {
      id
      taskId
    }
  }
`;

export const TASK_DETAIL = gql`
  query GetTaskById($id: Float!) {
    getTaskById(id: $id) {
      id
      taskID
      taskHash
      title
      description
      status
      linkedEntities
      metaData
      taskType {
        taskType
        taskTypeName
        helpText
        helpTextTitle
      }
      assignedUser
      dueDate
      createdAt
      createdByUser
      updatedAt
      updatedByUser
      dueDate
    }
  }
`;

export const SPR_TASK_DETAIL = gql`
  query GetStandardsPeerReviewTask($taskId: String!) {
    getStandardsPeerReviewTask(taskId: $taskId) {
      id
      taskId
      taskIdStr
      fvsId
      assignor
      documentAuthor
      documentLink
      fedVehicleStandardsLink
      fedStandardDoc
      programName
      classType
      documentStatus
      createdAt
      createdByUser
      updatedAt
      updatedByUser
      deletedAt
    }
  }
`;

export const TOGGLE_TASK_STATUS = gql`
  mutation ToggleTaskStatus($id: Float!, $status: String!) {
    toggleTaskStatus(id: $id, status: $status) {
      title
      status
      updatedAt
      updatedByUser
    }
  }
`;

export const commentResponse = gql`
  fragment CommentFields on Comment {
    id
    comment
    createdByUser
    author {
      fullName
    }
    createdAt
    deletedAt
    updatedByUser
    updatedAt
  }
`;

export const CREATE_COMMENT_REPLY = gql`
  mutation AddTaskComment($id: Float!, $comment: String!, $threadId: Float!) {
    addTaskComment(id: $id, comment: $comment, threadId: $threadId) {
      ...CommentFields
    }
  }
  ${commentResponse}
`;

export const GET_TASK_COMMENTS = gql`
  query GetTaskComments(
    $id: Float!
    $limit: Float
    $offset: Float
    $order: OrderBy
  ) {
    getTaskComments(id: $id, limit: $limit, offset: $offset, order: $order) {
      rows {
        ...CommentFields
      }
      count
      hasMore
    }
  }
  ${commentResponse}
`;

export const CREATE_TASK_COMMENT = gql`
  mutation AddTaskComment($id: Float!, $comment: String!) {
    addTaskComment(id: $id, comment: $comment) {
      ...CommentFields
    }
  }
  ${commentResponse}
`;

export const DELETE_COMMENT = gql`
  mutation DeleteComment($id: Float!) {
    deleteComment(id: $id)
  }
`;

export default {
  CREATE_TASK_COMMENT,
  GET_TASK_COMMENTS,
  DELETE_COMMENT,
};
