import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const MakeAndModeApprovalRequestContext = createContext({});

const initialState = {
  makeCode: '',
  makeName: '',
  modelCode: '',
  modelName: '',
  chassisMakeCode: '',
  chassisMakeName: '',
  chassisModelCode: '',
  chassisModelName: '',
  modelYear: '',
  makeCustomKeyword: '',
  modelCustomKeyword: '',
  chassisMakeCustomKeyword: '',
  chassisModelCustomKeyword: '',
  isSubmitted: false,
  isClearedTypeAhead: false,
  status: null, // type: { fleetApprovalStatus: string } | null
  comment: '',
};

const MakeModelApprovalRequestReducer = (state, action) => {
  switch (action.type) {
    case 'SET_MAKE': {
      return {
        ...state,
        makeCode: action.payload?.makeCode || '',
        makeName: action.payload?.makeName || '',
      };
    }
    case 'SET_MODEL': {
      return {
        ...state,
        modelCode: action.payload?.modelCode || '',
        modelName: action.payload?.modelName || '',
      };
    }
    case 'SET_CHASSIS_MAKE': {
      return {
        ...state,
        chassisMakeCode: action.payload?.makeCode || '',
        chassisMakeName: action.payload?.makeName || '',
      };
    }
    case 'SET_CHASSIS_MODEL': {
      return {
        ...state,
        chassisModelCode: action.payload?.modelCode || '',
        chassisModelName: action.payload?.modelName || '',
      };
    }
    case 'SET_MODEL_YEAR': {
      return { ...state, modelYear: action.payload || '' };
    }

    case 'SET_MAKE_CUSTOM_KEYWORD': {
      return { ...state, makeCustomKeyword: action.payload };
    }
    case 'SET_MODEL_CUSTOM_KEYWORD': {
      return { ...state, modelCustomKeyword: action.payload };
    }
    case 'SET_CHASSIS_MAKE_CUSTOM_KEYWORD': {
      return { ...state, chassisMakeCustomKeyword: action.payload };
    }
    case 'SET_CHASSIS_MODEL_CUSTOM_KEYWORD': {
      return { ...state, chassisModelCustomKeyword: action.payload };
    }
    case 'SET_IS_SUBMITTED': {
      return { ...state, isSubmitted: action.payload };
    }
    case 'SET_TYPE_AHEAD_IS_CLEARED': {
      return { ...state, isClearedTypeAhead: action.payload };
    }
    case 'SET_SELECTED_ITEM': {
      return { ...state, selectedMakeModelItem: { ...action.payload } };
    }
    case 'SET_STATUS': {
      return { ...state, status: action.payload };
    }
    case 'SET_COMMENT': {
      return { ...state, comment: action.payload };
    }
    case 'RESET': {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
};

function MakeAndModeApprovalRequestProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(
    MakeModelApprovalRequestReducer,
    initialState,
    () => {
      return initialState;
    },
  );

  const setAddMakeModelContext = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  return (
    <MakeAndModeApprovalRequestContext.Provider
      value={{
        ...state,
        setAddMakeModelContext,
        ...props,
      }}
    >
      {children}
    </MakeAndModeApprovalRequestContext.Provider>
  );
}

export default MakeAndModeApprovalRequestProvider;

MakeAndModeApprovalRequestProvider.defaultProps = {};

MakeAndModeApprovalRequestProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useMakeAndModeApprovalRequestContext = () =>
  useContext(MakeAndModeApprovalRequestContext);
