import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import HtmlDiff from 'htmldiff-js';

const StandardItemTitle = ({ entity, value }) => {
  const [title, setTitle] = useState(null);
  const [prevTitle, setPrevTitle] = useState(null);

  useEffect(() => {
    setTitle(value);

    if (entity?.change?.differences?.differences) {
      const titleChange = entity?.change?.differences?.differences?.find(
        (d) => d?.path.indexOf('title') >= 0,
      );
      if (titleChange) {
        setPrevTitle(`${entity?.standardItemNumber} - ${titleChange?.lhs}`);
      }
    }
  }, [entity, value]);

  return (
    <>
      {!title && <span>&ndash;</span>}

      {title && !prevTitle && <span>{title}</span>}
      {title &&
        prevTitle &&
        ReactHtmlParser(`<div>
              ${HtmlDiff.execute(prevTitle, title)}
           </div>`)}
    </>
  );
};

StandardItemTitle.defaultProps = {
  entity: {},
  value: '',
};

StandardItemTitle.propTypes = {
  entity: PropTypes.shape(Object),
  value: PropTypes.string,
};

export default StandardItemTitle;
