import { Button } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import AttachmentModal from './AttachmentModal';
import { useAttachmentComponent } from './ComponentContext';

const AttachmentDelete = ({ title }) => {
  const {
    deleting,
    onDelete,
    deleteState,
    resetDeleteState,
  } = useAttachmentComponent();

  return (
    <AttachmentModal
      id="attachment-delete-confirm"
      className="attachment-delete-modal"
      title={title}
      show={deleteState?.show}
      onClose={resetDeleteState}
      actions={
        <>
          <Button
            type="button"
            variant="outline"
            onClick={resetDeleteState}
            label="Cancel"
          />
          <Button
            disabled={deleting}
            variant="secondary"
            className="delete-document-button action-button"
            onClick={() => {
              resetDeleteState();
              if (deleteState?.data?.id) onDelete(deleteState.data);
            }}
            label="Delete document"
          />
        </>
      }
    >
      <p>
        Are you sure you wish to delete this{' '}
        <strong>{deleteState?.data?.name}?</strong> This action cannot be
        undone.
      </p>
    </AttachmentModal>
  );
};

AttachmentDelete.defaultProps = {
  title: <h2>Delete document</h2>,
};

AttachmentDelete.propTypes = {
  title: PropTypes.node,
};

export default AttachmentDelete;
