import React from 'react';
import { Button } from '@gsa/afp-component-library';
import Modal from '../../components/Modal/Modal';
import { useEquipmentDetailCodes } from './equipment-code-detail-provider';
import EditEquipmentCode from './equipment-code-edit';
import AssociationAdd from './equipment-code-association-add';
import AssociationEdit from './equipment-code-association-edit';
import AssociationCopy from './equipment-code-association-copy';
import AssociationMessages from './equipment-code-association-messages';

const ModalTitle = () => {
  const { modalMode, MODAL_MODES } = useEquipmentDetailCodes();
  let title = '';

  switch (modalMode) {
    case MODAL_MODES.EDIT:
      title = 'Edit Equipment Code';
      break;
    case MODAL_MODES.ADD_ASSOCIATION:
      title = 'Add Standard Item';
      break;
    case MODAL_MODES.EDIT_ASSOCIATION:
      title = 'Edit Standard Item';
      break;
    case MODAL_MODES.REMOVE_ASSOCIATION:
      title = 'Confirm removal of Standard Item';
      break;
    case MODAL_MODES.COPY:
      title = 'Copy Standard item';
      break;
    case MODAL_MODES.CONFIRM_COPY:
      title = 'Confirm replacement of Standard Items';
      break;
    case MODAL_MODES.CONFIRM_APPEND:
      title = 'Confirm addition of Standard Items';
      break;
    case MODAL_MODES.CONFIRM_UPDATE:
      title = 'Confirm Equipment Code overwrite';
      break;
    case MODAL_MODES.CONFIRM_BULK_DELETE:
      title = 'Confirm removal of Standard Items';
      break;
    default:
      break;
  }
  return (
    <div className="margin-top-5">
      <AssociationMessages isModal isVisible={false} />
      {/* eslint-disable-next-line */}
      <h3 aria-label={title} tabIndex="0">
        {title}
      </h3>
    </div>
  );
};

const ModalContent = () => {
  const {
    modalMode,
    MODAL_MODES,
    equipmentAssociation,
    copyAssociation,
    selectedEquipmentCode,
    updatingEquipmentCode,
    selectedAssociations,
  } = useEquipmentDetailCodes();
  if (modalMode === MODAL_MODES.EDIT) return <EditEquipmentCode />;
  if (modalMode === MODAL_MODES.ADD_ASSOCIATION) return <AssociationAdd />;
  if (modalMode === MODAL_MODES.EDIT_ASSOCIATION) return <AssociationEdit />;
  if (modalMode === MODAL_MODES.COPY) return <AssociationCopy />;
  if (modalMode === MODAL_MODES.CONFIRM_UPDATE)
    return (
      <>
        <div data-testid="confirm_eq_overwrite_modal_content">
          The Equipment Code {selectedEquipmentCode?.code} already exists for
          the Program {updatingEquipmentCode?.program}. Do you want to continue
          with the update?
        </div>
      </>
    );
  if (modalMode === MODAL_MODES.REMOVE_ASSOCIATION)
    return (
      <div>
        {`Are you sure you want to remove the ${equipmentAssociation?.standardItem?.title} Code?`}
      </div>
    );

  if (modalMode === MODAL_MODES.CONFIRM_BULK_DELETE) {
    return (
      <div>
        {`You are about to remove ${selectedAssociations?.length} Standard Items associated to ${selectedEquipmentCode?.title}.
         Please confirm that you want to perform this action by selecting "Remove" below.
          To cancel this action, select "Cancel".`}
      </div>
    );
  }

  if (
    modalMode === MODAL_MODES.CONFIRM_COPY ||
    modalMode === MODAL_MODES.CONFIRM_APPEND
  ) {
    const copyType = copyAssociation?.copyType?.toLowerCase();
    const fromEquipmentCode = selectedEquipmentCode?.code;
    const toEquipmentCode = copyAssociation?.toEquipmentCode?.label;
    const selectedCount = copyAssociation?.associationsToCopy?.length ?? 0;

    return copyType === 'replace' ? (
      <div>
        You are about to replace all existing Standard Items associated with
        Equipment Code {toEquipmentCode} with the <b>{selectedCount}</b>{' '}
        Standard Item(s) associated with Equipment Code {fromEquipmentCode}.
        This action cannot be undone.
      </div>
    ) : (
      <div>
        You are about to add <b> {selectedCount} </b>
        Standard Item(s) associated with Equipment Code {fromEquipmentCode} to
        Equipment Code {toEquipmentCode}. This will not impact the existing
        Standard Items currently associated with Equipment Code{' '}
        {toEquipmentCode}.
      </div>
    );
  }
  return null;
};

const ModalAction = () => {
  const {
    MODAL_MODES,
    setData,
    modalMode,
    equipmentAssociation,
    deleteEquipmentAssociation,
    copyAssociation,
    copyEquipmentAssociation,
    selectedEquipmentCode,
    updatingEquipmentCode,
    overwriteEquipmentCode,
    selectedAssociations,
  } = useEquipmentDetailCodes();

  const onCancel = () => {
    setData('SET_FORM_MODAL', false);
    setData('SET_MODAL_MODE', null);
    setData('SET_ERROR_MESSAGE', '');
  };

  const overWrite = async () => {
    await overwriteEquipmentCode({
      variables: {
        id: Number.parseInt(selectedEquipmentCode.id, 10),
        equipmentcode: {
          category: updatingEquipmentCode.category,
          code: selectedEquipmentCode.code,
          program: updatingEquipmentCode.program,
          quantityRequired: updatingEquipmentCode.quantityRequired,
          sequence: updatingEquipmentCode.sequence,
          status: updatingEquipmentCode.status,
          title: updatingEquipmentCode.title,
          unit: updatingEquipmentCode.unit,
          content: String(updatingEquipmentCode.content),
          tags: updatingEquipmentCode.tags,
          contentId: updatingEquipmentCode.content?.id,
          year: selectedEquipmentCode.year,
        },
      },
    });
    setData('SET_FORM_MODAL', false);
  };

  if (modalMode === MODAL_MODES.EDIT) {
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="equipment-code-edit-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="primary"
          type="submit"
          form="equipment-code-edit"
          data-testid="equipment-code-edit-save-btn"
          label="Save"
        />
      </>
    );
  }
  if (modalMode === MODAL_MODES.REMOVE_ASSOCIATION) {
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="association-modal-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="secondary"
          data-testid="association-modal-remove-btn"
          onClick={async () => {
            if (equipmentAssociation?.id) {
              await deleteEquipmentAssociation({
                variables: {
                  equipmentAssociationDelete: {
                    ids: [Number(equipmentAssociation.id)],
                  },
                },
              });
              setData('SET_EQUIPMENT_ASSOCIATION', null);
              setData('SET_FORM_MODAL', false);
            }
          }}
          label="Remove"
        />
      </>
    );
  }
  if (modalMode === MODAL_MODES.ADD_ASSOCIATION) {
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="association-modal-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="primary"
          type="submit"
          form="association-add-form"
          data-testid="association-modal-save-btn"
          label="Save and close"
        />
      </>
    );
  }
  if (modalMode === MODAL_MODES.EDIT_ASSOCIATION) {
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="association-modal-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="outline"
          onClick={() => {
            setData('SET_MODAL_MODE', MODAL_MODES.REMOVE_ASSOCIATION);
          }}
          data-testid="association-modal-remove-btn"
          label="Remove Standard Item"
        />
        <Button
          variant="primary"
          type="submit"
          form="association-edit-form"
          data-testid="association-modal-save-btn"
          label="Save and close"
        />
      </>
    );
  }

  if (modalMode === MODAL_MODES.COPY)
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="association-modal-cancel-btn"
          label="Cancel"
        />
        <Button
          type="submit"
          form="equipment-code-copy-form"
          variant="primary"
          data-testid="association-modal-submit-btn"
          label="Submit"
        />
      </>
    );

  if (
    modalMode === MODAL_MODES.CONFIRM_COPY ||
    modalMode === MODAL_MODES.CONFIRM_APPEND
  ) {
    const { toEquipmentCode, copyType, ...dataToCopy } = copyAssociation;

    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="association-modal-cancel-btn"
          label="Cancel"
        />
        <Button
          type="submit"
          variant="primary"
          data-testid="association-modal-append-btn"
          onClick={() => {
            copyEquipmentAssociation({
              variables: {
                copyInput: { ...dataToCopy, copyType: copyType.toLowerCase() },
              },
            });
          }}
          label={
            copyType.toLowerCase() === MODAL_MODES.APPEND ? 'Add' : 'Replace'
          }
        />
      </>
    );
  }
  if (modalMode === MODAL_MODES.CONFIRM_BULK_DELETE) {
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="eq-code-confirm-bulk-delete-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="secondary"
          data-testid="eq-code-confirm-bulk-remove-btn"
          onClick={async () => {
            if (selectedAssociations.length) {
              const ids = selectedAssociations.map(({ original }) =>
                Number(original.id),
              );
              await deleteEquipmentAssociation({
                variables: { equipmentAssociationDelete: { ids } },
              });
              setData('SET_EQUIPMENT_ASSOCIATION', null);
              setData('SET_FORM_MODAL', false);
              setData('SET_SELECTALL_SELECTED', false);
            }
          }}
          label="Remove"
        />
      </>
    );
  }

  if (modalMode === MODAL_MODES.CONFIRM_UPDATE) {
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="eq-code-confirm-update-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="secondary"
          type="submit"
          data-testid="eq-code-confirm-update-yes-btn"
          onClick={overWrite}
          label="Overwrite"
        />
      </>
    );
  }

  return null;
};

const EquipmentModalWrapper = () => {
  const { showFormModal, setData } = useEquipmentDetailCodes();
  return (
    <Modal
      title={<ModalTitle />}
      actions={<ModalAction />}
      show={showFormModal}
      onClose={() => {
        setData('SET_FORM_MODAL', false);
        setData('SET_MODAL_MODE', null);
        setData('SET_EQUIPMENT_ASSOCIATION', null);
        setData('SET_ERROR_MESSAGE', '');
      }}
    >
      <ModalContent />
    </Modal>
  );
};

export default EquipmentModalWrapper;
