import { gql } from '@apollo/client';

export const SEARCH_OPTIONS = [
  { label: 'Program name', value: 'programName' },
  { label: 'Solicitation number', value: 'solicitationNumber' },
  { label: 'Solicitation name', value: 'solicitationName' },
  { label: 'Vendor name/UEI', value: 'vendorNameOrUei' },
];

export const GET_BIDS_FOR_EVALUATION = gql`
  query(
    $criteria: EvaluationSearchCriteriaType!
    $limit: Float
    $offset: Float
    $order: OrderBy
  ) {
    getBidsForEvaluation(
      criteria: $criteria
      order: $order
      offset: $offset
      limit: $limit
    ) {
      rows {
        id
        solicitationId
        soliciation {
          solicitationNumber
          title
        }
        vendorId
        vendorDetail {
          vendorName
          uniqueEntityIdentifier
        }
      }
      count
    }
  }
`;

export const parseSortValue = (order) => {
  const v = order.split(' ');
  return [[...v[0].replace(/`/g, '').split('.'), v[1]]];
};

export const getKeyLabel = (value, toUpperCase = false) => {
  const { label } = SEARCH_OPTIONS.find((opt) => opt.value === value);
  if (toUpperCase) return label.replace(' n', ' N');
  return label.replace('P', ' p').replace('S', ' s').replace('V', ' v');
};
