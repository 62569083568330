import React from 'react';
import Breadcrumbs from '../../../../../widgets/breadcrumbs';
import ReviewSubmitWrapper from './review-submit-solicitation';
import SolicitationNotification from '../../../utils/solicitation-notification';

const ReviewSolicitation = () => {
  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs.home}/home`,
      label: 'Home',
    },
    {
      location: '/catalog/solicitations',
      label: 'Solicitations',
    },
  ];
  return (
    <>
      {' '}
      <div className="grid-col">
        <Breadcrumbs current="Edit Solicitation" path={breadcrumbsPath} />
        <div className="grid-row">
          <div className="grid-col-fill">
            <SolicitationNotification isModal={false} />
          </div>
        </div>
        <ReviewSubmitWrapper />
      </div>
    </>
  );
};

export default ReviewSolicitation;
