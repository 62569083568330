import { CounterTag } from '@gsa/afp-component-library';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import StandardComments, {
  CommentLinkedEntityTypes,
} from '../../components/standard-comments';
import { useFvsCommentsContext } from '../../fvs-comments-provider';
import RequirementsTable from './requirements-table';

const RequirementTabs = () => {
  const { commentList, setFvsCommentsContextData } = useFvsCommentsContext();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const location = useLocation();
  const { showCommentTab } = queryString.parse(location.search);

  useEffect(() => {
    if (showCommentTab) {
      setFvsCommentsContextData('SET_SHOW_COMMENT_TAB', showCommentTab);
      setSelectedIndex(1);
    }
  }, [showCommentTab]);

  const ownCommentList = commentList;

  return (
    <Tabs
      selectedIndex={selectedIndex}
      onSelect={(tabIndex) => setSelectedIndex(tabIndex)}
    >
      <TabList>
        <Tab data-testid="vehicle-standards-req-tab">
          <span className="text-no-wrap">Requirements</span>
        </Tab>

        <Tab data-testid="vehicle-standards-comments-tab">
          <span className="text-no-wrap">
            Standard Item comments
            {ownCommentList && ownCommentList?.length > 0 ? (
              <CounterTag
                className="margin-left-1"
                count={ownCommentList.length}
              />
            ) : null}
          </span>
        </Tab>
      </TabList>
      <TabPanel>
        <RequirementsTable />
      </TabPanel>
      <TabPanel>
        <StandardComments
          linkedEntityType={CommentLinkedEntityTypes.StandardItem}
        />
      </TabPanel>
    </Tabs>
  );
};

export default RequirementTabs;
