/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useMemo, useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  AFPTable,
  Spinner,
  useModal,
  EmptyState,
  CounterTag,
  AFPTableRowAction,
} from '@gsa/afp-component-library';
import BidStandardItemDetailsSubRow from './bid-standard-item-details-row';
import { BID_STANDARDITEM_TABLE_ACTIONS } from '../constants';
import BidMakeAndModelWrapper from './make-and-model/index';
import RemoveConfirmationModal from './remove-confirmation-modal';
import ImportStandardItemModal from './import-standard-item/import-standard-item-modal';
import { bidLineAtom } from '../../bids/atoms/bids-atoms';
import { isFeatureEnabled } from '../../../utilities/feature-toggle';

const StandardItemsTable = ({
  tableProps,
  isDataLoading,
  refetchData,
  isBidClosed,
}) => {
  const [order] = useState('solicitationNumber ASC');
  const [selectedItem, setSelectedItem] = useState(null);
  const [bidLine] = useRecoilState(bidLineAtom);
  const isNoData = !bidLine?.length;
  const {
    isOpen: isRemoveOpen,
    openModal: openRemoveModal,
    closeModal: closeRemoveModal,
  } = useModal();
  const {
    isOpen: isImportOpen,
    openModal: openImportModal,
    closeModal: closeImportModal,
  } = useModal();
  const {
    isOpen: isMakeModelOpen,
    openModal: openMakeModelModal,
    closeModal: closeMakeModelModal,
  } = useModal();

  let actionList = [];
  // default action list
  actionList.push(
    {
      icon: 'edit',
      label: BID_STANDARDITEM_TABLE_ACTIONS.SELECT_MAKE_MODEL,
    },
    {
      icon: 'arrow_downward',
      label: BID_STANDARDITEM_TABLE_ACTIONS.IMPORT_DATA,
    },
    {
      icon: 'cancel',
      label: BID_STANDARDITEM_TABLE_ACTIONS.REMOVE,
    },
  );
  // skip Import Data menu when bid-sin-import-data feature is toggled off
  if (!isFeatureEnabled('bid-sin-import-data')) {
    actionList = [];
    actionList.push(
      {
        icon: 'edit',
        label: BID_STANDARDITEM_TABLE_ACTIONS.SELECT_MAKE_MODEL,
      },
      {
        icon: 'cancel',
        label: BID_STANDARDITEM_TABLE_ACTIONS.REMOVE,
      },
    );
  }

  const onActionClick = (action, item) => {
    setSelectedItem(item);
    switch (action) {
      case BID_STANDARDITEM_TABLE_ACTIONS.REMOVE:
        openRemoveModal();
        break;
      case BID_STANDARDITEM_TABLE_ACTIONS.SELECT_MAKE_MODEL:
        openMakeModelModal();
        break;
      default:
        openImportModal();
    }
  };

  const onCloseRemoveModal = (toRefetch) => {
    closeRemoveModal();
    if (toRefetch === true && refetchData) refetchData();
  };

  const onCloseImportModal = (toRefetch) => {
    closeImportModal();
    if (toRefetch === true && refetchData) refetchData();
  };

  const onCloseMakeModelModal = (toRefetch) => {
    closeMakeModelModal();
    if (toRefetch === true && refetchData) refetchData();
  };

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Standard Item',
        accessor: 'solicitationNumber',
        sortable: true,
        Cell: ({ row }) => {
          return `${row?.original?.standardItemNumber}-${row?.original?.title}`;
        },
      },
      {
        Header: 'Your total Makes and Models selected per SIN',
        accessor: 'afpMake.length',
        sortable: true,
        Cell: ({ row }) => {
          // If there is a scheduleLine there is at lest one make and model
          return (
            <CounterTag
              count={
                (row?.original?.scheduleLine &&
                  row?.original?.afpMake?.length) ||
                0
              }
            />
          );
        },
      },
      {
        Header: 'Actions',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        Cell: ({ row }) => {
          return (
            !isBidClosed && (
              <AFPTableRowAction
                row={row}
                onSelectAction={(action) => onActionClick(action, row.original)}
                actions={actionList}
              />
            )
          );
        },
      },
    ];
    return columnList;
  }, []);

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    return (
      <BidStandardItemDetailsSubRow
        original={original}
        openMakeModelModal={openMakeModelModal}
        setSelectedItem={setSelectedItem}
        isBidClosed={isBidClosed}
      />
    );
  }, []);

  return (
    <>
      {isDataLoading && <Spinner aria-busy="true" size="small" />}

      <AFPTable
        {...tableProps}
        data={bidLine}
        columns={columns}
        renderRowSubComponent={renderRowSubComponent}
        defaultSort={order}
      />
      {/* {!isNoData && <Pagination {...paginationProps} />} */}

      {isNoData && !isDataLoading && (
        <div className="text-center margin-top-neg-2 height-full">
          <EmptyState
            hasBackground
            containerStyles="padding-y-6 height-full"
            topText={<p>No Standard Item available.</p>}
          />
        </div>
      )}

      {isRemoveOpen && selectedItem && (
        <RemoveConfirmationModal
          data={selectedItem}
          onClose={onCloseRemoveModal}
        />
      )}

      {isImportOpen && selectedItem && (
        <ImportStandardItemModal
          standardItem={selectedItem}
          onClose={onCloseImportModal}
        />
      )}

      <BidMakeAndModelWrapper
        isOpen={isMakeModelOpen}
        initialData={{ siData: [selectedItem] }}
        onClose={onCloseMakeModelModal}
      />
    </>
  );
};

export default StandardItemsTable;
