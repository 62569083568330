/* eslint-disable react/destructuring-assignment */
import {
  AFPTable,
  AFPTableRowAction,
  Button,
  Pagination,
  FilterTableFrame,
  StatusBadge,
  EmptyState,
  connectModal,
} from '@gsa/afp-component-library';
import { useRecoilState } from 'recoil';
import { useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { filterSelector } from './solicitation-helpers';
import useDidMountEffect from '../../hooks/use-did-mount';
import {
  ACTIONS,
  COLUMN_HEADERS,
  SOLICITATION_STATUS_BADGE_VARIANT_MAP,
  MODAL_MODES,
} from './constants';
import { UPDATE_SOLICITATION } from './queries';
import SolicitationFilters from './solicitation-filters';
import solicitationNotifications from './utils/solicitation-notification-helpers';
import './styles/solicitation-table.scss';
import useSolicitation from './add-edit-solicitation/apis/solicitation-apis';
import { enDashUnicode } from '../../utilities/constants';
import SolicitationModal from './solicitation-modal';
import SolicitationDetailsRow from './solicitation-details-row';
import useToggle from '../../hooks/use-toggle';
import { ACTION_PARAM_KEY, createURLSearchParam } from './route-utils';
import {
  hasContractAwardedForNewSolicitationVersion,
  hasContractAwardedForSolicitation,
} from './utils/solicitation-table-utils';
import { isUpiidLegacyFormat } from './utils/solicitation-upiid-utils';

/* eslint-disable react/prop-types */
const SolicitationsTable = () => {
  const [selectedSolicitation, setSelectedSolicitation] = useState({});
  const [notifications, setSolicitationNotification] = useRecoilState(
    solicitationNotifications,
  );
  const [showDeleteSolModal, toggleDeleteSolModal] = useToggle();

  // selected solicitation row set in recoil to use in apis and modal js
  const {
    setModalMode,
    setSelectedSolicitationRow,
    solicitations,
    getSolicitations,
  } = useSolicitation();
  const history = useHistory();

  // getting filters from recoil
  const [filters] = useRecoilState(filterSelector);
  const initialTableOrderState = [['solicitationNumber', 'ASC']];
  const [order, setOrder] = useState(initialTableOrderState);
  const [showFilters] = useState(true);
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  const tableRef = React.createRef();

  const getData = () => {
    const { limit, offset } = paginationState;
    getSolicitations({
      variables: {
        filters,
        limit,
        offset,
        order,
      },
    });
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  useEffect(() => {
    // clear notifications
    const filteredNotification = notifications.filter(
      (item) => item.type === 'success',
    );
    setSolicitationNotification([...filteredNotification]);
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const [updateSolicitation] = useMutation(UPDATE_SOLICITATION, {
    onCompleted: () => {
      // set solicitation notification here.
      getData();
      setSolicitationNotification([
        {
          id: 'SUBMIT_SOLICITATION_AS_NEW_NO_SIN',
          message: `Solicitation ${
            selectedSolicitation?.solicitation?.solicitationNumber
          } has been successfully ${
            selectedSolicitation?.solicitation?.status === 'Archived'
              ? 'reactivated.'
              : 'archived.'
          }`,
          type: 'success',
          closeable: true,
          showInModal: false,
        },
      ]);
    },
  });

  const handleUpdateSolicitation = (updateType) => {
    const submitData = {
      solicitationinput: {
        solicitationID: Number(selectedSolicitation?.solicitation?.id),
        solicitationNumber:
          selectedSolicitation?.solicitation?.solicitationNumber,
        title: selectedSolicitation?.solicitation?.title,
        contractYear: Number(selectedSolicitation?.solicitation?.contractYear),
        purchaseTypeCode: selectedSolicitation?.solicitation?.purchaseTypeCode,
        solicitationType: selectedSolicitation?.solicitation?.solicitationType,
        description: selectedSolicitation?.solicitation?.description,
        status: updateType === 'ARCHIVE' ? 'Archived' : 'Draft',
        samUrl: selectedSolicitation?.solicitation?.samUrl,
      },
    };
    updateSolicitation({
      variables: {
        ...submitData,
      },
    });
  };

  useEffect(() => {
    const { solicitation, action } = selectedSolicitation || {};
    if (solicitation?.id) {
      if (solicitation?.status === 'Closed') {
        if (action === ACTIONS.ARCHIVE) handleUpdateSolicitation('ARCHIVE');
      }
      if (solicitation?.status === 'Archived') {
        if (action === ACTIONS.REACTIVATE)
          handleUpdateSolicitation('REACTIVATE');
      }
      if (['Draft', 'New'].includes(solicitation?.status)) {
        if (action === ACTIONS.DELETE) {
          setSelectedSolicitationRow(solicitation);
          setModalMode({
            mode: MODAL_MODES.SOLICITATION_CONFIRM_DELETE,
            showInModal: true,
          });
          toggleDeleteSolModal();
        }
      }
    }
  }, [selectedSolicitation]);

  useDidMountEffect(() => {
    handlePaginationChange(1, paginationState.limit);
    setOrder(initialTableOrderState);
    tableRef.current.toggleSortBy('solicitationNumber', false);
    getData();
  }, [filters]);

  useDidMountEffect(() => {
    getData();
  }, [order, paginationState]);

  const ConnectedDeleteSolModal = connectModal(SolicitationModal);

  const handleSelectedAction = (evt, row) => {
    switch (evt) {
      case ACTIONS.REOPEN:
      case ACTIONS.MIDCYCLE:
      case ACTIONS.RENEW:
        history.push({
          pathname: `/catalog/solicitations/edit-solicitation/${row.original.id}/upiid-details`,
          search: `?${createURLSearchParam({ [ACTION_PARAM_KEY]: evt })}`,
          state: row.original,
        });
        break;
      case ACTIONS.EDIT:
        history.push({
          pathname: `/catalog/solicitations/edit-solicitation/${row.original.id}/upiid-details`,
          state: row.original,
        });
        break;
      case ACTIONS.REACTIVATE: {
        history.push({
          pathname: `/catalog/solicitations/edit-solicitation/${row.original.id}/upiid-details`,
          search: `?${createURLSearchParam({
            [ACTION_PARAM_KEY]: hasContractAwardedForSolicitation(row.original)
              ? ACTIONS.MIDCYCLE
              : ACTIONS.REOPEN,
          })}`,
          state: row.original,
        });
        break;
      }
      case ACTIONS.ARCHIVE:
      case ACTIONS.DELETE:
      default:
        setSelectedSolicitation({
          solicitation: row.original,
          action: evt,
        });
        break;
    }
  };

  const isValidUPIID = (uPIID) => {
    const regex = /^47QMCA\d{2}[A-Za-z]\d{4}$/;
    return regex.test(uPIID);
  };

  const getActionsForSolicitation = (original) => {
    if (!isValidUPIID(original.solicitationNumber)) {
      return [];
    }

    const editAction = {
      icon: 'edit',
      label: 'Edit',
    };

    const deleteAction = {
      icon: 'delete',
      label: 'Delete',
    };

    const archiveAction = {
      icon: 'arrow_downward',
      label: 'Archive',
    };

    const reactivateAction = {
      icon: 'downward_downward',
      label: 'Reactivate',
    };

    const reopenAction = {
      icon: 'autorenew',
      label: 'Reopen',
    };
    const renewAction = {
      icon: 'edit',
      label: 'Renew',
    };
    const midCycleAction = {
      icon: 'autorenew',
      label: 'Mid-cycle',
    };
    const actions = [];
    // need more logic for others
    switch (original.status) {
      case 'Draft':
        actions.push(editAction);
        actions.push(deleteAction);
        break;
      case 'New':
        actions.push(editAction);
        actions.push(deleteAction);
        break;
      case 'Open':
        actions.push(editAction);
        break;
      case 'Closed': {
        actions.push(
          hasContractAwardedForSolicitation(original)
            ? midCycleAction
            : reopenAction,
        );
        if (
          original?.solicitationType !== 'Standalone' &&
          !hasContractAwardedForNewSolicitationVersion(original)
        ) {
          actions.push(renewAction);
        }
        actions.push(archiveAction);
        break;
      }
      case 'Archived':
        if (!isUpiidLegacyFormat(original?.solicitationNumber))
          actions.push(reactivateAction);
        break;
      default:
        actions.push(editAction);
    }

    return actions;
  };

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: COLUMN_HEADERS.uPIID,
        accessor: 'solicitationNumber',
        Cell: ({ row: { original }, value }) => (
          <Button
            variant="unstyled"
            // eslint-disable-next-line no-console
            onClick={() => {
              history.push(`/catalog/solicitations/${original.id}`);
            }}
            label={value}
          />
        ),
      },
      {
        Header: COLUMN_HEADERS.title,
        accessor: 'title',
      },
      {
        Header: COLUMN_HEADERS.program,
        accessor: 'programs',
        minWidth: 30,
        width: 150,
        maxWidth: 400,
        sortable: false,
        Cell: ({ value }) =>
          value?.map((aProgram) => aProgram.program)?.join(', '),
      },
      {
        Header: COLUMN_HEADERS.process,
        accessor: 'solicitationType',
        minWidth: 30,
        width: 150,
        maxWidth: 400,
      },
      {
        Header: COLUMN_HEADERS.status,
        accessor: 'status',
        minWidth: 300,
        width: 400,
        maxWidth: 600,
        Cell: ({ value }) => (
          <StatusBadge variant={SOLICITATION_STATUS_BADGE_VARIANT_MAP[value]}>
            {value}
          </StatusBadge>
        ),
      },
      {
        Header: COLUMN_HEADERS.action,
        sortable: false,
        minWidth: 30,
        width: 150,
        maxWidth: 400,
        Cell: (props) => {
          const actions = getActionsForSolicitation(props.row.original);
          if (actions.length === 0) {
            return enDashUnicode;
          }

          return (
            <AFPTableRowAction
              {...props}
              actions={actions}
              onSelectAction={(evt) => handleSelectedAction(evt, props.row)}
            />
          );
        },
      },
    ];
    return columnList;
  }, []);

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    const { id } = original;

    return <SolicitationDetailsRow solicitationId={id} />;
  }, []);

  const handleSort = (options) => {
    const [columnWithTemplateQuotes, direction] = options?.split(' ');
    const column = columnWithTemplateQuotes?.replace(/^`|`$/g, '');
    setOrder([[column, direction]]);
  };

  const TableWrapper = ({ tableProps, paginationProps }) => (
    <>
      <AFPTable {...tableProps} />
      {tableProps.data?.length ? <Pagination {...paginationProps} /> : null}
      {tableProps.data === null ||
        (tableProps.data?.length === 0 && (
          <div className="text-center margin-top-neg-2 height-full">
            <EmptyState
              hasBackground
              containerStyles="padding-top-9 height-full"
              topText={
                <p
                  aria-label="There are no solicitations for the selected contract year."
                  // eslint-disable-next-line
                  tabIndex={0}
                >
                  <strong>
                    There are no solicitations for the selected contract year.
                  </strong>
                </p>
              }
            />
          </div>
        ))}
    </>
  );

  const FTF = useMemo(
    () => FilterTableFrame(null, Button, SolicitationFilters, TableWrapper),
    [],
  );

  return (
    <>
      {' '}
      <FTF
        upRightProps={{
          label: 'Create solicitation',
          leftIcon: { name: 'add', type: '', className: '' },
          className: 'float-right',
          onClick: () =>
            history.push('/catalog/solicitations/new-solicitation'),
        }}
        lowRightProps={{
          tableProps: {
            testId: 'solicitation-table',
            expandable: true,
            fullWidth: true,
            ref: tableRef,
            columns,
            data: solicitations.rows || [],
            renderRowSubComponent,
            defaultSort: order,
            onSort: handleSort,
          },
          paginationProps: {
            itemsPerPageOptions: [10, 25, 50],
            onPageChange: handlePaginationChange,
            variant: 'advanced',
            itemsPerPage: paginationState.limit,
            currentPage: paginationState.currentPage,
            itemsCount: solicitations?.count,
          },
        }}
        filterToggle={showFilters}
      />
      {selectedSolicitation?.solicitation?.id && (
        <ConnectedDeleteSolModal
          isOpen={showDeleteSolModal}
          onClose={toggleDeleteSolModal}
          onComplete={getData}
        />
      )}
    </>
  );
};

export default SolicitationsTable;
