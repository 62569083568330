import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const MarkUpText = ({ content, textToMarkUp, marker: Marker, markerProps }) => {
  const MarkedText = <Marker {...markerProps}>{textToMarkUp}</Marker>;
  if (!textToMarkUp || !content?.includes(textToMarkUp)) return content;

  return (
    <>
      {content?.split?.(textToMarkUp)?.map?.((splitText, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          {index !== 0 ? MarkedText : ''}
          {splitText}
        </Fragment>
      ))}
    </>
  );
};

MarkUpText.propTypes = {
  content: PropTypes.string,
  textToMarkUp: PropTypes.string,
  marker: PropTypes.node,
  markerProps: PropTypes.objectOf(PropTypes.any),
};
MarkUpText.defaultProps = {
  content: '',
  textToMarkUp: '',
  marker: 'span',
  markerProps: {},
};

export default MarkUpText;
