import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import {
  DatePicker,
  SelectDropdown,
  Textbox,
  TextInput,
  ErrorMessage,
} from '@gsa/afp-component-library';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  capitalizeFirstLetter,
  convertToFullName,
} from '../helpers/helperFunc';
import {
  getTomorrowStr,
  USDateStrToUTC,
} from '../../../utilities/date-helpers';
import { useTaskDetail } from '../task-detail-provider';

const schema = yup.object().shape({
  assignedUser: yup
    .string()
    .notOneOf(['Select - ', ''], 'User must be selected'),
  documentLink: yup
    .string()
    .typeError('Document link is a required field')
    .required('Document link is a required field')
    .matches(
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      'Must be a link',
    ),
  description: yup
    .string()
    .nullable()
    .max(250, 'Description cannot exceed 250 characters'), // not needed since text area already limits the amount of char
});

const EditTaskForm = ({ handleSave }) => {
  const { task, users } = useTaskDetail();
  const tomorrowStr = getTomorrowStr();

  const { errors, watch, control, handleSubmit, setValue, formState } = useForm(
    {
      resolver: yupResolver(schema),
    },
  );

  const { dirtyFields } = formState;

  const watchContent = watch('content');

  const createErrorMessage = (err) =>
    err ? <ErrorMessage>{err.message}</ErrorMessage> : null;

  const onSubmit = (data) => {
    handleSave({
      ...data,
      content: watchContent,
      isAssigneeChanged: !!dirtyFields.assignedUser,
    });
  };

  const renderRequiredComponent = (value) => {
    return (
      <>
        <h2 className="font-sans-md">
          {value} <span style={{ color: 'red' }}>*</span>
        </h2>
      </>
    );
  };

  const defaultDropdownOption = [{ value: '', label: 'Select - ' }];

  const userOptions =
    users
      ?.map((o) => ({
        value: o.id,
        label: `${o.fullName}`,
      }))
      .concat(defaultDropdownOption)
      .reverse() || defaultDropdownOption;

  return (
    <form
      id="edit-task-form"
      className="margin-top-2"
      data-testid="update-account-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* Assign from */}
      <div className="margin-top-6 margin-bottom-6">
        <p>
          <strong>Assign from</strong>
        </p>
        {convertToFullName(users, task.assignor)}
      </div>

      {/* Assign to */}
      <div>
        {renderRequiredComponent('Assign to')}
        <Controller
          data-testid="assign-from-testid"
          name="assignedUser"
          control={control}
          defaultValue={task.assignedUser}
          render={({ onChange, value }) => (
            <SelectDropdown
              data-testid="assign-from-sel-testid"
              onChange={(ev) => {
                onChange(ev.target.value);
              }}
              options={userOptions}
              value={value}
            />
          )}
        />
        {createErrorMessage(errors.assignedUser)}
      </div>

      {/* Document Author */}
      <div className=" margin-top-6">
        <h2 className="font-sans-md">Document author</h2>
        {convertToFullName(users, task.createdByUser)}
      </div>

      {/* Due Date */}
      <div className=" margin-top-6 usa-form-group">
        <h2 className="font-sans-md">Due Date</h2>
        <div className="usa-hint" id="due-date-hint">
          mm/dd/yyyy
        </div>
        <Controller
          name="dueDate"
          data-testid="due-date-testid"
          control={control}
          minDate="0000-01-01"
          defaultValue={task.dueDate ?? null}
          render={({ name, value, onBlur }) => (
            <DatePicker
              id="dueDate"
              name={name}
              defaultValue={value < tomorrowStr ? null : value}
              minDate={tomorrowStr}
              onBlur={onBlur}
              onChange={(val) => {
                setValue('dueDate', val ? USDateStrToUTC(val) : null);
              }}
              value={value}
            />
          )}
        />
      </div>

      {/* Document Link */}
      <div className=" margin-top-6">
        {renderRequiredComponent('Document link')}
        <Controller
          data-testid="doc-link-testid"
          name="documentLink"
          defaultValue={task.documentLink ?? null}
          control={control}
          render={({ name, onChange, value }) => (
            <Textbox
              data-testid="doc-link-textbox-testid"
              placeholder={
                task.documentLink ? task.documentLink : 'URL goes here'
              }
              type="text"
              value={value}
              name={name}
              onChange={(e) => onChange(e.target.value)}
            />
          )}
        />
        {createErrorMessage(errors.documentLink)}
      </div>

      {/* Federal Vehicle Standards Link */}
      <div className=" margin-top-6">
        <h2 className="font-sans-md">Federal Vehicle Standards link</h2>
        <Link to="/catalog/vehicle-standards">Go to page</Link>
      </div>

      {/* Document status */}
      <div className="margin-top-6">
        <>
          <h2 className="font-sans-md">Document status</h2>
        </>
        {capitalizeFirstLetter(task.documentStatus)}
      </div>

      {/* Fed Std Doc - Program Name - Class Type */}
      <div className="margin-top-6">
        <>
          <h2 className="font-sans-md">
            Fed Std Doc: Program Name - Class Type
          </h2>
        </>

        {`${task.fedStandardDoc}: ${task.programName ?? ''}-${
          task.classType ?? ''
        }`}
      </div>

      {/* Description */}
      <div className="margin-top-6 padding-bottom-2">
        <div className="">
          <h2 className="font-sans-md">Description</h2>
        </div>
        <div className="grid-col-10">
          <Controller
            data-testid="task_edit_description"
            name="description"
            control={control}
            defaultValue={task.description ?? null}
            render={({ onChange, value }) => (
              <TextInput
                data-testid="task_edit_description-testid"
                defaultValue={task.description ?? null}
                type="textarea"
                maxLength={250}
                hint="250 characters allowed"
                onChange={(val) => {
                  onChange(val);
                }}
                content={value}
              />
            )}
          />
          {createErrorMessage(errors.description)}
        </div>
      </div>
    </form>
  );
};

EditTaskForm.propTypes = {
  handleSave: PropTypes.func.isRequired,
};

export default EditTaskForm;
