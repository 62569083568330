import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@gsa/afp-component-library';
import ViewSolicitationStandardItems from './view-solicitation-std-items';

const ViewSolicitationStdItemsModal = ({ closeModal, stdItems }) => {
  const ModalTitle = () => {
    return (
      <div className="margin-top-5">
        {/* eslint-disable-next-line */}
        <h1 aria-label="Related Standard Items" tabIndex="0">
          Related Standard Items
        </h1>
      </div>
    );
  };

  const ModalContent = () => {
    return (
      <>
        <div>
          <ViewSolicitationStandardItems standardItems={stdItems} />
        </div>
      </>
    );
  };

  return (
    <Modal
      id="view-solicitation-std-items-modal"
      test-id="view-solicitation-std-items-modal"
      title={<ModalTitle />}
      onClose={closeModal}
      variant="extra-large"
      actions={
        <>
          <Button
            type="button"
            variant="unstyled"
            onClick={closeModal}
            label="Cancel"
          />
        </>
      }
    >
      <ModalContent stdItems={stdItems} />
    </Modal>
  );
};

ViewSolicitationStdItemsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  stdItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ViewSolicitationStdItemsModal;
