import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Button, AFPTable, EmptyState } from '@gsa/afp-component-library';
import { useBidLineDetails } from '../provider/bid-line-details-provider';
import {
  getEngineColumns,
  EngineExpandedRow,
} from '../provider/table-defs-engine';
import { AUTO_SAVE_BID_LINE_ENGINE } from '../provider/queries';
import {
  getFuelInputMap,
  isEngineDataReady,
  string2number,
  string2bool,
  DELIMITER,
  FAST_CHARGE_TYPE,
  resetFcTypes,
} from '../provider/helpers';
import { TAB_KEY } from '../copy-line-item/helpers';
import { getOriginalValue } from '../components/table-input-fields';

const EngineAndFuel = ({ openCopyModal }) => {
  const {
    bidLine,
    FuelTypes,
    engineTable,
    setEngine,
    isAdmin,
  } = useBidLineDetails();

  const [autoSaveBidLineEngine] = useMutation(AUTO_SAVE_BID_LINE_ENGINE);

  const updateChargingBlock = (original, fields, value) => {
    const [subField, fcType, fcTypeField] = fields;
    const charging = { ...original.charging };
    if (subField === 'typeInput' && value.includes(FAST_CHARGE_TYPE)) {
      // set fast charge capable if includes fast charge type
      charging.typeInput = value;
      charging.isFastChargeCapableInput = 'Y';
    } else if (subField === 'isFastChargeCapableInput' && value !== 'Y') {
      // reset fast charge types if is not capable
      charging.isFastChargeCapableInput = value;
      charging.fcTypes = resetFcTypes();
    } else if (subField === 'fcTypes') {
      charging.fcTypes[fcType][fcTypeField] = value;
      if (fcTypeField === 'checked' && !value) {
        // clear fast charge type field if unchecked
        charging.fcTypes[fcType].userEnteredType = '';
        charging.fcTypes[fcType].chargeOption = '';
      }
    } else {
      charging[subField] = value;
    }
    return charging;
  };

  const updateUIstates = (original, fieldName, value) => {
    const fields = fieldName.split('.');
    let data; // form data update
    let table; // table UI update
    if (fields[0] === 'fuelTypeCodeInput') {
      // update fuel type map and clear fields not in the map
      const fuelMap = getFuelInputMap(value, FuelTypes);
      const emptyMpgObj = {
        cityInput: '',
        highwayInput: '',
        combinedInput: '',
        gpmiInput: '',
      };
      const emptyChargingObj = {
        typeInput: [],
        isFastChargeCapableInput: '',
        fcTypes: resetFcTypes(),
        bkwhInput: '',
      };
      const resetFields = {
        ...(Object.keys(fuelMap).length > 0 || { isFuelDataUnknown: false }),
        ...(fuelMap.displacement || {
          cylindersInput: '',
          engineLitresInput: '',
        }),
        ...(fuelMap.range || { rangeInput: '' }),
        ...(fuelMap.rangeE || { rangeElectricInput: '' }),
        ...(fuelMap.charging || { charging: emptyChargingObj }),
        ...(fuelMap.mpgC || { mpgC: emptyMpgObj }),
        ...(fuelMap.mpgA || { mpgA: emptyMpgObj }),
        ...(fuelMap.mpgE || { mpgE: emptyMpgObj }),
      };
      data = {
        fuelTypeCodeInput: value,
        fuelInputMap: fuelMap,
        ...resetFields,
      };
      if (value) table = { isExpanded: true };
    } else if (fields[0] === 'deliveryRegionType' && value !== 'Other') {
      // clear states if region is not 'Other'
      data = { deliveryRegionType: value, deliveryStatesInput: [] };
    } else if (fields[0] === 'charging') {
      data = {
        charging: updateChargingBlock(original, fields.slice(1), value),
      };
    } else {
      data = fields[1]
        ? { [fields[0]]: { ...original[fields[0]], [fields[1]]: value } }
        : { [fields[0]]: value };
    }

    // update ready state and SI flags
    if (original.requiresOCONUS !== original.isMetric)
      data.isMetric = original.requiresOCONUS;
    if (original.requiresGPTKM !== original.isGPTKM)
      data.isGPTKM = original.requiresGPTKM;
    data.ready = isEngineDataReady({ ...original, ...data });
    setEngine(original.id, { data, table });
  };

  const autoSave = async (original, fieldName, value) => {
    const input = {};
    const [field, subField, fcType, fcTypeField] = fieldName.split('.');

    if (original.bidLineEngineId) {
      // existing line
      input.id = original.bidLineEngineId;
    } else {
      // new line
      input.bidLineId = +bidLine.id;
      input.associationId = original.id;
      input.equipmentCode = original.equipmentCode;
    }
    if (original.requiresOCONUS !== original.isMetric)
      input.isMetric = original.requiresOCONUS;
    if (original.requiresGPTKM !== original.isGPTKM)
      input.isGPTKM = original.requiresGPTKM;

    const parseMpg = () => {
      const fuelType = field.substring(3, 4);
      let type = 'Grams/mi';
      if (subField === 'cityInput') type = 'City';
      else if (subField === 'highwayInput') type = 'Highway';
      else if (subField === 'combinedInput') type = 'Combined';
      return { fuelType, type, value: string2number(value) };
    };

    switch (field) {
      case 'fuelTypeCodeInput':
        input.fuelTypeCodeId = string2number(value);
        input.fuelTypeCode = FuelTypes.find((ft) => ft.id === value)?.code;
        break;
      case 'deliveryStatesInput':
        input.deliveryStates = value.join(DELIMITER);
        break;
      case 'engineLitresInput':
      case 'cylindersInput':
      case 'rangeInput':
      case 'rangeElectricInput':
        // number fields
        input[field.substring(0, field.length - 5)] = string2number(value);
        break;
      case 'mpgC':
      case 'mpgA':
      case 'mpgE':
        input.mpg = parseMpg();
        break;
      case 'charging':
        if (subField === 'typeInput')
          input.charging = { type: value.join(DELIMITER) };
        else if (subField === 'isFastChargeCapableInput')
          input.charging = { isFastChargeCapable: string2bool(value) };
        else if (subField === 'bkwhInput')
          input.charging = { bkwh: string2number(value) };
        else {
          // fast charge types
          input.charging = {
            fastChargeTypes: [
              {
                fastChargeType: fcType,
                [fcTypeField]: value === false ? false : value || null,
              },
            ],
          };
        }
        break;
      default:
        // 'engineModel', 'deliveryRegionType', 'isFuelDataUnknown', 'explanation'
        input[field] = value;
    }

    // for admin user, return the original bid-line-ec id
    // this prevents the auto-save but still allows the UI to update
    if (isAdmin) return { bidLineEngineId: original.bidLineEngineId };

    return autoSaveBidLineEngine({ variables: { input } })
      .then(async ({ data }) => ({
        bidLineEngineId: data.autoSaveBidLineEngine,
      }))
      .catch((error) => ({ error }));
  };

  const onUpdate = async (original, fieldName, value) => {
    const fieldValue = getOriginalValue(original, fieldName);
    // no change
    const compareValue = (val1, val2) => {
      if (typeof val1 !== 'object') return val1 === val2;
      return JSON.stringify(val1) === JSON.stringify(val2);
    };
    if (compareValue(fieldValue, value)) return;

    updateUIstates(original, fieldName, value);

    // auto save updates
    const { bidLineEngineId, error } = await autoSave(
      original,
      fieldName,
      value,
    );

    // roll back UI states if autosave fails
    if (error) {
      setEngine(original.id, { data: original });
      // global gql API error Alert automatically shown
      return;
    }

    // update id if new line is successfully added
    if (bidLineEngineId !== original.bidLineEngineId)
      setEngine(original.id, { data: { bidLineEngineId } });
  };

  const columns = useMemo(() => getEngineColumns({ onUpdate }), [onUpdate]);
  const subrow = useMemo(
    // eslint-disable-next-line
    () => ({ row }) => <EngineExpandedRow row={row} onUpdate={onUpdate} />,
    [onUpdate],
  );

  return (
    <div className="margin-bottom-8 bid-line-item-details">
      <div className="display-flex flex-row flex-justify flex-align-end margin-bottom-4">
        <h3>Engine and fuel</h3>
        <Button
          variant="outline"
          label="Copy engine and fuel"
          leftIcon={{
            name: 'content_copy',
          }}
          className="margin-left-2"
          onClick={() => openCopyModal(TAB_KEY.engineFuel)}
        />
      </div>

      <AFPTable
        fullWidth
        columns={columns}
        data={engineTable}
        renderRowSubComponent={subrow}
      />
      {!engineTable.length && (
        <div className="table-empty-state">
          <EmptyState bottomText="Engine and fuel not available" />
        </div>
      )}
    </div>
  );
};

EngineAndFuel.propTypes = {
  openCopyModal: PropTypes.func.isRequired,
};

export default EngineAndFuel;
