import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@gsa/afp-component-library';

const Notification = ({ content, type }) => {
  const [closed, setClosed] = useState(false);

  return (
    <>
      {!closed ? (
        <Alert type={type} showClose onClose={() => setClosed(true)}>
          {content}
        </Alert>
      ) : null}
    </>
  );
};

Notification.propTypes = {
  type: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};

export default Notification;
