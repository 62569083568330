/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import '../styles/standard-forms.scss';

export const StandardFieldset = ({ label, children, name, className }) => {
  const titleClassName = cx('margin-bottom-2', { [className]: !!className });
  return (
    <fieldset
      className="usa-fieldset usa-fieldset  margin-bottom-2"
      name={name}
    >
      {label && (
        <legend
          className="usa-legend font-body-lg text-bold"
          tabIndex="0"
          aria-label={label}
        >
          <h4 className={titleClassName}>{label}</h4>
        </legend>
      )}
      {children}
    </fieldset>
  );
};
StandardFieldset.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
StandardFieldset.defaultProps = {
  label: undefined,
  className: '',
};

export default StandardFieldset;
