import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typeahead, FilterPanel } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { GET_MODELS_BY_PARTIAL_MATCH } from '../../queries.gql';

export const modelTypeAheadValue = (modelName, modelCode) => {
  return [modelName, modelCode].join(' - ');
};

const { useFilterPanel } = FilterPanel;

let globalMakeCode = '';

const ModelTypeAhead = ({ filter }) => {
  const {
    setFilters: setFilterPanelFilters,
    clearOneFilter,
  } = useFilterPanel();

  const { key, placeholder, ariaLabel, customFieldProps } = filter || {};
  const { inputCharNum, makeCode } = customFieldProps || {};
  const [suggestions, setSuggestions] = useState([]);
  const [values, setValues] = useState([]);
  const [, setError] = useState(null);
  const [searchValue] = useState('');

  useEffect(() => {
    globalMakeCode = makeCode;
  }, [makeCode]);

  const [getModelsByPartialName] = useLazyQuery(GET_MODELS_BY_PARTIAL_MATCH, {
    onCompleted: (data) => {
      setSuggestions(data?.getModelsByPartialMatch);
      setValues(
        data.getModelsByPartialMatch.map(
          (m) =>
            `${m.modelName} - ${
              m?.nhtsaModelCode ? m?.nhtsaModelCode : m.modelCode
            }`,
        ),
      );
    },
    onError: () => {
      setError('Unable to fetch model suggestions.');
    },
  });

  const fetchValues = (_, value) => {
    getModelsByPartialName({
      variables: {
        makeCode: parseInt(globalMakeCode, 10),
        modelName: value?.split(' - ')[0],
        includeRejectedItems: true,
        dataSource: ['AFP', 'NHTSA'],
      },
    });
  };

  const handleTypeAheadClear = () => {
    clearOneFilter(key);
    setFilterPanelFilters({
      type: 'setOne',
      fetchNewData: false,
      filter: {
        ...filter,
        displayValue: () => '',
        value: '',
      },
    });
  };

  const shortenModelCode = (modelCode, max) => {
    if (modelCode.length > 10) {
      return modelCode.substring(0, max);
    }
    return modelCode;
  };

  const handleMakeSelection = (data) => {
    const { modelName, modelCode, nhtsaModelCode } = data || {};
    if (modelCode) {
      setFilterPanelFilters({
        type: 'setOne',
        fetchNewData: true,
        filter: {
          ...filter,
          displayValue: () =>
            `Model: ${modelName} Code: ${
              nhtsaModelCode || shortenModelCode(modelCode, 5)
            }`,

          value: modelCode,
        },
      });
    }
  };

  const generateCustomOption = (opt) => {
    const item = suggestions?.find((o) => o.modelCode === opt?.split(' - ')[1]);
    return (
      <>
        {item ? (
          <div className="display-flex flex-column">
            <span className="text-ink">Make: {item?.modelName}</span>
            <span className="text-ink">
              Code:{' '}
              {item?.nhtsaModelCode
                ? item?.nhtsaModelCode
                : shortenModelCode(item?.modelCode, 8)}
            </span>
          </div>
        ) : (
          opt
        )}
      </>
    );
  };

  return (
    <Typeahead
      key={key}
      filterValue={searchValue}
      placeholder={placeholder}
      ariaLabel={ariaLabel}
      onOptionEnter={(value) => {
        const segs = value.split(' - ');
        const code = segs[segs.length - 1];
        handleMakeSelection(
          suggestions.find(
            (m) => m.modelCode === code || m?.nhtsaModelCode === code,
          ),
        );
      }}
      onClear={handleTypeAheadClear}
      typeaheadValues={values}
      fetchTypeaheadValues={fetchValues}
      inputCharNum={inputCharNum}
      reset={!filter?.value}
      generateCustomOption={generateCustomOption}
      debounceDelay={500}
      promptText="Type to search for models"
    />
  );
};

ModelTypeAhead.propTypes = {
  filter: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
    operator: PropTypes.string,
    placeholder: PropTypes.string,
    ariaLabel: PropTypes.string,
    customFieldProps: PropTypes.shape({
      inputCharNum: PropTypes.number,
      setFilters: PropTypes.func,
      currentFilters: PropTypes.shape({
        value: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string,
            operator: PropTypes.string,
            value: PropTypes.string,
          }),
        ),
      }),
    }),
  }).isRequired,
};

export default ModelTypeAhead;
