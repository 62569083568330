import React, { createContext, useContext, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import fileDownload from 'js-file-download';
import {
  CREATE_APPENDIX_DOC,
  GET_STANDARDS_REL_DOCS,
  UPLOAD_FVS_DOC,
} from './fvs.gql';
import { useFederalStandards } from './fvs-provider';
import { GET_AWS_SIGNED_READ_URL } from '../../components/Attachment/helpers';
import { POLLING } from './constants';

export const FvsSupportingDocsContext = createContext({});

const initialState = {
  isAttachmentLoading: false,
  supportingDocs: {
    count: 0,
    hasMore: false,
    rows: [],
  },
  selectedDocument: null,
  signedUrl: null,
  showDocCommentTab: false,
  fileName: null,
};

// current polling age cumulator;
let Age = 0;

const FvsSupportingDocsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_IS_ATTACHMENT_LOADING': {
      return { ...state, headerCopy: action.payload };
    }
    case 'SET_SHOW_DOC_COMMENT_TAB': {
      return { ...state, showDocCommentTab: action.payload };
    }
    case 'GET_SUPPORTING_DOCS': {
      return { ...state, supportingDocs: action.payload };
    }
    case 'SET_SELECTED_DOC': {
      return { ...state, selectedDocument: action.payload };
    }
    case 'SET_PDF_PREVIEW': {
      return { ...state, signedUrl: action.payload };
    }
    case 'SET_FILE_NAME': {
      return { ...state, fileName: action.payload };
    }
    default:
      return state;
  }
};

function FvsSupportingDocsProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(
    FvsSupportingDocsReducer,
    initialState,
    () => {
      return initialState;
    },
  );

  const [documentCount, setDocumentCount] = useState(0);
  const [ispollingCallable, setIspollingCallable] = useState(false);

  const {
    addOrReplaceFvsMessageById,
    vehicleStandard,
    setFvsError,
  } = useFederalStandards();

  const fetchDocumentVariables = {
    variables: {
      filters: {
        operator: 'AND',
        value: [
          {
            operator: 'EQ',
            key: 'fvsId',
            value: vehicleStandard?.id,
          },
        ],
      },
      limit: 5,
      offset: 0,
      order: 'createdAt DESC',
    },
  };

  /**
   * Add state
   */
  const [addState, setAddState] = useState({
    show: false,
    adding: false,
    added: false,
  });

  /**
   * Error state
   */
  const [attachmentErrors, setAttachmentErrors] = useState({});

  const setFvsSupportingDocsData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const [getStandardsSupportingDocs] = useLazyQuery(GET_STANDARDS_REL_DOCS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (requestError) => {
      setFvsError(requestError);
    },
    onCompleted: (responseData) => {
      if (responseData?.getStandardsDocuments) {
        setDocumentCount(responseData?.getStandardsDocuments.count);
        setFvsSupportingDocsData(
          'GET_SUPPORTING_DOCS',
          responseData.getStandardsDocuments,
        );
      }
    },
  });

  const [
    getStandardsSupportingDocsPolling,
    // TODO: remove polling when our package is updated to appollo client 3.3.7
    { startPolling, stopPolling },
  ] = useLazyQuery(GET_STANDARDS_REL_DOCS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !ispollingCallable,
    onError: (requestError) => {
      setFvsError(requestError);
    },
    onCompleted: (responseData) => {
      if (responseData?.getStandardsDocuments) {
        if (responseData?.getStandardsDocuments.count <= documentCount) {
          /* do not poll after current age bypasses maximum polling age
             this might b/c of long document generation process which
             is > than 2 minutes or the document creation process failed at ll
          */
          if (Age <= POLLING.MaxAge) {
            startPolling(POLLING.Interval);
            // update the current age with the polling interval
            Age += POLLING.Interval;
          } else {
            stopPolling();
            setFvsSupportingDocsData('SET_IS_ATTACHMENT_LOADING', false);
            const message = {
              id: 'DOCUMENT',
              message:
                'Unable to get Appendix document, please try again later by refreshing the page .',
              type: 'error',
            };

            addOrReplaceFvsMessageById(message);
          }
        } else {
          // TODO: remove polling when our package is updated to appollo client 3.3.7
          stopPolling();
          setFvsSupportingDocsData('SET_IS_ATTACHMENT_LOADING', false);
          const message = {
            id: 'DOCUMENT',
            message:
              'Appendix document created successfully and it is listed in the documents section below.',
            type: 'success',
          };

          // TODO: remove polling when our package is updated to appollo client 3.3.7
          // eslint-disable-next-line
          ispollingCallable && addOrReplaceFvsMessageById(message);
          // eslint-disable-next-line
          ispollingCallable &&
            setFvsSupportingDocsData(
              'GET_SUPPORTING_DOCS',
              responseData.getStandardsDocuments,
            );
        }
      }
    },
  });

  const [createAppendix] = useMutation(CREATE_APPENDIX_DOC, {
    onError: (requestError) => {
      setFvsError(requestError);
    },
    onCompleted: (responseData) => {
      if (responseData) {
        setFvsSupportingDocsData('SET_IS_ATTACHMENT_LOADING', true);
        const message = {
          id: 'DOCUMENT',
          message:
            'Appendix document creation initiated and will be listed in the documents section momentarily.',
          type: 'success',
        };
        addOrReplaceFvsMessageById(message);
        // call getStandardsSupportingDocs to get the current document count
        getStandardsSupportingDocs(fetchDocumentVariables);
        setIspollingCallable(true);
        // call getStandardsSupportingDocsPolling to check if a new document is generated
        getStandardsSupportingDocsPolling(fetchDocumentVariables);
      }
    },
  });

  const [uploadFvsDocument] = useMutation(UPLOAD_FVS_DOC, {
    onError: (requestError) => {
      setFvsError(requestError);
    },
    onCompleted: (responseData) => {
      return responseData;
    },
  });

  /**
   *  Save Attachment to S3.
   */
  const saveDocument = async (formData, docMetadata) => {
    try {
      const res = await fetch(docMetadata.signedUrl, {
        method: 'PUT',
        body: formData,
      });
      if (res.ok) {
        const status = formData.get('status');
        const docName = formData.get('attachment').name;
        const message = {
          id: 'DOCUMENT',
          message: (
            <span>
              You have successfully uploaded <strong>{docName}</strong> in{' '}
              <strong>{status}</strong> status.
            </span>
          ),
          type: 'success',
        };
        addOrReplaceFvsMessageById(message);
        getStandardsSupportingDocs(fetchDocumentVariables);
      } else {
        setAttachmentErrors({
          save: {
            message: res.statusText,
          },
        });
      }
    } catch (e) {
      setAttachmentErrors({
        save: {
          message: e.message,
        },
      });
    }
  };

  const [getSignedURL, { loading: gettingReadURL }] = useLazyQuery(
    GET_AWS_SIGNED_READ_URL,
    {
      onError: (requestError) => {
        setFvsError(requestError);
      },
      onCompleted: async (requestData) => {
        if (requestData?.generateReadSignedURL) {
          setFvsSupportingDocsData(
            'SET_PDF_PREVIEW',
            requestData?.generateReadSignedURL,
          );
        }
      },
    },
  );

  const [downloadFile, { loading: downloadingFile }] = useLazyQuery(
    GET_AWS_SIGNED_READ_URL,
    {
      onError: (requestError) => {
        setFvsError(requestError);
      },
      onCompleted: async (requestData) => {
        if (requestData?.generateReadSignedURL) {
          try {
            const fileURL = requestData.generateReadSignedURL;
            const type = state.selectedDocument?.metadata?.fileMimeType;
            // fetch file using signed url.
            const response = await fetch(fileURL, {
              method: 'GET',
              headers: { 'Content-Type': type },
            });
            // Grab blob from response
            const blob = await response.blob();
            // Browser starts download
            let fn = state.selectedDocument?.metadata?.name;
            if (!fn) {
              fn = state?.fileName;
            }
            fileDownload(blob, fn);
          } catch (e) {
            setFvsError({});
          }
        }
      },
    },
  );

  return (
    <FvsSupportingDocsContext.Provider
      value={{
        ...state,
        gettingReadURL,
        downloadingFile,
        setFvsSupportingDocsData,
        createAppendix,
        addState,
        setAddState,
        saveDocument,
        uploadFvsDocument,
        attachmentErrors,
        getStandardsSupportingDocs,
        getSignedURL,
        downloadFile,
        fetchDocumentVariables,
        setIspollingCallable,
        ...props,
      }}
    >
      {children}
    </FvsSupportingDocsContext.Provider>
  );
}

export default FvsSupportingDocsProvider;

FvsSupportingDocsProvider.defaultProps = {};

FvsSupportingDocsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useFvsSupportingDocs = () => useContext(FvsSupportingDocsContext);
