import React from 'react';
import PropTypes from 'prop-types';
import { DefaultLayout } from '@gsa/afp-component-library';
import { useCurrentUser, useNavigationMenu } from '@gsa/afp-shared-ui-utils';
import GlobalErrorMessages from '../pages/error/global-error-messages';

const Layout = ({ children }) => {
  const { currentUser, isLoggedIn } = useCurrentUser();

  /* To check for global messages in a generic way
   const { data: globalMessages } = useQuery(GetGlobalMessagesCacheQuery, {
    fetchPolicy: "cache-only",   // Used for first execution
  });
  const [globalMessages, setGlobalMessages] = useState(null);
  const querySubscription = dataStore
    .watchQuery({
      query: GetGlobalMessagesCacheQuery,
      fetchPolicy: 'cache-only',
    })
    .subscribe({
      next: ({ data }) => {
        console.log('globalMessages sub', globalMessages);
        setGlobalMessages(data.messages);
      },
    }); */

  const menu = useNavigationMenu();
  return (
    <DefaultLayout currentUser={isLoggedIn && currentUser} menu={menu}>
      <GlobalErrorMessages />
      {children}
    </DefaultLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
