import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import { useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import solicitationNotifications from '../utils/solicitation-notification-helpers';
import { UPDATE_SOLICITATION } from '../queries';

const ArchiveSolicitationButton = ({ solicitation, ...rest }) => {
  const history = useHistory();
  const setSolicitationNotification = useSetRecoilState(
    solicitationNotifications,
  );

  const [updateSolicitation, { loading }] = useMutation(UPDATE_SOLICITATION, {
    onError: () => {
      setSolicitationNotification([
        {
          id: 'ARCHIVE_SOLICITATION_BUTTON_FAILURE',
          message: `An error happened while archiving this soilcitation. Please try again`,
          type: 'error',
          closeable: true,
          showInModal: false,
        },
      ]);
    },
    onCompleted: () => {
      setSolicitationNotification([
        {
          id: 'ARCHIVE_SOLICITATION_BUTTON_SUCCESS',
          message: `Solicitation ${solicitation.solicitationNumber} has been successfully archived`,
          type: 'success',
          closeable: true,
        },
      ]);
      history.push(`/catalog/solicitations`);
    },
  });

  const archiveSolicitation = () => {
    updateSolicitation({
      variables: {
        solicitationinput: {
          solicitationID: Number(solicitation?.id),
          solicitationNumber: solicitation?.solicitationNumber,
          title: solicitation?.title,
          contractYear: Number(solicitation?.contractYear),
          purchaseTypeCode: solicitation?.purchaseTypeCode,
          solicitationType: solicitation?.solicitationType,
          description: solicitation?.description,
          status: 'Archived',
          samUrl: solicitation?.samUrl,
        },
      },
    });
  };

  return (
    <Button
      variant="secondary"
      label="Archive"
      disabled={loading}
      onClick={archiveSolicitation}
      {...rest}
    />
  );
};

ArchiveSolicitationButton.propTypes = {
  solicitation: PropTypes.shape({
    id: PropTypes.string,
    solicitationNumber: PropTypes.string,
    title: PropTypes.string,
    contractYear: PropTypes.number,
    purchaseTypeCode: PropTypes.string,
    solicitationType: PropTypes.string,
    description: PropTypes.string,
    samUrl: PropTypes.string,
  }).isRequired,
};

export default ArchiveSolicitationButton;
