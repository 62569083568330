import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@gsa/afp-component-library';

const OverlaySpinner = ({ message }) => {
  if (message)
    return (
      <div
        className="afp-modal-overlay"
        style={{ background: 'rgba(0, 0, 0, 0.75)' }}
        data-testid="overlay-spinner"
      >
        <Spinner style={{ position: 'absolute', top: '45%' }} />
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, 0)',
            color: '#fff',
            fontSize: '24px',
          }}
        >
          {message}
        </div>
      </div>
    );
  return (
    <div
      role="none"
      className="afp-modal-overlay"
      style={{ background: 'rgba(0, 0, 0, 0.25)' }}
      data-testid="overlay-spinner"
    >
      <Spinner
        style={{
          position: 'absolute',
          top: '50%',
          transform: 'translate(0, -50%)',
        }}
      />
    </div>
  );
};

OverlaySpinner.propTypes = {
  message: PropTypes.node,
};
OverlaySpinner.defaultProps = {
  message: undefined,
};

export default OverlaySpinner;
