import React from 'react';
import { Spinner } from '@gsa/afp-component-library';
import '../styles/bids.scss';
import { useVendorInfo } from './apis/vendor-api';
import BidsAdminView from './admin-view';
import VendorUserView from './vendor-user-view';

const BidsCenter = () => {
  const { hasAdminPrivilege, isHasAdminPrivilegeReady } = useVendorInfo();

  if (!isHasAdminPrivilegeReady) {
    return <Spinner />;
  }

  return hasAdminPrivilege ? <BidsAdminView /> : <VendorUserView />;
};

export default BidsCenter;
