import React from 'react';
import Breadcrumbs from '../../../../../widgets/breadcrumbs';
import { getSolicitationOprType } from '../../../utils/solicitation-status-utils';
import ReOpenPeriods from './re-open-periods';

const ReOpenPeriodWrapper = () => {
  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs?.home}/home`,
      label: 'Home',
    },
    {
      location: '/catalog/solicitations',
      label: 'Solicitations',
    },
  ];

  return (
    <div className="grid-col" data-testId="re-open-periods-wrapper">
      <Breadcrumbs current={getSolicitationOprType()} path={breadcrumbsPath} />
      <ReOpenPeriods />
    </div>
  );
};

export default ReOpenPeriodWrapper;
