import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, connectModal } from '@gsa/afp-component-library';

const ErrorModal = ({ closeModal, isOpen }) => {
  return (
    <Modal
      id="error-modal"
      variant="large"
      className="solicitation-modal"
      onClose={closeModal}
      isOpen={isOpen}
      actions={
        <>
          <Button
            type="button"
            variant="unstyled"
            onClick={closeModal}
            label="Close"
          />
        </>
      }
    >
      <div className="modal-content">
        <h2>Contract line cannot be added</h2>

        <ul className="padding-4 bg-blue-5">
          <li className="text-red-60v">
            The contract needs to be published before adding a contract line.
          </li>
        </ul>
      </div>
    </Modal>
  );
};

ErrorModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default connectModal(ErrorModal);
