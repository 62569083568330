import React from 'react';
import Breadcrumbs from '../../../../../widgets/breadcrumbs';
import { getSolicitationOprType } from '../../../utils/solicitation-status-utils';
import SelectStandardItemWrapper from './sin-selection';

const SolicitationSINSelection = () => {
  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs.home}/home`,
      label: 'Home',
    },
    {
      location: '/catalog/solicitations',
      label: 'Solicitations',
    },
  ];

  return (
    <>
      <div className="grid-col">
        <Breadcrumbs
          current={getSolicitationOprType()}
          path={breadcrumbsPath}
        />
        <SelectStandardItemWrapper />
      </div>
    </>
  );
};

export default SolicitationSINSelection;
