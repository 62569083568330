import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@gsa/afp-component-library';

const DeleteContractLineModal = ({
  isOpen,
  closeModal,
  onDelete,
  selectedItems,
}) => (
  <Modal
    id="delete-contract-line-modal"
    variant="large"
    className="contract-line-modal"
    isOpen={isOpen}
    onClose={closeModal}
    actions={
      <>
        <Button
          type="button"
          variant="unstyled"
          onClick={closeModal}
          label="Cancel"
        />
        <Button
          type="button"
          variant="secondary"
          onClick={onDelete}
          label="Delete"
        />
      </>
    }
  >
    <div className="modal-content">
      <strong>
        Are you sure you want to delete the line(s)
        {selectedItems.map((item) => ` ${item.scheduleLine}`).join(', ')}?
      </strong>
    </div>
  </Modal>
);

DeleteContractLineModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      scheduleLine: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default DeleteContractLineModal;
