import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  Button,
  ButtonDropdown,
  ButtonDropdownItem,
  SelectDropdown,
  // MultiSelectDropdown,
  Checkbox,
  RadioButton,
  Label,
  // RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import OverlaySpinner from '../../../../components/overlay-spinner';
import { FT_PDF, REPORT_CONFIG } from './contract-report-types';
import {
  DEFAULT_OPTION,
  getVendorOptions,
  getSolicitationOptions,
} from './contract-report-helper';
import { GET_CONTRACT_HEADERS_BY_PURCHASE_TYPE_AND_STATUS } from '../query';

const SolicitationSelectionForm = ({
  canViewAll,
  formValues,
  setFormValues,
  onPrev,
  onSubmit,
}) => {
  const reportConfig = REPORT_CONFIG[formValues.report.value];

  const [selectSolVendor, setSelectSolVendor] = useState('Solicitation');
  const [vendorOptions, setVendorOptions] = useState([]);
  const [solicitationOptions, setSolicitationOptions] = useState([]);

  const { loading: optionsLoading } = useQuery(
    GET_CONTRACT_HEADERS_BY_PURCHASE_TYPE_AND_STATUS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        purchaseTypeCode: formValues.reportType.value[0],
        ...(formValues.contractStatus.value.length === 1
          ? { isActive: formValues.contractStatus.value[0] === 'active' }
          : {}),
      },
      onCompleted: ({ getContractHeadersByPurchaseTypeAndStatus }) => {
        const data = getContractHeadersByPurchaseTypeAndStatus?.rows;
        if (data) {
          setVendorOptions(getVendorOptions(data));
          setSolicitationOptions(getSolicitationOptions(data));
        }
      },
      onError: () => {
        setVendorOptions([DEFAULT_OPTION]);
        setSolicitationOptions([DEFAULT_OPTION]);
      },
    },
  );

  const onInputChange = (newFormValues) => {
    setFormValues({
      ...formValues,
      ...newFormValues,
    });
  };

  const onVendorChange = ({ target: { value } }) => {
    const { solicitationIds } = vendorOptions.find(
      (opt) => opt.value === value,
    );
    onInputChange({
      vendor: { value },
      solicitations: { value: solicitationIds },
    });
  };

  const onSolVendorChange = (selection) => {
    setSelectSolVendor(selection);
    setFormValues({
      ...formValues,
      solicitation: { value: '' },
      vendor: { value: '' },
      solicitations: { value: [] },
    });
  };

  const onEmailReportChange = ({ target: { checked } }) => {
    setFormValues({ ...formValues, isEmailReport: { value: checked } });
  };

  const onClickSubmit = (fileType) => {
    let hasError = false;
    const newFormValues = {};

    if (
      reportConfig.solVendorSS &&
      selectSolVendor === 'Solicitation' &&
      !formValues.solicitation.value
    ) {
      newFormValues.solicitation = {
        value: '',
        errorMessage: 'Solicitation is required',
      };
      hasError = true;
    }
    if (
      reportConfig.solVendorSS &&
      selectSolVendor === 'Vendor' &&
      !formValues.vendor.value
    ) {
      newFormValues.vendor = {
        value: '',
        errorMessage: 'Vendor is required',
      };
      hasError = true;
    }
    if (hasError) setFormValues({ ...formValues, ...newFormValues });
    else onSubmit(fileType);
  };

  const SectionSolVendorSS = (
    <div>
      <Label required className="text-bold">
        Select a solicitation or vendor to be included in the report
      </Label>
      {canViewAll && (
        <div className="grid-row grid-gap-5">
          <div className="grid-col-3">
            <RadioButton
              name="selectSolVendor"
              label="Solicitation"
              checked={selectSolVendor === 'Solicitation'}
              onClick={() => {
                onSolVendorChange('Solicitation');
              }}
            />
          </div>

          <div className="grid-col-3">
            <RadioButton
              name="selectSolVendor"
              label="Vendor"
              checked={selectSolVendor === 'Vendor'}
              onClick={() => {
                onSolVendorChange('Vendor');
              }}
            />
          </div>
        </div>
      )}

      {selectSolVendor === 'Solicitation' && (
        <SelectDropdown
          name="select-solicitation"
          data-testid="select-solicitation"
          value={formValues.solicitation.value}
          errorMessage={formValues.solicitation.errorMessage}
          options={solicitationOptions}
          onChange={({ target: { value } }) => {
            onInputChange({ solicitation: { value } });
          }}
          required
        />
        // <div data-testid="solicitations-select">
        //   <label
        //     className="usa-label margin-bottom-1 margin-top-0"
        //     htmlFor="select-solicitations"
        //   >
        //     <strong>
        //       Enter the solicitation uPIID <RequiredFieldIndicator />
        //     </strong>
        //   </label>
        //   <MultiSelectDropdown
        //     name="select-solicitations"
        //     data-testid="select-solicitations"
        //     selectedValues={formValues.solicitations.value}
        //     options={solicitationOptions}
        //     noResults={() => 'No solicitations found'}
        //     onChange={(value) => onInputChange('solicitations', value)}
        //   />
        // </div>
      )}

      {selectSolVendor === 'Vendor' && (
        <SelectDropdown
          name="select-vendor"
          data-testid="select-vendor"
          value={formValues.vendor.value}
          errorMessage={formValues.vendor.errorMessage}
          options={vendorOptions}
          onChange={onVendorChange}
          required
        />
        // <div data-testid="vendor-select">
        //   <label
        //     className="usa-label margin-bottom-1 margin-top-0"
        //     htmlFor="select-vendor-name"
        //   >
        //     <strong>Enter the vendor name/UEI</strong>
        //   </label>
        //   <MultiSelectDropdown
        //     name="select-vendors"
        //     data-testid="select-vendors"
        //     selectedValues={formValues.vendors.value}
        //     options={vendorOptions}
        //     noResults={() => 'No vendors found'}
        //     onChange={(value) => onInputChange('vendors', value)}
        //   />
        // </div>
      )}
    </div>
  );

  return (
    <div className="grid-col-6 margin-top-4">
      {optionsLoading && <OverlaySpinner />}
      {reportConfig.solVendorSS && SectionSolVendorSS}

      <div className="margin-top-8">
        <Checkbox
          label="Email the report. Please use this option for long-running reports with large data sets. An email will be sent to you with a link to download the report."
          id="email-report-checkbox"
          data-testid="email-report-checkbox"
          name="email-report-checkbox"
          checked={formValues.isEmailReport.value}
          onChange={onEmailReportChange}
        />
      </div>

      <div className="grid-row margin-top-8">
        <div className="grid-col-auto padding-right-1">
          <Button
            label="Previous"
            variant="outline"
            type="button"
            data-testid="prev-button"
            onClick={onPrev}
            leftIcon={{ name: 'arrow_back' }}
          />
        </div>
        <div className="grid-col-auto">
          <ButtonDropdown
            label="Download Report"
            variant="primary"
            data-testId="download-reports-button"
          >
            {reportConfig.fileTypes.map((fileType) => (
              <ButtonDropdownItem
                key={fileType}
                label={fileType}
                iconName={fileType === FT_PDF ? 'pdf' : 'csv_icon'}
                onClick={() => onClickSubmit(fileType)}
              />
            ))}
          </ButtonDropdown>
        </div>
      </div>
    </div>
  );
};

SolicitationSelectionForm.defaultProps = { canViewAll: false };
SolicitationSelectionForm.propTypes = {
  canViewAll: PropTypes.bool,
  formValues: PropTypes.shape(Object).isRequired,
  setFormValues: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SolicitationSelectionForm;
