/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import {
  AFPTable,
  AFPTableRowAction,
  Button,
  StatusBadge,
  Pagination,
  PageTitle,
  Spinner,
  EmptyState,
  FilterTableFrame,
} from '@gsa/afp-component-library';
import { useHistory } from 'react-router';
import useDidMountEffect from '../../../../hooks/use-did-mount';
import RevealText from '../../components/reveal-text';
import { useFederalStandards } from '../../fvs-provider';
import { useFvsPreBidContext } from '../../fvs-pre-bid-selection-provider';
import FvsMessages from '../../components/fvs-messages';
import PreBidSummaryFilters from './pre-bid-summary-filter-panel';
import { PreBidReviewStatus } from '../../constants';
import {
  MODAL_MODES,
  PREBID_SUMMARY_DESCRIPTION_TEXT,
  PRE_BID_SUMMARY_LABELS,
} from '../constants';
import MakeAndModelModalWrapper from '../make-model-modal';

const PreBidSummary = () => {
  const history = useHistory();
  const params = useParams();
  const { year } = params;

  const [tableData, setTableData] = useState([]);
  const [, setOrder] = useState('contractYear ASC');
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  const {
    selectedYear,
    setFvsContextData,
    fvsMessages,
    removeFvsMessageById,
    clearAllMessages,
  } = useFederalStandards();

  const {
    preBidSelections,
    getPreBidSummaryData,
    setFvsPreBidContextData,
    isPreBidSelectionLoading,
  } = useFvsPreBidContext();

  const defaultFilters = {
    operator: 'AND',
    value: [
      {
        key: 'contractYear',
        operator: 'EQ',
        value: selectedYear || year || new Date().getFullYear().toString(),
      },
    ],
  };

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    return () => {
      // Clear generated messages before unload
      clearAllMessages();
    };
  }, []);

  const statusMap = (value) => {
    let color;
    let text;

    switch (value) {
      case PreBidReviewStatus.NeedReview:
        color = 'Warning-Gray';
        text = 'Need Review';
        return { text, color };

      case PreBidReviewStatus.Done:
        color = 'Ready-Gray';
        text = 'Done';
        return { text, color };

      default:
        color = 'Inactive-Gray';
        text = '';
        return { text, color };
    }
  };

  const navigateToRequirements = (row) => {
    const { fedStandardCode, standardItemNumber } = row.original.standardItem;
    const { contractYear } = row.original;
    const url = `/catalog/vehicle-standards/requirements/${contractYear}/${fedStandardCode}/${standardItemNumber}/`;
    setFvsContextData('SET_SELECTED_YEAR', Number.parseInt(contractYear, 10));
    setFvsContextData('SET_SELECTED_OPTIONS', {
      fedStandardCode,
      standardItemNumber,
      vehicleGroup: '',
      vehicleType: '',
    });
    history.push(url);
  };

  /* eslint-disable-next-line */
  const navigateToMakeAndModel = (row) => {
    // TODO: navigate to make and model page
  };

  /* eslint-disable-next-line */
  const handleSelectedRowClick = (evt, row) => {
    navigateToRequirements(row);
  };

  /* eslint-disable-next-line */
  const handleSelectedAction = (evt, row) => {
    // SET SELECTED ITEM.
    setFvsPreBidContextData('SET_SELECTED_ITEM', row.original);

    if (evt === PRE_BID_SUMMARY_LABELS.VIEW_DETAILS) {
      navigateToRequirements(row);
    } else if (evt === PRE_BID_SUMMARY_LABELS.VIEW_MAKE_MODEL) {
      setFvsPreBidContextData('SET_MODAL_MODE', MODAL_MODES.VIEW_MAKE_MODEL);
      setFvsPreBidContextData('SET_FORM_MODAL', true);
      navigateToMakeAndModel();
    }
    return null;
  };

  const handleAddEditStandardItemButtonClick = () => {
    const url = `/catalog/vehicle-standards/pre-bid-selections/${selectedYear}`;
    history.push(url);
  };

  const actions = [
    {
      icon: 'visibility',
      label: PRE_BID_SUMMARY_LABELS.VIEW_DETAILS,
      canShowIndicator: 'canView',
    },
    {
      icon: 'visibility',
      label: PRE_BID_SUMMARY_LABELS.VIEW_MAKE_MODEL,
      canShowIndicator: 'canView',
    },
  ];

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: PRE_BID_SUMMARY_LABELS.STANDARD_ITEM,
        accessor: 'standardItem.title',
        cellClassName: 'afpTable-cell-wordBreak',
        /* eslint-disable-next-line */
        Cell: ({ row, value }) => {
          return (
            <Button
              variant="unstyled"
              onClick={() =>
                handleSelectedRowClick(PRE_BID_SUMMARY_LABELS.VIEW, row)
              }
              label={`(${row?.original?.standardItem?.standardItemNumber}) - ${value}`}
            />
          );
        },
      },
      {
        Header: PRE_BID_SUMMARY_LABELS.STATUS,
        accessor: 'reviewStatus',
        /* eslint-disable-next-line */
        Cell: ({ value }) => {
          return (
            <StatusBadge variant={statusMap(value).color}>
              {statusMap(value).text}
            </StatusBadge>
          );
        },
      },
      {
        Header: PRE_BID_SUMMARY_LABELS.MAKE_MODEL_COUNT,
        accessor: 'preBidMakeModels.length',
        minWidth: 30,
        width: 150,
        maxWidth: 400,
      },
    ];

    columnList.push({
      Header: PRE_BID_SUMMARY_LABELS.ACTIONS,
      sortable: false,
      minWidth: 30,
      width: 150,
      maxWidth: 400,
      Cell: (props) => (
        <AFPTableRowAction
          {...props}
          actions={actions}
          /* eslint-disable-next-line */
          onSelectAction={(evt) => handleSelectedAction(evt, props.row)}
        />
      ),
    });

    return columnList;
  }, []);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  useDidMountEffect(() => {
    handlePaginationChange(1, paginationState.limit);
    setOrder('contractYear ASC');
    getPreBidSummaryData(paginationState);
  }, [filters]);

  useDidMountEffect(() => {
    getPreBidSummaryData(paginationState);
  }, [paginationState]);

  useEffect(() => {
    if (preBidSelections) {
      setTableData(preBidSelections?.rows);
    }
  }, [preBidSelections]);

  const TableWrapper = ({ tableProps, paginationProps }) => (
    <>
      <AFPTable {...tableProps} />
      {isPreBidSelectionLoading && <Spinner className="margin-y-9" />}
      {!isPreBidSelectionLoading && tableProps.data?.length ? (
        <Pagination {...paginationProps} />
      ) : null}
      {tableProps.data === null ||
        (tableProps.data?.length === 0 && (
          <div className="text-center margin-top-neg-2 height-full">
            <EmptyState
              hasBackground
              containerStyles="padding-top-9 height-full"
              topText={
                // eslint-disable-next-line
                <div
                  aria-label="No data available for the pre-bid summary report."
                  tabIndex="0"
                >
                  <strong>
                    There are no Standard Items found for the selected filter
                    options.
                  </strong>
                </div>
              }
            />
          </div>
        ))}
    </>
  );

  const FTF = useMemo(
    () => FilterTableFrame(null, Button, PreBidSummaryFilters, TableWrapper),
    [],
  );

  return (
    <>
      {fvsMessages && <FvsMessages onClose={removeFvsMessageById} />}
      <div className="desktop:grid-col-10">
        <PageTitle
          aria-label="My Pre-bid Summary"
          tabIndex="0"
          title="My Pre-bid Summary"
        />
      </div>
      <div className="padding-bottom-8">
        <RevealText
          isExpanded
          className="margin-left-1"
          variant="unstyled"
          title="About Pre-Bid Summary"
          copy={<>{ReactHtmlParser(PREBID_SUMMARY_DESCRIPTION_TEXT)}</>}
        />
      </div>
      <FTF
        upRightProps={{
          label: 'Add or edit Standard Item',
          className: 'float-right',
          onClick: () => handleAddEditStandardItemButtonClick(),
          'data-testid': 'pre-bid-add-si-btn',
        }}
        lowLeftProps={{
          currentFilters: filters,
          setFilters,
        }}
        lowRightProps={{
          tableProps: {
            columns,
            data: tableData || [],
            fullWidth: true,
            testId: 'pre-bid-summary-table',
          },
          paginationProps: {
            itemsPerPageOptions: [10, 25, 50],
            onPageChange: handlePaginationChange,
            variant: 'advanced',
            itemsPerPage: paginationState.limit,
            currentPage: paginationState.currentPage,
            itemsCount: preBidSelections?.count,
          },
        }}
        filterToggle
      />
      <MakeAndModelModalWrapper />
    </>
  );
};

export default PreBidSummary;
