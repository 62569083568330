import PropTypes from 'prop-types';
import React from 'react';
import { NotFound } from '@gsa/afp-component-library';
import { getFeatures } from './constants';

const FEATURES = getFeatures();
const FEATURE_LIST = Object.keys(FEATURES);
const isFeature = (feature) => feature && FEATURE_LIST.includes(feature);
const isFeatureON = (feature) => FEATURES[feature];

const EmptyCmp = () => null;

export const PlaceHolderEmptyPage = () => {
  return NotFound;
};

export const isFeatureEnabled = (feature) => {
  return isFeature(feature) && isFeatureON(feature);
};

export const useFeatureToggle = () => {
  const feature = (cmp, feat) => {
    if (isFeature(feat) && isFeatureON(feat)) {
      return cmp;
    }
    return NotFound;
  };
  return { feature };
};

export default function FeatureToggle({ children, feature, returnNull }) {
  if (isFeature(feature) && isFeatureON(feature)) {
    return children;
  }
  return returnNull ? <EmptyCmp /> : <PlaceHolderEmptyPage />;
}

FeatureToggle.defaultProps = {
  children: null,
  feature: '',
  returnNull: false,
};

FeatureToggle.propTypes = {
  children: PropTypes.node,
  feature: PropTypes.string,
  returnNull: PropTypes.bool,
};
