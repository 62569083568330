import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from '@gsa/afp-component-library';

const SolicitationPeriodErrors = ({ errorIdList, errorObj }) => {
  const errorObjList =
    errorIdList?.map?.((eachErrorId) => errorObj?.[eachErrorId]) || [];

  return errorObjList.map(({ message, type } = {}, index) =>
    message || type ? (
      // eslint-disable-next-line react/no-array-index-key
      <ErrorMessage key={index}>{message || type}</ErrorMessage>
    ) : null,
  );
};

SolicitationPeriodErrors.propTypes = {
  errorIdList: PropTypes.arrayOf(PropTypes.string).isRequired,
  errorObj: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }).isRequired,
};

export default SolicitationPeriodErrors;
