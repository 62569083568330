import React, { useEffect } from 'react';
import { PageTitle, Spinner, Alert } from '@gsa/afp-component-library';
import Breadcrumbs from '../../widgets/breadcrumbs';
import BidsSolicitationBlock from '../bids/components/bids-solicitation-block/bids-solicitation-block';
import Tabs from './tabs';
import { useBid } from './bid-provider';
import LineItemSummary from './line-item-summary';

const BidDashboard = () => {
  const {
    solId,
    bidId,
    bid,
    called,
    loading,
    error,
    toaster,
    setToaster,
    isUnseenBidElementsChangesExist,
  } = useBid();

  useEffect(() => {
    return () => setToaster?.(null);
  }, []);

  const reassignedLineItems = (bid?.BidLines || [])
    .filter((bl) => bl.reassignedFromBidLine)
    .map((bl) => ({
      from: bl.reassignedFromBidLine.scheduleLine,
      to: bl.scheduleLine,
    }));

  if (!called || loading) {
    return (
      <Spinner
        data-testid="bid-dashboard-spinner"
        className="margin-top-4"
        size="medium"
      />
    );
  }

  if (error) {
    return (
      <Alert className="margin-top-2" slim focused type="error">
        {error.message}
      </Alert>
    );
  }

  const allBidLineIds = bid?.BidLines?.filter(
    (item) => item.scheduleLine,
  ).map((item) => parseInt(item.id, 10));
  const submittedBidLineIds = bid?.BidLines?.filter(
    (item) => item.scheduleLine && item.status === 'Completed',
  ).map((item) => parseInt(item.id, 10));

  const alertMessage =
    'There has been a change to a standard item with this bid. Please view the Notification tab below for details.';

  return (
    <section>
      {toaster && (
        <Alert
          className="margin-top-2"
          slim
          focused
          type={toaster.type}
          onClose={() => setToaster(null)}
        >
          {toaster.message}
        </Alert>
      )}
      <Breadcrumbs
        path={[
          {
            location: `${window.AFP_CONFIG.appURLs.home}/home`,
            label: 'Home',
          },
          {
            location: `/catalog/bids`,
            label: 'Bids Management',
          },
        ]}
        current={`Bid Dashboard: ${bid?.soliciation?.title}`}
      />
      <PageTitle
        title={`Bid Dashboard: ${bid?.soliciation?.title}`}
        aria-label={`Bid Dashboard: ${bid?.soliciation?.title}`}
        className="margin-bottom-0"
      />
      <p className="margin-bottom-4">Line items and their submission status.</p>
      {isUnseenBidElementsChangesExist && (
        <div className="padding-bottom-2">
          <Alert slim focused type="warning" onClose={() => {}}>
            {alertMessage}
          </Alert>
        </div>
      )}
      {reassignedLineItems.length > 0 && (
        <div className="padding-bottom-2">
          <Alert slim focused type="warning" onClose={() => {}}>
            {reassignedLineItems.map((item) => (
              <div>
                <span className="text-bold">Line item {item.from}</span> was
                reassigned by an Engineer to{' '}
                <span className="text-bold">{item.to}</span>.
              </div>
            ))}
          </Alert>
        </div>
      )}

      <BidsSolicitationBlock
        solId={solId}
        bidId={bidId}
        solicitationNumber={bid?.soliciation?.solicitationNumber}
        samUrl={bid?.soliciation?.samUrl}
        title={bid?.soliciation?.title}
        solicitationPeriod={bid?.solicitationPeriod}
        bidLineIds={allBidLineIds}
        submittedBidLineIds={submittedBidLineIds}
        setAlert={setToaster}
      />

      <LineItemSummary />

      <Tabs />
    </section>
  );
};

export default BidDashboard;
