/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect } from 'react';
import { Label, RadioButton } from '@gsa/afp-component-library';
import { Controller, useFormContext } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import biddingType from '../atoms/solicitation-helpers';

const BidSelection = () => {
  const [, setBidType] = useRecoilState(biddingType);
  const { control, register } = useFormContext();

  useEffect(() => {
    // Set default bid type to "OUTSIDE_FLEET" when component mounts
    setBidType('OUTSIDE_FLEET');
  }, [setBidType]);

  return (
    <>
      <div className="bid-selection-container">
        <div tabIndex={0}>
          <Label required>Bid selection</Label>
        </div>
        <Controller
          control={control}
          name="bid_type"
          render={() => (
            <>
              <RadioButton
                label="Bid in GSA Fleet"
                name="bidType"
                value="IN_FLEET"
                inputRef={register}
                onChange={(v) => {
                  setBidType(v.target.value);
                }}
              />
              <div className="radio-group">
                <RadioButton
                  label="External Bid"
                  name="bidType"
                  value="OUTSIDE_FLEET"
                  inputRef={register}
                  onChange={(v) => {
                    setBidType(v.target.value);
                  }}
                  checked
                />
                <p className="margin-left-4 margin-top-neg-1 text-base">
                  The bid will be conducted outside of this system.
                </p>
              </div>
            </>
          )}
        />
      </div>
    </>
  );
};

export default BidSelection;
