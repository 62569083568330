import React from 'react';
import { useFvsPreBidSelectionsReport } from './pre-bid-report-provider';
import ToastMessage from '../../../components/Toast/toast';

const PreBidReportMessages = () => {
  const { includesOpenPeriods } = useFvsPreBidSelectionsReport();

  return (
    includesOpenPeriods && (
      <ToastMessage
        key="commenting_period_count_message"
        message={
          <span>
            Please note that the results currently reflect an <b>OPEN</b>{' '}
            commenting period and are subject to change until the commenting
            period has been closed.
          </span>
        }
        type="info"
        closeable={false}
      />
    )
  );
};

export default PreBidReportMessages;
