/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonDropdown, ButtonDropdownItem } from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import {
  OPERATIONS,
  SUBJECTS,
  FVS_STATUS,
} from '../../../../utilities/constants';
import { MODAL_MODES } from '../constants';
import { useFvsLandingPageContext } from '../fvs-landing-page-provider';
import { useFvsSupportingDocs } from '../../fvs-supporting-docs-provider';
import { useFederalStandards } from '../../fvs-provider';

const FVSActionsPanel = () => {
  const {
    vehicleStandard,
    commentingPeriodCount,
    selectedYear,
  } = useFederalStandards();
  const ability = useAppAbility();

  const {
    handleCreateSprTask,
    setFvsLandingPageData,
  } = useFvsLandingPageContext();

  const { createAppendix } = useFvsSupportingDocs();
  const history = useHistory();
  const isFVSArchived = () => {
    const commentingPeriods = vehicleStandard?.commentingPeriods;
    if (commentingPeriods?.length) {
      let isArchived = true;
      commentingPeriods.forEach((element) => {
        if (element.isArchived === false) {
          isArchived = false;
        }
      });
      return isArchived;
    }
    return false;
  };
  // is Fed standard code published or Amended
  const isFSCPublishedOrAmended = () => {
    return (
      vehicleStandard?.status === FVS_STATUS?.Published ||
      vehicleStandard?.status === FVS_STATUS?.Amended
    );
  };
  const filteredActionsPanelLinks = (handler) => [
    ability.can(OPERATIONS.Update, SUBJECTS.FVS) && {
      title: 'Request peer review',
      onClickHandler: handler?.spr,
      icon: 'people',
    },
    // ENG
    ability.can(OPERATIONS.Update, SUBJECTS.FVS) &&
      !isFSCPublishedOrAmended() && {
        title: 'Manage commenting period',
        onClickHandler: handler?.scheduleCommentingHandler,
        customIcon: true,
        icon: 'calendar',
      },
    // PSO/Public
    !ability.can(OPERATIONS.Manage, SUBJECTS.FVSComments) && {
      title: 'View comments',
      onClickHandler: handler?.viewComments,
      customIcon: true,
      icon: 'calendar',
    },
    ability.can(OPERATIONS.Manage, SUBJECTS.FVSComments) &&
      !isFVSArchived() && {
        title: 'Manage comments',
        onClickHandler: handler?.manageComments,
        customIcon: true,
        icon: 'comments',
      },
    ability.can(OPERATIONS.Manage, SUBJECTS.FVSComments) &&
      isFVSArchived() && {
        title: 'View comments',
        onClickHandler: handler?.viewComments,
        customIcon: true,
        icon: 'comments',
      },
    ability.can(OPERATIONS.Update, SUBJECTS.FVS) && {
      title: 'Generate new reqs appendix',
      onClickHandler: handler?.appendix,
      customIcon: true,
      icon: 'document_file',
    },
    ability.can(OPERATIONS.Update, SUBJECTS.FVS) && {
      title: 'Add supporting document',
      onClickHandler: handler?.supportingDoc,
      customIcon: true,
      icon: 'plus',
    },
    ability.can(OPERATIONS.Update, SUBJECTS.FVS) && {
      title: 'Publish',
      onClickHandler: handler?.publish,
      customIcon: true,
      icon: 'publish',
    },
    // ENG
    (ability.can(OPERATIONS.Update, SUBJECTS.FVS) ||
      ability.can(OPERATIONS.Manage, SUBJECTS.Solicitation)) && {
      title: 'Vendor Pre-Bid Selections',
      onClickHandler: handler?.preBidReport,
      customIcon: true,
      icon: 'chart',
    },
    // PSO only & if commentingPeriod is open
    ability.can(OPERATIONS.Create, SUBJECTS.PSOQuestionnaire) &&
      commentingPeriodCount && {
        title: 'View my pre-bid summary',
        onClickHandler: handler?.preBid,
        customIcon: true,
        icon: 'chart',
      },
  ];

  const unFilteredActionsPanelLinks = (handler) => [
    // ENG/PSO/Public
    {
      title: 'View comments',
      onClickHandler: handler?.viewComments,
      customIcon: true,
      icon: 'calendar',
    },
  ];

  const actionHandlers = {
    spr: handleCreateSprTask,
    appendix: () => {
      createAppendix({
        variables: {
          fedVehicleStandardsId: Number.parseInt(vehicleStandard.id, 10),
        },
      });
    },
    publish: () => {
      setFvsLandingPageData('SET_MODAL_MODE', MODAL_MODES.PUBLISH);
    },
    manageComments: () => {
      history.push('/catalog/comments-admin');
    },
    supportingDoc: () => {
      setFvsLandingPageData('SET_MODAL_MODE', MODAL_MODES.UPLOAD);
    },
    scheduleCommentingHandler: () => {
      setFvsLandingPageData('SET_MODAL_MODE', MODAL_MODES.SCHEDULE_COMMENTING);
    },
    preBid: () => {
      // test
    },
    viewComments: () => {
      // TODO after building view comments page
      history.push('/catalog/comments', vehicleStandard);
    },
    preBidReport: () => {
      history.push(`vehicle-standards/pre-bid-report/${selectedYear}`);
    },
  };

  const filteredActionsLinks = filteredActionsPanelLinks(actionHandlers);
  const unFilteredActionsLinks = unFilteredActionsPanelLinks(actionHandlers);

  return (
    <>
      <ButtonDropdown
        className="margin-right-0"
        label="Actions"
        variant="primary"
        iconSize={3}
        side="right"
      >
        {(vehicleStandard?.id
          ? filteredActionsLinks
          : unFilteredActionsLinks
        ).map((link, i) => (
          <>
            {link ? (
              <ButtonDropdownItem
                key={`dropdownItem${i}`}
                className="text-primary hover:bg-blue-5"
                label={link.title}
                iconName={link.icon}
                iconType={link.customIcon && 'custom'}
                iconFill="#005ea2"
                onClick={() => link.onClickHandler()}
              />
            ) : null}
          </>
        ))}
      </ButtonDropdown>
    </>
  );
};

export default FVSActionsPanel;
