import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_CONTRACT_HEADER_VERSION_WITH_HISTORY = gql`
  query GetContractHeaderVersionWithHistory(
    $versionNumber: Float!
    $contractHeaderId: String!
  ) {
    getContractHeaderVersionWithHistory(
      versionNumber: $versionNumber
      contractHeaderId: $contractHeaderId
    ) {
      contractHeaderId
      contractHeaderHistory {
        id
        contractHeaderVersionId
        historyCreatedBy
        historyCreatedAt
        contractNumber
        solicitationNumber
        contractStartDate
        contractEndDate
        dollarValueMaximumOrder
        contractSpecialistName
        contractSpecialistEmail
        formalContractNumber
        contractingOfficerName
        contractingOfficerEmail
        solicitationId
        solicitationPeriodId
        contractUpiid
        vendorId
        contractYear
        fleetAwarded
        awardedDate
        awardCancelledDate
        pocName
        pocEmail
        pocPhone
        parentContractNumber
        isActive
        status
        publishedInFleet
        updatedBy
        updatedAt
        vendor {
          id
          vendorName
          id
          uniqueEntityIdentifier
          fleetVendorNo
          fleetVendorNumber
          fleetStatus
          registrationExpirationDate
        }
        solicitation {
          purchaseTypeCode
          solicitationNumber
          solicitationNumberLegacy
          solicitationPeriods {
            id
            startDate
            endDate
            periodType
          }
          programs {
            id
            program
            solicitationLines {
              id
              standardItem {
                id
                standardItemNumber
                status
                title
                tags
              }
            }
          }
        }
      }
    }
  }
`;
