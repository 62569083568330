import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  gql,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getToken } from '@gsa/afp-shared-ui-utils';
import { CUSTOM_ERROR } from '../utilities/constants';

export const GetGlobalMessagesCacheQuery = gql`
  query GetGlobalMessages {
    messages {
      id
      message
      type
      closeable
    }
  }
`;

const httpLink = createHttpLink({
  uri: window.AFP_CONFIG.api_url,
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const { cache } = operation.getContext();
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions }) => {
      if (
        // cache error, only when exception name undefined (un-handled) and not a 'custom-error'
        !extensions?.exception?.name &&
        !extensions?.exception?.jse_shortmsg &&
        extensions?.exception?.name !== CUSTOM_ERROR &&
        (extensions?.code === 'INTERNAL_SERVER_ERROR' ||
          extensions?.code === 'GRAPHQL_VALIDATION_FAILED')
      ) {
        const m =
          !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
            ? `Internal server error: ${message}`
            : 'There was a system error. Please try again later. If you continue to experience technical difficulties with this page, please contact the GSA Fleet Technical support team at 866-472-1711 or gsafleet@gsa.gov.';
        cache.writeQuery({
          query: GetGlobalMessagesCacheQuery,
          data: {
            messages: [
              {
                id: 'GLOBAL_ERROR',
                message: m,
                type: 'error',
                closeable: true,
              },
            ],
          },
        });
      }
    });
    // TODO: network error scenarios
  } else if (networkError) {
    const m =
      !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
        ? `Network failure: ${networkError}`
        : 'An error occurred. Please refresh this page. Contact administrator if error persists.';
    cache.writeQuery({
      query: GetGlobalMessagesCacheQuery,
      data: {
        messages: [
          {
            id: 'GLOBAL_ERROR',
            message: m,
            type: 'error',
            closeable: false,
          },
        ],
      },
    });
  }
});

// custom link handler to reset global error messages before next API call
const initLink = new ApolloLink((operation, forward) => {
  const { cache } = operation.getContext();
  cache.writeQuery({
    query: GetGlobalMessagesCacheQuery,
    data: {
      messages: [
        {
          id: 'GLOBAL_ERROR',
          message: '',
          type: 'error',
          closeable: true,
        },
      ],
    },
  });
  return forward(operation);
});

const dataStore = new ApolloClient({
  link: authLink.concat(initLink).concat(errorLink).concat(httpLink),
  cache: new InMemoryCache(), // removing __typename
});

const sharedPublicLink = createHttpLink({
  uri: window.AFP_CONFIG.public_api_url,
});

export const sharedGatewayClient = new ApolloClient({
  link: initLink.concat(errorLink).concat(sharedPublicLink),
  cache: new InMemoryCache(),
});

export const CURRENT_USER = gql`
  query Me {
    me {
      id
      email
      firstName
      lastName
    }
  }
`;

export const GET_STANDARD_ITEM = gql`
  query GetStandardItem($filter: FilterType!) {
    getStandardItem(filter: $filter) {
      id
      title
      standardItemNumber
      status
      year
      tags
      contentId
      fast
      fastCode {
        code
        title
      }
      fedStandard {
        code
        title
      }
      content {
        id
        content
      }
      vehicleTypeCode {
        code
        title
      }
      canEdit
      canView
      canDelete
    }
  }
`;

export const STANDARD_ITEMS = gql`
  query GetStandardItems(
    $filters: FilterType
    $virtualFilters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getStandardItems(
      filters: $filters
      virtualFilters: $virtualFilters
      order: $order
      limit: $limit
      offset: $offset
    ) {
      rows {
        id
        title
        standardItemNumber
        status
        year
        tags
        fast
        fastCode {
          code
          title
        }
        contentId
        fedStandard {
          code
          title
        }
        content {
          id
          content
        }
        vehicleTypeCode {
          code
          title
        }
        canEdit
        canView
        canDelete
      }
      hasMore
      count
    }
  }
`;

export const STANDARD_ITEMS_WITH_CHANGES = gql`
  query GetStandardItems(
    $filters: FilterType
    $virtualFilters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getStandardItems(
      filters: $filters
      virtualFilters: $virtualFilters
      order: $order
      limit: $limit
      offset: $offset
    ) {
      rows {
        id
        title
        standardItemNumber
        status
        year
        tags
        contentId
        fast
        fastCode {
          code
          title
        }
        fedStandard {
          code
          title
        }
        content {
          id
          content
        }
        vehicleTypeCode {
          code
          title
        }
        canEdit
        canView
        canDelete
        change {
          isInserted
          isDeleted
          isModified
          isDescriptionModified
          differences
          prevVersionDesc
        }
      }
      hasMore
      count
    }
  }
`;

export const STANDARDS_CODES = gql`
  query GetCommonCodes(
    $filters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getStandardsCodes(
      filters: $filters
      order: $order
      limit: $limit
      offset: $offset
    ) {
      rows {
        category
        code
        title
        parentCategory
        parentCode
        tags
      }
    }
  }
`;

export const STANDARD_ITEM_FILTER_OPTIONS = gql`
  query GetStandardItemFilterOptions($filters: [Filter!]) {
    getStandardItemFilterOptions(filters: $filters)
  }
`;

export const STANDARD_ITEM_TYPEAHEAD_OPTIONS_SEARCH = gql`
  query GetStandardItemTypeAheadOptions(
    $key: String!
    $search: String
    $filters: [Filter!]
  ) {
    getStandardItemTypeAheadOptions(
      key: $key
      search: $search
      filters: $filters
    )
  }
`;

export const EQUIPMENT_CODES = gql`
  query GetEquipmentCodes(
    $filters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getEquipmentCodes(
      filters: $filters
      order: $order
      limit: $limit
      offset: $offset
    ) {
      rows {
        id
        program
        code
        title
        status
        quantityRequired
        quantityRequiredCode {
          code
          title
        }
        category
        categoryCode {
          code
          title
        }
        unit
        unitCode {
          code
          title
        }
        tags
        sequence
        contentId
        content {
          id
          content
        }
        year
        canEdit
        canView
        canDelete
      }
      hasMore
      count
    }
  }
`;

export const EQUIPMENT_CODES_FILTER_OPTIONS = gql`
  query GetEquipmentCodesFilterOptions($filters: [Filter!]) {
    getEquipmentCodesFilterOptions(filters: $filters)
  }
`;

export const EQUIPMENT_CODES_TYPEAHEAD_OPTIONS_SEARCH = gql`
  query GetEquipmentCodesTypeAheadOptions(
    $key: String!
    $search: String
    $filters: [Filter!]
  ) {
    getEquipmentCodesTypeAheadOptions(
      key: $key
      search: $search
      filters: $filters
    )
  }
`;

export const UPDATE_STANDARD_ITEM = gql`
  mutation UpdateStandardItem($id: String!, $standarditem: StandardItemInput!) {
    updateStandardItem(id: $id, standarditem: $standarditem) {
      id
      standardItemNumber
      title
    }
  }
`;

export const CREATE_STANDARD_ITEM = gql`
  mutation CreateStandardItem($standarditem: StandardItemInput!) {
    addStandardItem(standarditem: $standarditem) {
      id
      standardItemNumber
      title
      year
    }
  }
`;

export const DELETE_STANDARD_ITEM = gql`
  mutation deleteStandardItem($id: String!) {
    deleteStandardItem(id: $id)
  }
`;

export const DELETE_EQUIPMENT_CODE = gql`
  mutation deleteEquipmentCode($id: Float!) {
    deleteEquipmentCode(id: $id)
  }
`;

export const UPDATE_EQUIPMENT_CODE = gql`
  mutation UpdatedEquipmentCode(
    $id: Float!
    $equipmentcode: EquipmentCodeInput!
  ) {
    updateEquipmentCode(id: $id, equipmentcode: $equipmentcode) {
      id
      title
      code
    }
  }
`;

export const OVERWRITE_EQUIPMENT_CODE = gql`
  mutation OverwriteEquipmentCode(
    $id: Float!
    $equipmentcode: EquipmentCodeInput!
  ) {
    overwriteEquipmentCode(id: $id, equipmentcode: $equipmentcode) {
      id
      title
      code
    }
  }
`;

export const GET_EQUIPMENT_ASSOCIATIONS = gql`
  query GetEquipmentAssociations(
    $filters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getEquipmentAssociations(
      filters: $filters
      order: $order
      offset: $offset
      limit: $limit
    ) {
      rows {
        id
        standardItemId
        standardItem {
          id
          title
          year
          standardItemNumber
          vehicleType
        }
        equipmentCodeId
        equipmentCode {
          id
          title
          code
          program
          category
        }
        associationTypeCode
        associationType {
          id
          code
          title
        }
        associationText
        inputTypeCode
        inputType {
          id
          code
          title
        }
        lowerBound
        upperBound
        criteriaCode
        criteria {
          id
          code
          title
        }
        unitCode
        unit {
          id
          code
          title
        }
        preDefinedValue
        createdByUser
        createdAt
        updatedByUser
        updatedAt
        deletedAt
        canEdit
        canDelete
      }
      count
      hasMore
    }
  }
`;

export const GET_EQUIPMENT_ASSOCIATIONS_WITH_CHANGES = gql`
  query GetEquipmentAssociations(
    $filters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getEquipmentAssociations(
      filters: $filters
      order: $order
      offset: $offset
      limit: $limit
    ) {
      rows {
        id
        standardItemId
        standardItem {
          id
          title
          year
          standardItemNumber
          vehicleType
        }
        equipmentCodeId
        equipmentCode {
          id
          title
          code
          program
          category
          content {
            content
          }
          change {
            isInserted
            isDeleted
            isModified
            isDescriptionModified
            differences
            prevVersionDesc
          }
        }
        associationTypeCode
        associationType {
          id
          code
          title
        }
        associationText
        inputTypeCode
        inputType {
          id
          code
          title
        }
        lowerBound
        upperBound
        criteriaCode
        criteria {
          id
          code
          title
        }
        unitCode
        unit {
          id
          code
          title
        }
        preDefinedValue
        createdByUser
        createdAt
        updatedByUser
        updatedAt
        deletedAt
        canEdit
        canDelete
        change {
          isInserted
          isDeleted
          isModified
          isDescriptionModified
          differences
        }
      }
      count
      hasMore
    }
  }
`;

export const GET_PUBLIC_EQUIPMENT_ASSOCIATIONS_WITH_CHANGES = gql`
  query GetEquipmentAssociations(
    $filters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getEquipmentAssociations(
      filters: $filters
      order: $order
      offset: $offset
      limit: $limit
    ) {
      rows {
        id
        standardItemId
        standardItem {
          id
          title
          year
          standardItemNumber
          vehicleType
        }
        equipmentCodeId
        equipmentCode {
          id
          title
          code
          program
          category
          content {
            content
          }
          change {
            isInserted
            isDeleted
            isModified
            isDescriptionModified
            differences
            prevVersionDesc
          }
        }
        associationTypeCode
        associationType {
          id
          code
          title
        }
        associationText
        inputTypeCode
        inputType {
          id
          code
          title
        }
        lowerBound
        upperBound
        criteriaCode
        criteria {
          id
          code
          title
        }
        unitCode
        unit {
          id
          code
          title
        }
        preDefinedValue
        createdByUser
        createdAt
        updatedByUser
        updatedAt
        deletedAt
        change {
          isInserted
          isDeleted
          isModified
          isDescriptionModified
          differences
        }
      }
      count
      hasMore
    }
  }
`;

export const GET_EQUIPMENT_ASSOCIATIONS_WITH_COMMENTS = gql`
  query GetEquipmentAssociations(
    $filters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getEquipmentAssociations(
      filters: $filters
      order: $order
      offset: $offset
      limit: $limit
    ) {
      rows {
        id
        standardItemId
        standardItem {
          id
          title
          year
          standardItemNumber
          vehicleType
        }
        equipmentCodeId
        equipmentCode {
          id
          title
          code
          program
          category
          content {
            content
          }
        }
        associationTypeCode
        associationType {
          id
          code
          title
        }
        associationText
        inputTypeCode
        inputType {
          id
          code
          title
        }
        lowerBound
        upperBound
        criteriaCode
        criteria {
          id
          code
          title
        }
        unitCode
        unit {
          id
          code
          title
        }
        preDefinedValue
        createdByUser
        createdAt
        updatedByUser
        updatedAt
        deletedAt
        canEdit
        canDelete
        commentsCount
        comments {
          id
          comment
          repliesCount
          status
          authorType
          createdByUser
          createdByUserInfo {
            fullName
          }
          createdAt
          publishStatus
          replies {
            id
            comment
            status
            authorType
            createdByUser
            createdAt
            publishStatus
            createdByUserInfo {
              fullName
            }
          }
        }
      }
      count
      hasMore
    }
  }
`;

export const ADD_EQUIPMENT_ASSOCIATION = gql`
  mutation AddEquipmentAssociation(
    $equipmentAssociation: EquipmentAssociationInput!
  ) {
    addEquipmentAssociation(equipmentAssociation: $equipmentAssociation) {
      id
    }
  }
`;

export const UPDATE_EQUIPMENT_ASSOCIATION = gql`
  mutation UpdateEquipmentAssociation(
    $id: Float!
    $equipmentAssociation: EquipmentAssociationInput!
  ) {
    updateEquipmentAssociation(
      id: $id
      equipmentAssociation: $equipmentAssociation
    ) {
      id
    }
  }
`;

export const DELETE_EQUIPMENT_ASSOCIATION = gql`
  mutation DeleteEquipmentAssociation(
    $equipmentAssociationDelete: EquipmentAssociationDelete!
  ) {
    deleteEquipmentAssociation(
      equipmentAssociationDelete: $equipmentAssociationDelete
    )
  }
`;

export const COPY_EQUIPMENT_ASSOCIATIONS = gql`
  mutation CopyAssociations($copyInput: CopyAssociationsInput!) {
    copyAssociations(copyInput: $copyInput)
  }
`;

export const GET_OPTIONS = gql`
  query GetOptions(
    $model: String!
    $label: String!
    $value: String!
    $filter: FilterType
    $limit: Float
  ) {
    getOptions(
      model: $model
      label: $label
      value: $value
      filter: $filter
      limit: $limit
    ) {
      id
      label
      value
      type
    }
  }
`;

export const GET_MULTIPLE_OPTIONS = gql`
  query GetMultipleOptions($options: [OptionType!]!) {
    getMultipleOptions(options: $options) {
      id
      label
      value
      type
      uniqueKey
      currentYear
      parent
    }
  }
`;

export const GET_EQUIPMENT_CODES_TO_ADD = gql`
  query GetEquipmentCodesToAdd($filter: FilterType!, $standardItemId: Float!) {
    getEquipmentCodesToAdd(filter: $filter, standardItemId: $standardItemId) {
      id
      title
      code
    }
  }
`;

export const GET_ASSOCIATED_STANDARD = gql`
  query GetEquipmentAssociations($filters: FilterType!) {
    getEquipmentAssociations(filters: $filters) {
      rows {
        standardItem {
          standardItemNumber
        }
      }
      count
      hasMore
    }
  }
`;

export const GET_EQ_STANDARD_ITEMS = gql`
  query GetStandardItems($filters: FilterType!, $order: OrderBy) {
    getStandardItems(filters: $filters, order: $order) {
      rows {
        id
        standardItemNumber
        title
        vehicleTypeCode {
          title
        }
        status
        year
      }
      count
      hasMore
    }
  }
`;

export const GET_STANDARD_ITEMS_BY_YEARS = gql`
  query GetStandardItems($filters: FilterType!) {
    getStandardItems(filters: $filters, limit: 9999) {
      rows {
        id
        title
        standardItemNumber
        status
        year
      }
      count
    }
  }
`;

export const ADD_EQUIPMENT_CODE = gql`
  mutation AddEquipmentCode($equipmentcode: EquipmentCodeInput!) {
    addEquipmentCode(equipmentcode: $equipmentcode) {
      id
      title
      code
      year
      program
    }
  }
`;

export const GET_EQUIPMENT_CODE_TO_COPY_FROM = gql`
  query GetEquipmentCodesToCopyFrom($keyword: String!, $filters: FilterType!) {
    getEquipmentCodesToCopyFrom(keyword: $keyword, filters: $filters) {
      id
      title
      code
    }
  }
`;

export const GET_ACTIVE_USERS_BY_ROLES = gql`
  query getActiveUsersByRoles($order: OrderBy, $roleIds: [Int!]!) {
    getActiveUsersByRoles(order: $order, roleId: $roleIds) {
      id
      fullName
      email
    }
  }
`;
// Comments
export const ADD_COMMENT = gql`
  mutation AddCommentForStandardItem($comment: StandardsCommentInput!) {
    addStandardsComment(commentInput: $comment) {
      id
      comment
      threadId
      authorType
      replies {
        comment
      }
      repliesCount
      status
      year
      linkedEntityType
      linkedEntityId
      fvsCode
    }
  }
`;

export const BULK_REVIEW_REPLY_COMMENT = gql`
  mutation BulkReviewReplyComments($bulkInput: BulkReviewReplyInput!) {
    bulkReviewReplyComments(bulkInput: $bulkInput) {
      status
      invalid {
        id
        createdAt
        createdByUserInfo {
          fullName
        }
        authorType
      }
      published {
        id
        createdAt
        createdByUserInfo {
          fullName
        }
        authorType
      }
      new {
        id
        createdAt
        createdByUserInfo {
          fullName
        }
        authorType
      }
    }
  }
`;

export const MANAGE_COMMENT = gql`
  mutation ManageComment($manageInput: ManageCommentInput!) {
    manageComment(manageInput: $manageInput) {
      id
      createdByUserInfo {
        fullName
      }
      authorType
    }
  }
`;

export const BULK_PUBLISH_COMMENTS = gql`
  mutation BulkPublishComments($bulkInput: BulkPublishInput!) {
    bulkPublishComments(bulkInput: $bulkInput) {
      status
      invalid {
        id
        createdAt
        createdByUserInfo {
          fullName
        }
        authorType
      }
      published {
        id
        createdAt
        createdByUserInfo {
          fullName
        }
        authorType
      }
      new {
        id
        createdAt
        createdByUserInfo {
          fullName
        }
        authorType
      }
    }
  }
`;

export const GET_COMMENTS = gql`
  query GetComments(
    $filters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getStandardsComments(
      filters: $filters
      order: $order
      offset: $offset
      limit: $limit
    ) {
      rows {
        id
        threadId
        comment
        authorType
        publishStatus
        status
        year
        createdAt
        createdByUser
        linkedEntityType
        linkedEntityId
        fvsCode
        createdByUserInfo {
          fullName
          email
        }
        replies {
          id
          comment
          status
          authorType
          createdByUser
          createdAt
          publishStatus
          createdByUserInfo {
            fullName
          }
        }
        repliesCount
        linkedEntity {
          __typename
          ... on StandardItem {
            standardItemNumber
            year
          }
          ... on EquipmentAssociation {
            standardItemId
            equipmentCodeId
            associationTypeCode
            associationText
            standardItem {
              standardItemNumber
            }
            equipmentCode {
              code
            }
          }
        }
      }
      count
      hasMore
    }
  }
`;

export const UPDATE_COMMENT = gql`
  mutation UpdateComment($id: Float!, $commentInput: StandardsCommentInput!) {
    updateComment(id: $id, commentInput: $commentInput) {
      id
    }
  }
`;

export const GET_OPEN_AND_RECENT_COMMENTING_PERIODS = gql`
  query GetAllCommentingPeriods(
    $includeStatus: Boolean!
    $excludeArchived: Boolean!
  ) {
    getOpenAndRecentCommentingPeriods(
      includeStatus: $includeStatus
      excludeArchived: $excludeArchived
    ) {
      id
      fvsId
      fvsCode
      fvsTitle
      periodStart
      periodEnd
      userType
      status
      daysLeft
      statusDetail
    }
  }
`;

export const ARCHIVE_COMMENTING_PERIOD = gql`
  mutation ArchiveCommentingPeriod($commentingPeriodId: Float!) {
    archiveCommentingPeriod(commentingPeriodId: $commentingPeriodId) {
      id
      periodStart
      periodEnd
      userType
    }
  }
`;

export const GET_ANNOUNCEMENT_BY_BOARD = gql`
  query GetAnnouncementByBoard($boardType: Float!) {
    getAnnouncementByBoard(boardType: $boardType) {
      id
      boardType
      content
    }
  }
`;

export const UPDATE_ANNOUNCEMENT_BY_BOARD = gql`
  mutation UpdateAnnouncementByBoard($boardType: Float!, $content: String!) {
    updateAnnouncementByBoard(boardType: $boardType, content: $content) {
      boardType
      content
    }
  }
`;

export const DELETE_STANDARD_COMMENT = gql`
  mutation deleteStandardsComment($id: Float!) {
    deleteStandardsComment(id: $id) {
      createdAt
    }
  }
`;

export const GET_VENDORS_BY_PERMISSION = gql`
  query getVendorsByPermission(
    $operation: String!
    $subject: String!
    $filters: [Filter!]
  ) {
    getVendorsByPermission(
      operation: $operation
      subject: $subject
      filters: $filters
    ) {
      id
      fleetVendorNumber
      uniqueEntityIdentifier
      vendorName
      vendorContacts {
        contactType
        city
        state {
          stateCode
        }
        postalCode
      }
      vendorTypes {
        vendorTypeCode
        commonCode {
          name
        }
      }
      fleetStatus
    }
  }
`;

export const GET_VENDOR_TYPE_AHEAD_OPTIONS = gql`
  query getVendorTypeAheadOptions($key: String!, $search: String!) {
    getVendorTypeAheadOptions(key: $key, search: $search)
  }
`;

export default dataStore;
