/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  StatusBadge,
  Tag,
  Button,
  RadioButton,
  TextInput,
} from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { useCommentsAdmin } from './comments-admin-provider';
import { publishStatusColor, tagColors, STATUS_MAP } from './helpers';
import { getAuthor } from '../../utilities/types/role-types';
import CommentReplyList from './comment-replies-list';
import { emDashUnicode } from '../../utilities/constants';

const ManageComment = ({ formId }) => {
  const {
    selectedComment,
    setData,
    manageComment,
    commentFormState,
  } = useCommentsAdmin();
  const {
    currentUser: { roles },
  } = useCurrentUser();
  const [showReplies, setShowReplies] = useState(false);
  const [reply, setReply] = useState('');
  const [, setStatus] = useState(null);
  const handleClick = () => {
    const current = showReplies;
    setShowReplies(!current);
  };

  const handleFormSubmit = (isPublish) => {
    const manageInput = {
      id: Number(selectedComment.id),
      status: STATUS_MAP[commentFormState] || null,
      authorType: getAuthor(roles),
      comment: reply,
      isPublish,
    };

    manageComment({
      variables: {
        manageInput,
      },
    });
    setData('SET_MODAL_MODE', null);
    setData('SET_COMMENT_FORM_STATE', null);
  };

  const statusOptions = [
    { id: 2, label: 'Approved', value: 'Approved' },
    { id: 3, label: 'Disapproved', value: 'Disapproved' },
    { id: 1, label: 'Noted', value: 'Noted' },
    { id: 4, label: 'Invalid', value: 'Invalid' },
  ];

  const repliesButtonLabel = !showReplies ? 'Show replies' : 'Hide replies';

  return (
    <>
      <div className="grid-col">
        <div className="grid-row">
          <Tag variant={tagColors[selectedComment.status]}>
            {selectedComment.status}
          </Tag>
          <StatusBadge
            variant={publishStatusColor[selectedComment.publishStatus]}
          >
            {selectedComment.publishStatus}
          </StatusBadge>
        </div>
        <br />
        <span
          className="text-bold"
          // eslint-disable-next-line
          tabIndex="0"
          aria-label={selectedComment.createdByUserInfo?.fullName}
        >
          {/*  Later need to change to this format. {props.userFirstName} {props.userLastName}, {props.userType} */}
          {selectedComment.createdByUserInfo?.fullName || emDashUnicode},{' '}
          {selectedComment.authorType} {'• '}
          <a
            className="usa-link text-normal"
            href={`mailto:${selectedComment.createdByUserInfo?.email}`}
          >
            {selectedComment.createdByUserInfo?.email}
          </a>
        </span>
        {/* eslint-disable-next-line */}
        <p aria-label={selectedComment.comment} tabIndex="0">
          {selectedComment.comment}
        </p>
        {selectedComment.replies.length && showReplies ? (
          <CommentReplyList replies={selectedComment.replies} />
        ) : null}
        {selectedComment.replies.length ? (
          <div className="more-btn">
            <Button
              variant="unstyled"
              onClick={() => handleClick()}
              label={repliesButtonLabel}
              rightIcon={{
                name: !showReplies ? 'expand_more' : 'expand_less',
                className: 'usa-icon--size-3',
              }}
            />
          </div>
        ) : (
          ''
        )}
        <br />
        {/* eslint-disable-next-line */}
        <span className="text-bold" aria-label="Review state" tabIndex="0">
          Review state
        </span>
        <form
          id={formId}
          onSubmit={(e) => {
            e.preventDefault();
            const butonName = e.nativeEvent?.submitter?.name;
            const isPublish = butonName === 'save_publish';
            handleFormSubmit(isPublish);
          }}
        >
          <fieldset legend="Review state" className="usa-fieldset">
            {statusOptions.map((st) => {
              return (
                <RadioButton
                  name="commentStatus"
                  id={st.id}
                  aria-label={st.value}
                  key={st.id}
                  value={st.value}
                  label={st.label}
                  checked={commentFormState === st.value}
                  onClick={() => {
                    setStatus(st.value);
                    setData('SET_COMMENT_FORM_STATE', st.value);
                  }}
                />
              );
            })}
          </fieldset>
          <br />
          {/* eslint-disable-next-line */}
          <span aria-label="Reply" tabIndex="0" className="text-bold">
            Reply
          </span>
          <TextInput
            id="comment-text"
            type="textarea"
            value={reply}
            maxLength="2000"
            onChange={(e) => setReply(e.target.value)}
            aria-label="Reply 2,000 characters allowed"
          />
          {/* eslint-disable-next-line */}
          <span aria-label="2,000 characters allowed" tabIndex={0}>
            2,000 characters allowed
          </span>
        </form>
      </div>
    </>
  );
};

export default ManageComment;
