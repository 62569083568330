/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  AFPTable,
  EmptyState,
  Pagination,
  Checkbox,
  SelectDropdown,
  Icon,
  StatusBadge,
  AFPTableRowAction,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import {
  OPERATIONS,
  SUBJECTS,
  enDashUnicode,
} from '../../../utilities/constants';
import { useBidLineEvaluation } from './bid-line-evaluation-provider';
import {
  YES,
  NO,
  EVAL_AWARD_OPTIONS,
  getItemByBidLineId,
  EVAL_STATUS,
} from './bid-line-evaluation-helpers';

const CheckboxField = ({ id, value, canEdit, onChange }) => {
  if (canEdit)
    return (
      <Checkbox
        key={id}
        id={id}
        data-testid={id}
        checked={value}
        onChange={({ target: { checked } }) => {
          onChange(checked);
        }}
      />
    );
  if (value)
    return (
      <Icon
        iconName="check_circle"
        aria-label="Completed"
        size={3}
        style={{ color: '#008817' }}
      />
    );
  return enDashUnicode;
};

const SelectField = ({ id, value, canEdit, onChange }) => {
  if (canEdit)
    return (
      <SelectDropdown
        key={id}
        id={id}
        data-testid={id}
        value={value}
        options={EVAL_AWARD_OPTIONS}
        onChange={({ target: { value: val } }) => {
          onChange(val);
        }}
      />
    );
  if (value === YES) return 'Yes';
  if (value === NO) return 'No';
  return enDashUnicode;
};

const ActionCell = ({ row, canReassign, canAward }) => {
  const {
    lineData,
    evaluable,
    saveEvaluation,
    setReassignRow,
    setAlert,
  } = useBidLineEvaluation();

  const actReassign = {
    icon: 'edit',
    label: 'Reassign SIN',
  };
  const actInReview = {
    icon: 'edit',
    label: "Change status to 'In review'",
  };
  const actCancelAward = {
    icon: 'edit',
    label: 'Cancel award',
  };

  const line = getItemByBidLineId(lineData, row.original.bidLineId);
  const actionList = [];
  if (canReassign && line.status !== EVAL_STATUS.Awarded)
    actionList.push(actReassign);
  if (evaluable && line.status === EVAL_STATUS.Ready)
    actionList.push(actInReview);
  if (canAward && line.status === EVAL_STATUS.Awarded)
    actionList.push(actCancelAward);

  if (actionList.length === 0)
    return <span data-testid="no-actions">{enDashUnicode}</span>;
  return (
    <AFPTableRowAction
      row={row}
      actions={actionList}
      onSelectAction={(action) => {
        setAlert(null);
        if (action === actInReview.label) saveEvaluation(line, {});
        else if (action === actCancelAward.label)
          saveEvaluation(line, { awarded: false });
        else setReassignRow(row.original);
      }}
    />
  );
};

const BidLineEvaluationTable = () => {
  const {
    tableData,
    rowData,
    evaluable,
    count,
    offset,
    limit,
    pageReset,
    onPageChange,
  } = useBidLineEvaluation();

  const ability = useAppAbility();
  const canTechEval =
    evaluable && ability.can(OPERATIONS.EngineerReview, SUBJECTS.Bid);
  const canReassign = canTechEval;
  const canPriceEval =
    evaluable && ability.can(OPERATIONS.Approve, SUBJECTS.Bid);
  const canAward = canPriceEval;

  const awardColumn = {
    id: 'awardSelection',
    Header: () => {
      const {
        lineData,
        tableData: rows,
        awardSelection,
        setAwardSelection,
      } = useBidLineEvaluation();
      let hasRecommend = false;
      const isChecked = rows.every(({ bidLineId }) => {
        const row = getItemByBidLineId(lineData, bidLineId);
        const isRecommend = row.status === EVAL_STATUS.Recommend;
        hasRecommend = hasRecommend || isRecommend;
        return !isRecommend || awardSelection.includes(row.evalId);
      });
      return (
        <Checkbox
          id="award-select-all"
          data-testid="award-select-all"
          className="award-check-all"
          checked={isChecked && hasRecommend}
          onChange={({ target: { checked } }) => {
            if (!checked) setAwardSelection([]);
            else {
              const newAwards = [];
              rows.forEach(({ bidLineId }) => {
                const row = getItemByBidLineId(lineData, bidLineId);
                if (row.status === EVAL_STATUS.Recommend)
                  newAwards.push(row.evalId);
              });
              setAwardSelection(newAwards);
            }
          }}
        />
      );
    },
    cellClassName: 'cell-selection',
    sortable: false,
    accessor: 'bidLineId',
    Cell: ({ value }) => {
      const {
        lineData,
        awardSelection,
        setAwardSelection,
      } = useBidLineEvaluation();
      const line = getItemByBidLineId(lineData, value);
      if (line.status !== EVAL_STATUS.Recommend) return null;
      return (
        <Checkbox
          id={`${value}-award-select`}
          data-testid={`${value}-award-select`}
          checked={awardSelection.includes(line.evalId)}
          onChange={({ target: { checked } }) => {
            if (checked) setAwardSelection([...awardSelection, line.evalId]);
            else
              setAwardSelection(
                awardSelection.filter((i) => i !== line.evalId),
              );
          }}
        />
      );
    },
  };

  const columns = [
    {
      Header: 'Line item',
      accessor: 'scheduleLine',
      sortable: false,
      Cell: ({ row: { original } }) => {
        return (
          <Link
            to={`/catalog/solicitations/${original.solId}/bid-dashboard/${original.bidId}/bid-line-details/${original.bidLineId}/review-and-submit`}
          >
            {original.scheduleLine}
          </Link>
        );
      },
    },
    {
      Header: 'Body make and model',
      accessor: 'body',
      sortable: false,
    },
    {
      Header: 'Chassis make and model',
      accessor: 'chassis',
      sortable: false,
    },
    {
      Header: 'Standard Item',
      accessor: 'standardItem',
      sortable: false,
    },
    {
      id: 'techEvalCompleted',
      Header: 'Tech. eval. completed',
      sortable: false,
      accessor: 'bidLineId',
      Cell: ({ value }) => {
        const { lineData, saveEvaluation } = useBidLineEvaluation();
        const line = getItemByBidLineId(lineData, value);
        return (
          <CheckboxField
            id={`${value}-tech-eval-completed`}
            value={line.techEvalCompleted}
            canEdit={canTechEval && !line.awarded}
            onChange={(val) => saveEvaluation(line, { techEvalCompleted: val })}
          />
        );
      },
    },
    {
      id: 'techEvalAward',
      Header: 'Tech. eval. recommend award',
      sortable: false,
      accessor: 'bidLineId',
      Cell: ({ value }) => {
        const { lineData, saveEvaluation } = useBidLineEvaluation();
        const line = getItemByBidLineId(lineData, value);
        return (
          <SelectField
            id={`${value}-tech-eval-award`}
            value={line.techEvalAward}
            canEdit={canTechEval && !line.awarded}
            onChange={(val) => saveEvaluation(line, { techEvalAward: val })}
          />
        );
      },
    },
    {
      id: 'priceEvalCompleted',
      Header: 'Price eval. completed',
      sortable: false,
      accessor: 'bidLineId',
      Cell: ({ value }) => {
        const { lineData, saveEvaluation } = useBidLineEvaluation();
        const line = getItemByBidLineId(lineData, value);
        return (
          <CheckboxField
            id={`${value}-price-eval-completed`}
            value={line.priceEvalCompleted}
            canEdit={canPriceEval && !line.awarded}
            onChange={(val) => {
              saveEvaluation(line, { priceEvalCompleted: val });
            }}
          />
        );
      },
    },
    {
      id: 'priceEvalAward',
      Header: 'Price eval. recommend award',
      sortable: false,
      accessor: 'bidLineId',
      Cell: ({ value }) => {
        const { lineData, saveEvaluation } = useBidLineEvaluation();
        const line = getItemByBidLineId(lineData, value);
        return (
          <SelectField
            id={`${value}-price-eval-award`}
            value={line.priceEvalAward}
            canEdit={canPriceEval && !line.awarded}
            onChange={(val) => saveEvaluation(line, { priceEvalAward: val })}
          />
        );
      },
    },
    {
      id: 'evalStatus',
      Header: 'Eval. status',
      sortable: false,
      accessor: 'bidLineId',
      Cell: ({ value }) => {
        const { lineData } = useBidLineEvaluation();
        const line = getItemByBidLineId(lineData, value);
        if (line.status !== EVAL_STATUS.Awarded) return line.status;
        return <StatusBadge variant="Ready-Gray">Awarded</StatusBadge>;
      },
    },
    {
      id: 'actions',
      Header: 'Actions',
      sortable: false,
      accessor: 'bidLineId',
      Cell: ({ row }) => (
        <ActionCell row={row} canAward={canAward} canReassign={canReassign} />
      ),
    },
  ];

  return (
    <div className="margin-top-4">
      <AFPTable
        expandable={false}
        fullWidth
        columns={canAward ? [awardColumn, ...columns] : columns}
        data={tableData}
      />
      {count > 0 ? (
        <div className="padding-y-4 pagination">
          <Pagination
            itemsPerPageOptions={[5, 10, 25]}
            onPageChange={onPageChange}
            variant="advanced"
            itemsPerPage={limit}
            currentPage={offset / limit + 1}
            itemsCount={count}
            isReset={pageReset}
          />
        </div>
      ) : (
        <div>
          <EmptyState
            hasBackground
            containerStyles="padding-y-4"
            topText={`No line item ${
              rowData.length ? 'matches the filters' : 'found'
            }`}
          />
        </div>
      )}
    </div>
  );
};

export default BidLineEvaluationTable;
