import { atom } from 'recoil';

const biddingType = atom({
  key: 'Solicitation/biddingType',
  default: 'IN_FLEET',
});

export const reopenPeriodModalVendorIds = atom({
  key: 'Solicitation/reopenPeriodModalVendorIds',
  default: [],
});

export const vendorIds = atom({
  key: 'Solicitation/vendorIds',
  default: [],
});

export default biddingType;
