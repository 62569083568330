import { useState, useCallback } from 'react';

/**
 * Extension on useState hook for toggling a value
 * @param {boolean} initialValue
 * @returns {[boolean, ()=>void, React.Dispatch<React.SetStateAction<boolean>>]} [ value, toggleValue, setValue ]
 */
const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);
  const toggleValue = useCallback(
    () => setValue((currentValue) => !currentValue),
    [],
  );

  return [value, toggleValue, setValue];
};

export default useToggle;
