import React, { useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Alert,
  Icon,
  ErrorMessage,
  Spinner,
} from '@gsa/afp-component-library';
import OverlaySpinner from '../../../../components/overlay-spinner';
import { useBidLineDetails } from '../provider/bid-line-details-provider';
import Breadcrumbs from '../../../../widgets/breadcrumbs';
import { SUBMIT_BID_LINE } from '../provider/queries';
import BidLineReviewAndSubmitDetail from './bid-line-review-and-submit-detail';
import { COPY_MODAL_TITLE } from '../copy-line-item/helpers';
import {
  DOWNLOAD_LINE_ITEM,
  getDownloadAlert,
} from '../../../bids/bid-reports/bid-report-helpers';
import { GET_TABS_STATUSES } from '../../../bids/bids.gql';
import { BID_LINE_STATUS } from '../../constants';

let callCount = 0;

const BidLineReviewAndSubmit = () => {
  const history = useHistory();
  const { solId, bidId } = useParams();
  const {
    solicitation,
    bidLine,
    loading,
    loadingError,
    alert,
    setAlert,
    tabStatuses,
  } = useBidLineDetails();

  const [getTabsStatuses] = useLazyQuery(GET_TABS_STATUSES, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  let lineStatus = '';
  if (bidLine?.status === BID_LINE_STATUS.Submitted) lineStatus = 'Submitted';
  else if (bidLine?.status === BID_LINE_STATUS.NotStarted)
    lineStatus = bidLine?.status;
  else if (bidLine?.status) {
    const isReady = tabStatuses.every((stat) => stat);
    lineStatus = isReady
      ? BID_LINE_STATUS.ReadyToSubmit
      : BID_LINE_STATUS.InProgress;
    if (bidLine && bidLine.status !== lineStatus) {
      // call API which will update BE bid line status
      if (callCount === 0)
        getTabsStatuses({ variables: { bidLineId: parseInt(bidLine.id, 10) } });
      callCount += 1;
    }
  }

  const isEditable = (bl) => {
    if (!bl) return false;
    const { Days, Hours, Minutes } = bl.bid.solicitationPeriod.closeInDays;
    return !(!Days && !Hours && !Minutes);
  };
  const editable = isEditable(bidLine);

  const [submitBidline, { loading: isSubmitting }] = useMutation(
    SUBMIT_BID_LINE,
    {
      onCompleted: (data) => {
        if (data.errors) {
          setAlert({
            type: 'error',
            message: data.errors[0]?.message,
          });
          return;
        }
        setAlert({
          type: 'success',
          message: `The line item ${bidLine?.scheduleLine} has been submitted.`,
        });
      },
      onError: (error) => {
        setAlert({
          type: 'error',
          message: error.message,
        });
      },
    },
  );

  const onSubmitClick = useCallback(() => {
    const tabHeaders = Object.values(COPY_MODAL_TITLE).slice(0, 5);
    tabHeaders.push('documentation');
    const notReadyTabs = tabStatuses
      .map((stat, i) => {
        return stat ? null : tabHeaders[i];
      })
      .filter((tab) => tab);
    if (notReadyTabs.length) {
      setAlert({
        type: 'error',
        message: `Following tab(s) have not been completed: ${notReadyTabs.join(
          ', ',
        )}. Please enter all the required fields to proceed.`,
      });
    } else {
      submitBidline({
        variables: {
          solId: parseInt(solicitation.id, 10),
          bidLineId: parseInt(bidLine.id, 10),
        },
      });
    }
  }, [submitBidline, solicitation, bidLine, tabStatuses]);

  const [downloadLineItem, { loading: loadingJSReport }] = useLazyQuery(
    DOWNLOAD_LINE_ITEM,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const { content, contentType, fileExtension } = data.downloadLineItem;
        setAlert(
          getDownloadAlert(
            'Line item report',
            content.data,
            contentType,
            `Line Item ${bidLine.scheduleLine} Report.${fileExtension}`,
          ),
        );
      },
    },
  );

  const onDownload = () => {
    setAlert(null);
    downloadLineItem({ variables: { bidLineId: +bidLine.id } });
  };

  if (loading) return <Spinner className="margin-top-2" />;

  if (loadingError)
    return (
      <ErrorMessage>
        Error occurred while loading data: {loadingError.message}
      </ErrorMessage>
    );

  if (!bidLine) {
    return (
      <ErrorMessage>No bid line found for this solicitation.</ErrorMessage>
    );
  }

  const { scheduleLine } = bidLine;
  const bidDetailLocation = `/catalog/solicitations/${solId}/bid-dashboard/${bidId}/bid-line-details/${bidLine.id}`;

  return (
    <>
      <Breadcrumbs
        path={[
          {
            location: `${window.AFP_CONFIG.appURLs.home}/home`,
            label: 'Home',
          },
          {
            location: `/catalog/bids`,
            label: 'Bids Management',
          },
          {
            location: `/catalog/solicitations/${solId}/bid-dashboard/${bidId}`,
            label: `Bid Dashboard: ${solicitation.title}`,
          },
          {
            location: editable ? bidDetailLocation : './review-and-submit',
            label: `Line Item ${scheduleLine}`,
          },
        ]}
        current="Review and Submit"
      />
      {loadingJSReport && <OverlaySpinner />}
      {alert && (
        <Alert
          type={alert.type}
          slim
          focused
          className="margin-top-0"
          showClose
          onClose={() => setAlert(null)}
          actions={
            <div className="position-absolute top-1 right-1 cursor-pointer">
              <Icon
                data-testid="alert-close"
                iconName="close"
                onClick={() => setAlert(null)}
                className="text-large text-heavy font-sans-xl text-gray-500"
              />
            </div>
          }
        >
          {typeof alert.message === 'string' && alert.message.includes('\n')
            ? alert.message.split('\n').map((line) => {
                return (
                  <span>
                    {line} <br />
                  </span>
                );
              })
            : alert.message}
        </Alert>
      )}
      <div
        className="margin-top-8 display-flex flex-justify-end flex-align-end"
        style={{ marginBottom: '-5rem' }}
      >
        {editable && (
          <Button
            data-testid="bidline-top-back-btn"
            onClick={() => {
              history.push(bidDetailLocation);
            }}
            label="Back to bid input"
            variant="outline"
            className="margin-right-2"
          />
        )}
        <Button
          data-testid="download-bidline-report"
          onClick={onDownload}
          variant="outline"
          label="Download bid line report"
        />
      </div>
      <BidLineReviewAndSubmitDetail
        editable={editable}
        lineStatus={lineStatus}
        onSubmitClick={onSubmitClick}
        isSubmitting={isSubmitting}
        bidId={bidId}
        solId={solId}
        history={history}
      />
    </>
  );
};

export default BidLineReviewAndSubmit;
