import React from 'react';
import { Button } from '@gsa/afp-component-library';
import Modal from '../../components/Modal/Modal';
import { useEquipmentCodes } from './equipment-codes-provider';

import AssociatedStandardItemsTable from './associated-standard-items-table';
import AddEquipmentCode from './equipment-code-add';
import EditEquipmentCode from './equipment-code-edit';
import EquipmentCodeMessages from './equipment-code-messages';

const ModalTitle = () => {
  const { modalMode, MODAL_MODES, selectedEquipmentCode } = useEquipmentCodes();
  let title = '';

  switch (modalMode) {
    case MODAL_MODES.ADD:
      title = 'Add Equipment Code';
      break;
    case MODAL_MODES.EDIT:
      title = 'Edit Equipment Code';
      break;
    case MODAL_MODES.VIEW_ASSOCIATION:
      title = `Standard Items for Equipment Code ${selectedEquipmentCode.code} - ${selectedEquipmentCode?.title} - ${selectedEquipmentCode?.year}`;
      break;
    case MODAL_MODES.CONFIRM_DELETE:
      title = 'Confirm removal of Equipment Code';
      break;
    case MODAL_MODES.CONFIRM_UPDATE:
      title = 'Confirm Equipment Code overwrite';
      break;
    default:
      break;
  }
  return (
    <div className="margin-top-5">
      <EquipmentCodeMessages isModal />
      {/* eslint-disable-next-line */}
      <h3 tabIndex="0" aria-label={title}>
        {title}
      </h3>
    </div>
  );
};

const ModalContent = () => {
  const {
    modalMode,
    MODAL_MODES,
    selectedEquipmentCode,
    updatingEquipmentCode,
  } = useEquipmentCodes();
  if (modalMode === MODAL_MODES.ADD) return <AddEquipmentCode />;
  if (modalMode === MODAL_MODES.EDIT) return <EditEquipmentCode />;
  if (modalMode === MODAL_MODES.VIEW_ASSOCIATION)
    return <AssociatedStandardItemsTable />;
  if (modalMode === MODAL_MODES.CONFIRM_DELETE) {
    return (
      <div>
        Are you sure you want to remove {selectedEquipmentCode?.code} for
        program {selectedEquipmentCode?.program} from year{' '}
        {selectedEquipmentCode?.year}?
      </div>
    );
  }
  if (modalMode === MODAL_MODES.CONFIRM_UPDATE)
    return (
      <>
        <div>
          The Equipment Code {selectedEquipmentCode?.code} already exists for
          the Program {updatingEquipmentCode?.program}. Do you want to continue
          with the update?
        </div>
      </>
    );

  return null;
};

const ModalAction = () => {
  const {
    MODAL_MODES,
    setData,
    modalMode,
    selectedEquipmentCode,
    updatingEquipmentCode,
    deleteEquipmentCode,
    overwriteEquipmentCode,
  } = useEquipmentCodes();

  const onCancel = () => {
    setData('SET_FORM_MODAL', false);
    setData('SET_MODAL_MODE', null);
  };

  const overWrite = async () => {
    await overwriteEquipmentCode({
      variables: {
        id: Number.parseInt(selectedEquipmentCode.id, 10),
        equipmentcode: {
          category: updatingEquipmentCode.category,
          code: selectedEquipmentCode.code,
          program: updatingEquipmentCode.program,
          quantityRequired: updatingEquipmentCode.quantityRequired,
          sequence: updatingEquipmentCode.sequence,
          status: updatingEquipmentCode.status,
          title: updatingEquipmentCode.title,
          unit: updatingEquipmentCode.unit,
          content: String(updatingEquipmentCode.content),
          tags: updatingEquipmentCode.tags,
          contentId: updatingEquipmentCode.content?.id,
          year: selectedEquipmentCode.year,
        },
      },
    });
    setData('SET_FORM_MODAL', false);
  };

  if (modalMode === MODAL_MODES.ADD) {
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="standard-item-edit-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="primary"
          type="submit"
          form="equipment-code-add"
          data-testid="equipment-code-add-save-btn"
          label="Save"
        />
      </>
    );
  }
  if (modalMode === MODAL_MODES.EDIT) {
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="equipment-code-edit-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="primary"
          type="submit"
          form="equipment-code-edit"
          data-testid="equipment-code-edit-save-btn"
          label="Save"
        />
      </>
    );
  }
  if (modalMode === MODAL_MODES.VIEW_ASSOCIATION) {
    return null;
  }
  if (modalMode === MODAL_MODES.CONFIRM_DELETE) {
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="standard-item-delete-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="secondary"
          data-testid="standard-item-delete-remove-btn"
          onClick={async () => {
            await deleteEquipmentCode({
              variables: {
                id: Number.parseInt(selectedEquipmentCode.id, 10),
              },
            });
            setData('SET_FORM_MODAL', false);
          }}
          label="Remove"
        />
      </>
    );
  }
  if (modalMode === MODAL_MODES.CONFIRM_UPDATE) {
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="eq-code-confirm-update-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="secondary"
          type="submit"
          data-testid="eq-code-confirm-update-yes-btn"
          onClick={overWrite}
          label="Overwrite"
        />
      </>
    );
  }
  return null;
};

const EquipmentModalWrapper = () => {
  const { showFormModal, setData } = useEquipmentCodes();
  return (
    <Modal
      title={<ModalTitle />}
      actions={<ModalAction />}
      show={showFormModal}
      onClose={() => {
        setData('SET_FORM_MODAL', false);
        setData('SET_MODAL_MODE', null);
      }}
    >
      <ModalContent />
    </Modal>
  );
};

export default EquipmentModalWrapper;
