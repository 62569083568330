import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage, SelectDropdown } from '@gsa/afp-component-library';

export const STATUSES = [
  {
    value: '',
    label: 'Select',
  },
  {
    value: 'Active',
    label: 'Active',
  },
  {
    value: 'Inactive',
    label: 'Inactive',
  },
];

const StatusInput = () => {
  const { errors, control } = useFormContext();

  const renderRequiredComponent = (value) => {
    return (
      <>
        {/* eslint-disable-next-line */}
        <h2 className="font-sans-xs" tabIndex="0" ariaLabel={value}>
          {value} <span className="usa-hint usa-hint--required">*</span>
        </h2>
      </>
    );
  };

  return (
    <>
      {renderRequiredComponent('Status')}
      <Controller
        id="si_form_status"
        name="status"
        defaultValue="Select"
        control={control}
        render={({ onChange, onBlur, value }) => (
          <SelectDropdown
            aria-label="status"
            id="si_form_status_sel"
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            options={STATUSES}
          />
        )}
      />
      {errors?.status?.message && (
        <ErrorMessage>{errors.status.message}</ErrorMessage>
      )}
    </>
  );
};

export default StatusInput;
