import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StepIndicator } from '@gsa/afp-component-library';
import { useRecoilValue } from 'recoil';
import biddingType from '../atoms/solicitation-helpers';

const SolicitationStepper = ({ currentStep }) => {
  const [solicitationSteps, setSolicitationSteps] = useState([]);
  const [heading, setHeading] = useState({});
  const bidding = useRecoilValue(biddingType);
  const IN_FLEET_STEPS = [
    {
      stepID: 1,
      label: 'uPIID and details',
    },
    {
      stepID: 2,
      label: 'Open period',
    },
    {
      stepID: 3,
      label: 'Additional periods',
      status: 'not completed',
    },
    {
      stepID: 4,
      label: 'Standard Items',
      status: 'not completed',
    },
    {
      stepID: 5,
      label: 'Assign quantities',
      status: 'not completed',
    },
    {
      stepID: 6,
      label: 'Review & submit',
      status: 'not completed',
    },
  ];

  const OUTSIDE_FLEET_STEPS = [
    {
      stepID: 1,
      label: 'uPIID and details',
    },
    {
      stepID: 2,
      label: 'Standard Items',
      status: 'not completed',
    },
    {
      stepID: 3,
      label: 'Assign quantities',
      status: 'not completed',
    },
    {
      stepID: 4,
      label: 'Review & submit',
      status: 'not completed',
    },
  ];

  useEffect(() => {
    const tempSteps = [];
    if (bidding === 'IN_FLEET') {
      IN_FLEET_STEPS.forEach((step) => {
        if (step.stepID < currentStep) {
          tempSteps.push({
            label: step.label,
            status: 'completed',
          });
        } else if (step.stepID === currentStep) {
          tempSteps.push({
            label: step.label,
            status: 'current',
          });
          setHeading({
            current: step.stepID,
            text: step.label,
          });
        } else if (step.stepID > currentStep) {
          tempSteps.push({
            label: step.label,
            status: 'not completed',
          });
        }
      });
    } else if (bidding === 'OUTSIDE_FLEET') {
      OUTSIDE_FLEET_STEPS.forEach((step) => {
        if (step.stepID < currentStep) {
          tempSteps.push({
            label: step.label,
            status: 'completed',
          });
        } else if (step.stepID === currentStep) {
          tempSteps.push({
            label: step.label,
            status: 'current',
          });
          setHeading({
            current: step.stepID,
            text: step.label,
          });
        } else if (step.stepID > currentStep) {
          tempSteps.push({
            label: step.label,
            status: 'not completed',
          });
        }
      });
    }

    setSolicitationSteps(tempSteps);
  }, [currentStep, bidding]);

  return (
    <>
      <StepIndicator
        counterSize="small"
        data-testid="solicitation-stepper"
        heading={heading}
        steps={[...solicitationSteps]}
        className="margin-bottom-1"
      />
      <hr className="border-gray-1" />
    </>
  );
};

SolicitationStepper.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

export default SolicitationStepper;
