import React from 'react';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import CatalogCodeForm from '../../../components/forms/catalog-codes-form';
import { useCatalogCodes } from '../catalog-codes-provider';
import {
  getAdditionalProps,
  getFormattedTags,
  removeCustomSchemaFields,
} from './helper';

const CatalogCodeEdit = () => {
  const ability = useAppAbility();
  const {
    metadataOfSelectedCategory,
    selectedCatalogCode,
    catalogCodesOptions,
    updateCatalogCode,
  } = useCatalogCodes();

  let equipmentCategorySchema;
  if (metadataOfSelectedCategory.category === 'Equipment Category') {
    equipmentCategorySchema =
      metadataOfSelectedCategory.propertiesSchema.properties;
  }

  let fuelTypeCategorySchema;
  if (metadataOfSelectedCategory.category === 'Fuel Type') {
    fuelTypeCategorySchema = metadataOfSelectedCategory.propertiesSchema;
  }

  const onSubmit = async (data) => {
    const tags = getFormattedTags(data);
    const newData = removeCustomSchemaFields(data);
    const additionalProps = getAdditionalProps(
      metadataOfSelectedCategory.category,
      data.allowNewBids,
      data,
    );

    await updateCatalogCode({
      variables: {
        id: Number(selectedCatalogCode.id),
        catalogCode: {
          ...newData,
          category: metadataOfSelectedCategory.category,
          additionalProps,
          parentCategory: metadataOfSelectedCategory.parentCategory,
          tags,
          code: selectedCatalogCode.code,
          changeType: 'Update',
        },
      },
    });
  };

  return (
    <CatalogCodeForm
      formId="catalog-code-edit-form"
      selectedCatalogCode={selectedCatalogCode}
      metadataOfSelectedCategory={metadataOfSelectedCategory}
      catalogOptionsForTagsAndParentCodes={catalogCodesOptions}
      isNew={false}
      equipmentCategorySchema={equipmentCategorySchema}
      fuelTypeCategorySchema={fuelTypeCategorySchema}
      onSubmit={onSubmit}
      ability={ability}
    />
  );
};

export default CatalogCodeEdit;
