import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  DetailsTable,
  Button,
  useModal,
  connectModal,
} from '@gsa/afp-component-library';
import {
  capitalizeFirstLetter,
  convertToFullName,
} from '../helpers/helperFunc';
import { UTCDateStrToUS } from '../../../utilities/date-helpers';
import { useTaskDetail } from '../task-detail-provider';
import EditModal from '../task-detail-modal';

const TaskDetails = () => {
  const {
    task,
    getUsers,
    users,
    updateStandardPeerReviewTask,
  } = useTaskDetail();
  const {
    taskType,
    status,
    assignedUser,
    assignor,
    description,
    documentAuthor,
    documentLink,
    fedVehicleStandardsLink,
    fedStandardDoc,
    programName,
    classType,
    documentStatus,
    dueDate,
  } = task;

  const { isOpen, openModal, closeModal } = useModal();

  useEffect(() => {
    getUsers({
      variables: {
        order: [['lastName', 'DESC']],
        roleIds: [501, 502],
      },
    });
  }, []);

  const ConnectedModal = connectModal(() => (
    <EditModal
      closeModal={closeModal}
      onSubmit={(formData) => {
        if (formData.assignedUser) {
          const { dueDate: d, ...otherFormData } = formData;
          const formattedDate = d ? new Date(d) : null;
          updateStandardPeerReviewTask({
            variables: {
              id: Number.parseInt(task.sprId, 10),
              input: {
                ...otherFormData,
                fvsId: task.fvsId,
                dueDate: formattedDate,
              },
            },
          });

          closeModal();
        }
      }}
    />
  ));

  function detailsForTask() {
    switch (taskType.taskType) {
      case 'SPR':
        return [
          ['Assign from', convertToFullName(users, assignor)],
          ['Assign to', convertToFullName(users, assignedUser)],
          ['Document author', convertToFullName(users, documentAuthor)],
          ['Due date', `${dueDate ? UTCDateStrToUS(dueDate) : ''}`],
          [
            'Document link',
            !documentLink ? (
              'No link added'
            ) : (
              <a href={documentLink} rel="noreferrer" target="_blank">
                Read
              </a>
            ),
          ],
          [
            'Federal Vehicle Standards link',
            <Link to={fedVehicleStandardsLink}>Read</Link>,
          ],
          ['Document status', capitalizeFirstLetter(documentStatus)],
          [
            'Fed Std Doc - Program Name - Class Type',
            `${fedStandardDoc}: ${programName ?? ''}-${classType ?? ''}`, // Placeholder for programName & classType
          ],
          ['Description', description],
        ];
      default:
        return [['Status Code', status]];
    }
  }

  return (
    <div className="bg-base-lightest padding-3 margin-top-4 margin-bottom-8 afp-task-detail">
      {taskType.taskType === 'SPR' ? (
        <>
          <div className="afp-task-detail__header_edit_line">
            <h2 className="afp-task-detail__header_edit">
              {taskType.helpTextTitle}
            </h2>
          </div>
          <div className="afp-task-detail__header_description_line">
            {taskType.helpText}
          </div>
        </>
      ) : null}
      <DetailsTable data={detailsForTask()} />
      <Button
        className="margin-top-4"
        variant="outline"
        onClick={openModal}
        label="Edit"
      />
      <ConnectedModal isOpen={isOpen} closeModal={closeModal} />
    </div>
  );
};

export default TaskDetails;
