/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable import/no-named-as-default */
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import { Spinner } from '@gsa/afp-component-library';
import DetailTable from '../../../../components/DetailTable';
import RevealText from '../../components/reveal-text';
import { useFederalStandards } from '../../fvs-provider';
import { useFvsLandingPageContext } from '../fvs-landing-page-provider';
import { mapStatusToTags } from '../helper';

const FVSDetails = () => {
  const {
    vehicleStandard,
    vehicleStandardsLoading,
    isCommentingAllowed4Fvs4User,
  } = useFederalStandards();

  const { infoCopy, getAnnouncementForFvsInfo } = useFvsLandingPageContext();

  const [standardsData, setStandardsData] = useState([]);
  const [publicCommentingPeriodEnd, setPublicCommentingPeriodEnd] = useState(
    '',
  );

  const handleData = () => {
    const tableData = [
      {
        header: 'Contract year',
        value: vehicleStandard?.year,
        type: 'text',
      },
      {
        header: 'Federal Standard Code',
        value: `${vehicleStandard?.fedStandardCode} - ${vehicleStandard?.fedStandard?.title}`,
        type: 'text',
      },
      {
        header: 'Status',
        statuses: mapStatusToTags(vehicleStandard?.status),
        type: 'multi_status',
      },
    ];

    setStandardsData(tableData);
  };

  useEffect(() => {
    if (vehicleStandard) {
      handleData();
      if (vehicleStandard?.commentingPeriods) {
        const currentPublicOpenPeriod = vehicleStandard.commentingPeriods.find(
          (c) => c.status === 'Open' && c.userType === 'Public',
        );
        if (currentPublicOpenPeriod) {
          setPublicCommentingPeriodEnd(
            moment(currentPublicOpenPeriod.periodEnd).format('l'),
          );
        }
      }
    }

    handleData();
  }, [vehicleStandard]);

  useEffect(() => {
    getAnnouncementForFvsInfo({
      variables: { boardType: 2 },
    });
  }, []);

  return (
    <>
      {vehicleStandardsLoading ? (
        <Spinner size="small" />
      ) : (
        <>
          <h2 className="margin-top-neg-1" tabIndex="0">
            Details
          </h2>

          <div className="grid-row grid-gap">
            <div className="desktop:grid-col-9 tablet-lg:grid-col-12 padding-bottom-2 margin-right-2">
              <DetailTable className="fvs-info-table" data={standardsData} />
            </div>
            <div className="desktop:grid-col-3" />
          </div>
          {isCommentingAllowed4Fvs4User && (
            <div className="grid-row grid-gap">
              <div className="desktop:grid-col-8 tablet-lg:grid-col-12 padding-top-2 margin-right-2">
                <span className="body-bold" tabIndex="0">
                  The current commenting period for public is open through{' '}
                  {publicCommentingPeriodEnd}.
                </span>
                <ul>
                  <li>
                    <span className="body" tabIndex="0">
                      To comment on a Federal Standard Code Documentation,
                      select a document within the Documents tab below.
                    </span>
                  </li>
                  <li>
                    <span className="body" tabIndex="0">
                      To comment on a Standard Item or its related Equipment
                      Code, navigate to a Standard Item using the menu below.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          )}

          <RevealText
            isExpanded
            className="margin-top-3"
            title="About Standard Items"
            copy={<>{ReactHtmlParser(infoCopy)}</>}
          />
        </>
      )}
    </>
  );
};

export default FVSDetails;
