import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import { useFederalStandards } from '../../../fvs-provider';

const {
  FilterPanelField,
  FilterPanelFilterShape,
  useFilterPanel,
} = FilterPanel;

const VehicleGroupFilterItem = ({ filter }) => {
  const { clearOneFilter } = useFilterPanel();

  const {
    vehicleClassificationOptions,
    selectedOptions,
  } = useFederalStandards();

  useEffect(() => {
    clearOneFilter(filter);
  }, [selectedOptions]);

  return (
    <>
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'select',
          options: vehicleClassificationOptions?.vehicleGroup
            ? vehicleClassificationOptions?.vehicleGroup?.map((vGroup) => ({
                label: vGroup.label,
                value: vGroup.value,
              }))
            : [],
          value: (selectedOptions && selectedOptions?.vehicleGroup) || '',
        }}
      />
    </>
  );
};

VehicleGroupFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default VehicleGroupFilterItem;
