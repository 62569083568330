import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MultiSelectDropdown } from '@gsa/afp-component-library';
import { useBidLineDetails } from '../provider/bid-line-details-provider';
import { getOptionalEcList } from '../provider/helpers';
import { getEditConflictBlock, displayCludes } from './conflicts-components';

const EditIncludesExcludesBlock = ({ field, original, options, onSave }) => {
  const [selectedValues, setSelectedValues] = useState(original[field].value);

  const { optionalECs } = useBidLineDetails();
  const ecList = getOptionalEcList(optionalECs);

  const onClickSave = () => {
    const stringify = (strArray) => JSON.stringify(strArray.sort());
    if (stringify(selectedValues) !== stringify(original[field].value))
      onSave(field, selectedValues);
  };
  const getForm = () => (
    <MultiSelectDropdown
      id={field}
      selectedValues={selectedValues}
      options={options}
      onChange={setSelectedValues}
      queryThreshold={1}
      chipShowValue
    />
  );

  const getSummary = () => {
    if (selectedValues.length > 0) return displayCludes(selectedValues, ecList);
    return <div className="margin-left-2">(None)</div>;
  };

  return getEditConflictBlock(
    original.equipment,
    field,
    getForm,
    getSummary,
    onClickSave,
  );
};

EditIncludesExcludesBlock.propTypes = {
  field: PropTypes.string.isRequired,
  original: PropTypes.shape(PropTypes.objectOf({})).isRequired,
  options: PropTypes.arrayOf(PropTypes.objectOf({})).isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EditIncludesExcludesBlock;
