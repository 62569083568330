import React from 'react';
import PropTypes from 'prop-types';
import { useModal, Button } from '@gsa/afp-component-library';
import {
  COPY_MODAL_TITLE,
  TAB_KEY,
  getScheduleLine,
  countAllCopySuccessItems,
  countAllCopyFailedItems,
} from './helpers';
import CopyResultsModal from './copy-results-modal';

const CopyResultsMessage = ({ tabKey, responses, bidLines }) => {
  const { isOpen, openModal, closeModal } = useModal();

  const showTabResult = (response, toScheduleLine) => {
    const {
      copied = [],
      partialCopied = [],
      notCopied = [],
      srcOnly = [],
      destOnly = [],
      error,
    } = response[tabKey];
    const title = COPY_MODAL_TITLE[tabKey];
    if (error)
      return (
        <div key={response.toBidLineId}>
          Failed to copy {title} to <b>line item {toScheduleLine}</b>: {error}
        </div>
      );
    return (
      <div key={response.toBidLineId}>
        <b>
          {copied.length} {title}
        </b>{' '}
        have been copied
        {partialCopied.length ? (
          <>
            , <b>{partialCopied.length}</b> have been partially copied,
          </>
        ) : (
          ''
        )}{' '}
        and <b>{notCopied.length + srcOnly.length + destOnly.length}</b> could
        not be copied to <b>line item {toScheduleLine}</b>.
      </div>
    );
  };

  const showAllTabsResults = (response, fromScheduleLine, toScheduleLine) => {
    let text;
    if (countAllCopySuccessItems(response) === 0) text = 'not copied';
    else if (countAllCopyFailedItems(response) === 0)
      text = 'copied successfully';
    else text = 'partially copied';
    return (
      <div key={response.toBidLineId}>
        <b>Line item {fromScheduleLine}</b> was <b>{text}</b> to line item{' '}
        <b>{toScheduleLine}</b>
      </div>
    );
  };

  return (
    <div>
      {responses.map((res) => {
        const fromScheduleLine = getScheduleLine(res.fromBidLineId, bidLines);
        const toScheduleLine = getScheduleLine(res.toBidLineId, bidLines);
        if (res.error)
          return (
            <div key={res.toBidLineId}>
              Failed to copy to <b>line item {toScheduleLine}</b>: {res.error}
            </div>
          );
        if (tabKey === TAB_KEY.allTabs)
          return showAllTabsResults(res, fromScheduleLine, toScheduleLine);
        return showTabResult(res, toScheduleLine);
      })}
      <Button
        variant="unstyled"
        label="Click here to view detailed results"
        onClick={openModal}
      />
      {isOpen && (
        <CopyResultsModal
          tabKey={tabKey}
          responses={responses}
          bidLines={bidLines}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

CopyResultsMessage.propTypes = {
  tabKey: PropTypes.string.isRequired,
  responses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  bidLines: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default CopyResultsMessage;
