import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Alert } from '@gsa/afp-component-library';
import { useContractLine } from '../provider/contract-line-provider';
import EditIncludesExcludesBlock from './edit-includes-excludes-block';
import EditRequiresBlock from './edit-requires-block';
import {
  getOptionalEcList,
  isInactiveOptionType,
  objEq,
} from '../helpers/ec-helpers';
import { validateConflicts, showConflictTrace } from './conflicts-components';

const EditConflictModal = ({ row, onClose }) => {
  const { optionalECs, getOptionalEC } = useContractLine();
  const original = getOptionalEC(row.original);
  const ecList = getOptionalEcList(optionalECs);

  const [alert, setAlert] = useState(null);
  const [conflicts, setConflicts] = useState({
    includes: original.includes.newValue,
    excludes: original.excludes.newValue,
    requires: original.requires.newValue,
  });

  const options = ecList
    .filter(
      ({ equipmentCode, inputOptionType }) =>
        equipmentCode !== original.equipmentCode &&
        !isInactiveOptionType(inputOptionType),
    )
    .sort((a, b) => (a.equipmentCode < b.equipmentCode ? -1 : 1))
    .map(({ equipmentCode, equipment }) => ({
      label: equipment,
      value: equipmentCode,
    }));

  const includesRequiresOptions = options.filter(
    ({ value }) => !conflicts.excludes.includes(value),
  );
  const excludesOptions = options.filter(
    ({ value }) =>
      !conflicts.includes.includes(value) &&
      conflicts.requires.every((ecs) => !ecs.includes(value)),
  );

  const onUpdate = async (field, value) => {
    setAlert(null);
    // validate conflicts
    const validate = validateConflicts(
      original.equipmentCode,
      field,
      value,
      optionalECs,
    );
    if (!validate.isValid) {
      const EC = original.equipmentCode;
      setAlert({
        type: 'error',
        message: (
          <>
            <div>
              Unable to save <b>{field.toUpperCase()}</b>. The conflict is
              highlighted below.
            </div>
            <li>
              <u>
                {validate.rootEc} must have {validate.contradictEc}
              </u>
              : {showConflictTrace(validate.includeTrace, EC, field, value)}
            </li>
            <li>
              <u>
                {validate.rootEc} cannot have {validate.contradictEc}
              </u>
              : {showConflictTrace(validate.excludeTrace, EC, field, value)}
            </li>
          </>
        ),
      });
    } else {
      setConflicts((prev) => ({ ...prev, [field]: value }));
    }
  };

  const onCloseModal = () => onClose();

  const onUpdateConflicts = () => {
    const { includes, excludes, requires: reqs } = conflicts;
    const requires = reqs.filter((r) => r.length);
    const update = {};
    if (!objEq(original.includes.newValue, includes))
      update.includes = includes;
    if (!objEq(original.excludes.newValue, excludes))
      update.excludes = excludes;
    if (!objEq(original.requires.newValue, requires))
      update.requires = requires;
    if (Object.keys(update).length) onClose(original, update);
    else onClose();
  };

  return (
    <div className="afp-modal-wrapper edit-conflicts-modal">
      <div className="afp-modal-overlay">
        <Modal
          id="edit-conflict-modal"
          variant="extra-large"
          title={<h2>Manage conflicts</h2>}
          onClose={onCloseModal}
          actions={
            <div>
              <Button
                data-testid="close-edit-conflict-modal-btn"
                variant="unstyled"
                onClick={onCloseModal}
                label="Cancel"
              />
              <Button
                data-testid="close-edit-conflict-modal-btn"
                variant="primary"
                onClick={onUpdateConflicts}
                label="Update"
              />
            </div>
          }
        >
          <div className="margin-y-2 text-bold">{original.equipment}</div>
          {alert && (
            <Alert
              type={alert.type}
              slim
              focused
              className="margin-top-0 margin-bottom-2"
              showClose
              onClose={() => setAlert(null)}
            >
              {alert.message}
            </Alert>
          )}
          <div className="margin-bottom-2">
            <EditIncludesExcludesBlock
              equipment={original.equipment}
              field="includes"
              data={conflicts.includes}
              onUpdate={onUpdate}
              options={includesRequiresOptions}
            />
          </div>
          <div className="margin-bottom-2">
            <EditIncludesExcludesBlock
              equipment={original.equipment}
              field="excludes"
              data={conflicts.excludes}
              onUpdate={onUpdate}
              options={excludesOptions}
            />
          </div>
          <div>
            <EditRequiresBlock
              equipment={original.equipment}
              field="requires"
              data={conflicts.requires}
              onUpdate={onUpdate}
              options={includesRequiresOptions}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};

EditConflictModal.propTypes = {
  row: PropTypes.shape(PropTypes.objectOf({})).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditConflictModal;
