export const makesFetchOptionsPromise = (searchValue, token, signal) => {
  return fetch(window.AFP_CONFIG.api_url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      query: `
        query GetMakesByPartialMatch($makeName: String!, $dataSource: [String!]) {
          getMakesByPartialMatch(makeName: $makeName, dataSource: $dataSource) {
            value: makeCode
            label: makeName
            approvalStatus
            fleetStatus
            nhtsaMakeCode
          }
        }
        `,
      variables: {
        makeName: searchValue,
        dataSource: ['AFP', 'NHTSA'],
      },
    }),
    signal,
  });
};

export const modelsFetchOptionsPromise = (
  inputValue,
  makeCode,
  token,
  signal,
) => {
  return fetch(window.AFP_CONFIG.api_url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      query: `
        query GetModelsByPartialMatch(
          $makeCode: Int!
          $modelName: String!
          $dataSource: [String!]
        ) {
          getModelsByPartialMatch(
            makeCode: $makeCode
            modelName: $modelName
            dataSource: $dataSource
          ) {
            value: modelCode
            label: modelName
            approvalStatus
            fleetStatus
            nhtsaModelCode
          }
        }
          `,
      variables: {
        modelName: inputValue,
        makeCode: makeCode ? parseInt(makeCode, 10) : -1,
        dataSource: ['AFP', 'NHTSA'],
      },
    }),
    signal,
  });
};
