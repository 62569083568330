import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import Truncate from '../Truncate';
import { EQUIPMENT_CODES } from '../../services/data-store';

const EquipmentCodeDescription = ({ equipmentCodeId, desc }) => {
  const [description, setDescription] = useState('');

  const [getEquipmentCodeWithDescription] = useLazyQuery(EQUIPMENT_CODES, {
    fetchPolicy: 'network-only',
    onError: (requestError) => {
      // eslint-disable-next-line no-console
      console.log(requestError);
      setDescription('');
    },
    onCompleted: (responseData) => {
      if (responseData?.getEquipmentCodes) {
        setDescription(
          responseData?.getEquipmentCodes?.rows[0]?.content?.content,
        );
      }
    },
  });

  useEffect(() => {
    if (equipmentCodeId && !desc) {
      getEquipmentCodeWithDescription({
        variables: {
          filters: {
            key: 'id',
            operator: 'EQ',
            value: Number(equipmentCodeId),
          },
        },
      });
    }
    if (desc) {
      setDescription(desc);
    }
  }, [equipmentCodeId, desc]);

  return (
    <div className="grid-row padding-bottom-2">
      <div className="grid-col-6">
        <h2 className="font-sans-md">Description</h2>
        {description ? (
          <Truncate content={description} />
        ) : (
          <span>&ndash;</span>
        )}
      </div>
    </div>
  );
};

EquipmentCodeDescription.defaultProps = {
  desc: null,
};

EquipmentCodeDescription.propTypes = {
  equipmentCodeId: PropTypes.string.isRequired,
  desc: PropTypes.string,
};

export default EquipmentCodeDescription;
