import React from 'react';
import { Switch } from 'react-router-dom';
import { PublicRoute } from '@gsa/afp-shared-ui-utils';
import VehicleStandardsPage from './landing-page';
import DocPreview from './doc-preview';
import FvsProvider from './fvs-provider';
import FvsSupportingDocsProvider from './fvs-supporting-docs-provider';
import CommentingPeriods from './commenting-periods';
import Requirements from './requirements';
import FvsCommentsProvider from './fvs-comments-provider';
import ViewComments from './comments/view-comments';

const FedVehicleStandards = () => {
  return (
    <FvsProvider>
      <FvsSupportingDocsProvider>
        <FvsCommentsProvider>
          <Switch>
            <PublicRoute
              exact
              path="/public/fvs/vehicle-standards"
              component={VehicleStandardsPage}
              title="Federal Vehicle Standards"
            />
            <PublicRoute
              exact
              path="/public/fvs/vehicle-standards/requirements/:year/:fedCode/:standardItemNumber"
              component={Requirements}
              title="Vehicle Requirements"
            />
            <PublicRoute
              exact
              path="/public/fvs/comments"
              component={ViewComments}
              title="Comments"
              featureName="FEDERAL_VEHICLE_STANDARD"
            />
            <PublicRoute
              exact
              path="/public/fvs/vehicle-standards/commenting-periods"
              component={CommentingPeriods}
              title="Commenting Periods"
            />
            <PublicRoute
              exact
              path="/public/fvs/vehicle-standards/preview/:year/:fvsId/:docId?"
              component={DocPreview}
              title="Vehicle Standards Documentation"
            />
            {/* <PublicRoute
                exact
                path="/public/fvs/vehicle-standards/preview/:year/:fvsId/:docId?"
                component={DocPreview}
                title="Vehicle Standards Documentation"
              />
              
              <PublicRoute
                exact
                path="/public/fvs/vehicle-standards/:year/:fedCode"
                component={VehicleStandardsPage}
                title="Federal Vehicle Standards"
              />
              <PublicRoute
                path="*"
                component={NotFound}
                title="Sorry for the Detour"
              /> */}
          </Switch>
        </FvsCommentsProvider>
      </FvsSupportingDocsProvider>
    </FvsProvider>
  );
};

export default FedVehicleStandards;
