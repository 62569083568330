import { Button } from '@gsa/afp-component-library';
import React from 'react';
import Modal from '../../../components/Modal/Modal';
import { MODAL_MODES } from './constants';
import { useFvsPreBidContext } from '../fvs-pre-bid-selection-provider';
import MakeModelView from './make-model-view';

const ModalTitle = () => {
  const { modalMode } = useFvsPreBidContext();

  let title = '';
  switch (modalMode) {
    case MODAL_MODES.VIEW_MAKE_MODEL:
      title = `Make and Model`;
      break;
    default:
      break;
  }
  return (
    <div className="margin-top-5">
      {/* <StandardItemMessage isModal /> */}
      {/* eslint-disable-next-line */}
      <h3 aria-label={title} tabIndex="0">
        {title}
      </h3>
    </div>
  );
};

const ModalContent = () => {
  const { modalMode } = useFvsPreBidContext();

  if (modalMode === MODAL_MODES.VIEW_MAKE_MODEL) return <MakeModelView />;

  return null;
};

const OnCancel = (setFvsPreBidContextData) => {
  return () => {
    setFvsPreBidContextData('SET_FORM_MODAL', false);
    setFvsPreBidContextData('SET_MODAL_MODE', null);
    setFvsPreBidContextData('SET_SELECTED_ITEM', null);
  };
};

const ModalAction = () => {
  const { modalMode, setFvsPreBidContextData } = useFvsPreBidContext();

  if (modalMode === MODAL_MODES.VIEW_MAKE_MODEL) {
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={OnCancel(setFvsPreBidContextData)}
          data-testid="make-model-view-cancel-btn"
          label="Cancel"
        />
      </>
    );
  }

  return null;
};

const MakeAndModelModalWrapper = () => {
  const { showFormModal, setFvsPreBidContextData } = useFvsPreBidContext();

  return (
    <Modal
      title={<ModalTitle />}
      actions={<ModalAction />}
      show={showFormModal}
      onClose={OnCancel(setFvsPreBidContextData)}
    >
      <ModalContent />
    </Modal>
  );
};

export default MakeAndModelModalWrapper;
