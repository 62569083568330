import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './notification.css';
import { Button } from '@gsa/afp-component-library';
import classNames from 'classnames';

const Notification = ({ message, id, variant }) => {
  const [closed, setClosed] = useState(false);

  const handleClose = () => {
    setClosed(true);
  };
  useEffect(() => {
    setClosed(false);
  }, [id, message, variant]);

  const classes = classNames(
    'usa-alert',
    { [`usa-alert--${variant}`]: !!variant },
    'usa-alert--slim',
  );

  return (
    <>
      {!closed ? (
        <div className={classes} key={id} data-testid="usa-alert-slim">
          <div className="usa-alert__body">
            <p className="usa-alert__text">{message}</p>
            <Button
              className="usa-button usa-button--unstyled"
              onClick={handleClose}
              label="Close"
              leftIcon={{
                name: 'close',
              }}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};
Notification.defaultProps = {
  variant: 'success',
  id: '',
};
Notification.propTypes = {
  message: PropTypes.string.isRequired,
  variant: PropTypes.string,
  id: PropTypes.string || PropTypes.number,
};

export default Notification;
