import React from 'react';
import { useRecoilState } from 'recoil';
import { Alert } from '@gsa/afp-component-library';
import BidsCenter from './bids-center';
import Breadcrumbs from '../../widgets/breadcrumbs';
import { bidsMessagesAtom } from './atoms/bids-atoms';
import { VendorInfoProvider } from './bids-center/apis/vendor-api';

const Bids = () => {
  const [bidsMessages] = useRecoilState(bidsMessagesAtom);
  return (
    <div className="grid grid-container bids-center-wrapper">
      {bidsMessages && bidsMessages.length > 0 && (
        <div className="margin-top-2">
          {bidsMessages.map((message) => (
            <Alert
              key={message.id}
              type={message.type}
              focused
              slim
              closable
              showClose
            >
              {message.message}
            </Alert>
          ))}
        </div>
      )}
      <Breadcrumbs
        current="Bids Management"
        path={[
          {
            location: `${window.AFP_CONFIG.appURLs?.home}/home`,
            label: 'Home',
          },
        ]}
      />
      <VendorInfoProvider>
        <BidsCenter />
      </VendorInfoProvider>
    </div>
  );
};

export default Bids;
