import { Button } from '@gsa/afp-component-library';
import React from 'react';
import Modal from '../../components/Modal/Modal';
import { MODAL_MODES } from './constants';
import EquipmentAssociationsView from './equipment-associations-view';
import StandardItemEdit from './standard-item-edit';
import StandardItemAdd from './standard-item.add';
import { useStandards } from './standards-provider';
import StandardItemMessage from './standard-item-message';

const ModalTitle = () => {
  const { modalMode, selectedStandardItem } = useStandards();

  let title = '';
  switch (modalMode) {
    case 'add':
      title = 'Add Standard Item';
      break;
    case 'edit':
      title = 'Edit Standard Item';
      break;
    case 'confirm_delete':
      title = 'Confirm removal of Standard Item';
      break;
    case 'view_associations':
      title = `Equipment Codes for Standard Item ${selectedStandardItem?.standardItemNumber} - ${selectedStandardItem?.title} - ${selectedStandardItem?.year}`;
      break;
    default:
      break;
  }

  return (
    <div className="margin-top-5">
      <StandardItemMessage isModal />
      {/* eslint-disable-next-line */}
      <h3 aria-label={title} tabIndex="0">
        {title}
      </h3>
    </div>
  );
};

const ModalContent = () => {
  const { modalMode, selectedStandardItem } = useStandards();

  if (modalMode === MODAL_MODES.ADD) return <StandardItemAdd />;
  if (modalMode === MODAL_MODES.EDIT) return <StandardItemEdit />;
  if (modalMode === MODAL_MODES.VIEW_ASSOCIATION)
    return <EquipmentAssociationsView />;
  if (modalMode === MODAL_MODES.CONFIRM_DELETE) {
    return (
      <div>
        {`Are you sure you want to remove the ${selectedStandardItem?.standardItemNumber} from year ${selectedStandardItem?.year}?`}
      </div>
    );
  }

  return null;
};

const ModalAction = () => {
  const {
    modalMode,
    selectedStandardItem,
    setData,
    deleteStandardItem,
  } = useStandards();

  const onCancel = () => {
    setData('SET_FORM_MODAL', false);
    setData('SET_MODAL_MODE', null);
    setData('SET_SELECTED_ITEM', null);
  };

  if (modalMode === MODAL_MODES.EDIT) {
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="standard-item-edit-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="primary"
          type="submit"
          form="standard-item-edit-form"
          data-testid="standard-item-edit-save-btn"
          label="Save"
        />
      </>
    );
  }

  if (modalMode === MODAL_MODES.CONFIRM_DELETE) {
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="standard-item-delete-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="secondary"
          data-testid="standard-item-delete-remove-btn"
          onClick={async () => {
            if (selectedStandardItem?.id) {
              deleteStandardItem({
                variables: {
                  id: selectedStandardItem.id,
                },
              });
            }
          }}
          label="Remove"
        />
      </>
    );
  }

  if (modalMode === MODAL_MODES.ADD) {
    return (
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={onCancel}
          data-testid="standard-item-add-cancel-btn"
          label="Cancel"
        />
        <Button
          variant="primary"
          type="submit"
          form="standard-item-add-form"
          data-testid="standard-item-add-save-btn"
          label="Save"
        />
      </>
    );
  }

  return null;
};

const StandardItemModalWrapper = () => {
  const { showFormModal, setData } = useStandards();
  return (
    <Modal
      title={<ModalTitle />}
      actions={<ModalAction />}
      show={showFormModal}
      onClose={() => {
        setData('SET_FORM_MODAL', false);
        setData('SET_MODAL_MODE', null);
        setData('SET_SELECTED_ITEM', null);
      }}
    >
      <ModalContent />
    </Modal>
  );
};

export default StandardItemModalWrapper;
