import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Fieldset, Button, TextInput } from '@gsa/afp-component-library';
import { useBidLineDetails } from '../../provider/bid-line-details-provider';
import {
  AUTO_SAVE_BID_LINE_CLARIFICATION,
  REMOVE_BID_LINE_CLARIFICATION,
} from '../../provider/queries';

const Clarifications = ({ clarificationType }) => {
  const {
    bidLine,
    findHighlight,
    removeHighlight,
    bidDetailData,
    updateBidDetailData,
    isAdmin,
  } = useBidLineDetails();

  const existingClarifications = useMemo(() => {
    const filterer = (c) => c.clarificationType === clarificationType;
    let cs = bidDetailData?.clarifications?.filter(filterer);
    if (!cs?.length) {
      cs = bidLine?.clarifications?.filter(filterer);
    }
    return cs;
  }, [bidDetailData, clarificationType]);

  const clonedExisitngClarifications = existingClarifications?.length
    ? JSON.parse(JSON.stringify(existingClarifications))
    : [];

  const [textareas, setTextareas] = useState(clonedExisitngClarifications);

  const setTextareValue = (index, value) => {
    const newTextareas = [...textareas];
    newTextareas[index].clarification = value;
    setTextareas(newTextareas);
  };

  const [createBidLineClarification] = useMutation(
    AUTO_SAVE_BID_LINE_CLARIFICATION,
    {
      onCompleted: ({ autoSaveBidLineClarification: id }) => {
        if (!textareas.find((x) => x.id === id)) {
          setTextareas((prev) => [
            ...prev,
            {
              id,
              clarification: '',
              bidLineId: bidLine.id,
              clarificationType,
            },
          ]);
        }
      },
    },
  );
  const [removeBidLineClarification] = useMutation(
    REMOVE_BID_LINE_CLARIFICATION,
  );

  const addTextarea = () => {
    // eslint-disable-next-line
    !isAdmin &&
      createBidLineClarification({
        variables: {
          input: {
            bidLineId: bidLine?.id,
            clarification: '',
            clarificationType,
          },
        },
      });
  };

  const removeClarification = (id) => {
    setTextareas((prev) => prev.filter((x) => x.id !== id));
    if (id !== null) {
      removeBidLineClarification({
        variables: {
          removeBidLineClarificationId: id,
        },
      });
    }
  };

  const updateClarification = async (id, value) => {
    const updatedClarification = {
      id,
      bidLineId: bidLine?.id,
      clarification: value,
      clarificationType,
    };
    // eslint-disable-next-line
    !isAdmin &&
      createBidLineClarification({
        variables: {
          input: updatedClarification,
        },
      });
    const cs = bidDetailData?.Clarifications || bidLine?.clarifications;
    const csWithoutUpdated = cs.filter((c) => c.id !== id);
    updateBidDetailData('clarifications', [
      ...csWithoutUpdated,
      updatedClarification,
    ]);
  };

  return (
    <Fieldset
      name="clarification"
      data-testid={
        clarificationType === 'C' ? 'clarifications' : 'technical-exceptions'
      }
    >
      <div className="margin-bottom-1">
        {clarificationType === 'C'
          ? 'Provide clarification for this bid line'
          : 'Provide explanation of any non-compliance for this bid line'}
      </div>
      <>
        {textareas.map((item, index) => {
          const highlight = findHighlight(
            'detail',
            'bid_line_clarification',
            item?.id,
            'clarification',
          );
          const isClarrificationHighlighted = !!highlight;

          return (
            <div className="grid-row flex-row" key={item.id}>
              <div className="grid-col-4">
                <TextInput
                  className={
                    isClarrificationHighlighted
                      ? 'bid-line-highlighted-input-data'
                      : ''
                  }
                  id={index}
                  value={item.clarification}
                  data-testid={
                    clarificationType === 'C'
                      ? `clarifications-input-${index}`
                      : `technical-exceptions-input-${index}`
                  }
                  type="textarea"
                  characterLimit={2000}
                  onChange={(e) => setTextareValue(index, e.target.value)}
                  onBlur={(event) => {
                    const { value } = event.target;
                    updateClarification(item.id, value);
                    if (isClarrificationHighlighted) {
                      removeHighlight(highlight.id);
                    }
                  }}
                />
              </div>
              <div className="grid-col-8 flex-align-start">
                <Button
                  variant="outline"
                  onClick={() => removeClarification(item.id)}
                  leftIcon={{ name: 'close' }}
                  style={{
                    height: 50,
                    width: 50,
                    margin: '134px 0 0 8px',
                    padding: 0,
                  }}
                  size="medium"
                  data-testid={
                    clarificationType === 'C'
                      ? `clarifications-${index}-remove-btn`
                      : `technical-exceptions-${index}-remove-btn`
                  }
                />
              </div>
            </div>
          );
        })}
        <Button
          variant="unstyled"
          size="medium"
          label={`Add ${
            clarificationType === 'C' ? 'clarification' : 'technical exception'
          }`}
          leftIcon={{ name: 'add' }}
          onClick={addTextarea}
          className="margin-top-1"
          data-testid={
            clarificationType === 'C'
              ? 'add-clarification'
              : 'add-technical-exception'
          }
        />
      </>
    </Fieldset>
  );
};

Clarifications.propTypes = {
  clarificationType: PropTypes.oneOf(['C', 'E']),
};

Clarifications.defaultProps = {
  clarificationType: 'C',
};

export default Clarifications;
