import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@gsa/afp-component-library';
import StandardItemTypeaheadInput from './standard-item-typeahead-input';
import { useEquipmentDetailCodes } from '../equipment-code-detail-provider';

const StandardItem = ({ isNew, errors, control, setStandardItemId }) => {
  const { equipmentAssociation } = useEquipmentDetailCodes();

  const renderRequiredComponent = (value) => {
    return (
      <>
        <h2 className="font-sans-md">
          {value} <span className="usa-hint usa-hint--required">*</span>
        </h2>
      </>
    );
  };

  return (
    <div className="grid-row padding-bottom-2">
      <div className="grid-col-6">
        {renderRequiredComponent('Standard Item')}
        {isNew ? (
          <>
            <Controller
              name="standardItemId"
              control={control}
              defaultValue=""
              render={({ onChange, value }) => (
                <StandardItemTypeaheadInput
                  value={value ?? ''}
                  onChange={({ selected, selectedData }) => {
                    const selectedId = Number(selectedData?.id);
                    if (selectedId) {
                      onChange(selected);
                      setStandardItemId(selectedId);
                    }
                  }}
                />
              )}
            />
            {errors?.standardItemId?.message && (
              <ErrorMessage>{errors.standardItemId.message}</ErrorMessage>
            )}
          </>
        ) : (
          equipmentAssociation?.standardItem.title
        )}
      </div>
    </div>
  );
};

StandardItem.propTypes = {
  isNew: PropTypes.bool.isRequired,
  control: PropTypes.shape(Object).isRequired,
  errors: PropTypes.shape(Object).isRequired,
  setStandardItemId: PropTypes.func.isRequired,
};

export default StandardItem;
