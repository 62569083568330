import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert } from '@gsa/afp-component-library';
import { useAttachmentComponent } from './ComponentContext';
import DescriptionField from './DescriptionField';
import EffectiveDateField from './EffectiveDateField';
import { useAttachments } from './AttachmentProvider';

const AttachmentEditForm = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const schema = yup.object().shape({
    effectiveDate: yup
      .date()
      .typeError('Effective date is required.')
      .nullable()
      .min(yesterday, 'Effective date can not be in the past.'),
  });
  const {
    onUpdateDescription,
    attachmentErrors,
    editState: { data, field },
  } = useAttachmentComponent();

  const { updateSupportingDocEffectiveDate } = useAttachments();

  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      description: data?.description,
      effectiveDate: data?.effectiveDate,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const onSubmit = (formData) => {
    if (!data.id) return;
    if (formData?.effectiveDate) {
      updateSupportingDocEffectiveDate({
        variables: {
          id: +data.id,
          effectiveDate: new Date(formData.effectiveDate),
        },
      });
      return;
    }
    if (formData?.description) {
      onUpdateDescription({
        variables: {
          input: {
            id: data.documentMetadataId,
            description: formData.description,
          },
        },
      });
    }
  };

  return (
    <>
      <form
        data-testid="attachment-edit-form"
        id="attachment-edit-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {attachmentErrors?.update?.message && (
          <Alert type="error">{attachmentErrors?.update?.message}</Alert>
        )}

        {field === 'description' && (
          <DescriptionField
            fieldRef={register}
            showErrorMessage={errors?.description?.type === 'required'}
          />
        )}
        {field === 'effectiveDate' && (
          <EffectiveDateField
            fieldRef={register}
            props={{ documentTitle: data?.name, control, errors }}
          />
        )}
      </form>
    </>
  );
};

export default AttachmentEditForm;
