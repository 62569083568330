import React from 'react';
import { TextInput } from '@gsa/afp-component-library';
import { useSIMakeModelProvider } from '../make-model/si-make-model-panel-provider';
import { useAddContractLineProvider } from '../add-contract-line/add-contract-line-provider';

const ManufacturerModelNumberTextField = () => {
  const { setDirtyFields, dirtyFields } = useSIMakeModelProvider();
  const {
    manufacturerModelNumber,
    setManufacturerModelNumber,
    getManufacturerModelNumberErrors,
  } = useAddContractLineProvider();

  const manufacturerModelNumberErrors = getManufacturerModelNumberErrors();
  const manufacturerModelNumberError = manufacturerModelNumberErrors?.find(
    (e) => e.field === 'manufacturerModelNumber',
  )?.message;

  return (
    <TextInput
      required
      data-testid="manufacturer-model-number"
      label="Manufacturer model number"
      value={manufacturerModelNumber}
      onChange={(evt) => {
        setManufacturerModelNumber(evt.target.value);
      }}
      onBlur={() => {
        setManufacturerModelNumber(manufacturerModelNumber.trim());
        setDirtyFields((prev) => [...prev, 'manufacturerModelNumber']);
      }}
      errorMessage={
        dirtyFields?.includes('manufacturerModelNumber') &&
        manufacturerModelNumberError
          ? manufacturerModelNumberError
          : undefined
      }
    />
  );
};

export default ManufacturerModelNumberTextField;
