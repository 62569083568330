/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { AFPTable } from '@gsa/afp-component-library';
import { emDashUnicode } from '../../../utilities/constants';
import {
  formatDateTime,
  getVendorLink,
} from './contract-header-history-helpers';
import { ContractContext } from '../contract-provider';

export const Columns = [
  {
    Header: 'Version',
    accessor: 'versionNumber',
    sortable: true,
    Cell: ({ value }) => {
      const { maxVersionNumber } = useContext(ContractContext);
      const { contractHeaderId } = useParams();
      if (value === maxVersionNumber) {
        return (
          <RouterLink
            to={`/catalog/contract/contract-header/${contractHeaderId}`}
          >
            {value}
          </RouterLink>
        );
      }

      return (
        <RouterLink
          to={`/catalog/contract/contract-header/${contractHeaderId}/history/${value}`}
        >
          {value}
        </RouterLink>
      );
    },
  },
  {
    Header: 'Changed by',
    accessor: 'changedBy',
    sortable: true,
    Cell: ({ value }) => value,
  },
  {
    Header: 'Date/time of change',
    accessor: 'changedAt',
    sortable: true,
    Cell: ({ value }) => formatDateTime(value),
  },
  {
    Header: 'Number of changes',
    accessor: 'changeCount',
    sortable: false,
    Cell: ({ value }) => value || emDashUnicode,
  },
];

const ValueCell = ({ value, row: { original } }) => {
  if (original.fieldName !== 'Vendor') return value;
  return getVendorLink(value);
};
ValueCell.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      fieldName: PropTypes.string,
      previousValue: PropTypes.string,
      currentValue: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export const RowSubComponent = ({ row: { original } }) => {
  if (original.changeCount === 0) return null;

  const changeColumns = [
    {
      Header: 'Field name',
      accessor: 'fieldName',
      sortable: false,
    },
    {
      Header: 'Previous value',
      accessor: 'previousValue',
      sortable: false,
      Cell: ValueCell,
    },
    {
      Header: 'Current value',
      accessor: 'currentValue',
      sortable: false,
      Cell: ValueCell,
    },
  ];

  return (
    <section className="contract-history-table-subrow">
      <h3 className="text-primary margin-0">CONTRACT VERSION CHANGES</h3>
      <AFPTable data={original.changes} columns={changeColumns} />
    </section>
  );
};

RowSubComponent.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      changeCount: PropTypes.number.isRequired,
      changes: PropTypes.arrayOf(
        PropTypes.shape({
          fieldName: PropTypes.string,
          previousValue: PropTypes.string,
          currentValue: PropTypes.string,
        }).isRequired,
      ),
    }),
  }).isRequired,
};
