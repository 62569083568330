import { gql } from '@apollo/client';

export const GET_EQUIPMENT_OPTIONS = gql`
  query getEquipmentOptions($standardItemId: Float!, $getActive: Boolean) {
    getEquipmentOptions(
      standardItemId: $standardItemId
      getActive: $getActive
    ) {
      id
      associationTypeCode
      associationText
      inputTypeCode
      lowerBound
      upperBound
      criteriaCode
      unitCode
      preDefinedValue
      equipmentCodeId
      equipmentCode {
        code
        category
        title
        quantityRequired
        sequence
        tags
        categoryCode {
          code
          title
          additionalProps
        }
        unitCode {
          code
          standardsCodesCR {
            code
          }
          requestStatus
        }
      }
      comments {
        comment
      }
    }
  }
`;

export const AUTO_SAVE_BID_LINE_EC = gql`
  mutation autoSaveBidLineEC($input: BidLineEcInput!) {
    autoSaveBidLineEC(input: $input)
  }
`;

export const SAVE_BID_LINE_EC_RELATION = gql`
  mutation saveBidLineEcRelation($input: BidLineEcRelationInput!) {
    saveBidLineEcRelation(input: $input)
  }
`;

export const AUTO_SAVE_BID_LINE_ENGINE = gql`
  mutation autoSaveBidLineEngine($input: BidLineEngineInput!) {
    autoSaveBidLineEngine(input: $input)
  }
`;

export const GET_GSA_COLORS = gql`
  query getGSAColors {
    getGSAColors {
      id
      code
      title
    }
  }
`;

export const GET_FUEL_TYPES = gql`
  query getFuelTypes($isForBids: Boolean) {
    getFuelTypes(isForBids: $isForBids) {
      id
      code
      title
    }
  }
`;

export const GET_STATES = gql`
  query getStates {
    getStates {
      stateCode
      stateName
      isoCountryCode2
    }
  }
`;

export const CREATE_BID_LINE_COLOR = gql`
  mutation createBidLineColor($input: BidLineColorInput!) {
    createBidLineColor(bidLineColorInput: $input) {
      id
      vendorColorName
      vendorColorCode
      gsaColorCodeId
      standardPremiumCode
      price
    }
  }
`;

export const REMOVE_BID_LINE_COLOR = gql`
  mutation removeBidLineColor($id: Float!) {
    removeBidLineColor(id: $id)
  }
`;

export const CREATE_BIDLINE_ASSO_LOC = gql`
  mutation CreateBidLineAssociatedLocation(
    $bidLineAssociatedLocationInput: BidLineAssociatedLocationInput!
  ) {
    createBidLineAssociatedLocation(
      bidLineAssociatedLocationInput: $bidLineAssociatedLocationInput
    ) {
      id
      vendorAssemblyPointId
      vendorInspectionPointId
      percentageOfDomContent
    }
  }
`;

export const GET_BIDLINE_ASSO_LOCS = gql`
  query GetBidLineAssociatedLocations($bidLineId: Int!) {
    getBidLineAssociatedLocations(bidLineId: $bidLineId) {
      id
      bidLineId
      vendorAssemblyPointId
      vendorInspectionPointId
      percentageOfDomContent
    }
  }
`;

export const GET_BIDLINE_ASSO_LOC = gql`
  query GetBidLineAssociatedLocation($getBidLineAssociatedLocationId: Int!) {
    getBidLineAssociatedLocation(id: $getBidLineAssociatedLocationId) {
      id
      bidLineId
      vendorAssemblyPointId
      vendorInspectionPointId
      percentageOfDomContent
    }
  }
`;

export const REMOVE_BIDLINE_ASSO_LOC = gql`
  mutation RemoveBidLineAssociatedLocation(
    $removeBidLineAssociatedLocationId: Float!
  ) {
    removeBidLineAssociatedLocation(id: $removeBidLineAssociatedLocationId)
  }
`;

export const AUTO_SAVE_BID_LINE_CLARIFICATION = gql`
  mutation AutoSaveBidLineClarification($input: BidLineClarificationInput!) {
    autoSaveBidLineClarification(input: $input)
  }
`;

export const REMOVE_BID_LINE_CLARIFICATION = gql`
  mutation RemoveBidLineClarification($removeBidLineClarificationId: Float!) {
    removeBidLineClarification(id: $removeBidLineClarificationId)
  }
`;

export const AUTO_SAVE_BID_LINE_DETAILS = gql`
  mutation AutoSaveBidLineDetails($input: BidLineDetailsInput!) {
    autoSaveBidLineDetails(input: $input)
  }
`;

export const ADD_DOCUMENT_TO_BIDLINE = gql`
  mutation addDocumentToBidline(
    $addDocumentToBidLine: AddDocumentToBidlineInput!
  ) {
    addDocumentToBidline(addDocumentToBidLine: $addDocumentToBidLine) {
      bidLineAttachmentId
      documentMetadataId
      signedUrl
      description
      orderNum
    }
  }
`;

export const SET_BILINDE_ATTACHMENTS_ORDER = gql`
  mutation setBidLineAttachmentsOrder(
    $bideLineId: Float!
    $orderedAttachmentIds: [Float!]!
  ) {
    setBidLineAttachmentsOrder(
      bidLineId: $bideLineId
      orderedAttachmentIds: $orderedAttachmentIds
    ) {
      bidLineAttachmentId
      bidLineId
      name
      documentType
      documentMetadataId
      signedUrl
      description
      orderNum
    }
  }
`;

export const DELETE_DOCUMENT_FROM_BIDLINE = gql`
  mutation deleteBidLineAttachment($bidLineAttachmentId: Float!) {
    deleteBidLineAttachment(bidLineAttachmentId: $bidLineAttachmentId)
  }
`;

export const GET_BID_LINE_ATTACHMENTS = gql`
  query getBidLineAttachments($bidLineId: Float!) {
    getBidLineAttachments(bidLineId: $bidLineId) {
      bidLineAttachmentId
      bidLineId
      name
      documentType
      documentMetadataId
      signedUrl
      description
      orderNum
    }
  }
`;

export const GET_DOCUMENT_META_RECORD_BY_ID = gql`
  query getDocMetadata($id: String!) {
    getDocMetadata(id: $id) {
      fileLocation
      fileMimeType
      scanStatus
    }
  }
`;

export const GET_SIGNED_READ_URL = gql`
  query GenerateReadSignedURL($fileKey: String!, $fileType: String!) {
    generateReadSignedURL(fileKey: $fileKey, fileType: $fileType)
  }
`;

export const GET_BID_LINES = gql`
  query($bidId: String!) {
    getBidStandardItems(bidId: $bidId) {
      id
      scheduleLine
    }
  }
`;

export const COPY_BID_LINE_DETAILS_TAB = gql`
  mutation copyBidLineDetailsTab($input: CopyBidLineDetailsInput!) {
    copyBidLineDetailsTab(input: $input)
  }
`;

export const SUBMIT_BID_LINE = gql`
  mutation submitBidLine($bidLineId: Float!, $solId: Float!) {
    submitBidLine(bidLineId: $bidLineId, solId: $solId) {
      id
      status
    }
  }
`;

export const SUBMIT_BID_LINES = gql`
  mutation submitBidLines($bidLineIds: [Int!]!, $solId: Float!) {
    submitBidLines(bidLineIds: $bidLineIds, solId: $solId) {
      bidLineId
      error
    }
  }
`;

export const GET_BID_LINE_PREVIOUS_PRICE = gql`
  query getPastContractPriceForSIN(
    $standardItemIds: [Float!]!
    $vendorId: String!
    $modelYear: Float!
    $makeCode: Float
    $modelCode: String
    $chassisMakeCode: Float
    $chassisModelCode: String
  ) {
    getPastContractPriceForSIN(
      standardItemIds: $standardItemIds
      vendorId: $vendorId
      modelYear: $modelYear
      makeCode: $makeCode
      modelCode: $modelCode
      chassisMakeCode: $chassisMakeCode
      chassisModelCode: $chassisModelCode
    )
  }
`;

export const GET_PAST_YEAR_STANDARD_ITEM_IDS = gql`
  query GetPastYearStandardItemIdsBySINs($sin: String!) {
    getPastYearStandardItemIdsBySINs(sin: $sin)
  }
`;

export const UPDATE_BID_LINE_STATUS = gql`
  mutation updateBidLineStatus($bidLineId: Float!, $status: String!) {
    updateBidLineStatus(bidLineId: $bidLineId, status: $status)
  }
`;

export const GET_BID_LINE_HIGHLIGHT = gql`
  query getBidLineHighlights($bidLineId: Int!) {
    getBidLineHighlights(bidLineId: $bidLineId) {
      id
      tabKey
      tableName
      rowId
      fieldName
    }
  }
`;

export const REMOVE_BID_LINE_HIGHLIGHT = gql`
  mutation removeBidLineHighlight($highlightId: Float!) {
    removeBidLineHighlight(highlightId: $highlightId)
  }
`;

export const REMOVE_BID_LINE_HIGHLIGHTS = gql`
  mutation removeBidLineHighlights($bidLineId: Float!) {
    removeBidLineHighlights(bidLineId: $bidLineId)
  }
`;
