import React, { useEffect, useState } from 'react';
import HtmlDiff from 'htmldiff-js';
import ReactHtmlParser from 'react-html-parser';
import { isEmpty } from 'lodash';
import { StatusTag, EmptyState } from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import Truncate from '../../../../components/Truncate';
import StandardItemTitle from '../../components/standard-item-title';
import { RoleIDs } from '../../../../utilities/types/role-types';
import { useFederalStandards } from '../../fvs-provider';
import { useFvsRequirements } from '../requirements-provider';
import '../styles/requirements.scss';

// eslint-disable-next-line react/prop-types
const RequirementInfo = () => {
  const { standardItem, isSILoading } = useFederalStandards();
  const { doHighlightChanges } = useFvsRequirements();

  const [showDiff, setShowDiff] = useState(false);
  const [isNewSI, setIsNewSI] = useState(false);

  const { roles } = useCurrentUser()?.currentUser || {};

  const isVehicleSupplier = () => {
    const roleIDs = roles?.map(({ id }) => id);
    return roleIDs?.includes(RoleIDs.VS || RoleIDs.PSO);
  };

  useEffect(() => {
    setIsNewSI(false);
    if (
      doHighlightChanges &&
      standardItem?.change?.prevVersionDesc &&
      standardItem?.content?.content
    ) {
      setShowDiff(true);
    } else {
      setShowDiff(false);
    }

    if (standardItem && standardItem?.change?.isInserted === 'Yes') {
      setIsNewSI(true);
    }
  }, [standardItem, doHighlightChanges]);

  return (
    <>
      {standardItem && !isEmpty(standardItem?.standardItemNumber) && (
        <>
          {showDiff && (
            <h1>
              <StandardItemTitle
                value={`${standardItem?.standardItemNumber || '-'}  - ${
                  standardItem?.title || '-'
                }`}
                entity={standardItem}
              />
            </h1>
          )}
          {!showDiff && (
            <h1>
              <StandardItemTitle
                value={`${standardItem?.standardItemNumber || '-'} - ${
                  standardItem?.title || '-'
                }`}
                entity={null}
              />
            </h1>
          )}

          {isNewSI && (
            <StatusTag status="active" className="font-body-sm">
              New
            </StatusTag>
          )}

          {standardItem?.content?.content &&
            showDiff &&
            ReactHtmlParser(`<div>
            ${HtmlDiff.execute(
              standardItem?.change?.prevVersionDesc,
              standardItem?.content?.content,
            )}
            </div>`)}

          {standardItem?.content?.content && !showDiff && (
            <Truncate content={standardItem?.content?.content} size={500} />
          )}

          {!standardItem?.content?.content && (
            <div className="text-bold">
              <br />
              &ndash;
            </div>
          )}
          <div className="padding-y-5 width-tablet-lg">
            <div className="text-bold margin-bottom-2 text-primary">
              GSA WANTS YOUR FEEDBACK
            </div>

            <div style={{ border: '1px solid #73B3E7' }} className="">
              <ul>
                <li>
                  Comment on Equipment Codes using the{' '}
                  <span className="text-bold">Requirements tab</span>
                </li>
                {isVehicleSupplier() && (
                  <li>
                    Indicate your interest in bidding and provide Makes and
                    Models using the{' '}
                    <span className="text-bold">Make and Model tab.</span>
                  </li>
                )}
                <li>
                  Comment on this Standard Item using the{' '}
                  <span className="text-bold">Standard Item comments tab</span>
                </li>
              </ul>
            </div>
          </div>
        </>
      )}

      {!isSILoading && isEmpty(standardItem?.standardItemNumber) && (
        <EmptyState
          hasBackground
          containerStyles="padding-y-8 margin-top-1 height-full"
          topText={
            <>
              <strong>
                No Standard items found for the selected criteria.
              </strong>
            </>
          }
          topTextStyles="margin-top-7"
        />
      )}
    </>
  );
};

export default RequirementInfo;
