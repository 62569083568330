import { gql } from '@apollo/client';

// eslint-disable-next-line
export const GET_CONTRACT_LINE_HISTORY = gql`
  query getContractLineHistory($contractLineId: Float!) {
    getContractLineHistory(contractLineId: $contractLineId) {
      id
      versionNumber
      modTag
      contractHeaderVersionId
      contractHeaderVersion {
        versionNumber
      }
      createdBy
      createdByUser {
        fullName
        email
      }
      createdAt
      changes
    }
  }
`;
