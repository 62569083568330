import { gql } from '@apollo/client';

export const SET_CONTRACTLINE_ATTACHMENTS_ORDER = gql`
  mutation setContractLineAttachmentsOrder(
    $contractLineId: Float!
    $orderedAttachmentIds: [Float!]!
  ) {
    setContractLineAttachmentsOrder(
      contractLineId: $contractLineId
      orderedAttachmentIds: $orderedAttachmentIds
    ) {
      id
      contractLineId
      name
      documentType
      documentMetadataId
      signedUrl
      description
      sequenceNumber
    }
  }
`;

export const DELETE_DOCUMENT_FROM_CONTRACTLINE = gql`
  mutation deleteContractLineAttachment($contractLineAttachmentId: Float!) {
    deleteContractLineAttachment(
      contractLineAttachmentId: $contractLineAttachmentId
    )
  }
`;

export const GET_CONTRACTLINE_ATTACHMENTS = gql`
  query getContractLineAttachments($contractLineId: Float!) {
    getContractLineAttachments(contractLineId: $contractLineId) {
      id
      contractLineId
      name
      documentType
      documentMetadataId
      signedUrl
      description
      sequenceNumber

      clAttachmentMetadata {
        fileLocation
        fileMimeType
        scanStatus
      }
    }
  }
`;

export const GET_DOCUMENT_META_RECORD_BY_ID = gql`
  query getDocMetadata($id: String!) {
    getDocMetadata(id: $id) {
      fileLocation
      fileMimeType
      scanStatus
    }
  }
`;

export const GET_SIGNED_READ_URL = gql`
  query GenerateReadSignedURL($fileKey: String!, $fileType: String!) {
    generateReadSignedURL(fileKey: $fileKey, fileType: $fileType)
  }
`;

export const ADD_DOCUMENT_TO_CONTRACTLINE = gql`
  mutation addDocumentToContractLine(
    $addDocumentToContractLine: AddDocumentToContractLineInput!
  ) {
    addDocumentToContractLine(
      addDocumentToContractLine: $addDocumentToContractLine
    ) {
      id
      documentMetadataId
      signedUrl
      description
      sequenceNumber
    }
  }
`;
