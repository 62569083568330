/* eslint-disable import/no-named-as-default */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Button, PageTitle } from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { OPERATIONS, SUBJECTS } from '../../../../utilities/constants';
import MODAL_MODES from '../constants';
import RevealText from '../../components/reveal-text';
import { useFvsLandingPageContext } from '../fvs-landing-page-provider';

function FvsHeader() {
  const {
    setFvsLandingPageData,
    headerCopy,
    getAnnouncementForFvsHeader,
  } = useFvsLandingPageContext();

  const ability = useAppAbility();

  useEffect(() => {
    getAnnouncementForFvsHeader({
      variables: { boardType: 1 },
    });
  }, []);

  return (
    <div className="desktop:grid-col-9 maxw-tablet-lg margin-bottom-2">
      <PageTitle
        aria-label="Federal Vehicle Standards"
        tabIndex="0"
        title="Federal Vehicle Standards"
      />
      <RevealText
        // expanded if PSO/Public
        isExpanded={!ability.can(OPERATIONS.Update, SUBJECTS.FVS)}
        title="About the standards"
        copy={
          <>
            {ReactHtmlParser(headerCopy)}
            {ability.can(OPERATIONS.Approve, SUBJECTS.CatalogCodes) ? (
              <Button
                aria-label="edit announcement modal"
                onClick={() =>
                  setFvsLandingPageData(
                    'SET_MODAL_MODE',
                    MODAL_MODES.HEADER_RTE,
                  )
                }
                className="margin-left-1"
                variant="unstyled"
                data-testid="edit-announcement"
                label="Edit"
                leftIcon={{
                  name: 'edit',
                  className: 'usa-icon--size-2',
                }}
              />
            ) : null}
          </>
        }
      />
    </div>
  );
}

export default FvsHeader;
