import React from 'react';
import BidLineEvaluationProvider from './bid-line-evaluation-provider';
import BidLineEvaluationContent from './bid_line_evaluation-content';

const BidLineEvaluation = () => {
  return (
    <BidLineEvaluationProvider>
      <BidLineEvaluationContent />
    </BidLineEvaluationProvider>
  );
};

export default BidLineEvaluation;
