import React from 'react';
import PropTypes from 'prop-types';
import { EmptyState } from '@gsa/afp-component-library';
import { transformedSolPeriodVendorList } from '../utils/solicitation-period-utils';
import { CUSTOM_SOL_PERIOD_AUDIENCE_DESCRIPTION } from '../constants';

export const content = {
  AUDIENCE: 'AUDIENCE',
  VENDORS: 'VENDORS',
  EMPTY_STATE_MSG: 'There is no audience under this solicitation period.',
  DEFAULT_AUDIENCE: CUSTOM_SOL_PERIOD_AUDIENCE_DESCRIPTION.Custom,
  NULL_AUDIENCE: 'null',
};
export const testIds = {
  eachVendorName: 'each-sol-period-vendor',
  eachAudienceType: 'each-sol-period-audience-type',
};
const SolicitationPeriodsTableSubComponent = ({ row }) => {
  const solPeriodVendorEntriesList = transformedSolPeriodVendorList(
    row?.original,
  );

  return (
    <>
      {!solPeriodVendorEntriesList?.length ? (
        <EmptyState bottomText={content.EMPTY_STATE_MSG} />
      ) : (
        solPeriodVendorEntriesList.map(([eachAudience, vendorsList]) => (
          <section className="margin-left-2">
            <section className="grid-row padding-0 grid-gap-1">
              <p className="grid-col-4 text-heavy line-height-sans-5">
                {content.AUDIENCE}
              </p>
              <p className="grid-col-8 text-heavy line-height-sans-5">
                {content.VENDORS}
              </p>
            </section>

            <section className="grid-row padding-0 border-gray-10 border-bottom-1px padding-y-1 grid-gap-1 margin-bottom-3">
              <p
                className="grid-col-4 line-height-sans-5"
                key={eachAudience}
                data-testid={testIds.eachAudienceType}
              >
                {eachAudience === content.NULL_AUDIENCE
                  ? content.DEFAULT_AUDIENCE
                  : CUSTOM_SOL_PERIOD_AUDIENCE_DESCRIPTION[eachAudience]}
              </p>
              <section className="grid-col-8 maxh-mobile grid-row flex-column flex-wrap overflow-auto grid-gap-1 padding-bottom-5 margin-bottom-3">
                {vendorsList?.map((eachVendor) => (
                  <p
                    className="text-uppercase border-gray-10 border-bottom-1px grid-col-4 margin-0 padding-y-1 margin-right-4 line-height-sans-5"
                    key={eachVendor?.id}
                    data-testid={testIds.eachVendorName}
                  >
                    {eachVendor?.vendorDetail?.vendorName}
                  </p>
                ))}
              </section>
            </section>
          </section>
        ))
      )}
    </>
  );
};

SolicitationPeriodsTableSubComponent.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

export default SolicitationPeriodsTableSubComponent;
