export const REPORTS_ALL = {
  'Bid input reports': [
    'bidDetails',
    'bidPeriodsComparison',
    'clarifications',
    'engineAndFuels',
    'engineAndFuelsDelivery',
    'makeAndModel',
    'optionConflicts',
    'technicalExceptions',
  ],
  'Bid line items reports': [
    'allLineItems',
    'bidsNotSubmitted',
    'submittedLineItems',
  ],
  'Price analysis reports': [
    'bidPriceAnalysis',
    'colorPriceAnalysis',
    'optionalEquipmentPriceAnalysis',
  ],
  'Federal standards reports': [
    'minimumAndOptionalComparison',
    'vehicleSupplierDataComparison',
  ],
};

export const REPORTS_VEHICLE_SUPPLIER = {
  'Bid input reports': ['bidPeriodsComparison'],
  'Bid line items reports': [
    'allLineItems',
    'bidsNotSubmitted',
    'submittedLineItems',
  ],
  'Federal standards reports': ['minimumAndOptionalComparison'],
};

export const getReportTypes = (reports) => Object.keys(reports);

export const FT_CSV = 'CSV';
export const FT_XLSX = 'XLSX';
export const FT_PDF = 'PDF';
export const REPORT_CONFIG = {
  // Bid input reports
  bidDetails: {
    reportTypeId: 505,
    label: 'Bid details report',
    fileTypes: [FT_XLSX],
    description:
      'Report of line item details to include shipping time, allocation, 1122 participation, and percent of domestic content by assembly point.',
  },
  bidPeriodsComparison: {
    reportTypeId: 508,
    label: 'Bid periods comparison report',
    fileTypes: [FT_XLSX, FT_PDF],
    vehicleSupplierSS: true,
    standardItemSS: true,
    showMakeModels: true,
    description:
      'Comparison report of bid inputs from a vehicle supplier for all bid periods within solicitation.',
  },
  clarifications: {
    reportTypeId: 501,
    label: 'Clarifications report',
    fileTypes: [FT_XLSX],
    description:
      'Report of clarifiations entered for a line item with the associated clarification type.',
  },
  engineAndFuels: {
    reportTypeId: 504,
    label: 'Engine and fuels report',
    fileTypes: [FT_XLSX],
    description: 'Report of engine and fuel related data.',
  },
  engineAndFuelsDelivery: {
    reportTypeId: 514,
    label: 'Engine and fuels delivery report',
    fileTypes: [FT_XLSX],
    description: 'Report of engine and fuel delivery data.',
  },
  makeAndModel: {
    reportTypeId: 515,
    label: 'Make and model report',
    fileTypes: [FT_XLSX],
    hideVehicleSupplier: true,
    description:
      'Report of make and model combinations added to a solicitation for a contract year.',
  },
  optionConflicts: {
    reportTypeId: 502,
    label: 'Option conflicts report',
    fileTypes: [FT_XLSX],
    description:
      'Report of optional equipment code conflicts entered for a line item with associated conflict type.',
  },
  technicalExceptions: {
    reportTypeId: 503,
    label: 'Technical exceptions report',
    fileTypes: [FT_XLSX, FT_PDF],
    description:
      'Report of technical exceptions entered for a line item with the associated exception type.',
  },

  // Bid line items reports
  allLineItems: {
    reportTypeId: 516,
    label: 'All line items report',
    fileTypes: [FT_PDF],
    description: 'Report of bid input for all line items.',
  },
  bidsNotSubmitted: {
    reportTypeId: 507,
    label: 'Bids not submitted report',
    fileTypes: [FT_XLSX],
    description: 'Report of line items that are not in submitted status.',
  },
  submittedLineItems: {
    reportTypeId: 506,
    label: 'Submitted line items report',
    fileTypes: [FT_PDF],
    description:
      'Report of bid input for line items that are in submitted status.',
  },

  // Price analysis reports
  bidPriceAnalysis: {
    reportTypeId: 510,
    label: 'Bid price analysis report',
    fileTypes: [FT_XLSX],
    description:
      'Report of current bid period pricing compared to both previous bid period pricing and the latest contract pricing.',
  },
  colorPriceAnalysis: {
    reportTypeId: 509,
    label: 'Color price analysis report',
    fileTypes: [FT_XLSX],
    description:
      'Report of colors with associated color type that captures trends in pricing and color type.',
  },
  optionalEquipmentPriceAnalysis: {
    reportTypeId: 511,
    label: 'Optional equipment price analysis report',
    fileTypes: [FT_XLSX],
    description:
      'Report of current bid period pricing for optional equipment compared to both previous bid period pricing and the latest contract pricing.',
  },

  // Federal standards reports
  minimumAndOptionalComparison: {
    reportTypeId: 512,
    label: 'Minimum and optional comparison report',
    fileTypes: [FT_XLSX, FT_PDF],
    contractYearsMS: true,
    hideSolicitation: true,
    hideVehicleSupplier: true,
    sinSS: true,
    description: 'Comparison report of federal standards between years.',
  },
  vehicleSupplierDataComparison: {
    reportTypeId: 513,
    label: 'Vehicle supplier data comparison report',
    fileTypes: [FT_XLSX, FT_PDF],
    description:
      'Report of GSA minimums , description, and input type for all equipment codes with vehicle supplier responses side by side (Grouped by Standard item, then by Equipment category); changes are highlighted.',
  },
};

export const API_TIME_LIMIT = 30000; // ms (30 seconds)
