import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { PageTitle, Button } from '@gsa/afp-component-library';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import BidSearchSection from './bid-search-section';
import BidEvaluationTable from './bid-evaluation-table';
import {
  SEARCH_OPTIONS,
  GET_BIDS_FOR_EVALUATION,
  parseSortValue,
  getKeyLabel,
} from './bid-search-helpers';

const BidSearch = () => {
  const history = useHistory();

  const [searchKey, setSearchKey] = useState(SEARCH_OPTIONS[0].value);
  const [searchValue, setSearchValue] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [bids, setBids] = useState([]);
  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState([]);

  const genSearchResult = (cnt) => {
    const key = getKeyLabel(searchKey, true);
    return `${cnt ? 'Search' : 'No'} Results for ${key} "${searchValue}"`;
  };

  const [getProposals, { loading }] = useLazyQuery(GET_BIDS_FOR_EVALUATION, {
    onCompleted: ({ getBidsForEvaluation: res }) => {
      setCount(res.count);
      setBids(res.rows);
      setSearchResult(genSearchResult(res.count));
    },
  });

  const onSearch = () => {
    if (searchValue) {
      setOffset(0); // reset to page 1
      getProposals({
        variables: {
          criteria: { [searchKey]: searchValue },
          order,
          limit,
          offset: 0,
        },
      });
    }
  };

  const onPageChange = (currentPage, itemsPerPage) => {
    let newOffset;
    if (itemsPerPage === limit) {
      newOffset = (currentPage - 1) * itemsPerPage;
    } else {
      newOffset = 0; // reset to page 1
      setLimit(itemsPerPage);
    }
    setOffset(newOffset);
    getProposals({
      variables: {
        criteria: { [searchKey]: searchValue },
        order,
        limit: itemsPerPage,
        offset: newOffset,
      },
    });
  };

  const onSort = (val) => {
    const newOrder = parseSortValue(val);
    setOrder(newOrder);
    getProposals({
      variables: {
        criteria: { [searchKey]: searchValue },
        order: newOrder,
        limit,
        offset,
      },
    });
  };

  return (
    <div className="margin-bottom-8">
      <Breadcrumbs
        current="Proposal Evaluation"
        path={[
          {
            location: `${window.AFP_CONFIG.appURLs?.home}/home`,
            label: 'Home',
          },
        ]}
      />
      <div className="grid-row margin-bottom-2">
        <div className="grid-col-10">
          <PageTitle
            title="Proposal Evaluation"
            aria-label="Proposal Evaluation"
            className="margin-bottom-0"
          />
          <div>
            Search by solicitation or vendor, to see a list of line items
            available for evaluation.
          </div>
        </div>
        <div className="grid-col-2 text-right padding-top-4">
          <Button
            type="button"
            variant="outline"
            onClick={() => history.push('/catalog/bid-reports')}
            label="Bid reports"
          />
        </div>
      </div>
      <BidSearchSection
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        onSearch={onSearch}
      />
      {searchResult && (
        <div className="text-bold margin-top-05">{searchResult}</div>
      )}
      <BidEvaluationTable
        loading={loading}
        data={bids}
        count={count}
        offset={offset}
        limit={limit}
        onPageChange={onPageChange}
        onSort={onSort}
      />
    </div>
  );
};

export default BidSearch;
