import React, { createContext, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { useHistory, useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_MULTIPLE_OPTIONS, GET_OPTIONS } from '../../services/data-store';
import {
  GET_CATALOG_CODES,
  ADD_CATALOG_CODE,
  DELETE_CATALOG_CODE,
  UPDATE_CATALOG_CODE,
  CHANGE_APPROVAL_STATUS,
  GET_CHANGE_REQUEST_HISTORY,
  GET_CODES_METADATA,
} from './catalog-codes.gql';
import groupMultipleOptions from '../../utilities/options-helper';
import { OPERATIONS, SUBJECTS } from '../../utilities/constants';

export const CatalogCodesContext = createContext();

export const CATALOG_CODES_MODAL_MODES = {
  ADD: 'add',
  EDIT: 'edit',
  CONFIRM_DELETE: 'confirm_delete',
};

const initialState = {
  catalogCodesMessages: [],
  metadataList: [],
  selectedCategoryName: 'Vehicle Group',
  selectedCatalogCode: null,
  metadataOfSelectedCategory: {},
  filteredCodes: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  catalogCodesOptions: {},
  catalogCodesModalMode: null,
  catalogCodeShowModal: false,
  catalogCodesChangeRequestHistory: [],
  directRouteParams: {
    code: null,
    standardCodeMetadataId: null,
  },
  typeaheadData: null,
};

const reducer = (state, action) => {
  switch (action?.type) {
    case 'SET_CODES_METADATA': {
      let newState = { ...state, metadataList: action.payload };
      if (state.selectedCategoryName) {
        const filtered = action.payload.find(
          (m) => m.category === state.selectedCategoryName,
        );
        if (filtered) {
          newState = {
            ...state,
            metadataList: action.payload,
            metadataOfSelectedCategory: filtered,
          };
        }
      }
      return newState;
    }
    case 'SET_SELECTED_CATEGORY_NAME': {
      let newState = { ...state, selectedCategoryName: action.payload };
      if (state.metadataList && state.metadataList.length > 0) {
        const filtered = state.metadataList.find(
          (m) => m.category === action.payload,
        );

        if (filtered) {
          newState = {
            ...state,
            selectedCategoryName: action.payload,
            metadataOfSelectedCategory: filtered,
          };
        }
      }
      return newState;
    }
    case 'SET_CATALOG_CODES_LIST': {
      return { ...state, filteredCodes: action.payload };
    }
    case 'SET_CATALOG_CODES_OPTIONS': {
      return { ...state, catalogCodesOptions: action.payload };
    }
    case 'SET_SELECTED_CATALOG_CODE': {
      return { ...state, selectedCatalogCode: action.payload };
    }
    case 'SET_CATALOG_CODES_CHANGE_REQUEST_HISTORY': {
      return { ...state, catalogCodesChangeRequestHistory: action.payload };
    }
    case 'SET_PARAMS_FROM_ROUTE': {
      return { ...state, directRouteParams: action.payload };
    }
    case 'SET_CATALOG_CODE_MESSAGES': {
      return { ...state, catalogCodesMessages: action.payload };
    }
    case 'SET_MODAL_MODE': {
      let showForm = false;
      if (action.payload && action.payload !== '') {
        showForm = true;
      }
      return {
        ...state,
        catalogCodesModalMode: action.payload,
        catalogCodeShowModal: showForm,
      };
    }
    case 'SET_TYPEAHEAD_FILTER_DATA':
      return { ...state, typeaheadData: action.payload };
    case 'SET_ERROR': {
      return { ...state, catalogCodesErrors: action.payload };
    }
    default: {
      throw new Error('Invalid action');
    }
  }
};

function CatalogCodesProvider({ children, ...props }) {
  const ability = useAppAbility();
  const history = useHistory();
  const location = useLocation();

  // The state is initialized uniformly for loading the codes table page and codes detail page
  // Intent is to avoid reinitializing the state when user navigates from table to content and comes back to table page
  // Also this should support users directly navigating to detail page with a URL.
  // The init could have been passed by the initializing component but that would also result in reinitializing the state for all cases.
  const [state, dispatch] = useReducer(reducer, initialState);

  const setCatalogCodesError = (type, payload) => {
    dispatch({
      type: 'SET_ERROR',
      payload: { ...state.catalogCodesErrors, [type]: payload },
    });
  };

  const setCatalogCodesData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const addOrReplaceMessageById = (message) => {
    const existingMessages = [...state.catalogCodesMessages];
    const splicedArray = existingMessages.filter((m) => m.id !== message.id);
    setCatalogCodesData('SET_CATALOG_CODE_MESSAGES', [
      ...splicedArray,
      message,
    ]);
  };

  const removeMessageById = (id) => {
    const existingMessages = [...state.catalogCodesMessages];
    const splicedArray = existingMessages.filter((m) => m.id !== id);
    setCatalogCodesData('SET_CATALOG_CODE_MESSAGES', [...splicedArray]);
  };

  const setCatalogCodeMessagesOnDetailPage = () => {
    if (state.selectedCatalogCode?.requestStatus === 'Pending-edit') {
      addOrReplaceMessageById({
        message: ability.can(OPERATIONS.Approve, SUBJECTS.CatalogCodes)
          ? 'This edited Catalog Code is pending your review. Please approve, reject or further edit the code.'
          : 'This edited Catalog Code is pending approval.',
        id: 'APPROVAL_STATUS',
        type: 'info',
        showInModal: false,
      });
    }

    if (state.selectedCatalogCode?.requestStatus === 'Pending-new') {
      addOrReplaceMessageById({
        message: ability.can(OPERATIONS.Approve, SUBJECTS.CatalogCodes)
          ? 'This new Catalog Code is pending your review. Please approve, reject or further edit the code.'
          : 'This new Catalog Code is pending approval.',
        id: 'APPROVAL_STATUS',
        type: 'info',
        showInModal: false,
      });
    }
  };

  const clearModalMessages = () => {
    const otherMessages = state.catalogCodesMessages.filter(
      (m) => m.showInModal !== true,
    );
    setCatalogCodesData('SET_CATALOG_CODE_MESSAGES', [...otherMessages]);
  };

  const [getCodesMetadata] = useLazyQuery(GET_CODES_METADATA, {
    onError: (requestError) => {
      setCatalogCodesError(
        'GET_CODES_METADATA',
        requestError.message ?? 'Unknown Error.',
      );
    },
    onCompleted: (responseData) => {
      if (responseData?.getCatalogCodesMetadata) {
        setCatalogCodesData(
          'SET_CODES_METADATA',
          responseData.getCatalogCodesMetadata.rows,
        );
        setCatalogCodesError('GET_CODES_METADATA', '');
      }
    },
  });

  // Get Catalog codes lazy.
  const [getCatalogCodes, { refetch: refetchCatalogCodes }] = useLazyQuery(
    GET_CATALOG_CODES,
    {
      fetchPolicy: 'network-only',
      onError: (requestError) => {
        setCatalogCodesError(
          'GET_CATALOG_CODES_LIST',
          requestError.message ?? 'Unknown Error.',
        );
      },
      onCompleted: (responseData) => {
        if (responseData?.getStandardCodes) {
          setCatalogCodesData(
            'SET_CATALOG_CODES_LIST',
            responseData.getStandardCodes,
          );
          setCatalogCodesError('GET_CATALOG_CODES_LIST', '');
        }
      },
    },
  );

  // Get Catalog codes lazy.
  const [getCatalogCode] = useLazyQuery(GET_CATALOG_CODES, {
    fetchPolicy: 'network-only',
    onError: (requestError) => {
      setCatalogCodesError(
        'GET_SELECTED_CATALOG_CODE',
        requestError.message ?? 'Unknown Error.',
      );
    },
    onCompleted: (responseData) => {
      if (responseData?.getStandardCodes) {
        setCatalogCodesData(
          'SET_SELECTED_CATALOG_CODE',
          responseData.getStandardCodes?.rows[0],
        );
        setCatalogCodesError('GET_SELECTED_CATALOG_CODE', '');
        if (
          !state.selectedCategoryName ||
          state.selectedCategoryName !==
            responseData.getStandardCodes?.rows[0]?.metadata?.category
        ) {
          setCatalogCodesData(
            'SET_SELECTED_CATEGORY_NAME',
            responseData.getStandardCodes?.rows[0]?.metadata?.category,
          );
        }
      }
    },
  });

  // Get Multiple Options
  const [getCatalogCodesOptions] = useLazyQuery(GET_MULTIPLE_OPTIONS, {
    fetchPolicy: 'network-only',
    onError: (requestError) => {
      setCatalogCodesError(
        'GET_CATALOG_CODES_OPTIONS',
        requestError.message ?? 'Unknown Error.',
      );
    },
    onCompleted: (multipleOptionsData) => {
      if (multipleOptionsData?.getMultipleOptions) {
        setCatalogCodesData(
          'SET_CATALOG_CODES_OPTIONS',
          groupMultipleOptions(multipleOptionsData?.getMultipleOptions),
        );
        setCatalogCodesError('GET_CATALOG_CODES_OPTIONS', '');
      }
    },
  });

  const [getCatalogCodeChangeRequestHistory] = useLazyQuery(
    GET_CHANGE_REQUEST_HISTORY,
    {
      fetchPolicy: 'network-only',
      onError: (requestError) => {
        setCatalogCodesError(
          'SET_CATALOG_CODES_CHANGE_REQUEST_HISTORY',
          requestError.message ?? 'Unknown Error.',
        );
      },
      onCompleted: (changeRequestHistoryData) => {
        if (changeRequestHistoryData?.getStandardCodesChangeRequest) {
          setCatalogCodesData(
            'SET_CATALOG_CODES_CHANGE_REQUEST_HISTORY',
            changeRequestHistoryData?.getStandardCodesChangeRequest?.rows,
          );
          setCatalogCodesError('SET_CATALOG_CODES_CHANGE_REQUEST_HISTORY', '');
        }
      },
    },
  );

  const fetchCode = (id) => {
    const filters = {
      operator: 'AND',
      value: [
        {
          operator: 'EQ',
          key: 'id',
          value: id,
        },
      ],
    };
    getCatalogCode({
      variables: { filters },
    });
  };

  const fetchHistory = (id, code) => {
    const filters = {
      operator: 'AND',
      value: [
        {
          operator: 'EQ',
          key: 'standardsCodeId',
          value: id,
        },
        {
          operator: 'EQ',
          key: 'code',
          value: code,
        },
      ],
    };

    getCatalogCodeChangeRequestHistory({
      variables: {
        filters,
        limit: 50,
        orderBy: 'createdAt DESC',
      },
    });
  };

  // add catalog codes
  const [createCatalogCode, { loading: ccAddInProgress }] = useMutation(
    ADD_CATALOG_CODE,
    {
      onError: (requestError) => {
        addOrReplaceMessageById({
          message: requestError.message,
          id: 'NEW_CODE',
          type: 'error',
          showInModal: true,
        });
      },
      onCompleted: (catalogCodeResponse) => {
        setCatalogCodesData('SET_MODAL_MODE', undefined);
        setCatalogCodesError('CREATE_CATALOG_CODE', '');
        setCatalogCodesData(
          'SET_SELECTED_CATALOG_CODE',
          catalogCodeResponse.addCatalogCode,
        );
        fetchHistory(
          catalogCodeResponse.addCatalogCode?.id,
          catalogCodeResponse.addCatalogCode?.code,
        );
        addOrReplaceMessageById({
          message: `You have successfully added Code ${catalogCodeResponse?.addCatalogCode?.code}`,
          id: 'NEW_CODE',
          type: 'success',
          showInModal: false,
        });
        history.push(
          `/catalog/codes/${state.metadataOfSelectedCategory.id}/${catalogCodeResponse.addCatalogCode.code}`,
        );
      },
    },
  );

  // update catalog code
  const [updateCatalogCode, { loading: ccUpdateInProgress }] = useMutation(
    UPDATE_CATALOG_CODE,
    {
      onError: (requestError) => {
        addOrReplaceMessageById({
          message: requestError.message,
          id: 'UPDATE_CODE',
          type: 'error',
          showInModal: true,
        });
      },
      onCompleted: (catalogCodeResponse) => {
        setCatalogCodesData('SET_MODAL_MODE', undefined);
        setCatalogCodesError('UPDATE_CATALOG_CODE', '');

        if (state.metadataOfSelectedCategory.requiresApproval) {
          addOrReplaceMessageById({
            message: `Edited Catalog Code ${catalogCodeResponse?.updateCatalogCode?.code} is pending approval.`,
            id: 'UPDATE_CODE',
            type: 'success',
            showInModal: false,
          });
        } else {
          addOrReplaceMessageById({
            message: `You have successfully edited Code ${catalogCodeResponse?.updateCatalogCode?.code}`,
            id: 'UPDATE_CODE',
            type: 'success',
            showInModal: false,
          });
        }

        if (location.pathname.endsWith('catalog/codes')) {
          refetchCatalogCodes();
        } else {
          fetchCode(catalogCodeResponse.updateCatalogCode?.id);
          fetchHistory(
            catalogCodeResponse.updateCatalogCode?.category,
            catalogCodeResponse.updateCatalogCode?.code,
          );
        }
      },
    },
  );

  const [updateApprovalStatus] = useMutation(CHANGE_APPROVAL_STATUS, {
    onError: (requestError) => {
      setCatalogCodesError(
        'SET_ERROR_MESSAGE',
        requestError.message ?? 'Unknown Error.',
      );
    },
    onCompleted: (approvalResponseData) => {
      const catalogCode = approvalResponseData?.changeApprovalStatus;
      const message = `You have ${catalogCode.approvalStatus.toLowerCase()} the ${catalogCode.changeType.toLowerCase()} request for Code ${
        catalogCode?.code
      } - ${catalogCode?.title}`;

      fetchCode(state.selectedCatalogCode.id);
      fetchHistory(
        state.selectedCatalogCode.category,
        state.selectedCatalogCode.code,
      );
      setCatalogCodeMessagesOnDetailPage();
      addOrReplaceMessageById({
        message,
        id: 'APPROVAL_STATUS',
        type: 'success',
        showInModal: false,
      });
    },
  });

  // delete catalog code
  const [deleteCatalogCode] = useMutation(DELETE_CATALOG_CODE, {
    onError: (requestError) => {
      addOrReplaceMessageById({
        message: requestError.message ?? 'Unknown Error.',
        id: 'DELETE_CODE',
        type: 'error',
        showInModal: true,
      });
    },
    onCompleted: async (catalogCodeResponse) => {
      setCatalogCodesData('SET_MODAL_MODE', null);
      setCatalogCodesData('SET_SELECTED_CATALOG_CODE', null);

      if (location.pathname.endsWith('catalog/codes')) {
        refetchCatalogCodes();
      } else {
        setCatalogCodesData('SET_CATALOG_CODE_MESSAGES', []);
        fetchCode(state.selectedCatalogCode.id);
        fetchHistory(
          state.selectedCatalogCode.category,
          state.selectedCatalogCode.code,
        );
      }

      addOrReplaceMessageById({
        message: `You have successfully deleted Code ${catalogCodeResponse?.deleteCatalogCode?.code}`,
        id: 'DELETE_CODE',
        type: 'success',
        showInModal: false,
      });
    },
  });

  // TypeAhead filter.
  const [getTypeAheadOptions] = useLazyQuery(GET_OPTIONS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getOptions }) => {
      if (getOptions) {
        const formattedOptions = getOptions.map(({ value }) => value);
        setCatalogCodesData('SET_TYPEAHEAD_FILTER_DATA', {
          field: 'code',
          values: formattedOptions,
        });
      }
    },
  });

  // Load metadata list on init
  useEffect(() => {
    getCodesMetadata({
      variables: {
        offset: 0,
        limit: 100,
        order: 'id ASC',
        filters: {
          operator: 'EQ',
          key: 'allowNewEntries',
          value: true,
        },
      },
    });
  }, []);

  // Load parent and tag codes based on the metadata of selected category / code
  useEffect(() => {
    const optionsForParentAndTag = [];
    if (
      state.metadataOfSelectedCategory &&
      state.metadataOfSelectedCategory.parentCategory
    ) {
      const selectedCategoryMetadata = state.metadataList.find(
        (m) => m.category === state.metadataOfSelectedCategory.parentCategory,
      );

      optionsForParentAndTag.push({
        model: 'StandardsCodeModel',
        label: 'title',
        value: 'code',
        filter: {
          operator: 'EQ',
          key: 'standardCodeMetadataId',
          value: selectedCategoryMetadata?.id,
        },
        uniqueKey: 'parentCodes',
        includeValueInLabel: true,
      });
    }

    if (
      state.metadataOfSelectedCategory &&
      state.metadataOfSelectedCategory.categoryOfTags
    ) {
      const selectedCategoryMetadata = state.metadataList.find(
        (m) => m.category === state.metadataOfSelectedCategory.categoryOfTags,
      );

      optionsForParentAndTag.push({
        model: 'StandardsCodeModel',
        label: 'title',
        value: 'code',
        filter: {
          operator: 'EQ',
          key: 'standardCodeMetadataId',
          value: selectedCategoryMetadata?.id,
        },
        uniqueKey: 'tagCodes',
        includeValueInLabel: false,
      });
    }

    if (optionsForParentAndTag.length > 0) {
      getCatalogCodesOptions({
        variables: {
          options: optionsForParentAndTag,
        },
      });
    }
  }, [state.metadataOfSelectedCategory]);

  return (
    <CatalogCodesContext.Provider
      value={{
        ...state,
        getCatalogCodes,
        setCatalogCodesData,
        createCatalogCode,
        updateCatalogCode,
        deleteCatalogCode,
        getCatalogCode,
        getCatalogCodeChangeRequestHistory,
        updateApprovalStatus,
        setCatalogCodeMessagesOnDetailPage,
        clearModalMessages,
        removeMessageById,
        ccAddInProgress,
        getTypeAheadOptions,
        ccUpdateInProgress,
        ...props,
      }}
    >
      {children}
    </CatalogCodesContext.Provider>
  );
}

export default CatalogCodesProvider;

CatalogCodesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useCatalogCodes = () => useContext(CatalogCodesContext);
