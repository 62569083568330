import React, { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { PageTitle } from '@gsa/afp-component-library';
import RevealText from '../../components/reveal-text';
import { useFvsLandingPageContext } from '../fvs-landing-page-provider';

function FvsHeader() {
  const {
    headerCopy,
    getAnnouncementForFvsHeader,
  } = useFvsLandingPageContext();

  useEffect(() => {
    getAnnouncementForFvsHeader({
      variables: { boardType: 1 },
    });
  }, []);

  return (
    <div className="desktop:grid-col-9 maxw-tablet-lg margin-bottom-2">
      <PageTitle
        aria-label="Federal Vehicle Standards"
        tabIndex="0"
        title="Federal Vehicle Standards"
      />
      <RevealText
        isExpanded
        title="About the standards"
        copy={<>{ReactHtmlParser(headerCopy)}</>}
      />
    </div>
  );
}

export default FvsHeader;
