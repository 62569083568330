import { gql } from '@apollo/client';

export const GET_MULTIPLE_OPTIONS_FOR_BID = gql`
  query GetMultipleOptions($options: [OptionType!]!) {
    getMultipleOptions(options: $options) {
      id
      label
      value
      type
      uniqueKey
      currentYear
      parent
    }
  }
`;

export const GET_BIDS_OPEN_SOLICIATIONS_FOR_VENDOR = gql`
  query getOpenSolicitationsForVendor(
    $vendorId: String!
    $limit: Float
    $offset: Float
    $order: OrderBy
    $filters: [Filter!]!
  ) {
    getOpenSolicitationsForVendor(
      vendorId: $vendorId
      limit: $limit
      offset: $offset
      order: $order
      filters: $filters
    ) {
      rows {
        solicitationId: id
        solicitationNumber
        title
        contractYear
        purchaseTypeCode
        solicitationType
        status
        samUrl
        programs {
          id
          program
          programInfo {
            code
            title
          }
          solicitationLines {
            standardItemId
            preBidSelection {
              id
              standardItemId
              preBidMakeModels {
                id
              }
            }
          }
        }
        solicitationPeriods {
          id
          startDate
          endDate
          audience
          periodType
        }
      }
    }
  }
`;

export const GET_SOLICITATIONS = gql`
  query getSolicitations(
    $limit: Float
    $offset: Float
    $order: OrderBy
    $filters: [Filter!]!
  ) {
    getSolicitations(
      limit: $limit
      offset: $offset
      order: $order
      filters: $filters
    ) {
      rows {
        id
        solicitationNumber
        title
        contractYear
        purchaseTypeCode
        solicitationType
        status
      }
    }
  }
`;

export const GET_REPORT_OPTIONS = gql`
  query getReportOptionsBySolId(
    $solicitationId: Float!
    $includeMakeModel: Boolean
  ) {
    getReportOptionsBySolId(
      solicitationId: $solicitationId
      includeMakeModel: $includeMakeModel
    )
  }
`;

export const GET_ACTIVE_BIDS_FOR_VENDOR = gql`
  query GetActiveBidForVendor($vendorId: String!) {
    getActiveBidsForVendor(vendorId: $vendorId) {
      id
      vendorId
      solicitationId
      status
      showReopenLink
      BidLines {
        id
        status
        scheduleLine
      }
      solicitationPeriod {
        closeInDays
        startDate
        endDate
      }
      SolicitaionInfo {
        solicitationNumber
        title
        contractYear
        samUrl
        solicitationPeriods {
          startDate
          endDate
          closeInDays
        }
      }
    }
  }
`;

export const USER_WITH_SCOP_REFERENCE = gql`
  query GetUserWithScopRefById($id: String!) {
    getUserById(id: $id) {
      id
      userRoleList {
        id
        role {
          name
        }
        scope {
          id
          name: prefixedName
          scopeReference
        }
      }
    }
  }
`;

export const VENDORS_BY_FLEET_VENDOR_NUMBERS = gql`
  query GetVendorsByFVNs($fleetVendorNumbers: [String!]!) {
    getVendorsByFleetVendorNumbers(fleetVendorNumbers: $fleetVendorNumbers) {
      id
      vendorName
      vendorShortName
      fleetVendorNumber
      samStatus
      fleetStatus
      uniqueEntityIdentifier
    }
  }
`;

export const GET_BID_STANDARD_ITEMS = gql`
  query($bidId: String!) {
    getBidStandardItems(bidId: $bidId) {
      id
      bidId
      bid {
        id
        vendorId
        status
      }
      status
      submittedAt
      scheduleLine
      makeCode
      modelCode
      chassisMakeCode
      chassisModelCode
      modelYear
      standardItem {
        id
        standardItemNumber
        title
        vehicleGroup {
          code
          title
        }
        vehicleTypeCode {
          code
          title
        }
        tags
      }
      afpMake {
        makeCode
        makeName
        approvalStatus
        fleetStatus
      }
      afpModel {
        modelCode
        makeCode
        modelName
        approvalStatus
        fleetStatus
      }
      chassisMake {
        makeCode
        makeName
        approvalStatus
        fleetStatus
      }
      chassisModel {
        modelCode
        makeCode
        modelName
        approvalStatus
        fleetStatus
      }
      makeModelApproval {
        fleetApprovalStatus
      }
      solicitationLine {
        id
        standardItem {
          id
          standardItemNumber
          title
          vehicleGroup {
            code
            title
          }
          vehicleTypeCode {
            code
            title
          }
        }
        solicitationProgram {
          id
          solicitationId
          programInfo {
            id
            code
            title
          }
        }
      }
    }
  }
`;

export const CREATE_BID_LINE = gql`
  mutation createBidLine($bidLineInput: BidLineInput!) {
    createBidLine(bidLineInput: $bidLineInput) {
      id
      bidId
      standardItem {
        standardItemNumber
      }
    }
  }
`;

export const CREATE_BID_LINE_ITEM = gql`
  mutation createBidLineItem($createBidLineInput: CreateBidLineInput!) {
    createBidLineItem(createBidLineInput: $createBidLineInput) {
      id
      bidId
    }
  }
`;

export const REMOVE_BID_STANDARD_ITEM = gql`
  mutation removeBidStandardItem($bidId: Float!, $standardItemId: Float!) {
    removeBidStandardItem(bidId: $bidId, standardItemId: $standardItemId)
  }
`;

export const UPDATE_BID_LINE_ITEM = gql`
  mutation updateBidLineItem(
    $bidLineId: Float!
    $updateBidLineInput: CreateBidLineInput!
  ) {
    updateBidLineItem(
      bidLineId: $bidLineId
      updateBidLineInput: $updateBidLineInput
    ) {
      id
      bidId
      scheduleLine
      makeCode
      afpMake {
        makeName
      }
      modelCode
      afpModel {
        modelName
      }
      chassisMakeCode
      chassisMake {
        makeName
      }
      chassisModelCode
      chassisModel {
        modelName
      }
      modelYear
    }
  }
`;

export const REMOVE_BID_LINE = gql`
  mutation removeBidLine($id: Float!) {
    removeBidLine(id: $id)
  }
`;

export const START_BID = gql`
  mutation startBid(
    $solicitationId: Float!
    $vendorId: String!
    $contractYear: Float!
  ) {
    startBid(
      solicitationId: $solicitationId
      contractYear: $contractYear
      vendorId: $vendorId
    ) {
      id
    }
  }
`;

export const REOPEN_BID = gql`
  mutation reopenBid($solicitationId: Float!, $vendorId: String!) {
    reopenBid(solicitationId: $solicitationId, vendorId: $vendorId) {
      id
    }
  }
`;

export const GET_BID_LINES = gql`
  query GetBidLines($bidId: Int!) {
    getBidLines(bidId: $bidId) {
      id
      bidId
      solicitationLineId
      standardItem {
        id
        vehicleGroup {
          code
          title
        }
      }
      scheduleLine
    }
  }
`;

export const GET_ENGINEER_DETAILS = gql`
  query GetStandardsVehicleGroupPocsByStdItemId($standardItemId: Float!) {
    getStandardsVehicleGroupPocsByStdItemId(standardItemId: $standardItemId) {
      name
      email
    }
  }
`;

export const REQUEST_NEW_MAKE_MODEL_APPROVAL = gql`
  mutation newMakeModelApproval(
    $makeModelApprovalRequestInput: MakeModelApprovalRequestInput!
  ) {
    requestNewMakeModelApproval(
      makeModelApprovalRequestInput: $makeModelApprovalRequestInput
    ) {
      makeCode
      modelCode
      chassisMakeCode
      chassisModelCode
      fleetApprovalStatus
    }
  }
`;

export const GET_BID_ELEMENTS_CHANGE_HISTORY = gql`
  query getBidElementsChangeHist($bidId: Float!) {
    getBidElementsChangeWithHistory(bidId: $bidId) {
      id
      changeHistories {
        id
        standardItem {
          standardItemNumber
          title
          vehicleTypeCode {
            title
          }
        }
        equipmentCode {
          code
          title
        }
        changeType
        oldValue
        newValue
        createdAt
      }
      createdAt
    }
  }
`;

export const IS_UNSEEN_BID_CHANGE_EXIST = gql`
  query isUnseenBidElementChange($bidId: Float!) {
    isUnseenBidElementsChangesExist(bidId: $bidId)
  }
`;

export const GET_CLOSED_BIDS_BY_VENDOR_ID = gql`
  query getClosedBidsByVendorId($vendorId: String!) {
    getClosedBidsByVendorId(vendorId: $vendorId) {
      id
      vendorId
      solicitationId
      soliciation {
        id
        title
        solicitationNumber
        contractYear
      }
      solicitationPeriod {
        id
        solicitationId
        startDate
        endDate
        periodType
      }
      BidLines {
        afpMake {
          makeCode
          makeName
        }
        afpModel {
          modelCode
          modelName
        }
        chassisMake {
          makeCode
          makeName
        }
        chassisModel {
          modelCode
          modelName
        }
        standardItem {
          id
          standardItemNumber
        }
      }
    }
  }
`;

export const GET_TABS_STATUSES = gql`
  query getBidLineTabsStatuses($bidLineId: Float!) {
    getBidLineTabsStatuses(bidLineId: $bidLineId) {
      key
      value
    }
  }
`;
