import React from 'react';
import PropTypes from 'prop-types';
import { useStandards } from './standards-provider';
import GenericMessagesToast from '../../components/Toast/generic-messages-toast';

const StandardItemMessage = ({ isModal }) => {
  const {
    standardItemMessages,
    removeStandardItemsMessageById,
  } = useStandards();
  return (
    <GenericMessagesToast
      onClose={removeStandardItemsMessageById}
      isModal={isModal}
      allMessages={standardItemMessages}
    />
  );
};

StandardItemMessage.propTypes = {
  isModal: PropTypes.bool.isRequired,
};

export default StandardItemMessage;
