import React from 'react';
import PropTypes from 'prop-types';
import { AFPTableRowAction } from '@gsa/afp-component-library';
import { isSolPeriodDeactivated } from '../utils/solicitation-period-utils';
import { enDashUnicode } from '../../../utilities/constants';

export const EDIT_ACTION = {
  icon: 'edit',
  label: 'Edit',
};

export const DELETE_ACTION = {
  icon: 'delete',
  label: 'Delete',
};

const ReopenPeriodsRowActions = (props) => {
  const { row } = props || {};
  const { original } = row || {};

  const handleOnMenuSelect = (eventName) => {
    if (original?.onMenuSelectMap?.[eventName])
      original.onMenuSelectMap[eventName](original);
  };
  const startDate = new Date(original?.startDate);
  const endDate = new Date(original?.endDate);
  const todayDate = new Date();

  const isDeactivatedPeriod = isSolPeriodDeactivated(original);
  const showEdit = endDate > todayDate && !isDeactivatedPeriod;
  const showDelete = startDate > todayDate && !isDeactivatedPeriod;

  const createAction = (action) => (isShow) => ({
    ...action,
    show: isShow,
  });
  const editAction = createAction(EDIT_ACTION)(showEdit);
  const deleteAction = createAction(DELETE_ACTION)(showDelete);

  const actions = [editAction, deleteAction];
  const allowedActions = actions.filter((action) => action?.show);
  if (
    original?.status === 'COMPLETED' ||
    original?.status === 'DEACTIVATED' ||
    allowedActions.length === 0
  ) {
    return enDashUnicode;
  }

  return (
    <AFPTableRowAction
      {...props}
      onSelectAction={handleOnMenuSelect}
      actions={allowedActions}
    />
  );
};

ReopenPeriodsRowActions.propTypes = {
  row: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default ReopenPeriodsRowActions;
