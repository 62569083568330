import React from 'react';
import SimpleModal from '../../../../../components/SimpleModal/SimpleModal';

export const TEXT_CONTENT = {
  content:
    'The Standard Items associated to the selected contract year might be different from the current selection. Are you sure you want to proceed?',
  proceedBtn: 'Yes, proceed',
  closeBtn: 'No',
};

export const TEST_ID = {
  closeBtn: 'modal-btn-close',
  proceedBtn: 'modal-btn-proceed',
  content: 'modal-content',
};
const ContractYearChangeWarnModal = (props) => (
  <SimpleModal {...props} content={TEXT_CONTENT.content} />
);

export default ContractYearChangeWarnModal;
