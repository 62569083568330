import {
  connectModal,
  Modal as AfpModal,
  useModal,
} from '@gsa/afp-component-library';
import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import './modal.scss';

const Modal = ({
  id,
  title,
  className,
  show,
  actions,
  onClose,
  children,
  variant,
}) => {
  const [activeTrap, setActiveTrap] = useState(false);
  const { isOpen, openModal, closeModal } = useModal();
  const classes = classnames('modal', 'bg-white', className);

  const unmountTrap = () => {
    setActiveTrap(false);
  };

  useEffect(() => {
    if (show) {
      setActiveTrap(show);
      openModal();
    } else {
      closeModal();
    }

    return () => {
      setActiveTrap(false);
    };
  }, [show]);

  const LocalModal = useMemo(
    () => () => (
      <AfpModal
        id={id}
        className={classes}
        title={title}
        onClose={() => {
          closeModal();
          onClose();
        }}
        actions={actions}
        variant={variant}
      >
        {children}
      </AfpModal>
    ),
    [show, actions],
  );

  const ConnectedModal = connectModal(LocalModal);

  const trap = activeTrap ? (
    <FocusTrap
      focusTrapOptions={{
        onDeactivate: unmountTrap,
        escapeDeactivates: false,
      }}
    >
      <div className="trap">
        <ConnectedModal isOpen={isOpen} onClose={closeModal} />
      </div>
    </FocusTrap>
  ) : (
    <ConnectedModal isOpen={isOpen} onClose={closeModal} />
  );

  return trap;
};

Modal.defaultProps = {
  id: 'afp-modal',
  className: 'modal',
  title: null,
  children: null,
  actions: null,
  show: false,
  onClose: () => null,
};

Modal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.node,
  show: PropTypes.bool,
  actions: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
};

export default Modal;
