import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import { useStandards } from './standards-provider';

const {
  FilterPanelField,
  FilterPanelFilterShape,
  useFilterPanel,
} = FilterPanel;

const FedStandardCodeFilterItem = ({ filter }) => {
  const { clearOneFilter } = useFilterPanel();

  const { multipleOptions, selectedOptions } = useStandards();

  useEffect(() => {
    if (selectedOptions.vehicleType) {
      clearOneFilter(filter);
    }
  }, [selectedOptions]);

  return (
    <>
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'select',
          options: multipleOptions?.fedStandardCode || [],
        }}
      />
    </>
  );
};

FedStandardCodeFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default FedStandardCodeFilterItem;
