/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/prop-types */
import React from 'react';
import { Label, TextInput } from '@gsa/afp-component-library';
import { useFormContext } from 'react-hook-form';
import './upiid-number.scss';

const UpiidNumber = ({ isDisabled }) => {
  const { register, errors, clearErrors } = useFormContext();
  const resetDuplicateUpiidError = () => {
    clearErrors('upiidAACRenewal');
    clearErrors('upiidSQRenewal');
  };
  return (
    <div className="upiid-container">
      <div tabIndex={0}>
        <Label required>Solicitation uPIID Number</Label>
      </div>

      <div className="grid-row grid-gap-2">
        <div className="desktop:grid-col-2 display-flex flex-row">
          <TextInput
            name="upiidAAC"
            aria-label="6 character AAC"
            data-testid="upiid-aac"
            inputRef={register}
            help="6 character AAC"
            maxLength="6"
            style={{ textTransform: 'uppercase' }}
            errorMessage={errors.upiidAAC && errors.upiidAAC.message}
            readOnly={isDisabled}
            containerClassName={
              errors.upiidAACRenewal ? 'usa-form-group--error' : ''
            }
            variant={errors.upiidAACRenewal ? 'error' : ''}
            onFocus={resetDuplicateUpiidError}
          />
        </div>
        <div className="desktop:grid-col-3 display-flex flex-row upiid-box">
          <TextInput
            name="upiidSQ"
            aria-label="2 digit fiscal year, 1 character instrument type, 4 position sequence number"
            data-testid="upiid-sq"
            inputRef={register}
            help="2 digit fiscal year, 1 character instrument type, 4 position sequence number"
            maxLength="7"
            style={{ textTransform: 'uppercase' }}
            errorMessage={errors.upiidSQ && errors.upiidSQ.message}
            readOnly={isDisabled}
            containerClassName={
              errors.upiidSQRenewal ? 'usa-form-group--error' : ''
            }
            variant={errors.upiidSQRenewal ? 'error' : ''}
            onFocus={resetDuplicateUpiidError}
          />
        </div>
      </div>
    </div>
  );
};

export default UpiidNumber;
