import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getValidator } from '../../utilities/validations/error-rules';

yup.addMethod(
  yup.string,
  'afpValidate',
  function callback(errorMessage, field, isValid) {
    return this.test(field, errorMessage, function getError() {
      const { path, createError } = this;
      return isValid || createError({ path, message: errorMessage });
    });
  },
);

export const standardItemSchema = yup.object().shape({
  standardItemNumber: yup.lazy((value) => {
    const isValid = getValidator(value, 'StandardItemNumber');
    if (value !== undefined && !isValid) {
      return yup
        .string()
        .afpValidate(
          'Make sure the Standard Item Number meets the requirements listed below.',
          'standardItemNumber',
          isValid,
        )
        .required();
    }
    return yup.mixed().notRequired();
  }),

  year: yup.lazy((value) => {
    if (value !== undefined) {
      return yup.string().required('Contract year is required');
    }
    return yup.mixed().notRequired();
  }),
  title: yup.lazy((value) => {
    const isValid = getValidator(value, 'Title');
    if (value !== undefined && !isValid) {
      return yup
        .string()
        .afpValidate(
          'Make sure the title meets the requirements listed below.',
          'title',
          isValid,
        )
        .required();
    }
    return yup.string().required('Title is required');
  }),
  vehicleType: yup.string().required('vehicle type Code is required'),
  status: yup.string().required('Status is required'),
  fastCode: yup.string(),
  copyFromStandardItemId: yup.number().when('copyFromYear', {
    is: (copyFromYear) => {
      return copyFromYear > 0;
    },
    then: yup
      .number()
      .required('Standard Item number is required')
      .moreThan(0, 'Standard Item number is required')
      .typeError('Standard Item number is required'),
  }),
});

const StandardItemFormContext = ({ standardItem, children }) => {
  const inputData = {
    standardItemNumber: standardItem?.standardItemNumber,
    title: standardItem?.title,
    fedStandardCode: standardItem?.fedStandard?.code || '',
    year: standardItem?.year || '',
    status: standardItem?.status || '',
    vehicleType: standardItem?.vehicleTypeCode?.code || '',
    fast: standardItem?.fastCode?.code || '',
    tags: standardItem?.tags?.value || [],
    content: standardItem?.content?.content || null,
    copyFromEquipmentCodeId: 0,
    copyFromYear: 0,
  };
  const methods = useForm({
    resolver: yupResolver(standardItemSchema),
    defaultValues: inputData,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

StandardItemFormContext.defaultProps = {
  standardItem: {},
  multipleOptions: [],
};

StandardItemFormContext.propTypes = {
  standardItem: PropTypes.shape(Object),
  multipleOptions: PropTypes.shape(Object),
  children: PropTypes.node.isRequired,
};

export default StandardItemFormContext;
