import moment from 'moment';
import * as Yup from 'yup';
import { formatDate } from '../../../../../utilities/date-helpers';
import { checkIfTimeIsPast } from '../../../components/solicitation-form-context';
import { parseDateString } from '../../../utils/solicitation-period-utils';
import { getTimestampFromDateNTime } from '../reopen-periods/open-reopen-period-util';

export const OPEN_PERIOD_YUP_ERROR_MSG = {
  END_DATE_BEFORE_START_DATE: 'Closing date must be after posting date.',
  END_DATE_PAST: 'Closing date should not be less than the current date.',
  END_DATE_REQUIRED: 'Closing date is required.',
  END_TIME_BEFORE_START_TIME: 'Closing time must be after the posting time.',
  END_TIME_REQUIRED: 'Closing time is required.',
  OPEN_PERIOD_OVERLAP_REOPEN_PERIOD:
    'The Open period added overlaps with an existing Reopen period.',
  START_DATE_PAST: 'Posting date should not be less than the current date.',
  START_DATE_REQUIRED: 'Posting date is required.',
  START_TIME_REQUIRED: 'Posting time is required.',
  START_TIME_PAST: 'Posting time cannot be in the past.',
};

const checkIfAnyOtherFieldIsFilled = (currentFieldName, parent = {}) => {
  const fieldList = ['startDate', 'startTime', 'endDate', 'endTime'];
  const fieldsFromParent = fieldList.filter(
    (eachField) => eachField !== currentFieldName,
  );
  return fieldsFromParent.reduce(
    (reducedIsEmpty, eachParentField) =>
      reducedIsEmpty || !!parent?.[eachParentField],
    false,
  );
};
const getBaseStartDateSchema = ({ minDate } = {}) =>
  Yup.date()
    .typeError(' ')
    .transform(parseDateString)
    .nullable()
    .min(minDate, OPEN_PERIOD_YUP_ERROR_MSG.START_DATE_PAST)
    .test({
      name: 'startDate-validation',
      skipAbsent: true,
      test(startDate, { parent, createError, path }) {
        if (checkIfAnyOtherFieldIsFilled(path, parent) && !startDate)
          return createError({
            message: OPEN_PERIOD_YUP_ERROR_MSG.START_DATE_REQUIRED,
          });
        return true;
      },
    });
const getBaseStartTimeSchema = () =>
  Yup.string()
    .test({
      name: 'posting-time-validation',
      skipAbsent: true,
      test(startTime, { parent, createError, path }) {
        if (checkIfAnyOtherFieldIsFilled(path, parent) && !startTime)
          return createError({
            message: OPEN_PERIOD_YUP_ERROR_MSG.START_TIME_REQUIRED,
          });

        return true;
      },
    })
    .when(['startDate'], {
      is: (startDate) =>
        moment(startDate).isSame(new Date(), 'day') && startDate,
      then: Yup.string()
        .required('Posting time is required.')
        .test(
          'is-valid',
          'Posting time cannot be in the past.',
          checkIfTimeIsPast,
        ),
    });
const getBaseEndDateSchema = ({ minDate } = {}) =>
  Yup.date()
    .typeError(' ')
    .transform(parseDateString)
    .nullable()
    .min(minDate, OPEN_PERIOD_YUP_ERROR_MSG.END_DATE_PAST)
    .test({
      name: 'endDate-validation',
      skipAbsent: true,
      test(endDate, { parent, createError, path }) {
        const { startDate } = parent || {};

        if (checkIfAnyOtherFieldIsFilled(path, parent) && !endDate)
          return createError({
            message: OPEN_PERIOD_YUP_ERROR_MSG.END_DATE_REQUIRED,
          });
        if (moment(endDate).isBefore(startDate, 'day'))
          return createError({
            message: OPEN_PERIOD_YUP_ERROR_MSG.END_DATE_BEFORE_START_DATE,
          });

        return true;
      },
    });
const getBaseEndTimeSchema = ({ maxDateTime } = {}) =>
  Yup.string().when(['startDate', 'endDate', 'startTime'], {
    is: (startDate, endDate, startTime) =>
      startDate &&
      endDate &&
      startTime &&
      moment(startDate).isValid &&
      moment(endDate).isValid,
    then: Yup.string()
      .required('Closing time is required.')

      .test({
        name: 'is-valid-date-range',
        skipAbsent: true,
        test(endTime, { createError, parent }) {
          const { endDate, startDate, startTime } = parent || {};
          const startDateTime = getTimestampFromDateNTime(startDate, startTime);
          const endtDateTime = getTimestampFromDateNTime(endDate, endTime);
          if (moment().isAfter(moment(endtDateTime), 'minutes'))
            return createError({
              message: 'Closing time should not be less than the current time.',
            });
          if (
            moment(startDateTime).isSame(endtDateTime, 'day') &&
            moment(startDateTime).isSameOrAfter(endtDateTime)
          )
            return createError({
              message: OPEN_PERIOD_YUP_ERROR_MSG.END_TIME_BEFORE_START_TIME,
            });
          if (
            maxDateTime &&
            moment(endtDateTime).isSameOrAfter(maxDateTime, 'minutes')
          )
            return createError({
              message:
                OPEN_PERIOD_YUP_ERROR_MSG.OPEN_PERIOD_OVERLAP_REOPEN_PERIOD,
            });
          return true;
        },
      }),
  });

export const getOpenPeriodYupSchema = ({
  status,
  maxDateTime,
  readonlyFields = [],
}) => {
  const minDate = formatDate();

  const baseStartDateSchema = getBaseStartDateSchema({ minDate });
  const baseStartTimeSchema = getBaseStartTimeSchema();
  const baseEndDateSchema = getBaseEndDateSchema({ minDate });
  const baseEndTimeSchema = getBaseEndTimeSchema({ maxDateTime });

  const draftSchemaShape = {
    startDate: baseStartDateSchema,
    startTime: baseStartTimeSchema,
    endDate: baseEndDateSchema,
    endTime: baseEndTimeSchema,
  };

  const editSchemaShape = {
    startDate: (readonlyFields?.includes('startDate')
      ? Yup.date()
      : baseStartDateSchema
    ).required(OPEN_PERIOD_YUP_ERROR_MSG.START_DATE_REQUIRED),

    startTime: (readonlyFields?.includes('startTime')
      ? Yup.string()
      : baseStartTimeSchema
    ).required(OPEN_PERIOD_YUP_ERROR_MSG.START_TIME_REQUIRED),
    endDate: (readonlyFields?.includes('endDate')
      ? Yup.date()
      : baseEndDateSchema
    ).required(OPEN_PERIOD_YUP_ERROR_MSG.END_DATE_REQUIRED),
    endTime: (readonlyFields?.includes('endTime')
      ? Yup.string()
      : baseEndTimeSchema
    ).required(OPEN_PERIOD_YUP_ERROR_MSG.END_TIME_REQUIRED),
  };

  return Yup.object().shape(
    status === 'Draft' ? draftSchemaShape : editSchemaShape,
  );
};
