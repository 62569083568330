import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import Truncate from '../../../components/Truncate';
import { GET_STANDARD_ITEM } from '../../../services/data-store';

const StandardItemDescription = ({ standardItemId }) => {
  const [description, setDescription] = useState('');

  const [getStandardItem] = useLazyQuery(GET_STANDARD_ITEM, {
    fetchPolicy: 'network-only',
    onError: (requestError) => {
      // eslint-disable-next-line no-console
      console.log(requestError);
    },
    onCompleted: (responseData) => {
      if (responseData?.getStandardItem) {
        setDescription(responseData?.getStandardItem?.content?.content);
      }
    },
  });

  useEffect(() => {
    getStandardItem({
      variables: {
        filter: {
          key: 'id',
          operator: 'EQ',
          value: Number(standardItemId),
        },
      },
    });
    return () => {
      setDescription('');
    };
  }, [standardItemId]);

  return (
    <div className="grid-row padding-bottom-2">
      <div className="grid-col-6">
        <h2 className="font-sans-md">Description</h2>
        {description ? (
          <Truncate content={description} />
        ) : (
          <span>&ndash;</span>
        )}
      </div>
    </div>
  );
};

StandardItemDescription.propTypes = {
  standardItemId: PropTypes.string.isRequired,
};

export default StandardItemDescription;
