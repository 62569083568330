import React from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { Modal, Spinner, Button } from '@gsa/afp-component-library';
import { REMOVE_BID_STANDARD_ITEM } from '../../bids/bids.gql';
import { bidsDashboardMessageAtom } from '../../bids/atoms/bids-atoms';
import CanIChangeBidData from '../../bids/components/protect-bid-data-crud';

const RemoveConfirmationModal = ({ data, onClose }) => {
  const { bidId, standardItemId, standardItemNumber } = data;
  const [, setToaster] = useRecoilState(bidsDashboardMessageAtom);

  // gql call to remove standard item
  const [removeStandardItem, { loading }] = useMutation(
    REMOVE_BID_STANDARD_ITEM,
    {
      variables: {
        bidId,
        standardItemId: parseInt(standardItemId, 10),
      },
      onCompleted: () => {
        setToaster({
          type: 'success',
          message: (
            <>
              Standard Item <b>{standardItemNumber}</b> has been removed from
              the bid.
            </>
          ),
        });
        onClose(true);
      },
      onError: () => onClose(),
    },
  );

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          id="remove-standard-item-modal"
          variant="medium"
          title={<h3>Remove confirmation</h3>}
          onClose={onClose}
          actions={
            <div>
              <Button
                data-testid="bid-si-removal-modal-cancel-btn"
                variant="unstyled"
                onClick={onClose}
                label="Cancel"
              />
              <CanIChangeBidData>
                <Button
                  variant="secondary"
                  onClick={removeStandardItem}
                  label="Yes"
                />
              </CanIChangeBidData>
            </div>
          }
        >
          {loading && (
            <div
              role="none"
              className="afp-modal-overlay"
              data-testid="delete-si-loading-spinner"
            >
              <Spinner
                style={{
                  position: 'absolute',
                  top: '50%',
                  transform: 'translate(0, -50%)',
                }}
              />
            </div>
          )}
          <div className="margin-top-2 margin-bottom-8">
            Are you sure you want to remove the Standard Item{' '}
            <b>{standardItemNumber}</b> and associated makes and models from the
            bid? Deleting this Standard Item will also delete the associated bid
            lines from the solicitation.
          </div>
        </Modal>
      </div>
    </div>
  );
};

RemoveConfirmationModal.propTypes = {
  data: PropTypes.shape(
    PropTypes.objectOf({
      bidId: PropTypes.number.isRequired,
      standardItemId: PropTypes.string.isRequired,
      standardItemNumber: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RemoveConfirmationModal;
