import React from 'react';
import { BidProvider } from './bid-provider';
import BidView from './bid-view';

export default () => {
  return (
    <BidProvider>
      <BidView />
    </BidProvider>
  );
};
