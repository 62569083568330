import React, { useCallback } from 'react';
import { useBid } from './bid-provider';

const LineItemSummary = () => {
  const { bidSIData } = useBid();

  const getCount = useCallback(
    (status) => {
      const items =
        bidSIData?.getBidStandardItems?.filter((l) => l.scheduleLine != null) ||
        [];
      if (!status) return items.length;
      return items.filter((i) => i.status === status).length;
    },
    [bidSIData],
  );
  const getSection = (count, title, className) => (
    <section
      className={`padding-3 display-flex flex-align-center flex-justify-center ${
        className || ''
      }`}
    >
      <span className="title-l text-primary margin-right-1">{count}</span>
      <span className="text-s text-bold text-base">{title}</span>
    </section>
  );

  return (
    <section className="grid-row margin-bottom-1 margin-top-4 margin-bottom-8">
      <section className="grid-col-3 bg-blue-5 padding-y-205 padding-x-3 radius-sm title-m display-flex flex-justify-start flex-align-center">
        Line item summary
      </section>
      <section className="grid-col-9 bg-blue-2 padding-y-205 padding-x-3 radius-sm grid-row padding-0 display-flex flex-justify-start flex-align-center">
        {getSection(
          getCount(),
          'Total',
          'border-right-1px border-primary-light',
        )}
        {getSection(getCount('Not started'), 'Not started')}
        {getSection(getCount('In progress'), 'In progress')}
        {getSection(getCount('Ready to submit'), 'Ready to submit')}
        {getSection(getCount('Completed'), 'Submitted')}
      </section>
    </section>
  );
};

export default LineItemSummary;
