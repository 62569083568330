import React from 'react';
import PropTypes from 'prop-types';
import { MultiSelectDropdown } from '@gsa/afp-component-library';
import { useContractLine } from '../provider/contract-line-provider';
import { getOptionalEcList } from '../helpers/ec-helpers';
import { getEditConflictBlock, displayCludes } from './conflicts-components';

const EditIncludesExcludesBlock = ({
  equipment,
  field,
  data,
  options,
  onUpdate,
}) => {
  const { optionalECs } = useContractLine();
  const ecList = getOptionalEcList(optionalECs);

  const getForm = () => (
    <MultiSelectDropdown
      id={field}
      selectedValues={data}
      options={options}
      onChange={(val) => onUpdate(field, val)}
      chipShowValue
    />
  );

  const getSummary = () => {
    if (data.length > 0) return displayCludes(data, ecList);
    return <div className="margin-left-2">(None)</div>;
  };

  return getEditConflictBlock(equipment, field, getForm, getSummary);
};

EditIncludesExcludesBlock.propTypes = {
  equipment: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.arrayOf(PropTypes.objectOf({})).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default EditIncludesExcludesBlock;
