import React, { useEffect, useMemo, useState } from 'react';
import { AFPTable, EmptyState } from '@gsa/afp-component-library';
import { useFvsPreBidContext } from '../fvs-pre-bid-selection-provider';

const MakeModelView = () => {
  const { showFormModal, selectedMakeModelItem } = useFvsPreBidContext();

  const [data, setData] = useState({
    rows: [],
    hasMore: false,
    count: 0,
  });

  /* eslint-disable react/prop-types */
  const columns = useMemo(
    () => [
      {
        Header: 'Make',
        accessor: 'make.makeName',
        Cell: ({ value }) => {
          return value || <span>&ndash;</span>;
        },
      },
      {
        Header: 'Model',
        accessor: 'model.modelName',
        Cell: ({ value }) => {
          return value || <span>&ndash;</span>;
        },
      },
      {
        Header: 'Chassis Make',
        accessor: 'chassisMake.makeName',
        Cell: ({ value }) => {
          return value || <span>&ndash;</span>;
        },
      },
      {
        Header: 'Chassis Model',
        accessor: 'chassisModel.modelName',
        Cell: ({ value }) => {
          return value || <span>&ndash;</span>;
        },
      },
    ],
    [],
  );

  useEffect(() => {
    setData(selectedMakeModelItem);
  }, [showFormModal]);

  return (
    <div>
      <div>
        <strong>Standard Item </strong>
        <span>&nbsp;</span>
        {data?.standardItem?.standardItemNumber} <span>&ndash;</span>
        {data?.standardItem?.title}
      </div>
      <div className="association-table-wrapper">
        {data?.preBidMakeModels && (
          <AFPTable
            columns={columns}
            data={data?.preBidMakeModels}
            data-testid="make-and-model-table"
          />
        )}

        {data?.preBidMakeModels?.length === 0 && (
          <EmptyState
            hasBackground
            containerStyles="padding-y-8 margin-top-1"
            topText={
              <strong>
                There are no Make and Models for this Standard Item.
              </strong>
            }
          />
        )}
      </div>
    </div>
  );
};

MakeModelView.prototype = {};

export default MakeModelView;
