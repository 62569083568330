import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { PaginationPropType } from '../../utilities/types';

export const commentsContext = createContext(null);

const CommentsContextProvider = ({
  parentPrimaryKey,
  children,
  comments,
  setComments,
  refetchComments,
}) => (
  <commentsContext.Provider
    value={{ parentPrimaryKey, comments, setComments, refetchComments }}
  >
    {children}
  </commentsContext.Provider>
);

CommentsContextProvider.propTypes = {
  parentPrimaryKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  children: PropTypes.node.isRequired,
  comments: PropTypes.shape(PaginationPropType).isRequired,
  setComments: PropTypes.func.isRequired,
  refetchComments: PropTypes.func.isRequired,
};

export default CommentsContextProvider;
