/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { Label, RadioButton, Fieldset, Icon } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { AUDIENCE_MAP } from '../constants';

const AudienceSelection = (props) => {
  const {
    name,
    inputRef,
    label,
    currentValue,
    onChange,
    value,
    children,
  } = props;

  useEffect(() => {
    onChange(currentValue || AUDIENCE_MAP.Open);
  }, [currentValue]);
  const registeredProps = inputRef(name);
  const handleChange = (ev) => onChange(ev.target.value);
  return (
    <>
      <div className="audience-selection-container">
        <div tabIndex={0}>
          <Label className="text-bold">{label}</Label>
        </div>
        <Fieldset legend="Audience selection" legendSrOnly>
          <div style={{ display: 'flex', justifyContent: 'start' }}>
            <RadioButton
              {...registeredProps}
              data-testid="open-season"
              label="Open season"
              ariaLable="Open season"
              name={name}
              value={AUDIENCE_MAP.Open}
              onChange={handleChange}
              checked={value === AUDIENCE_MAP.Open}
              data-tip="React-tooltip"
            />
            <Icon
              data-tip
              data-for="openSeason"
              iconName="info"
              className="font-sans-lg margin-bottom-0 margin-left-1 margin-top-1"
              style={{ fill: 'black' }}
            />
            <ReactTooltip
              data-testid="open-season-tooltip"
              id="openSeason"
              place="right"
              type="dark"
              effect="float"
              getContent={() => {
                return (
                  <div className="height-11 font-sans-md width-mobile-lg text-left">
                    All Vehicle Suppliers with an active account in
                    GSAFleet.gov. When this is selected it will give access to
                    all Vehicle Suppliers regardless of other audience
                    selections made.
                  </div>
                );
              }}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'start' }}>
            <RadioButton
              {...registeredProps}
              data-testid="custom-audience"
              className="text-pre-line"
              label="Awardees, Active bidders, Custom audience"
              ariaLable="Awardees, Active bidders, Custom audience"
              name={name}
              value={AUDIENCE_MAP.Custom}
              onChange={handleChange}
              checked={value === AUDIENCE_MAP.Custom}
            />
            <Icon
              data-tip="React-tooltip"
              iconName="info"
              className="font-sans-lg margin-bottom-0 margin-left-1 margin-top-1"
              style={{ fill: 'black' }}
            />
            <ReactTooltip
              data-testid="custome-audience-tooltip"
              place="right"
              type="dark"
              effect="float"
              getContent={() => {
                return (
                  <div className="height-full font-sans-md width-mobile-lg text-left">
                    <p>
                      <b>Awardees:</b> All Vehicle Suppliers with a contract
                      associated with this solicitation.
                    </p>
                    <p>
                      <b>Active bidders:</b> All Vehicle Suppliers who have bid
                      on this solicitation in a prior open period this contract
                      year.
                    </p>
                    <p>
                      <b>Custom audience:</b> Ad Hoc selection of Vehicle
                      Suppliers. You may also use this to add additional Vehicle
                      Suppliers to an Awardees or Active bidders selection.
                    </p>
                  </div>
                );
              }}
            />
          </div>
        </Fieldset>
      </div>
      <div style={{ display: 'flex', width: 800 }}>
        {value === AUDIENCE_MAP.Custom ? children : null}
      </div>
    </>
  );
};

AudienceSelection.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  currentValue: PropTypes.string.isRequired,
  inputRef: PropTypes.shape(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
};

export default AudienceSelection;
