import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Checkbox, ErrorMessage } from '@gsa/afp-component-library';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFvsPreBidContext } from '../fvs-pre-bid-selection-provider';

const DocReviewIndicatorForm = () => {
  const params = useParams();
  const { fvsId } = params;

  const [docReviewForFvs, setDocReviewForFvs] = useState(null);

  const {
    preBidSelectionDataForVendor,
    markDocAsReviewed,
  } = useFvsPreBidContext();

  useEffect(() => {
    if (
      preBidSelectionDataForVendor &&
      preBidSelectionDataForVendor?.docsReview
    ) {
      const drForFvs = preBidSelectionDataForVendor.docsReview.find(
        (d) => d.fvsId === Number.parseInt(fvsId, 10),
      );
      if (drForFvs) {
        setDocReviewForFvs(drForFvs);
      }
    }
  }, [preBidSelectionDataForVendor, fvsId]);

  const DocReviewFormSchema = yup.object().shape({
    isChecked: yup
      .bool()
      .oneOf(
        [true],
        'Check the above box before submit to mark this Federal Standard as reviewed.',
      ),
  });

  const onSubmit = (data) => {
    if (data.isChecked) {
      markDocAsReviewed({
        variables: {
          fvsId: Number.parseInt(fvsId, 10),
        },
      });
    }
  };

  const { errors, control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(DocReviewFormSchema),
    defaultValues: {
      reviewStatus: docReviewForFvs ? docReviewForFvs.reviewStatus : 0,
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  return (
    <>
      {docReviewForFvs && (
        <form
          id="commenting-period-schedule-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="grid-row grid-gap">
            <div className="desktop:grid-col-12 tablet-lg:grid-col-12 padding-bottom-2 margin-right-2 flex-align-start flex-justify-start">
              <Controller
                name="isChecked"
                control={control}
                defaultValue={docReviewForFvs?.isChecked}
                render={({ name, value, onBlur, onChange }) => (
                  <Checkbox
                    className="float-left"
                    label={
                      <span className="body-block text-black">
                        I have reviewed the Federal Standards documentation.
                      </span>
                    }
                    name={name}
                    defaultChecked={!!docReviewForFvs?.isChecked}
                    onBlur={onBlur}
                    onChange={(val) => {
                      onChange(val);
                      setValue('isChecked', val.target.checked);
                    }}
                    value={value}
                    data-testid="doc-review-for-fvs"
                    disabled={docReviewForFvs?.reviewStatus === 1}
                  />
                )}
              />
            </div>
            {errors?.reviewStatus?.message && (
              <div className="desktop:grid-col-12 tablet-lg:grid-col-12 padding-bottom-2 margin-right-2 flex-align-start flex-justify-start">
                <ErrorMessage>{errors.reviewStatus.message}</ErrorMessage>
              </div>
            )}
          </div>
          {docReviewForFvs?.reviewStatus !== 1 && (
            <div className="grid-row grid-gap">
              <div className="desktop:grid-col-12 tablet-lg:grid-col-12 padding-bottom-2 margin-right-2 flex-align-start flex-justify-start">
                <Button
                  className="margin-right-2 float-left"
                  variant="unstyled"
                  type="submit"
                  data-testid="standard-item-edit-cancel-btn"
                  label="Confirm review"
                />
              </div>
            </div>
          )}
        </form>
      )}
    </>
  );
};

export default DocReviewIndicatorForm;
