import moment from 'moment-timezone';

// time zone for standard date
export const TIMEZONE = 'America/New_York';
export const DATE_SORT_ASCENDING = 'ASCENDING';
export const DATE_SORT_DESCENDING = 'DESCENDING';

// The value for the month is zero-based
const FIRST_DAY_OF_YEAR = [0, 1]; // January is 0
// const LAST_DAY_OF_YEAR = [11, 31]; //December is 11

// get UTC date according to TIMEZONE e.g. "2021-05-18T00:00:00.000Z"
export const getUTCDate = (date, offset = 0) => {
  const m = date ? moment(date) : moment();
  return new Date(m.tz(TIMEZONE).add(offset, 'days').format('YYYY-MM-DD'));
};
export const getUTCDateStr = (date, offset = 0) =>
  getUTCDate(date, offset).toISOString();
export const getTodayDate = () => getUTCDate(null, 0);
export const getTodayStr = () => getTodayDate().toISOString();
export const getTomorrowDate = () => getUTCDate(null, 1);
export const getTomorrowStr = () => getTomorrowDate().toISOString();

// convert date string between "05/18/2021" and "2021-05-18T00:00:00.000Z"
export const USDateStrToUTC = (dateStr) =>
  dateStr
    ? `${dateStr.substr(6, 4)}-${dateStr.substr(0, 2)}-${dateStr.substr(
        3,
        2,
      )}T00:00:00.000Z`
    : dateStr;
export const UTCDateStrToUS = (dateStr) =>
  dateStr
    ? `${dateStr.substr(5, 2)}/${dateStr.substr(8, 2)}/${dateStr.substr(0, 4)}`
    : dateStr;

// get time stamp date according to time zone
export const getTSDateStrToUS = (ts) =>
  moment.utc(ts).tz(TIMEZONE).format('MM/DD/YYYY');

// fiscal year functions
const FY_OFFSET_DAY = 92;
export const getUTCFYInt = (date = undefined) =>
  moment.utc(date).add(FY_OFFSET_DAY, 'days').year();

export const getFYInt = (date = undefined) =>
  moment.utc(date).tz(TIMEZONE).add(FY_OFFSET_DAY, 'days').year();

export const getFYUTCDateRange = (year) => {
  const yearNum = parseInt(year, 10);
  const num2year = (num) => num.toString().padStart(4, '0');
  return {
    startDate: `${num2year(yearNum - 1)}-10-01T00:00:00.000Z`,
    endDate: `${num2year(yearNum)}-09-30T00:00:00.000Z`,
  };
};

/**
 *
 * @param {  DATE_SORT_ASCENDING |  DATE_SORT_DESCENDING} order
 */
export const sortDate = (order = DATE_SORT_ASCENDING) =>
  /**
   * @param {string} accessor
   */
  (accessor) =>
    /**
     *
     * @param {string | object} a
     * @param {string | object} b
     */
    (a, b) => {
      const getDateStr = (data) => moment(accessor ? data?.[accessor] : data);
      const dateA = getDateStr(a);
      const dateB = getDateStr(b);
      return order === DATE_SORT_DESCENDING
        ? dateB.diff(dateA)
        : dateA.diff(dateB);
    };
export const sortDateAscending = sortDate()();
export const sortDateAscendingWithAccessors = sortDate();

export const sortDateDescending = sortDate(DATE_SORT_DESCENDING)();
export const sortDateDescendingWithAccessors = sortDate(DATE_SORT_DESCENDING);
export const formatDate = (dateStr, formatStr = 'MM/DD/YYYY') =>
  moment(dateStr).format(formatStr);

const getYearDayDate = (monthIndex = 0, dayInMonth = 1) => (year) =>
  new Date(year || new Date().getFullYear(), monthIndex, dayInMonth);
export const getYearFirstDayDate = getYearDayDate(...FIRST_DAY_OF_YEAR);
