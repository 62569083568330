import { gql } from '@apollo/client';

export const GET_APPLICABLE_YEARS = gql`
  query GetYears {
    getYearsForFVS {
      label
      value
      isDefault
    }
  }
`;

export const GET_FVS_OPTIONS_FOR_YEAR = gql`
  query GetFvsCodes($year: Float!, $includeOnlyCommentable: Boolean!) {
    getVehicleClassificationFVS(
      year: $year
      includeOnlyCommentable: $includeOnlyCommentable
    ) {
      id
      label
      value
      type
      uniqueKey
      currentYear
      parent
    }
  }
`;

export const GET_VEHICLES_STANDARDS = gql`
  query GetVehicleStandards(
    $filters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getFederalStandards(
      offset: $offset
      limit: $limit
      order: $order
      filters: $filters
    ) {
      rows {
        id
        fedStandardCode
        fedStandard {
          code
          title
        }
        status
        year
        version
        commentingPeriods {
          id
          periodStart
          periodEnd
          isArchived
          userType
          status
        }
        statusHistory {
          id
          version
          status
          createdAt
          createdByUser
        }
      }
      count
      hasMore
    }
  }
`;

export const GET_STANDARDS_REL_DOCS = gql`
  query GetStandardsSupportingDocs(
    $filters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getStandardsDocuments(
      offset: $offset
      limit: $limit
      order: $order
      filters: $filters
    ) {
      rows {
        id
        fvsId
        documentMetadataId
        docType
        metadata {
          name
          description
          docSource
          docStoreUri
          fileLocation
          fileMimeType
          scanStatus
          size
          uploadCompletedAt
        }
        createdAt
        createdByUser
        status
        effectiveDate
        readUri
      }
      count
      hasMore
    }
  }
`;

export const COUNT_OPEN_COMMENTING_PERIODS = gql`
  query GetCountsOfCommentingPeriod {
    getCountOpenCommentingPeriods {
      allOpenCount
      publicCommentPeriodCounts {
        year
        openCount
      }
    }
  }
`;

export const GET_OPEN_AND_RECENT_COMMENTING_PERIODS = gql`
  query GetAllCommentingPeriods {
    getOpenAndRecentCommentingPeriods {
      id
      fvsId
      fvsCode
      fvsTitle
      fvsYear
      periodStart
      periodEnd
      userType
      status
      daysLeft
    }
  }
`;

export const GET_PRE_BID_SELECTIONS_COUNT = gql`
  query GetPreBidDataForVendor($contractYear: Int!) {
    getPreBidDataForVendor(contractYear: $contractYear) {
      contractYear
      counts {
        selectionCount
        reviewedSelectionCount
        docsCount
        reviewedDocsCount
      }
      selectionsForYear {
        contractYear
        selections {
          vehicleGroupCode
          selectedStandardItemIds
        }
      }
      docsReview {
        fvsId
        totalDocs
        reviewStatus
        contractYear
        isChecked
      }
    }
  }
`;

export const GET_PRE_BID_SELECTIONS = gql`
  query GetPreBidSelections(
    $filters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getPreBidSelections(
      filters: $filters
      order: $order
      limit: $limit
      offset: $offset
    ) {
      count
      hasMore
      rows {
        id
        intentToBid
        preBidMakeModels {
          makeCode
          modelCode
        }
      }
    }
  }
`;
