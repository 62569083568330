import React, { useEffect, useState, useContext } from 'react';
import { PageTitle } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import Breadcrumbs from '../../widgets/breadcrumbs';
import EquipmentCodeDetailProvider from './equipment-code-detail-provider';
import EquipmentCodeInfo from './equipment-code-info';
import EquipmentModalWrapper from './equipment-code-detail-form-modal';
import AssociatedStandardItemsTable from './associated-standard-items-table';
import Notification from '../../widgets/notification';
import { EQUIPMENT_CODES } from '../../services/data-store';
import { NotificationContext } from '../../utilities/notification/notification-provider';
import AssociationMessages from './equipment-code-association-messages';
import EquipmentCodePageTitle from './equipment-code-page-title';
import './equipment-code-details.scss';

const EquipmentCodeDetail = () => {
  const history = useHistory();
  const [passedData, setPassedData] = useState(history.location.state);
  const [notification] = useContext(NotificationContext);
  const params = useParams();
  const { year, program, code } = params;

  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs.home}/home`,
      label: 'Home',
    },
    {
      location: '/catalog/equip-codes',
      label: 'Equipment Codes',
    },
  ];

  const [getEquipmentCode] = useLazyQuery(EQUIPMENT_CODES, {
    fetchPolicy: 'network-only',
    onCompleted: (responseData) => {
      if (responseData?.getEquipmentCodes) {
        setPassedData(responseData.getEquipmentCodes.rows[0]);
      }
    },
  });

  const loadData = () => {
    if (!code || !year) return;

    getEquipmentCode({
      variables: {
        filters: {
          operator: 'AND',
          value: [
            {
              operator: 'EQ',
              key: 'code',
              value: code,
            },
            {
              operator: 'EQ',
              key: 'year',
              value: year,
            },
            {
              operator: 'EQ',
              key: 'program',
              value: program,
            },
          ],
        },
      },
    });
  };

  useEffect(() => {
    loadData();
  }, [history.location.state]);

  return (
    <>
      <EquipmentCodeDetailProvider selected={passedData}>
        <div className="grid-col">
          <Breadcrumbs
            path={breadcrumbsPath}
            current="Equipment Codes details"
          />
          <AssociationMessages />
          {notification ? <Notification {...notification} /> : null}
          <EquipmentCodePageTitle />
          {passedData && <EquipmentCodeInfo />}
          <PageTitle
            aria-label="Standard Items"
            tabIndex="0"
            title="Standard Items"
          />
          <br />
          <AssociatedStandardItemsTable />
          <EquipmentModalWrapper />
        </div>
      </EquipmentCodeDetailProvider>
    </>
  );
};

export default EquipmentCodeDetail;
