import { isEmpty } from 'lodash';
import { FVS_STATUS } from '../constants';

export const RoleIDs = {
  Engineer: '501',
  SeniorEngineer: '502',
  PSO: '503',
  VS: '503',
  VM: '105',
  Public: '103',
  Admin: '100',
};

export const StandardsCommentAuthorType = {
  Engineer: 0,
  PSO: 1,
  Public: 2,
};

export const ROLES_ENGINEER = 'Engineer';
export const ROLES_SR_ENGINEER = 'Senior Engineer';
// basically both PSO and VS are the same. PSO kept for historical reasons.
export const ROLES_PSO = 'PSO';
export const ROLES_VEHICLE_SUPPLIER = 'Vehicle Supplier';
export const ROLES_SITE_ADMIN = 'Site Administrator';
export const ROLES_PUBLIC = 'Public';

export const getAuthor = (roles) => {
  const roleNames = roles?.map(({ name }) => name);
  if (
    roleNames?.includes(ROLES_ENGINEER) ||
    roleNames?.includes(ROLES_SR_ENGINEER)
  ) {
    return StandardsCommentAuthorType.Engineer;
  }
  if (
    roleNames?.includes(ROLES_PSO) ||
    roleNames?.includes(ROLES_VEHICLE_SUPPLIER)
  ) {
    return StandardsCommentAuthorType.PSO;
  }
  return StandardsCommentAuthorType.Public;
};

export const getRole = (roles) => {
  if (isEmpty(roles)) {
    return 'Unknown';
  }
  const roleNames = roles.map(({ name }) => name);
  if (roleNames.includes(ROLES_ENGINEER)) {
    return ROLES_ENGINEER;
  }
  if (roleNames.includes(ROLES_VEHICLE_SUPPLIER)) {
    return ROLES_VEHICLE_SUPPLIER;
  }

  if (roleNames.includes(ROLES_PUBLIC)) {
    return ROLES_PUBLIC;
  }
  return roles[0].name;
};

export const canViewComment = (comment, id, role) => {
  const isOwnComment = comment?.createdByUser === id;
  const commentIsPublished = comment?.publishStatus === FVS_STATUS.Published;
  return isOwnComment || commentIsPublished || role === ROLES_ENGINEER;
};

export const getCommentingAllowed = (vehicleStandard, role) => {
  const psoCommentingAllowed =
    role === ROLES_VEHICLE_SUPPLIER &&
    FVS_STATUS.PSOCommentOpen === vehicleStandard?.status;
  const publicCommentingAllowed =
    role === ROLES_PUBLIC &&
    FVS_STATUS.PublicCommentOpen === vehicleStandard?.status;
  const allCommentingAllowed =
    FVS_STATUS.AllCommentOpen === vehicleStandard?.status ||
    FVS_STATUS.CommentOpen === vehicleStandard?.status;

  return (
    allCommentingAllowed || psoCommentingAllowed || publicCommentingAllowed
  );
};
