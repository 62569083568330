/* eslint-disable jsx-a11y/role-supports-aria-props */
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@gsa/afp-component-library';
import EquipmentCodeTypeaheadInput from './equipment-code-typeahead-input';

const EquipmentCode = ({
  isNew,
  errors,
  setValue,
  control,
  setEQcode,
  equipmentAssociation,
  program,
}) => {
  return (
    <div className="grid-row padding-bottom-2">
      <div className="grid-col-6">
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <h2 className="font-sans-xs" tabIndex="0" aria-required="true">
          Equipment Code <span className="usa-hint usa-hint--required">*</span>
        </h2>
        {isNew ? (
          <>
            <Controller
              id="si_edit_code"
              name="equipmentCodeId"
              control={control}
              defaultValue=""
              render={({ onChange, value }) => (
                <EquipmentCodeTypeaheadInput
                  value={value ?? ''}
                  onChange={({ selected, selectedData }) => {
                    const selectedId = Number(selectedData?.id);
                    if (selectedId) {
                      onChange(selected);
                      setEQcode(selectedId);
                    } else {
                      // unset the Equipment Code ID form using the its name to trigger yup form validation
                      setValue('equipmentCodeId', '');
                    }
                  }}
                  equipmentAssociation={equipmentAssociation}
                  program={program}
                />
              )}
            />
            {errors?.equipmentCodeId?.message && (
              <ErrorMessage>{errors.equipmentCodeId.message}</ErrorMessage>
            )}
          </>
        ) : (
          equipmentAssociation?.equipmentCode.code
        )}
      </div>
    </div>
  );
};

EquipmentCode.propTypes = {
  isNew: PropTypes.bool.isRequired,
  control: PropTypes.shape(Object).isRequired,
  errors: PropTypes.shape(Object).isRequired,
  setValue: PropTypes.func.isRequired,
  setEQcode: PropTypes.func.isRequired,
  equipmentAssociation: PropTypes.shape(Object).isRequired,
  program: PropTypes.string.isRequired,
};

export default EquipmentCode;
