import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  PageTitle,
  RequiredFieldIndicator,
  StepIndicator,
  Alert,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import OverlaySpinner from '../../../components/overlay-spinner';
import { OPERATIONS, SUBJECTS } from '../../../utilities/constants';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import {
  API_TIME_LIMIT,
  REPORT_CONFIG,
} from './components/contract-report-types';
import ReportSelectionForm from './components/report-selection-form';
import SolicitationSelectionForm from './components/solicitation-selection-form';
import {
  STEPS,
  getDownloadAlert,
  asyncDownloadAlert,
} from './contract-report-helpers';
import { GET_CONTRACT_JS_REPORT } from './query';

let reportStartTime;

const ContractReports = () => {
  const canViewAll = useAppAbility().can(
    OPERATIONS.View,
    SUBJECTS.AllBidReports,
  );
  const step2defaults = {
    solicitation: { value: '' },
    vendor: { value: '' },
    solicitations: { value: [] },
    vendors: { value: [] },

    isEmailReport: { value: false },
  };

  const [currentStep, setCurrentStep] = useState(0);
  const [formValues, setFormValues] = useState({
    reportType: { value: 'SOP' },
    report: { value: '' },
    contractStatus: { value: [] },
    ...step2defaults,
  });

  const [toaster, setToaster] = useState(null);

  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs.home}/home`,
      label: 'Home',
    },
  ];

  // Query to fetch report data
  const [getContractJSReportData, { loading: loadingJSReport }] = useLazyQuery(
    GET_CONTRACT_JS_REPORT,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ getContractJSReport: res }) => {
        setToaster(
          res.requestSent
            ? asyncDownloadAlert
            : getDownloadAlert(
                REPORT_CONFIG[formValues.report.value].label,
                res.content.data,
                res.contentType,
                `${res.reportName}.${res.fileExtension}`,
              ),
        );
      },
      onError: () => {
        if (canViewAll && Date.now() - reportStartTime >= API_TIME_LIMIT)
          setToaster({
            type: 'warning',
            message:
              "Report generation exceeded time limit. Please try again, or select 'email the report' option.",
          });
      },
    },
  );

  const onSubmit = (fileType) => {
    setToaster(null);
    const queryParams = {};

    if (formValues.vendor.value)
      queryParams.vendorIds = [formValues.vendor.value];
    if (formValues.vendors.value.length)
      queryParams.vendorIds = formValues.vendors.value;
    if (formValues.solicitations.value.length)
      queryParams.solicitationIds = formValues.solicitations.value.map(
        (v) => +v, // string to number
      );
    if (formValues.solicitation.value)
      queryParams.solicitationIds = [+formValues.solicitation.value];
    if (formValues.contractStatus.value.length === 1)
      queryParams.isActive = formValues.contractStatus.value[0] === 'active';

    let method = formValues.isEmailReport.value ? 'async' : 'sync';
    if (!canViewAll) method = 'auto';

    reportStartTime = Date.now();
    getContractJSReportData({
      variables: {
        input: {
          reportTypeId: REPORT_CONFIG[formValues.report.value].reportTypeId,
          outputType: fileType,
          method,
          queryParams,
        },
      },
    });
  };

  return (
    <div className="margin-bottom-8">
      {loadingJSReport && <OverlaySpinner />}
      {toaster && (
        <Alert
          slim
          focused
          type={toaster.type}
          onClose={() => setToaster(null)}
        >
          {toaster.message}
        </Alert>
      )}
      <Breadcrumbs current="Contract reports" path={breadcrumbsPath} />
      <PageTitle
        title="Contract reports"
        aria-label="Contract reports"
        tabIndex="0"
      />
      <div>
        Use this tool to export the contract reports from Fleet. All reports
        will pull the latest version of the contracts.
      </div>
      <div className="margin-bottom-4">
        Required fields are marked with an asterisk (<RequiredFieldIndicator />
        ).
      </div>

      <StepIndicator
        counterSize="small"
        data-testid="bid-reports-stepper"
        heading={STEPS[currentStep].heading}
        steps={STEPS[currentStep].steps}
        className="margin-bottom-1"
      />
      <hr className="border-gray-1" />

      {currentStep === 0 ? (
        <ReportSelectionForm
          canViewAll={canViewAll}
          formValues={formValues}
          setFormValues={setFormValues}
          onNext={() => {
            setCurrentStep(1);
            setFormValues((prevValues) => ({
              ...prevValues,
              ...step2defaults,
            }));
          }}
        />
      ) : (
        <SolicitationSelectionForm
          canViewAll={canViewAll}
          formValues={formValues}
          setFormValues={setFormValues}
          onPrev={() => setCurrentStep(0)}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};

export default ContractReports;
