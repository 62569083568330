/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  createRef,
} from 'react';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';
import {
  AFPTable,
  Button,
  Pagination,
  EmptyState,
  Spinner,
  FilterTableFrame,
} from '@gsa/afp-component-library';
// import PreBidReportFilter from './pre-bid-report-filter';
import PreBidReportFilters from './pre-bid-report-filter-panel';
import { useFvsPreBidSelectionsReport } from './pre-bid-report-provider';
import useDidMountEffect from '../../../hooks/use-did-mount';
import { useFederalStandards } from '../fvs-provider';
import { useFvsSupportingDocs } from '../fvs-supporting-docs-provider';
import { emDashUnicode } from '../../../utilities/constants';

const PreBidReportTable = () => {
  const history = useHistory();

  const {
    selectedYear,
    selectedOptions,
    vehicleStandard,
    isApplicableFvsYearsLoading,
    isFVSOptionsForYearLoading,
  } = useFederalStandards();

  const {
    downloadFile,
    downloadingFile,
    setFvsSupportingDocsData,
  } = useFvsSupportingDocs();

  const {
    getPreBidMakeModelData,
    preBidSelectionsData,
    setFVSPreBidReportContextData,
    isPreBidSelectionLoading,
  } = useFvsPreBidSelectionsReport();

  const f = useMemo(() => {
    const defaultFilters = {
      operator: 'AND',
      value: [
        {
          key: '$preBidSelection.contract_year$',
          operator: 'EQ',
          value: selectedYear,
        },
      ],
    };

    if (!isEmpty(selectedOptions?.fedStandardCode)) {
      defaultFilters.value.push({
        key: '$preBidSelection.standardItem.fed_standard_code$',
        operator: 'IN',
        value: [selectedOptions?.fedStandardCode],
      });
    }

    if (!isEmpty(selectedOptions?.vehicleGroup)) {
      defaultFilters.value.push({
        key: '$preBidSelection.vehicle_group_code$',
        operator: 'EQ',
        value: selectedOptions?.vehicleGroup,
      });
    }
  }, [selectedYear, selectedOptions]);

  const [filters, setFilters] = useState(f);
  const [order, setOrder] = useState('vendor_id ASC');
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  const getData = (isReset) => {
    const { limit, offset } = paginationState;
    if (isReset && offset !== 0)
      setPaginationState((prev) => ({ ...prev, offset: 0, currentPage: 1 }));
    getPreBidMakeModelData({
      variables: {
        filters,
        limit,
        offset: isReset ? 0 : offset,
        order,
      },
    });
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  useEffect(() => {
    getData();
    if (vehicleStandard && vehicleStandard?.status) {
      setFVSPreBidReportContextData('SET_SELECTED_STANDARDS', [
        vehicleStandard,
      ]);
    }
  }, []);

  useDidMountEffect(() => {
    getData();
  }, [order, paginationState]);

  useDidMountEffect(() => {
    getData(true);
  }, [filters]);

  const tableRef = createRef();

  const downloadPreBidReportCsv = async () => {
    setFvsSupportingDocsData(
      'SET_FILE_NAME',
      `pre-bid-report-${selectedYear}.csv`,
    );
    await downloadFile({
      variables: {
        fileKey: `catalog/pre-bid-reports/${selectedYear}/pre-bid-report-${selectedYear}.csv`,
        fileType: 'text/csv',
      },
    });
  };

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Vendor',
        accessor: 'preBidSelection.vendorDetail.vendorName',
        minWidth: 30,
        width: 150,
        maxWidth: 400,
        sortable: false,
        Cell: ({ row: { original } }) =>
          `${original?.preBidSelection?.vendorDetail?.vendorName}` ||
          emDashUnicode,
      },
      {
        Header: 'Intention to bid',
        accessor: 'preBidSelection.intentToBid',
        minWidth: 30,
        width: 150,
        maxWidth: 400,
        Cell: ({ value }) => (value === 1 ? 'Y' : 'N'),
      },
      {
        Header: 'Fed. Std. Code',
        accessor: 'preBidSelection.standardItem.fedStandardCode',
        minWidth: 30,
        width: 150,
        maxWidth: 400,
        Cell: ({ row: { original }, value }) =>
          `${value} - ${original?.preBidSelection?.standardItem?.fedStandard?.title}`,
      },
      {
        Header: 'Standard Item',
        accessor: 'preBidSelection.standardItemId',
        minWidth: 30,
        width: 150,
        maxWidth: 400,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          // eslint-disable-next-line react/prop-types
          const standardItem = original?.preBidSelection.standardItem;
          const urlForReq = `/catalog/vehicle-standards/requirements/${standardItem?.year}/${standardItem?.fedStandardCode}/${standardItem?.standardItemNumber}`;
          return (
            <Button
              variant="unstyled"
              data-testid="pre-bid-report-standard-button"
              onClick={() => {
                history.push(urlForReq);
              }}
              label={` ${standardItem?.standardItemNumber} - ${standardItem?.title}`}
            />
          );
        },
      },
      {
        Header: 'Vehicle grouping',
        accessor: 'preBidSelection.vehicleGroupCode',
        //   cellClassName: 'afpTable-cell-wordBreak',
        minWidth: 300,
        width: 400,
        maxWidth: 600,
        Cell: ({ row: { original } }) =>
          `${original?.preBidSelection?.vehicleGroupCode} - ${original?.preBidSelection?.vehicleGroup?.title}`,
      },
    ];

    return columnList;
  }, []);

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    return (
      <div className="grid-container standard-items-sub-component-wrapper">
        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-6">
            <div className="grid-row standard-item-wrapper standard-item-separator">
              <div className="tablet:grid-col standard-item-title">
                Vendor company user name
              </div>
              <div className="tablet:grid-col text-right margin-left-1">
                {original?.preBidSelection?.createdByUserInfo?.fullName ||
                  emDashUnicode}
              </div>
            </div>

            <div className="grid-row standard-item-wrapper standard-item-separator">
              <div className="tablet:grid-col standard-item-title">
                Vendor email
              </div>
              <div className="tablet:grid-col text-right standard-item-last-row margin-left-1">
                {original?.preBidSelection?.vendorDetail?.emailAddress ||
                  emDashUnicode}
              </div>
            </div>

            <div className="grid-row standard-item-wrapper standard-item-separator">
              <div className="tablet:grid-col standard-item-title">
                Vehicle type
              </div>
              <div className="tablet:grid-col text-right margin-left-1">
                {original?.preBidSelection?.standardItem?.vehicleTypeCode.code}{' '}
                -{' '}
                {original?.preBidSelection?.standardItem?.vehicleTypeCode.title}
              </div>
            </div>
          </div>
          <div className="tablet:grid-col-6">
            <div className="grid-row standard-item-wrapper standard-item-separator">
              <div className="standard-item-title">Body Make</div>
              <div className="tablet:grid-col text-right margin-left-1" />
              {original?.make?.makeName || <span>&ndash;</span>}
            </div>

            <div className="grid-row standard-item-wrapper standard-item-separator">
              <div className="standard-item-title">Body Model</div>
              <div className="tablet:grid-col text-right margin-left-1">
                {original?.model?.modelName || <span>&ndash;</span>}
              </div>
            </div>

            <div className="grid-row standard-item-wrapper standard-item-separator">
              <div className="standard-item-title">Chassis Make</div>
              <div className="tablet:grid-col text-right margin-left-1">
                {original?.chassisMake?.makeName || <span>&ndash;</span>}
              </div>
            </div>

            <div className="grid-row standard-item-wrapper standard-item-separator">
              <div className="standard-item-title">Chassis Model</div>
              <div className="tablet:grid-col text-right margin-left-1">
                {original?.chassisModel?.modelName || <span>&ndash;</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, []);

  const TableWrapper = ({ tableProps, paginationProps }) => (
    <>
      <AFPTable {...tableProps} />
      {(isPreBidSelectionLoading ||
        isApplicableFvsYearsLoading ||
        isFVSOptionsForYearLoading) && <Spinner className="margin-y-8" />}
      {!isPreBidSelectionLoading &&
      !isApplicableFvsYearsLoading &&
      !isFVSOptionsForYearLoading &&
      tableProps.data?.length ? (
        <Pagination {...paginationProps} />
      ) : null}
      {!isPreBidSelectionLoading &&
        !isApplicableFvsYearsLoading &&
        !isFVSOptionsForYearLoading &&
        tableProps.data?.length === 0 && (
          <div className="text-center margin-top-neg-2 height-full">
            <EmptyState
              hasBackground
              containerStyles="padding-y-8 margin-top-1 height-full"
              topText={
                <div
                  aria-label="No data available for the pre-bid summary report."
                  tabIndex="0"
                >
                  <strong>
                    No data available for the pre-bid summary report.
                  </strong>
                </div>
              }
              topTextStyles="margin-top-7"
            />
          </div>
        )}
    </>
  );

  const FTF = useMemo(
    () => FilterTableFrame(null, null, PreBidReportFilters, TableWrapper),
    [],
  );

  return (
    <>
      {/* Download csv button */}
      <div className="grid-row">
        <div className="grid-col">
          {downloadingFile && <Spinner size="medium" />}
          <div className="float-right">
            {!downloadingFile && (
              <Button
                data-testid="pre-bid-report-download-csv-button"
                onClick={async () => {
                  await downloadPreBidReportCsv();
                }}
                type="custom"
                label="Download CSV"
                leftIcon={{
                  name: 'file_download',
                }}
              />
            )}
          </div>
        </div>
      </div>
      <FTF
        lowLeftProps={{
          currentFilters: filters,
          setFilters,
        }}
        lowRightProps={{
          tableProps: {
            columns,
            data: preBidSelectionsData.rows,
            fullWidth: true,
            expandable: true,
            ref: tableRef,
            renderRowSubComponent,
            testId: 'pre-bid-report',
            defaultSort: order,
            onSort: setOrder,
          },
          paginationProps: {
            itemsPerPageOptions: [10, 25, 50],
            onPageChange: handlePaginationChange,
            variant: 'advanced',
            itemsPerPage: paginationState.limit,
            currentPage: paginationState.currentPage,
            itemsCount: preBidSelectionsData?.count,
          },
        }}
        filterToggle
      />
    </>
  );
};

export default PreBidReportTable;
