/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
import React, { useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import {
  AFPTable,
  EmptyState,
  Icon,
  StatusBadge,
  Button,
} from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { getMakeModelStatusBadge, getApprovalStatus } from '../../helpers';
import { emDashUnicode } from '../../../../utilities/constants';
import CanIChangeBidData from '../../../bids/components/protect-bid-data-crud';

const MakesAndModleTable = ({ data = [], hasChassis, onRemoveMakeModel }) => {
  // only keep line-items having make and model i.e. scheduleLine is generated
  const lineItemsHavingMakeModel = data.filter(
    (obj) => obj.scheduleLine !== null,
  );

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Body Make',
        accessor: 'make.makeName',
        sortable: false,
      },
      {
        Header: 'Body Model',
        accessor: 'model.modelName',
        sortable: false,
      },
      {
        Header: 'Model year',
        accessor: 'modelYear',
        sortable: false,
      },
      {
        Header: (
          <div>
            Engineer review status{' '}
            <Icon
              data-tip
              data-for="openSeason"
              iconName="info"
              className="usa-icon margin-x-1 margin-y-neg-05"
            />
            <ReactTooltip
              data-testid="open-season-tooltip"
              id="openSeason"
              place="right"
              type="dark"
              effect="float"
              getContent={() => (
                <div className="height-11 width-mobile-lg text-left">
                  <p>Engineer review status</p>
                  The National Highway Traffic Safety Administration (NHSTA) or
                  GSA Engineers must approve Makes and Models. Some Makes and
                  Models have already gone through this process while others
                  require review. Review needed status is conducted by GSA
                  engineers and will approved or rejected.
                </div>
              )}
            />
          </div>
        ),
        accessor: 'make.approvalStatus',
        sortable: false,
        Cell: ({ row: { original } }) => {
          const status = getApprovalStatus(original);
          const badge = getMakeModelStatusBadge(status);
          return badge ? (
            <StatusBadge variant={badge.variant}>{badge.label}</StatusBadge>
          ) : (
            emDashUnicode
          );
        },
      },
      {
        Header: 'Actions',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        Cell: ({ row: { original } }) => {
          return (
            <CanIChangeBidData>
              <Button
                label="Delete"
                variant="unstyled"
                leftIcon={{ name: 'cancel' }}
                className="text-no-wrap"
                onClick={() => onRemoveMakeModel(original)}
              />
            </CanIChangeBidData>
          );
        },
      },
    ];

    hasChassis &&
      columnList.splice(2, 0, {
        Header: 'Chassis Make',
        accessor: 'chassisMake.makeName',
        sortable: false,
      });
    hasChassis &&
      columnList.splice(3, 0, {
        Header: 'Chassis Model',
        accessor: 'chassisModel.modelName',
        sortable: false,
      });

    return columnList;
  }, [hasChassis, onRemoveMakeModel]);

  return (
    <>
      <AFPTable
        key={`si-makes-table-${columns.length}`}
        data={lineItemsHavingMakeModel}
        columns={columns}
      />
      {!lineItemsHavingMakeModel.length && (
        <div className="text-center margin-top-neg-2 height-full">
          <EmptyState
            hasBackground
            containerStyles="padding-y-6 height-full"
            topText={<p>No Makes or Models have been assigned to this SIN.</p>}
          />
        </div>
      )}
    </>
  );
};

export const MakeModelTableDataType = PropTypes.arrayOf(
  PropTypes.shape({
    bidLineId: PropTypes.string,
    make: PropTypes.shape({
      makeName: PropTypes.string,
      approvalStatus: PropTypes.string,
    }),
    model: PropTypes.shape({
      modelName: PropTypes.string,
      approvalStatus: PropTypes.string,
    }),
    chassisMake: PropTypes.shape({
      makeName: PropTypes.string,
      approvalStatus: PropTypes.string,
    }),
    chassisModel: PropTypes.shape({
      modelName: PropTypes.string,
      approvalStatus: PropTypes.string,
    }),
    modelYear: PropTypes.number,
  }),
);

MakesAndModleTable.propTypes = {
  data: MakeModelTableDataType.isRequired,
  onRemoveMakeModel: PropTypes.func.isRequired,
  hasChassis: PropTypes.bool.isRequired,
};

export default MakesAndModleTable;
