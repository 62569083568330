export const statusMap = (value) => {
  let color;
  let text;

  switch (value) {
    case 'ToDo':
      color = 'Ready-Gray';
      text = 'To do';
      return { text, color };

    case 'Draft':
      color = 'Warning-Gray';
      text = 'Draft';
      return { text, color };

    case 'Closed':
      color = 'Inactive-Gray';
      text = 'Done';
      return { text, color };

    case 'InProgress':
      color = 'Info-Gray';
      text = 'In progress';
      return { text, color };

    default:
      color = 'Inactive-Gray';
      text = 'Done';
      return { text, color };
  }
};

export const taskStatusMapping = (status) => {
  if (status === 'Draft') {
    return 'To-Do';
  }
  if (status === 'ToDo') {
    return 'In progress';
  }
  if (status === 'InProgress') {
    return 'Closed';
  }
  if (status === 'Closed') {
    return 'To-Do';
  }
  return 'To-Do';
};

export const TaskStatus = (status) => {
  if (status === 'ToDo') {
    return 'To-do';
  }
  if (status === 'Draft') {
    return 'Draft';
  }
  if (status === 'InProgress') {
    return 'In progress';
  }
  return 'Done';
};
