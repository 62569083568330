import React, { useState } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { useFederalStandards } from '../../fvs-provider';
import { getVehicleClassificationHierarchy } from '../../../../utilities/options-helper';

const filterKeys = ['vehicleType', 'vehicleGroup', 'fedStandardCode'];

const PreBidSummaryFilters = ({ currentFilters, setFilters }) => {
  const {
    selectedYear,
    selectedOptions,
    vehicleClassificationOptions,
    applicableFvsYears,
    setFvsContextData,
  } = useFederalStandards();

  const { FilterPanel: PreBidSummaryComponentFilterPanel } = FilterPanel;

  const [reRenderKey, setReRenderKey] = useState(1);

  const [defaultFilters, setDefaultFilters] = useState({
    year: selectedYear.toString() || new Date().getFullYear().toString(),
    vehicleType: selectedOptions?.vehicleType || '',
    vehicleGroup: selectedOptions?.vehicleGroup || '',
    fedStandardCode: selectedOptions?.fedStandardCode || '',
  });

  // eslint-disable-next-line
  let initialFilterStructure = [
    {
      key: 'year',
      title: 'Contract year',
      type: 'select',
      value: defaultFilters.year,
      operator: '$or',
      options: applicableFvsYears?.map((year) => ({
        label: year.label,
        value: year.value,
      })),
      expanded: true,
      hideClear: true,
    },
    {
      key: 'vehicleType',
      title: 'Vehicle type',
      type: 'select',
      options: vehicleClassificationOptions?.vehicleType || [],
      value: defaultFilters.vehicleType,
      operator: '$or',
      expanded: true,
      hideClear: true,
    },
    {
      key: 'vehicleGroup',
      title: 'Vehicle group',
      type: 'select',
      options: vehicleClassificationOptions?.vehicleGroup || [],
      value: defaultFilters.vehicleGroup,
      operator: '$or',
      expanded: true,
      hideClear: false,
    },
    {
      key: 'fedStandardCode',
      title: 'Federal Standard Code',
      type: 'select',
      options: vehicleClassificationOptions?.fedStandardCode || [],
      value: defaultFilters.fedStandardCode,
      operator: '$exact',
      expanded: true,
      hideClear: true,
    },
  ];

  const handleFiltersChange = (updatedFilters) => {
    const filterByKeys = updatedFilters?.reduce(
      (filterObj, currentFilterObj) => {
        return { ...filterObj, [currentFilterObj?.key]: currentFilterObj };
      },
      {},
    );

    const newFilter = {
      ...currentFilters,
      value: [...updatedFilters.filter((f) => !filterKeys.includes(f.key))],
    };

    if (filterByKeys?.year?.value) {
      setFvsContextData(
        'SET_SELECTED_YEAR',
        Number.parseInt(filterByKeys?.year?.value, 10),
      );
    }

    if (filterByKeys?.vehicleType?.value) {
      const vehicleClassificationHierarchy = getVehicleClassificationHierarchy(
        vehicleClassificationOptions,
        {
          year:
            filterByKeys?.year?.value || new Date().getFullYear().toString(),
          fedStandardCode: filterByKeys?.fedStandardCode?.value || '',
          vehicleGroup: filterByKeys?.vehicleGroup?.value || '',
          vehicleType: filterByKeys?.vehicleType?.value || '',
        },
        selectedOptions,
      );

      // setFvsContextData('SET_SELECTED_OPTIONS', {
      //   ...selectedOptions,
      //   vehicleType: filterByKeys?.vehicleType?.value,
      //   vehicleGroup: '',
      //   fedStandardCode: '',
      // });
      setDefaultFilters({
        ...defaultFilters,
        vehicleType: filterByKeys?.vehicleType?.value,
      });
      setFvsContextData('SET_SELECTED_OPTIONS', {
        fedStandardCode: vehicleClassificationHierarchy?.fedStandardCode,
        vehicleGroup: vehicleClassificationHierarchy?.vehicleGroup,
        vehicleType: vehicleClassificationHierarchy?.vehicleType,
      });
    }
    if (filterByKeys?.vehicleGroup?.value) {
      const vehicleClassificationHierarchy = getVehicleClassificationHierarchy(
        vehicleClassificationOptions,
        {
          year:
            filterByKeys?.year?.value || new Date().getFullYear().toString(),
          fedStandardCode: filterByKeys?.fedStandardCode?.value || '',
          vehicleGroup: filterByKeys?.vehicleGroup?.value || '',
          vehicleType: filterByKeys?.vehicleType?.value || '',
        },
        selectedOptions,
      );
      setDefaultFilters({
        ...defaultFilters,
        vehicleGroup: filterByKeys?.vehicleGroup?.value,
      });
      setFvsContextData('SET_SELECTED_OPTIONS', {
        fedStandardCode: vehicleClassificationHierarchy?.fedStandardCode,
        vehicleGroup: vehicleClassificationHierarchy?.vehicleGroup,
        vehicleType: vehicleClassificationHierarchy?.vehicleType,
      });
    }

    if (filterByKeys?.fedStandardCode?.value) {
      const vehicleClassificationHierarchy = getVehicleClassificationHierarchy(
        vehicleClassificationOptions,
        {
          year:
            filterByKeys?.year?.value || new Date().getFullYear().toString(),
          fedStandardCode: filterByKeys?.fedStandardCode?.value || '',
          vehicleGroup: filterByKeys?.vehicleGroup?.value || '',
          vehicleType: filterByKeys?.vehicleType?.value || '',
        },
        selectedOptions,
      );
      // selectedOptions?.vehicleGroup = '';
      // selectedOptions?.vehicleType = ''
      setDefaultFilters({
        ...defaultFilters,
        fedStandardCode: filterByKeys?.fedStandardCode?.value,
      });
      setFvsContextData('SET_SELECTED_OPTIONS', {
        fedStandardCode: vehicleClassificationHierarchy?.fedStandardCode,
        vehicleGroup: vehicleClassificationHierarchy?.vehicleGroup,
        vehicleType: vehicleClassificationHierarchy?.vehicleType,
      });
    }

    setFilters(newFilter);
  };

  return (
    <PreBidSummaryComponentFilterPanel
      key={reRenderKey} // This is the key that will force the component to rerender
      clearButtonLabel="Reset All"
      filterStructure={initialFilterStructure}
      model="PreBidSummary"
      order=""
      setQueryFiltersState={handleFiltersChange}
      showClearIcon
      handleClearAll={() => {
        setReRenderKey(reRenderKey + 1);
      }}
      retainFilters={false}
    />
  );
};

PreBidSummaryFilters.propTypes = {
  currentFilters: PropTypes.shape({
    operator: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]),
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default PreBidSummaryFilters;
