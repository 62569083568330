import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  TextInput,
  SelectDropdown,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';

import { INPUT_DROPDOWN_DEFAULT_OPTION, COLOR_TYPE } from './colors-table-defs';
import { useContractLine } from '../provider/contract-line-provider';

import { ReadOnlyWrapper } from '../helpers/ec-components';

const AddColor = () => {
  const {
    contractLine,
    isAwardedInFleet,
    colorsTabData,
    setColorsTabData,
    gsaColors,
    setAlert,
    canUpdateContract,
    canVendorUpdateContract,
  } = useContractLine();

  const canUpdateColor = canUpdateContract || canVendorUpdateContract;

  const colors = colorsTabData || [];

  const requiredText = 'This is a required field';
  const schema = yup.object().shape({
    vendorColorName: yup.string().required(requiredText),
    vendorColorCode: yup
      .string()
      .required(requiredText)
      .test(
        'color-exists',
        'Color code already exists',
        // eslint-disable-next-line
        function (code) {
          // eslint-disable-next-line
          const { existingColors } = this.parent;
          return !existingColors.some(
            (c) => c.vendorColorCode.toUpperCase() === code.toUpperCase(),
          );
        },
      ),
    gsaColorCodeId: yup.string().when([], {
      is: () => isAwardedInFleet,
      then: yup.string().required(requiredText),
      otherwise: yup.string(),
    }),
    colorType: yup.string().required(requiredText),
    price: yup.number().when(['colorType'], {
      is: (colorType) => isAwardedInFleet && colorType === COLOR_TYPE.optional,
      then: yup
        .number()
        .integer('Must be a whole number')
        .typeError(requiredText)
        .min(1, 'Must be $1 or more')
        .max(9999, 'Must be less than $10,000')
        .required(),
    }),
  });

  const { errors, watch, control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      vendorColorName: '',
      vendorColorCode: '',
      gsaColorCodeId: '',
      colorType: !isAwardedInFleet ? COLOR_TYPE.standard : '',
      price: '',
      existingColors: colors,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const colorType = watch('colorType');

  useEffect(() => {
    setValue('existingColors', colors);
  }, [colors]);

  const gsaColorOptions = [INPUT_DROPDOWN_DEFAULT_OPTION];
  gsaColors?.forEach((color) =>
    gsaColorOptions.push({ label: color.title, value: color.id }),
  );

  const colorTypeOptions = canUpdateContract
    ? [
        INPUT_DROPDOWN_DEFAULT_OPTION,
        { label: 'Standard', value: COLOR_TYPE.standard },
        { label: 'Optional', value: COLOR_TYPE.optional },
      ]
    : [
        INPUT_DROPDOWN_DEFAULT_OPTION,
        { label: 'Standard', value: COLOR_TYPE.standard },
      ];

  const onSubmit = useCallback(
    (data) => {
      setAlert(null);
      setColorsTabData((prev) => {
        const newColor = {
          contractLineId: +contractLine.id,
          vendorColorName: data.vendorColorName,
          vendorColorCode: data.vendorColorCode,
          gsaColorCodeId: +data.gsaColorCodeId,
          standardPremiumCode: data.colorType,
          price: data.price,
        };

        if (!prev) {
          reset();
          return [newColor];
        }
        const existingColor = prev.find(
          (c) => c.vendorColorCode === data.vendorColorCode,
        );

        if (existingColor) {
          setAlert({
            type: 'error',
            message: 'Color code already exists',
          });
          return prev;
        }
        reset();
        return [newColor, ...prev];
      });
    },
    [contractLine],
  );

  const genLabel = (label, required) => (
    <span className="text-bold">
      {label} {required && <RequiredFieldIndicator />}
    </span>
  );

  return (
    <div className="bg-primary-lightest padding-bottom-2">
      <form
        id="commenting-period-schedule-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="existingColors"
          control={control}
          render={() => null}
        />
        <div className="display-flex flex-row flex-justify flex-align-end margin-x-2">
          <div
            className={`grid-col-2 ${
              errors.vendorColorName ? 'padding-left-2' : ''
            }`}
          >
            <Controller
              name="vendorColorName"
              control={control}
              defaultValue=""
              render={({ name, value, onChange, onBlur }) => (
                <ReadOnlyWrapper>
                  <TextInput
                    name={name}
                    data-testid="add-color-vendor-color-name"
                    type="text"
                    label={genLabel('Vendor color name', true)}
                    maxLength={30}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={errors.vendorColorName?.message}
                    disabled={!canUpdateColor}
                  />
                </ReadOnlyWrapper>
              )}
            />
          </div>
          <div
            className={`grid-col-2 ${
              errors.vendorColorCode ? 'padding-left-2' : ''
            }`}
          >
            <Controller
              name="vendorColorCode"
              control={control}
              render={({ name, value, onChange, onBlur }) => (
                <ReadOnlyWrapper>
                  <TextInput
                    name={name}
                    data-testid="add-color-vendor-color-code"
                    type="text"
                    label={genLabel('Vendor color code', true)}
                    maxLength={10}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={errors.vendorColorCode?.message}
                    disabled={!canUpdateColor}
                  />
                </ReadOnlyWrapper>
              )}
            />
          </div>
          <div
            className={`grid-col-2 ${
              errors.gsaColorCodeId ? 'padding-left-2' : ''
            }`}
          >
            <Controller
              name="gsaColorCodeId"
              control={control}
              render={({ name, value, onChange, onBlur }) => (
                <ReadOnlyWrapper>
                  <SelectDropdown
                    name={name}
                    id="add-color-gsa-color"
                    data-testid="add-color-gsa-color"
                    label={genLabel('GSA color', isAwardedInFleet)}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={errors.gsaColorCodeId?.message}
                    options={gsaColorOptions}
                    disabled={!canUpdateColor}
                  />
                </ReadOnlyWrapper>
              )}
            />
          </div>
          <div
            className={`grid-col-2 ${errors.colorType ? 'padding-left-2' : ''}`}
          >
            <Controller
              name="colorType"
              control={control}
              render={({ name, value, onChange, onBlur }) => (
                <ReadOnlyWrapper>
                  <SelectDropdown
                    name={name}
                    id="add-color-is-optional"
                    data-testid="add-color-standard-optional"
                    label={genLabel('Standard or optional', true)}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={errors.colorType?.message}
                    options={colorTypeOptions}
                    disabled={!isAwardedInFleet || !canUpdateColor}
                  />
                </ReadOnlyWrapper>
              )}
            />
          </div>
          <div className={`grid-col-2 ${errors.price ? 'padding-left-2' : ''}`}>
            {isAwardedInFleet && colorType === 'Premium' && (
              <Controller
                name="price"
                control={control}
                render={({ name, value, onChange, onBlur }) => (
                  <ReadOnlyWrapper>
                    <TextInput
                      name={name}
                      data-testid="add-color-price"
                      type="number"
                      prefix="$"
                      label={genLabel('Price', true)}
                      maxLength={10}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={errors.price?.message}
                      disabled={!canUpdateColor}
                    />
                  </ReadOnlyWrapper>
                )}
              />
            )}
          </div>
          <div className="text-right">
            <Button
              type="submit"
              variant="outline"
              label="Add"
              leftIcon={{ name: 'add' }}
              className="margin-left-2 margin-right-0"
              disabled={!canUpdateColor}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddColor;
