import React from 'react';
import {
  Label,
  Button,
  Spinner,
  SelectDropdown,
} from '@gsa/afp-component-library';
import { useHistory } from 'react-router-dom';
import { useFederalStandards } from '../../fvs-provider';

// eslint-disable-next-line react/prop-types
const FVSSelectSI = () => {
  const history = useHistory();
  const {
    selectedYear,
    selectedOptions,
    standardItemsAsOptions,
    isSILoading,
    setFvsContextData,
    standardItem,
  } = useFederalStandards();

  const options =
    standardItemsAsOptions?.length > 0
      ? [
          {
            value: '',
            label: '- Select a Standard Item -',
            defaultValue: true,
          },
          ...standardItemsAsOptions,
        ]
      : [{ value: '', label: 'No results found' }];

  const onSIChange = (ev) => {
    setFvsContextData('SET_SELECTED_STANDARD_ITEM', ev.target.value);
  };

  const handleSubmitParams = () => {
    history.push(
      `/public/fvs/vehicle-standards/requirements/${selectedYear}/${selectedOptions.fedStandardCode}/${selectedOptions.standardItemNumber}`,
    );
  };

  return (
    <div className="margin-top-4">
      <Label htmlFor="StandardItem">
        <strong>Standard Item</strong>
      </Label>

      <p className="margin-top-05 margin-bottom-05 text-base">
        Select related Standard Item
      </p>

      {!standardItemsAsOptions || isSILoading ? (
        <Spinner size="small" />
      ) : (
        <SelectDropdown
          name="standardItem"
          data-testid="si-options"
          className="maxw-tablet"
          onChange={onSIChange}
          defaultValue={-1}
          options={options}
        />
      )}

      <Button
        disabled={!standardItem}
        className="margin-top-3"
        onClick={() => handleSubmitParams()}
        data-testid="si-option-view-btn"
        label="View Standard Item"
      />
    </div>
  );
};

export default FVSSelectSI;
