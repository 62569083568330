import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { GET_STANDARD_ITEM } from '../../services/data-store';
import Breadcrumbs from '../../widgets/breadcrumbs';
import './standard-item-detail.scss';
import AssociationTable from './standard-item-equipment-association-table';
import StandardItemProvider from './standard-item-provider';
import AssociationMessages from './standard-item-equipment-messages';
import StandardItemPageTitle from './standard-item-page-title';
import StandardItemInfo from './standard-item-info';

const StandardItemDetail = () => {
  const history = useHistory();
  const [passedData, setPassedData] = useState(history.location.state);

  const [, setError] = useState({});
  const pageParams = useParams();

  const [getStandardItem] = useLazyQuery(GET_STANDARD_ITEM, {
    fetchPolicy: 'network-only',
    onCompleted: (responseData) => {
      if (responseData?.getStandardItem) {
        setPassedData(responseData.getStandardItem);
      }
    },
    onError: (responseError) => {
      setError((prev) => ({ ...prev, GET_STANDARD_ITEM: responseError }));
    },
  });

  // Called only if user loads page at /standard-item/:key
  // i.e.: user didn't start from standard items table => Manage Equipment Codes.
  const loadData = () => {
    const { key } = pageParams;

    if (!key) return;

    const [standardItemNumber, year] = key.split('-');
    // Invalid param so don't try to fetch data.
    if (!standardItemNumber.length || !year.length) return;

    getStandardItem({
      variables: {
        filter: {
          operator: 'AND',
          value: [
            {
              operator: 'EQ',
              key: 'standardItemNumber',
              value: standardItemNumber,
            },
            { operator: 'EQ', key: 'year', value: year },
          ],
        },
      },
    });
  };

  useEffect(() => {
    loadData();
  }, [history.location.state]);

  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs.home}/home`,
      label: 'Home',
    },
    {
      location: '/catalog/standard-items',
      label: 'Standard Items',
    },
  ];

  return (
    <StandardItemProvider selected={passedData}>
      <div className="grid-col">
        <Breadcrumbs path={breadcrumbsPath} current="Standard Items detail" />
        <AssociationMessages />
        <StandardItemPageTitle />
        {passedData && <StandardItemInfo />}
        <AssociationTable />
      </div>
    </StandardItemProvider>
  );
};

export default StandardItemDetail;
