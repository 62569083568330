import moment from 'moment';
import { isRenewActionFlow } from '../route-utils';

export const solcitationStatus = {
  open: 'Open',
};

export const SolicitationOprType = {
  EDIT_SOLICITATION: 'Edit Solicitation',
  NEW_SOLICITATION: 'New Solicitation',
  RENEW_SOLICITATION: 'Renew Solicitation',
};

export const verifyIsSolicitationStatusOpen = (status, dateTime) =>
  status === solcitationStatus.open &&
  moment(dateTime).isSameOrBefore(moment());

export const getSolicitationOprType = () => {
  // const { search } = useLocation();
  // const solicitaionProcessIdentifier = new URLSearchParams(search).get(
  //   ACTION_PARAM_KEY,
  // );
  if (
    window.location.pathname.includes('edit-solicitation') &&
    isRenewActionFlow()
  ) {
    return SolicitationOprType.RENEW_SOLICITATION;
  }
  if (window.location.pathname.includes('edit-solicitation')) {
    return SolicitationOprType.EDIT_SOLICITATION;
  }
  return SolicitationOprType.NEW_SOLICITATION;
};
