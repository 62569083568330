import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@gsa/afp-component-library';

const CommentForm = ({ addComment }) => {
  const [commentBody, setCommentBody] = useState('');

  const submitComment = () => {
    if (commentBody && commentBody.length > 0) {
      addComment(commentBody);
    }
  };

  return (
    <>
      <div className="grid-row">
        <div className="grid-col-10">
          <div>Comment</div>
          <div>
            <textarea
              data-testid="standard-item-comment-box"
              className=" height-card width-full comment"
              aria-label="comment"
              value={commentBody}
              onChange={(e) => setCommentBody(e.target.value)}
              maxLength={2000}
            />
            <span
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex="0"
              id="characterAllowed"
              className="text-base"
              aria-labelledby="characterAllowed"
            >
              2000 characters allowed
            </span>
            <div className="comment-actions margin-bottom-3">
              <Button
                data-testid="submitCommentBtn"
                variant="primary margin-05"
                onClick={() => {
                  submitComment();
                  setCommentBody('');
                }}
                label="Post Comment"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CommentForm.propTypes = {
  addComment: PropTypes.func.isRequired,
};

export default CommentForm;
