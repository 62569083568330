import React from 'react';
import { useStandards } from './standards-provider';
import StandardItemForm from '../../components/forms/standard-item-form';
import StandardItemFormContext from '../../components/forms/standard-item-form-context';

const StandardItemAdd = () => {
  const { multipleOptions, createStandardItem } = useStandards();

  const handleSave = async (data) => {
    const tags = { value: [] };
    if (data?.tags) {
      tags.value = data.tags;
    }
    const {
      copyStandardItem,
      copyFromYear,
      copyFromStandardItemId,
      year,
      ...others
    } = data;
    const standardItemInput = {
      ...others,
      tags,
      year: parseInt(year, 10),
    };
    if (copyFromStandardItemId) {
      standardItemInput.copyFromStandardItemId = copyFromStandardItemId;
      standardItemInput.copyFromYear = parseInt(copyFromYear, 10);
    }
    await createStandardItem({
      variables: {
        standarditem: standardItemInput,
      },
    });
  };

  return (
    <StandardItemFormContext multipleOptions={multipleOptions}>
      <StandardItemForm
        formId="standard-item-add-form"
        years={multipleOptions?.futureYears}
        standardItemTags={multipleOptions?.tags}
        fedStandardCodes={multipleOptions?.fedStandardCode}
        vehicleGroups={multipleOptions?.vehicleGroup}
        vehicleTypes={multipleOptions?.vehicleType}
        fastCodes={multipleOptions?.fastCodes}
        handleSave={handleSave}
        isAddForm
      />
    </StandardItemFormContext>
  );
};

export default StandardItemAdd;
