import { FilterTableFrame } from '@gsa/afp-component-library';
import AddStandardItem from './add-standard-items';
import StandardItemsFilter from './standard-items-filters';
import StandardItemsTable from './standard-items-table';

export default FilterTableFrame(
  null,
  AddStandardItem,
  StandardItemsFilter,
  StandardItemsTable,
);
