import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  ADD_OR_UPDATE_SOLICITATION_PERIOD,
  GET_SOLICITATION_BY_ID_WITH_CONTRACTS,
} from '../../queries';
import { getTimestampFromDateNTime } from '../common/reopen-periods/open-reopen-period-util';
import solicitationNotifications from '../../utils/solicitation-notification-helpers';
import { reopenPeriodModalVendorIds } from '../../atoms/solicitation-helpers';
import { MessageType } from '../../../../components/Toast/toast';

const useEditReopenPeriod = ({ solicitationID, currentPeriodData } = {}) => {
  const setSolicitationNotification = useSetRecoilState(
    solicitationNotifications,
  );

  const [vndrIds, setVendorIds] = useRecoilState(reopenPeriodModalVendorIds);

  const { period, id: periodId } = currentPeriodData || {};
  const [addSolicitationPeriod] = useMutation(
    ADD_OR_UPDATE_SOLICITATION_PERIOD,
    {
      refetchQueries: [
        {
          query: GET_SOLICITATION_BY_ID_WITH_CONTRACTS,
          variables: {
            id: parseFloat(solicitationID),
          },
        },
      ],
      onError: (requestError) => {
        setSolicitationNotification((prevNotifications) => [
          {
            id: 'ADD_OR_UPDATE_SOLICITATION_PERIOD_ERROR',
            message: requestError.message,
            type: MessageType.ERROR,
            closeable: false,
            showInModal: false,
          },
          ...(prevNotifications || []),
        ]);
      },
      onCompleted: () => {
        setVendorIds([]);
        setSolicitationNotification([
          {
            id: period,
            message: `Sucessfully edited ${period}.`,
            type: MessageType.SUCCESS,
            closeable: true,
            showInModal: false,
          },
        ]);
      },
    },
  );

  const submitEditReopenPeriod = (data) => {
    const { startDate, endDate, startTime, endTime, audience } = data || {};
    if (startDate && endDate && startTime && endTime)
      setSolicitationNotification([]);
    addSolicitationPeriod({
      variables: {
        solicitationPeriodInput: {
          solicitationID: Number(solicitationID),
          audience,
          startDate: getTimestampFromDateNTime(startDate, startTime),
          endDate: getTimestampFromDateNTime(endDate, endTime),
          periodType: 'R',
          id: Number(periodId),
          vendorIds: vndrIds?.length
            ? vndrIds
            : currentPeriodData.solicitationPeriodVendors.map((v) => {
                return {
                  vendorId: v.vendorId,
                  audienceType: v.audienceType,
                };
              }),
        },
      },
    });
  };

  useEffect(() => () => setVendorIds([]), []);
  return {
    submitEditReopenPeriod,
  };
};

export default useEditReopenPeriod;
