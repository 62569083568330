import React, { createContext, useContext, useState } from 'react';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_VENDORS_BY_PERMISSION } from '../../../../services/data-store';
import { OPERATIONS, SUBJECTS } from '../../../../utilities/constants';

export const VendorInfoContext = createContext({});

export const VendorInfoProvider = ({ children }) => {
  const [vendorInfoForAdmin, setVendorInfoForAdmin] = useState(null);
  const [selectedVendorSearchOption, setSelectedVendorSearchOption] = useState(
    null,
  );
  const [alert, setAlert] = useState(null);

  const ability = useAppAbility();
  const { currentUser } = useCurrentUser() || {};
  const isHasAdminPrivilegeReady = ability !== undefined && ability !== null;

  const hasAdminPrivilege =
    ability.can(OPERATIONS.Manage, SUBJECTS.All) || false;

  // dont call this api for admin login, only call for vehicle suppliers
  const {
    data: vendor,
    error: vendorDataLoadingError,
    loading: isVendorDataLoading,
    called: isVendorDataCalled,
  } = useQuery(GET_VENDORS_BY_PERMISSION, {
    fetchPolicy: 'network-only',
    variables: {
      operation: 'view',
      subject: 'Vendor',
    },
    skip: !currentUser?.id || hasAdminPrivilege,
  });

  const hasDraftInactiveVendors = vendor?.getVendorsByPermission?.some(
    (vndr) => vndr.fleetStatus === 'Draft' || vndr.fleetStatus === 'Inactive',
  );

  return (
    <VendorInfoContext.Provider
      value={{
        currentUser,
        vendorDataLoadingError,
        vendor: vendor?.getVendorsByPermission[0],
        isVendorDataLoading,
        isVendorDataCalled,
        vendorInfoForAdmin,
        setVendorInfoForAdmin,
        hasAdminPrivilege,
        isHasAdminPrivilegeReady,
        hasDraftInactiveVendors,
        selectedVendorSearchOption,
        setSelectedVendorSearchOption,
        alert,
        setAlert,
      }}
    >
      {children}
    </VendorInfoContext.Provider>
  );
};

VendorInfoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useVendorInfo = () => useContext(VendorInfoContext);
