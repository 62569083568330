import React from 'react';
import MarkUpText from '../../../components/MarkUpText/MarkUpText';
import UserGuideLink from '../../../components/UserGuideLink/user-guide-link';

export const introduction =
  'Use this page to manage new and existing solicitations. If you need help creating or managing solicitations please reference the user guide for step-by-step instructions.';
export const userGuideLinkText = 'user guide';

const SolicitationIntroContent = () => {
  const userguideLinkProps = {
    name: 'solicitation-management.pdf',
    'data-testid': 'sol-user-guide',
  };
  return (
    <p className="measure-6 margin-bottom-6" data-testid="sol-intro-text">
      <MarkUpText
        content={introduction}
        textToMarkUp={userGuideLinkText}
        marker={UserGuideLink}
        markerProps={userguideLinkProps}
      />
    </p>
  );
};

export default SolicitationIntroContent;
