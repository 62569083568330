import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  Modal,
  connectModal,
  Label,
  Button,
  RadioButton,
  TextInput,
  SelectDropdown,
} from '@gsa/afp-component-library';
import { LineVersionFlag } from '../helpers/version-helpers';

const DEFAULT_OPTION = { value: '', label: '-Select-' };
export const GET_CONTRACT_LINE_MOD = gql`
  query($contractLineId: Float!) {
    getContractLineModifications(contractLineId: $contractLineId)
  }
`;

const ModificationSelectionModal = ({
  contractLineId,
  modFlag,
  onClose,
  onSave,
}) => {
  const [modTag, setModTag] = useState('');
  const [isVersion, setIsVersion] = useState(false);
  const [isNewMod, setIsNewMod] = useState(false);
  const [modOptions, setModOptions] = useState([DEFAULT_OPTION]);

  useQuery(GET_CONTRACT_LINE_MOD, {
    fetchPolicy: 'network-only',
    variables: { contractLineId },
    skip: !contractLineId,
    onCompleted: ({ getContractLineModifications: modTags }) => {
      setModOptions([
        DEFAULT_OPTION,
        ...modTags.map((tag) => ({ value: tag, label: tag })),
      ]);
    },
  });

  const onClickSave = () => {
    if (isVersion || modTag.trim()) {
      onSave(modTag.trim());
      onClose();
    }
  };

  return (
    <div className="afp-modal-wrapper line-modification-selection-modal">
      <div className="afp-modal-overlay">
        <Modal
          id="remove-standard-item-modal"
          variant="large"
          title={<h2>Review updates</h2>}
          onClose={onClose}
          actions={
            <div>
              <Button
                data-testid="bid-line-removal-modal-cancel-btn"
                variant="unstyled"
                onClick={onClose}
                label="Cancel"
              />
              <Button
                variant="primary"
                onClick={onClickSave}
                label="Save changes"
              />
            </div>
          }
        >
          <div>
            {modFlag === LineVersionFlag.Mod
              ? 'The updates you made triggered a modification. Assign an existing modification or create a new one.'
              : 'The updates you made can be considered either a contract line version or a modification. Make approperiate selection.'}
          </div>

          {modFlag !== LineVersionFlag.Mod && (
            <>
              <Label required className="text-bold">
                Select an option
              </Label>
              <div className="display-flex flex-row">
                <RadioButton
                  className="margin-right-4"
                  label="Save changes as a version"
                  checked={isVersion}
                  onClick={() => {
                    setIsVersion(true);
                    setModTag('');
                  }}
                />
                <RadioButton
                  label="Save changes as a modification"
                  checked={!isVersion}
                  onClick={() => setIsVersion(false)}
                />
              </div>
            </>
          )}
          {!isVersion && (
            <div className="bg-blue-5 margin-y-2 padding-x-4 padding-top-1 padding-bottom-4">
              <Label required className="text-bold">
                Select a modification option
              </Label>
              <div className="display-flex flex-row">
                <RadioButton
                  className="bg-blue-5 margin-right-4"
                  label="Apply changes to existing modification"
                  checked={!isNewMod}
                  onClick={() => {
                    setIsNewMod(false);
                    setModTag('');
                  }}
                />
                <RadioButton
                  className="bg-blue-5"
                  label="Enter new modification number"
                  checked={isNewMod}
                  onClick={() => {
                    setIsNewMod(true);
                    setModTag('');
                  }}
                />
              </div>
              <div className="margin-top-4">
                {isNewMod ? (
                  <TextInput
                    label="Enter a new modification number"
                    required
                    value={modTag}
                    onChange={({ target: { value } }) => setModTag(value)}
                  />
                ) : (
                  <SelectDropdown
                    label="Modification name"
                    required
                    options={modOptions}
                    value={modTag}
                    onChange={({ target: { value } }) => setModTag(value)}
                  />
                )}
              </div>
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};

ModificationSelectionModal.propTypes = {
  contractLineId: PropTypes.number.isRequired,
  modFlag: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default connectModal(ModificationSelectionModal);
