import React from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import { useFederalStandards } from '../../../fvs-provider';

const { FilterPanelField, FilterPanelFilterShape } = FilterPanel;

const FederalStandardCodeFilterItem = ({ filter }) => {
  const {
    vehicleClassificationOptions,
    selectedOptions,
  } = useFederalStandards();

  return (
    <>
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'select',
          options: vehicleClassificationOptions?.fedStandardCode
            ? vehicleClassificationOptions?.fedStandardCode?.map((fsc) => ({
                label: fsc.label,
                value: fsc.value,
              }))
            : [],
          value: (selectedOptions && selectedOptions?.fedStandardCode) || '',
        }}
      />
    </>
  );
};

FederalStandardCodeFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default FederalStandardCodeFilterItem;
