import React, { useCallback, useEffect, useMemo } from 'react';
import * as yup from 'yup';
import moment from 'moment';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { Button, Spinner, connectModal } from '@gsa/afp-component-library';
import useReopenPeriod from '../../apis/reopen-period-apis';

import SolicitationPeriod from '../../../components/solicitation-period';
import SolicitationNotification from '../../../utils/solicitation-notification';
import SolicitationHeader from '../../../components/solicitation-header';
import SolicitationStepper from '../../../components/solicitation-stepper';
import { reopenPeriodFieldProps } from '../../../constants';
import {
  POSSIBLE_DATE_FORMAT,
  isEmpty,
  timeValidation,
  checkIfTimeIsPast,
} from '../../../components/solicitation-form-context';
import ReopenPeriodsTable from '../../../components/reopen-periods-table';

import { getTimestampFromDateNTime } from '../../common/reopen-periods/open-reopen-period-util';
import EditReopenPeriodModal from '../../../components/edit-reopen-period-modal';
import useReopenPeriodsRowActions from './use-reopen-periods-row-actions';
import solicitationNotifications from '../../../utils/solicitation-notification-helpers';
import SolicitationModal from '../../../solicitation-modal';
import biddingType, { vendorIds } from '../../../atoms/solicitation-helpers';
import { sortDateAscendingWithAccessors } from '../../../../../utilities/date-helpers';
import { getSolicitationPeriodMinDate } from '../../../utils/open-periods-helpers';

export const parseDateString = (value, originalValue) => {
  if (!originalValue) return null;
  const parsedDate =
    originalValue instanceof Date && originalValue
      ? originalValue
      : moment(originalValue, POSSIBLE_DATE_FORMAT, true).toDate();
  return parsedDate;
};

const ReopenPeriods = () => {
  const history = useHistory();
  const setSolicitationNotification = useSetRecoilState(
    solicitationNotifications,
  );

  const { id } = useParams();

  const {
    solicitationLoading,
    solicitation,
    solicitationID,
    openPeriod,
    reopenPeriods,
    solicitationContract,
    getSolicitationById,
    addSolicitationPeriod,
    getContractsBySolicitationId,
    solicitationContractLoading,
    addPeriodLoading,
  } = useReopenPeriod();

  const reopenPeriodListDescendingOrder = [...reopenPeriods]?.reverse();

  const newReopenPeriodMinTimestamp =
    reopenPeriodListDescendingOrder?.[0]?.endDate || openPeriod?.endDate;

  const newReopenPeriodMinDateFormated = newReopenPeriodMinTimestamp
    ? moment(newReopenPeriodMinTimestamp).format('MM/DD/YYYY')
    : getSolicitationPeriodMinDate(solicitation.contractYear);

  const solicitationOprType = window.location.pathname.includes(
    'edit-solicitation',
  )
    ? 'edit'
    : 'new';

  const previousPageUrl = `/catalog/solicitations/${
    openPeriod.startDate ? 'edit' : 'new'
  }-solicitation/${Number(solicitationID)}/open-period`;

  const nextPageUrl = window.location.pathname.includes('edit-solicitation')
    ? `/catalog/solicitations/edit-solicitation/${Number(
        solicitationID,
      )}/select-sin`
    : `/catalog/solicitations/new-solicitation/${Number(
        solicitationID,
      )}/select-sin`;
  const navigate = (pathname) => () =>
    history.push({ pathname, search: history.location.search });

  const navigatePreviousPage = navigate(previousPageUrl);
  const navigateNextPage = navigate(nextPageUrl);
  const schema = yup.object().shape({
    startDate: yup.date().when('rawSolPeriod.status', {
      is: (status) => status !== 'ACTIVE',
      then: yup
        .date()
        .required('Posting date is required.')
        .typeError(' ')
        .transform(parseDateString)
        .nullable()
        .min(
          moment(openPeriod?.endDate).format('MM/DD/YYYY'),
          'Posting date should not be the same as open period.',
        )
        .min(
          newReopenPeriodMinDateFormated,
          'Posting date should not be the same as other reopen or mid-cycle period.',
        ),
    }),

    endDate: yup
      .date()
      .required('Closing date is required.')
      .typeError(' ')
      .transform(parseDateString)
      .nullable()
      .when('startDate', {
        is: (startDate) => !isEmpty(startDate),
        then: yup
          .date()
          .required('Closing date is required.')
          .typeError(' ')
          .transform(parseDateString)
          .nullable()
          .min(
            yup.ref('startDate'),
            'Closing date must be after posting date.',
          ),
      }),
    startTime: yup
      .string()
      .required('Posting time is required.')
      .when(['startDate'], {
        is: (startDate) =>
          moment(startDate).isSame(new Date(), 'day') && startDate,
        then: yup
          .string()
          .required('Posting time is required.')
          .test(
            'is-valid',
            'Posting time cannot be in the past.',
            checkIfTimeIsPast,
          ),
      })
      .when(['startDate', 'endDate'], {
        is: (startDate, endDate) =>
          !isEmpty(endDate) &&
          moment(startDate).isSame(moment(newReopenPeriodMinDateFormated)),
        then: yup
          .string()
          .required('Posting time is required.')
          .test(
            'min-start-time',
            'Posting time must be after the last open period.',
            /* eslint-disable */
            function (value) {
              const { startDate } = this.parent || {};
              /* eslint-enable */
              return moment(
                getTimestampFromDateNTime(startDate, value),
              ).isAfter(moment(newReopenPeriodMinTimestamp));
            },
          ),
      })
      .test({
        name: 'min-start-time',
        skipAbsent: true,
        test(startTime, { createError, parent }) {
          const { startDate } = parent || {};
          const startDateTime = getTimestampFromDateNTime(startDate, startTime);
          if (moment().isAfter(moment(startDateTime), 'minutes'))
            return createError({
              message: 'Posting time should not be less than the current time.',
            });
          if (
            moment(startDateTime).isSameOrBefore(
              moment(newReopenPeriodMinTimestamp),
              'minutes',
            )
          )
            return createError({
              message:
                solicitationContract.length > 0
                  ? 'The new Mid-cycle period added overlaps with an existing Mid-cycle period.'
                  : `The new Reopen period added overlaps with an existing ${
                      reopenPeriodListDescendingOrder?.[0]?.endDate
                        ? 'Reopen'
                        : 'Open'
                    } period.`,
            });

          return true;
        },
      }),
    endTime: yup
      .string()
      .required('Closing time is required.')
      .when(['endDate'], {
        is: (endDate) => moment(endDate).isSame(new Date(), 'day') && endDate,
        then: yup
          .string()
          .required('Closing time is required.')
          .test(
            'is-valid',
            'Closing time cannot be in the past.',
            checkIfTimeIsPast,
          ),
      })
      .when(['startDate', 'endDate', 'startTime'], {
        is: (startDate, endDate, startTime) =>
          moment(startDate).isSame(endDate, 'day') && startTime,
        then: yup
          .string()
          .required('Closing time is required.')
          .test(
            'is-greater',
            'Closing time must be after posting time.',
            timeValidation,
          ),
      }),
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    trigger,
    formState: { errors },
  } =
    useForm({
      resolver: yupResolver(schema),
      mode: 'onBlur',
      reValidateMode: 'onChange',
      defaultValues: {
        audience: 'Open',
        startTime: '',
        endTime: '',
        startDate: '',
        endDate: '',
      },
    }) || {};

  const getSolicitationData = (paramSolId) => {
    getSolicitationById({
      variables: {
        id: parseFloat(paramSolId || solicitationID),
      },
    });

    getContractsBySolicitationId({
      variables: {
        solicitationId: parseFloat(paramSolId || solicitationID),
      },
    });
  };

  const [, setBidType] = useRecoilState(biddingType);

  useEffect(() => {
    getSolicitationData(id);
  }, [id]);

  useEffect(() => {
    const { bidType: selectedBidType } = solicitation;
    setBidType(selectedBidType);
  }, [solicitation]);

  useEffect(() => {
    return () => {
      setSolicitationNotification([]);
    };
  }, []);

  const {
    showEditReopenPeriodModal,
    currentPeriodModalData,
    toggleEditReopenPeriodModal,
    showDeleteReopenPeriodModal,
    toggleDeleteReopenPeriodModal,
    handleRowActionEdit,
    handleRowActionDelete,
  } = useReopenPeriodsRowActions();

  const [selectedVendorIds] = useRecoilState(vendorIds);

  const idsArr = useMemo(() => {
    if (selectedVendorIds?.length > 0) {
      return selectedVendorIds;
    }
    const ids = currentPeriodModalData?.solicitationPeriodVendors?.map(
      (vendor) => ({
        vendorId: vendor?.vendorId,
        audienceType: vendor?.audienceType,
      }),
    );
    return ids;
  }, [selectedVendorIds, currentPeriodModalData]);

  const submitHandler = (data) => {
    const { startDate, endDate, startTime, endTime, audience } = data || {};

    if (openPeriod.startDate && openPeriod.endDate) {
      if (startDate && endDate && startTime && endTime) {
        addSolicitationPeriod({
          variables: {
            solicitationPeriodInput: {
              solicitationID: Number(solicitationID),
              audience,
              startDate: getTimestampFromDateNTime(startDate, startTime),
              endDate: getTimestampFromDateNTime(endDate, endTime),
              periodType: solicitationContract.length > 0 ? 'M' : 'R',
              vendorIds: idsArr,
            },
          },
        });
      }
    } else {
      setSolicitationNotification([
        {
          id: 'ADD_OR_UPDATE_SOLICITATION_PERIOD_ERROR',
          message:
            'Reopen or mid-cycle periods cannot be entered without an Open period.',
          type: 'error',
          closeable: false,
          showInModal: false,
        },
      ]);
    }
  };

  const getContractAwardDate = useCallback(
    () =>
      solicitation?.contracts
        ?.filter(
          (aContract) => aContract?.contractYear === solicitation?.contractYear,
        )
        .sort(sortDateAscendingWithAccessors('awardedDate'))[0]?.awardedDate,
    [solicitation.id],
  );

  const ConnectedDeleteSolReopenPeriodModal = connectModal(SolicitationModal);

  return (
    <>
      <EditReopenPeriodModal
        isOpen={showEditReopenPeriodModal}
        currentPeriodData={currentPeriodModalData}
        onClose={toggleEditReopenPeriodModal}
        previousPeriodData={
          reopenPeriods[currentPeriodModalData?.index - 1] || null
        }
        nextPeriodData={
          reopenPeriods[currentPeriodModalData?.index + 1] || null
        }
        solicitationID={solicitationID}
        openPeriod={openPeriod}
        solicitationStatus={solicitation?.status}
      />

      <ConnectedDeleteSolReopenPeriodModal
        isOpen={showDeleteReopenPeriodModal}
        onComplete={getSolicitationData}
        onClose={toggleDeleteReopenPeriodModal}
        solicitationID={solicitationID}
        currentPeriodData={currentPeriodModalData}
      />

      <SolicitationNotification isModal={false} />
      <SolicitationHeader
        solicitationNumber={solicitation?.solicitationNumber}
        solicitationStatus={solicitation?.status}
        solicitationType={solicitation?.solicitationType}
      />
      <SolicitationStepper currentStep={3} />
      {solicitationLoading || solicitationContractLoading ? (
        <>
          <Spinner />
        </>
      ) : (
        <>
          <form data-testId="re-open-period-form">
            {!addPeriodLoading && (
              <SolicitationPeriod
                title="(Optional) Enter your reopen periods or mid-cycle periods. These dates cannot overlap with your open period, or each other."
                contractYear={solicitation.contractYear}
                solicitationOprType={solicitationOprType}
                stepperStage="reopen"
                formRegister={register}
                formControl={control}
                formWatch={watch}
                formErrors={errors}
                formValidationTrigger={trigger}
                minDate={newReopenPeriodMinDateFormated}
                formWrapperClassName="padding-3"
                {...reopenPeriodFieldProps}
              >
                <Button
                  label="Add period"
                  leftIcon={{
                    name: 'add',
                  }}
                  onClick={handleSubmit(submitHandler)}
                  variant="outline"
                  className="width-card-lg"
                />
              </SolicitationPeriod>
            )}
          </form>
          <ReopenPeriodsTable
            data={
              !(addPeriodLoading || solicitationLoading) ? reopenPeriods : []
            }
            loading={addPeriodLoading || solicitationLoading}
            handleRowActionEdit={handleRowActionEdit}
            handleRowActionDelete={handleRowActionDelete}
            contractAwardDate={getContractAwardDate()}
          />
          <div className="grid-row padding-top-6">
            <div className="flex">
              <Button
                label="Previous"
                data-testid="re-open-period-previous-button"
                leftIcon={{
                  name: 'arrow_back',
                }}
                onClick={navigatePreviousPage}
                variant="outline"
              />

              <Button
                label="Next"
                data-testid="re-open-period-submit-button"
                rightIcon={{
                  name: 'arrow_forward',
                }}
                onClick={navigateNextPage}
                variant="primary"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ReopenPeriods;
