/* eslint-disable react/prop-types */
import { EmptyState } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React, { createContext } from 'react';
import './attachment.scss';
import AttachmentAdd from './AttachmentAdd';
import AttachmentMessages from './AttachmentMessages';
import AttachmentTable from './AttachmentTable';
import ComponentContext from './ComponentContext';

export const AttachmentContext = createContext();

const NoData = () => {
  return (
    <div
      data-testid="attachment-no-data"
      className="attachment-no-data padding-bottom-1"
    >
      <EmptyState
        hasBackground
        containerStyles="padding-y-8 margin-top-1"
        topText={
          <p>
            <strong>There are no available documents.</strong>
          </p>
        }
      />
    </div>
  );
};

NoData.defaultProps = {};

NoData.propTypes = {};

export default function Attachment({
  noDataTitle,
  noDataMessage,
  allowChanges = true,
  isFromTaskDetail = false,
  publicView = false,
  ...props
}) {
  const { title } = props;
  const NoDataMessage = React.useMemo(
    () => () => <NoData title={noDataTitle} />,
    [],
  );

  return (
    <ComponentContext {...props}>
      <>
        {title}

        <AttachmentMessages />

        {!isFromTaskDetail && allowChanges ? (
          <AttachmentAdd isFromTaskDetail={isFromTaskDetail} />
        ) : null}
        {!isFromTaskDetail && (
          <AttachmentTable
            noData={NoDataMessage}
            allowChanges={allowChanges}
            publicView={publicView}
          />
        )}

        {isFromTaskDetail ? (
          <div className="margin-bottom-3">
            Task documents can be found in the documents tab on the{' '}
            <a href="/catalog/vehicle-standards">FVS landing page</a>.
          </div>
        ) : null}
        {isFromTaskDetail && allowChanges ? (
          <AttachmentAdd isFromTaskDetail={isFromTaskDetail} />
        ) : null}
      </>
    </ComponentContext>
  );
}

Attachment.defaultProps = {
  title: null,
  data: [],
  count: 0,
  hasMore: false,
  uploadPath: '',
  loading: false,
  adding: false,
  added: false,
  updating: false,
  updated: false,
  deleting: false,
  deleted: false,
  linkedEntities: {},
  loadMoreLimit: 5,
  currentUser: { email: '' },
  errors: {
    fetch: { message: '' },
    save: { message: '' },
    edit: { message: '' },
    delete: { message: '' },
    download: { message: '' },
  },
  fileTypes:
    '.jpg,.jpeg,.png,.bmp,.pdf,.doc,.docx,.xls,.xlsx,.txt,.csv,.pptx,.ppt,.rtf',
  maxFileSize: 52428800, // 50MB
  noDataTitle: <h3>No Supporting Documents Available</h3>,
  noDataMessage: (
    <p>
      You don’t have any supporting documents at this time. Please check back
      again or start uploading documents. Input accepts a single file. Supported
      file formats are .jpg, .jpeg, .png, .bmp, .pdf, .doc, .docx, .rtf, .xls,
      .xlsx, .ppt, .pptx, .txt, .csv and file size should be under 5 MB.
    </p>
  ),
  setErrors: () => {
    // empty function
  },
  onSort: () => {
    // empty function
  },
  onDelete: () => {
    // empty function
  },
  onDownload: () => {
    // empty function
  },
  onLoadMore: () => {
    // empty function
  },
  onUpdateDescription: () => {
    // empty function
  },
  onAdd: () => {
    // empty function
  },
  allowChanges: true,
  isFromTaskDetail: false,
};

Attachment.propTypes = {
  title: PropTypes.node,
  data: PropTypes.arrayOf(Object),
  count: PropTypes.number,
  hasMore: PropTypes.bool,
  uploadPath: PropTypes.node,
  loading: PropTypes.bool,
  adding: PropTypes.bool,
  added: PropTypes.bool,
  updating: PropTypes.bool,
  updated: PropTypes.bool,
  deleting: PropTypes.bool,
  deleted: PropTypes.bool,
  linkedEntities: PropTypes.shape(Object),
  loadMoreLimit: PropTypes.number,
  paginationLimit: PropTypes.number.isRequired,
  currentUser: PropTypes.shape({
    email: PropTypes.string,
  }),
  errors: PropTypes.shape({
    fetch: PropTypes.shape(Object),
    save: PropTypes.shape(Object),
    update: PropTypes.shape(Object),
    delete: PropTypes.shape(Object),
    download: PropTypes.shape(Object),
  }),
  noDataTitle: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  noDataMessage: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  fileTypes: PropTypes.string,
  maxFileSize: PropTypes.number,
  setErrors: PropTypes.func,
  onSort: PropTypes.func,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onLoadMore: PropTypes.func,
  onUpdateDescription: PropTypes.func,
  onAdd: PropTypes.func,
  allowChanges: PropTypes.bool,
  isFromTaskDetail: PropTypes.bool,
};
