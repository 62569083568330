const getOptions = (options, optionKey) => {
  if (!options || !optionKey) {
    return [];
  }
  return options[optionKey];
};
const nextYear = new Date().getFullYear() + 1;

// eslint-disable-next-line import/prefer-default-export
export const getFilterStructure = (filterOptions) => [
  {
    key: 'year',
    title: 'Contract year',
    type: 'select',
    options: getOptions(filterOptions, 'year'),
    value: nextYear.toString(),
    operator: 'EQ',
    expanded: true,
    hideClear: true,
  },
  {
    key: 'fedStandardCode',
    title: 'Federal Standard',
    type: 'multiselect',
    options: getOptions(filterOptions, 'fedStandardCode'),
    value: [],
    operator: 'OR',
    expanded: true,
    hideClear: true,
  },
  {
    key: 'userType',
    title: 'User type',
    type: 'multiselect',
    options: getOptions(filterOptions, 'userType'),
    value: [],
    operator: 'OR',
    expanded: true,
    hideClear: true,
  },
  {
    key: 'origin',
    title: 'Origin',
    type: 'multiselect',
    options: getOptions(filterOptions, 'origin'),
    value: [],
    operator: 'OR',
    expanded: true,
    hideClear: true,
  },
];
