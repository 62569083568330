/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import { PropTypes } from 'prop-types';
import { Button } from '@gsa/afp-component-library';
import { useParams, useHistory } from 'react-router-dom';

const addIconProps = {
  name: 'add',
};
const AddStandardItem = ({ solicitation, isBidClosed }) => {
  const history = useHistory();
  const { solId, bidId } = useParams();
  return (
    !isBidClosed && (
      <Button
        leftIcon={addIconProps}
        variant="outline"
        label="Add Standard Items"
        onClick={() =>
          history.push(
            `/catalog/bids/${bidId}/${solId}/add-standard-items`,
            solicitation,
          )
        }
        className="usa-button float-right"
      />
    )
  );
};

AddStandardItem.propTypes = {
  solicitation: PropTypes.objectOf(PropTypes.any).isRequired,
  isBidClosed: PropTypes.bool.isRequired,
};

export default AddStandardItem;
