// eslint-disable-next-line no-unused-vars
import React from 'react';
import { SI_LABELS } from '../constants';
import VehicleTypeFilterItem from '../vehicle-type-filter-item';
import FedStandardCodeFilterItem from '../fed-standard-code-filter-item';

const getOptions = (options, optionKey) => {
  if (!options || !optionKey) {
    return [];
  }
  return options[optionKey];
};

const nextYear = new Date().getFullYear() + 1;

// eslint-disable-next-line import/prefer-default-export
export const getFilterStructure = (filterOptions, standardItemsFilter) => [
  {
    key: 'year',
    title: 'Contract year',
    type: 'select',
    options: getOptions(filterOptions, 'year'),
    value: standardItemsFilter?.year || nextYear.toString(),
    operator: 'EQ',
    expanded: true,
    hideClear: true,
  },
  {
    key: 'standardItemNumber',
    title: SI_LABELS.NUMBER,
    type: 'typeahead',
    value: standardItemsFilter?.standardItemNumber || '',
    operator: 'EQ',
    expanded: true,
    hideClear: true,
    customFieldProps: { inputCharNum: 1 },
  },
  {
    key: 'vehicleType',
    title: SI_LABELS.TYPE,
    type: 'select',
    component: VehicleTypeFilterItem,
    value: standardItemsFilter?.vehicleType || '',
    operator: 'EQ',
    expanded: true,
    hideClear: true,
  },
  {
    key: 'status',
    title: SI_LABELS.STATUS,
    type: 'multiselect',
    options: [
      { label: 'Active', value: 'Active' },
      { label: 'Inactive', value: 'Inactive' },
      { label: 'Archived', value: 'Archived' },
    ],
    value: standardItemsFilter?.status || ['Active'],
    operator: 'OR',
    expanded: true,
    hideClear: true,
  },
  {
    key: 'title',
    title: SI_LABELS.TITLE,
    type: 'typeahead',
    value: standardItemsFilter?.title || '',
    operator: 'EQ',
    expanded: true,
    hideClear: true,
    customFieldProps: { inputCharNum: 1 },
  },
  {
    key: 'fast',
    title: SI_LABELS.FASTCODE,
    type: 'select',
    options: getOptions(filterOptions, 'fastCodes'),
    value: standardItemsFilter?.fast || '',
    operator: 'EQ',
    expanded: true,
    hideClear: true,
  },
  {
    key: 'fedStandardCode',
    title: SI_LABELS.FED_CODE,
    type: 'select',
    component: FedStandardCodeFilterItem,
    value: standardItemsFilter?.fedStandardCode || '',
    operator: 'EQ',
    expanded: true,
    hideClear: true,
  },
  {
    key: 'tags',
    title: SI_LABELS.TAGS,
    type: 'multiselect',
    options: getOptions(filterOptions, 'tags'),
    value: standardItemsFilter?.tags || [],
    operator: 'JSON_CONTAINS',
    expanded: true,
    hideClear: true,
  },
];
