import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Button, Spinner } from '@gsa/afp-component-library';
import { useRecoilState } from 'recoil';
import useOpenPeriod from '../../apis/open-period-apis';
import { ADD_OR_UPDATE_SOLICITATION_PERIOD } from '../../../queries';
import SolicitationPeriod from '../../../components/solicitation-period';
import SolicitationNotification from '../../../utils/solicitation-notification';
import solicitationNotifications from '../../../utils/solicitation-notification-helpers';
import SolicitationHeader from '../../../components/solicitation-header';
import SolicitationStepper from '../../../components/solicitation-stepper';
import { SOLICITATION_STATUSES } from '../../../constants';
import { getOpenPeriodYupSchema } from '../../common/open-period/open-period-yup-schema';
import { getTimestampFromDateNTime } from '../../common/reopen-periods/open-reopen-period-util';
import { OpenSolicitationReadonlyEditPeriodEndDateTimeFields } from '../../../utils/solicitation-period-utils';
import { isMidCycleActionFlow, isRenewActionFlow } from '../../../route-utils';
import biddingType, { vendorIds } from '../../../atoms/solicitation-helpers';
import useSimpleAlertModal from '../../../../../hooks/use-simple-alert-modal';
import ClearReopenPeriodsModal from './clear-reopen-periods-modal';
import { getSolicitationPeriodMinDate } from '../../../utils/open-periods-helpers';

const OpenPeriod = () => {
  const history = useHistory();
  const [notifications, setSolicitationNotification] = useRecoilState(
    solicitationNotifications,
  );
  const [, setBidType] = useRecoilState(biddingType);

  const { id } = useParams();
  const {
    isModalOpen: showClearAdditionalPeriods,
    handleToggleModal: toggleClearShowAdditionalPeriods,
    handleOnModalProceed,
  } = useSimpleAlertModal();

  const {
    solicitationLoading,
    solicitation,
    solicitationID,
    openPeriod,
    currentPeriod,
    contracts,
    getSolicitationById,
    adjacentReopenPeriodStartDate,
  } = useOpenPeriod();

  const [selectedVendorIds] = useRecoilState(vendorIds);

  const idsArr = useMemo(() => {
    if (selectedVendorIds.length > 0) {
      return selectedVendorIds;
    }

    const ids =
      currentPeriod?.vendors?.map((vendor) => ({
        vendorId: vendor.vendorId,
        audienceType: vendor.audienceType,
      })) || [];

    return ids;
  }, [selectedVendorIds, currentPeriod?.vendors]);

  const isOpenPeriodOverlapReopenPeriodAllowed = [
    SOLICITATION_STATUSES.Draft,
    SOLICITATION_STATUSES.New,
  ].includes(solicitation.status);

  const newOpenPeriodMinDateFormated = getSolicitationPeriodMinDate(
    solicitation.contractYear,
  );
  const solicitationOprType = window.location.pathname.includes(
    'edit-solicitation',
  )
    ? 'edit'
    : 'new';

  const previousPageUrl = `/catalog/solicitations/edit-solicitation/${Number(
    solicitationID,
  )}/upiid-details`;

  const nextPageUrl = window.location.pathname.includes('edit-solicitation')
    ? `/catalog/solicitations/edit-solicitation/${Number(
        solicitationID,
      )}/reopen-period`
    : `/catalog/solicitations/new-solicitation/${Number(
        solicitationID,
      )}/reopen-period`;

  const navigate = (pathname) => () =>
    history.push({ pathname, search: history.location.search });
  const navigatePreviousPage = navigate(previousPageUrl);
  const navigateNextPage = navigate(nextPageUrl);

  const [addOrUpdateSolicitationPeriod] = useMutation(
    ADD_OR_UPDATE_SOLICITATION_PERIOD,
    {
      onError: (requestError) => {
        setSolicitationNotification([
          {
            id: 'ADD_OR_UPDATE_SOLICITATION_PERIOD_ERROR',
            message: requestError.message,
            type: 'error',
            closeable: false,
            showInModal: false,
          },
          ...notifications,
        ]);
      },
      onCompleted: () => {
        navigateNextPage();
      },
    },
  );

  const readonlyFields = useMemo(() => {
    if (!solicitation?.status) {
      return [];
    }

    const isLegacyDataSource = solicitation?.dataSource === 'Legacy';

    if (contracts.length > 0) {
      return isLegacyDataSource
        ? ['startDate', 'startTime', 'endDate', 'endTime', 'audience', 'vendor']
        : OpenSolicitationReadonlyEditPeriodEndDateTimeFields;
    }

    if (
      solicitation?.cronUpdatedAt ||
      solicitation?.status === 'Open' ||
      solicitation?.status === 'Closed'
    ) {
      const isAfterEndDate = moment(openPeriod?.endDate).isBefore(moment());

      if (isAfterEndDate) {
        return isLegacyDataSource
          ? ['startDate', 'startTime', 'endDate', 'endTime', 'audience', 'vendor']
          : OpenSolicitationReadonlyEditPeriodEndDateTimeFields;
      }

      if (moment(openPeriod?.startDate).isBefore(moment())) {
        return ['startDate', 'startTime', 'audience', 'vendor'];
      }
    }

    return [];
  }, [contracts, openPeriod, solicitation]);

  const {
    register,
    handleSubmit,
    control,
    watch,
    trigger,
    formState: { errors },
    reset,
  } =
    useForm({
      resolver:
        !isMidCycleActionFlow() && !solicitation.prevStatus
          ? yupResolver(
              getOpenPeriodYupSchema({
                status: solicitation?.status,
                maxDateTime: isOpenPeriodOverlapReopenPeriodAllowed
                  ? null
                  : adjacentReopenPeriodStartDate,
                readonlyFields,
              }),
            )
          : undefined,
      mode: 'onBlur',
      reValidateMode: 'onChange',
      defaultValues: {
        audience: currentPeriod?.audience,
        startTime: currentPeriod?.startTime || '',
        endTime: currentPeriod?.endTime || '',
        startDate: currentPeriod?.startDate || '',
        endDate: currentPeriod?.endDate || '',
      },
    }) || {};

  const submitHandler = (data) => {
    const { startDate, endDate, startTime, endTime, audience } = data || {};
    if (
      startDate &&
      endDate &&
      startTime &&
      endTime &&
      audience &&
      !isMidCycleActionFlow() &&
      !readonlyFields.includes('startdate') &&
      !solicitation.prevStatus
    ) {
      const newEndDateTime = getTimestampFromDateNTime(endDate, endTime);
      if (
        !!adjacentReopenPeriodStartDate &&
        !showClearAdditionalPeriods &&
        new Date(newEndDateTime) > new Date(adjacentReopenPeriodStartDate)
      ) {
        toggleClearShowAdditionalPeriods(data);
      } else {
        // check id data is dirty or not
        // for solicitationOprType edit flow
        // if (isSolicitationOpenPeriodDataDirty(soli, data)) {
        addOrUpdateSolicitationPeriod({
          variables: {
            solicitationPeriodInput: {
              ...(openPeriod.id && { id: Number(openPeriod.id) }),
              solicitationID: Number(solicitationID),
              audience,
              startDate: getTimestampFromDateNTime(startDate, startTime),
              endDate: newEndDateTime,
              periodType: 'O',
              vendorIds: idsArr,
            },
          },
        });
      }
    } else {
      navigateNextPage();
    }
  };

  useEffect(() => {
    reset(currentPeriod);
    setBidType(solicitation.bidType);
  }, [solicitation?.id]);

  useEffect(() => {
    getSolicitationById({
      variables: {
        id: parseFloat(id),
      },
    });
    return () => {
      setSolicitationNotification([]);
    };
  }, [id]);

  return (
    <>
      <ClearReopenPeriodsModal
        isOpen={showClearAdditionalPeriods}
        onClose={toggleClearShowAdditionalPeriods}
        onProceedCallback={handleOnModalProceed(submitHandler)}
      />
      <SolicitationNotification isModal={false} />
      <SolicitationHeader
        solicitationNumber={solicitation?.solicitationNumber}
        solicitationStatus={solicitation?.status}
        solicitationType={solicitation?.solicitationType}
      />
      <SolicitationStepper currentStep={2} />
      {solicitationLoading ? (
        <Spinner />
      ) : (
        <form
          id="form"
          onSubmit={handleSubmit(submitHandler)}
          className="open-period-form"
          data-testid="open-period-form"
          noValidate
        >
          <SolicitationPeriod
            title="Enter your Open Period information. These fields are optional until you are ready to submit your solicitation."
            contractYear={solicitation.contractYear}
            currentSolicitationPeriod={currentPeriod}
            solicitationOprType={
              isRenewActionFlow() && !currentPeriod?.startDate
                ? 'new'
                : solicitationOprType
            }
            stepperStage="open"
            formRegister={register}
            formControl={control}
            formWatch={watch}
            formErrors={errors}
            formValidationTrigger={trigger}
            minDate={newOpenPeriodMinDateFormated}
            readonlyFields={readonlyFields}
            idPrefix="openPeriod"
            rawSolPeriod={openPeriod}
          />
          <div className="grid-row padding-top-6">
            <div className="flex">
              <Button
                label="Previous"
                data-testid="open-period-previous-button"
                leftIcon={{
                  name: 'arrow_back',
                }}
                onClick={navigatePreviousPage}
                variant="outline"
              />

              <Button
                label="Next"
                type="submit"
                data-testid="open-period-submit-button"
                rightIcon={{
                  name: 'arrow_forward',
                }}
                variant="primary"
              />
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default OpenPeriod;
