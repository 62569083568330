export const addOrReplaceMessageById = (message, existingMessage) => {
  const existingMessages = [...existingMessage];
  const splicedArray = existingMessages.filter((m) => m.id !== message.id);
  return [...splicedArray, message];
};

export const removeMessageById = (id, existingMessage) => {
  const e = [...existingMessage];
  const splicedArray = e.filter((m) => m.id !== id);
  return [...splicedArray];
};
