import { gql } from '@apollo/client';

// eslint-disable-next-line
export const GET_BIDS_BY_SOLI_NUMBER = gql`
  query GetBidsBySolicitationNumber($solicitationNumber: String!) {
    getBidsBySolicitationNumber(solicitationNumber: $solicitationNumber) {
      id
      soliciation {
        id
        solicitationNumber
      }
      vendorDetail {
        id
        vendorName
        uniqueEntityIdentifier
      }
    }
  }
`;
