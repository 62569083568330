import React from 'react';
import PropTypes from 'prop-types';
import BidsMakeAndModelProvider from './make-and-model-provider';
import BidMakesAndModelsModal, {
  MakeModelModalDataType,
} from './add-makes-and-models-modal';

const BidMakeAndModelWrapper = ({
  isOpen,
  onClose,
  initialData,
  modalTitle,
}) => {
  return (
    <BidsMakeAndModelProvider>
      <BidMakesAndModelsModal
        isOpen={isOpen}
        initialData={initialData}
        onClose={onClose}
        modalTitle={modalTitle}
      />
    </BidsMakeAndModelProvider>
  );
};

BidMakeAndModelWrapper.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  initialData: MakeModelModalDataType,
  modalTitle: PropTypes.string,
};
BidMakeAndModelWrapper.defaultProps = {
  initialData: {
    siData: [],
  },
  modalTitle: undefined,
};

export default BidMakeAndModelWrapper;
