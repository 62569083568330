import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, connectModal } from '@gsa/afp-component-library';

const HighlightsReviewConfirm = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal
      id="remove-standard-item-modal"
      variant="medium"
      onClose={onClose}
      isOpen={isOpen}
      actions={
        <div className="flex-align-start flex-justify-start">
          <Button
            data-testid="approve-reject-modal-cancel-btn"
            variant="unstyled"
            onClick={onClose}
            className="padding-right-1"
            label="Cancel"
          />
          <Button variant="primary" onClick={onConfirm} label="Submit bid" />
        </div>
      }
    >
      <h3>Acknowledgement</h3>
      There are fields copied or imported that have not been updated. Please
      confirm that you have reviewed the data.
    </Modal>
  );
};

HighlightsReviewConfirm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

const ConnectedHighlightsReviewConfirm = connectModal(HighlightsReviewConfirm);

export default ConnectedHighlightsReviewConfirm;
