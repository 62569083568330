import { gql } from '@apollo/client';

export const AttachmentFields = gql`
  fragment AttachmentFields on Attachment {
    id
    name
    description
    contentURL
    type
    size
    lastModified
    createdAt
    updatedAt
    createdByUser
    user {
      fullName
    }
  }
`;

export const GET_ATTACHMENTS = gql`
  query GetAttachments(
    $filter: AttachmentFilter
    $limit: Float
    $offset: Float
    $order: OrderBy
  ) {
    getAttachments(
      filter: $filter
      limit: $limit
      offset: $offset
      order: $order
    ) {
      rows {
        ...AttachmentFields
      }
      count
      hasMore
    }
  }
  ${AttachmentFields}
`;

export const GET_AWS_SIGNED_READ_URL = gql`
  query GenerateReadSignedURL($fileKey: String!, $fileType: String!) {
    generateReadSignedURL(fileKey: $fileKey, fileType: $fileType)
  }
`;

export const GET_AWS_READ_URL = gql`
  query GenerateReadURL($fileKey: String!, $fileType: String!) {
    generateReadURL(fileKey: $fileKey, fileType: $fileType)
  }
`;

export const DELETE_ATTACHMENT = gql`
  mutation DeleteAttachment($id: Float!) {
    deleteAttachment(id: $id)
  }
`;

export const UPDATE_DOC_DESCRIPTION = gql`
  mutation UpdateDocMetadata($input: DocMetadataUpdateInput!) {
    updateMetadataRecord(input: $input) {
      id
      name
      docStoreUri
      scanStatus
    }
  }
`;

export const UPDATE_DOC_EFFECTIVE_DATE = gql`
  mutation updateSupportingDocEffectiveDate(
    $id: Float!
    $effectiveDate: DateTime!
  ) {
    updateSupportingDocEffectiveDate(id: $id, effectiveDate: $effectiveDate) {
      id
      effectiveDate
    }
  }
`;

export const UPDATE_SUPPORTING_DOC_STATUS = gql`
  mutation updateFVSDocStatus($id: Float!, $status: Float!) {
    updateSupportingDocStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;
