import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { groupBy, isEmpty } from 'lodash';
import {
  GET_PRE_BID_SELECTIONS_COUNT,
  GET_PRE_BID_DOCUMENTATION_REVIEW,
  GET_PRE_BID_SELECTIONS_SUMMARY,
  ADD_OR_UPDATE_PRE_BID_SELECTIONS,
  MARK_DOC_AS_REVIEWED,
  GET_FVS_OPTIONS_FOR_YEAR,
  GET_PRE_BID_MAKE_MODEL_DATA,
  ADD_MAKE_AND_MODEL_FOR_SELECTION,
  GET_PRE_BID_SELECTIONS,
  ADD_OR_UPDATE_INTENTION_TO_BID,
  DELETE_MAKE_AND_MODEL_FOR_SELECTION,
  DELETE_MAKE_AND_MODEL_BY_ID,
} from './fvs.gql';
import { useFederalStandards } from './fvs-provider';
import { STANDARD_ITEMS } from '../../services/data-store';
import { OPERATIONS, SUBJECTS } from '../../utilities/constants';
import {
  makesFetchOptionsPromise,
  modelsFetchOptionsPromise,
} from './requirements/components/make-model/api-helpers';

export const FvsPreBidContext = createContext({});
export const NON_STANDARD_LINE_TYPES = {
  MANUFACTURER_MODEL_NUMBER: 0,
  MAKE_MODEL_YEAR: 1,
};
const initialState = {
  showFormModal: false,
  modalMode: null,
  selectedMakeModelItem: null,
  preBidSelectionDataForVendor: null,
  preBidDocumentationListForVendor: null,
  vehicleGroupPreBid: null,
  standardItemsForVehicleGroup: [],
  selectedVehicleGrpPreBid: '',
  makeCode: '',
  modelCode: '',
  isSubmitted: false,
  isClearedTypeAhead: false,
  chassisMakeCode: '',
  chassisModelCode: '',
  makeCustomKeyword: '',
  modelCustomKeyword: '',
  modelYear: '',
  tags: { value: '' },
  chassisMakeCustomKeyword: '',
  chassisModelCustomKeyword: '',
  preBidSelectionsMakeAndModel: [],
  preBidSelectionForStandardItem: null,
  preBidSelections: {
    rows: [],
    hasMore: false,
    count: 0,
  },
};

const FvsPreBidReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTIONS_DATA': {
      return { ...state, preBidSelectionDataForVendor: action.payload };
    }
    case 'SET_PRE_BID_DOC_LIST': {
      return { ...state, preBidDocumentationListForVendor: action.payload };
    }
    case 'SET_VEHICLE_GROUP_PRE_BID': {
      return { ...state, vehicleGroupPreBid: action.payload };
    }
    case 'SET_SELECTED_VEHICLE_GROUP_PRE_BID': {
      return { ...state, selectedVehicleGrpPreBid: action.payload };
    }
    case 'SET_SI_FOR_VEHICLE_GROUP': {
      return { ...state, standardItemsForVehicleGroup: action.payload };
    }
    case 'SET_MAKE_CODE': {
      return { ...state, makeCode: action.payload };
    }
    case 'SET_MODEL_CODE': {
      return { ...state, modelCode: action.payload };
    }
    case 'SET_CHASSIS_MAKE_CODE': {
      return { ...state, chassisMakeCode: action.payload };
    }
    case 'SET_CHASSIS_MODEL_CODE': {
      return { ...state, chassisModelCode: action.payload };
    }
    case 'SET_MAKE_CUSTOM_KEYWORD': {
      return { ...state, makeCustomKeyword: action.payload };
    }
    case 'SET_MODEL_CUSTOM_KEYWORD': {
      return { ...state, modelCustomKeyword: action.payload };
    }
    case 'SET_CHASSIS_MAKE_CUSTOM_KEYWORD': {
      return { ...state, chassisMakeCustomKeyword: action.payload };
    }
    case 'SET_CHASSIS_MODEL_CUSTOM_KEYWORD': {
      return { ...state, chassisModelCustomKeyword: action.payload };
    }
    case 'SET_PRE_BID_SELECTION_MAKE_MODEL': {
      return { ...state, preBidSelectionsMakeAndModel: action.payload };
    }
    case 'SET_PRE_BID_SELECTION_FOR_STANDARD_ITEM': {
      return { ...state, preBidSelectionForStandardItem: action.payload };
    }
    case 'SET_IS_SUBMITTED': {
      return { ...state, isSubmitted: action.payload };
    }
    case 'SET_TYPE_AHEAD_IS_CLEARED': {
      return { ...state, isClearedTypeAhead: action.payload };
    }
    case 'SET_PRE_BID_SUMMARY_DATA': {
      return { ...state, preBidSelections: action.payload };
    }
    case 'SET_SELECTED_ITEM': {
      return { ...state, selectedMakeModelItem: { ...action.payload } };
    }
    case 'SET_MODAL_MODE': {
      return { ...state, modalMode: action.payload };
    }
    case 'SET_FORM_MODAL': {
      return { ...state, showFormModal: action.payload };
    }
    case 'SET_MODEL_YEAR': {
      return { ...state, modelYear: action.payload };
    }
    default: {
      return state;
    }
  }
};

function FvsPreBidProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(FvsPreBidReducer, initialState, () => {
    return initialState;
  });
  const { getToken } = useCurrentUser();
  const fetchMakes = useCallback(
    async (input, signal) => {
      const token = await getToken();
      return makesFetchOptionsPromise(input, token, signal);
    },
    [getToken],
  );
  const fetchModels = useCallback(
    async (input, makeCode, signal) => {
      const token = await getToken();
      return modelsFetchOptionsPromise(input, makeCode, token, signal);
    },
    [getToken],
  );

  const [hasChassis, setHasChassis] = useState(
    state.tags?.value?.includes('REQ_CHASSIS_MOD'),
  );

  const [modelYear, setModelYear] = useState();
  const [makeModelApprovalStatus, setMakeModelApprovalStatus] = useState();

  const bodyMakeInitialState = initialState?.bodyMake
    ? {
        label: initialState?.bodyMake?.makeName,
        value: initialState?.bodyMake?.makeCode,
      }
    : undefined;
  const [bodyMake, setBodyMake] = useState(bodyMakeInitialState);
  const [bodyMakeInputValue, setBodyMakeInputValue] = useState('');
  const [bodyMakeOptions, setBodyMakeOptions] = useState();

  const bodyModelInitialState = initialState?.bodyModel
    ? {
        label: initialState?.bodyModel?.modelName,
        value: initialState?.bodyModel?.modelCode,
      }
    : undefined;
  const [bodyModel, setBodyModel] = useState(bodyModelInitialState);
  const [bodyModelInputValue, setBodyModelInputValue] = useState('');
  const [bodyModelOptions, setBodyModelOptions] = useState();

  const chassisMakeInitialState = initialState?.chassisMake
    ? {
        label: initialState?.chassisMake?.makeName,
        value: initialState?.chassisMake?.makeCode,
      }
    : undefined;
  const [chassisMake, setChassisMake] = useState(chassisMakeInitialState);
  const [chassisMakeInputValue, setChassisMakeInputValue] = useState('');
  const [chassisMakeOptions, setChassisMakeOptions] = useState();

  const chassisModelInitialState = initialState?.chassisModel
    ? {
        label: initialState?.chassisModel?.modelName,
        value: initialState?.chassisModel?.modelCode,
      }
    : undefined;
  const [chassisModel, setChassisModel] = useState(chassisModelInitialState);
  const [chassisModelInputValue, setChassisModelInputValue] = useState('');
  const [chassisModelOptions, setChassisModelOptions] = useState();

  const [manufacturerModelNumber, setManufacturerModelNumber] = useState();

  const [nonStandardLineType] = useState(
    NON_STANDARD_LINE_TYPES.MANUFACTURER_MODEL_NUMBER,
  );

  const [createError] = useState();

  const [dirtyFields, setDirtyFields] = useState([]);
  const getErrors = useCallback(() => {
    const requiredMsg = 'This is a required field';
    const errors = [];

    if (
      nonStandardLineType === NON_STANDARD_LINE_TYPES.MANUFACTURER_MODEL_NUMBER
    ) {
      if (!manufacturerModelNumber) {
        errors.push({
          field: 'manufacturerModelNumber',
          message: requiredMsg,
        });
      }

      return errors;
    }

    if (!bodyMake) {
      if (!bodyMakeInputValue) {
        errors.push({
          field: 'bodyMake',
          message: requiredMsg,
        });
      }
    }

    if (!bodyModel) {
      if (!bodyModelInputValue) {
        errors.push({
          field: 'bodyModel',
          message: requiredMsg,
        });
      }
    }

    if (hasChassis) {
      if (!chassisMake) {
        if (!chassisMakeInputValue) {
          errors.push({
            field: 'chassisMake',
            message: requiredMsg,
          });
        }
      }

      if (!chassisModel) {
        if (!chassisModelInputValue) {
          errors.push({
            field: 'chassisModel',
            message: requiredMsg,
          });
        }
      }
    }

    if (!modelYear) {
      errors.push({
        field: 'modelYear',
        message: requiredMsg,
      });
    }

    return errors;
  }, [
    bodyMake,
    bodyMakeInputValue,
    bodyModel,
    bodyModelInputValue,
    chassisMake,
    chassisMakeInputValue,
    chassisModel,
    chassisModelInputValue,
    modelYear,
    hasChassis,
    manufacturerModelNumber,
    nonStandardLineType,
  ]);

  const history = useHistory();
  const ability = useAppAbility();

  const {
    selectedYear,
    selectedOptions,
    setFvsError,
    addOrReplaceFvsMessageById,
  } = useFederalStandards();

  const setFvsPreBidContextData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const setSuccessToastMessage = (psoQuestuonnairCount) => {
    let toastMessage = '';
    if (psoQuestuonnairCount === 0) {
      toastMessage = 'No pre-bid selections have been saved.';
    } else if (psoQuestuonnairCount > 1) {
      toastMessage = `Your ${psoQuestuonnairCount} pre-bid selections have been saved.`;
    } else {
      toastMessage = `Your ${psoQuestuonnairCount} pre-bid selection have been saved.`;
    }
    return toastMessage;
  };

  const [getPreBidOptionsForYear] = useLazyQuery(GET_FVS_OPTIONS_FOR_YEAR, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (requestError) => {
      setFvsError(requestError);
    },
    onCompleted: (responseData) => {
      if (responseData?.getVehicleClassificationFVS) {
        const allOptionsGrouped = groupBy(
          responseData.getVehicleClassificationFVS,
          'uniqueKey',
        );
        const vehicleGroups = {};
        const sortedVehGroups = {};

        if (!allOptionsGrouped || !allOptionsGrouped?.vehicleGroup) return;

        allOptionsGrouped?.vehicleGroup?.forEach((i) => {
          if (i?.value) {
            vehicleGroups[i.value] = {
              name: i.label.substr(i.label.indexOf('-') + 2),
            };
          }
        });

        const sortedVgKeys = Object.keys(vehicleGroups);
        sortedVgKeys.sort();

        const vehicleTypesGrouped = groupBy(
          allOptionsGrouped?.vehicleType,
          'parent',
        );

        sortedVgKeys.forEach((vg) => {
          const vtForVg = {};
          const sortedVtForVg = {};

          if (vehicleTypesGrouped[vg]) {
            vehicleTypesGrouped[vg].forEach((vt) => {
              vtForVg[vt.value] = {
                name: vt.label.substr(vt.label.indexOf('-') + 2),
              };
            });
            const sortedVtKeys = Object.keys(vtForVg);
            sortedVtKeys.sort();

            sortedVtKeys.forEach((vt) => {
              sortedVtForVg[vt] = vtForVg[vt];
            });
          }

          sortedVehGroups[vg] = {
            name: vehicleGroups[vg].name,
            vehicleTypes: sortedVtForVg,
          };
        });
        setFvsPreBidContextData('SET_VEHICLE_GROUP_PRE_BID', sortedVehGroups);
        setFvsPreBidContextData(
          'SET_SELECTED_VEHICLE_GROUP_PRE_BID',
          Object.keys(sortedVehGroups)[0],
        );
      }
    },
  });

  const [getPreBidSelectionCount] = useLazyQuery(GET_PRE_BID_SELECTIONS_COUNT, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (requestError) => {
      setFvsError(requestError);
    },
    onCompleted: (responseData) => {
      if (responseData?.getPreBidDataForVendor) {
        setFvsPreBidContextData(
          'SET_SELECTIONS_DATA',
          responseData.getPreBidDataForVendor,
        );
      }
    },
  });

  const [getPreBidDocumentationList] = useLazyQuery(
    GET_PRE_BID_DOCUMENTATION_REVIEW,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (requestError) => {
        setFvsError(requestError);
      },
      onCompleted: (responseData) => {
        if (responseData?.getPreBidReviewDocumentationForVendor) {
          setFvsPreBidContextData(
            'SET_PRE_BID_DOC_LIST',
            responseData.getPreBidReviewDocumentationForVendor,
          );
        }
      },
    },
  );

  const [
    getPreBidSelectionSummary,
    { loading: isPreBidSelectionLoading },
  ] = useLazyQuery(GET_PRE_BID_SELECTIONS_SUMMARY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (requestError) => {
      setFvsError(requestError);
    },
    onCompleted: (responseData) => {
      if (responseData) {
        setFvsPreBidContextData(
          'SET_PRE_BID_SUMMARY_DATA',
          responseData.getPreBidSelections,
        );
      }
    },
  });

  const [
    getStandardItemsForVehicleGroup,
    { loading: isSIsForVGLoading },
  ] = useLazyQuery(STANDARD_ITEMS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (requestError) => {
      setFvsError(requestError);
    },
    onCompleted: (responseData) => {
      if (responseData?.getStandardItems) {
        setFvsPreBidContextData(
          'SET_SI_FOR_VEHICLE_GROUP',
          responseData?.getStandardItems?.rows,
        );
      }
    },
  });

  // add pso questionnaire / pre-bid selections
  const [addOrUpdatePreBidSelections] = useMutation(
    ADD_OR_UPDATE_PRE_BID_SELECTIONS,
    {
      onError: (requestError) => {
        setFvsError(requestError);
      },
      onCompleted: (responseData) => {
        const successMessage = setSuccessToastMessage(
          responseData?.addOrUpdatePreBidSelections?.counts?.selectionCount,
        );

        setFvsPreBidContextData(
          'SET_SELECTIONS_DATA',
          responseData.addOrUpdatePreBidSelections,
        );

        const message = {
          id: 'PRE_BID_SELECTIONS',
          message: successMessage,
          type: 'success',
        };

        addOrReplaceFvsMessageById(message);
        history.goBack();
      },
    },
  );

  const [markDocAsReviewed] = useMutation(MARK_DOC_AS_REVIEWED, {
    onError: (requestError) => {
      setFvsError(requestError);
    },
    onCompleted: () => {
      const message = {
        id: 'DOC_REVIEWED',
        message: 'Thank you for reviewing the Federal standard document',
        type: 'success',
      };

      addOrReplaceFvsMessageById(message);

      getPreBidSelectionCount({
        variables: {
          contractYear: selectedYear,
        },
      });
    },
  });

  // make and model
  const [updatePreBidSelectionsWithMakeAndMode] = useMutation(
    ADD_MAKE_AND_MODEL_FOR_SELECTION,
    {
      // eslint-disable-next-line no-unused-vars
      onError: (requestError) => {
        setFvsError({
          ...requestError,
          message: 'Another entry with the same make and model already exists',
        });
      },
      // eslint-disable-next-line no-unused-vars
      onCompleted: (response) => {
        setFvsPreBidContextData('SET_MAKE_MODEL_ERROR_MESSAGE', '');
      },
    },
  );

  const [findAllMakeAndModelByStandardItemId, { refetch }] = useLazyQuery(
    GET_PRE_BID_MAKE_MODEL_DATA,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (responseData) => {
        if (responseData?.getPreBidMakeModelData) {
          setFvsPreBidContextData(
            'SET_PRE_BID_SELECTION_MAKE_MODEL',
            responseData?.getPreBidMakeModelData?.rows,
          );
        }
      },
    },
  );

  const [addOrUpdateIntentionToBid] = useMutation(
    ADD_OR_UPDATE_INTENTION_TO_BID,
    {
      onError: (requestError) => {
        setFvsError(requestError);
      },
      onCompleted: (responseData) => {
        if (responseData?.addOrUpdateIntentionToBid) {
          setFvsPreBidContextData(
            'SET_PRE_BID_SELECTION_FOR_STANDARD_ITEM',
            responseData?.addOrUpdateIntentionToBid,
          );
          getPreBidSelectionCount({
            variables: {
              contractYear: selectedYear,
            },
          });
        }
      },
    },
  );

  const [deleteMakeAndModelForSelection] = useMutation(
    DELETE_MAKE_AND_MODEL_FOR_SELECTION,
    {
      // eslint-disable-next-line no-unused-vars
      onCompleted: (responseData) => {
        // TODO
      },
    },
  );

  const [deleteMakeAndModelById] = useMutation(DELETE_MAKE_AND_MODEL_BY_ID, {
    // eslint-disable-next-line no-unused-vars
    onCompleted: (responseData) => {
      refetch();
    },
  });

  const [getPreBidSelectionByStandardItemId] = useLazyQuery(
    GET_PRE_BID_SELECTIONS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (responseData) => {
        if (responseData?.getPreBidSelections) {
          setFvsPreBidContextData(
            'SET_PRE_BID_SELECTION_FOR_STANDARD_ITEM',
            responseData?.getPreBidSelections?.rows[0],
          );
        }
      },
    },
  );

  useEffect(() => {
    if (ability.can(OPERATIONS.Create, SUBJECTS.PSOQuestionnaire)) {
      if (selectedYear) {
        getPreBidSelectionCount({
          variables: {
            contractYear: selectedYear,
          },
        });

        getPreBidDocumentationList({
          variables: {
            contractYear: selectedYear,
          },
        });

        getPreBidOptionsForYear({
          variables: {
            year: Number.parseInt(selectedYear, 10),
            includeOnlyCommentable: true,
          },
        });
      }
    }
  }, [selectedYear]);

  const getPreBidSummaryData = (paginationState) => {
    if (selectedYear === null) {
      return;
    }

    const filters = {
      operator: 'AND',
      value: [{ operator: 'EQ', key: 'contractYear', value: selectedYear }],
    };

    if (!isEmpty(selectedOptions?.fedStandardCode)) {
      filters.value.push({
        operator: 'EQ',
        key: '$standardItem.fed_standard_code$',
        value: selectedOptions.fedStandardCode,
      });
    }

    if (!isEmpty(selectedOptions?.vehicleGroup)) {
      filters.value.push({
        operator: 'EQ',
        key: 'vehicleGroupCode',
        value: selectedOptions.vehicleGroup,
      });
    }

    if (!isEmpty(selectedOptions?.vehicleType)) {
      filters.value.push({
        operator: 'EQ',
        key: '$standardItem.vehicle_type$',
        value: selectedOptions.vehicleType,
      });
    }
    getPreBidSelectionSummary({
      variables: {
        filters,
        limit: paginationState?.limit || 1000,
        offset: paginationState?.offset || 0,
        order: `contractYear ASC`,
      },
    });
  };

  const removeMakeAndModelMessage = () => {
    setFvsPreBidContextData('SET_MAKE_MODEL_ERROR_MESSAGE', '');
  };

  return (
    <FvsPreBidContext.Provider
      value={{
        ...state,
        setFvsPreBidContextData,
        addOrUpdatePreBidSelections,
        getStandardItemsForVehicleGroup,
        isSIsForVGLoading,
        isPreBidSelectionLoading,
        markDocAsReviewed,
        getPreBidSelectionCount,
        addOrUpdateIntentionToBid,
        updatePreBidSelectionsWithMakeAndMode,
        findAllMakeAndModelByStandardItemId,
        getPreBidSelectionByStandardItemId,
        getPreBidSummaryData,
        deleteMakeAndModelById,
        deleteMakeAndModelForSelection,
        getPreBidDocumentationList,
        removeMakeAndModelMessage,

        hasChassis,
        setHasChassis,

        bodyMake,
        setBodyMake,
        bodyMakeInputValue,
        setBodyMakeInputValue,
        bodyMakeOptions,
        setBodyMakeOptions,

        bodyModel,
        setBodyModel,
        bodyModelInputValue,
        setBodyModelInputValue,
        bodyModelOptions,
        setBodyModelOptions,

        chassisMake,
        setChassisMake,
        chassisMakeInputValue,
        setChassisMakeInputValue,
        chassisMakeOptions,
        setChassisMakeOptions,

        chassisModel,
        setChassisModel,
        chassisModelInputValue,
        setChassisModelInputValue,
        chassisModelOptions,
        setChassisModelOptions,

        modelYear,
        setModelYear,

        makeModelApprovalStatus,
        setMakeModelApprovalStatus,

        fetchMakes,
        fetchModels,

        getErrors,
        dirtyFields,
        setDirtyFields,

        createError,

        manufacturerModelNumber,
        setManufacturerModelNumber,

        ...props,
      }}
    >
      {children}
    </FvsPreBidContext.Provider>
  );
}

export default FvsPreBidProvider;

FvsPreBidProvider.defaultProps = {};

FvsPreBidProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useFvsPreBidContext = () => useContext(FvsPreBidContext);
