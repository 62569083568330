import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams, Link } from 'react-router-dom';
import {
  ButtonDropdown,
  ButtonDropdownItem,
  FlexView,
  useModal,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import AddContractLineModal from './add-contract-line/add-contract-line-modal';
import { SIMakeModelPanellProvider } from './make-model/si-make-model-panel-provider';
import ImportContractLineModal from './import-contract-line/import-contract-line-modal';
import { AddContractLineProvider } from './add-contract-line/add-contract-line-provider';
import ErrorModal from './error-modal';
import { OPERATIONS, SUBJECTS } from '../../../utilities/constants';
import { isContractSOP } from '../components/contract-helpers';

const ContractLineActions = ({
  contractHeader,
  setAlert,
  refetchLines,
  refetchHeader,
}) => {
  const history = useHistory();
  const { contractHeaderId } = useParams();

  const isSop = isContractSOP(contractHeader?.solicitation?.purchaseTypeCode);

  const {
    isOpen: isAddContractLineModalOpen,
    openModal: setAddContractLineModalOpen,
    closeModal: setAddContractLineModalClose,
  } = useModal();

  const {
    isOpen: isImportContractLineModalOpen,
    openModal: openImportContractLineModal,
    closeModal: closeImportContractLineModal,
  } = useModal();

  const {
    isOpen: isErrorModalOpen,
    openModal: openErrorModal,
    closeModal: closeErrorModal,
  } = useModal();
  const ability = useAppAbility();
  const canUpdateContract = ability.can(OPERATIONS.Update, SUBJECTS.Contract);

  const actionsPanelLinks = () => [
    {
      title: 'View contract header',
      onClickHandler: () =>
        history.push(`/catalog/contract/contract-header/${contractHeaderId}`),
      icon: 'visibility',
    },
    ...(canUpdateContract
      ? [
          {
            title: 'Add contract line',
            onClickHandler: () =>
              !isSop && !contractHeader.publishedInFleet
                ? openErrorModal(true)
                : setAddContractLineModalOpen(true),
            icon: 'plus',
          },
        ]
      : []),
    ...(isSop && canUpdateContract
      ? [
          {
            title: 'Import awarded contract lines',
            onClickHandler: openImportContractLineModal,
            icon: 'arrow_downward',
          },
        ]
      : []),
  ];

  return (
    <FlexView hAlignContent="right">
      <ButtonDropdown
        className="margin-right-0 margin-bottom-1"
        variant="outline"
        label="Actions"
        side="right"
      >
        {actionsPanelLinks().map((link) => (
          <React.Fragment key={link.title}>
            {link ? (
              <ButtonDropdownItem
                className="text-primary hover:bg-blue-5"
                label={link.title}
                iconName={link.icon}
                onClick={link.onClickHandler}
              />
            ) : null}
          </React.Fragment>
        ))}
      </ButtonDropdown>
      {isAddContractLineModalOpen && (
        <AddContractLineProvider>
          <SIMakeModelPanellProvider>
            <AddContractLineModal
              isOpen={isAddContractLineModalOpen}
              onClose={setAddContractLineModalClose}
              contractHeader={contractHeader}
              onCreateSuccess={(contractLine) => {
                setAddContractLineModalClose();
                setAlert({
                  type: 'success',
                  message: (
                    <>
                      Line item
                      <Link
                        to={`/catalog/contract/${contractHeaderId}/contract-line-template/${contractLine.id}`}
                        className="text-bold margin-left-1"
                      >
                        {contractLine.scheduleLine}
                      </Link>{' '}
                      has been added.
                    </>
                  ),
                });
                refetchLines();
              }}
            />
          </SIMakeModelPanellProvider>
        </AddContractLineProvider>
      )}
      <ImportContractLineModal
        isOpen={isImportContractLineModalOpen}
        contractHeader={contractHeader}
        onClose={closeImportContractLineModal}
        setAlert={setAlert}
        refetchLines={refetchLines}
        refetchHeader={refetchHeader}
      />
      {isErrorModalOpen && (
        <ErrorModal closeModal={closeErrorModal} isOpen={isErrorModalOpen} />
      )}
    </FlexView>
  );
};

ContractLineActions.propTypes = {
  contractHeader: PropTypes.shape({
    solicitation: PropTypes.shape({
      purchaseTypeCode: PropTypes.string,
    }),
    publishedInFleet: PropTypes.bool,
  }),
  setAlert: PropTypes.func.isRequired,
  refetchLines: PropTypes.func.isRequired,
  refetchHeader: PropTypes.func.isRequired,
};

ContractLineActions.defaultProps = {
  contractHeader: {},
};

export default ContractLineActions;
