import React, { useState, useMemo } from 'react';
import { AccordionItem, Button, AFPTable } from '@gsa/afp-component-library';

import { useContractLine } from '../provider/contract-line-provider';
import {
  AccordionTitle,
  getHandelToggle,
  getToggleAccordion,
} from '../helpers/ec-components';
import { getOptEqColumns, EcExpandedRow } from '../helpers/table-defs-ec';
import {
  EC_OPTION_TYPE,
  isInactiveOptionType,
  isOptEqItemReady,
  getUpdateObj,
} from '../helpers/ec-helpers';
import EditConflictModal from './edit-conflict-modal';

const OptionalEquipment = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const {
    contractLine,
    isAwardedInFleet,
    optionalECs,
    optionalECTable: cats,
    setOptionalECTable,
    setOptionalEC,
    deleteEngineLine,
    addEngineLine,
    canUpdateContract,
  } = useContractLine();

  const toggleAccordion = getToggleAccordion(setOptionalECTable);
  const handleToggle = getHandelToggle(setOptionalECTable);

  const updateItem = async (original, data, table) => {
    // update data rows in the "Engine and fuel" tab
    if (data.inputOptionType !== undefined && original.hasMpgTag) {
      if (original.inputOptionType.newValue === EC_OPTION_TYPE.standalone)
        deleteEngineLine(original.id);
      else if (data.inputOptionType === EC_OPTION_TYPE.standalone)
        addEngineLine(original, contractLine.standardItem.tags.value);
    }
    // update UI state
    const updateObj = getUpdateObj(original, data);
    const newLine = { ...original, ...updateObj };
    const ready = isOptEqItemReady(
      newLine.inputOptionType,
      newLine.inputMaxQuantity,
      newLine.inputUnitPrice,
      newLine.quantityRequired,
      isAwardedInFleet,
    );
    setOptionalEC(original, { data: { ...updateObj, ready }, table });
  };

  const onUpdate = (original, field, value) => {
    let addInput = {};
    let tableUpdate = null;
    switch (field) {
      case 'inputOptionType':
        if (original.inputOptionType.newValue !== value) {
          if (value === EC_OPTION_TYPE.standard)
            addInput = { inputUnitPrice: '0' };
          else if (isInactiveOptionType({ newValue: value })) {
            addInput = {
              inputMaxQuantity: '',
              inputUnitPrice: '',
              inputShipmentDays: '',
              inputExplanation: '',
              inputException: '',
            };
            tableUpdate = { isExpanded: false };
          }
          updateItem(
            original,
            { inputOptionType: value, ...addInput },
            tableUpdate,
          );
        }
        break;
      case 'conflicts':
        updateItem(original, value);
        break;
      default:
        // 'inputUnitPrice', 'inputMaxQuantity', 'inputShipmentDays',
        // 'inputExplanation' and 'inputException',
        if (original[field].newValue !== value) {
          updateItem(original, { [field]: value });
        }
    }
  };

  const onCloseModal = (original, update) => {
    setSelectedRow(null);
    if (update) onUpdate(original, 'conflicts', update);
  };

  const onActionClick = (_action, row) => {
    // open conflict modal
    setSelectedRow(row);
  };

  const columns = useMemo(
    () => getOptEqColumns(onUpdate, onActionClick, !canUpdateContract),
    [onUpdate, onActionClick, canUpdateContract],
  );
  const subrow = useMemo(
    // eslint-disable-next-line
    () => ({ row }) => (
      <EcExpandedRow
        ecType="O"
        row={row}
        onUpdate={onUpdate}
        onActionClick={onActionClick}
        isReadOnly={!canUpdateContract}
      />
    ),
    [onUpdate, onActionClick, canUpdateContract],
  );

  return (
    <div className="margin-bottom-8 contract-line-template">
      <div className="display-flex flex-row flex-justify flex-align-end margin-bottom-4">
        <div className="title-m-bold">Optional equipment</div>
        <div>
          <Button
            variant="unstyled"
            label="Open/close all"
            leftIcon={{ name: 'unfold_more' }}
            onClick={toggleAccordion}
          />
        </div>
      </div>

      {selectedRow && (
        <EditConflictModal row={selectedRow} onClose={onCloseModal} />
      )}

      {cats.map((cat, i) => {
        const isReady = optionalECs[cat.index].data.every((row) => row.ready);
        return (
          <AccordionItem
            key={cat.id}
            id={cat.id}
            title={<AccordionTitle title={cat.title} isReady={isReady} />}
            expanded={cat.expanded}
            content={
              cat.expanded && (
                <div className="padding-top-1">
                  <AFPTable
                    fullWidth
                    columns={columns}
                    data={cat.data}
                    renderRowSubComponent={subrow}
                  />
                </div>
              )
            }
            handleToggle={() => handleToggle(i)}
          />
        );
      })}
    </div>
  );
};

export default OptionalEquipment;
