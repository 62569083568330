import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import {
  Modal,
  Spinner,
  Button,
  connectModal,
} from '@gsa/afp-component-library';
import {
  APPROVE_REJECT_MAKE_MODEL,
  GET_BID_LINES_BY_MAKE_MODEL_FOR_APPROVAL,
} from '../queries.gql';
import { makeModelStatusMessageAtom } from '../atoms/make-model-atoms';
import CommentSection from '../make-model/make-model-modal-comment-section';

const ApproveRejectConfirmationModal = ({
  data,
  action,
  isOpen,
  onClose,
  refetchData,
}) => {
  const {
    bodyMake,
    bodyModel,
    makeCode,
    modelCode,
    chassisMake,
    chassisModel,
    chassisMakeCode,
    chassisModelCode,
    makeModelApprovalId,
  } = data;
  const [, setToaster] = useRecoilState(makeModelStatusMessageAtom);
  const [rejectCommentBody, setRejectCommentBody] = useState('');

  const {
    data: bidLineMakeModelData,
    loading: isbidLineMakeModelDataLoading,
  } = useQuery(GET_BID_LINES_BY_MAKE_MODEL_FOR_APPROVAL, {
    fetchPolicy: 'cache-and-network',
    variables: {
      makeModelSearchInput: {
        makeCode,
        modelCode,
        chassisMakeCode,
        chassisModelCode,
      },
    },
    skip: !makeCode || !modelCode,
  });

  // get all the users who used the make & model combination in their bid-line
  const vehicleSuppliersEmail =
    !isbidLineMakeModelDataLoading &&
    bidLineMakeModelData?.getBidLinesByMakeAndModel?.map(
      (item) => item?.vehicleSupplierUser?.email,
    );
  const [approveRejectMakeModel, { loading }] = useMutation(
    APPROVE_REJECT_MAKE_MODEL,
    {
      variables: {
        makeModelApprovalInput: {
          makeModelApprovalId: Number.parseInt(makeModelApprovalId, 10),
          makeCode,
          modelCode,
          chassisMakeCode,
          chassisModelCode,
          status: action === 'Approve' ? 'Approved' : 'Rejected',
          comment: rejectCommentBody || '',
          vehicleSuppliersEmail,
        },
      },
      onCompleted: () => {
        setToaster({
          type: 'success',
          message: (
            <>
              You have{' '}
              {action === 'Approve' ? 'successfully approved' : 'rejected'}{' '}
              {bodyMake.makeName} and {bodyModel.modelName}
              {chassisMake &&
              chassisMake.chassisMakeName &&
              chassisModel &&
              chassisModel.chassisModelName
                ? ` / ${chassisMake.chassisMakeName} and ${chassisModel.chassisModelName}`
                : ''}
            </>
          ),
        });
        refetchData();
        onClose();
      },
      onError: () => onClose(),
    },
  );

  return (
    <Modal
      id="remove-standard-item-modal"
      variant="medium"
      onClose={onClose}
      isOpen={isOpen}
      actions={
        <div className="flex-align-start flex-justify-start">
          <Button
            data-testid="approve-reject-modal-cancel-btn"
            variant="unstyled"
            onClick={onClose}
            className="padding-right-1"
            label="Cancel"
          />
          <Button
            variant={action === 'Approve' ? 'primary' : 'secondary'}
            onClick={approveRejectMakeModel}
            label={action === 'Approve' ? 'Approve' : 'Reject'}
          />
        </div>
      }
    >
      <>
        {(loading || isbidLineMakeModelDataLoading) && (
          <div
            role="none"
            className="afp-modal-overlay"
            data-testid="approve-reject-modal-loading-spinner"
          >
            <Spinner
              style={{
                position: 'absolute',
                top: '50%',
                transform: 'translate(0, -50%)',
              }}
            />
          </div>
        )}
        <div>
          <div className="margin-top-10 margin-bottom-8">
            <span>
              <strong>
                Are you sure you would like to{' '}
                {action === 'Approve' ? 'approve' : 'reject'} the make/model?
              </strong>
            </span>
            <br />
            {bodyMake.makeName} / {bodyModel.modelName}{' '}
            {chassisMake &&
            chassisMake.makeName &&
            chassisModel &&
            chassisModel.modelName
              ? `/ ${chassisMake.makeName} / ${chassisModel.modelName}`
              : ''}
          </div>
          {action === 'Reject' && (
            <CommentSection
              commentBody={rejectCommentBody}
              onCommentChange={setRejectCommentBody}
            />
          )}
        </div>
      </>
    </Modal>
  );
};

ApproveRejectConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.shape(
    PropTypes.objectOf({
      makeCode: PropTypes.number.isRequired,
      makeName: PropTypes.string.isRequired,
      modelCode: PropTypes.string.isRequired,
      modelName: PropTypes.string.isRequired,
      chassisMakeCode: PropTypes.number,
      chassisModelCode: PropTypes.string,
      makeModelApprovalId: PropTypes.number,
    }),
  ).isRequired,
  action: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  refetchData: PropTypes.func.isRequired,
};

const ConnectedMakeModelApprovalModal = connectModal(
  ApproveRejectConfirmationModal,
);

export default ConnectedMakeModelApprovalModal;
