import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AssignQuantityTable from './assign-quantity-table';
import Breadcrumbs from '../../../../../widgets/breadcrumbs';
import SolicitationNotification from '../../../utils/solicitation-notification';
import { ACTION_PARAM_KEY } from '../../../route-utils';

const AssignQuantityToStandardItems = () => {
  const par = useLocation();

  const solicitaionProcessIdentifier = new URLSearchParams(par.search).get(
    ACTION_PARAM_KEY,
  );

  const [mode, setMode] = useState('add');

  useEffect(() => {
    if (
      par.pathname.includes('edit-solicitation') &&
      solicitaionProcessIdentifier === 'Renew'
    ) {
      setMode('renew');
    } else if (par.pathname.includes('edit-solicitation')) {
      setMode('edit');
    } else {
      setMode('add');
    }
  }, [par.pathname]);

  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs.home}/home`,
      label: 'Home',
    },
    {
      location: '/catalog/solicitations',
      label: 'Solicitations',
    },
  ];

  const pathMap = {
    add: 'New Solicitation',
    edit: 'Edit Solicitation',
    renew: 'Renew Solicitation',
  };

  return (
    <>
      <Breadcrumbs current={pathMap[mode]} path={breadcrumbsPath} />
      <SolicitationNotification isModal={false} />
      <AssignQuantityTable mode={mode} />
    </>
  );
};

export default AssignQuantityToStandardItems;
