import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { GET_ANNOUNCEMENT_BY_BOARD } from '../../../services/data-store';

export const FvsLandingPageContext = createContext({});

const initialState = {
  modalMode: null,
  showFormModal: false,
  relatedTasks: {
    rows: [],
    hasMore: false,
    count: 0,
  },
};

export const FvsLandingPageReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case 'SET_MODAL_MODE': {
      if (action.payload) {
        return { ...state, modalMode: action.payload, showFormModal: true };
      }
      return { ...state, modalMode: action.payload, showFormModal: false };
    }
    // Announcement Billboard Copy
    case 'SET_HEADER_COPY': {
      return { ...state, headerCopy: action.payload };
    }
    case 'SET_INFO_COPY': {
      return { ...state, infoCopy: action.payload };
    }
    default: {
      return state;
    }
  }
};

function FvsLandingPageProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(
    FvsLandingPageReducer,
    initialState,
    () => {
      return initialState;
    },
  );

  const setFvsLandingPageData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const [
    getAnnouncementForFvsHeader,
    { variables: headerVariable },
  ] = useLazyQuery(GET_ANNOUNCEMENT_BY_BOARD, {
    fetchPolicy: 'network-only',
    onError: (requestError) => {
      // eslint-disable-next-line no-console
      console.error(requestError);
    },
    onCompleted: (res) => {
      if (res?.getAnnouncementByBoard) {
        if (headerVariable.boardType === 1) {
          setFvsLandingPageData(
            'SET_HEADER_COPY',
            res?.getAnnouncementByBoard?.content,
          );
        }
      }
    },
  });

  const [getAnnouncementForFvsInfo, { variables: infoVariable }] = useLazyQuery(
    GET_ANNOUNCEMENT_BY_BOARD,
    {
      fetchPolicy: 'network-only',
      onError: (requestError) => {
        // eslint-disable-next-line no-console
        console.error(requestError);
      },
      onCompleted: (res) => {
        if (res?.getAnnouncementByBoard) {
          if (infoVariable.boardType === 2) {
            setFvsLandingPageData(
              'SET_INFO_COPY',
              res?.getAnnouncementByBoard?.content,
            );
          }
        }
      },
    },
  );

  return (
    <FvsLandingPageContext.Provider
      value={{
        ...state,
        setFvsLandingPageData,
        getAnnouncementForFvsHeader,
        getAnnouncementForFvsInfo,
        ...props,
      }}
    >
      {children}
    </FvsLandingPageContext.Provider>
  );
}

export default FvsLandingPageProvider;

FvsLandingPageProvider.defaultProps = {};

FvsLandingPageProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useFvsLandingPageContext = () => useContext(FvsLandingPageContext);
