import React from 'react';
import { useLocation } from 'react-router-dom';
import Breadcrumbs from '../../../../../widgets/breadcrumbs';
import EditSelectStandardItemWrapper from './sin-selection';
import SelectSIModalWrapper from './utils/select-si-modal-wrapper';
import { ACTION_PARAM_KEY } from '../../../route-utils';

const EditSolicitationSINSelection = () => {
  const { search } = useLocation();
  const solicitaionProcessIdentifier = new URLSearchParams(search).get(
    ACTION_PARAM_KEY,
  );
  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs.home}/home`,
      label: 'Home',
    },
    {
      location: '/catalog/solicitations',
      label: 'Solicitations',
    },
  ];

  return (
    <>
      <div className="grid-col">
        {solicitaionProcessIdentifier === 'Renew' ? (
          <Breadcrumbs current="Renew Solicitation" path={breadcrumbsPath} />
        ) : (
          <Breadcrumbs current="Edit Solicitation" path={breadcrumbsPath} />
        )}
        <EditSelectStandardItemWrapper />
        <SelectSIModalWrapper />
      </div>
    </>
  );
};

export default EditSolicitationSINSelection;
