import React from 'react';
import PropTypes from 'prop-types';
import BidsMakeAndModelProvider from '../../si-filter-table-frame/make-and-model/make-and-model-provider';
import EditBidLineItemModal, {
  EditBidLineModalDataType,
} from './edit-bid-line-item-modal';

const EditBidLineItemModalWrapper = ({ onClose, initialData }) => {
  return (
    <BidsMakeAndModelProvider initialData={initialData}>
      <EditBidLineItemModal data={initialData} onClose={onClose} />
    </BidsMakeAndModelProvider>
  );
};

EditBidLineItemModalWrapper.propTypes = {
  onClose: PropTypes.func.isRequired,
  initialData: EditBidLineModalDataType,
};
EditBidLineItemModalWrapper.defaultProps = {
  initialData: {
    standardItemNumber: '',
    title: '',
    tags: { value: [] },
    afpMake: [],
  },
};

export default EditBidLineItemModalWrapper;
