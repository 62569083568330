import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  AccordionItem,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import VehicleInfoSection from './sections/vehicle-info';
import PriceInfoSection from './sections/price-info';
import Clarifications from './sections/clarifications';
import VendorAssociatedLocationSection from './sections/vendor-associated-location/vendor-associated-locations-section';
import { TAB_KEY } from '../copy-line-item/helpers';

const BidDetails = ({ openCopyModal }) => {
  return (
    <div className="margin-bottom-8">
      <div className="display-flex flex-row flex-justify flex-align-end margin-bottom-4">
        <h3>Bid detail</h3>
        <Button
          variant="outline"
          label="Copy bid detail"
          leftIcon={{
            name: 'content_copy',
          }}
          className="margin-left-2"
          onClick={() => openCopyModal(TAB_KEY.bidDetail)}
        />
      </div>
      <div className="padding-bottom-2">
        <p>
          Required fields are marked with an asterisk (
          <RequiredFieldIndicator />
          ).
        </p>
      </div>

      <AccordionItem
        key="vehicle_info"
        id="vehicle_info"
        title="Vehicle information"
        expanded
        bordered
        content={
          <div>
            <VehicleInfoSection />
          </div>
        }
      />

      <AccordionItem
        key="price_info"
        id="price_info"
        title="Price information"
        expanded
        content={
          <>
            <PriceInfoSection />
          </>
        }
      />

      <AccordionItem
        key="associated_location_info"
        id="associated_location_info"
        title="Final assembly point / Inspection point"
        expanded
        content={
          <div className="width-full">
            <VendorAssociatedLocationSection />
          </div>
        }
      />

      <AccordionItem
        key="clarifications"
        id="clarifications"
        title="Clarifications"
        expanded
        content={
          <>
            <Clarifications />
          </>
        }
      />

      <AccordionItem
        key="technical_exceptions"
        id="technical_exceptions"
        title="Technical exceptions"
        expanded
        content={
          <>
            <Clarifications clarificationType="E" />
          </>
        }
      />
    </div>
  );
};

BidDetails.propTypes = {
  openCopyModal: PropTypes.func.isRequired,
};

export default BidDetails;
