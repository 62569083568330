export const MODAL_MODES = {
  APPLY_REVIEW_STATE: 'review',
  MANAGE_COMMENT: 'manage',
  REPLY: 'reply',
  PUBLISH: 'publish',
  DELETE_COMMENT: 'delete_comment',
  CONFIRM_ARCHIVE: 'confirm_archive',
};

export const LABELS = {
  DATE: 'Date',
  COMMENT_DETAIL: 'Comment details',
  REVIEW_STATE: 'Review state',
  ACTIONS: 'Actions',
};

export const FILTER_OPTIONS = {
  PUBLISH_STATUS: ['Unpublished', 'Published'],
  REVIEW_STATE: ['New', 'Approved', 'Disapproved', 'Noted', 'Invalid'],
  USER_TYPE: ['PSO', 'Engineer', 'Public'],
  ORIGIN: ['Federal Standards', 'Standard Item', 'Equipment Code'],
};

export const PUBLISH_STATUS_FILTER = {
  Unpublished: 0,
  Published: 1,
};

export const STATUS_FILTER = {
  New: 0,
  Noted: 1,
  Approved: 2,
  Disapproved: 3,
  Invalid: 4,
};

export const AUTHOR_FILTER = {
  Engineer: 0,
  // PSO: 1,
  VehicleSupplier: 1,
  Public: 2,
};

export const ENTITY_FILTER = {
  StandardItem: 0,
  EquipmentCode: 1,
  Association: 2,
  Document: 3,
};
