import { isEmpty } from 'lodash';

const nextYear = new Date().getFullYear() + 1;
const getOptions = (options, optionKey) => {
  if (isEmpty(options) || !optionKey) {
    return [];
  }
  return options[optionKey];
};

const getProgramOptions = (options) => {
  const rawProgramOptions = getOptions(options, 'program');
  if (rawProgramOptions.length !== 0) {
    const programOptions = rawProgramOptions.map((element) => ({
      value: element?.value,
      label: element?.label,
    }));
    programOptions.unshift({ label: 'Select', value: '' });
    return programOptions;
  }
  return [];
};

// eslint-disable-next-line import/prefer-default-export
export const getFilterStructure = (filterOptions, equipmentCodesFilter) => [
  {
    key: 'year',
    title: 'Contract year',
    type: 'select',
    options: getOptions(filterOptions, 'year'),
    value: nextYear.toString(),
    operator: 'EQ',
    expanded: true,
    hideClear: true,
  },
  {
    key: 'program',
    title: 'Program',
    type: 'select',
    options: getProgramOptions(filterOptions),
    value: '',
    operator: 'EQ',
    expanded: true,
    hideClear: true,
  },
  {
    key: 'code',
    title: 'Code',
    type: 'typeahead',
    value: equipmentCodesFilter?.code || '',
    operator: 'EQ',
    expanded: true,
    hideClear: true,
    customFieldProps: { inputCharNum: 1 },
  },
  {
    // There is similar key with other filter hence prefix with ec
    key: 'ec_title',
    title: 'Title',
    type: 'typeahead',
    value: equipmentCodesFilter?.title || '',
    operator: 'EQ',
    expanded: true,
    hideClear: true,
    customFieldProps: { inputCharNum: 1 },
  },
  {
    key: 'ec_status',
    title: 'Status',
    type: 'multiselect',
    options: [
      { label: 'Active', value: 'Active' },
      { label: 'Inactive', value: 'Inactive' },
    ],
    value: equipmentCodesFilter?.status || ['Active'],
    operator: 'OR',
    expanded: true,
    hideClear: true,
  },
  {
    key: 'ec_quantityRequired',
    title: 'Valid quantity',
    type: 'multiselect',
    options: [
      { label: 'Not Applicable', value: 'Not Applicable' },
      { label: 'Order', value: 'Order' },
      { label: 'Vehicle', value: 'Vehicle' },
    ],
    value: equipmentCodesFilter?.quantityRequired || [],
    operator: 'OR',
    expanded: true,
    hideClear: true,
  },
  {
    key: 'category',
    title: 'Category code',
    type: 'typeahead',
    value: equipmentCodesFilter?.category || '',
    operator: 'EQ',
    expanded: true,
    hideClear: true,
    customFieldProps: { inputCharNum: 1 },
  },
  {
    key: 'tags',
    title: 'Tags',
    type: 'multiselect',
    options: getOptions(filterOptions, 'tags'),
    value: equipmentCodesFilter?.tags || [],
    operator: 'JSON_CONTAINS',
    expanded: true,
    hideClear: true,
  },
];
