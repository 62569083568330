import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import fileDownload from 'js-file-download';
import { GET_STANDARDS_REL_DOCS } from './fvs.gql';
import { useFederalStandards } from './fvs-provider';
import { GET_AWS_READ_URL } from '../../components/Attachment/helpers';

export const FvsSupportingDocsContext = createContext({});

const initialState = {
  isAttachmentLoading: false,
  supportingDocs: {
    count: 0,
    hasMore: false,
    rows: [],
  },
  selectedDocument: null,
  signedUrl: null,
  showDocCommentTab: false,
  fileName: null,
};

const FvsSupportingDocsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_IS_ATTACHMENT_LOADING': {
      return { ...state, headerCopy: action.payload };
    }
    case 'SET_SHOW_DOC_COMMENT_TAB': {
      return { ...state, showDocCommentTab: action.payload };
    }
    case 'GET_SUPPORTING_DOCS': {
      return { ...state, supportingDocs: action.payload };
    }
    case 'SET_SELECTED_DOC': {
      return { ...state, selectedDocument: action.payload };
    }
    case 'SET_PDF_PREVIEW': {
      return { ...state, signedUrl: action.payload };
    }
    case 'SET_FILE_NAME': {
      return { ...state, fileName: action.payload };
    }
    default:
      return state;
  }
};

function FvsSupportingDocsProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(
    FvsSupportingDocsReducer,
    initialState,
    () => {
      return initialState;
    },
  );

  const { vehicleStandard, setFvsError } = useFederalStandards();

  const fetchDocumentVariables = {
    variables: {
      filters: {
        operator: 'AND',
        value: [
          {
            operator: 'EQ',
            key: 'fvsId',
            value: vehicleStandard?.id,
          },
        ],
      },
      limit: 5,
      offset: 0,
      order: 'createdAt DESC',
    },
  };

  /**
   * Add state
   */
  const [addState, setAddState] = useState({
    show: false,
    adding: false,
    added: false,
  });

  /**
   * Error state
   */
  const [attachmentErrors, setAttachmentErrors] = useState({});

  const setFvsSupportingDocsData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const [getStandardsSupportingDocs] = useLazyQuery(GET_STANDARDS_REL_DOCS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (requestError) => {
      setFvsError(requestError);
    },
    onCompleted: (responseData) => {
      if (responseData?.getStandardsDocuments) {
        setFvsSupportingDocsData(
          'GET_SUPPORTING_DOCS',
          responseData.getStandardsDocuments,
        );
      }
    },
  });
  const [getSignedURL, { loading: gettingReadURL }] = useLazyQuery(
    GET_AWS_READ_URL,
    {
      onError: (requestError) => {
        setFvsError(requestError);
      },
      onCompleted: async (requestData) => {
        if (requestData?.generateReadURL) {
          setFvsSupportingDocsData(
            'SET_PDF_PREVIEW',
            requestData?.generateReadURL,
          );
        }
      },
    },
  );

  const [downloadFile, { loading: downloadingFile }] = useLazyQuery(
    GET_AWS_READ_URL,
    {
      onError: (requestError) => {
        setFvsError(requestError);
      },
      onCompleted: async (requestData) => {
        if (requestData?.generateReadURL) {
          try {
            const fileURL = requestData.generateReadURL;
            const type = state.selectedDocument?.metadata?.fileMimeType;

            // fetch file using signed url.
            const response = await fetch(fileURL, {
              method: 'GET',
              headers: { 'Content-Type': type },
            });
            // Grab blob from response
            const blob = await response.blob();
            // Browser starts download
            let fn = state.selectedDocument?.metadata?.name;
            if (!fn) {
              fn = state?.fileName;
            }
            fileDownload(blob, fn);
          } catch (e) {
            setFvsError({});
          }
        }
      },
    },
  );

  const downloadPublicFile = useCallback(
    async ({ fileKey, fileType, fileName }) => {
      try {
        const fileURL = fileKey;
        const type = fileType;
        const response = await fetch(fileURL, {
          method: 'GET',
          headers: { 'Content-Type': type },
        });
        const blob = await response.blob();
        fileDownload(blob, fileName);
      } catch (e) {
        setFvsError({
          message: e,
        });
      }
    },
    [],
  );

  return (
    <FvsSupportingDocsContext.Provider
      value={{
        ...state,
        gettingReadURL,
        downloadingFile,
        setFvsSupportingDocsData,
        addState,
        setAddState,
        attachmentErrors,
        setAttachmentErrors,
        getStandardsSupportingDocs,
        getSignedURL,
        downloadFile,
        downloadPublicFile,
        fetchDocumentVariables,
        ...props,
      }}
    >
      {children}
    </FvsSupportingDocsContext.Provider>
  );
}

export default FvsSupportingDocsProvider;

FvsSupportingDocsProvider.defaultProps = {};

FvsSupportingDocsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useFvsSupportingDocs = () => useContext(FvsSupportingDocsContext);
