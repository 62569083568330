import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RichTextEditor } from '@gsa/afp-component-library';
import { useFvsLandingPageContext } from '../fvs-landing-page-provider';

function RTEPopover({ boardType, copy, formId }) {
  const {
    setFvsLandingPageData,
    updateAnnouncementByBoard,
  } = useFvsLandingPageContext();

  const [value, setValue] = useState(copy);

  const handleSubmit = (e) => {
    e.preventDefault();

    updateAnnouncementByBoard({
      variables: { boardType, content: value },
    });

    setFvsLandingPageData('SET_MODAL_MODE', null);
  };

  return (
    <div className="padding-2">
      <form id={formId} onSubmit={handleSubmit}>
        <RichTextEditor
          onChange={(v) => setValue(v)}
          content={value}
          disableHeaders
        />
      </form>
    </div>
  );
}

RTEPopover.propTypes = {
  boardType: PropTypes.number.isRequired,
  copy: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
};

export default RTEPopover;
