/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import PropTypes from 'prop-types';
import SolicitationStatusBadge from './solicitation-status-badge';

const SolicitationHeader = ({
  solicitationNumber,
  solicitationStatus,
  solicitationType,
}) => {
  return (
    <div className="grid-row margin-bottom-2">
      <div className="grid-col-7">
        <h1
          id="section-heading-h1"
          tabIndex="0"
          aria-label={`${solicitationType} Solicitation-${solicitationNumber}`}
        >
          {`${solicitationType} Solicitation-${solicitationNumber}`}
          <SolicitationStatusBadge
            status={solicitationStatus}
            className="radius-pill font-ui-xs text-light padding-05 margin-2 bg-gray-3"
            tabIndex="0"
            aria-label={solicitationStatus}
          />
        </h1>
      </div>
    </div>
  );
};

SolicitationHeader.propTypes = {
  solicitationNumber: PropTypes.string.isRequired,
  solicitationStatus: PropTypes.string.isRequired,
  solicitationType: PropTypes.string.isRequired,
};

export default SolicitationHeader;
