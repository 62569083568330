import React from 'react';
import { useTaskDetail } from '../task-detail-provider';

const Description = () => {
  const { task } = useTaskDetail();

  return (
    <>
      <h2 className="font-sans-m margin-bottom-0 margin-top-0">{task.title}</h2>
    </>
  );
};

Description.propTypes = {};

Description.defaultProps = {};

export default Description;
