/* eslint-disable no-unused-vars */
const generateFilterCondition = (operator, key, value) => {
  if (!key || !value || !value.length) {
    return [];
  }
  return [
    {
      operator,
      key,
      value,
    },
  ];
};

export const getSolicitationFilters = ({
  process,
  year,
  audience,
  status,
  purchaseType,
  solicitationNumber,
  title,
  programName,
  standardItem,
}) => {
  return {
    operator: '$and',
    conditions: [
      //   // Add condition for status
      ...generateFilterCondition('$exact', 'status', status),
      ...generateFilterCondition('$exact', 'contractYear', year),
      ...generateFilterCondition('$exact', 'purchaseTypeCode', purchaseType),
      ...generateFilterCondition('$exact', 'solicitationType', process),
      ...generateFilterCondition('$exact', '$programs.program$', programName),
      ...generateFilterCondition(
        '$exact',
        'solicitationNumber',
        solicitationNumber,
      ),
      ...generateFilterCondition('$exact', 'title', title),
    ],
  };
};
export const OrderByMap = {
  status: 'status',
};
