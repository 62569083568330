import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import AddStandardItemsHeader from './header';
import ProgramSection from './program-section';
import { enDashUnicode } from '../../../utilities/constants';

const AddStandardItems = ({ selectedBidLines, fvsPrograms }) => {
  const [selections, setSelections] = useState([]);
  const [hasAssociatedLineItems,setHasAssociatedLineItems] = useState(false);
  const { state: solicitation } = useLocation();

  const result = solicitation?.programs.reduce((acc, item) => {
    const { program } = item;
    item.solicitationLines.forEach((line) => {
      const { title } = line.standardItem.vehicleGroup;
      const { standardItem } = line;
      const updatedStandardItem = {
        ...standardItem,
        solicitationLineID: line?.id,
      };
      acc[program] = acc[program] || {};
      acc[program][title] = acc[program][title] || [];
      acc[program][title].push(updatedStandardItem);
    });
    return acc;
  }, {});

  const getFullProgramName = (shortProgramName) => {
    const matchedObject = fvsPrograms?.find(
      (program) => program.value === shortProgramName,
    );
    return matchedObject ? matchedObject.label : enDashUnicode;
  };

  useEffect(() => {
    setSelections(selectedBidLines || []);
  }, [selectedBidLines]);

  return (
    <div className="margin-bottom-8">
      <AddStandardItemsHeader
        bidLines={selections}
        title={solicitation?.title}
        hasAssociatedLineItems={hasAssociatedLineItems}
      />
      <div className="display-flex flex-row flex-justify-end padding-bottom-2">
        <div className="title-m-bold padding-right-1">{selections.length}</div>
        <div className="title-m">Standard Items Selected</div>
      </div>
      {Object.entries(result).map(([key, value]) => (
        <div key={key} className="margin-bottom-6">
          <div className="border-bottom-1px border-gray-30">
            <div className="title-m">{getFullProgramName(key)}</div>
          </div>
          <ProgramSection
            value={value}
            selections={selections}
            setSelections={setSelections}
            selectedStandardItems={selectedBidLines}
            setHasAssociatedLineItems={setHasAssociatedLineItems}
          />
        </div>
      ))}
    </div>
  );
};

AddStandardItems.propTypes = {
  selectedBidLines: PropTypes.arrayOf(PropTypes.object).isRequired,
  fvsPrograms: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AddStandardItems;
