import React from 'react';
import PropTypes from 'prop-types';
import { StatusBadge } from '@gsa/afp-component-library';
import {
  SOLICITATION_STATUSES,
  SOLICITATION_STATUS_BADGE_VARIANT_MAP,
} from '../constants';
import { isReopenActionFlow, isMidCycleActionFlow } from '../route-utils';

function SolicitationStatusBadge({ status, ...restProps }) {
  const badgeContent =
    isReopenActionFlow() || isMidCycleActionFlow()
      ? SOLICITATION_STATUSES.Draft
      : status;
  return (
    <StatusBadge
      {...restProps}
      variant={SOLICITATION_STATUS_BADGE_VARIANT_MAP[badgeContent]}
    >
      {badgeContent}
    </StatusBadge>
  );
}

SolicitationStatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
};

export default SolicitationStatusBadge;
