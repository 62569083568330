import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Spinner } from '@gsa/afp-component-library';
import { StatusIcon } from '../bid-line-details/components/tags';
import { TAB_KEY } from '../bid-line-details/copy-line-item/helpers';
import { GET_TABS_STATUSES } from '../../bids/bids.gql';
import { BID_LINE_STATUS } from '../constants';

const TabStatuses = ({
  original: { id, bidId, status },
  isBidClosed,
  refetchData,
}) => {
  const getUrl = (tab) => {
    const { tabIndex, hash } = tab;
    const pathname = `./${bidId}/bid-line-details/${id}`;
    return isBidClosed
      ? `${pathname}/review-and-submit#${hash}`
      : { pathname, state: { tabIndex } };
  };

  const [tabStatuses, setTabStatuses] = useState([]);
  const { loading } = useQuery(GET_TABS_STATUSES, {
    fetchPolicy: 'network-only',
    skip:
      status === BID_LINE_STATUS.NotStarted ||
      status === BID_LINE_STATUS.Submitted,
    variables: { bidLineId: parseInt(id, 10) },
    onCompleted: ({ getBidLineTabsStatuses }) => {
      setTabStatuses(getBidLineTabsStatuses);
      if (
        getBidLineTabsStatuses.every((s) => s.value) !==
        (status === BID_LINE_STATUS.ReadyToSubmit)
      )
        refetchData();
    },
    onError: () => {},
  });

  return (
    <section className="display-flex flex-column">
      {[
        {
          key: TAB_KEY.bidDetail,
          label: 'Bid detail',
          tabIndex: 0,
          hash: 'bid-detail',
        },
        {
          key: TAB_KEY.minimumRequirements,
          label: 'Minimum requirements',
          tabIndex: 1,
          hash: 'min-requirements',
        },
        {
          key: TAB_KEY.optionalEquipment,
          label: 'Optional equipment',
          tabIndex: 2,
          hash: 'opt-equipment',
        },
        {
          key: TAB_KEY.engineFuel,
          label: 'Engine and fuel',
          tabIndex: 3,
          hash: 'engine',
        },
        { key: TAB_KEY.colors, label: 'Colors', tabIndex: 4, hash: 'colors' },
        {
          key: TAB_KEY.documents,
          label: 'Documents',
          tabIndex: 5,
          hash: 'documentation',
        },
      ].map((tab) => (
        <div
          key={tab.key}
          className="display-flex flex-row flex-start margin-bottom-105"
        >
          {loading ? (
            <Spinner aria-busy="true" size="small" role="status" />
          ) : (
            <StatusIcon
              isReady={
                status === BID_LINE_STATUS.Submitted ||
                (status !== BID_LINE_STATUS.NotStarted &&
                  tabStatuses.find((s) => s.key === tab.key)?.value === true)
              }
            />
          )}
          <Link to={getUrl(tab)} className="margin-left-1">
            {tab.label}
          </Link>
        </div>
      ))}
    </section>
  );
};

TabStatuses.propTypes = {
  original: PropTypes.objectOf({
    id: PropTypes.string.isRequired,
    bidId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  isBidClosed: PropTypes.bool.isRequired,
  refetchData: PropTypes.func.isRequired,
};

export default TabStatuses;
