import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import moment from 'moment';

export const isEmpty = (value) => {
  return value === undefined || value === null || value === '';
};

export const POSSIBLE_DATE_FORMAT = ['MM/DD/YYYY', 'YYYY-MM-DD'];
export const parseDateString = (value, originalValue) => {
  if (!originalValue) return null;
  const parsedDate =
    originalValue instanceof Date && originalValue
      ? originalValue
      : moment(originalValue, POSSIBLE_DATE_FORMAT, true).toDate();
  return parsedDate;
};

export function timeValidation(value) {
  const { startTime } = this.parent || {};
  const dayStr = moment().format('DD/MM/YYYY');
  return moment(`${dayStr} ${value}`, 'DD/MM/YYYY HH:mm').isAfter(
    moment(`${dayStr} ${startTime}`, 'DD/MM/YYYY HH:mm'),
  );
}

export function checkIfTimeIsPast(value) {
  const passedValue = moment(value, ['HH: mm A']).format('HH:mm A');
  const currentTime = moment().format('HH:mm A');
  return passedValue >= currentTime;
}

export const solicationFormSchema = yup.object().shape({
  upiidAAC: yup
    .string()
    .min(6, 'This field must be 6 characters')
    .max(6, 'This field must be 6 characters')
    .required('This is a required field')
    .matches('[a-zA-Z0-9]{6}', 'Invalid UPIID number'),
  upiidSQ: yup
    .string()
    .min(7, 'This field must be 7 characters')
    .max(7, 'This field must be 7 characters')
    .required('This is a required field')
    .matches('^[0-9]{2}[a-zA-Z]{1}[0-9]{4}$', 'Invalid UPIID number'),
  solicitationTitle: yup.string().required('This is a required field'),
  solicitationDescription: yup.string(),
  solicitationPosting: yup.string().url('Please enter a valid URL'),
  contractYear: yup.string().required(),
  purchaseType: yup.string().required(),
  startDate: yup.date().typeError(' ').transform(parseDateString).nullable(),
  endDate: yup
    .date()
    .typeError(' ')
    .transform(parseDateString)
    .nullable()
    .when('startDate', {
      is: (startDate) => !isEmpty(startDate),
      then: yup
        .date('error')
        .typeError(' ')
        .transform(parseDateString)
        .nullable()
        .required('Closing date is required')
        .min(yup.ref('startDate'), 'End date must be after start date'),
    }),
  startTime: yup.string().when('startDate', {
    is: (startDate) => !isEmpty(startDate),
    then: yup.string().required('Posting time is required'),
  }),
  endTime: yup
    .mixed()
    .when('startDate', {
      is: (startDate) => !isEmpty(startDate),
      then: yup.mixed().required('Closing time is required'),
    })
    .when(['startDate', 'endDate'], {
      is: (startDate, endDate) =>
        !isEmpty(startDate) &&
        !isEmpty(endDate) &&
        moment(startDate).isSame(endDate, 'day'),
      then: yup
        .mixed()
        .test(
          'is-greater',
          'Closing time must be after posting time.',
          timeValidation,
        ),
    }),
});
// eslint-disable-next-line react/prop-types
export const SoliciationFormContext = ({ solicitation, children }) => {
  const defaultValues = solicitation || {
    upiidAAC: '47QMCA',
  };
  const methods = useForm({
    resolver: yupResolver(solicationFormSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues,
  });
  return <FormProvider {...methods}>{children}</FormProvider>;
};
