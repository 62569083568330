import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Spinner, Button } from '@gsa/afp-component-library';
import {
  GET_DOCUMENT_META_RECORD_BY_ID,
  GET_SIGNED_READ_URL,
} from '../provider/queries';

import AttachmentModal from '../../../../components/Attachment/AttachmentModal';

const Modal = ({ url, onClose }) => {
  return (
    <AttachmentModal
      id="image-attachment-preview-modal"
      show
      onClose={onClose}
      hideClose
      variant="large"
      className="bideline-image-attachment-modal"
      actions={
        <Button
          data-testid="attachment-image-modal-close"
          label="Close"
          onClick={onClose}
        />
      }
    >
      <img src={url} alt="Bidline attachment" />
    </AttachmentModal>
  );
};

Modal.propTypes = {
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const PhotoCell = ({ documentMetadataId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [scanStatus, setScanStatus] = useState(null);

  const [getSignedReadURL] = useLazyQuery(GET_SIGNED_READ_URL, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setUrl(data?.generateReadSignedURL);
      setIsLoading(false);
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  const [getDocumentMetaRecord] = useLazyQuery(GET_DOCUMENT_META_RECORD_BY_ID, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data?.getDocMetadata) {
        setScanStatus(data?.getDocMetadata?.scanStatus ?? null);
        getSignedReadURL({
          variables: {
            fileKey: data.getDocMetadata.fileLocation,
            fileType: data.getDocMetadata.fileMimeType,
          },
        });
      }
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (documentMetadataId) {
      setIsLoading(true);
      getDocumentMetaRecord({
        variables: {
          id: String(documentMetadataId),
        },
      });
    }
  }, [documentMetadataId]);

  const width = 102;
  const height = 66;

  let content = null;
  if (isLoading) {
    content = <Spinner size="small" />;
  } else if (scanStatus === 'New') {
    content = (
      <div title="Scanning file for viruses">
        <div className="body-small-tight text-center">Virus scanning...</div>
      </div>
    );
  } else if (scanStatus === 'Quarantined') {
    content = (
      <div>
        <div className="body-small-tight">Quarantined</div>
      </div>
    );
  } else if (scanStatus === 'Accepted' && url) {
    content = (
      <img src={url} alt="Bidline attachment" width={width} height={height} />
    );
  }

  return (
    <>
      <div
        role="button"
        tabIndex="0"
        onClick={() => {
          if (scanStatus === 'Accepted') {
            setShowModal(true);
          }
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13 || e.keyCode === 32) {
            if (scanStatus === 'Accepted') {
              setShowModal(true);
            }
          }
        }}
        style={{
          display: 'flex',
          width,
          height: height + 2,
          margin: 8,
          border: `1px solid #A9AEB1`,
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        {content}
      </div>
      {showModal && <Modal url={url} onClose={() => setShowModal(false)} />}
    </>
  );
};

PhotoCell.propTypes = {
  documentMetadataId: PropTypes.string.isRequired,
};

export default PhotoCell;
