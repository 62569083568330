import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import { useFederalStandards } from '../fvs-provider';
import FvsRequirementsProvider from './requirements-provider';
import RequirementInfo from './components/requirement-info';
import RequirementTabs from './components/requirement-tabs';
import FvsMessages from '../components/fvs-messages';
import RequirementsModalWrapper from './components/requirements-modal';

const Requirements = () => {
  const params = useParams();
  const {
    standardItem,
    setFvsContextData,
    standardItems,
    isSILoading,
    removeFvsMessageById,
    selectedYear,
    selectedOptions,
    clearAllMessages,
  } = useFederalStandards();

  const { year, fedCode, standardItemNumber } = params;

  useEffect(() => {
    const y = Number.parseInt(year, 10);
    if (selectedYear !== y) {
      setFvsContextData?.('SET_SELECTED_YEAR', y);
    }
  }, [selectedYear]);

  // Clear generated messages on load and also before unload
  useEffect(() => {
    clearAllMessages();
  }, []);

  useEffect(() => {
    return () => {
      clearAllMessages();
    };
  }, []);

  const location = useLocation();
  const { showCommentTab } = queryString.parse(location.search);

  useEffect(() => {
    if (showCommentTab) {
      setFvsContextData('SET_SHOW_COMMENT_TAB', showCommentTab);
    }
  }, [showCommentTab]);

  useEffect(() => {
    if (!standardItem && !isSILoading) {
      setFvsContextData('SET_SELECTED_OPTIONS', {
        fedStandardCode: fedCode,
        standardItemNumber,
        vehicleGroup: '',
        vehicleType: '',
      });
      setFvsContextData('SET_SELECTED_STANDARD_ITEM', standardItemNumber);
    } else if (selectedOptions?.fedStandardCode !== fedCode) {
      setFvsContextData('SET_SELECTED_OPTIONS', {
        fedStandardCode: fedCode,
        standardItemNumber: '',
        vehicleGroup: '',
        vehicleType: '',
      });
    }
  }, [standardItems, standardItem, year, fedCode]);

  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs?.home}/home`,
      label: 'Home',
    },
    {
      location: '/catalog/vehicle-standards',
      label: 'Federal Vehicle Standards',
    },
  ];

  return (
    <FvsRequirementsProvider>
      <div>
        <div className="grid-row grid-gap" data-testid="fvs-container">
          <div className="tablet:grid-col">
            <Breadcrumbs
              current={`${standardItem?.standardItemNumber} - ${standardItem?.title} - ${standardItem?.year}`}
              path={breadcrumbsPath}
            />
            <FvsMessages onClose={removeFvsMessageById} />
          </div>
        </div>

        <div className="grid-col">
          <RequirementInfo />
          <RequirementTabs />
        </div>
        {standardItem && !isSILoading && <RequirementsModalWrapper />}
      </div>
    </FvsRequirementsProvider>
  );
};

export default Requirements;
