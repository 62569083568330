/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, useModal } from '@gsa/afp-component-library';
import CommentModal from './comment-modal';

const CommentForm = ({
  linkedEntityId,
  linkedEntityType,
  fvsCode,
  contractYear,
}) => {
  const [commentBody, setCommentBody] = useState('');
  const submitModal = useModal();

  const submitComment = () => {
    if (commentBody && commentBody.length > 0) {
      // addComment(commentBody);
      submitModal.openModal();
    }
  };

  return (
    <>
      <div className="grid-row">
        <div className="grid-col-10">
          <div>Comment</div>
          <div>
            <textarea
              data-testid="standard-item-comment-box"
              className=" height-card width-full comment"
              aria-label="comment"
              value={commentBody}
              onChange={(e) => setCommentBody(e.target.value)}
              maxLength={2000}
            />
            <span
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex="0"
              id="characterAllowed"
              className="text-base"
              aria-labelledby="characterAllowed"
            >
              2000 characters allowed
            </span>
            <div className="comment-actions margin-bottom-3">
              <Button
                data-testid="submitCommentBtn"
                variant="primary margin-05"
                onClick={() => {
                  submitComment();
                }}
                label="Post Comment"
              />
              {submitModal.isOpen && (
                <CommentModal
                  linkedEntityId={linkedEntityId}
                  linkedEntityType={linkedEntityType}
                  fvsCode={fvsCode}
                  contractYear={contractYear}
                  commentText={commentBody}
                  onClose={(addedComment) => {
                    submitModal.closeModal();
                    if (addedComment) {
                      setCommentBody('');
                    }
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CommentForm.propTypes = {};

export default CommentForm;
