import {
  getYearFirstDayDate,
  sortDateAscendingWithAccessors,
} from '../../../utilities/date-helpers';

export const getInitialOpenPeriod = (solicitationPeriods) => {
  const [initialOpenPeriod] = [...(solicitationPeriods || [])].sort(
    sortDateAscendingWithAccessors('startDate'),
  );
  return initialOpenPeriod ?? null;
};

export const getSolicitationPeriodMinDate = (contractYear) => {
  const contractYearFirstDate = getYearFirstDayDate(contractYear);
  const today = new Date();
  return today < contractYearFirstDate ? today : contractYearFirstDate;
};
