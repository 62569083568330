import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import HtmlDiff from 'htmldiff-js';
import PropTypes from 'prop-types';
import PredeterminedInput from '../../../widgets/predetermined-input';

const MinimumsHighlighter = ({
  row: {
    original: {
      inputType,
      unit,
      lowerBound,
      upperBound,
      preDefinedValue,
      change,
    },
  },
}) => {
  let currentValue;

  switch (inputType?.code?.toLowerCase()) {
    case 'c': // Comply yes/no
      currentValue = 'Comply yes/no';
      break;
    case 'f': // Free text
      currentValue = 'Free text';
      break;
    case 'p': // Predefined value
      break;
    case 'n': {
      // Numerical
      if (lowerBound && unit?.title)
        currentValue = `${lowerBound} ${unit.title}`;
      break;
    }
    case 'r': {
      // Numerical range
      if (lowerBound && upperBound && unit?.title)
        currentValue = `${lowerBound}-${upperBound} ${unit.title}`;
      break;
    }
    default:
      break;
  }

  if (!change) {
    if (inputType?.code?.toLowerCase() === 'p') {
      return <PredeterminedInput preDefinedValue={preDefinedValue} />;
    }
    if (!currentValue) {
      return <span data-testid="empty-text">&ndash;</span>;
    }
    return currentValue;
  }

  if (change) {
    let prevInputType;
    let prevUnit;
    let prevLowerBound;
    let prevUpperBound;
    let prevPreDefinedValue;
    let previousValue;

    let fieldChange = change.differences?.differences?.find(
      (d) => d?.path.indexOf('inputTypeCode') >= 0,
    );
    if (fieldChange) {
      prevInputType = fieldChange?.lhs ? fieldChange?.lhs.toLowerCase() : '';
    } else {
      prevInputType = inputType?.code?.toLowerCase();
    }

    fieldChange = change.differences?.differences?.find(
      (d) => d?.path.indexOf('unitCode') >= 0,
    );
    if (fieldChange) {
      prevUnit = fieldChange?.lhs ? fieldChange?.lhs : unit?.title;
    } else {
      prevUnit = unit?.title;
    }

    fieldChange = change.differences?.differences?.find(
      (d) => d?.path.indexOf('lowerBound') >= 0,
    );
    if (fieldChange) {
      prevLowerBound = fieldChange?.lhs ? fieldChange?.lhs : lowerBound;
    } else {
      prevLowerBound = lowerBound;
    }

    fieldChange = change.differences?.differences?.find(
      (d) => d?.path.indexOf('upperBound') >= 0,
    );
    if (fieldChange) {
      prevUpperBound = fieldChange?.lhs ? fieldChange?.lhs : upperBound;
    } else {
      prevUpperBound = upperBound;
    }

    prevPreDefinedValue = preDefinedValue?.value?.join(', ');
    const fieldChanges = change.differences?.differences?.filter(
      (d) => d?.path.indexOf('preDefinedValue') >= 0,
    );

    if (fieldChanges) {
      fieldChanges.forEach((f) => {
        prevPreDefinedValue = prevPreDefinedValue?.replace(f?.rhs, f?.lhs);
      });
    } else {
      prevPreDefinedValue = preDefinedValue?.value?.join(', ');
    }

    switch (prevInputType) {
      case 'c': // Comply yes/no
        previousValue = 'Comply yes/no';
        break;
      case 'f': // Free text
        previousValue = 'Free text';
        break;
      case 'p': // Predefined value
        break;
      case 'n': {
        // Numerical
        if (prevLowerBound && prevUnit)
          previousValue = `${prevLowerBound} ${prevUnit}`;
        break;
      }
      case 'r': {
        // Numerical range
        if (prevLowerBound && prevUpperBound && prevUnit)
          previousValue = `${prevLowerBound}-${prevUpperBound} ${prevUnit}`;
        break;
      }
      default:
        previousValue = null;
    }

    if (!previousValue) {
      previousValue = '';
    }

    if (inputType?.code?.toLowerCase() === 'p' && prevInputType === 'p') {
      currentValue = preDefinedValue?.value?.join(', ');
      previousValue = prevPreDefinedValue;
    }

    if (inputType?.code?.toLowerCase() === 'p' && prevInputType !== 'p') {
      return <PredeterminedInput preDefinedValue={preDefinedValue} />;
    }
    return ReactHtmlParser(`<div>
          ${HtmlDiff.execute(previousValue, currentValue)}
        </div>`);
  }

  return null;
};

MinimumsHighlighter.defaultProps = {
  row: {
    original: {},
  },
};

MinimumsHighlighter.propTypes = {
  row: PropTypes.shape(Object),
};

export default MinimumsHighlighter;
