/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import MakeAndModeApprovalRequestProvider from './make-model-approval-provider';
import EditMakeModelApprovalModal from './make-model-approval-request-edit-modal';

const MakeModelApprovalModalWrapper = ({
  isOpen,
  onClose,
  data,
  refetchData,
}) => {
  return (
    <MakeAndModeApprovalRequestProvider>
      <EditMakeModelApprovalModal
        isOpen={isOpen}
        onClose={onClose}
        data={data}
        refetchData={refetchData}
      />
    </MakeAndModeApprovalRequestProvider>
  );
};

MakeModelApprovalModalWrapper.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    makeCode: PropTypes.number.isRequired,
    makeName: PropTypes.string.isRequired,
    modelCode: PropTypes.string.isRequired,
    modelName: PropTypes.string.isRequired,
    chassisMakeCode: PropTypes.number,
    chassisModelCode: PropTypes.string,
    makeModelApprovalId: PropTypes.number.isRequired,
  }).isRequired,
};

export default MakeModelApprovalModalWrapper;
