import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@gsa/afp-component-library';
import { MakeAndModelPropType } from '../../../../../utilities/types';

const DeleteMakeAndModel = ({
  closeModal,
  onSubmit,
  onClose,
  makeAndModelToBeDeleted,
}) => (
  <Modal
    id="delete-make-model-modal"
    title={<h2>Confirm deletion of Make and Model</h2>}
    onClose={() => {
      closeModal();
      onClose();
    }}
    actions={
      <>
        <Button
          type="button"
          data-testid="cancel-make-model-btn-modal"
          variant="unstyled"
          className="margin-right-2"
          onClick={() => {
            closeModal();
            onClose();
          }}
          label="Cancel"
        />
        <Button
          data-testid="delete-make-model-btn-modal"
          type="submit"
          onClick={onSubmit}
          variant="secondary"
          label="Delete"
        />
      </>
    }
  >
    {`You are about to delete ${makeAndModelToBeDeleted?.makeName} 
                         ${makeAndModelToBeDeleted?.modelName}. 
                         Are you sure you want to delete this Make and Model?`}
  </Modal>
);

DeleteMakeAndModel.propTypes = {
  makeAndModelToBeDeleted: PropTypes.shape(MakeAndModelPropType).isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

DeleteMakeAndModel.defaultProps = {
  onClose: () => {
    // noop
  },
};

export default DeleteMakeAndModel;
