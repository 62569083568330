import React from 'react';
import { Spinner, Alert } from '@gsa/afp-component-library';
import { Redirect } from 'react-router-dom';
import ActiveBids from './bids-active';
import BidsSolicitations from './bids-solicitations';
import BidManagementHeader from './components/bid-management-header';
import { useVendorInfo } from './apis/vendor-api';

const VendorUserView = () => {
  const {
    alert,
    setAlert,
    hasDraftInactiveVendors,
    isVendorDataLoading,
    isVendorDataCalled,
  } = useVendorInfo();

  if (!isVendorDataCalled || isVendorDataLoading) {
    return <Spinner />;
  }
  if (hasDraftInactiveVendors) {
    return <Redirect to="/unauthorized" />;
  }
  return (
    <>
      {alert && (
        <Alert
          type={alert.type}
          slim
          focused
          showClose
          onClose={() => setAlert(null)}
        >
          {alert.message}
        </Alert>
      )}
      <BidManagementHeader />
      <ActiveBids />
      <BidsSolicitations />
    </>
  );
};

export default VendorUserView;
