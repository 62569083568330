import React from 'react';
import { gql } from '@apollo/client';
import { Button } from '@gsa/afp-component-library';
import exportFile from '../../../components/file-export/client-side-file-export';

export const GET_JS_REPORT = gql`
  query getJSReport($input: JSReportInput!) {
    getJSReport(input: $input)
  }
`;

export const DOWNLOAD_LINE_ITEM = gql`
  query downloadLineItem($bidLineId: Float!) {
    downloadLineItem(bidLineId: $bidLineId)
  }
`;

export const getDownloadAlert = (
  reportTitle,
  fileData,
  fileType,
  fileName,
) => ({
  type: 'success',
  message: (
    <div>
      {reportTitle} has been generated successfully.{' '}
      <Button
        variant="unstyled"
        label="Click here to download the report"
        onClick={() => {
          exportFile(Buffer.from(fileData), fileType, fileName);
        }}
      />
    </div>
  ),
});

export const asyncDownloadAlert = {
  type: 'success',
  message:
    'The report is processing and might take time to generate. An email will be sent to you with a link to download the report.',
};

const STEP_LABELS = ['Report selection', 'Solicitation selection'];
export const STEPS = [
  {
    heading: { current: 1, text: STEP_LABELS[0] },
    steps: [
      { label: STEP_LABELS[0], status: 'current' },
      { label: STEP_LABELS[1], status: 'not completed' },
    ],
  },
  {
    heading: { current: 2, text: STEP_LABELS[1] },
    steps: [
      { label: STEP_LABELS[0], status: 'completed' },
      { label: STEP_LABELS[1], status: 'current' },
    ],
  },
];
