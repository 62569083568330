import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import { useFederalStandards } from '../../fvs-provider';
import { useFvsSupportingDocs } from '../../fvs-supporting-docs-provider';
import { getVehicleClassificationHierarchy } from '../../../../utilities/options-helper';
import VehicleTypeFilterItem from './vehicle-classification-filters/vehicle-type-filter';
import VehicleGroupFilterItem from './vehicle-classification-filters/vehicle-group-filter';
import FederalStandardCodeFilterItem from './vehicle-classification-filters/fsc-filter';

// eslint-disable-next-line
const FvsFilter = ({ onFilter }) => {
  const {
    vehicleClassificationOptions,
    selectedYear,
    selectedOptions,
    applicableFvsYears,
    setFvsContextData,
    removeFvsMessageById,
  } = useFederalStandards();

  const { stopPolling, setIspollingCallable } = useFvsSupportingDocs();

  const defaultYear = applicableFvsYears?.find(
    (year) => year.isDefault === true,
  ) ?? { value: new Date().getFullYear() };

  // eslint-disable-next-line
  let initialFilterStructure = [
    {
      key: 'year',
      title: 'Contract year',
      type: 'select',
      value: defaultYear?.value?.toString(),
      operator: '$or',
      options: applicableFvsYears?.map((year) => ({
        label: year.label,
        value: year.value,
      })),
      expanded: true,
      hideClear: true,
    },
    {
      key: 'vehicleType',
      title: 'Vehicle type',
      component: VehicleTypeFilterItem,
      operator: '$or',
      expanded: true,
      hideClear: true,
    },
    {
      key: 'vehicleGroup',
      title: 'Vehicle group',
      component: VehicleGroupFilterItem,
      operator: '$or',
      expanded: true,
      hideClear: false,
    },
    {
      key: 'fedStandardCode',
      title: 'Federal Standard Code',
      component: FederalStandardCodeFilterItem,
      operator: '$exact',
      expanded: true,
      hideClear: true,
    },
  ];

  const { FilterPanel: FVSFilterPanel } = FilterPanel;

  const [filterStructure, setfilterStructure] = useState(
    initialFilterStructure,
  );

  const [renderKey, setRenderKey] = useState(1);

  useEffect(() => {
    setfilterStructure(initialFilterStructure);
  }, [vehicleClassificationOptions]);

  useEffect(() => {
    setfilterStructure(initialFilterStructure);
    setRenderKey(renderKey + 1);
  }, [defaultYear?.value?.toString()]);

  const handleClearAll = () => {
    setFvsContextData('SET_SELECTED_OPTIONS', {
      fedStandardCode: '',
      vehicleGroup: '',
      vehicleType: '',
      year: defaultYear?.value?.toString(),
    });
  };

  const handleFiltersChange = (filters) => {
    // eslint-disable-next-line
    let state = {
      fedStandardCode: '',
      vehicleGroup: '',
      vehicleType: '',
      year: selectedYear.toString() || defaultYear?.value?.toString(),
    };

    const fedStandardCode = filters?.find(
      (filter) => filter.key === 'fedStandardCode',
    );
    const year = filters?.find((filter) => filter.key === 'year');
    const vehcType = filters?.find((filter) => filter.key === 'vehicleType');
    const vehcGroup = filters?.find((filter) => filter.key === 'vehicleGroup');

    if (vehcType && vehcType?.value !== selectedOptions.vehicleType) {
      state.fedStandardCode = fedStandardCode?.value;
      state.vehicleType = vehcType?.value;
      state.vehicleGroup = vehcGroup?.value;
      state.year = Number.parseInt(year?.value, 10);

      const classification = getVehicleClassificationHierarchy(
        vehicleClassificationOptions,
        state,
        selectedOptions,
      );

      setFvsContextData('SET_SELECTED_OPTIONS', {
        fedStandardCode: classification?.fedStandardCode,
        vehicleGroup: classification?.vehicleGroup,
        vehicleType: classification?.vehicleType,
      });
    } else if (vehcGroup && vehcGroup?.value !== selectedOptions.vehicleGroup) {
      state.fedStandardCode = fedStandardCode?.value || '';
      state.vehicleType = vehcType?.value || '';
      state.vehicleGroup = vehcGroup?.value;
      state.year = Number.parseInt(year?.value, 10);
      selectedOptions.vehicleType = '';

      const classification = getVehicleClassificationHierarchy(
        vehicleClassificationOptions,
        state,
        selectedOptions,
      );

      setFvsContextData('SET_SELECTED_OPTIONS', {
        fedStandardCode: classification?.fedStandardCode,
        vehicleGroup: classification?.vehicleGroup,
        vehicleType: '',
      });
    } else if (
      fedStandardCode &&
      fedStandardCode?.value !== selectedOptions.fedStandardCode
    ) {
      // clear toast messages generated for a given fvs code
      removeFvsMessageById('DOCUMENT');
      // eslint-disable-next-line
      stopPolling && stopPolling();
      // eslint-disable-next-line
      setIspollingCallable && setIspollingCallable(false);
      setFvsContextData('SET_SELECTED_OPTIONS', {
        fedStandardCode: fedStandardCode?.value,
        vehicleGroup: '',
        vehicleType: '',
      });
    } else if (year?.value !== selectedYear) {
      setFvsContextData('SET_SELECTED_YEAR', Number.parseInt(year?.value, 10));
    }

    if (
      fedStandardCode?.value === undefined &&
      state.fedStandardCode === '' &&
      state.vehicleGroup === '' &&
      state.vehicleType === ''
    ) {
      handleClearAll();
    }
  };

  return (
    <>
      <FVSFilterPanel
        key={renderKey}
        clearButtonLabel="Reset All"
        filterStructure={filterStructure}
        setQueryFiltersState={handleFiltersChange}
        handleClearAll={handleClearAll}
        showClearIcon
        retainFilters={false}
      />
    </>
  );
};

FvsFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
};

export default FvsFilter;
