import React, { useCallback, useEffect } from 'react';
import { TabSet } from '@gsa/afp-component-library';
import { useHistory, useLocation } from 'react-router-dom';

import { useContractLine } from './provider/contract-line-provider';
import { TABS } from './helpers/constants';
import ContractLineDetail from './line-details/line-details';
import MinimumRequirements from './minimum-requirements/minimum-requirements';
import OptionalEquipment from './optional-equipment/optional-equipment';
import EngineAndFuel from './engine-and-fuel/engine-and-fuel';
import Documentation from './documentation/documentation-tab';

import LineTab from './line/line';
import ColorsTab from './colors/colors-tab';

const ContractLineTabs = () => {
  const { tabIndex, setTabIndex } = useContractLine();

  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  const handleOnTabChange = useCallback((index) => {
    setTabIndex(index);
  }, []);

  useEffect(() => {
    queryParams.set('tabIndex', tabIndex);
    history.push(`${location.pathname}?${queryParams.toString()}`);
  }, [tabIndex]);

  return (
    <TabSet
      onSelect={handleOnTabChange}
      tabs={[
        {
          heading: TABS[0].heading,
          content: <LineTab />,
          tabSelectedWhenOpen: tabIndex < 1 || tabIndex > 6,
        },
        {
          heading: TABS[1].heading,
          content: <ContractLineDetail />,
          tabSelectedWhenOpen: tabIndex === 1,
        },
        {
          heading: TABS[2].heading,
          content: <MinimumRequirements />,
          tabSelectedWhenOpen: tabIndex === 2,
        },
        {
          heading: TABS[3].heading,
          content: <OptionalEquipment />,
          tabSelectedWhenOpen: tabIndex === 3,
        },
        {
          heading: TABS[4].heading,
          content: <EngineAndFuel />,
          tabSelectedWhenOpen: tabIndex === 4,
        },
        {
          heading: TABS[5].heading,
          content: <ColorsTab />,
          tabSelectedWhenOpen: tabIndex === 5,
        },
        {
          heading: TABS[6].heading,
          content: <Documentation />,
          tabSelectedWhenOpen: tabIndex === 6,
        },
      ]}
    />
  );
};

export default ContractLineTabs;
