import React from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { Modal, Spinner, Button } from '@gsa/afp-component-library';
import { REMOVE_BID_LINE } from '../../bids/bids.gql';
import { bidsDashboardMessageAtom } from '../../bids/atoms/bids-atoms';
import CanIChangeBidData from '../../bids/components/protect-bid-data-crud';

const RemoveConfirmationModal = ({ data, onClose }) => {
  const [, setToaster] = useRecoilState(bidsDashboardMessageAtom);

  const lineItemNumber = `${data.scheduleLine}`;

  // gql call to remove bidLine (make/model)
  const [removeBidLine, { loading }] = useMutation(REMOVE_BID_LINE, {
    variables: {
      id: parseInt(data.id, 10),
    },
    onCompleted: () => {
      setToaster({
        type: 'success',
        message: (
          <>
            Line Item <b>{lineItemNumber}</b> has been removed from the bid.
          </>
        ),
      });
      onClose(true);
    },
    onError: () => onClose(),
  });

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          id="remove-standard-item-modal"
          variant="medium"
          title={<h3>Remove confirmation</h3>}
          onClose={onClose}
          actions={
            <div>
              <Button
                data-testid="bid-line-removal-modal-cancel-btn"
                variant="unstyled"
                onClick={onClose}
                label="Cancel"
              />
              <CanIChangeBidData>
                <Button
                  variant="secondary"
                  onClick={removeBidLine}
                  label="Yes"
                />
              </CanIChangeBidData>
            </div>
          }
        >
          {loading && (
            <div
              role="none"
              className="afp-modal-overlay"
              data-testid="delete-bid-line-loading-spinner"
            >
              <Spinner
                style={{
                  position: 'absolute',
                  top: '50%',
                  transform: 'translate(0, -50%)',
                }}
              />
            </div>
          )}
          <div className="margin-top-2 margin-bottom-8">
            Are you sure you want to remove the line item{' '}
            <b>{lineItemNumber}</b> from the bid?
          </div>
        </Modal>
      </div>
    </div>
  );
};

RemoveConfirmationModal.propTypes = {
  data: PropTypes.shape(
    PropTypes.objectOf({
      bidId: PropTypes.number.isRequired,
      standardItemId: PropTypes.string.isRequired,
      standardItemNumber: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RemoveConfirmationModal;
