import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Link } from '@gsa/afp-component-library';
import { GET_SIGNED_READ_URL } from './query';
import { useContractLine } from '../provider/contract-line-provider';

const DownloadableDocumentCell = ({ id }) => {
  const { documents, getSignedUrl, setSignedUrl } = useContractLine();
  const doc = useMemo(() => documents.find((p) => p.id === id), [
    documents,
    id,
  ]);
  const docName = doc?.name;
  const scanStatus = doc?.clAttachmentMetadata?.scanStatus;
  const signedUrl = useMemo(() => getSignedUrl(doc?.documentMetadataId), [
    doc?.documentMetadataId,
    getSignedUrl,
  ]);

  const onSignedUrlCompleted = useCallback(
    (data) => {
      setSignedUrl(doc?.documentMetadataId, data?.generateReadSignedURL);
    },
    [doc?.documentMetadataId, setSignedUrl],
  );

  const [loadSignedUrl] = useLazyQuery(GET_SIGNED_READ_URL, {
    fetchPolicy: 'cache-and-network',
    onCompleted: onSignedUrlCompleted,
    onError: () => {},
  });

  useEffect(() => {
    if (!signedUrl) {
      loadSignedUrl({
        variables: {
          fileKey: doc?.clAttachmentMetadata?.fileLocation,
          fileType: doc?.clAttachmentMetadata?.fileMimeType,
        },
      });
    }
  }, [signedUrl, doc, loadSignedUrl]);

  let content = null;
  if (scanStatus === 'New') {
    content = (
      <div title="Scanning file for viruses">
        <div>{docName}</div>
        <div className="body-small-tight">Virus scanning...</div>
      </div>
    );
  } else if (scanStatus === 'Quarantined') {
    content = (
      <div>
        <div className="body-small-tight">Quarantined</div>
      </div>
    );
  } else if (scanStatus === 'Accepted' && signedUrl) {
    content = (
      <Link
        href={signedUrl}
        target="__blank"
        alt="Contract line attachment download"
      >
        {docName}
      </Link>
    );
  }

  return content;
};

DownloadableDocumentCell.propTypes = {
  documentMetadataId: PropTypes.string.isRequired,
  docName: PropTypes.string.isRequired,
};

export default DownloadableDocumentCell;
