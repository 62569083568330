import React, { useEffect } from 'react';
import { Alert, Spinner } from '@gsa/afp-component-library';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import PreBidSiSelections from './components/pre-bid-si-selections';
import { useFederalStandards } from '../fvs-provider';
import './styles/prebid-selection.scss';

const PreBidSelectionsPage = () => {
  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs?.home}/home`,
      label: 'Home',
    },
    {
      location: `${window.AFP_CONFIG.appURLs?.catalog}/catalog/vehicle-standards`,
      label: 'Federal Vehicle Standards',
    },
  ];

  const params = useParams();
  const { year } = params;

  const {
    setFvsContextData,
    commentingPeriodsData,
    isCommentingPeriodDataLoading,
  } = useFederalStandards() || {};

  useEffect(() => {
    setFvsContextData?.('SET_SELECTED_YEAR', Number.parseInt(year, 10));
  }, []);

  // check if there is at least one commenting period open for the selected year for a vehicle supplier
  const isCommentingOpenForVehSupplier = commentingPeriodsData?.psoCommentPeriodCounts?.some(
    (cp) => cp.year === Number.parseInt(year, 10) && cp?.openCount > 0,
  );

  return (
    <div
      className="grid-container margin-bottom-7"
      data-testid="pre-bid-selection-grid"
    >
      <Breadcrumbs current="My Pre-Bid Selection" path={breadcrumbsPath} />
      {/* eslint-disable-next-line no-nested-ternary */}
      {isCommentingPeriodDataLoading ? (
        <Spinner />
      ) : !isCommentingOpenForVehSupplier ? (
        <Alert
          type="info"
          focused
          className="margin-top-2"
          title={`There is no active commenting period for the selected year: ${year}`}
        >
          There is no active commenting period for the selected year: {year}
        </Alert>
      ) : (
        <PreBidSiSelections />
      )}
    </div>
  );
};

export default PreBidSelectionsPage;
