import PropTypes from 'prop-types';
import React from 'react';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import Attachment from '../../../components/Attachment';
import AttachmentProvider, {
  useAttachments,
} from '../../../components/Attachment/AttachmentProvider';
import { useTaskDetail } from '../task-detail-provider';
import {
  DELETE_ATTACHMENT,
  GET_ATTACHMENTS,
} from '../../../components/Attachment/helpers';
import { useFvsSupportingDocs } from '../../fvs/fvs-supporting-docs-provider';

const AttachmentWrapper = ({ uploadPath, allowChanges }) => {
  const {
    fetching,
    rows,
    count,
    hasMore,
    loadMore,
    saving,
    saved,
    updating,
    updated,
    deleting,
    currentFetchVariables: { limit },
    downloadAttachment,
    deleteAttachment,
    sortAttachment,
    saveAttachment,
    updateDescription,
    attachmentErrors,
    setAttachmentErrors,
    currentUser,
    linkedEntities,
    uploadButtonLabel,
  } = useAttachments();

  const { task } = useTaskDetail();
  const { uploadFvsDocument } = useFvsSupportingDocs();

  const handleDocUpload = async (formData) => {
    const docMetadata = await uploadFvsDocument({
      variables: {
        input: {
          fvsId: task?.linkedEntities?.fvsId,
          fvsCode: task?.fedStandardDoc,
          name: formData.get('attachment').name,
          description: formData.get('description'),
          size: formData.get('attachment').size,
          fileMimeType: formData.get('attachment').type,
          status: 'Final',
        },
      },
    });

    if (docMetadata?.data?.uploadSupportingDoc) {
      await saveAttachment(formData, docMetadata.data.uploadSupportingDoc);
    }
  };

  return (
    <div className="attachment" data-testid="attachment-container">
      <Attachment
        title={<h2>Supporting Documents</h2>}
        loading={fetching}
        data={rows}
        count={count}
        hasMore={hasMore}
        loadMoreLimit={5}
        paginationLimit={limit}
        uploadPath={uploadPath}
        onLoadMore={loadMore}
        adding={saving}
        added={saved}
        updating={updating}
        updated={updated}
        deleting={deleting}
        onDownload={downloadAttachment}
        onSort={sortAttachment}
        onDelete={deleteAttachment}
        onUpdateDescription={updateDescription}
        onAdd={handleDocUpload}
        currentUser={currentUser}
        errors={attachmentErrors}
        setErrors={setAttachmentErrors}
        linkedEntities={linkedEntities}
        allowChanges={allowChanges}
        uploadButtonLabel={uploadButtonLabel}
        isFromTaskDetail
        fileTypes={['pdf', 'jpeg', 'jpg', 'docx']}
        maxFileSize={10 * 1024 * 1024} // 10MB
      />
    </div>
  );
};

AttachmentWrapper.defaultProps = {
  uploadPath: '',
  allowChanges: true,
};
AttachmentWrapper.propTypes = {
  uploadPath: PropTypes.string,
  allowChanges: PropTypes.bool,
};

const Documents = () => {
  const { task, setTaskAttachments } = useTaskDetail();
  const allowChanges = task.status !== 'Closed';
  const { currentUser } = useCurrentUser();
  const linkedEntities = {
    ...task?.linkedEntities,
    tasks: [{ taskId: task.id }],
  };

  return (
    <AttachmentProvider
      fetchVariables={{
        filter: { tasks: [{ taskId: task.id }] },
        limit: 5,
        offset: 0,
        order: 'updatedAt DESC',
      }}
      setParentContext={setTaskAttachments}
      linkedEntities={linkedEntities}
      currentUser={currentUser}
      uploadButtonLabel="Add supporting document"
      fetchGql={GET_ATTACHMENTS}
      deleteGql={DELETE_ATTACHMENT}
    >
      <AttachmentWrapper allowChanges={allowChanges} />
    </AttachmentProvider>
  );
};

export default Documents;
