import React from 'react';
import PropTypes from 'prop-types';
import { SelectDropdown } from '@gsa/afp-component-library';

const StandardItemSelect = ({
  options,
  selectedOption,
  onChange,
  onBlur,
  errorMessage = undefined,
}) => {
  return (
    <div className="grid-container">
      <div className="grid-row">
        <SelectDropdown
          data-testid="select-standard-item-dropdown"
          aria-label="Select a Standard Item"
          aria-required="true"
          containerClassName="expand-full"
          label="Select a Standard Item"
          required
          onChange={onChange}
          onBlur={onBlur}
          options={[
            { value: '', label: 'Select an available Standard Item' },
            ...options,
          ]}
          errorMessage={errorMessage}
          defaultValue={selectedOption?.value}
        />
      </div>
    </div>
  );
};

StandardItemSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  selectedOption: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
  onBlur: PropTypes.func,
  errorMessage: PropTypes.string,
};

StandardItemSelect.defaultProps = {
  options: [],
  onChange: () => {},
  selectedOption: undefined,
  onBlur: () => {},
  errorMessage: undefined,
};

export default StandardItemSelect;
