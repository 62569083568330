/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { PageTitle } from '@gsa/afp-component-library';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import CommentsAdminProvider from '../comments-admin-provider';
import ViewCommentsTable from './view-comments-table';

const Comments = () => {
  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs.home}/home`,
      label: 'Home',
    },
    {
      label: 'Federal Vehicle Standards',
      location: `${window.AFP_CONFIG.appURLs.catalog}/catalog/vehicle-standards`,
    },
  ];

  return (
    <CommentsAdminProvider>
      <div className="grid-col">
        <Breadcrumbs current="Comments" path={breadcrumbsPath} />
        <PageTitle aria-label="Comments" tabIndex="0" title="Comments" />
        <ViewCommentsTable />
      </div>
    </CommentsAdminProvider>
  );
};

export default Comments;
