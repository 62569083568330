import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Spinner } from '@gsa/afp-component-library';
import TaskDetailProvider from './task-detail-provider';
import FvsSupportingDocsProvider from '../fvs/fvs-supporting-docs-provider';
import { TASK_DETAIL, SPR_TASK_DETAIL } from '../../services/fragments/tasks';
import StandardsPeerReview from './taskTypes/StandardsPeerReview';
import './taskDetails.scss';

const TaskDetail = () => {
  const { id } = useParams();
  const [task, setTask] = useState(null);

  const { data: sprData, refetch: refetchSPR } = useQuery(SPR_TASK_DETAIL, {
    variables: { taskId: id },
  });

  const { data, refetch } = useQuery(TASK_DETAIL, {
    variables: { id: parseFloat(id) },
  });

  useEffect(() => {
    if (data && data.getTaskById) {
      setTask({
        comments: task && task.comments, // TODO: gql nested query for comments
        ...(sprData && sprData.getStandardsPeerReviewTask
          ? sprData.getStandardsPeerReviewTask
          : null),
        sprId: sprData?.getStandardsPeerReviewTask?.id,
        ...data.getTaskById,
      });
    } else {
      setTask(null);
    }
  }, [data, sprData]);

  return task ? (
    <TaskDetailProvider
      task={task}
      setTask={setTask}
      refetchTask={refetch}
      refetchSPR={refetchSPR}
    >
      <FvsSupportingDocsProvider>
        {task.taskType.taskType === 'SPR' && (
          <StandardsPeerReview task={task} />
        )}
      </FvsSupportingDocsProvider>
    </TaskDetailProvider>
  ) : (
    <Spinner className="margin-y-9" />
  );
};

export default TaskDetail;
