import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  SelectDropdown,
  MultiSelectDropdown,
} from '@gsa/afp-component-library';
import {
  getReportTypes,
  REPORTS_ALL,
  REPORTS_VS,
  REPORT_CONFIG,
} from './contract-report-types';
import { DEFAULT_OPTION } from './contract-report-helper';

const ReportSelectionForm = ({
  canViewAll,
  formValues,
  setFormValues,
  onNext,
}) => {
  const reportGroups = canViewAll ? REPORTS_ALL : REPORTS_VS;
  const reportTypeOptions = getReportTypes(reportGroups).map((type) => ({
    value: type,
    label: type,
  }));
  const getReportOptions = () => {
    const { value } = formValues.reportType;
    if (value)
      return [
        DEFAULT_OPTION,
        ...reportGroups[value].map((rpt) => ({
          value: rpt,
          label: REPORT_CONFIG[rpt].label,
        })),
      ];
    return [DEFAULT_OPTION];
  };

  const getContractStatusOptions = () => {
    return [
      { value: 'active', label: 'Active' },
      { value: 'expired', label: 'Expired' },
    ];
  };

  const onReportTypeChange = ({ target: { value } }) => {
    setFormValues({
      ...formValues,
      reportType: {
        value,
        errorMessage: value ? '' : 'Report type is required',
      },
      report: { value: '', errorMessage: '' },
      contractStatus: { value: [], errorMessage: '' },
    });
  };

  const onReportChange = ({ target: { value } }) => {
    setFormValues({
      ...formValues,
      report: { value, errorMessage: value ? '' : 'Report is required' },
    });
  };

  const onContractStatusChange = (value) => {
    setFormValues({
      ...formValues,
      contractStatus: {
        value,
        errorMessage: '',
      },
    });
  };

  const onClickNext = () => {
    const { reportType, report } = formValues;
    if (reportType.value && report.value) onNext();
    else
      setFormValues({
        ...formValues,
        ...(reportType.value
          ? {}
          : {
              reportType: {
                value: '',
                errorMessage: 'Report type is required',
              },
            }),
        ...(report.value
          ? {}
          : { report: { value: '', errorMessage: 'Report is required' } }),
      });
  };

  return (
    <>
      {canViewAll && (
        <div className="grid-row margin-top-4">
          <div className="grid-col-6">
            <SelectDropdown
              autoFocus
              label={<strong>Select a contract type</strong>}
              name="select report type"
              className="usa-select"
              data-testid="report-type-select"
              onChange={onReportTypeChange}
              value={formValues.reportType.value}
              errorMessage={formValues.reportType.errorMessage}
              options={reportTypeOptions}
              required
            />
          </div>
        </div>
      )}

      <div className="grid-row">
        <div className="grid-col-6">
          <SelectDropdown
            label={<strong>Select a report</strong>}
            name="select a report"
            className="usa-select"
            data-testid="report-select"
            value={formValues.report.value}
            errorMessage={formValues.report.errorMessage}
            options={getReportOptions()}
            onChange={onReportChange}
            required
          />
        </div>
      </div>

      <div className="grid-row">
        <div className="grid-col-6">
          <div
            data-testid="contract-status-multi-select"
            className={
              formValues.contractStatus.errorMessage
                ? 'grid-col-9 border-left-05 border-secondary-dark padding-left-2 margin-left-neg-2'
                : 'grid-col-9 '
            }
          >
            <label
              className="usa-label margin-bottom-1"
              htmlFor="select-standard-items"
            >
              <strong>Select the contract status</strong>{' '}
            </label>
            {formValues.contractStatus.errorMessage && (
              <div className="text-bold text-secondary-dark margin-bottom-1">
                {formValues.contractStatus.errorMessage}
              </div>
            )}
            <div
              className={
                formValues.contractStatus.errorMessage
                  ? 'border-05 border-secondary-dark'
                  : ''
              }
            >
              <MultiSelectDropdown
                id="contract-status-multi-select"
                name="contract-status-multi-select"
                selectedValues={formValues.contractStatus.value}
                options={getContractStatusOptions()}
                onChange={onContractStatusChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="grid-row margin-top-8">
        <Button
          variant="primary"
          label="Next"
          data-testid="next-button"
          rightIcon={{ name: 'arrow_forward' }}
          onClick={onClickNext}
        />
      </div>
    </>
  );
};

ReportSelectionForm.defaultProps = {};
ReportSelectionForm.propTypes = {
  canViewAll: PropTypes.bool.isRequired,
  formValues: PropTypes.shape(Object).isRequired,
  setFormValues: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default ReportSelectionForm;
