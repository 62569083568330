import React from 'react';
import { Icon } from '@gsa/afp-component-library';
import {
  Title,
  Description,
  TaskDetails,
  Documents,
  Comments,
} from '../widgets';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import TaskMessages from '../task-messages';

const links = [
  {
    location: `${window.AFP_CONFIG?.appURLs?.home}/home`,
    label: 'Home',
  },
  {
    location: `${window.AFP_CONFIG?.appURLs?.home}/tasks`,
    label: 'Task Manager',
  },
];

const StandardsPeerReview = () => {
  return (
    <div className="grid-container">
      <Breadcrumbs path={links} current="Task Details" />
      <TaskMessages />
      <Title />
      <div className="grid-row grid-gap flex-justify">
        <Description />
        <div className="display-flex desktop:flex-justify-end margin-right-1">
          <a href="/catalog/vehicle-standards">
            <Icon iconName="arrow_back" className="margin-right-1" />
            FVS landing page
          </a>
        </div>
      </div>
      <div style={{ maxWidth: '45rem' }}>
        <TaskDetails />
        <Documents />
        <Comments />
      </div>
    </div>
  );
};

export default StandardsPeerReview;
