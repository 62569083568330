import { enDashUnicode } from '../../../../utilities/constants';

// tab key for copy tab - must follow tabIndex order
export const TAB_KEY = {
  allTabs: 'All',
  bidDetail: 'Detail',
  minimumRequirements: 'MinReq',
  optionalEquipment: 'OptEq',
  engineFuel: 'Engine',
  colors: 'Color',
  documents: 'Documents',
  photos: 'Photos',
};
export const ALL_TABS = Object.values(TAB_KEY).slice(1);

export const COPY_MODAL_TITLE = {
  [TAB_KEY.bidDetail]: 'bid detail',
  [TAB_KEY.minimumRequirements]: 'minimum requirements',
  [TAB_KEY.optionalEquipment]: 'optional equipment',
  [TAB_KEY.engineFuel]: 'engine and fuel',
  [TAB_KEY.colors]: 'colors',
  [TAB_KEY.documents]: 'documents',
  [TAB_KEY.photos]: 'photos',
  [TAB_KEY.allTabs]: 'line items',
};
export const COPY_MODAL_TEXT = {
  [TAB_KEY.bidDetail]: 'Only clarifications will be copied from bid detail.',
};

export const COPY_TYPE = {
  append: 'A',
  overwrite: 'O',
};

export const getScheduleLine = (bidLineId, bidLines) => {
  const bidLine = bidLines.find((line) => +line.id === bidLineId);
  return bidLine?.scheduleLine || enDashUnicode;
};

export const sentenceCase = (str) =>
  `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

const getReduceFn = (tabRes) => (sum, key) => sum + (tabRes[key]?.length || 0);
export const countCopyItems = (tabRes) =>
  [
    'copied',
    'partialCopied',
    'notCopied',
    'deleted',
    'srcOnly',
    'destOnly',
  ].reduce(getReduceFn(tabRes), 0);
const countCopySuccessItems = (tabRes) =>
  ['copied', 'partialCopied', 'deleted'].reduce(getReduceFn(tabRes), 0);
const countCopyFailedItems = (tabRes) =>
  tabRes.error
    ? 1
    : ['notCopied', 'partialCopied', 'srcOnly', 'destOnly'].reduce(
        getReduceFn(tabRes),
        0,
      );
export const countAllCopySuccessItems = (res) =>
  ALL_TABS.reduce((sum, tabKey) => {
    return sum + countCopySuccessItems(res[tabKey]);
  }, 0);
export const countAllCopyFailedItems = (res) =>
  ALL_TABS.reduce((sum, tabKey) => {
    return sum + countCopyFailedItems(res[tabKey]);
  }, 0);
