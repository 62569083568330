import * as yup from 'yup';

// afp-home-app/src/utilities/RegexUtils.js
const phoneNumberNumeric = /^\d*$/;
const phoneNumberUS = /^\+1[ ]?(?:\(\d{3}\)|\d{3})[-. ]?\d{3}[-. ]?\d{4}$/;

const contractHeaderSchema = yup.object().shape({
  pocName: yup.string().nullable(),
  pocEmail: yup.string().nullable().email('Point of Contact Email is invalid'),
  pocPhone: yup
    .string()
    .nullable()
    .test('is-valid-phone', 'Point of Contact Phone is not valid', (value) => {
      if (value.includes('+1')) {
        return phoneNumberUS.test(value);
      }
      return phoneNumberNumeric.test(value);
    }),
  dollarValueMaximumOrder: yup
    .number('Dollar Value Maximum Order is not valid')
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value ?? null;
    }),
});

export default contractHeaderSchema;
