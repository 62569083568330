import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Icon } from '@gsa/afp-component-library';
import { Can } from '@gsa/afp-shared-ui-utils';
import DetailTable from '../../../components/DetailTable';
import {
  CATALOG_CODES_MODAL_MODES,
  useCatalogCodes,
} from '../catalog-codes-provider';
import { OPERATIONS, SUBJECTS } from '../../../utilities/constants';
import CatalogCodeDetailActions from './catalog-code-detail-actions';

const CatalogCodeInfo = () => {
  const {
    selectedCatalogCode,
    setCatalogCodesData,
    catalogCodeShowModal,
  } = useCatalogCodes();

  const [catalogCodeItemData, setCatalogCodeItemData] = useState([]);
  const history = useHistory();

  const handleData = (cc) => {
    const tableData = [
      {
        header: 'Code',
        value: cc?.code,
        type: 'text',
      },
      {
        header: 'Title',
        value: cc?.title,
        type: 'text',
      },
      {
        header: 'Category',
        value: cc?.metadata?.category,
        type: 'text',
      },
    ];

    if (cc?.additionalProps) {
      Object.keys(cc?.additionalProps).forEach((k) => {
        let header;
        let value = cc?.additionalProps[k];
        switch (k) {
          case 'sequence':
            header = 'Sequence';
            break;
          case 'selectionType':
            header = 'Selection type';
            break;
          case 'shortHand':
            header = 'Shorthand';
            break;
          case 'fastCode':
            header = 'FAST Code';
            break;
          case 'allowNewBids':
            header = 'New Bids';
            value = cc?.additionalProps?.allowNewBids ? 'Yes' : 'No';
            break;
          default:
            break;
        }
        if (header) {
          tableData.push({
            header,
            value,
            type: 'text',
          });
        }
      });
    }
    if (cc?.metadata?.parentCategory) {
      tableData.push({
        header: cc.metadata.parentCategory,
        value: cc?.parentCode,
        type: 'text',
      });
    }
    if (cc?.tags) {
      tableData.push({
        header: 'Tags',
        value: cc?.tags?.value,
        type: 'tags',
      });
    }
    tableData.push({
      header: 'Description',
      value: { content: cc?.description },
      type: 'truncate',
    });
    const pocs = selectedCatalogCode?.standardsVehicleGroupPocs || [];
    pocs.forEach((poc) => {
      let title = '';
      switch (poc.pocType) {
        case 'PocSeniorEngineer':
          title = 'Senior Engineer';
          break;
        case 'PocPrimaryEngineer':
          title = 'Primary Engineer';
          break;
        case 'PocSecondaryEngineer':
          title = 'Secondary Engineer';
          break;
        case 'PocSeniorCO':
          title = 'Senior Contracting Officer';
          break;
        case 'PocPrimaryCO':
          title = 'Primary Contracting Officer';
          break;
        case 'PocSecondaryCO':
          title = 'Secondary Contracting Officer';
          break;
        default:
          break;
      }
      tableData.push({
        header: title,
        value: {
          content: poc.name,
        },
        type: 'truncate',
      });
    });

    if (cc?.status) {
      const mapStatusToBadgeColor = (status) => {
        const itemStatus = status?.toLowerCase();
        if (!itemStatus) return '';
        if (itemStatus === 'draft') return 'Warning-Gray';
        if (itemStatus === 'active') return 'Ready-Gray';
        return 'Inactive-Gray';
      };
      tableData.push({
        header: 'Code Status',
        value: cc?.status,
        variant: mapStatusToBadgeColor(cc?.status),
        type: 'status',
      });
    }

    if (cc?.requestStatus) {
      const mapRequestStatusToBadgeColor = (requestStatus) => {
        const status = requestStatus?.toLowerCase();

        const isPendingStatusType = () => {
          if (
            status === 'pending-new' ||
            status === 'pending-edit' ||
            status === 'pending-delete'
          )
            return true;
          return false;
        };

        if (!status) return '';
        if (isPendingStatusType()) return 'Warning-Gray';
        if (status === 'approved') return 'Ready-Gray';
        return 'Inactive-Gray';
      };

      tableData.push({
        header: 'Request Status',
        value: `${selectedCatalogCode?.requestStatus}`,
        variant: mapRequestStatusToBadgeColor(
          selectedCatalogCode?.requestStatus,
        ),
        type: 'status',
      });
    }
    setCatalogCodeItemData(tableData);
  };

  useEffect(() => {
    if (selectedCatalogCode) {
      handleData(selectedCatalogCode);
    }
  }, [selectedCatalogCode]);

  return (
    <>
      <CatalogCodeDetailActions />
      <div className="grid-row">
        <div className="desktop:grid-col-7 ">
          <div className="detail-table-links">
            <div>
              <button
                type="button"
                className="usa-button usa-button--unstyled"
                onClick={() => {
                  history.push('/catalog/codes');
                }}
              >
                <span className="dropdown-content-icon">
                  <Icon iconName="arrow_back" className="usa-icon--size-2" />
                </span>
                Back to Catalog Codes
              </button>
            </div>
            <Can I={OPERATIONS.Update} a={SUBJECTS.CatalogCodes}>
              <div>
                {selectedCatalogCode &&
                  selectedCatalogCode.status !== 'Inactive' && (
                    <button
                      type="button"
                      className="usa-button usa-button--unstyled"
                      onClick={() => {
                        setCatalogCodesData(
                          'SET_MODAL_MODE',
                          CATALOG_CODES_MODAL_MODES.EDIT,
                        );
                      }}
                    >
                      <span className="dropdown-content-icon">
                        <Icon iconName="edit" className="usa-icon--size-2" />
                      </span>
                      Edit
                    </button>
                  )}
              </div>
            </Can>
          </div>
          <div className="bg-base-lightest padding-205 margin-top-105">
            <DetailTable
              data={catalogCodeItemData}
              showFormModal={catalogCodeShowModal}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CatalogCodeInfo;
