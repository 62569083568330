export const getHandelToggle = (setItems) => (index) => {
  setItems((prevItems) =>
    prevItems.map((item, i) => {
      if (i !== index) return item;
      return {
        ...item,
        expanded: !item.expanded,
      };
    }),
  );
};

export const getToggleAccordion = (setItems) => () => {
  setItems((prevItems) => {
    const allExpanded = !prevItems.some((item) => !item.expanded);
    return prevItems.map((item) => ({
      ...item,
      expanded: !allExpanded,
    }));
  });
};
