import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { ErrorMessage, Typeahead } from '@gsa/afp-component-library';
import { Controller } from 'react-hook-form';
import { GET_OPTIONS } from '../../services/data-store';

let userTyped = false;
let yearFilter = '';

export const EquipmentCodeTypeAhead = ({ onChange, value, year }) => {
  const [option, setOption] = useState({ values: [], raw: [] });
  const [reset, setReset] = useState(false);
  const [userInput, setUserInput] = useState('');
  let error = '';
  yearFilter = year;

  // Executes when the Year filter changes.
  useEffect(() => {
    setReset(true);
    onChange({ selectedData: { id: '0' } });
    setOption({ values: [], raw: [] });
  }, [yearFilter]);

  // Executes when the text changes with in the TypeAhead.
  useEffect(() => {
    if (userInput?.length <= 1 && value > 0) {
      setUserInput('');
      error = '';
      onChange({ selectedData: { id: '0' } });
    }
  }, [userInput]);

  // Determines the error message based on the error code.
  const optionvalue = option?.values[0];
  if (optionvalue === 'Error: 404') {
    error = 'Equipment Code was not found for the selected Contract year.';
  } else if (optionvalue === 'Error: emptyYear') {
    error = 'Please select a Contract year';
  } else {
    error = '';
  }

  // Get the options to select when the user enters text in the type ahead.
  const [getOptions] = useLazyQuery(GET_OPTIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let result = { values: [], raw: [] };
      if (data?.getOptions) {
        result = {
          values: data.getOptions.map((v) => v.value),
          raw: data.getOptions,
        };
      }

      // Determine what needs to be sent as a response to display in the Error Message for the TypeAhead.
      setOption(() => {
        if (result.values.length > 0) {
          return result;
        }
        if (userInput?.length === 0) {
          return {
            values: ['Error: emptyKeyword'],
            raw: ['Error: emptyKeyword'],
          };
        }
        if (year === '') {
          return { values: ['Error: emptyYear'], raw: ['Error: emptyYear'] };
        }
        return { values: ['Error: 404'], raw: ['Error: 404'] };
      });
    },
    onError: () => {
      //  TODO - intentionally blank now
    },
  });

  EquipmentCodeTypeAhead.defaultProps = {
    value: '',
    year: '',
  };
  EquipmentCodeTypeAhead.propTypes = {
    value: PropTypes.string,
    year: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  return (
    <div className="margin-top-1">
      <Typeahead
        id="copyFromEquipmentCodeId"
        role="textbox"
        name="from associated equipment code"
        filterValue=""
        typeaheadValues={option?.values}
        onFilterKeyDown={(e) => {
          // Executes when the user types text in the type ahead.
          userTyped = true;
          setReset(false);
          setUserInput(e.target.value);
        }}
        onOptionEnter={(selected) => {
          // Executes the following when the user selects a option from the returned options.
          const selectedData = option?.raw.find((o) => o.label === selected);
          onChange({ selected, selectedData });
        }}
        fetchTypeaheadValues={(_, search) => {
          // Executes when text changes in the type ahead and fetches the values.
          const keyword = search.trim();
          if (userTyped) {
            getOptions({
              variables: {
                model: 'EquipmentCode',
                label: 'code',
                value: 'code',
                limit: 10,
                filter: {
                  operator: 'AND',
                  value: [
                    {
                      operator: 'EQ',
                      key: 'year',
                      value: yearFilter,
                    },
                    {
                      operator: 'LIKE',
                      key: 'code',
                      value: keyword,
                    },
                  ],
                },
              },
            });
            userTyped = false;
          }
        }}
        debounceDelay={250}
        inputCharNum={0}
        errorMessage={error}
        forceUpdate={reset ? { value: '' } : null}
        onClear={(selected) => {
          onChange({ selected, undefined });
        }}
      />
    </div>
  );
};
const EquipmentCode = ({
  isNew,
  errors,
  control,
  setValue,
  year,
  nameValue,
  watch,
  clearErrors,
}) => {
  return (
    <div className="grid-row padding-bottom-2">
      <div className="grid-col-10">
        {isNew && (
          <>
            <h2 className="font-sans-xs">Associated Equipment Code</h2>
            <EquipmentCodeTypeAhead
              value={watch('copyFromEquipmentCodeId')}
              year={year}
              onChange={({ selectedData }) => {
                setValue(nameValue, parseInt(selectedData?.id, 10));
                clearErrors('copyFromEquipmentCodeId');
              }}
            />
            {errors?.[nameValue]?.message && (
              <ErrorMessage>{errors?.[nameValue]?.message}</ErrorMessage>
            )}
            <Controller control={control} name={nameValue} defaultValue="" />
            <Controller
              control={control}
              name="copyFromEquipmentCodeId"
              defaultValue=""
            />
          </>
        )}
      </div>
    </div>
  );
};

EquipmentCode.defaultProps = {
  isNew: false,
  year: '',
};
EquipmentCode.propTypes = {
  isNew: PropTypes.bool,
  errors: PropTypes.shape(Object).isRequired,
  control: PropTypes.shape(Object).isRequired,
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  year: PropTypes.number,
  nameValue: PropTypes.string.isRequired,
  clearErrors: PropTypes.func.isRequired,
};
export default EquipmentCode;
