import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import { emDashUnicode } from '../../../utilities/constants';

const BidChangeNotificationTableSubRow = ({ row }) => {
  const capitalizeFirstChar = (str) => {
    return str && str.charAt(0).toUpperCase() + str.slice(1);
  };

  const DataComparisonTable = ({ oldData, newData }) => {
    return (
      <>
        <div className="grid-row border-bottom padding-bottom-1">
          <div className="grid-col flex-1 text-bold">Attribute</div>
          <div className="grid-col flex-1 text-bold">Previous Value</div>
          <div className="grid-col flex-1 text-bold">New Value</div>
        </div>

        {newData ? (
          Object.keys(newData).map((attribute) => (
            <div className="grid-row padding-top-1">
              <div className="grid-col flex-1">
                {capitalizeFirstChar((attribute && attribute) || '')}
              </div>
              <div
                className={`grid-col flex-1${
                  oldData && oldData[attribute] !== newData[attribute]
                    ? ' bg-yellow'
                    : ''
                }`}
              >
                {ReactHtmlParser(oldData && oldData[attribute])}
              </div>
              <div
                className={`grid-col flex-1${
                  oldData && oldData[attribute] !== newData[attribute]
                    ? ' bg-yellow'
                    : ''
                }`}
              >
                {ReactHtmlParser(newData[attribute])}
              </div>
            </div>
          ))
        ) : (
          <div className="grid-row padding-top-1">
            <div className="grid-col flex-1">{emDashUnicode}</div>
            <div className="grid-col flex-1">{emDashUnicode}</div>
            <div className="grid-col flex-1">{emDashUnicode}</div>
          </div>
        )}
      </>
    );
  };
  DataComparisonTable.propTypes = {
    oldData: PropTypes.objectOf(PropTypes.any).isRequired,
    newData: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  return (
    <>
      <DataComparisonTable
        oldData={row.original?.oldValue}
        newData={row.original?.newValue}
      />
    </>
  );
};

BidChangeNotificationTableSubRow.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

export default BidChangeNotificationTableSubRow;
