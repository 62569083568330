import React from 'react';
import PropTypes from 'prop-types';
import { useModal, Button } from '@gsa/afp-component-library';
import ImportResultsModal from './import-results-modal';

const ImportResultsMessage = ({ imported, failed, standardItem }) => {
  const { isOpen, openModal, closeModal } = useModal();

  const siLabel = (
    <span className="text-bold">
      {standardItem.standardItemNumber} - {standardItem.title}
    </span>
  );
  const iCount = imported.length;
  const fCount = failed.length;
  const failedLink = (
    <span>
      <Button
        variant="unstyled"
        label={
          <span>
            <b>{fCount}</b> Line Items could not be imported
          </span>
        }
        onClick={openModal}
      />
      {isOpen && <ImportResultsModal failed={failed} onClose={closeModal} />}
    </span>
  );

  if (!fCount)
    return (
      <div>
        <b>{iCount}</b> Line Items imported to {siLabel}
      </div>
    );

  if (!iCount)
    return (
      <div>
        {failedLink} to {siLabel}
      </div>
    );

  return (
    <div>
      <b>{iCount}</b> Line Items imported to {siLabel}, and {failedLink}
    </div>
  );
};

ImportResultsMessage.propTypes = {
  imported: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  failed: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  standardItem: PropTypes.shape({
    standardItemNumber: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default ImportResultsMessage;
