/*  eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, TextInput } from '@gsa/afp-component-library';

import { CHASSIS_TAG_REGEX } from '../../../../bid-dashboard/constants';
import { useContractLine } from '../../provider/contract-line-provider';
import SIMakeModelPanel from '../../../contract-line/make-model/si-make-model-panel';
import { useSIMakeModelProvider } from '../../../contract-line/make-model/si-make-model-panel-provider';

const SelectMakeModelModal = ({
  data,
  onClose,
  onSelect,
  showManufacturerNumber,
}) => {
  const {
    bodyMake,
    bodyModel,
    chassisMake,
    chassisModel,
    modelYear,
    setDirtyFields,
  } = useSIMakeModelProvider();
  const makeCode = bodyMake?.value;
  const makeName = bodyMake?.label;
  const modelCode = bodyModel?.value;
  const modelName = bodyModel?.label;
  const chassisMakeCode = chassisMake?.value;
  const chassisMakeName = chassisMake?.label;
  const chassisModelCode = chassisModel?.value;
  const chassisModelName = chassisModel?.label;
  const { contractLine } = useContractLine();
  const [manufacturerModelNumber, setManufacturerModelNumber] = useState(
    contractLine?.manufacturerModelNumber,
  );
  const [mMNIsDirty, setMMNIsDirty] = useState(false);
  const modalSubtitle = `${contractLine?.standardItem?.standardItemNumber} ${contractLine?.standardItem?.title}`;
  const hasChassis = contractLine?.standardItem?.tags?.value.some((tag) =>
    tag.match(CHASSIS_TAG_REGEX),
  );

  const onUpdateMakeModel = () => {
    if (showManufacturerNumber && !manufacturerModelNumber) {
      setMMNIsDirty(true);
    } else {
      setDirtyFields([
        'bodyMake',
        'bodyModel',
        'chassisMake',
        'chassisModel',
        'modelYear',
      ]);
    }

    if (showManufacturerNumber) {
      if (manufacturerModelNumber) {
        onSelect({
          manufacturerModelNumber,
        });
      }
      return;
    }

    const fields = [
      { missing: !makeCode, field: 'Body Make' },
      { missing: !modelCode, field: 'Body Model' },
      {
        missing: hasChassis && !chassisMakeCode,
        field: 'Chassis Make',
      },
      {
        missing: hasChassis && !chassisModelCode,
        field: 'Chassis Model',
      },
      { missing: !modelYear, field: 'Model year' },
    ];
    const missingField = fields.find((f) => f.missing);
    if (!missingField) {
      onSelect({
        makeCode,
        modelCode,
        chassisMakeCode,
        chassisModelCode,
        make: {
          makeCode,
          makeName,
          fleetStatus: bodyMake?.fleetApprovalStatus,
        },
        model: {
          modelCode,
          modelName,
          fleetStatus: bodyModel?.fleetApprovalStatus,
        },
        chassisMake: {
          makeCode: chassisMakeCode,
          makeName: chassisMakeName,
          fleetStatus: chassisMake?.fleetApprovalStatus,
        },
        chassisModel: {
          modelCode: chassisModelCode,
          modelName: chassisModelName,
          fleetStatus: chassisModel?.fleetApprovalStatus,
        },
        modelYear,
      });
      onClose(true);
    }
  };

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          id="remove-standard-item-modal"
          variant="extra-large"
          title={<h2>{`Edit line item  ${data?.scheduleLine || ''}`}</h2>}
          onClose={onClose}
          actions={
            <div>
              <Button
                data-testid="bid-line-removal-modal-cancel-btn"
                variant="unstyled"
                onClick={onClose}
                label="Cancel"
              />
              <Button
                variant="primary"
                onClick={onUpdateMakeModel}
                label={
                  showManufacturerNumber
                    ? 'Select manufacturer model number'
                    : 'Select make and model and close'
                }
              />
            </div>
          }
        >
          <div
            className="text-body text-base-dark"
            aria-label={modalSubtitle}
            tabIndex="0"
          >
            <h4>{modalSubtitle}</h4>
          </div>
          {showManufacturerNumber ? (
            <div className="grid-container">
              <TextInput
                reqired
                label={
                  <span className="text-bold">Manufacturer model number</span>
                }
                value={manufacturerModelNumber}
                onChange={(evt) => {
                  setManufacturerModelNumber(evt.target.value);
                }}
                onBlur={() => {
                  setMMNIsDirty(true);
                }}
                errorMessage={
                  mMNIsDirty && !manufacturerModelNumber
                    ? 'This is a required field'
                    : undefined
                }
              />
            </div>
          ) : (
            <SIMakeModelPanel standardItem={contractLine?.standardItem} />
          )}
        </Modal>
      </div>
    </div>
  );
};

export const SelectMakeModelModalType = PropTypes.shape(
  PropTypes.objectOf({
    bidId: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    standardItem: PropTypes.objectOf({
      standardItemId: PropTypes.string.isRequired,
      standardItemNumber: PropTypes.string.isRequired,
      tags: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
    solicitationLine: PropTypes.objectOf({
      id: PropTypes.string.isRequired,
    }),
  }),
);

SelectMakeModelModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  data: SelectMakeModelModalType.isRequired,
  showManufacturerNumber: PropTypes.bool,
};

SelectMakeModelModal.defaultProps = {
  showManufacturerNumber: false,
};

export default SelectMakeModelModal;
