/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  AFPTable,
  Pagination,
  EmptyState,
  StatusBadge,
  AFPTableRowAction,
} from '@gsa/afp-component-library';
import {
  statusMap,
  TaskStatus,
  taskStatusMapping,
} from '../../../task-detail/helpers/taskStatus';
import { useFederalStandards } from '../../fvs-provider';
import { useFvsLandingPageContext } from '../fvs-landing-page-provider';
import { emDashUnicode } from '../../../../utilities/constants';

const RelatedTasks = () => {
  const { vehicleStandard } = useFederalStandards();
  const history = useHistory();
  const {
    getStandardsRelatedTasks,
    relatedTasks,
    toggleTaskStatus,
  } = useFvsLandingPageContext();

  const [paginationState, setPaginationState] = useState({
    limit: 5,
    offset: 0,
    currentPage: 1,
  });

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  useEffect(() => {
    const { limit, offset } = paginationState;
    if (vehicleStandard && vehicleStandard.id) {
      getStandardsRelatedTasks({
        variables: {
          filters: {
            operator: 'AND',
            value: [
              {
                operator: 'EQ',
                key: 'fvsId',
                value: vehicleStandard.id,
              },
            ],
          },
          limit,
          offset,
          order: 'createdAt DESC',
        },
      });
    }
  }, [paginationState]);

  const actionView = {
    icon: 'visibility',
    label: 'View task details',
  };
  const actions = [
    {
      icon: 'loop',
      label: 'Mark as in Progress',
      status: 'ToDo',
    },
    {
      icon: 'loop',
      label: 'Mark as done',
      status: 'InProgress',
    },
    {
      icon: 'loop',
      label: 'Reopen',
      status: 'Closed',
    },
  ];

  const allowedActions = (row) => {
    const { taskInfo } = row;
    return [
      actionView,
      ...actions.filter((item) => item.status === taskInfo.status),
    ];
  };

  const handleSelectedAction = (evt, original) => {
    if (evt === actionView.label)
      history.push(`/catalog/tasks/${original.taskId}`);
    else
      toggleTaskStatus({
        variables: {
          id: parseInt(original.taskInfo.id, 10),
          status: taskStatusMapping(original.taskInfo.status),
        },
      });
  };

  const taskColumns = [
    {
      Header: 'Date created',
      accessor: 'createdAt',
      Cell: ({ value }) => {
        return <span>{moment(value).format('L')}</span>;
      },
      sortable: true,
    },
    {
      Header: 'Task name',
      accessor: 'taskInfo.title',
      Cell: ({ value, row }) =>
        value ? (
          // eslint-disable-next-line no-console
          <a
            href={`/catalog/tasks/${row?.original?.taskId}`}
            className="usa-button usa-button--unstyled width-auto line-height-sans-4  "
          >
            {value}
          </a>
        ) : null,
      sortable: false,
    },
    {
      Header: 'Status',
      accessor: 'taskInfo.status',
      sortable: true,
      Cell: ({ value }) => {
        return (
          <StatusBadge variant={statusMap(value).color}>
            {TaskStatus(value)}
          </StatusBadge>
        );
      },
    },
    {
      Header: 'Assigned to',
      accessor: 'taskInfo.assignedTo',
      sortable: false,
      Cell: ({ row: { original } }) => {
        return `${original?.taskInfo?.assignedTo?.fullName || emDashUnicode}`;
      },
    },
    {
      Header: 'Actions',
      sortable: false,
      Cell: ({ row }) => {
        return (
          <AFPTableRowAction
            row={row}
            onSelectAction={(evt) => handleSelectedAction(evt, row.original)}
            actions={allowedActions(row.original)}
          />
        );
      },
    },
  ];

  return (
    <>
      <AFPTable
        expandable={false}
        fullWidth
        columns={taskColumns}
        data={relatedTasks?.rows}
      />
      {relatedTasks?.rows.length === 0 && (
        <EmptyState
          hasBackground
          containerStyles="padding-y-8 margin-top-1"
          topText={<strong>There are no available tasks.</strong>}
        />
      )}
      <div className="padding-y-4 pagination">
        <Pagination
          itemsPerPageOptions={[5, 10, 25]}
          onPageChange={handlePaginationChange}
          variant="advanced"
          itemsPerPage={paginationState.limit}
          currentPage={paginationState.currentPage}
          itemsCount={relatedTasks?.count}
        />
      </div>
    </>
  );
};

export default RelatedTasks;
