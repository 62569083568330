import React from 'react';
import PropTypes from 'prop-types';
import { CommentPropType } from '../../../utilities/types';
import Comment from './comment';

const CommentItem = ({ comment }) => {
  return (
    <>
      <div className="grid-row">
        <div className="grid-col-12">
          <Comment comment={comment} />

          <div
            style={{ borderLeft: '1px solid #73B3E7' }}
            className="margin-left-7 padding-left-2"
          >
            {comment?.replies?.map((reply, i) => (
              <div
                key={reply.id}
                className={`${i === 0 ? 'margin-top-4' : 'margin-top-6'}`}
              >
                <ul className="add-list-reset">
                  <li className="margin-bottom-4">
                    <Comment comment={reply} />
                  </li>
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

CommentItem.propTypes = {
  comment: PropTypes.shape(CommentPropType).isRequired,
};

export default CommentItem;
