/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AFPTable,
  AFPTableRowAction,
  Button,
  Icon,
  EmptyState,
} from '@gsa/afp-component-library';
import { isEmpty, kebabCase, uniqBy } from 'lodash';

const PredefinedValueTable = ({
  // eslint-disable-next-line react/prop-types
  value,
  onChange,
  deletedRows,
  setDeletedRows,
  newRows,
  showAdd,
  setShowAdd,
  setPredefinedValues,
}) => {
  // eslint-disable-next-line react/prop-types
  const values = value?.value || value || [];
  const [tableData, setTableData] = useState([]);
  const inputRef = useRef(null);

  const allowedActions = [
    {
      icon: 'edit',
      label: 'Edit',
    },
    {
      icon: 'delete',
      label: 'Delete',
    },
  ];

  const handleSelectedAction = (evt, row, rows) => {
    if (evt === 'Edit') {
      const totalValues = rows.map((r) => r.original.value);

      const newValues = totalValues.map((v) => ({
        id: kebabCase(v),
        value: v,
        editing: row.original.value === v,
        adding: false,
      }));
      setTableData(newValues);
      onChange(newValues.map((v) => v.value));
    }

    if (evt === 'Delete') {
      setDeletedRows((prev) => [...prev, row.original.value]);
    }
  };

  const getTotalRows = () => {
    return [...values, ...newRows];
  };

  const handleValue = (v) => ({
    id: kebabCase(v),
    value: v,
    editing: false,
    adding: false,
  });

  useEffect(() => {
    const newValues = getTotalRows().filter((v) => !deletedRows.includes(v));
    setTableData(newValues.map((v) => handleValue(v)));
    onChange(newValues);
  }, [deletedRows]);

  useEffect(() => {
    setTableData((prev) => {
      return showAdd && prev.filter((v) => v.id === '').length < 1
        ? [
            ...prev,
            {
              id: '',
              value: '',
              editing: false,
              adding: showAdd,
            },
          ]
        : [...prev];
    });
    setShowAdd(false);
    inputRef.current?.focus({ preventScroll: false });
  }, [showAdd]);

  useEffect(() => {
    onChange(getTotalRows());
  }, [newRows]);

  const handleEditValue = (row, rows, updatedValue) => {
    const totalValues = rows.map((r) => r.original.value);
    const filteredValues = isEmpty(updatedValue)
      ? totalValues.filter((v) => !isEmpty(v))
      : totalValues;

    const newValues = filteredValues.map((v) => ({
      id: kebabCase(row.original.value === v ? updatedValue : v),
      value: row.original.value === v ? updatedValue : v,
      editing: false,
      adding: false,
    }));

    const uniqValues = uniqBy(newValues, 'value');
    setTableData(uniqValues);
    const newValuesToAdd = uniqValues.map((v) => v.value);
    onChange(newValuesToAdd);
    setPredefinedValues(newValuesToAdd);
  };

  function getTotalRowValues(rows) {
    return rows.map((r) => r.original.value);
  }

  const swap = (rowIndex, newIndex, totalValues) => {
    // eslint-disable-next-line no-param-reassign,prefer-destructuring
    totalValues[rowIndex] = [
      totalValues[newIndex],
      // eslint-disable-next-line no-param-reassign
      (totalValues[newIndex] = totalValues[rowIndex]),
    ][0];
  };

  const handleSort = (iconName, original, rows) => {
    const totalValues = getTotalRowValues(rows);
    const rowIndex = totalValues.indexOf(original.value);

    if (iconName === 'arrow_upward') {
      const newIndex = rowIndex - 1;
      swap(rowIndex, newIndex, totalValues);
    } else {
      const newIndex = rowIndex + 1;
      swap(rowIndex, newIndex, totalValues);
    }

    const sortedValues = totalValues.map((v) => handleValue(v));
    setTableData(sortedValues);
    onChange(totalValues);
    setPredefinedValues(totalValues);
  };

  const renderIcon = (iconName, { original }, rows) => {
    return (
      <Icon
        iconName={iconName}
        className="usa-icon--size-2 "
        style={{ fill: 'grey', cursor: 'pointer' }}
        onClick={() => handleSort(iconName, original, rows)}
      />
    );
  };

  const RenderEditForm = ({ currentValue }) => (
    <input
      id="value-text"
      type="input"
      aria-label="predefined value"
      defaultValue={currentValue}
      ref={inputRef}
      className="usa-input usa-input--small"
    />
  );

  RenderEditForm.propTypes = {
    currentValue: PropTypes.string.isRequired,
  };

  const RenderPredefineValue = ({ row }) => {
    // eslint-disable-next-line react/prop-types
    return row.original.editing || row.original.adding ? (
      <RenderEditForm currentValue={row.original.value} />
    ) : (
      row.original.value
    );
  };

  RenderPredefineValue.propTypes = {
    row: PropTypes.shape({
      id: PropTypes.string.isRequired,
      original: PropTypes.shape({
        id: PropTypes.string.isRequired,
        editing: PropTypes.bool.isRequired,
        value: PropTypes.string.isRequired,
      }),
    }).isRequired,
  };

  // eslint-disable-next-line react/prop-types
  const isFirstRow = (row, rows) => row?.id === '0' && rows?.length !== 1;
  const isAtleastTwo = (rows) => rows.length > 1;
  // eslint-disable-next-line react/prop-types
  const isLastRow = (row, rows) => Number(row?.id) === rows.length - 1;
  const isNotFirstAndLastRow = (row, rows) =>
    !isFirstRow(row) && !isLastRow(row, rows);

  const columns = useMemo(
    () => [
      {
        accessor: 'id',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row, rows }) => {
          return (
            // eslint-disable-next-line react/prop-types
            !(row.original.editing || row.original.adding) && (
              <div>
                {isFirstRow(row, rows) &&
                  renderIcon('arrow_downward', row, rows)}
                {isLastRow(row, rows) &&
                  isAtleastTwo(rows) &&
                  renderIcon('arrow_upward', row, rows)}
                {isNotFirstAndLastRow(row, rows) && (
                  <>
                    <div style={{ display: 'inline-block' }}>
                      {renderIcon('arrow_upward', row, rows)}
                      {renderIcon('arrow_downward', row, rows)}
                    </div>
                  </>
                )}
              </div>
            )
          );
        },
        sortable: false,
        className: 'predefinedIcon',
      },
      {
        Header: 'Predefined values',
        accessor: 'value',
        sortable: false,
        className: 'minw-15',
        Cell: (props) => <RenderPredefineValue {...props} />,
      },
      {
        Header: 'Actions',
        sortable: false,
        Cell: (props) =>
          // eslint-disable-next-line react/destructuring-assignment,react/prop-types
          props.row.original.editing || props.row.original.adding ? (
            <Button
              className="padding-top-205"
              variant="unstyled"
              type="button"
              label="Add"
              onClick={() => {
                if (inputRef.current) {
                  handleEditValue(
                    // eslint-disable-next-line react/prop-types
                    props.row,
                    // eslint-disable-next-line react/prop-types
                    props.rows,
                    inputRef.current.value,
                  );
                  inputRef.current.value = '';
                }
              }}
            />
          ) : (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <span onClick={(e) => e.preventDefault()}>
              <AFPTableRowAction
                {...props}
                actions={allowedActions}
                onSelectAction={(
                  evt, // eslint-disable-next-line react/prop-types
                ) => handleSelectedAction(evt, props.row, props.rows)}
              />
            </span>
          ),
      },
    ],
    [],
  );

  return (
    <div>
      <div className="grid-row padding-bottom-0 margin-bottom-0">
        <div className="grid-col-6">
          <h2 className="font-sans-md padding-bottom-2">Pre-defined values</h2>
          <AFPTable
            id="predefinedValues"
            columns={columns}
            onSort={() => {
              // eslint-disable-next-line no-console
              console.log('No sort required');
            }}
            data={tableData}
          />
          {tableData.length === 0 && (
            <EmptyState
              hasBackground
              containerStyles="padding-y-8 margin-top-1"
              topText={
                <p>
                  <strong>No values have been assigned.</strong>
                  <br />
                  Select Add new value to begin assigning a value.
                </p>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

PredefinedValueTable.propTypes = {
  // value: PropTypes.shape(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  deletedRows: PropTypes.arrayOf(PropTypes.object).isRequired,
  newRows: PropTypes.arrayOf(PropTypes.object).isRequired,
  setDeletedRows: PropTypes.func.isRequired,
  setShowAdd: PropTypes.func.isRequired,
  setPredefinedValues: PropTypes.func.isRequired,
  showAdd: PropTypes.bool.isRequired,
};

export default PredefinedValueTable;
