import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@gsa/afp-component-library';

const ContractHeaderUnsavedModal = ({
  onComplete,
  leavePage,
  closeModal,
  disabled,
}) => {
  return (
    <Modal
      id="contract-header-unsave-modal"
      title={<h2>Unsaved changes</h2>}
      variant="large"
      className="solicitation-modal"
      onClose={closeModal}
      actions={
        <>
          <Button
            data-testid="unsaved-modal-cancel-button"
            type="button"
            variant="unstyled"
            onClick={closeModal}
            label="Cancel"
            disabled={disabled}
          />
          <Button
            data-testid="unsaved-modal-leave-page-button"
            type="button"
            variant="secondary"
            onClick={leavePage}
            label="Leave page"
            disabled={disabled}
          />
          <Button
            data-testid="unsaved-modal-save-changes-button"
            type="button"
            onClick={onComplete}
            label="Save changes"
            disabled={disabled}
          />
        </>
      }
    >
      <p>Are you sure you want to leave this page?</p>

      <p>Changes you made will not be saved.</p>
    </Modal>
  );
};

ContractHeaderUnsavedModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  leavePage: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ContractHeaderUnsavedModal.defaultProps = {
  disabled: false,
};

export default ContractHeaderUnsavedModal;
