/* eslint-disable react/prop-types */
import React from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
  Spinner,
  FlexView,
  ErrorMessage,
  Alert,
  AFPTable,
  NotFound,
} from '@gsa/afp-component-library';
import { useQuery } from '@apollo/client';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import { emDashUnicode } from '../../../utilities/constants';
import ToastMessage from '../../../components/Toast/toast';
import LineHistoryActions from './line-history-actions';
import {
  ContractLineProvider,
  useContractLine,
} from '../line-template/provider/contract-line-provider';
import LineHeader from '../line-template/line-header';
import LineHistorySubrow from './line-history-subrow';
import { GET_CONTRACT_LINE_HISTORY } from './line-history.gql';
import { formatDateTime } from '../contract-header-history/contract-header-history-helpers';

const ContractLineHistoryView = () => {
  const { contractHeaderId } = useParams();
  const {
    contractLineId,
    contractLine,
    getContractLineQueryResult: query,
    loading,
    alert,
    setAlert,
  } = useContractLine();

  const { data: versionsData, loading: loadingHistory } = useQuery(
    GET_CONTRACT_LINE_HISTORY,
    {
      variables: { contractLineId: +contractLineId },
      fetchPolicy: 'network-only',
      skip: !contractLineId,
    },
  );

  const processData = (data) => {
    return {
      contractVersion: data.contractHeaderVersion.versionNumber,
      contractLineVersion: data.versionNumber,
      modTag: data.modTag,
      changedBy: data.createdByUser?.fullName ?? data.createdBy,
      changedAt: data.createdAt,
      changeCount: data.changes.length,
      changes: [...data.changes].reverse(),
    };
  };
  const versions = versionsData?.getContractLineHistory.map(processData) || [];
  const maxVersionNumber = Math.max(
    ...versions.map((v) => v.contractLineVersion),
  );

  const solicitaion = contractLine?.contractHeader?.solicitation;
  const purchaseTypeCode = solicitaion?.purchaseTypeCode;

  const breadcrumbs = (
    <Breadcrumbs
      path={[
        {
          location: `${window.AFP_CONFIG.appURLs.home}/home`,
          label: 'Home',
        },
        {
          location: `/catalog/contract`,
          label: 'Contracts',
        },
        {
          location: `/catalog/contract/contract-header/${contractHeaderId}/lines`,
          label: 'Contract line listing',
        },
        {
          location: `/catalog/contract/${contractHeaderId}/contract-line-template/${contractLineId}`,
          label: `Line item ${contractLine?.scheduleLine || ''}`,
        },
      ]}
      current="History"
    />
  );

  if (query?.called && !query?.loading && !contractLine) {
    return (
      <>
        {breadcrumbs}
        <Alert type="error">Contract line {contractLineId} is not found</Alert>
      </>
    );
  }

  if (loading || loadingHistory) {
    return (
      <>
        {breadcrumbs}
        <Spinner />
      </>
    );
  }

  if (!versionsData) {
    return (
      <FlexView column>
        <FlexView>
          {query?.error && (
            <ErrorMessage>
              An error occurred while loading the contract line data.
            </ErrorMessage>
          )}
        </FlexView>

        {breadcrumbs}
        <NotFound />
      </FlexView>
    );
  }

  const isNonStandard = purchaseTypeCode === 'N';
  const isMas = purchaseTypeCode === 'M';
  let subHeader = null;
  const showManufacturerNumber =
    (isNonStandard && contractLine?.manufacturerModelNumber) || isMas;

  if (showManufacturerNumber) {
    subHeader = (
      <div className="title-m">
        <span className=" title-m-bold">Manufacturer model number: </span>
        {contractLine?.manufacturerModelNumber || emDashUnicode}
      </div>
    );
  } else {
    subHeader = (
      <>
        <div className="margin-right-2 title-m">
          <span className=" title-m-bold">Body: </span>
          {contractLine?.make?.makeName || emDashUnicode}{' '}
          {contractLine?.model?.modelName || emDashUnicode}
        </div>
        {contractLine?.chassisMake && (
          <div className="title-m">
            <span className=" title-m-bold">Chassis: </span>
            {contractLine?.chassisMake?.makeName || emDashUnicode}{' '}
            {contractLine?.chassisModel?.modelName || emDashUnicode}
          </div>
        )}
      </>
    );
  }

  return (
    <FlexView column>
      <FlexView>
        {query?.error && (
          <ErrorMessage>
            An error occurred while loading the contract line data.
          </ErrorMessage>
        )}
      </FlexView>

      {contractLine && (
        <>
          {breadcrumbs}
          {alert && (
            <ToastMessage
              type={alert.type}
              message={alert.message}
              onClose={() => setAlert(null)}
              closable
              className="margin-bottom-2"
            />
          )}

          <FlexView
            vAlignContent="bottom"
            grow
            className="margin-top-2 margin-bottom-2"
          >
            <div className="margin-bottom-2">
              <FlexView vAlignContent="center" className="margin-bottom-1">
                <div className="title-xl">
                  Line Item {contractLine?.scheduleLine}
                </div>
              </FlexView>
              <FlexView className="margin-top-2">{subHeader}</FlexView>
            </div>
            <FlexView grow> </FlexView>
            <LineHistoryActions />
          </FlexView>

          <LineHeader />

          <div className="title-m-bold margin-top-4 margin-bottom-2">
            Contract line history
          </div>
          <AFPTable
            data={versions}
            expandable
            columns={[
              {
                Header: 'Contract version',
                accessor: 'contractVersion',
                sortable: true,
                Cell: ({ value }) => {
                  return (
                    <RouterLink
                      to={`/catalog/contract/contract-header/${contractHeaderId}/history/${value}`}
                      className="text-bold margin-left-1"
                    >
                      {value}
                    </RouterLink>
                  );
                },
              },
              {
                Header: 'Contract line version',
                accessor: 'contractLineVersion',
                sortable: true,
                Cell: ({ value }) => {
                  if (value === maxVersionNumber) {
                    return (
                      <RouterLink
                        to={`/catalog/contract/${contractHeaderId}/contract-line-template/${contractLineId}/`}
                        className="text-bold margin-left-1"
                      >
                        {value}
                      </RouterLink>
                    );
                  }
                  return (
                    <RouterLink
                      to={`/catalog/contract/${contractHeaderId}/contract-line-template/${contractLineId}/history/${value}`}
                      className="text-bold margin-left-1"
                    >
                      {value}
                    </RouterLink>
                  );
                },
              },
              {
                Header: 'Modification number',
                accessor: 'modTag',
                sortable: true,
                Cell: ({ value }) => value || emDashUnicode,
              },
              {
                Header: 'Changed by',
                accessor: 'changedBy',
                sortable: true,
              },
              {
                Header: 'Date/time of change',
                accessor: 'changedAt',
                sortable: true,
                Cell: ({ value }) => formatDateTime(value),
              },
              {
                Header: 'Number of changes',
                accessor: 'changeCount',
                sortable: false,
                Cell: ({ value }) => value || emDashUnicode,
              },
            ]}
            renderRowSubComponent={({ row }) => {
              return <LineHistorySubrow changes={row.original.changes} />;
            }}
            defaultSort="contractLineVersion DESC"
          />
        </>
      )}
    </FlexView>
  );
};

const ContractLineHistory = () => {
  return (
    <ContractLineProvider>
      <ContractLineHistoryView />
    </ContractLineProvider>
  );
};

export default ContractLineHistory;
