import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  ButtonDropdown,
  ButtonDropdownItem,
  FlexView,
} from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

const ContractLineActionsPannel = ({ contractHeaderId }) => {
  const { contractLineId, versionNumber } = useParams();
  const history = useHistory();
  const actionsPanelLinks = () => {
    if (versionNumber) {
      return [
        {
          title: 'View latest contract version',
          onClickHandler: () => {
            history.push(
              `/catalog/contract/contract-header/${contractHeaderId}`,
            );
          },
          customIcon: true,
          icon: 'visibility',
        },
        {
          title: 'View latest contract line version',
          onClickHandler: () => {
            history.push(
              `/catalog/contract/${contractHeaderId}/contract-line-template/${contractLineId}`,
            );
          },
          customIcon: true,
          icon: 'visibility',
        },
      ];
    }

    return [
      {
        title: 'View contract line history',
        onClickHandler: () => {
          history.push(
            `/catalog/contract/${contractHeaderId}/contract-line-template/${contractLineId}/history`,
          );
        },
        customIcon: true,
        icon: 'visibility',
      },
      {
        title: 'View contract header',
        onClickHandler: () => {
          history.push(`/catalog/contract/contract-header/${contractHeaderId}`);
        },
        customIcon: true,
        icon: 'visibility',
      },
    ];
  };

  return (
    <FlexView hAlignContent="right">
      <ButtonDropdown
        className="margin-right-0 margin-bottom-1"
        label="Actions"
        variant="outline"
        iconSize={3}
        side="right"
      >
        {actionsPanelLinks().map((link) => (
          <React.Fragment key={link.title}>
            {link ? (
              <ButtonDropdownItem
                className="text-primary hover:bg-blue-5"
                label={link.title}
                iconName={link.icon}
                iconType={link.customIcon && 'custom'}
                iconFill="#005ea2"
                onClick={() => link.onClickHandler()}
              />
            ) : null}
          </React.Fragment>
        ))}
      </ButtonDropdown>
    </FlexView>
  );
};

ContractLineActionsPannel.propTypes = {
  contractHeaderId: PropTypes.string.isRequired,
};
export default ContractLineActionsPannel;
