import React from 'react';
import PropTypes from 'prop-types';
import { Link, Icon } from '@gsa/afp-component-library';
import moment from 'moment';
import StatusBadge from './status-badge';
import { emDashUnicode } from '../../../../utilities/constants';
import BidsDownloadLink from './bids-download-link';

export const SamLink = ({ samUrl }) => (
  <Link
    className="text-primary font-sans-xs"
    href={samUrl}
    target="_blank"
    data-testid="sol-sam-link"
  >
    Solicitation at SAM.gov
    <Icon iconName="launch" className="margin-left-1" />
  </Link>
);
SamLink.propTypes = {
  samUrl: PropTypes.string.isRequired,
};

const BidsSolicitationBlock = ({
  solicitationNumber,
  title,
  samUrl,
  solicitationPeriod,
  bidId,
  setAlert,
  children,
}) => {
  const { endDate, closeInDays } = solicitationPeriod || {};
  const dataTitleCx = 'text-bold font-sans-sm margin-bottom-0';
  const dataValueCx = 'font-sans-lg margin-y-105';
  return (
    <section className="grid-row border-top-1px border-primary-light margin-bottom-1 margin-top-2">
      <section className="grid-col-3 bg-blue-5 padding-y-205 padding-x-3 radius-sm">
        <p className={dataTitleCx} data-testid="sol-number">
          {`Solicitation ${solicitationNumber}`}
        </p>
        <p className={dataValueCx} data-testid="sol-title">
          {title}
        </p>
        {samUrl && <SamLink samUrl={samUrl} />}
      </section>
      <section className="grid-col-9 bg-blue-2 padding-y-205 padding-x-3 radius-sm grid-row">
        <section className="grid-col-3 minw-15">
          <p className={dataTitleCx} data-testid="sol-closing-date-title">
            Closing date
          </p>
          <p className={dataValueCx} data-testid="sol-closing-date">
            {endDate ? moment(endDate).format('MM/DD/YYYY') : emDashUnicode}
          </p>
        </section>
        <section className="grid-col minw-15">
          <p className={dataTitleCx} data-testid="sol-closing-in-title">
            Closing in
          </p>
          <p className={dataValueCx} data-testid="sol-closing-in-status">
            <StatusBadge
              closingInDays={closeInDays?.Days}
              closingInHrs={closeInDays?.Hours}
              closingInMinutes={closeInDays?.Minutes}
            />
          </p>
        </section>
        <section className="grid-col-5 display-flex flex-align-center minw-15">
          <BidsDownloadLink bidId={bidId} setAlert={setAlert} />
        </section>
        <section
          data-testid="sol-actions-section"
          className="grid-col-2 minw-15 display-flex flex-justify-center flex-align-center margin-right-0"
        >
          {children}
        </section>
      </section>
    </section>
  );
};

BidsSolicitationBlock.propTypes = {
  solicitationNumber: PropTypes.string.isRequired,
  samUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  solicitationPeriod: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    closeInDays: PropTypes.shape({
      Days: PropTypes.number.isRequired,
      Hours: PropTypes.number,
    }).isRequired,
  }).isRequired,
  setAlert: PropTypes.func.isRequired,
  children: PropTypes.node,
  bidId: PropTypes.string.isRequired,
};

BidsSolicitationBlock.defaultProps = {
  children: null,
};

export default BidsSolicitationBlock;
