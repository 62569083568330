/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { groupBy } from 'lodash';
import {
  Button,
  Label,
  EmptyState,
  Accordion,
  MultiSelect,
  CounterTag,
  Checkbox,
  Spinner,
  Link,
} from '@gsa/afp-component-library';
import { useRecoilState } from 'recoil';
import { useParams, useHistory } from 'react-router-dom';
import useSinSelection from '../../apis/sin-selection-apis';
import solicitationNotifications from '../../../utils/solicitation-notification-helpers';
import '../../../styles/select-standard.scss';
import { ADD_STANDARD_ITEMS_TO_SOLICITATION } from '../../../queries';
import SinSelectionHeader from '../../components/sin-selection-header';
import SolicitationProgramMultiselect from '../../components/solicitaion-program-wrapper';
import {
  getInactiveAlertMsg,
  getInactiveLinkText,
  getInactiveSIArray,
} from './utils/inactive-si-utils';
import MarkUpText from '../../../../../components/MarkUpText/MarkUpText';
import { MODAL_MODES } from './utils/constants';
import './utils/styles/solicitation-modal.scss';
import biddingType from '../../../atoms/solicitation-helpers';

const EditSelectStandardItemWrapper = () => {
  const params = useParams();
  const history = useHistory();
  const [selectedPrograms, setSelectedPrograms] = useState(null);
  const [bidType, setBidType] = useRecoilState(biddingType);

  const {
    optionsLoading,
    fvsLoading,
    SILoading,
    solicitationLoading,
    options,
    vehicleClassifications,
    standardItems,
    solicitation,
    getOptions,
    getStandardItems,
    getSolicitationById,
    setModalMode,
    setInactiveSIList,
  } = useSinSelection();

  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedVehicleGroup, setSelectedVehicleGroup] = useState(null);
  const [groupedStandardItems, setGroupedStandardItems] = useState([]);
  const [selectedStandardItems, setSelectedStandardItems] = useState(null);
  const [selections, setSelections] = useState({});
  const [notifications, setSolicitationNotification] = useRecoilState(
    solicitationNotifications,
  );
  const [isDirty, setIsDirty] = useState(false);
  const [isForwardRedirect, setIsForwardRedirect] = useState(true);

  const [addStandardItemstoSolicitation] = useMutation(
    ADD_STANDARD_ITEMS_TO_SOLICITATION,
    {
      onError: (requestError) => {
        setSolicitationNotification([
          {
            id: 'ADD_STANDARD_ITEMS_ERROR',
            message: requestError.message,
            type: 'error',
            closeable: false,
            showInModal: false,
          },
          ...notifications,
        ]);
      },
      onCompleted: (response) => {
        const { id, solicitationNumber } = response.addOrUpdateSINSelections;
        // set success message
        setSolicitationNotification([
          {
            id: 'CREATE_SOLICITATION',
            message: `Solicitation ${solicitationNumber} updated successfully.`,
            type: 'success',
            closeable: true,
            showInModal: false,
          },
          ...notifications,
        ]);

        history.push({
          pathname: `/catalog/solicitations/edit-solicitation/${id}/${
            // eslint-disable-next-line
            isForwardRedirect
              ? 'assign-quantity'
              : bidType === 'IN_FLEET'
              ? 'reopen-period'
              : 'upiid-details'
          }`,
          search: history.location.search,
        });
      },
    },
  );
  // initial pull
  useEffect(() => {
    // clear any carryover notifications
    setSolicitationNotification([{}]);
    const dropdownOptions = [
      {
        model: 'StandardsCodeModel',
        label: 'title',
        value: 'code',
        filter: {
          operator: 'EQ',
          key: 'category',
          value: 'Program',
        },
        uniqueKey: 'program',
        includeValueInLabel: true,
      },
      {
        model: 'StandardsCodeModel',
        label: 'title',
        value: 'code',
        filter: {
          operator: 'EQ',
          key: 'category',
          value: 'Federal Standard Codes',
        },
        uniqueKey: 'fedCode',
        includeValueInLabel: false,
      },
    ];
    getOptions({
      variables: {
        options: dropdownOptions,
      },
    });
    getSolicitationById({
      variables: { id: Number(params?.id) },
    });
    return () => {
      setSolicitationNotification([{}]);
    };
  }, []);

  const getVehicleTypeFromCode = (code) => {
    const vehicleTypes = vehicleClassifications.filter(
      (item) => item.uniqueKey === 'vehicleType' && item.value === code,
    );
    if (vehicleTypes.length > 0) {
      return vehicleTypes[0].label.split('-')[1];
    }
    return '-';
  };

  const programDropdownOptions = options
    ?.filter((item) => item.uniqueKey === 'program' && item?.status === 1)
    ?.map((option) => ({
      label: option.value,
      value: option.label,
    }));

  useEffect(() => {
    if (selectedStandardItems > 0) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [selectedStandardItems]);
  useEffect(() => {
    if (solicitation?.id) {
      const inactiveSIArray = getInactiveSIArray(solicitation?.programs);
      if (inactiveSIArray.length > 0) {
        const tempData = inactiveSIArray.map((item) => {
          return {
            standardItem: `${item.standardItem.standardItemNumber} - ${item.standardItem.title}`,
            vehicleType: getVehicleTypeFromCode(item.standardItem.vehicleType),
          };
        });
        setInactiveSIList(tempData);
        const inactiveSIAlertMsg = getInactiveAlertMsg(inactiveSIArray.length);
        const inactiveLinkText = getInactiveLinkText(inactiveSIArray.length);
        setSolicitationNotification([
          {
            id: 'INACTIVE_STANDARD_ITEMS_DETECTED',
            message: (
              <MarkUpText
                content={inactiveSIAlertMsg}
                marker={Link}
                textToMarkUp={inactiveLinkText}
                markerProps={{
                  onClick: () => {
                    setModalMode(MODAL_MODES.INACTIVE_SI);
                  },
                }}
              />
            ),
            type: 'warning',
            closeable: false,
            showInModal: false,
          },
          ...notifications,
        ]);
      }
      const { bidType: selectedBidType } = solicitation;
      setBidType(selectedBidType);
    }
  }, [solicitation?.id]);

  useEffect(() => {
    const inactiveSIArray = getInactiveSIArray(solicitation?.programs);
    const tempData = inactiveSIArray.map((item) => {
      return {
        standardItem: `${item.standardItem.standardItemNumber} - ${item.standardItem.title}`,
        vehicleType: getVehicleTypeFromCode(item.standardItem.vehicleType),
      };
    });
    setInactiveSIList(tempData);
  }, [solicitation, vehicleClassifications]);

  useEffect(() => {
    if (solicitation) {
      const { programs } = solicitation;
      const selectedProgramsList = [];
      programs.forEach((item) => {
        const programCode = item.program;
        const option = programDropdownOptions.find(
          (o) => o.label === programCode,
        );
        if (option) {
          selectedProgramsList.push(option.value);
        }
      });
      setSelectedPrograms(selectedProgramsList);

      // pre select existing standard items

      getStandardItems({
        variables: {
          filters: {
            operator: 'AND',
            value: [
              {
                operator: 'EQ',
                key: 'year',
                value: solicitation?.contractYear,
              },
              { operator: 'EQ', key: 'deletedAt', value: null },
              { operator: 'EQ', key: 'isActive', value: 1 },
            ],
          },
          limit: 5000, // TODO insure we are getting the whole SIN for a given year
          offset: 0,
          order: 'standardItemNumber ASC',
        },
      });
    }
  }, [solicitation]);

  useEffect(() => {
    if (solicitation) {
      const currentSelections = {};
      const { programs } = solicitation;
      programs.forEach((item) => {
        const { solicitationLines } = item;

        solicitationLines.forEach((line) => {
          const standardItem = standardItems.find(
            (si) => Number(si.id) === Number(line.standardItemId),
          );

          if (standardItem) {
            // this si is still active and needs to be pre populated.
            const { code } = standardItem.fedStandard;
            if (currentSelections[code]) {
              const newSel = [...currentSelections[code], standardItem.id];
              currentSelections[code] = newSel;
            } else {
              currentSelections[code] = [standardItem.id];
            }
          }
        });
      });
      setSelections(currentSelections);
    }
  }, [standardItems]);

  const getProgramNameForVehicleGroup = (vehicleGroup) => {
    const programName = vehicleClassifications.find(
      (item) => item.value === vehicleGroup,
    )?.parent;
    return programName;
  };

  useEffect(() => {
    const countOfAllSI = Object.values(selections).reduce((acc, curr) => {
      return acc + curr.length;
    }, 0);
    setSelectedStandardItems(countOfAllSI);
  }, [selections]);

  useEffect(() => {
    // list of all possible standard items
    const possibleValues = standardItems?.filter(
      (item) => item.fedStandard.code === selectedVehicleGroup,
    );
    // if the length matches we select the checkbox
    if (possibleValues?.length === selections[selectedVehicleGroup]?.length) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }
  }, [selectedVehicleGroup, selections]);

  const handleSelectStandardItem = (item, vehicleTypeCode) => {
    const origSelections = { ...selections };
    const originalSI = origSelections[vehicleTypeCode] || [];
    const newSelections = originalSI.includes(item)
      ? originalSI.filter((i) => i !== item)
      : [...originalSI, item];
    origSelections[vehicleTypeCode] = newSelections;
    setSelections(origSelections);

    // need to update selectall checkbox based on count.
    const totalSIInVehicleGroupCount = standardItems.filter(
      (v) => v.vehicleTypeCode.code === vehicleTypeCode,
    ).length;
    if (origSelections[vehicleTypeCode].length !== totalSIInVehicleGroupCount) {
      setSelectAllChecked(false);
    }
  };

  const handleSaveStandardItemsSelection = (isForward) => {
    // No need to force sin selection, since solicitation can be created as draft without sin
    setIsForwardRedirect(isForward);
    const lines = [];
    Object.entries(selections).forEach(([key, value]) => {
      lines.push({
        program: getProgramNameForVehicleGroup(key),
        selectedStandardItemIds: value.map((item) => Number(item)),
      });
    });
    addStandardItemstoSolicitation({
      variables: {
        selections: {
          solicitationID: Number(params.id),
          lines,
        },
      },
    });
    // }
  };

  const handleBackToNewSolicitation = () => {
    // handle here
    if (isDirty) {
      //
      handleSaveStandardItemsSelection(false);
    } else if (bidType === 'IN_FLEET') {
      history.push({
        pathname: `/catalog/solicitations/edit-solicitation/${Number(
          params?.id,
        )}/reopen-period`,
        search: history.location.search,
      });
    } else {
      history.push({
        pathname: `/catalog/solicitations/edit-solicitation/${Number(
          params?.id,
        )} /upiid-details`,
      });
    }
  };

  useEffect(() => {
    const filteredSIByVehicleGroup = standardItems.filter((item) => {
      return item.fedStandard.code === selectedVehicleGroup;
    });

    const groupedSi = groupBy(filteredSIByVehicleGroup, 'vehicleTypeCode.code');
    setGroupedStandardItems(groupedSi);
  }, [selectedVehicleGroup]);

  const handleSelectAllChange = (checkedStatus) => {
    const currentSelections = { ...selections };
    // if the select all is selected add an entry to selections with all the si in the vehicle group
    if (checkedStatus) {
      const siToSelect = standardItems.filter((item) => {
        return item.fedStandard.code === selectedVehicleGroup;
      });
      const newSelections = siToSelect.map((item) => item.id);
      currentSelections[selectedVehicleGroup] = newSelections;
      setSelections(currentSelections);
    } else {
      // if select all is unchecked remove the selected SINs of the selected vehicle group
      currentSelections[selectedVehicleGroup] = [];
      setSelections(currentSelections);
    }
  };
  const handleProgramDropdownChange = (items) => {
    // update selections if changes were made to the program dropdown
    setSelectedVehicleGroup(null);
    const cleanedItems = items.map((item) => {
      const code = item.split('-')[0].trim();
      return code;
    });
    const newSelections = {};
    Object.entries(selections).forEach(([key, value]) => {
      const programName = getProgramNameForVehicleGroup(key);
      if (cleanedItems.includes(programName.toString())) {
        newSelections[key] = value;
      }
    });
    setSelections(newSelections);
    if (items) {
      setSelectedPrograms(items);
    }
  };

  return (
    <>
      {optionsLoading ||
      fvsLoading ||
      SILoading ||
      solicitationLoading ||
      selectedStandardItems === null ? (
        <>
          <div className="height-mobile">
            <Spinner />
          </div>
        </>
      ) : (
        <>
          <SinSelectionHeader
            solicitationNumber={solicitation?.solicitationNumber}
            solicitationStatus={solicitation?.status}
            solicitationContractYear={solicitation?.contractYear}
            solicitationType={solicitation?.solicitationType}
            solicitationBidType={solicitation?.bidType}
          />
          <div className="grid-row flex-row flex-wrap">
            <div className=" grid-col flex-2">
              <Label
                className="text-bold" // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
                aria-label={selectedStandardItems}
                required
              >
                Program name{' '}
              </Label>
              <SolicitationProgramMultiselect
                programDropdownOptions={programDropdownOptions}
                selectedPrograms={selectedPrograms}
                handleProgramDropdownChange={handleProgramDropdownChange}
              />
            </div>
            <div className="padding-top-20 flex-align-self-end">
              <span
                className="text-bold"
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
                aria-label={selectedStandardItems}
              >
                {selectedStandardItems}
              </span>
              <span
                className="padding-left-1"
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
                aria-label="Standard Items selected"
              >
                Standard Items selected
              </span>
            </div>
          </div>

          <div className="columns-wrapper grid-row flex-row margin-top-5 grid-gap-3">
            <div className="grid-col flex-4">
              <div className="header text-primary">
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                <span aria-label="Selected programs" tabIndex={0}>
                  Selected programs
                </span>
              </div>
              {selectedPrograms?.length > 0 ? (
                <>
                  {selectedPrograms?.map((item) => (
                    <Accordion
                      key={item}
                      items={[
                        {
                          title: item,
                          content: (
                            <div>
                              {vehicleClassifications.map((vehicle) => {
                                const programName = item?.split('-')[0].trim();
                                if (
                                  vehicle?.parent === programName &&
                                  vehicle?.uniqueKey === 'fedStandardCodes'
                                ) {
                                  return (
                                    <ul
                                      key={vehicle.id}
                                      className="usa-sidenav"
                                    >
                                      <li className="">
                                        <a
                                          style={{ cursor: 'pointer' }}
                                          className={
                                            selectedVehicleGroup ===
                                            vehicle.value
                                              ? 'usa-current'
                                              : ''
                                          }
                                          onClick={() => {
                                            setSelectedVehicleGroup(
                                              vehicle.value,
                                            );
                                          }}
                                          aria-label={
                                            vehicle.label.split('-')[1]
                                          }
                                        >
                                          {vehicle.label}
                                          {selections[vehicle.value]?.length >
                                          0 ? (
                                            <CounterTag
                                              count={
                                                selections[vehicle.value]
                                                  ?.length
                                              }
                                              className="margin-left-1"
                                            />
                                          ) : null}
                                        </a>
                                      </li>
                                    </ul>
                                  );
                                }
                                return null;
                              })}
                            </div>
                          ),
                        },
                      ]}
                    />
                  ))}
                </>
              ) : (
                <EmptyState
                  bottomText="Select one or more program names to start selecting related federal standard codes."
                  containerStyles="padding-y-6 margin-top-1"
                  hasBackground
                />
              )}
            </div>
            <div className="grid-col flex-8">
              <div className="header text-primary grid-row flex-justify-self-start flex-align-baseline">
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                <span aria-label="Selected programs" tabIndex={0}>
                  Standard items
                </span>
                {selectedVehicleGroup?.length > 0 ? (
                  <Checkbox
                    checked={selectAllChecked}
                    label="Select all"
                    className="text-checkbox margin-left-2"
                    data-testid="select-all-checkbox"
                    onChange={() => {
                      setSelectAllChecked(!selectAllChecked);
                      handleSelectAllChange(!selectAllChecked);
                    }}
                  />
                ) : null}
              </div>
              {selectedVehicleGroup ? (
                Object.keys(groupedStandardItems).map((group, index) => {
                  const standarditems = groupedStandardItems[group];
                  const { title } = standarditems[0].vehicleTypeCode;
                  const content = (
                    <MultiSelect
                      data-testid={`solicitation-std-item-multiselect-${index}`}
                      options={standarditems.map((v) => {
                        return {
                          label: `${v.standardItemNumber} - ${v.title}`,
                          value: v.id,
                        };
                      })}
                      onChange={(v) =>
                        handleSelectStandardItem(v, selectedVehicleGroup)
                      }
                      selectedValues={selections[selectedVehicleGroup] || []}
                    />
                  );

                  return (
                    <div className="select-standard-item-accordion">
                      <Accordion
                        multiselectable
                        bordered
                        className="margin-top-1"
                        key={title}
                        items={[
                          {
                            title,
                            content,
                          },
                        ]}
                      />
                    </div>
                  );
                })
              ) : (
                <EmptyState
                  bottomText="Select a program name and federal standard code to view related standard Items."
                  containerStyles="padding-y-7 margin-top-1"
                  hasBackground
                />
              )}
            </div>
          </div>
          <br />
          <div className="grid-row">
            <div className="flex">
              <Button
                variant="outline"
                label="Previous"
                data-testid="back-button"
                leftIcon={{ name: 'arrow_back' }}
                onClick={() => handleBackToNewSolicitation()}
              />
            </div>
            <div className="flex padding-left-1">
              <Button
                variant="primary"
                label="Next"
                data-testid="next-button"
                rightIcon={{ name: 'arrow_forward' }}
                onClick={() => handleSaveStandardItemsSelection(true)}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditSelectStandardItemWrapper;
