/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { orderBy } from 'lodash';
import {
  Button,
  AFPTable,
  PageTitle,
  Spinner,
  EmptyState,
} from '@gsa/afp-component-library';
import './review.scss';
import { useHistory, useParams } from 'react-router-dom';
import { ReopenPeriodcolumns } from './helpers';
import GenericMessagesToast from '../../../../../components/Toast/generic-messages-toast';
import SolicitationStepper from '../../../components/solicitation-stepper';
// import SolicitationNotification from '../../../utils/solicitation-notification';
import {
  solicitationNoOpenPeriodErrorNotifications,
  solicitationNoSinErrorNotifications,
} from '../../../atoms/solicitaion-submit-error-atom';
import useReview from '../../apis/review-apis';
import { PURCHASE_TYPE_MAP, SOLICITATION_STATUSES } from '../../../constants';
import ViewSolicitationStandardItems from '../../../view-solicitation/view-solicitation-std-items';
import {
  processOpenPeriod,
  processReopenPeriods,
  // eslint-disable-next-line import/no-useless-path-segments
} from '../../common/reopen-periods/open-reopen-period-util';
import SolicitationStatusBadge from '../../../components/solicitation-status-badge';
import biddingType from '../../../atoms/solicitation-helpers';
import SolicitationPeriodsTableSubComponent from '../../../components/solicitation-periods-table-sub-comp';
import OpenPeriodTable from './open-period-table';
import { emDashUnicode } from '../../../../../utilities/constants';

const ReviewSubmitWrapper = () => {
  const [bidType, setBidType] = useRecoilState(biddingType);
  const {
    solicitation,
    getSolicitationById,
    solicitationLoading,
    updateSolicitation,
    submitSolicitation,
    SIcount,
    getFvsCodes,
    standardItems,
  } = useReview();

  const { id } = useParams();

  const [noOpenPeriodErrnotifications] = useRecoilState(
    solicitationNoOpenPeriodErrorNotifications,
  );
  const [noSinErrnotifications] = useRecoilState(
    solicitationNoSinErrorNotifications,
  );

  const [openPeriod, setOpenPeriod] = useState({});
  const [reopenPeriods, setReopenPeriods] = useState([]);
  const [order, setOrder] = useState('period ASC');

  useEffect(() => {
    getSolicitationById({ variables: { id: Number(id) } });
  }, [id]);

  useEffect(() => {
    if (solicitation?.solicitationPeriods.length > 0) {
      const ropenPeriods = processReopenPeriods(
        solicitation?.solicitationPeriods,
      );

      const rp =
        ropenPeriods.length > 0
          ? ropenPeriods.map((prd, idx) => {
              if (prd.periodType === 'R') {
                return {
                  ...prd,
                  period: `Reopen period ${idx + 2}`,
                };
              }
              return {
                ...prd,
                period: `Midcycle period ${idx + 2}`,
              };
            })
          : [];
      setReopenPeriods(rp);
      setOpenPeriod(processOpenPeriod(solicitation?.solicitationPeriods));
    }
    if (solicitation?.contractYear) {
      getFvsCodes({
        variables: {
          year: solicitation.contractYear,
          includeOnlyCommentable: false,
        },
      });
      const { bidType: selectedBidType } = solicitation;
      setBidType(selectedBidType);
    }
  }, [solicitation]);

  useEffect(() => {
    if (reopenPeriods && order) {
      const sortField = order?.split(' ')[0].trim().slice(1, -1);
      const sortOrder = order?.split(' ')[1]?.toLowerCase();
      setReopenPeriods(orderBy([...reopenPeriods], sortField, sortOrder));
    }
  }, [order]);

  const history = useHistory();

  const handleSubmitasDraft = () => {
    const submitData = {
      solicitationinput: {
        solicitationID: Number(solicitation.id),
        solicitationNumber: solicitation.solicitationNumber,
        title: solicitation.title,
        contractYear: Number(solicitation.contractYear),
        purchaseTypeCode: solicitation.purchaseTypeCode,
        solicitationType: solicitation.solicitationType,
        description: solicitation.description,
        status: 'Draft',
        samUrl: solicitation.samUrl,
      },
    };

    updateSolicitation({
      variables: {
        ...submitData,
      },
    });
  };

  return (
    <>
      <div
        className="submit-solicitation-wrapper"
        data-testId="submit-solicitation-wrapper"
      >
        {!solicitationLoading ? (
          <>
            <div className=" grid-row review-header">
              <PageTitle
                title={`${solicitation?.solicitationType} Solicitation - ${solicitation?.solicitationNumber}`}
                aria-label={`${solicitation?.solicitationType} Solicitation - ${solicitation?.solicitationNumber}`}
                className="margin-top-0"
                tabIndex="0"
              />
              <div className="statusBadgeContainer margin-left-1 margin-top-2">
                <SolicitationStatusBadge status={solicitation?.status} />
              </div>
            </div>

            <SolicitationStepper currentStep={bidType === 'IN_FLEET' ? 6 : 4} />
            <p className="grid-col-7" role="paragraph">
              Review your solicitation information for accuracy. To make changes
              to a section, use the Edit link for that section. You may also use
              the Previous button at the bottom of the page to navigate back to
              any step to make changes. Click Submit to set your solicitation to
              New status.
            </p>
            <br />
            <div className="solicitation-upiid-container">
              <div className="grid-row flex-justify">
                <h3 className="margin-bottom-1">Solicitation uPIID</h3>
                <Button
                  variant="unstyled"
                  className="padding-bottom-10"
                  label="Edit"
                  leftIcon={{
                    name: 'edit',
                  }}
                  onClick={() =>
                    history.push({
                      pathname: `/catalog/solicitations/edit-solicitation/${id}/upiid-details`,
                      search: history.location.search,
                    })
                  }
                />
              </div>
              <hr className=" border-gray-1 margin-top-0" />
              <h4 className="margin-bottom-1">Solicitation uPIID number</h4>
              <span>{solicitation?.solicitationNumber}</span>
              <div className="grid-row grid-col-5 grid-gap-2">
                <div className="year-container">
                  <h4 className="margin-bottom-1">Contract year</h4>
                  <span>{solicitation?.contractYear}</span>
                </div>
                <div className="type-container">
                  <h4 className="margin-bottom-1">Purchase type</h4>
                  <span>
                    {PURCHASE_TYPE_MAP[solicitation?.purchaseTypeCode]}
                  </span>
                </div>
                <div className="type-container">
                  <h4 className="margin-bottom-1">Solicitation process</h4>
                  <span>{solicitation?.solicitationType}</span>
                </div>
              </div>
              <br />
            </div>
            <div className="solicitation-details-container">
              <div className="grid-row flex-justify">
                <h3 className="margin-bottom-1">Solicitation details</h3>
                <Button
                  variant="unstyled"
                  className="padding-bottom-10"
                  label="Edit"
                  leftIcon={{
                    name: 'edit',
                  }}
                  onClick={() =>
                    history.push({
                      pathname: `/catalog/solicitations/edit-solicitation/${id}/upiid-details`,
                      search: history.location.search,
                    })
                  }
                />
              </div>
              <hr className=" border-gray-1 margin-top-0" />
              <h4 className="margin-bottom-1">Solicitation title</h4>
              <span>{solicitation?.title}</span>
              <h4 className="margin-bottom-1">Description</h4>
              <div className="grid-col-7">
                <span>{solicitation?.description}</span>
              </div>
              <h4 className="margin-bottom-1">Solicitation posting</h4>
              <div className="grid-row">
                <div
                  className="grid-col-8"
                  tabIndex="0"
                  aria-label={
                    solicitation?.samUrl ? solicitation?.samUrl : emDashUnicode
                  }
                >
                  <span>
                    {solicitation?.samUrl ? (
                      <a href={solicitation?.samUrl}>{solicitation?.samUrl}</a>
                    ) : (
                      emDashUnicode
                    )}
                  </span>
                </div>
              </div>
            </div>
            <br />
          </>
        ) : (
          <Spinner />
        )}

        {bidType === 'IN_FLEET' && (
          <>
            <div className="open-period-container">
              <div className="grid-row flex-justify">
                <h3 className="margin-bottom-1">Open period</h3>
                <Button
                  variant="unstyled"
                  className="padding-bottom-10"
                  label="Edit"
                  leftIcon={{
                    name: 'edit',
                  }}
                  onClick={() => {
                    history.push({
                      pathname: `/catalog/solicitations/${
                        openPeriod?.startDate
                          ? 'edit-solicitation'
                          : 'new-solicitation'
                      }/${id}/open-period`,
                      search: history.location.search,
                    });
                  }}
                />
              </div>
              <hr className=" border-gray-1 margin-top-0" />
              <div className="grid-row ">
                <div className="grid-col-fill">
                  <GenericMessagesToast
                    allMessages={noOpenPeriodErrnotifications}
                    isModal={false}
                    onClose={() => {
                      return null;
                    }}
                  />
                </div>
              </div>
              <OpenPeriodTable openPeriod={openPeriod} />
            </div>
            <div className="reopen-periods-container">
              <div className="grid-row flex-justify">
                <h3 className="margin-bottom-1">Additional periods</h3>
                <Button
                  variant="unstyled"
                  className="padding-bottom-10"
                  label="Edit"
                  leftIcon={{
                    name: 'edit',
                  }}
                  onClick={() =>
                    history.push({
                      pathname: `/catalog/solicitations/edit-solicitation/${id}/reopen-period`,
                      search: history.location.search,
                    })
                  }
                />
              </div>
              <hr className=" border-gray-1 margin-top-0" />
              <AFPTable
                columns={ReopenPeriodcolumns}
                data={reopenPeriods}
                onSort={setOrder}
                defaultSort={order}
                renderRowSubComponent={SolicitationPeriodsTableSubComponent}
              />
              {reopenPeriods?.length === 0 && (
                <EmptyState
                  hasBackground
                  containerStyles="padding-y-5 margin-top-1 adding-y-8 margin-top-neg-2"
                  data-testId="empty-state"
                />
              )}
            </div>
          </>
        )}

        <div className="standard-items-container">
          <div className="grid-row flex-justify">
            <h3 className="margin-bottom-1">Standard Items</h3>
            <Button
              variant="unstyled"
              className="padding-bottom-10"
              label="Edit"
              leftIcon={{
                name: 'edit',
              }}
              onClick={() =>
                history.push({
                  pathname: `/catalog/solicitations/edit-solicitation/${id}/select-sin`,
                  search: history.location.search,
                })
              }
            />
          </div>
          <hr className=" border-gray-1 margin-top-0" />
          <div className="grid-row">
            <div className="grid-col-fill">
              <GenericMessagesToast
                allMessages={noSinErrnotifications}
                isModal={false}
                onClose={() => {
                  return null;
                }}
              />
            </div>
          </div>
          <div className="grid-row">
            <div
              className="grid-col-8"
              data-testid="view-solicitation-detail-standard-item-count"
              tabIndex="0"
              aria-label={`${SIcount} Standard Items selected`}
            >
              <span>
                <strong>{SIcount}</strong> Standard Items selected
              </span>
              <br />
            </div>
          </div>
          <ViewSolicitationStandardItems standardItems={standardItems} />
        </div>
        <div className="grid-row padding-top-4 padding-bottom-1">
          <div className="flex">
            <Button
              variant="outline"
              label="Previous"
              leftIcon={{ name: 'arrow_back' }}
              onClick={() => {
                history.push({
                  pathname: `/catalog/solicitations/edit-solicitation/${id}/assign-quantity`,
                  search: history.location.search,
                });
              }}
            />
          </div>
          <div className="flex padding-left-1">
            <Button
              variant="primary"
              label="Submit"
              onClick={() =>
                submitSolicitation({
                  variables: { solicitationId: Number(id) },
                })
              }
            />
          </div>
        </div>
        <Button
          variant="unstyled"
          label={
            solicitation?.status === SOLICITATION_STATUSES.Open
              ? 'Return to Solicitation Listing page'
              : 'Keep as draft & return to Solicitation Listing page'
          }
          onClick={() => {
            if (solicitation?.status !== SOLICITATION_STATUSES.Open) {
              handleSubmitasDraft();
            } else {
              history.push('/catalog/solicitations'); // Navigate back to listing page
            }
          }}
          className="text-underline"
          leftIcon={{
            name: 'arrow_back',
          }}
        />
      </div>
    </>
  );
};

export default ReviewSubmitWrapper;
