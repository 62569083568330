import React from 'react';
import { Alert, Icon, Button } from '@gsa/afp-component-library';
import moment from 'moment';
import { emDashUnicode } from '../../utilities/constants';

import { useCommentsAdmin } from './comments-admin-provider';

const CommentAdminMessages = () => {
  const {
    successMessage,
    errorMessage,
    setData,
    warningMessage,
    bulkResponse,
  } = useCommentsAdmin();

  const CustomResponse = () => {
    return (
      <>
        {bulkResponse.invalid.length + bulkResponse.new.length !== 0 ? (
          <div>
            The following{' '}
            {bulkResponse.invalid.length + bulkResponse.new.length} comments
            cannot be published at this time for the reasons indicated:
          </div>
        ) : null}

        {bulkResponse.new?.map((comment) => (
          <ul>
            <li>
              <b>
                {comment.createdByUserInfo?.fullName || emDashUnicode},{' '}
                {comment.authorType}
              </b>{' '}
              {moment(comment.createdAt).format('MM-DD-YYYY')} : Cannot be in
              &quot;new&quot; state
            </li>
          </ul>
        ))}
        {bulkResponse.invalid?.map((comment) => (
          <ul>
            <li>
              <b>
                {comment.createdByUserInfo?.fullName || emDashUnicode},{' '}
                {comment.authorType}
              </b>{' '}
              {moment(comment.createdAt).format('MM-DD-YYYY')} : Cannot be in
              &quot;invalid&quot; state
            </li>
          </ul>
        ))}
      </>
    );
  };

  return (
    <>
      {errorMessage && (
        <Alert
          slim
          focused
          actions={
            <div className="position-relative">
              <div className="position-static">
                <div className="position-absolute bottom-0 right-0">
                  <Button
                    aria-label="close"
                    data-testid="error-close"
                    variant="unstyled"
                    style={{ color: 'black' }}
                    className="margin-x-1 margin-y-neg-2"
                    onClick={() => setData('SET_ERROR_MESSAGE', '')}
                    leftIcon={{
                      name: 'close',
                      className: 'usa-icon--size-3',
                    }}
                  />
                </div>
              </div>
            </div>
          }
          type="error"
        >
          {errorMessage}
        </Alert>
      )}

      {successMessage && (
        <Alert
          slim
          focused
          actions={
            <div className="position-relative">
              <div className="position-static">
                <div className="position-absolute bottom-0 right-0">
                  <Button
                    aria-label="close"
                    data-testid="success-close"
                    variant="unstyled"
                    style={{ color: 'black' }}
                    className="margin-x-1 text-large text-heavy font-sans-xl text-gray-500"
                    onClick={
                      () => {
                        setData('SET_SUCCESS_MESSAGE', '');
                      }
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    leftIcon={{
                      name: 'close',
                    }}
                  />
                </div>
              </div>
            </div>
          }
          type="success"
        >
          {successMessage}
        </Alert>
      )}

      {warningMessage && (
        <Alert
          slim
          actions={
            <div className="position-relative">
              <div className="position-static">
                <div className="position-absolute bottom-0 right-0">
                  <Icon
                    data-testid="success-close"
                    iconName="close"
                    style={{ cursor: 'pointer' }}
                    onClick={
                      () => {
                        setData('SET_WARNING_MESSAGE', '');
                        setData('SET_BULK_RESPONSE', null);
                      }
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    className="margin-x-1 text-large text-heavy font-sans-xl text-gray-500"
                  />
                </div>
              </div>
            </div>
          }
          type="warning"
        >
          {bulkResponse ? <CustomResponse /> : warningMessage}
        </Alert>
      )}
    </>
  );
};

CommentAdminMessages.defaultProps = {
  isModal: false,
};

export default CommentAdminMessages;
