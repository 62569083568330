import React from 'react';
import {
  ErrorMessage,
  MultiSelect,
  SelectDropdown,
  Textbox,
  TextInput,
} from '@gsa/afp-component-library';
import { isEmpty } from 'lodash';
import * as PropTypes from 'prop-types';
import { Controller, FormProvider, useFormContext } from 'react-hook-form';
import {
  getRules,
  renderCodeError,
  renderTitleError,
  renderSequenceError,
  validate,
} from '../../utilities/validations/error-rules';
import InteractiveCheck from '../InteractiveCheck/InteractiveCheck';
import getFormContext from './catalog-codes-form-context';
import { StandardFieldset } from '../../pages/solicitation/components/standard-fieldset';
import { OPERATIONS, SUBJECTS } from '../../utilities/constants';

const selectionTypeOptions = [
  { label: 'M - Multiple', value: 'M' },
  { label: 'S - Single', value: 'S' },
];

const allowNewBidOptions = [
  { label: '-Select-', value: '' },
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
];

function validateUSPhoneNumber(phoneNumber) {
  const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  return regex.test(phoneNumber);
}

function validateEmailAddress(email) {
  const regex = /^[a-z0-9!#$%&'+/=?^{|}~_-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[a-z]{2}|aero|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel)$/i;
  return regex.test(email);
}

// eslint-disable-next-line react/prop-types
const ContactForm = ({ contactType, title, required = true }) => {
  const { control, setError, clearErrors, errors } = useFormContext();
  return (
    <StandardFieldset>
      <h4
        className="standard-section-header"
        style={{
          fontSize: '0.75em',
          textTransform: 'uppercase',
          fontWeight: 700,
          color: '#005ea2',
        }}
      >
        {title}
      </h4>
      <div className="grid-row">
        <div className="grid-col-5">
          <Controller
            control={control}
            name={`vehicleGroupPOCs.${contactType}.firstName`}
            defaultValue=""
            required={required}
            render={({ onChange, value }) => {
              return (
                <TextInput
                  style={{ width: 248 }}
                  required={required}
                  name={`vehicleGroupPOCs.${contactType}.firstName`}
                  onChange={onChange}
                  onBlur={(v) => {
                    if (required && !v.target.value) {
                      setError(`vehicleGroupPOCs.${contactType}.firstName`, {
                        type: 'manual',
                        message: 'This is a required field.',
                      });
                    } else {
                      clearErrors(`vehicleGroupPOCs.${contactType}.firstName`);
                    }
                  }}
                  value={value}
                  minLength={2}
                  maxLength={120}
                  label="First name"
                  errorMessage={
                    errors?.vehicleGroupPOCs?.[contactType]?.firstName?.message
                  }
                />
              );
            }}
          />
        </div>
        <div className="grid-col-5">
          <Controller
            control={control}
            name={`vehicleGroupPOCs.${contactType}.lastName`}
            defaultValue=""
            required={required}
            render={({ onChange, value }) => {
              return (
                <TextInput
                  style={{ width: 248 }}
                  required={required}
                  name={`vehicleGroupPOCs.${contactType}.lastName`}
                  onChange={onChange}
                  onBlur={(v) => {
                    if (required && !v.target.value) {
                      setError(`vehicleGroupPOCs.${contactType}.lastName`, {
                        type: 'manual',
                        message: 'This is a required field.',
                      });
                    } else {
                      clearErrors(`vehicleGroupPOCs.${contactType}.lastName`);
                    }
                  }}
                  value={value}
                  maxLength="120"
                  label="Last name"
                  errorMessage={
                    errors?.vehicleGroupPOCs?.[contactType]?.lastName?.message
                  }
                />
              );
            }}
          />
        </div>
      </div>

      <div className="grid-row">
        <div className="grid-col-5">
          <Controller
            control={control}
            name={`vehicleGroupPOCs.${contactType}.email`}
            defaultValue=""
            required={required}
            render={({ onChange, value }) => {
              return (
                <TextInput
                  style={{ width: 248 }}
                  required={required}
                  name={`vehicleGroupPOCs.${contactType}.email`}
                  onChange={(v) => {
                    onChange(v);
                  }}
                  onBlur={(v) => {
                    if (required && !v.target.value) {
                      setError(`vehicleGroupPOCs.${contactType}.email`, {
                        type: 'manual',
                        message: 'This is a required field.',
                      });
                    } else if (
                      v.target.value &&
                      !validateEmailAddress(v.target.value)
                    ) {
                      setError(`vehicleGroupPOCs.${contactType}.email`, {
                        type: 'manual',
                        message: 'Invalid email address',
                      });
                    } else {
                      clearErrors(`vehicleGroupPOCs.${contactType}.email`);
                    }
                  }}
                  value={value}
                  label="Email"
                  errorMessage={
                    errors?.vehicleGroupPOCs?.[contactType]?.email?.message
                  }
                />
              );
            }}
          />
        </div>
        <div className="grid-col-5">
          <Controller
            control={control}
            name={`vehicleGroupPOCs.${contactType}.phoneNumber`}
            defaultValue=""
            required={required}
            render={({ onChange, value }) => {
              return (
                <TextInput
                  style={{ width: 248 }}
                  required={required}
                  name={`vehicleGroupPOCs.${contactType}.phoneNumber`}
                  onChange={(v) => {
                    onChange(v);
                  }}
                  onBlur={(v) => {
                    if (required && !v.target.value) {
                      setError(`vehicleGroupPOCs.${contactType}.phoneNumber`, {
                        type: 'manual',
                        message: 'This is a required field.',
                      });
                    } else if (
                      v.target.value &&
                      !validateUSPhoneNumber(v.target.value)
                    ) {
                      setError(`vehicleGroupPOCs.${contactType}.phoneNumber`, {
                        type: 'manual',
                        message: 'Invalid phone number',
                      });
                    } else {
                      clearErrors(
                        `vehicleGroupPOCs.${contactType}.phoneNumber`,
                      );
                    }
                  }}
                  value={value}
                  label="Phone number"
                  errorMessage={
                    errors?.vehicleGroupPOCs?.[contactType]?.phoneNumber
                      ?.message
                  }
                />
              );
            }}
          />
        </div>
      </div>
    </StandardFieldset>
  );
};

const CatalogCodeForm = ({
  formId,
  selectedCatalogCode,
  metadataOfSelectedCategory,
  catalogOptionsForTagsAndParentCodes,
  isNew,
  equipmentCategorySchema,
  fuelTypeCategorySchema,
  onSubmit,
  // eslint-disable-next-line react/prop-types
  ability,
}) => {
  const methods = getFormContext(
    metadataOfSelectedCategory.category,
    selectedCatalogCode,
    // eslint-disable-next-line react/prop-types
    ability?.can(OPERATIONS.Manage, SUBJECTS.VehicleGroupEngPOCs),
  );
  const { errors, handleSubmit, control, register, watch } = methods;

  const renderRequiredComponent = (value) => {
    return (
      <>
        <h2 className="font-sans-md">
          {value} <span className="usa-hint usa-hint--required">*</span>
        </h2>
      </>
    );
  };

  const hasTagOptions = !!metadataOfSelectedCategory.categoryOfTags;
  const hasParentCategory = !!metadataOfSelectedCategory.parentCategory;
  const tagOptions = catalogOptionsForTagsAndParentCodes?.tagCodes;
  const parentOptions = catalogOptionsForTagsAndParentCodes?.parentCodes;
  const catalogTagDefaultValue = selectedCatalogCode
    ? selectedCatalogCode.tags?.value
    : [];

  // code
  const isDirty = !isEmpty(watch('code')) || !isEmpty(errors?.code?.message);
  const rules = getRules(watch('code'), metadataOfSelectedCategory.category);
  const isValid = validate(rules);
  const hasCodeError = !isValid && isDirty;

  // Title
  const isTitleDirty =
    !isEmpty(watch('title')) || !isEmpty(errors?.title?.message);
  const titleRules = getRules(watch('title'), 'Title');
  const isTitleValid = validate(titleRules);
  const hasTittleError = !isTitleValid && isTitleDirty;

  // Sequence
  const isSequenceDirty =
    !isEmpty(watch('sequence')) || !isEmpty(errors?.sequence?.message);
  const Sequencerules = getRules(watch('sequence'), 'Sequence');
  const isSequenceValid = validate(Sequencerules);
  const hasSequenceError = !isSequenceValid && isSequenceDirty;

  const allowNewBidValue = (defaultValue) => {
    if (isNew) {
      return '';
    }
    return defaultValue ? 'Y' : 'N';
  };

  return (
    <FormProvider {...methods}>
      <form id={formId} onSubmit={handleSubmit(onSubmit)} noValidate>
        <div
          className={`grid-row padding-bottom-2 ${
            hasCodeError ? 'padding-left-2' : null
          }`}
        >
          <div className="grid-col-4 ">
            {isNew ? (
              renderRequiredComponent('Code')
            ) : (
              <h2 className="font-sans-md">Code</h2>
            )}
            {isNew ? (
              <>
                <TextInput
                  data-testid="catalog_code_code"
                  name="code"
                  aria-label="Code"
                  aria-required="true"
                  type="text"
                  inputRef={register}
                  errorMessage={renderCodeError(
                    hasCodeError,
                    'Make sure the code meets the requirements listed below.',
                  )}
                />
                {hasCodeError && (
                  <InteractiveCheck checks={rules} isDirty={isDirty} />
                )}
              </>
            ) : (
              selectedCatalogCode?.code
            )}
          </div>
        </div>

        <div
          className={`grid-row padding-bottom-2 ${
            hasTittleError ? 'padding-left-2' : null
          }`}
        >
          <div className="grid-col-4">
            {renderRequiredComponent('Title')}
            <TextInput
              data-testid="catalog_title_code"
              name="title"
              aria-label="Title"
              aria-required="true"
              type="text"
              defaultValue={isNew ? '' : selectedCatalogCode?.title}
              inputRef={register}
              errorMessage={renderTitleError(hasTittleError)}
            />
            {hasTittleError && (
              <InteractiveCheck checks={titleRules} isDirty={isTitleDirty} />
            )}
          </div>
        </div>
        {/* Sequence */}
        {!isEmpty(equipmentCategorySchema) && (
          <>
            <div
              className={`grid-row padding-bottom-2 ${
                hasSequenceError ? 'padding-left-2' : null
              }`}
            >
              <div className="grid-col-4">
                {renderRequiredComponent('Sequence')}
                <TextInput
                  data-testid="eq_edit_sequence"
                  name="sequence"
                  aria-label="Sequence"
                  aria-required="true"
                  inputRef={register}
                  type="text"
                  defaultValue={
                    isNew ? '0' : selectedCatalogCode?.additionalProps?.sequence
                  }
                  errorMessage={renderSequenceError(
                    hasSequenceError,
                    'Make sure the Sequence meets the requirements listed below.',
                  )}
                />
                {hasSequenceError && (
                  <InteractiveCheck
                    checks={Sequencerules}
                    isDirty={isSequenceDirty}
                  />
                )}
              </div>
            </div>
            <div className="grid-row padding-bottom-2">
              <div className="grid-col-4">
                {renderRequiredComponent('Selection Type')}
                <Controller
                  data-testid="catalog_selectionType"
                  name="selectionType"
                  control={control}
                  defaultValue={
                    isNew
                      ? selectionTypeOptions[0].value
                      : selectedCatalogCode?.additionalProps?.selectionType
                  }
                  render={({ onChange, value }) => (
                    <SelectDropdown
                      data-testid="catalog_selectionType_sel"
                      aria-label="Selection Type"
                      aria-required="true"
                      onChange={(ev) => {
                        onChange(ev.target.value);
                      }}
                      options={selectionTypeOptions}
                      value={value}
                    />
                  )}
                />
                {errors?.selectionType?.message && (
                  <ErrorMessage>{errors.selectionType.message}</ErrorMessage>
                )}
              </div>
            </div>
          </>
        )}

        {!isEmpty(fuelTypeCategorySchema) && (
          <>
            <div className="grid-row padding-bottom-2">
              <div className="grid-col-4">
                {renderRequiredComponent('Fast Code')}
                <Textbox
                  data-testid="catalog_fastCode"
                  name="fastCode"
                  aria-label="Fast Code"
                  aria-required="true"
                  type="text"
                  defaultValue={
                    isNew ? '' : selectedCatalogCode?.additionalProps?.fastCode
                  }
                  error={errors?.fastCode}
                  inputRef={register}
                />
                {errors?.fastCode?.message && (
                  <ErrorMessage>{errors.fastCode.message}</ErrorMessage>
                )}
              </div>
            </div>

            <div className="grid-row padding-bottom-2">
              <div className="grid-col-4">
                {renderRequiredComponent('Short Hand')}
                <Textbox
                  data-testid="catalog_shortHand"
                  name="shortHand"
                  aria-label="Short Hand"
                  aria-required="true"
                  type="text"
                  defaultValue={
                    isNew ? '' : selectedCatalogCode?.additionalProps?.shortHand
                  }
                  error={errors?.shortHand}
                  inputRef={register}
                />
                {errors?.shortHand?.message && (
                  <ErrorMessage>{errors.shortHand.message}</ErrorMessage>
                )}
              </div>
            </div>

            <div className="grid-row padding-bottom-2">
              <div className="grid-col-4">
                {renderRequiredComponent('New Bids')}
                <Controller
                  data-testid="catalog_new_bid"
                  name="allowNewBids"
                  control={control}
                  defaultValue={allowNewBidValue(
                    selectedCatalogCode?.additionalProps?.allowNewBids,
                  )}
                  render={({ onChange, value }) => (
                    <SelectDropdown
                      data-testid="catalog_newbids_sel"
                      aria-label="Allow New bids"
                      aria-required="true"
                      onChange={(ev) => {
                        onChange(ev.target.value);
                      }}
                      options={allowNewBidOptions}
                      value={value}
                    />
                  )}
                />
                {errors?.allowNewBids?.message && (
                  <ErrorMessage>{errors.allowNewBids.message}</ErrorMessage>
                )}
              </div>
            </div>
          </>
        )}

        {hasParentCategory && (
          <div className="grid-row padding-bottom-2">
            <div className="grid-col-4">
              {renderRequiredComponent(
                `${metadataOfSelectedCategory.parentCategory}`,
              )}
              <Controller
                data-testid="catalog_code_parent"
                name="parentCode"
                control={control}
                defaultValue={isNew ? '' : selectedCatalogCode?.parentCode}
                render={({ onChange, value, ref }) => (
                  <SelectDropdown
                    data-testid="catalog_code_parent_sel"
                    aria-label={` Select ${metadataOfSelectedCategory.parentCategory}`}
                    required="true"
                    onChange={(ev) => {
                      onChange(ev.target.value);
                    }}
                    options={[
                      { label: '- Select -', value: '' },
                      ...parentOptions,
                    ]}
                    value={value}
                    inputRef={ref}
                  />
                )}
              />
              {errors?.parentCode?.message && (
                <ErrorMessage>{errors.parentCode.message}</ErrorMessage>
              )}
            </div>
          </div>
        )}

        <div className="grid-row padding-bottom-2">
          <div className="grid-col-6">
            <h2 className="font-sans-md">Description</h2>
            <Textbox
              data-testid="catalog_description_code"
              name="description"
              aria-label="Description"
              type="textarea"
              defaultValue={isNew ? '' : parentOptions?.description}
              error={errors?.description}
              inputRef={register}
            />
            {errors?.description?.message && (
              <ErrorMessage>{errors.description.message}</ErrorMessage>
            )}
          </div>
        </div>

        {hasTagOptions ? (
          <div className="grid-row padding-bottom-2">
            <div className="grid-col-4">
              <h2 className="font-sans-xs">Tags</h2>
              <Controller
                id="catalog_tags"
                name="tags"
                defaultValue={catalogTagDefaultValue}
                control={control}
                render={({ onChange, value = [] }) => {
                  return (
                    <MultiSelect
                      data-testid="catalog_tags_sel"
                      key="catalog_tags_sel"
                      name="tags"
                      defaultValue={catalogTagDefaultValue}
                      options={tagOptions}
                      value={value}
                      onChange={(val, checked) => {
                        let changedValue;
                        if (checked) {
                          changedValue = [...value, val];
                        } else {
                          changedValue = value.filter((el) => el !== val);
                        }
                        onChange(changedValue);
                      }}
                      selectedValues={value}
                    />
                  );
                }}
              />
              {errors?.tags?.message && (
                <ErrorMessage>{errors.tags.message}</ErrorMessage>
              )}
            </div>
          </div>
        ) : null}

        {metadataOfSelectedCategory?.category === 'Vehicle Group' && (
          <>
            {ability?.can(OPERATIONS.Manage, SUBJECTS.VehicleGroupEngPOCs) && (
              <>
                <ContactForm
                  contactType="PocSeniorEngineer"
                  title="Senior Engineer"
                  required
                />
                <ContactForm
                  contactType="PocPrimaryEngineer"
                  title="Primary Engineer"
                  required
                />
                <ContactForm
                  contactType="PocSecondaryEngineer"
                  title="Secondary Engineer"
                  required={false}
                />
                <ContactForm
                  contactType="PocSeniorCO"
                  title="Senior Contracting Officer"
                  required
                />
                <ContactForm
                  contactType="PocPrimaryCO"
                  title="Primary Contracting Officer"
                  required
                />
                <ContactForm
                  contactType="PocSecondaryCO"
                  title="Secondary Contracting Officer"
                  required={false}
                />
              </>
            )}
          </>
        )}
      </form>
    </FormProvider>
  );
};

CatalogCodeForm.defaultProps = {
  selectedCatalogCode: {},
  metadataOfSelectedCategory: {},
  equipmentCategorySchema: {},
  fuelTypeCategorySchema: {},
  catalogOptionsForTagsAndParentCodes: {},
};

CatalogCodeForm.propTypes = {
  formId: PropTypes.string.isRequired,
  selectedCatalogCode: PropTypes.shape(Object),
  metadataOfSelectedCategory: PropTypes.shape(Object),
  catalogOptionsForTagsAndParentCodes: PropTypes.shape(Object),
  isNew: PropTypes.bool.isRequired,
  equipmentCategorySchema: PropTypes.shape(Object),
  fuelTypeCategorySchema: PropTypes.shape(Object),
  onSubmit: PropTypes.func.isRequired,
  ability: PropTypes.shape(Object).isRequired,
};

export default CatalogCodeForm;
