import { gql } from '@apollo/client';

export const GET_BID_FOR_EVALUATION = gql`
  query getBidForEvaluation($bidId: Float!) {
    getBidForEvaluation(bidId: $bidId) {
      id
      solicitationId
      solicitationPeriodId
      soliciation {
        solicitationNumber
        contractYear
        solicitationPeriods {
          id
          startDate
          endDate
        }
      }
      vendorDetail {
        vendorName
        uniqueEntityIdentifier
      }
      BidLines {
        id
        bidId
        scheduleLine
        standardItem {
          id
          standardItemNumber
          title
          vehicleTypeCode {
            title
          }
        }
        afpMake {
          makeName
        }
        afpModel {
          modelName
        }
        chassisMake {
          makeName
        }
        chassisModel {
          modelName
        }
        evaluation {
          id
          techEvalCompleted
          techEvalAward
          priceEvalCompleted
          priceEvalAward
          awarded
        }
      }
    }
  }
`;

export const AUTO_SAVE_EVALUATION = gql`
  mutation autoSaveBidLineEvaluation($input: BidLineEvaluationInput!) {
    autoSaveBidLineEvaluation(input: $input)
  }
`;

export const AWARD_EVALUATIONS = gql`
  mutation awardBidLineEvaluations($evaluationIds: [Float!]!) {
    awardBidLineEvaluations(evaluationIds: $evaluationIds)
  }
`;

export const GET_SOLICITIATION_BY_ID = gql`
  query GetSolicitationById($id: Float!) {
    getSolicitationById(id: $id) {
      id
      programs {
        id
        program
        solicitationLines {
          id
          standardItem {
            id
            standardItemNumber
            title
          }
        }
      }
    }
  }
`;

export const REASSIGN_BID_LINE = gql`
  mutation reassignBidLine($input: ReassignBidLineInput!) {
    reassignBidLine(input: $input)
  }
`;
