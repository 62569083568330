import React from 'react';
import PropTypes from 'prop-types';
import { useModal, Button } from '@gsa/afp-component-library';
import ImportResultsModal from './import-results-modal';

const ImportResultsMessage = ({ results }) => {
  const { isOpen, openModal, closeModal } = useModal();
  const nImported = results.filter((res) => !res.errorMessage).length;
  const nFailed = results.filter((res) => res.errorMessage).length;
  return (
    <div>
      {nImported > 0 && `${nImported} line(s) have been appended/imported. `}
      {nFailed > 0 && `${nFailed} line(s) could not be imported. `}
      Click{' '}
      <span>
        <Button variant="unstyled" label="here" onClick={openModal} />
        <ImportResultsModal
          results={results}
          onClose={closeModal}
          isOpen={isOpen}
        />
      </span>{' '}
      to view details.
    </div>
  );
};

ImportResultsMessage.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      isNew: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
      errorMessage: PropTypes.string,
    }),
  ).isRequired,
};

export default ImportResultsMessage;
