import { useState } from 'react';
import useToggle from '../../../../../hooks/use-toggle';
import { MODAL_MODES } from '../../../constants';
import useSolicitation from '../../apis/solicitation-apis';

const useReopenPeriodsRowActions = () => {
  const [showEditReopenPeriodModal, toggleEditReopenPeriodModal] = useToggle();
  const [
    showDeleteReopenPeriodModal,
    toggleDeleteReopenPeriodModal,
  ] = useToggle();
  const [currentPeriodModalData, setCurrentPeriodModalData] = useState(null);

  const { setModalMode } = useSolicitation();

  const handleRowActionEdit = (rowData) => {
    setCurrentPeriodModalData(rowData);
    toggleEditReopenPeriodModal();
  };

  const handleRowActionDelete = (rowData) => {
    setCurrentPeriodModalData(rowData);
    setModalMode({
      mode: MODAL_MODES.REOPEN_PERIOD_CONFIRM_DELETE,
      showInModal: true,
    });
    toggleDeleteReopenPeriodModal();
  };

  return {
    showEditReopenPeriodModal,
    toggleEditReopenPeriodModal,
    showDeleteReopenPeriodModal,
    toggleDeleteReopenPeriodModal,
    currentPeriodModalData,
    setCurrentPeriodModalData,
    handleRowActionEdit,
    handleRowActionDelete,
  };
};
export default useReopenPeriodsRowActions;
