import React from 'react';
import SchedulingCommentingForm from '../../../../components/commenting-schedule/commenting-schedule-form';
import { useFederalStandards } from '../../fvs-provider';
import { useFvsLandingPageContext } from '../fvs-landing-page-provider';

const ScheduleCommentingContent = () => {
  const { vehicleStandard } = useFederalStandards();
  const { saveCommentingPeriod } = useFvsLandingPageContext();

  const getCommentingPeriod = () => {
    if (!vehicleStandard?.commentingPeriods) {
      return {};
    }

    const commentingPeriod = {
      publicStatus: 'Unscheduled',
      psoStatus: 'Unscheduled',
    };

    const publicPeriod = vehicleStandard?.commentingPeriods.find(
      (c) => c.userType === 'Public' && c.status !== 'Closed',
    );

    if (publicPeriod) {
      commentingPeriod.publicPeriodId = publicPeriod.id;
      commentingPeriod.publicPeriodStart = publicPeriod.periodStart;
      commentingPeriod.publicPeriodEnd = publicPeriod.periodEnd;
      commentingPeriod.publicStatus = publicPeriod.status;
    }

    const psoPeriod = vehicleStandard?.commentingPeriods.find(
      (c) => c.userType === 'VehicleSupplier' && c.status !== 'Closed',
    );

    if (psoPeriod) {
      commentingPeriod.psoPeriodId = psoPeriod.id;
      commentingPeriod.psoPeriodStart = psoPeriod.periodStart;
      commentingPeriod.psoPeriodEnd = psoPeriod.periodEnd;
      commentingPeriod.psoStatus = psoPeriod.status;
    }

    return commentingPeriod;
  };

  const handleSave = async (data) => {
    const dataToSave = [];
    if (data?.publicPeriodStart && data?.publicPeriodEnd) {
      dataToSave.push({
        fvsId: Number.parseInt(vehicleStandard.id, 10),
        periodId: data.publicPeriodId
          ? Number.parseInt(data.publicPeriodId, 10)
          : undefined,
        periodStart: data.publicPeriodStart,
        periodEnd: data.publicPeriodEnd,
        userType: 1,
      });
    }

    if (data?.psoPeriodStart && data?.psoPeriodEnd) {
      dataToSave.push({
        fvsId: Number.parseInt(vehicleStandard.id, 10),
        periodId: data.psoPeriodId
          ? Number.parseInt(data.psoPeriodId, 10)
          : undefined,
        periodStart: data.psoPeriodStart,
        periodEnd: data.psoPeriodEnd,
        userType: 0,
      });
    }

    if (dataToSave.length > 0) {
      await saveCommentingPeriod({
        variables: {
          input: dataToSave,
        },
      });
    }
  };

  return (
    <>
      <div className="padding-top-2">
        <SchedulingCommentingForm
          commentingPeriod={getCommentingPeriod()}
          handleSave={handleSave}
        />
      </div>
    </>
  );
};

ScheduleCommentingContent.propTypes = {};

ScheduleCommentingContent.defaultProps = {};

export default ScheduleCommentingContent;
