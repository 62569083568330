import { FilterTableFrame } from '@gsa/afp-component-library';
import LineItemsSubmitAddButtons from './line-items-submit-add-buttons';
import LineItemsFilter from './line-items-filters';
import LineItemsTable from './line-items-table';

export default FilterTableFrame(
  null,
  LineItemsSubmitAddButtons,
  LineItemsFilter,
  LineItemsTable,
);
