import React from 'react';
import PropTypes from 'prop-types';
import AttachmentModal from '../../../../components/Attachment/AttachmentModal';
import AddDocForm from './add-doc-form';
import './modal.css';

const AddDocModal = ({ bidLineId, onClose, onSuccess, docType }) => {
  return (
    <AttachmentModal
      id="attachment-add-modal"
      show
      onClose={onClose}
      className="bidline-doc-attachment-modal"
    >
      <AddDocForm
        bidLineId={bidLineId}
        onSuccess={onSuccess}
        onClose={onClose}
        docType={docType}
      />
    </AttachmentModal>
  );
};

AddDocModal.propTypes = {
  bidLineId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  docType: PropTypes.oneOf(['D', 'I']).isRequired,
};

export default AddDocModal;
