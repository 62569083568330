import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Typeahead } from '@gsa/afp-component-library';
import { GET_OPTIONS } from '../../../services/data-store';
import { useEquipmentDetailCodes } from '../equipment-code-detail-provider';

let userTyped = false;

const StandardItemTypeaheadInput = ({ onChange, value }) => {
  const {
    selectedEquipmentCode: { year },
    associationList: { rows },
  } = useEquipmentDetailCodes();
  const standardAssociationIds = rows.map(
    ({ standardItemId }) => standardItemId,
  );

  const [option, setOption] = useState({ values: [], raw: [] });

  const [getOptions] = useLazyQuery(GET_OPTIONS, {
    onCompleted: (data) => {
      let result = { values: [], raw: [] };
      if (data?.getOptions) {
        result = {
          values: data.getOptions.map((ec) => `${ec.value} - ${ec.label}`),
          raw: data.getOptions,
        };
      }
      setOption(result);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    },
  });

  StandardItemTypeaheadInput.defaultProps = {
    value: '',
  };
  StandardItemTypeaheadInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  return (
    <div className="margin-top-1">
      <Typeahead
        data-testid="standard-item-field"
        name="standard item"
        required="true"
        filterValue={value}
        typeaheadValues={option?.values}
        onFilterKeyDown={() => {
          userTyped = true;
        }}
        onOptionEnter={(selected) => {
          const selectedData = option?.raw.find(
            (ec) => `${ec.value} - ${ec.label}` === selected,
          );
          onChange({ selected, selectedData });
        }}
        fetchTypeaheadValues={(_, search) => {
          const keyword = search.trim();
          if (userTyped) {
            getOptions({
              variables: {
                model: 'StandardItem',
                label: 'title',
                value: 'standardItemNumber',
                filter: {
                  operator: 'AND',
                  value: [
                    {
                      operator: 'EQ',
                      key: 'year',
                      value: year,
                    },
                    {
                      operator: 'NOTIN',
                      key: 'id',
                      value: standardAssociationIds,
                    },
                    {
                      operator: 'OR',
                      value: [
                        {
                          operator: 'LIKE',
                          key: 'standardItemNumber',
                          value: keyword,
                        },
                        {
                          operator: 'LIKE',
                          key: 'title',
                          value: keyword,
                        },
                      ],
                    },
                  ],
                },
              },
            });
            userTyped = false;
          }
        }}
        debounceDelay={500}
        inputCharNum={0}
        onClear={(selected) => {
          onChange({ selected, undefined });
        }}
      />
    </div>
  );
};

export default StandardItemTypeaheadInput;
