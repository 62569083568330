import React from 'react';
import PropTypes from 'prop-types';
import Fieldset from './fieldset';
import {
  isOpenSeasonAudienceType,
  transformedSolPeriodVendorList,
} from '../utils/solicitation-period-utils';
import { CUSTOM_SOL_PERIOD_AUDIENCE_DESCRIPTION } from '../constants';

const ReadOnlyAudienceContent = {
  audience: 'Audience',
  vendors: 'Vendors',
};

export const AnAudienceVendorPair = ({
  audience,
  solicitationPeriodVendors,
}) => {
  const audienceDescription =
    audience === CUSTOM_SOL_PERIOD_AUDIENCE_DESCRIPTION.Open
      ? 'Open Season'
      : CUSTOM_SOL_PERIOD_AUDIENCE_DESCRIPTION[audience];

  const vendorDesc =
    solicitationPeriodVendors
      ?.map?.(
        (eachSolPeriodVendor) => eachSolPeriodVendor?.vendorDetail?.vendorName,
      )
      .join(', ') || '';
  return (
    <section className="grid-col-12 grid-row">
      <Fieldset
        title={ReadOnlyAudienceContent.audience}
        description={audienceDescription}
        className="grid-col-5"
      />
      {audience !== CUSTOM_SOL_PERIOD_AUDIENCE_DESCRIPTION.Open && (
        <Fieldset
          title={ReadOnlyAudienceContent.vendors}
          description={vendorDesc}
          defaultDescription=""
          className="grid-col margin-left-1"
        />
      )}
    </section>
  );
};

AnAudienceVendorPair.defaultProps = {
  solicitationPeriodVendors: null,
};

export const ReadOnlyAudience = ({ solPeriod }) => {
  if (isOpenSeasonAudienceType(solPeriod))
    return (
      <AnAudienceVendorPair
        audience={CUSTOM_SOL_PERIOD_AUDIENCE_DESCRIPTION.Open}
      />
    );
  return (
    <>
      {transformedSolPeriodVendorList(solPeriod)?.map(
        ([audience, solicitationPeriodVendors]) => (
          <AnAudienceVendorPair
            audience={audience}
            solicitationPeriodVendors={solicitationPeriodVendors}
          />
        ),
      )}
    </>
  );
};

const VendorDetailShape = PropTypes.shape({
  vendorName: PropTypes.string,
});
const SolPeriodVendor = PropTypes.shape({
  audienceType: PropTypes.string,
  vendorDetail: VendorDetailShape,
});
const solPeriodShape = PropTypes.shape({
  audience: PropTypes.string,
  solicitationPeriodVendors: PropTypes.arrayOf(SolPeriodVendor),
});
ReadOnlyAudience.propTypes = {
  solPeriod: solPeriodShape.isRequired,
};
AnAudienceVendorPair.propTypes = {
  audience: PropTypes.string.isRequired,
  solicitationPeriodVendors: PropTypes.arrayOf(SolPeriodVendor),
};
