import React from 'react';
import { AccordionItem } from '@gsa/afp-component-library';

import LineMakeModelSection from '../line/make-model/line-make-model';
import NonSOPInfo from './non-sop-info';

const NonSopContractLineTab = () => {
  return (
    <div className="margin-top-2 ">
      <div className="title-m-bold margin-bottom-3">Contract line</div>
      <AccordionItem
        key="vehicle_info"
        id="vehicle_info"
        title="Basic information"
        expanded
        bordered
        content={
          <>
            <LineMakeModelSection />
            <NonSOPInfo />
          </>
        }
      />
    </div>
  );
};

export default NonSopContractLineTab;
