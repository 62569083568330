/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { useFvsPreBidContext } from '../fvs-pre-bid-selection-provider';
import DocumentationModalWrapper from '../pre-bid-document-review/documentation-list-wrapper';
import { OPERATIONS, SUBJECTS } from '../../../utilities/constants';

const PsoDashboard = ({
  year,
  docsCount,
  docsReviewed,
  standardItemsCount,
  standardItemsReviewed,
  reviewFvsId,
}) => {
  const history = useHistory();
  const ability = useAppAbility();

  const [canVendorStart, setCanVendorStart] = useState(true);
  const {
    setFvsPreBidContextData,
    getPreBidDocumentationList,
  } = useFvsPreBidContext();
  const onClick = () => {
    if (ability.can(OPERATIONS.Create, SUBJECTS.PSOQuestionnaire)) {
      getPreBidDocumentationList({
        variables: {
          contractYear: year,
        },
      });
    }
    setFvsPreBidContextData('SET_FORM_MODAL', true);
  };

  useEffect(() => {
    if (standardItemsCount > 0 || docsCount > 0) {
      setCanVendorStart(false);
    }
  }, [docsCount, standardItemsCount, year]);

  const getStarted = () => {
    history.push(`/catalog/vehicle-standards/pre-bid-selections/${year}`);
  };

  return (
    <>
      {!canVendorStart && (
        <div className="grid-row grid-gap flex-justify">
          <div className="desktop:grid-col-2 display-flex flex-align-center padding-3 bg-primary-lightest">
            <span className="body-bold margin-left-2" tabIndex="0">
              My Pre-Bid Overview
            </span>
          </div>
          <div className="desktop:grid-col-4 display-flex flex-align-center bg-blue-2 border-right border-primary-light">
            {reviewFvsId && (
              <>
                <Button
                  variant="unstyled"
                  className="bold text-primary margin-right-4 margin-left-2"
                  onClick={onClick}
                  data-testid="documentation-list-modal-nav-link"
                  label={
                    <span className="body-bold text-primary">
                      Documentation
                    </span>
                  }
                />
              </>
            )}
            {!reviewFvsId && (
              <span className="body-bold text-primary margin-right-4 margin-left-2">
                Documentation
              </span>
            )}
            <span className="title-l text-primary margin-right-2">
              {docsCount}
            </span>
            <span className="body-small text-base margin-right-2" tabIndex="0">
              Total
            </span>
            <span className="title-l text-primary margin-right-2">
              {docsReviewed}
            </span>
            <span className="body-small text-base margin-right-2" tabIndex="0">
              Needs review
            </span>
          </div>
          <div className="desktop:grid-col-6 display-flex flex-align-center bg-blue-2">
            <Link
              to={`/catalog/vehicle-standards/pre-bid-summary/${year}`}
              className="margin-left-2 margin-right-4"
            >
              <span className="body-bold text-primary">My Pre-Bid Summary</span>
            </Link>

            <span className="title-l text-primary margin-right-2">
              {standardItemsCount}
            </span>
            <span className="body-small text-base margin-right-1" tabIndex="0">
              Selections
            </span>
            <Button
              variant="unstyled"
              className="margin-right-2 body-small"
              onClick={() => {
                history.push(
                  `/catalog/vehicle-standards/pre-bid-selections/${year}`,
                );
              }}
              label="Edit"
            />
            <span className="title-l text-primary margin-right-2">
              {standardItemsReviewed}
            </span>
            <span className="body-small text-base margin-right-2" tabIndex="0">
              Needs review
            </span>
          </div>
          <DocumentationModalWrapper />
        </div>
      )}

      {canVendorStart && (
        <div className="grid-row grid-gap flex-justify padding-2 margin-top-2 bg-blue-5 flex-align-center">
          <div className="desktop:grid-auto">
            <Button
              variant="outline"
              onClick={() => getStarted()}
              label={`Get started for ${year}`}
              data-testid="get-startedfor-year-nav-link"
              leftIcon={{
                name: 'check',
              }}
            />
            <span className="flex-justify-start float-none">
              <span className="title-s margin-left-4" tabIndex="0">
                Pre-bid work starts here.
              </span>
              <span className="body" tabIndex="0">
                Your feedback today means a smoother bid process tomorrow.
              </span>
            </span>
          </div>
          <div className="desktop:grid-col-1" />
          <div className="desktop:grid-col-4" />
        </div>
      )}
    </>
  );
};

PsoDashboard.defaultProps = {
  docsCount: 0,
  docsReviewed: 0,
  standardItemsCount: 0,
  standardItemsReviewed: 0,
  reviewFvsId: null,
};

PsoDashboard.propTypes = {
  year: PropTypes.number.isRequired,
  docsCount: PropTypes.number,
  docsReviewed: PropTypes.number,
  standardItemsCount: PropTypes.number,
  standardItemsReviewed: PropTypes.number,
  reviewFvsId: PropTypes.string,
};

export default PsoDashboard;
