export const hasContractAwardedForNewSolicitationVersion = (aSolicitation) => {
  const { contractYear, contracts } = aSolicitation || {};
  return !!contracts?.some?.(
    (aContract) => aContract.contractYear > contractYear,
  );
};

export const hasContractAwardedForSolicitation = (aSolicitation) => {
  const { contractYear, contracts } = aSolicitation || {};
  return !!contracts?.some?.(
    (aContract) => aContract.contractYear === contractYear,
  );
};
