import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import { useSetRecoilState } from 'recoil';
import { lineItemsAtom } from '../../bids/atoms/bids-atoms';
import { getUniqueFilterElements } from '../si-filter-table-frame/standard-items-filters';

const LineItemsFilter = ({ filterProps }) => {
  const { siData = [], allLineItems = [] } = filterProps || {};
  const setItems = useSetRecoilState(lineItemsAtom);
  const [typeaheadData, setTypeaheadData] = useState(null);

  const programs = getUniqueFilterElements(siData, 'programCode');
  const vehicleGroups = getUniqueFilterElements(siData, 'vehicleGroup');
  const vehicleTypes = getUniqueFilterElements(siData, 'vehicleType');
  const standardItems = getUniqueFilterElements(siData, 'standardItemNumber');

  const initialFilterStructure = [
    {
      title: 'Program',
      key: 'programCode',
      id: 'filter-line-items-program',
      type: 'select',
      permanent: false,
      operator: '$exact',
      options: programs,
      expanded: true,
      value: '',
    },
    {
      title: 'Vehicle Group',
      key: 'vehicleGroup',
      id: 'filter-si-vehicle-group',
      type: 'select',
      permanent: false,
      operator: '$exact',
      options: vehicleGroups,
      expanded: true,
      value: '',
    },
    {
      title: 'Vehicle Type',
      key: 'vehicleType',
      id: 'filter-si-vehicle-type',
      type: 'select',
      permanent: false,
      operator: '$exact',
      options: vehicleTypes,
      expanded: true,
      value: '',
    },
    {
      key: 'standardItemNumber',
      title: 'Standard Item',
      type: 'typeahead',
      value: '',
      operator: 'EQ',
      expanded: true,
      hideClear: true,
      placeholder: 'Search Standard Items',
      customFieldProps: { inputCharNum: 1 },
    },
    {
      title: 'Submission status',
      key: 'submissionStatus',
      type: 'multiselect',
      expanded: true,
      value: [],
      options: [
        { value: 'Not started', label: 'Not started' },
        { value: 'In progress', label: 'In progress' },
        { value: 'Ready to submit', label: 'Ready to submit' },
        { value: 'Completed', label: 'Submitted' },
      ],
      permanent: false,
      operator: '$in',
      hideClear: true,
    },
  ];

  const handleFiltersChange = (updatedFilters) => {
    if (updatedFilters?.length === 0) {
      setItems(allLineItems);
      return;
    }

    const filters = {};

    const filterByKeys = updatedFilters?.reduce(
      (filterObj, currentFilterObj) => {
        return { ...filterObj, [currentFilterObj?.key]: currentFilterObj };
      },
      {},
    );

    if (filterByKeys?.programCode) {
      filters.programCode = filterByKeys?.programCode?.value;
    }
    if (filterByKeys?.vehicleGroup) {
      filters.vehicleGroup = filterByKeys?.vehicleGroup?.value;
    }
    if (filterByKeys?.vehicleType) {
      filters.vehicleType = filterByKeys?.vehicleType?.value;
    }
    if (filterByKeys?.standardItemNumber) {
      filters.standardItemNumber = filterByKeys?.standardItemNumber?.value;
    }
    if (filterByKeys?.submissionStatus) {
      filters.submissionStatus = filterByKeys?.submissionStatus?.value;
    }

    const filteredBidLineData = allLineItems.filter((item) => {
      return Object.keys(filters).every((key) => {
        if (key === 'programCode') {
          return (
            item?.solicitationLine?.solicitationProgram?.programInfo?.code ===
            filters[key]
          );
        }
        if (key === 'vehicleGroup') {
          return (
            item?.solicitationLine?.standardItem?.vehicleGroup?.title ===
            filters[key]
          );
        }
        if (key === 'vehicleType') {
          return (
            item?.solicitationLine?.standardItem?.vehicleTypeCode?.title ===
            filters[key]
          );
        }
        if (key === 'standardItemNumber') {
          return (
            item?.solicitationLine?.standardItem?.standardItemNumber ===
            filters[key]
          );
        }
        if (key === 'submissionStatus') {
          return filters[key].includes(item?.status);
        }
        return true;
      });
    });

    if (Object.keys(filterByKeys).length !== 0) {
      setItems(filteredBidLineData);
    }
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const getStandardItemsAsync = async (filterObj) => {
    const lowerCaseFilterValue = filterObj.value.toLowerCase();
    const stdItems = [
      ...new Set(
        standardItems
          .filter((item) =>
            item.value.toLowerCase().includes(lowerCaseFilterValue),
          )
          .map((item) => item.value),
      ),
    ];

    await delay(50);
    return stdItems;
  };

  const handleFetchTypeaheads = ({ variables: queryVariables }) => {
    const selectedKey = queryVariables?.field;
    const filterObj = queryVariables?.filters[0]?.conditions?.find(
      (filter) => filter.key === selectedKey,
    );

    getStandardItemsAsync(filterObj).then((result) => {
      setTypeaheadData({ field: 'standardItemNumber', values: result });
    });
  };

  return (
    <FilterPanel.FilterPanel
      filterStructure={initialFilterStructure}
      clearButtonLabel="Reset All"
      showClearIcon
      setQueryFiltersState={handleFiltersChange}
      model="Task"
      order={[['id', 'ASC']]}
      fetchTypeaheads={handleFetchTypeaheads}
      subTitle="Apply filters"
      titleSeparator
      showSearchIcon
      typeaheadData={typeaheadData}
      retainFilters={false}
    />
  );
};

LineItemsFilter.propTypes = {
  filterProps: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    allLineItems: PropTypes.arrayOf(PropTypes.object),
  }),
};

LineItemsFilter.defaultProps = {
  filterProps: {
    data: [],
    allLineItems: [],
  },
};

export default LineItemsFilter;
