import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useSetRecoilState } from 'recoil';
import SimpleModal from '../../../../../components/SimpleModal/SimpleModal';
import solicitationNotifications from '../../../utils/solicitation-notification-helpers';
import { DELETE_ADDITIONAL_PERIODS_BY_SOLICITATION_ID } from '../../../queries';
import { MessageType } from '../../../../../components/Toast/toast';

export const TEXT_CONTENT = {
  title: 'Dates overlap!',
  content:
    'Open period dates overlap with Additional period dates. Additional period dates will be cleared if you continue forward.',
  primaryButtonText: 'Yes, clear Additional periods',
  secondaryButtonText: 'Cancel',
};

const ClearReopenPeriodsModal = ({
  onProceedCallback,
  onClose,
  ...restProps
}) => {
  const { id } = useParams();
  const setSolicitationNotification = useSetRecoilState(
    solicitationNotifications,
  );
  const handleOnClose = () => {
    if (onClose) onClose();
  };
  const [deleteAdditionalPeriodsBySolicitationId] = useMutation(
    DELETE_ADDITIONAL_PERIODS_BY_SOLICITATION_ID,
    {
      variables: {
        id: Number(id),
      },
      onError: (requestError) => {
        setSolicitationNotification((prevNotifications) => [
          {
            id: 'DELETE_ADDITIONAL_PERIODS_BY_SOLICITATION_ID',
            message: requestError.message,
            type: MessageType.ERROR,
            closeable: false,
            showInModal: false,
          },
          ...(prevNotifications || []),
        ]);
        handleOnClose();
      },
      onCompleted: async () => {
        if (onProceedCallback) await onProceedCallback();
        handleOnClose();
      },
    },
  );

  return (
    <SimpleModal
      {...restProps}
      {...TEXT_CONTENT}
      onClose={onClose}
      onProceed={deleteAdditionalPeriodsBySolicitationId}
      isCloseOnProceed={false}
    />
  );
};

ClearReopenPeriodsModal.propTypes = {
  onProceedCallback: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ClearReopenPeriodsModal;
