import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Modal, Button } from '@gsa/afp-component-library';
import OverlaySpinner from '../../../../components/overlay-spinner';
import { useBidLineDetails } from '../provider/bid-line-details-provider';
import { REMOVE_BID_LINE_COLOR } from '../provider/queries';

const DeleteColorModal = ({ color: { id, vendorColorName }, onClose }) => {
  const { setColors, setAlert } = useBidLineDetails();

  const [deleteColor, { loading }] = useMutation(REMOVE_BID_LINE_COLOR, {
    variables: { id },
    onError: (error) => {
      setAlert({
        type: 'error',
        message: (
          <>
            Unable to delete color <b>{vendorColorName}</b>: ${error.message}
          </>
        ),
      });
      onClose();
    },
    onCompleted: () => {
      setColors((prevColors) => prevColors.filter((c) => c.id !== id));
      setAlert({
        type: 'success',
        message: (
          <>
            Color <b>{vendorColorName}</b> deleted successfully
          </>
        ),
      });
      onClose();
    },
  });

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          id="remove-standard-item-modal"
          variant="medium"
          title={<h3>Delete confirmation</h3>}
          onClose={() => onClose()}
          actions={
            <div>
              <Button
                data-testid="bid-si-removal-modal-cancel-btn"
                variant="unstyled"
                onClick={() => onClose()}
                label="Cancel"
              />
              <Button
                variant="secondary"
                onClick={deleteColor}
                label="Delete"
              />
            </div>
          }
        >
          {loading && <OverlaySpinner />}
          <div className="margin-top-2 margin-bottom-8">
            Are you sure you want to delete <b>color {vendorColorName}</b>?
          </div>
        </Modal>
      </div>
    </div>
  );
};

DeleteColorModal.propTypes = {
  color: PropTypes.shape(
    PropTypes.objectOf({
      id: PropTypes.number.isRequired,
      vendorColorName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeleteColorModal;
