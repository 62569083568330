import React from 'react';
import { Button } from '@gsa/afp-component-library';
import Modal from '../../../components/Modal/Modal';
import PsoReviewDocumentList from './components/documentation-review-list';
import { useFvsPreBidContext } from '../fvs-pre-bid-selection-provider';

const ModalTitle = () => {
  const title = 'Documentation List';
  return (
    <div className="margin-top-5 display-flex margin-x-auto">
      {/* eslint-disable-next-line */}
      <h3 aria-label={title} tabIndex="0">
        {title}
      </h3>
    </div>
  );
};

const ModalContent = () => {
  // AFPTable to show documentation review list
  return <PsoReviewDocumentList />;
};

const ModalAction = () => {
  const { setFvsPreBidContextData } = useFvsPreBidContext();

  const onCancel = () => {
    setFvsPreBidContextData('SET_FORM_MODAL', false);
  };
  return (
    <>
      <Button
        variant="unstyled"
        className="margin-right-2"
        onClick={onCancel}
        data-testid="documentation-list-modal-cancel-btn"
        label="Cancel"
      />
    </>
  );
};

const DocumentationModalWrapper = () => {
  const { showFormModal, setFvsPreBidContextData } = useFvsPreBidContext();
  return (
    <Modal
      title={<ModalTitle />}
      actions={<ModalAction />}
      show={showFormModal}
      onClose={() => {
        setFvsPreBidContextData('SET_FORM_MODAL', false);
      }}
    >
      <ModalContent />
    </Modal>
  );
};

export default DocumentationModalWrapper;
