import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import {
  ButtonDropdown,
  ButtonDropdownItem,
  FlexView,
} from '@gsa/afp-component-library';

const LineHistoryActions = () => {
  const { contractHeaderId, contractLineId } = useParams();
  const history = useHistory();
  const actionsPanelLinks = () => [
    {
      title: 'View contract header',
      onClickHandler: () => {
        history.push(`/catalog/contract/contract-header/${contractHeaderId}`);
      },
      customIcon: true,
      icon: 'visibility',
    },
    {
      title: 'View contract line',
      customIcon: true,
      icon: 'visibility',
      onClickHandler: () => {
        history.push(
          `/catalog/contract/${contractHeaderId}/contract-line-template/${contractLineId}`,
        );
      },
    },
    {
      title: 'View contract line listing',
      customIcon: true,
      icon: 'visibility',
      onClickHandler: () => {
        history.push(
          `/catalog/contract/contract-header/${contractHeaderId}/lines`,
        );
      },
    },
  ];

  return (
    <FlexView hAlignContent="right">
      <ButtonDropdown
        className="margin-right-0 margin-bottom-1"
        label="Actions"
        variant="outline"
        iconSize={3}
        side="right"
      >
        {actionsPanelLinks().map((link) => (
          <React.Fragment key={link.title}>
            {link ? (
              <ButtonDropdownItem
                className="text-primary hover:bg-blue-5"
                label={link.title}
                iconName={link.icon}
                iconType={link.customIcon && 'custom'}
                iconFill="#005ea2"
                onClick={() => link.onClickHandler()}
              />
            ) : null}
          </React.Fragment>
        ))}
      </ButtonDropdown>
    </FlexView>
  );
};

export default LineHistoryActions;
