import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import { AFPTable, EmptyState, Pagination } from '@gsa/afp-component-library';

const ViewSolicitationStandardItems = ({ standardItems }) => {
  const [stdItems, setStdItems] = useState(standardItems);
  const [order, setOrder] = useState('title ASC');
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  const [data, setData] = useState({
    rows: [],
    hasMore: false,
    count: 0,
  });

  useEffect(() => {
    if (standardItems.length > 0) {
      setStdItems(standardItems);
    }
  }, [standardItems]);

  const tableRef = React.createRef();
  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    const count = stdItems.length;

    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });

    setData({
      rows: stdItems.slice(
        offset,
        offset + itemsPerPage <= count ? offset + itemsPerPage : count,
      ),
      hasMore: offset < count,
      count,
    });
  };

  /* eslint-disable react/prop-types */
  const columns = useMemo(
    () => [
      {
        Header: 'Standard Item',
        accessor: 'title',
        Cell: ({ value }) => {
          return value || <span>&ndash;</span>;
        },
      },
      {
        Header: 'Vehicle type',
        accessor: 'vehicleType',
        Cell: ({ value }) => {
          return value || <span>&ndash;</span>;
        },
      },
      {
        Header: 'Total quantity',
        accessor: 'estimatedQty',
      },
      {
        Header: 'Fleet quantity',
        accessor: 'estimatedFleetQty',
      },
    ],
    [],
  );

  useEffect(() => {
    const initData = {
      rows:
        stdItems.length > paginationState.limit
          ? stdItems.slice(0, paginationState.limit)
          : stdItems,
      hasMore: stdItems.length > paginationState.limit,
      count: stdItems.length,
    };
    setData(initData);
  }, [stdItems]);

  useEffect(() => {
    if (stdItems && order) {
      const sortField = order?.split(' ')[0].trim().slice(1, -1);
      const sortOrder = order?.split(' ')[1]?.toLowerCase();
      const sortedStdItems = orderBy([...stdItems], sortField, sortOrder);
      setStdItems(sortedStdItems);
      const sortInitData = {
        rows:
          sortedStdItems.length > paginationState.limit
            ? sortedStdItems.slice(0, paginationState.limit)
            : sortedStdItems,
        hasMore: sortedStdItems.length > paginationState.limit,
        count: sortedStdItems.length,
      };
      setData(sortInitData);
    }
  }, [order]);

  return (
    <div>
      <div className="view-solicitation-std-items-wrapper">
        <AFPTable
          columns={columns}
          data={data?.rows || []}
          data-testid="view-solicitation-std-items-table"
          onSort={setOrder}
          defaultSort={order}
          fullWidth
          ref={tableRef}
        />

        {stdItems?.length === 0 ? (
          <EmptyState
            hasBackground
            containerStyles="padding-y-5 margin-top-1 margin-top-neg-2"
            topText={
              <strong>
                No Standard Items have been associated to this solicitation
              </strong>
            }
          />
        ) : (
          <div className="padding-y-4 pagination">
            <Pagination
              data-testid="view-solicitation-std-items-pagination"
              itemsPerPageOptions={[10, 25, 50]}
              onPageChange={handlePaginationChange}
              variant="advanced"
              itemsPerPage={paginationState.limit}
              currentPage={paginationState.currentPage}
              itemsCount={stdItems.length}
            />
          </div>
        )}
      </div>
    </div>
  );
};

ViewSolicitationStandardItems.propTypes = {
  standardItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ViewSolicitationStandardItems;
