import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { FilterPanel } from '@gsa/afp-component-library';
import { bidLineAtom } from '../../bids/atoms/bids-atoms';

export const getUniqueFilterElements = (originalData, property) => {
  const uniqueElements = [
    ...new Set(originalData.map((item) => item[property])),
  ];
  const options = uniqueElements.map((element) => ({
    value: element,
    label: element,
  }));

  options.unshift({ value: '', label: '-Select-' });

  return options;
};

const StandardItemsFilter = ({ filterProps }) => {
  const [, setBidLine] = useRecoilState(bidLineAtom);
  const [typeaheadData, setTypeaheadData] = useState(null);

  const { data = [] } = filterProps || {};

  const programs = getUniqueFilterElements(data, 'programCode');
  const vehicleGroups = getUniqueFilterElements(data, 'vehicleGroup');
  const vehicleTypes = getUniqueFilterElements(data, 'vehicleType');
  const standardItems = getUniqueFilterElements(data, 'standardItemNumber');

  const initialFilterStructure = [
    {
      title: 'Program',
      key: 'programCode',
      id: 'filter-si-program',
      type: 'select',
      permanent: false,
      operator: '$exact',
      options: programs,
      expanded: true,
      value: '',
    },
    {
      title: 'Vehicle Group',
      key: 'vehicleGroup',
      id: 'filter-si-vehicle-group',
      type: 'select',
      permanent: false,
      operator: '$exact',
      options: vehicleGroups,
      expanded: true,
      value: '',
    },
    {
      title: 'Vehicle Type',
      key: 'vehicleType',
      id: 'filter-si-vehicle-type',
      type: 'select',
      permanent: false,
      operator: '$exact',
      options: vehicleTypes,
      expanded: true,
      value: '',
    },
    {
      key: 'standardItemNumber',
      title: 'Standard Item',
      type: 'typeahead',
      value: '',
      operator: 'EQ',
      expanded: true,
      hideClear: true,
      placeholder: 'Search Standard Items',
      customFieldProps: { inputCharNum: 1 },
    },
  ];

  const handleFiltersChange = (updatedFilters) => {
    if (updatedFilters?.length === 0) {
      setBidLine(data);
      return;
    }
    const filters = {};

    const filterByKeys = updatedFilters?.reduce(
      (filterObj, currentFilterObj) => {
        return { ...filterObj, [currentFilterObj?.key]: currentFilterObj };
      },
      {},
    );

    if (filterByKeys?.programCode) {
      filters.programCode = filterByKeys?.programCode?.value;
    }
    if (filterByKeys?.vehicleGroup) {
      filters.vehicleGroup = filterByKeys?.vehicleGroup?.value;
    }
    if (filterByKeys?.vehicleType) {
      filters.vehicleType = filterByKeys?.vehicleType?.value;
    }
    if (filterByKeys?.standardItemNumber) {
      filters.standardItemNumber = filterByKeys?.standardItemNumber?.value;
    }

    const filteredBidLineData = data.filter((item) => {
      return Object.keys(filters).every((key) => {
        if (key === 'programCode') {
          return item?.programCode === filters[key];
        }
        if (key === 'vehicleGroup') {
          return item?.vehicleGroup === filters[key];
        }
        if (key === 'vehicleType') {
          return item?.vehicleType === filters[key];
        }
        if (key === 'standardItemNumber') {
          return item?.standardItemNumber === filters[key];
        }
        return true;
      });
    });

    if (Object.keys(filterByKeys).length !== 0) {
      setBidLine(filteredBidLineData);
    }
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const getStandardItemsAsync = async (filterObj) => {
    const lowerCaseFilterValue = filterObj.value.toLowerCase();
    const stdItems = [
      ...new Set(
        standardItems
          .filter((item) =>
            item.value.toLowerCase().includes(lowerCaseFilterValue),
          )
          .map((item) => item.value),
      ),
    ];

    await delay(50);
    return stdItems;
  };

  const handleFetchTypeaheads = ({ variables: queryVariables }) => {
    const selectedKey = queryVariables?.field;
    const filterObj = queryVariables?.filters[0]?.conditions?.find(
      (filter) => filter.key === selectedKey,
    );

    getStandardItemsAsync(filterObj).then((result) => {
      setTypeaheadData({ field: 'standardItemNumber', values: result });
    });
  };

  return (
    <FilterPanel.FilterPanel
      filterStructure={initialFilterStructure}
      clearButtonLabel="Reset All"
      showClearIcon
      setQueryFiltersState={handleFiltersChange}
      model="BidLine"
      order={[['id', 'ASC']]}
      fetchTypeaheads={handleFetchTypeaheads}
      subTitle="Apply filters"
      titleSeparator
      showSearchIcon
      typeaheadData={typeaheadData}
      retainFilters={false}
    />
  );
};

const FilterPropType = PropTypes.shape({
  data: PropTypes.arrayOf(PropTypes.object),
});

StandardItemsFilter.defaultProps = {
  filterProps: {
    data: [],
  },
};

StandardItemsFilter.propTypes = {
  filterProps: FilterPropType,
};

export default StandardItemsFilter;
