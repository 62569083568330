const addOrReplaceMessageById = (messages, message) => {
  const existingMessages = [...(messages || [])];
  const splicedArray = existingMessages.filter((m) => m?.id !== message?.id);
  return [...splicedArray, message];
};

const removeMessageById = (messages, id) => {
  const existingMessages = [...(messages || [])];
  const splicedArray = existingMessages.filter((m) => m?.id !== id);
  return [...splicedArray];
};

export { addOrReplaceMessageById, removeMessageById };
