/* eslint-disable react/prop-types */
import React from 'react';
import { Tag, Button } from '@gsa/afp-component-library';
import moment from 'moment';
import { tagColors } from './helpers';
import './comment-admin.scss';
import { useCommentsAdmin } from './comments-admin-provider';
import { emDashUnicode } from '../../utilities/constants';

const CommentReply = ({ reply, canDelete }) => {
  const { setData } = useCommentsAdmin();

  return (
    <div className="margin-bottom-4">
      <div className="reply-main">
        <span className="text-bold padding-right-1">
          {reply.createdByUserInfo?.fullName || emDashUnicode}
        </span>{' '}
        <span className="padding-right-1">
          {' '}
          {moment(reply.createdAt).format('l')} &bull;{' '}
          {moment(reply.createdAt).format('h:mm a')}
        </span>
        {canDelete ? (
          <Button
            type="button"
            variant="unstyled"
            className="cursor-pointer display-flex flex-align-center"
            data-testid="delete-btn"
            onClick={() => {
              setData('SET_SELECTED_REPLY', reply);
              setData('SET_MODAL_MODE', 'delete_comment');
            }}
            label="Delete"
            leftIcon={{
              name: 'cancel',
              className: 'usa-icon--size-3 text-base',
            }}
          />
        ) : null}
      </div>
      <p className="margin-top-05">{reply.comment}</p>
      {reply.status !== 'New' ? (
        <span className="margin-top-05 margin-bottom-05">
          &ndash; Marked as{' '}
          <Tag variant={tagColors[reply.status]}>{reply.status}</Tag>
        </span>
      ) : null}
    </div>
  );
};

CommentReply.propTypes = {};

export default CommentReply;
