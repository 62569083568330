import React, { useCallback, useState } from 'react';
import { Spinner } from '@gsa/afp-component-library';
import { useQuery } from '@apollo/client';
import { GET_MAKE_MODEL_APPROVAL } from '../queries.gql';
import MakeModelApprovalTableFrame from './make-model-approval-table-frame';
import MakeModelApprovalDetailsRow from './make-model-approval-detail-row';
import { emDashUnicode } from '../../../utilities/constants';

export default () => {
  const itemsPerPageOptions = [50, 100, 150, 200];
  const initialPaginationState = {
    limit: itemsPerPageOptions[0],
    offset: 0,
    currentPage: 1,
    isReset: false,
  };
  const [paginationState, setPaginationState] = useState(
    initialPaginationState,
  );

  const [queryFiltersState, setQueryFiltersState] = useState();
  const [order, setOrder] = useState('bodyMake.makeName ASC');

  const tableRef = React.createRef();

  const { data, isLoading, refetch: refetchMakeModelApproval } = useQuery(
    GET_MAKE_MODEL_APPROVAL,
    {
      variables: {
        offset: (paginationState.currentPage - 1) * paginationState.limit,
        limit: paginationState.limit,
        order,
        ...(queryFiltersState ? { filters: queryFiltersState } : {}),
      },
    },
  );

  const { rows = [], count = 0 } = data?.getFilteredMakeModelApprovals || {};

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    const {
      fleetComment,
      makeCode,
      modelCode,
      chassisMakeCode,
      chassisModelCode,
      bodyMake,
      bodyModel,
      chassisMake,
      chassisModel,
    } = original;

    return (
      <MakeModelApprovalDetailsRow
        comment={fleetComment || emDashUnicode}
        makeCode={makeCode}
        modelCode={modelCode}
        chassisMakeCode={chassisMakeCode}
        chassisModelCode={chassisModelCode}
        nhtsaCodes={{
          nhtsaMakeCode: bodyMake?.nhtsaMakeCode,
          nhtsaModelCode: bodyModel?.nhtsaModelCode,
          chassisNhtsaMakeCode: chassisMake?.nhtsaMakeCode,
          chassisNhtsaModelCode: chassisModel?.nhtsaModelCode,
        }}
      />
    );
  }, []);

  const handleFilterChange = useCallback((updatedFilters) => {
    const filters = updatedFilters.map((filter) => {
      const statusMap = {
        Pending: 0,
        Approved: 1,
        Rejected: 2,
        GSAReview: 3,
        Archived: 4,
      };
      if (filter.key === 'approvalStatus') {
        return {
          ...filter,
          value: filter.value.map((v) => String(statusMap[v])),
        };
      }
      return filter;
    });
    setPaginationState({
      ...paginationState,
      currentPage: 1,
    });

    setQueryFiltersState({
      operator: '$and',
      conditions: filters,
    });
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  const onSort = (val) => {
    setOrder(val);
    refetchMakeModelApproval();
  };

  return (
    <MakeModelApprovalTableFrame
      filterToggle
      lowLeftProps={{
        handleFilterChange,
      }}
      lowRightProps={{
        tableProps: {
          expandable: true,
          ref: tableRef,
          data: rows,
          count,
          renderRowSubComponent,
          refetchMakeModelApproval,
          onSort,
          defaultSort: order,
        },
        paginationState,
        setPaginationState,
      }}
    />
  );
};
