import React from 'react';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import SolicitationNotification from '../utils/solicitation-notification';
import ViewSolicitationDetail from './view-solicitation-detail';

const ViewSolicitation = () => {
  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs.home}/home`,
      label: 'Home',
    },
    {
      location: '/catalog/solicitations',
      label: 'Solicitations',
    },
  ];

  return (
    <div className="grid-col">
      <Breadcrumbs current="View Solicitation" path={breadcrumbsPath} />
      <SolicitationNotification isModal={false} />
      <ViewSolicitationDetail />
    </div>
  );
};

export default ViewSolicitation;
