/*  eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { Modal, Spinner, Button, Alert } from '@gsa/afp-component-library';
import {
  REQUEST_NEW_MAKE_MODEL_APPROVAL,
  UPDATE_BID_LINE_ITEM,
  GET_ENGINEER_DETAILS,
} from '../../../bids/bids.gql';
import { bidsDashboardMessageAtom } from '../../../bids/atoms/bids-atoms';
import MakeAndModelSearchPanel from '../../si-filter-table-frame/make-and-model/make-and-model-select-panel';
import { useBidMakeAndModelContext } from '../../si-filter-table-frame/make-and-model/make-and-model-provider';
import { ENTRY_SOURCE, FLEET_APPROVAL_STATUS } from '../../constants';
import { emDashUnicode } from '../../../../utilities/constants';
import CanIChangeBidData from '../../../bids/components/protect-bid-data-crud';

const EditBidLineItemModal = ({ data, onClose }) => {
  const [, setToaster] = useRecoilState(bidsDashboardMessageAtom);
  const [alert, setAlert] = useState(null);

  const {
    bodyMake,
    bodyModel,
    chassisMake,
    chassisModel,
    bodyMakeInputValue,
    bodyModelInputValue,
    chassisMakeInputValue,
    chassisModelInputValue,
    modelYear,
    hasChassis,
    getErrors,
    // reset,
  } = useBidMakeAndModelContext();

  const error = getErrors();
  const modalSubtitle = `${data?.standardItem?.standardItemNumber} ${data?.standardItem?.title}`;
  const standardItemId = parseFloat(data?.standardItem?.id);

  // gql call to add bidLine (make/model)
  const [updateBidLineItem, { loading }] = useMutation(UPDATE_BID_LINE_ITEM, {
    variables: {
      id: parseInt(data.id, 10),
    },
    onCompleted: (result) => {
      setToaster({
        type: 'success',
        message: `The line item ${
          result?.scheduleLine || emDashUnicode
        } has been edited successfully.`,
      });
      onClose(result?.updateBidLineItem);
    },
    onError: (err) => {
      setAlert({
        type: 'error',
        message: err.message,
      });
    },
  });

  // gql call to get the engineer details
  const [
    getEngineerDetails,
    { loading: engineerDetailsLoading },
  ] = useLazyQuery(GET_ENGINEER_DETAILS, {
    onCompleted: () => {
      // Set the Alert
      setAlert({
        type: 'warning',
        message: `One or more Make and Model combinations selected are in Rejected status. Please reach out to the 
        GSA Engineering team ( Engineer Name - ${data?.getStandardsVehicleGroupPocsByStdItemId?.name}, 
        Email - ${data?.getStandardsVehicleGroupPocsByStdItemId?.email}) if the status needs review.`,
      });
    },
    onError: (err) => {
      setAlert({
        type: 'error',
        message: `Unable to get the engineer details: ${err.message}`,
      });
    },
  });

  // gql call to add a new (make/model) which is subjected to approval
  const [addNewMakeModel, { loading: newMakeModelAdding }] = useMutation(
    REQUEST_NEW_MAKE_MODEL_APPROVAL,
    {
      onCompleted: (makeModelApprovalData) => {
        let createBidLineInput = {
          solicitationLineId: parseFloat(data?.solicitationLine?.id),
          bidId: data?.bidId,
          standardItemId,
          standardItemNumber: data?.standardItem?.standardItemNumber,
          makeCode: parseFloat(
            makeModelApprovalData?.requestNewMakeModelApproval?.makeCode,
          ),
          modelCode:
            makeModelApprovalData?.requestNewMakeModelApproval?.modelCode,
          modelYear: parseFloat(modelYear),
        };
        if (hasChassis) {
          createBidLineInput = {
            ...createBidLineInput,
            chassisMakeCode: parseFloat(
              makeModelApprovalData?.requestNewMakeModelApproval
                ?.chassisMakeCode,
            ),
            chassisModelCode:
              makeModelApprovalData?.requestNewMakeModelApproval
                ?.chassisModelCode,
          };
        }
        if (
          makeModelApprovalData?.requestNewMakeModelApproval
            ?.fleetApprovalStatus === FLEET_APPROVAL_STATUS.Rejected
        ) {
          getEngineerDetails({
            variables: {
              standardItemId,
            },
          });
        } else {
          updateBidLineItem({
            variables: {
              bidLineId: parseFloat(data?.id),
              updateBidLineInput: createBidLineInput,
            },
          });
        }
      },
      onError: (err) => {
        setToaster({
          type: 'error',
          message: `Unable to update bid line item ${data?.id}: ${err.message}`,
        });
        onClose();
      },
    },
  );

  const onUpdateMakeModel = () => {
    // add approved make/model
    if (error?.length > 0) {
      setAlert({
        type: 'error',
        message: (
          <>
            <strong>{error[0].name}</strong> is a required field.
          </>
        ),
      });
    } else {
      setAlert(null);

      // if no chassis, the make and model is fleet Approved
      // create the bid line item directly without approval else send for approval
      const isBodyMakeApproved =
        bodyMake?.fleetApprovalStatus === FLEET_APPROVAL_STATUS.Approved;
      const isBodyModelApproved =
        bodyModel?.fleetApprovalStatus === FLEET_APPROVAL_STATUS.Approved;

      if (!hasChassis && isBodyMakeApproved && isBodyModelApproved) {
        const updateBidLineInput = {
          solicitationLineId: parseFloat(data?.solicitationLine?.id),
          bidId: data?.bidId,
          standardItemId,
          standardItemNumber: data?.standardItem?.standardItemNumber,
          makeCode: parseFloat(bodyMake?.value),
          modelCode: bodyModel?.value,
          modelYear: parseFloat(modelYear),
        };
        updateBidLineItem({
          variables: {
            bidLineId: parseFloat(data?.id),
            updateBidLineInput,
          },
        });
      } else {
        const makeModelApprovalRequestInput = {
          makeName: bodyMakeInputValue?.trim() || bodyMake?.label,
          modelName: bodyModelInputValue?.trim() || bodyModel?.label,
          ...(hasChassis && {
            chassisMakeName:
              chassisMakeInputValue?.trim() || chassisMake?.label,
          }),
          ...(hasChassis && {
            chassisModelName:
              chassisModelInputValue?.trim() || chassisModel?.label,
          }),
          entrySource: ENTRY_SOURCE.BID,
          standardItemInfo: [
            data?.standardItem?.id,
            data?.standardItem?.standardItemNumber,
          ],
        };
        addNewMakeModel({ variables: { makeModelApprovalRequestInput } });
      }
    }
  };

  const showAlert = () => {
    if (!alert) return null;
    const { message, ...restProps } = alert;
    return (
      <div className="margin-top-1 margin-bottom-1">
        <Alert
          slim
          showClose
          focused
          onClose={() => setAlert(null)}
          {...restProps}
        >
          {message}
        </Alert>
      </div>
    );
  };

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          id="remove-standard-item-modal"
          variant="extra-large"
          title={<h2>{`Edit line item  ${data?.scheduleLine || ''}`}</h2>}
          onClose={onClose}
          actions={
            <div>
              <Button
                data-testid="bid-line-removal-modal-cancel-btn"
                variant="unstyled"
                onClick={onClose}
                label="Cancel"
              />
              <CanIChangeBidData>
                <Button
                  variant="primary"
                  onClick={onUpdateMakeModel}
                  label="Save changes and close"
                />
              </CanIChangeBidData>
            </div>
          }
        >
          {loading ||
            newMakeModelAdding ||
            (engineerDetailsLoading && (
              <div
                role="none"
                className="afp-modal-overlay"
                data-testid="delete-bid-line-loading-spinner"
              >
                <Spinner
                  style={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translate(0, -50%)',
                  }}
                />
              </div>
            ))}
          <div
            className="text-body text-base-dark"
            aria-label={modalSubtitle}
            tabIndex="0"
          >
            <h4>{modalSubtitle}</h4>
          </div>
          {showAlert()}
          <MakeAndModelSearchPanel
            hasChassis={hasChassis}
            onAddMakeModel={onUpdateMakeModel}
            action="update"
          />
        </Modal>
      </div>
    </div>
  );
};

export const EditBidLineModalDataType = PropTypes.shape(
  PropTypes.objectOf({
    bidId: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    standardItem: PropTypes.objectOf({
      standardItemId: PropTypes.string.isRequired,
      standardItemNumber: PropTypes.string.isRequired,
      tags: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
    solicitationLine: PropTypes.objectOf({
      id: PropTypes.string.isRequired,
    }),
  }),
);

EditBidLineItemModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: EditBidLineModalDataType.isRequired,
};

export default EditBidLineItemModal;
