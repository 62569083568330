import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import {
  SelectDropdown,
  Spinner,
  PageTitle,
  Button,
  EmptyState,
} from '@gsa/afp-component-library';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Can } from '@gsa/afp-shared-ui-utils';
import queryString from 'query-string';
import useDidMountEffect from '../../../hooks/use-did-mount';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import { useFvsSupportingDocs } from '../fvs-supporting-docs-provider';
import { useFederalStandards } from '../fvs-provider';
import { OPERATIONS, SUBJECTS } from '../../../utilities/constants';
import DocReviewIndicatorForm from './doc-review-indicator-form';
import FvsMessages from '../components/fvs-messages';
import StandardComments, {
  CommentLinkedEntityTypes,
} from '../components/standard-comments';
import './styles/document-preview.scss';

const VehicleStandardsDocumentPreviewPage = () => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const { year, fvsId, docId } = params;

  const {
    setFvsSupportingDocsData,
    getSignedURL,
    signedUrl,
    gettingReadURL,
    supportingDocs,
    selectedDocument,
    getStandardsSupportingDocs,
    downloadFile,
  } = useFvsSupportingDocs();

  const {
    vehicleStandard,
    getVehicleStandards,
    removeFvsMessageById,
    clearAllMessages,
  } = useFederalStandards();

  const [pdfValue, setPdfValue] = useState({ value: '' });
  const [pdfOptions, setPdfOptions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { showCommentTab } = queryString.parse(location.search);

  useEffect(() => {
    clearAllMessages();
  }, []);

  useEffect(() => {
    return () => {
      // Clear generated messages before unload
      clearAllMessages();
    };
  }, []);

  useEffect(() => {
    if (showCommentTab) {
      setFvsSupportingDocsData('SET_SHOW_DOC_COMMENT_TAB', true);
      setSelectedIndex(1);
    }
  }, [showCommentTab]);

  const url = location.pathname.slice(0, location.pathname.lastIndexOf('/'));

  // changes url pdfId to selected pdfValue.value in dropdown
  useDidMountEffect(() => {
    if (selectedDocument && pdfValue?.value) {
      history.replace(`${url}/${pdfValue.value}`);
    }
  }, [pdfValue?.value]);

  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs.home}/home`,
      label: 'Home',
    },
    {
      location: `${window.AFP_CONFIG.appURLs.catalog}/catalog/vehicle-standards`,
      label: `Federal Vehicle Standards`,
    },
  ];

  useEffect(() => {
    if (!vehicleStandard?.id && params) {
      getVehicleStandards({
        variables: {
          filters: {
            operator: 'AND',
            value: [
              {
                operator: 'EQ',
                key: 'id',
                value: fvsId,
              },
            ],
          },
          limit: 1,
          offset: 0,
          order: 'fedStandardCode ASC',
        },
      });
    }
  }, [vehicleStandard, params]);

  useEffect(() => {
    if (vehicleStandard?.id) {
      getStandardsSupportingDocs({
        variables: {
          filters: {
            operator: 'AND',
            value: [
              {
                operator: 'EQ',
                key: 'fvsId',
                value: vehicleStandard.id,
              },
            ],
          },
          limit: 100,
          offset: 0,
          order: 'createdAt ASC',
        },
      });
    }
  }, [vehicleStandard, supportingDocs]);

  // maps options for dropdown
  useEffect(() => {
    if (supportingDocs) {
      const options = supportingDocs.rows?.map((option) => ({
        value: option.id,
        label: option.metadata.name,
      }));
      setPdfOptions(options);
    }
  }, [supportingDocs]);

  // sets the active doc based on URL params
  useEffect(() => {
    if (supportingDocs) {
      if (docId) {
        const activeDoc = supportingDocs.rows.find((doc) => doc.id === docId);
        setPdfValue({ value: activeDoc?.id });
      } else {
        const activeDoc = supportingDocs.rows[0];
        if (activeDoc) {
          setPdfValue({ value: activeDoc?.id });
          history.replace(
            `/catalog/vehicle-standards/preview/${year}/${fvsId}/${activeDoc?.id}`,
          );
        }
      }
    }
  }, [supportingDocs, docId]);

  // changes active doc based on selected dropdown option
  useEffect(() => {
    if (supportingDocs) {
      const selectedPdf = supportingDocs.rows.find(
        (doc) => doc.id === pdfValue.value,
      );
      setFvsSupportingDocsData('SET_SELECTED_DOC', selectedPdf);
    }
  }, [supportingDocs, pdfValue.value]);

  useEffect(() => {
    if (selectedDocument?.metadata?.scanStatus === 'Accepted' || selectedDocument?.metadata?.scanStatus === 'New') {
      getSignedURL({
        variables: {
          fileKey: selectedDocument?.metadata?.fileLocation,
          fileType: selectedDocument?.metadata?.fileMimeType,
        },
      });
    }
  }, [selectedDocument]);

  const onPdfChange = (ev) => {
    setPdfValue({ value: ev.target.value });
  };

  const download = (attachment) => {
    if (attachment?.metadata?.fileLocation) {
      downloadFile({
        variables: {
          fileKey: attachment?.metadata?.fileLocation,
          fileType: attachment?.metadata?.fileMimeType,
        },
      });
    }
  };

  if (!selectedDocument || !supportingDocs)
    return <Spinner size="medium" className="margin-top-8" />;

  return (
    <>
      <Breadcrumbs
        data-testId="doc-preview-crumbs"
        current="Documentation"
        path={breadcrumbsPath}
      />
      <FvsMessages onClose={removeFvsMessageById} />
      <div className="grid-container margin-bottom-15">
        <div className="display-flex flex-justify document-preview-mobile">
          <PageTitle
            className="font-sans-2xl margin-bottom-0"
            tabIndex="0"
            aria-label="Federal Standards Documentation"
            title="Federal Standards Documentation"
          />
          <SelectDropdown
            autoFocus
            label={<strong>Select a document to review</strong>}
            name="select document"
            className="document-preview-select"
            onChange={onPdfChange}
            value={pdfValue?.value}
            options={pdfOptions}
          />
        </div>
        <h2 className="text-normal margin-top-0 margin-bottom-4">
          {selectedDocument?.metadata?.name.split('.')[0]}
        </h2>

        <p className="text-primary text-bold text-uppercase margin-bottom-neg-1">
          Description
        </p>
        <p>{selectedDocument?.metadata?.description}</p>
        <Can I={OPERATIONS.Create} a={SUBJECTS.PSOQuestionnaire}>
          <div className="margin-top-2">
            <DocReviewIndicatorForm />
          </div>
        </Can>
        <br />
        <Tabs
          selectedIndex={selectedIndex}
          onSelect={(tabIndex) => setSelectedIndex(tabIndex)}
        >
          <TabList>
            <Tab data-testid="vehicle-standards-docs-tab">
              <span className="text-no-wrap">Document view</span>
            </Tab>
            <Tab data-testid="vehicle-standards-comments-tab">
              <span className="text-no-wrap">Comments</span>
            </Tab>
          </TabList>

          <TabPanel>
            <>
              <Button
                aria-label="download document"
                className="float-right margin-top-2 margin-bottom-6"
                onClick={() => download(selectedDocument)}
                label="Download"
                leftIcon={{
                  name: 'file_download',
                }}
              />
              {gettingReadURL ? (
                <Spinner size="medium" />
              ) : (
                <div style={{ height: '90vh' }}>
                  {selectedDocument?.metadata?.fileMimeType ===
                  'application/pdf' ? (
                    <object
                      data={signedUrl}
                      title={selectedDocument?.metadata?.name}
                      type="application/pdf"
                      width="100%"
                      height="100%"
                      aria-label={`PDF Document: ${selectedDocument?.metadata?.name}`}
                    />
                  ) : (
                    <EmptyState
                      hasBackground
                      containerStyles="padding-y-8 margin-top-1"
                      topText={
                        <strong>
                          Preview is not available for the file format
                        </strong>
                      }
                      topTextStyles="font-sans-md"
                    />
                  )}
                </div>
              )}
            </>
          </TabPanel>
          <TabPanel>
            <StandardComments
              linkedEntityType={CommentLinkedEntityTypes.Document}
            />
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

VehicleStandardsDocumentPreviewPage.propTypes = {};

export default VehicleStandardsDocumentPreviewPage;
