import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  AFPTable,
  EmptyState,
  Spinner,
  ErrorMessage,
} from '@gsa/afp-component-library';
import BidChangeNotificationTableSubRow from './change-notification-table-sub-row';
import { emDashUnicode } from '../../../utilities/constants';

const BidCommunicationCenterTable = ({ tableData, error, isDataLoading }) => {
  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Vehicle Type',
        accessor: 'vehicleType',
        sortable: false,
        Cell: ({ row }) =>
          `${row.original.standardItem.vehicleTypeCode.title}` || emDashUnicode,
      },
      {
        Header: 'Standard Item',
        accessor: 'standardItem.title',
        sortable: false,
        Cell: ({ row }) =>
          `${row.original.standardItem.standardItemNumber}-${row.original.standardItem.title}`,
      },
      {
        Header: 'Equipment Code',
        accessor: 'equipmentCode.title',
        sortable: false,
        Cell: ({ value }) => (value && value) || emDashUnicode,
      },
      {
        Header: 'Change Type',
        accessor: 'changeType',
        sortable: false,
      },
      {
        Header: 'Change Date',
        accessor: 'createdAt',
        Cell: ({ value }) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
      },
    ];
    return columnList;
  }, []);

  const renderRowSubComponent = useCallback(({ row }) => {
    return <BidChangeNotificationTableSubRow row={row} />;
  }, []);

  if (error)
    return (
      <div className="padding-left-6 padding-bottom-6">
        <ErrorMessage>Something went wrong</ErrorMessage>
      </div>
    );
  if (isDataLoading) return <Spinner />;

  return (
    <>
      <AFPTable
        testId="bid-notification-table-test-id"
        fullWidth
        columns={columns}
        data={tableData || []}
        expandable
        defaultSort="createdAt asc"
        renderRowSubComponent={renderRowSubComponent}
      />
      {tableData && !tableData?.length && (
        <div className="text-center margin-top-neg-2 height-full">
          <EmptyState
            hasBackground
            containerStyles="padding-top-9 height-full"
            topText={
              <p
                aria-label="There are no changes."
                // eslint-disable-next-line
                tabIndex={0}
              >
                <strong>There are no changes.</strong>
              </p>
            }
          />
        </div>
      )}
    </>
  );
};

BidCommunicationCenterTable.propTypes = {
  tableData: PropTypes.objectOf(PropTypes.any).isRequired,
  error: PropTypes.objectOf(PropTypes.any).isRequired,
  isDataLoading: PropTypes.bool.isRequired,
};

export default BidCommunicationCenterTable;
