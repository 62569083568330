/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import GenericMessagesToast from '../../../components/Toast/generic-messages-toast';
import { useFederalStandards } from '../fvs-provider';

const FvsMessages = ({ isModal }) => {
  const { fvsMessages, removeFvsMessageById } = useFederalStandards();
  useEffect(() => {
    if (fvsMessages?.length) window.scrollTo(0, 0);
  }, [fvsMessages]);
  return (
    <GenericMessagesToast
      onClose={removeFvsMessageById}
      isModal={isModal}
      allMessages={fvsMessages}
    />
  );
};

FvsMessages.defaultProps = {
  isModal: false,
};

FvsMessages.propTypes = {
  isModal: PropTypes.bool,
};

export default FvsMessages;
