/* eslint-disable react/prop-types */
import React from 'react';
import CommentReply from './comment-reply';
import './comment-admin.scss';

const CommentReplyList = ({ replies, currentUser, isPublished }) => {
  const canDelete = (user) => {
    return user === currentUser && !isPublished;
  };

  return (
    <>
      <div className="repliesWrapper padding-left-105">
        {replies.map((item) => {
          return (
            <CommentReply
              key={item.id}
              reply={item}
              canDelete={canDelete(item.createdByUser)}
            />
          );
        })}
      </div>
    </>
  );
};

CommentReplyList.propTypes = {};

export default CommentReplyList;
