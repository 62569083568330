import React from 'react';
import { MultiSelectDropdown, Spinner } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

const SolicitationProgramMultiselect = ({
  programDropdownOptions,
  selectedPrograms,
  handleProgramDropdownChange,
}) => {
  return selectedPrograms?.length >= 0 &&
    programDropdownOptions?.length >= 0 ? (
    // eslint-disable-next-line
    <MultiSelectDropdown
      name="Program select"
      options={programDropdownOptions}
      selectedValues={selectedPrograms}
      getLabel={(item) => item.label}
      getValue={(item) => item.value}
      onChange={(items) => handleProgramDropdownChange(items)}
      aria-label="Select programs"
    />
  ) : (
    <Spinner aria-label="Loading programs" role="status" />
  );
};

SolicitationProgramMultiselect.propTypes = {
  programDropdownOptions: PropTypes.oneOfType([PropTypes.object]).isRequired,
  selectedPrograms: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleProgramDropdownChange: PropTypes.func.isRequired,
};

export default SolicitationProgramMultiselect;
