import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  Modal,
  Button,
  DetailsTable,
  SelectDropdown,
} from '@gsa/afp-component-library';
import OverlaySpinner from '../../../components/overlay-spinner';
import { useBidLineEvaluation } from './bid-line-evaluation-provider';
import {
  GET_SOLICITIATION_BY_ID,
  REASSIGN_BID_LINE,
} from './bid-line-evaluation-gql';

const EMPTY_OPTION = { label: '-Select-', value: '' };
const ReassignBidLineModal = () => {
  const [siOptions, setSiOptions] = useState([EMPTY_OPTION]);
  const [selectedSiId, setSelectedSiId] = useState('');
  const [selectError, setSelectError] = useState(false);

  const {
    reassignRow,
    setReassignRow,
    removeReassignedLine,
    setAlert,
  } = useBidLineEvaluation();
  const closeModal = () => setReassignRow(null);
  const assignmentData = [
    ['Line item', reassignRow.scheduleLine],
    ['Solicitation number', reassignRow.solNumber],
    ['Vehicle supplier', reassignRow.vendorName],
    ['Standard Item', reassignRow.standardItem],
    ['Body make and model', reassignRow.body],
    ['Chassis make and model', reassignRow.chassis],
  ];

  const { loading } = useQuery(GET_SOLICITIATION_BY_ID, {
    variables: { id: reassignRow.solId },
    fetchPolicy: 'network-only',
    onCompleted: ({ getSolicitationById }) => {
      const opts = [];
      getSolicitationById.programs.forEach((prog) => {
        prog.solicitationLines.forEach((line) => {
          const { id, standardItemNumber, title } = line.standardItem;
          if (
            id !== reassignRow.standardItemId &&
            !opts.find((opt) => opt.value === id)
          )
            opts.push({ value: id, label: `${standardItemNumber} - ${title}` });
        });
      });
      setSiOptions([
        EMPTY_OPTION,
        ...opts.sort((a, b) => (a.label < b.label ? -1 : 1)),
      ]);
    },
    onError: closeModal,
  });

  const [reassignLine, { loading: reassigning }] = useMutation(
    REASSIGN_BID_LINE,
    {
      onCompleted: ({ reassignBidLine: { error, warning, toSin } }) => {
        if (error) {
          setAlert({
            type: 'error',
            message:
              error === 'MAKE_MODEL_EXIST'
                ? 'The make and model you are trying to reassign already exists for the selected standard item. The line item cannot be reassigned.'
                : `Error: ${error}`,
          });
        } else {
          const successMsg = `Line item ${reassignRow.scheduleLine} was successfully reassigned to Standard Item ${toSin}.`;
          const warningMsg =
            ' However, unable to copy data to the new line item.';
          setAlert({
            type: warning ? 'warning' : 'success',
            message: `${successMsg}${warning ? warningMsg : ''}`,
          });
          removeReassignedLine(reassignRow.bidLineId);
        }
        closeModal();
      },
      onError: closeModal,
    },
  );

  const onSelect = ({ target: { value } }) => {
    setSelectedSiId(value);
    setSelectError(!value);
  };

  const onReassign = () => {
    if (!selectedSiId) setSelectError(true);
    else {
      const input = {
        solicitationId: reassignRow.solId,
        fromBidLineId: reassignRow.bidLineId,
        fromSiId: +reassignRow.standardItemId,
        toSiId: +selectedSiId,
      };
      reassignLine({ variables: { input } });
    }
  };

  return (
    <div className="afp-modal-wrapper edit-conflicts-modal reassign-modal">
      <div className="afp-modal-overlay">
        <Modal
          id="copy-line-item-modal"
          variant="large"
          title={<h1>Reassign SIN</h1>}
          onClose={closeModal}
          actions={
            <div>
              <Button
                data-testid="cancel-reassign-btn"
                variant="unstyled"
                onClick={closeModal}
                label="Cancel"
              />
              <Button
                data-testid="reassign-bid-line-btn"
                variant="primary"
                onClick={onReassign}
                label="Reassign"
              />
            </div>
          }
        >
          {(loading || reassigning) && <OverlaySpinner />}

          <div className="margin-top-4 text-bold text-primary">
            CURRENT ASSIGNMENT
          </div>
          <DetailsTable data={assignmentData} />

          <div className="margin-top-4 text-bold text-primary">REASSIGN TO</div>
          <SelectDropdown
            data-testid="reassign-to-si-select"
            className="margin-bottom-8"
            label="Standard Item"
            required
            errorMessage={selectError ? 'Standard Item is required' : ''}
            value={selectedSiId}
            options={siOptions}
            onChange={onSelect}
          />
        </Modal>
      </div>
    </div>
  );
};

export default ReassignBidLineModal;
