import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import { useFederalStandards } from '../../../fvs-provider';

const {
  FilterPanelField,
  FilterPanelFilterShape,
  useFilterPanel,
} = FilterPanel;

const VehicleGroupFilterItem = ({ filter }) => {
  const { clearOneFilter } = useFilterPanel();

  const {
    vehicleClassificationOptions,
    selectedOptions,
  } = useFederalStandards();

  useEffect(() => {
    clearOneFilter(filter);
  }, [selectedOptions]);

  const vehicleGroupsPerFSC = vehicleClassificationOptions?.vehicleGroup?.filter(
    (vGroup) => vGroup?.parent === selectedOptions?.fedStandardCode,
  );
  vehicleGroupsPerFSC?.unshift({ label: 'Select', value: '' });

  return (
    <>
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'select',
          options: vehicleGroupsPerFSC
            ? vehicleGroupsPerFSC?.map((vGroup) => ({
                label: vGroup.label,
                value: vGroup.value,
              }))
            : [],
          value: (selectedOptions && selectedOptions?.vehicleGroup) || '',
        }}
      />
    </>
  );
};

VehicleGroupFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default VehicleGroupFilterItem;
