/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import ToastMessage from './toast';

const GenericMessagesToast = ({ allMessages, isModal, onClose }) => {
  const closeMessage = (id) => {
    onClose(id);
  };

  return (
    <>
      {!isModal &&
        allMessages &&
        allMessages.map((m, i) => {
          // show custom messages only when message id defined for custom errors, un-handled error messages won't have message id set
          // un-handled error messages are intercepted by data-store(onError) and displayed by global-error-messages component in Layout
          if (!m.showInModal && m.id) {
            return (
              <ToastMessage
                key={i}
                message={m.message}
                onClose={() => closeMessage(m.id)}
                type={m.type}
                closeable={m.closeable}
              />
            );
          }
          return null;
        })}

      {isModal &&
        allMessages &&
        allMessages.map((m, j) => {
          // show custom messages only when message id defined for custom errors, un-handled error messages won't have message id set
          // un-handled error messages are intercepted by data-store(onError) and displayed by global-error-messages component in Layout
          if (m.showInModal && m.id) {
            return (
              <ToastMessage
                key={j}
                message={m.message}
                onClose={() => closeMessage(m.id)}
                type={m.type}
                closeable={m.closeable}
              />
            );
          }
          return null;
        })}
    </>
  );
};

GenericMessagesToast.defaultProps = {
  isModal: false,
};

GenericMessagesToast.propTypes = {
  isModal: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  allMessages: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      closeable: PropTypes.bool,
      showInModal: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};

export default GenericMessagesToast;
