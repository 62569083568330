/**
 * Checks if the bid is closed based on the current date and the bid's closing date.
 *
 * @param {Date} bidCloseDate - The closing date of the bid.
 * @returns {boolean} - Returns true if the current date is past the bid's closing date, otherwise false.
 */
export const isBidClosed = (period) => {
  return (
    period?.closeInDays?.Days === 0 &&
    period?.closeInDays?.Hours === 0 &&
    period?.closeInDays?.Minutes === 0
  );
};

/**
 * Determines if the bid's closing date is today.
 *
 * @param {Date} bidCloseDate - The closing date of the bid.
 * @returns {boolean} - Returns true if the bid's closing date is the same as today's date, otherwise false.
 */
export const isBidClosedDateToday = (bidClosedDate) => {
  const today = new Date();
  const closedDateObj = new Date(bidClosedDate);

  return (
    today.getFullYear() === closedDateObj.getFullYear() &&
    today.getMonth() === closedDateObj.getMonth() &&
    today.getDate() === closedDateObj.getDate()
  );
};
