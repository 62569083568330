import {
  AFPTable,
  AFPTableRowAction,
  StatusBadge,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { OPERATIONS, SUBJECTS } from '../../../utilities/constants';
import { useCatalogCodes } from '../catalog-codes-provider';
import './change-request-history-table.scss';

const actionList = [
  {
    icon: 'check_circle_outline',
    label: 'Approve',
  },
  {
    icon: 'do_not_disturb',
    label: 'Reject',
  },
];

const ChangeRequestHistoryTable = () => {
  const {
    updateApprovalStatus,
    catalogCodesChangeRequestHistory,
    metadataOfSelectedCategory,
    selectedCatalogCode,
  } = useCatalogCodes();

  const ability = useAppAbility();

  const tableRef = React.createRef();
  const handleSelectedAction = useCallback((e, { original: { id } }) => {
    updateApprovalStatus({
      variables: {
        id: Number(id),
        approval: e === 'Approve' ? 1 : 2,
      },
    });
  }, []);

  const mapStatusToBadgeColor = (historyStatus) => {
    const status = historyStatus?.toLowerCase();

    if (!status) return '';
    if (status === 'approved') return 'Ready-Gray';
    if (status === 'pending') return 'Warning-Gray';
    if (status === 'rejected') return 'Inactive-Gray';
    return 'Inactive-Gray';
  };

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Change type',
        accessor: 'changeType',
        sortable: false,
      },
      {
        Header: 'Request Status',
        accessor: 'approvalStatus',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          return (
            <StatusBadge variant={mapStatusToBadgeColor(value)}>
              {value}
            </StatusBadge>
          );
        },
      },
      {
        Header: 'Requested by',
        accessor: 'requester',
        sortable: false,
        Cell: ({ value }) => {
          if (!value) return null;
          return value?.fullName;
        },
      },
      {
        Header: 'Requested at',
        accessor: 'createdAt',
        Cell: ({ value }) => {
          if (!value) return null;
          return `
          ${moment(value).format('L')} 
          ${moment(value).format('LTS')}`;
        },
        sortable: false,
      },
    ];

    if (
      ability.can(OPERATIONS.Approve, SUBJECTS.CatalogCodes) &&
      selectedCatalogCode.status !== 'Inactive'
    ) {
      columnList.push({
        Header: 'Actions',
        sortable: false,
        Cell: (props) => {
          // eslint-disable-next-line react/destructuring-assignment, react/prop-types
          if (props.row?.original?.approvedByUser) {
            return null;
          }
          // eslint-disable-next-line react/destructuring-assignment, react/prop-types
          if (
            // eslint-disable-next-line react/prop-types, react/destructuring-assignment
            props.row?.original?.standardsCode?.status === 'Draft' &&
            // eslint-disable-next-line react/destructuring-assignment, react/prop-types
            props.row?.original?.changeType !== 'Add'
          ) {
            return null;
          }
          return (
            // eslint-disable-next-line react/destructuring-assignment, react/prop-types
            <AFPTableRowAction
              actions={actionList}
              // eslint-disable-next-line react/destructuring-assignment, react/prop-types
              onSelectAction={(evt) => handleSelectedAction(evt, props.row)}
              {...props}
            />
          );
        },
      });
    }

    return columnList;
  }, []);

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    return (
      <div className="grid-container">
        <div className="grid-row grid-gap">
          <div className="tablet:grid-col">
            {original?.differences?.changes &&
              original?.differences?.changes.map((change) => {
                const changedField = change?.path[0];
                const fieldChanged = change?.path[1];

                const data = [];

                if (changedField) {
                  const curLabel =
                    changedField.charAt(0).toUpperCase() +
                    changedField.slice(1);

                  let showThisChange = false;
                  switch (changedField) {
                    case 'title':
                      showThisChange = true;
                      data.push({
                        prevValue: change.lhs ? change.lhs : <>&ndash;</>,
                        newValue: change.rhs,
                        label: curLabel,
                      });
                      break;
                    case 'description':
                      showThisChange = !!change.rhs;
                      data.push({
                        prevValue: change.lhs ? change.lhs : <>&ndash;</>,
                        newValue: change.rhs,
                        label: curLabel,
                      });
                      break;
                    case 'tags':
                      showThisChange = !!change.rhs;
                      if (fieldChanged) {
                        showThisChange = true;
                        data.push({
                          prevValue: change?.item?.lhs ? (
                            change.item.lhs
                          ) : (
                            <>&ndash;</>
                          ),
                          newValue: change?.item?.rhs,
                          label: curLabel,
                        });
                      } else if (showThisChange) {
                        data.push({
                          prevValue: change.lhs ? (
                            change.lhs?.value.join(',')
                          ) : (
                            <>&ndash;</>
                          ),
                          newValue: change.rhs?.value.join(','),
                          label: curLabel,
                        });
                      }
                      break;
                    case 'additionalProps':
                      showThisChange = !!change.rhs;
                      if (fieldChanged) {
                        let c =
                          fieldChanged.charAt(0).toUpperCase() +
                          fieldChanged.slice(1);
                        c = c.replace(/([a-z])([A-Z])/g, '$1 $2');
                        data.push({
                          prevValue: change.lhs ? change.lhs : <>&ndash;</>,
                          newValue: change.rhs,
                          label: c,
                        });
                      } else if (showThisChange) {
                        Object.keys(change.rhs).forEach((adProp) => {
                          let c =
                            adProp.charAt(0).toUpperCase() + adProp.slice(1);
                          c = c.replace(/([a-z])([A-Z])/g, '$1 $2');
                          data.push({
                            prevValue: change.lhs ? (
                              change.lhs[adProp]
                            ) : (
                              <>&ndash;</>
                            ),
                            newValue: change.rhs[adProp],
                            label: c,
                          });
                        });
                      }
                      break;
                    case 'vehicleGroupPOCs': {
                      const newPocs = change?.rhs;
                      const pocData = [];
                      Object.keys(newPocs).forEach((pocType) => {
                        pocData.push({
                          pocType,
                          name: `${newPocs[pocType].firstName} ${newPocs[pocType].lastName}`,
                          email: newPocs[pocType].email,
                          phoneNumber: newPocs[pocType].phoneNumber,
                        });
                      });
                      return (
                        <div className="vehicle-group-poc-change-table-wrapper">
                          New values of POC information
                          <AFPTable
                            fullWidth
                            testId="poc-new-values"
                            columns={[
                              {
                                Header: 'POC type',
                                accessor: 'pocType',
                                sortable: false,
                              },
                              {
                                Header: 'Name',
                                accessor: 'name',
                                sortable: false,
                              },
                              {
                                Header: 'Email',
                                accessor: 'email',
                                sortable: false,
                              },
                              {
                                Header: 'Phone number',
                                accessor: 'phoneNumber',
                                sortable: false,
                              },
                            ]}
                            data={pocData || []}
                          />
                        </div>
                      );
                    }
                    default:
                      break;
                  }

                  if (showThisChange) {
                    return (
                      <>
                        {data.map((d) => (
                          <div className="grid-row margin-bottom-4">
                            <div className="desktop:grid-col-6">
                              <div className="grid-row text-bold margin-bottom-1 desktop:margin-left-8">
                                {d.label}
                              </div>
                              <div className="grid-row margin-bottom-1 desktop:margin-left-8">
                                {d.prevValue}
                              </div>
                            </div>

                            <div className="desktop:grid-col-6">
                              <div className="grid-row text-bold margin-bottom-1 desktop:margin-left-8">
                                {`Proposed ${d.label}`}
                              </div>
                              <div className="grid-row margin-bottom-1 desktop:margin-left-8">
                                <span>{d.newValue}</span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    );
                  }
                  return null;
                }
                return null;
              })}

            {original?.approvedByUser &&
              metadataOfSelectedCategory?.requiresApproval && (
                <div className="grid-row margin-bottom-4">
                  <div className="desktop:grid-col-6">
                    <div className="grid-row text-bold margin-bottom-1 desktop:margin-left-8">
                      {original?.approvalStatus === 'Approved'
                        ? 'Approved by'
                        : 'Rejected by'}
                    </div>
                    <div className="grid-row margin-bottom-1 desktop:margin-left-8">
                      {original?.approver?.fullName}
                    </div>
                  </div>
                  <div className="desktop:grid-col-6">
                    <div className="grid-row text-bold margin-bottom-1 desktop:margin-left-8">
                      {original?.approvalStatus === 'Approved'
                        ? 'Approved at'
                        : 'Rejected at'}
                    </div>
                    <div className="grid-row margin-bottom-1 desktop:margin-left-8">
                      {`${moment(original?.approvedAt).format('L')} ${moment(
                        original?.approvedAt,
                      ).format('LTS')}`}
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }, []);

  return (
    <>
      <h2 data-testid="catalog-code-category" className="margin-top-6">
        Change history
      </h2>
      <div className="association-table-wrapper">
        <AFPTable
          expandable
          fullWidth
          testId="change-request-history-table"
          ref={tableRef}
          columns={columns}
          data={catalogCodesChangeRequestHistory}
          renderRowSubComponent={renderRowSubComponent}
          onSort={() => {}}
        />
      </div>
    </>
  );
};

export default ChangeRequestHistoryTable;
