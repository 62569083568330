import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { GET_VENDORS_BY_PERMISSION } from '../../../../../services/data-store';

export const vendorTypeAheadValue = (vendorName, uniqueEntityIdentifier) => {
  return [vendorName, uniqueEntityIdentifier].join(' - ');
};

const VendorSearchTypeahead = ({ onVendorSelection, errorMessage, label }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [, setError] = useState(null);
  const [getVendorsByPermission] = useLazyQuery(GET_VENDORS_BY_PERMISSION, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getVendorsByPermission: data }) => {
      setSuggestions(data);
    },
    onError: () => {
      setError('Not able to fetch vendor suggestions');
    },
  });

  const fetchValues = (_, value) => {
    getVendorsByPermission({
      variables: {
        operation: 'view',
        subject: 'Vendor',
        filters: {
          operator: '$or',
          conditions: [
            { operator: '$like', key: 'vendorName', value },
            { operator: '$like', key: 'uniqueEntityIdentifier', value },
          ],
        },
      },
    });
  };

  return (
    <Typeahead
      filterValue=""
      label={`Search for a bid by ${label}`}
      placeholder="Search"
      onOptionEnter={(value) => {
        const segs = value.split(' - ');
        const vendorName = segs.slice(0, segs.length - 1).join(' - ');
        if (onVendorSelection)
          onVendorSelection(
            suggestions.find((v) => v.vendorName === vendorName),
          );
      }}
      onClear={() => {
        if (onVendorSelection) onVendorSelection(null);
      }}
      errorMessage={errorMessage}
      typeaheadValues={suggestions.map((d) =>
        vendorTypeAheadValue(d.vendorName, d.uniqueEntityIdentifier),
      )}
      fetchTypeaheadValues={fetchValues}
      inputCharNum={3}
      debounceDelay={500}
      promptText="Search requires 3 characters"
    />
  );
};

VendorSearchTypeahead.propTypes = {
  onVendorSelection: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
};

VendorSearchTypeahead.defaultProps = {
  errorMessage: null,
  label: null,
};

export default VendorSearchTypeahead;
