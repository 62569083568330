import { atom } from 'recoil';

const bidsOpenSolicitationsAtom = atom({
  key: 'bids/openSolicitations',
  default: [],
});

const bidsActiveBidsAtom = atom({
  key: 'bids/activeBids',
  default: [],
});

const bidsMessagesAtom = atom({
  key: 'bids/bidsMessages',
  default: [],
});

// Data Type: {type: 'success' | 'warning' | 'error' | 'info'; message: string | ReactElement},
const bidsDashboardMessageAtom = atom({
  key: 'bids/dashboardMessages',
  default: null,
});

const bidSolicitationAtom = atom({
  key: 'bids/solicitation',
  default: [],
});

const bidLineAtom = atom({
  key: 'bids/bidLine',
  default: [],
});

const lineItemsAtom = atom({
  key: 'bids/lineItems',
  default: [],
});

const lineItemTableSelectedItems = atom({
  key: 'bids/lineItemTableSelectedItems',
  default: [],
});

const bidDashboardActiveTabAtom = atom({
  key: 'bids/bidDashboard/tab',
  default: 0, // tab index 0 - Standard Items tab
});

const bidDashboardAdmivDefaultViewAtom = atom({
  key: 'bids/bidDashboard/admin',
  default: null,
});

export {
  bidsOpenSolicitationsAtom,
  bidsActiveBidsAtom,
  bidsMessagesAtom,
  bidsDashboardMessageAtom,
  bidSolicitationAtom,
  bidLineAtom,
  lineItemsAtom,
  lineItemTableSelectedItems,
  bidDashboardActiveTabAtom,
  bidDashboardAdmivDefaultViewAtom,
};
