/* eslint-disable react/prop-types */
import {
  AFPTable,
  AFPTableRowAction,
  Button,
  Pagination,
  EmptyState,
  Spinner,
  StatusBadge,
} from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { UTCDateStrToUS } from '../../utilities/date-helpers';
import './attachment.scss';
import AttachmentDelete from './AttachmentDelete';
import AttachmentEdit from './AttachmentEdit';
import { useAttachmentComponent } from './ComponentContext';
import { useAttachments } from './AttachmentProvider';
import { OPERATIONS, SUBJECTS } from '../../utilities/constants';

export const NoData = () => {
  return (
    <div
      data-testid="attachment-no-data"
      className="attachment-no-data padding-bottom-1"
    >
      <EmptyState hasBackground containerStyles="padding-y-8 margin-top-1" />
    </div>
  );
};

NoData.defaultProps = {};

NoData.propTypes = {};

const actions = (row) => {
  const updateStatus =
    row.status === 'Draft'
      ? [
          {
            icon: 'check',
            label: 'Update status to final',
            operation: OPERATIONS.Update,
          },
        ]
      : [];

  return [
    ...updateStatus,
    {
      icon: 'edit',
      label: 'Edit Description',
      operation: OPERATIONS.Update,
    },
    {
      icon: 'edit',
      label: 'Manage effective date',
      operation: OPERATIONS.Update,
    },
    {
      icon: 'cancel',
      label: 'Delete',
      operation: OPERATIONS.Update,
    },
  ];
};

const AttachmentTable = ({ noData: NoDataComponent, publicView = false }) => {
  const {
    rows: attachments,
    count,
    loading,
    setDeleteState,
    onDownload,
    setEditState,
    updateDocumentStatus,
  } = useAttachmentComponent();

  const commonActions = [
    {
      icon: 'visibility',
      label: 'View',
      operation: OPERATIONS.View,
    },
    {
      icon: 'file_download',
      label: 'Download',
      operation: OPERATIONS.View,
    },
  ];

  const { refetch, setData } = useAttachments();
  const [order, setOrder] = useState('createdAt DESC');
  const [modalTitle, setModalTitle] = useState('');
  const tableRef = React.createRef();
  const history = useHistory();
  const ability = useAppAbility();

  const allowedActions = (original) => {
    const filteredActions = actions(original).filter((a) =>
      ability?.can(a.operation, SUBJECTS.FVS),
    );
    filteredActions.push(...commonActions);
    return filteredActions;
  };

  const onSelectAction = (evt, original) => {
    setData('SET_SELECTED_ROW', original);
    if (evt === 'View') {
      const params = original?.fileLocation.split('/');
      const year = params[1];
      const url = `/${
        publicView ? 'public/fvs' : 'catalog'
      }/vehicle-standards/preview/${year}/${original?.fvsId}/${original?.id}`;
      history.push(url, original);
    } else if (evt === 'Delete') {
      setDeleteState({ data: original, show: true });
    } else if (evt === 'Download') {
      onDownload(original);
    } else if (evt === 'Edit Description') {
      setEditState({ data: original, show: true, field: 'description' });
      setModalTitle('Edit description');
    } else if (evt === 'Manage effective date') {
      setEditState({ data: original, show: true, field: 'effectiveDate' });
      setModalTitle('Manage effective date');
    } else if (evt === 'Update status to final') {
      updateDocumentStatus({
        variables: {
          id: parseInt(original?.id || 0, 10),
          status: 1,
        },
      });
    } else if (evt === 'Refresh') {
      // TODO improve
      window.location.reload(true);
    }
  };

  const columns = useMemo(() => [
    {
      Header: 'Document name',
      accessor: 'name',
      Cell: (props) => {
        // eslint-disable-next-line react/prop-types
        const {
          row: { original },
          value,
        } = props;
        // eslint-disable-next-line react/prop-types
        const params = original?.fileLocation?.split('/');
        const year = params?.[1];
        const onClick = () => {
          const url = `/${
            publicView ? 'public/fvs' : 'catalog'
          }/vehicle-standards/preview/${year}/${original?.fvsId}/${
            original?.id
          }`;
          history.push(url, original);
        };
        return (
          // eslint-disable-next-line react/prop-types
          <Button
            data-testid="file-name-download-link"
            variant="unstyled"
            onClick={onClick}
            className="width-auto line-height-sans-4"
            label={value}
          />
        );
      },
      sortable: false,
    },
    {
      Header: 'Description',
      accessor: 'description',
      sortable: false,
    },
    {
      Header: 'Effective date',
      accessor: 'effectiveDate',
      Cell: ({ value }) => {
        return value ? UTCDateStrToUS(value) : '';
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => {
        return (
          <StatusBadge variant={value === 'Final' ? 'Ready-Gray' : 'Info-Gray'}>
            {value}
          </StatusBadge>
        );
      },
    },

    {
      Header: 'Actions',
      accessor: 'action',
      sortable: false,
      Cell: (props) => {
        // eslint-disable-next-line react/prop-types
        const {
          row: { original },
        } = props;
        return (
          <AFPTableRowAction
            {...props}
            actions={allowedActions(original)}
            // eslint-disable-next-line react/prop-types
            onSelectAction={(e) => onSelectAction(e, original)}
          />
        );
      },
    },
  ]);

  const data = React.useMemo(
    () =>
      attachments.map(({ __typename, ...attachment }) => {
        return attachment;
      }),
    [attachments],
  );

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    return (
      <div className="grid-container">
        <div className="grid-row grid-gap">
          <div className="tablet:grid-col">
            <div className="grid-row attachment-item-wrapper attachment-item-separator">
              <div className="tablet:grid-col attachment-item-title">
                Date uploaded
              </div>
              <div className="tablet:grid-col">
                {original?.createdAt ? (
                  UTCDateStrToUS(original?.createdAt)
                ) : (
                  <div className="text-bold">&ndash;</div>
                )}
              </div>
            </div>

            {ability?.can(OPERATIONS.Update, SUBJECTS.FVS) && (
              // TODO - the API shouldn't be returning this data
              <div className="grid-row attachment-item-wrapper attachment-item-separator">
                <div className="tablet:grid-col attachment-item-title">
                  Uploaded by
                </div>
                <div className="tablet:grid-col">
                  {original?.uploadedBy ? (
                    <div>{original?.uploadedBy?.fullName}</div>
                  ) : (
                    <div className="text-bold">&ndash;</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }, []);

  const [paginationState, setPaginationState] = useState({
    limit: 5,
    offset: 0,
    currentPage: 1,
  });

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  useEffect(() => {
    const { limit, offset } = paginationState;
    refetch({
      limit,
      offset,
      order,
    });
  }, [order, paginationState]);

  // Loading message.
  if (loading)
    return <Spinner data-testid="attachment-table-spinner" size="small" />;

  // No data message.
  if (!data.length) return <NoDataComponent />;

  return (
    <div className="attachment-table" data-testid="attachment-table">
      {/* Delete Confirmation Modal */}
      <AttachmentDelete />
      {/* Edit Modal */}
      <AttachmentEdit title={modalTitle} />
      <AFPTable
        ref={tableRef}
        expandable
        fullWidth
        columns={columns}
        data={data}
        renderRowSubComponent={renderRowSubComponent}
        onSort={setOrder}
        defaultSort={order}
      />
      <div className="padding-y-4 pagination">
        <Pagination
          itemsPerPageOptions={[5, 10, 25]}
          onPageChange={handlePaginationChange}
          variant="advanced"
          itemsPerPage={paginationState.limit}
          currentPage={paginationState.currentPage}
          itemsCount={count}
        />
      </div>
    </div>
  );
};

AttachmentTable.defaultProps = {
  noData: NoData,
};

AttachmentTable.propTypes = {
  noData: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
};

export default AttachmentTable;
