/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { AFPTable } from '@gsa/afp-component-library';
import './styles/solicitation-modal.scss';

const InactiveSITable = ({ data }) => {
  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Standard Item',
        accessor: 'standardItem',
      },
      {
        Header: 'Vehicle Type',
        accessor: 'vehicleType',
      },
    ];

    return columnList;
  }, []);

  return (
    <div className="overflow-y">
      <AFPTable columns={columns} data={data || []} />
    </div>
  );
};

export default InactiveSITable;
