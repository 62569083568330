import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Icon } from '@gsa/afp-component-library';
import DeleteCommentButton from './DeleteCommentButton';
import { CommentPropType } from '../../utilities/types';

const Comment = ({ comment, allowChanges }) => (
  <li key={comment.id} className="margin-bottom-4">
    <div className="display-flex flex-align-center">
      <Icon iconName="person" className="usa-icon--size-3 margin-right-1" />
      <span className="text-bold">
        {(comment.author || {}).fullName || comment.createdByUser}
      </span>
      <span className="margin-x-4">
        {moment(comment.createdAt).format('l')} &bull;{' '}
        {moment(comment.createdAt).format('h:mm a')}
      </span>
      <DeleteCommentButton comment={comment} allowChanges={allowChanges} />
    </div>
    <p>{comment.comment}</p>
  </li>
);

Comment.defaultProps = {
  allowChanges: true,
};

Comment.propTypes = {
  comment: PropTypes.shape(CommentPropType).isRequired,
  allowChanges: PropTypes.bool,
};

export default Comment;
