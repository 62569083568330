import { CounterTag } from '@gsa/afp-component-library';
import { useCurrentUser, useAppAbility } from '@gsa/afp-shared-ui-utils';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { OPERATIONS, SUBJECTS } from '../../../../utilities/constants';
import StandardComments, {
  CommentLinkedEntityTypes,
} from '../../components/standard-comments';
import { useFvsCommentsContext } from '../../fvs-comments-provider';
import { useFvsPreBidContext } from '../../fvs-pre-bid-selection-provider';
import { useFederalStandards } from '../../fvs-provider';
import IntentionToBid from './make-model/intention-to-bid';
import MakeAndModel from './make-model/make-model';
import RequirementsTable from './requirements-table';

const RequirementTabs = () => {
  const id = useCurrentUser()?.currentUser?.id;

  const ability = useAppAbility();

  const { commentList, setFvsCommentsContextData } = useFvsCommentsContext();
  const {
    addOrUpdateIntentionToBid,
    preBidSelectionForStandardItem,
    deleteMakeAndModelForSelection,
    getPreBidSelectionByStandardItemId,
  } = useFvsPreBidContext();

  const intentToBid = !!preBidSelectionForStandardItem?.intentToBid;

  const { standardItem } = useFederalStandards();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const location = useLocation();
  const { showCommentTab } = queryString.parse(location.search);

  useEffect(() => {
    if (showCommentTab) {
      setFvsCommentsContextData('SET_SHOW_COMMENT_TAB', showCommentTab);
      setSelectedIndex(2);
    }
  }, [showCommentTab]);

  useEffect(() => {
    if (ability?.can(OPERATIONS.Create, SUBJECTS.PSOQuestionnaire)) {
      getPreBidSelectionByStandardItemId({
        variables: {
          filters: {
            key: 'standardItemId',
            operator: 'EQ',
            value: Number.parseInt(standardItem?.id, 10),
          },
          order: 'id ASC',
        },
      });
    }
  }, [standardItem?.id]);

  const canViewComment = (comment) => {
    const isOwnComment = comment.createdByUser === id;
    const commentIsPublished = comment?.publishStatus === 'Published';
    return isOwnComment || commentIsPublished;
  };

  const ownCommentList = commentList?.filter((comment) =>
    canViewComment(comment),
  );

  return (
    <Tabs
      selectedIndex={selectedIndex}
      onSelect={(tabIndex) => setSelectedIndex(tabIndex)}
    >
      <TabList>
        <Tab data-testid="vehicle-standards-docs-tab">
          <span className="text-no-wrap">Requirements</span>
        </Tab>

        {ability?.can(OPERATIONS.Create, SUBJECTS.PSOQuestionnaire) ? (
          <Tab data-testid="make-and-model-tab">
            <span className="text-no-wrap">Make and Model</span>
          </Tab>
        ) : null}

        <Tab data-testid="vehicle-standards-comments-tab">
          <span className="text-no-wrap">
            Standard Item comments
            {ownCommentList && ownCommentList?.length > 0 ? (
              <CounterTag
                className="margin-left-1"
                count={ownCommentList.length}
              />
            ) : null}
          </span>
        </Tab>
      </TabList>
      <TabPanel>
        <RequirementsTable />
      </TabPanel>
      {ability?.can(OPERATIONS.Create, SUBJECTS.PSOQuestionnaire) && (
        <TabPanel>
          <IntentionToBid
            deleteMakeAndModelForSelection={deleteMakeAndModelForSelection}
            intentToBid={intentToBid}
            preBidSelectionId={preBidSelectionForStandardItem?.id}
            standardItemId={standardItem?.id}
            addOrUpdateIntentionToBid={addOrUpdateIntentionToBid}
          />
          {intentToBid && (
            <MakeAndModel
              setData={setFvsCommentsContextData}
              standardItemId={standardItem?.id}
              tags={standardItem?.tags}
            />
          )}
        </TabPanel>
      )}
      <TabPanel>
        <StandardComments
          linkedEntityType={CommentLinkedEntityTypes.StandardItem}
        />
      </TabPanel>
    </Tabs>
  );
};

export default RequirementTabs;
