import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import {
  Button,
  RequiredFieldIndicator,
  SelectDropdown,
  TextInput,
} from '@gsa/afp-component-library';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import OverlaySpinner from '../../../../../../components/overlay-spinner';
import { INPUT_DROPDOWN_DEFAULT_OPTION } from '../../../provider/helpers';
import { CREATE_BIDLINE_ASSO_LOC } from '../../../provider/queries';
import { useBidLineDetails } from '../../../provider/bid-line-details-provider';
import CanIChangeBidData from '../../../../../bids/components/protect-bid-data-crud';

const AddAssociatedLocationForm = () => {
  const {
    bidLine,
    setAlert,
    setAssociatedLocations,
    isVendorDataLoading,
    isVendorLocationsLoading,
    vendorLocations,
  } = useBidLineDetails();

  const assemblyPointOptions = [INPUT_DROPDOWN_DEFAULT_OPTION];
  const inspectionPointOptions = [INPUT_DROPDOWN_DEFAULT_OPTION];

  const groupedLocations = vendorLocations.reduce((groups, location) => {
    const { code } = location.commonCode;
    return { ...groups, [code]: [...(groups[code] || []), location] };
  }, {});

  const getPointOptions = (points) => {
    const options = [];

    points?.forEach((point) => {
      options.push({
        label: `${point?.country?.countryName} (${point.country?.isoCountryCode2}) ${point?.address1} ${point?.city} ${point?.state?.stateCode}, ${point?.postalCode}`,
        value: point.id,
      });
    });

    return options;
  };

  assemblyPointOptions.push(...getPointOptions(groupedLocations?.AsmPoint));
  inspectionPointOptions.push(...getPointOptions(groupedLocations?.InspPoint));

  const schema = yup.object().shape({
    assemblyPoint: yup.string().required('Final assembly point is required.'),
    inspectionPoint: yup.string().required('Inspection point is required.'),
    percentageOfDomContent: yup.mixed().when('$percentageRequired', {
      is: false,
      then: yup.string(),
      otherwise: yup
        .number()
        .transform((value) =>
          value === '' || Number.isNaN(value) ? undefined : value,
        )
        .required('Percentage of domestic content is required.')
        .min(0, 'Percentage must be between 0 and 100.')
        .max(100, 'Percentage must be between 0 and 100.'),
    }),
  });

  const { errors, control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      assemblyPoint: '',
      inspectionPoint: '',
      percentageOfDomContent: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const genLabel = (label, required) => (
    <div aria-label={label} className="text-bold" data-testid={label}>
      {label} {required && <RequiredFieldIndicator />}
    </div>
  );

  const [createBidLineAssociatedLocation] = useMutation(
    CREATE_BIDLINE_ASSO_LOC,
    {
      onError: (error) => {
        const errMessage = error.message.includes('.')
          ? error.message.split('.')[0]
          : error.message;
        setAlert({
          type: 'error',
          message: errMessage,
        });
      },
      onCompleted: ({
        createBidLineAssociatedLocation: bidLineClarification,
      }) => {
        reset();
        setAssociatedLocations((prev) => [...prev, bidLineClarification]);
        setAlert({
          type: 'success',
          message: (
            <>Final assembly point with inspection point is added to the bid.</>
          ),
        });
      },
    },
  );

  const onSubmit = (formData) => {
    setAlert(null);
    createBidLineAssociatedLocation({
      variables: {
        bidLineAssociatedLocationInput: {
          bidLineId: +bidLine.id,
          vendorAssemblyPointId: +formData.assemblyPoint,
          vendorInspectionPointId: +formData.inspectionPoint,
          percentageOfDomContent: +formData.percentageOfDomContent,
        },
      },
    });
  };

  const InputElementWrapper = ({ help, label, children }) => {
    return (
      <>
        <div>{genLabel(label, true)}</div>
        {help && <span>{help}</span>}
        {children}
      </>
    );
  };

  InputElementWrapper.propTypes = {
    help: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };

  return (
    <div className="bg-primary-lightest padding-bottom-6 padding-top-4">
      {(isVendorDataLoading || isVendorLocationsLoading) && <OverlaySpinner />}

      <form
        id="add-vendor-associated-loc-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="display-flex flex-row flex-justify flex-align-end margin-x-4">
          <div
            className={`grid-col-2 ${
              errors.assemblyPoint ? 'padding-left-2' : ''
            } width-mobile`}
          >
            <Controller
              name="assemblyPoint"
              control={control}
              render={({ name, value, onChange, onBlur }) => (
                <InputElementWrapper
                  help="Select address from your vendor company profile"
                  label="Final assembly point"
                >
                  <SelectDropdown
                    name={name}
                    id="assembly-point"
                    data-testid="assembly_point"
                    help="Test"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={errors.assemblyPoint?.message}
                    options={assemblyPointOptions}
                  />
                </InputElementWrapper>
              )}
            />
          </div>
          <div
            className={`grid-col-2 ${
              errors.inspectionPoint ? 'padding-left-2' : ''
            } width-mobile`}
          >
            <Controller
              name="inspectionPoint"
              control={control}
              render={({ name, value, onChange, onBlur }) => (
                <InputElementWrapper
                  help="Select address from your vendor company profile"
                  label="Inspection point"
                >
                  <SelectDropdown
                    name={name}
                    id="inspection-point"
                    data-testid="inspection_point"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={errors.inspectionPoint?.message}
                    options={inspectionPointOptions}
                  />
                </InputElementWrapper>
              )}
            />
          </div>
          <div
            className={`grid-col-2 ${
              errors.percentageOfDomContent ? 'padding-left-2' : ''
            } width-mobile`}
          >
            <Controller
              name="percentageOfDomContent"
              control={control}
              render={({ name, value, onChange, onBlur }) => (
                <InputElementWrapper
                  help="Enter the percentage of domestic content for this location"
                  label="Percentage of domestic content"
                >
                  <TextInput
                    name={name}
                    data-testid="percentage_of_dom_content"
                    type="number"
                    suffix="%"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={errors.percentageOfDomContent?.message}
                  />
                </InputElementWrapper>
              )}
            />
          </div>
          <div className="text-right">
            <CanIChangeBidData>
              <Button
                type="submit"
                variant="outline"
                label="Add"
                leftIcon={{ name: 'add' }}
                className="margin-left-2 margin-right-0"
              />
            </CanIChangeBidData>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddAssociatedLocationForm;
