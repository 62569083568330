import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  AFPTable,
  EmptyState,
  useModal,
} from '@gsa/afp-component-library';
import { useBidLineDetails } from '../provider/bid-line-details-provider';
import { DELETE_ACTION, getColorColumns } from '../provider/table-defs';
import AddColor from './add-color';
import DeleteColorModal from './delete-color-modal';
import { TAB_KEY } from '../copy-line-item/helpers';

const ColorsTab = ({ openCopyModal }) => {
  const [selectedColor, setSelectedColor] = useState(null);
  const { isOpen, openModal, closeModal } = useModal();

  const { colors, gsaColors, setAlert } = useBidLineDetails();

  const onActionClick = (action, { original }) => {
    setSelectedColor(original);
    setAlert(null);
    if (action === DELETE_ACTION) openModal();
  };

  const columns = useMemo(() => getColorColumns(onActionClick, gsaColors));

  return (
    <div className="margin-bottom-8 bid-line-item-details">
      <div className="display-flex flex-row flex-justify flex-align-end margin-bottom-4">
        <h3>Colors</h3>
        <div className="margin-top-2">
          <Button
            variant="outline"
            label="Copy colors"
            leftIcon={{
              name: 'content_copy',
            }}
            className="margin-left-2"
            onClick={() => openCopyModal(TAB_KEY.colors)}
          />
        </div>
      </div>
      <AddColor />
      <AFPTable
        fullWidth
        columns={columns}
        data={colors}
        defaultSort={[['vendorColorName', 'asc']]}
      />
      {!colors.length && (
        <div className="table-empty-state">
          <EmptyState bottomText="Add colors to the table" />
        </div>
      )}
      {isOpen && selectedColor && (
        <DeleteColorModal color={selectedColor} onClose={closeModal} />
      )}
    </div>
  );
};

ColorsTab.propTypes = {
  openCopyModal: PropTypes.func.isRequired,
};

export default ColorsTab;
