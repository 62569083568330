/* eslint-disable react/prop-types */
import React from 'react';
import { AFPTable } from '@gsa/afp-component-library';

import './line-history-subrow.scss';
import { emDashUnicode } from '../../../utilities/constants';

const LineHistorySubrow = ({ changes }) => {
  return (
    <div className="line-history-subrow">
      <div className="title-s-caps text-primary margin-top-2">
        Contract line version changes
      </div>
      <AFPTable
        data={changes || []}
        columns={[
          {
            Header: 'Change type',
            accessor: 'tab',
            sortable: false,
          },
          {
            Header: 'Equipment category',
            accessor: 'category',
            sortable: false,
          },
          {
            Header: 'Equipment/color code',
            accessor: 'equipmentCode',
            sortable: false,
          },
          {
            Header: 'Field name',
            accessor: 'fieldName',
            sortable: false,
          },
          {
            Header: 'Previous value',
            accessor: 'oldValue',
            sortable: false,
            Cell: ({ value }) => value || emDashUnicode,
          },
          {
            Header: 'Current value',
            accessor: 'newValue',
            sortable: false,
            Cell: ({ value }) => value || emDashUnicode,
          },
        ]}
      />
    </div>
  );
};

export default LineHistorySubrow;
