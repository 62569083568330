import React from 'react';
import {
  FlexView,
  RequiredFieldIndicator,
  AccordionItem,
} from '@gsa/afp-component-library';
import VehicleInfoSection from './vehicle-info';
import VendorAssociatedLocationSection from './vendor-associated-location/vendor-associated-locations-section';
import Clarifications from './clarifications';

const LineDetails = () => {
  return (
    <FlexView column className="margin-top-6">
      <span className="title-m-bold">Contract line details</span>
      <p>
        Required fields are marked with an asterisk (
        <RequiredFieldIndicator />
        ).
      </p>

      <AccordionItem
        key="vehicle_info"
        id="vehicle_info"
        title="Additional contract line information"
        expanded
        bordered
        content={
          <div>
            <VehicleInfoSection />
          </div>
        }
      />

      <AccordionItem
        key="associated_location_info"
        id="associated_location_info"
        title="Final assembly point / Inspection point"
        expanded
        content={
          <div className="width-full">
            <VendorAssociatedLocationSection />
          </div>
        }
      />

      <AccordionItem
        key="clarifications"
        id="clarifications"
        title="Clarifications"
        expanded
        content={
          <>
            <Clarifications />
          </>
        }
      />

      <AccordionItem
        key="technical_exceptions"
        id="technical_exceptions"
        title="Technical exceptions"
        expanded
        content={
          <>
            <Clarifications clarificationType="E" />
          </>
        }
      />
    </FlexView>
  );
};

export default LineDetails;
