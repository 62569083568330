import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import {
  AFPTable,
  AFPTableRowAction,
  EmptyState,
} from '@gsa/afp-component-library';
import { useBidLineDetails } from '../../../provider/bid-line-details-provider';
import { REMOVE_BIDLINE_ASSO_LOC } from '../../../provider/queries';

const VendorAssociatedLocationTable = ({ isReadOnly }) => {
  const [deletedAssociatedLocId, setDeletedAssociatedLocId] = useState(null);

  const {
    setAlert,
    associatedLocations,
    setAssociatedLocations,
    vendorLocations,
    isAdmin,
  } = useBidLineDetails();

  const [removeBidLineAssociatedLocation] = useMutation(
    REMOVE_BIDLINE_ASSO_LOC,
    {
      onError: (error) => {
        setAlert({
          type: 'error',
          message: `Unable to remove associated location: ${error.message}`,
        });
      },
      onCompleted: () => {
        setAssociatedLocations(() =>
          associatedLocations.filter((a) => a.id !== deletedAssociatedLocId),
        );
        setAlert({
          type: 'success',
          message: (
            <>
              Final assembly point and inspection point is removed from the bid.
            </>
          ),
        });
      },
    },
  );

  const handleSelectedAction = (action, { original }) => {
    setAlert(null);
    if (action === 'Delete') {
      setDeletedAssociatedLocId(original?.id);
      removeBidLineAssociatedLocation({
        variables: {
          removeBidLineAssociatedLocationId: original?.id,
        },
      });
    }
  };

  const actions = [];
  if (!isAdmin) {
    actions.push({
      icon: 'delete',
      label: 'Delete',
    });
  }

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Final assembly point',
        accessor: 'vendorAssemblyPointId',
        sortable: false,
        Cell: ({ value }) => {
          const assembly = vendorLocations.find((b) => +b.id === value);
          return `${assembly?.country?.countryName} (${assembly?.country?.isoCountryCode2}) 
              ${assembly?.address1} ${assembly?.city} ${assembly?.state?.stateCode},
              ${assembly?.postalCode} 
            `;
        },
      },
      {
        Header: 'Inspection point',
        accessor: 'vendorInspectionPointId',
        sortable: false,
        Cell: ({ value }) => {
          const inspection = vendorLocations.find((b) => +b.id === value);
          return `${inspection?.country?.countryName} (${inspection?.country?.isoCountryCode2}) 
              ${inspection?.address1} ${inspection?.city} ${inspection?.state?.stateCode},
              ${inspection?.postalCode} 
            `;
        },
      },
      {
        Header: 'Percentage of domestic content',
        accessor: 'percentageOfDomContent',
        sortable: false,
      },
    ];
    if (!isReadOnly) {
      columnList.push({
        Header: 'Actions',
        sortable: false,
        Cell: (props) => (
          <AFPTableRowAction
            {...props}
            actions={actions}
            // eslint-disable-next-line
            onSelectAction={(evt) => handleSelectedAction(evt, props.row)}
          />
        ),
      });
    }
    return columnList;
  }, [vendorLocations, isReadOnly]);

  return (
    <div
      className={`grid-col flex-2 ${
        isReadOnly ? '' : 'margin-top-4 margin-bottom-4'
      }`}
    >
      {vendorLocations.length > 0 && (
        <AFPTable
          testId="assembly_inspection_point_table"
          columns={columns}
          data={associatedLocations || []}
        />
      )}
      {!associatedLocations.length && (
        <div className="margin-top-2">
          <div className="table-empty-state">
            <EmptyState bottomText="No Assembly point/Inspection point found." />
          </div>
        </div>
      )}
    </div>
  );
};

VendorAssociatedLocationTable.propTypes = {
  isReadOnly: PropTypes.bool,
};

VendorAssociatedLocationTable.defaultProps = {
  isReadOnly: false,
};

export default VendorAssociatedLocationTable;
