import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { PageTitle, Button, useModal,connectModal } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import { CREATE_BID_LINE } from '../bids.gql';
import CanIChangeBidData from '../components/protect-bid-data-crud';
import SiSelectionConfirmationModal from './si-selection-confirmation-modal';

const AddStandardItemsHeader = ({ title, bidLines, hasAssociatedLineItems = false  }) => {

  const { solId, bidId } = useParams();
  const history = useHistory();
  const { isOpen, openModal, closeModal } = useModal();

  const ConnectedSiSelectionConfirmationModal = connectModal(
    SiSelectionConfirmationModal,
  );

  const modifiedBidLines = bidLines.map(bidLine=>( {vehGrp: bidLine.vehGrp, soliLineSi: bidLine.soliLineSi }))

  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs.home}/home`,
      label: 'Home',
    },
    {
      location: `/catalog/bids`,
      label: 'Bids Management',
    },
    {
      location: `/catalog/solicitations/${solId}/bid-dashboard/${bidId}`,
      label: `Bid Dashboard:  ${title}`,
    },
  ];

  const navigateBidDashboard = () => {
    history.push(`/catalog/solicitations/${solId}/bid-dashboard/${bidId}`);
  };

  const [createBidLine] = useMutation(CREATE_BID_LINE, {
    onError: () => {
      navigateBidDashboard();
    },
    onCompleted: () => {
      navigateBidDashboard();
    },
  });
const saveBidLine = ()=> createBidLine({
  variables: {
    bidLineInput: {
      bidId,
      lines: modifiedBidLines,
    },
  },
});
  const handleClick = () => {
    if (hasAssociatedLineItems) {
      openModal();
    } else {
      saveBidLine()
    }
  };

  return (
    <>
      <Breadcrumbs current="Add Standard Items" path={breadcrumbsPath} />
      <div className="grid-row flex-row">
        <div className="grid-col">
          <PageTitle
            title="Add Standard Items"
            aria-label="Add Standard Items"
            className="margin-bottom-0"
          />
        </div>
        <CanIChangeBidData>
          <div className="grid-col">
            <Button
              label="Save and return to Bid Dashboard"
              onClick={() => handleClick()}
              className="usa-button float-right margin-top-3"
            />
          </div>
        </CanIChangeBidData>
      </div>
      <p className="margin-bottom-4">
        Select Standard items to include in your bid.
      </p>
      <ConnectedSiSelectionConfirmationModal closeModal={closeModal} isOpen={isOpen} handleSave={saveBidLine} />
    </>
  );
};

AddStandardItemsHeader.propTypes = {
  title: PropTypes.string.isRequired,
  bidLines: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasAssociatedLineItems: PropTypes.bool
};


AddStandardItemsHeader.defaultProps = {
  hasAssociatedLineItems: false
}
export default AddStandardItemsHeader;
