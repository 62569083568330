import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useCatalogCodes } from '../catalog-codes-provider';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import '../styles/catalog-code-detail.scss';
import CatalogCodeWrapper from '../components/catalog-codes-form-modal';
import CatalogCodeInfo from './catalog-code-info';
import ChangeRequestHistoryTable from './change-request-history-table';
import CatalogCodeMessages from '../components/catalog-code-messages';

const CatalogCodesDetail = () => {
  const {
    selectedCatalogCode,
    setCatalogCodesData,
    getCatalogCodeChangeRequestHistory,
    catalogCodesChangeRequestHistory,
    setCatalogCodeMessagesOnDetailPage,
    getCatalogCode,
  } = useCatalogCodes();

  const params = useParams();

  const getData = () => {
    if (!selectedCatalogCode) {
      if (params.code && params.standardCodeMetadataId) {
        setCatalogCodesData('SET_PARAMS_FROM_ROUTE', {
          code: params.code,
          standardCodeMetadataId: params.standardCodeMetadataId,
        });

        const filters = {
          operator: 'AND',
          value: [
            {
              operator: 'EQ',
              key: 'code',
              value: params.code,
            },
            {
              operator: 'EQ',
              key: 'standardCodeMetadataId',
              value: params.standardCodeMetadataId,
            },
          ],
        };
        getCatalogCode({
          variables: { filters },
        });
      }
    }

    if (selectedCatalogCode) {
      setCatalogCodeMessagesOnDetailPage();

      const filters = {
        operator: 'AND',
        value: [
          {
            operator: 'EQ',
            key: 'standardsCodeId',
            value: selectedCatalogCode?.id,
          },
          { operator: 'EQ', key: 'code', value: selectedCatalogCode?.code },
        ],
      };

      getCatalogCodeChangeRequestHistory({
        variables: {
          filters,
          limit: 50,
          order: 'createdAt DESC',
        },
      });
    }
  };

  useEffect(() => {
    getData();
  }, [selectedCatalogCode]);

  useEffect(() => {
    getData();
  }, []);

  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs.home}/home`,
      label: 'Home',
    },
    {
      location: '/catalog/codes',
      label: 'Catalog Codes',
    },
  ];

  return (
    <>
      <Breadcrumbs
        path={breadcrumbsPath}
        current={
          selectedCatalogCode
            ? `${selectedCatalogCode?.code}-${selectedCatalogCode?.title}`
            : ``
        }
      />
      <CatalogCodeMessages />
      <CatalogCodeInfo />
      {selectedCatalogCode &&
        catalogCodesChangeRequestHistory &&
        catalogCodesChangeRequestHistory.length > 0 && (
          <ChangeRequestHistoryTable />
        )}
      <CatalogCodeWrapper />
    </>
  );
};

export default CatalogCodesDetail;
