import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, connectModal } from '@gsa/afp-component-library';

export const TEXT_CONTENT = { proceedBtn: 'Yes, proceed', closeBtn: 'No' };

export const TEST_ID = {
  closeBtn: 'modal-btn-close',
  proceedBtn: 'modal-btn-proceed',
  content: 'modal-content',
  title: 'modal-title',
};
const SimpleModal = ({
  onClose,
  onProceed,
  secondaryButtonText,
  primaryButtonText,
  content,
  title,
  isCloseOnProceed,
  ...restProps
}) => {
  const handleOnClose = () => {
    if (onClose) onClose();
  };
  const handleOnProceed = () => {
    if (onProceed) onProceed();
    if (isCloseOnProceed) handleOnClose();
  };
  return (
    <Modal
      id="contract-year-change-warn-modal"
      onClose={handleOnClose}
      variant="large"
      title={title && <h2 data-testid={TEST_ID.title}>{title}</h2>}
      actions={
        <>
          <Button
            type="button"
            variant="unstyled"
            onClick={handleOnClose}
            aria-label={secondaryButtonText}
            label={secondaryButtonText}
            data-testid={TEST_ID.closeBtn}
          />
          <Button
            type="button"
            variant="primary"
            onClick={handleOnProceed}
            label={primaryButtonText}
            aria-label={primaryButtonText}
            className="margin-205"
            data-testid={TEST_ID.proceedBtn}
          />
        </>
      }
      {...restProps}
    >
      {/* eslint-disable */}
      <div tabIndex="0" data-testid={TEST_ID.content} aria-label={content}>
        {content}
      </div>
      {/* eslint-enable */}
    </Modal>
  );
};

SimpleModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  secondaryButtonText: PropTypes.string,
  primaryButtonText: PropTypes.string,
  content: PropTypes.node,
  title: PropTypes.string,
  isCloseOnProceed: PropTypes.bool,
};
SimpleModal.defaultProps = {
  content: null,
  secondaryButtonText: TEXT_CONTENT.closeBtn,
  primaryButtonText: TEXT_CONTENT.proceedBtn,
  title: null,
  isCloseOnProceed: true,
};

export default connectModal(SimpleModal);
