import { Button } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import AttachmentEditForm from './AttachmentEditForm';
import AttachmentModal from './AttachmentModal';
import { useAttachmentComponent } from './ComponentContext';

const AttachmentEdit = ({ title }) => {
  const { updating, editState, resetEditState } = useAttachmentComponent();

  return (
    <AttachmentModal
      id="attachment-edit-modal"
      // className="attachment-edit-modal"
      title={title}
      show={editState?.show}
      onClose={resetEditState}
      actions={
        <>
          <Button
            disabled={updating}
            type="button"
            variant="unstyled"
            onClick={resetEditState}
            data-testid="attachment-cancel"
            label="Cancel"
          />

          <Button
            disabled={updating}
            type="submit"
            form="attachment-edit-form"
            className="edit-document-button action-button"
            label={
              editState?.field === 'effectiveDate'
                ? 'Submit date'
                : 'Submit description'
            }
          />
        </>
      }
    >
      <AttachmentEditForm />
    </AttachmentModal>
  );
};

AttachmentEdit.defaultProps = {
  title: <h2>Edit description</h2>,
};

AttachmentEdit.propTypes = {
  title: PropTypes.node,
};

export default AttachmentEdit;
