/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@gsa/afp-component-library';
import ReviewItemError from './review-item-error';

const ReviewItemsModal = ({
  closeModal,
  data = {},
  errors,
  updatedContractLines,
}) => {
  const NAMES = {
    openTime: 'Open time',
    isDirect: 'Direct',
    openDate: 'Open date',
    closeDate: 'Close out date',
    closeTime: 'Close out time',
  };

  return (
    <Modal
      id="review-items-modal"
      variant="large"
      className="solicitation-modal"
      onClose={closeModal}
      actions={
        <>
          <Button
            type="button"
            variant="unstyled"
            onClick={closeModal}
            label="Close"
          />
        </>
      }
    >
      <div className="modal-content">
        {updatedContractLines.length > 0 && (
          <div>
            <h2>Review Updates</h2>

            <p>
              The following updates have been made to the contract lines listed
              below:
            </p>

            <div>
              {updatedContractLines.map((item) => (
                <div className="review-item-container" key={item.scheduleLine}>
                  <h3>{item?.scheduleLine}</h3>
                  <ul>
                    {Object.entries(data).map(([key, value]) => (
                      <li>
                        {NAMES[key]} value was updated to{' '}
                        <strong>{value}</strong>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        )}
        {errors.length > 0 && (
          <div>
            <p className="title-m-bold">Updates could not be made</p>
            <p>The following contract lines could not be updated.</p>
            <ul>
              {errors.map((error) => {
                return (
                  <li>
                    <div className="title-s">{error.scheduleLine}</div>
                    <ReviewItemError error={error} />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </Modal>
  );
};

ReviewItemsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.shape({
    openTime: PropTypes.string,
    openDate: PropTypes.string,
    closeDate: PropTypes.string,
    closeTime: PropTypes.string,
    isDirect: PropTypes.string,
  }),
};

ReviewItemsModal.defaultProps = {
  data: {},
};

export default ReviewItemsModal;
