import { groupBy } from 'lodash';

const groupMultipleOptions = (multipleOptions) => {
  const { standardsCodeModel, ...rest } = groupBy(multipleOptions, 'type');
  const allOptions = {
    ...rest,
    ...groupBy(standardsCodeModel, 'uniqueKey'),
  };
  const currentYear = allOptions.year?.find((c) => c.currentYear === true)
    ?.value;
  const futureYears = allOptions.year?.filter((o) => o.value >= currentYear);
  futureYears?.unshift({
    label: 'Select',
    type: 'year',
    uniqueKey: 'year',
    value: '',
  });
  allOptions.futureYears = futureYears;
  allOptions?.fastCodes?.unshift({ label: '', value: '' });
  allOptions?.year?.unshift({ label: '', value: '' });
  return allOptions;
};

export default groupMultipleOptions;

export const getVehicleClassificationHierarchy = (
  vehicleClassificationOptions,
  currentSelection,
  prevSelection,
) => {
  let correspondingVehType;
  let correspondingVehGrp;
  let correspondingFedCode;

  let result = {};
  if (currentSelection.vehicleType !== prevSelection.vehicleType) {
    correspondingVehType = vehicleClassificationOptions.vehicleType.find(
      (vt) => vt.value === currentSelection.vehicleType,
    );
    correspondingVehGrp = vehicleClassificationOptions.vehicleGroup.find(
      (vg) => vg.value === correspondingVehType?.parent,
    );
    correspondingFedCode = vehicleClassificationOptions.fedStandardCode.find(
      (fvs) => fvs.value === correspondingVehGrp?.parent,
    );

    result = {
      fedStandardCode: correspondingFedCode?.value,
      vehicleGroup: correspondingVehGrp?.value,
      vehicleType: correspondingVehType?.value,
    };
  } else if (currentSelection.vehicleGroup !== prevSelection.vehicleGroup) {
    correspondingVehGrp = vehicleClassificationOptions.vehicleGroup.find(
      (vg) => vg.value === currentSelection.vehicleGroup,
    );
    correspondingVehType = vehicleClassificationOptions.vehicleType.find(
      (vt) => vt.parent === currentSelection.vehicleGroup,
    );
    correspondingFedCode = vehicleClassificationOptions.fedStandardCode.find(
      (fvs) => fvs.value === correspondingVehGrp?.parent,
    );

    result = {
      fedStandardCode: correspondingFedCode?.value,
      vehicleGroup: correspondingVehGrp?.value,
      vehicleType: correspondingVehType?.value,
    };
  } else if (
    currentSelection.fedStandardCode !== prevSelection.fedStandardCode
  ) {
    correspondingFedCode = vehicleClassificationOptions.fedStandardCode.find(
      (fvs) => fvs.value === currentSelection?.fedStandardCode,
    );
    const correspondingVehGrps = vehicleClassificationOptions.vehicleGroup.filter(
      (vg) => vg.parent === correspondingFedCode?.value,
    );
    for (let i = 0; i < correspondingVehGrps.length; i += 1) {
      correspondingVehGrp = correspondingVehGrps[i];
      correspondingVehType = vehicleClassificationOptions.vehicleType.find(
        (vt) => vt.parent === correspondingVehGrps[i].value,
      );
      if (correspondingVehType) {
        break;
      }
    }

    result = {
      fedStandardCode: correspondingFedCode?.value,
      vehicleGroup: correspondingVehGrp?.value,
      vehicleType: correspondingVehType?.value,
    };
  }

  return result;
};
