import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  TextInput,
  SelectDropdown,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import OverlaySpinner from '../../../../components/overlay-spinner';
import { useBidLineDetails } from '../provider/bid-line-details-provider';
import { INPUT_DROPDOWN_DEFAULT_OPTION, COLOR_TYPE } from '../provider/helpers';
import { CREATE_BID_LINE_COLOR } from '../provider/queries';
import CanIChangeBidData from '../../../bids/components/protect-bid-data-crud';

// eslint-disable-next-line
const AddColor = () => {
  const {
    bidLine,
    colors,
    setColors,
    gsaColors,
    setAlert,
  } = useBidLineDetails();

  const requiredText = 'This is a required field';
  const schema = yup.object().shape({
    vendorColorName: yup.string().required(requiredText),
    vendorColorCode: yup
      .string()
      .required(requiredText)
      .test(
        'color-exists',
        'Color code already exists',
        // eslint-disable-next-line
        function (code) {
          // eslint-disable-next-line
          const { existingColors } = this.parent;
          return !existingColors.some(
            (c) => c.vendorColorCode.toUpperCase() === code.toUpperCase(),
          );
        },
      ),
    gsaColorCodeId: yup.string().required(requiredText),
    colorType: yup.string().required(requiredText),
    price: yup.number().when(['colorType'], {
      is: (colorType) => colorType === COLOR_TYPE.optional,
      then: yup
        .number()
        .integer('Must be a whole number')
        .typeError(requiredText)
        .min(1, 'Must be $1 or more')
        .max(9999, 'Must be less than $10,000')
        .required(),
    }),
  });

  const { errors, watch, control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      vendorColorName: '',
      vendorColorCode: '',
      gsaColorCodeId: '',
      colorType: '',
      price: '',
      existingColors: colors,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const colorType = watch('colorType');

  useEffect(() => {
    setValue('existingColors', colors);
  }, [colors]);

  const [createColor, { loading }] = useMutation(CREATE_BID_LINE_COLOR, {
    onError: (error) => {
      setAlert({
        type: 'error',
        message: `Unable to add color: ${error.message}`,
      });
    },
    onCompleted: ({ createBidLineColor }) => {
      reset(); // reset form
      setColors((prevColors) => [...prevColors, createBidLineColor]);
      setAlert({
        type: 'success',
        message: (
          <>
            Color <b>{createBidLineColor.vendorColorName}</b> added successfully
          </>
        ),
      });
    },
  });

  const gsaColorOptions = [INPUT_DROPDOWN_DEFAULT_OPTION];
  gsaColors.forEach((color) =>
    gsaColorOptions.push({ label: color.title, value: color.id }),
  );

  const colorTypeOptions = [
    INPUT_DROPDOWN_DEFAULT_OPTION,
    { label: 'Standard', value: COLOR_TYPE.standard },
    { label: 'Optional', value: COLOR_TYPE.optional },
  ];

  const onSubmit = (data) => {
    setAlert(null);
    createColor({
      variables: {
        input: {
          bidLineId: +bidLine.id,
          vendorColorName: data.vendorColorName,
          vendorColorCode: data.vendorColorCode,
          gsaColorCodeId: +data.gsaColorCodeId,
          standardPremiumCode: data.colorType,
          price: data.price,
        },
      },
    });
  };

  const genLabel = (label, required) => (
    <span className="text-bold">
      {label} {required && <RequiredFieldIndicator />}
    </span>
  );

  return (
    <div className="bg-primary-lightest padding-bottom-2">
      {loading && <OverlaySpinner />}
      <form
        id="commenting-period-schedule-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="existingColors"
          control={control}
          render={() => null}
        />
        <div className="display-flex flex-row flex-justify flex-align-end margin-x-2">
          <div
            className={`grid-col-2 ${
              errors.vendorColorName ? 'padding-left-2' : ''
            }`}
          >
            <Controller
              name="vendorColorName"
              control={control}
              defaultValue=""
              render={({ name, value, onChange, onBlur }) => (
                <TextInput
                  name={name}
                  data-testid="add-color-vendor-color-name"
                  type="text"
                  label={genLabel('Vendor color name', true)}
                  maxLength={30}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={errors.vendorColorName?.message}
                />
              )}
            />
          </div>
          <div
            className={`grid-col-2 ${
              errors.vendorColorCode ? 'padding-left-2' : ''
            }`}
          >
            <Controller
              name="vendorColorCode"
              control={control}
              render={({ name, value, onChange, onBlur }) => (
                <TextInput
                  name={name}
                  data-testid="add-color-vendor-color-code"
                  type="text"
                  label={genLabel('Vendor color code', true)}
                  maxLength={10}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={errors.vendorColorCode?.message}
                />
              )}
            />
          </div>
          <div
            className={`grid-col-2 ${
              errors.gsaColorCodeId ? 'padding-left-2' : ''
            }`}
          >
            <Controller
              name="gsaColorCodeId"
              control={control}
              render={({ name, value, onChange, onBlur }) => (
                <SelectDropdown
                  name={name}
                  id="add-color-gsa-color"
                  data-testid="add-color-gsa-color"
                  label={genLabel('GSA color', true)}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={errors.gsaColorCodeId?.message}
                  options={gsaColorOptions}
                />
              )}
            />
          </div>
          <div
            className={`grid-col-2 ${errors.colorType ? 'padding-left-2' : ''}`}
          >
            <Controller
              name="colorType"
              control={control}
              render={({ name, value, onChange, onBlur }) => (
                <SelectDropdown
                  name={name}
                  id="add-color-is-optional"
                  data-testid="add-color-standard-optional"
                  label={genLabel('Standard or optional', true)}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={errors.colorType?.message}
                  options={colorTypeOptions}
                />
              )}
            />
          </div>
          <div className={`grid-col-2 ${errors.price ? 'padding-left-2' : ''}`}>
            {colorType === 'Premium' && (
              <Controller
                name="price"
                control={control}
                render={({ name, value, onChange, onBlur }) => (
                  <TextInput
                    name={name}
                    data-testid="add-color-price"
                    type="number"
                    prefix="$"
                    label={genLabel('Price', true)}
                    maxLength={10}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={errors.price?.message}
                  />
                )}
              />
            )}
          </div>
          <div className="text-right">
            <CanIChangeBidData>
              <Button
                type="submit"
                variant="outline"
                label="Add"
                leftIcon={{ name: 'add' }}
                className="margin-left-2 margin-right-0"
              />
            </CanIChangeBidData>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddColor;
