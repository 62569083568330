import React from 'react';
import moment from 'moment';
import { AFPTable, StatusBadge, EmptyState } from '@gsa/afp-component-library';
import { useFederalStandards } from '../../fvs-provider';
import { mapStatusToTags } from '../helper';

const FvsHistory = () => {
  const { vehicleStandard } = useFederalStandards();

  const taskColumns = [
    {
      Header: 'Date',
      accessor: 'createdAt',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => {
        return <span>{moment(value).format('L')}</span>;
      },
    },
    {
      Header: 'Completed by',
      accessor: 'initiatedByUser',
      Cell: ({ value }) => {
        if (!value) return null;
        return value?.fullName;
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => {
        const statuses = mapStatusToTags(value);
        return statuses.map((v) => (
          <div className="afp-multi-tag-spacer">
            <StatusBadge variant={v.variant}>{v.value}</StatusBadge>
          </div>
        ));
      },
    },
  ];

  const statusHistory = vehicleStandard?.statusHistory || [];
  return (
    <>
      <AFPTable
        expandable={false}
        fullWidth
        columns={taskColumns}
        data={statusHistory}
      />
      {statusHistory.length === 0 && (
        <EmptyState
          hasBackground
          containerStyles="padding-y-8 margin-top-1"
          topText={
            <strong>
              There are no status updates for this Federal Standard Code.
            </strong>
          }
        />
      )}
    </>
  );
};

export default FvsHistory;
