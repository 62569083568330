import { isEmpty } from 'lodash';

export const isNumeric = (value) =>
  // eslint-disable-next-line no-restricted-globals
  !isEmpty(value) && isEmpty(value?.split('').filter((c) => isNaN(c)));

export const isNotLetter = (value) => !/\d/.test(value);
export const isUpperCase = (value) => value?.match(/^[A-Z]*$/);
export const hasSpecialCharacter = (value) =>
  /[~`!@()._#$%^&*+=\-[\]\\';,/{}|":<>?]/g.test(value);

export const titleNoSpecialCharacter = (value) =>
  /[~`!@$%^&*+=[\]\\';/{}|":<>?]/g.test(value);
export const codeNoSpecialCharacter = (value) =>
  /[~`!@$%^&*+=[\]\\';/{}|":<>?#\\.]/g.test(value);

export const hasSpace = (value) => value?.includes(' ');
export const hasAtLeastOneCharacter = (value) => value?.length > 0;
export const hasAtLeastThreeCharacter = (value) => value?.length > 2;
export const hasOneMaxCharacter = (value) => value?.length === 1;
export const hasFiveMaxCharacters = (value) =>
  hasAtLeastOneCharacter(value) && value?.length <= 5;
export const hasSixMaxCharacters = (value) => value?.length <= 6;
export const hasThreeMaxCharacters = (value) =>
  hasAtLeastOneCharacter(value) && value?.length <= 3;
export const hasThirtyMaxCharacters = (value) =>
  hasAtLeastOneCharacter(value) && value?.length <= 30;
export const hasFourMaxCharacters = (value) =>
  hasAtLeastOneCharacter(value) && value?.length <= 4;
export const hasOneTwentyMaxCharacters = (value) => value?.length <= 120;
export const hasTwentyMaxCharacters = (value) => value?.length <= 20;

// Used for Sequence field validations.
export const isNumericAndMax5digits = (value) =>
  // eslint-disable-next-line no-restricted-globals
  !isEmpty(value) &&  isEmpty(value?.split('').filter((c) => Number.isNaN(c))) &&  value?.length <= 5 && value?.length > 0;
export const isSequenceNumeric = (value) =>
  // eslint-disable-next-line no-restricted-globals
  !isEmpty(value) && isEmpty(value?.split('').filter((c) => isNaN(c)));

// Checks for spaces including special characters.
export const hasSpecialCharacterinSequence = (value) =>
  // eslint-disable-next-line no-restricted-globals
  /[~`!@()._#$%^&*+=\-[\]\\';,/{}|":<>?\s]/g.test(value);
