/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState, useEffect } from 'react';
import { find, orderBy } from 'lodash';
import {
  AFPTable,
  Button,
  Spinner,
  Label,
  EmptyState,
  useModal,
  connectModal,
  PageTitle,
} from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useHistory, useParams } from 'react-router-dom';
import { StandardFieldset } from '../components/standard-fieldset';
import solicitationNotifications from '../utils/solicitation-notification-helpers';
import {
  viewSolicitationReopenPeriodsAtom,
  viewSolicitationDetailAtom,
  viewSolicitationStdItemsAtom,
  viewSolicitationStdItemsCountAtom,
} from '../atoms/view-solicitation-atom';
import {
  GET_SOLICITATION_BY_ID_WITH_CONTRACTS,
  GET_SOLICITATION_PERIODS_BY_NUMBER,
  GET_FVS_OPTIONS_FOR_YEAR,
} from '../queries';
import {
  PURCHASE_TYPE_MAP,
  MODAL_MODES,
  ACTIONS,
  SOLICITATION_TYPES,
} from '../constants';
import ViewSolicitationStdItemsModal from './view-solicitation-std-items-modal';
import useSolicitation from '../add-edit-solicitation/apis/solicitation-apis';
import SolicitationModal from '../solicitation-modal';
import MarkUpText from '../../../components/MarkUpText/MarkUpText';
import useToggle from '../../../hooks/use-toggle';
import {
  processOpenPeriod,
  processReopenPeriods,
} from '../add-edit-solicitation/common/reopen-periods/open-reopen-period-util';
import ArchiveSolicitationButton from './archive-solicitation-button';
import { ACTION_PARAM_KEY, createURLSearchParam } from '../route-utils';
import {
  hasContractAwardedForSolicitation,
  hasContractAwardedForNewSolicitationVersion,
} from '../utils/solicitation-table-utils';
import SolicitationPeriodsTableSubComponent from '../components/solicitation-periods-table-sub-comp';
import { isUpiidLegacyFormat } from '../utils/solicitation-upiid-utils';
import OpenPeriodTable from '../add-edit-solicitation/common/review/open-period-table';
import { emDashUnicode } from '../../../utilities/constants';
import { ReopenPeriodcolumns } from '../add-edit-solicitation/common/review/helpers';
import SolicitationStatusBadge from '../components/solicitation-status-badge';

const ViewSolicitationDetail = () => {
  const { id } = useParams();
  const history = useHistory();

  const [showDeleteSolModal, toggleDeleteSolModal] = useToggle();

  const [order, setOrder] = useState('period ASC');
  const [solicitation, setSolicitation] = useRecoilState(
    viewSolicitationDetailAtom,
  );
  const [contractYears, setContractYears] = useState({});
  const [openPeriod, setOpenPeriod] = useState({});
  const [selectedContractYear, setSelectedContractYear] = useState(0);

  const [reopenPeriods, setReopenPeriods] = useRecoilState(
    viewSolicitationReopenPeriodsAtom,
  );
  const [stdItemSelectionCount, setStdItemSelectionCount] = useRecoilState(
    viewSolicitationStdItemsCountAtom,
  );

  const [standardItems, setStandardItems] = useRecoilState(
    viewSolicitationStdItemsAtom,
  );

  const setSolicitationNotification = useSetRecoilState(
    solicitationNotifications,
  );

  const tableRef = React.createRef();
  const { isOpen, openModal, closeModal } = useModal();
  const { setModalMode, setSelectedSolicitationRow } = useSolicitation();

  const getReopenPeriods = (periods, selCntrtYear, currCntrtYear) => {
    const periodsArr = [...periods];

    const ropenPeriods =
      periodsArr.length > 1
        ? [
            ...periodsArr.sort(
              (a, b) => new Date(a.startDate) - new Date(b.startDate),
            ),
          ]
        : [...periodsArr];
    let reopenCount = 0;
    let midCycleCount = 0;
    let openPeriodCount = 0;
    if (selCntrtYear === currCntrtYear) {
      return processReopenPeriods([...ropenPeriods]).map((prd) => {
        return {
          ...prd,
          period: `${prd.periodType === 'R' ? 'Reopen' : 'Mid-cycle'} period ${
            // eslint-disable-next-line no-plusplus
            prd.periodType === 'R' ? ++reopenCount : ++midCycleCount
          }`,
        };
      });
    }

    if (ropenPeriods.length > 1 && selCntrtYear !== currCntrtYear) {
      return [...ropenPeriods].map((prd) => {
        return {
          ...prd,
          period: `${
            // eslint-disable-next-line no-nested-ternary
            prd.periodType === 'O'
              ? 'Open'
              : prd.periodType === 'R'
              ? 'Reopen'
              : 'Mid-cycle'
          } Period ${
            // eslint-disable-next-line no-nested-ternary
            prd.periodType === 'O'
              ? // eslint-disable-next-line no-plusplus
                ++openPeriodCount
              : prd.periodType === 'R'
              ? // eslint-disable-next-line no-plusplus
                ++reopenCount
              : // eslint-disable-next-line no-plusplus
                ++midCycleCount
          }`,
        };
      });
    }

    return [];
  };

  const getStandardItemCount = (programs) => {
    let count = 0;
    if (programs && programs.length > 0) {
      programs.forEach((program) => {
        count += program.solicitationLines?.length;
      });
    }

    return count;
  };

  const getStandardItems = (programs, vTypes) => {
    const stdItems = [];
    if (programs && programs.length > 0) {
      programs.forEach((program) => {
        program.solicitationLines?.forEach((solctnLine) => {
          const vehicleType = find(vTypes, {
            value: solctnLine?.standardItem?.vehicleType,
          });
          stdItems.push({
            standardItemNumber: solctnLine?.standardItem?.standardItemNumber,
            title: `${solctnLine?.standardItem?.standardItemNumber}-${solctnLine?.standardItem?.title}`,
            vehicleType: vehicleType ? vehicleType.label : emDashUnicode,
            estimatedQty: solctnLine.estimatedQty,
            estimatedFleetQty: solctnLine.estimatedFleetQty,
          });
        });
      });
    }

    return orderBy([...stdItems], 'standardItemNumber', 'asc');
  };

  const [getSolicitationById] = useLazyQuery(
    GET_SOLICITATION_BY_ID_WITH_CONTRACTS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setSolicitation(data.getSolicitationById);
        const reopenPrds = getReopenPeriods(
          [...data.getSolicitationById.solicitationPeriods],
          data.getSolicitationById.contractYear,
          data.getSolicitationById.contractYear,
        );
        const openPeriodTemp = processOpenPeriod([
          ...data.getSolicitationById.solicitationPeriods,
        ]);
        if (openPeriodTemp?.startDate) {
          setOpenPeriod(openPeriodTemp);
        }
        setReopenPeriods(reopenPrds);
        setStdItemSelectionCount(
          getStandardItemCount([...data.getSolicitationById.programs]),
        );
      },
    },
  );

  const [getReopenPeriodsBySolicitation] = useLazyQuery(
    GET_SOLICITATION_PERIODS_BY_NUMBER,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const cntrtYears = {};
        data.getSolicitationPeriodsByNumber.forEach((inst) => {
          cntrtYears[inst.contractYear] = inst.solicitationPeriods;
        });
        setContractYears(cntrtYears);
        setSelectedContractYear(solicitation.contractYear);
      },
    },
  );

  const [getFvsCodes] = useLazyQuery(GET_FVS_OPTIONS_FOR_YEAR, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const vehicleTypes = data.getVehicleClassificationFVS.filter(
        (inst) => inst.uniqueKey === 'vehicleType',
      );
      setStandardItems(getStandardItems(solicitation.programs, vehicleTypes));
    },
  });

  const getData = () => {
    getSolicitationById({
      variables: {
        id: parseFloat(id),
      },
    });
  };

  const navigateToListPage = () => {
    history.push(`/catalog/solicitations`);
    // set the toast message before navigate to list page
    const message = `Solicitation ${solicitation.solicitationNumber} has been successfully deleted.`;
    setSolicitationNotification([
      {
        id: 'DELETE_SOLICITATION',
        message: (
          <MarkUpText
            content={message}
            textToMarkUp={`Solicitation ${solicitation.solicitationNumber}`}
            marker="strong"
          />
        ),
        type: 'success',
        closeable: true,
        showInModal: false,
      },
    ]);
  };

  useEffect(() => {
    getData();
  }, [id]);

  useEffect(() => {
    if (reopenPeriods && order) {
      const sortField = order?.split(' ')[0].trim().slice(1, -1);
      const sortOrder = order?.split(' ')[1]?.toLowerCase();
      setReopenPeriods(orderBy([...reopenPeriods], sortField, sortOrder));
    }
  }, [order]);

  useEffect(() => {
    if (solicitation && solicitation.solicitationNumber) {
      getReopenPeriodsBySolicitation({
        variables: {
          solicitationNumber: solicitation.solicitationNumber,
        },
      });
    }
    if (solicitation && solicitation.contractYear) {
      getFvsCodes({
        variables: {
          year: solicitation.contractYear,
          includeOnlyCommentable: false,
        },
      });
    }
  }, [solicitation]);

  useEffect(() => {
    // clear notifications
    setSolicitationNotification([]);
  }, []);

  const onContractYearChange = (ev) => {
    const ctrtYear = ev.target.value;
    setSelectedContractYear(Number(ctrtYear));
    setReopenPeriods(
      getReopenPeriods(
        contractYears[ctrtYear],
        Number(ctrtYear),
        solicitation.contractYear,
      ),
    );
  };

  const ConnectedModal = connectModal(() => (
    <ViewSolicitationStdItemsModal
      closeModal={closeModal}
      stdItems={standardItems}
    />
  ));

  const ConnectedDeleteSolModal = connectModal(SolicitationModal);

  const renderButtons = (solicitationStatus, solicitationid) => {
    // Regular expression to match the pattern
    const regex = /^47QMCA\d{2}[A-Za-z]\d{4}$/;

    // Check if solicitation number matches the pattern
    const isSolicitationValid = regex.test(solicitation.solicitationNumber);

    switch (solicitationStatus) {
      case 'Open':
        return (
          <>
            <Button
              variant="primary"
              label="Edit"
              onClick={() => {
                history.push(
                  `/catalog/solicitations/edit-solicitation/${solicitationid}/upiid-details`,
                );
              }}
            />
          </>
        );
      case 'Closed':
        if (!isSolicitationValid) {
          return null;
        }
        return (
          <>
            <ArchiveSolicitationButton solicitation={solicitation} />
            {solicitation.solicitationType !== SOLICITATION_TYPES.Standalone &&
              !hasContractAwardedForNewSolicitationVersion(solicitation) && (
                <Button
                  variant="primary"
                  onClick={() => {
                    history.push({
                      pathname: `/catalog/solicitations/edit-solicitation/${solicitation.id}/upiid-details`,
                      search: `?${createURLSearchParam({
                        [ACTION_PARAM_KEY]: ACTIONS.RENEW,
                      })}`,
                      state: solicitation,
                    });
                  }}
                  label="Renew"
                />
              )}
            {hasContractAwardedForSolicitation(solicitation) ? (
              <Button
                label="Mid-cycle"
                variant="primary"
                data-testid="solicitation-midcycle-btn"
                onClick={() => {
                  history.push({
                    pathname: `/catalog/solicitations/edit-solicitation/${solicitation.id}/upiid-details`,
                    search: `?${createURLSearchParam({
                      [ACTION_PARAM_KEY]: ACTIONS.MIDCYCLE,
                    })}`,
                    state: solicitation,
                  });
                }}
              />
            ) : (
              <Button
                label="Reopen"
                variant="primary"
                onClick={() => {
                  history.push({
                    pathname: `/catalog/solicitations/edit-solicitation/${solicitation.id}/upiid-details`,
                    search: `?${createURLSearchParam({
                      [ACTION_PARAM_KEY]: ACTIONS.REOPEN,
                    })}`,
                    state: solicitation,
                  });
                }}
                data-testid="solicitation-reopen-btn"
              />
            )}
          </>
        );

      case 'Archived':
        if (!isSolicitationValid) {
          return null;
        }
        return (
          <>
            {!isUpiidLegacyFormat(solicitation?.solicitationNumber) && (
              <Button
                variant="primary"
                label="Reactivate"
                onClick={() => {
                  history.push({
                    pathname: `/catalog/solicitations/edit-solicitation/${solicitation.id}/upiid-details`,
                    search: `?${createURLSearchParam({
                      [ACTION_PARAM_KEY]: hasContractAwardedForSolicitation(
                        solicitation,
                      )
                        ? ACTIONS.MIDCYCLE
                        : ACTIONS.REOPEN,
                    })}`,
                    state: solicitation,
                  });
                }}
              />
            )}
          </>
        );
      case 'New':
      default:
        return (
          <>
            <Button
              variant="secondary"
              label="Delete"
              onClick={() => {
                setSelectedSolicitationRow(solicitation);
                // show confirmation message modal, delete operation is invoked on delete button on Modal
                setModalMode({
                  mode: MODAL_MODES.SOLICITATION_CONFIRM_DELETE,
                  showInModal: true,
                });
                toggleDeleteSolModal();
              }}
            />
            <Button
              variant="primary"
              label="Edit"
              onClick={() => {
                history.push(
                  `/catalog/solicitations/edit-solicitation/${solicitationid}/upiid-details`,
                );
              }}
            />
          </>
        );
    }
  };

  return solicitation ? (
    <>
      <div className="view-solicitation" data-testid="view-solicitation-detail">
        <div className="grid-row margin-bottom-2">
          <PageTitle
            title={`Solicitation - ${solicitation?.solicitationNumber}`}
            aria-label={`Solicitation - ${solicitation?.solicitationNumber}`}
            className="margin-top-0"
            tabIndex="0"
          />
          <div className="statusBadgeContainer margin-left-1 margin-top-2">
            <SolicitationStatusBadge status={solicitation?.status} />
          </div>
        </div>
        <StandardFieldset>
          <div className="grid-row">
            <div
              className="grid-col-8"
              tabIndex="0"
              aria-label="Solicitation uPIID Number"
            >
              <strong>
                <span>Solicitation uPIID number</span>
              </strong>
            </div>
          </div>
          <div className="grid-row">
            <div
              className="grid-col-8"
              tabIndex="0"
              aria-label={solicitation.solicitationNumber}
            >
              <span>{solicitation.solicitationNumber}</span>
            </div>
          </div>
        </StandardFieldset>
        <StandardFieldset>
          <div className="grid-row grid-gap">
            <div
              className="grid-col-2"
              tabIndex="0"
              aria-label={`Contract Year ${solicitation.contractYear}`}
            >
              <Label>
                <strong>Contract year</strong>
              </Label>
              <span>{solicitation.contractYear}</span>
            </div>
            <div
              className="grid-col-2"
              tabIndex="0"
              aria-label={`Purchase Type ${
                PURCHASE_TYPE_MAP[solicitation.purchaseTypeCode]
              }`}
            >
              <Label>
                <strong>Purchase type</strong>
              </Label>
              <span>{PURCHASE_TYPE_MAP[solicitation.purchaseTypeCode]}</span>
            </div>
            <div
              className="grid-col-2"
              tabIndex="0"
              aria-label={`Solicitation Process ${solicitation.solicitationType}`}
            >
              <Label>
                <strong>Solicitation process</strong>
              </Label>
              <span>{solicitation.solicitationType}</span>
            </div>
          </div>
        </StandardFieldset>
        <StandardFieldset
          label="Solicitation details"
          name="solicitationDetails"
        >
          <div className="grid-row">
            <div
              className="grid-col-8"
              tabIndex="0"
              aria-label="Solicitation title"
            >
              <strong>
                <span>Solicitation title</span>
              </strong>
            </div>
          </div>
          <div className="grid-row">
            <div
              className="grid-col-8"
              tabIndex="0"
              aria-label={
                solicitation.title ? solicitation.title : emDashUnicode
              }
            >
              <span>
                {solicitation.title ? solicitation.title : emDashUnicode}
              </span>
            </div>
          </div>
        </StandardFieldset>
        <StandardFieldset>
          <div className="grid-row">
            <div className="grid-col-8" tabIndex="0" aria-label="description">
              <strong>
                <span>Description</span>
              </strong>
            </div>
          </div>
          <div className="grid-row">
            <div
              className="grid-col-8"
              tabIndex="0"
              aria-label={
                solicitation.description
                  ? solicitation.description
                  : emDashUnicode
              }
            >
              <span>
                {solicitation.description
                  ? solicitation.description
                  : emDashUnicode}
              </span>
            </div>
          </div>
        </StandardFieldset>
        <StandardFieldset>
          <div className="grid-row">
            <div
              className="grid-col-8"
              tabIndex="0"
              aria-label="Solicitation posting"
            >
              <strong>
                <span>Solicitation posting</span>
              </strong>
            </div>
          </div>
          <div className="grid-row">
            <div
              className="grid-col-8"
              tabIndex="0"
              aria-label={
                solicitation.samUrl ? solicitation.samUrl : emDashUnicode
              }
            >
              <span>
                {solicitation.samUrl ? (
                  <a href={solicitation.samUrl}>{solicitation.samUrl}</a>
                ) : (
                  emDashUnicode
                )}
              </span>
            </div>
          </div>
        </StandardFieldset>
        <StandardFieldset label="Open period" className="margin-bottom-0">
          <OpenPeriodTable openPeriod={openPeriod} />
        </StandardFieldset>
        <StandardFieldset label="Additional periods">
          <div className="grid-row">
            <div
              className="grid-col-8"
              tabIndex="0"
              aria-label="Choose a contract year to view related open and additional periods"
            >
              <span>
                Choose a contract year to view related open and additional
                periods
              </span>
            </div>
          </div>
        </StandardFieldset>
        <StandardFieldset>
          <div className="grid-row">
            <div
              className="grid-col-8"
              tabIndex="0"
              aria-label="Select contract year"
            >
              <strong>
                <span>Select contract year</span>
              </strong>
            </div>
          </div>
          <div className="grid-row">
            <select
              className="usa-select width-15"
              name="options"
              id="options"
              value={selectedContractYear}
              onChange={onContractYearChange}
            >
              {Object.keys(contractYears).map((cntrtYear) => (
                <option value={cntrtYear} key={cntrtYear}>
                  {cntrtYear}
                </option>
              ))}
            </select>
          </div>
          <div className="gird-col-12">
            <AFPTable
              fullWidth
              testId="reopen-periods-table"
              ref={tableRef}
              columns={ReopenPeriodcolumns}
              data={reopenPeriods}
              onSort={setOrder}
              defaultSort={order}
              renderRowSubComponent={SolicitationPeriodsTableSubComponent}
            />
            {reopenPeriods?.length === 0 && (
              <EmptyState
                hasBackground
                containerStyles="padding-y-5 margin-top-1 adding-y-8 margin-top-neg-2"
                topText={<>There is no reopen period for this contract year</>}
              />
            )}
          </div>
        </StandardFieldset>
        <StandardFieldset
          label="Related Standard Items"
          name="relatedStandardItems"
        >
          <div className="grid-row">
            <div
              className="grid-col-8"
              data-testid="view-solicitation-detail-standard-item-count"
              tabIndex="0"
              aria-label={`${stdItemSelectionCount} Standard Items selected`}
            >
              <span>
                <strong>{stdItemSelectionCount}</strong> Standard Items selected
              </span>
            </div>
          </div>
          <div className="grid-row">
            <Button
              onClick={openModal}
              variant="unstyled"
              label="View details"
            />
            <ConnectedModal isOpen={isOpen} closeModal={closeModal} />
          </div>
        </StandardFieldset>
        <StandardFieldset>
          <div className="grid-row grid-gap-4 margin-top-4">
            <Button
              onClick={() => {
                history.push('/catalog/solicitations');
              }}
              variant="unstyled"
              label="Cancel"
            />
            {renderButtons(solicitation.status, solicitation.id)}
          </div>
        </StandardFieldset>
        {solicitation && (
          <ConnectedDeleteSolModal
            isOpen={showDeleteSolModal}
            onClose={toggleDeleteSolModal}
            onComplete={navigateToListPage}
          />
        )}
      </div>
    </>
  ) : (
    <Spinner aria-busy="true" size="small" />
  );
};

export default ViewSolicitationDetail;
