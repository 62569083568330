import React, { useEffect } from 'react';
import { Button } from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import { TOGGLE_TASK_STATUS } from '../../../services/fragments/tasks';
import { useTaskDetail } from '../task-detail-provider';
import { taskStatusMapping } from '../helpers/taskStatus';

const CloseTaskButton = () => {
  const { task, setTask, setData } = useTaskDetail();

  const [toggleTaskStatus, { data }] = useMutation(TOGGLE_TASK_STATUS, {
    variables: {
      id: parseInt(task.id, 10),
      status: taskStatusMapping(task.status),
    },
  });

  const handleClick = () => {
    if (!task.documentLink) {
      setData(
        'SET_ERROR_MESSAGE',
        'A valid document link needs to be added before initiating a task. Please click on the edit button to add a document link.',
      );
      setData('SET_SUCCESS_MESSAGE', '');
    } else {
      toggleTaskStatus();
    }
  };

  useEffect(() => {
    if (data && data.toggleTaskStatus) {
      setTask({
        ...task,
        ...data.toggleTaskStatus,
      });
    }
  }, [data && data.toggleTaskStatus]);

  const buttonLabel = (status) => {
    switch (status) {
      case 'Draft':
        return 'Initiate Task';
      case 'ToDo':
        return 'Mark as in progress';
      case 'InProgress':
        return 'Mark as done';
      case 'Closed':
        return 'Reopen';
      default:
        return 'Mark as done';
    }
  };

  return (
    <Button
      data-testid="closeTaskButtonId"
      onClick={handleClick}
      label={buttonLabel(task.status)}
    />
  );
};

export default CloseTaskButton;
