import React from 'react';
import * as PropTypes from 'prop-types';
import { Alert, Button } from '@gsa/afp-component-library';
import { useEquipmentDetailCodes } from './equipment-code-detail-provider';

const AssociationMessages = ({ isModal }) => {
  const { successMessage, errorMessage, setData } = useEquipmentDetailCodes();
  return (
    <>
      {errorMessage && isModal && (
        <Alert
          slim
          actions={
            <div className="position-relative">
              <div className="position-static maxw-105">
                <div className="position-absolute bottom-0 right-0">
                  <Button
                    aria-label="close"
                    data-testid="error-close"
                    variant="unstyled"
                    style={{ color: 'black' }}
                    className="margin-x-1 text-large text-heavy font-sans-xl text-gray-500"
                    onClick={() => setData('SET_ERROR_MESSAGE', '')}
                    leftIcon={{
                      name: 'close',
                    }}
                  />
                </div>
              </div>
            </div>
          }
          type="error"
        >
          {errorMessage}
        </Alert>
      )}
      {successMessage && (
        <Alert
          slim
          actions={
            <div className="position-relative">
              <div className="position-static">
                <div className="position-absolute bottom-1 right-0">
                  <Button
                    aria-label="close"
                    data-testid="success-close"
                    variant="unstyled"
                    className="margin-x-1 text-large text-heavy font-sans-xl text-gray-500"
                    onClick={() => setData('SET_SUCCESS_MESSAGE', '')}
                    leftIcon={{
                      name: 'close',
                      className: 'margin-x-1 text-middle text-gray-50',
                    }}
                  />
                </div>
              </div>
            </div>
          }
          type="success"
        >
          {successMessage}
        </Alert>
      )}
    </>
  );
};

AssociationMessages.defaultProps = {
  isModal: false,
};

AssociationMessages.propTypes = {
  isModal: PropTypes.bool,
};

export default AssociationMessages;
