import moment from 'moment';
import * as Yup from 'yup';
import { formatDate } from '../../../../../utilities/date-helpers';
import { parseDateString } from '../../../components/solicitation-form-context';
import { getTimestampFromDateNTime } from './open-reopen-period-util';

const getEditReopenPeriodYupSchema = ({
  rawPreviousPeriodData,
  previousPeriodData,
  rawNextPeriodData,
  readonlyFields,
} = {}) => {
  return Yup.object().shape({
    startDate: readonlyFields?.includes('startDate')
      ? Yup.date().required('Posting date is required.')
      : Yup.date()
          .required('Posting date is required.')
          .typeError(' ')
          .transform(parseDateString)
          .nullable()
          .min(
            moment().isAfter(previousPeriodData?.endDate)
              ? formatDate()
              : formatDate(previousPeriodData?.endDate),
            () =>
              moment().isAfter(previousPeriodData?.endDate)
                ? `Posting date should not be less than the current date.`
                : `The new Reopen period added overlaps with an existing ${
                    rawPreviousPeriodData?.endDate ? 'Reopen' : 'Open'
                  } period.`,
          ),

    startTime: readonlyFields?.includes('startTime')
      ? Yup.string().required('Posting time is required.')
      : Yup.string()
          .required('Posting time is required.')
          .when('startDate', {
            is: (startDate) => startDate && moment(startDate).isValid(),
            then: Yup.string()
              .required('Posting time is required.')
              .test({
                name: 'min-start-time',
                skipAbsent: true,
                test(startTime, { createError, parent }) {
                  const { startDate } = parent || {};
                  const startDateTime = getTimestampFromDateNTime(
                    startDate,
                    startTime,
                  );
                  const previousPeriodDateTime = getTimestampFromDateNTime(
                    previousPeriodData?.endDate,
                    previousPeriodData?.endTime,
                  );
                  if (moment().isAfter(moment(startDateTime), 'minutes'))
                    return createError({
                      message:
                        'Posting time should not be less than the current time.',
                    });
                  if (
                    moment(startDateTime).isSameOrBefore(
                      moment(previousPeriodDateTime),
                      'minutes',
                    )
                  )
                    return createError({
                      message: `The new Reopen period added overlaps with an existing ${
                        rawPreviousPeriodData?.endDate ? 'Reopen' : 'Open'
                      } period.`,
                    });

                  return true;
                },
              }),
          }),
    endDate: Yup.date()
      .required('Closing date is required.')
      .typeError('Closing time is required.')
      .transform(parseDateString)
      .nullable()
      .when('startDate', {
        is: (startDate) => startDate && moment(startDate).isValid(),
        then: Yup.date()
          .required('Closing date is required.')
          .typeError('Closing time is required.')
          .transform(parseDateString)
          .nullable()
          .min(
            Yup.ref('startDate'),
            'Closing date must be after posting date.',
          ),
      }),
    endTime: Yup.string()
      .required('Closing time is required.')
      .when(['startDate', 'endDate', 'startTime'], {
        is: (startDate, endDate, startTime) =>
          startDate &&
          endDate &&
          startTime &&
          moment(startDate).isValid &&
          moment(endDate).isValid,
        then: Yup.string()
          .required('Closing time is required.')
          .test({
            name: 'is-valid-date-range',
            skipAbsent: true,
            test(endTime, { createError, parent }) {
              const { endDate, startDate, startTime } = parent || {};
              const startDateTime = getTimestampFromDateNTime(
                startDate,
                startTime,
              );
              const endtDateTime = getTimestampFromDateNTime(endDate, endTime);
              if (moment().isAfter(moment(endtDateTime), 'minutes'))
                return createError({
                  message:
                    'Closing time should not be less than the current time.',
                });
              if (
                moment(startDateTime).isSame(endtDateTime, 'day') &&
                moment(startDateTime).isAfter(endtDateTime)
              )
                return createError({
                  message: 'Closing time must be after the posting time.',
                });
              if (
                rawNextPeriodData?.startDate &&
                moment(endtDateTime).isSameOrAfter(
                  rawNextPeriodData?.startDate,
                  'minutes',
                )
              )
                return createError({
                  message:
                    'The new Reopen period added overlaps with an existing Reopen period.',
                });
              return true;
            },
          }),
      }),
  });
};

export default getEditReopenPeriodYupSchema;
