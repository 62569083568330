import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_SOLICITATION_BY_ID_WITH_CONTRACTS,
  ADD_OR_UPDATE_SOLICITATION_PERIOD,
  GET_CONTRACTS_BY_SOLICITATION_ID,
  DELETE_SOLICITATION_PERIOD,
} from '../../queries';
import {
  processOpenPeriod,
  processReopenPeriods,
} from '../common/reopen-periods/open-reopen-period-util';
import solicitationNotifications from '../../utils/solicitation-notification-helpers';

export default function useReopenPeriod(onCompleteCallback) {
  const [solicitation, setSolicitation] = useState({});
  const [solicitationID, setSolicitationID] = useState(0);
  const [solicitationContract, setSolicitationContract] = useState([]);
  const [openPeriod, setOpenPeriod] = useState({});
  const [reopenPeriods, setReopenPeriods] = useState([]);
  const [contractYear, setSolicitationContractYear] = useState(
    new Date().getFullYear(),
  );
  const [notifications, setSolicitationNotification] = useRecoilState(
    solicitationNotifications,
  );

  const [getSolicitationById, { loading: solicitationLoading }] = useLazyQuery(
    GET_SOLICITATION_BY_ID_WITH_CONTRACTS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setSolicitation(data.getSolicitationById);
        setSolicitationID(data.getSolicitationById.id);
        setSolicitationContractYear(data.getSolicitationById.contractYear);
        setOpenPeriod(
          processOpenPeriod([...data.getSolicitationById.solicitationPeriods]),
        );
        const reopenPeriodsTemp = processReopenPeriods([
          ...data.getSolicitationById.solicitationPeriods,
        ]);
        setReopenPeriods(reopenPeriodsTemp);
      },
    },
  );

  const [
    getContractsBySolicitationId,
    { loading: solicitationContractLoading },
  ] = useLazyQuery(GET_CONTRACTS_BY_SOLICITATION_ID, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setSolicitationContract(data.getContractsBySolicitationId);
    },
  });

  const [addSolicitationPeriod, { loading: addPeriodLoading }] = useMutation(
    ADD_OR_UPDATE_SOLICITATION_PERIOD,
    {
      onError: (requestError) => {
        setSolicitationNotification([
          {
            id: 'ADD_OR_UPDATE_SOLICITATION_PERIOD_ERROR',
            message: requestError.message,
            type: 'error',
            closeable: false,
            showInModal: false,
          },
        ]);
      },
      onCompleted: () => {
        getSolicitationById({ variables: { id: Number(solicitationID) } });
      },
    },
  );

  const [deleteSolicitationPeriod] = useMutation(DELETE_SOLICITATION_PERIOD, {
    onError: (requestError) => {
      setSolicitationNotification([
        {
          id: 'DELETE_SOLICITATION_PERIOD_ERROR',
          message: requestError.message,
          type: 'error',
          closeable: false,
          showInModal: false,
        },
        ...notifications,
      ]);
    },
    onCompleted: (response) => {
      const { id } = response.deleteSolicitationPeriodById || {};
      if (id) {
        if (onCompleteCallback) onCompleteCallback();
      }
    },
  });

  return {
    solicitationLoading,
    solicitation,
    solicitationID,
    openPeriod,
    reopenPeriods,
    contractYear,
    addPeriodLoading,
    solicitationContract,
    solicitationContractLoading,
    getContractsBySolicitationId,
    getSolicitationById,
    addSolicitationPeriod,
    deleteSolicitationPeriod,
  };
}
