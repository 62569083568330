import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Alert } from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import AttachmentModal from '../../../../components/Attachment/AttachmentModal';
import { DELETE_DOCUMENT_FROM_BIDLINE } from '../provider/queries';

const DeleteDocModal = ({ doc, onSuccess, onCancel }) => {
  const [error, setError] = useState(null);
  const [deleteDoc, { loading }] = useMutation(DELETE_DOCUMENT_FROM_BIDLINE, {
    onCompleted: (res) => {
      if (res?.deleteBidLineAttachment === true) {
        onSuccess();
      }
      if (res?.deleteBidLineAttachment?.errors) {
        setError(res.deleteBidLineAttachment.errors[0]?.message);
      }
    },
    onError: () => {
      setError('Unexpected error occurred. Please try again later.');
    },
  });

  return (
    <AttachmentModal
      id="bidlibe-attachment-delete-confirm-modal"
      title="Delete confirmation"
      show
      onClose={onCancel}
      variant="large"
      actions={
        <>
          <Button
            type="button"
            variant="unstyled"
            onClick={onCancel}
            disabled={loading}
            label="Cancel"
          />
          <Button
            disabled={loading}
            variant="secondary"
            onClick={() => {
              deleteDoc({
                variables: {
                  bidLineAttachmentId: parseInt(doc.bidLineAttachmentId, 10),
                },
              });
            }}
            label="Delete"
          />
        </>
      }
    >
      {error && <Alert type="error">{error}</Alert>}
      <p>
        Are you sure you want to remove <strong>{doc.name}</strong> from the
        bid?
      </p>
    </AttachmentModal>
  );
};

DeleteDocModal.propTypes = {
  doc: PropTypes.shape({
    bidLineAttachmentId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

DeleteDocModal.defaultProps = {};

export default DeleteDocModal;
