import { gql } from '@apollo/client';

export const GET_CONTRACT_HEADERS_BY_PURCHASE_TYPE_AND_STATUS = gql`
  query getContractHeadersByPurchaseTypeAndStatus(
    $purchaseTypeCode: String!
    $isActive: Boolean
    $limit: Float
    $offset: Float
    $order: OrderBy
  ) {
    getContractHeadersByPurchaseTypeAndStatus(
      purchaseTypeCode: $purchaseTypeCode
      isActive: $isActive
      limit: $limit
      offset: $offset
      order: $order
    ) {
      count
      rows {
        contractHeaderId
        contractNumber
        formalContractNumber
        vendorId
        contractYear
        solicitationId
        contractUpiid
        isActive
        vendor {
          id
          fleetVendorNumber
          vendorName
          uniqueEntityIdentifier
        }
        solicitation {
          id
          title
          purchaseTypeCode
          solicitationUpiid
        }
      }
      hasMore
    }
  }
`;

export const GET_CONTRACT_JS_REPORT = gql`
  query getContractJSReport($input: JSReportInput!) {
    getContractJSReport(input: $input)
  }
`;
