/* eslint-disable react/prop-types */
/*  eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import {
  SelectDropdown,
  Button,
  RequiredFieldIndicator,
  StatusBadge,
} from '@gsa/afp-component-library';
import TypeAhead from '../../../contract/contract-line/make-model/typeahead';
import '../../../fvs/requirements/components/make-model/make-model-table.scss';
import { useBidMakeAndModelContext } from './make-and-model-provider';
import { getMakeModelStatusBadge } from '../../helpers';
import { emDashUnicode } from '../../../../utilities/constants';
import CanIChangeBidData from '../../../bids/components/protect-bid-data-crud';

const MakeAndModelHeader = ({ title, required }) => {
  return (
    <div className="titleHeader" aria-label={title} tabIndex="0">
      {title}
      {required && (
        <span className="margin-left-05">
          <RequiredFieldIndicator />
        </span>
      )}
    </div>
  );
};

const MakeAndModelSearchPanel = ({
  hasChassis,
  onAddMakeModel,
  action,
  disabled = false,
}) => {
  const {
    status,
    bodyMake,
    setBodyMake,
    bodyMakeInputValue,
    setBodyMakeInputValue,
    bodyMakeOptions,
    setBodyMakeOptions,

    bodyModel,
    setBodyModel,
    bodyModelInputValue,
    setBodyModelInputValue,
    bodyModelOptions,
    setBodyModelOptions,

    chassisMake,
    setChassisMake,
    chassisMakeInputValue,
    setChassisMakeInputValue,
    chassisMakeOptions,
    setChassisMakeOptions,

    chassisModel,
    setChassisModel,
    chassisModelInputValue,
    setChassisModelInputValue,
    chassisModelOptions,
    setChassisModelOptions,

    modelYear,
    setModelYear,

    fetchMakes,
    fetchModels,

    getErrors,
    dirtyFields,
    setDirtyFields,
  } = useBidMakeAndModelContext();

  const handleModelYearChanged = (val) => {
    setModelYear(val);
  };

  const getModelYearRange = (previousYears, nextYears) => {
    const currentYear = new Date().getFullYear();
    const years = [{ value: '', label: '-Select-' }];
    for (
      let i = currentYear - previousYears;
      i <= currentYear + nextYears;
      i += 1
    ) {
      years.push({ value: i, label: i.toString() });
    }
    return years;
  };

  const makeOptionRenderer = (option) => {
    return (
      <>
        <div>
          <b>Make:</b> {option.label}
        </div>
        <div>
          <b>Code:</b>{' '}
          {option?.nhtsaMakeCode ? option?.nhtsaMakeCode : option.value}
        </div>
      </>
    );
  };

  const modelOptionRenderer = (option) => {
    return (
      <>
        <div>
          <b>Model:</b> {option.label}
        </div>
        <div>
          <b>Code:</b>{' '}
          {option?.nhtsaModelCode ? option?.nhtsaModelCode : option.value}
        </div>
      </>
    );
  };

  const errors = getErrors();
  const bodyMakeError = errors?.find((e) => e.field === 'bodyMake')?.message;
  const bodyModelError = errors?.find((e) => e.field === 'bodyModel')?.message;
  const chassisMakeError = errors?.find((e) => e.field === 'chassisMake')
    ?.message;
  const chassisModelError = errors?.find((e) => e.field === 'chassisModel')
    ?.message;

  const badge = getMakeModelStatusBadge(status);

  return (
    <>
      <div className="grid-row grid-gap bg-primary-lighter padding-top-2">
        <div className="grid-col flex-1 margin-bottom-3">
          <MakeAndModelHeader title="Body Make" required />
          <TypeAhead
            disablePaste
            testId="make"
            placeholder="Enter make"
            preLoadHelpMsg="Type to search for makes"
            required
            options={bodyMakeOptions || []}
            setOptions={setBodyMakeOptions}
            selectedOption={bodyMake}
            inputValue={bodyMakeInputValue}
            setInputValue={(v) => {
              setBodyMakeInputValue(v);
              if (!v && (bodyModel || bodyModelInputValue)) {
                setBodyModel(undefined);
                setBodyModelInputValue('');
                setBodyModelOptions([]);
                setModelYear('');
              }
            }}
            setSelectedOption={setBodyMake}
            fetchOptions={fetchMakes}
            renderOptionLabel={makeOptionRenderer}
            onBlur={() => {
              setDirtyFields((prev) => [...prev, 'bodyMake']);
            }}
            errorMessage={
              dirtyFields?.includes('bodyMake') && bodyMakeError
                ? bodyMakeError
                : undefined
            }
          />
        </div>
        <div className="grid-col flex-1">
          <MakeAndModelHeader title="Body Model" required />
          <TypeAhead
            testId="model"
            disablePaste
            placeholder="Enter model"
            preLoadHelpMsg="Type to search for models"
            required
            options={bodyModelOptions || []}
            setOptions={setBodyModelOptions}
            selectedOption={bodyModel}
            inputValue={bodyModelInputValue}
            setInputValue={setBodyModelInputValue}
            setSelectedOption={setBodyModel}
            fetchOptions={(inputValue, signal) => {
              return fetchModels(
                inputValue,
                bodyMake?.value ? parseInt(bodyMake?.value, 10) : -1,
                signal,
              );
            }}
            renderOptionLabel={modelOptionRenderer}
            responseProcessor={(response) => {
              return response.data?.getModelsByPartialMatch?.map((model) => ({
                value: model.value,
                label: model.label,
                fleetApprovalStatus: model?.fleetStatus,
                NHTSApprovalStatus: model?.approvalStatus,
                nhtsaModelCode: model?.nhtsaModelCode,
              }));
            }}
            onBlur={() => {
              setDirtyFields((prev) => [...prev, 'bodyModel']);
            }}
            errorMessage={
              dirtyFields?.includes('bodyModel') && bodyModelError
                ? bodyModelError
                : undefined
            }
          />
        </div>
        {hasChassis && (
          <div className="grid-col flex-1">
            <>
              <MakeAndModelHeader title="Chassis Make" required />
              <TypeAhead
                disablePaste
                testId="chassisMake"
                placeholder="Enter chassis make"
                preLoadHelpMsg="Type to search for chassis makes"
                required
                options={chassisMakeOptions || []}
                setOptions={setChassisMakeOptions}
                selectedOption={chassisMake}
                inputValue={chassisMakeInputValue}
                setInputValue={(v) => {
                  setChassisMakeInputValue(v);
                  if (!v && (chassisModel || chassisModelInputValue)) {
                    setChassisModel(undefined);
                    setChassisModelInputValue('');
                    setChassisModelOptions([]);
                  }
                }}
                setSelectedOption={setChassisMake}
                fetchOptions={fetchMakes}
                renderOptionLabel={makeOptionRenderer}
                onBlur={() => {
                  setDirtyFields((prev) => [...prev, 'chassisMake']);
                }}
                errorMessage={
                  dirtyFields?.includes('chassisMake') && chassisMakeError
                    ? chassisMakeError
                    : undefined
                }
              />
            </>
          </div>
        )}
        {hasChassis && (
          <div className="grid-col flex-1">
            <>
              <MakeAndModelHeader title="Chassis Model" required />
              <TypeAhead
                disablePaste
                testId="chassisModel"
                placeholder="Enter chassis model"
                preLoadHelpMsg="Type to search for chassis models"
                required
                options={chassisModelOptions || []}
                setOptions={setChassisModelOptions}
                selectedOption={chassisModel}
                inputValue={chassisModelInputValue}
                setInputValue={setChassisModelInputValue}
                setSelectedOption={setChassisModel}
                fetchOptions={(inputValue, signal) => {
                  return fetchModels(
                    inputValue,
                    chassisMake?.value ? parseInt(chassisMake?.value, 10) : -1,
                    signal,
                  );
                }}
                renderOptionLabel={modelOptionRenderer}
                responseProcessor={(response) => {
                  return response.data?.getModelsByPartialMatch?.map(
                    (model) => ({
                      value: model.value,
                      label: model.label,
                      fleetApprovalStatus: model?.fleetStatus,
                      NHTSApprovalStatus: model?.approvalStatus,
                      nhtsaModelCode: model?.nhtsaModelCode,
                    }),
                  );
                }}
                onBlur={() => {
                  setDirtyFields((prev) => [...prev, 'chassisModel']);
                }}
                errorMessage={
                  dirtyFields?.includes('chassisModel') && chassisModelError
                    ? chassisModelError
                    : undefined
                }
              />
            </>
          </div>
        )}
        <div className="grid-col flex-1">
          <MakeAndModelHeader title="Model year" required />
          <SelectDropdown
            name="model_year"
            onChange={({ target: { value } }) => handleModelYearChanged(value)}
            value={modelYear}
            options={getModelYearRange(1, 3)}
            className="margin-top-4"
          />
        </div>
        {action === 'add' && (
          <div className="grid-col flex-1">
            <CanIChangeBidData>
              <Button
                leftIcon={{
                  name: 'add',
                }}
                type="button"
                variant="outline"
                onClick={onAddMakeModel}
                label="Add"
                aria-label="Add"
                className="margin-top-7"
                disabled={disabled}
              />
            </CanIChangeBidData>
          </div>
        )}
        {action === 'update' && (
          <div className="grid-col flex-1">
            <MakeAndModelHeader title="Status" />
            <div className="margin-top-4">
              {badge ? (
                <StatusBadge variant={badge.variant}>{badge.label}</StatusBadge>
              ) : (
                emDashUnicode
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MakeAndModelSearchPanel;
