import { Button } from '@gsa/afp-component-library';
import React from 'react';
import {
  useCatalogCodes,
  CATALOG_CODES_MODAL_MODES,
} from '../catalog-codes-provider';

const CatalogCodeDetailActions = () => {
  const { setCatalogCodesData, selectedCatalogCode } = useCatalogCodes();

  return (
    <div className="catalog-header">
      <h1>
        {selectedCatalogCode?.code} - {selectedCatalogCode?.title}
      </h1>
      <div>
        {selectedCatalogCode?.status === 'Draft' && (
          <Button
            variant="secondary"
            label="Delete"
            onClick={() => {
              setCatalogCodesData(
                'SET_MODAL_MODE',
                CATALOG_CODES_MODAL_MODES.CONFIRM_DELETE,
              );
            }}
          />
        )}
      </div>
    </div>
  );
};

export default CatalogCodeDetailActions;
