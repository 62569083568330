import React from 'react';
import { PageTitle } from '@gsa/afp-component-library';
import Breadcrumbs from '../../widgets/breadcrumbs';
import SolicitationsTable from './solicitation-table';
import SolicitationNotification from './utils/solicitation-notification';
import SolicitationIntroContent from './components/solicitation-intro-content';

const Solicitations = () => {
  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs?.home}/home`,
      label: 'Home',
    },
  ];

  return (
    <>
      <div className="grid grid-container">
        <Breadcrumbs current="Solicitations" path={breadcrumbsPath} />
        <SolicitationNotification isModal={false} />
        <PageTitle
          title="Solicitations"
          aria-label="Solicitations"
          tabIndex="0"
        />
        <SolicitationIntroContent />
        <SolicitationsTable />
      </div>
    </>
  );
};

export default Solicitations;
