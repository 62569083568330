import React from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker, ErrorMessage } from '@gsa/afp-component-library';
import {
  getTomorrowStr,
  getTSDateStrToUS,
} from '../../../../utilities/date-helpers';
import { useFederalStandards } from '../../fvs-provider';
import { useFvsLandingPageContext } from '../fvs-landing-page-provider';

const PublishStandard = () => {
  const schema = yup.object().shape({
    effectiveDate: yup.date().nullable().required('Publish date is required'),
  });

  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      effectiveDate: null,
    },
    mode: 'onBlur',
  });

  const tomorrowStr = getTomorrowStr();
  const { publishFvs, vehicleStandard } = useFederalStandards();
  const { setFvsLandingPageData } = useFvsLandingPageContext();

  const onPublish = async ({ effectiveDate }) => {
    await publishFvs({
      variables: {
        fedVehicleStandardsId: Number.parseInt(vehicleStandard?.id, 10),
        effectiveDate: effectiveDate ? getTSDateStrToUS(effectiveDate) : null,
      },
    });
    setFvsLandingPageData('SET_MODAL_MODE', null);
  };

  return (
    <div>
      <p>
        All new documents will be published. Please be sure to review all
        documents before publishing.
      </p>
      <div className="height-mobile">
        <form
          id="fvs-effective-date-form"
          className="margin-top-2"
          data-testid="update-account-form"
          onSubmit={handleSubmit(onPublish)}
        >
          <div className=" margin-top-6 usa-form-group">
            <h2 className="font-sans-md">
              Enter an estimated effective date for this standard
            </h2>
            <div className="usa-hint" id="fvs-date-hint">
              mm/dd/yyyy
            </div>
            <Controller
              name="effectiveDate"
              data-testid="fvs-effective-date"
              control={control}
              defaultValue=""
              render={({ name, value, onBlur, onChange }) => (
                <DatePicker
                  id="effectiveDate"
                  name={name}
                  aria-label="Effective Date"
                  defaultValue={value < tomorrowStr ? null : value}
                  minDate={tomorrowStr}
                  onBlur={onBlur}
                  onChange={onChange}
                />
              )}
            />
            {errors?.effectiveDate?.message && (
              <ErrorMessage>{errors.effectiveDate.message}</ErrorMessage>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default PublishStandard;
