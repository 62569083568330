import React from 'react';
import { Controller } from 'react-hook-form';
import {
  DatePicker,
  TimePicker,
  SelectDropdown,
  RadioButton,
} from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import moment from 'moment';
import Fieldset from './fieldset';
import { formatDate } from '../../../utilities/date-helpers';
import { AUDIENCE_FULL_DESCRIPTION_MAP } from '../constants';
import { READ_ONLY_FIELD_LABEL_MAP } from '../utils/solicitation-period-utils';
import AudienceSelection from './audience-selection';
import AudienceList from './audience-list';

const SolicitationPeriodField = ({
  idPrefix,
  name: fieldName,
  label,
  required,
  control,
  stepperStage,
  solicitationOprType,
  minDate,
  maxDate,
  register,
  isReadonly,
  solicitationStatus,
  currentValue,
  options,
  component = 'DatePicker',
  'data-testid': dataTestId,
  children,
}) => {
  const componentMap = {
    TimePicker,
    DatePicker,
    SelectDropdown,
    RadioButton,
  };
  const Component = componentMap[component] || DatePicker;

  const getFieldsetDescription = () => {
    switch (component) {
      case 'TimePicker':
        return moment(currentValue, ['HH:mm']).format('hh:mma');
      case 'SelectDropdown':
        return AUDIENCE_FULL_DESCRIPTION_MAP[currentValue];
      case 'DatePicker':
      default:
        return formatDate(currentValue);
    }
  };

  const isStartDate = fieldName === 'startDate'; 
  const isActiveStatus = solicitationStatus === 'active'; 
  const isPastCurrentDate = moment(currentValue).isBefore(moment(), 'day'); 

  const shouldBeReadonly = isStartDate && isActiveStatus && isPastCurrentDate;

  return (
    <Controller
      name={fieldName}
      data-testid={dataTestId}
      control={control}
      defaultValue={
        component === 'RadioButton' ? currentValue?.audience : currentValue
      }
      render={({ name, value, onChange }) => {
        if (isReadonly || shouldBeReadonly) {
          return (
            children || (
              <Fieldset
                title={READ_ONLY_FIELD_LABEL_MAP[fieldName] || label}
                description={getFieldsetDescription()}
                data-testid={`${idPrefix}-${component}-${name}-readonly`}
              />
            )
          );
        }

        const commonProps = {
          'data-testid': dataTestId,
          'aria-label': label,
          inputRef: register,
          label,
          minDate,
          name,
          required,
          onChange,
          value,
          id: `${idPrefix}-${component}-${name}`,
          currentValue,
        };

        if (component === 'SelectDropdown')
          return (
            <SelectDropdown
              {...commonProps}
              aria-required="true"
              options={options}
              onChange={(ev) => {
                onChange(ev.target.value);
              }}
            />
          );

        if (component === 'RadioButton')
          return (
            <AudienceSelection
              {...commonProps}
              aria-required="true"
              currentValue={currentValue?.audience}
            >
              <AudienceList
                audienceList={currentValue?.vendors}
                isModal={!!currentValue?.period}
                aria-required="true"
              />
            </AudienceSelection>
          );

        if (
          stepperStage === 'open' &&
          solicitationOprType === 'edit' &&
          currentValue !== undefined
        ) {
          return (
            <Component
              {...commonProps}
              defaultValue={currentValue}
              maxDate={maxDate}
            />
          );
        }
        if (solicitationOprType === 'new' || stepperStage === 'reopen') {
          return <Component {...commonProps} defaultValue="" />;
        }
        return null;
      }}
    />
  );
};

SolicitationPeriodField.propTypes = {
  component: PropTypes.oneOf([
    'Timepicker',
    'Datepicker',
    'SelectDropdown',
    'RadioButton',
  ]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.string.isRequired,
  stepperStage: PropTypes.oneOf(['edit', 'reopen', '']),
  solicitationOprType: PropTypes.oneOf(['new', 'open', '']),
  minDate: PropTypes.string.isRequired,
  maxDate: PropTypes.string.isRequired,
  isReadonly: PropTypes.bool,
  currentValue: PropTypes.string.isRequired,
  register: PropTypes.shape(Object).isRequired,
  control: PropTypes.shape(Object).isRequired,
  solicitationStatus: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  'data-testid': PropTypes.string,
  idPrefix: PropTypes.string.isRequired,
  children: PropTypes.node,
};
SolicitationPeriodField.defaultProps = {
  component: 'Datepicker',
  isReadonly: false,
  options: undefined,
  stepperStage: '',
  solicitationOprType: '',
  'data-testid': '',
  children: '',
};

export default SolicitationPeriodField;
