/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Can } from '@gsa/afp-shared-ui-utils';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useQuery, useMutation, gql } from '@apollo/client';
import { useRecoilState } from 'recoil';
import moment from 'moment';
import {
  AFPTable,
  Button,
  Spinner,
  EmptyState,
  CounterTag,
  ErrorMessage,
} from '@gsa/afp-component-library';

import {
  bidsOpenSolicitationsAtom,
  bidsMessagesAtom,
  bidsActiveBidsAtom,
} from '../atoms/bids-atoms';
import { addOrReplaceMessageById } from '../utils/bids-util';
import {
  GET_BIDS_OPEN_SOLICIATIONS_FOR_VENDOR,
  START_BID,
  REOPEN_BID,
} from '../bids.gql';
import Programs from '../components/programs';
import { useVendorInfo } from './apis/vendor-api';
import {
  OPERATIONS,
  SUBJECTS,
  emDashUnicode,
} from '../../../utilities/constants';

import './bids-solicitation.css';

const BidsSolicitations = () => {
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [bidId, setBidId] = useState(null);
  const [soliId, setSoliId] = useState(null);
  const order = [
    ['contractYear', 'ASC'],
    ['solicitationPeriods', 'endDate', 'ASC'],
  ];
  const {
    vendor,
    vendorInfoForAdmin,
    hasAdminPrivilege,
    isVendorDataLoading,
    vendorDataLoadingError,
  } = useVendorInfo();

  const closedBidsQueryResult = useQuery(
    gql`
      query getClosedBidsByVendorId($vendorId: String!) {
        getClosedBidsByVendorId(vendorId: $vendorId) {
          solicitationId
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        vendorId: vendor?.id,
      },
      skip: !vendor?.id,
    },
  );

  if (!isVendorDataLoading && vendorDataLoadingError) {
    return <ErrorMessage>Something went wrong</ErrorMessage>;
  }

  // set atoms
  const [openSolicitations, setOpenSolicitations] = useRecoilState(
    bidsOpenSolicitationsAtom,
  );

  const vendorId = hasAdminPrivilege ? vendorInfoForAdmin?.id : vendor?.id;

  const [activeBids] = useRecoilState(bidsActiveBidsAtom);

  const [bidsMessages, setBidsMessages] = useRecoilState(bidsMessagesAtom);

  const clearAllMessages = () => {
    setBidsMessages([]);
  };

  const setBidsError = (error) => {
    const message = {
      id: error?.graphQLErrors[0]?.extensions?.exception?.name || '',
      message: error?.message,
      type: 'error',
    };
    const messages = addOrReplaceMessageById(bidsMessages, message);
    setBidsMessages(messages);
  };

  const [
    getBidsOpenSolicitationsForVendor,
    { loading: isOpenSolicitationsLoading },
  ] = useLazyQuery(GET_BIDS_OPEN_SOLICIATIONS_FOR_VENDOR, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (requestError) => {
      setBidsError(requestError);
    },
    onCompleted: (responseData) => {
      if (responseData?.getOpenSolicitationsForVendor) {
        // Process the data to make solicitationPeriods a single object instead of an array
        const processedData = responseData?.getOpenSolicitationsForVendor?.rows?.map(
          (item) => {
            const period = item.solicitationPeriods[0];
            return {
              ...item,
              solicitationPeriod: period,
            };
          },
        );
        setOpenSolicitations(processedData);
      }
    },
  });

  /**
   * Start Bid GQL Call
   */
  const [startBid, { loading: isBidCreationInProgress }] = useMutation(
    START_BID,
    {
      onError: (requestError) => {
        setBidsError(requestError);
      },
      onCompleted: (data) => {
        if (data?.startBid) {
          setBidId(data.startBid?.id);
        }
      },
    },
  );

  const [reopenBid, { loading: isReopenBidCreationInProgress }] = useMutation(
    REOPEN_BID,
    {
      onError: (requestError) => {
        setBidsError(requestError);
      },
      onCompleted: (data) => {
        if (data?.reopenBid) {
          setBidId(data.reopenBid?.id);
        }
      },
    },
  );

  const getData = () => {
    // eslint-disable-next-line
    vendorId &&
      getBidsOpenSolicitationsForVendor({
        variables: {
          vendorId,
          limit: 100,
          offset: 0,
          order,
          filters: {
            operator: '$and',
            conditions: [
              {
                operator: '$in',
                key: 'status',
                value: ['Open'],
              },
            ],
          },
        },
      });
  };

  const redirectToBidDashBoard = () => {
    if (!isBidCreationInProgress && !isReopenBidCreationInProgress && bidId) {
      history.push(`/catalog/solicitations/${soliId}/bid-dashboard/${+bidId}`);
    }
  };

  /* eslint-disable-next-line */
  const handleSelectedRowClick = (evt, original) => {
    const { solicitationId, contractYear } = original?.original;
    setSoliId(+solicitationId);
    if (evt === 'start_bid') {
      // eslint-disable-next-line
      vendor?.id &&
        solicitationId &&
        contractYear &&
        startBid({
          variables: {
            solicitationId: +solicitationId,
            vendorId: vendor?.id,
            contractYear,
          },
        });
      return;
    }
    if (evt === 'reopen_bid') {
      if (vendor?.id && solicitationId) {
        reopenBid({
          variables: {
            solicitationId: +solicitationId,
            vendorId: vendor?.id,
          },
        });
      }
      return;
    }

    window.open(original?.samUrl, '_blank');
  };

  /**
   * Method to count the total makes & models selected on pre-bid
   * @param {*} programs
   * @returns totalMakeModelCount: the total count for makes & models selected on pre-bid
   */
  const getPreBidMakeModelSelectionCount = (programs) => {
    return programs.reduce((total, program) => {
      return (
        total +
        (program?.solicitationLines?.reduce((lineTotal, line) => {
          const preBidMakeModels = line?.preBidSelection?.preBidMakeModels;
          return lineTotal + (preBidMakeModels ? preBidMakeModels.length : 0);
        }, 0) || 0)
      );
    }, 0);
  };

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Solicitation number (uPIID)',
        sortable: false,
        accessor: 'solicitationNumber',
      },
      {
        Header: 'Title',
        sortable: false,
        accessor: 'title',
      },
      {
        Header: 'Posting date',
        sortable: false,
        accessor: 'solicitationPeriods[0].startDate',
        Cell: ({ value }) => {
          return moment(value).format('L');
        },
      },
      {
        Header: 'Closing date',
        sortable: false,
        accessor: 'solicitationPeriods[0].endDate',
        Cell: ({ value }) => {
          return moment(value).format('L');
        },
      },
      {
        Header: 'Period type',
        sortable: false,
        accessor: 'solicitationPeriods[0].periodType',
        Cell: ({ value }) => {
          const map = {
            O: 'Open',
            R: 'Reopen',
            M: 'Mid-cycle',
          };
          return map[value] || emDashUnicode;
        },
      },
      {
        Header: 'Contract year',
        sortable: false,
        accessor: 'contractYear',
      },
      {
        Header: 'Makes and Models you proposed in Fed Standards',
        sortable: false,
        accessor: 'preBidSelectionCount',
        // eslint-disable-next-line no-unused-vars
        Cell: ({ row }) => {
          const preBidSelectionCount = getPreBidMakeModelSelectionCount(
            row.original.programs,
          );
          return <CounterTag count={preBidSelectionCount} />;
          // return '-';
        },
      },
      {
        Header: 'Actions',
        accessor: 'startbid',
        sortable: false,
        Cell: ({ row }) => {
          const previousBidExists = closedBidsQueryResult?.data?.getClosedBidsByVendorId?.some(
            (obj) =>
              obj.solicitationId === parseInt(row.original.solicitationId, 10),
          );
          if (closedBidsQueryResult?.loading) {
            return <Spinner size="small" />;
          }
          const periodType = row?.original?.solicitationPeriods[0]?.periodType;
          let button;
          if (periodType === 'O' || !previousBidExists) {
            button = (
              <Button
                variant="unstyled"
                onClick={() => handleSelectedRowClick('start_bid', row)}
                label="Start Bid"
              />
            );
          } else {
            button = (
              <Button
                variant="unstyled"
                onClick={() => handleSelectedRowClick('reopen_bid', row)}
                label="Reopen Bid"
              />
            );
          }
          return (
            <Can I={OPERATIONS.Create} a={SUBJECTS.PSOQuestionnaire}>
              {!hasAdminPrivilege && button}
            </Can>
          );
        },
      },
    ];

    return columnList;
  }, [vendor, vendorInfoForAdmin, closedBidsQueryResult]);

  useEffect(() => {
    return () => {
      // Clear generated messages before unload
      clearAllMessages();
    };
  }, []);

  useEffect(() => {
    if (bidId && soliId) {
      redirectToBidDashBoard();
    }
  }, [bidId]);

  useEffect(() => {
    getData();
  }, [vendor, vendorInfoForAdmin]);

  useEffect(() => {
    const availableSol = [];
    if (activeBids?.length === 0) {
      setTableData(openSolicitations);
    } else if (openSolicitations && activeBids?.length) {
      openSolicitations?.forEach((sol) => {
        const bidExists = activeBids.some(
          (obj) => obj.solicitationId === parseInt(sol.solicitationId, 10),
        );
        const bidExistsAndShowReopenLink = activeBids.some(
          (obj) =>
            obj.solicitationId === parseInt(sol.solicitationId, 10) &&
            obj.showReopenLink === true,
        );

        if (bidExistsAndShowReopenLink || !bidExists) {
          availableSol.push(sol);
        }
      });
      setTableData(availableSol);
    }
  }, [openSolicitations, activeBids]);

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    return (
      <>
        <section>
          <Button
            variant="unstyled"
            onClick={
              () => handleSelectedRowClick('solicitation_at_sam', original)
              /* eslint-disable-next-line */
            }
            label="Solicitation at SAM"
            rightIcon={{ name: 'launch', className: 'text-ink' }}
          />
        </section>
        <section className="margin-top-1">
          <Programs programs={original?.programs} />
        </section>
      </>
    );
  });

  const isSomethingLoading =
    isBidCreationInProgress ||
    isReopenBidCreationInProgress ||
    isOpenSolicitationsLoading ||
    isVendorDataLoading;

  let label;
  if (isBidCreationInProgress) {
    label = 'Starting bid...';
  } else if (isReopenBidCreationInProgress) {
    label = 'Reopening bid...';
  } else if (isOpenSolicitationsLoading) {
    label = 'Loading open solicitations...';
  } else if (isVendorDataLoading) {
    label = 'Loading vendor data...';
  }

  return (
    <>
      <div className="vendor-available-solicitations-table-wrapper desktop:grid-col-12">
        <div className="text-primary bid-header">Available Solicitations</div>
        {isSomethingLoading && (
          <div className="margin-y-9 display-flex flex-column flex-align-center flex-justify-center">
            <Spinner size="medium" className="margin-bottom-2" />
            {label}
          </div>
        )}
        {!isSomethingLoading && vendorId !== undefined && (
          <AFPTable
            fullWidth
            expandable
            testId="bids-solicitations-table"
            columns={columns}
            renderRowSubComponent={renderRowSubComponent}
            data={tableData || []}
            defaultSort={order}
          />
        )}
        {!isSomethingLoading && tableData?.length === 0 && (
          <EmptyState
            hasBackground
            containerStyles="padding-y-8 margin-top-1"
            topText="There are no open Solicitations available."
          />
        )}
      </div>
    </>
  );
};

export default BidsSolicitations;
