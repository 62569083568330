import React from 'react';
import { useRecoilState } from 'recoil';
import solicitationNotifications from './solicitation-notification-helpers';
import GenericMessagesToast from '../../../components/Toast/generic-messages-toast';

const SolicitationNotification = () => {
  const [notifications, setNotifications] = useRecoilState(
    solicitationNotifications,
  );
  const handleClose = (id) => {
    const newNotifications = notifications.filter(
      (notification) => notification.id !== id,
    );
    setNotifications(newNotifications);
  };
  return (
    <div data-testid="notification-container">
      {' '}
      <GenericMessagesToast
        allMessages={notifications}
        isModal={false}
        onClose={handleClose}
      />
    </div>
  );
};

export default SolicitationNotification;
