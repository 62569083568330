import React, { useEffect } from 'react';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import PreBidSummary from './components/pre-bid-summary';

const PreBidSummaryPage = () => {
  useEffect(() => {
    document.title = 'Pre-bid Summary - GSAFleet.gov';
  }, []);

  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs.home}/home`,
      label: 'Home',
    },
    {
      location: '/catalog/vehicle-standards',
      label: 'Federal Vehicle Standards',
    },
  ];

  return (
    <>
      <Breadcrumbs current="My Pre-bid Summary" path={breadcrumbsPath} />
      <PreBidSummary />
    </>
  );
};

export default PreBidSummaryPage;
