import truncate from 'html-truncate';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import './truncate.scss';

const Truncate = ({ content, ellipsis, size, showFormModal, showViewMore }) => {
  if (!content) return null;

  const initialSize = size;
  const fullSize = content?.length;
  const [previewSize, setPreviewSize] = useState(initialSize);

  useEffect(() => {
    if (showFormModal) setPreviewSize(initialSize);
  }, [showFormModal]);

  return (
    <>
      {/* Using ReactHtmlParser as it is safer than using the native dangerouslySetInnerHTML */}
      {content && ReactHtmlParser(truncate(content, previewSize, { ellipsis }))}
      {previewSize === initialSize &&
        content?.length > 0 &&
        content?.length > initialSize && (
          <div className="grid-row">
            {content && showViewMore && (
              <button
                type="button"
                data-testid="truncate-view-more"
                className="usa-button usa-button--unstyled"
                onClick={() => setPreviewSize(fullSize)}
              >
                View more
              </button>
            )}
          </div>
        )}
      {previewSize > initialSize && content?.length > 0 && (
        <div className="grid-row">
          {content && (
            <button
              type="button"
              data-testid="truncate-view-less"
              className="usa-button usa-button--unstyled"
              onClick={() => setPreviewSize(initialSize)}
            >
              View less
            </button>
          )}
        </div>
      )}
    </>
  );
};

Truncate.defaultProps = {
  content: null,
  ellipsis: '',
  size: 200,
  showFormModal: false,
  showViewMore: true,
};
Truncate.propTypes = {
  content: PropTypes.string,
  ellipsis: PropTypes.string,
  size: PropTypes.number,
  showFormModal: PropTypes.bool,
  showViewMore: PropTypes.bool,
};

export default Truncate;
