export const COMMENTING_PERIOD_WELCOME_TEXT =
  'Federal Vehicle Standards are posted for feedback as part of the annual development, coordination and maintenance of the standards. This commenting period occurs once per standard in each Contract year. <br><br> Comments can be provided for: <ul> <li>Federal Vehicle Standard</li> <li>Standard Items</li> <li>Equipment Codes</li> </ul>';

export const COMMENTING_PERIOD_LABELS = {
  VIEW: 'View Fvs',
  FVS: 'Federal Vehicle Standard',
  TYPE: 'User type',
  STARTS: 'Starts',
  ENDS: 'Ends',
  DAYSLEFT: 'Days Left',
  STATE: 'State',
  YEAR: 'Contract Year',
};
