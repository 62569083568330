import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  PageTitle,
  RequiredFieldIndicator,
  StepIndicator,
  Alert,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import OverlaySpinner from '../../../components/overlay-spinner';
import { OPERATIONS, SUBJECTS } from '../../../utilities/constants';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import ReportSelectionForm from '../components/bid-reports/report-selection-form';
import SolicitationSelectionForm from '../components/bid-reports/solicitation-selection-form';
import {
  GET_JS_REPORT,
  STEPS,
  getDownloadAlert,
  asyncDownloadAlert,
} from './bid-report-helpers';
import {
  API_TIME_LIMIT,
  REPORT_CONFIG,
} from '../components/bid-reports/bid-report-types';

let reportStartTime;

const BidReports = () => {
  const canViewAll = useAppAbility().can(
    OPERATIONS.View,
    SUBJECTS.AllBidReports,
  );
  const step2defaults = {
    sin: { value: '' },
    solicitation: { value: '' },
    vehicleSupplier: { value: '' },
    vehicleSuppliers: { value: [] },
    standardItem: { value: '' },
    standardItems: { value: [] },
    lineItems: { value: [] },
    isEmailReport: { value: false },
  };

  const [currentStep, setCurrentStep] = useState(0);
  const [formValues, setFormValues] = useState({
    reportType: { value: '' },
    report: { value: '' },
    contractYear: { value: '' },
    contractYears: { value: [] },
    ...step2defaults,
  });

  const [toaster, setToaster] = useState(null);

  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs.home}/home`,
      label: 'Home',
    },
  ];

  // Query to fetch report data
  const [getJSReportData, { loading: loadingJSReport }] = useLazyQuery(
    GET_JS_REPORT,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ getJSReport: res }) => {
        setToaster(
          res.requestSent
            ? asyncDownloadAlert
            : getDownloadAlert(
                REPORT_CONFIG[formValues.report.value].label,
                res.content.data,
                res.contentType,
                `${res.reportName}.${res.fileExtension}`,
              ),
        );
      },
      onError: () => {
        if (canViewAll && Date.now() - reportStartTime >= API_TIME_LIMIT)
          setToaster({
            type: 'warning',
            message:
              "Report generation exceeded time limit. Please try again, or select 'email the report' option.",
          });
      },
    },
  );

  const onSubmit = (fileType) => {
    setToaster(null);
    const queryParams = {};
    if (formValues.solicitation.value)
      queryParams.solicitationId = +formValues.solicitation.value;
    if (formValues.vehicleSuppliers.value.length)
      queryParams.vendorIds = formValues.vehicleSuppliers.value;
    if (formValues.standardItems.value.length)
      queryParams.standardItemIds = formValues.standardItems.value.map(
        (i) => +i,
      );

    // Bid periods comparison report
    if (formValues.vehicleSupplier.value)
      queryParams.vendorIds = [formValues.vehicleSupplier.value];
    if (formValues.standardItem.value)
      queryParams.standardItemIds = [+formValues.standardItem.value];
    if (formValues.lineItems.value.length)
      queryParams.bidLineIds = formValues.lineItems.value
        .join(',')
        .split(',')
        .map((i) => +i);

    // Minimum and optional comparison report
    if (formValues.contractYears.value.length)
      queryParams.contractYears = formValues.contractYears.value.map((i) => +i);
    if (formValues.sin.value) queryParams.sin = formValues.sin.value;

    // set reporting method
    let method = formValues.isEmailReport.value ? 'async' : 'sync';
    if (!canViewAll) method = 'auto';

    reportStartTime = Date.now();
    getJSReportData({
      variables: {
        input: {
          reportTypeId: REPORT_CONFIG[formValues.report.value].reportTypeId,
          outputType: fileType,
          method,
          queryParams,
        },
      },
    });
  };

  return (
    <div className="margin-bottom-8">
      {loadingJSReport && <OverlaySpinner />}
      {toaster && (
        <Alert
          slim
          focused
          type={toaster.type}
          onClose={() => setToaster(null)}
        >
          {toaster.message}
        </Alert>
      )}
      <Breadcrumbs current="Bid Reports" path={breadcrumbsPath} />
      <PageTitle title="Bid Reports" aria-label="Bid Reports" tabIndex="0" />
      <div>Use this tool to export the bid reports from Fleet.</div>
      <div className="margin-bottom-4">
        Required fields are marked with an asterisk (<RequiredFieldIndicator />
        ).
      </div>

      <StepIndicator
        counterSize="small"
        data-testid="bid-reports-stepper"
        heading={STEPS[currentStep].heading}
        steps={STEPS[currentStep].steps}
        className="margin-bottom-1"
      />
      <hr className="border-gray-1" />

      {currentStep === 0 ? (
        <ReportSelectionForm
          canViewAll={canViewAll}
          formValues={formValues}
          setFormValues={setFormValues}
          onNext={() => {
            setCurrentStep(1);
            setFormValues((prevValues) => ({
              ...prevValues,
              ...step2defaults,
            }));
          }}
        />
      ) : (
        <SolicitationSelectionForm
          canViewAll={canViewAll}
          formValues={formValues}
          setFormValues={setFormValues}
          onPrev={() => setCurrentStep(0)}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};

export default BidReports;
