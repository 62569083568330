import React, { createContext, useState, useContext, useReducer } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { UPDATE_PEER_REVIEW } from '../../services/fragments/tasks';
import { TaskPropType } from '../../utilities/types';
import { GET_ACTIVE_USERS_BY_ROLES } from '../../services/data-store';

export const TaskDetailContext = createContext();

const initialState = {
  successMessage: '',
  errorMessage: '',
  taskDetailErrors: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SUCCESS_MESSAGE':
      return { ...state, successMessage: action.payload };

    case 'SET_USERS':
      return { ...state, users: action.payload };

    case 'SET_ERROR_MESSAGE':
      return { ...state, errorMessage: action.payload };

    case 'SET_ERROR': {
      return { ...state, taskDetailErrors: action.payload };
    }

    default:
      throw new Error('Invalid action');
  }
};

function TaskDetailProvider({
  children,
  task,
  setTask,
  refetchTask,
  refetchSPR,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setError = (type, payload) => {
    dispatch({
      type: 'SET_ERROR',
      payload: { ...state.errors, [type]: payload },
    });
  };

  const setData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const [taskComments, setTaskComments] = useState({
    rows: [],
    count: 0,
    hasMore: false,
  });

  const [taskAttachments, setTaskAttachments] = useState({
    rows: [],
    count: 0,
    hasMore: false,
  });

  const [updateStandardPeerReviewTask] = useMutation(UPDATE_PEER_REVIEW, {
    onError: (requestError) => {
      setError('UPDATE_PEER_REVIEW', requestError.message ?? 'Unknown Error.');
    },
    onCompleted: (responseData) => {
      if (responseData?.updateStandardPeerReviewTask) {
        refetchSPR();
        refetchTask();
        setError('UPDATE_PEER_REVIEW', '');
        setData('SET_ERROR_MESSAGE', '');
        setData(
          'SET_SUCCESS_MESSAGE',
          `You have successfully edited ${task?.taskIdStr}!`,
        );
      }
    },
  });

  const [getUsers, { data }] = useLazyQuery(GET_ACTIVE_USERS_BY_ROLES, {
    fetchPolicy: 'network-only',
    onError: (requestError) => {
      setError('SET_USERS', requestError.message ?? 'Unknown Error.');
    },
    onCompleted: () => {
      setData('SET_USERS', data?.getActiveUsersByRoles);
      setError('SET_USERS', '');
    },
  });

  return (
    <TaskDetailContext.Provider
      value={{
        ...state,
        task,
        setData,
        setTask,
        getUsers,
        setError,
        refetchSPR,
        refetchTask,
        taskComments,
        taskAttachments,
        setTaskComments,
        setTaskAttachments,
        updateStandardPeerReviewTask,
      }}
    >
      {children}
    </TaskDetailContext.Provider>
  );
}

export default TaskDetailProvider;

TaskDetailProvider.propTypes = {
  children: PropTypes.node.isRequired,
  task: PropTypes.shape(TaskPropType).isRequired,
  setTask: PropTypes.func.isRequired,
  refetchTask: PropTypes.func.isRequired,
  refetchSPR: PropTypes.func.isRequired,
};

export const useTaskDetail = () => useContext(TaskDetailContext);
