import moment from 'moment';
import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_SOLICITATION_BY_ID } from '../../queries';
import {
  processOpenPeriod,
  processReopenPeriods,
} from '../common/reopen-periods/open-reopen-period-util';
import { AUDIENCE_MAP } from '../../constants';

export default function useOpenPeriod() {
  const [solicitation, setSolicitation] = useState({});
  const [solicitationID, setSolicitationID] = useState(0);
  const [openPeriod, setOpenPeriod] = useState({});
  const [currentPeriod, setCurrentPeriod] = useState({
    audience: AUDIENCE_MAP.Open,
  });
  const [contracts, setContracts] = useState([]);
  const [contractYear, setSolicitationContractYear] = useState(
    new Date().getFullYear(),
  );

  const [getSolicitationById, { loading: solicitationLoading }] = useLazyQuery(
    GET_SOLICITATION_BY_ID,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setSolicitation(data.getSolicitationById);
        setSolicitationID(data.getSolicitationById.id);
        setSolicitationContractYear(data.getSolicitationById.contractYear);
        const openPeriodTemp = processOpenPeriod([
          ...data.getSolicitationById.solicitationPeriods,
        ]);

        if (openPeriodTemp?.startDate) {
          setOpenPeriod(openPeriodTemp);
          setCurrentPeriod({
            startDate: moment(openPeriodTemp.startDate).format('YYYY-MM-DD'),
            endDate: moment(openPeriodTemp.endDate).format('YYYY-MM-DD'),
            startTime: moment(openPeriodTemp.startDate).format('HH:mm'),
            endTime: moment(openPeriodTemp.endDate).format('HH:mm'),
            audience: openPeriodTemp?.audience,
            vendors: openPeriodTemp?.solicitationPeriodVendors,
          });
        }

        if (solicitation.contracts && solicitation.contracts.length > 0) {
          setContracts(solicitation.contracts);
        }
      },
    },
  );

  const adjacentReopenPeriodStartDate = processReopenPeriods(
    solicitation?.solicitationPeriods || [],
  )?.[0]?.startDate;

  return {
    solicitationLoading,
    solicitation,
    solicitationID,
    openPeriod,
    currentPeriod,
    contractYear,
    contracts,
    getSolicitationById,
    adjacentReopenPeriodStartDate,
  };
}
