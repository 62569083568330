export const DEFAULT_OPTION = { value: '', label: '-Select-' };

export const getSortedOptions = (opts, withDefault = false) => {
  const sorted = opts.sort((a, b) => (a.label < b.label ? -1 : 1));
  return withDefault ? [DEFAULT_OPTION, ...sorted] : sorted;
};
export const getUniqueSortedOptions = (options, withDefault = false) => {
  const opts = [];
  options.forEach((op) => {
    if (opts.every((o) => o.value !== op.value)) opts.push(op);
  });
  return getSortedOptions(opts, withDefault);
};

const getVendorSolIds = (vendorId, data) => {
  const solIds = new Set();
  data
    .filter((item) => item.vendorId === vendorId)
    .forEach((item) => solIds.add(String(item.solicitationId)));
  return [...solIds];
};

export const getVendorOptions = (data) => {
  const vendorOpts = getSortedOptions(
    data.map((item) => ({
      value: item.vendorId,
      label: item.vendor?.vendorName,
    })),
    true,
  );
  return vendorOpts.map((opt) => ({
    ...opt,
    solicitationIds: getVendorSolIds(opt.value, data),
  }));
};

export const getSolicitationOptions = (data) => {
  return getSortedOptions(
    data.map((item) => ({
      value: String(item.solicitationId),
      label: item.solicitation?.solicitationUpiid,
    })),
    true,
  );
};
