import React from 'react';
import PropTypes from 'prop-types';
import { PageTitle } from '@gsa/afp-component-library';
import SolicitationNotification from '../../utils/solicitation-notification';
import SolicitationStepper from '../../components/solicitation-stepper';
import SolicitationStatusBadge from '../../components/solicitation-status-badge';

const SinSelectionHeader = ({
  solicitationNumber,
  solicitationStatus,
  solicitationContractYear,
  solicitationType,
  solicitationBidType,
}) => {
  return (
    <>
      <SolicitationNotification isModal={false} />
      <div className="grid-row">
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <PageTitle
          title={`${solicitationType} Solicitation - ${solicitationNumber}`}
          aria-label={`${solicitationType} Solicitation - ${solicitationNumber}`}
          tabIndex="0"
          className="margin-0"
        />
        <SolicitationStatusBadge
          status={solicitationStatus}
          className="radius-pill  margin-2 bg-gray-3  display-row"
          tabIndex="0"
          aria-label={solicitationStatus}
        />
      </div>

      <div className="margin-top-2">
        <SolicitationStepper
          currentStep={solicitationBidType === 'OUTSIDE_FLEET' ? 2 : 4}
        />
      </div>

      <div className="grid-row">
        <span
          className="text-black margin-top-2"
          aria-label="First select the applicable Program Name(s), then choose your Standard Items. "
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex="0"
        >
          First select the applicable Program name(s), then choose your Standard
          Items.
        </span>
      </div>
      <div className="grid-row margin-top-3">
        <span
          className="text-bold"
          aria-label="Contract Year"
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex="0"
        >
          Contract year
        </span>
        <span
          className="padding-left-8 text-normal"
          aria-label={solicitationContractYear}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex="0"
        >
          {solicitationContractYear}
        </span>
      </div>
    </>
  );
};

SinSelectionHeader.propTypes = {
  solicitationNumber: PropTypes.string.isRequired,
  solicitationStatus: PropTypes.string.isRequired,
  solicitationContractYear: PropTypes.string.isRequired,
  solicitationType: PropTypes.string.isRequired,
  solicitationBidType: PropTypes.string.isRequired,
};

export default SinSelectionHeader;
