import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@gsa/afp-component-library';

const readyColor = '#008817';
const notReadyColor = '#B50909';
const warningColor = '#FFBE2E';

const getReadyTag = (isReady) => (
  <div
    className="text-normal text-white padding-x-1 padding-y-2px radius-sm font-sans-xs"
    style={{ background: isReady ? readyColor : notReadyColor }}
  >
    {isReady ? 'READY' : 'ACTION REQUIRED'}
  </div>
);

export const AccordionTitle = ({ title, isReady, isHighlighted }) => {
  const tag = typeof isReady === 'boolean' ? getReadyTag(isReady) : null;
  return (
    <div className="display-flex flex-row flex-justify">
      <div>{title}</div>
      <div className="display-flex flex-row">
        {isHighlighted && (
          <div
            className="text-normal padding-x-1 padding-y-2px radius-sm font-sans-xs margin-right-1"
            style={{ background: warningColor }}
          >
            IMPORTED/COPIED
          </div>
        )}
        {tag}
      </div>
    </div>
  );
};
AccordionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  isReady: PropTypes.bool,
  isHighlighted: PropTypes.bool,
};
AccordionTitle.defaultProps = {
  isReady: false,
  isHighlighted: false,
};

export const ReadyIcon = ({ isReady }) => {
  if (isReady == null) return null;
  return (
    <Icon
      iconName={isReady ? 'check_circle' : 'cancel'}
      aria-label={isReady ? 'Ready' : 'Not ready'}
      style={{
        color: isReady ? readyColor : notReadyColor,
        fontSize: '1.75rem',
      }}
    />
  );
};
ReadyIcon.propTypes = {
  isReady: PropTypes.bool,
};
ReadyIcon.defaultProps = {
  isReady: undefined,
};

export const StatusIcon = ({ isReady }) => {
  if (isReady == null) return null;
  return (
    <Icon
      iconName={isReady ? 'check_circle' : 'warning'}
      aria-label={isReady ? 'Ready' : 'Not ready'}
      style={{
        color: isReady ? readyColor : warningColor,
        fontSize: '1.25rem',
        marginTop: '0.125rem',
      }}
    />
  );
};
StatusIcon.propTypes = {
  isReady: PropTypes.bool,
};
StatusIcon.defaultProps = {
  isReady: undefined,
};
