import React from 'react';
import { CounterTag } from '@gsa/afp-component-library';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useFvsSupportingDocs } from '../../fvs-supporting-docs-provider';
import VehicleStandardsSupportingDoc from './supporting-docs';

const RelatedInfo = () => {
  const { supportingDocs } = useFvsSupportingDocs();

  return (
    <div className="related-info-tabs margin-top-7">
      <h2>Related information</h2>
      <Tabs className="related-info-tabs-width">
        <TabList style={{ padding: 0 }}>
          <Tab data-testid="vehicle-standards-docs-tab">
            <span className="text-no-wrap text-bold">
              Documents
              {supportingDocs?.count > 0 && (
                <CounterTag
                  count={supportingDocs?.count}
                  className="margin-left-1"
                />
              )}
            </span>
          </Tab>
        </TabList>

        <TabPanel>
          <VehicleStandardsSupportingDoc />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default RelatedInfo;
