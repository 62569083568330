import React from 'react';
import { PageTitle } from '@gsa/afp-component-library';
import Breadcrumbs from '../../widgets/breadcrumbs';
import WelcomeMessage from '../../widgets/welcome-message';
import EquipmentCodesTable from './equipment-codes-table';
import EquipmentCodesProvider from './equipment-codes-provider';
import EquipmentCodeMessages from './equipment-code-messages';

const EquipmentCode = () => {
  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs.home}/home`,
      label: 'Home',
    },
  ];

  return (
    <EquipmentCodesProvider>
      <div className="grid-col">
        <Breadcrumbs path={breadcrumbsPath} current="Equipment Codes" />
        <EquipmentCodeMessages isModal={false} />
        <PageTitle
          aria-label="Equipment Codes"
          tabIndex="0"
          title="Equipment Codes"
        />
        <WelcomeMessage
          message="Equipment Codes (EC) are either standard or
        optional attributes that make up the requirements of a
        Standard Item Number. You may use the Equipment Code table to filter
        and update Equipment Codes. Click the 'View' link to view
        Standard Items that are associated with individual Equipment Codes."
        />
        <EquipmentCodesTable />
      </div>
    </EquipmentCodesProvider>
  );
};

export default EquipmentCode;
