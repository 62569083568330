/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from '@gsa/afp-component-library';

const RevealText = ({ className, title, copy, isExpanded }) => {
  const [reveal, setReveal] = useState(isExpanded);

  const classes = classnames('afp-reveal-text', className);
  return (
    <div className={classes}>
      <Button
        aria-haspopup="true"
        onClick={() => setReveal(!reveal)}
        className="display-flex flex-align-center"
        variant="unstyled"
        data-testid="afp-reveal-text"
        label={title}
        rightIcon={{
          name: reveal ? 'expand_less' : 'expand_more',
          className: `usa-icon--size-3 ${title && 'margin-left-05'}`,
        }}
      />
      {reveal && (
        <div
          className="margin-top-2"
          aria-hidden={reveal ? 'false' : 'true'}
          tabIndex="0"
        >
          {copy}
        </div>
      )}
    </div>
  );
};

RevealText.defaultProps = {
  title: null,
  className: '',
  isExpanded: false,
};
RevealText.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  isExpanded: PropTypes.bool,
  copy: PropTypes.shape(Object).isRequired,
};

export default RevealText;
