import { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useRecoilState } from 'recoil';
import {
  GET_SOLICITIATION_WITH_LINES,
  GET_SOLICITIATION_LINES,
  UPDATE_SOLICITATION_LINE,
} from '../../../../queries';
import solicitationNotifications from '../../../../utils/solicitation-notification-helpers';

export default function useAssignQuantity() {
  const [solicitationLines, setSolicitationLines] = useState([]);
  const [solicitation, setSolicitation] = useState({});
  const [notifications, setSolicitationNotification] = useRecoilState(
    solicitationNotifications,
  );

  const [getSolicitationById, { loading: solicitationLoading }] = useLazyQuery(
    GET_SOLICITIATION_WITH_LINES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setSolicitation(data.getSolicitationById);
      },
    },
  );

  const [
    getSolicitationLinesBySolId,
    { loading: solicitationLineLoading },
  ] = useLazyQuery(GET_SOLICITIATION_LINES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const lines = data.getSolicitationLines;
      setSolicitationLines(lines);
    },
  });

  const [updateSolicitationLine] = useMutation(UPDATE_SOLICITATION_LINE, {
    onCompleted: (data) => {
      if (data.updateSolicitationLines) {
        setSolicitationNotification([
          {
            id: 'UPDATE_SOLICITATION_LINE_SUCCESS',
            message: `Standard Item quantities have been saved.`,
            type: 'success',
            closeable: true,
            showInModal: false,
          },
        ]);
      }
    },
    onError: (requestError) => {
      setSolicitationNotification([
        {
          id: 'UPDATE_SOLICITATION_LINE_ERROR',
          message: requestError.message,
          type: 'error',
          closeable: false,
          showInModal: false,
        },
      ]);
    },
  });

  return {
    solicitationLines,
    setSolicitationLines,
    getSolicitationById,
    solicitation,
    updateSolicitationLine,
    getSolicitationLinesBySolId,
    setSolicitationNotification,
    notifications,
    solicitationLoading,
    solicitationLineLoading,
  };
}
