import { useCallback, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_CONTRACTLINE_ATTACHMENTS } from '../documentation/query';

const useDocumentationTab = (contractLineId) => {
  const [documents, setDocuments] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [docIdToSignedUrlMap, setDocIdToSignedUrlMap] = useState({});

  const getSignedUrl = useCallback(
    (docId) => {
      if (docIdToSignedUrlMap[docId]) return docIdToSignedUrlMap[docId];
      return null;
    },
    [docIdToSignedUrlMap],
  );

  const setSignedUrl = useCallback((docId, signedUrl) => {
    setDocIdToSignedUrlMap((prev) => ({
      ...prev,
      [docId]: signedUrl,
    }));
  }, []);

  const [docLoadingError, setLoadingError] = useState(null);
  const [getContractLineDocuments] = useLazyQuery(
    GET_CONTRACTLINE_ATTACHMENTS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const docs = data?.getContractLineAttachments?.filter(
          (d) => d.documentType === 'D',
        );
        const pics = data?.getContractLineAttachments?.filter(
          (d) => d.documentType === 'I',
        );
        setDocuments(docs);
        setPhotos(pics);
      },
      onError: (err) => {
        setLoadingError(err);
      },
    },
  );

  const loadContractLineAttachments = () => {
    getContractLineDocuments({
      variables: {
        contractLineId: parseFloat(contractLineId),
      },
    });
  };

  return {
    documents,
    setDocuments,
    photos,
    setPhotos,
    docLoadingError,
    loadContractLineAttachments,
    getSignedUrl,
    setSignedUrl,
  };
};

export default useDocumentationTab;
