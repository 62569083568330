import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import ActiveBids from './bids-active';
import BidsSolicitations from './bids-solicitations';
import { bidDashboardAdmivDefaultViewAtom } from '../atoms/bids-atoms';
import AdminBidSearchPanel from './components/admin-view/admin-bid-search-panel';
import AdminActiveBidVendors from './components/admin-view/admin-active-bid-vendors-table';
import BidManagementHeader from './components/bid-management-header';

const BidsAdminView = () => {
  const [defaultDisplayOption] = useRecoilState(
    bidDashboardAdmivDefaultViewAtom,
  );
  const [bidData, setBidData] = useState([]);

  const handleOnBidSearchCompletion = (data) => {
    setBidData(data);
  };

  return (
    <>
      <BidManagementHeader />
      <AdminBidSearchPanel
        onBidSearchCompletion={handleOnBidSearchCompletion}
      />
      {defaultDisplayOption === 'Solicitation number' && (
        <AdminActiveBidVendors bidData={bidData} />
      )}
      {(!defaultDisplayOption ||
        defaultDisplayOption !== 'Solicitation number') && (
        <div>
          <ActiveBids />
          <BidsSolicitations />
        </div>
      )}
    </>
  );
};

export default BidsAdminView;
