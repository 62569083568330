import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, connectModal } from '@gsa/afp-component-library';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import SolicitationPeriod from './solicitation-period';
import { transformDataSolicitationPeriod } from '../add-edit-solicitation/common/reopen-periods/open-reopen-period-util';
import useEditReopenPeriod from '../add-edit-solicitation/apis/edit-reopen-period-api';
import getEditReopenPeriodYupSchema from '../add-edit-solicitation/common/reopen-periods/edit-reopen-period-yup-schema';
import { END_DATE_TIME_MSG } from '../constants';
import { verifyIsSolicitationStatusOpen } from '../utils/solicitation-status-utils';
import { OpenSolicitationReadonlyEditPeriodEndDateTimeFields } from '../utils/solicitation-period-utils';

const EditReopenPeriodModal = ({
  currentPeriodData: rawCurrentPeriod,
  previousPeriodData: rawPreviousPeriodData,
  nextPeriodData: rawNextPeriodData,
  openPeriod,
  onClose,
  solicitationID: solicitationIDFromProps,
  solicitationStatus,
}) => {
  const { id = '' } = useParams();
  const solicitationID = solicitationIDFromProps || id;

  const isSolStatusOpen = verifyIsSolicitationStatusOpen(
    solicitationStatus,
    rawCurrentPeriod.startDate,
  );
  const isStartDatePastToday = moment(rawCurrentPeriod.startDate).isBefore(
    moment(),
    'day',
  );

  const readonlyFields = useMemo(() => {
    if (moment(rawCurrentPeriod?.startDate).isBefore(moment())) {
      if (moment(rawCurrentPeriod?.endDate).isBefore(moment())) {
        return OpenSolicitationReadonlyEditPeriodEndDateTimeFields;
      }

      return ['startDate', 'startTime', 'audience', 'vendor'];
    }

    return [];
  }, [rawCurrentPeriod]);

  const currentPeriodData = transformDataSolicitationPeriod(rawCurrentPeriod);
  const previousPeriodData = transformDataSolicitationPeriod(
    rawPreviousPeriodData || openPeriod,
  );
  const nextPeriodData = transformDataSolicitationPeriod(rawNextPeriodData);
  const schema = getEditReopenPeriodYupSchema({
    nextPeriodData,
    rawNextPeriodData,
    rawPreviousPeriodData,
    previousPeriodData,
    readonlyFields,
    isStartDatePastToday,
  });
  const {
    register,
    handleSubmit,
    control,
    watch,
    trigger,
    formState: { errors },
  } =
    useForm({
      resolver: yupResolver(schema),
      mode: 'onBlur',
      reValidateMode: 'onChange',
      defaultValues: {
        audience: 'Open',
        ...currentPeriodData,
      },
    }) || {};

  const { submitEditReopenPeriod } = useEditReopenPeriod({
    solicitationID,
    currentPeriodData,
  });

  const onSubmit = (data) => {
    submitEditReopenPeriod(data);
    onClose();
  };
  const handleOnSave = handleSubmit(onSubmit);
  const minDate = moment().isSameOrAfter(previousPeriodData?.endDate)
    ? moment().format()
    : previousPeriodData?.endDate;

  return (
    <Modal
      title={
        <h2 aria-label={currentPeriodData?.period}>
          {currentPeriodData?.period}
        </h2>
      }
      onClose={onClose}
      variant="large"
      actions={
        <>
          <Button
            type="button"
            variant="unstyled"
            onClick={onClose}
            aria-label="Cancel"
            label="Cancel"
          />
          <Button
            type="button"
            variant="primary"
            onClick={handleOnSave}
            label="Save"
            aria-label="Save"
            className="margin-205"
          />
        </>
      }
    >
      <SolicitationPeriod
        stepperStage="open"
        title=""
        solicitationOprType="edit"
        formRegister={register}
        formControl={control}
        formWatch={watch}
        formErrors={errors}
        formValidationTrigger={trigger}
        currentSolicitationPeriod={currentPeriodData}
        minDate={minDate}
        maxDate={nextPeriodData?.startDate}
        readonlyFields={readonlyFields}
        endDateTimeMsg={isSolStatusOpen && END_DATE_TIME_MSG}
        idPrefix="editReopenPeriod"
        rawSolPeriod={rawCurrentPeriod}
      />
    </Modal>
  );
};
const PeriodDataShape = PropTypes.shape({
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  period: PropTypes.string,
});
EditReopenPeriodModal.propTypes = {
  currentPeriodData: PeriodDataShape.isRequired,
  nextPeriodData: PeriodDataShape,
  previousPeriodData: PeriodDataShape,
  onClose: PropTypes.func.isRequired,
  solicitationID: PropTypes.string,
  openPeriod: PeriodDataShape,
  solicitationStatus: PropTypes.string,
};
EditReopenPeriodModal.defaultProps = {
  nextPeriodData: null,
  previousPeriodData: null,
  solicitationID: '',
  openPeriod: null,
  solicitationStatus: undefined,
};

export default connectModal(EditReopenPeriodModal);
