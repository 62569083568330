import React from 'react';
import { AFPTableRowAction } from '@gsa/afp-component-library';
import { enDashUnicode } from '../../../../utilities/constants';

export const INPUT_DROPDOWN_DEFAULT_OPTION = {
  label: '-Select-',
  value: '',
};

export const DELETE_ACTION = 'Delete';

export const COLOR_TYPE = {
  standard: 'Standard',
  optional: 'Premium',
};

const colorsActions = [
  {
    icon: 'cancel',
    label: DELETE_ACTION,
  },
];

export const getColorsReadOnlyColumns = (gsaColors) => [
  {
    Header: 'Vendor color name',
    accessor: 'vendorColorName',
    sortable: false,
  },
  {
    Header: 'Vendor color code',
    accessor: 'vendorColorCode',
    sortable: false,
  },
  {
    Header: 'GSA color',
    accessor: 'gsaColorCodeId',
    sortable: false,
    Cell: ({ value }) =>
      gsaColors.find((c) => +c.id === value)?.title || enDashUnicode,
  },
  {
    Header: 'Standard or optional',
    accessor: 'standardPremiumCode',
    sortable: false,
    Cell: ({ value }) => {
      if (value === COLOR_TYPE.standard) return 'Standard';
      if (value === COLOR_TYPE.optional) return 'Optional';
      return enDashUnicode;
    },
  },
  {
    Header: 'Price',
    accessor: 'price',
    sortable: false,
    cellClassName: 'cell-right',
    Cell: ({ value }) =>
      (value || 0).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
  },
];

export const getColorColumns = (onActionClick, gsaColors) => [
  {
    Header: 'Vendor color name',
    accessor: 'vendorColorName',
    sortable: false,
  },
  {
    Header: 'Vendor color code',
    accessor: 'vendorColorCode',
    sortable: false,
  },
  {
    Header: 'GSA color',
    accessor: 'gsaColorCodeId',
    sortable: false,
    Cell: ({ value }) => {
      const label =
        gsaColors.find((c) => +c.id === value)?.title || enDashUnicode;
      return label;
    },
  },
  {
    Header: 'Standard or optional',
    accessor: 'standardPremiumCode',
    sortable: false,
    Cell: ({ value }) => {
      let label = enDashUnicode;
      if (value === COLOR_TYPE.standard) label = 'Standard';
      if (value === COLOR_TYPE.optional) label = 'Optional';
      return label;
    },
  },
  {
    Header: 'Price',
    accessor: 'price',
    sortable: false,
    cellClassName: 'cell-right',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => {
      return (
        <span>
          {(value || 0).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </span>
      );
    },
  },
  {
    Header: 'Actions',
    sortable: false,
    // eslint-disable-next-line react/prop-types
    Cell: ({ row }) => {
      return (
        <AFPTableRowAction
          row={row}
          actions={colorsActions}
          onSelectAction={(action) => onActionClick(action, row)}
        />
      );
    },
  },
];
