import moment from 'moment';
import * as Yup from 'yup';
import { parseDateString } from '../../solicitation/utils/solicitation-period-utils';
import { getTimestampFromDateNTime } from '../../solicitation/add-edit-solicitation/common/reopen-periods/open-reopen-period-util';

const CURRENT_DATE = moment();

const updateLineItemsSchema = (
  awardedDate,
  contractTerminationDate,
  contractEndDate,
) =>
  Yup.object().shape({
    openDate: Yup.date()
      .typeError('Invalid date format')
      .nullable()
      .transform(parseDateString)
      .min(
        moment(CURRENT_DATE).startOf('day'),
        'The open date/time cannot be prior to the current date/time.',
      )
      .test({
        name: 'openDate-validation',
        skipAbsent: true,
        test(openDate, { createError }) {
          if (awardedDate && moment(openDate).isSameOrBefore(moment(awardedDate)))
            return createError({
              message:
                'The open date/time cannot be prior to the contract award date.',
            });
          return true;
        },
      })
      .required('Open date is required'),
    openTime: Yup.string()
      .nullable()
      .when(['openDate'], {
        is: (openDate) => openDate && moment(openDate).isValid,
        then: Yup.string()
          .required('Open time is required.')
          .test({
            name: 'is-valid-date-range',
            skipAbsent: true,
            test(openTime, { createError, parent }) {
              const { openDate } = parent || {};
              const openDateTime = getTimestampFromDateNTime(
                openDate,
                openTime,
              );
              if (moment(openDate).isSame(moment(), 'day') && moment().isAfter(moment(openDateTime), 'minutes')) {
                return createError({
                  message:
                    'The open time cannot be prior to the current date/time.',
                });
              }

              return true;
            },
          }),
      })
      .required('Open time is required'),
    closeDate: Yup.date()
      .typeError('Invalid date format')
      .nullable()
      .transform(parseDateString)
      .test({
        name: 'closeDate-validation',
        skipAbsent: true,
        test(closeDate, { createError, parent }) {
          const { openDate } = parent || {};
          if (openDate && moment(openDate).isAfter(moment(closeDate)))
            return createError({
              message:
                'The closeout date/time cannot be prior to the open date/time.',
            });
          if (
            contractTerminationDate &&
            moment(contractTerminationDate).isBefore(moment(closeDate))
          )
            return createError({
              message:
                'The closeout date/time cannot be past the contract termination date.',
            });
          if (
            contractEndDate &&
            moment(contractEndDate).isBefore(moment(closeDate))
          )
            return createError({
              message:
                'The closeout date/time cannot be past the contract end date.',
            });
          return true;
        },
      })
      .required('Closeout date is required'),
    closeTime: Yup.string().nullable().required('Closeout time is required'),
    isDirect: Yup.string().typeError('Direct value is required').required('Direct value is required'),
  });

export default updateLineItemsSchema;
