export const isLineDetailTabFieldrequired = (isAwardedInFleet, fieldName) => {
  const fields = {
    participant1122Prog: true,
    shipmentDays: true,
    shipmentJustification: false,
    associatedLocations: isAwardedInFleet,
    clarifications: false,
    technicalExceptions: false,
  };
  return fields[fieldName];
};

export const isLineTabFieldrequired = (_isAwardedInFleet, fieldName) => {
  const fields = {
    gsaModel: true,
    modelYear: true,
    openDate: false,
    openTime: false,
    closedOutDate: false,
    closedOutTime: false,
    additionalInfo: false,

    allocation: false,
    estimatedTotalQuantity: true,
    estimatedFleetQuantity: true,

    currentPrice: true,
    previousPrice: false,
    netInvoicePrice: true,
    netMsrpPrice: true,
    asePrice: true,
    destinationCharge: false,
  };
  return fields[fieldName];
};
