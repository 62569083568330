import React from 'react';
import { PropTypes } from 'prop-types';
import { Button, StatusBadge } from '@gsa/afp-component-library';
import BidStandardItemClassificationInfo from './bid-standard-item-classification';
import { getMakeModelStatusBadgeConfig } from '../helpers';
import { emDashUnicode, emSpaceUnicode } from '../../../utilities/constants';

const BidStandardItemDetailsSubRow = ({
  original,
  openMakeModelModal,
  setSelectedItem,
  isBidClosed,
}) => {
  const onClickEdit = () => {
    setSelectedItem(original);
    openMakeModelModal();
  };

  return (
    <div className="grid-container">
      <div className="grid-row grid-gap">
        <div className="grid-col-8">
          <div>
            <div className="grid-row solicitation-separator padding-bottom-1  padding-top-1">
              <div className="grid-col-3 text-bold">Line item</div>
              <div className="grid-col-3 text-bold">Body Make and Model</div>
              <div className="grid-col-3 text-bold">Chassis Make and Model</div>
              <div className="grid-col-3 text-bold">Engineer review status</div>
            </div>
            {original.afpMake?.map((item) => {
              const badge = getMakeModelStatusBadgeConfig(
                item?.makeModelApproval,
              );

              return (
                <div
                  className="grid-row solicitation-separator padding-bottom-1"
                  key={item.bidLineId}
                >
                  <div className="grid-col-3">
                    {item?.scheduleLine || emDashUnicode}
                  </div>
                  <div className="grid-col-3">
                    {item?.make?.makeName || emDashUnicode}
                    {emSpaceUnicode}
                    {item?.model?.modelName || emDashUnicode}
                  </div>
                  <div className="grid-col-3">
                    {item?.chassisMake?.makeName || emDashUnicode}
                    {emSpaceUnicode}
                    {item?.chassisModel?.modelName || emDashUnicode}
                  </div>
                  <div className="grid-col-3">
                    {badge ? (
                      <StatusBadge variant={badge.variant}>
                        {badge.label}
                      </StatusBadge>
                    ) : (
                      emDashUnicode
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="grid-col-4">
          <BidStandardItemClassificationInfo original={original} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 margin-top-4">
          {!isBidClosed && (
            <Button
              variant="outline"
              label="Edit Make and Model selections"
              onClick={onClickEdit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

BidStandardItemDetailsSubRow.propTypes = {
  original: PropTypes.shape({
    afpMake: PropTypes.arrayOf(
      PropTypes.shape({
        scheduleLine: PropTypes.string,
        make: PropTypes.shape({
          makeName: PropTypes.string,
          approvalStatus: PropTypes.string,
        }),
        model: PropTypes.shape({
          modelName: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
  openMakeModelModal: PropTypes.func.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
  isBidClosed: PropTypes.bool.isRequired,
};

export default BidStandardItemDetailsSubRow;
