import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@gsa/afp-component-library';

const ContractHeaderMaximumValueModal = ({
  onComplete,
  closeModal,
  disabled,
}) => {
  return (
    <Modal
      id="contract-header-maximum-value-modal"
      variant="large"
      className="solicitation-modal"
      onClose={closeModal}
      actions={
        <>
          <Button
            type="button"
            variant="unstyled"
            onClick={closeModal}
            label="Cancel"
            disabled={disabled}
          />
          <Button
            data-testid="modal-save-changes-button"
            type="button"
            onClick={onComplete}
            label="Save changes"
            disabled={disabled}
          />
        </>
      }
    >
      The contract maximum value cannot be edited once saved. Do you want to
      proceed?
    </Modal>
  );
};

ContractHeaderMaximumValueModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ContractHeaderMaximumValueModal.defaultProps = {
  disabled: false,
};

export default ContractHeaderMaximumValueModal;
