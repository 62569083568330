export const REPORTS_ALL = {
  SOP: [
    'contractPricing',
    'assemblyPointInspectionPoint',
    'contractsAwarded',
    'contractPriceComparison',
    'contractEngineAndFuel',
    'contractEngineAndFuelDelivery',
    'contractStatus',
    'fuelUnknown',
    'contractLineVersionComparison',
  ],
  'Non SOP': [
    'contractPricing',
    'assemblyPointInspectionPoint',
    'contractsAwarded',
    'contractStatus',
  ],
};
export const REPORTS_VS = {
  SOP: [
    'contractPricing',
    'contractPriceComparison',
    'contractEngineAndFuelDelivery',
    'contractStatus',
  ],
};

export const getReportTypes = (reports) => Object.keys(reports);

export const FT_CSV = 'CSV';
export const FT_XLSX = 'XLSX';
export const FT_PDF = 'PDF';
export const REPORT_CONFIG = {
  contractPricing: {
    reportTypeId: 602,
    label: 'Contract pricing report',
    fileTypes: [FT_XLSX, FT_PDF],
    description: '',
    solVendorSS: true,
  },
  assemblyPointInspectionPoint: {
    reportTypeId: 601,
    label: 'Assembly point/Inspection point summary',
    fileTypes: [FT_XLSX],
    description: '',
    solVendorSS: true,
  },
  contractsAwarded: {
    reportTypeId: 603,
    label: 'Contracts awarded report',
    fileTypes: [FT_XLSX],
    description: '',
    solVendorSS: true,
  },
  contractEngineAndFuel: {
    reportTypeId: 604,
    label: 'Contract engine and fuel report',
    fileTypes: [FT_XLSX],
    description: '',
  },
  contractEngineAndFuelDelivery: {
    reportTypeId: 605,
    label: 'Contract engine and fuel delivery report',
    fileTypes: [FT_XLSX],
    description: '',
    solVendorSS: true,
  },
  contractStatus: {
    reportTypeId: 606,
    label: 'Contract status report',
    fileTypes: [FT_XLSX],
    description: '',
  },
  contractPriceComparison: {
    reportTypeId: 607,
    label: 'Contract price comparison report',
    fileTypes: [FT_XLSX],
    description: '',
    solVendorSS: true,
  },
  fuelUnknown: {
    reportTypeId: 608,
    label: 'Fuel unknown report',
    fileTypes: [FT_XLSX],
    description: '',
    solVendorSS: true,
  },
  contractLineVersionComparison: {
    reportTypeId: 609,
    label: 'Contract line version comparison report',
    fileTypes: [FT_XLSX, FT_PDF],
    description: '',
    solVendorSS: true,
  },
};

export const API_TIME_LIMIT = 30000; // ms (30 seconds)
