import { CounterTag, StatusBadge, PageTitle } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import CloseTaskButton from './CloseTaskButton';
import { useTaskDetail } from '../task-detail-provider';
import { statusMap, TaskStatus } from '../helpers/taskStatus';

const Status = ({ status }) => (
  <StatusBadge variant={statusMap(status).color}>
    {TaskStatus(status)}
  </StatusBadge>
);
Status.propTypes = { status: PropTypes.string.isRequired };

const TypeTag = ({ type }) => <span>{`Task Type: ${type}`}</span>;
TypeTag.propTypes = { type: PropTypes.string.isRequired };

const DocumentsTag = ({ count }) => (
  <CounterTag count={count}>Supporting Documents</CounterTag>
);
DocumentsTag.propTypes = { count: PropTypes.number.isRequired };

const CommentsTag = ({ count }) => (
  <CounterTag count={count}>Comments</CounterTag>
);
CommentsTag.propTypes = { count: PropTypes.number.isRequired };

const Title = () => {
  const { task, taskAttachments, taskComments } = useTaskDetail();

  // quick fix for correctly displaying comments tag
  const countCommentsNotDeleted = (comments) => {
    let count = 0;
    if (comments) {
      comments.map((comment) => {
        if (!comment.deletedAt) {
          count += 1;
        }
        return count;
      });
    }
    return count;
  };

  return (
    <div className="text-no-wrap display-flex flex-align-center flex-wrap">
      <div className="margin-right-2">
        <PageTitle area-label={task.taskID} tabIndex="0" title={task.taskID} />
      </div>
      <div className="grid-row grid-gap flex-justify">
        <TypeTag type={task.taskType.taskTypeName} />
        <DocumentsTag count={taskAttachments.count} />
        <CommentsTag count={countCommentsNotDeleted(taskComments.rows)} />
        <div>
          <Status status={task.status} />
        </div>
      </div>
      <div className="display-flex desktop:flex-justify-end flex-fill margin-y-2">
        <CloseTaskButton />
      </div>
    </div>
  );
};

export default Title;
