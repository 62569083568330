import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { keyBy } from 'lodash';
import { getValidator } from '../../utilities/validations/error-rules';
import { codeNoSpecialCharacter } from '../../utilities/validations/validation-rules';

yup.addMethod(
  yup.string,
  'afpValidate',
  function callback(errorMessage, field, isValid) {
    return this.test(field, errorMessage, function getError() {
      const { path, createError } = this;
      return isValid || createError({ path, message: errorMessage });
    });
  },
);

const fuelTypeFields = (category) => {
  return category === 'Fuel Type'
    ? {
        fastCode: yup
          .string()
          .required('Fast code is required.')
          .max(30, 'Max 30 characters allowed.')
          .test(
            'special characters',
            'No special characters except ( ) and -',
            (value) => !codeNoSpecialCharacter(value),
          ),
        shortHand: yup.string().required('ShortHand is required.'),
        allowNewBids: yup.string().required('New Bids is required.'),
      }
    : {};
};

const PocSchema = yup.object().shape({
  firstName: yup.string().required('This is a required field.'),
  lastName: yup.string().required('This is a required field.'),
  email: yup
    .string()
    .email('This is not a valid email.')
    .required('This is a required field.'),
  phoneNumber: yup.string().required('This is a required field.'),
});

const catalogCodesSchema = (catalogCodeCategory, canAddCoPocs = false) => {
  return yup.object().shape({
    code: yup.lazy((value) => {
      const isValid = getValidator(value, catalogCodeCategory);
      if (value !== undefined && !isValid) {
        return yup
          .string()
          .afpValidate(
            'Make sure the code meets the requirements listed below.',
            'code',
            isValid,
          )
          .required();
      }
      return yup.mixed().notRequired();
    }),

    title: yup.lazy((value) => {
      const isValid = getValidator(value, 'Title');
      if (value !== undefined && !isValid) {
        return yup
          .string()
          .afpValidate(
            'Make sure the title meets the requirements listed below.',
            'title',
            isValid,
          )
          .required();
      }
      return yup.mixed().notRequired();
    }),
    sequence: yup.lazy((value) => {
      const isSequenceValid = getValidator(value, 'Sequence');
      if (value !== undefined && !isSequenceValid) {
        return yup
          .string()
          .afpValidate(
            'Make sure the Sequence meets the requirements listed below.',
            'sequence',
            isSequenceValid,
          )
          .required();
      }
      return yup.string().notRequired();
    }),
    parentCode: yup.lazy((value) => {
      if (value !== undefined) {
        return yup.string().required('Parent Category Code is required.');
      }
      return yup.mixed().notRequired();
    }),
    description: yup.string(),
    tags: yup.array().of(yup.string()),
    ...(catalogCodeCategory === 'Vehicle Group'
      ? {
          vehicleGroupPOCs: yup.object().shape({
            PocSeniorEngineer: PocSchema,
            PocPrimaryEngineer: PocSchema,
            ...(canAddCoPocs
              ? {
                  PocSeniorCO: PocSchema,
                  PocPrimaryCO: PocSchema,
                }
              : {}),
          }),
        }
      : {}),
    ...fuelTypeFields(catalogCodeCategory),
  });
};

const getInputData = (catalogCode) => {
  const pocs = (catalogCode?.standardsVehicleGroupPocs || []).map((poc) => {
    return {
      firstName: poc.name?.split(' ')[0],
      lastName: poc.name?.split(' ')[1],
      pocType: poc.pocType,
      email: poc.email,
      phoneNumber: poc.phoneNumber,
    };
  });
  const vehicleGroupPOCs = keyBy(pocs, 'pocType') || {};
  return {
    category: catalogCode?.category,
    code: catalogCode?.code,
    title: catalogCode?.title,
    tags: catalogCode?.tags?.value || [],
    parentCategory: catalogCode?.parentCategory,
    parentCode: catalogCode?.parentCode,
    additionalProps: catalogCode?.additionalProps || {},
    description: catalogCode?.description,
    vehicleGroupPOCs,
  };
};

const getFormContext = (category, catalogCode, canAddCoPocs) => {
  const methods = useForm({
    resolver: yupResolver(catalogCodesSchema(category, canAddCoPocs)),
    defaultValues: getInputData(catalogCode),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  return methods;
};

export default getFormContext;
