import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from '@gsa/afp-component-library';

export const PredeterminedInput = ({ preDefinedValue }) => {
  const [expanded, setExpanded] = useState(false);

  const iconName = expanded ? 'expand_less' : 'expand_more';

  const listClass = classnames('grid-column margin-top-2', 'expandable-list', {
    'expand-predetermined': !!expanded,
  });

  return (
    <div className="grid-row">
      <Button
        variant="unstyled"
        onClick={() => setExpanded(!expanded)}
        label="Predetermined value"
        rightIcon={{ name: iconName }}
      />
      <div className={listClass}>
        {preDefinedValue?.value?.map((v) => (
          <div key={v}>{v}</div>
        ))}
      </div>
    </div>
  );
};

const PreDefinedValueShape = {
  value: PropTypes.arrayOf(PropTypes.string),
};

PredeterminedInput.defaultProps = {
  preDefinedValue: { value: [] },
};

PredeterminedInput.propTypes = {
  preDefinedValue: PropTypes.objectOf(PreDefinedValueShape),
};

export const InputType = ({
  row: {
    original: { inputType, unit, lowerBound, upperBound, preDefinedValue },
  },
}) => {
  switch (inputType?.code?.toLowerCase()) {
    case 'c': // Comply yes/no
      return 'Comply yes/no';
    case 'p': // Predefined value
      return <PredeterminedInput preDefinedValue={preDefinedValue} />;
    case 'n': {
      // Numerical
      if (lowerBound && unit?.title) return `${lowerBound} ${unit.title}`;
      return '-';
    }
    case 'r': {
      // Numerical range
      if (lowerBound && upperBound && unit?.title)
        return `${lowerBound}-${upperBound} ${unit.title}`;
      return '-';
    }
    default:
      return inputType?.title ?? '-';
  }
};
