import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { FilterPanel } from '@gsa/afp-component-library';
import VendorSearchTypeahead from './filter-items/vendorNameTypeahead';
import { useFederalStandards } from '../fvs-provider';
import { useFvsPreBidSelectionsReport } from './pre-bid-report-provider';
import { STANDARD_ITEM_TYPEAHEAD_OPTIONS_SEARCH } from '../../../services/data-store';

const PreBidReportFilters = ({ setFilters }) => {
  const {
    selectedYear,
    selectedOptions,
    vehicleClassificationOptions,
    applicableFvsYears,
  } = useFederalStandards();

  const { setFVSPreBidReportContextData } = useFvsPreBidSelectionsReport();

  const { FilterPanel: PreBidReportFilterPanel } = FilterPanel;

  const [reRenderKey, setReRenderKey] = useState(1);
  const [typeaheadData, setTypeaheadData] = useState([]);
  const [sinWithIds, setSinWithIds] = useState([]);
  const [, setError] = useState(null);

  const [defaultFilters] = useState({
    year: selectedYear.toString() || new Date().getFullYear().toString(),
    vehicleGroup: selectedOptions?.vehicleGroup || '',
    fedStandardCode: selectedOptions?.fedStandardCode || '',
  });

  // eslint-disable-next-line
  let filters = [
    {
      key: 'year',
      title: 'Contract year',
      type: 'select',
      value: defaultFilters.year,
      operator: '$or',
      options: applicableFvsYears?.map((year) => ({
        label: year.label,
        value: year.value,
      })),
      expanded: true,
      hideClear: true,
    },
    {
      key: 'vendor',
      title: 'Vendor name',
      id: 'vendorName',
      ariaLabel: 'Search by Vendor name',
      value: '',
      operator: 'EQ',
      component: VendorSearchTypeahead,
      isSingleValueFilter: true,
      permanent: false,
      expanded: true,
      hideClear: true,
      customFieldProps: {
        inputCharNum: 2,
        help: 'Search by Vendor name or Unique Entity Identifier',
      },
    },
    {
      key: 'intentionToBid',
      title: 'Intention to bid',
      type: 'multiselect',
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
      value: [],
      operator: '$or',
      expanded: true,
      hideClear: true,
    },
    {
      key: 'standardItemNumber',
      title: 'Standard Item',
      type: 'typeahead',
      value: '',
      operator: 'EQ',
      expanded: true,
      hideClear: true,
      customFieldProps: { inputCharNum: 1 },
    },
    {
      key: 'vehicleGroup',
      title: 'Vehicle group',
      type: 'select',
      options: vehicleClassificationOptions?.vehicleGroup || [],
      value: defaultFilters.vehicleGroup,
      operator: '$or',
      expanded: true,
      hideClear: false,
    },
    {
      key: 'fedStandardCode',
      title: 'Federal Standard Code',
      type: 'select',
      options: vehicleClassificationOptions?.fedStandardCode || [],
      value: defaultFilters.fedStandardCode,
      operator: '$exact',
      expanded: true,
      hideClear: true,
    },
  ];

  const [getFilterTypeAheadOptions] = useLazyQuery(
    STANDARD_ITEM_TYPEAHEAD_OPTIONS_SEARCH,
    {
      fetchPolicy: 'network-only',
      onError: () => {
        setError('Error fetching Standard Item type-ahead options');
        setTypeaheadData([]);
      },
      onCompleted: ({ getStandardItemTypeAheadOptions }) => {
        if (getStandardItemTypeAheadOptions) {
          const { options, key, optionsWithIds } =
            getStandardItemTypeAheadOptions;
          setSinWithIds(optionsWithIds);
          setTypeaheadData({ field: key, values: options });
        }
      },
    },
  );

  const handleFiltersChange = (updatedFilters) => {
    const filterByKeys = updatedFilters?.reduce(
      (filterObj, currentFilterObj) => {
        return { ...filterObj, [currentFilterObj?.key]: currentFilterObj };
      },
      {},
    );

    const appliedFilters = {
      operator: 'AND',
      value: [],
    };

    if (filterByKeys?.year?.value) {
      appliedFilters.value.push({
        key: `$preBidSelection.contract_year$`,
        operator: 'EQ',
        value: parseInt(filterByKeys?.year?.value, 10),
      });
      setFVSPreBidReportContextData(
        'SET_PBR_SELECTED_YEAR',
        parseInt(filterByKeys?.year?.value, 10),
      );
    }
    if (filterByKeys?.vendor?.value) {
      appliedFilters.value.push({
        key: `$preBidSelection.vendor_id$`,
        operator: 'EQ',
        value: filterByKeys?.vendor?.value,
      });
    }
    if (filterByKeys?.fedStandardCode?.value) {
      const value = [filterByKeys?.fedStandardCode?.value];
      appliedFilters.value.push({
        key: '$preBidSelection.standardItem.fed_standard_code$',
        operator: 'IN',
        value,
      });
      setFVSPreBidReportContextData('SET_SELECTED_CODES', value);
      setFVSPreBidReportContextData('SET_VEHICLE_GROUP_REPORTING', '');
    }

    if (filterByKeys?.intentionToBid?.value) {
      const newValues = filterByKeys?.intentionToBid?.value?.map((v) =>
        v === 'Yes' ? 1 : 0,
      );
      appliedFilters.value.push({
        key: '$preBidSelection.intent_to_bid$',
        operator: 'IN',
        value: newValues,
      });
    }
    if (filterByKeys?.vehicleGroup?.value) {
      appliedFilters.value.push({
        key: '$preBidSelection.vehicle_group_code$',
        operator: 'EQ',
        value: filterByKeys?.vehicleGroup?.value,
      });
      setFVSPreBidReportContextData(
        'SET_VEHICLE_GROUP_REPORTING',
        filterByKeys?.vehicleGroup?.value,
      );
      setFVSPreBidReportContextData('SET_SELECTED_CODES', []);
    }
    if (filterByKeys?.standardItemNumber?.value) {
      const sinId = sinWithIds?.find(
        (sin) =>
          sin.standardItemNumber === filterByKeys?.standardItemNumber?.value,
      )?.id;
      appliedFilters.value.push({
        key: '$preBidSelection.standard_item_id$',
        operator: 'IN',
        value: [sinId],
      });
    }
    setFilters({ ...appliedFilters });
  };

  const handleFetchTypeaheads = ({ variables: queryVariables }) => {
    const selectedKey = queryVariables?.field;
    const filterObj = queryVariables?.filters[0]?.conditions?.find(
      (filter) => filter.key === selectedKey,
    );

    if (filterObj?.key === 'standardItemNumber') {
      const { value } = filterObj;
      const variables = {
        key: selectedKey,
        search: value,
        filters: [
          {
            operator: '$and',
            conditions: [
              { operator: '$exact', key: 'year', value: defaultFilters.year },
            ],
          },
        ],
      };
      getFilterTypeAheadOptions({ variables });
    }
  };

  return (
    <PreBidReportFilterPanel
      key={reRenderKey} // This is the key that will force the component to rerender
      clearButtonLabel="Reset All"
      filterStructure={filters}
      model="PreBidSelection"
      setQueryFiltersState={handleFiltersChange}
      fetchTypeaheads={handleFetchTypeaheads}
      typeaheadData={typeaheadData}
      showClearIcon
      handleClearAll={() => {
        setReRenderKey(reRenderKey + 1);
      }}
      retainFilters={false}
    />
  );
};

PreBidReportFilters.propTypes = {
  setFilters: PropTypes.func.isRequired,
};

export default PreBidReportFilters;
