/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import classNames from 'classnames';
import {
  AFPTableRowAction,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import { useBidLineDetails } from './bid-line-details-provider';
import {
  TextInputField,
  NumberInputField,
  SelectDropdownField,
  MultiSelectDropdownField,
  CheckboxField,
  RadioGroupField,
  TextareaInputField,
} from '../components/table-input-fields';
import { TableExpanderCell } from '../components/table-components';
import { ReadyIcon } from '../components/tags';
import {
  CHARGE_TYPES,
  DC_FAST_CHARGE_TYPES,
  FAST_CHARGE_TYPE,
} from './helpers';
import { emDashUnicode, enDashUnicode } from '../../../../utilities/constants';

const SELECT_OPTION = { label: '-Select-', value: '' };

const findEngineByRow = (engineData, row) =>
  engineData.find(({ id }) => id === row.original.id);

// AFP table column generator for "Engine and fuel" tab
const ActionsCell = ({ row }) => {
  const { engineData, setEngine } = useBidLineDetails();
  const original = findEngineByRow(engineData, row);

  const actDelivery = {
    icon: 'edit',
    label: 'Enter delivery info',
  };
  const actFuelData = {
    icon: 'edit',
    label: 'Enter fuel data',
  };
  const actClarification = {
    icon: 'edit',
    label: 'Add / edit clarification',
  };
  const actionsList = original.requiresOCONUS ? [] : [actDelivery];
  if (Object.keys(original.fuelInputMap).length > 0)
    actionsList.push(actFuelData);
  actionsList.push(actClarification);

  return (
    <AFPTableRowAction
      row={row}
      actions={actionsList}
      onSelectAction={() => {
        if (!row.original.isExpanded)
          setEngine(row.original.id, { table: { isExpanded: true } });
      }}
    />
  );
};

export const getEngineReadOnlyColumns = () => {
  return [
    {
      // Controlled expander cell
      id: 'expander', // needs an ID w/o Header
      sortable: false,
      headerClassName: 'cell-expander',
      Cell: ({ row }) => {
        return <TableExpanderCell row={row} isReadyOnly />;
      },
    },
    {
      Header: 'Engines and base',
      accessor: 'equipment',
      sortable: false,
    },
    {
      Header: 'Engine model',
      sortable: false,
      Cell: ({ row }) => {
        const { engineData } = useBidLineDetails();
        const original = findEngineByRow(engineData, row);
        return original.engineModel;
      },
    },
    {
      Header: 'Fuel type',
      sortable: false,
      Cell: ({ row }) => {
        const { engineData, FuelTypes } = useBidLineDetails();
        const original = findEngineByRow(engineData, row);
        const options = [SELECT_OPTION];
        FuelTypes.forEach((ft) =>
          options.push({ label: `${ft.code} ${ft.title}`, value: ft.id }),
        );
        return (
          <SelectDropdownField
            original={original}
            readonly
            options={options}
            field="fuelTypeCodeInput"
            id="fuel-type"
            label="Fuel type"
            required
            hideLabel
          />
        );
      },
    },
    {
      Header: 'Ready',
      sortable: false,
      Cell: ({ row }) => {
        const { engineData } = useBidLineDetails();
        const original = findEngineByRow(engineData, row);
        return <ReadyIcon isReady={!!original.ready} />;
      },
    },
  ];
};

export const getEngineColumns = ({ onUpdate }) => {
  return [
    {
      // Controlled expander cell
      id: 'expander', // needs an ID w/o Header
      sortable: false,
      headerClassName: 'cell-expander',
      Cell: ({ row }) => {
        const {
          bidLine,
          engineData,
          setEngine,
          hasSomeHighlightByRowIds,
        } = useBidLineDetails();

        const original = findEngineByRow(engineData, row);

        const hasSomeEngineHighlight = hasSomeHighlightByRowIds(
          'engine',
          'bid_line_engine',
          [original.bidLineEngineId],
          ['engineModel', 'fuelTypeCodeId'],
        );

        const engine = bidLine.engines?.find(
          (e) => e.id === original.bidLineEngineId,
        );
        const engineClarification = engine?.clarification;
        const hasAClarificationHighlight = hasSomeHighlightByRowIds(
          'engine',
          'bid_line_clarification',
          [engineClarification?.id],
        );

        const hasMpgHighlight = hasSomeHighlightByRowIds(
          'engine',
          'bid_line_engine_mpg',
          engine?.mpgs?.map((m) => m.id),
        );
        const hasChargingHighlight = hasSomeHighlightByRowIds(
          'engine',
          'bid_line_engine_charging',
          [engine?.charging?.id],
        );
        const hasFastChargeTypeHighlight = hasSomeHighlightByRowIds(
          'engine',
          'bid_line_engine_charging_fc_type',
          engine?.charging?.fastChargeTypes?.map((fct) => fct.id),
        );
        return (
          <div
            className={
              hasSomeEngineHighlight ||
              hasAClarificationHighlight ||
              hasMpgHighlight ||
              hasChargingHighlight ||
              hasFastChargeTypeHighlight
                ? 'bid-line-highlighted-input-data-only-button'
                : ''
            }
            style={{ padding: 0 }}
          >
            <TableExpanderCell
              row={row}
              onClick={(isExpanded) => {
                setEngine(row.original.id, { table: { isExpanded } });
              }}
            />
          </div>
        );
      },
    },
    {
      Header: 'Engines and base',
      accessor: 'equipment',
      sortable: false,
    },
    {
      Header: 'Engine model',
      sortable: false,
      Cell: ({ row }) => {
        const {
          engineData,
          findHighlight,
          removeHighlight,
        } = useBidLineDetails();
        const original = findEngineByRow(engineData, row);
        const highlight = findHighlight(
          'engine',
          'bid_line_engine',
          original.bidLineEngineId,
          'engineModel',
        );
        const onInputUpdate = useCallback(
          (_original, type, value) => {
            if (highlight) {
              removeHighlight(highlight.id);
            }
            onUpdate(_original, type, value);
          },
          [highlight, removeHighlight, onUpdate],
        );
        return (
          <TextInputField
            original={original}
            onUpdate={onInputUpdate}
            field="engineModel"
            id="engine-model"
            label="Engine model"
            hideLabel
            isHighlighted={highlight}
          />
        );
      },
    },
    {
      Header: 'Fuel type',
      sortable: false,
      Cell: ({ row }) => {
        const {
          engineData,
          FuelTypes,
          findHighlight,
          removeHighlight,
        } = useBidLineDetails();
        const original = findEngineByRow(engineData, row);
        const options = [SELECT_OPTION];
        FuelTypes.forEach((ft) =>
          options.push({ label: `${ft.code} ${ft.title}`, value: ft.id }),
        );
        const highlight = findHighlight(
          'engine',
          'bid_line_engine',
          original.bidLineEngineId,
          'fuelTypeCodeId',
        );
        const onInputUpdate = useCallback(
          (_original, type, value) => {
            if (highlight) {
              removeHighlight(highlight.id);
            }
            onUpdate(_original, type, value);
          },
          [highlight, removeHighlight, onUpdate],
        );
        return (
          <SelectDropdownField
            original={original}
            onUpdate={onInputUpdate}
            isHighlighted={highlight}
            options={options}
            field="fuelTypeCodeInput"
            id="fuel-type"
            label="Fuel type"
            required
            hideLabel
          />
        );
      },
    },
    {
      Header: 'Ready',
      sortable: false,
      Cell: ({ row }) => {
        const { engineData } = useBidLineDetails();
        const original = findEngineByRow(engineData, row);
        return <ReadyIcon isReady={!!original.ready} />;
      },
    },
    {
      Header: 'Actions',
      sortable: false,
      Cell: ActionsCell,
    },
  ];
};

// Expanded row input field blocks
const DeliveryBlock = ({ original, onUpdate, States, isReadOnly }) => {
  const { findHighlight, removeHighlight } = useBidLineDetails();
  const highlight = findHighlight(
    'engine',
    'bid_line_engine',
    original.bidLineEngineId,
    'deliveryRegionType',
  );

  const onInputUpdate = useCallback(
    (_original, type, value) => {
      if (highlight) {
        removeHighlight(highlight.id);
      }
      onUpdate(_original, type, value);
    },
    [highlight, removeHighlight, onUpdate],
  );

  if (original.requiresOCONUS) return null;

  const stateOptions = States.map((st) => ({
    label: st.stateName,
    value: st.stateCode,
  }));
  const deliveryOptions = [
    { value: '50Plus5', label: '50 states and 5 U.S. territories' },
    { value: 'All50', label: '50 states only' },
    { value: 'Lower48', label: 'Lower 48 only' },
    { value: 'Other', label: 'Other' },
  ];

  const cs = classNames('margin-bottom-2', {
    'bid-line-highlighted-input-data': highlight,
    'padding-1': highlight,
  });

  return (
    <div className={cs}>
      <div className="text-bold margin-bottom-1">
        CONUS delivery {!isReadOnly && <RequiredFieldIndicator />}
      </div>
      {isReadOnly ? (
        <span>
          {
            deliveryOptions.find((v) => v.value === original.deliveryRegionType)
              ?.label
          }
        </span>
      ) : (
        <RadioGroupField
          original={original}
          onUpdate={onInputUpdate}
          options={deliveryOptions}
          field="deliveryRegionType"
          id="delivery-region"
          hideLabel
        />
      )}
      {original.deliveryRegionType === 'Other' && (
        <div className="margin-top-05 margin-left-4">
          {isReadOnly ? (
            <span>
              {original.deliveryStatesInput
                .map(
                  (sCode) =>
                    States.find((s) => s.stateCode === sCode)?.stateName,
                )
                .join(', ')}
            </span>
          ) : (
            <MultiSelectDropdownField
              original={original}
              onUpdate={onUpdate}
              options={stateOptions}
              field="deliveryStatesInput"
              id="delivery-states"
              label="Select states or territories to which you deliver"
              required
            />
          )}
        </div>
      )}
    </div>
  );
};

const DisplacementBlock = ({ original, onUpdate, isReadOnly }) => {
  const { findHighlight, removeHighlight } = useBidLineDetails();
  const engineLitresHighlight = findHighlight(
    'engine',
    'bid_line_engine',
    original.bidLineEngineId,
    'engineLitres',
  );
  const cylindersHighlight = findHighlight(
    'engine',
    'bid_line_engine',
    original.bidLineEngineId,
    'cylinders',
  );

  if (!original.fuelInputMap.displacement) return null;
  return (
    <div className="margin-bottom-2">
      <div className="text-bold margin-bottom-1">Engine displacement</div>
      <div className="display-flex flex-row">
        <div className="margin-right-2 maxw-15">
          <NumberInputField
            original={original}
            onUpdate={(o, f, v) => {
              onUpdate(o, f, v);
              if (engineLitresHighlight) {
                removeHighlight(engineLitresHighlight.id);
              }
            }}
            field="engineLitresInput"
            id="engine-litres"
            label="Liters"
            required
            validate={(n) => n > 0}
            readonly={isReadOnly}
            isHighlighted={engineLitresHighlight}
          />
        </div>
        <div className="maxw-15">
          <NumberInputField
            original={original}
            onUpdate={(o, f, v) => {
              onUpdate(o, f, v);
              if (cylindersHighlight) {
                removeHighlight(cylindersHighlight.id);
              }
            }}
            field="cylindersInput"
            id="cylinders"
            label="Cylinders"
            required
            isInt
            validate={(n) => n > 0}
            readonly={isReadOnly}
            isHighlighted={cylindersHighlight}
          />
        </div>
      </div>
    </div>
  );
};

const RangeBlock = ({ original, onUpdate, isReadOnly }) => {
  const { findHighlight, removeHighlight } = useBidLineDetails();
  const rangeHighlight = findHighlight(
    'engine',
    'bid_line_engine',
    original.bidLineEngineId,
    'range',
  );
  const rangeElectricHighlight = findHighlight(
    'engine',
    'bid_line_engine',
    original.bidLineEngineId,
    'rangeElectric',
  );
  if (!original.fuelInputMap.range && !original.fuelInputMap.rangeE)
    return null;
  const requireRangeTotal =
    original.requiresMPG &&
    !original.fuelInputMap.rangeE &&
    !original.requiresOCONUS &&
    !original.isFuelDataUnknown;
  return (
    <div className="margin-bottom-2">
      <div className="text-bold margin-bottom-1">
        Range in {original.requiresOCONUS ? 'kilometers' : 'miles'} (estimated)
      </div>
      <div className="display-flex flex-row">
        {original.fuelInputMap.range && (
          <div className="margin-right-2 maxw-15">
            <NumberInputField
              original={original}
              onUpdate={(o, f, v) => {
                onUpdate(o, f, v);
                if (rangeHighlight) {
                  removeHighlight(rangeHighlight.id);
                }
              }}
              field="rangeInput"
              id="total-range"
              label="Total"
              required={requireRangeTotal}
              isInt
              validate={(n) => n > 0}
              readonly={isReadOnly}
              isHighlighted={rangeHighlight}
            />
          </div>
        )}
        {original.fuelInputMap.rangeE && (
          <div className="maxw-15">
            <NumberInputField
              original={original}
              onUpdate={(o, f, v) => {
                onUpdate(o, f, v);
                if (rangeElectricHighlight) {
                  removeHighlight(rangeElectricHighlight.id);
                }
              }}
              field="rangeElectricInput"
              id="electric-range"
              label="All electric"
              required
              isInt
              validate={(n) => n > 0}
              readonly={isReadOnly}
              isHighlighted={rangeElectricHighlight}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const MpgBlock = ({ original, onUpdate, fuelType, isReadOnly }) => {
  const { bidLine, findHighlight, removeHighlight } = useBidLineDetails();
  const mpgs = bidLine.engines
    ?.find((e) => e.id === original.bidLineEngineId)
    ?.mpgs.filter((m) => m.fuelType === fuelType);

  const cityHighlight = findHighlight(
    'engine',
    'bid_line_engine_mpg',
    mpgs?.find((m) => m.type === 'City')?.id,
    'value',
  );

  const highwayHighlight = findHighlight(
    'engine',
    'bid_line_engine_mpg',
    mpgs?.find((m) => m.type === 'Highway')?.id,
    'value',
  );

  const combinedHighlight = findHighlight(
    'engine',
    'bid_line_engine_mpg',
    mpgs?.find((m) => m.type === 'Combined')?.id,
    'value',
  );

  const gpmiHighlight = findHighlight(
    'engine',
    'bid_line_engine_mpg',
    mpgs?.find((m) => m.type === 'Grams/mi')?.id,
    'value',
  );

  if (!original.fuelInputMap[`mpg${fuelType}`]) return null;

  const header = original.requiresOCONUS ? 'Km/L' : 'MPG';
  const title = {
    C: `${header} conventional fuel`,
    A: `${header} alternative fuel`,
    E: `${header} electric`,
  };
  const getEmissionLabel = () => {
    if (!original.requiresOCONUS) return 'Grams/mi';
    return original.requiresGPTKM ? 'G/t-km' : 'G/km';
  };
  const required =
    original.requiresMPG &&
    !original.requiresOCONUS &&
    !original.isFuelDataUnknown;
  return (
    <div className="margin-bottom-2">
      <div className="text-bold margin-bottom-1">{title[fuelType]}</div>
      <div className="display-flex flex-row">
        <div className="margin-right-2 maxw-15">
          <NumberInputField
            original={original}
            onUpdate={(o, f, v) => {
              onUpdate(o, f, v);
              if (cityHighlight) {
                removeHighlight(cityHighlight.id);
              }
            }}
            field={`mpg${fuelType}.cityInput`}
            id={`mpg-${fuelType}-city`}
            label="City"
            required={required}
            validate={(n) => n > 0}
            readonly={isReadOnly}
            isHighlighted={cityHighlight}
          />
        </div>
        <div className="margin-right-2 maxw-15">
          <NumberInputField
            original={original}
            onUpdate={(o, f, v) => {
              onUpdate(o, f, v);
              if (highwayHighlight) {
                removeHighlight(highwayHighlight.id);
              }
            }}
            field={`mpg${fuelType}.highwayInput`}
            id={`mpg-${fuelType}-highway`}
            label="Highway"
            required={required}
            isInt
            validate={(n) => n > 0}
            readonly={isReadOnly}
            isHighlighted={highwayHighlight}
          />
        </div>
        <div className="margin-right-2 maxw-15">
          <NumberInputField
            original={original}
            onUpdate={(o, f, v) => {
              onUpdate(o, f, v);
              if (combinedHighlight) {
                removeHighlight(combinedHighlight.id);
              }
            }}
            field={`mpg${fuelType}.combinedInput`}
            id={`mpg-${fuelType}-combined`}
            label="Combined"
            required={required}
            validate={(n) => n > 0}
            readonly={isReadOnly}
            isHighlighted={combinedHighlight}
          />
        </div>
        {fuelType !== 'E' && (
          <div className="margin-right-2 maxw-15">
            <NumberInputField
              original={original}
              onUpdate={(o, f, v) => {
                onUpdate(o, f, v);
                if (gpmiHighlight) {
                  removeHighlight(gpmiHighlight.id);
                }
              }}
              field={`mpg${fuelType}.gpmiInput`}
              id={`mpg-${fuelType}-gpmi`}
              label={getEmissionLabel()}
              required={required}
              validate={(n) => n > 0}
              readonly={isReadOnly}
              isHighlighted={gpmiHighlight}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const ChargingBlock = ({ original, onUpdate, isReadOnly }) => {
  const { bidLine, findHighlight, removeHighlight } = useBidLineDetails();
  const engine = bidLine.engines?.find(
    (e) => e.id === original.bidLineEngineId,
  );
  const chargingTypeHighlight = findHighlight(
    'engine',
    'bid_line_engine_charging',
    engine?.charging?.id,
    'type',
  );

  const fastChargeCapableHighlight = findHighlight(
    'engine',
    'bid_line_engine_charging',
    engine?.charging?.id,
    'isFastChargeCapable',
  );

  const bkwhHighlight = findHighlight(
    'engine',
    'bid_line_engine_charging',
    engine?.charging?.id,
    'bkwh',
  );

  if (!original.fuelInputMap.charging) return null;

  const fcCapableOptions = [
    SELECT_OPTION,
    { label: 'Yes', value: 'Y' },
    { label: 'No', value: 'N' },
  ];
  const typeOptions = (original.requiresOCONUS
    ? CHARGE_TYPES.slice(1)
    : CHARGE_TYPES
  ).map((i) => ({ label: i, value: i }));

  return (
    <div className="margin-bottom-2">
      <div className="text-bold margin-bottom-1">Charging</div>
      <div
        className={`display-flex flex-row ${
          isReadOnly ? '' : 'flex-align-end'
        }`}
      >
        <div className="margin-right-2 width-card-lg">
          <MultiSelectDropdownField
            original={original}
            onUpdate={(o, f, v) => {
              onUpdate(o, f, v);
              if (chargingTypeHighlight) {
                removeHighlight(chargingTypeHighlight.id);
              }
            }}
            options={typeOptions}
            field="charging.typeInput"
            id="charge-types"
            label="Type of charging"
            readonly={isReadOnly}
            required
            isHighlighted={chargingTypeHighlight}
          />
        </div>
        <div className="margin-right-2 width-card-lg">
          <SelectDropdownField
            original={original}
            onUpdate={(o, f, v) => {
              onUpdate(o, f, v);
              if (fastChargeCapableHighlight) {
                removeHighlight(fastChargeCapableHighlight.id);
              }
            }}
            options={fcCapableOptions}
            field="charging.isFastChargeCapableInput"
            id="fast-charge-capable"
            label="DC fast charge capable"
            required
            readonly={isReadOnly}
            disabled={original.charging.typeInput.includes(FAST_CHARGE_TYPE)}
            isHighlighted={fastChargeCapableHighlight}
          />
        </div>
        <div className="margin-right-2 width-card-lg">
          <NumberInputField
            original={original}
            onUpdate={(o, f, v) => {
              onUpdate(o, f, v);
              if (bkwhHighlight) {
                removeHighlight(bkwhHighlight.id);
              }
            }}
            field="charging.bkwhInput"
            id="charge-bkwh"
            label="Kilowatt hours (BKWH)"
            required
            validate={(n) => n > 0}
            readonly={isReadOnly}
            isHighlighted={bkwhHighlight}
          />
        </div>
      </div>
    </div>
  );
};

const FastChargeTypeRow = ({ original, fcType, onUpdate, isReadOnly }) => {
  const { bidLine, findHighlight, removeHighlight } = useBidLineDetails();
  const engine = bidLine.engines?.find(
    (e) => e.id === original.bidLineEngineId,
  );

  const fcTypeId = engine?.charging?.fastChargeTypes?.find(
    (fct) => fct.fastChargeType === fcType,
  )?.id;
  const chargeOptionHighlight = findHighlight(
    'engine',
    'bid_line_engine_charging_fc_type',
    fcTypeId,
    'chargeOption',
  );

  const otherHighlight = findHighlight(
    'engine',
    'bid_line_engine_charging_fc_type',
    fcTypeId,
    'userEnteredType',
  );

  const { checked, userEnteredType, chargeOption } = original.charging.fcTypes[
    fcType
  ];

  if (isReadOnly)
    return checked ? (
      <div className="grid-row">
        <div className="grid-col-2">
          {fcType === 'Other' ? userEnteredType || '(Other)' : fcType}
        </div>
        <div className="grid-col-2">{chargeOption || enDashUnicode}</div>
      </div>
    ) : null;

  const fcChargeOptions = [
    SELECT_OPTION,
    { label: 'Standard', value: 'Standard' },
    { label: 'Adapter', value: 'Adapter' },
  ];
  return (
    <div className="grid-row margin-top-1 minh-5">
      <div className="grid-col-3">
        <div className="display-flex flex-row padding-right-2">
          <CheckboxField
            original={original}
            onUpdate={onUpdate}
            field={`charging.fcTypes.${fcType}.checked`}
            id={`fc-type-${fcType}-checkbox`}
            label={fcType === 'Other' && checked ? '' : fcType}
            disabled={isReadOnly}
          />
          {fcType === 'Other' && checked && (
            <TextInputField
              original={original}
              onUpdate={(o, f, v) => {
                onUpdate(o, f, v);
                if (otherHighlight) {
                  removeHighlight(otherHighlight.id);
                }
              }}
              field={`charging.fcTypes.${fcType}.userEnteredType`}
              id={`fc-type-${fcType}-user-type`}
              required
              label="Type"
              hideLabel
              readOnly={isReadOnly}
              placeholder="Enter type"
              isHighlighted={otherHighlight}
            />
          )}
        </div>
      </div>
      <div className="grid-col-2">
        {checked && (
          <SelectDropdownField
            original={original}
            onUpdate={(o, f, v) => {
              onUpdate(o, f, v);
              if (chargeOptionHighlight) {
                removeHighlight(chargeOptionHighlight.id);
              }
            }}
            options={fcChargeOptions}
            field={`charging.fcTypes.${fcType}.chargeOption`}
            id={`fc-type-${fcType}-option`}
            required
            label="Option"
            hideLabel
            readonly={isReadOnly}
            isHighlighted={chargeOptionHighlight}
          />
        )}
      </div>
    </div>
  );
};

const FastChargeTypesBlock = ({ original, onUpdate, isReadOnly }) => {
  const fcTypeList = original.requiresOCONUS
    ? DC_FAST_CHARGE_TYPES.slice(1)
    : [DC_FAST_CHARGE_TYPES[0], ...DC_FAST_CHARGE_TYPES.slice(2)];
  const hasSelection = fcTypeList.some(
    (fct) => original.charging.fcTypes[fct].checked,
  );
  return (
    <div className="margin-top-2">
      <div className="text-bold margin-bottom-1">DC fast charge types</div>
      <div className="grid-row">
        <div
          className={isReadOnly ? 'grid-col-2' : 'grid-col-3 padding-left-4'}
        >
          Type {!isReadOnly && <RequiredFieldIndicator />}
        </div>
        {(isReadOnly || hasSelection) && (
          <div className="grid-col-2">
            Option {!isReadOnly && <RequiredFieldIndicator />}
          </div>
        )}
      </div>
      {fcTypeList.map((fcType) => (
        <FastChargeTypeRow
          original={original}
          fcType={fcType}
          onUpdate={onUpdate}
          isReadOnly={isReadOnly}
        />
      ))}
    </div>
  );
};

const ClarificationBlock = ({ original, onUpdate }) => {
  const { bidLine, findHighlight, removeHighlight } = useBidLineDetails();
  const engineClarification = bidLine.engines?.find(
    (e) => e.id === original.bidLineEngineId,
  )?.clarification;
  const highlight = findHighlight(
    'engine',
    'bid_line_clarification',
    engineClarification?.id,
    'clarification',
  );
  const onInputUpdate = useCallback(
    (_original, type, value) => {
      if (highlight) {
        removeHighlight(highlight.id);
      }
      onUpdate(_original, type, value);
    },
    [highlight, removeHighlight, onUpdate],
  );
  return (
    <div>
      <div className="text-bold margin-bottom-1">Clarification</div>
      <TextareaInputField
        original={original}
        onUpdate={onInputUpdate}
        field="explanation"
        id="explanation-textarea"
        label="Clarification"
        hideLabel
        characterLimit={500}
        isHighlighted={highlight}
      />
    </div>
  );
};

// Readonly expanded row
export const EngineExpandedReadonlyRow = ({ row }) => {
  const { engineData, States } = useBidLineDetails();
  const original = findEngineByRow(engineData, row);

  return (
    <div className="grid-row margin-y-1" key={original.id}>
      <div className="grid-col-3">
        <DeliveryBlock original={original} States={States} isReadOnly />
      </div>
      <div className="grid-col-9 padding-left-4">
        {Object.keys(original.fuelInputMap).length > 0 &&
          original.isFuelDataUnknown && (
            <div className="margin-bottom-2 full-width padding-top-0 padding-bottom-2">
              Fuel-related data is unknown at this time and will be provided
              within 60 days of contract award.
            </div>
          )}
        <div className="display-flex flex-row">
          <div className="margin-right-6">
            <DisplacementBlock original={original} isReadOnly />
            <RangeBlock original={original} isReadOnly />
          </div>
          <div>
            <MpgBlock original={original} isReadOnly fuelType="C" />
            <MpgBlock original={original} isReadOnly fuelType="A" />
            <MpgBlock original={original} isReadOnly fuelType="E" />
          </div>
        </div>
        <div className="margin-bottom-4">
          <ChargingBlock original={original} isReadOnly />
          {original.charging.isFastChargeCapableInput === 'Y' && (
            <FastChargeTypesBlock original={original} isReadOnly />
          )}
        </div>
        <section>
          <header className="title-s-caps text-primary">Clarification</header>
          <div className="margin-top-1">
            {original.explanation || emDashUnicode}
          </div>
        </section>
      </div>
    </div>
  );
};

// Expanded row
export const EngineExpandedRow = ({ row, onUpdate, isReadOnly = false }) => {
  const {
    engineData,
    States,
    findHighlight,
    removeHighlight,
  } = useBidLineDetails();
  const original = findEngineByRow(engineData, row);
  const fuelDataUnknownHighlight = findHighlight(
    'engine',
    'bid_line_engine',
    original.bidLineEngineId,
    'isFuelDataUnknown',
  );

  return (
    <div className="grid-row margin-y-1" key={original.id}>
      <div className="grid-col-3">
        <DeliveryBlock
          original={original}
          onUpdate={onUpdate}
          States={States}
        />
      </div>
      <div className="grid-col-9 padding-left-4">
        {Object.keys(original.fuelInputMap).length > 0 && (
          <div
            className={`margin-bottom-2 full-width border-1px border-gray-10 padding-top-0 padding-x-2 padding-bottom-2 ${
              fuelDataUnknownHighlight ? 'bid-line-highlighted-input-data' : ''
            }`}
          >
            <CheckboxField
              original={original}
              onUpdate={(o, f, v) => {
                onUpdate(o, f, v);
                if (fuelDataUnknownHighlight) {
                  removeHighlight(fuelDataUnknownHighlight.id);
                }
              }}
              field="isFuelDataUnknown"
              id="fuel-data-unknown"
              label="Fuel-related data is unknown at this time and will be provided within 60 days of contract award."
              disabled={isReadOnly}
            />
          </div>
        )}
        <div className="display-flex flex-row">
          <div className="margin-right-6">
            <DisplacementBlock original={original} onUpdate={onUpdate} />
            <RangeBlock original={original} onUpdate={onUpdate} />
          </div>
          <div>
            <MpgBlock original={original} onUpdate={onUpdate} fuelType="C" />
            <MpgBlock original={original} onUpdate={onUpdate} fuelType="A" />
            <MpgBlock original={original} onUpdate={onUpdate} fuelType="E" />
          </div>
        </div>
        <div className="margin-bottom-4">
          <ChargingBlock original={original} onUpdate={onUpdate} />
          {original.charging.isFastChargeCapableInput === 'Y' && (
            <FastChargeTypesBlock
              original={original}
              onUpdate={onUpdate}
              isReadOnly={isReadOnly}
            />
          )}
        </div>
        <ClarificationBlock original={original} onUpdate={onUpdate} />
      </div>
    </div>
  );
};
