import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@gsa/afp-component-library';

const getUserGuidePath = (guideName = '') =>
  `${process.env.PUBLIC_URL}/user-guides/${guideName}`;

const UserGuideLink = ({ name, ...restProps }) => {
  return (
    <Link
      href={getUserGuidePath(name)}
      target="_blank"
      rel="noreferrer"
      {...restProps}
    />
  );
};

UserGuideLink.propTypes = {
  name: PropTypes.string.isRequired,
};

export default UserGuideLink;
