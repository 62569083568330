import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import {
  Modal,
  Button,
  DetailsTable,
  MultiSelectDropdown,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import OverlaySpinner from '../../../../components/overlay-spinner';
import { COPY_MODAL_TITLE, COPY_MODAL_TEXT, COPY_TYPE } from './helpers';
import { GET_BID_LINES, COPY_BID_LINE_DETAILS_TAB } from '../provider/queries';
import CopyResultsMessage from './copy-results-message';
import CanIChangeBidData from '../../../bids/components/protect-bid-data-crud';

const CopyLineItemModal = ({
  bidLine,
  tabKey,
  onClose,
  setAlert,
  onCopySuccess,
}) => {
  const [options, setOptions] = useState([]);
  const [selectedLines, setSelectedLines] = useState([]);
  const [selectError, setSelectError] = useState(false);
  const [noResults, setNoResults] = useState('');
  const [copyingMessage, setCopyingMessage] = useState(null);

  const { loading, data: bidLinesData } = useQuery(GET_BID_LINES, {
    variables: { bidId: bidLine.bidId.toString() },
    fetchPolicy: 'network-only',
    onCompleted: ({ getBidStandardItems }) => {
      const lineItems = getBidStandardItems.filter(
        (item) => item.scheduleLine && item.id !== bidLine.id.toString(),
      );
      setOptions(
        lineItems
          .map((item) => ({
            label: item.scheduleLine,
            value: `${item.id}:${item.scheduleLine}`,
          }))
          .sort((optA, optB) => (optA.label < optB.label ? -1 : 1)),
      );
      setNoResults('No line items found');
    },
    onError: () => {
      setNoResults('Unable to load line items');
    },
  });

  const [copyTab, { loading: copying }] = useMutation(
    COPY_BID_LINE_DETAILS_TAB,
    { onError: () => {} },
  );

  const copyFromData = [
    ['Line item', bidLine.scheduleLine],
    [
      'Body make and model',
      `${bidLine.afpMake.makeName} ${bidLine.afpModel.modelName}`,
    ],
    [
      'Chassis make and model',
      bidLine.chassisModel
        ? `${bidLine.chassisMake.makeName} ${bidLine.chassisModel.modelName}`
        : undefined,
    ],
    ['Model year', bidLine.modelYear],
    ['Standard Item', bidLine.standardItem.title],
  ];
  const onSelectChange = (val) => {
    setSelectError(selectedLines.length > 0 && val.length === 0);
    setSelectedLines(val);
  };

  const onSubmit = async (copyType) => {
    if (!selectedLines.length) {
      setSelectError(true);
      return;
    }

    const responses = [];
    for (let i = 0; i < selectedLines.length; i += 1) {
      const [lineId, scheduleLine] = selectedLines[i].split(':');
      const toBidLineId = +lineId;
      const input = {
        copyType,
        tabKey,
        fromBidLineId: +bidLine.id,
        toBidLineIds: [toBidLineId],
      };

      setCopyingMessage(
        `Copying to line item ${i + 1} of ${
          selectedLines.length
        }: ${scheduleLine} ...`,
      );
      // eslint-disable-next-line
      const res = await copyTab({ variables: { input } });
      if (res) responses.push(...res.data.copyBidLineDetailsTab);
      else
        responses.push({
          fromBidLineId: +bidLine.id,
          toBidLineId,
          error: 'API call failed',
        });
    }
    setCopyingMessage(null);
    onCopySuccess?.();
    setAlert({
      type: 'info',
      message: (
        <CopyResultsMessage
          tabKey={tabKey}
          responses={responses}
          bidLines={bidLinesData.getBidStandardItems}
        />
      ),
    });
    onClose();
  };

  const text = COPY_MODAL_TEXT[tabKey];

  return (
    <div className="afp-modal-wrapper edit-conflicts-modal">
      <div className="afp-modal-overlay">
        <Modal
          id="copy-line-item-modal"
          variant="large"
          title={<h1>Copy {COPY_MODAL_TITLE[tabKey]}</h1>}
          onClose={onClose}
          actions={
            <div>
              <Button
                data-testid="close-copy-line-item-modal-btn"
                variant="unstyled"
                onClick={onClose}
                label="Cancel"
              />
              <CanIChangeBidData>
                <Button
                  data-testid="copy-line-item-modal-append-btn"
                  variant="primary"
                  onClick={() => onSubmit(COPY_TYPE.append)}
                  label="Append"
                />
                <Button
                  data-testid="copy-line-item-modal-overwrite-btn"
                  variant="primary"
                  onClick={() => onSubmit(COPY_TYPE.overwrite)}
                  label="Overwrite"
                />
              </CanIChangeBidData>
            </div>
          }
        >
          {loading && <OverlaySpinner />}
          {copying && <OverlaySpinner message={copyingMessage} />}

          {text && <div>{text}</div>}

          <div className="margin-top-4 text-bold text-primary">COPY FROM</div>
          <DetailsTable data={copyFromData} />

          <div className="margin-top-4 text-bold text-primary">COPY TO</div>
          <div>
            Choose which Line Items you would like to copy information to.
          </div>
          <div
            className={
              selectError
                ? 'border-left-05 border-secondary-dark padding-left-2'
                : ''
            }
          >
            <div className="text-bold margin-y-1">
              Line item(s) <RequiredFieldIndicator />
            </div>
            {selectError && (
              <div className="text-bold text-secondary-dark">
                Line item(s) is required
              </div>
            )}
            <MultiSelectDropdown
              data-testid="copy-to-line-items-multiselect"
              noResults={noResults}
              selectedValues={selectedLines}
              options={options}
              onChange={onSelectChange}
            />
          </div>

          <div className="margin-top-6">
            <span className="text-bold">Append</span> will add the copied data
            to the existing data.
          </div>
          <div className="margin-bottom-neg-2">
            <span className="text-bold">Overwrite</span> will replace the
            existing data.
          </div>
        </Modal>
      </div>
    </div>
  );
};

CopyLineItemModal.propTypes = {
  bidLine: PropTypes.shape({
    id: PropTypes.string,
    bidId: PropTypes.number,
    scheduleLine: PropTypes.string,
    modelYear: PropTypes.number,
    afpMake: PropTypes.shape({ makeName: PropTypes.string }),
    afpModel: PropTypes.shape({ modelName: PropTypes.string }),
    chassisMake: PropTypes.shape({ makeName: PropTypes.string }),
    chassisModel: PropTypes.shape({ modelName: PropTypes.string }),
    standardItem: PropTypes.shape({ title: PropTypes.string }),
  }).isRequired,
  tabKey: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  onCopySuccess: PropTypes.func,
};

CopyLineItemModal.defaultProps = {
  onCopySuccess: undefined,
};

export default CopyLineItemModal;
