import React from 'react';
import { PageTitle } from '@gsa/afp-component-library';
import Breadcrumbs from '../../widgets/breadcrumbs';
import WelcomeMessage from '../../widgets/welcome-message';
import StandardItemsTable from './standard-item-table';
import StandardItemsProvider from './standards-provider';
import { SI_WELCOME_TEXT } from './constants';
import StandardItemMessage from './standard-item-message';

const StandardItem = () => {
  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs.home}/home`,
      label: 'Home',
    },
  ];

  return (
    <StandardItemsProvider>
      <div className="grid-col">
        <Breadcrumbs current="Standard Items" path={breadcrumbsPath} />
        <StandardItemMessage isModal={false} />
        <PageTitle
          aria-label="Standard Items"
          tabIndex="0"
          title="Standard Items"
        />
        <WelcomeMessage message={SI_WELCOME_TEXT} />
        <StandardItemsTable />
      </div>
    </StandardItemsProvider>
  );
};

export default StandardItem;
