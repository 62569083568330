/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, ErrorMessage } from '@gsa/afp-component-library';
import { Controller } from 'react-hook-form';

const EffectiveDateField = ({ fieldRef, props }) => {
  const { documentTitle, control, errors } = props;

  return (
    <div className="card">
      <div className="usa-form-group">
        <div
          className="grid-row flex-column padding-bottom-1"
          id="attachment-effective-date-hint"
        >
          <span className="text-normal">
            Please select the effective date for document
          </span>
          <span className="text-bold">{documentTitle}</span>
        </div>
        <div
          id="fvs-doc-effective-date-form-field "
          className="padding-4 bg-gray-3"
        >
          <span className="body-bold">Effective date</span>
          <div className="usa-hint">mm/dd/yyyy</div>
          {errors?.effectiveDate?.message && (
            <ErrorMessage id="attachment-effective-date-error-alert">
              {errors?.effectiveDate?.message}
            </ErrorMessage>
          )}
          <Controller
            name="effectiveDate"
            control={control}
            render={({ value, onBlur, onChange }) => (
              <DatePicker
                inputRef={fieldRef}
                name="effectiveDate"
                onBlur={onBlur}
                onChange={onChange}
                defaultValue={value}
                aria-label="Document attachment effective date"
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

EffectiveDateField.propTypes = {
  fieldRef: PropTypes.func.isRequired,
  control: PropTypes.shape({
    setValue: PropTypes.func,
    register: PropTypes.func,
    errors: {
      effectiveDate: PropTypes.shape({
        message: PropTypes.string,
      }),
    },
    getValues: PropTypes.func,
  }).isRequired,
  props: PropTypes.shape({
    documentTitle: PropTypes.string,
    control: {
      setValue: PropTypes.func,
      register: PropTypes.func,
      errors: {
        effectiveDate: PropTypes.shape({
          message: PropTypes.string,
        }),
      },
      getValues: PropTypes.func,
    },
    errors: {
      effectiveDate: PropTypes.shape({
        message: PropTypes.string,
      }),
    },
  }).isRequired,
  documentTitle: PropTypes.string.isRequired,
};

export default EffectiveDateField;
