/* eslint-disable react/prop-types */
import React from 'react';
import './make-model-table.scss';
import { ReactComponent as Info } from './info.svg';

const MakeAndModelHeader = ({ title }) => {
  return (
    <div className="titleWrapper">
      <div className="titleInnerWrapper">
        <p className="titleHeader">{title}</p>
        <Info />
      </div>
    </div>
  );
};

export default MakeAndModelHeader;
