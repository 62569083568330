import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MultiSelectDropdown } from '@gsa/afp-component-library';
import { useBidLineDetails } from '../provider/bid-line-details-provider';
import { getOptionalEcList } from '../provider/helpers';
import { getEditConflictBlock, displayRequires } from './conflicts-components';

const EditRequiresBlock = ({ original, options, field, onSave }) => {
  const [relations, setRelations] = useState([]);
  const setRelation = (i, value) => {
    setRelations((prev) => {
      const newRelations = prev.map((row, rowIndex) => {
        return rowIndex === i ? value : row;
      });
      if (newRelations[newRelations.length - 1].length > 0)
        newRelations.push([]);
      return newRelations;
    });
  };

  useEffect(() => {
    const newValue = [...original[field].value];
    newValue.push([]);
    setRelations(newValue);
  }, [original[field].value]);

  const { optionalECs } = useBidLineDetails();
  const ecList = getOptionalEcList(optionalECs);

  const onClickSave = () => {
    const stringify = (strArrayArray) => {
      const strArray = strArrayArray
        .filter((strArr) => strArr.length > 0)
        .map((strArr) => JSON.stringify(strArr.sort()));
      return JSON.stringify(strArray);
    };
    if (stringify(relations) !== stringify(original[field].value))
      onSave(field, relations);
  };

  const getForm = () =>
    relations.map((row, i) => (
      <div
        // eslint-disable-next-line
        key={`requires-input-${i}`}
        className={`${
          i < relations.length - 1 && row.length === 0 ? 'display-none' : ''
        }`}
      >
        <MultiSelectDropdown
          id={`${field}-${i}`}
          selectedValues={row}
          options={options}
          onChange={(value) => setRelation(i, value)}
          queryThreshold={1}
          chipShowValue
        />
        {row.length > 0 && <div className="text-bold font-sans-2xs">OR</div>}
      </div>
    ));

  const getSummary = () => {
    const rows = relations.filter((row) => row.length);
    if (rows.length > 0) return displayRequires(rows, ecList);
    return <div className="margin-left-2">(None)</div>;
  };

  return getEditConflictBlock(
    original.equipment,
    field,
    getForm,
    getSummary,
    onClickSave,
  );
};

EditRequiresBlock.propTypes = {
  field: PropTypes.string.isRequired,
  original: PropTypes.shape(PropTypes.objectOf({})).isRequired,
  options: PropTypes.arrayOf(PropTypes.objectOf({})).isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EditRequiresBlock;
