import React, { useEffect, useMemo, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import orderBy from 'lodash/orderBy';
import { useHistory } from 'react-router';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import {
  AFPTable,
  StatusBadge,
  Button,
  PageTitle,
} from '@gsa/afp-component-library';
import RevealText from '../components/reveal-text';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import { GET_OPEN_AND_RECENT_COMMENTING_PERIODS } from '../fvs.gql';
import {
  COMMENTING_PERIOD_WELCOME_TEXT,
  COMMENTING_PERIOD_LABELS,
} from './constants';
import { OPERATIONS, SUBJECTS } from '../../../utilities/constants';
import { useFederalStandards } from '../fvs-provider';

/* eslint-disable react/prop-types */
const CommentingPeriods = () => {
  const ability = useAppAbility();
  const history = useHistory();

  const [order, setOrder] = useState(
    ability.can(OPERATIONS.Update, SUBJECTS.FVS)
      ? 'fvsTitle ASC'
      : 'status DESC',
  );
  const [tableData, setTableData] = useState([]);
  const [, setError] = useState({});

  const { setFvsContextData } = useFederalStandards();

  const handleSelectedRowClick = (evt, row) => {
    if (evt === COMMENTING_PERIOD_LABELS.VIEW) {
      const { fvsYear, fvsCode } = row.original;
      setFvsContextData('SET_SELECTED_YEAR', Number.parseInt(fvsYear, 10));
      setFvsContextData('SET_SELECTED_OPTIONS', {
        fedStandardCode: fvsCode,
        standardItemNumber: '',
        vehicleGroup: '',
        vehicleType: '',
      });
      const url = `/catalog/vehicle-standards`;
      history.push(url, row.original);
    }
  };

  const [getOpenAndRecentCommentingPeriods] = useLazyQuery(
    GET_OPEN_AND_RECENT_COMMENTING_PERIODS,
    {
      fetchPolicy: 'network-only',
      onError: (responseError) => {
        setError((prev) => ({
          ...prev,
          GET_COMMENTING_PERIODS: responseError,
        }));
      },
      onCompleted: (periodResponse) => {
        if (ability.can(OPERATIONS.Update, SUBJECTS.FVS)) {
          const sortedData = orderBy(
            [...periodResponse.getOpenAndRecentCommentingPeriods],
            ['fvsTitle'],
            ['asc'],
          );
          setTableData(sortedData);
          setOrder('fvsTitle ASC');
        } else {
          const sortedData = orderBy(
            [...periodResponse.getOpenAndRecentCommentingPeriods],
            ['status', 'fvsTitle', 'userType'],
            ['desc', 'asc', 'asc'],
          );
          setTableData(sortedData);
          setOrder('status DESC');
        }
      },
    },
  );

  const mapStatusToBadgeColor = (commentStatus) => {
    const status = commentStatus?.toLowerCase();

    if (!status) return '';
    if (status === 'open') return 'Ready-Gray';
    if (status === 'scheduled') return 'Info-Gray';
    if (status === 'unscheduled') return 'Inactive-Gray';
    if (status === 'closed') return 'Inactive-Gray';
    return 'Inactive-Gray';
  };

  /* eslint-disable-next-line */
  const columns = useMemo(() => {
    const columnList = [
      {
        Header: COMMENTING_PERIOD_LABELS.FVS,
        accessor: 'fvsTitle',
        Cell: ({ row, value }) => {
          return (
            <Button
              variant="unstyled"
              onClick={
                () => handleSelectedRowClick(COMMENTING_PERIOD_LABELS.VIEW, row)
                /* eslint-disable-next-line */
              }
              label={`${value} (${row.original.fvsCode})`}
            />
          );
        },
      },
      {
        Header: COMMENTING_PERIOD_LABELS.YEAR,
        accessor: 'fvsYear',
      },
      {
        Header: COMMENTING_PERIOD_LABELS.TYPE,
        accessor: 'userType',
        Cell: ({ value }) => {
          return value === 'VehicleSupplier' ? 'Vehicle Supplier' : value;
        },
      },
      {
        Header: COMMENTING_PERIOD_LABELS.STARTS,
        accessor: 'periodStart',
        Cell: ({ value }) => {
          return moment(value).format('L');
        },
      },
      {
        Header: COMMENTING_PERIOD_LABELS.ENDS,
        accessor: 'periodEnd',
        Cell: ({ value }) => {
          return moment(value).format('L');
        },
      },
      {
        Header: COMMENTING_PERIOD_LABELS.DAYSLEFT,
        accessor: 'daysLeft',
      },
      {
        Header: COMMENTING_PERIOD_LABELS.STATE,
        accessor: 'status',
        Cell: ({ value }) => {
          return (
            <StatusBadge variant={mapStatusToBadgeColor(value)}>
              {value}
            </StatusBadge>
          );
        },
      },
    ];

    return columnList;
  }, []);

  useEffect(() => {
    getOpenAndRecentCommentingPeriods();
  }, []);

  useEffect(() => {
    if (tableData && order) {
      const sortColumn = order?.split(' ')[0]?.replace(/`/g, '');
      // requires asc/desc to be lowercase for lodash orderBy function
      const sortOrder = order?.split(' ')[1]?.toLowerCase();
      setTableData(orderBy([...tableData], sortColumn, sortOrder));
    }
  }, [order]);

  const getBreadCrumbsPath = useMemo(() => {
    const breadcrumbsPath = [
      {
        location: `${window.AFP_CONFIG.appURLs.home}/home`,
        label: 'Home',
      },
      {
        location: '/catalog/vehicle-standards',
        label: 'Federal Vehicle Standards',
      },
    ];

    // reset commenting period selections
    setFvsContextData(
      'SET_SELECTED_YEAR',
      Number.parseInt(new Date().getFullYear(), 10),
    );
    setFvsContextData('SET_SELECTED_OPTIONS', {
      fedStandardCode: '',
      standardItemNumber: '',
      vehicleGroup: '',
      vehicleType: '',
    });

    return breadcrumbsPath;
  }, []);

  return (
    <>
      <Breadcrumbs current="Commenting Periods" path={getBreadCrumbsPath} />
      <PageTitle
        aria-label="Commenting Periods"
        tabIndex="0"
        title="Commenting Periods"
      />
      <RevealText
        // show expanded for pso/public
        isExpanded={!ability.can(OPERATIONS.Update, SUBJECTS.FVS)}
        className="margin-top-3 width-tablet"
        title="About Commenting Periods"
        copy={<>{ReactHtmlParser(COMMENTING_PERIOD_WELCOME_TEXT)}</>}
      />
      <AFPTable
        fullWidth
        testId="commenting-periods-table"
        columns={columns}
        data={tableData}
        onSort={setOrder}
        defaultSort={order}
      />
    </>
  );
};

export default CommentingPeriods;
