import React from 'react';
import PropTypes from 'prop-types';
import { SelectDropdown, TextInput, Button } from '@gsa/afp-component-library';
import { SEARCH_OPTIONS, getKeyLabel } from './bid-search-helpers';
import VendorSearchTypeahead from '../../bids/bids-center/components/admin-view/vendor-search-typeahead';

const BidSearchSection = ({
  searchKey,
  setSearchKey,
  searchValue,
  setSearchValue,
  onSearch,
}) => {
  return (
    <div
      className="display-flex flex-row flex-wrap flex-justify-start flex-align-end bg-primary-lighter"
      style={{ maxWidth: '48rem' }}
    >
      <div className="margin-left-2 margin-bottom-2">
        <SelectDropdown
          data-testid="proposal-search-key"
          label="Search by"
          style={{ width: 200 }}
          value={searchKey}
          options={SEARCH_OPTIONS}
          onChange={({ target: { value } }) => {
            setSearchKey(value);
            if (searchKey === 'vendorNameOrUei' || value === 'vendorNameOrUei')
              setSearchValue('');
          }}
        />
      </div>
      <div className="margin-left-2 margin-bottom-2">
        {searchKey === 'vendorNameOrUei' ? (
          <div style={{ width: 360 }} data-testid="proposal-search-typeahead">
            <VendorSearchTypeahead
              onVendorSelection={(vendor) => {
                setSearchValue(vendor?.vendorName || '');
              }}
              label={getKeyLabel(searchKey)}
            />
          </div>
        ) : (
          <TextInput
            data-testid="proposal-search-value"
            label={`Search for a bid by ${getKeyLabel(searchKey)}`}
            style={{ width: 360 }}
            value={searchValue}
            onChange={({ target: { value } }) => setSearchValue(value)}
            maxLength={50}
          />
        )}
      </div>
      <div className="margin-left-2 margin-bottom-2">
        <Button
          data-testid="proposal-search-btn"
          type="button"
          variant="outline"
          onClick={onSearch}
          label="Search"
          leftIcon={{ name: 'add', className: 'usa-icon--size-3' }}
        />
      </div>
    </div>
  );
};

BidSearchSection.propTypes = {
  searchKey: PropTypes.string.isRequired,
  setSearchKey: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default BidSearchSection;
