import React, {  useMemo, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { PageTitle, TabSet, Alert } from '@gsa/afp-component-library';
import Breadcrumbs from '../../widgets/breadcrumbs';
import MakeModelApprovalTable from './make-model-approval';
import MakeModelTable from './make-model';
import {
  makeModelStatusMessageAtom,
} from './atoms/make-model-atoms';


export default () => {
  const title = 'Makes and Models';
  const [toaster, setToaster] = useRecoilState(makeModelStatusMessageAtom);

  const tabs = useMemo(
    () => [
      {
        heading: 'Make and Model Approval',
        content: <MakeModelApprovalTable />,
      },
      {
        heading: 'Makes and Models',
        content: <MakeModelTable />,
      },
    ],
    [],
  );

  useEffect(() => {
    setToaster(null); // clear toaster when page reload
  }, []);

  return (
    <>
      {toaster && (
        <Alert
          slim
          focused
          type={toaster.type}
          onClose={() => setToaster(null)}
        >
          {toaster.message}
        </Alert>
      )}
      <Breadcrumbs
        current={title}
        path={[
          {
            location: `${window.AFP_CONFIG.appURLs?.home}/home`,
            label: 'Home',
          },
        ]}
      />
      <PageTitle title={title} aria-label={title} />
      <p className="margin-bottom-6">
        Please review Body Make/Body Models that are not available in the
        system. If the Engineering team approves, Make/Model entries will be
        made available for future use. Any rejected Make/Model would require
        resubmission for approval, and bidders won&apos;t be able to submit the
        bid on the rejected line item.
      </p>
      <TabSet tabs={tabs} />
    </>
  );
};
