import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as yup from 'yup';
import {
  Button,
  TextInput,
  RequiredFieldIndicator,
  SelectDropdown,
  useModal,
} from '@gsa/afp-component-library';
import { useBidLineDetails } from '../../provider/bid-line-details-provider';
import { INPUT_DROPDOWN_DEFAULT_OPTION } from '../../provider/helpers';
import { enDashUnicode } from '../../../../../utilities/constants';
import { AUTO_SAVE_BID_LINE_DETAILS } from '../../provider/queries';
import EditBidLineItemModalWrapper from '../../../line-items-filter-table-frame/edit-bid-line-item';

const VehicleInfoSection = () => {
  const {
    bidLine,
    updateBidDetailData,
    bidDetailData,
    isAdmin,
  } = useBidLineDetails();

  const [bidLineState, setBidLineState] = useState({
    isIn1122Program: bidDetailData?.isIn1122Program,
    shipmentDays: bidDetailData?.shipmentDays,
    shipmentJustification: bidDetailData?.shipmentJustification,
  });
  const [shippingTimeErrorMessage, setShippingTimeErrorMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { isOpen, openModal, closeModal } = useModal();

  const onEditMakeModalClose = useCallback(
    (updatedBidLine) => {
      closeModal();
      if (updatedBidLine?.afpMake?.makeName) {
        updateBidDetailData('updated_afpMake', updatedBidLine.afpMake);
        updateBidDetailData('updated_afpModel', updatedBidLine.afpModel);
        updateBidDetailData('updated_chassisMake', updatedBidLine.chassisMake);
        updateBidDetailData('updated_chassisMake', updatedBidLine.chassisMake);
      }
    },
    [closeModal, updateBidDetailData],
  );

  const validationSchema = yup.object().shape({
    isIn1122Program: yup.string().required('1122 Program is required.'),
    shipmentDays: yup
      .number()
      .integer('Shipping days must be a whole number.')
      .moreThan(0, 'Shipping days must be between 0 and 999.')
      .lessThan(1000, 'Shipping days must be between 0 and 999.')
      .required('Shipping days is required.'),
    shipmentJustification: yup.string(),
  });

  const options = [INPUT_DROPDOWN_DEFAULT_OPTION];
  options.push({ label: 'Yes', value: true }, { label: 'No', value: false });

  const genLabel = (label, required) => (
    <span className="text-bold" data-testid={label}>
      {label} {required && <RequiredFieldIndicator />}
    </span>
  );

  const castValueBasedOnType = (name, value) => {
    let transformedValue;

    if (name === 'shipmentDays') {
      transformedValue = parseFloat(value);
      if (Number.isNaN(transformedValue)) {
        transformedValue = value;
      }
    } else if (name === 'isIn1122Program') {
      if (value === 'true') {
        transformedValue = true;
      } else if (value === 'false') {
        transformedValue = false;
      } else {
        transformedValue = value;
      }
    } else {
      transformedValue = value;
    }

    return transformedValue;
  };

  const [autoSaveBidLineDetails] = useMutation(AUTO_SAVE_BID_LINE_DETAILS);

  const autoSave = async (name, value) => {
    if (!value || isAdmin) return;
    autoSaveBidLineDetails({
      variables: {
        input: {
          bidLineId: bidLine?.id,
          [name]: castValueBasedOnType(name, value),
        },
      },
    });
    updateBidDetailData(name, value);
  };

  const handleBlur = async (event) => {
    const { name, value } = event.target;
    if (
      name === 'shipmentDays' &&
      (value === '' || Number.isNaN(Number(value)))
    ) {
      setShippingTimeErrorMessage('Shipping days is required.');
      return;
    }
    try {
      await validationSchema.validateAt(name, { [name]: value });
      setShippingTimeErrorMessage('');
      setErrorMessage('');
    } catch (error) {
      if (name === 'shipmentDays') {
        setShippingTimeErrorMessage(error.message);
      } else {
        setErrorMessage(error.message);
      }
      return;
    }

    autoSave(name, value);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setBidLineState({
      ...bidLineState,
      [name]: value,
    });
  };

  const onSelectDropdownChange = (event) => {
    onChange(event);
    handleBlur(event);
  };

  const makeName =
    bidDetailData?.updatedAfpMake?.makeName ||
    bidLine?.afpMake?.makeName ||
    enDashUnicode;
  const modelName =
    bidDetailData?.updatedAfpModel?.modelName ||
    bidLine?.afpModel?.modelName ||
    enDashUnicode;
  const chassisMakeName =
    bidDetailData?.updatedChassisMake?.makeName ||
    bidLine?.chassisMake?.makeName ||
    enDashUnicode;
  const chassisModelName =
    bidDetailData?.updatedChassisModel?.modelName ||
    bidLine?.chassisModel?.modelName ||
    enDashUnicode;

  return (
    <>
      <div className="grid-row margin-top-1">
        <div className="grid-col flex-1">
          {genLabel('Body Make and Model', false)}
          <div className="padding-top-1">
            {makeName} {modelName}
          </div>
        </div>
        {bidLine?.chassisMake?.makeName && (
          <div className="grid-col flex-1">
            {genLabel('Chassis Make and Model', false)}
            <div className="padding-top-1">
              {chassisMakeName} {chassisModelName}
            </div>
          </div>
        )}
        <div className="grid-col flex-1">
          {genLabel('Model Year', false)}
          <div className="padding-top-1">
            {bidLine?.modelYear ? bidLine?.modelYear : enDashUnicode}
          </div>
        </div>
        <div className="grid-col flex-2 padding-top-1">
          <Button
            variant="outline"
            label="Edit line item"
            onClick={openModal}
          />
        </div>
      </div>

      <section className="margin-top-8">
        <section>
          {genLabel(
            'Do you want this line to be a part of the 1122 program?',
            true,
          )}
          <div className="padding-top-1 flex-5">
            The 1122 program allows states and units of local government access
            to federal sources of supply to purchase equipment to support
            specific activities.
          </div>
        </section>
        <div className="width-card-lg margin-bottom-4">
          <SelectDropdown
            name="isIn1122Program"
            data-testid="1122_program_yes_no"
            value={bidLineState.isIn1122Program}
            errorMessage={errorMessage}
            options={options}
            onChange={onSelectDropdownChange}
          />
        </div>
      </section>

      <div className="grid-row flex-wrap margin-top-4 margin-bottom-6">
        <div className="grid-col flex-5">
          <div className="width-card-lg margin-bottom-6">
            {genLabel('Shipping days', true)}
            <TextInput
              name="shipmentDays"
              min={0}
              max={999}
              data-testid="shipping-days"
              type="number"
              errorMessage={shippingTimeErrorMessage}
              value={bidLineState.shipmentDays}
              onChange={onChange}
              onBlur={handleBlur}
            />
          </div>
          {genLabel('Justification', false)}
          <TextInput
            name="shipmentJustification"
            data-testid="shipmentJustification"
            help="Add a justification for outside the shipping timeline."
            type="textarea"
            characterLimit={2000}
            value={bidLineState.shipmentJustification}
            onChange={onChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      {isOpen && (
        <EditBidLineItemModalWrapper
          initialData={bidLine}
          onClose={onEditMakeModalClose}
        />
      )}
    </>
  );
};

export default VehicleInfoSection;
