import React, { useEffect } from 'react';
import { PageTitle } from '@gsa/afp-component-library';
import WelcomeMessage from '../../../widgets/welcome-message';
import FvsPreBidSelectionProvider from './pre-bid-report-provider';
import Breadcrumbs from '../../../widgets/breadcrumbs';
import PreBidReportTable from './pre-bid-report-table';
import PreBidReportMessages from './pre-bit-report-message';

const PreBidReportPage = () => {
  useEffect(() => {
    document.title = 'Pre-bid Report - GSAFleet.gov';
  }, []);

  const breadcrumbsPath = [
    {
      location: `${window.AFP_CONFIG.appURLs.home}/home`,
      label: 'Home',
    },
    {
      location: '/catalog/vehicle-standards',
      label: 'Federal Vehicle Standards',
    },
  ];

  return (
    <FvsPreBidSelectionProvider>
      <div>
        <Breadcrumbs
          current="Vendor Pre-Bid Selections"
          path={breadcrumbsPath}
        />
        <PreBidReportMessages />
        <PageTitle
          aria-label="Vendor Pre-Bid Selections"
          tabIndex="0"
          title="Vendor Pre-Bid Selections"
        />
        <WelcomeMessage message="The Vendor pre-bid selection report will include the intention to bid expressed by Vehicle suppliers across all federal standard codes. This report can only be downloaded once an hour." />
        <PreBidReportTable />
      </div>
    </FvsPreBidSelectionProvider>
  );
};

export default PreBidReportPage;
