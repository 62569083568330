import { ACTIONS } from './constants';

export const ACTION_PARAM_KEY = 'action';

export const createURLSearchParam = (paramObj) =>
  new URLSearchParams(paramObj).toString();

const checkActionFlow = (action) => () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(ACTION_PARAM_KEY) === action;
};

export const isReopenActionFlow = checkActionFlow(ACTIONS.REOPEN);
export const isMidCycleActionFlow = checkActionFlow(ACTIONS.MIDCYCLE);
export const isRenewActionFlow = checkActionFlow(ACTIONS.RENEW);
