import React from 'react';
import PropTypes from 'prop-types';
import { AFPTable, EmptyState } from '@gsa/afp-component-library';
import SolicitationPeriodsTableSubComponent from '../../../components/solicitation-periods-table-sub-comp';
import { ReopenPeriodcolumns } from './helpers';

const OpenPeriodTable = ({ openPeriod, noDataMsg, ...restProps }) => {
  const data = openPeriod?.startDate
    ? [{ ...openPeriod, period: 'Open period' }]
    : [];
  return (
    <>
      <AFPTable
        fullWidth
        testId="open-periods-table"
        data={data}
        renderRowSubComponent={SolicitationPeriodsTableSubComponent}
        columns={ReopenPeriodcolumns}
        defaultSort="period ASC"
        {...restProps}
      />
      {!data.length && (
        <EmptyState
          hasBackground
          containerStyles="padding-y-5 margin-top-1 adding-y-8 margin-top-neg-2"
          topText={noDataMsg}
        />
      )}
    </>
  );
};
OpenPeriodTable.propTypes = {
  openPeriod: PropTypes.objectOf(PropTypes.any).isRequired,
  noDataMsg: PropTypes.string,
};
OpenPeriodTable.defaultProps = {
  noDataMsg: 'There is no open period for this contract year.',
};

export default OpenPeriodTable;
