/* eslint-disable no-unused-expressions */
import React from 'react';
import { PageTitle } from '@gsa/afp-component-library';
import { Link } from 'react-router-dom';
import { useVendorInfo } from '../apis/vendor-api';

const BidManagementHeader = () => {
  const { vendor, vendorInfoForAdmin, hasAdminPrivilege } = useVendorInfo();
  const { id, vendorName, uniqueEntityIdentifier } =
    vendor || vendorInfoForAdmin || {};

  const headerText = hasAdminPrivilege
    ? 'Search for a bid by Solicitation number, Vendor name or UEI.'
    : ' Here is a list of available solicitations and/or bids that you have active.';

  return (
    <>
      <div className="grid-row">
        <div className="grid-col flex-4">
          <PageTitle
            title="Bids Management"
            aria-label="Bids Management"
            tabIndex="0"
          />
          <div
            className="margin-top-neg-2 margin-bottom-5"
            aria-label={headerText}
          >
            {headerText}
          </div>
        </div>
        {vendorName && (
          <div
            className="grid-col flex-2 margin-top-3 flex-align-self-stretch"
            aria-label={vendorName}
          >
            {vendorName || '-'}, &nbsp;
            {uniqueEntityIdentifier || '-'}
            <div className="margin-top-1 flex-align-self-end">
              <Link
                to={{
                  pathname: '/catalog/bids/bid-center/bid-history',
                  state: {
                    vendorData: { id, vendorName, uniqueEntityIdentifier },
                  },
                }}
              >
                Bid history
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BidManagementHeader;
