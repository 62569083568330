/* eslint-disable react/prop-types */
import React from 'react';
import { useCommentsAdmin } from './comments-admin-provider';

const DeleteReply = ({ formId }) => {
  const { deleteStandardsComment, selectedReply } = useCommentsAdmin();

  const handleFormSubmit = () => {
    deleteStandardsComment({
      variables: {
        id: Number(selectedReply.id),
      },
    });
  };

  return (
    <>
      <form
        id={formId}
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit();
        }}
      >
        Are you sure you want to delete this comment?
      </form>
    </>
  );
};

export default DeleteReply;
