import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as resolvers from '../../services/data-store';
import { useFederalStandards } from './fvs-provider';
import { GET_COMMENTS } from './requirements/query.gql';

export const FvsCommentsContext = createContext({});

const initialState = {
  commentList: [],
  showStandardCommentTab: false,
};

const FvsCommentsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_COMMENTS_LIST': {
      return { ...state, commentList: action.payload };
    }
    case 'SET_SHOW_COMMENT_TAB': {
      return { ...state, showCommentTab: action.payload };
    }
    case 'SET_IS_DELETING_COMMENT': {
      return { ...state, isDeletingComment: action.payload };
    }
    default: {
      return state;
    }
  }
};

function FvsCommentsProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(FvsCommentsReducer, initialState, () => {
    return initialState;
  });

  const { setFvsError, addOrReplaceFvsMessageById } = useFederalStandards();

  const setFvsCommentsContextData = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const [getStandardsComments, { refetch: refetchComment }] = useLazyQuery(
    GET_COMMENTS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (requestError) => {
        setFvsError(requestError);
      },
      onCompleted: (responseData) => {
        if (responseData?.getStandardsComments) {
          setFvsCommentsContextData(
            'SET_COMMENTS_LIST',
            responseData.getStandardsComments?.rows,
          );
        }
      },
    },
  );

  const [addComment] = useMutation(resolvers.ADD_COMMENT, {
    onError: (requestError) => {
      setFvsError(requestError);
    },
    onCompleted: () => {
      const message = {
        id: 'FVS_COMMENT',
        message: `Your comment was successfully submitted.`,
        type: 'success',
        closeable: true,
      };
      addOrReplaceFvsMessageById(message);
      refetchComment();
    },
  });

  const [deleteStandardsComment, { loading: isDeletingComment }] = useMutation(
    resolvers.DELETE_STANDARD_COMMENT,
    {
      onError: (requestError) => {
        setFvsError(requestError);
      },
      onCompleted: (response) => {
        if (response?.deleteStandardsComment) {
          const comment = response?.deleteStandardsComment;
          const date = moment(comment.createdAt).format('l');
          const time = moment(comment.createdAt).format('h:mm a');
          const message = {
            id: 'FVS_COMMENT',
            message: `You have successfully deleted your comment made on ${date} at ${time}.`,
            type: 'success',
            closeable: true,
          };
          addOrReplaceFvsMessageById(message);
        }
        refetchComment();
      },
    },
  );

  return (
    <FvsCommentsContext.Provider
      value={{
        ...state,
        setFvsCommentsContextData,
        getStandardsComments,
        addComment,
        isDeletingComment,
        deleteStandardsComment,
        ...props,
      }}
    >
      {children}
    </FvsCommentsContext.Provider>
  );
}

export default FvsCommentsProvider;

FvsCommentsProvider.defaultProps = {};

FvsCommentsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useFvsCommentsContext = () => useContext(FvsCommentsContext);
