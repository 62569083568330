import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typeahead, FilterPanel } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { GET_VENDORS_BY_PERMISSION } from '../../../../services/data-store';

export const vendorTypeAheadValue = (vendorName, uniqueEntityIdentifier) => {
  return [vendorName, uniqueEntityIdentifier].join(' - ');
};
const { useFilterPanel } = FilterPanel;

const VendorSearchTypeahead = ({ filter }) => {
  const {
    setFilters: setFilterPanelFilters,
    clearOneFilter,
  } = useFilterPanel();
  const { key, placeholder, ariaLabel, customFieldProps } = filter || {};
  const { inputCharNum } = customFieldProps || {};
  const [suggestions, setSuggestions] = useState([]);
  const [, setError] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const [
    getVendorsByPermission,
    { loading: vendorSuggestionsLoading },
  ] = useLazyQuery(GET_VENDORS_BY_PERMISSION, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getVendorsByPermission: data }) => {
      setSuggestions(data);
    },
    onError: () => {
      setError('Not able to fetch vendor suggestions');
    },
  });

  const fetchValues = (_, value) => {
    getVendorsByPermission({
      variables: {
        operation: 'view',
        subject: 'Vendor',
        filters: {
          operator: '$or',
          conditions: [
            { operator: '$like', key: 'vendorName', value },
            { operator: '$like', key: 'uniqueEntityIdentifier', value },
          ],
        },
      },
    });
  };

  const handleTypeAheadClear = () => {
    setSearchValue('');
    clearOneFilter(key);
    setFilterPanelFilters({
      type: 'setOne',
      fetchNewData: true,
      filter: {
        ...filter,
        displayValue: () => '',

        value: '',
      },
    });
  };

  const handleVendorSelection = (data) => {
    const { vendorName, id } = data || {};
    if (id) {
      setSearchValue(vendorName);
      setFilterPanelFilters({
        type: 'setOne',
        fetchNewData: true,
        filter: {
          ...filter,
          displayValue: () => vendorName || '',

          value: id,
        },
      });
    }
  };

  return (
    <Typeahead
      key={key}
      filterValue={searchValue}
      placeholder={placeholder}
      ariaLabel={ariaLabel}
      onOptionEnter={(value) => {
        const segs = value.split(' - ');
        const name = segs[segs.length - 2];
        handleVendorSelection(suggestions.find((v) => v.vendorName === name));
      }}
      onClear={handleTypeAheadClear}
      typeaheadValues={suggestions.map((d) =>
        vendorTypeAheadValue(d.vendorName, d.uniqueEntityIdentifier),
      )}
      fetchTypeaheadValues={fetchValues}
      showLoadingSpinner={vendorSuggestionsLoading}
      inputCharNum={inputCharNum}
      reset={!filter?.value}
    />
  );
};

VendorSearchTypeahead.propTypes = {
  filter: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
    operator: PropTypes.string,
    placeholder: PropTypes.string,
    ariaLabel: PropTypes.string,
    customFieldProps: PropTypes.shape({
      inputCharNum: PropTypes.number,
      setFilters: PropTypes.func,
      currentFilters: PropTypes.shape({
        value: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string,
            operator: PropTypes.string,
            value: PropTypes.string,
          }),
        ),
      }),
    }),
  }).isRequired,
};

export default VendorSearchTypeahead;
