import React, { useMemo, useState, useEffect } from 'react';
import {
  EmptyState,
  AFPTable,
  Pagination,
  Button,
  Spinner,
} from '@gsa/afp-component-library';
import orderBy from 'lodash/orderBy';
import { useHistory, useParams } from 'react-router';
import { useRecoilState } from 'recoil';
import { useMutation } from '@apollo/client';
import useDidMountEffect from '../../../../../hooks/use-did-mount';
import useAssignQuantity from './hooks/assign-quantity-api';
import './assign-quantity.scss';
import SolicitationStepper from '../../../components/solicitation-stepper';
import WelcomeMessage from '../../../../../widgets/welcome-message';
import { CSV_DELIMITER } from '../../../constants';
import SolicitationStatusBadge from '../../../components/solicitation-status-badge';
import biddingType from '../../../atoms/solicitation-helpers';
import { isRenewActionFlow } from '../../../route-utils';
import { UPDATE_SOLICITATION_LINE } from '../../../queries';

const AssignQuantityTable = () => {
  const [bidType, setBidType] = useRecoilState(biddingType);
  const solicitationLine = [];
  const history = useHistory();
  const params = useParams();
  // const [solLines, setSolLines] = useState(solicitationLines?.rows);
  const {
    solicitationLines,
    setSolicitationLines,
    setSolicitationNotification,
    getSolicitationLinesBySolId,
    getSolicitationById,
    solicitation,
    solicitationLoading,
  } = useAssignQuantity();

  const customSolicitationLinesCSV = {
    header: 'Standard Items,Total Quantity,Fleet Quantity',
  };

  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  const getFilteredSolicitationLines = () => {
    const { programs } = solicitation;
    const filtererdSolicitationLines = [];
    programs?.map((lines) =>
      lines?.solicitationLines?.map((sin) =>
        filtererdSolicitationLines.push(sin),
      ),
    );
    return filtererdSolicitationLines;
  };

  const generateSolicitaionLinesCSV = () => {
    const solicitaionLinesCSV = getFilteredSolicitationLines().map((item) => {
      const escapedTitle = item.standardItem.title
        .replaceAll('"', '\\"')
        .replaceAll("'", "\\'");
      return `"${item.standardItem.standardItemNumber}-${escapedTitle}"`
        .toString()
        .concat(CSV_DELIMITER.comma)
        .concat(item.estimatedQty.toString())
        .concat(CSV_DELIMITER.comma)
        .concat(item.estimatedFleetQty)
        .toString();
    });
    if (solicitaionLinesCSV) {
      solicitaionLinesCSV.unshift(customSolicitationLinesCSV.header);
    }
    return solicitaionLinesCSV.join('\r\n');
  };

  const downloadSolicitaionLineCSV = () => {
    const csvData = generateSolicitaionLinesCSV();
    // A Blob for having a csv file format
    const blob = new Blob([csvData], { type: 'text/csv' });
    // An object for downloading url
    const url = window.URL.createObjectURL(blob);
    // Creating an anchor(a) tag of HTML
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute(
      'download',
      `${'solicitaion-lines'}-${
        solicitation?.solicitationNumber
      }-${Date.now()}.csv`,
    );
    a.click();
  };

  const [order, setOrder] = useState('standardItem.standardItemNumber ASC');
  const [updatedSolicitaionLines, setUpdatedSolicitaionLines] = useState([]);

  const tableRef = React.createRef();

  const getData = () => {
    const { limit, offset } = paginationState;
    getSolicitationLinesBySolId({
      variables: {
        solicitationID: Number(params.id),
        limit,
        offset,
      },
    });
  };

  useDidMountEffect(() => {
    getData();
  }, [paginationState]);

  useEffect(() => {
    getData();
    getSolicitationById({
      variables: {
        id: Number(params.id),
      },
    });
  }, []);

  useEffect(() => {
    const defualtedSINs = getFilteredSolicitationLines().filter(
      (item) => item.estimatedQty === 1 || item.estimatedFleetQty === 1,
    );
    if (defualtedSINs.length !== 0) {
      setSolicitationNotification([
        {
          id: 'SOLICITATION_LINE_WARNING',
          message: [
            <b>{defualtedSINs.length}</b>,
            ` Standard Items are added with default quantities. Please review the newly added items and update the quantities.`,
          ],
          type: 'warning',
          closeable: false,
          showInModal: false,
        },
      ]);
    }
    const { bidType: selectedBidType } = solicitation;
    setBidType(selectedBidType);
  }, [solicitation, solicitationLines]);

  useEffect(() => {
    if (solicitationLines?.rows && order) {
      const sortOrder = order?.split(' ')[1]?.toLowerCase();
      const sortedsolicitationLinesRows = orderBy(
        solicitationLines?.rows,
        ['standardItem.standardItemNumber'],
        [sortOrder],
      );
      const sortedStandardItems = {
        rows: sortedsolicitationLinesRows,
        hasMore: solicitationLines?.length > paginationState.limit,
        count: solicitationLines?.count,
      };

      setSolicitationLines(sortedStandardItems);
    }
  }, [order]);

  // method to check if the orginal solicitationLin's estimatedQty or estimatedFleetQty values are changed or not
  const filterSolicitationLineChanges = (arr, filterArr) => {
    return arr.filter((el) =>
      filterArr.some(
        (f) =>
          Number(f.id) === el.solicitaionLineID &&
          (f.estimatedQty !== el.estimatedQty ||
            f.estimatedFleetQty !== el.estimatedFleetQty),
      ),
    );
  };

  const handleAction = (action) => {
    let path;
    if (action === 'next') {
      path = `/catalog/solicitations/edit-solicitation/${Number(
        params?.id,
      )}/review`;
    } else if (action === 'prev') {
      path = `/catalog/solicitations/edit-solicitation/${Number(
        params?.id,
      )}/select-sin`;
    }
    history.push({
      pathname: path,
      search: history.location.search,
    });
  };

  const [action, setAction] = useState();

  const [updateSolicitationLine] = useMutation(UPDATE_SOLICITATION_LINE, {
    onCompleted: (data) => {
      if (data.updateSolicitationLines) {
        setSolicitationNotification([
          {
            id: 'UPDATE_SOLICITATION_LINE_SUCCESS',
            message: `Standard Item quantities have been saved.`,
            type: 'success',
            closeable: true,
            showInModal: false,
          },
        ]);
        handleAction(action);
      }
    },
    onError: (requestError) => {
      setSolicitationNotification([
        {
          id: 'UPDATE_SOLICITATION_LINE_ERROR',
          message: requestError.message,
          type: 'error',
          closeable: false,
          showInModal: false,
        },
      ]);
    },
  });

  const saveUpdatedSolicitationLine = (actionA) => {
    const filteredSolicitationLineChanges = filterSolicitationLineChanges(
      updatedSolicitaionLines,
      solicitationLines?.rows,
    );
    if (filteredSolicitationLineChanges.length !== 0) {
      const updatedResult = updateSolicitationLine({
        variables: {
          inputs: {
            solicitationLines: filteredSolicitationLineChanges,
          },
        },
      });
      if (updatedResult) {
        updatedSolicitaionLines.length = 0;
      }
    } else {
      handleAction(actionA);
    }
  };

  const handleSubmit = async (actionA) => {
    setAction(actionA);
    if (updatedSolicitaionLines.length !== 0) {
      saveUpdatedSolicitationLine(actionA);
    } else {
      handleAction(actionA);
    }
  };

  useEffect(() => {
    setSolicitationNotification([]);
  }, []);

  const validateSolicitationLine = () => {
    setSolicitationNotification([]);
    let isValid = true;
    const filteredSolicitationLineChanges = filterSolicitationLineChanges(
      updatedSolicitaionLines,
      solicitationLines?.rows,
    );
    if (filteredSolicitationLineChanges.length !== 0) {
      const errorRows = filteredSolicitationLineChanges.filter((item) => {
        return item.estimatedQty < item.estimatedFleetQty;
      });
      if (errorRows.length > 0) {
        isValid = false;
        setSolicitationNotification([
          {
            id: 'SOLICITATION_LINES_ERROR',
            message: `${errorRows.length} Standard Items have Fleet quantity greater than the Total quantity.`,
            type: 'error',
            closeable: false,
            showInModal: false,
          },
        ]);
      }
    }
    return isValid;
  };

  const upsertSolicitationLine = (element, inputName) => {
    const i = solicitationLine.findIndex(
      (_element) => _element.solicitaionLineID === element.solicitaionLineID,
    );
    if (i > -1) {
      if (inputName === 'estimatedFleetQty')
        solicitationLine[i].estimatedFleetQty = element.estimatedFleetQty;
      if (inputName === 'estimatedQty')
        solicitationLine[i].estimatedQty = element.estimatedQty;
    } else solicitationLine.push(element);
    setUpdatedSolicitaionLines(solicitationLine);
  };

  const preventNegativeAndNonNumericInput = (e) => {
    if (!(e.charCode > 47 && e.charCode < 58)) {
      e.preventDefault();
    }
  };

  const preventPaste = (e) => {
    e.preventDefault();
  };

  const onBlurHandler = (inputName, original, newValue, e) => {
    if (e.target.value === '0' || e.target.value === '') {
      e.target.value = 1;
    }
    let updatedValue = newValue;
    const { id, estimatedQty, estimatedFleetQty } = original;
    if (newValue === '0') {
      updatedValue = '1'; // If the value is '0', update it to '1'
    }

    if (inputName === 'estimatedFleetQty') {
      upsertSolicitationLine(
        {
          solicitaionLineID: Number(id),
          estimatedQty,
          estimatedFleetQty: Number(updatedValue),
        },
        inputName,
      );
    }
    if (inputName === 'estimatedQty') {
      upsertSolicitationLine(
        {
          solicitaionLineID: Number(id),
          estimatedQty: Number(updatedValue),
          estimatedFleetQty,
        },
        inputName,
      );
    }
  };

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Standard Item',
        accessor: 'standardItem',
        Cell: ({ value }) => {
          return `${value?.standardItemNumber}-${value?.title}`;
        },
        sortable: false,
      },
      {
        Header: 'Total quantity',
        accessor: 'estimatedQty',
        sortable: false,
        // eslint-disable-next-line
        Cell: ({ row: { original }, value }) => (
          // eslint-disable-next-line
          <div key={original.id} className="usa-form margin-top-neg-2">
            <input
              data-testid="total-quantity"
              className="assign-qty-input"
              // ref={inputRef}
              min={1}
              maxLength={6}
              type="text"
              defaultValue={value}
              onKeyPress={preventNegativeAndNonNumericInput}
              onPaste={preventPaste}
              onBlur={
                () =>
                  onBlurHandler(
                    'estimatedQty',
                    original,
                    // eslint-disable-next-line
                    event.target.value,
                    // eslint-disable-next-line
                    event,
                  )
                // eslint-disable-next-line
              }
            />
          </div>
        ),
      },
      {
        Header: 'Fleet quantity',
        accessor: 'estimatedFleetQty',
        sortable: false,
        // eslint-disable-next-line
        Cell: ({ row: { original }, value }) => (
          // eslint-disable-next-line
          <div key={original.id} className="usa-form margin-top-neg-2">
            <input
              data-testid="fleet-quantity"
              className="assign-qty-input"
              name="estimatedFleetQty"
              type="text"
              maxLength={6}
              min={1}
              // ref={inputRef}
              defaultValue={value}
              required
              onKeyPress={preventNegativeAndNonNumericInput}
              onPaste={preventPaste}
              // eslint-disable-next-line
              onBlur={
                () =>
                  onBlurHandler(
                    'estimatedFleetQty',
                    original,
                    // eslint-disable-next-line
                    event.target.value,
                    // eslint-disable-next-line
                    event,
                  )
                // eslint-disable-next-line
              }
            />
          </div>
        ),
      },
    ];
    return columnList;
  }, []);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    saveUpdatedSolicitationLine();
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  const contYear = solicitation?.contractYear;

  return (
    <>
      {!solicitationLoading ? (
        <>
          <div className="grid-row flex-row assign-quantity-header">
            <h1>
              {`${solicitation?.solicitationType} Solicitation - ${solicitation?.solicitationNumber}`}
            </h1>
            <div className="statusBadgeContainer margin-left-1">
              <SolicitationStatusBadge status={solicitation?.status} />
            </div>
          </div>
          <SolicitationStepper currentStep={bidType === 'IN_FLEET' ? 5 : 3} />
          <WelcomeMessage message="Enter quantities for all your Standard Items. Fleet quantity represents vehicles for lease, while Total quantity represents the total vehicle count for purchase and lease from vendors." />
        </>
      ) : (
        <Spinner />
      )}

      <>
        <div className="padding-top-2 display-flex flex-row flex-justify-start">
          <span
            className="text-bold"
            aria-label="Contract Year"
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex="0"
          >
            Contract year
          </span>
          <span
            className="padding-left-8"
            aria-label={contYear}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex="0"
          >
            {contYear || '----'}
          </span>
        </div>

        <div className="grid-col-10 display-flex flex-row flex-justify-end">
          {solicitationLines?.rows?.length > 0 ? (
            <Button
              variant="outline"
              label="Export CSV"
              leftIcon={{ name: 'file_download' }}
              onClick={() => downloadSolicitaionLineCSV()}
            />
          ) : null}
        </div>
        <div className="grid-col-10">
          <div id="assign-quantity-table">
            <AFPTable
              testId="assign-quantity-table"
              columns={columns}
              data={solicitationLines?.rows || []}
              onSort={setOrder}
              defaultSort={order}
              fullWidth
              ref={tableRef}
            />
          </div>
          <div className="padding-y-1 pagination">
            <Pagination
              itemsPerPageOptions={[10, 25, 50]}
              onPageChange={handlePaginationChange}
              variant="advanced"
              itemsPerPage={paginationState.limit}
              currentPage={paginationState.currentPage}
              itemsCount={solicitationLines?.count}
            />
          </div>
        </div>
      </>

      {solicitationLines?.length !== 0 && solicitationLines?.rows?.length <= 0 && (
        <div
          className="margin-top-neg-5"
          id="assign-quantity-table-empty-state"
        >
          <EmptyState
            hasBackground
            containerStyles="padding-y-8 margin-top-1 height-full"
            topText={
              <>
                {isRenewActionFlow() ? (
                  <strong>
                    There are no Standard Items for the contract year.{' '}
                  </strong>
                ) : (
                  <strong>
                    There are no Standard Items associated with this
                    solicitation.{' '}
                  </strong>
                )}
              </>
            }
            topTextStyles="margin-top-7"
          />
        </div>
      )}

      <div className="grid-row padding-top-4 padding-bottom-10">
        <div className="flex">
          <Button
            variant="outline"
            label="Previous"
            leftIcon={{ name: 'arrow_back' }}
            data-testid="arrow_back"
            onClick={() => validateSolicitationLine() && handleSubmit('prev')}
          />
        </div>
        <div className="flex padding-left-1">
          <Button
            variant="primary"
            label="Next"
            onClick={() => validateSolicitationLine() && handleSubmit('next')}
            data-testid="arrow_forward"
            rightIcon={{
              name: 'arrow_forward',
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AssignQuantityTable;
