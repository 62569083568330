import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@gsa/afp-component-library';

import './contract-details.scss';

export const StandardFieldset = ({ label, children, className }) => (
  <div className={className}>
    {label && (
      <div className="text-primary text-bold text-uppercase">{label}</div>
    )}
    {children}
  </div>
);

StandardFieldset.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
};

StandardFieldset.defaultProps = {
  className: '',
  label: undefined,
};

export const StandardFieldsetRow = ({ children }) => (
  <div className="grid-row margin-bottom-4 grid-gap-4">{children}</div>
);

StandardFieldsetRow.propTypes = {
  children: PropTypes.node,
};

StandardFieldsetRow.defaultProps = {
  children: null,
};

export const StandardFieldsetRowCol = ({ colWidth, children, label, data }) => (
  <div className={`col grid-col-${colWidth}`} aria-label={label}>
    {label && <Label>{label}</Label>}
    {data && <div>{data}</div>}
    {children}
  </div>
);

StandardFieldsetRowCol.propTypes = {
  colWidth: PropTypes.number,
  children: PropTypes.node,
  label: PropTypes.string,
  data: PropTypes.node,
};

StandardFieldsetRowCol.defaultProps = {
  colWidth: 1,
  children: null,
  label: null,
  data: null,
};
