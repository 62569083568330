import React from 'react';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';

const LogoutPage = () => {
  const { logout } = useCurrentUser();
  React.useLayoutEffect(() => {
    logout();
    window.location.replace(`${window.AFP_CONFIG.appURLs.home}`);
  });

  return (
    <div>
      <h6>Logging out..</h6>
    </div>
  );
};

export default LogoutPage;
