/* eslint-disable react/prop-types */
import React from 'react';
import './make-model-table.scss';
import { Button, StatusBadge } from '@gsa/afp-component-library';
import { ENGINEER_APPROVAL_STATUS } from '../../../../bid-dashboard/constants';

const MakeAndModelTableBody = ({
  makeName,
  modelName,
  makeChassis,
  modelChassis,
  // makeApprovalStatus,
  // modelApprovalStatus,
  makeModelApprovalStatus,
  hasChassis,
  id,
  setMakeAndModeToBeDeleted,
  // chassisMakeApprovalStatus,
  openModal,
  // chassisModelApprovalStatus,
  modelYear,
}) => {
  // const makeAndModelStatus =
  //   makeApprovalStatus === 'Approved' && modelApprovalStatus === 'Approved';

  // const chassisMakeAndModelStatus =
  //   chassisModelApprovalStatus === 'Approved' &&
  //   chassisMakeApprovalStatus === 'Approved';

  const tableWidth = hasChassis ? 'chassisWidth' : 'makeAndModelWidth';

  // const getStatus = () => {
  //   if (hasChassis) {
  //     return makeAndModelStatus && chassisMakeAndModelStatus
  //       ? 'Engineering approved'
  //       : 'Needs engineering review';
  //   }
  //   return makeAndModelStatus
  //     ? 'Engineering approved'
  //     : 'Needs engineering review';
  // };

  const getBadgeVariant = (status) => {
    if (status === 'Approved') {
      return 'Ready-Gray';
    }
    if (status === 'Rejected') {
      return 'Urgent-Gray';
    }
    if (status === 'Archived') {
      return 'Inactive-Gray';
    }
    return 'Warning-Gray';
  };

  return (
    <div className={`bodyWrapper ${tableWidth}`}>
      <div
        className="statusWrapper"
        style={{ width: hasChassis ? '230px' : '235px' }}
      >
        <StatusBadge
          variant={
            // makeModelApprovalStatus === 'Approved'
            //   ? 'Ready-Gray'
            //   : 'Warning-Gray'
            getBadgeVariant(makeModelApprovalStatus)
          }
        >
          {ENGINEER_APPROVAL_STATUS[makeModelApprovalStatus]}
        </StatusBadge>
      </div>
      <div className="bodyTextWrapper">
        <p style={{ paddingLeft: hasChassis ? '8px' : '0' }}>{makeName}</p>
      </div>
      <div className="bodyTextWrapper">
        <p style={{ paddingLeft: hasChassis ? '8px' : '0' }}>{modelName}</p>
      </div>
      {hasChassis && (
        <div className="bodyTextWrapper">
          <p style={{ paddingLeft: '8px' }}>{makeChassis || ''}</p>
        </div>
      )}
      {hasChassis && (
        <div className="bodyTextWrapper">
          <p style={{ paddingLeft: '8px' }}>{modelChassis || ''}</p>
        </div>
      )}
      <div className="bodyTextWrapper">
        <p style={{ paddingLeft: hasChassis ? '8px' : '0' }}>{modelYear}</p>
      </div>
      <div className="deleteBtn">
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={() => {
            openModal();
            setMakeAndModeToBeDeleted({
              id,
              modelName,
              makeName,
            });
          }}
          data-testid={`delete-${makeName}`}
          label="Delete"
        />
      </div>
    </div>
  );
};

export default MakeAndModelTableBody;
