import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { groupBy } from 'lodash';
import {
  Accordion,
  Button,
  Checkbox,
  CounterTag,
  EmptyState,
  MultiSelect,
  Spinner,
  PageTitle,
} from '@gsa/afp-component-library';
import { useFvsPreBidContext } from '../../fvs-pre-bid-selection-provider';
import WelcomeMessage from '../../../../widgets/welcome-message';

const PreBidSiSelections = () => {
  const params = useParams();
  const { year } = params;

  const {
    vehicleGroupPreBid,
    getStandardItemsForVehicleGroup,
    isSIsForVGLoading,
    standardItemsForVehicleGroup,
    selectedVehicleGrpPreBid,
    preBidSelectionDataForVendor,
    addOrUpdatePreBidSelections,
  } = useFvsPreBidContext() || {};

  const [selectedVehicleGroup, setSelectedVehicleGroup] = useState(null);
  const [selectionChanges, setSelectionChanges] = useState({
    contractYear: Number.parseInt(year, 10),
    selections: [],
  });
  const [countByVehGrp, setCountByVehGrp] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setSelectedVehicleGroup(selectedVehicleGrpPreBid);
  }, [selectedVehicleGrpPreBid]);

  useEffect(() => {
    setSelectAll(
      countByVehGrp[selectedVehicleGroup] ===
        standardItemsForVehicleGroup?.length,
    );
  }, [
    countByVehGrp,
    selectedVehicleGroup,
    JSON.stringify(standardItemsForVehicleGroup),
  ]);

  useEffect(() => {
    if (preBidSelectionDataForVendor?.selectionsForYear) {
      const localSelections = {
        contractYear: Number.parseInt(year, 10),
        selections: [],
      };
      preBidSelectionDataForVendor?.selectionsForYear?.selections?.forEach(
        (s) => {
          localSelections.selections.push({
            vehicleGroupCode: s.vehicleGroupCode,
            selectedStandardItemIds: s.selectedStandardItemIds,
          });
        },
      );
      setSelectionChanges(localSelections);
    }
  }, [preBidSelectionDataForVendor]);

  useEffect(() => {
    const c = {};
    selectionChanges?.selections?.forEach((s) => {
      c[s.vehicleGroupCode] = s?.selectedStandardItemIds
        ? s.selectedStandardItemIds.length
        : 0;
    });
    setCountByVehGrp(c);
  }, [selectionChanges]);

  useEffect(() => {
    if (selectedVehicleGroup) {
      setSelectAll(false);
      getStandardItemsForVehicleGroup({
        variables: {
          filters: {
            operator: 'AND',
            value: [
              { operator: 'EQ', key: 'year', value: year },
              {
                operator: 'EQ',
                key: '$vehicleTypeCode.parent_code$',
                value: selectedVehicleGroup,
              },
              { operator: 'EQ', key: 'deletedAt', value: null },
            ],
          },
          limit: 1000,
          offset: 0,
          order: 'standardItemNumber ASC',
        },
      });
    }
  }, [selectedVehicleGroup]);

  const onSelectionChange = (checkboxValue, checked) => {
    let selections = [];
    let selectionIndex = -1;
    selectionChanges?.selections?.forEach((d, i) => {
      if (d?.vehicleGroupCode === selectedVehicleGroup) {
        selections = [...d.selectedStandardItemIds];
        selectionIndex = i;
      }
    });

    if (checked) {
      selections.push(checkboxValue);
    } else {
      selections = selections.filter((el) => el !== checkboxValue);
    }

    const updatedSelections = [...selectionChanges.selections];
    if (selectionIndex >= 0) {
      updatedSelections.splice(selectionIndex, 1, {
        vehicleGroupCode: selectedVehicleGroup,
        selectedStandardItemIds: selections,
      });
    } else {
      updatedSelections.push({
        vehicleGroupCode: selectedVehicleGroup,
        selectedStandardItemIds: selections,
      });
    }

    setSelectionChanges({
      contractYear: Number.parseInt(year, 10),
      selections: updatedSelections,
    });
  };

  const getSelectedForVegGrp = () => {
    let result = [];
    selectionChanges?.selections?.forEach((i) => {
      if (i?.vehicleGroupCode === selectedVehicleGroup) {
        result = i.selectedStandardItemIds;
      }
    });
    return result;
  };

  const groupStandardItemsByVehicleType = () => {
    const standardItemsByVehicleType = {};

    const groupedSi = groupBy(
      standardItemsForVehicleGroup,
      'vehicleTypeCode.code',
    );

    Object.keys(groupedSi).forEach((k) => {
      const vehicleTypeTitle = groupedSi[k][0]?.vehicleTypeCode?.title;
      standardItemsByVehicleType[vehicleTypeTitle] = {};
      standardItemsByVehicleType[vehicleTypeTitle].items = groupedSi[k].map(
        (item) => {
          return {
            label: `${item.standardItemNumber} - ${item.title}`,
            value: Number.parseInt(item.id, 10),
          };
        },
      );
    });

    return standardItemsByVehicleType;
  };

  const getStandardItemsByVehicleType = () => {
    const standardItemsByVehicleType = groupStandardItemsByVehicleType();
    return Object.keys(standardItemsByVehicleType).map((vehicleType) => ({
      title: vehicleType,
      content: (
        <div
          style={{
            height:
              standardItemsByVehicleType[vehicleType]?.items?.length > 15
                ? '500px'
                : '',
            overflowY:
              standardItemsByVehicleType[vehicleType]?.items?.length > 15
                ? 'scroll'
                : '',
          }}
        >
          {standardItemsByVehicleType[vehicleType]?.items && (
            <div className="margin-left-1">
              <MultiSelect
                id={`si-list-${selectedVehicleGroup}`}
                key={`si-list-${selectedVehicleGroup}`}
                data-testid="pso-questionnaire-std-item"
                options={standardItemsByVehicleType[vehicleType]?.items}
                onChange={onSelectionChange}
                selectedValues={getSelectedForVegGrp()}
              />
            </div>
          )}
        </div>
      ),
      expanded: false,
      id: vehicleType,
    }));
  };

  // triggered when the user clicks on the "Select All" button
  const onSelectAll = (isSelected) => {
    let selections = [];
    const selectedStdItemIds = [];

    const updatedSelections = [...selectionChanges.selections];
    const standardItemsByVehicleType = groupStandardItemsByVehicleType();

    // pick the vehicle group's standard items
    Object.keys(standardItemsByVehicleType).forEach((vehicleType) => {
      // eslint-disable-next-line array-callback-return
      standardItemsByVehicleType[vehicleType].items.map((item) => {
        if (isSelected) {
          const vehicleGroupCodeStdIds = {
            vehicleGroupCode: selectedVehicleGroup,
            selectedStdId: item.value,
          };
          selections.push(vehicleGroupCodeStdIds);
        } else {
          selections = selections.filter(
            (el) => el.vehicleGroupCode === selectedVehicleGroup,
          );
        }
      });
    });

    if (!isSelected) {
      // remove the unselected vehicle group's standard item ids from the selections
      selections
        .filter((a) => a.vehicleGroupCode !== selectedVehicleGroup)
        .map((b) => selectedStdItemIds.push(b.selectedStdId));
    } else {
      // add the selected vehicle group's standard item ids to the selections
      selections.map((c) => selectedStdItemIds.push(c.selectedStdId));
    }

    if (
      updatedSelections.filter(
        (a) => a.vehicleGroupCode === selectedVehicleGroup,
      ).length > 0
    ) {
      // if selections already exist for the vehicle group, update the selected standard item ids
      updatedSelections.filter(
        (res) => res.vehicleGroupCode === selectedVehicleGroup,
      )[0].selectedStandardItemIds =
        selectedStdItemIds.length > 0 ? selectedStdItemIds : [];
    } else {
      updatedSelections.push({
        vehicleGroupCode: selectedVehicleGroup,
        selectedStandardItemIds: selectedStdItemIds,
      });
    }

    setSelectionChanges({
      contractYear: Number.parseInt(year, 10),
      selections: updatedSelections,
    });
  };

  const handlePreBidSelectionsSave = () => {
    addOrUpdatePreBidSelections({
      variables: {
        selections: selectionChanges,
      },
    });
  };

  return (
    <>
      <div className="grid-row grid-gap position-relative  margin-bottom-3">
        <div className="desktop:grid-col-10">
          <PageTitle
            aria-label="My Pre-Bid Selections"
            tabIndex="0"
            title="My Pre-Bid Selections"
          />
          <WelcomeMessage message="The commenting period is now open and GSA wants your feedback. Start by selecting vehicle groups and then related standard items you're interested in. Save these selections to help track review and comment opportunities to save time when bidding season begins." />
        </div>
        <div className="desktop:grid-col-2 display-flex flex-row flex-justify-end">
          <div>
            <Button
              type="button"
              data-testid="questionnaire-save-btn"
              className="questionnaire-save-btn"
              onClick={handlePreBidSelectionsSave}
              label="Save and exit"
            />
          </div>
        </div>
      </div>
      <div className="grid-row grid-gap position-relative margin-bottom-15">
        <div className="desktop:grid-col-4 vehicle-group-navbar">
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
          <h2 aria-label="Vehicle groups" tabIndex="0">
            Vehicle groups
          </h2>
          {vehicleGroupPreBid && (
            <ul className="usa-sidenav">
              {Object.keys(vehicleGroupPreBid).map((vehGrp) => {
                // eslint-disable-next-line react/no-array-index-key
                return (
                  <li
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                    tabIndex="0"
                    key={`sidenav_item_${vehGrp}`}
                    className="usa-sidenav__item"
                  >
                    {/* eslint-disable jsx-a11y/anchor-is-valid */
                    /* eslint-disable jsx-a11y/no-static-element-interactions */}
                    <a
                      data-testid="questionnaire-vehicle-group"
                      className={
                        selectedVehicleGroup === vehGrp ? 'usa-current' : ''
                      }
                      onClick={() => setSelectedVehicleGroup(vehGrp)}
                      onKeyDown={() => setSelectedVehicleGroup(vehGrp)}
                      // next line commented out to support proper Tab ordering issue reported with APF-11484
                      // tabIndex={key}
                    >
                      {vehicleGroupPreBid[vehGrp].name}{' '}
                      {countByVehGrp[vehGrp] > 0 ? (
                        <CounterTag count={countByVehGrp[vehGrp]} />
                      ) : null}
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <div className="desktop:grid-col-8">
          {vehicleGroupPreBid && (
            <>
              <div className="grid-row flex-justify-self-start flex-align-baseline">
                <h2
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                  tabIndex={0}
                  aria-label={`Standard Item - ${
                    vehicleGroupPreBid[selectedVehicleGroup]
                      ? vehicleGroupPreBid[selectedVehicleGroup].name
                      : ''
                  }`}
                >
                  Standard Items -{' '}
                  {vehicleGroupPreBid[selectedVehicleGroup]
                    ? vehicleGroupPreBid[selectedVehicleGroup].name
                    : ''}
                </h2>

                <Checkbox
                  className="margin-left-3"
                  label="Select all"
                  aria-label="Checkbox"
                  data-testid="selectAllCheckBox"
                  name="checkbox_pso_questionnaire"
                  value={selectAll}
                  checked={selectAll}
                  onChange={() => {
                    setSelectAll(!selectAll);
                    onSelectAll(!selectAll);
                  }}
                />
              </div>
              <div className="grid-row flex-justify-self-start flex-align-baseline">
                {isSIsForVGLoading && <Spinner className="margin-y-9" />}
                {!isSIsForVGLoading && (
                  <Accordion
                    multiselectable
                    bordered
                    items={getStandardItemsByVehicleType()}
                  />
                )}
              </div>
            </>
          )}

          {!vehicleGroupPreBid && (
            <EmptyState
              hasBackground
              containerStyles="padding-y-8 margin-top-1 height-full"
              topText={
                <>
                  <strong>
                    Pre-bid selections are not available for {year}
                  </strong>
                </>
              }
              topTextStyles="margin-top-7"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PreBidSiSelections;
