import { useLazyQuery } from '@apollo/client';
import { AFPTable, StatusBadge } from '@gsa/afp-component-library';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  GET_ASSOCIATED_STANDARD,
  GET_EQ_STANDARD_ITEMS,
} from '../../services/data-store';
import { useEquipmentCodes } from './equipment-codes-provider';

const AssociatedStandardItemsTable = () => {
  const history = useHistory();

  const {
    showFormModal,
    selectedEquipmentCode: selectedItem,
  } = useEquipmentCodes();

  const [responseData, setResponseData] = useState([]);

  const [getData, { data }] = useLazyQuery(GET_ASSOCIATED_STANDARD, {
    fetchPolicy: 'network-only',
    variables: {
      filters: {
        operator: 'EQ',
        key: 'equipmentCodeId',
        value: Number(selectedItem?.id),
      },
    },
  });

  const [getStandardItems, { data: standardItemsData }] = useLazyQuery(
    GET_EQ_STANDARD_ITEMS,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    // Don't fire getData if modal is not open.
    if (!showFormModal || !selectedItem?.id) return;
    getData();
  }, [showFormModal]);

  useEffect(() => {
    if (!data?.getEquipmentAssociations || !selectedItem?.year) return;

    const ids = data.getEquipmentAssociations.rows.map(
      (eq) => eq.standardItem?.standardItemNumber,
    );

    if (ids?.length) {
      getStandardItems({
        variables: {
          filters: {
            operator: 'AND',
            key: 'standardItemNumber',
            value: [
              {
                operator: 'IN',
                key: 'standardItemNumber',
                value: ids,
              },
              {
                operator: 'EQ',
                key: 'year',
                value: selectedItem.year,
              },
            ],
          },
          order: 'standardItemNumber ASC',
        },
      });
    }
  }, [data]);

  useEffect(() => {
    if (standardItemsData?.getStandardItems) {
      setResponseData(standardItemsData.getStandardItems.rows);
    }
  }, [standardItemsData]);

  const columns = useMemo(
    () => [
      {
        Header: 'Standard Item',
        accessor: 'standardItemNumber',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value, row }) =>
          value ? (
            <button
              type="button"
              className="usa-button usa-button--unstyled standard-item-view-button"
              onClick={() => {
                // eslint-disable-next-line react/prop-types
                const url = `/catalog/standard-item/${value}-${row.original.year}`;
                // eslint-disable-next-line react/prop-types
                history.push(url, row.original);
              }}
            >
              {value}
            </button>
          ) : null,
      },
      {
        Header: 'Vehicle Type',
        accessor: 'vehicleTypeCode.title',
      },
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Status',
        accessor: 'status',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <StatusBadge
            variant={value === 'Active' ? 'Ready-Gray' : 'Inactive-Gray'}
          >
            {value}
          </StatusBadge>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <AFPTable
        columns={columns}
        data={responseData}
        defaultSort="standardItemNumber ASC"
        fullWidth
      />
    </>
  );
};

export default AssociatedStandardItemsTable;
