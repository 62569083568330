import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, AccordionItem } from '@gsa/afp-component-library';
import {
  ALL_TABS,
  COPY_MODAL_TITLE,
  TAB_KEY,
  countCopyItems,
  getScheduleLine,
  sentenceCase,
} from './helpers';
import { BASE_ENGINE_VIRTUAL_CODE } from '../provider/helpers';

const CopyResultsModal = ({ tabKey, responses, bidLines, onClose }) => {
  const getItemName = (item) => {
    if (item.ec === BASE_ENGINE_VIRTUAL_CODE) return item.title;
    return `${item.ec} - ${item.title}`;
  };
  const showResultEcList = (resEcs, title, text) => {
    if (!resEcs?.length) return null;
    return (
      <li className="margin-bottom-2" style={{ maxWidth: '80rem' }}>
        <div>
          <b>{resEcs.length}</b> {title} item(s) {text}:
          <ul className="margin-top-0">
            {resEcs.map((item) => {
              const name = getItemName(item);
              return (
                <li key={name} style={{ maxWidth: '80rem' }}>
                  <span className="text-bold">{name}</span>
                  {item.errors ? `: ${item.errors.join('; ')}` : ''}
                </li>
              );
            })}
          </ul>
        </div>
      </li>
    );
  };

  const showTabResult = (tabRes, title) => {
    if (tabRes.error)
      return (
        <div>
          Copying {title} failed: {tabRes.error}
        </div>
      );
    if (countCopyItems(tabRes) === 0) return <div>No results</div>;
    return (
      <ul className="margin-top-0">
        {showResultEcList(
          tabRes.copied,
          title,
          'have been copied successfully',
        )}
        {showResultEcList(
          tabRes.partialCopied,
          'have been partially copied successfully',
        )}
        {showResultEcList(tabRes.notCopied, title, 'could not be copied')}
        {showResultEcList(tabRes.deleted, title, 'have been deleted')}
        {showResultEcList(
          tabRes.srcOnly,
          title,
          'could not be copied because they only exist in the source',
        )}
        {showResultEcList(
          tabRes.destOnly,
          title,
          'could not be copied because they only exist in the destination',
        )}
      </ul>
    );
  };

  const showTabResultInAccordion = (res, tab) => {
    const [expanded, setExpanded] = useState(false);
    const title = COPY_MODAL_TITLE[tab];
    return (
      <AccordionItem
        id={tab}
        title={sentenceCase(title)}
        expanded={expanded}
        content={showTabResult(res[tab], title)}
        handleToggle={() => setExpanded(!expanded)}
      />
    );
  };

  const showResults = (res) => {
    if (res.error) return <div>Copying line item failed: {res.error}</div>;
    if (tabKey !== TAB_KEY.allTabs)
      return showTabResult(res[tabKey], COPY_MODAL_TITLE[tabKey]);
    return ALL_TABS.map((tab) => (
      <div key={tab} className="margin-bottom-2">
        {showTabResultInAccordion(res, tab)}
      </div>
    ));
  };

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          id="copy-results-modal"
          variant="extra-large"
          title={<h1>Copy {COPY_MODAL_TITLE[tabKey]} results</h1>}
          onClose={onClose}
          actions={
            <Button
              data-testid="close-copy-results-modal-btn"
              variant="unstyled"
              onClick={onClose}
              label="Close"
            />
          }
        >
          <div className="overflow-y-auto minh-mobile maxh-mobile-lg">
            {responses.map((res) => {
              const fromScheduleLine = getScheduleLine(
                res.fromBidLineId,
                bidLines,
              );
              const toScheduleLine = getScheduleLine(res.toBidLineId, bidLines);
              return (
                <div key={res.toBidLineId}>
                  <h3 className="margin-bottom-0">
                    Copy from line item {fromScheduleLine} to line item{' '}
                    {toScheduleLine}
                  </h3>
                  {showResults(res)}
                </div>
              );
            })}
          </div>
        </Modal>
      </div>
    </div>
  );
};

CopyResultsModal.propTypes = {
  tabKey: PropTypes.string.isRequired,
  responses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  bidLines: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CopyResultsModal;
