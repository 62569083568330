/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { RadioButton } from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { useCommentsAdmin } from './comments-admin-provider';
import { STATUS_MAP } from './helpers';
import { getAuthor } from '../../utilities/types/role-types';

const BulkReview = ({ formId }) => {
  const [, setStatus] = useState(null);
  const {
    selectedComments,
    bulkReplyReview,
    setData,
    commentFormState,
  } = useCommentsAdmin();
  const {
    currentUser: { roles },
  } = useCurrentUser();
  const handleFormSubmit = (isPublish) => {
    const commentIds = selectedComments.map((comment) => {
      return Number(comment.original.id);
    });

    const bulkInput = {
      status: STATUS_MAP[commentFormState],
      comment: '',
      actionType: 'REVIEW',
      applyToIds: commentIds,
      authorType: getAuthor(roles),
      isPublish,
    };

    bulkReplyReview({
      variables: {
        bulkInput,
      },
    });
    setData('SET_MODAL_MODE', null);
    setData('SET_COMMENT_FORM_STATE', null);
  };

  const statusOptions = [
    { id: 2, label: 'Approved', value: 'Approved' },
    { id: 3, label: 'Disapproved', value: 'Disapproved' },
    { id: 1, label: 'Noted', value: 'Noted' },
    { id: 4, label: 'Invalid', value: 'Invalid' },
  ];

  return (
    <>
      <div className="grid-col">
        {/* eslint-disable-next-line */}
        <span className="text-bold" tabIndex="0" aria-label="Review state">
          Review state
        </span>
        <form
          id={formId}
          onSubmit={(e) => {
            e.preventDefault();
            const butonName = e.nativeEvent?.submitter?.name;
            const isPublish = butonName === 'apply_publish';
            handleFormSubmit(isPublish);
          }}
        >
          {statusOptions.map((st, index) => {
            return (
              <RadioButton
                name="commentStatus"
                id={st.id}
                key={st.id}
                tabIndex={index}
                aria-label={st.value}
                value={st.value}
                label={st.label}
                checked={commentFormState === st.value}
                onClick={() => {
                  setStatus(st.value);
                  setData('SET_COMMENT_FORM_STATE', st.value);
                }}
              />
            );
          })}
        </form>
      </div>
    </>
  );
};

export default BulkReview;
