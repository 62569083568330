/* eslint-disable import/prefer-default-export */
import { atom } from 'recoil';

export const vehicleClassifications = atom({
  key: 'Solicitation/selectedStandardItem/VehicleClassifications',
  default: [],
});

export const multipleOptions = atom({
  key: 'Solicitation/selectedStandardItem/MultipleOptions',
  default: [],
});
export const modalMode = atom({
  key: 'Solicitation/selectedStandardItem/ModalMode',
  default: null,
});

export const inactiveSIArray = atom({
  key: 'Solicitation/selectedStandardItem/inacgiveSIArray',
  default: [],
});
