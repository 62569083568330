import { useQuery } from '@apollo/client';
import { flow } from 'lodash/fp';
import GET_ENABLED_CATALOG_FEATURES from './enabled-features-query';
import GET_VENDORS from './get-vendors-query';

// ()->{data}
const retriveDataObJ = () =>
  useQuery(GET_ENABLED_CATALOG_FEATURES, {
    fetchPolicy: 'network-only',
  });

const retriveVendorObJ = () =>
  useQuery(GET_VENDORS, {
    variables: {
      operation: 'view',
      subject: 'Vendor',
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

// (string)->({data})->bool
const validateFeature = (featureName) => ({ data }) => {
  if (data && data.getEnabledCatalogFeatures) {
    const { getEnabledCatalogFeatures } = data;
    if (getEnabledCatalogFeatures.includes(featureName)) {
      return true;
    }
  }
  return false;
};

const validateVendorFeature = ({ data }) => {
  if (
    data &&
    data.getVendorsByPermission &&
    data.getVendorsByPermission.length > 0
  ) {
    const hasDraftInactiveVendors = data.getVendorsByPermission?.some(
      (vndr) => vndr.fleetStatus === 'Draft' || vndr.fleetStatus === 'Inactive',
    );
    if (hasDraftInactiveVendors) {
      return false;
    }
  }
  return true;
};

// {featureName:string}->bool
const validateProcess = ({ featureName }) => {
  const validateFeatureSingleName = validateFeature(featureName);
  return flow(retriveDataObJ, validateFeatureSingleName)();
};

export const validateVendorProcess = () => {
  return flow(retriveVendorObJ, validateVendorFeature);
};

export default validateProcess;
