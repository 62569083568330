import React from 'react';
import { Redirect } from 'react-router-dom';
import { Spinner } from '@gsa/afp-component-library';
import { useQuery } from '@apollo/client';
import { PrivateRoute, useAppAbility } from '@gsa/afp-shared-ui-utils';
import GET_VENDORS from '../feature-toggle/get-vendors-query';
import CAN_I_ACCESS_BID from './queries';

const NonAdminControlledAccess = ({ ...rest }) => {
  const {
    loading: vendorLoading,
    called: vendorCalled,
    data: vendorData,
  } = useQuery(GET_VENDORS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      operation: 'view',
      subject: 'Vendor',
    },
  });

  const { bidId = null } = rest?.computedMatch?.params;
  const {
    loading: bidAccessLoading,
    called: bidAccessCalled,
    data: bidAccessData,
  } = useQuery(CAN_I_ACCESS_BID, {
    fetchPolicy: 'cache',
    variables: { bidId },
    skip: !bidId,
  });

  if (vendorCalled && vendorLoading) {
    return (
      <Spinner
        data-testid="access-route-spinner"
        className="margin-top-4"
        size="medium"
      />
    );
  }

  const hasDraftInactiveVendors = vendorData?.getVendorsByPermission?.some(
    (vndr) => vndr.fleetStatus === 'Draft' || vndr.fleetStatus === 'Inactive',
  );

  if (hasDraftInactiveVendors) {
    return <Redirect to="/unauthorized" />;
  }

  if (bidId !== null) {
    if (bidAccessCalled && bidAccessLoading) {
      return (
        <Spinner
          data-testid="access-route-spinner"
          className="margin-top-4"
          size="medium"
        />
      );
    }

    const isBidAccessible = bidAccessData?.canIAccessThisBid;

    if (!isBidAccessible) {
      return <Redirect to="/unauthorized" />;
    }
  }

  return <PrivateRoute {...rest} />;
};

const AccessControlledRoute = ({ ...rest }) => {
  const ability = useAppAbility();

  if (ability === undefined || ability === null) {
    // render nothing until useAbility is done loading
    // then we decide what to do based on the ability
    return null;
  }

  if (ability.can('review', 'Bid')) {
    return <PrivateRoute {...rest} />;
  }

  return <NonAdminControlledAccess {...rest} />;
};

export default AccessControlledRoute;
