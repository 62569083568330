import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@gsa/afp-component-library';
import EditTaskForm from './widgets/EditTaskForm';

const EditModal = ({ closeModal, onSubmit }) => {
  return (
    <Modal
      id="edit-task-modal"
      title={<h2>Edit Task</h2>}
      onClose={closeModal}
      actions={
        <>
          <Button
            type="button"
            variant="outline"
            onClick={closeModal}
            label="Cancel"
          />
          <Button
            form="edit-task-form"
            type="submit"
            onClick={onSubmit}
            variant="primary"
            label="Save and close"
          />
        </>
      }
    >
      <EditTaskForm handleSave={onSubmit} />
    </Modal>
  );
};

EditModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default EditModal;
