import moment from 'moment';
import { formatDate } from '../../../../../utilities/date-helpers';

export const getTimestampFromDateNTime = (date, time) =>
  moment(`${moment(date).format('YYYY-MM-DD')} ${time}`).toDate();

// export const getDateFromTimeStamp = (ts) =>
//   moment(`${ts.split()[0]}`, 'YYYY-MM-DD').format('MM-DD-YYYY');

// export const getTimeFromTimeStamp = (ts) =>
//   moment(ts, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');

export const getLastSolicitationPeriod = (periods) => {
  const periodsArr = [...periods];

  // Sort in descending order of the period start date
  const sortedPeriods =
    periodsArr.length > 1
      ? [
          ...periodsArr.sort(
            (a, b) => new Date(b.startDate) - new Date(a.startDate),
          ),
        ]
      : [...periodsArr];
  return sortedPeriods.length > 0 ? sortedPeriods[0] : {};
};

export const processOpenPeriod = (periods) => {
  const periodsArr = [...periods];

  const openPeriods = [
    ...periodsArr.filter((period) => period.periodType === 'O'),
  ];

  return openPeriods.length > 0 ? openPeriods[0] : {};
};

export const processReopenPeriods = (periods) => {
  const periodsArr = [...periods];

  const reopenPeriods = [
    ...periodsArr.filter(
      (period) => period.periodType === 'R' || period.periodType === 'M',
    ),
  ];

  return reopenPeriods.length > 1
    ? [
        ...reopenPeriods.sort(
          (a, b) => new Date(a.startDate) - new Date(b.startDate),
        ),
      ]
    : [...reopenPeriods];
};

export const processOnlyReopenPeriods = (periods) => {
  const periodsArr = [...periods];

  const reopenPeriods = [
    ...periodsArr.filter((period) => period.periodType === 'R'),
  ];

  return reopenPeriods.length > 1
    ? [
        ...reopenPeriods.sort(
          (a, b) => new Date(a.startDate) - new Date(b.startDate),
        ),
      ]
    : [...reopenPeriods];
};

export const processOnlyMidcyclePeriods = (periods) => {
  const periodsArr = [...periods];

  const midcyclePeriods = [
    ...periodsArr.filter((period) => period.periodType === 'M'),
  ];

  return midcyclePeriods.length > 1
    ? [
        ...midcyclePeriods.sort(
          (a, b) => new Date(a.startDate) - new Date(b.startDate),
        ),
      ]
    : [...midcyclePeriods];
};

export const transformDataSolicitationPeriod = (solicitationPeriod) => {
  if (!solicitationPeriod?.startDate) return solicitationPeriod;
  const startDate = formatDate(solicitationPeriod?.startDate, 'YYYY-MM-DD');
  const startTime = formatDate(solicitationPeriod?.startDate, 'HH:mm');
  const endDate = formatDate(solicitationPeriod?.endDate, 'YYYY-MM-DD');
  const endTime = formatDate(solicitationPeriod?.endDate, 'HH:mm');
  return {
    ...solicitationPeriod,
    startDate,
    startTime,
    endDate,
    endTime,
    vendors: solicitationPeriod?.solicitationPeriodVendors,
  };
};

export const yupValidateDateRange = (previousDate, nextDate) => {
  return function validation(endTime) {
    const { startTime, startDate, endDate } = this?.parent || {};
    const rangeStartDateTime = getTimestampFromDateNTime(startDate, startTime);
    const rangeEndDateTime = getTimestampFromDateNTime(endDate, endTime);
    if (!previousDate && !nextDate) return true;
    if (!previousDate) return moment(rangeEndDateTime).isBefore(nextDate);
    if (!nextDate) return moment(rangeStartDateTime).isAfter(previousDate);
    return (
      moment(rangeStartDateTime).isAfter(previousDate) &&
      moment(rangeEndDateTime).isBefore(nextDate)
    );
  };
};
