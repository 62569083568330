import React, { useEffect, useMemo, useState } from 'react';
import orderBy from 'lodash/orderBy';
import { useHistory } from 'react-router';

import {
  AFPTable,
  StatusBadge,
  Button,
  EmptyState,
} from '@gsa/afp-component-library';
import { useFvsPreBidContext } from '../../fvs-pre-bid-selection-provider';
import {
  PRE_BID_DOCUMENTATION_LABELS,
  DOCUMENT_REVIEW_STATUS,
} from '../constants';
/* eslint-disable react/prop-types */
const PsoReviewDocumentList = () => {
  const history = useHistory();
  const { preBidDocumentationListForVendor } = useFvsPreBidContext();

  const [order, setOrder] = useState('fvsId ASC');

  const [tableData, setTableData] = useState([]);

  const handleSelectedRowClick = (evt, row) => {
    if (evt === 'document') {
      const { fvsId, contractYear } = row.original;
      const url = `/catalog/vehicle-standards/preview/${contractYear}/${fvsId}`;
      // TO-DO
      // set setFvsPreBidContextData('SET_MODAL_MODE', false); in order to close the modal
      history.push(url, row.original);
    }
  };

  const mapStatusToBadgeColor = (reviewStatus) => {
    return reviewStatus === 1 ? 'Ready-Gray' : 'Warning-Gray';
  };

  /* eslint-disable-next-line */
  const columns = useMemo(() => {
    const columnList = [
      {
        Header: PRE_BID_DOCUMENTATION_LABELS.DOCUMENTS,
        accessor: 'fvsId',
        Cell: ({ row }) => {
          return (
            <Button
              variant="unstyled"
              data-testid="select-row"
              onClick={() => handleSelectedRowClick('document', row)}
              label={`${row?.original?.fedVehicleStandard?.fedStandardCode} - ${row?.original?.fedVehicleStandard?.fedStandard?.title}`}
            />
          );
        },
      },
      {
        Header: PRE_BID_DOCUMENTATION_LABELS.STATUS,
        accessor: 'reviewStatus',
        sortable: false,
        Cell: ({ value }) => {
          return (
            <StatusBadge variant={mapStatusToBadgeColor(value)}>
              {value === 1
                ? DOCUMENT_REVIEW_STATUS.REVIEW_DONE
                : DOCUMENT_REVIEW_STATUS.NEEDS_REVIEW}
            </StatusBadge>
          );
        },
      },
    ];

    return columnList;
  }, []);

  useEffect(() => {
    if (
      preBidDocumentationListForVendor &&
      preBidDocumentationListForVendor?.preBidReviewDocuments
    ) {
      setTableData(
        orderBy(
          preBidDocumentationListForVendor?.preBidReviewDocuments,
          'fvsId',
          'ASC',
        ),
      );
    }
  }, []);

  useEffect(() => {
    if (tableData?.length > 0 && order) {
      const sortColumn = order?.split(' ')[0]?.replace(/`/g, '');
      // requires asc/desc to be lowercase for lodash orderBy function
      const sortOrder = order?.split(' ')[1]?.toLowerCase();
      setTableData(orderBy([...tableData], sortColumn, sortOrder));
    }
  }, [order]);

  return (
    <>
      {tableData && tableData.length > 0 ? (
        <AFPTable
          fullWidth
          testId="pso-review-doc-list"
          columns={columns}
          data={tableData}
          onSort={setOrder}
          defaultSort={order}
        />
      ) : (
        <EmptyState
          hasBackground
          containerStyles="padding-y-8 margin-top-1"
          topText={<strong>There is no document to review.</strong>}
        />
      )}
    </>
  );
};

export default PsoReviewDocumentList;
