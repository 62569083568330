import React from 'react';
import { PageTitle } from '@gsa/afp-component-library';
import { useEquipmentDetailCodes } from './equipment-code-detail-provider';

const EquipmentCodePageTitle = () => {
  const { selectedEquipmentCode } = useEquipmentDetailCodes();

  return (
    <>
      <PageTitle
        aria-label={`${selectedEquipmentCode?.code} - ${selectedEquipmentCode?.title} - ${selectedEquipmentCode?.year}`}
        tabIndex="0"
        title={`${selectedEquipmentCode?.code} - ${selectedEquipmentCode?.title} - ${selectedEquipmentCode?.year}`}
      />
    </>
  );
};

export default EquipmentCodePageTitle;
