import { useState } from 'react';
import useToggle from './use-toggle';

const useSimpleAlertModal = () => {
  const [isModalOpen, toggleModal] = useToggle();
  const [data, setData] = useState({});

  const handleToggleModal = (onProceedPayload = {}) => {
    setData(onProceedPayload);
    toggleModal();
  };

  const handleOnModalProceed = (callbackFunc) => () => callbackFunc(data);
  return {
    isModalOpen,
    handleOnModalProceed,
    handleToggleModal,
  };
};

export default useSimpleAlertModal;
