import React from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { EmptyState, Button, Spinner } from '@gsa/afp-component-library';
import BidsSolicitationBlock from '../components/bids-solicitation-block/bids-solicitation-block';
import { useVendorInfo } from './apis/vendor-api';
import { GET_ACTIVE_BIDS_FOR_VENDOR } from '../bids.gql';
import { bidsActiveBidsAtom } from '../atoms/bids-atoms';

const ActiveBids = () => {
  const {
    vendor,
    vendorInfoForAdmin,
    hasAdminPrivilege,
    setAlert,
  } = useVendorInfo();
  const history = useHistory();
  // vendor-id is determined based on the logged in role
  const vendorId = hasAdminPrivilege ? vendorInfoForAdmin?.id : vendor?.id;

  // set atoms
  const [activeBids, setActiveBids] = useRecoilState(bidsActiveBidsAtom);

  const { loading } = useQuery(GET_ACTIVE_BIDS_FOR_VENDOR, {
    fetchPolicy: 'network-only',
    variables: {
      vendorId,
    },
    onCompleted: (responseData) => {
      if (responseData?.getActiveBidsForVendor) {
        const bids = responseData.getActiveBidsForVendor || [];
        const filteredBids = bids.filter((bid) => !bid.showReopenLink);
        setActiveBids(filteredBids);
      }
    },
    skip: !vendorId,
  });

  return (
    <div className="desktop:grid-col-12">
      <div
        className="text-primary bid-header"
        data-testid="my-active-solicitation-header"
      >
        My ACTIVE SOLICITATIONS
      </div>
      <div>
        {activeBids?.map((bid) => {
          const { Days, Hours, Minutes } =
            bid?.solicitationPeriod?.closeInDays || {};
          const allIds = bid?.BidLines.filter(
            (bl) => bl?.scheduleLine,
          )?.map((bl) => parseInt(bl?.id, 10));
          const completedIds = bid?.BidLines.filter(
            (bl) => bl?.status === 'Completed',
          )?.map((bl) => parseInt(bl?.id, 10));

          return (
            // only show solicitaions which are active
            !!(Number(Days) || Number(Hours) || Number(Minutes)) && (
              <BidsSolicitationBlock
                testId={bid?.id}
                key={bid?.id}
                solicitationNumber={bid?.SolicitaionInfo?.solicitationNumber}
                title={bid?.SolicitaionInfo?.title}
                samUrl={bid?.SolicitaionInfo?.samUrl}
                solicitationPeriod={bid?.solicitationPeriod}
                bidId={bid?.id}
                solId={bid?.solicitationId}
                bidLineIds={allIds}
                submittedBidLineIds={completedIds}
                setAlert={setAlert}
              >
                {!!(Number(Days) || Number(Hours) || Number(Minutes)) && (
                  <Button
                    variant="outline"
                    label={hasAdminPrivilege ? 'View dashboard' : 'Edit'}
                    onClick={() => {
                      history.push(
                        `/catalog/solicitations/${bid?.solicitationId}/bid-dashboard/${bid?.id}`,
                      );
                    }}
                  />
                )}
              </BidsSolicitationBlock>
            )
          );
        })}
        {loading && <Spinner />}
        {activeBids?.length < 1 && (
          <EmptyState
            hasBackground
            containerStyles="adding-y-8 margin-top-1"
            data-testid="emptyActiveSolicitations"
            topText={
              <>
                You have no solicitations that you are actively bidding on.{' '}
                <br /> See available solicitations below.
              </>
            }
          />
        )}
      </div>
      <br />
    </div>
  );
};

export default ActiveBids;
