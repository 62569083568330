import React from 'react';
import { Label } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

const Fieldset = ({ title, description, defaultDescription, ...restProps }) => {
  const ariaLabel = `${title} ${description}`;
  return (
    <section aria-label={ariaLabel} {...restProps}>
      <Label className="text-bold">{title}</Label>
      <p>{description || defaultDescription}</p>
    </section>
  );
};

Fieldset.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  defaultDescription: PropTypes.string,
};
Fieldset.defaultProps = {
  defaultDescription: '-',
};

export default Fieldset;
