import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Typeahead } from '@gsa/afp-component-library';
import { GET_OPTIONS } from '../../services/data-store';
import { useStandardItem } from '../../pages/standard-items-detail/standard-item-provider'; // TODO component cannot load from pages

let userTyped = false;

const EquipmentCodeTypeaheadInput = ({ onChange, value, program }) => {
  const {
    standardItem: { year },
    equipmentAssociationList: { rows },
  } = useStandardItem();
  const equipmentAssociationIds = rows.map(
    ({ equipmentCodeId }) => equipmentCodeId,
  );

  const [option, setOption] = useState({ values: [], raw: [] });
  const [selectedProgram, setSelectedProgram] = useState(program);

  useEffect(() => {
    setSelectedProgram(program);
  }, [program]);

  useEffect(() => {
    setOption({ values: [], raw: [] });
  }, [selectedProgram]);

  const [getOptionsForECTypeInModal] = useLazyQuery(GET_OPTIONS, {
    onCompleted: (data) => {
      let result = { values: [], raw: [] };
      if (data?.getOptions) {
        result = {
          values: data.getOptions.map((ec) => `${ec.value} - ${ec.label}`),
          raw: data.getOptions,
        };
      }
      setOption(() => {
        if (result.values.length > 0) {
          return result;
        }
        return { values: ['Error: 404'], raw: ['Error: 404'] };
      });
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    },
  });

  return (
    <div className="margin-top-1">
      <Typeahead
        key={`toStandardItemId${selectedProgram}`} // This triggers a rerender and the type-ahead to take program change
        data-testid="toStandardItemId"
        name="equipment code"
        required="true"
        filterValue={value}
        typeaheadValues={option?.values}
        onFilterKeyDown={() => {
          userTyped = true;
        }}
        onOptionEnter={(selected) => {
          const selectedData = option?.raw.find(
            (ec) => `${ec.value} - ${ec.label}` === selected,
          );
          onChange({ selected, selectedData });
        }}
        onClear={(selected) => {
          onChange({ selected, undefined });
        }}
        fetchTypeaheadValues={(_, search) => {
          const keyword = search.trim();
          if (userTyped && keyword?.length > 0) {
            getOptionsForECTypeInModal({
              variables: {
                model: 'EquipmentCode',
                label: 'title',
                value: 'code',
                filter: {
                  operator: 'AND',
                  value: [
                    {
                      operator: 'NOTIN',
                      key: 'id',
                      value: equipmentAssociationIds, // TODO SQLs have a limit on the items in list. This should be handled in the query.
                    },
                    {
                      operator: 'EQ',
                      key: 'year',
                      value: year,
                    },
                    {
                      operator: 'EQ',
                      key: 'program',
                      value: selectedProgram,
                    },
                    {
                      operator: 'OR',
                      value: [
                        {
                          operator: 'LIKE',
                          key: 'code',
                          value: keyword,
                        },
                        {
                          operator: 'LIKE',
                          key: 'title',
                          value: keyword,
                        },
                      ],
                    },
                  ],
                },
              },
            });
            userTyped = false;
          }
        }}
        debounceDelay={500}
        inputCharNum={0}
      />
    </div>
  );
};

EquipmentCodeTypeaheadInput.defaultProps = {
  value: '',
};
EquipmentCodeTypeaheadInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  program: PropTypes.string.isRequired,
};

export default EquipmentCodeTypeaheadInput;
