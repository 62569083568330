/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { useRecoilState } from 'recoil';
import { STATUS_OPTIONS } from './constants';
import { GET_SOLICITATION_TYPE_AHEAD_OPTIONS } from './queries';
import { GET_MULTIPLE_OPTIONS } from '../../services/data-store';
import {
  selectedProcess,
  selectedYear,
  selectedStatus,
  selectedPurchaseType,
  selectedProgramName,
  selectedSolicitationNumber,
  selectedTitle,
} from './solicitation-helpers';
import solicitationNotifications from './utils/solicitation-notification-helpers';
import useLocalStorage from '../../hooks/use-local-storage';

const SolicitationFilters = () => {
  const { FilterPanel: ComponentFilterPanel } = FilterPanel;
  const [filterOptions, setFilterOptions] = useState([]);

  const [localeStorateFilters, setLocalStorageFilter] = useLocalStorage(
    'SOLICITATION_TABLE_FILTERS',
    null,
  );

  // set atoms
  const [, setProcess] = useRecoilState(selectedProcess);
  const [year, setYear] = useRecoilState(selectedYear);
  const [, setSolicitationNumber] = useRecoilState(selectedSolicitationNumber);
  const [, setTitle] = useRecoilState(selectedTitle);
  const [, setStatus] = useRecoilState(selectedStatus);
  const [, setPurchaseType] = useRecoilState(selectedPurchaseType);
  const [, setProgramName] = useRecoilState(selectedProgramName);
  const [notifications, setSolicitationNotification] = useRecoilState(
    solicitationNotifications,
  );

  const [typeaheadData, setTypeaheadData] = useState(null);

  // initial fetchin of filter options
  const [getFilterOptions] = useLazyQuery(GET_MULTIPLE_OPTIONS, {
    fetchPolicy: 'c',
    onCompleted: (data) => {
      setFilterOptions(data.getMultipleOptions);
    },
  });

  // fetching of type ahead options
  const [getSolicitationTypeAheadOptions] = useLazyQuery(
    GET_SOLICITATION_TYPE_AHEAD_OPTIONS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.getSolicitationTypeAheadOptions) {
          const { key, options } = data.getSolicitationTypeAheadOptions;
          // return unique options only.
          const uniqueOptions = Array.from(new Set(options));
          setTypeaheadData({
            field: key,
            values: uniqueOptions,
          });
        }
      },
    },
  );

  const handleFetchSolicitationTypeaheads = ({ variables: queryVariables }) => {
    const selectedKey = queryVariables?.field;
    const filterObj = queryVariables?.filters[0]?.conditions?.find(
      (filter) => filter.key === selectedKey,
    );

    getSolicitationTypeAheadOptions({
      variables: {
        key: selectedKey,
        search: filterObj.value,
        year,
      },
    });
  };

  useEffect(() => {
    const options = [
      {
        model: 'StandardsCodeModel',
        label: 'title',
        value: 'code',
        filter: {
          operator: 'EQ',
          key: 'category',
          value: 'Solicitation Type',
        },
        uniqueKey: 'solicitationType',
        includeValueInLabel: false,
      },
      {
        model: 'StandardsCodeModel',
        label: 'title',
        value: 'code',
        filter: {
          operator: 'EQ',
          key: 'category',
          value: 'Purchase Type',
        },
        uniqueKey: 'purchaseType',
        includeValueInLabel: false,
      },
      {
        model: 'StandardsCodeModel',
        label: 'title',
        value: 'code',
        filter: {
          operator: 'EQ',
          key: 'category',
          value: 'Program',
        },
        uniqueKey: 'program',
        includeValueInLabel: false,
      },
    ];
    getFilterOptions({
      variables: {
        options,
      },
    });
  }, []);

  const handleFiltersChange = (filters) => {
    const statusFilter = filters.find((filter) => filter.key === 'status');
    const yearFilter = filters.find((filter) => filter.key === 'year');
    const processFilter = filters.find((filter) => filter.key === 'process');
    const typeFilter = filters.find((filter) => filter.key === 'type');
    const programFilter = filters.find((filter) => filter.key === 'program');
    const titleFilter = filters.find((filter) => filter.key === 'title');
    const solicitationNumberFilter = filters.find(
      (filter) => filter.key === 'solicitationNumber',
    );

    setLocalStorageFilter(filters);

    if (yearFilter) {
      setSolicitationNotification([
        ...notifications.filter(
          (notification) => notification.id !== 'CONTRACT_YEAR_FILTER_ERROR',
        ),
      ]);
      setYear(yearFilter.value);
    } else {
      // setYear('');
      setSolicitationNotification([
        {
          id: 'CONTRACT_YEAR_FILTER_ERROR',
          message: 'Contract year is a required field.',
          type: 'error',
          closeable: true,
          showInModal: false,
        },
        ...notifications,
      ]);
    }
    if (processFilter) {
      setProcess(processFilter.value);
    } else {
      setProcess('');
    }
    if (statusFilter) {
      setStatus(statusFilter.value);
    } else {
      setStatus('');
    }
    if (typeFilter) {
      setPurchaseType(typeFilter.value);
    } else {
      setPurchaseType('');
    }
    if (titleFilter) {
      setTitle(titleFilter.value);
    } else {
      setTitle('');
    }
    if (solicitationNumberFilter) {
      setSolicitationNumber(solicitationNumberFilter.value);
    } else {
      setSolicitationNumber('');
    }
    if (programFilter) {
      setProgramName(programFilter.value);
    } else {
      setProgramName('');
    }
  };

  const getLocalStorageFilterValue = (key, def = '') =>
    localeStorateFilters?.find((f) => f.key === key)?.value || def;

  const [filterStructure, setFilterStructure] = useState(null);

  useEffect(() => {
    setFilterStructure([
      {
        key: 'solicitationNumber',
        title: 'Solicitation uPIID',
        type: 'typeahead',
        value: getLocalStorageFilterValue('solicitationNumber'),
        operator: '$startswith',
        expanded: true,
        hideClear: true,
      },
      {
        key: 'title',
        title: 'Solicitation title',
        type: 'typeahead',
        value: getLocalStorageFilterValue('title'),
        operator: '$in',
        expanded: true,
        hideClear: true,
      },
      {
        key: 'process',
        title: 'Solicitation process',
        type: 'select',
        options: [{ label: '', value: '' }].concat(
          filterOptions
            ?.filter((item) => item.uniqueKey === 'solicitationType')
            ?.map((option) => ({ label: option.label, value: option.label })),
        ),
        value: getLocalStorageFilterValue('process'),
        operator: '$in',
        expanded: true,
        hideClear: true,
      },
      {
        key: 'year',
        title: 'Contract year',
        type: 'select',
        options: [{ label: '', value: '' }].concat(
          filterOptions
            ?.filter((item) => item.uniqueKey === 'year')
            ?.map((option) => ({ label: option.label, value: option.value })),
        ),

        value: getLocalStorageFilterValue(
          'year',
          new Date().getFullYear().toString(),
        ),
        operator: '$exact',
        expanded: true,
        hideClear: true,
      },
      {
        key: 'status',
        title: 'Status',
        type: 'select',
        options: STATUS_OPTIONS,
        value: getLocalStorageFilterValue('status'),
        operator: '$in',
        expanded: true,
        hideClear: true,
      },
      {
        key: 'type',
        title: 'Purchase type',
        type: 'select',
        options: [{ label: '', value: '' }].concat(
          filterOptions
            ?.filter((item) => item.uniqueKey === 'purchaseType')
            ?.map((option) => ({ label: option.label, value: option.value })),
        ),
        value: getLocalStorageFilterValue('type'),
        operator: '$in',
        expanded: true,
        hideClear: true,
      },
      {
        key: 'program',
        title: 'Program name',
        type: 'select',
        options: [{ label: '', value: '' }].concat(
          filterOptions
            ?.filter((item) => item.uniqueKey === 'program')
            ?.map((option) => ({ label: option.label, value: option.value })),
        ),
        value: getLocalStorageFilterValue('program'),
        operator: '$in',
        expanded: true,
        hideClear: true,
      },
    ]);
  }, [filterOptions]);

  // a workaround to fix local storage state issue
  // FilterPanel considers passed filterstructure as a default and resets to it when user clicks "Clear All"
  // in reality we need the default state to be always one filter selected with the current year selected
  const [rerenderKey, setRerenderKey] = useState(1);

  if (!filterStructure) {
    return null;
  }

  return (
    <ComponentFilterPanel
      key={rerenderKey}
      clearButtonLabel="Reset All"
      handleClearAll={() => {
        const n = [
          ...filterStructure.map((v) =>
            v.key === 'year'
              ? { ...v, value: new Date().getFullYear().toString() }
              : { ...v, value: '' },
          ),
        ];
        setFilterStructure(n);
        setRerenderKey(rerenderKey + 1);
      }}
      filterStructure={filterStructure}
      model="solicitation"
      order="solicitationNumber ASC"
      setQueryFiltersState={handleFiltersChange}
      fetchTypeaheads={handleFetchSolicitationTypeaheads}
      typeaheadData={typeaheadData}
      showClearIcon
      retainFilters={false}
    />
  );
};

export default SolicitationFilters;
