/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, gql } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { FormGroup, Textbox, ErrorMessage } from '@gsa/afp-component-library';
import GSAExternalPathError from './gsa-external-path-error';
import { useFederalStandards } from '../fvs-provider';
import StandardItemTitle from './standard-item-title';

const Regex = {
  // This probably does not match some new TLD
  email: /^[a-z0-9!#$%&'+/=?^{|}~_-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[a-z]{2}|aero|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel)$/i,
};

const validationOptions = {
  email: {
    required: 'Please provide your email',
    pattern: {
      value: Regex.email,
      message: 'Email is not valid',
    },
  },
  confirmEmail: {
    required: 'Please re-type your email',
    pattern: {
      value: Regex.email,
      message: 'Email is not valid',
    },
  },
};
const CommentModalEmailForm = ({
  linkedEntityType,
  fvsCode,
  contractYear,
  onEmailChange,
}) => {
  const { standardItem, vehicleStandard } = useFederalStandards();
  const url = useMemo(() => {
    if (linkedEntityType === 0) {
      return `/catalog/vehicle-standards/requirements/${contractYear}/${fvsCode}/${standardItem?.standardItemNumber}?showCommentTab=1`;
    }
    if (linkedEntityType === 2) {
      return `/catalog/vehicle-standards/requirements/${contractYear}/${fvsCode}/${standardItem?.standardItemNumber}`;
    }
    if (linkedEntityType === 3) {
      return `/catalog/vehicle-standards/preview/${contractYear}/${vehicleStandard.id}?showCommentTab=1`;
    }
    return `/catalog/vehicle-standards`;
  }, [
    contractYear,
    fvsCode,
    linkedEntityType,
    standardItem?.standardItemNumber,
    vehicleStandard?.id,
  ]);

  const {
    register,
    watch,
    formState: { errors },
  } = useForm({
    validateCriteriaMode: 'all',
    mode: 'all',
    shouldFocusError: true,
  });

  const email = watch('email', '');
  const confirmEmail = watch('confirmEmail', '');
  const [isExistingAccount, setIsExistingAccount] = useState(null);

  useEffect(() => {
    const isValid =
      !errors.email &&
      !errors.confirmEmail &&
      confirmEmail &&
      email === confirmEmail;
    onEmailChange(email, confirmEmail, isValid, isExistingAccount);
  }, [email, confirmEmail, isExistingAccount]);

  const hasErrors = (condition) => {
    return condition ? 'error' : '';
  };

  const [checkEmail] = useLazyQuery(
    gql`
      query checkEmail($email: String!) {
        checkEmail(email: $email)
      }
    `,
    {
      onCompleted: (data) => {
        setIsExistingAccount(!!data?.checkEmail);
      },
      onError: () => {
        setIsExistingAccount(false);
      },
    },
  );

  const checkEmailHandler = useCallback((evnt) => {
    checkEmail({
      variables: {
        email: evnt.target.value,
      },
    });
  });

  const entity = useMemo(() => {
    if (linkedEntityType === 0) {
      return standardItem;
    }
    if (linkedEntityType === 2) {
      return standardItem;
    }

    if (linkedEntityType === 3) {
      return vehicleStandard;
    }

    return undefined;
  }, [linkedEntityType, standardItem, vehicleStandard]);

  const title = useMemo(() => {
    if (linkedEntityType === 0) {
      return (
        <StandardItemTitle
          value={`${standardItem?.standardItemNumber} - ${standardItem?.title}`}
          entity={entity}
        />
      );
    }
    if (linkedEntityType === 2) {
      return (
        <StandardItemTitle
          value={`${standardItem?.standardItemNumber} - ${standardItem?.title}`}
          entity={entity}
        />
      );
    }

    if (linkedEntityType === 3) {
      return vehicleStandard?.title;
    }

    return null;
  }, [entity, linkedEntityType, standardItem, vehicleStandard]);

  return (
    <div className="desktop:grid-col-7">
      <h1 className="margin-top-4 margin-bottom-1 title-l">{title}</h1>

      <fieldset className="usa-fieldset">
        <FormGroup
          className="margin-left-0 margin-top-0"
          error={errors && errors.email}
        >
          <label className="usa-label text-bold" htmlFor="email">
            Email{' '}
            <abbr title="required" className="usa-label--required">
              *
            </abbr>
          </label>
          {errors && errors.email && errors.email.message && (
            <ErrorMessage id="error-email">
              {errors.email && errors.email.message}
            </ErrorMessage>
          )}

          <Textbox
            name="email"
            type="email"
            id="email"
            data-testid="email-testid"
            className="usa-input max-w-none"
            variant={hasErrors(errors.email)}
            aria-describedby="error-email"
            inputRef={register(validationOptions.email)}
            onBlur={checkEmailHandler}
            required
          />
        </FormGroup>
        {isExistingAccount && <GSAExternalPathError url={url} />}

        {!isExistingAccount && (
          <>
            <FormGroup
              className="margin-left-0"
              error={errors && errors.confirmEmail}
            >
              <label className="usa-label text-bold" htmlFor="confirm-email">
                Re-type email{' '}
                <abbr title="required" className="usa-label--required">
                  *
                </abbr>
              </label>
              {errors && errors.confirmEmail && errors.confirmEmail.message && (
                <ErrorMessage id="error-confirm-email">
                  {errors?.confirmEmail.message}
                </ErrorMessage>
              )}

              <Textbox
                name="confirmEmail"
                type="email"
                id="confirm-email"
                data-testid="confirm-email-testid"
                className="usa-input max-w-none"
                variant={hasErrors(errors.confirmEmail)}
                aria-describedby="error-confirm-email"
                inputRef={register({
                  ...validationOptions.confirmEmail,
                  validate: (value) => {
                    return (
                      value === email ||
                      'Your email address does not match. Please try again.'
                    );
                  },
                })}
                required
              />
            </FormGroup>
            <p>
              Upon submission, please check your email to finalize your posted
              comment.
            </p>
          </>
        )}
      </fieldset>
    </div>
  );
};

CommentModalEmailForm.propTypes = {
  onEmailChange: PropTypes.func.isRequired,
};

export default CommentModalEmailForm;
