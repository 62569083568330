/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FilterTableFrame,
  AFPTable,
  EmptyState,
  AFPTableRowAction,
  Pagination,
  StatusBadge,
} from '@gsa/afp-component-library';
import TableFilters from './make-model-table-filters';
import { ModelMakeStatuses } from '../constants';
import ModelApproveRejectModal from './model-approve-reject-modal';

const MakeModelTable = ({
  tableProps,
  paginationState,
  setPaginationState,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMakeModel, setSelectedMakeModel] = useState({});
  const [action, setAction] = useState(null);

  const handleSelectedAction = (evt, row) => {
    setSelectedMakeModel(row.original);
    setAction(evt);
    setIsOpen(true);
  };

  const closeModelApprovalModel = () => {
    setIsOpen(false);
  };

  const getMMCombinationFleetStatus = (MakeFleetStatus, ModelFleetStatus) => {
    const combination = `${MakeFleetStatus}-${ModelFleetStatus}`;

    const statusMap = new Map([
      ['Approved-Approved', { badge: 'Ready-Gray', status: 'Approved' }],
      ['Rejected-Approved', { badge: 'Urgent-Gray', status: 'Rejected' }],
      ['Approved-Rejected', { badge: 'Urgent-Gray', status: 'Rejected' }],
      ['Rejected-PendingReview', { badge: 'Urgent-Gray', status: 'Rejected' }],
      ['PendingReview-Rejected', { badge: 'Urgent-Gray', status: 'Rejected' }],
      ['Rejected-Rejected', { badge: 'Urgent-Gray', status: 'Rejected' }],
      [
        'Approved-PendingReview',
        { badge: 'Warning-Gray', status: 'Pending review' },
      ],
      [
        'PendingReview-Approved',
        { badge: 'Warning-Gray', status: 'Pending review' },
      ],
      [
        'PendingReview-PendingReview',
        { badge: 'Warning-Gray', status: 'Pending review' },
      ],
      ['Approved-Archived', { badge: 'Inactive-Gray', status: 'Archived' }],
      ['Archived-Approved', { badge: 'Inactive-Gray', status: 'Archived' }],
      ['Archived-Archived', { badge: 'Inactive-Gray', status: 'Archived' }],
    ]);

    return (
      statusMap.get(combination) || {
        badge: 'Warning-Gray',
        status: 'Pending review',
      }
    );
  };

  const useColumns = () => {
    const columns = useMemo(
      () => [
        {
          Header: 'Make',
          accessor: 'make.makeName',
        },
        {
          Header: 'Model',
          accessor: 'modelName',
        },
        {
          Header: 'Status',
          accessor: 'fleetStatus',
          sortable: false,
          // eslint-disable-next-line react/prop-types
          Cell: ({ row }) => {
            const {
              make: { fleetStatus: makeFleetStatus },
              fleetStatus: modelFleetStatus,
            } = row.original;
            const variant = getMMCombinationFleetStatus(
              makeFleetStatus,
              modelFleetStatus,
            ).badge;
            const value = getMMCombinationFleetStatus(
              makeFleetStatus,
              modelFleetStatus,
            ).status;

            return (
              variant && <StatusBadge variant={variant}>{value}</StatusBadge>
            );
          },
        },
        {
          Header: 'Approved by',
          accessor: 'approvedByUser',
          sortable: false,
          Cell: ({ row }) => {
            const { approvalStatus: nhtsaStatus, fleetStatus } = row.original;
            if (
              nhtsaStatus === ModelMakeStatuses.Approved.value &&
              fleetStatus === ModelMakeStatuses.Approved.value
            ) {
              return 'NHTSA';
            }
            if (
              fleetStatus === ModelMakeStatuses.Approved.value ||
              fleetStatus === ModelMakeStatuses.Rejected.value
            ) {
              return 'GSA Engineer';
            }

            return '-';
          },
        },
        {
          Header: 'Actions',
          sortable: false,
          minWidth: 30,
          width: 150,
          maxWidth: 400,
          Cell: (props) => (
            <AFPTableRowAction
              {...props}
              actions={[
                {
                  icon: 'check',
                  label: 'Approve',
                },
                {
                  icon: 'close',
                  label: 'Reject',
                },
              ]}
              onSelectAction={(evt) => handleSelectedAction(evt, props.row)}
            />
          ),
        },
      ],
      [],
    );
    return columns;
  };

  const columns = useColumns();
  const isNoData = !tableProps?.data?.length;

  return (
    <>
      <AFPTable data={tableProps?.data} columns={columns} {...tableProps} />
      <ModelApproveRejectModal
        isOpen={isOpen}
        data={selectedMakeModel}
        action={action}
        onClose={closeModelApprovalModel}
        refetchData={tableProps?.refetchMakeModel}
      />
      {tableProps?.data.length > 0 && (
        <Pagination
          itemsPerPageOptions={[50, 100, 150, 200]}
          variant="advanced"
          onPageChange={(currentPage, itemsPerPage) => {
            const newState = {
              ...paginationState,
              currentPage,
              offset: (currentPage - 1) * itemsPerPage,
              limit: itemsPerPage,
            };
            setPaginationState(newState);
          }}
          itemsPerPage={paginationState.limit}
          currentPage={paginationState.currentPage}
          itemsCount={tableProps.count}
        />
      )}
      {isNoData && (
        <div className="text-center margin-top-neg-2 height-full">
          <EmptyState
            hasBackground
            containerStyles="padding-y-6 height-full"
            topText={<p>No data is available.</p>}
          />
        </div>
      )}
    </>
  );
};

MakeModelTable.propTypes = {
  tableProps: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    count: PropTypes.number,
  }).isRequired,
  paginationState: PropTypes.shape({
    currentPage: PropTypes.number,
    offset: PropTypes.number,
    limit: PropTypes.number,
  }).isRequired,
  setPaginationState: PropTypes.func.isRequired,
};

export default FilterTableFrame(null, null, TableFilters, MakeModelTable);
