import PropTypes from 'prop-types';
import React, { createContext, useContext, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_BID_FOR_EVALUATION,
  AUTO_SAVE_EVALUATION,
  AWARD_EVALUATIONS,
} from './bid-line-evaluation-gql';
import {
  EVAL_FILTER_OPTIONS,
  EVAL_FILTERS,
  processBid,
  getFilteredRows,
  getTableData,
  prepAutoSaveFields,
  getEvalStatus,
} from './bid-line-evaluation-helpers';

const BidLineEvaluationContext = createContext({});

const BidLineEvaluationProvider = ({ children, ...props }) => {
  const { bidId } = useParams();

  const [alert, setAlert] = useState(null);
  const [bidInfo, setBidInfo] = useState({});
  const [filterOptions, setFilterOptions] = useState(EVAL_FILTER_OPTIONS);
  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageReset, setPageReset] = useState(false);

  const [lineData, setLineData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState(EVAL_FILTERS);
  const [awardSelection, setAwardSelection] = useState([]);
  const [reassignRow, setReassignRow] = useState(null);
  const [evaluable, setEvaluable] = useState(null);

  const { loading: loadingBid } = useQuery(GET_BID_FOR_EVALUATION, {
    fetchPolicy: 'network-only',
    variables: { bidId: parseInt(bidId, 10) },
    skip: !bidId,
    onCompleted: ({ getBidForEvaluation }) => {
      if (getBidForEvaluation) {
        const { info, lines, rows, options, evaluable: ev } = processBid(
          getBidForEvaluation,
        );
        setBidInfo(info);
        setEvaluable(ev);
        setLineData(lines);
        setRowData(rows);
        setFilterOptions(options);
        setCount(lines.length);
        const filteredRows = getFilteredRows(lines, rows, filters);
        setTableData(getTableData(filteredRows, offset, limit));
      } else {
        setEvaluable(false);
      }
    },
  });

  const [autoSave, { loading: autoSaving }] = useMutation(AUTO_SAVE_EVALUATION);
  const [award, { loading: awarding }] = useMutation(AWARD_EVALUATIONS);

  const resetPagination = () => {
    setPageReset(true);
    setTimeout(() => setPageReset(false), 100);
  };

  const removeTableRows = (bidLineIds, prevLines) => {
    const newCount = count - bidLineIds.length;
    let newOffset = offset;
    if (newCount <= offset && offset > 0) {
      newOffset = offset - limit;
      setOffset(newOffset);
      resetPagination();
      setAwardSelection([]); // reset award selection
    }
    const filteredRows = getFilteredRows(prevLines, rowData, filters).filter(
      (row) => !bidLineIds.includes(row.bidLineId),
    );
    setTableData(getTableData(filteredRows, newOffset, limit));
    setCount(newCount);
  };

  const saveEvaluation = (line, update) => {
    const idObj = line.evalId
      ? { id: line.evalId }
      : { bidLineId: line.bidLineId };
    autoSave({
      variables: { input: { ...idObj, ...prepAutoSaveFields(update) } },
    })
      .then(async ({ data }) => {
        setLineData((prevItems) =>
          prevItems.map((item) => {
            if (item.bidLineId !== line.bidLineId) return item;
            const newLine = {
              ...item,
              ...update,
              evalId: data.autoSaveBidLineEvaluation,
            };
            const status = getEvalStatus(newLine);
            if (filters.status && filters.status !== status)
              removeTableRows([line.bidLineId], prevItems);
            if (
              awardSelection.includes(line.evalId) &&
              status !== 'Recommended'
            ) {
              setAwardSelection((prev) =>
                prev.filter((id) => id !== line.evalId),
              );
            }
            return { ...newLine, status };
          }),
        );
      })
      .catch(() => null);
  };

  const awardEvaluations = () => {
    if (!awardSelection.length) return;
    award({
      variables: { evaluationIds: awardSelection },
    })
      .then(async ({ data: { awardBidLineEvaluations: bidLineIds } }) => {
        setLineData((prevItems) => {
          if (filters.status === 'Recommended')
            removeTableRows(bidLineIds, prevItems);
          return prevItems.map((item) => {
            if (!awardSelection.includes(item.evalId)) return item;
            const newLine = { ...item, awarded: true };
            const status = getEvalStatus(newLine);
            return { ...newLine, status };
          });
        });
        setAwardSelection([]);

        const lines = bidLineIds
          .map((blid) => rowData.find((r) => r.bidLineId === blid).scheduleLine)
          .sort();
        setAlert({
          type: 'success',
          message: `Line item${lines.length > 1 ? 's' : ''} ${lines.join(
            ', ',
          )} ${lines.length > 1 ? 'have' : 'has'} been marked as awarded.`,
        });
      })
      .catch(() => null);
  };

  const removeReassignedLine = (bidLineId) => {
    setLineData((prevItems) => {
      removeTableRows([bidLineId], prevItems);
      return prevItems.filter((item) => item.bidLineId !== bidLineId);
    });
    setRowData((prevItems) =>
      prevItems.filter((item) => item.bidLineId !== bidLineId),
    );
  };

  const onPageChange = (currentPage, itemsPerPage) => {
    let newOffset;
    if (itemsPerPage === limit) {
      newOffset = (currentPage - 1) * itemsPerPage;
    } else {
      newOffset = 0; // reset to page 1
      resetPagination();
      setLimit(itemsPerPage);
    }
    setOffset(newOffset);
    setAwardSelection([]); // reset award selection
    const filteredRows = getFilteredRows(lineData, rowData, filters);
    setTableData(getTableData(filteredRows, newOffset, itemsPerPage));
  };

  const updateFilters = (newFilters) => {
    const filteredRows = getFilteredRows(lineData, rowData, newFilters);
    setCount(filteredRows.length);
    setOffset(0);
    resetPagination();
    setTableData(getTableData(filteredRows, 0, limit));
    setFilters(newFilters);
    setAwardSelection([]); // reset award selection
  };

  const onFilterChange = (key, val) => {
    updateFilters({ ...filters, [key]: val });
  };

  const onResetFilters = () => {
    updateFilters(EVAL_FILTERS);
  };

  const contextValue = useMemo(() => ({
    loadingBid,
    bidInfo,
    evaluable,
    filterOptions,
    count,
    offset,
    limit,
    pageReset,
    lineData,
    rowData,
    tableData,
    filters,
    awardSelection,
    setAwardSelection,
    reassignRow,
    setReassignRow,
    removeReassignedLine,
    onPageChange,
    onFilterChange,
    onResetFilters,
    autoSaving,
    saveEvaluation,
    awarding,
    awardEvaluations,
    alert,
    setAlert,
    ...props,
  }));

  return (
    <BidLineEvaluationContext.Provider value={contextValue}>
      {children}
    </BidLineEvaluationContext.Provider>
  );
};

BidLineEvaluationProvider.propTypes = {
  children: PropTypes.node.isRequired,
  blId: PropTypes.number,
};

BidLineEvaluationProvider.defaultProps = {
  blId: null,
};

export default BidLineEvaluationProvider;

export const useBidLineEvaluation = () => useContext(BidLineEvaluationContext);
