import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  AFPTable,
  EmptyState,
  Spinner,
  Alert,
} from '@gsa/afp-component-library';
import ReopenPeriodsRowActions, {
  EDIT_ACTION,
  DELETE_ACTION,
} from './reopen-periods-row-actions';
import SolicitationPeriodStatusBadge from './solicitation-period-status';
import {
  getAudienceValues,
  getSolicitationPeriodStatus,
  hasFutureDeactiavedSolPeriods,
  isOpenSeasonAudienceType,
} from '../utils/solicitation-period-utils';
import { SOLICITATION_PERIOD_STATUS_FIELD_NAME } from '../constants';
import SolicitationPeriodsTableSubComponent from './solicitation-periods-table-sub-comp';
import { createConditionalExpandableColumn } from '../../../utilities/table-utils';

const FUTURE_SOLICITATION_PERIODS_DEACTIVATED_MSG =
  'Some of the future periods have been deactivated as a contract has been awarded to this solicitation.';
const ReopenPeriodsTable = ({
  data,
  loading,
  handleRowActionEdit,
  handleRowActionDelete,
  contractAwardDate,
}) => {
  const [order] = useState('startDate ASC');
  const PERIOD_FIELD_NAME = 'period';
  const onMenuSelectMap = {
    [EDIT_ACTION.label]: handleRowActionEdit,
    [DELETE_ACTION.label]: handleRowActionDelete,
  };
  const getTransformData = (dataArr) => {
    let reopenCount = 0;
    let midCycleCount = 0;
    return dataArr?.map?.((eachPeriodObj, index) => {
      return {
        ...eachPeriodObj,
        [PERIOD_FIELD_NAME]: `${
          eachPeriodObj.periodType === 'R' ? 'Reopen' : 'Mid-cycle'
        } period ${
          // eslint-disable-next-line no-plusplus
          eachPeriodObj.periodType === 'R' ? ++reopenCount : ++midCycleCount
        }`,
        onMenuSelectMap,
        [SOLICITATION_PERIOD_STATUS_FIELD_NAME]: getSolicitationPeriodStatus(
          eachPeriodObj,
          contractAwardDate,
        ),
        index,
      };
    });
  };

  const transformedData = getTransformData(data);
  const showFutureDeactivatedSolPeriodAlert = hasFutureDeactiavedSolPeriods(
    transformedData,
  );

  const columns = [
    createConditionalExpandableColumn(isOpenSeasonAudienceType),
    {
      Header: 'Period',
      accessor: PERIOD_FIELD_NAME,
      sortable: true,
    },
    {
      Header: 'Posting date',
      accessor: 'startDate',
      Cell: ({ value }) =>
        `${moment(value).format('MM/DD/YYYY')}  ${moment(value).format(
          'hh:mm A',
        )}`,
      sortable: true,
    },
    {
      Header: 'Closing date',
      accessor: 'endDate',
      Cell: ({ value }) =>
        `${moment(value).format('MM/DD/YYYY')}  ${moment(value).format(
          'hh:mm A',
        )}`,
      sortable: true,
    },
    {
      Header: 'Audience',
      accessor: 'audience',
      sortable: false,
      Cell: ({
        // eslint-disable-next-line react/prop-types
        row: { original },
      }) => getAudienceValues(original),
    },
    {
      Header: 'Status',
      Cell: SolicitationPeriodStatusBadge,
      sortable: false,
    },
    {
      Header: 'Actions',
      Cell: ReopenPeriodsRowActions,
      sortable: false,
    },
  ];

  return (
    <>
      {showFutureDeactivatedSolPeriodAlert && (
        <Alert type="info" slim className="margin-bottom-0 margin-top-10">
          {FUTURE_SOLICITATION_PERIODS_DEACTIVATED_MSG}
        </Alert>
      )}
      <AFPTable
        columns={columns}
        data={transformedData || []}
        defaultSort={order}
        renderRowSubComponent={SolicitationPeriodsTableSubComponent}
      />
      {loading && <Spinner data-testid="spinner" size="medium" />}
      {data?.length === 0 && !loading && (
        <EmptyState
          hasBackground
          containerStyles="padding-y-5 margin-top-1 adding-y-8 margin-top-neg-2 margin-bottom-4"
          topText={<p data-testid="empty-state">Add periods to the table</p>}
        />
      )}
    </>
  );
};

const SolicitationPeriodShape = PropTypes.shape({
  id: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  audience: PropTypes.string,
});
ReopenPeriodsTable.propTypes = {
  data: PropTypes.arrayOf(SolicitationPeriodShape),
  loading: PropTypes.bool,
  openPeriod: SolicitationPeriodShape,
  handleRowActionEdit: PropTypes.func,
  handleRowActionDelete: PropTypes.func,
  contractAwardDate: PropTypes.string,
};

ReopenPeriodsTable.defaultProps = {
  data: [],
  loading: false,
  openPeriod: null,
  handleRowActionEdit: () => {},
  handleRowActionDelete: () => {},
  contractAwardDate: '',
};

export default ReopenPeriodsTable;
