/* eslint-disable react/prop-types */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Button } from '@gsa/afp-component-library';

const GSAExternalPathError = ({ url }) => {
  const history = useHistory();
  const handleLoginExternal = async (e) => {
    e.preventDefault();
    history.push(url);
  };

  return (
    <Alert type="warning" id="gsa-login-requirement-error" slim>
      We noticed that you have a registered GSAFleet.gov account associated with
      this email.
      <br />
      <br />
      As a registered user, you should be logged in to participate in the
      Federal Vehicle standards commenting period. Please login here and enter
      your comments.
      <br />
      <Button
        label="Login"
        variant="primary"
        className="margin-top-1 margin-bottom-1"
        id="gsa-employee-login"
        onClick={handleLoginExternal}
        data-testid="gsa-employee-login"
      />
    </Alert>
  );
};

export default GSAExternalPathError;
