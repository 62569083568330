import React from 'react';
import { Alert, Icon } from '@gsa/afp-component-library';
import ReactHtmlParser from 'react-html-parser';
import { useAttachments } from './AttachmentProvider';

const AttachmentMessages = () => {
  const { errorMessage, successMessage, setData } = useAttachments();

  return (
    <>
      {errorMessage && (
        <Alert
          slim
          noIcon
          actions={
            <div className="position-relative">
              <div className="position-static">
                <div className="position-absolute bottom-0 right-0">
                  <Icon
                    data-testid="error-close"
                    iconName="close"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setData('SET_ERROR_MESSAGE', '');
                    }}
                    className="margin-x-1 text-large text-heavy font-sans-xl text-gray-500"
                  />
                </div>
              </div>
            </div>
          }
          type="error"
        >
          <div>{errorMessage ? ReactHtmlParser(errorMessage) : ''}</div>
        </Alert>
      )}

      {successMessage && (
        <Alert
          slim
          actions={
            <div className="position-relative">
              <div className="position-static">
                <div className="position-absolute bottom-0 right-0">
                  <Icon
                    data-testid="success-close"
                    iconName="close"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setData('SET_SUCCESS_MESSAGE', '');
                    }}
                    className="margin-x-1 text-large text-heavy font-sans-xl text-gray-500"
                  />
                </div>
              </div>
            </div>
          }
          type="success"
        >
          <div>{successMessage ? ReactHtmlParser(successMessage) : ''}</div>
        </Alert>
      )}
    </>
  );
};

export default AttachmentMessages;
