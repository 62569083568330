export const INACTIVE_SI_STATUS = 'Inactive';
const INACTIVE_SI_COUNT_PLACEHOLDER = '<x>';
export const INACTIVE_SI_LINK_TEMPLATE = `${INACTIVE_SI_COUNT_PLACEHOLDER} Standard Items`;
const INACTIVE_SI_ALERT_MSG_TEMPLATE = `${INACTIVE_SI_COUNT_PLACEHOLDER} Standard Items that you previously selected are now inactive and unavailable for selection under the contract.`;

export const getInactiveSIArray = (programs) =>
  (programs || [])?.reduce?.(
    (reducedArr, eachProgram) => [
      ...reducedArr,
      ...(eachProgram?.solicitationLines?.filter(
        (eachSI) => eachSI?.standardItem?.status === INACTIVE_SI_STATUS,
      ) || []),
    ],
    [],
  );
export const updateTextTemplate = (template = '') => (placeholder = '') => (
  textToAdd = '',
) => template?.replace?.(placeholder, textToAdd) || '';

export const getInactiveAlertMsg = updateTextTemplate(
  INACTIVE_SI_ALERT_MSG_TEMPLATE,
)(INACTIVE_SI_COUNT_PLACEHOLDER);

export const getInactiveLinkText = updateTextTemplate(
  INACTIVE_SI_LINK_TEMPLATE,
)(INACTIVE_SI_COUNT_PLACEHOLDER);
