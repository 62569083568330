import React from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute, NotFound, PublicRoute } from '@gsa/afp-shared-ui-utils';
import VehicleStandardsPage from './landing-page';
import DocPreview from './doc-preview';
import FvsProvider from './fvs-provider';
import FvsSupportingDocsProvider from './fvs-supporting-docs-provider';
import CommentingPeriods from './commenting-periods';
import Requirements from './requirements';
import FvsPreBidProvider from './fvs-pre-bid-selection-provider';
import PreBidSelectionsPage from './pre-bid-selection';
import FvsCommentsProvider from './fvs-comments-provider';
import PreBidSummaryPage from './pre-bid-summary';
import PreBidReportPage from './pre-bid-report';
import { OPERATIONS, SUBJECTS } from '../../utilities/constants';

const FedVehicleStandards = () => {
  return (
    <FvsProvider>
      <FvsSupportingDocsProvider>
        <FvsPreBidProvider>
          <FvsCommentsProvider>
            <Switch>
              <PrivateRoute
                exact
                path="/catalog/vehicle-standards"
                component={VehicleStandardsPage}
                title="Federal Vehicle Standards"
              />
              <PrivateRoute
                exact
                path="/catalog/vehicle-standards/pre-bid-selections/:year"
                component={PreBidSelectionsPage}
                title="Pre-bid Selections"
              />
              <PrivateRoute
                exact
                path="/catalog/vehicle-standards/pre-bid-report/:year"
                component={PreBidReportPage}
              />
              <PrivateRoute
                exact
                path="/catalog/vehicle-standards/requirements/:year/:fedCode/:standardItemNumber"
                component={Requirements}
                title="Vehicle Requirements"
              />
              <PrivateRoute
                exact
                path="/catalog/vehicle-standards/preview/:year/:fvsId/:docId?"
                component={DocPreview}
                title="Vehicle Standards Documentation"
              />
              <PrivateRoute
                exact
                path="/catalog/vehicle-standards/commenting-periods"
                component={CommentingPeriods}
                title="Commenting Periods"
              />
              <PrivateRoute
                exact
                path="/catalog/vehicle-standards/pre-bid-summary/:year"
                component={PreBidSummaryPage}
                title="Pre-bid Summary"
                operation={OPERATIONS.Create}
                subject={SUBJECTS.PSOQuestionnaire}
              />
              <PrivateRoute
                exact
                path="/catalog/vehicle-standards/:year/:fedCode"
                component={VehicleStandardsPage}
                title="Federal Vehicle Standards"
              />
              <PublicRoute
                path="*"
                component={NotFound}
                title="Sorry for the Detour"
              />
            </Switch>
          </FvsCommentsProvider>
        </FvsPreBidProvider>
      </FvsSupportingDocsProvider>
    </FvsProvider>
  );
};

export default FedVehicleStandards;
