// eslint-disable-next-line import/prefer-default-export
export const standardItemOptions = [
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: {
      operator: 'EQ',
      key: 'standardCodeMetadataId',
      value: '9',
    },
    uniqueKey: 'fedStandardCode',
    includeValueInLabel: true,
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: {
      operator: 'EQ',
      key: 'standardCodeMetadataId',
      value: '11',
    },
    uniqueKey: 'vehicleType',
    includeValueInLabel: true,
    parent: 'parentCode',
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: {
      operator: 'EQ',
      key: 'standardCodeMetadataId',
      value: '10',
    },
    uniqueKey: 'vehicleGroup',
    includeValueInLabel: true,
    parent: 'parentCode',
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: {
      operator: 'EQ',
      key: 'standardCodeMetadataId',
      value: '16',
    },
    uniqueKey: 'fastCodes',
    includeValueInLabel: false,
  },
  {
    model: 'StandardsCodeModel',
    label: 'title',
    value: 'code',
    filter: { operator: 'EQ', key: 'standardCodeMetadataId', value: '12' },
    uniqueKey: 'tags',
    includeValueInLabel: false,
  },
];
