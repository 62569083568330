/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import React, { useEffect } from 'react';
import { Icon } from '@gsa/afp-component-library';

export const TableExpanderCell = ({ row, onClick, isHidden, isReadyOnly }) => {
  const {
    toggleRowExpanded,
    original: { id, isExpanded },
  } = row;
  const iconName = isReadyOnly || isExpanded ? 'expand_more' : 'navigate_next';
  useEffect(() => {
    if (isReadyOnly && !isHidden) {
      toggleRowExpanded(true);
    } else {
      toggleRowExpanded(isExpanded);
    }
  }, [isReadyOnly, isHidden, toggleRowExpanded, isExpanded]);

  if (isHidden) return null;
  return (
    <button
      type="button"
      aria-expanded={isExpanded}
      data-testid={`row-expander-${id}`}
      disabled={isReadyOnly}
      title="Toggle Row Expanded"
      className="usa-button usa-button--unstyled usa-button--medium table__expand_button"
      style={{
        height: 32,
        width: 32,
        marginTop: 4,
      }}
      onClick={() => onClick(!isExpanded)}
    >
      <Icon iconName={iconName} size={3} />
    </button>
  );
};
