import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BID_CLOSE_MONITOR_SETTINGS } from '../constants';

function BidClosedDateChecker({ closedDate, solId, bidId, setAlert }) {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const isBidClosedDatePast = () => {
      const currentDate = new Date();
      const bidClosedDate = new Date(closedDate);

      // Set seconds and milliseconds to 0 for both dates
      // so that checks will be accurate based on minutes
      currentDate.setSeconds(0, 0);
      bidClosedDate.setSeconds(0, 0);

      // Calculate time difference in minutes
      const timeDiff = Math.floor((bidClosedDate - currentDate) / (1000 * 60));

      if (timeDiff <= 0) {
        window.location.href = `/catalog/solicitations/${solId}/bid-dashboard/${bidId}`;
      } else if (
        timeDiff <= BID_CLOSE_MONITOR_SETTINGS.ALERT_THRESHOLD_MINUTES &&
        !showAlert
      ) {
        setShowAlert(true);
        setAlert({
          type: 'warning',
          message: `This bid will close in less than ${BID_CLOSE_MONITOR_SETTINGS.ALERT_THRESHOLD_MINUTES} minutes.`,
        });
      }
    };

    isBidClosedDatePast();

    const interval = setInterval(
      isBidClosedDatePast,
      BID_CLOSE_MONITOR_SETTINGS.CHECK_INTERVAL_MS,
    ); // Check every 10 seconds

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [closedDate]);

  return null;
}

BidClosedDateChecker.propTypes = {
  closedDate: PropTypes.instanceOf(Date).isRequired,
  solId: PropTypes.number.isRequired,
  bidId: PropTypes.number.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default BidClosedDateChecker;
