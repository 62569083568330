import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  Button,
  ButtonDropdown,
  ButtonDropdownItem,
  SelectDropdown,
  MultiSelectDropdown,
  Checkbox,
} from '@gsa/afp-component-library';
import OverlaySpinner from '../../../../components/overlay-spinner';
import { GET_REPORT_OPTIONS, GET_SOLICITATIONS } from '../../bids.gql';
import { GET_STANDARD_ITEMS_BY_YEARS } from '../../../../services/data-store';
import { FT_PDF, REPORT_CONFIG } from './bid-report-types';
import {
  DEFAULT_OPTION,
  processSiOptions,
  getStandardItemOptions,
  getVendorOptions,
  getMakeModelOptions,
  getSinOptions,
} from './bid-report-helper';

const SolicitationSelectionForm = ({
  canViewAll,
  formValues,
  setFormValues,
  onPrev,
  onSubmit,
}) => {
  const reportConfig = REPORT_CONFIG[formValues.report.value];
  const [options, setOptions] = useState({});
  const [solOptions, setSolOptions] = useState([DEFAULT_OPTION]);

  // Execute the query to fetch the solicitations
  const { loading: solicitationLoading } = useQuery(GET_SOLICITATIONS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !formValues.contractYear.value,
    variables: {
      filters: {
        operator: '$and',
        conditions: [
          {
            operator: '$exact',
            key: 'contractYear',
            value: formValues.contractYear.value,
          },
        ],
      },
      limit: 9999,
      order: [['solicitationNumber', 'ASC']],
    },
    onCompleted: ({ getSolicitations: { rows } }) => {
      setSolOptions([
        DEFAULT_OPTION,
        ...rows.map((r) => ({ value: r.id, label: r.solicitationNumber })),
      ]);
    },
    onError: () => {},
  });

  const { loading: optionsLoading } = useQuery(GET_REPORT_OPTIONS, {
    fetchPolicy: 'cache-and-network',
    skip: !formValues.solicitation.value,
    variables: {
      solicitationId: +formValues.solicitation.value,
      includeMakeModel: reportConfig.showMakeModels,
    },
    onCompleted: ({ getReportOptionsBySolId }) => {
      setOptions(getReportOptionsBySolId);
    },
    onError: () => setOptions({}),
  });

  const { loading: siLoading } = useQuery(GET_STANDARD_ITEMS_BY_YEARS, {
    fetchPolicy: 'cache-and-network',
    skip: formValues.contractYear.value,
    variables: {
      filters: {
        operator: 'IN',
        key: 'year',
        value: formValues.contractYears.value,
      },
    },
    onCompleted: ({ getStandardItems }) => {
      const allOpts = processSiOptions(getStandardItems?.rows || []);
      setOptions(allOpts);
    },
    onError: () => {
      setOptions({ solicitations: [] });
    },
  });

  const onSolicitationChange = ({ target: { value } }) => {
    setFormValues({
      ...formValues,
      solicitation: {
        value,
        errorMessage: value ? '' : 'Solicitation is required',
      },
      vehicleSuppliers: { value: [] },
      standardItems: { value: [] },
      vehicleSupplier: { value: '' },
      standardItem: { value: '' },
      lineItems: { value: [] },
    });
  };

  const onVehicleSuppliersChange = (value) => {
    setFormValues({
      ...formValues,
      vehicleSuppliers: { value },
      standardItems: { value: [] },
      lineItems: { value: [] },
    });
  };

  const onStandardItemsChange = (value) => {
    setFormValues({
      ...formValues,
      standardItems: { value },
      lineItems: { value: [] },
    });
  };

  // Bid periods comparison report
  const onVehicleSupplierChange = ({ target: { value } }) => {
    setFormValues({
      ...formValues,
      vehicleSupplier: {
        value,
        errorMessage: value ? '' : 'Vehicle supplier is required',
      },
      standardItem: { value: '' },
      lineItems: { value: [] },
    });
  };
  const onStandardItemChange = ({ target: { value } }) => {
    setFormValues({
      ...formValues,
      standardItem: {
        value,
        errorMessage: value ? '' : 'Standard Item is required',
      },
      lineItems: { value: [] },
    });
  };
  const onLineItemsChange = (value) => {
    setFormValues({ ...formValues, lineItems: { value } });
  };

  // Minimum and optional comparison report
  const onSinChange = ({ target: { value } }) => {
    setFormValues({ ...formValues, sin: { value } });
  };

  const onEmailReportChange = ({ target: { checked } }) => {
    setFormValues({ ...formValues, isEmailReport: { value: checked } });
  };

  const onClickSubmit = (fileType) => {
    let hasError = false;
    const newFormValues = {};
    if (!reportConfig.hideSolicitation && !formValues.solicitation.value) {
      newFormValues.solicitation = {
        value: '',
        errorMessage: 'Solicitaion is required',
      };
      hasError = true;
    }
    if (
      canViewAll &&
      reportConfig.vehicleSupplierSS &&
      !formValues.vehicleSupplier.value
    ) {
      newFormValues.vehicleSupplier = {
        value: '',
        errorMessage: 'Vehicle supplier is required',
      };
      hasError = true;
    }
    if (reportConfig.standardItemSS && !formValues.standardItem.value) {
      newFormValues.standardItem = {
        value: '',
        errorMessage: 'Standard Item is required',
      };
      hasError = true;
    }
    if (reportConfig.sinSS && !formValues.sin.value) {
      newFormValues.sin = {
        value: '',
        errorMessage: 'Standard Item is required',
      };
      hasError = true;
    }

    if (hasError) setFormValues({ ...formValues, ...newFormValues });
    else onSubmit(fileType);
  };

  return (
    <div className="grid-col-6 margin-top-4">
      <div className="text-bold margin-bottom-1">{reportConfig.label}</div>
      <div className="margin-bottom-4">{reportConfig.description}</div>
      {(solicitationLoading || optionsLoading || siLoading) && (
        <OverlaySpinner />
      )}
      {!reportConfig.hideSolicitation && (
        <SelectDropdown
          autoFocus
          label={
            <strong>
              Select the solicitation to be included in the report
            </strong>
          }
          name="select solicitation"
          data-testid="solicitation-select"
          required
          value={formValues.solicitation.value}
          errorMessage={formValues.solicitation.errorMessage}
          options={solOptions}
          onChange={onSolicitationChange}
        />
      )}
      {canViewAll &&
        !reportConfig.hideVehicleSupplier &&
        !reportConfig.vehicleSupplierSS && (
          <div data-testid="vehicle-suppliers-select">
            <label
              className="usa-label margin-bottom-1"
              htmlFor="select-vehicle-suppliers"
            >
              <strong>
                Select the vehicle suppliers to be included in the report
              </strong>
            </label>
            <MultiSelectDropdown
              name="select-vehicle-suppliers"
              data-testid="select-vehicle-suppliers"
              selectedValues={formValues.vehicleSuppliers.value}
              options={getVendorOptions(options)}
              noResults={() => 'No vehicle supplier found'}
              onChange={onVehicleSuppliersChange}
            />
          </div>
        )}
      {canViewAll &&
        !reportConfig.hideVehicleSupplier &&
        reportConfig.vehicleSupplierSS && (
          <SelectDropdown
            label={
              <strong>
                Select the vehicle supplier to be included in the report
              </strong>
            }
            name="select-vehicle-supplier"
            data-testid="select-vehicle-supplier"
            required
            value={formValues.vehicleSupplier.value}
            errorMessage={formValues.vehicleSupplier.errorMessage}
            options={getVendorOptions(options, true)}
            onChange={onVehicleSupplierChange}
          />
        )}
      {reportConfig.sinSS && (
        <SelectDropdown
          label={
            <strong>
              Select the Standard Item to be included in the report
            </strong>
          }
          name="select-sin"
          data-testid="select-sin"
          required
          value={formValues.sin.value}
          errorMessage={formValues.sin.errorMessage}
          options={getSinOptions(options)}
          onChange={onSinChange}
        />
      )}
      {reportConfig.standardItemSS && (
        <SelectDropdown
          label={
            <strong>
              Select the Standard Item to be included in the report
            </strong>
          }
          name="select-standard-item"
          data-testid="select-standard-item"
          required
          value={formValues.standardItem.value}
          errorMessage={formValues.standardItem.errorMessage}
          options={getStandardItemOptions(
            options,
            canViewAll ? [formValues.vehicleSupplier.value] : [],
            true,
          )}
          onChange={onStandardItemChange}
        />
      )}
      {!reportConfig.sinSS && !reportConfig.standardItemSS && (
        <div data-testid="select-standard-items">
          <label
            className="usa-label margin-bottom-1"
            htmlFor="select-standard-items"
          >
            <strong>
              Select the Standard Items to be included in the report
            </strong>
          </label>
          <MultiSelectDropdown
            id="select-standard-items"
            name="select-standard-items"
            selectedValues={formValues.standardItems.value}
            options={getStandardItemOptions(
              options,
              formValues.vehicleSuppliers.value,
            )}
            noResults={() => 'No Standard Items found'}
            onChange={onStandardItemsChange}
          />
        </div>
      )}
      {reportConfig.showMakeModels && (
        <div data-testid="select-line-items">
          <label
            className="usa-label margin-bottom-1"
            htmlFor="select-line-items"
          >
            <strong>
              Select the makes and models to be included in the report
            </strong>
          </label>
          <MultiSelectDropdown
            id="select-line-items"
            name="select-line-items"
            selectedValues={formValues.lineItems.value}
            options={getMakeModelOptions(
              options,
              formValues.vehicleSupplier.value,
              formValues.standardItem.value,
              !canViewAll, // is VS
            )}
            noResults={() => 'No line items found'}
            onChange={onLineItemsChange}
          />
        </div>
      )}

      {canViewAll && (
        <div className="margin-top-8">
          <Checkbox
            label="Email the report. Please use this option for long-running reports with large data sets. An email will be sent to you with a link to download the report."
            id="email-report-checkbox"
            data-testid="email-report-checkbox"
            name="email-report-checkbox"
            checked={formValues.isEmailReport.value}
            onChange={onEmailReportChange}
          />
        </div>
      )}
      <div className="grid-row margin-top-8">
        <div className="grid-col-auto padding-right-1">
          <Button
            label="Previous"
            variant="outline"
            type="button"
            data-testid="prev-button"
            onClick={onPrev}
            leftIcon={{ name: 'arrow_back' }}
          />
        </div>
        <div className="grid-col-auto">
          <ButtonDropdown
            label="Download Report"
            variant="primary"
            data-testId="download-reports-button"
          >
            {reportConfig.fileTypes.map((fileType) => (
              <ButtonDropdownItem
                key={fileType}
                label={fileType}
                iconName={fileType === FT_PDF ? 'pdf' : 'csv_icon'}
                onClick={() => onClickSubmit(fileType)}
              />
            ))}
          </ButtonDropdown>
        </div>
      </div>
    </div>
  );
};

SolicitationSelectionForm.defaultProps = { canViewAll: false };
SolicitationSelectionForm.propTypes = {
  canViewAll: PropTypes.bool,
  formValues: PropTypes.shape(Object).isRequired,
  setFormValues: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SolicitationSelectionForm;
