export const DEFAULT_OPTION = { value: '', label: '-Select-' };

export const processSiOptions = (sis) => {
  const sins = [];
  sis.forEach((si) => {
    const { standardItemNumber: sin, title } = si;
    if (sins.every((s) => s.id !== sin))
      sins.push({
        id: sin,
        option: { label: `${sin} - ${title}`, value: sin },
      });
  });
  return { sins };
};

export const getSortedOptions = (opts, withDefault = false) => {
  const sorted = opts.sort((a, b) => (a.label < b.label ? -1 : 1));
  return withDefault ? [DEFAULT_OPTION, ...sorted] : sorted;
};
export const getUniqueSortedOptions = (options, withDefault = false) => {
  const opts = [];
  options.forEach((op) => {
    if (opts.every((o) => o.value !== op.value)) opts.push(op);
  });
  return getSortedOptions(opts, withDefault);
};

export const getVendorOptions = (allOptions, isSS = false) => {
  const opts = getUniqueSortedOptions(
    (allOptions.vendors || []).map((o) => o.option),
  );
  return isSS ? [DEFAULT_OPTION, ...opts] : opts;
};

export const getStandardItemOptions = (allOptions, vendorIds, isSS = false) => {
  const opts = getUniqueSortedOptions(
    (allOptions.siIds || [])
      .filter((o) => vendorIds.length === 0 || vendorIds.includes(o.vendorId))
      .map((o) => o.option),
  );
  return isSS ? [DEFAULT_OPTION, ...opts] : opts;
};

export const getMakeModelOptions = (allOptions, vendorId, siId, isVS) => {
  return getUniqueSortedOptions(
    (allOptions.makeModels || [])
      .filter((o) => (isVS || vendorId === o.vendorId) && siId === o.siId)
      .map((o) => o.option),
  );
};

export const getSinOptions = (allOptions) => {
  return getSortedOptions(
    (allOptions.sins || []).map((o) => o.option),
    true,
  );
};
