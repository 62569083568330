import React from 'react';
import { Alert, Button } from '@gsa/afp-component-library';
import { useTaskDetail } from './task-detail-provider';

const TaskMessages = () => {
  const { successMessage, errorMessage, setData } = useTaskDetail();
  return (
    <>
      {errorMessage && (
        <Alert
          slim
          type="error"
          actions={
            <div className="position-relative">
              <div className="position-static">
                <div className="position-absolute bottom-0 right-0 display-flex flex-align-center flex-justify-center">
                  <Button
                    aria-label="close"
                    data-testid="error-close"
                    variant="unstyled"
                    style={{ color: 'black' }}
                    className="margin-x-1 text-large text-heavy font-sans-xl text-gray-500"
                    onClick={() => setData('SET_ERROR_MESSAGE', '')}
                    leftIcon={{
                      name: 'close',
                    }}
                  />
                </div>
              </div>
            </div>
          }
        >
          {errorMessage}
        </Alert>
      )}
      {successMessage && (
        <Alert
          slim
          actions={
            <div className="position-relative">
              <div className="position-static">
                <div className="position-absolute bottom-0 right-0 display-flex flex-align-center flex-justify-center">
                  <Button
                    aria-label="close"
                    variant="unstyled"
                    data-testid="success-close"
                    style={{ color: 'black' }}
                    className="margin-x-1 text-large text-heavy font-sans-xl text-gray-500"
                    onClick={() => setData('SET_SUCCESS_MESSAGE', '')}
                    leftIcon={{ name: 'close' }}
                  />
                </div>
              </div>
            </div>
          }
          type="success"
        >
          {successMessage}
        </Alert>
      )}
    </>
  );
};

export default TaskMessages;
