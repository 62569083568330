import { gql } from '@apollo/client';

const GET_VENDORS = gql`
  query getVendorsByPermission(
    $operation: String!
    $subject: String!
    $filters: [Filter!]
  ) {
    getVendorsByPermission(
      operation: $operation
      subject: $subject
      filters: $filters
    ) {
      id
      fleetVendorNumber
      vendorName
      vendorContacts {
        contactType
        city
        state {
          stateCode
        }
        postalCode
      }
      vendorTypes {
        vendorTypeCode
        commonCode {
          name
        }
      }
      fleetStatus
    }
  }
`;

export default GET_VENDORS;

export const GET_VENDOR_DETAIL_BY_ID = gql`
  query getVendorDetailById($id: String!) {
    getVendorDetailById(id: $id) {
      id
      vehicleSupplierInfo {
        id
        supplierTypeCode
        vendorAssociatedLocations {
          id
          vehicleSupplierId
          associatedLocationName
          address1
          city
          postalCode
          state {
            stateCode
            stateName
          }
          country {
            countryName
            isoCountryCode2
          }
          commonCode {
            code
            name
          }
        }
      }
    }
  }
`;

export const GET_VENDOR_LOCATIONS_BY_TYPES = gql`
  query getVendorLocationsByTypes(
    $vendorId: String!
    $locationTypeCodes: [String!]!
  ) {
    getVendorLocationsByTypes(
      vendorId: $vendorId
      locationTypeCodes: $locationTypeCodes
    ) {
      id
      vehicleSupplierId
      associatedLocationName
      address1
      city
      postalCode
      state {
        stateCode
        stateName
      }
      country {
        countryName
        isoCountryCode2
      }
      commonCode {
        code
        name
      }
    }
  }
`;
