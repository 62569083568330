import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { AFPTable, EmptyState } from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import { without } from 'lodash';
import { useBidLineDetails } from '../provider/bid-line-details-provider';
import {
  getPhotoColumns,
  DELETE_ACTION,
  MARK_REP_IMG_ACTION,
} from '../provider/table-defs';
import { SET_BILINDE_ATTACHMENTS_ORDER } from '../provider/queries';

const ImagesTable = ({ setDocToDelete }) => {
  const { bidLine, photos, reloadBidLineDocuments } = useBidLineDetails();

  const [setBidLineAttachmentsOrder] = useMutation(
    SET_BILINDE_ATTACHMENTS_ORDER,
    {
      onCompleted: () => {
        reloadBidLineDocuments();
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      },
    },
  );

  const photosWithRepresentativeImage = useMemo(() => {
    if (!photos || photos.length === 0) return [];
    if (photos.length === 1) {
      return [{ ...photos[0], representativeImage: true }];
    }
    const sortedPhotos = photos.sort((a, b) => a.orderNum - b.orderNum);
    sortedPhotos[0].representativeImage = true;
    return [...sortedPhotos];
  }, [photos]);

  const onActionClick = useCallback(
    (action, { original }, rows) => {
      if (action === DELETE_ACTION) {
        setDocToDelete(original);
      }

      if (action === MARK_REP_IMG_ACTION) {
        const newRepresentativeImageId = parseInt(
          original.bidLineAttachmentId,
          10,
        );
        const ids = rows.map((r) =>
          parseInt(r.original.bidLineAttachmentId, 10),
        );
        const idsWithoutRepImage = without(ids, newRepresentativeImageId);
        setBidLineAttachmentsOrder({
          variables: {
            bideLineId: parseInt(bidLine?.id, 10),
            orderedAttachmentIds: [
              newRepresentativeImageId,
              ...idsWithoutRepImage,
            ],
          },
        });
      }
    },
    [setBidLineAttachmentsOrder, bidLine],
  );

  const photoColumns = useMemo(() => getPhotoColumns(onActionClick), [
    onActionClick,
    getPhotoColumns,
  ]);

  return (
    <>
      <AFPTable
        fullWidth
        columns={photoColumns}
        data={photosWithRepresentativeImage}
        defaultSort={[['orderNum', 'asc']]}
      />
      {!photosWithRepresentativeImage.length && (
        <div className="table-empty-state">
          <EmptyState bottomText="No pictures available. Please use the add photos button to upload pictures." />
        </div>
      )}
    </>
  );
};

ImagesTable.propTypes = {
  setDocToDelete: PropTypes.func.isRequired,
};

export default ImagesTable;
