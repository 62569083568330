import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Modal, Button, Alert, Icon } from '@gsa/afp-component-library';
import OverlaySpinner from '../../../../components/overlay-spinner';
import { useBidLineDetails } from '../provider/bid-line-details-provider';
import { SAVE_BID_LINE_EC_RELATION } from '../provider/queries';
import EditIncludesExcludesBlock from './edit-includes-excludes-block';
import EditRequiresBlock from './edit-requires-block';
import {
  EC_CONFLICT_TYPE,
  getOptionalEcList,
  isInactiveOptionType,
} from '../provider/helpers';
import { validateConflicts, showConflictTrace } from './conflicts-components';

const EditConflictModal = ({ row, onClose }) => {
  const [alert, setAlert] = useState(null);
  const {
    optionalECs,
    getOptionalEC,
    setOptionalEC,
    isAdmin,
  } = useBidLineDetails();
  const original = getOptionalEC(row.original);
  const ecList = getOptionalEcList(optionalECs);

  const options = ecList
    .filter(
      ({ equipmentCode, inputOptionType }) =>
        equipmentCode !== original.equipmentCode &&
        !isInactiveOptionType(inputOptionType),
    )
    .sort((a, b) => (a.equipmentCode < b.equipmentCode ? -1 : 1))
    .map(({ equipmentCode, equipment }) => ({
      label: equipment,
      value: equipmentCode,
    }));

  const includesRequiresOptions = options.filter(
    ({ value }) => !original.excludes.value.includes(value),
  );
  const excludesOptions = options.filter(
    ({ value }) =>
      !original.includes.value.includes(value) &&
      original.requires.value.every((ecs) => !ecs.includes(value)),
  );

  const [saveEcRelation, { loading }] = useMutation(SAVE_BID_LINE_EC_RELATION);

  const saveConflict = async (field, value) => {
    const conflict = original[field];
    const ecMapFn = (ec) => ({
      relatedEc: ec,
      relatedEcId: ecList.find((item) => item.equipmentCode === ec).id,
    });

    // prepare api variables
    const input = { relationshipCode: EC_CONFLICT_TYPE[field] };
    if (conflict.id) {
      // existing conflict
      input.id = conflict.id;
    } else {
      // new conflict
      input.bidLineEcId = original.bidLineEcId;
      input.associationId = original.id;
      input.equipmentCode = original.equipmentCode;
    }
    if (field === 'requires') {
      const newValue = value.filter((ecs) => ecs.length > 0);
      input.conflicts = {
        operator: '$or',
        relations: newValue.map((ecs) => ({
          operator: '$and',
          ecs: ecs.map(ecMapFn),
        })),
      };
    } else {
      input.conflicts = {
        operator: '$and',
        ecs: value.map(ecMapFn),
      };
    }

    // call api to save conflicts
    if (isAdmin) return { id: conflict.id || 0 }; // do NOT save for admin
    return saveEcRelation({ variables: { input } })
      .then(({ data }) => ({ id: data.saveBidLineEcRelation }))
      .catch((error) => ({ error }));
  };

  const onSave = async (field, value) => {
    setAlert(null);

    // validate conflicts
    const validate = validateConflicts(
      original.equipmentCode,
      field,
      value,
      optionalECs,
    );
    if (!validate.isValid) {
      const EC = original.equipmentCode;
      setAlert({
        type: 'error',
        message: (
          <>
            <div>
              Unable to save <b>{field.toUpperCase()}</b>. The conflict is
              highlighted below.
            </div>
            <li>
              <u>
                {validate.rootEc} must have {validate.contradictEc}
              </u>
              : {showConflictTrace(validate.includeTrace, EC, field, value)}
            </li>
            <li>
              <u>
                {validate.rootEc} cannot have {validate.contradictEc}
              </u>
              : {showConflictTrace(validate.excludeTrace, EC, field, value)}
            </li>
          </>
        ),
      });
      return;
    }

    // call API to save conflict
    const { id, error } = await saveConflict(field, value);

    if (id) {
      // success - show success message; update provider data
      setAlert({
        type: 'success',
        message: `${field.toUpperCase()} has been saved successfully`,
      });
      setOptionalEC(row.original, {
        data: {
          [field]: {
            id,
            value: value.filter((item) => item.length > 0),
          },
        },
      });
    } else {
      // error - show error message
      setAlert({
        type: 'error',
        message: `Unable to save ${field.toUpperCase()}: ${error.message}`,
      });
    }
  };

  return (
    <div className="afp-modal-wrapper edit-conflicts-modal">
      <div className="afp-modal-overlay">
        <Modal
          id="edit-conflict-modal"
          variant="extra-large"
          title={<h2>Manage conflicts</h2>}
          onClose={onClose}
          actions={
            <div>
              <Button
                data-testid="close-edit-conflict-modal-btn"
                variant="primary"
                onClick={onClose}
                label="Close"
              />
            </div>
          }
        >
          {loading && <OverlaySpinner />}
          <div className="margin-y-2 text-bold">{original.equipment}</div>
          {alert && (
            <Alert
              type={alert.type}
              slim
              focused
              className="margin-top-0 margin-bottom-2"
              showClose
              onClose={() => setAlert(null)}
            >
              {alert.message}
            </Alert>
          )}
          <div className="margin-bottom-2">
            <EditIncludesExcludesBlock
              original={original}
              onSave={onSave}
              options={includesRequiresOptions}
              field="includes"
            />
          </div>
          <div className="margin-bottom-2">
            <EditIncludesExcludesBlock
              original={original}
              onSave={onSave}
              options={excludesOptions}
              field="excludes"
            />
          </div>
          <div>
            <EditRequiresBlock
              original={original}
              onSave={onSave}
              options={includesRequiresOptions}
              field="requires"
            />
          </div>
          <div style={{ marginTop: 48, marginBottom: -48 }}>
            <Icon iconName="warning" className="usa-icon--size-3 margin-x-1" />
            Please use the Save buttons to save your changes. Any unsaved
            changes will be lost when you close the modal.
          </div>
        </Modal>
      </div>
    </div>
  );
};

EditConflictModal.propTypes = {
  row: PropTypes.shape(PropTypes.objectOf({})).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditConflictModal;
