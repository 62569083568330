import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import HtmlDiff from 'htmldiff-js';

const TypeHighlighter = ({ entity, value, fieldName }) => {
  const [currentValue, setCurrentValue] = useState(null);
  const [previousValue, setPreviousValue] = useState(null);
  const [showDiff, setShowDiff] = useState(false);

  useEffect(() => {
    setCurrentValue(value);
    if (entity?.change?.differences?.differences) {
      const fieldChange = entity?.change?.differences?.differences?.find(
        (d) => d?.path.indexOf(fieldName) >= 0,
      );

      if (fieldChange) {
        let lhs = fieldChange?.lhs;
        if (fieldChange?.kind === 'N') {
          lhs = '';
        }
        if (fieldChange?.lhs === 'S') {
          lhs = 'Standard';
        }
        if (fieldChange?.lhs === 'O') {
          lhs = 'Optional';
        }
        setPreviousValue(lhs);
        setShowDiff(true);
      }
    } else {
      setPreviousValue(null);
      setShowDiff(false);
    }
  }, [entity, value]);

  return (
    <>
      {!currentValue && <span>&ndash;</span>}

      {currentValue && !showDiff && <span>{currentValue}</span>}
      {currentValue &&
        showDiff &&
        ReactHtmlParser(`<div>
              ${HtmlDiff.execute(previousValue, currentValue)}
           </div>`)}
    </>
  );
};

TypeHighlighter.defaultProps = {
  entity: {},
  value: '',
  fieldName: '',
};

TypeHighlighter.propTypes = {
  entity: PropTypes.shape(Object),
  value: PropTypes.string,
  fieldName: PropTypes.string,
};

export default TypeHighlighter;
