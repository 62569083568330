import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { isEmpty } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { getValidator } from '../../utilities/validations/error-rules';

yup.addMethod(
  yup.string,
  'afpValidate',
  function callback(errorMessage, field, isValid) {
    return this.test(field, errorMessage, function getError() {
      const { path, createError } = this;
      return isValid || createError({ path, message: errorMessage });
    });
  },
);
yup.addMethod(
  yup.number,
  'afpValidateNum',
  function callback(errorMessage, field, isSequenceValid) {
    return this.test(field, errorMessage, function getError() {
      const { path, createError } = this;
      return isSequenceValid || createError({ path, message: errorMessage });
    });
  },
);
const EquipmentCodeInputSchema = yup.object().shape({
  code: yup.lazy((value) => {
    const isValid = getValidator(value, 'EquipmentCode');
    if (value !== undefined && !isValid) {
      return yup
        .string()
        .afpValidate(
          'Make sure the code meets the requirements listed below.',
          'code',
          isValid,
        )
        .required();
    }
    return yup.mixed().notRequired();
  }),

  title: yup.lazy((value) => {
    const isValid = getValidator(value, 'Title');
    if (value !== undefined && !isValid) {
      return yup
        .string()
        .afpValidate(
          'Make sure the title meets the requirements listed below.',
          'title',
          isValid,
        )
        .required();
    }
    return yup.mixed().notRequired();
  }),
  program: yup.lazy((value) => {
    if (value !== undefined) {
      return yup.string().required('Program is required');
    }
    return yup.mixed().notRequired();
  }),

  // Validate sequence:- number equal to or greater than 0, 5 digits max and no decimals.
  sequence: yup.lazy((value) => {
    const isSequenceValid = getValidator(value, 'Sequence');
    if (value !== undefined && !isSequenceValid) {
      // Used by sequence which is a number, no white spaces.
      // Add transformation to invalidate white space.
      return yup
        .number()
        .transform((sequencevalue, originalValue) =>
          /[\s./]/.test(originalValue) ? NaN : sequencevalue,
        )
        .afpValidateNum(
          'Make sure the Sequence meets the requirements listed below.',
          'sequence',
          isSequenceValid,
        )
        .required();
    }
    return yup.number().notRequired();
  }),
  quantityRequired: yup.string().required('Valid quantity is required'),
  status: yup.string().required('Status is required'),
  unitCode: yup.string(),
  category: yup.lazy((value) => {
    if (value !== undefined) {
      return yup.string().required('Category code is required');
    }
    return yup.mixed().notRequired();
  }),
  copyFromYear: yup.string(),
  year: yup.lazy((value) => {
    if (value !== undefined) {
      return yup.string().required('Contract year is required');
    }
    return yup.mixed().notRequired();
  }),
  copyFromEquipmentCodeId: yup.number().when('copyFromYear', {
    is: (copyFromYear) => !isEmpty(copyFromYear),
    then: yup
      .number()
      .required('Equipment code is required')
      .moreThan(0, 'Equipment code is required')
      .typeError('Equipment code is required'),
  }),
});

const EquipmentCodeFormContext = ({ equipmentCode, children }) => {
  const inputData = {
    code: equipmentCode?.code,
    title: equipmentCode?.title,
    program: equipmentCode?.program,
    category: equipmentCode?.category,
    status: equipmentCode?.status,
    sequence: equipmentCode?.sequence,
    quantityRequired: equipmentCode?.quantityRequiredCode?.code,
    tags: equipmentCode?.tags?.value,
    unit: equipmentCode?.unitCode?.code,
    content: equipmentCode?.content?.content,
    year: equipmentCode?.year,
    copyFromEquipmentCodeId: 0,
    copyFromYear: '',
  };

  const methods = useForm({
    resolver: yupResolver(EquipmentCodeInputSchema),
    defaultValues: inputData,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

EquipmentCodeFormContext.defaultProps = {
  equipmentCode: {
    code: '',
    title: '',
    program: '',
    category: '',
    status: '',
    sequence: 0,
    quantityRequiredCode: {
      code: '',
    },
    tags: null,
    unitCode: {
      code: '',
    },
    content: null,
    year: new Date().getFullYear(),
  },
};

EquipmentCodeFormContext.propTypes = {
  equipmentCode: PropTypes.shape({
    code: PropTypes.string,
    title: PropTypes.string,
    program: PropTypes.string,
    category: PropTypes.string,
    status: PropTypes.string,
    sequence: PropTypes.number,
    quantityRequiredCode: PropTypes.shape({
      code: PropTypes.string,
    }),
    tags: PropTypes.shape({
      value: PropTypes.shape(Object),
    }),
    unitCode: PropTypes.shape({
      code: PropTypes.string,
    }),
    content: PropTypes.shape({
      content: PropTypes.string,
    }),
    year: PropTypes.number,
  }),
  children: PropTypes.node.isRequired,
};

export default EquipmentCodeFormContext;
