import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Spinner, Button } from '@gsa/afp-component-library';
import { GET_SIGNED_READ_URL } from './query';

import AttachmentModal from '../../../../components/Attachment/AttachmentModal';
import { useContractLine } from '../provider/contract-line-provider';

const Modal = ({ url, onClose }) => {
  return (
    <AttachmentModal
      id="image-attachment-preview-modal"
      show
      onClose={onClose}
      hideClose
      variant="large"
      className="contractline-image-attachment-modal"
      actions={
        <Button
          data-testid="attachment-image-modal-close"
          label="Close"
          onClick={onClose}
        />
      }
    >
      <img src={url} alt="Contract line attachment" />
    </AttachmentModal>
  );
};

Modal.propTypes = {
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const Photo = ({ url, isLoading, scanStatus, setShowModal }) => {
  const width = 102;
  const height = 66;
  let content = null;
  if (!url && isLoading) {
    content = <Spinner size="small" />;
  } else if (scanStatus === 'New') {
    content = (
      <div title="Scanning file for viruses">
        <div className="body-small-tight text-center">Virus scanning...</div>
      </div>
    );
  } else if (scanStatus === 'Quarantined') {
    content = (
      <div>
        <div className="body-small-tight">Quarantined</div>
      </div>
    );
  } else if (scanStatus === 'Accepted' && url) {
    content = (
      <img src={url} alt="Contract attachment" width={width} height={height} />
    );
  }

  return (
    <>
      <div
        role="button"
        tabIndex="0"
        onClick={() => {
          if (scanStatus === 'Accepted') {
            setShowModal(true);
          }
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13 || e.keyCode === 32) {
            if (scanStatus === 'Accepted') {
              setShowModal(true);
            }
          }
        }}
        style={{
          display: 'flex',
          width,
          height: height + 2,
          margin: 8,
          border: `1px solid #A9AEB1`,
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        {content}
      </div>
    </>
  );
};

Photo.propTypes = {
  url: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  scanStatus: PropTypes.string.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

const PhotoCell = ({ id }) => {
  const { photos, getSignedUrl, setSignedUrl } = useContractLine();
  const photo = useMemo(() => photos.find((p) => p.id === id), [photos, id]);
  const [showModal, setShowModal] = useState(false);
  const signedUrl = useMemo(() => getSignedUrl(photo?.documentMetadataId), [
    photo?.documentMetadataId,
    getSignedUrl,
  ]);

  const onSignedUrlCompleted = useCallback(
    (data) => {
      setSignedUrl(photo?.documentMetadataId, data?.generateReadSignedURL);
    },
    [photo?.documentMetadataId, setSignedUrl],
  );

  const [loadSignedUrl] = useLazyQuery(GET_SIGNED_READ_URL, {
    fetchPolicy: 'cache-and-network',
    onCompleted: onSignedUrlCompleted,
    onError: () => {},
  });

  useEffect(() => {
    if (!signedUrl) {
      loadSignedUrl({
        variables: {
          fileKey: photo?.clAttachmentMetadata?.fileLocation,
          fileType: photo?.clAttachmentMetadata?.fileMimeType,
        },
      });
    }
  }, [signedUrl, photo, loadSignedUrl]);

  return (
    <>
      <Photo
        url={signedUrl}
        scanStatus={photo?.clAttachmentMetadata?.scanStatus}
        setShowModal={setShowModal}
      />
      {showModal && (
        <Modal url={signedUrl} onClose={() => setShowModal(false)} />
      )}
    </>
  );
};

PhotoCell.propTypes = {
  id: PropTypes.string.isRequired,
};

export default PhotoCell;
