import React from 'react';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import CatalogCodeForm from '../../../components/forms/catalog-codes-form';
import { useCatalogCodes } from '../catalog-codes-provider';
import {
  getAdditionalProps,
  getFormattedTags,
  removeCustomSchemaFields,
} from './helper';

const CatalogCodeAdd = () => {
  const ability = useAppAbility();
  const {
    metadataOfSelectedCategory,
    catalogCodesOptions,
    createCatalogCode,
  } = useCatalogCodes();

  let equipmentCategorySchema;
  if (metadataOfSelectedCategory.category === 'Equipment Category') {
    equipmentCategorySchema =
      metadataOfSelectedCategory.propertiesSchema.properties;
  }

  let fuelTypeCategorySchema;
  if (metadataOfSelectedCategory.category === 'Fuel Type') {
    fuelTypeCategorySchema = metadataOfSelectedCategory.propertiesSchema;
  }

  const onSubmit = async (data) => {
    const tags = getFormattedTags(data);
    const newData = removeCustomSchemaFields(data);
    const additionalProps = getAdditionalProps(
      metadataOfSelectedCategory.category,
      data.allowNewBids,
      data,
    );

    const catalogcode = {
      ...newData,
      category: metadataOfSelectedCategory.category,
      additionalProps,
      parentCategory: metadataOfSelectedCategory.parentCategory
        ? metadataOfSelectedCategory.parentCategory
        : null,
      tags,
      changeType: 'Add',
    };

    await createCatalogCode({ variables: { catalogcode } });
  };

  return (
    <CatalogCodeForm
      formId="catalog-code-add-form"
      selectedCatalogCode={null}
      metadataOfSelectedCategory={metadataOfSelectedCategory}
      catalogOptionsForTagsAndParentCodes={catalogCodesOptions}
      isNew
      equipmentCategorySchema={equipmentCategorySchema}
      fuelTypeCategorySchema={fuelTypeCategorySchema}
      onSubmit={onSubmit}
      ability={ability}
    />
  );
};

export default CatalogCodeAdd;
