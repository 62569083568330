import React from 'react';
import PropTypes from 'prop-types';
import GenericMessagesToast from '../../components/Toast/generic-messages-toast';
import { useEquipmentCodes } from './equipment-codes-provider';

const EquipmentCodeMessages = ({ isModal }) => {
  const { ecMessages, removeEcMessageById } = useEquipmentCodes();

  return (
    <GenericMessagesToast
      onClose={removeEcMessageById}
      isModal={isModal}
      allMessages={ecMessages}
    />
  );
};

EquipmentCodeMessages.propTypes = {
  isModal: PropTypes.bool.isRequired,
};

export default EquipmentCodeMessages;
