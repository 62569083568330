import React from 'react';
import DocumentPreview from './document-preview';

const DocumentPreviewPage = () => {
  return (
    <div className="grid-container margin-bottom-7">
      <DocumentPreview />
    </div>
  );
};

export default DocumentPreviewPage;
