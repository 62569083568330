/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useParams } from 'react-router-dom';
import moment from 'moment';
import { emDashUnicode } from '../../../utilities/constants';

export function getColumns({ isSop = true }) {
  return [
    {
      Header: 'Contract line',
      accessor: 'scheduleLine',
      sortable: true,
      Cell: ({ row }) => {
        const { contractHeaderId } = useParams();
        const { scheduleLine, id } = row?.original || {};
        return (
          <RouterLink
            to={`/catalog/contract/${contractHeaderId}/contract-line-template/${id}`}
            className="text-bold margin-left-1"
          >
            {scheduleLine || emDashUnicode}
          </RouterLink>
        );
      },
    },
    {
      Header: 'Body make',
      accessor: 'make.makeName',
      sortable: false,
      Cell: ({ row }) => {
        const { make } = row?.original || {};
        return `${make?.makeName || emDashUnicode}`;
      },
    },
    {
      Header: 'Body model',
      accessor: 'model.modelName',
      sortable: false,
      Cell: ({ row }) => {
        const { model } = row?.original || {};
        return `${model?.modelName || emDashUnicode}`;
      },
    },
    {
      Header: 'Chassis make',
      accessor: 'chassisMake.makeName',
      sortable: false,
      Cell: ({ row }) => {
        const { chassisMake } = row?.original || {};
        if (chassisMake?.makeName?.toLowerCase() === 'unkown') {
          return emDashUnicode;
        }
        return `${chassisMake?.makeName || emDashUnicode}`;
      },
    },
    {
      Header: 'Chassis model',
      accessor: 'chassisModel.modelName',
      sortable: false,
      Cell: ({ row }) => {
        const { chassisModel } = row?.original || {};
        return `${chassisModel?.modelName || emDashUnicode}`;
      },
    },
    ...(isSop
      ? [
          {
            Header: 'Open date/time',
            accessor: 'orderStartDate',
            sortable: true,
            Cell: ({ row }) => {
              const { orderStartDate } = row?.original || {};
              return orderStartDate
                ? moment(orderStartDate).utc().format('YYYY-MM-DD hh:mm A')
                : emDashUnicode;
            },
          },
          {
            Header: 'Closeout date/time',
            accessor: 'orderEndDate',
            sortable: true,
            Cell: ({ row }) => {
              const { orderEndDate } = row?.original || {};
              return orderEndDate
                ? moment(orderEndDate).utc().format('YYYY-MM-DD hh:mm A')
                : emDashUnicode;
            },
          },
          {
            Header: 'Direct',
            accessor: 'isDirect',
            sortable: true,
            Cell: ({ row }) => {
              const { isDirect } = row?.original || {};
              if (isDirect === 1) return 'Yes';
              if (isDirect === 0) return 'No';
              return emDashUnicode;
            },
          },
        ]
      : [
          {
            Header: 'Manufacturer model number',
            accessor: 'manufacturerModelNumber',
            sortable: true,
            Cell: ({ row }) => {
              const { manufacturerModelNumber } = row?.original || {};
              return manufacturerModelNumber || emDashUnicode;
            },
          },
          {
            Header: 'Make available for Ordering',
            accessor: 'makeAvailableForOrdering',
            sortable: true,
            Cell: ({ row }) => {
              const { orderEndDate, orderStartDate } = row?.original || {};
              if (orderEndDate && orderStartDate) {
                const startDate = new Date(orderStartDate);
                const endDate = new Date(orderEndDate);
                const currentDate = new Date();

                return startDate <= currentDate && currentDate <= endDate
                  ? 'Yes'
                  : 'No';
              }
              return emDashUnicode;
            },
          },
        ]),
  ];
}

export const RowSubComponent = ({ row: { original = {} }, isSop }) => {
  const { contractHeaderId } = useParams();
  return (
    <section className="grid-row grid-gap padding-left-5">
      <section className="grid-col-4">
        <h3 className="text-primary margin-top-0">CONTRACT LINE DETAILS</h3>
        <section className="display-flex flex-column">
          <p className="border-bottom border-base-lighter margin-top-0">
            <RouterLink
              to={`/catalog/contract/${contractHeaderId}/contract-line-template/${original?.id}?tabIndex=0`}
              className="text-bold margin-left-1"
            >
              Contract line
            </RouterLink>
          </p>
          {isSop && (
            <>
              <p className="border-bottom border-base-lighter margin-top-0">
                <RouterLink
                  to={`/catalog/contract/${contractHeaderId}/contract-line-template/${original?.id}?tabIndex=1`}
                  className="text-bold margin-left-1"
                >
                  Contract line details
                </RouterLink>
              </p>
              <p className="border-bottom border-base-lighter margin-top-0">
                <RouterLink
                  to={`/catalog/contract/${contractHeaderId}/contract-line-template/${original?.id}?tabIndex=2`}
                  className="text-bold margin-left-1"
                >
                  Minimum requirements
                </RouterLink>
              </p>
              <p className="border-bottom border-base-lighter margin-top-0">
                <RouterLink
                  to={`/catalog/contract/${contractHeaderId}/contract-line-template/${original?.id}?tabIndex=3`}
                  className="text-bold margin-left-1"
                >
                  Optional equipment
                </RouterLink>
              </p>
              <p className="border-bottom border-base-lighter margin-top-0">
                <RouterLink
                  to={`/catalog/contract/${contractHeaderId}/contract-line-template/${original?.id}?tabIndex=4`}
                  className="text-bold margin-left-1"
                >
                  Engine and fuel
                </RouterLink>
              </p>
              <p className="border-bottom border-base-lighter margin-top-0">
                <RouterLink
                  to={`/catalog/contract/${contractHeaderId}/contract-line-template/${original?.id}?tabIndex=5`}
                  className="text-bold margin-left-1"
                >
                  Colors
                </RouterLink>
              </p>
              <p className="border-bottom border-base-lighter margin-top-0">
                <RouterLink
                  to={`/catalog/contract/${contractHeaderId}/contract-line-template/${original?.id}?tabIndex=6`}
                  className="text-bold margin-left-1"
                >
                  Documentation
                </RouterLink>
              </p>
            </>
          )}
        </section>
      </section>
      <section className="grid-col">
        <h3 className="text-primary margin-top-0">ADDITIONAL INFORMATION</h3>
        <section className="display-flex flex-column">
          {isSop ? (
            <>
              <p className="border-bottom border-base-lighter margin-top-0">
                <span className="text-bold">Program:</span>
                <span className="margin-left-1">
                  {original?.programName || emDashUnicode}
                </span>
              </p>
              <p className="border-bottom border-base-lighter margin-top-0">
                <span className="text-bold">Vehicle group:</span>
                <span className="margin-left-1">
                  {`${
                    original?.standardItem?.vehicleGroup?.code || emDashUnicode
                  } - ${
                    original?.standardItem?.vehicleGroup?.title || emDashUnicode
                  }`}
                </span>
              </p>
              <p className="border-bottom border-base-lighter margin-top-0">
                <span className="text-bold">Vehicle type:</span>
                <span className="margin-left-1">
                  {`${original?.standardItem?.vehicleType || emDashUnicode} - ${
                    original?.standardItem?.vehicleTypeCode?.title ||
                    emDashUnicode
                  }`}
                </span>
              </p>
            </>
          ) : (
            <p className="border-bottom border-base-lighter margin-top-0">
              <span className="text-bold">Additional information:</span>
              <span className="margin-left-1">
                {`${original?.additionalInformation || emDashUnicode}`}
              </span>
            </p>
          )}

          <p className="border-bottom border-base-lighter margin-top-0">
            <span className="text-bold">Standard item:</span>
            <span className="margin-left-1">
              {`${
                original?.standardItem?.standardItemNumber || emDashUnicode
              } - ${original?.standardItem?.title || emDashUnicode}`}
            </span>
          </p>
          <p className="border-bottom border-base-lighter margin-top-0">
            <span className="text-bold">Model year:</span>
            <span className="margin-left-1">
              {!original?.modelYear ||
              parseInt(original?.modelYear, 10) === 1901
                ? emDashUnicode
                : original?.modelYear}
            </span>
          </p>
        </section>
      </section>
    </section>
  );
};

RowSubComponent.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      standardItem: PropTypes.shape({
        vehicleGroup: PropTypes.shape({
          code: PropTypes.string,
          title: PropTypes.string,
        }),
        vehicleType: PropTypes.string,
        vehicleTypeCode: PropTypes.shape({
          title: PropTypes.string,
        }),
        standardItemNumber: PropTypes.string,
        title: PropTypes.string,
      }),
    }),
  }).isRequired,
  isSop: PropTypes.bool.isRequired,
};
